import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getTaskPriorities, getTasks, getTaskStatuses, getTaskTypes, navLinks} from "../../services/tasks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import moment from "moment";
import DatePeriodField from "../../components/fields/DatePeriodField";
import {withTranslation} from "react-i18next";
import CheckboxField from "../../components/fields/CheckboxField";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getBusinessUnits} from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {getUsers} from "../../services/user";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import HLine from "../../components/left-bar/HLine";

export default
@withTranslation()
@connect(state => ({
    currentUser: state.auth.user,
}),{
    showMessage: messageDialogActions.show,
})
class ListPage extends React.Component
{
    state = {
        search: {
            dateStart: moment(),
            dateEnd: moment(),
            createdDate: false,
            businessUnits: [],
            taskTypes: [],
            taskStatuses: [],
            taskPriorities: [],
            executor: null,
            creator: null,
            showArchived: false,
        },
        businessUnits: [],
        taskTypes: [],
        taskStatuses: [],
        taskPriorities: [],
        users: [],
        refreshTable: false,
    };

    columnData = [
        { id: 'num', label: 'Номер' },
        { id: 'title', label: 'Наименование' },
        { id: 'statusText', label: 'Статус' },
        { id: 'priorityText', label: 'Приоритет', colorField: 'priorityColor' },
        { id: 'createdAt', label: 'Дата создания', dateFormat: 'DD.MM.YYYY'},
        { id: 'expectDate', label: 'Плановая дата завершения', dateFormat: 'DD.MM.YYYY'},
        { id: 'expectedAt', label: 'Фактическая дата завершения', dateFormat: 'DD.MM.YYYY'},
        { id: 'businessUnitName', label: 'Business unit'},
        { id: 'executor.username', label: 'Исполнитель' },
        { id: 'creator.username', label: 'Постановщик' },
    ];

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });
        getTaskTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskTypes: response.data,
                    })
                }
            });
        getTaskStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskStatuses: response.data,
                    })
                }
            });
        getTaskPriorities()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskPriorities: response.data,
                    })
                }
            });
    }

    getTableData = (...params) => {
        const {search} = this.state;
        return getTasks(processMomentFieldInObject(search, ['dateStart', 'dateEnd']), ...params);
    };

    handleAdd = () => {
        this.props.history.push('/tasks/view/0');
    };

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    render() {
        const { t, currentUser } = this.props;
        const {search, refreshTable, taskTypes, taskStatuses, taskPriorities, users, businessUnits} = this.state;
        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <CheckboxField
                    label={t('Date of creation')}
                    value={search.createdDate}
                    onChange={this.handleChange('createdDate')}
                    margin="none"
                />
                <DatePeriodField
                    valueFrom={search.dateStart}
                    valueTo={search.dateEnd}
                    maxDate={moment()}
                    onChangeFrom={this.handleChange('dateStart')}
                    onChangeTo={this.handleChange('dateEnd')}
                    label={t('Date')}
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.businessUnits}
                    onChange={this.handleChange('businessUnits')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={taskTypes}
                    label={t('Type')}
                    value={search.taskTypes}
                    onChange={this.handleChange('taskTypes')}
                    valueField='id'
                    textField='title'
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={taskStatuses}
                    label={t('Status')}
                    value={search.taskStatuses}
                    onChange={this.handleChange('taskStatuses')}
                    valueField='id'
                    textField='title'
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={taskPriorities}
                    label={t('Priority')}
                    value={search.taskPriorities}
                    onChange={this.handleChange('taskPriorities')}
                    valueField='id'
                    textField='title'
                    fullWidth
                />
                <AutocompleteSelectField
                    label={t('Executor')}
                    value={search.executor}
                    name="executor"
                    onChange={this.handleChange('executor')}
                    fullWidth
                    options={users.map(user => ({value: user.id, label: user.username})).sort((a, b) => {
                        if (a.value === currentUser.id) {
                            return -1;
                        } else if (b.value === currentUser.id) {
                            return  1;
                        } else {
                            if (a.label < b.label) {
                                return -1;
                            } else {
                                return 1;
                            }
                        }
                    })}
                />
                <AutocompleteSelectField
                    label={t('Creator')}
                    value={search.creator}
                    name="creator"
                    onChange={this.handleChange('creator')}
                    fullWidth
                    options={users.map(user => ({value: user.id, label: user.username})).sort((a, b) => {
                        if (a.value === currentUser.id) {
                            return -1;
                        } else if (b.value === currentUser.id) {
                            return  1;
                        } else {
                            if (a.label < b.label) {
                                return -1;
                            } else {
                                return 1;
                            }
                        }
                    })}
                />
                <CheckboxField
                    label={t('Show archived')}
                    value={search.showArchived}
                    onChange={this.handleChange('showArchived')}
                />
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <HLine/>
                <ActionButton onClick={this.handleAdd}>Создать задачу</ActionButton>
            </LeftBar>
            <Content title={t('Tasks')}>
                <DataTable
                    dataFunc={this.getTableData}
                    selector={false}
                    columnData={this.columnData}
                    linkTemplate={task => `/tasks/view/${task.id}`}
                    refresh={refreshTable}
                    numeration
                />
            </Content>
        </React.Fragment>
    }
}
