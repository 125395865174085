import React from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {getContacts, getGuest, navLinks} from "../../services/guests";
import ActionButton from "../../components/button/ActionButton";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";
import GuestLeftFields from "./GuestLeftFields";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'dateCreated', numeric: false, disablePadding: false, label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'user', numeric: false, disablePadding: false, label: 'User' },
    { id: 'target', numeric: false, disablePadding: false, label: 'Interaction topic' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Interaction type' },
    { id: 'result', numeric: false, disablePadding: false, label: 'Interaction result' },
    { id: 'comment', numeric: false, disablePadding: false, label: 'Comment' },
    { id: 'files', numeric: false, disablePadding: false, files: true, label: 'Attached files' },
];

export default
@withRouter
@withTranslation()
class HistoryContactPage extends React.Component {

    state = {
        id: '',
        guest: {
            id: null,
            lastName: null,
            name: null,
            secondName: null,
            phone: null,
            payDocs: null,
        },
        refreshTable: false,
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getGuest(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: response.data
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/guests/view/${this.state.id}`);
    };

    handleContact = () => {
        this.props.history.push(`/guests/contact/${this.state.guest.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getContacts(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { t } = this.props;
        const { guest, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Interaction history")}/>}>
                    <GuestLeftFields guest={guest}/>
                    <br />
                    <br />
                    <ActionButton visible={!!guest.id} onClick={this.handleContact}>{t("Fix interaction")}</ActionButton>
                    <ActionButton onClick={this.handleClickBack}>{t("Back")}</ActionButton>
                </LeftBar>
                <Content title={t("Interaction history")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        order="desc"
                        orderBy="dateCreated"
                    />
                </Content>
            </React.Fragment>
        );
    }
}
