import React from 'react';
import {getPayDocsForSale, navLinks} from "../../services/payDocs";
import DataTable from "../../components/data-table/DataTable";
import _ from "lodash";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import SearchField from "../../components/fields/SearchField";
import ShowField from "../../components/fields/ShowField";
import NumberRangeField from "../../components/fields/NumberRangeField";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'num', numeric: false, disablePadding: true, label: 'Identifier' },
    { id: 'parlour', numeric: false, label: 'Parlour' },
];

export default
@connect(null, {showMessage: messageDialogActions.show})
@withTranslation()
class CheckPage extends React.Component {

    state = {
        search: '',
        searchFrom: '',
        searchTo: '',
        selected: [],
        total: 0,
        refreshTable: false,
    };

    debounceRefresh = _.debounce(()=>{
        this.setState(state => ({refreshTable: !state.refreshTable}));
    }, 500);

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });

        if (prop === 'searchFrom' || prop === 'searchTo') {
            this.debounceRefresh();
        }
    };

    handleClickSearch = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleTotalChange = total => {
        this.setState({ total });
    };

    getTableData = (...params) => {
        const {search, searchFrom, searchTo} = this.state;
        return getPayDocsForSale(search, searchFrom, searchTo, ...params);
    };

    handleSendReport = () => {
        this.props.showMessage(this.props.t('Report sent')); //TODO: Сделать отправку отчета

        this.setState({
            selected: [],
        });
    };

    render() {
        const { t } = this.props;
        const { search, searchFrom, searchTo, selected, total, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search || ''}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label={t("Search for payment documents")}
                    />
                    <NumberRangeField
                        label={t("Search by number range of payment documents")}
                        valueFrom={searchFrom}
                        valueTo={searchTo}
                        onChangeFrom={this.handleChange('searchFrom')}
                        onChangeTo={this.handleChange('searchTo')}
                        label2Lines
                    />
                    <ShowField
                        label={t("Total payment documents for implementation")}
                        label2Lines
                        value={total}
                    />
                    <ShowField
                        label={t("Total marked documents")}
                        value={selected.length}
                    />
                    <ShowField
                        label={t("The difference in the number of documents")}
                        value={total - selected.length}
                    />
                    <br />
                    <br />
                    <ActionButton disabled={!selected.length} onClick={this.handleSendReport}>{t("Send report to the manager")}</ActionButton>
                </LeftBar>
                <Content title={t("Payment documents")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        onSelect={this.handleSelect}
                        onTotalChange={this.handleTotalChange}
                        refresh={refreshTable}
                        selected={selected}
                    />
                </Content>
            </React.Fragment>
        );
    }
}