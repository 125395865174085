import React from 'react';
import {Route, Switch} from "react-router";
import PersonalReportCard from "./PersonalReportCard";
import ReferralPartnerPersonalInfo from "./ReferralPartnerPersonalInfo";
import ProfilePage from "./ProfilePage";
import SalaryReportPage from "./SalaryReportPage";

class PersonalAccountPage extends React.Component {

    render() {
        return (
            <Switch>
                <Route exact path="/personal-account"                               component={ProfilePage}/>
                <Route       path="/personal-account/personal-schedule"             component={PersonalReportCard}/>
                <Route       path="/personal-account/referral-program"              component={ReferralPartnerPersonalInfo}/>
                <Route       path="/personal-account/salary"              component={SalaryReportPage}/>
            </Switch>
        );
    }
}

export default PersonalAccountPage;