import React from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {getGuest, getSales, navLinks} from "../../services/guests";
import ActionButton from "../../components/button/ActionButton";
import {formatMoney, getFalseResult} from "../../services/common";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import GuestLeftFields from "./GuestLeftFields";
import {Link} from "react-router-dom";
import {getSalableEntityLink, salableEntityClasses} from "../../services/sale";

const columnData = [
    {id: 'created_at', numeric: false, disablePadding: false, label: 'Created date', dateFormat: 'DD.MM.YYYY HH:mm:ss',
        processValue: (value, _, row) => {
            if (row.type === salableEntityClasses.goodStorageSale) {
                return <Link to={getSalableEntityLink(salableEntityClasses.goodStorageSale, row.id)} target={'_blank'}>
                            {value}
                       </Link>
            }

            return value
        }
    },
    {id: 'parlour', numeric: false, disablePadding: false, label: 'Parlour'},
    {id: 'seller', numeric: false, disablePadding: false, label: 'Seller'},
    {
        id: 'goods',
        numeric: false,
        disablePadding: false,
        label: 'Product',
        processValue: (value, _, row) => {
            if (row.type === salableEntityClasses.goodStorageSale) {
                return value.map(g => `${g.name} - ${formatMoney(g.price)}`).join(', ')
            }

            if (row.type === salableEntityClasses.payDoc) {
                return <Link to={getSalableEntityLink(salableEntityClasses.payDoc, value.id)} target={'_blank'}>{value.num}</Link>
            }
        },

    },
    {
        id: 'pays',
        numeric: false,
        disablePadding: false,
        label: 'Payment type',
        processValue: (value, t) => {
            return value.map(p => `${t('pay.types.'+p.type)} - ${formatMoney(p.amount)}`).join(', ')
        },
    },
    {id: 'total', numeric: false, disablePadding: false, label: 'Sum',
        processValue:
                value => Array.isArray(value) ? value.map(m => formatMoney(m)).join(', ') : formatMoney(value)
    },
];

export default @withRouter
@withTranslation()
class HistoryBuyPage extends React.Component {

    state = {
        id: '',
        guest: {
            id: null,
            lastName: null,
            name: null,
            secondName: null,
            phone: null,
            payDocs: null,
        },
        refreshTable: false,
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getGuest(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: response.data
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/guests/view/${this.state.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getSales(this.state.id, null, ...params) : getFalseResult();
    };

    render() {
        const {t} = this.props;
        const {guest, refreshTable} = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Purchase history")}/>}>
                    <GuestLeftFields guest={guest}/>
                    <br/>
                    <br/>
                    <ActionButton onClick={this.handleClickBack}>{t("Back")}</ActionButton>
                </LeftBar>
                <Content title={t("Purchase history")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
