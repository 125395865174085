import React, { useCallback, useState } from "react";
import { useHistory, Route, Switch, useRouteMatch } from "react-router-dom";
import LeftBar from "../../../components/left-bar/LeftBar";
import NavigationLinks from "../../../components/navigation-links/NavigationLinks";
import { getExecutorsForTable, navLinks } from "../../../services/settings";
import Content from "../../../components/content/Content";
import DataTable from "../../../components/data-table/DataTable";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../components/button/ActionButton";

function ExecutorsPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const { path } = useRouteMatch();
    const [refreshTable] = useState(false);

    const columnData = [
        { id: "name", label: "Executors.Name" },
        { id: "comment", label: "Executors.Comment" },
    ];

    const handleCreateExecutor = useCallback(() => {
        history.push({
            pathname: `/settings/executor/view/create`,
        });
    }, [history]);

    const executors = useCallback((...params) => {
        return getExecutorsForTable(...params);
    }, []);

    return (
        <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={handleCreateExecutor}>{t("Executors.Create")}</ActionButton>
            </LeftBar>
            <Content title="EXECUTORS">
                <Switch>
                    <Route exact path={path}>
                        <DataTable
                            columnData={columnData}
                            dataFunc={executors}
                            selector={false}
                            refresh={refreshTable}
                            linkTemplate={(item) => `/settings/executor/view/${item.id}`}
                        />
                    </Route>
                </Switch>
            </Content>
        </React.Fragment>
    );
}

export default ExecutorsPage;