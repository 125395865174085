import React from "react";
import * as moment from "moment";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import ShowField from "../../components/fields/ShowField";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getHistoryChange, getVisit, navLinks} from "../../services/calendar";
import {getFalseResult} from "../../services/common";
import {getMastersName, getVisitTimeString} from "./helpers";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";

export default
@withRouter
@withTranslation()
class HistoryChangePage extends React.Component {
    state = {
        visit: {},
        refreshTable: false,
    }

    processFacilityValue = (value, t, row) => {
        if (row.field !== 'Услуги в визите' || !value) {
            return value;
        }

        let arr = value.split(' / ');
        arr[0] = moment(arr[0]).utcOffset(this.state.visit.parlour.timeZone).format('DD.MM.YYYY HH:mm');

        return arr.join(' / ');
    }

    columnData = [
        {id: 'dt', numeric: false, disablePadding: false, label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        {id: 'user', numeric: false, disablePadding: false, label: 'User'},
        {id: 'field', numeric: false, disablePadding: false, label: 'Changed parameter'},
        {
            id: 'oldValue',
            numeric: false,
            disablePadding: false,
            label: 'Value "was"',
            processValue: this.processFacilityValue
        },
        {
            id: 'newValue',
            numeric: false,
            disablePadding: false,
            label: 'Value "became"',
            processValue: this.processFacilityValue
        },
    ];

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);

        if (resultId.length) {

            getVisit(resultId[0])
                .then(response => {
                    if (response.success) {
                        let visit = response.data;
                        visit.visitFacility.forEach((item, id) => {
                            visit.visitFacility[id].start = moment(item.start);
                            visit.visitFacility[id].duration = item.duration / 60;
                        })

                        this.setState({
                            visit: visit,
                            refreshTable: !this.state.refreshTable
                        })
                    }
                });
        }
    }

    getTableData = (...params) => {
        return this.state.visit.id ? getHistoryChange(this.state.visit.id, ...params) : getFalseResult();
    }

    handleClickBack = () => {
        this.props.history.push(`/orders/view/${this.state.visit.id}`);
    };



    render() {
        const { t } = this.props;
        const { refreshTable, visit } = this.state;

        let masters = getMastersName(visit.visitFacility);
        let visitTime = getVisitTimeString(visit.visitFacility);

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Change history")}/>}>
                    <ShowField
                        label = {t("Visit time")}
                        value = { visitTime }
                    />
                    <ShowField
                        label = {t("Master")}
                        value = { masters }
                    />
                    <ShowField
                        label = {t("Guest")}
                        value = { visit.guest && visit.guest.fullName }
                    />
                    <ShowField
                        label = {t("Cost of visit")}
                        value = { visit.totalFormatted }
                    />
                    <ShowField
                        label = {t("Visit status")}
                        value = { visit.statusText }
                    />

                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>{t("Back")}</ActionButton>
                </LeftBar>
                <Content title={t("Change history")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        order="desc"
                        orderBy="dt"
                    />
                </Content>
            </React.Fragment>
        );
    }
}
