import React from "react";
import types from 'prop-types'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import AnyField from "../fields/AnyField";
import {withTranslation} from "react-i18next";
import CheckboxField from "../fields/CheckboxField";
import MoneyField from "../fields/MoneyField";
import confirmDialogActions from "./confirmDialog-acions";
import {connect} from "react-redux";


function EditDialog(props) {

    const {columns, values, open, closeDialog, onChange, onDelete, t, showConfirm} = props

    const handleConfirmDelete = () => {
        showConfirm({
            message: 'Уверены что хотите удалить',
            title: 'Удаление',
            onClose: onDelete

        });
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={closeDialog(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">{t('GuestClub.Subscription')}
                    {values.id &&
                    <Button onClick={handleConfirmDelete}
                            color="primary"
                            style={{float: 'right'}}>Удалить</Button>
                    }
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        {columns.map((column, idx) => {

                            let component = {};

                            switch (column?.type) {
                                case 'bool':
                                    component = <CheckboxField
                                        label={t(column.label)}
                                        value={values[column.id]}
                                        onChange={onChange(column.id)}
                                        required
                                        fullWidth
                                        error={!values[column.id]}
                                        autoFocus
                                    />;
                                    break;
                                case 'money':
                                    component = <MoneyField
                                        label={t(column.label)}
                                        value={values[column.id]}
                                        onChange={onChange(column.id)}
                                        required
                                        fullWidth
                                        error={!values[column.id]}
                                        autoFocus
                                        currencies={["RUB"]}
                                    />

                                    break;
                                default:
                                    component = <AnyField
                                        label={t(column.label)}
                                        value={values[column.id]}
                                        onChange={onChange(column.id)}
                                        required
                                        error={!values[column.id]}
                                        fullWidth
                                        autoFocus
                                    />;

                            }

                            return (<Grid item xs={12} key={idx}>
                                {component}
                            </Grid>)
                        })}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog(true)} color="primary">Сохранить</Button>
                    <Button onClick={closeDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

EditDialog.propTypes = {
    values: types.object,
    columns: types.array,
    open: types.bool,
    closeDialog: types.func,
    onChange: types.func,
    onDelete: types.func
};

EditDialog.defaultProps = {
    onChange: () => {
    }
}


export default withTranslation()(connect(state => ({}), {
    showConfirm: confirmDialogActions.show
})(EditDialog));