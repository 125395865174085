import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar"
import NavigationLinks from "../../components/navigation-links/NavigationLinks"
import {emptyPriority, getTaskPriorities, navLinks, saveTaskPriority} from "../../services/tasks"
import Content from "../../components/content/Content"
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {connect} from "react-redux";
import ColorField from "../../components/fields/ColorField";

export default
@connect(null,{
    showMessage: messageDialogActions.show
})
class PrioritiesPage extends React.Component {
    columnData = [
        {id: 'title', label: 'Наименование' },
        {id: 'color', label: 'Цвет', color: true}
    ];

    state = {
        priority: emptyPriority,
        dialogEditOpen: false,
        refreshTable: false,
    };

    inputs = [];

    handleAdd = () => {
        this.setState({
            priority: emptyPriority,
            dialogEditOpen: true,
        })
    }

    handleEdit = priority => {
        this.setState({
            priority,
            dialogEditOpen: true,
        })
    };

    handleChange = prop => event => {
        this.setState({
            priority: {
                ...this.state.priority,
                [prop]: event.target.value,
            }
        })
    }

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        saveTaskPriority(this.state.priority)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })

    }

    handleKeyPress = event => {
        if (event.key === "Enter") {
            const idx = this.inputs.indexOf(event.target);
            if (idx >= 0 && idx < this.inputs.length) {
                this.inputs[idx+1].focus();
            }
        }
    }

    handleDialogEnter = () => {
        this.inputs[0].focus();
    }

    render() {
        const { priority, dialogEditOpen, refreshTable } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
            </LeftBar>
            <Content title="Приоритеты задач">
                <DataTable
                    columnData={this.columnData}
                    dataFunc={getTaskPriorities}
                    selector={false}
                    refresh={refreshTable}
                    onClick={this.handleEdit}
                />
            </Content>
            <Dialog
                open={dialogEditOpen}
                onClose={this.handleCloseEditDialog(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                keepMounted
                onEnter={this.handleDialogEnter}
            >
                <DialogTitle id="dialog-title">Приоритет задачи</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Наименование"
                                value={priority.title}
                                onChange={this.handleChange('title')}
                                required
                                error={!priority.title}
                                fullWidth
                                autoFocus
                                inputRef={el => this.inputs.push(el)}
                                onKeyPress={this.handleKeyPress}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ColorField
                                label="Цвет"
                                value={priority.color}
                                onChange={this.handleChange('color')}
                                required
                                fullWidth
                                error={!priority.color}
                                inputRef={el => this.inputs.push(el)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseEditDialog(true)} disabled={!priority.title} color="primary">OK</Button>
                    <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    }
}
