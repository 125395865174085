import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {getContactTargets} from "../../services/guests";
import ActionButton from "../../components/button/ActionButton";

const columnData = [
    { id: 'target', label: 'Цель' },
];

export default
class ContactTargetsPage extends React.Component {

    state = {
        data: [],
    };

    componentDidMount() {
        getContactTargets()
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                    })
                }
            });
    }

    handleAdd = () => {
        this.props.history.push(`/settings/targets/view/0`);
    };

    render() {
        const { data } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    ---
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Темы взаимодействия">
                    <DataTableLocal
                        columnData={columnData}
                        data={data}
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
