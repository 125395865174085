import React from 'react';
import * as PropTypes from 'prop-types';
import {navLinks} from "../../services/settings";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import {getBusinessRole, saveBusinessRole, getRoles, deleteBusinessRole, hasRole} from "../../services/user";
import AnyField from "../../components/fields/AnyField";
import {getBusinessUnits} from "../../services/organization";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import SelectListField from "../../components/fields/SelectListField";
import HLine from "../../components/left-bar/HLine";
import {withStyles} from "@material-ui/core/styles";
import memoize from 'lodash/memoize'


const emptyRoleDetailed = {
    role: null,
    businessUnitIds: []
};

@withStyles(theme => ({
    roleTitle: {

    },
    roleId: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    }
}))
class AddRolesDialog extends React.Component {
    static propTypes = {
        roles: PropTypes.array,
        onClose: PropTypes.func,
    };

    processRoleId = (value, t) => {
        const { classes } = this.props;
        if (value) {
            return <div>
                <div className={classes.roleTitle}>{t('roles.' + value)}</div>
                <div className={classes.roleId}>{value}</div>
            </div>
        }

        return value;
    }

    rolesColumns = [{id: 'id', label: 'Роли', processValue:  this.processRoleId }]

    state = {
        addRolesIds: [],
    };

    handleClose = memoize(ok => () => {
        if (this.props.onClose) {
            this.props.onClose(ok, this.state.addRolesIds.map(sel => sel.id));
        }
    });

    handleChangeAddRoles = selected => {
        this.setState({
            addRolesIds: selected,
        });
    };

    render() {
        const { addRolesIds } = this.state;
        const { roles } = this.props;

        return <Dialog
            open={true}
            onClose={this.handleClose(false)}
            maxWidth="md"
        >
            <DialogTitle>Выберите роли</DialogTitle>
            <DialogContent style={{width: 700}}>
                <DataTableLocal
                    columnData={this.rolesColumns}
                    data={roles}
                    pagination={false}
                    selected={addRolesIds}
                    onSelect={this.handleChangeAddRoles}
                />
            </DialogContent>
            <DialogActions>
                <ActionButton fullWidth={false} onClick={this.handleClose(true)} disabled={!addRolesIds.length}>OK</ActionButton>
                <ActionButton fullWidth={false} onClick={this.handleClose(false)}>Отмена</ActionButton>
            </DialogActions>
        </Dialog>
    }
}


export default
@connect(state => ({
    currentUser: state.auth.user,
}), {
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
@withStyles(theme => ({
    roleTitle: {

    },
    roleId: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    }
}))
class BusinessRolesViewPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            businessRole: {
                id: null,
                name: '',
                roles: [],
                rolesDetailed: []
            },
            businessRoleTable: [],
            roles: [],
            businessUnits: [],
            showBusinessRoles: !!(props.currentUser && hasRole('ROLE_UI_TAB_SETTINGS_BUSINESSROLE')),
            addBusinessUnitDialogOpen: false,
            addBusinessUnitIds: [],
            setBusinessUnitDialogOpen: false,
            setBusinessUnitIds: [],
            setBusinessUnitRoleId: null,
            selectedRoles: [],
            addRolesDialogOpen: false,
        };
    }

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getBusinessRole(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            businessRole: response.data,
                            businessRoleTable: this.processRoles(response.data),
                        })
                    }
                });
        }

        getRoles()
            .then(response => {
                if (response.success) {
                    this.setState({
                        roles: response.data.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0 )
                    });
                }
            });

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data
                    }, () => {
                        this.setState(state => ({
                            businessRoleTable: this.processRoles(state.businessRole),
                        }));
                    })
                }
            });

    }

    processRoles = businessRole => {
        const { businessUnits } = this.state;
        return businessRole.roles.map(addedRoleId => {
            let detailed = 'Все';

            const roleDetailed = businessRole.rolesDetailed.find(d => d.role === addedRoleId);
            if (roleDetailed && roleDetailed.businessUnitIds && roleDetailed.businessUnitIds.length) {
                const filteredBu = businessUnits.filter(bu => roleDetailed.businessUnitIds.indexOf(bu.id) >= 0);
                if (filteredBu && filteredBu.length) {
                    detailed = filteredBu.map(bu => bu.name).join(', ');
                }
            }

            return {
                id: addedRoleId,
                detailed,
            };
        }).sort((a,b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0);
    }

    handleChange = event => {
        const { businessRole } = this.state;
        this.setState({ businessRole: {...businessRole, [event.target.name]: event.target.value } });
    };

    handleSave = () => {
        saveBusinessRole(this.state.businessRole)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/settings/business-roles/`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleSaveAs = () => {
        saveBusinessRole({...this.state.businessRole, id: null})
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/settings/business-roles/`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleAddBusinessUnit = () => {
        this.setState({
            addBusinessUnitDialogOpen: true,
            addBusinessUnitIds: [],
        });
    };

    handleChangeDialog = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleCloseAddBusinessUnitCancel = () => {
        this.setState({
            addBusinessUnitDialogOpen: false,
        });
    }

    handleCloseAddBusinessUnitSuccess = () => {
        const { businessRole, addBusinessUnitIds, selectedRoles } = this.state;

        selectedRoles.forEach(sel => {
            const idx = businessRole.rolesDetailed.findIndex(role => role.role === sel.id);
            if (idx >= 0) {
                addBusinessUnitIds.forEach(addBusinessUnitId => {
                    if (businessRole.rolesDetailed[idx].businessUnitIds.indexOf(addBusinessUnitId) < 0) {
                        businessRole.rolesDetailed[idx].businessUnitIds.push(addBusinessUnitId);
                    }
                });
            } else {
                businessRole.rolesDetailed.push({
                    role: sel.id,
                    businessUnitIds: [...addBusinessUnitIds],
                });
            }
        });

        this.setState({
            businessRole,
            businessRoleTable: this.processRoles(businessRole),
            addBusinessUnitDialogOpen: false,
        });
    };

    handleSetBusinessUnit = () => {
        this.setState({
            setBusinessUnitDialogOpen: true,
            setBusinessUnitIds: [],
            setBusinessUnitRoleId: null,
        });
    };

    handleCloseSetBusinessUnitCancel = () => {
        this.setState({
            setBusinessUnitDialogOpen: false,
        });
    }

    handleCloseSetBusinessUnitSuccess = () => {
        const { businessRole, setBusinessUnitIds, selectedRoles, setBusinessUnitRoleId } = this.state;
        const roleIds = setBusinessUnitRoleId ? [setBusinessUnitRoleId] : selectedRoles.map(sel => sel.id);

        roleIds.forEach(roleId => {
           const idx = businessRole.rolesDetailed.findIndex(role => role.role === roleId);
           if (idx >= 0) {
               businessRole.rolesDetailed[idx].businessUnitIds = [...setBusinessUnitIds];
           } else {
               businessRole.rolesDetailed.push({
                   role: roleId,
                   businessUnitIds: [...setBusinessUnitIds]
               })
           }
        });

        this.setState({
            businessRole,
            businessRoleTable: this.processRoles(businessRole),
            setBusinessUnitDialogOpen: false,
        });
    };

    handleDelete = () => {
        this.props.showConfirm({
            message: 'Вы уверены что хотите удалить бизнес роль?',
            title: 'Удаление бизнес роли',
            onClose: ok => {
                if (ok) {
                    deleteBusinessRole(this.state.businessRole.id)
                        .then(response => {
                            if (response.success) {
                                this.props.history.push(`/settings/business-roles/`);
                            } else {
                                this.props.showMessage(response.error ? response.error.message : response.message);
                            }
                        })
                }
            }
        });
    };

    handleChangeSelected = selected => {
      this.setState({
          selectedRoles: selected,
      });
    };

    handleDeleteSelectedRoles = () => {
        const { selectedRoles } = this.state;
        const businessRole = {
            ...this.state.businessRole,
            roles: this.state.businessRole.roles.filter(rId => selectedRoles.findIndex(sRole => sRole.id === rId) < 0),
        }

        this.setState({
            businessRole,
            businessRoleTable: this.processRoles(businessRole),
            selectedRoles: [],
        });
    };

    handleAddRoles = () => {
        this.setState({
            addRolesDialogOpen: true,
        });
    };

    handleCloseAddRole = (ok, addRolesIds) => {
        if (!ok) {
            this.setState({
                addRolesDialogOpen: false,
            });
            return;
        }

        const newRoles = addRolesIds.filter(addId => this.state.businessRole.roles.indexOf(addId) < 0);
        const businessRole = {
            ...this.state.businessRole,
            roles: [...this.state.businessRole.roles, ...newRoles],
        }

        this.setState({
            businessRole,
            businessRoleTable: this.processRoles(businessRole),
            addRolesDialogOpen: false,
        });
    };

    handleClickDetailed = (itemRow, itemIdx, event) => {
      event.stopPropagation();
      event.preventDefault();
      const { businessRole } = this.state;

      const detailed = businessRole.rolesDetailed.find(rol => rol.role === itemRow.id) || emptyRoleDetailed;

      this.setState({
          setBusinessUnitDialogOpen: true,
          setBusinessUnitRoleId: itemRow.id,
          setBusinessUnitIds: detailed.businessUnitIds,
      })
    };

    handleClearSetBusinessUnit = () => {
        this.setState({
            setBusinessUnitIds: [],
        });
    }

    processRoleId = (value, t) => {
        const { classes } = this.props;
        if (value) {
            return <div>
                <div className={classes.roleTitle}>{t('roles.' + value)}</div>
                <div className={classes.roleId}>{value}</div>
            </div>
        }

        return value;
    }

    rolesColumnsData = [
        { id: 'id', label: 'Роль', processValue:  this.processRoleId },
        { id: 'detailed', label: 'Объекты', onClick: this.handleClickDetailed },
    ]

    render() {
        const {
            businessRole,
            showBusinessRoles,
            addBusinessUnitDialogOpen,
            businessUnits,
            addBusinessUnitIds,
            setBusinessUnitDialogOpen,
            setBusinessUnitIds,
            selectedRoles,
            addRolesDialogOpen,
            roles,
            businessRoleTable,
        } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAddBusinessUnit} disabled={!selectedRoles || !selectedRoles.length}>Добавить салон к ролям</ActionButton>
                    <ActionButton onClick={this.handleSetBusinessUnit} disabled={!selectedRoles || !selectedRoles.length}>Установить салон к ролям</ActionButton>
                    <HLine/>
                    <ActionButton onClick={this.handleDeleteSelectedRoles} disabled={!selectedRoles || !selectedRoles.length}>Убрать выбранные роли</ActionButton>
                    <ActionButton onClick={this.handleAddRoles}>Добавить роли</ActionButton>
                    <HLine/>
                    <ActionButton onClick={this.handleSave}>Сохранить</ActionButton>
                    <ActionButton onClick={this.handleSaveAs} disabled={!businessRole.id}>Сохранить как</ActionButton>
                    <HLine/>
                    <ActionButton onClick={this.handleDelete}>Удалить бизнес роль</ActionButton>
                </LeftBar>
                <Content title="Бизнес роль">
                    <AnyField
                        id="businessRole"
                        label="Название"
                        name="name"
                        value={businessRole.name}
                        fullWidth
                        onChange={this.handleChange}
                    />
                    {showBusinessRoles &&
                        <DataTableLocal
                            columnData={this.rolesColumnsData}
                            data={businessRoleTable}
                            pagination={false}
                            disableSorting
                            selected={selectedRoles}
                            onSelect={this.handleChangeSelected}
                        />
                    }



                    <Dialog
                        open={addBusinessUnitDialogOpen}
                        onClose={this.handleCloseAddBusinessUnitCancel}
                    >
                        <DialogTitle>Выберите бизнес единицу</DialogTitle>
                        <DialogContent>
                            <SelectListField
                                items={businessUnits}
                                value={addBusinessUnitIds}
                                name="addBusinessUnitIds"
                                onChange={this.handleChangeDialog}
                            />
                        </DialogContent>
                        <DialogActions>
                            <ActionButton fullWidth={false} onClick={this.handleCloseAddBusinessUnitSuccess} disabled={!addBusinessUnitIds.length}>OK</ActionButton>
                            <ActionButton fullWidth={false} onClick={this.handleCloseAddBusinessUnitCancel}>Отмена</ActionButton>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={setBusinessUnitDialogOpen}
                        onClose={this.handleCloseSetBusinessUnitCancel}
                    >
                        <DialogTitle>Выберите бизнес единицы</DialogTitle>
                        <DialogContent>
                            <SelectListField
                                items={businessUnits}
                                value={setBusinessUnitIds}
                                name="setBusinessUnitIds"
                                onChange={this.handleChangeDialog}
                            />
                        </DialogContent>
                        <DialogActions>
                            <ActionButton fullWidth={false} onClick={this.handleClearSetBusinessUnit}>Очистить</ActionButton>
                            <ActionButton fullWidth={false} onClick={this.handleCloseSetBusinessUnitSuccess}>OK</ActionButton>
                            <ActionButton fullWidth={false} onClick={this.handleCloseSetBusinessUnitCancel}>Отмена</ActionButton>
                        </DialogActions>
                    </Dialog>

                    {addRolesDialogOpen &&
                        <AddRolesDialog
                            roles={roles}
                            onClose={this.handleCloseAddRole}
                        />
                    }

                </Content>
            </React.Fragment>
        );
    }
}
