import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import SearchField from "../../components/fields/SearchField";
import {getMedicalCardZonePrescriptions, saveMedicalCardZonePrescription} from "../../services/guests";
import ColorField from "../../components/fields/ColorField";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'nameRu', label: 'medicalCard.nameRu' },
    { id: 'nameEn', label: 'medicalCard.nameEn' },
    { id: 'color', label: 'medicalCard.color', color: true },
];

const emptyMedicalCardZonePrescription = {
    id: null,
    nameRu: null,
    nameEn: null,
    color: null,
};

export default
@withTranslation()
@connect(state => ({
}),{
    showMessage: messageDialogActions.show
})
class MedicalCardZonePrescriptionsListPage extends React.Component
{
    state = {
        medicalCardZonePrescription: emptyMedicalCardZonePrescription,
        dialogEditOpen: false,
        refreshTable: false,
        search: {
            search: null
        }
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getMedicalCardZonePrescriptions(search, ...params);
    };

    handleAdd = () => {
        this.setState({
            medicalCardZonePrescription: emptyMedicalCardZonePrescription,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        saveMedicalCardZonePrescription(this.state.medicalCardZonePrescription)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleEdit = medicalCardZonePrescription => {
        this.setState({
            medicalCardZonePrescription,
            dialogEditOpen: true,
        })
    };

    handleChange = (prop) => event => {
        this.setState({
            medicalCardZonePrescription: {
                ...this.state.medicalCardZonePrescription,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    render() {
        const { dialogEditOpen, medicalCardZonePrescription, refreshTable, search } = this.state;
        const { t } = this.props;
        const disabled = !medicalCardZonePrescription.nameRu || !medicalCardZonePrescription.nameEn || !medicalCardZonePrescription.color;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label={t("Search")}
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>{t("Add")}</ActionButton>
                </LeftBar>
                <Content title="Предписания (Мед. карты)">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog(false)}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Предписание</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Наименование RU"
                                    value={medicalCardZonePrescription.nameRu}
                                    onChange={this.handleChange('nameRu')}
                                    required
                                    error={!medicalCardZonePrescription.nameRu}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Наименование EN"
                                    value={medicalCardZonePrescription.nameEn}
                                    onChange={this.handleChange('nameEn')}
                                    required
                                    error={!medicalCardZonePrescription.nameEn}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ColorField
                                    label="Цвет"
                                    value={medicalCardZonePrescription.color}
                                    onChange={this.handleChange('color')}
                                    required
                                    error={!medicalCardZonePrescription.color}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} disabled={disabled} color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
}
