import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {getBusinessRoles} from "../../services/user";
import HLine from "../../components/left-bar/HLine";
import SearchField from "../../components/fields/SearchField";

const columnData = [
    { id: 'name', label: 'Наименование' },
];

class BusinessRolesListPage extends React.Component
{
    state = {
        refreshTable: false,
        search: {
            search: null,
        },
    };

    handleRefresh = () => {
        this.setState({
            refreshTable: !this.state.refreshTable,
        });
    }

    handleChangeSearch = event => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value,
            }
        })
    }

    getTableData = (...params) => {
        const { search } = this.state;
        return getBusinessRoles(search, ...params);
    }

    handleAdd = () => {
        this.props.history.push(`/settings/business-roles/view/0`);
    };

    render() {
        const { refreshTable, search } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        name="search"
                        value={search.search}
                        onChange={this.handleChangeSearch}
                        onClick={this.handleRefresh}
                    />
                    <ActionButton onClick={this.handleRefresh}>Поиск</ActionButton>
                    <HLine/>
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Бизнес роли">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        linkTemplate={(item) => `/settings/business-roles/view/${item.id}`}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>

        )
    }
}

export default BusinessRolesListPage;
