import React from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {getGuest, getWhatsAppMessages, navLinks} from "../../services/guests";
import ActionButton from "../../components/button/ActionButton";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";
import GuestLeftFields from "./GuestLeftFields";
import {withTranslation} from "react-i18next";
import {lighten} from "@material-ui/core";

const columnData = [
    { id: 'systemMessageDate', numeric: false, disablePadding: false, disableSorting: true, label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'userName', numeric: false, disablePadding: false, disableSorting: true, label: 'User name' },
    { id: 'messageType', numeric: false, disablePadding: false, disableSorting: true, label: 'Message type' },
    { id: 'message', numeric: false, disablePadding: false, disableSorting: true, label: 'Message' },
    { id: 'messageSendStatus', numeric: false, disablePadding: false, disableSorting: true, label: 'Message send status' },
    { id: 'messageSendErrorStatus', numeric: false, disablePadding: false, disableSorting: true, label: 'Message send error status' },
    { id: 'connectInitiator', numeric: false, disablePadding: false, disableSorting: true, label: 'Message connect initiator' },
    { id: 'messageQueueType', numeric: false, disablePadding: false, disableSorting: true, label: 'Message queue type' },
]

export default
@withRouter
@withTranslation()
class WhatsAppMessagesPage extends React.Component {

    state = {
        id: '',
        guest: {
            id: null,
            lastName: null,
            name: null,
            secondName: null,
            phone: null,
            payDocs: null,
        },
        refreshTable: false,
        visitStatuses: []
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getGuest(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: response.data
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/guests/view/${this.state.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getWhatsAppMessages(this.state.id, null, ...params) : getFalseResult();
    };

    rowStyle = item => {
        if(item.messageTypeValue === 'out') {
            return {backgroundColor: lighten('#ff9494', 0.4)}
        }
        return {backgroundColor: lighten('#fff9005c', 0.4)}
    };

    render() {
        const { t } = this.props;
        const { guest, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("History of visits")}/>}>
                    <GuestLeftFields guest={guest}/>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>{t("Back")}</ActionButton>
                </LeftBar>
                <Content title={t("WhatsApp messages")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        rowStyleFunc={this.rowStyle}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
