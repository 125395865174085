import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    error: {
        color: 'red'
    }
})

const BoolValue = ({ title, value, translateValuePrefix = '' }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={!value ? classes.error : null}>{t(title)}: {t(translateValuePrefix + value)}</div>
    );
};

BoolValue.propTypes = {
    title: PropTypes.string,
    value: PropTypes.bool,
    translateValuePrefix: PropTypes.string,
};

export default BoolValue;