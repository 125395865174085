import React from "react";
import {fromToDates} from "../../../../../services/common";

const parentDayWithBu = (value, t, row) => {
    return <div>
        <div>{fromToDates('DD.MM.YYYY')(value)}</div>
        <div>{row.businessUnitName}</div>
    </div>
};

export default parentDayWithBu;
