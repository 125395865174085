import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    navLinks,
    getGeneralSettings,
    getGeneralProperties,
    saveGeneralSettings
} from "../../services/settings";
import Content from "../../components/content/Content";
import {Grid} from "@material-ui/core";
import CheckboxField from "../../components/fields/CheckboxField";
import {withTranslation} from "react-i18next";
import AnyField from "../../components/fields/AnyField";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import IntegerField from "../../components/fields/IntegerField";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import UploadFilesField from "../../components/fields/UploadFilesField";
import {safeSafe} from "../../services/moneyFlow";

export const PROPERTIES_COMPONENTS = {
    passwordRecovery: CheckboxField,
    daysToBurnPayDocAfterExpired: IntegerField,
    daysToBurnPaymentAfterBlocked: IntegerField,
    autoSendCert: CheckboxField,
    messageActiveAddGuestPersonalDiscount: CheckboxField,
    messageActiveCloseVisit: CheckboxField,
    messageActiveCreateVisit: CheckboxField,
    messageActiveUpdateVisit: CheckboxField,
    messageActiveCancelVisit: CheckboxField,
    queueEnabled: CheckboxField,
    guestNameVerificationEnable: CheckboxField,
    bitrixLowRatingVisitNotificationEnabled: CheckboxField,
    bitrixCreateDealNewGuestNoRatingFirstVisitEnabled: CheckboxField


};

export const PROPERTIES_FILE_COMPONENTS = {

    documentsRulesOfUsingPayDoc:UploadFilesField,
    documentsPolicyOfConfidentiality:UploadFilesField,
    documentsContraindications:UploadFilesField,
    documentsRulesOfAndRefundPayment:UploadFilesField

};

export default
@withTranslation()
@connect(null, {
    showMessage: messageDialogActions.show,
})
@withStyles(() => ({
    info: {
        marginTop: 10,
        paddingLeft: 10,
    }
}))
class GeneralSettingsPage extends React.Component {
    state = {
        values: {},
        properties: {
            mainTab: [],
            templatesTab: [],
            bitrixTab: [],
            queueTab: [],
            crnTab: [],
            documentsTab: []
        },
        tabVal:0
    }

    componentDidMount() {
        getGeneralSettings()
            .then(response => {
                if (response.success) {
                    this.setState({
                        values: response.data
                    })
                }
            });
        getGeneralProperties()
            .then(response => {
                if (response.success) {
                    this.setState({
                        properties: {
                            mainTab: response.data.filter(prop =>
                                (
                                    !prop.id.startsWith('twig') &&
                                    !prop.id.startsWith('bitrix') &&
                                    !prop.id.startsWith('message') &&
                                    !prop.id.startsWith('queue') &&
                                    !prop.id.startsWith('crn') &&
                                    !prop.id.startsWith('documents')
                                )
                            ),
                            templatesTab: response.data.filter(prop => prop.id.startsWith('twig') || prop.id.startsWith('message')).sort().reverse(),
                            bitrixTab: response.data.filter(prop => prop.id.startsWith('bitrix')),
                            queueTab: response.data.filter(prop => prop.id.startsWith('queue')),
                            crnTab: response.data.filter(prop => prop.id.startsWith('crn')),
                            documentsTab: response.data.filter(prop => prop.id.startsWith('documents')),

                        }
                    })
                }
            });
    }

    handleChange = event => {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value,
            },
        });
    }

    handleChangeFile = event => {
        console.log(event.target.value)
        this.setState({
            values: {
                ...this.state.values,
                files: {
                    ...this.state.values.files,
                    [event.target.name]: event.target.value
                },
            },
        });
    }

    renderProperties(tabName) {
        const { properties, values } = this.state;
        const { classes } = this.props;

        const components = [];

        if (tabName === 'templatesTab')
            components.push(<Grid key={"info"} item xs={12}>
                <Typography align={'left'} color={'primary'} display={'block'} className={classes.info}>
                    В каждый из шаблонов может быть вставлен плейсхолдер {'{{guest_appeal}}'} для вставки приветствия гостя.<br/>
                    Также может быть вставлен плейсхолдер {'{{new_line}}'} для вставки перехода на новую строку.
                </Typography>
            </Grid>);

        if (tabName === 'crnTab')
            components.push(<Grid key={"info"} item xs={12}>
                <Typography align={'left'} color={'primary'} display={'block'} className={classes.info}>
                    Настройки для взаимодействия с сервисом CRN.
                </Typography>
            </Grid>);

        // checkboxes up above others fields
        if (tabName === 'bitrixTab')
            properties['bitrixTab'].sort((a, b) => {
                if (PROPERTIES_COMPONENTS[a.id] && !PROPERTIES_COMPONENTS[b.id])
                    return -1;
                else if (!PROPERTIES_COMPONENTS[a.id] && PROPERTIES_COMPONENTS[b.id])
                    return 1;
                else
                    return 0;
            })

        properties[tabName].forEach(property => {
            let PropertyComponent = AnyField;
            let value = values[property.id]
            let handleChange = this.handleChange;

            if (PROPERTIES_COMPONENTS[property.id]) {
                PropertyComponent = PROPERTIES_COMPONENTS[property.id];
            }

            if(tabName ==='documentsTab') {

                if (PROPERTIES_FILE_COMPONENTS[property.id]) {
                    PropertyComponent = PROPERTIES_FILE_COMPONENTS[property.id];
                    value = values.files ? values.files[property.id] : null;
                    handleChange = this.handleChangeFile
                }
            }

            let gridCols = 12;

            if (property.id.startsWith('message'))
                gridCols = 3;

            if (property.id.startsWith('twigTextAppeal'))
                gridCols = 4;

            components.push(<Grid key={property.id} item xs={gridCols}><PropertyComponent
                id={property.id}
                name={property.id}
                label={property.text}
                value={value}
                onChange={handleChange}
                readOnly={property.readOnly}
                helperText={property.readOnly ? 'Переопределено в .env' : null}
                fullWidth
            /></Grid>);
        });

        return components;
    }

    handleSave = () => {


        saveGeneralSettings(this.state.values)
            .then(response => {
                if (response.success) {
                    this.setState({
                        values: response.data,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    tabHandleChange = (event, value) => {
        this.setState({
            tabVal: parseInt(value)
        });
    }

    safeSafe = () => {
        safeSafe()
            .then(response=>{
                if(response.success) {
                    console.log(response)
                    this.props.showMessage(response.response);
                } else{
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    render() {
        const { tabVal } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={this.handleSave}>Сохранить</ActionButton>
            </LeftBar>
            <Content title="Общие настройки">
                <Tabs value={tabVal} onChange={this.tabHandleChange}>
                    <Tab label="Общие" id={0} />
                    <Tab label="Шаблоны сообщений" id={1} />
                    <Tab label="Bitrix24" id={2} />
                    <Tab label="Очередь сообщений" id={3} />
                    <Tab label="CRN" id={4} />
                    <Tab label="Документы" id={5} />
                </Tabs>
                <TabPanel index={0} value={tabVal}>
                    <Grid container spacing={2}>
                        <Grid style={{marginTop:'30px'}}>
                            <ActionButton onClick={this.safeSafe} >Выгрузка всех сейфов в ТГ</ActionButton>
                        </Grid>

                        {this.renderProperties('mainTab')}
                    </Grid>
                </TabPanel>
                <TabPanel index={1} value={tabVal}>
                    <Grid container spacing={2}>
                        {this.renderProperties('templatesTab')}
                    </Grid>
                </TabPanel>
                <TabPanel index={2} value={tabVal}>
                    <Grid container spacing={2}>
                        {this.renderProperties('bitrixTab')}
                    </Grid>
                </TabPanel>
                <TabPanel index={3} value={tabVal}>
                    <Grid container spacing={2}>
                        {this.renderProperties('queueTab')}
                    </Grid>
                </TabPanel>
                <TabPanel index={4} value={tabVal}>
                    <Grid container spacing={2}>
                        {this.renderProperties('crnTab')}
                    </Grid>
                </TabPanel>
                <TabPanel index={5} value={tabVal}>
                    <Grid container spacing={2}>
                        {this.renderProperties('documentsTab')}
                    </Grid>
                </TabPanel>
            </Content>
        </React.Fragment>
    }
}

class TabPanel extends React.Component {
    render() {
        const { children, value, index } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
            >
                {children}
            </div>
        );
    }
}