import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getSpendingTypes,
    getSpendingTypeCategories,
    getSpendingReport,
    navLinks,
    debtLevels
} from "../../services/moneyFlow";
import {
    getHumanEmployees
} from "../../services/organization";
import moment from "moment";
import {FormControl, FormControlLabel, Divider, Radio, RadioGroup} from "@material-ui/core";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import XLSX from "xlsx";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import DateMonthField from "../../components/fields/DateMonthField";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import CheckboxField from "../../components/fields/CheckboxField";
import {dataTableGroupValueStrategy, formatArrayMoney, formatMoney, fromToDates} from "../../services/common";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import {getBusinessUnitByRole} from "../../services/user";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import ShowField from "../../components/fields/ShowField";

const paymentTypeGroup = (currentVal, row) => {
    currentVal = currentVal || [];
    if (row['paymentType'] && currentVal.indexOf(row['paymentType']) < 0) {
        currentVal.push(row['paymentType']);
    }

    return currentVal;
}
const dealTypeGroup = (currentVal, row) => {
    currentVal = currentVal || [];
    if (row['dealType'] && currentVal.indexOf(row['dealType']) < 0) {
        currentVal.push(row['dealType']);
    }

    return currentVal;
}

export default
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withTranslation()
class SpendingReportPage extends React.Component {

    constructor(props) {
        super(props);

        const { t } = props;

        let businessUnits = [];
        let spendingTypes = [];
        let spendingTypeCategories = [];
        let paymentTypes = [];
        let lvl = [];

        if (props.currentParlour) {
            businessUnits.push(props.currentParlour.id);
        }

        const querySearch = getFilterFromQuery();

        let dateStart = moment().startOf('week');
        let dateEnd = moment();
        let dateType = 'period';
        let month = moment().add(-10, 'day').startOf('month');
        let debts = false;

        if (querySearch) {
            if (querySearch.dateStart) {
                dateStart = moment(querySearch.dateStart);
            }

            if (querySearch.dateEnd) {
                dateEnd = moment(querySearch.dateEnd);
            }

            if (querySearch.businessUnits) {
                businessUnits = querySearch.businessUnits;
            }

            if (querySearch.spendingTypes) {
                businessUnits = querySearch.spendingTypes;
            }

            if (querySearch.spendingTypeCategories) {
                businessUnits = querySearch.spendingTypeCategories;
            }

            if (querySearch.paymentTypes) {
                businessUnits = querySearch.paymentTypes;
            }

            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }

            if (querySearch.month) {
                month = moment(querySearch.month);
            }

            if (querySearch.debts) {
                debts = Boolean(querySearch.debts);
            }

            if (querySearch.lvl) {
                lvl = querySearch.lvl;
            }
        }

        this.state = {
            search: {
                dateStart: dateStart,
                dateEnd: dateEnd,
                month: month,
                dateType: dateType,
                businessUnits: businessUnits,
                spendingTypes: spendingTypes,
                spendingTypeCategories: spendingTypeCategories,
                paymentTypes: paymentTypes,
                showInactive: true,
                debts,
                lvl: lvl,
                source: {
                    businessUnitIds: [],
                    employeeIds: []
                }
            },
            refreshTable: false,
            businessUnits: [],
            spendingTypes: [],
            spendingTypeCategories: [],
            employees: []
        };


        this.columnDataXlsx = [
            { id: 'paymentType', label: t('Payment type'), processValue: this.processPaymentType},
            { id: 'applyAt', label: t('Date'), processValue: value => moment(value).format('DD.MM.YYYY HH:mm:ss')},
            { id: 'amount.val', label: t('sum')},
            { id: 'amount.cur', label: t('sum')},
            { id: 'businessUnit', label: t('Business unit')},
            { id: 'spendingTypeCategory', label: t('Spending type category')},
            { id: 'spendingType', label: t('Spending type') },
            { id: 'comment', label: t('Comment') },
            { id: 'counterpart', label: t('Counterpart')},
            { id: 'source', label: t('Source')},
            { id: 'dealType', label: t('Type'), processValue: this.processDealType},
            { id: 'cancellationFact', label: t('Cancellation fact'), processValue: value => moment(value).format('DD.MM.YYYY')},
        ];
    }

    processPaymentType = (value, t) => value ? t('pay.types.' + value) : null;
    processDealType = (value, t) => value ? t('dealType.' + value) : null;

    columnData = [
        {id: 'businessUnit', label: 'Business unit', groupStrategy: dataTableGroupValueStrategy.concat('businessUnit')},
        {
            id: 'paymentType',
            label: 'Payment type',
            processValue: this.processPaymentType,
            parentProcessValue: (value, t) => value && value.length ? value.map(item => this.processPaymentType(item, t)).join(', ') : null,
            groupStrategy: paymentTypeGroup
        },
        {
            id: 'applyAt',
            label: 'Date',
            dateFormat: 'DD.MM.YYYY HH:mm:ss',
            groupStrategy: dataTableGroupValueStrategy.fromTo('applyAt'),
            parentProcessValue: fromToDates('DD.MM.YYYY HH:mm:ss'),
        },
        {
            id: 'amount',
            label: 'Sum',
            processValue: formatMoney,
            parentProcessValue: formatArrayMoney,
            groupStrategy: dataTableGroupValueStrategy.moneySumWithCurrencyToArray('amount'),
        },
        {id: 'spendingTypeCategory', label: 'Spending type category', groupStrategy: dataTableGroupValueStrategy.concat('spendingTypeCategory')},
        {id: 'spendingType', label: 'Spending type', groupStrategy: dataTableGroupValueStrategy.concat('spendingType')},
        {id: 'lvl', label: 'Level', groupStrategy: dataTableGroupValueStrategy.concat('lvl')},
        {id: 'comment', label: 'Comment', groupStrategy: () => ''},
        {id: 'counterpart', label: 'Counterpart', groupStrategy: dataTableGroupValueStrategy.concat('counterpart')},
        {id: 'source', label: 'Source', groupStrategy: dataTableGroupValueStrategy.concat('source')},
        {
            id: 'dealType',
            label: 'Type',
            groupStrategy: dealTypeGroup,
            processValue: this.processDealType,
            parentProcessValue: (value, t) => value && value.length ? value.map(item => this.processDealType(item, t)).join(', ') : null,
        },
        {
            id: 'cancellationFact',
            label: 'Cancellation fact',
            dateFormat: 'DD.MM.YYYY',
            groupStrategy: dataTableGroupValueStrategy.fromTo('cancellationFact'),
            parentProcessValue: fromToDates('DD.MM.YYYY'),
        },
    ];


    componentDidMount() {
        getBusinessUnitByRole('ROLE_MONEYFLOW_DEAL_GET')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });

        getSpendingTypes({showInactive: this.state.search.showInactive})
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypes: response.data,
                    });
                }
            });

        getSpendingTypeCategories()
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypeCategories: response.data,
                    });
                }
            });

        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    });
                }
            });
    }

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        }, () => {
            if (prop === 'showInactive' || prop === 'spendingTypeCategories') {
                getSpendingTypes({
                    showInactive: this.state.search.showInactive,
                    categories: this.state.search.spendingTypeCategories
                })
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                spendingTypes: response.data,
                            });
                        }
                    });
            } else if (prop === 'dateType') {
                this.setState({
                    search: {
                        ...this.state.search,
                        debts: this.state.search.dateType === 'month' ? this.state.search.debts : false,
                    }
                });
            }
        });
    };

    handleChangeSource = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                source: {
                    ...this.state.search.source,
                    [prop]: event.target.value
                }
            }
        })
    }

    handleClickFilter = () => {

        pushFilterToQuery(processMomentFieldInObject(this.state.search, ['dateStart', 'dateEnd', 'month']));

        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getSpendingReport(search, ...params);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleExport = () => {
        const { t } = this.props;
        const { search } = this.state;
        const from = search.dateStart;
        const to = search.dateEnd;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => {
                                const value = _.get(row, item.id);
                                if (item.processValue) {
                                    return item.processValue(value, t);
                                }
                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Spending");
                    XLSX.writeFile(new_workbook, `Spending ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };


    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits, spendingTypes, spendingTypeCategories, employees } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.dateType}
                            onChange={this.handleChange('dateType')}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="period" label="за период" />
                            <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
                        </RadioGroup>
                    </FormControl>

                    {search.dateType === 'period' ?
                        <DatePeriodField2
                            valueFrom={search.dateStart}
                            valueTo={search.dateEnd}
                            maxDate={moment()}
                            onChangeFrom={this.handleChange('dateStart')}
                            onChangeTo={this.handleChange('dateEnd')}
                            checkValuesOrder
                        /> :
                        <DateMonthField
                            value={search.month}
                            onChange={this.handleChange('month')}
                            fullWidth
                        />
                    }
                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        label={t('Business unit')}
                        value={search.businessUnits}
                        onChange={this.handleChange('businessUnits')}
                        fullWidth
                    />
                    <AutocompleteMultiSelectField
                        options={spendingTypeCategories}
                        label={t('Spending type category')}
                        value={search.spendingTypeCategories}
                        onChange={this.handleChange('spendingTypeCategories')}
                        fullWidth
                    />
                    <Divider style={{marginTop: 20}}/>
                    <AutocompleteMultiSelectField
                        options={spendingTypes}
                        label={t('Spending type')}
                        value={search.spendingTypes}
                        onChange={this.handleChange('spendingTypes')}
                        fullWidth
                    />
                    <CheckboxField
                        label={t('Show not active spending type')}
                        value={search.showInactive}
                        onChange={this.handleChange('showInactive')}
                    />
                    <Divider style={{marginBottom: 20}}/>

                    <MultiSelectFromItemsField
                        items={[
                            {id: "cashless", name: t('pay.types.cashless')},
                            {id: "cash", name: t('pay.types.cash')},
                        ]}
                        emptySelectedLabel="---Не выбрано---"
                        label={t('Payment type')}
                        value={search.paymentTypes}
                        onChange={this.handleChange('paymentTypes')}
                        fullWidth
                    />

                    <MultiSelectFromItemsField
                        items={debtLevels}
                        emptySelectedLabel="---Не выбрано---"
                        label={t('Level')}
                        value={search.lvl}
                        onChange={this.handleChange('lvl')}
                        fullWidth
                    />

                    <CheckboxField
                        label={t('Show debts')}
                        value={search.debts}
                        disabled={search.dateType !== 'month'}
                        onChange={this.handleChange('debts')}
                    />

                    <Divider style={{marginTop: 20, marginBottom: 20}}/>
                    <ShowField
                        label={t("Source")}
                    />
                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        label={t('Business unit')}
                        value={search.source.businessUnitIds}
                        onChange={this.handleChangeSource('businessUnitIds')}
                        fullWidth
                    />
                    <AutocompleteMultiSelectField
                        options={employees}
                        label={t('Employee')}
                        value={search.source.employeeIds}
                        onChange={this.handleChangeSource('employeeIds')}
                        fullWidth
                    />

                    <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t("Spending report")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        groupBy="businessUnit"
                        summary
                        summaryTop
                        pagination={false}
                        disableSorting
                        autoload={false}
                />
                </Content>
            </React.Fragment>
        );
    }
}
