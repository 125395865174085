import React from "react";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    MenuItem,
    withStyles
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {connect} from "react-redux";
import infoActions from "../info/info-actions";
import messageDialogActions from "./messageDialog-acions";
import confirmDialogActions from "./confirmDialog-acions";
import * as PropTypes from "prop-types";
import {
    emptyDelivery,
    getCouriers,
    getDeliveryTypes,
    getTransportCompanies
} from "../../services/common";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField"
import {getPaymentTypes} from "../../services/sale";
import {withRouter} from "react-router";
import MoneyField from "../fields/MoneyField";

export default
@withRouter
@connect(state => ({
    token: state.auth.token,
}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
@withStyles(theme => ({
    grid: {
        alignSelf: 'center'
    },
    dialogContent: {
        minWidth: 600,
    },
    tableTitle: {
        fontWeight: 'bold',
    },
    error: {
        color: theme.palette.error.main,
    },
    placeholder: {
        color: theme.palette.text.secondary,
    },

}))
class DeliveryDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        delivery: PropTypes.object,
        showInverseCheckbox: PropTypes.bool,
        businessUnit: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            delivery: props.delivery,
            providers: [],
            couriers: [],
            types: [],
            paymentTypes: [],
            showInverseCheckbox: props.showInverseCheckbox || false,
            currentLimit: null,
            currentLimitValue: null,
        };


    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.delivery !== this.props.delivery) {
            this.setState({
                delivery: nextProps.delivery !== null ? nextProps.delivery : emptyDelivery,
            })
        }

        //if (nextProps.businessUnitId !== this.props.businessUnitId) {
            /*deliveryLimit(nextProps.businessUnitId).then(response => {
                let currentLimit = '';
                let currentLimitValue = null;
                if (response.success) {
                    currentLimit = response.data.currentAmountFormatted;
                    currentLimitValue = response.data.currentAmount.val;
                } else {
                    currentLimit = response.error ? response.error.message : response.message;
                }
                this.setState({
                    currentLimit: currentLimit,
                    currentLimitValue: currentLimitValue
                });
            });*/
        //}
    }

    componentDidMount() {
        getDeliveryTypes().then(response => {
            if (response.success) {
                this.setState({
                    types: response.data.filter(o => o.id !== 'pickup')
                });
            }
        });

        getPaymentTypes().then(response => {
            if (response.success) {
                this.setState({
                    paymentTypes: response.data.filter(o => o.id !== 'paydoc')
                });
            }
        });

        getTransportCompanies().then(response => {
            if (response.success) {
                this.setState({
                    providers: response.data,
                });
            }
        });

        getCouriers().then(response => {
            if (response.success) {
                this.setState({
                    couriers: response.data,
                });
            }
        });

    };

    handleChange = prop => event => {
        this.setState({
            delivery: {
                ...this.state.delivery,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeCb = prop => event => {
        this.setState({
            delivery: {
                ...this.state.delivery,
                [prop]: event.target.checked,
            }
        });

    };

    handleChangeCharges = prop => event => {
        this.setState({
            delivery: {
                ...this.state.delivery,
                [prop]: event.target.value
            }
        });
    };

    handleClose = ok => () => {
        if (this.props.onClose) {
            this.props.onClose(ok, this.state.delivery);
        }
    };

    render() {

        const { classes, open, businessUnit } = this.props;
        const { id, type, providerId, paymentType, waybillNumber, receiptScan, charges, comment, courierId, inversePayer } = this.state.delivery;
        const { types, providers, paymentTypes, couriers, showInverseCheckbox, currentLimitValue } = this.state;
        const isRequest = currentLimitValue !== null && charges.val > currentLimitValue;
        const disabled = !type || !charges ||
            (type === 'transport_company' && (!providerId || !paymentType)) ||
            (type === 'courier' && !courierId) ||
            (isRequest && !comment);

        const currencies = businessUnit ? [businessUnit.mainCurrency] : [];

        return (
            <Dialog
                open={open}
                onClose={this.handleClose(false)}
                aria-labelledby="delivery-dialog-title"
            >
                <DialogTitle id="delivery-dialog-title">Передача в доставку</DialogTitle>
                <DialogContent className={classes.dialogContent} style={{width: 400}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                id="type"
                                value={type}
                                onChange={this.handleChange('type')}
                                select
                                readOnly={!!id}
                                label="Способ доставки"
                                required
                                fullWidth>
                                {types.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={12}>
                            <MoneyField
                                currencies={ currencies }
                                label="Стоимость"
                                value={charges}
                                readOnly={!!id}
                                onChange={this.handleChangeCharges('charges')}
                                required
                                fullWidth
                            />
                        </Grid>

                        {type === 'transport_company' &&
                        <React.Fragment>
                            {/*
                            <Grid item xs={12}>
                                <p>Текущий лимит на доставку: {currentLimit}</p>
                            </Grid>
                            */}
                            <Grid item xs={12}>
                                <AnyField
                                    id="type"
                                    readOnly={!!id}
                                    value={paymentType}
                                    onChange={this.handleChange('paymentType')}
                                    select
                                    label="Тип оплаты"
                                    required
                                    fullWidth>
                                    {paymentTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                                </AnyField>
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteSelectField
                                    label="Транспортная компания/курьер"
                                    value={providerId}
                                    readOnly={!!id}
                                    required
                                    onChange={this.handleChange('providerId')}
                                    fullWidth={true}
                                    options={providers.map(item => ({value: item.id, label: `${item.name}`}))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    id="waybill"
                                    label="Номер накладной"
                                    value={waybillNumber}
                                    onChange={this.handleChange('waybillNumber')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UploadFilesField
                                    label="Скан квитанции"
                                    id="receiptScan"
                                    value={receiptScan}
                                    onChange={this.handleChange('receiptScan')}
                                />
                            </Grid>
                        </React.Fragment>
                        }

                        {type === 'courier' &&
                        <Grid item xs={12}>
                            <AutocompleteSelectField
                                label="Курьер"
                                value={courierId}
                                readOnly={!!id}
                                required
                                onChange={this.handleChange('courierId')}
                                fullWidth={true}
                                options={couriers.map(item => ({value: item.id, label: `${item.name}`}))}
                            />
                        </Grid>
                        }

                        {showInverseCheckbox &&
                        <Grid item xs={12}>
                            <FormControlLabel className={classes.textField}
                                              control={
                                                  <Checkbox
                                                      checked={inversePayer}
                                                      onChange={this.handleChangeCb('inversePayer')}
                                                      value="inversePayer"
                                                  />
                                              }
                                              label="За доставку платит отправляющая сторона"
                                              disabled={!!id}
                            />
                        </Grid>
                        }

                        <Grid item xs={12}>
                            <AnyField
                                id="comment"
                                label="Комментарий"
                                value={comment}
                                //error={isRequest && !comment}
                                //required={isRequest && !comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose(true)} disabled={disabled}
                            color="primary">OK</Button>
                    <Button onClick={this.handleClose(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
