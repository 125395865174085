import { combineReducers } from 'redux';
import auth from './pages/login/auth-reducer';
import user from './pages/user/user-reducer';
import info from './components/info/info-reducer';
import message from './components/dialogs/messageDialog-reducer';
import confirm from './components/dialogs/confirmDialog-reducer';
import goods from './pages/goods/goods-reducer';
import calendar from './pages/calendar/calendar-reducer';
import calendarEmployee from './pages/calendarEmployee/calendarEmployee-reducer';
import socket from './socket/socket-reducer';
import backgroundProcess from './components/background-progress/background-process-reducer';
import common from './common-reducer';

export default combineReducers({
    auth,
    user,
    info,
    message,
    confirm,
    goods,
    calendar,
    calendarEmployee,
    socket,
    backgroundProcess,
    common,
});
