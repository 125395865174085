import {dataTableGroupValueStrategy} from "../../../../../services/common";

const revenue = (currentVal, row) => {
    return {
        cash: dataTableGroupValueStrategy.arrayMoneySum('cash')(
            currentVal ? currentVal.cash : null,
            row.revenue
        ),
        cashDetails: dataTableGroupValueStrategy.appendArray('cashDetails')(
            currentVal?.cashDetails,
            row.revenue
        ),
        cashless: dataTableGroupValueStrategy.arrayMoneySum('cashless')(
            currentVal ? currentVal.cashless : null,
            row.revenue
        ),
        cashlessDetails: dataTableGroupValueStrategy.appendArray('cashlessDetails')(
            currentVal?.cashlessDetails,
            row.revenue
        ),
        checkingAccount: dataTableGroupValueStrategy.arrayMoneySum('checkingAccount')(
            currentVal ? currentVal.checkingAccount : null,
            row.revenue
        ),
        checkingAccountDetails: dataTableGroupValueStrategy.appendArray('checkingAccountDetails')(
            currentVal?.checkingAccountDetails,
            row.revenue
        ),
        total: dataTableGroupValueStrategy.arrayMoneySum('total')(
            currentVal ? currentVal.total : null,
            row.revenue
        ),
        compareCashless: dataTableGroupValueStrategy.arrayMoneySum('compareCashless')(
            currentVal ? currentVal.compareCashless : null,
            row.revenue
        ),
        compareCash: dataTableGroupValueStrategy.arrayMoneySum('compareCash')(
            currentVal ? currentVal.compareCash : null,
            row.revenue
        ),
    };
};

export default revenue;