import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    declineRequest, emptyGood, emptyRequest,
    getCategories,
    getGoodPurposes,
    getGoods,
    getGoodStorageRequest,
    navLinks, processRequestAfterGet, requestStatuses,
    saveRequest, sendRequest
} from "../../services/goods";
import Content from "../../components/content/Content";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import ShowField from "../../components/fields/ShowField";
import ActionButton from "../../components/button/ActionButton";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import {formatMoney} from "../../services/common";
import NumberFormatCount from "../../components/fields/NumberFormatCount";
import ShowWithEditField from "../../components/fields/ShowWithEditField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {getParlours} from "../../services/organization";
import _ from 'lodash';
import {withRouter} from "react-router";
import MoneyField from "../../components/fields/MoneyField";

const columnData = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'purposeText', label: 'Предназначение' },
    { id: 'category.name', label: 'Группа товаров' },
    { id: 'priceFormat', number: true, label: 'Цена покупки' },
    { id: 'quantityStorage', label: 'Остаток на складе' },
    { id: 'quantity', label: 'Количество товаров в запросе' },
    { id: 'quantityDelivery', label: 'Количество товаров в поставке' },
    { id: 'quantityRecommend', label: 'Рекомендованное количество' },
    { id: 'statusText', label: 'Статус товара в запросе' },
];

export default
@connect(state => ({
    user: state.auth.user,
    currentParlour: state.common.currentParlour,
}), {
    showInfo: infoActions.show, showMessage: messageDialogActions.show, showConfirm: confirmDialogActions.show
})
@withStyles(theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(2),
    }
}))
@withRouter
class RequestViewPage extends React.Component
{
    state = {
        request: emptyRequest,
        editingGood: emptyGood,
        editingIndex: null,
        dialogEditOpen: false,
        goods: [],
        categories: [],
        purposes: [],
        parlours: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            request: {
                ...emptyRequest,
                parlour: props.currentParlour ? props.currentParlour.id : emptyRequest.parlour,
            },
            editingGood: emptyGood,
            editingIndex: null,
            dialogEditOpen: false,
            goods: [],
            categories: [],
            purposes: [],
            parlours: [],
        };
    }

    componentDidMount() {
        const reId = new RegExp('/goods/requests/view/([^/]+)$');
        const resultId = reId.exec(this.props.location.pathname);
        if (resultId.length !== 2) {
            //Error request
            return;
        }

        if (resultId[1] !== "0") {
            getGoodStorageRequest(resultId[1])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            request: processRequestAfterGet(response.data),
                        })
                    }
                });
        }

        getCategories()
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.data,
                    })
                }
            });

        getGoods()
            .then(response => {
                if (response.success) {
                    this.setState({
                        goods: response.data,
                    })
                }
            });

        getGoodPurposes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        purposes: response.data,
                    })
                }
            });

        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/requests`);
    };

    handleClickEditGood = (groupedGood, itemIdx) => {
        const { request } = this.state;
        if (!(request.status === requestStatuses.new || request.status === requestStatuses.main_pending)) {
            return;
        }

        this.setState({
            editingGood: groupedGood,
            editingIndex: itemIdx,
            dialogEditOpen: true,

        })
    };

    handleClickAddGood = () => {
        this.setState({
            editingGood: emptyGood,
            editingIndex: this.state.request.goodStorageGrouped.length,
            dialogEditOpen: true,
        })
    };

    handleChangeRequest = prop => event => {
        this.setState({
            request: {
                ...this.state.request,
                [prop]: event.target.value,
            }
        })
    };

    handleChange = (prop, field) => event => {
        const value = event.target.value;

        if (field) {
            if (prop === 'good' && field === 'id') {
                const foundGood = this.state.goods.find(good => good.id === value);
                this.setState({
                    editingGood: {
                        ...this.state.editingGood,
                        good: {
                            ...foundGood,
                        },
                        category: {
                            ...foundGood.category
                        }
                    },
                });

            } else {
                this.setState({
                    editingGood: {
                        ...this.state.editingGood,
                        [prop]: {
                            ...this.state.editingGood[prop],
                            [field]: value,
                        },
                    }
                });
            }
        } else {

            if (prop === 'purpose') {
                this.setState({
                    editingGood: {
                        ...this.state.editingGood,
                        purpose: value,
                        purposeText: _.find(this.state.purposes, {'id': value}).text
                    }
                });
                return;
            }

            this.setState({
                editingGood: {
                    ...this.state.editingGood,
                    [prop]: value,
                }
            });
        }
    };

    handleCloseEditDialog = ok => () => {
        const { editingIndex, editingGood, request, purposes } = this.state;

        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        const purpose = purposes.find(item => item.id === editingGood.purpose);

        let goodStorageGrouped = [...request.goodStorageGrouped];

        if (editingIndex === request.goodStorageGrouped.length) {
            goodStorageGrouped.push({
                ...editingGood,
                priceFormat: formatMoney(editingGood.price),
                purposeText: purpose ? purpose.text : editingGood.purposeText,
            });
        } else {
            goodStorageGrouped[editingIndex] = {
                ...editingGood,
                priceFormat:formatMoney(editingGood.price),
                purposeText: purpose ? purpose.text : editingGood.purposeText,
            };
        }

        this.setState({
            request: {
                ...request,
                goodStorageGrouped,
                price: {
                    val: goodStorageGrouped.map(item => item.price && item.price.val ? parseFloat(item.price.val)*item.quantity : 0).reduce((a, b) => a + b, 0),
                    cur: request.price.cur,
                }
            },
            dialogEditOpen: false,
        });
    };

    handleSaveClick = () => {
        return saveRequest({
            ...this.state.request,
            price: null,
            goodStorageGrouped: this.state.request.goodStorageGrouped.map(item => ({ ...item, good: item.good.id })),
        })
            .then(response => {
                if (response.success) {
                    const request = processRequestAfterGet(response.data);

                    if (!this.state.request.id) {
                        this.props.history.push(`/goods/requests/view/${request.id}`);
                    }

                    this.setState({
                        request
                    });

                    this.props.showInfo('Изменения сохранены');
                    return Promise.resolve(request);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                    return Promise.reject(response.error ? response.error.message : response.message)
                }
            });
    };

    handleSendClick = () => {
        this.handleSaveClick()
            .then(request => {
                sendRequest(request)
                    .then(response => {
                        if (response.success) {
                            this.props.history.push(`/goods/requests`);
                            this.props.showInfo('Сформирован запрос на товар');
                        } else {
                            this.props.showMessage(response.error ? response.error.message : response.message);
                        }
                    });
            });
    };

    handleDeclineConfirm = ok => {
        if (ok) {
            declineRequest(this.state.request)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/goods/requests`);
                        this.props.showInfo('Запрос отклонен');
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleDeclineClick = () => {
        this.props.showConfirm({
            message: 'Вы уверены что хотите отклонить запрос?',
            title: 'Отклонение запроса',
            onClose: this.handleDeclineConfirm
        });
    };

    handleHistoryClick = () => {
        this.props.history.push(`/goods/requests/history-status/${this.state.request.id}`);

    };

    handleDelete = (grouped, idx) => {
        this.props.showConfirm({
            message: 'Вы уверены, что хотите убрать товар из запроса?',
            title: 'Удаление товара из запроса',
            onClose: this.handleCloseDelete(idx),
        })
    };

    handleCloseDelete = idx => ok => {
        const { request } = this.state;

        if (ok) {
            let goodStorageGrouped = [...request.goodStorageGrouped];
            _.remove(goodStorageGrouped, (value, index) => index === idx);

            this.setState({
                request: {
                    ...request,
                    goodStorageGrouped,
                    price: {
                        val: goodStorageGrouped.map(item => item.price && item.price.val ? parseFloat(item.price.val)*item.quantity : 0).reduce((a, b) => a + b, 0),
                        cur: request.price.cur,
                    }
                },
            });
        }
    };

    tableRowStyle = good => {
        if (good.comment) {
            return {
                background: '#ffea85',
            };
        }

        return null;
    };

    render() {
        const { request } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    {this.renderLeftFields()}
                    <br/><br/>
                    <ActionButton
                        visible={request.status === requestStatuses.new}
                        onClick={this.handleSendClick}
                        disabled={!request.goodStorageGrouped.length || !request.parlour}
                    >Заказать</ActionButton>
                    <ActionButton
                        visible={request.status === requestStatuses.new || request.status === requestStatuses.main_pending}
                        onClick={this.handleSaveClick}
                        disabled={!request.goodStorageGrouped.length || !request.parlour}
                    >Сохранить</ActionButton>
                    <ActionButton visible={request.status === requestStatuses.main_pending} onClick={this.handleDeclineClick}>Отказать</ActionButton>
                    <ActionButton visible={request.status !== requestStatuses.new} onClick={this.handleHistoryClick}>История изменения статусов запроса</ActionButton>
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>
                <Content title="Запрос ">
                    <DataTableLocal
                        columnData={columnData}
                        data={request.goodStorageGrouped}
                        selector={false}
                        onClick={this.handleClickEditGood}
                        pagination={false}
                        onDelete={(request.status === requestStatuses.new || request.status === requestStatuses.main_pending) ? this.handleDelete : null}
                        rowStyleFunc={this.tableRowStyle}
                    />
                    <ActionButton onClick={this.handleClickAddGood} visible={request.status === requestStatuses.new || request.status === requestStatuses.main_pending} disabled={!request.parlour} fullWidth={false}>Добавить товар в запрос</ActionButton>
                </Content>
                {this.renderEditDialog()}
            </React.Fragment>
        )
    }

    renderLeftFields() {
        const { request, parlours } = this.state;

        return (
            <React.Fragment>
                <ShowField
                    label="Номер запроса"
                    value={request.num}
                />
                <ShowField
                    label="Дата создания запроса"
                    value={request.createdAt}
                    dateFormat="DD.MM.YYYY"
                />
                <ShowField
                    label="Пользователь, создавший запрос"
                    value={request.creator.username}
                />
                <ShowField
                    label="Тел. пользователя, создавшего запрос"
                    value={request.creator.phone}
                    label2Lines
                />
                <ShowField
                    label="Примерная стоимость запроса"
                    value={formatMoney(request.price)}
                />
                <AnyField
                    label="Салон"
                    value={request.parlour}
                    required
                    error={!request.parlour}
                    onChange={this.handleChangeRequest('parlour')}
                    select
                    fullWidth
                    readOnly={request.status !== requestStatuses.new}
                > {parlours.map(parlour => <MenuItem key={parlour.id} value={parlour.id}>{parlour.name}</MenuItem>)}
                </AnyField>
                <ShowWithEditField
                    label="Комментарий"
                    value={request.comment}
                    multiline
                    rows={4}
                    onChange={this.handleChangeRequest('comment')}
                />
                <ShowField
                    label="Статус"
                    value={request.statusText}
                />
            </React.Fragment>
        );
    }

    renderEditDialog() {
        const { classes } = this.props;
        const { dialogEditOpen, editingGood, categories, goods, purposes, request, parlours } = this.state;
        let currencies = [];
        if (request.parlour && parlours.length) {
            currencies = [parlours.find((parlour) => parlour.id === request.parlour).mainCurrency];
        }


        const filteredGoods =
            editingGood.category.id ?
                goods.filter(good => good.category.id === editingGood.category.id)
                :
                goods;

        return (
            <Dialog
                open={dialogEditOpen}
                onClose={this.handleCloseEditDialog(false)}
            >
                <DialogTitle>Добавление товара</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Группа товаров"
                                value={editingGood.category.id}
                                onChange={this.handleChange('category','id')}
                                select
                                fullWidth
                                required
                            >
                                {categories.map(category => <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Наименование товара"
                                value={editingGood.good.id}
                                onChange={this.handleChange('good', 'id')}
                                select
                                fullWidth
                                required
                            >
                                {filteredGoods.map(good => <MenuItem key={good.id} value={good.id}>{good.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Описание товара"
                                value={editingGood.good.description}
                                readOnly
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Количество товара"
                                value={editingGood.good.massFormat}
                                readOnly
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MoneyField
                                label="Цена"
                                currencies={currencies}
                                value={ editingGood.price }
                                onChange={this.handleChange('price')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Предназначение"
                                value={editingGood.purpose}
                                required
                                fullWidth
                                onChange={this.handleChange('purpose')}
                                select
                            >
                                {purposes.map(purpose => <MenuItem key={purpose.id} value={purpose.id}>{purpose.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Количество"
                                value={editingGood.quantity}
                                fullWidth
                                onChange={this.handleChange('quantity')}
                                InputProps={{
                                    inputComponent: NumberFormatCount
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Комментарий"
                                value={editingGood.comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseEditDialog(true)} disabled={!editingGood.category.id || !editingGood.good.id || !parseInt(editingGood.quantity, 10) || !editingGood.price.val || !editingGood.purpose }  color="primary">OK</Button>
                    <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

