import React, {useMemo} from "react"
import PropTypes from "prop-types"
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    label: {
        ...theme.typography.caption,
    }
}))

const RadioGroupField = ({
    items,
    value,
    onChange,
    name,
    fullWidth
}) => {
    const classes = useStyles()

    const labelClasses = useMemo(() => ({
        label: classes.label
    }), [classes.label])

    return (
        <FormControl component="fieldset" fullWidth={fullWidth}>
            <RadioGroup
                value={value}
                onChange={onChange}
                row
                name={name}
            >
                {items?.map((it) => (
                    <FormControlLabel
                        key={it.id}
                        control={<Radio />}
                        value={it.id}
                        label={it.label}
                        classes={labelClasses}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}

const TValue = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

RadioGroupField.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: TValue.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
    value: TValue,
    onChange: PropTypes.func,
    name: PropTypes.string,
    fullWidth: PropTypes.bool,
}

RadioGroupField.defaultProps = {
    onChange () {},
    name: '',
    fullWidth: false,
}

export default RadioGroupField
