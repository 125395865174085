import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import * as classNames from 'classnames';
import LoginPage from "./pages/login/LoginPage";
import { connect } from 'react-redux';
import {Redirect, Route, Switch, withRouter } from 'react-router';
import {
    AppBar,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    Toolbar,
    Divider,
} from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CreditCard from "@material-ui/icons/CreditCard";
import Home from "@material-ui/icons/Home";
import People from "@material-ui/icons/People";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import Settings from "@material-ui/icons/Settings";
import StoreMallDirectory from "@material-ui/icons/StoreMallDirectory";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import ContactPhone from "@material-ui/icons/ContactPhone";
import AuthenticatedRoute from "./components/router/AuthenticatedRoute";
import CalendarPage from "./pages/calendar/CalendarPage";
import PayDocsPage from "./pages/pay-docs/PayDocsPage";
import PersonalAccount from "./pages/personalAccount/PersonalAccountPage";
import GuestsPage from "./pages/guests/GuestsPage";
import OrganizationPage from "./pages/organization/OrganizationPage";
import CalendarEmployeePage from "./pages/calendarEmployee/CalendarEmployeePage";
import SettingsPage from "./pages/settings/SettingsPage";
import FacilitiesPage from "./pages/facilities/FacilitiesPage";
import authActions from './pages/login/auth-actions';
import Info from "./components/info/Info";
import GoodsPage from "./pages/goods/GoodsPage";
import MessageDialog from "./components/dialogs/MessageDialog";
import ConfirmDialog from "./components/dialogs/ConfirmDialog";
import {hasRole} from "./services/user";
import socket from "./socket/socket";
import {green} from "@material-ui/core/colors";
import {DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import MoneyFlowPage from "./pages/money-flow/MoneyFlowPage";
import TasksPage from "./pages/tasks/TasksPage"
import TasksIcon from "@material-ui/icons/ListAlt";
import BackgroundProgress from "./components/background-progress/BackgroundProgress";
import {withTranslation} from "react-i18next";
import {getCourses} from "./services/moneyFlow";
import moment from "moment";
import {formatMoney, topBarSettings} from "./services/common";
import {Link} from "react-router-dom";
import {getParlour} from "./services/organization";
import commonActions from "./common-actions";
import CallCenterPage from "./pages/callCenter/CallCenterPage";
import ToolsPage from "./pages/tools/ToolsPage";
import RecoveryPage from './pages/login/RecoveryPage';
import Download from "./Download";
import _ from "lodash";
import LanguageSwitcher from "./components/button/LanguageSwitcher";
import cn from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
import messageDialogActions from "./components/dialogs/messageDialog-acions";
import {kkmService} from "./services/kkm";
import StuffSchedulePage from "./pages/staffSchedule/StuffSchedulePage";

const showLogo = process.env.REACT_APP_VARIANT !== 'ERP';

const routes = [
    { path: '/orders',         label: 'Schedule',            icon: PermContactCalendar,  role: 'ROLE_UI_TAB_ORDERS',       component: CalendarPage, },
    { path: '/pay-docs',       label: 'Payment documents',   icon: CreditCard,           role: 'ROLE_UI_TAB_PAYDOCS',      component: PayDocsPage, },
    { path: '/guests',         label: 'Guests',              icon: People,               role: 'ROLE_UI_TAB_GUESTS',       component: GuestsPage, },
    { path: '/call-center',    label: 'Call center',         icon: ContactPhone,         role: 'ROLE_UI_TAB_CALLCENTER',   component: CallCenterPage, },
    { path: '/facilities',     label: 'Facilities',          icon: ShoppingBasket,       role: 'ROLE_UI_TAB_FACILITIES',   component: FacilitiesPage, },
    { path: '/goods',          label: 'Warehouse',           icon: StoreMallDirectory,   role: 'ROLE_UI_TAB_GOOD',         component: GoodsPage, },
    { path: '/organization',   label: 'Organization',        icon: Home,                 role: 'ROLE_UI_TAB_ORGANIZATION', component: OrganizationPage, },
    { path: '/money-flow',     label: 'money',               icon: MonetizationOn,       role: 'ROLE_UI_TAB_MONEYFLOW',    component: MoneyFlowPage, },
    { path: '/report-card',    label: 'Staff',               icon: Person,               role: 'ROLE_UI_TAB_REPORT_CARD',  component: CalendarEmployeePage, },
    { path: '/settings',       label: 'Settings',            icon: Settings,             role: 'ROLE_UI_TAB_SETTINGS',     component: SettingsPage, },
    { path: '/tasks',          label: 'Tasks',               icon: TasksIcon,            role: 'ROLE_UI_TAB_TASKS',        component: TasksPage, },
];

export default
@withRouter
@connect(state => ({
    token: state.auth.token,
    currentUser: state.auth.user,
    socketConnected: state.socket.connected,
    currentParlour: state.common.currentParlour,
}), {
    fetchCurrentUser: authActions.fetchCurrentUser,
    logout: authActions.logout,
    setCurrentParlour: commonActions.setCurrentParlour,
    showMessage: messageDialogActions.show,
})
@withStyles(theme => ({
    root: {
        display: 'flex',
    },
    socketConnected: {
        color: green[500],
    },
    course: {
        marginRight:  theme.spacing(1),
        marginLeft:  theme.spacing(0.5),
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        },
    },
    userAccessDenied: {
        minWidth: 300,
        maxWidth: 500,
        padding: 20,
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
        marginBottom: 20,
        border: '1px solid #555555',
        borderRadius: 10,
    },
    tabs: {
        backgroundColor: '#fff',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.light,
        '& .MuiTabs-scrollButtons': {
                color: theme.palette.text.primary,
        },
        '& .MuiTab-root': {
            padding: '3px 6px',
        },
        '& .MuiTab-wrapper': {
            lineHeight: 1.75,
            fontSize: 10,
            '& > *:first-child': {
                fontSize: 24,
                marginBottom: 0,
            }
        },
        '& .MuiTab-labelIcon': {
            minHeight: 48,
        },
    },
    topBarItemSpacing: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    topBarPadding: {
        height: 48,
    },
    topBar: {
        zIndex: 1210,
        overflow: 'hidden',
    },
    topBarMaxHeight: {
        maxHeight: '100%',
    },
    logo: {
        display: 'inline-block',
        borderRight: '1px solid #fff',
        height: topBarSettings.logoStartHeight,
        minHeight: topBarSettings.logoStartHeight,
        paddingRight: topBarSettings.logoPaddingStart,
        marginRight: topBarSettings.logoPaddingStart,
    },
    slogan: {
        color: '#fff',
        padding: '2px 0 2px 0',
        lineHeight: '16px',
        whiteSpace: 'pre-wrap',
        textTransform: 'uppercase',
        fontWeight: 500,
        position: 'absolute',
    },
    fullWidthContainer: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    smallToolbar: {
        opacity: 0,
        display: 'none',
    },
    smallToolbarIcon: {
        color: theme.palette.getContrastText(theme.palette.primary.main),

        display: 'inline-block',
        marginRight: theme.spacing(1),
        padding: theme.spacing(0, 0.5),
    },
    smallToolbarIconActive: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.getContrastText(theme.palette.secondary.main),
    }
}))
@withTranslation()
class App extends React.Component {
    state = {
        value: 0,
        userMenuOpen: false,
        anchorEl: null,
        currentCourses: [],
        showMenu: true
    };

    roleRoutes = [];

    appBarRef = React.createRef()
    toolBarRef = React.createRef()
    buttonLogoRef = React.createRef()
    logoRef = React.createRef()
    barContainerRef = React.createRef()
    sloganRef = React.createRef()
    smallBarRef = React.createRef()

    componentDidMount() {
        const { token, fetchCurrentUser, currentParlour, showMessage } = this.props;
        kkmService.init(showMessage)

        if (token && token.access_token) {
            fetchCurrentUser();
            if (this.props.currentParlour && this.props.currentParlour.id) {
                getParlour(this.props.currentParlour.id)
                    .then(response => {
                        if (!_.isEqual(currentParlour, response.data)) {
                            this.props.setCurrentParlour(response.success ? response.data : null);
                        }
                    })
            }
            this.loadCurrentCourses();
        }

        window.addEventListener('scroll', this.handleScroll);

        this.props.history.listen(() => {
            this.sendUrlHistory();
        });
        this.sendUrlHistory();
    }

    sendUrlHistory = () => {
        if (window.location.hostname === 'localhost') {
            return;
        }

        if (window.yaCounter69275761) {
            window.yaCounter69275761.hit(window.location.pathname);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    updateToolbar = () => {
        const {
            startHeight,
            minHeight,
            logoMinHeight,
            logoStartHeight,
            minFontSize,
            startFontSize,
            logoPaddingStart,
            logoPaddingMin,
        } = topBarSettings;

        let topBarHeight = minHeight;

        if ((startHeight - minHeight) > this.scrollY / 3.0) {
            topBarHeight = startHeight - this.scrollY / 3.0;
        }
        const topBarHeightPx = `${topBarHeight}px`

        if (this.appBarRef.current.style.height !== topBarHeightPx) {
            const percent = (topBarHeight - minHeight) / (startHeight - minHeight);
            const logoHeight = `${logoMinHeight + percent * (logoStartHeight - logoMinHeight)}px`;
            const logoPadding = `${logoPaddingMin + percent * (logoPaddingStart - logoPaddingMin)}px`;
            const fontSizePx = `${minFontSize + percent * (startFontSize - minFontSize)}px`

            this.appBarRef.current.style.height = topBarHeightPx
            this.appBarRef.current.style.minHeight = topBarHeightPx
            this.appBarRef.current.style.fontSize = fontSizePx

            this.toolBarRef.current.style.height = topBarHeightPx
            this.toolBarRef.current.style.minHeight = topBarHeightPx
            this.toolBarRef.current.style.fontSize = fontSizePx

            this.buttonLogoRef.current.style.height = topBarHeightPx
            this.buttonLogoRef.current.style.minHeight = topBarHeightPx
            this.buttonLogoRef.current.style.fontSize = fontSizePx

            this.barContainerRef.current.style.height = topBarHeightPx
            this.barContainerRef.current.style.minHeight = topBarHeightPx
            this.barContainerRef.current.style.fontSize = fontSizePx

            this.logoRef.current.style.height = logoHeight
            this.logoRef.current.style.minHeight = logoHeight
            this.logoRef.current.style.paddingRight = logoPadding
            this.logoRef.current.style.marginRight = logoPadding

            this.sloganRef.current.style.opacity = percent
            this.sloganRef.current.style.display = percent < 0.1 ? 'none' : 'inline'

            if (this.smallBarRef.current) {
                this.smallBarRef.current.style.opacity = 1 - percent
                this.smallBarRef.current.style.display = percent < 0.8 ? 'block' : 'none'
            }
        }

        this.rAF = null;
    }

    handleScroll = () => {
        if (!this.appBarRef.current) {
            return;
        }

        if (this.rAF) {
            cancelAnimationFrame(this.rAF);
            this.rAF = null;
        }
        this.scrollY = window.scrollY
        this.rAF = requestAnimationFrame(this.updateToolbar)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { token, fetchCurrentUser, currentUser, currentParlour } = this.props;

        if (prevProps.token !== token && token && token.access_token) {
            fetchCurrentUser();
            this.loadCurrentCourses();
        }

        // do not show links for staff schedule page
        if (
            currentUser &&
            currentUser.roles.includes('ROLE_SCHEDULE_STAFF_GET') &&
            !currentUser.isSuperAdmin &&
            this.state.showMenu
        )
            this.setState({
                showMenu: false
            })

        if (prevProps.currentUser !== currentUser && currentUser) {
            socket.connect();
        }

        if (prevProps.currentParlour !== currentParlour) {
            this.loadCurrentCourses();
        }
    }

    loadCurrentCourses() {
        const parlour = this.props.currentParlour;
        if (hasRole('ROLE_MONEYFLOW_COURSES_GET') && parlour && parlour.id) {
            getCourses({
                businessUnitId: parlour.id,
                date_start: moment().utcOffset(parlour.timeZone).startOf('day').format('YYYY-MM-DD'),
                currency_to: parlour.mainCurrency,
            }).then(response => {
                if (response.success) {
                    this.setState({
                        currentCourses: response.data,
                    });
                }
            })
        }
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLogout = () => {
        this.handleClose();

        this.props.logout();
    };

    handlePersonalAccount = () => {
        this.handleClose();
        this.props.history.push('/personal-account');
    };

    render() {
        const { classes, currentUser, location, socketConnected, t, i18n, currentParlour } = this.props;
        const { anchorEl, currentCourses, showMenu } = this.state;
        const userMenuOpen = Boolean(anchorEl);

        this.roleRoutes = routes.filter(route => hasRole(route.role));
        let tabIndex = this.roleRoutes.findIndex(route => new RegExp('^'+route.path).test(location.pathname));

        if (tabIndex < 0) {
            tabIndex = false;
        }

        return <DndProvider backend={HTML5Backend}>
            <AppBar
                position="fixed"
                className={cn(classes.hidePrint, classes.topBar)}
                elevation={2}
                ref={this.appBarRef}
            >
                <Toolbar variant="dense" ref={this.toolBarRef}>
                    <div className={classes.topBarMaxHeight}>
                        <Button
                            component="a"
                            href="/"
                            size="small"
                            variant="text"
                            ref={this.buttonLogoRef}
                        >
                            {showLogo && (
                                <img
                                    src={'/img/logo_'+ (i18n.language === 'ru' ? 'ru' : 'en') +'.png'}
                                    alt="TT"
                                    className={cn(classes.logo, classes.topBarMaxHeight)}
                                    ref={this.logoRef}
                                />
                            )}
                        </Button>
                    </div>
                    <div
                        className={cn(classes.fullWidthContainer, classes.topBarMaxHeight)}
                        ref={this.barContainerRef}
                    >
                        <span
                            className={classes.slogan}
                            ref={this.sloganRef}
                        >{ t("Increase the number of happy moments") }</span>

                        {currentUser ?
                            <div
                                className={classes.smallToolbar}
                                ref={this.smallBarRef}
                            >
                                {this.roleRoutes.map((route, idx) => {
                                    const Icon = route.icon;
                                    return <Tooltip key={idx} title={t(route.label)}><Link
                                        to={this.roleRoutes[idx].path}
                                        disabled={route.disabled}
                                        size="small"
                                        className={cn(classes.smallToolbarIcon, {[classes.smallToolbarIconActive]: tabIndex === idx})}
                                    ><Icon fontSize="small"/></Link></Tooltip>;
                                })}
                            </div>
                            : null
                        }
                    </div>
                    {currentUser &&
                    <div className={classes.topBarItemSpacing}>
                        { currentParlour && <span>{currentParlour.name}</span>}
                        { (currentParlour && Boolean(currentCourses.length)) && <span>: </span>}
                        {currentCourses.map((course, courseIdx) =>
                            <span
                                key={courseIdx}
                                className={classes.course}>
                                            {formatMoney({val: 1, cur: course.currencyFrom})} =
                                {formatMoney({val: course.value, cur: course.currencyTo})}
                                        </span>
                        )}
                    </div>
                    }
                    <LanguageSwitcher className={classes.topBarItemSpacing}/>
                    { currentUser ?
                        <div>
                            <span>{currentUser.username}</span>
                            <IconButton
                                aria-owns={userMenuOpen ? 'menu-user' : null}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                            >
                                <AccountCircle className={classNames({ [classes.socketConnected]: socketConnected })}/>
                            </IconButton>
                            <Menu
                                id="menu-user"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={userMenuOpen}
                                onClose={this.handleClose}
                            >
                                <MenuItem onClick={this.handlePersonalAccount}>{t("Account")}</MenuItem>
                                <Divider/>
                                <MenuItem onClick={this.handleLogout}>{t("Exit")}</MenuItem>
                            </Menu>
                        </div>
                        : null
                    }
                </Toolbar>
            </AppBar>
            <AppBar position="static" className={classes.hidePrint}>
                <div className={classes.topBarPadding}> </div>
                {currentUser && showMenu ?
                    <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        className={classes.tabs}
                    >
                        {this.roleRoutes.map((route, idx) => {
                            const Icon = route.icon;
                            return <Tab key={idx}
                                 component={Link}
                                 to={this.roleRoutes[idx].path}
                                 label={t(route.label)}
                                 icon={<Icon/>}
                                 disabled={route.disabled}
                            />;
                        })}
                    </Tabs>
                    : null
                }
            </AppBar>
            <div className={classes.root}>
                <Switch>
                    <Route exact path="/" render={() => (<Redirect to="/orders"/>)}/>
                    <Route              path="/login"            component={LoginPage}/>
                    <Route path="/password-recovery" component={RecoveryPage}/>
                    {routes.map((route,idx) => <AuthenticatedRoute key={idx} path={route.path} component={route.component} userRole={route.role}/>)}
                    <AuthenticatedRoute path="/personal-account" component={PersonalAccount} userRole="ROLE_UI_TAB_PERSONAL_ACCOUNT"/>
                    <AuthenticatedRoute path="/tools" component={ToolsPage} userRole="ROLE_UI_TAB_TOOLS"/>
                    <AuthenticatedRoute path="/download" component={Download}/>
                    <AuthenticatedRoute path="/staff-schedule" component={StuffSchedulePage}/>
                </Switch>

                { currentUser && currentUser.roles && currentUser.roles.length === 0 ?
                    <div className={classes.userAccessDenied}>
                        <h3>{t('Access denied')}</h3>
                        <p>{t('accessDeniedEmptyRoles')}</p>
                    </div>
                    : null
                }
            </div>
            <Info/>
            <MessageDialog />
            <ConfirmDialog />

            <BackgroundProgress/>
        </DndProvider>;
    }
}
