import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {getGratuityReport, navLinks} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {getBusinessUnitByRole} from "../../services/user";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class GratuityReportPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: null,
            date_end: null,
            type: null,
            businessUnitId: null
        },
        refreshTable: false,
        businessUnits: [{ id: 0, name: this.props.t('---All---')}],
    };

    types = [
        {id: 'withVisit', text: this.props.t("With visit")},
        {id: 'withToParlour', text: this.props.t("With parlour")},
        {id: 'withToEmployee', text: this.props.t("With employee")},
    ];

    columnData = [
        { id: 'createdAt', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},

        { id: 'saleId', label: 'Sale', linkTemplate: (row) => `/tools/sales/${row.saleId}` },
        { id: 'saleTotalFormatted', label: 'Sale Total', },
        { id: 'visitId', label: 'Visit', linkTemplate: (row) => `/orders/view/${row.visitId}`, },
        { id: 'guestName', label: 'Guest', },
        { id: 'totalFormatted', label: 'Gratuity total', },

        { id: 'saleParlourName', label: 'Business unit', },
        { id: 'parlourTotalFormatted', label: 'Parlour will receive', },

        { id: 'toEmployeeName', label: 'To employee', },
        { id: 'toParlourName', label: 'To parlour', },

        { id: 'masterTotalFormatted', label: 'Masters will receive', },
        { id: 'masterNames', label: 'Masters', },

        { id: 'adminTotalFormatted', label: 'Administrators will receive', },
        { id: 'administratorNames', label: 'Administrators', },

    ];

    columnDataXlsx = [
        { id: 'createdAt', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},

        { id: 'saleId', label: 'Sale' },
        { id: 'saleTotalFormatted', label: 'Sale Total', },
        { id: 'visitId', label: 'Visit' },
        { id: 'guestName', label: 'Guest', },
        { id: 'totalFormatted', label: 'Gratuity total', },

        { id: 'saleParlourName', label: 'Business unit', },
        { id: 'parlourTotalFormatted', label: 'Parlour will receive', },

        { id: 'toEmployeeName', label: 'To employee', },
        { id: 'toParlourName', label: 'To parlour', },

        { id: 'masterTotalFormatted', label: 'Masters will receive', },
        { id: 'masterNames', label: 'Masters', },

        { id: 'adminTotalFormatted', label: 'Administrators will receive', },
        { id: 'administratorNames', label: 'Administrators', },
    ];

    componentDidMount() {
        getBusinessUnitByRole('ROLE_SALE_GRATUITYREPORT')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getGratuityReport(search, ...params);
    };

    handleExport = () => {
        const { t } = this.props;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => t(item.label) ),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Gratuity");
                    XLSX.writeFile(new_workbook, `Gratuity.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t('Show moving by date interval')}
                        className={classes.filterControl}
                    />
                    <AnyField
                        id="parlour"
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <SelectFromItemsField
                        items={this.types}
                        onChange={this.handleChange('type')}
                        value={search.type}
                        className={classes.filterControl}
                        label={t('Type')}
                        textField="text"
                        valueField="id"
                        fullWidth
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Gratuity')}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        autoload={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
