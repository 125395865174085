import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    MenuItem,
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import SearchField from "../../components/fields/SearchField";
import {getParlours} from "../../services/organization";
import {getGoodPrices, navLinks} from "../../services/goods";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

const columnData = [
    { id: 'good.nameWithMass', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товара' },
    { id: 'businessUnitName', label: 'Бизнес юнит' },
    { id: 'salePriceFormatted', label: 'Цена продажи' },
    { id: 'usePriceFormatted', label: 'Цена использования' },
    { id: 'createdAt', label: 'Создано', dateFormat: 'DD.MM.YYYY HH:mm:ss' },

];

export default
@connect(state => ({
}),{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
class GoodPricesListPage extends React.Component
{
    state = {
        dialogEditOpen: false,
        refreshTable: false,
        businessUnits: [],
        goods: [],
        search: {
            search: null,
            businessUnitId: null
        }
    };

    componentDidMount() {
        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });
    }

    getTableData = (...params) => {
        const {search} = this.state;
        return getGoodPrices(search, ...params);
    };

    handleAdd = () => {
        this.props.history.push(`/goods/prices/0`);
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    render() {
        const { refreshTable, businessUnits, search } = this.state;
        const { classes } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <AnyField
                        id="parlour"
                        label="Бизнес юнит"
                        value={search.businessUnitId}
                        onChange={this.handleChangeSearch('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <ActionButton onClick={this.handleClickSearch}>Отфильтровать</ActionButton>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Цены товаров">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        linkTemplate={(item) => `/goods/prices/${item.id}`}
                    />
                </Content>
            </React.Fragment>

        )
    }
}
