import React from 'react';
import CheckboxField from "../../components/fields/CheckboxField";
import {getVisitStatusColor, getVisitStatusText, visitStatuses} from "../../services/calendar";
import * as PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import calendarActions from "./calendar-actions";
import {withStyles} from "@material-ui/core";

const canBeHiddenStatuses = [
    visitStatuses.canceled,
    visitStatuses.blocked,
];

export default @withTranslation()
@connect(state => ({
    hiddenStatuses: state.calendar.hiddenStatuses,
    showTransferredVisits: state.calendar.showTransferredVisits,
}), {
    setHiddenStatuses: calendarActions.setHiddenStatuses,
    setShowTransferredVisits: calendarActions.setShowTransferredVisits
})
@withStyles(({
    legend: {
        display: 'inline-block',
    },
}))
class ScheduleLegend extends React.PureComponent {
    static propTypes = {
        hasRiskyStatus: PropTypes.bool,
    }

    static defaultProps = {
        hasRiskyStatus: false,
    }

    handleChangeStatus = status => event => {
        if (canBeHiddenStatuses.indexOf(status) < 0) {
            return;
        }

        const hiddenStatuses = [...this.props.hiddenStatuses];
        if (!event.target.value && hiddenStatuses.indexOf(status) < 0) {
            hiddenStatuses.push(status);
            this.props.setHiddenStatuses(hiddenStatuses);
        } else if (event.target.value && hiddenStatuses.indexOf(status) >= 0) {
            hiddenStatuses.splice(hiddenStatuses.indexOf(status), 1);
            this.props.setHiddenStatuses(hiddenStatuses);
        }
    };

    handleChangeShowTransferredVisits = (event) => {
        this.props.setShowTransferredVisits(event.currentTarget.checked)
    }

    render() {
        const {classes, t, hasRiskyStatus, hiddenStatuses, showTransferredVisits} = this.props;

        let availableStatuses = Object.keys(visitStatuses);
        if (!hasRiskyStatus) {
            availableStatuses = availableStatuses.filter(status => [visitStatuses.confirmed, visitStatuses.risky].indexOf(status) < 0);
        }
        return <>
            {availableStatuses.map(status =>
                <span className={classes.legend} key={status}>
                    <CheckboxField
                        value={hiddenStatuses.indexOf(status) < 0}
                        label={t(getVisitStatusText(status))}
                        margin="none"
                        checkboxStyle={{
                            color: getVisitStatusColor(status),
                        }}
                        onChange={this.handleChangeStatus(status)}
                        indeterminate={canBeHiddenStatuses.indexOf(status) < 0}
                        dense
                    />
                </span>
            )}
            <span className={classes.legend}>
                <CheckboxField
                    checked={showTransferredVisits}
                    value={!!showTransferredVisits}
                    label={t('Show transferred')}
                    margin="none"
                    onChange={this.handleChangeShowTransferredVisits}
                    dense
                />
            </span>
        </>;
    }
}