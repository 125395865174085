import { withTranslation } from "react-i18next";
import React from "react";
import { Fab, Grid } from "@material-ui/core";
import AnyField from "../../../components/fields/AnyField";
import * as PropTypes from "prop-types";
import DiscountSelect from "../../../components/fields/DiscountSelect";
import SelectFromItemsField from "../../../components/fields/SelectFromItemsField";
import MoneyField from "../../../components/fields/MoneyField";
import ClearIcon from "@material-ui/icons/Clear";
import ActionButton from "../../../components/button/ActionButton";
import Add from "@material-ui/icons/Add";
import { getGuestNalegke } from "../../../services/guests";
import { getVisitDurationHours } from "../helpers";
import _ from "lodash";
import { connect } from "react-redux";
import infoActions from "../../../components/info/info-actions";

const discountTimes = [
    {value: 15, text: '0:15'},
    {value: 30, text: '0:30'},
    {value: 45, text: '0:45'},
    {value: 60, text: '1:00'},
    {value: 75, text: '1:15'},
    {value: 90, text: '1:30'},
    {value: 105, text: '1:45'},
    {value: 120, text: '2:00'},
    {value: 135, text: '2:15'},
    {value: 150, text: '2:30'},
    {value: 165, text: '2:45'},
    {value: 180, text: '3:00'},
];

export const DISCOUNT_TYPE = 'discount';

export default
@withTranslation()
@connect(state => ({
    //
}),{
    showError: infoActions.showError,
})
class ViewOrderSectionDiscount extends React.PureComponent {

    static propTypes = {
        visit: PropTypes.object,
        editing: PropTypes.bool,
        discounts: PropTypes.array,
        guestsInDayWithDiscount: PropTypes.array,
        filterDiscounts: PropTypes.func,
        handleDiscountChange: PropTypes.func,
        handleDiscountDelete: PropTypes.func,
        handleSelectDiscount: PropTypes.func,
        handleChangeDiscountNumber: PropTypes.func,
        handleAddMultiDiscountRow: PropTypes.func,
        handleChangeMultiDiscountRow: PropTypes.func,
        handleDeleteMultiDiscountRow: PropTypes.func,
    };

    state = {
        nalegke: null,
    };

    get visit() {
        return this.props.visit;
    }

    get filteredDiscounts() {
        return this.props.discounts.filter((d) => {
            return this.props.filterDiscounts(d);
        })
    }

    get selectedDiscount() {
        return this.props.discounts.find((item) => item.id === this.props.visit.discountId)
    }

    get isSingle() {
        return this.selectedDiscount && this.selectedDiscount.numberType !== 'multi';
    }

    get isMulti() {
        return this.selectedDiscount && this.selectedDiscount.numberType === 'multi';
    }

    get isSelectAvailable() {
        return this.props.visit.discountType && this.props.visit.discountType === DISCOUNT_TYPE;
    }

    get isAddButtonAvailable() {
        if (this.isMulti) {
            return true;
        }

        if (this.isSingle) {
            return false;
        }

        return this.props.visit.discountType === null;
    }

    calculateNalegke(used, remain, count) {
        const visitTime = getVisitDurationHours(this.visit.visitFacility);

        let mapped = _.range(1, visitTime + 1).map((number) => {
            let current = Math.floor(used + number);

            if (current > count) {
                current = current - count;
            }

            return current;
        });

        if (!_.isInteger(visitTime) && !_.isInteger(used)) {
            let first = mapped.shift();
            mapped.push(first);
        }

        return mapped.join(', ');
    }

    loadNalegke(discount) {
        const guestId = this.props.visit.guest ?? this.props.visit.parentGuestId ?? null;

        if (guestId === null) {
            this.props.showError('Скидку запрещено использовать без указания гостя.');
            return;
        }

        return getGuestNalegke(guestId, discount.id, this.props.visit.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        nalegke: {
                            ...this.state.nalegke,
                            [discount.id]: response.data.find((n) => discount.id === n.id),
                        },
                    })
                }
            });
    }

    calculateNumber(discount) {
        const nalegke = this.state.nalegke[discount.id];

        return this.calculateNalegke(nalegke.used, nalegke.remain, nalegke.count);
    }

    async handleChangedDiscountInternal(discount) {
        await this.loadNalegke(discount);

        const number = this.calculateNumber(discount);

        this.props.handleChangeDiscountNumber({target: {value: number}});
    }

    handleDiscountSelectChange = (event) => {
        const selectedDiscount = this.props.discounts.find((item) => event.target.value === item.id);

        if (selectedDiscount.numberAutoCalculated) {
            void this.handleChangedDiscountInternal(selectedDiscount);
        }

        this.props.handleSelectDiscount(event);
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    {this.isSelectAvailable &&
                        <Grid item xs={3}>
                            {this.renderDiscountSelect(
                                this.visit.discountId,
                                this.filteredDiscounts,
                                this.handleDiscountSelectChange
                            )}
                        </Grid>
                    }
                    {this.isSingle &&
                        <Grid item xs={3}>
                            {this.renderDiscountTypeField(
                                this.selectedDiscount,
                                this.visit.discountNumber,
                                this.props.handleChangeDiscountNumber
                            )}
                        </Grid>
                    }
                    {this.props.editing && this.isSelectAvailable &&
                        <Grid item xs={3}>
                            <div style={{paddingTop: '20px'}}></div>
                            <Fab color="secondary"
                                 onClick={this.props.handleDiscountDelete}
                                 size={"small"}><ClearIcon/></Fab>
                        </Grid>
                    }
                </Grid>
                {this.isMulti
                    ? (this.visit.multiDiscount ?? []).map((row, index) => {
                            const selectedDiscount = this.props.discounts.find((item) => row.discountId === item.id);
                            const filteredDiscounts = this.props.discounts.filter((d) => {
                                return this.props.filterDiscounts(d, row.time)
                                    && d.numberType !== 'multi'
                                    && d.numberAutoCalculated === false;
                            });
                            return (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={3}>
                                        <SelectFromItemsField
                                            items={discountTimes}
                                            value={row.time}
                                            onChange={this.props.handleChangeMultiDiscountRow(index, 'time')}
                                            textField="text"
                                            fullWidth
                                            label="Время"
                                            readOnly={!this.props.editing}
                                            valueField="value"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {this.renderDiscountSelect(
                                            row.discountId,
                                            filteredDiscounts,
                                            this.props.handleChangeMultiDiscountRow(index, 'discountId')
                                        )}
                                    </Grid>
                                    {selectedDiscount &&
                                        <Grid item xs={3}>
                                            {this.renderDiscountTypeField(
                                                selectedDiscount,
                                                row.discountNumber,
                                                this.props.handleChangeMultiDiscountRow(index, 'discountNumber')
                                            )}
                                        </Grid>
                                    }
                                    {this.props.editing &&
                                        <Grid item xs={3}>
                                            <div style={{paddingTop: '20px'}}></div>
                                            <Fab color="secondary"
                                                 onClick={this.props.handleDeleteMultiDiscountRow(index)}
                                                 size={"small"}><ClearIcon/></Fab>
                                        </Grid>
                                    }
                                </Grid>
                            )
                        }
                    )
                    : null}
                {this.isAddButtonAvailable
                    ? <Grid container spacing={2}>
                        <Grid item xs={3}>{this.renderAddButton()}</Grid></Grid>
                    : null}
            </React.Fragment>
        )
    }

    renderDiscountSelect = (discountId, discounts, onChange) => {
        const {t} = this.props;

        return (
            <DiscountSelect
                label={t('Discount')}
                value={discountId}
                fullWidth
                readOnly={!this.props.editing}
                onChange={onChange}
                discounts={this.props.editing ? discounts : this.props.discounts}
            />
        )
    }

    renderDiscountTypeField = (discount, value, onChange) => {
        const {t} = this.props;
        const {visit} = this.props;
        const {editing} = this.props;
        const {guestsInDayWithDiscount} = this.props;

        switch (discount.numberType) {
            case "guest_in_day_with_discount":
                return <SelectFromItemsField
                    items={guestsInDayWithDiscount}
                    label={t("Guest")}
                    value={value}
                    readOnly={!editing}
                    fullWidth
                    visible={false}
                    required={discount && discount.numberRequired}
                    error={discount && discount.numberRequired && !value}
                    onChange={onChange}
                />;
            case "money":
                return <MoneyField
                    label={t("Amount of payment")}
                    value={{val: value, cur: visit.sale.total.cur}}
                    readOnly={!editing}
                    changeOnlyValue
                    fullWidth
                    required={discount && discount.numberRequired}
                    error={discount && discount.numberRequired && !value}
                    onChange={onChange}
                />;
            case "discount_card":
            case "text":
                return <AnyField
                    label={t("Discount number")}
                    value={value}
                    readOnly={!editing || discount.numberAutoCalculated}
                    required={discount && discount.numberRequired}
                    error={discount && discount.numberRequired && !value}
                    fullWidth
                    onChange={onChange}
                />;
            default:
                return null;
        }
    }

    onAddButtonClick = () => {
        if (this.isMulti) {
            this.props.handleAddMultiDiscountRow();
            return;
        }

        if (!this.isSingle) {
            this.props.handleDiscountChange(DISCOUNT_TYPE);
            return;
        }
    }

    renderAddButton = () => {
        const {t} = this.props;

        return (
            <ActionButton
                disabled={!this.props.editing}
                fullWidth={true}
                onClick={this.onAddButtonClick}><Add/>{t('Add discount')}</ActionButton>
        )
    }
}