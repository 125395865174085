import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getOrders, navLinks} from "../../services/goods";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import SearchField from "../../components/fields/SearchField";


const columnData = [
    { id: 'num', label: 'Номер заказа' },
    { id: 'createdAt', label: 'Дата создания заказа', dateFormat: 'DD.MM.YYYY' },
    { id: 'creator.username', label: 'Пользователь, создавший заказ' },
    { id: 'creator.phone', label: 'Телефон пользователя, создавшего заказ' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'counterpart.username', label: 'Контрагент' },
    { id: 'statusText', label: 'Статус заказа' },
];

class OrderListPage extends React.Component
{

    state = {
        search: '',
        refreshTable: false,
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getOrders({search}, ...params);
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickSearch = () => {
        this.setState({
            refreshTable: !this.state.refreshTable,
        })
    };

    render() {
        const { search, refreshTable } = this.state;
        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search}
                        onChange={this.handleChange('search')}
                        label="Поиск по заказам"
                        onClick={this.handleClickSearch}
                    />
                </LeftBar>

                <Content title="Заказы на товары">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/goods/orders/view/${item.id}`}
                        refresh={refreshTable}
                        order="desc"
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default OrderListPage;
