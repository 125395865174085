import React from 'react';
import {Route, Switch} from "react-router";
import ListPage from "./ListPage";
import ViewPage from "./ViewPage";
import HistoryChangePage from "./HistoryChangePage";

class FacilitiesPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/facilities"           component={ListPage}/>
                <Route       path="/facilities/edit"      component={ViewPage}/>
                <Route       path="/facilities/view"      component={ViewPage}/>
                <Route       path="/facilities/history-change"      component={HistoryChangePage}/>
            </Switch>
        );
    }
}

export default FacilitiesPage;