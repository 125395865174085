import React, {useCallback, useContext, useMemo} from 'react';
import moment from "moment";
import DatePeriodField2 from "../../../../components/fields/DatePeriodField2";
import DateMonthField from "../../../../components/fields/DateMonthField";
import Context from "../Context";

const PeriodMonth = () => {
    const { search: { dateType, dayStart, dayEnd, month }, updateSearch } = useContext(Context);

    const maxDate = useMemo(() => moment(), []);

    const handleChangeDayStart = useCallback(event => {
        updateSearch(draftSearch => {
            draftSearch.dayStart = event.target.value;
        });
    }, [updateSearch]);

    const handleChangeDayEnd = useCallback(event => {
        updateSearch(draftSearch => {
            draftSearch.dayEnd = event.target.value;
        });
    }, [updateSearch]);

    const handleChangeMonth = useCallback(event => {
        updateSearch(draftSearch => {
            draftSearch.month = event.target.value;
        });
    }, [updateSearch]);

    if (dateType === 'period') {
        return (
            <DatePeriodField2
                valueFrom={dayStart}
                valueTo={dayEnd}
                maxDate={maxDate}
                onChangeFrom={handleChangeDayStart}
                onChangeTo={handleChangeDayEnd}
                checkValuesOrder
            />
        );
    }

    return (
        <DateMonthField
            value={month}
            onChange={handleChangeMonth}
            fullWidth
        />
    );
};

export default PeriodMonth;