import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    MenuItem,
    InputAdornment,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    IconButton, LinearProgress
} from "@material-ui/core";
import Autosuggest from 'react-autosuggest/dist/Autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import BackspaceRounded from "@material-ui/icons/BackspaceRounded";
import ForwardIcon from "@material-ui/icons/Forward";
import AnyField from "./AnyField";
import {emptyFieldColor, fillFieldColor} from "../../services/constants";
import { connect } from "react-redux";
import messageDialogActions from "../dialogs/messageDialog-acions";
import classNames from "classnames";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import {createReferralPartner, getExternalSubject, getReferralPartners} from "../../services/organization";

const styles = theme => ({
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    imgLoading: {
        height: 30,
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    endAdornment: {
        marginRight: - theme.spacing(1),
    },
    textFieldContainer: {
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        bottom: 1,
        left: 1,
        right: 1,
    },
});

export default
@connect(null, {
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class ReferralPartnerField extends React.Component
{
    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
        readOnly: PropTypes.bool,
        required: PropTypes.bool,
        error: PropTypes.any,
    }

    static defaultProps = {
        readOnly: false,
        required: false,
    };

    state = {
        inputValue: '',
        suggestions: [],
        isLoading: false,
        partner: {
            id: null,
            name: null,
        },
        newPartner: {
            id: null,
            name: null,
            num: null
        },
        dialogAddOpen: false,
    };

    componentDidMount() {
        this.loadPartner(this.props.value)
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.state.partner.id) {
            this.loadPartner(nextProps.value);
        }
    }

    loadPartner = (id) => {
        if (id) {
            getExternalSubject(id)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            partner: response.data,
                            inputValue: this.getSuggestionValue(response.data),
                        })
                    }
                });
        } else {
            this.setState({
                partner: {
                    id: null,
                    name: null,
                },
                inputValue: '',
            });
        }
    };

    handleClear = () => {
        if (this.props.readOnly) {
            return;
        }

        this.setState({
            partner: {
                id: null,
                fullName: null,
            },
            inputValue: '',
        });

        if (this.props.onChange) {
            this.props.onChange( { target: { value: null } }, null );
        }
    };

    renderInputComponent = inputProps => {
        const { classes, readOnly, t } = this.props;
        const { partner } = this.state;
        const { inputRef = () => {}, ref, loading, ...other } = inputProps;

        return (
            <div className={classes.textFieldContainer}>
                <TextField
                    fullWidth
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                        endAdornment: partner.id ?
                            <React.Fragment>
                                <InputAdornment position="end" className={classes.endAdornment}>
                                    <Link to={`/organization/external-subjects/view/${partner.id}`}><Tooltip title={t("Go to partner card")}><ForwardIcon fontSize="small"/></Tooltip></Link>
                                </InputAdornment>
                                <InputAdornment position="end" className={classes.endAdornment}>
                                    <IconButton
                                        aria-label={t("Clear")}
                                        onClick={this.handleClear}
                                    >
                                        <BackspaceRounded fontSize="small"  />
                                    </IconButton>
                                </InputAdornment>
                            </React.Fragment>
                            : null,
                        readOnly: !!partner.id  || readOnly,
                        style: {
                            background: partner.id ? fillFieldColor : emptyFieldColor,
                        }
                    }}
                    {...other}
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
                {loading ?
                    <LinearProgress variant="query" className={classes.progress}/>
                    : null
                }
            </div>
        )
    };

    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        let label = suggestion.id === -1 ? this.getSuggestionValue(suggestion) : `${this.getSuggestionValue(suggestion)}`;
        const matches = match(label, query);
        const parts = parse(label, matches);

        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map((part, index) => {
                        return part.highlight
                            ? <span key={String(index)} style={{fontWeight: 500}}>{part.text}</span>
                            : <strong key={String(index)} style={{fontWeight: 300}}>{part.text}</strong>;
                    })}
                </div>
            </MenuItem>
        );
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debounceLoadSuggestions(value);
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    loadSuggestions = value => {
        const { t } = this.props;

        this.setState({
            isLoading: true,
        });

        getReferralPartners(value, 1, 20)
            .then(response => {
                this.setState({
                    isLoading: false,
                });
                if (response.success) {
                    this.setState({
                        suggestions: [...response.data, {id: -1, name: t("Add a new partner")}]
                    })
                }
            })
    };

    debounceLoadSuggestions = _.debounce(this.loadSuggestions, 500);

    getSuggestionValue(suggestion) {
        return suggestion.name + ' ' + (suggestion.num || '');
    }

    onSuggestionSelected = (event, { suggestion }) => {
        if (suggestion.id === -1) {
            this.setState({
                newPartner: {
                    id: null,
                    name: null,
                },
                inputValue: '',
                dialogAddOpen: true,
            });
        } else {
            this.setState({
                partner: suggestion,
                inputValue: suggestion.name,
            });

            if (this.props.onChange) {
                this.props.onChange( { target: { value: suggestion.id } }, suggestion );
            }
        }
    };

    shouldRenderSuggestions = value => {
        const { partner } = this.state;


        return value.length >= 1 && !partner.id;
    };

    handleCloseAddDialog = ok => () => {
        if (!ok) {
            this.setState({
                inputValue: '',
                dialogAddOpen: false,
            });
            return;
        }

        createReferralPartner(this.state.newPartner)
            .then(response => {
                if (response.success) {
                    const partner = response.data;
                    this.setState({
                        partner,
                        inputValue: this.getSuggestionValue(partner),
                        dialogAddOpen: false,
                    });

                    if (this.props.onChange) {
                        this.props.onChange( { target: { value: partner.id } }, partner );
                    }
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleChangeName = event => {
        this.setState({
            newPartner: {
                ...this.state.newPartner,
                name: event.target.value,
            }
        });
    };

    handleChangeNum = event => {
        this.setState({
            newPartner: {
                ...this.state.newPartner,
                num: event.target.value,
            }
        });
    };

    render() {
        const { classes, className, label, required, error, t } = this.props;
        const { suggestions, inputValue, isLoading, newPartner, dialogAddOpen } = this.state;

        return (
            <div className={classNames(classes.root,{[classes.withLabel]: label}, className)}>
                <Autosuggest
                    inputProps={{
                        label: label,
                        loading: isLoading,
                        value: inputValue,
                        onChange: event => {
                            this.setState({ inputValue: event.target.value });
                            },
                        required: required,
                        error: error,
                    }}
                    renderInputComponent={this.renderInputComponent}
                    suggestions={suggestions}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    renderSuggestion={this.renderSuggestion}
                    getSuggestionValue={this.getSuggestionValue}
                    theme={{
                        suggestionsList: classes.suggestionsList,
                    }}
                    onSuggestionSelected={this.onSuggestionSelected}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                />
                <Dialog
                    open={dialogAddOpen}
                    onClose={this.handleCloseAddDialog(false)}
                >
                    <DialogTitle>{t("Add partner")}</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    id="name"
                                    label={t("Name")}
                                    value={newPartner.name}
                                    fullWidth
                                    required
                                    onChange={this.handleChangeName}
                                    error={!newPartner.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label={t("Number")}
                                    value={newPartner.num}
                                    fullWidth
                                    required
                                    onChange={this.handleChangeNum}
                                    error={!newPartner.num}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseAddDialog(true)} disabled={!newPartner.name} color="primary">OK</Button>
                        <Button onClick={this.handleCloseAddDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
