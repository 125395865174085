import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getSummaryReportPeriodNew, navLinks} from "../../services/moneyFlow";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem, withStyles} from "@material-ui/core";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import {getParlours} from "../../services/organization";
import DataTable from "../../components/data-table/DataTable";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import ActionButton from "../../components/button/ActionButton";
import {dataTableGroupValueStrategy, formatMoney, fromToDates, getFalseResult} from "../../services/common";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import _ from "lodash";
import XLSX from 'xlsx';
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";

const styles = {
    filterControl: {
        marginBottom: 15
    },
};

const evotorIco = <img src="/img/evotor.ico" alt="evotor" height="16" style={{paddingRight: 3}}/>;

const sumIncomingsGroupStrategy = (rawKey) => (value, row) => {
    value = value || {};
    for (let currency in row[rawKey]) {
        if (!row[rawKey].hasOwnProperty(currency)) {
            continue;
        }

        if (!value[currency]) {
            value[currency] = [0,0];
        }
        let values = (row[rawKey][currency] + '').indexOf('/') !== -1 ? row[rawKey][currency].split('/') : [row[rawKey][currency], row[rawKey][currency]];
        value[currency][0] = value[currency][0] + parseFloat(values[0]);
        value[currency][1] = value[currency][1] + parseFloat(values[1]);
    }
    return value;
};

const processIncomingParentValue = key => (value, t, row) => {
    let result = [];
    for (let currency in value) {
        if (!value.hasOwnProperty(currency)) {
            continue;
        }
        result.push(<span key={currency} style={{whiteSpace: 'nowrap'}}>{formatMoney({val: value[currency][0], cur: currency})}  ({formatMoney({val: value[currency][1], cur: currency})})<br/></span>);
    }
    if (row.evotor && row.evotor[key]) {
        result.push(<span key="evotor" style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}>{evotorIco} {formatMoney(row.evotor[key])}</span>);
    }
    return result;
};

const processIncomingValue = key => (item, t, row) => {
    let result = [];
    for (let currency in item) {
        if (!item.hasOwnProperty(currency)) {
            continue;
        }
        result.push(<span key={currency} style={{whiteSpace: 'nowrap'}}>{item[currency]} <br/></span>);
    }

    if (row.evotor && row.evotor[key]) {
        result.push(<span key="evotor" style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}>{evotorIco} {formatMoney(row.evotor[key])}</span>);
    }

    return result;
};

const processBalanceValue = (item) => {
    let result = [];
    for (let currency in item) {
        if (!item.hasOwnProperty(currency)) {
            continue;
        }
        result.push(<span key={currency} style={{whiteSpace: 'nowrap'}}>{item[currency]} <br/></span>);
    }

    return result;
};

const processGuestsData = (guestKey) => (item, t) => {
    let result = [];
    result.push(<span key={'total'} style={{whiteSpace: 'nowrap'}}>{t('Total money')}: { guestKey === 'streetGuests' ? item.money : item.money.total} <br/></span>);
    result.push(<span key={'avg'} style={{whiteSpace: 'nowrap'}}>{t('Average check')}: {guestKey === 'streetGuests' ? item.avg : item.avg.total} <br/></span>);
    result.push(<span key={'person'} style={{whiteSpace: 'nowrap'}}>{t('Total person')}: {guestKey === 'streetGuests' ? item.guests : item.guests.total} <br/></span>);

    return result;
};

const processGuestsDataXlsx = (guestKey) => (item, t) => {
    let result = [];
    result.push(t('Total money') + ': ' + (guestKey === 'streetGuests' ? item.money : item.money.total));
    result.push(t('Average check') + ': '+ (guestKey === 'streetGuests' ? item.avg : item.avg.total));
    result.push(t('Total person') + ': ' + (guestKey === 'streetGuests' ? item.guests : item.guests.total));

    return result.join(" \n");
};

const guestsDataStrategy = (guestKey) => (value, row) => {
    value = value || {
        avgSum: 0,
        count: 0,
    };
    value.count++;
    value.avgSum += parseFloat( guestKey === 'streetGuests' ? row[guestKey].avg : row[guestKey].avg.total);
    return value;
};

const processGuestsDataParent = (value) => {
    return 'Средний чек визита: ' + (value.avgSum / value.count).toFixed(2);
};

let columnData = [
    {id:'day', label: 'Date', dateFormat: 'DD.MM.YYYY', groupStrategy: dataTableGroupValueStrategy.fromTo('day'), parentProcessValue: fromToDates('DD.MM.YYYY') },
    {id:'businessUnitName', label: 'Business unit', groupStrategy: dataTableGroupValueStrategy.same('businessUnitName') },
    {id:'admin', label: 'Administrators', multiline: true,
        processValue: (item) => item.map((administrator, index) => <div key={index}>  {administrator.name} </div>),
        groupStrategy: (value, row) => {
        value = value || [];
        row.admin.forEach((admin) => {
            value.push(admin.name);
        });
        return value;
        },
        parentProcessValue: (value) => {
            value = _.uniq(value);
            return value.join("\n");
        }
    },
    {id: 'cash', label: 'Incoming cash',
        processValue: processIncomingValue('cash'),
        groupStrategy: sumIncomingsGroupStrategy('cashRaw'),
        parentProcessValue: processIncomingParentValue('cash')
    },
    {id: 'cashless', label: 'Incoming cashless',
        processValue: processIncomingValue('cashless'),
        groupStrategy: sumIncomingsGroupStrategy('cashlessRaw'),
        parentProcessValue: processIncomingParentValue('cashless')
    },
    {id: 'total', label: 'Received',
        processValue: processIncomingValue('total'),
        groupStrategy: sumIncomingsGroupStrategy('totalRaw'),
        parentProcessValue: processIncomingParentValue('total')
    },
    {id: 'cash_box', label: 'In cash box', processValue: processBalanceValue, groupStrategy: () => '-'},
    {id: 'safe', label: 'In safe', processValue: processBalanceValue, groupStrategy: () => '-'},
    {id: 'mastersWorkLoad', label: 'Masters work load (unfree/free/total)', multiline: true, processValue: (item) =>
                item.map((master, index) =>
                <span key={index} style={{whiteSpace: 'nowrap'}}>
                    {`${master.name} - ${master.unfree} / ${master.free} / ${master.total}`}
                    <br/>
                </span>),
        groupStrategy: (value, row) => {
            value = value || {
                avgSum: 0,
                avgSumUnFree: 0,
                avgSumFree: 0,
                count: 0,
                sum: 0,
                free: 0,
                unFree: 0
            };
            value.count++;
            if (!row.mastersWorkLoad.length) {
                return value;
            }
            let raw = row.mastersWorkLoad.find( (row) => row.name === 'Загрузка на мастера' );
            value.avgSum += raw.total;
            value.avgSumUnFree += raw.unfree;
            value.avgSumFree += raw.free;
            raw = row.mastersWorkLoad.find( (row) => row.name === 'Всего' );
            value.sum += raw.total;
            value.free += raw.free;
            value.unFree += raw.unfree;

            return value;
        },
        parentProcessValue: (value) => {
            let avg = (value.avgSum/value.count).toFixed(1);
            let avgSumUnFree = (value.avgSumUnFree/value.count).toFixed(1);
            let avgSumFree = (value.avgSumFree/value.count).toFixed(1);
            let sum = value.sum.toFixed(1);
            let free = value.free.toFixed(1);
            let unFree = value.unFree.toFixed(1);
            return `Загрузка на мастера - ${avgSumUnFree} / ${avgSumFree} / ${avg}  \n Суммарная загрузка - ${unFree} / ${free} / ${sum}`;
        }
    },
    {id: 'parlourGuests', label: 'Guests salon', processValue: processGuestsData('parlourGuests'), groupStrategy: guestsDataStrategy('parlourGuests'), parentProcessValue: processGuestsDataParent },
    {id: 'streetGuests', label: 'Guests street', processValue: processGuestsData('streetGuests'), groupStrategy: guestsDataStrategy('streetGuests'), parentProcessValue: processGuestsDataParent },
    {id: 'masterHourAvgFormatted', label: 'Avg hour cost PG/All',
        groupStrategy: (value,row) => {
            value = value || {
                valPG: 0,
                countPG: 0,
                val: 0,
                count: 0,
            };
            if (row.masterHourAvg) {
                value.count++;
                value.val += row.masterHourAvg || 0;
            }
            if (row.masterHourAvgPG) {
                value.countPG++;
                value.valPG += row.masterHourAvgPG || 0;
            }
            return value;
        }, parentProcessValue: (value) => {
            let all = (value.val/value.count).toFixed(2);
            let pg = (value.valPG/value.countPG).toFixed(2);
            return `${pg} / ${all}`;
        }
    }
];

const columnDataXlsx = [
    { id: 'day', label: 'Date', dateFormat: 'DD.MM.YYYY' },
    { id: 'businessUnitName', label: 'Business unit' },
    { id: 'admin', label: 'Administrators' , processValue: (item) => item.map(administrator => administrator.name).join(', ') },
    { id: 'cashRawSystem', label: 'Incoming cash system', multiCurrency: true },
    { id: 'cashRawReal', label: 'Incoming cash real', multiCurrency: true },
    { id: 'cashlessRawSystem', label: 'Incoming cashless system', multiCurrency: true },
    { id: 'cashlessRawReal', label: 'Incoming cashless real', multiCurrency: true },
    { id: 'totalRawSystem', label: 'Received system', multiCurrency: true },
    { id: 'totalRawReal', label: 'Received real', multiCurrency: true },
    { id: 'cash_box', label: 'In cash box', multiCurrency: true },
    { id: 'safe', label: 'In safe', multiCurrency: true },
    { id: 'mastersWorkLoad', label: 'Masters work load (unfree/free/total)', processValue: (item) => item.map(master => `${master.name}: ${master.unfree} / ${master.free} / ${master.total}`).join("\n") },
    { id: 'parlourGuests', label: 'Guests salon', processValue: processGuestsDataXlsx('parlourGuests') },
    { id: 'streetGuests', label: 'Guests street', processValue: processGuestsDataXlsx('streetGuests') },
    { id: 'masterHourAvgFormatted', label: 'Avg hour cost PG/All' },
    { id: 'mastersWorkLoadTotal', label: 'Загрузка плат', processValue: value => value.unfree },
    { id: 'mastersWorkLoadTotal', label: 'Загрузка б/плат', processValue: value => value.free },
    { id: 'mastersWorkLoadTotal', label: 'Загрузка всего', processValue: value => value.total  },
    { id: 'masterHourAvgPG', label: 'Средняя стоимость часа ГС' },
    { id: 'masterHourAvg', label: 'Средняя стоимость часа все' },
];

export default
@withTranslation()
@withStyles(styles)
@connect(
    ()=>({}),
    {
        showError: infoActions.showError,
        showMessage: messageDialogActions.show,
    }
)

class SummaryReportPeriodNew extends React.Component {
    evotorGroupStrategy = (value, row) => {
        if (row.evotor) {
            value = value || {};
            Object.keys(row.evotor).forEach(key => {
                value[key] = dataTableGroupValueStrategy.moneySum(key)(value[key], row.evotor);
            })
        }
        return value;
    }

    hiddenColumns = [
        { id: 'evotor', groupStrategy: this.evotorGroupStrategy },
    ]

    constructor(props) {
        super(props);

        const querySearch = getFilterFromQuery();

        let businessUnitIds = [];
        let dayStart = moment().startOf('week');
        let dayEnd = moment();

        if (querySearch) {
            if (querySearch.dayStart) {
                dayStart = moment(querySearch.dayStart);
            }

            if (querySearch.dayEnd) {
                dayEnd = moment(querySearch.dayEnd);
            }

            if (querySearch.businessUnitIds) {
                businessUnitIds = querySearch.businessUnitIds;
            }
        }

        this.state = {
            search:{
                dayStart,
                dayEnd,
                businessUnitIds,
            },
            businessUnits: [],
            refreshTable: false,
        }
    }

    render() {
        const {search, businessUnits, refreshTable} = this.state;
        const {t, classes} = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField2
                        valueFrom={search.dayStart}
                        valueTo={search.dayEnd}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('dayStart')}
                        onChangeTo={this.handleChange('dayEnd')}
                        className={classes.filterControl}
                    />
                    <AnyField
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        label="Салоны"
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>---Не выбрано---</span>;
                                }

                                return businessUnits.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                            },
                        }}
                        className={classes.filterControl}
                        fullWidth
                    >
                        {businessUnits.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={search.businessUnitIds.indexOf(item.id) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </AnyField>
                    <ActionButton
                        onClick={this.handleClickSelect}
                        disabled={!search.dayStart || !search.dayEnd || !search.businessUnitIds.length}
                    >
                        {t('Select')}
                    </ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Summary report for period')}>
                <DataTable
                    columnData={columnData}
                    dataFunc={this.getSummaryReportPeriod}
                    selector={false}
                    refresh={refreshTable}
                    pagination={false}
                    disableSorting
                    groupBy="businessUnitName"
                    groupHiddenColumns={this.hiddenColumns}
                    autoload={false}
                />

                </Content>
            </React.Fragment>
        );
    }

    componentDidMount() {
        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        search: {
                            ...this.state.search,
                        },
                        businessUnits: [
                            { id: 0, name: '---Не выбрано---'},
                            ...response.data
                        ],
                    });
                }
            });
    }

    getSummaryReportPeriod = () => {
        const { search } = this.state;

        if (search.dayStart && search.dayEnd && search.businessUnitIds.length) {
            return getSummaryReportPeriodNew(search.dayStart.format('YYYY-MM-DD'), search.dayEnd.format('YYYY-MM-DD'), search.businessUnitIds);
        } else {
            return getFalseResult();
        }
    }

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    }

    handleClickSelect = () => {
        const { search } = this.state;

        if(!search.dayStart || !search.dayEnd || search.dayStart.isAfter(search.dayEnd)) {
            this.props.showError('Неверный интервал дат');
        }

        pushFilterToQuery(processMomentFieldInObject(search, ['dayStart', 'dayEnd']));

        this.setState({
            refreshTable: !this.state.refreshTable
        });
    }

    handleExport = () => {
        const { t } = this.props;

        this.getSummaryReportPeriod()
            .then(response => {
                if (response.success) {
                    const multiCurrencyColumns = [];
                    columnDataXlsx.forEach((column, columnIdx) => {
                        if (column.multiCurrency) {
                            multiCurrencyColumns.push({ column, columnIdx, currencies: [] });
                        }
                    });

                    if (multiCurrencyColumns.length) {
                        response.data.forEach(row => {
                            multiCurrencyColumns.forEach(mCColumn => {
                                const value = _.get(row, mCColumn.column.id);
                                if (value && _.isObject(value)) {
                                    const valueKeys = Object.keys(value);
                                    valueKeys.forEach(key => {
                                        if (!mCColumn.currencies.find(currency => currency === key)) {
                                            mCColumn.currencies.push(key);
                                        }
                                    });
                                }
                            });
                        });

                        multiCurrencyColumns.forEach(mCColumn => {
                            mCColumn.currencies.forEach((currency, currencyIdx) => {
                               if (currencyIdx === 0) {
                                   mCColumn.column.currency = currency;
                               } else {
                                   columnDataXlsx.splice(mCColumn.columnIdx + 1, 0, {
                                       ...mCColumn.column,
                                       currency,
                                   });

                                   //Обновляем индексы
                                   multiCurrencyColumns.forEach(c => {
                                       if (c.columnIdx > mCColumn.columnIdx) {
                                           c.columnIdx++;
                                       }
                                   });
                               }
                            });
                        });
                    }

                    const data = [
                        //First row - headers
                        columnDataXlsx.map(item =>
                            t(item.label) +
                            (item.multiCurrency && item.currency !== undefined ? ', ' + item.currency : '')
                        ),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXlsx.map(item => {
                                let value = _.get(row, item.id);

                                if (item.dateFormat) {
                                    value = moment(value).format(item.dateFormat);
                                }

                                if (item.processValue) {
                                    value = item.processValue(value, t);
                                }

                                if (item.multiCurrency && item.currency !== undefined ) {
                                    value = value[item.currency];
                                }

                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Salary");
                    XLSX.writeFile(new_workbook, `SummaryReport.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };
}