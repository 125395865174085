const masterWorkload = (value, row) => {
    value = value || [];
    if (row.mastersWorkLoad && row.mastersWorkLoad.length) {
        row.mastersWorkLoad.forEach(wl => {
            const found = value.find(item => item.name === wl.name);
            if (found) {
                found.total += wl.total;
                found.unfree += wl.unfree;
                found.free += wl.free;
                found.count++;
            } else {
                value.push({ ...wl, count: 1 });
            }
        })
    }
    return value;
};

export default masterWorkload;
