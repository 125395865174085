import React from 'react';
import {FormControl, InputLabel, Slider, withStyles} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import classNames from "classnames";
import {getLabelTransform} from "../../styles";
import _ from "lodash";

const styles = theme => ({
    root: {
        display: 'block',
    },
    withLabel: {
        marginTop: theme.spacing(3),
    },
    withLabel2Lines: {
        marginTop: theme.spacing(5),
    },
    label2Lines: {
        '&.shrink': {
            transform: getLabelTransform(-35),
        },
    },
});

export default @withStyles(styles)
class DurationSliderField extends React.PureComponent {
    static propTypes = {
        valueFrom: PropTypes.number,
        valueTo: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        label: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        label2Lines: PropTypes.bool,
        nameFrom: PropTypes.string,
        nameTo: PropTypes.string,
    };

    static defaultProps = {
        min: 0,
        max: 100,
        step: 10,
        disabled: false,
        required: false,
    };

    getMarks() {
        return _.range(this.props.min, this.props.max, this.props.step).map((num, i) => {
            return {
                value: num,
                label: num,
            };
        });
    }

    render() {
        const {
            classes,
        } = this.props;

        const marks = this.getMarks();

        return (
            <FormControl
                className={classNames(classes.root, {[classes.withLabel]: this.props.label}, this.props.className)}
                disabled={this.props.disabled}
            >
                {this.props.label ?
                    <InputLabel shrink disabled={this.props.disabled} required={this.props.required} variant="outlined"
                                classes={{
                                    root: this.props.label2Lines ? classes.label2Lines : null,
                                    shrink: 'shrink'
                                }}>{this.props.label}</InputLabel> : null}
                <Slider
                    value={[this.props.valueFrom, this.props.valueTo]}
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    marks={marks}
                    onChange={this.props.onChange}
                    disableSwap
                    valueLabelDisplay="auto"></Slider>
            </FormControl>
        );
    }
}
