import React from 'react';
import {
    MenuItem,
    Grid,
    Menu,
} from '@material-ui/core';
import {connect} from "react-redux";
import {
    closeVisit, completeVisit, confirmVisit,
    createVisit,
    navLinks, provideVisit, removeCreatedVisit,
    visitStatuses
} from "../../services/calendar";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import infoActions from "../../components/info/info-actions";
import ReviewDialog from "./ReviewDialog";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import MedicalCardDialog from "./MedicalCardDialog";
import {hasRole} from "../../services/user";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import CalendarLeftFields from "./CalendarLeftFields";
import VisitCancelDialog from "./VisitCancelDialog";
import ScheduleVisitsMulti from "./ScheduleVisitsMulti";
import ScheduleLegend from "./ScheduleLegend";
import calendarActions from "./calendar-actions";
import moment from "moment";

const POPUP_CONFIRM = 'confirm';
const POPUP_COME = 'come';
const POPUP_BEGIN = 'begin';
const POPUP_COMPLETE = 'complete';
const POPUP_CANCEL = 'cancel';
const POPUP_CLOSE = 'close';
const POPUP_EDIT = 'edit';
const POPUP_REVIEW = 'review';
const POPUP_DELETE = 'delete';

export default
@withTranslation()
@withRouter
@connect(state => ({
    user: state.auth.user,
    columns: state.calendar.multiColumns,
    currentDate: state.calendar.currentDate,
    currentParlour: state.common.currentParlour,
}), {
    showMessage: messageDialogActions.show,
    showError: infoActions.showError,
    showInfo: infoActions.show,
    showConfirm: confirmDialogActions.show,
    setColumns: calendarActions.setMultiColumns,
})
class CalendarMultiPage extends React.PureComponent {

    constructor(props) {
        super(props);

        if (props.columns && props.columns.length === 0) {
            const timeZone = props.currentParlour ? props.currentParlour.timeZone : '+04:00';
            const date = (props.currentDate ? props.currentDate.clone() : moment()).utcOffset(timeZone).startOf('day');
            props.setColumns([{
                parlour: props.currentParlour,
                parlourId: props.currentParlour ? props.currentParlour.id : null,
                date: date,
                dateEnd: date.clone().endOf('day'),
            }]);
        }
    }

    state = {
        selectedParlour: null,
        selectedVisit: null,
        selectedMoment: null,
        selectedMaster: {
            id: null,
            name: null
        },
        visitAnchorEl: null,
        dialogReviewOpen: false,
        openMedicalCardDialogVisitId: false,
        openCancelDialog: false,
    };

    handleVisitClick = visit => {
        this.setState({
            selectedVisit: visit,
            selectedMoment: null,
            selectedMaster: null,
            selectedParlour: null,
        });
    };

    handleVisitContextMenu = (visit, event) => {
        event.preventDefault();

        this.setState({
            selectedVisit: visit,
            visitAnchorEl: event.currentTarget,
        });
    };

    handleVisitDbClick = visit => {
        this.setState({selectedVisit: visit, selectedMoment: null, selectedMaster: null}, this.handleEditVisit);
    };

    handleSlotClick = (value, master, parlour) => {
        this.setState({selectedMoment: value, selectedMaster: master, selectedParlour: parlour, selectedVisit: null});
    };

    handleSlotDbClick = (value, master, parlour) => {
        this.setState({
            selectedMoment: value,
            selectedMaster: master,
            selectedParlour: parlour,
            selectedVisit: null,
        }, this.handleAddVisit);
    };

    handleAddVisit = () => {
        const { selectedParlour, selectedMoment, selectedMaster } = this.state;

        if (selectedParlour && selectedMoment && selectedMaster) {
            createVisit({
                parlour: selectedParlour,
                visitFacility: [{
                    start: selectedMoment,
                    master: [selectedMaster],
                }]
            })
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/orders/edit/${response.data.id}`);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        }
    };

    handleEditVisit = () => {
        this.props.history.push(`/orders/view/${this.state.selectedVisit.id}`);
    };

    handlePopupMenuClose = key => () => {
        const { selectedVisit } = this.state;
        const { t } = this.props;

        this.setState({
            visitAnchorEl: null,
        });

        switch (key) {
            case POPUP_CONFIRM:
                confirmVisit(selectedVisit.id)
                    .then(response => {
                        if (!response.success) {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;
            case POPUP_COME:
                this.setState({
                    openMedicalCardDialogVisitId: selectedVisit.id
                });

                break;

            case POPUP_BEGIN:
                provideVisit(selectedVisit.id)
                    .then(response => {
                        if (!response.success) {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_COMPLETE:
                completeVisit(selectedVisit.id)
                    .then(response => {
                        if (!response.success) {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_CANCEL:
                this.setState({
                    openCancelDialog: true
                });
                break;

            case POPUP_CLOSE:
                closeVisit(selectedVisit.id)
                    .then(response => {
                        if (!response.success) {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_REVIEW:
                this.setState({
                    dialogReviewOpen: true,
                });
                break;

            case POPUP_EDIT:
                this.handleEditVisit();
                break;

            case POPUP_DELETE:
                this.props.showConfirm({
                    message: <span>{t('Do you really want to delete a visit')}?<br/>{t('The visit will be permanently deleted')}.<br/>{t('This operation cannot be undone')}!</span>,
                    title: <span>{t('Delete visit')}</span>,
                    onClose: this.handleCloseRemoveVisit
                });
                break;

            default:
                break;
        }
    };

    handleCloseRemoveVisit = ok => {
        if (ok) {
            const { t } = this.props;

            removeCreatedVisit(this.state.selectedVisit.id)
                .then(response => {
                    if (response.success) {
                        this.props.showInfo(t('Visit deleted') + '!');
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleCloseReviewDialog = () => {
        this.setState({
            dialogReviewOpen: false,
        });
    };

    closeMedicalCardDialog = () => {
        this.setState({
            openMedicalCardDialogVisitId: false
        });
    };

    render() {
        const { t, columns } = this.props;
        const {
            selectedMoment,
            selectedVisit,
            selectedMaster,
            visitAnchorEl,
            dialogReviewOpen,
            openMedicalCardDialogVisitId,
            openCancelDialog,
        } = this.state;

        const hasRiskyStatus = columns.some(column => column.parlour && column.parlour.hasRiskyStatus);

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>} hidePrint>
                    <CalendarLeftFields
                        selectedMoment={selectedMoment}
                        selectedMaster={selectedMaster}
                        selectedVisit={selectedVisit}
                    />
                    <br />
                    <br />
                    <ActionButton disabled={!selectedMoment || !selectedMaster } visible={!selectedVisit} onClick={this.handleAddVisit}>{t("Add visit")}</ActionButton>
                    <ActionButton visible={Boolean(selectedVisit)} onClick={this.handleEditVisit}>{t("Edit visit")}</ActionButton>
                </LeftBar>
                <Content title={t("Schedule")} hidePrintTitle>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ScheduleLegend hasRiskyStatus={hasRiskyStatus}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ScheduleVisitsMulti
                                onSlotClick={this.handleSlotClick}
                                onSlotDbClick={this.handleSlotDbClick}

                                selectedVisit={selectedVisit}

                                onVisitClick={this.handleVisitClick}
                                onVisitDbClick={this.handleVisitDbClick}
                                onVisitContextMenu={this.handleVisitContextMenu}

                                onHeaderClick={this.handleHeaderClick}
                            />
                        </Grid>
                    </Grid>
                </Content>
                <Menu
                    id="visit-popup-menu"
                    anchorEl={visitAnchorEl}
                    open={Boolean(visitAnchorEl)}
                    onClose={this.handlePopupMenuClose(null)}
                >
                    {this.renderVisitMenuItem(t("Confirm"), POPUP_CONFIRM, [visitStatuses.created, visitStatuses.risky], Boolean(selectedVisit && selectedVisit.parlour && selectedVisit.parlour.hasRiskyStatus))}
                    {this.renderVisitMenuItem(t("Guest came"), POPUP_COME, [visitStatuses.confirmed, visitStatuses.risky, visitStatuses.created], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderVisitMenuItem(t("Start of service"), POPUP_BEGIN, [visitStatuses.starting], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderVisitMenuItem(t("End of service"), POPUP_COMPLETE, [visitStatuses.providing], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderVisitMenuItem(t("Close"), POPUP_CLOSE, [visitStatuses.created, visitStatuses.confirmed, visitStatuses.starting, visitStatuses.providing, visitStatuses.complete], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))/*TODO: Временно разрешено*/}
                    {this.renderVisitMenuItem(t("Cancel"), POPUP_CANCEL, [visitStatuses.confirmed, visitStatuses.created, visitStatuses.risky])}
                    {this.renderVisitMenuItem(t("View"), POPUP_EDIT, Object.keys(visitStatuses))}
                    {this.renderVisitMenuItem(t("Leave feedback"), POPUP_REVIEW, [visitStatuses.closed])}
                    {this.renderVisitMenuItem(t("Delete"), POPUP_DELETE, [visitStatuses.created, visitStatuses.confirmed, visitStatuses.starting, visitStatuses.providing, visitStatuses.complete], 'ROLE_SCHEDULE_VISIT_REMOVE')}
                </Menu>
                <ReviewDialog
                    open={dialogReviewOpen}
                    visitId={selectedVisit ? selectedVisit.id : null}
                    onClose={this.handleCloseReviewDialog}
                />
                { openMedicalCardDialogVisitId && <MedicalCardDialog open={!!openMedicalCardDialogVisitId} visitId={openMedicalCardDialogVisitId} onClose={this.closeMedicalCardDialog} startVisit={true}/> }
                { openCancelDialog && <VisitCancelDialog onClose={() => { this.setState({ openCancelDialog: false }) }} open={openCancelDialog} visitId={selectedVisit.id} />}
            </React.Fragment>
        );
    }

    renderVisitMenuItem(title, key, statuses = [], roleOrCondition = true)
    {
        const { selectedVisit } = this.state;
        let enabled = roleOrCondition;

        if (typeof roleOrCondition === 'string' || roleOrCondition instanceof String) {
            enabled = hasRole(roleOrCondition);
        }

        if ( selectedVisit && statuses.indexOf(selectedVisit.status) >= 0 && enabled ) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }
}
