import React from "react";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    activityTypes,
    activityTypeNames,
    activityTypeSplit,
    navLinks,
    clearActivities,
} from "../../services/calendarEmployee";
import Content from "../../components/content/Content";
import {
    Grid,
    InputAdornment,
    MenuItem,
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Fab,
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import {
    getEmployeesForWorksheet,
    getParlours,
} from "../../services/organization";
import {
    getActivityTypeColor,
    getActivityTypeText,
    activityTypeClear,
    activityTypeEdit,
    getEvents,
    eventCreate,
} from "../../services/calendarEmployee";
import Timesheets from "./Timesheets";
import { connect } from "react-redux";
import calendarEmployeeActions from "./calendarEmployee-actions";
import ActionButton from "../../components/button/ActionButton";
import ClearIcon from "@material-ui/icons/Clear";
import School from "@material-ui/icons/School";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import Typography from "@material-ui/core/es/Typography/Typography";
import commonActions from "../../common-actions";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import GavelIcon from "@material-ui/icons/Gavel";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import SatelliteIcon from "@material-ui/icons/Satellite";
import FlashAutoIcon from "@material-ui/icons/FlashAuto";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { withTranslation } from "react-i18next";
import moment from "moment";
import DateField from "../../components/fields/DateField";
import TimeField from "../../components/fields/TimeField";
import CheckboxField from "../../components/fields/CheckboxField";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import { createChangeHandle } from "../../utils/helpers";
import cn from "classnames";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import DateTimeField2 from "../../components/fields/DateTimeField2";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import { hasRole } from "../../services/user";
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import ReportCardExportExcelDialog from "./ReportCardExportExcelDialog";
import {
    workingHours,
    workingHoursType,
} from "../../entities/enums/workingHours";

export default
@connect(
    state => ({
        currentDate: state.calendarEmployee.currentDate,
        parlour: state.common.currentParlour,
    }),
    {
        setCurrentDate: calendarEmployeeActions.setCurrentDate,
        setCurrentParlour: commonActions.setCurrentParlour,
        showMessage: messageDialogActions.show,
    }
)
@withStyles({
    selectEvent: {
        marginTop: 5,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    itemActivity: {
        position: "relative",
    },
    buttonDel: {
        position: "absolute",
        top: 6,
        right: -41,
        width: 35,
        height: 35,
    },
    itemTitle: {
        color: "rgba(0, 0, 0, 0.54)",
    },
    blockActivityIcon: {
        padding: "3px 5px",
        borderRadius: "5px",
        cursor: "pointer",
        backgroundColor: "#ddd",
        border: "2px solid transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.8,
    },
    blockActivityIconSelected: {
        borderColor: "#333",
        opacity: 1,
    },
})
@withTranslation()
class ReportCard extends React.Component {
    state = {
        parlours: [],
        employees: [],
        processedEmployees: {},
        activityType: null,
        dialogEventAdd: false,
        events: [],
        eventSelected: {},
        newEvent: {
            name: null,
            activities: [],
        },
        periodNewActivities: {
            employee: null,
            from: moment().startOf("date"),
            to: moment().startOf("date"),
            activity: null,
            timeStart: null,
            timeEnd: null,
            weekends: [],
            workingDay: null,
            weekend: null,
        },
        approved: false,
        activityApproved: [],
        replaceMode: false,
        dialogFillSchedule: false,
        weekDays: [],
        tabs: 0,
        toggleFillSchedule: false,
        dialogClearSchedule: false,
        clearData: {
            employee: null,
            from: moment(),
            to: moment(),
        },
        refreshSheet: false,
        showEmployeesFromAllBusinessUnits: false,
        parlour: null,
        employeeFilter: "",
        progressDialogOpen: false,
        isExcelExportDialogOpen: false,
        workingHour: workingHoursType.fulltime,
    };

    handleExportExcelDialogOpen = () => {
        this.setState({ isExcelExportDialogOpen: true });
    };

    render() {
        const { currentDate, classes, t } = this.props;
        const { parlour, employeeFilter } = this.state;
        const {
            parlours,
            replaceMode,
            periodNewActivities,
            processedEmployees,
            toggleFillSchedule,
            employee,
            activityType,
            eventSelected,
            events,
            approved,
            saveApproved,
            refreshSheet,
            showEmployeesFromAllBusinessUnits,
            isExcelExportDialogOpen,
            workingHour,
        } = this.state;
        let processedEmployee = null;
        let numberShift =
            parlour && parlour.workShift && Array.isArray(parlour.workShift)
                ? parlour.workShift.length
                : null;

        if (employee) {
            processedEmployee = {};
            processedEmployee[employee.employeePost] = [employee];
        }

        const currDate = currentDate.clone().startOf("month");

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} />}>
                    {approved ? (
                        <React.Fragment>
                            <ActionButton onClick={this.handleSaveApproved}>
                                Сохранить
                            </ActionButton>
                            <ActionButton
                                onClick={this.handleApprovedActivityCancel}
                            >
                                Отмена
                            </ActionButton>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <ActionButton
                                onClick={this.handleApprovedActivitySuccess}
                            >
                                Подтвердить расписание
                            </ActionButton>
                            <ActionButton onClick={this.handleEventAdd}>
                                Добавить событие
                            </ActionButton>
                            <ActionButton onClick={this.handleFillSchedule}>
                                {t("CalendarEmployee.fillSchedule")}
                            </ActionButton>
                            <ActionButton onClick={this.handleClearSchedule}>
                                {t("CalendarEmployee.clearSchedule")}
                            </ActionButton>
                            <ActionButton
                                onClick={this.handleExportExcelDialogOpen}
                                visible={hasRole(
                                    "ROLE_CALENDAR_EMPLOYEE_PARLOUR_REPORT_CARD"
                                )}
                            >
                                {t("Export to Excel")}
                            </ActionButton>
                        </React.Fragment>
                    )}

                    <br />
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AnyField
                                name="month"
                                value={currDate?.clone().format("M")}
                                select
                                onChange={this.handleOnChangeDate}
                                label="Месяц"
                                required
                                fullWidth
                            >
                                {Array.apply(0, Array(12)).map((_, i) => (
                                    <MenuItem
                                        key={i}
                                        value={currDate
                                            ?.clone()
                                            .month(i)
                                            .format("M")}
                                    >
                                        {currDate
                                            ?.clone()
                                            .month(i)
                                            .format("MMMM")}
                                    </MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                name="year"
                                value={currDate?.clone().format("Y")}
                                select
                                onChange={this.handleOnChangeDate}
                                label="Год"
                                required
                                fullWidth
                            >
                                {Array.apply(0, Array(100)).map((_, i) => (
                                    <MenuItem
                                        key={i}
                                        value={currDate
                                            ?.clone()
                                            .year(2000 + i)
                                            .format("Y")}
                                    >
                                        {currDate
                                            ?.clone()
                                            .year(2000 + i)
                                            .format("Y")}
                                    </MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                    </Grid>
                </LeftBar>
                <Content title="Персонал">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <AnyField
                                value={employeeFilter}
                                onChange={this.handleChangeEmployeeFilter}
                                fullWidth
                                name={"employeeFilter"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            Поиск сотрудника
                                        </InputAdornment>
                                    ),
                                }}
                            ></AnyField>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <AnyField
                                value={parlour ? parlour.id : null}
                                onChange={this.handleChangeParlour}
                                select
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            Салон
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={showEmployeesFromAllBusinessUnits}
                            >
                                {parlours.map(item => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item>
                            <CheckboxField
                                value={showEmployeesFromAllBusinessUnits}
                                onChange={
                                    this
                                        .handleChangeShowEmployeesFromAllBusinessUnits
                                }
                                name="showEmployeesFromAllBusinessUnits"
                                label={t("Show employees of all BU")}
                                margin="none"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                {Object.keys(activityTypes).map(status =>
                                    this.renderToolButton(
                                        "CalendarEmployee." + status,
                                        status,
                                        getActivityTypeColor(status)
                                    )
                                )}
                                {this.renderToolButton(
                                    "CalendarEmployee.SplitTime",
                                    activityTypeSplit
                                )}
                                {this.renderToolButton(
                                    "CalendarEmployee.Edit",
                                    activityTypeEdit
                                )}
                                {this.renderToolButton(
                                    "Clear",
                                    activityTypeClear
                                )}
                                <Grid item>
                                    <CheckboxField
                                        value={replaceMode}
                                        onChange={this.handleChange}
                                        name="replaceMode"
                                        label={t("Replace mode")}
                                        margin="none"
                                        disabled={
                                            showEmployeesFromAllBusinessUnits
                                        }
                                    />
                                    {[activityTypes.working, activityTypes.internship].includes(activityType ?? '') ? (
                                        <AnyField
                                            value={workingHour}
                                            onChange={this.handleChange}
                                            name="workingHour"
                                            select
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        График
                                                    </InputAdornment>
                                                ),
                                            }}
                                        >
                                            {workingHours.map((event, id) => (
                                                <MenuItem
                                                    key={id}
                                                    value={event.id}
                                                >
                                                    {event.name}
                                                </MenuItem>
                                            ))}
                                        </AnyField>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <AnyField
                                value={eventSelected && eventSelected.id}
                                onChange={this.handleSelectEvent}
                                select
                                fullWidth
                                className={classes.selectEvent}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            Событие
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                <MenuItem value="clear">
                                    Удалить событие
                                </MenuItem>
                                {events.map((event, id) => (
                                    <MenuItem key={id} value={event.id}>
                                        {event.name}
                                    </MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Timesheets
                                currentDate={currentDate}
                                employees={
                                    processedEmployee
                                        ? processedEmployee
                                        : processedEmployees
                                }
                                employeeFilter={employeeFilter}
                                parlour={parlour}
                                activityType={activityType}
                                eventSelected={eventSelected}
                                numberShift={numberShift}
                                approved={approved}
                                saveApproved={saveApproved}
                                clearApprove={this.clearApprove}
                                replaceMode={replaceMode}
                                periodNewActivities={periodNewActivities}
                                toggleFillSchedule={toggleFillSchedule}
                                isAdmin
                                refresh={refreshSheet}
                                workingHour={workingHour}
                            />
                        </Grid>
                    </Grid>
                </Content>
                {this.renderAddDialog()}
                {this.renderFillDialog()}
                {this.renderClearDialog()}
                <ReportCardExportExcelDialog
                    open={isExcelExportDialogOpen}
                    businessUnits={parlours}
                    onClose={() =>
                        this.setState({ isExcelExportDialogOpen: false })
                    }
                />
                <ProgressDialog open={this.state.progressDialogOpen} />
            </React.Fragment>
        );
    }

    handleChangeTabs = (event, value) => {
        this.setState({
            tabs: value,
        });
    };

    getWeekDays = () => {
        let currentDate = this.props.currentDate;
        let weekDay = currentDate.startOf("week");
        let weekDays = [
            { id: weekDay.isoWeekday(), name: weekDay.format("dd") },
        ];

        for (let i = 0; i < 6; i++) {
            weekDay.add(1, "day");
            weekDays.push({
                id: weekDay.isoWeekday(),
                name: weekDay.format("dd"),
            });
        }

        this.setState({
            weekDays: weekDays,
        });
    };

    handleChangePeriodWeekend = numberDay => () => {
        let weekends = this.state.periodNewActivities.weekends;

        if (weekends.indexOf(numberDay) === -1) {
            weekends.push(numberDay);
        } else {
            weekends = weekends.filter(day => day !== numberDay);
        }

        this.setState({
            periodNewActivities: {
                ...this.state.periodNewActivities,
                weekends: weekends,
                weekend: null,
                workingDay: null,
            },
        });
    };

    handleFillSchedule = () => {
        this.setState({
            dialogFillSchedule: true,
        });
    };

    handleActionFillSchedule = ok => () => {
        this.setState({
            dialogFillSchedule: false,
        });

        if (ok && !this.checkDataFillReport()) {
            this.setState({
                toggleFillSchedule: !this.state.toggleFillSchedule,
            });
        }
    };

    handleChange = createChangeHandle(this);

    handleChangePeriod = props => event => {
        let value = {};

        if (["workingDay", "weekend"].indexOf(props) !== -1) {
            if (!/^[1-7]?$/.test(event.target.value)) {
                return;
            }

            value[props] = event.target.value;
            value["weekends"] = [];
        } else {
            value[props] = event.target.value;
        }

        this.setState({
            periodNewActivities: {
                ...this.state.periodNewActivities,
                ...value,
            },
        });
    };

    componentDidMount() {
        this.setState({
            parlour: this.props.parlour,
        });

        getParlours().then(response => {
            if (response.success) {
                const parlour = response.data.length ? response.data[0] : null;

                this.setState({
                    parlours: response.data,
                });

                if (parlour && !this.props.parlour) {
                    this.props.setCurrentParlour(parlour);
                }
            }
        });

        getEvents().then(response => {
            if (response.success) {
                this.setState({
                    events: response.data,
                });
            }
        });

        this.getWeekDays();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevState.parlour !== this.state.parlour ||
            prevProps.currentDate !== this.props.currentDate
        ) {
            this.loadParams();
        }
    }

    loadParams() {
        const { currentDate } = this.props;
        const { parlour, showEmployeesFromAllBusinessUnits } = this.state;

        if ((parlour && parlour.id) || showEmployeesFromAllBusinessUnits) {
            if (showEmployeesFromAllBusinessUnits) {
                this.setState({
                    progressDialogOpen: true,
                });
            }

            getEmployeesForWorksheet({
                parlour: parlour ? parlour.id : null,
                combineAdministrators: true,
                startDate: moment(currentDate)
                    .clone()
                    .startOf("month")
                    .toISOString(),
                endDate: moment(currentDate)
                    .clone()
                    .endOf("month")
                    .toISOString(),
            }).then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                        processedEmployees: _.groupBy(
                            response.data,
                            employee => employee.employeePost
                        ),
                        progressDialogOpen: false,
                    });
                }
            });
        }
    }

    handleDateChange = date => {
        this.props.setCurrentDate(date);
    };

    handleChangeParlour = event => {
        const parlourId = event.target.value;
        const { parlours } = this.state;

        this.setState({
            employeeFilter: "",
            parlour: parlours.find(item => item.id === parlourId),
        });

        this.props.setCurrentParlour(
            parlours.length
                ? parlours.find(item => item.id === parlourId)
                : null
        );
    };

    handleSelectEvent = event => {
        const { events } = this.state;
        let eventSelected = {};

        if (event.target.value !== null) {
            eventSelected =
                event.target.value === "clear"
                    ? { id: "clear" }
                    : events.find(
                          eventItem => eventItem.id === event.target.value
                      );
        }

        this.setState({
            eventSelected: eventSelected,
        });
    };

    handleChangeEmployee = event => {
        const { employees } = this.state;
        const employeeId = event.target.value;

        this.setState({
            employee: employees.length
                ? employees.find(item => item.id === employeeId)
                : null,
        });
    };

    handleChangeEmployeeFilter = event => {
        this.handleChange(event);
    };

    handleChangeActivityType = activityType => () => {
        if (activityTypes.transfer === activityType) {
            this.props.showMessage('Данная активность используется только для технического мастера, необходимого для переноса визитов. Не используйте её для обычных мастеров!');
        }

        this.setState({
            activityType:
                this.state.activityType !== activityType ? activityType : null,
        });
    };

    handleActionDialog = ok => () => {
        this.setState({
            dialogEventAdd: false,
        });

        if (ok) {
            let { newEvent } = this.state;

            newEvent.activities.forEach((activity, id) => {
                [null, undefined].indexOf(activity) !== -1 &&
                    delete newEvent.activities[`${id}`];
            });

            eventCreate(newEvent).then(response => {
                if (response.success) {
                    let { events } = this.state;
                    events.unshift(response.data);

                    this.setState({
                        newEvent: {
                            name: null,
                            activities: [],
                        },
                        events: events,
                    });
                }
            });
        }
    };

    handleEventAdd = () => {
        this.setState({
            dialogEventAdd: true,
        });
    };

    handleChangeNewEventName = event => {
        this.setState({
            newEvent: {
                ...this.state.newEvent,
                name: event.target.value,
            },
        });
    };

    handleOnChangeDate = e => {
        const { props } = this;

        this.props.setCurrentDate(
            e.target.name === "month"
                ? props?.currentDate?.clone().month(e.target.value - 1)
                : props?.currentDate?.clone().year(e.target.value)
        );
    };

    handleChangeNewActivity = idActivity => event => {
        let { newEvent } = this.state;

        if (idActivity !== undefined) {
            if (event.target.value !== null) {
                newEvent.activities[`${idActivity}`] = event.target.value;
            } else {
                let activities = [];

                newEvent.activities.forEach((activity, id) => {
                    id !== idActivity && activities.push(activity);
                });

                newEvent.activities = [...activities];
            }
        } else {
            if (event.target.value !== null) {
                newEvent.activities.push(event.target.value);
            }
        }

        this.setState({
            newEvent: newEvent,
        });
    };

    handleDeleteNewActivity = idActivity => () => {
        let { newEvent } = this.state;

        if ([null, undefined].indexOf(idActivity) === -1) {
            let activities = [];
            newEvent.activities.forEach((activity, id) => {
                id !== idActivity && activities.push(activity);
            });

            this.setState({
                newEvent: {
                    ...newEvent,
                    activities: activities,
                },
            });
        }
    };

    handleApprovedActivityCancel = () => {
        this.setState({
            approved: false,
            eventSelected: {},
            activityType: null,
        });
    };

    handleApprovedActivitySuccess = () => {
        this.setState({
            approved: true,
            eventSelected: {},
            activityType: null,
        });
    };

    handleSaveApproved = () => {
        this.setState({
            saveApproved: true,
        });
    };

    clearApprove = () => {
        this.setState({
            approved: false,
            saveApproved: false,
        });
    };

    getToolButtonIcon = activity => {
        const { parlour } = this.state;
        const color = parlour ? "inherit" : "disabled";

        switch (activity) {
            case activityTypes.working:
                return <GavelIcon color={color} />;
            case activityTypes.waiting:
                return <HourglassFullIcon color={color} />;
            case activityTypes.break:
                return <FreeBreakfastIcon color={color} />;
            case activityTypes.leave:
                return <SatelliteIcon color={color} />;
            case activityTypes.overtime:
                return <FlashAutoIcon color={color} />;
            case activityTypes.sick_leave:
                return <LocalHospitalIcon color={color} />;
            case activityTypes.internship:
                return <School color={color} />;
            case activityTypeSplit:
                return <QueryBuilderIcon color={color} />;
            case activityTypeEdit:
                return <EditIcon color={color} />;
            case activityTypeClear:
                return <DeleteForeverIcon color={color} />;
            case activityTypes.transfer:
                return <TransferWithinAStation color={color} />;
            default:
                return <span> </span>;
        }
    };

    renderToolButton(title, value, color = null) {
        const { t, classes } = this.props;
        const { activityType } = this.state;
        const style = {};
        if (color) {
            style.backgroundColor = getActivityTypeColor(value);
        }

        return (
            <Grid item key={value}>
                <Tooltip title={t(title)}>
                    <div
                        className={cn(classes.blockActivityIcon, {
                            [classes.blockActivityIconSelected]:
                                activityType === value,
                        })}
                        onClick={this.handleChangeActivityType(value)}
                        style={style}
                    >
                        {this.getToolButtonIcon(value)}
                    </div>
                </Tooltip>
            </Grid>
        );
    }

    checkTimeFillReport = () => {
        const { periodNewActivities } = this.state;
        let response = false;

        if (periodNewActivities.timeEnd && periodNewActivities.timeStart) {
            response = periodNewActivities.timeStart.isSameOrAfter(
                periodNewActivities.timeEnd
            );
        }

        return response;
    };

    checkDateFillReport = () => {
        const { periodNewActivities } = this.state;
        let response = false;

        if (periodNewActivities.to && periodNewActivities.from) {
            response = periodNewActivities.to.isBefore(
                periodNewActivities.from
            );
        }

        return response;
    };

    checkDataFillReport = () => {
        const { periodNewActivities } = this.state;
        let response = false;

        if (
            !periodNewActivities.employee ||
            !periodNewActivities.activity ||
            !periodNewActivities.from ||
            !periodNewActivities.to ||
            !periodNewActivities.timeStart ||
            !periodNewActivities.timeEnd
        ) {
            response = true;
        }

        return response;
    };

    renderAddDialog() {
        const { classes } = this.props;
        const { dialogEventAdd, newEvent } = this.state;

        return (
            <Dialog open={dialogEventAdd}>
                <DialogTitle>Добавление нового события</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Наименование события"
                                value={newEvent.name}
                                required
                                fullWidth
                                onChange={this.handleChangeNewEventName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className={classes.itemTitle}
                            >
                                Запретить
                            </Typography>
                        </Grid>
                        {newEvent.activities &&
                        Array.isArray(newEvent.activities) &&
                        newEvent.activities.length
                            ? newEvent.activities.map(
                                  (activity, idActivity) => (
                                      <Grid
                                          item
                                          xs={10}
                                          key={idActivity}
                                          className={classes.itemActivity}
                                      >
                                          <AnyField
                                              value={activity}
                                              onChange={this.handleChangeNewActivity(
                                                  idActivity
                                              )}
                                              fullWidth
                                              select
                                          >
                                              <MenuItem value={null}>
                                                  Не выбрано
                                              </MenuItem>
                                              {Object.keys(activityTypes).map(
                                                  (activity, id) => (
                                                      <MenuItem
                                                          key={id}
                                                          value={activity}
                                                      >
                                                          {getActivityTypeText(
                                                              activity
                                                          )}
                                                      </MenuItem>
                                                  )
                                              )}
                                          </AnyField>
                                          <Fab
                                              size="small"
                                              color="secondary"
                                              className={classes.buttonDel}
                                              onClick={this.handleDeleteNewActivity(
                                                  idActivity
                                              )}
                                          >
                                              <ClearIcon
                                                  className={
                                                      classes.delButtonIcon
                                                  }
                                              />
                                          </Fab>
                                      </Grid>
                                  )
                              )
                            : null}
                        <Grid item xs={10}>
                            <AnyField
                                value=""
                                fullWidth
                                select
                                onChange={this.handleChangeNewActivity()}
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                {Object.keys(activityTypes).map(
                                    (activity, id) => (
                                        <MenuItem key={id} value={activity}>
                                            {getActivityTypeText(activity)}
                                        </MenuItem>
                                    )
                                )}
                            </AnyField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleActionDialog(true)}
                        color="primary"
                    >
                        Сохранить
                    </Button>
                    <Button
                        onClick={this.handleActionDialog(false)}
                        color="primary"
                    >
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderFillDialog() {
        const { t, classes } = this.props;
        const {
            dialogFillSchedule,
            periodNewActivities,
            employees,
            tabs,
            weekDays,
        } = this.state;

        return (
            <Dialog open={dialogFillSchedule}>
                <DialogTitle>
                    {t("CalendarEmployee.fillingReportCard")}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AnyField
                                id="employee"
                                label={t("Employee")}
                                value={periodNewActivities.employee}
                                fullWidth
                                required
                                select
                                onChange={this.handleChangePeriod("employee")}
                            >
                                {employees.map(employee => (
                                    <MenuItem
                                        key={employee.id}
                                        value={employee.id}
                                    >
                                        {employee.name}
                                    </MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="activity"
                                label={t("Activity")}
                                value={periodNewActivities.activity}
                                fullWidth
                                required
                                select
                                onChange={this.handleChangePeriod("activity")}
                            >
                                {[
                                    activityTypes.working,
                                    activityTypes.leave,
                                    activityTypes.sick_leave,
                                    activityTypes.waiting,
                                    activityTypes.transfer,
                                ].map(activityType => (
                                    <MenuItem
                                        key={activityType}
                                        value={activityType}
                                    >
                                        {activityTypeNames[activityType]}
                                    </MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <DateField
                                label={t("Interval start")}
                                value={periodNewActivities.from}
                                onChange={time =>
                                    this.setState({
                                        periodNewActivities: {
                                            ...this.state.periodNewActivities,
                                            from: moment(time).startOf("date"),
                                        },
                                    })
                                }
                                fullWidth
                                required
                                error={this.checkDateFillReport()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateField
                                label={t("Interval end")}
                                value={periodNewActivities.to}
                                onChange={time =>
                                    this.setState({
                                        periodNewActivities: {
                                            ...this.state.periodNewActivities,
                                            to: moment(time).startOf("date"),
                                        },
                                    })
                                }
                                fullWidth
                                required
                                error={this.checkDateFillReport()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimeField
                                label={t("Start")}
                                value={periodNewActivities.timeStart}
                                onChange={time =>
                                    this.setState({
                                        periodNewActivities: {
                                            ...this.state.periodNewActivities,
                                            timeStart:
                                                moment(time).startOf("minute"),
                                        },
                                    })
                                }
                                fullWidth
                                minutesStep={15}
                                required
                                error={this.checkTimeFillReport()}
                                autoOk
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimeField
                                label={t("Ending")}
                                value={periodNewActivities.timeEnd}
                                onChange={time =>
                                    this.setState({
                                        periodNewActivities: {
                                            ...this.state.periodNewActivities,
                                            timeEnd:
                                                moment(time).startOf("minute"),
                                        },
                                    })
                                }
                                fullWidth
                                minutesStep={15}
                                required
                                error={this.checkTimeFillReport()}
                                autoOk
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper square>
                                <Tabs
                                    value={tabs}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={this.handleChangeTabs}
                                    aria-label="disabled tabs example"
                                >
                                    <Tab label={t("Working week")} />
                                    <Tab label={t("Work shift")} />
                                </Tabs>
                            </Paper>
                        </Grid>
                        {tabs === 0 ? (
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.itemTitle}
                                >
                                    {t("Select output")}
                                </Typography>
                                {weekDays.map(day => (
                                    <CheckboxField
                                        key={day.id}
                                        label={day.name}
                                        value={
                                            periodNewActivities.weekends.indexOf(
                                                day.id
                                            ) !== -1
                                        }
                                        onChange={this.handleChangePeriodWeekend(
                                            day.id
                                        )}
                                        margin="none"
                                    />
                                ))}
                            </Grid>
                        ) : null}
                        {tabs === 1 ? (
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <AnyField
                                        label={t(
                                            "CalendarEmployee.NumberOfWorkingDays"
                                        )}
                                        value={periodNewActivities.workingDay}
                                        onChange={this.handleChangePeriod(
                                            "workingDay"
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        label={t(
                                            "CalendarEmployee.NumberOfDaysOff"
                                        )}
                                        value={periodNewActivities.weekend}
                                        onChange={this.handleChangePeriod(
                                            "weekend"
                                        )}
                                    />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleActionFillSchedule(true)}
                        color="primary"
                        disabled={this.checkDataFillReport()}
                    >
                        {t("CalendarEmployee.Complete")}
                    </Button>
                    <Button
                        onClick={this.handleActionFillSchedule(false)}
                        color="primary"
                    >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleClearSchedule = () => {
        this.setState({
            dialogClearSchedule: true,
            clearData: {
                employee: null,
                from: moment(this.props.currentDate),
                to: moment(this.props.currentDate).add(7, "days"),
            },
        });
    };

    handleChangeClear = createChangeHandle(this, "clearData");

    handleCloseClearCancel = () => {
        this.setState({
            dialogClearSchedule: false,
        });
    };

    handleCloseClearSuccess = () => {
        const { clearData } = this.state;
        clearActivities(clearData.employee, clearData.from, clearData.to).then(
            response => {
                if (response.success) {
                    this.setState({
                        dialogClearSchedule: false,
                        refreshSheet: !this.state.refreshSheet,
                    });
                } else {
                    this.props.showMessage(
                        response.error
                            ? response.error.message
                            : response.message
                    );
                }
            }
        );
    };

    renderClearDialog() {
        const { t } = this.props;
        const { dialogClearSchedule, employees, clearData } = this.state;

        const validDates =
            clearData.to &&
            clearData.from &&
            clearData.to.isAfter(clearData.from);

        return (
            <Dialog
                open={dialogClearSchedule}
                onClose={this.handleCloseClearCancel}
            >
                <DialogTitle>
                    {t("CalendarEmployee.clearReportCard")}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectFromItemsField
                                value={clearData.employee}
                                name="employee"
                                items={employees}
                                label={t("Employee")}
                                fullWidth
                                required
                                onChange={this.handleChangeClear}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimeField2
                                label={t("Interval start")}
                                value={clearData.from}
                                name="from"
                                onChange={this.handleChangeClear}
                                fullWidth
                                required
                                error={!validDates}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimeField2
                                label={t("Interval end")}
                                value={clearData.to}
                                name="to"
                                onChange={this.handleChangeClear}
                                fullWidth
                                required
                                error={!validDates}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleCloseClearSuccess}
                        color="primary"
                        disabled={!validDates || !clearData.employee}
                    >
                        {t("CalendarEmployee.Clear")}
                    </Button>
                    <Button
                        onClick={this.handleCloseClearCancel}
                        color="primary"
                    >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChangeShowEmployeesFromAllBusinessUnits = event => {
        const { parlour } = this.props;

        this.setState({
            showEmployeesFromAllBusinessUnits: event.target.value,
            parlour: event.target.value ? null : parlour,
            progressDialogOpen: true,
        });
    };
}
