import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Grid,
    Button,
    InputAdornment,
    IconButton,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress, List, ListItem, ListItemText, ListItemSecondaryAction
} from '@material-ui/core';
import {
    register,
    getDeliveryPayDoc,
    getPayDoc,
    getPayDocSubtypes,
    payDocStatuses,
    sale,
    resale,
    update,
    activate,
    navLinks,
    lock,
    createRequest,
    requestTypes,
    completeSale,
    checkRemovePayDoc,
    removePayDoc,
    cancelPayDocSale, unfreezeRest, saveAdditionalGuests, getPayDocByNum, moveBalanceRequest,
    sendSertificateByEmail, getPayDocRefillsByPayDocId, extractBitrixDataFromQuery, getPayDocsByParent, getPayDocsByMain
} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import History from "@material-ui/icons/History";
import {createGuest, getGuest, getGuestByBitrixId, verifyGuestName} from "../../services/guests";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import SymbolRub from "../../components/typography/SymbolRub";
import moment from "moment";
import {getPartners, hasRole} from "../../services/user";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import DateField from "../../components/fields/DateField";
import AnyField from "../../components/fields/AnyField";
import GuestField from "../../components/fields/GuestField";
import {withRouter} from "react-router";
import {emptyMoney} from "../../services/common";
import {getDiscounts} from "../../services/calendar";
import Pay from "../../components/pay/Pay";
import {withTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import ForwardIcon from "@material-ui/icons/Forward";
import SaveIcon from "@material-ui/icons/Save";
import {Link} from "react-router-dom";
import infoActions from "../../components/info/info-actions";
import {
    getParlours,
    getParloursForResale,
    getRegionsForCert,
} from "../../services/organization";
import PayDocRefillDialog from "./PayDocRefillDialog";
import CheckboxField from "../../components/fields/CheckboxField";
import NumberFormatBalance from "../../components/fields/NumberFormatBalance";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import PayDocDeliveryDialog from "./PayDocDeliveryDialog";
import {getSale, salePayTypes} from "../../services/sale";
import UnfreezeIcon from '@material-ui/icons/SettingsBackupRestore';
import * as cn from "classnames";
import NumberFormatDecimal from "../../components/fields/NumberFormatDecimal";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import _ from "lodash";
import MoneyBackDialog from "./MoneyBackDialog";
import {kkmService} from "../../services/kkm";
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import GuestNameVerificationDialog from "../../components/dialogs/GuestNameVerificationDialog";
import {
    getGeneralProperty
} from "../../services/settings";
import DataTable from "../../components/data-table/DataTable";
import {askPinRuleType} from "../../entities/enums/askPinRule";

const emptySaleData = {
    subtypeId: null,
    phone: null,
    birthDate: null,
    name: null,
    guestId: null,
    partnerId: null,
    nominal: null,
    nominalComment: null,
    discountId: null,
    rechargeType: 0,
    amountCash: {...emptyMoney},
    amountNonCash: {...emptyMoney},
    bitrixId: null,
    bitrixUserId: null,
    isResale: false,
    saleId : null,
    parlourResaleId: null,
}

export default @withRouter
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showConfirm: confirmDialogActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles({
    container: {
        width: 700,
    },
    wrongParlour: {
        color: 'red',
    },
    parlourHistory: {
        position: 'absolute',
        right: 30
    },
    vCenterCell: {
        display: 'flex',
        alignItems: 'center'
    }
})
class ViewPage extends React.Component {

    state = {
        payDoc: {
            id: null,
            num: null,
            type: {
                id: null,
                hasSubtype: false,
                isPaper: false,
                isBarter: false,
                availableInBusinessUnits: [],
                pin: [],
            },
            canResale: false,
            parentPayDoc: null,
            parentPayDocId: null,
            mainPayDoc: null,
            mainPayDocId: null,
            subtypeId: null,
            statusId: null,
            status: null,
            pin: null,
            guestId: null,
            phone: null,
            fio: null,
            balance: null,
            loadedBalance: null,
            validity: null,
            canRefill: false,
            parlourId: null,
            parlour: null,
            nominal: null,
            nominalComment: null,
            saleId: null,
            saleStatus: null,
            discountId: null,
            businessUnitId: null,
            comment: null,
            removePayDoc: false,
            frozen: null,
            burned: null,
            externalSubjectId: null,
            externalSubjectName: null,
            additionalGuests: [],
            additionalGuestsPin: null,
            guestEmail: null,
            receiptName: null,
            bitrixId: null,
            utmSource: null,
            utm: null,
            haveChildren: false,
            haveMinor: false,
            isResold: false
        },
        subtypes: [],
        partners: [],
        dialogDeliveryGuest: false,
        dialogSaleOpen: false,
        dialogSaleLoading: false,
        saleData: emptySaleData,
        dialogPayStatusOpen: false,
        dialogRechargeOpen: false,
        rechargeBySite: false,
        activateData: {
            callPhone: null,
            guestId: null,
            changing: false,
            pin: null,
        },
        sendSertificateData: {
            guestEmail: null,
            regionId: null
        },
        dialogActivateOpen: false,
        dialogActivateTitle: 'Activation of a payment document',
        dialogSendSertificateByMailOpen: false,
        dialogSendSertificateByMailResult: {},
        dialogLockOpen: false,
        lockData: {
            reason: null
        },
        dialogRenewalOpen: false,
        renewalData: {
            reason: null,
            newValidity: null
        },
        discounts: [],
        parlours: [],
        parloursForResale: [],
        parlourChanging: false,
        notFound: false,
        deliveryPayDoc: {},
        dialogMoneyBackOpen: false,
        moveBalance: {
            dialogOpen: false,
            toNum: null,
            error: null,
            toPD: null,
            toNumLoading: false
        },
        canPrintReceipt: true,
        isBitrix24Data: false,
        bitrixId: null,
        bitrixUserId: null,
        bitrixRequest: {
            loading: false,
            result: null
        },
        nameVerifyingData: {
            dialogOpen: false,
            guestName: '',
            guestLastName: '',
            gender: '',
            nameVerifyCallback: null
        },
        settings: {},
        regionsForCert: [],
        visitParlourPrice: null
    };
    columnData = [
        { id: 'num', numeric: false, disablePadding: false, label: 'payDoc.certificateNumber'},
        { id: 'balance', numeric: true, disablePadding: false, label: 'Balance' },
    ];

    componentDidMount() {
        const {t} = this.props;
        const PDId = this.props.match.params.id;

        if (PDId) {
            this.loadDoc(PDId);


            if (hasRole('ROLE_PAYDOC_PAYDOC_REMOVE')) {
                checkRemovePayDoc(PDId)
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                payDoc: {
                                    ...this.state.payDoc,
                                    removePayDoc: response.data.removePayDoc
                                }
                            }, () => {

                                let search = this.props.location.search;

                                if (search.indexOf('bitrix') !== -1 && this.state.payDoc.statusId === "for_sale") {
                                    // get param 'bitrix_id' => 'bitrixId'
                                    let bitrixData = extractBitrixDataFromQuery(search);

                                    if (bitrixData.bitrixId && bitrixData.bitrixUserId) {
                                        this.setState({
                                            isBitrix24Data: true,
                                            bitrixId: bitrixData.bitrixId,
                                            bitrixUserId: bitrixData.bitrixUserId,
                                            saleData: {
                                                ...this.state.saleData,
                                                bitrixId: bitrixData.bitrixId,
                                                bitrixUserId: bitrixData.bitrixUserId,
                                            }
                                        })

                                        // autofill guest field in sale action
                                        this.setState({
                                            bitrixRequest: {
                                                ...this.state.bitrixRequest,
                                                loading: true,
                                            }
                                        }, () => {
                                            getGuestByBitrixId(bitrixData.bitrixUserId)
                                                .then((response) => {
                                                    if (response.success) {
                                                        this.setState({
                                                            payDoc: {
                                                                ...this.state.payDoc,
                                                                guestId: response.data.id,
                                                                guestFullName: response.data.fullName
                                                            },
                                                            bitrixRequest: {
                                                                loading: false,
                                                                result: true
                                                            }
                                                        })
                                                        this.getGuestFio()
                                                    } else {
                                                        this.setState({
                                                            bitrixRequest: {
                                                                loading: false,
                                                                result: false
                                                            }
                                                        }, () => {
                                                            this.props.showInfo((t('Could not find guest with bitrix id') + ': ' + bitrixData.bitrixUserId))
                                                        });
                                                    }
                                                })
                                        })
                                    }

                                }


                            })
                        }
                    })
            }
        }
        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    }, this.setVisitParlourPrice);
                }
            })

        getParloursForResale()
            .then(response=>{
                if(response.success)
                this.setState({
                    parloursForResale: response.data,
                })

            })


        getGeneralProperty('guestNameVerificationEnable').then(response => {
            if (response.success) {
                this.setState({
                    settings: {
                        ...this.state.settings,
                        "guestNameVerificationEnable": response.data,
                    }
                })
            }
        })

        getRegionsForCert().then(response => {
            if (response.success) {
                this.setState({
                    regionsForCert: response.data
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDoc(this.props.match.params.id)
        }
    }


    checkOpenRefillDialog = () => {
        let {dialogRechargeOpen} = this.state;

        if (this.props.location.hash === '#refillDialog' && !dialogRechargeOpen) {
            this.setState({
                dialogRechargeOpen: true,
            });
        }
    };

    loadDoc = (id) => {
        getPayDoc(id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDoc: response.data
                    }, () => {
                        if (response.data.statusId === payDocStatuses.delivery_guest) {
                            this.loadDeliveryPayDoc();
                        } else {
                            this.setState({
                                deliveryPayDoc: {}
                            })
                        }

                        this.checkOpenRefillDialog();
                    }, this.setVisitParlourPrice)
                } else {
                    if (response.error && response.error.code === 404) {
                        this.setState({
                            notFound: true,
                        });
                    }
                }
            });
    };

    getChildrenTableData = () => {
        const {payDoc} = this.state;
        return getPayDocsByParent(payDoc.id);
    };

    getMinorTableData = () => {
        const {payDoc} = this.state;
        return getPayDocsByMain(payDoc.id);
    };

    tableLinkTemplate = (item) => {
        return `/pay-docs/view/${item.id}`;
    }

    loadDocWithNameVerification = (id) => {
        getPayDoc(id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDoc: response.data
                    }, () => {
                        const {showInfo, t} = this.props;
                        const {payDoc, settings} = this.state;

                        if (response.data.statusId === payDocStatuses.delivery_guest) {
                            this.loadDeliveryPayDoc();
                        } else {
                            this.setState({
                                deliveryPayDoc: {}
                            })
                        }

                        // ------- verifying guest name process --------
                        showInfo(t('Check guest name verifying status'));
                        getGuest(payDoc.guestId).then((guestResponse) => {
                            if (
                                guestResponse.success &&
                                !_.isEmpty(settings) &&
                                settings.guestNameVerificationEnable
                            ) {
                                let guest = guestResponse.data;
                                if (!guest.nameVerified) {
                                    this.setState({
                                        nameVerifyingData: {
                                            dialogOpen: true,
                                            guestName: guest.name,
                                            guestLastName: guest.lastName,
                                            gender: guest.gender,
                                            nameVerifyCallback: _.bind(() => {
                                                this.checkOpenRefillDialog()
                                            }, this),
                                        }
                                    });
                                } else {
                                    this.checkOpenRefillDialog();
                                }
                            } else {
                                this.checkOpenRefillDialog();
                            }
                        });
                    })
                } else {
                    if (response.error && response.error.code === 404) {
                        this.setState({
                            notFound: true,
                        });
                    }
                }
            });
    };

    checkStatuses = (statuses) => {
        return statuses.indexOf(this.state.payDoc.statusId) >= 0;
    };

    handleRegister = () => {
        const {payDoc} = this.state;

        register(payDoc.id, {})
            .then(response => {
                if (response.success) {
                    this.loadDoc(payDoc.id)
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeCanRefill = event => {
        const {payDoc} = this.state;

        update(payDoc.id, {canRefill: event.target.value})
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data});
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeComment = event => {
        const {payDoc} = this.state;

        this.setState({
            payDoc: {
                ...payDoc,
                comment: event.target.value
            }
        })
    };

    handleSaveComment = () => {
        const {payDoc} = this.state;

        update(payDoc.id, {comment: payDoc.comment})
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data});
                    this.props.showInfo('Комментарий сохранен.');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };


    handleSale = () => {
        const {payDoc, discounts} = this.state;

        this.setState({
            dialogSaleOpen: true,
            dialogSaleLoading: payDoc.type.hasSubtype || payDoc.type.isBarter,
            saleData: {
                ...this.state.saleData,
                subtypeId: null,
                phone: null,
                birthDate: null,
                name: null,
                guestId: payDoc.guestId,
                partnerId: null,
                nominal: null,
                nominalComment: null,
                discountId: payDoc.discountId,
                rechargeType: 0,
                amountCash: {...emptyMoney},
                amountNonCash: {...emptyMoney},
            }
        });

        this.sale(payDoc, discounts);
    };

    sale(payDoc, discounts) {

        if (payDoc.type.hasSubtype) {
            getPayDocSubtypes(payDoc.type.id)
                .then(response => {
                    this.setState({dialogSaleLoading: false});
                    if (response.success) {
                        this.setState({subtypes: response.data});
                    }
                })
        }

        if (payDoc.type.isBarter) {
            getPartners()
                .then(response => {
                    this.setState({dialogSaleLoading: false});
                    if (response.success) {
                        this.setState({partners: response.data});
                    }
                })
        }

        if (!discounts.length) {
            getDiscounts({
                forParlour: payDoc.parlourId,
                forSalableEntity: 'PayDoc'
            })
                .then(response => {
                    if (response.success) {
                        let newState = {
                            discounts: response.data,
                        };

                        let discount = response.data.find(discount => discount.defaultSelected === true);

                        if (this.state.payDoc.nominal && !this.state.saleData.discountId && discount) {
                            newState.saleData = {
                                ...this.state.saleData,
                                discountId: discount ? discount.id : null
                            };
                        }

                        this.setState(newState);
                    }
                })
        }

    }


    handleResale = () => {

        const {discounts, payDoc} = this.state;

        this.setState({
            dialogSaleOpen: true,
            dialogSaleLoading: payDoc.type.hasSubtype || payDoc.type.isBarter,
            saleData: {
                ...this.state.saleData,
                subtypeId: null,
                phone: null,
                birthDate: null,
                name: null,
                guestId:  payDoc.saleStatus !== 'closed'? payDoc.guestId : null,
                partnerId: null,
                nominal: null,
                nominalComment: null,
                discountId: payDoc.discountId,
                rechargeType: 0,
                amountCash: {...emptyMoney},
                amountNonCash: {...emptyMoney},
                isResale: true,
                parlourResaleId: payDoc.saleStatus !== 'closed' ? payDoc.businessUnitId : null,
            }

        });
        this.sale(payDoc, discounts);
    };


    handleSaleChange = prop => event => {
        const {saleData} = this.state;

        saleData[prop] = event.target.value;

        this.setState({saleData});
    };

    saleSend = (saleData) => {
        const {payDoc} = this.state;

            sale(payDoc.id, saleData)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            payDoc: response.data,
                            dialogSaleOpen: !payDoc.type.isBarter,
                            dialogPayStatusMessage: 'Ожидание ответа от кассы/платёжного термина'
                        });

                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });

    };

    resaleSend = (saleData) =>{
        const {payDoc} = this.state;

            resale(payDoc.id,saleData)
                .then(response=>{
                    if(response.success){
                        this.props.history.push("/pay-docs/view/"+ response.data.id);
                    }else {

                        this.props.showMessage(response.error ? response.error.message : response.message);

                    }
                });

            };

    completeSale = (payDoc) => {
        const {t, showInfo} = this.props;
        const {settings} = this.state;

        completeSale(payDoc)
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDoc: response.data,
                        dialogSaleOpen: false,
                    }, () => {
                        // ----- verifying guest name process ------
                        showInfo(t('Check guest name verifying status'));
                        getGuest(payDoc.guestId).then((guestResponse) => {
                            if (
                                guestResponse.success &&
                                !_.isEmpty(settings) &&
                                settings.guestNameVerificationEnable
                            ) {
                                let guest = guestResponse.data;
                                if (!guest.nameVerified) {
                                    this.setState({
                                        nameVerifyingData: {
                                            dialogOpen: true,
                                            guestName: guest.name,
                                            guestLastName: guest.lastName,
                                            gender: guest.gender,
                                            nameVerifyCallback: () => {
                                                this.props.showMessage('Платежный документ реализован');
                                            }
                                        }
                                    });
                                } else {
                                    this.props.showMessage('Платежный документ реализован');
                                }
                            } else {
                                this.props.showMessage('Платежный документ реализован');
                            }
                        });
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleCloseSaleDialog = ok => () => {
        const {saleData, payDoc} = this.state;


        if (!ok) {
            this.setState({dialogSaleOpen: false});
            return;
        }

        if (!payDoc.type.isBarter && !saleData.guestId) {
            createGuest(saleData)
                .then(response => {
                    if (response.success) {
                        saleData.guestId = response.data.id;
                        this.setState({
                            saleData
                        });
                        this.saleSend(saleData);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        } else {
            if (payDoc.saleId && payDoc.saleStatus !== 'closed') {
                this.completeSale(payDoc);
            } else if(!saleData.isResale){
                this.saleSend(saleData);
            } else{
                this.resaleSend(saleData);
            }
        }
    };
    handleCloseResaleDialog = ok=>()=>{
        const {saleData, payDoc} = this.state;
        if (!ok) {
            this.setState({dialogSaleOpen: false});
            return;
        }

        if (payDoc.saleId && payDoc.saleStatus !== 'closed') {
            this.completeSale(payDoc);
        } else {
            this.saleSend(saleData);
        }

};

    handleRecharge = () => {
        this.setState({
            dialogRechargeOpen: true,
            rechargeBySite: false
        });
    };

    handleRechargeBySite = () => {
        this.setState({
            dialogRechargeOpen: true,
            rechargeBySite: true
        });
    }

    handleCloseRechargeDialog = ok => {
        this.setState({
            dialogRechargeOpen: false,
        });

        if (this.props.location.hash === '#refillDialog') {
            this.props.history.push(`/pay-docs/view/${this.state.payDoc.id}`);
        }
        if (ok) {
            this.loadDoc(this.state.payDoc.id);
            this.setVisitParlourPrice(ok);
        }
    };

    handleActivate = () => {
        this.setState({
            dialogActivateOpen: true,
            dialogActivateTitle: 'Activation of a payment document',
            activateData: {
                ...this.state.activateData,
                callPhone: this.state.payDoc.phone,
                changing: false,
                guestId: null,
                pin: null,
            }
        });
    };

    handleSendSerificateByEmail = () => {
        const {parlours, payDoc, regionsForCert} = this.state;

        const businessUnitPayDoc = parlours.find((parlour) => {
            return parlour.id === payDoc.parlourId;
        });

        const regionForCert = regionsForCert.find((region) => {
            return region.id === businessUnitPayDoc.regionId;
        });

        this.setState({
            dialogSendSertificateByMailOpen: true,
            sendSertificateData: {
                guestEmail: (this.state.payDoc.guestEmail) ? this.state.payDoc.guestEmail : null,
                regionId: regionForCert ? regionForCert.id : null
            },
            dialogSendSertificateByMailResult: {}
        });
    };

    handleCloseSertificateByMail = ok => () => {
        if (!ok) {
            this.setState({dialogSendSertificateByMailOpen: false});
            return;
        }

        sendSertificateByEmail(
            this.state.payDoc.id,
            this.state.sendSertificateData.guestEmail,
            this.state.sendSertificateData.regionId
            ).then(response => {
                if (response.success) {
                    let _this = this;

                    this.setState({
                        payDoc: {
                            ...this.state.payDoc,
                            guestEmail: this.state.sendSertificateData.guestEmail
                        },
                        dialogSendSertificateByMailResult: {success: true},
                    }, () => {
                        setTimeout(() => {
                            _this.setState({
                                dialogSendSertificateByMailOpen: false
                            });
                        }, 2000)
                    });
                } else {
                    let _this = this;

                    this.setState({
                        dialogSendSertificateByMailResult: {fail: true},
                    }, () => {
                        setTimeout(() => {
                            _this.setState({
                                dialogSendSertificateByMailOpen: false
                            });
                        }, 2000)
                    });
                }
            });
    };

    /* Возврат денежных средств */

    handleMoneyBack = () => {
        this.setState({
            dialogMoneyBackOpen: true,
        });
    };

    handleCloseMoneyBack = ok => {
        if (!ok) {
            this.setState({dialogMoneyBackOpen: false});
        } else {
            // reload payDoc data
            if (ok) {
                this.loadDoc(this.state.payDoc.id);
            }

            this.setState({dialogMoneyBackOpen: false});
        }


    }

    /* END Возврат денежных средств END */

    handleGuestEmailForSertificateChange = prop => event => {
        this.setState({
            sendSertificateData: {
                [prop]: event.target.value,
                regionId: this.state.sendSertificateData.regionId ?? null
            }
        });
    };

    handleChangeGuest = () => {
        this.setState({
            dialogActivateOpen: true,
            dialogActivateTitle: 'Guest change',
            activateData: {
                ...this.state.activateData,
                callPhone: this.state.payDoc.phone,
                changing: true,
                guestId: null,
                pin: null,
            }
        });
    };

    handleActivateChange = prop => event => {
        this.setState({
            activateData: {
                ...this.state.activateData,
                [prop]: event.target.value,
            }
        });
    };

    handleAdditionalGuestsPinChange = event => {
        const {payDoc} = this.state;

        this.setState({
            payDoc: {
                ...payDoc,
                additionalGuestsPin: event.target.value
            }
        })
    };

    handleCloseActivateDialog = ok => () => {
        if (!ok) {
            this.setState({dialogActivateOpen: false});
            return;
        }

        const {t} = this.props;

        if (!this.state.activateData.guestId) {
            const {fio, phone} = this.state.payDoc;
            let message = t('Activate on a guest') + ' ' + fio + ' ' + phone

            this.props.showConfirm({
                message: message,
                title: t("Activation confirmation"),
                onClose: this.activatingCertificate
            });
        } else {
            this.activatingCertificate(true);
        }
    };

    activatingCertificate = (ok) => {
        if (!ok) {
            return;
        }

        const {payDoc, activateData} = this.state;

        activate(payDoc.id, activateData.guestId, activateData.pin)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogActivateOpen: false,
                        payDoc: response.data,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleLock = () => {
        this.setState({
            dialogLockOpen: true,
            lockData: {
                reason: null,
            }
        })
    };

    handleLockChange = prop => event => {
        const {lockData} = this.state;

        this.setState({
            lockData: {...lockData, [prop]: event.target.value}
        })
    };

    handleCloseLockDialog = ok => () => {
        const {payDoc, lockData} = this.state;

        if (!ok) {
            this.setState({
                dialogLockOpen: false,
            });
            return;
        }

        lock(payDoc.id, lockData.reason)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogLockOpen: false,
                        payDoc: response.data,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleRenewal = () => {
        this.setState({
            dialogRenewalOpen: true,
            renewalData: {
                reason: null,
                newValidity: moment(this.state.payDoc.validity).add(7, 'days')
            }
        })
    };

    handleRenewalChange = prop => event => {
        const {renewalData} = this.state;

        this.setState({
            renewalData: {...renewalData, [prop]: event.target.value},
        })
    };

    handleRenewalChangeDate = prop => date => {
        const {renewalData} = this.state;

        this.setState({
            renewalData: {...renewalData, [prop]: date},
        })
    };

    handleCloseRenewalDialog = ok => () => {
        const {payDoc, renewalData} = this.state;

        if (!ok) {
            this.setState({
                dialogRenewalOpen: false,
            });
            return;
        }

        createRequest([payDoc.id], requestTypes.renewal, renewalData)
            .then(response => {
                this.setState({
                    progress: false
                });
                if (response.success) {
                    if (response.data.id === payDoc.id) {
                        this.setState({
                            payDoc: response.data,
                            dialogRenewalOpen: false,
                        });
                        this.props.showInfo('Срок действия продлён');
                    } else {
                        this.props.history.push(`/pay-docs/requests/view/${response.data.id}`);
                    }
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeParlour = event => {
        const {payDoc} = this.state;

        if (!payDoc.id) {
            return;
        }

        this.setState({
            parlourChanging: true,
        });

        update(payDoc.id, {parlour: event.target.value})
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data, parlourChanging: false});
                    this.props.showInfo('Салон изменен.');
                } else {
                    this.setState({parlourChanging: false});
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };
    handleChangeParlourResale = event => {
        const {payDoc,saleData} = this.state;

        if (!payDoc.id) {
            return;
        }

        this.setState({
            saleData: {
                ...saleData,
            parlourResaleId: event.target.value
        }
        })


    };

    handleChangeBalance = event => {
        const {payDoc} = this.state;

        this.setState({
            payDoc: {
                ...payDoc,
                balance: event.target.value
            }
        })
    };

    handleChangeMod = event => {
        const {payDoc} = this.state;

        this.setState({
            payDoc: {
                ...payDoc,
                mod: event.target.value
            }
        })
    };

    handleChangeRegionsForCert = event => {
        this.setState({
            sendSertificateData: {
                guestEmail: this.state.sendSertificateData.guestEmail ?? null,
                regionId: event.target.value
            },
        });
    };

    handleSaveBalance = () => {
        const {payDoc} = this.state;

        update(payDoc.id, {balance: payDoc.balance})
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data});
                    this.props.showInfo('Новый баланс сохранен.');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleSaveMod = () => {
        const {payDoc} = this.state;

        update(payDoc.id, {mod: payDoc.mod})
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data});
                    this.props.showInfo('Новый mod сохранен.');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    confirmDeletePayDoc = () => {
        const {t} = this.props;

        this.props.showConfirm({
            message: t("Do you want to delete the payment document"),
            title: t("Delete a payment document"),
            onClose: this.handleDeletePayDoc
        });
    }

    handleDeletePayDoc = (ok) => {

        if (!ok) {
            return;
        }

        const {payDoc} = this.state;

        if (!payDoc.id) {
            return;
        }

        removePayDoc(payDoc.id)
            .then(response => {
                if (response.success) {
                    this.props.history.push('/pay-docs');
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            })
    }

    handleDialogDelivery = (open) => () => {
        this.setState({
            dialogDeliveryGuest: open,
        })
    }

    handleConfirmDelivery = (open) => () => {
        const {payDoc} = this.state;

        getDeliveryPayDoc(payDoc.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogRechargeOpen: open,
                        deliveryPayDoc: {...response.data, businessUnitId: payDoc.businessUnitId},
                    })
                }
            })
    }

    handleCancelDelivery = () => {
        const {payDoc} = this.state;

        update(payDoc.id, {status: payDocStatuses.for_sale})
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data});
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    loadDeliveryPayDoc = () => {
        const {payDoc} = this.state;

        getDeliveryPayDoc(payDoc.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        deliveryPayDoc: {...response.data, businessUnitId: payDoc.businessUnitId},
                    })
                }
            })
    }

    handleCancelSale = () => {
        const {t} = this.props;
        this.props.showConfirm({
            message: t("Are you sure you want to cancel the sale"),
            title: t("Cancel sale"),
            onClose: this.cancelSale
        });
    }

    cancelSale = ok => {
        if (!ok) return;

        const {payDoc} = this.state;

        cancelPayDocSale(payDoc.id)
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data});
                    this.props.showInfo('Продажа ПД отменена');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleUnfreezeRest = () => {
        const {t} = this.props;
        this.props.showConfirm({
            message: t("Are you sure you want to unfreeze the leftovers"),
            title: t("Defrosting Residues"),
            onClose: this.unfreezeRest
        });
    }

    unfreezeRest = ok => {
        if (!ok) return;

        const {payDoc} = this.state;
        unfreezeRest(payDoc.id)
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data});
                    this.props.showInfo('Остатки разморожены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })

    }

    handleDeleteGuest = (idx) => () => {
        const {payDoc} = this.state;
        let additionalGuests = [...payDoc.additionalGuests];
        additionalGuests.splice(idx, 1);
        this.setState({
            payDoc: {
                ...payDoc,
                additionalGuests: additionalGuests
            }
        });
    }

    handleAddGuest = (e, guest) => {
        const {payDoc} = this.state;
        let additionalGuests = [...payDoc.additionalGuests];
        additionalGuests.push({
            id: guest.id,
            name: guest.name,
            phone: guest.phone
        });
        this.setState({
            payDoc: {
                ...payDoc,
                additionalGuests: additionalGuests
            }
        });
    }

    saveAdditionalGuests = () => {
        const {payDoc} = this.state;
        const {t} = this.props;
        let data = {
            'id': payDoc.id,
            'additionalGuestsIds': payDoc.additionalGuests.map(guest => guest.id),
            'pin': payDoc.additionalGuestsPin,
        }
        saveAdditionalGuests(data)
            .then(response => {
                if (response.success) {
                    this.setState({payDoc: response.data});
                    this.props.showInfo(t('Saved'));
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    openMoveBalanceDialog = () => {
        this.setState({
            moveBalance: {
                ...this.state.moveBalance,
                dialogOpen: true
            }
        })
    }

    closeMoveBalanceDialog = () => {
        this.setState({
            moveBalance: {
                ...this.state.moveBalance,
                dialogOpen: false
            }
        })
    }

    moveBalance = () => {
        const {moveBalance} = this.state;
        const {t, showConfirm} = this.props;

        if (moveBalance.toPD.statusId === payDocStatuses.active || moveBalance.toPD.statusId === payDocStatuses.sold) {
            showConfirm({
                message: t('payDoc.moveBalanceActiveConfirm'),
                title: t('payDoc.moveBalance'),
                onClose: (ok) => {
                    if (!ok) {
                        return;
                    }
                    this.moveBalanceRequest();
                },
            });

            return;
        }

        this.moveBalanceRequest();
    }

    moveBalanceRequest = () => {
        const {moveBalance, payDoc} = this.state;
        const {t, showInfo, showMessage} = this.props;

        moveBalanceRequest({
            'fromId': payDoc.id,
            'toNum': moveBalance.toNum,
        })
            .then(response => {
                if (response.success) {
                    showInfo(t('payDoc.moveBalanceMoved'));
                    this.closeMoveBalanceDialog();
                    this.setState({
                        payDoc: response.data
                    })
                } else {
                    showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleChangeMoveTo = event => {
        this.setState({
            moveBalance: {
                ...this.state.moveBalance,
                toNum: event.target.value,
                error: null,
                toNumLoading: true,
                toPD: null
            }
        }, this.getPayDocByNumDebounced)
    }

    findToPayDocByNum = () => {
        if (!this.state.moveBalance.toNum) {
            return;
        }

        getPayDocByNum(this.state.moveBalance.toNum)
            .then(response => {
                if (response.success) {
                    this.setState({
                        moveBalance: {
                            ...this.state.moveBalance,
                            toPD: response.data,
                            toNumLoading: false
                        }
                    });
                } else {
                    this.setState({
                        moveBalance: {
                            ...this.state.moveBalance,
                            toNumLoading: false,
                            error: response.error ? response.error.message : response.message
                        }
                    })
                }
            });
    }

    getPayDocByNumDebounced = _.debounce(this.findToPayDocByNum, 300);

    renderMoveBalanceDialog = () => {
        const {moveBalance, payDoc} = this.state;
        const {t, classes} = this.props;

        return (
            <Dialog
                open={moveBalance.dialogOpen}
                onClose={this.closeMoveBalanceDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>{t('payDoc.moveBalance')}</DialogTitle>
                <DialogContent style={{textAlign: 'center'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={5} className={classes.vCenterCell}>
                            <ShowField
                                label={t('payDoc.fromPD')}
                                value={payDoc.readableName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.vCenterCell}>
                            <ArrowForwardIcon fontSize="default"/>
                        </Grid>
                        <Grid item xs={5} className={classes.vCenterCell}>
                            <AnyField
                                value={moveBalance.toNum}
                                onChange={this.handleChangeMoveTo}
                                required
                                error={!!moveBalance.error}
                                helperText={moveBalance.error}
                                InputProps={{
                                    endAdornment: moveBalance.toNumLoading ?
                                        <InputAdornment position="end"><CircularProgress
                                            size={25}/></InputAdornment> : null
                                }}
                                placeholder={t('payDoc.toPD')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.moveBalance}
                            disabled={!moveBalance.toNum || moveBalance.error || !moveBalance.toPD}
                            color="primary">{t('payDoc.move')}</Button>
                    <Button onClick={this.closeMoveBalanceDialog} color="primary">{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    handlePrintLastRefillReceipt = () => {
        const {t} = this.props;
        const {payDoc} = this.state;
        let [saleId, lastRefillDate, creatorName, lastRefillIndex] = [0, null, '', 0];

        //avoiding double click
        this.setState({
            canPrintReceipt: false,
        }, () => {
            getPayDocRefillsByPayDocId(payDoc.id)
                .then((response) => {
                    if (response.data.length > 0) {
                        let currentRefillDate = null;

                        _.forEach(response.data, (refill, i) => {
                            currentRefillDate = new Date(refill.createdAt);
                            if (typeof lastRefillDate === 'undefined' && refill.amount > 0) {
                                lastRefillDate = currentRefillDate;
                                lastRefillIndex = i;
                            } else if (lastRefillDate < currentRefillDate && refill.amount > 0) {
                                lastRefillDate = currentRefillDate;
                                lastRefillIndex = i;
                            }
                        })

                        saleId = response.data[lastRefillIndex].saleId;
                        creatorName = response.data[lastRefillIndex].creatorName;
                    }

                    let cash = 0;
                    let cashless = 0;

                    getSale(saleId)
                        .then(response => {

                            if (response.success) {
                                response.data.payments.forEach((payment) => {
                                    if (payment.status === 'paid') {
                                        if (payment.type === "cashless")
                                            cashless += payment.amount.val;
                                        if (payment.type === "cash")
                                            cash += payment.amount.val;
                                    }
                                });

                                let username = creatorName;
                                let item = {};

                                item.title = (payDoc.receiptName) ? payDoc.receiptName : t('Refill pay document №') + payDoc.num;

                                item.cost = response.data.total.val;

                                kkmService.registerCheck(username, cash, cashless, [item]).then(() => {
                                    this.props.showInfo(t('Receipt printed'));
                                })
                            }
                        })
                })
        })
    }

    handleChangeVerifyGuestName = prop => event => {
        this.setState({
            nameVerifyingData: {
                ...this.state.nameVerifyingData,
                [prop]: event.target.value
            }
        })
    }

    handleVerifyGuestName = () => {
        let {nameVerifyingData, payDoc} = this.state;

        let guest = {
            id: payDoc.guestId,
            name: nameVerifyingData.guestName,
            lastName: nameVerifyingData.guestLastName,
            gender: nameVerifyingData.gender
        };

        verifyGuestName(guest).then(response => {
            if (response.success) {
                this.setState({
                    nameVerifyingData: {
                        ...nameVerifyingData,
                        dialogOpen: false,
                    }
                }, () => {
                    if (nameVerifyingData.nameVerifyCallback)
                        nameVerifyingData.nameVerifyCallback();
                })
            } else {
                if (nameVerifyingData.nameVerifyCallback)
                    nameVerifyingData.nameVerifyCallback();
            }
        })
    }

    isPinRequiredForActivate() {
        return this.state.activateData.changing
            ? this.state.payDoc.type.pin.includes(askPinRuleType.ON_GUEST_CHANGE)
            : this.state.payDoc.type.pin.includes(askPinRuleType.ON_ACTIVATE);
    }

    isPinRequiredForAdditional() {
        return this.state.payDoc.type.pin.includes(askPinRuleType.ON_GUEST_ADD);
    }

    setVisitParlourPrice = (ok = false) => {
        const {parlours, payDoc} = this.state;

        const isVisitAndPaidPayDoc = payDoc.balance > 0 && payDoc.type.visitingSalon;

        if ((isVisitAndPaidPayDoc && parlours.length > 0) || (ok && payDoc.type.visitingSalon)) {
            getPayDocRefillsByPayDocId(payDoc.id).then(res => {
                if (res.success && res.data.length > 0) {
                    const visitParlourId = res.data[0].visitBusinessUnitId;
                    const visitParlour = parlours.filter((parlour) => {
                        return parlour.id === visitParlourId;
                    });

                    if (visitParlour.length === 0) {
                        return;
                    }
                    const response = visitParlour[0].hourCosts.val + ' ' + visitParlour[0].hourCosts.cur;
                    
                    this.setState({
                        visitParlourPrice: response
                    })
                }
            });
        }

    }

    getGuestFio() {
        const {payDoc} = this.state;
        getGuest(payDoc.guestId).then(response => {
            if (response.success) {
                this.setState({
                    guestFullName: response.data.fullName
                })
            }
        })
    }

    render() {
        const {classes, t} = this.props;
        const {
            payDoc, dialogDeliveryGuest, deliveryPayDoc,
            dialogSaleLoading,
            dialogSaleOpen,
            subtypes,
            partners,
            saleData,
            dialogPayStatusOpen,
            dialogPayStatusMessage,
            dialogRechargeOpen,
            rechargeBySite,
            dialogActivateOpen,
            dialogSendSertificateByMailOpen,
            dialogMoneyBackOpen,
            activateData,
            sendSertificateData,
            dialogActivateTitle,
            dialogLockOpen,
            lockData,
            dialogRenewalOpen,
            renewalData,
            discounts,
            parlours,
            parloursForResale,
            parlourChanging,
            notFound,
            canPrintReceipt,
            bitrixRequest,
            nameVerifyingData,
            settings,
            regionsForCert,
        } = this.state;
        const showGuest = !!payDoc.guestId;
        const showPin = payDoc.pin && hasRole('ROLE_PAYDOCS_VIEW_PIN');
        let realizeButtonText = 'OK';
        if (payDoc.saleId && payDoc.saleStatus !== 'closed') {
            realizeButtonText = t('payDoc.doSale');
        } else if (!payDoc.isBarter) {
            realizeButtonText = t('payDoc.gotoPayment');
        }

        const availableInBusinessUnits =
            payDoc &&
            payDoc.type &&
            payDoc.type.availableInBusinessUnits ? payDoc.type.availableInBusinessUnits : [];

        const wrongBusinessUnit = Boolean(
            availableInBusinessUnits.length &&
            payDoc.parlourId &&
            availableInBusinessUnits.indexOf(payDoc.parlourId) === -1
        );

        const isEmpty = val => val === null || !(Object.keys(val) || val).length;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t('PD card')}/>}>
                    <ShowField
                        label={t("Payment document")}
                        value={payDoc.num}
                        fullWidth
                    />
                    <ShowField
                        label={t("Type")}
                        value={payDoc.type ? payDoc.type.name : null}
                        fullWidth
                    />
                    <ShowField
                        label={t("Status")}
                        value={payDoc.status}
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                <Link to={`/pay-docs/history/status/${payDoc.id}`}>
                                    <IconButton
                                        aria-label={t("History")}
                                        size="small"
                                        title={t("History")}
                                    ><History/></IconButton>
                                </Link>
                            </InputAdornment>
                        }
                    />
                    <ShowField
                        label={t("Creator")}
                        value={payDoc && payDoc.creator && payDoc.creator.username}
                        fullWidth
                    />
                    <ShowField
                        label={t("Created date")}
                        dateFormat="DD.MM.YYYY"
                        value={payDoc && payDoc.createdAt}
                        fullWidth
                    />
                    {
                        this.state.visitParlourPrice &&
                        <ShowField
                            label={t("Business unit visitng price")}
                            value={this.state.visitParlourPrice}
                            fullWidth
                        />
                    }
                    <ShowField
                        label={'Основной ПД'}
                        value={payDoc.parentPayDoc}
                        linkTo={'/pay-docs/view/' + payDoc.parentPayDocId}
                        visible={!!payDoc.parentPayDoc}
                    />
                    <ShowField
                        label={'Основной ПД'}
                        value={payDoc.mainPayDoc}
                        linkTo={'/pay-docs/view/' + payDoc.mainPayDocId}
                        visible={!!payDoc.mainPayDoc}
                    />
                    <ShowField
                        label={'UTM'}
                        value={payDoc && payDoc.utm ? Object.entries(payDoc.utm).map(value => (value[0]) + ': ' + value[1] + '; \n') : null}
                        fullWidth
                        visible={payDoc && payDoc.utm}
                    />
                    <ShowField
                        label={t('External subject')}
                        value={payDoc.externalSubjectName}
                        visible={payDoc && payDoc.externalSubjectName}
                    />
                    <ShowField
                        label={t('Utm')}
                        value={payDoc.utmSource}
                        visible={payDoc && payDoc.utmSource !== null}
                    />
                    <br/>
                    <br/>
                    <ActionButton visible={this.checkStatuses([payDocStatuses.delivery])}
                                  onClick={this.handleRegister}>{t("Take to the salon")}</ActionButton>
                    <ActionButton
                        visible={this.checkStatuses([payDocStatuses.for_sale]) && hasRole("ROLE_PAYDOC_PAYDOC_GUESTDELIVERY")}
                        disabled={!payDoc.businessUnitId || !payDoc.type.active}
                        onClick={this.handleDialogDelivery(true)}>{t("Send in shipping")}</ActionButton>
                    <ActionButton visible={this.checkStatuses([payDocStatuses.for_sale])}
                                  onClick={this.handleSale}
                                  disabled={!payDoc.type.active}>{t("Sell")}</ActionButton>
                    <ActionButton visible={this.checkStatuses([payDocStatuses.sold]) && !payDoc.isResold}
                                  onClick={this.handleActivate}>{t("Activate")}</ActionButton>
                    <ActionButton visible={this.checkStatuses([payDocStatuses.active])}
                                  onClick={this.handleChangeGuest}>{t("Change guest")}</ActionButton>
                    <ActionButton
                        visible={this.checkStatuses([payDocStatuses.sold, payDocStatuses.active]) && payDoc.canRefill && !payDoc.isResold}
                        disabled={!payDoc.type.active}
                        onClick={this.handleRecharge}>{t("Refill")}</ActionButton>
                    <ActionButton
                        visible={this.checkStatuses([payDocStatuses.sold, payDocStatuses.active]) && payDoc.canRefill && !payDoc.isResold}
                        disabled={!payDoc.type.active}
                        onClick={this.handleRechargeBySite}>{t("Refill by site")}</ActionButton>
                    <ActionButton
                        visible={this.checkStatuses([payDocStatuses.delivery_guest]) && hasRole("ROLE_PAYDOC_PAYDOC_GUESTDELIVERY")}
                        onClick={this.handleConfirmDelivery(true)}>{t("Complete delivery")}</ActionButton>
                    <ActionButton
                        visible={this.checkStatuses([payDocStatuses.delivery_guest]) && hasRole("ROLE_PAYDOC_PAYDOC_GUESTDELIVERY_CANCEL")}
                        onClick={this.handleCancelDelivery}>{t("Cancel delivery")}</ActionButton>
                    <ActionButton
                        visible={this.checkStatuses([payDocStatuses.sold]) && hasRole("ROLE_PAYDOC_PAYDOC_SALE_CANCEL") && !payDoc.isResold}
                        onClick={this.handleCancelSale}>{t("Cancel sale")}</ActionButton>
                    <ActionButton
                        visible={this.checkStatuses([payDocStatuses.sold, payDocStatuses.active]) && payDoc.guestId && !payDoc.isResold}
                        onClick={this.handleSendSerificateByEmail}>{t("Send sertificate by email")}</ActionButton>
                    <ActionButton visible={this.checkStatuses([payDocStatuses.active, payDocStatuses.expired])}
                                  onClick={this.handleRenewal}>{t("Extend")}</ActionButton>
                    <ActionButton visible={this.checkStatuses([payDocStatuses.active, payDocStatuses.sold]) && !payDoc.isResold}
                                  onClick={this.handleLock}>{t("Block")}</ActionButton>

                    { !payDoc.isResold && <ActionButton
                        visible={this.checkStatuses([payDocStatuses.sold, payDocStatuses.active, payDocStatuses.expired, payDocStatuses.renewal, payDocStatuses.liquidated])
                        && hasRole('ROLE_PAYDOC_PAYDOC_REMOVE')
                        && payDoc.removePayDoc
                        && !payDoc.isResold}
                        onClick={this.confirmDeletePayDoc}>{t("Delete")}</ActionButton>}
                    <ActionButton
                        visible={payDoc.type.balanceCanBeMoved && payDoc.balance > 0 && hasRole("ROLE_PAYDOC_PAYDOC_MOVE_BALANCE")}
                        onClick={this.openMoveBalanceDialog}>{t('payDoc.moveBalance')}</ActionButton>
                    <ActionButton
                        to={`/pay-docs/history/balance/${payDoc.id}`}>{t("History of replenishment and expenditure")}</ActionButton>
                    <ActionButton
                        visible={
                            this.checkStatuses([payDocStatuses.sold, payDocStatuses.active]) &&
                            parseInt(this.state.payDoc.balance) !== 0
                        }
                        onClick={this.handleMoneyBack}>{t("Money back")}
                    </ActionButton>
                    <ActionButton visible={hasRole("ROLE_PAYDOC_FULL_HISTORY_EVENTS")}
                                  to={`/pay-docs/full-history-events/${payDoc.id}`}>{t("Full history events")}</ActionButton>


                    <ActionButton
                        visible={this.checkStatuses([payDocStatuses.active, payDocStatuses.sold]) && canPrintReceipt && !payDoc.isResold}
                        onClick={this.handlePrintLastRefillReceipt}>{t("Print receipt again")}</ActionButton>
                    <ActionButton
                        visible={this.checkStatuses(
                            [payDocStatuses.active, payDocStatuses.sold
                                ,payDocStatuses.burned,payDocStatuses.expired]) && payDoc.balance === 0 && payDoc.canResale && hasRole("ROLE_PAYDOC_RESALE")
                    }
                        onClick={this.handleResale}>{t("Resale")}
                    </ActionButton>
                </LeftBar>
                <Content title={t('Payment document card')} notFoundContent={notFound}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={6}>
                            <AnyField
                                id="place"
                                label={t("Parlour")}
                                value={payDoc.parlourId}
                                readOnly={!hasRole("ROLE_PAYDOC_PAYDOC_SAVE_PARLOUR") || parlourChanging || !payDoc.id}
                                onChange={this.handleChangeParlour}
                                error={wrongBusinessUnit}
                                helperText={wrongBusinessUnit ? t("Wrong parlor for PD") : null}
                                select
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        hasRole("ROLE_PAYDOC_PAYDOC_BUSINESSUNITHISTORY_GET") && this.state.payDoc.parlourId &&
                                        <InputAdornment position="end" className={classes.parlourHistory}>
                                            <Link to={`/pay-docs/history/business-unit/${payDoc.id}`}>
                                                <IconButton size="small"><History/></IconButton>
                                            </Link>
                                        </InputAdornment>
                                }}
                            >
                                {parlours.map(parlour =>
                                    <MenuItem
                                        key={parlour.id}
                                        value={parlour.id}
                                        className={cn({[classes.wrongParlour]: availableInBusinessUnits.length && availableInBusinessUnits.indexOf(parlour.id) === -1})}
                                    >{parlour.name}</MenuItem>)}
                            </AnyField>
                            {showGuest ?
                                <AnyField
                                    id="phone"
                                    label={t("Guest phone")}
                                    value={payDoc.phone}
                                    readOnly
                                    fullWidth

                                />
                                : null
                            }
                            <AnyField
                                label={t("Nominal") + ' ' + (payDoc.type.measure === 'money' ? t('payDoc.nominalMoney') : t('payDoc.nominalTime'))}
                                value={payDoc.nominal}
                                readOnly
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatDecimal,
                                }}
                            />
                            <AnyField
                                id="balance"
                                label={t("Balance")}
                                value={payDoc.balance}
                                InputProps={{
                                    inputComponent: NumberFormatBalance,
                                    endAdornment:
                                        hasRole("ROLE_PAYDOC_PAYDOC_SAVE_BALANCE") && (payDoc.balance !== payDoc.loadedBalance) &&
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.handleSaveBalance} size="small"><SaveIcon
                                                fontSize="small"/></IconButton>
                                        </InputAdornment>
                                }}
                                onChange={this.handleChangeBalance}
                                readOnly={!hasRole("ROLE_PAYDOC_PAYDOC_SAVE_BALANCE")}
                                fullWidth
                            />
                            <AnyField
                                label={t("Frozen")}
                                value={payDoc.frozen || 0}
                                readOnly
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        hasRole('ROLE_PAYDOC_PAYDOC_UNFREEZE_REST') && Boolean(payDoc.frozen) &&
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.handleUnfreezeRest}
                                                        title={t('Defrosting Residues')} size="small"><UnfreezeIcon
                                                fontSize="small"/></IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {
                                payDoc.statusId === payDocStatuses.delivery_guest
                                    ?
                                    <AnyField
                                        label={'В доставке (' + t(deliveryPayDoc.paymentType ? 'pay.types.' + salePayTypes[deliveryPayDoc.paymentType] : '') + ')'}
                                        value={deliveryPayDoc && deliveryPayDoc.amount}
                                        readOnly
                                        fullWidth
                                    />
                                    : null
                            }
                            {
                                payDoc.burned > 0 ?
                                    <AnyField
                                        label={t('Burned amount')}
                                        value={payDoc.burned}
                                        readOnly
                                        fullWidth
                                    />
                                    : null
                            }
                            {hasRole("ROLE_PAYDOC_PAYDOC_SAVE_MOD") && (
                                <AnyField
                                    id="mod"
                                    label="mod"
                                    value={payDoc.mod}
                                    InputProps={{
                                        inputComponent: NumberFormatDecimal,
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.handleSaveMod} size="small">
                                                    <SaveIcon fontSize="small"/>
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                    onChange={this.handleChangeMod}
                                    fullWidth
                                />
                            )}
                            <CheckboxField
                                label={t("Allowed to replenish")}
                                value={payDoc.canRefill}
                                onChange={this.handleChangeCanRefill}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {showPin ?
                                <AnyField
                                    id="pin"
                                    label={t("PIN code")}
                                    value={payDoc.pin}
                                    readOnly
                                    fullWidth
                                />
                                : null
                            }
                            {showGuest ?
                                <AnyField
                                    label={t("Full name")}
                                    value={!isEmpty(payDoc.fio) ? payDoc.fio : this.state.guestFullName}
                                    readOnly
                                    fullWidth
                                    InputProps={{
                                        endAdornment:
                                            <React.Fragment>
                                                <InputAdornment position="end">
                                                    <Link to={`/guests/view/${payDoc.guestId}`}><Tooltip
                                                        title={t("Go to guest card")}><ForwardIcon
                                                        fontSize="small"/></Tooltip></Link>
                                                </InputAdornment>
                                                <InputAdornment position="end">
                                                    <Link to={`/pay-docs/history/guest/${payDoc.id}`}>
                                                        <IconButton
                                                            aria-label={t("History")}
                                                            size="small"
                                                            title={t("History")}
                                                        ><History/></IconButton>
                                                    </Link>
                                                </InputAdornment>
                                            </React.Fragment>
                                    }}
                                />
                                : null
                            }
                            <AnyField
                                id="validity"
                                label={t('payDoc.validity')}
                                value={payDoc.validity ? moment(payDoc.validity).utcOffset(0, false).format('DD.MM.YYYY') : ''}
                                readOnly
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Link to={`/pay-docs/history/validity/${payDoc.id}`}>
                                                <IconButton
                                                    aria-label={t("History")}
                                                    size="small"
                                                    title={t("History")}
                                                ><History/></IconButton>
                                            </Link>
                                        </InputAdornment>
                                }}
                            />
                            <AnyField
                                label={t("Comment")}
                                value={payDoc.comment}
                                multiline
                                rows={4}
                                fullWidth
                                onChange={this.handleChangeComment}
                            />
                            <AnyField
                                label={t("Bitrix id")}
                                value={this.state.payDoc.bitrixId}
                                visible={!!this.state.payDoc.bitrixId}
                                disabled={true}
                                fullWidth
                            />
                            <AnyField
                                label={t("Received bitrix id")}
                                value={this.state.bitrixId}
                                visible={this.state.isBitrix24Data}
                                disabled={true}
                                fullWidth
                            />
                            <AnyField
                                label={t("Received bitrix user id")}
                                value={this.state.bitrixUserId}
                                visible={this.state.isBitrix24Data}
                                disabled={true}
                                fullWidth
                            />
                            <ActionButton disabled={!payDoc.id}
                                          onClick={this.handleSaveComment}>{t('Save comment')}</ActionButton>
                        </Grid>
                        {payDoc.id && payDoc.type && payDoc.type.canBeAdditional &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>{t('Additional guests')}</Typography>
                                    {!payDoc.additionalGuests.length && <p>{t('Here is no additional guests yet')}</p>}
                                </Grid>
                                <Grid item xs={6}>
                                    <List disablePadding dense>
                                        {payDoc.additionalGuests.map((guest, idx) => (
                                            <ListItem key={guest.id} disableGutters>
                                                <ListItemText
                                                    primary={<a href={`/guests/view/${guest.id}`}>{guest.name}</a>}
                                                    secondary={guest.phone}
                                                />
                                                    <ListItemSecondaryAction
                                                    >

                                                        <IconButton aria-label={t("Delete")}
                                                                    onClick={this.handleDeleteGuest(idx)}>
                                                            <DeleteIcon
                                                                visibility={!payDoc.isResold}
                                                            />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <GuestField
                                        label={t("Add guest")}
                                        onChange={this.handleAddGuest}
                                    />
                                    {this.isPinRequiredForAdditional() ?
                                        <AnyField
                                            label={t('PIN code')}
                                            value={payDoc.additionalGuestsPin}
                                            onChange={this.handleAdditionalGuestsPinChange}
                                            fullWidth
                                        /> : null}
                                    <ActionButton
                                        onClick={this.saveAdditionalGuests}>{t('Save additional guests')}</ActionButton>
                                </Grid>
                            </>
                        }
                        { !!payDoc.id && payDoc.haveChildren &&
                            <Grid item xs={12}>
                                <DataTable
                                    columnData={this.columnData}
                                    dataFunc={this.getChildrenTableData}
                                    selector={false}
                                    disableSorting={true}
                                    linkTemplate={this.tableLinkTemplate}
                                    pagination = {false}
                                />
                            </Grid>
                        }
                        { !!payDoc.id && payDoc.haveMinor &&
                            <Grid item xs={12}>
                                <DataTable
                                    columnData={this.columnData}
                                    dataFunc={this.getMinorTableData}
                                    selector={false}
                                    disableSorting={true}
                                    linkTemplate={this.tableLinkTemplate}
                                    pagination = {false}
                                />
                            </Grid>
                        }
                    </Grid>
                    <ProgressDialog open={bitrixRequest.loading} message={t('Finding user by bitrix id')}/>
                </Content>
                <Dialog
                    open={dialogSaleOpen}
                    onClose={this.handleCloseSaleDialog(false)}
                    aria-labelledby="sale-dialog-title"
                    aria-describedby="sale-dialog-description"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="sale-dialog-title">{saleData.isResale ? t('payDoc.resale') : t('payDoc.sale')}</DialogTitle>
                    <DialogContent>
                        <div>
                            <Grid container spacing={2}>
                                {payDoc.type.isPaper ?
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <AnyField
                                                id="num"
                                                label={t('payDoc.number')}
                                                value={payDoc.num}
                                                fullWidth
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AnyField
                                                id="pin"
                                                label={t("PIN code")}
                                                value={payDoc.pin}
                                                fullWidth
                                                readOnly
                                            />
                                        </Grid>
                                    </React.Fragment>
                                    : null
                                }
                                {payDoc.type.hasSubtype ?
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <AnyField
                                                id="subtype"
                                                label={t('payDoc.subtype')}
                                                value={saleData.subtypeId}
                                                fullWidth
                                                select
                                                disabled={dialogSaleLoading}
                                                onChange={this.handleSaleChange('subtypeId')}
                                            >
                                                {subtypes.map(subtype =>
                                                    <MenuItem key={subtype.id} value={subtype.id}>
                                                        {subtype.name}
                                                    </MenuItem>
                                                )};
                                            </AnyField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/*Empty*/}
                                        </Grid>
                                    </React.Fragment>
                                    : null
                                }
                                {payDoc.type.isBarter ?
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <AnyField
                                                id="partner"
                                                label={t("Partner")}
                                                value={saleData.partnerId}
                                                fullWidth
                                                select
                                                disabled={dialogSaleLoading}
                                                onChange={this.handleSaleChange('partnerId')}
                                            >
                                                {partners.map(partner =>
                                                    <MenuItem key={partner.id} value={partner.id}>
                                                        {partner.username}
                                                    </MenuItem>
                                                )};
                                            </AnyField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/*Empty*/}
                                        </Grid>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Grid item xs={8}>
                                            <GuestField
                                                label={t("Guest")}
                                                value={saleData.guestId}
                                                error={!saleData.guestId}
                                                onChange={this.handleSaleChange('guestId')}
                                                required
                                                readOnly={!!payDoc.saleId && payDoc.saleStatus !== 'closed'}
                                            />
                                        </Grid>
                                        {
                                            !saleData.isResale &&
                                        <Grid item xs={4}>
                                            <AnyField
                                                label={t('payDoc.discount')}
                                                value={saleData.discountId}
                                                fullWidth
                                                select
                                                disabled={dialogSaleLoading || !payDoc.nominal}
                                                onChange={this.handleSaleChange('discountId')}
                                            >
                                                <MenuItem value={null}>{t('Not chosen')}</MenuItem>
                                                {discounts.map(discount =>
                                                    <MenuItem key={discount.id} value={discount.id}>
                                                        {discount.name}
                                                    </MenuItem>
                                                )};
                                            </AnyField>
                                        </Grid>
                                        }
                                        <Grid item xs={4}>
                                            <AnyField
                                                id="place"
                                                label={t("Parlour")}
                                                visible={saleData.isResale}
                                                value={saleData.parlourResaleId}
                                                readOnly={!hasRole("ROLE_PAYDOC_RESALE_CHANGE_PARLOUR") || payDoc.saleStatus !== 'closed'}
                                                onChange={this.handleChangeParlourResale}
                                                error={wrongBusinessUnit}
                                                helperText={wrongBusinessUnit ? t("Wrong parlor for PD") : null}
                                                select
                                                fullWidth
                                            >
                                                {parloursForResale.map(parlour =>
                                                    <MenuItem
                                                        key={parlour.id}
                                                        value={parlour.id}
                                                        className={cn({[classes.wrongParlour]: availableInBusinessUnits.length && availableInBusinessUnits.indexOf(parlour.id) === -1})}
                                                    >{parlour.name}</MenuItem>)}
                                            </AnyField>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {payDoc.type.isBarter &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <AnyField
                                                id="nominal"
                                                label={t("Nominal")}
                                                value={saleData.nominal}
                                                fullWidth
                                                onChange={this.handleSaleChange('nominal')}
                                                InputProps={{
                                                    inputComponent: NumberFormatDecimal,
                                                    endAdornment: <SymbolRub/>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AnyField
                                                id="nominalComment"
                                                label={t('payDoc.nominalComment')}
                                                value={saleData.nominalComment}
                                                fullWidth
                                                onChange={this.handleSaleChange('nominalComment')}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {payDoc.saleId && payDoc.saleStatus !== 'closed' &&
                                    <Grid item xs={12}>
                                        <Pay
                                            saleId={payDoc.saleId}
                                            availablePaymentTypes={[salePayTypes.cash, salePayTypes.cashless]}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSaleDialog(true)}
                                disabled={!((  saleData.isResale ? !!saleData.parlourResaleId : true) && (saleData.guestId || saleData.partnerId) )|| dialogSaleLoading || (payDoc.type.hasSubtype && !saleData.subtypeId)}
                                color="primary">{realizeButtonText}</Button>
                        <Button onClick={this.handleCloseSaleDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={dialogPayStatusOpen}
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-labelledby="pay-dialog-title"
                    aria-describedby="pay-dialog-description"
                >
                    <DialogTitle id="pay-dialog-title">{t('payDoc.refillTitle')}</DialogTitle>
                    <DialogContent style={{textAlign: 'center'}}>
                        <DialogContentText id="pay-dialog-description">{dialogPayStatusMessage}</DialogContentText>

                        <CircularProgress size={50}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={dialogActivateOpen}
                    onClose={this.handleCloseActivateDialog(false)}
                    aria-labelledby="activate-dialog-title"
                    aria-describedby="activate-dialog-description"
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle id="activate-dialog-title">{t(dialogActivateTitle)}</DialogTitle>
                    <DialogContent>
                        <AnyField
                            id="callPhone"
                            label={t('payDoc.currentGuestPhone')}
                            value={activateData.callPhone}
                            readOnly
                            fullWidth
                        />
                        <GuestField
                            label={t("Guest")}
                            value={activateData.guestId}
                            required={!payDoc.guestId || activateData.changing}
                            error={!(payDoc.guestId || activateData.guestId) || (activateData.changing && (!activateData.guestId || activateData.guestId === payDoc.guestId))}
                            onChange={this.handleActivateChange('guestId')}
                        />
                        {this.isPinRequiredForActivate() ?
                            <AnyField
                                label={t('PIN code')}
                                value={activateData.pin}
                                onChange={this.handleActivateChange('pin')}
                                fullWidth
                            /> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseActivateDialog(true)}
                            disabled={!(payDoc.guestId || activateData.guestId) || (activateData.changing && (!activateData.guestId || activateData.guestId === payDoc.guestId))}
                            color="primary"
                        >OK</Button>
                        <Button onClick={this.handleCloseActivateDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={dialogSendSertificateByMailOpen}
                    onClose={this.handleCloseSertificateByMail(false)}
                    aria-labelledby="activate-dialog-title"
                    aria-describedby="activate-dialog-description"
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle id="activate-dialog-title">{t('Send sertificate by email')}</DialogTitle>
                    <DialogContent>
                        <AnyField
                            id="callPhone"
                            label={t('payDoc.currentGuestEmail')}
                            value={(sendSertificateData.guestEmail) ? sendSertificateData.guestEmail : ''}
                            required={!sendSertificateData.guestEmail}
                            error={!sendSertificateData.guestEmail}
                            onChange={this.handleGuestEmailForSertificateChange('guestEmail')}
                            visible={!this.state.dialogSendSertificateByMailResult.success && !this.state.dialogSendSertificateByMailResult.fail}
                            fullWidth
                        />

                        <AnyField
                            id="regionsForCert"
                            label={t("List of salons region")}
                            value={sendSertificateData.regionId}
                            onChange={this.handleChangeRegionsForCert}
                            select
                            visible={!this.state.dialogSendSertificateByMailResult.success && !this.state.dialogSendSertificateByMailResult.fail}
                            fullWidth
                        >
                            {regionsForCert.map(region =>
                                <MenuItem
                                    key={region.id}
                                    value={region.id}
                                >{region.name}</MenuItem>)}
                        </AnyField>
                        <div
                            style={{display: this.state.dialogSendSertificateByMailResult.success ? "block" : "none"}}>{t("Message sent")}</div>
                        <div
                            style={{display: this.state.dialogSendSertificateByMailResult.fail ? "block" : "none"}}>{t("Message sent with error")}</div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseSertificateByMail(true)}
                            disabled={!sendSertificateData.guestEmail || this.state.dialogSendSertificateByMailResult.success || this.state.dialogSendSertificateByMailResult.fail}
                            color="primary"
                        >{t("Send")}</Button>
                        <Button onClick={this.handleCloseSertificateByMail(false)}
                                color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={dialogLockOpen}
                    onClose={this.handleCloseLockDialog(false)}
                    aria-labelledby="lock-dialog-title"
                    aria-describedby="lock-dialog-description"
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle id="lock-dialog-title">Подтверждение блокировки</DialogTitle>
                    <DialogContent>
                        <AnyField
                            id="reason"
                            label="Причина блокировки"
                            value={lockData.reason}
                            multiline
                            rows="4"
                            onChange={this.handleLockChange('reason')}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseLockDialog(true)} color="primary">OK</Button>
                        <Button onClick={this.handleCloseLockDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={dialogRenewalOpen}
                    onClose={this.handleCloseRenewalDialog(false)}
                    aria-labelledby="renewal-dialog-title"
                    aria-describedby="renewal-dialog-description"
                >
                    <DialogTitle id="renewal-dialog-title">Продление истекшего платежного документа</DialogTitle>
                    <DialogContent>
                        <DateField
                            label="Продлить до"
                            value={renewalData.newValidity}
                            onChange={this.handleRenewalChangeDate('newValidity')}
                            minDate={moment(payDoc.validity)}
                            maxDate={moment(payDoc.validity).add(12, 'months')}
                            fullWidth
                        />
                        <AnyField
                            id="reason"
                            label="Основание продления"
                            value={renewalData.reason}
                            multiline
                            rows="4"
                            onChange={this.handleRenewalChange('reason')}
                            fullWidth
                            required={renewalData.newValidity && renewalData.newValidity.diff(moment(payDoc.validity), 'day') > 7}
                            error={!renewalData.reason && renewalData.newValidity && renewalData.newValidity.diff(moment(payDoc.validity), 'day') > 7}
                        />
                    </DialogContent>
                    <DialogActions>
                        <ActionButton
                            onClick={this.handleCloseRenewalDialog(true)}
                            disabled={!renewalData.newValidity}
                            fullWidth={false}
                            visible={renewalData.newValidity && (
                                renewalData.newValidity.diff(moment(payDoc.validity), 'day') <= 7
                            )}
                        >OK</ActionButton>
                        <ActionButton
                            onClick={this.handleCloseRenewalDialog(true)}
                            disabled={!(renewalData.reason && renewalData.newValidity)}
                            fullWidth={false}
                            visible={renewalData.newValidity && (
                                renewalData.newValidity.diff(moment(payDoc.validity), 'day') > 7 &&
                                hasRole('ROLE_PAYDOC_PAYDOC_RENEWAL_6MONTH') && renewalData.newValidity.diff(moment(payDoc.validity), 'month') <= 6
                            )}
                        >OK</ActionButton>
                        <ActionButton
                            onClick={this.handleCloseRenewalDialog(true)}
                            disabled={!(renewalData.reason && renewalData.newValidity)}
                            fullWidth={false}
                            visible={renewalData.newValidity && (
                                renewalData.newValidity.diff(moment(payDoc.validity), 'day') > 7 &&
                                (!hasRole('ROLE_PAYDOC_PAYDOC_RENEWAL_6MONTH') || renewalData.newValidity.diff(moment(payDoc.validity), 'month') > 6)
                            )}
                        >{t("Create request")}</ActionButton>
                        <ActionButton fullWidth={false}
                                      onClick={this.handleCloseRenewalDialog(false)}>{t("Cancel")}</ActionButton>
                    </DialogActions>
                </Dialog>
                <PayDocDeliveryDialog
                    payDoc={payDoc}
                    dialogDeliveryGuest={dialogDeliveryGuest}
                    handleDialogDelivery={this.handleDialogDelivery}
                    loadDoc={this.loadDocWithNameVerification}
                />
                <PayDocRefillDialog
                    open={dialogRechargeOpen}
                    rechargeBySite={rechargeBySite}
                    payDoc={payDoc}
                    onClose={this.handleCloseRechargeDialog}
                    deliveryPayDoc={deliveryPayDoc}
                />
                {this.renderMoveBalanceDialog()}
                <MoneyBackDialog
                    open={dialogMoneyBackOpen}
                    payDoc={payDoc}
                    onClose={this.handleCloseMoneyBack}
                />
                <GuestNameVerificationDialog
                    open={
                        nameVerifyingData.dialogOpen &&
                        !_.isEmpty(settings) &&
                        settings.guestNameVerificationEnable
                    }
                    nameVerifyData={nameVerifyingData}
                    onChange={this.handleChangeVerifyGuestName}
                    onSubmit={this.handleVerifyGuestName}
                />
            </React.Fragment>
        );
    }
}
