import React, { memo, useCallback, useState } from "react";
import LeftBar from "../../../components/left-bar/LeftBar";
import NavigationLinks from "../../../components/navigation-links/NavigationLinks";
import {
    getDomRuPhonesForTable,
    navLinks, syncDomRuPhones,
} from "../../../services/settings";
import Content from "../../../components/content/Content";
import DataTable from "../../../components/data-table/DataTable";
import { useTranslation } from "react-i18next";
import DomRuPhonesEditDialog from "./DomRuPhoneEditDialog";
import ActionButton from "../../../components/button/ActionButton";
import infoActions from "../../../components/info/info-actions";
import {useDispatch} from "react-redux";

const columnData = [
    { id: "telnum", label: "DomRu.Telephone number" },
    { id: "location", label: "DomRu.Location" },
    { id: "online", label: "DomRu.Is online-marketing phone" },
];

export default memo(function DomRuPhonesPage() {
    const { t } = useTranslation();

    const [refreshTable, setRefreshTable] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const openEditDomRuPhonesDialog = useCallback((domRuPhone) => {
        setDialogData(domRuPhone);
    }, []);

    const getTableData = useCallback((...params) => {
        return getDomRuPhonesForTable(...params);
    }, []);

    const handleClose = useCallback(() => {
        setDialogData(null);
    }, []);

    const handleSubmit = useCallback(() => {
        setDialogData(null);
        setRefreshTable(prevState => !prevState);
    }, []);

    const dispatch = useDispatch();

    const showInfo = useCallback((message) => dispatch(infoActions.show(message)), [dispatch]);
    const showError = useCallback((message) => dispatch(infoActions.showError(message)), [dispatch]);

    const handleClickSyncDomRu = () => {
        syncDomRuPhones()
            .then(response => {
                if (response.success) {
                    showInfo(`Обновлено телефонов: ${response.data}`);
                } else {
                    showError(response.error ? response.error.message : response.message);
                }
            });
    }


    return (
        <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={handleClickSyncDomRu}>{t("DomRu.Sync with DomRu")}</ActionButton>
            </LeftBar>
            <Content title={t("DomRu.DomRu phones")}>
                <DataTable
                    columnData={columnData}
                    dataFunc={getTableData}
                    selector={false}
                    refresh={refreshTable}
                    onClick={openEditDomRuPhonesDialog}
                />
            </Content>
            {!!dialogData && (
                <DomRuPhonesEditDialog
                    data={dialogData}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                />
            )}
        </React.Fragment>
    );
});
