import React from 'react';
import DateField2 from "./DateField2";
import * as PropTypes from "prop-types";

class DateMonthField extends React.PureComponent
{
    static propTypes = {
        value: PropTypes.any,
        minDate: PropTypes.any,
        maxDate: PropTypes.any,
        initialFocusedDate: PropTypes.any,
        format: PropTypes.string,
        onChange: PropTypes.func,
        autoOk: PropTypes.bool,
        disablePast: PropTypes.bool,
        disableFuture: PropTypes.bool,
        animateYearScrolling: PropTypes.bool,
        labelFunc: PropTypes.func,
        renderDay: PropTypes.func,
        shouldDisableDate: PropTypes.func,
        allowKeyboardControl: PropTypes.bool,
        forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        fullWidth: PropTypes.bool,
        invalidDateMessage: PropTypes.node,
        minDateMessage: PropTypes.node,
        maxDateMessage: PropTypes.node,
        className: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        cancelLabel: PropTypes.string,
        todayLabel: PropTypes.string,
        name: PropTypes.string,
    };

    render() {
        return <DateField2
            format="MMMM YYYY"
            {...this.props}
            views={['month']}
            mask=""
        />;
    }
}

export default DateMonthField;