import React from "react";
import * as PropTypes from "prop-types";
import moment from 'moment';
import DraggableScheduleEvent from "./DraggableScheduleEvent";
import ScheduleTimeSlots from "./ScheduleTimeSlots";
import withStyles from "@material-ui/core/styles/withStyles";
import {propTypeDuration} from "../../utils/moment-utils";
import cn from 'classnames';
import {topBarSettings} from "../../services/common";
import ScheduleSheetCurrentLocalTimeLine from "./ScheduleSheetCurrentLocalTimeLine"
import {hasRole} from "../../services/user";
import ScheduleSheetEquipmentBreakTime from "./ScheduleSheetEquipmentBreakTime";

export default
@withStyles(theme => ({
    container: {
        display: 'flex',
        flex: '1 0 0%',
        position: 'relative',
        border: '1px solid #777',
    },
    timeGutter: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #777',
    },
    timeLabel: {
        paddingLeft:  theme.spacing(1),
        paddingRight:  theme.spacing(2),
    },
    timeSlotGroup: {
        borderTop: '1px solid #777',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    rightContainer: {
        width: '100%',
        overflowX: 'auto',

        '@media print': {
            overflow: 'hidden',
        },
    },
    rightContainerFixed: {
        position: 'fixed',
        overflow: 'hidden',
        top: topBarSettings.minHeight,
        zIndex: 10,
        background: theme.palette.background.paper,
        boxShadow: '0px 7px 6px -4px rgba(0, 0, 0, 0.50)',
        display: 'none',
    },
    rightContainerFixedVisible: {
        display: 'block',
    },
    columnsHeaderContainer: {
        flex: '1 0 0%',
        display: 'flex',
        position: 'relative',
    },
    columnHeader: {
        padding: '18px 8px 8px 8px',
        textAlign: 'center',
        borderRight: '1px solid #777',
        position: 'relative',
    },
    columnsContainer: {
        flex: '1 0 0%',
        display: 'flex',
        position: 'relative',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #888',
        position: 'relative',
    },
    columnSlot: {
        borderBottom: '1px solid #ccc',
        '&:last-of-type': {
            borderBottom: 'none',
        },
        '&:hover': {
            background: '#ddd',
        }
    },
    timeZone: {
        fontSize: theme.typography.body2.fontSize,
        textAlign: 'center',
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        }
    },
    showPrint: {
        display: 'none',

        '@media print': {
            display: 'block',
        }
    },
}))
class ScheduleSheet extends React.PureComponent {

    static propTypes = {
        printInfo: PropTypes.any,
        timeZone: PropTypes.string,
        showCurrentTime: PropTypes.bool,
        startDayTime: propTypeDuration,
        endDayTime: propTypeDuration,
        timeStep: propTypeDuration,
        slotHeight: PropTypes.number,
        slotsInGroup: PropTypes.number,

        headerHeight: PropTypes.number,
        columnWidth: PropTypes.number,

        columns: PropTypes.array,
        columnTitle: PropTypes.func,
        columnStyle: PropTypes.func,

        events: PropTypes.array,
        renderEvent: PropTypes.func,

        onSlotClick: PropTypes.func,
        onSlotDbClick: PropTypes.func,
        onSlotDropHover: PropTypes.func,
        onSlotContextMenu: PropTypes.func,

        onHeaderClick: PropTypes.func,
        onBeginDrag: PropTypes.func,
        onEndDrag: PropTypes.func,
        lines: PropTypes.array,
        markAdminGapSlots: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    }

    static defaultProps = {
        timeZone: "+04:00",
        showCurrentTime: false,
        startDayTime: moment.duration("00:00"),
        endDayTime: moment.duration("23:59"),
        timeStep: moment.duration(15,'minutes'),
        slotHeight: 15,
        slotsInGroup: 4,

        headerHeight: 50,
        columnWidth: 130,

        columns: [],
        events: [],
        lines: [],
        markAdminGapSlots: false,
    }

    state = {
        fixedVisible: false,
    }

    constructor(props) {
        super(props);

        this.timeSlots = ScheduleSheet.getTimeSlots(props.startDayTime, props.endDayTime, props.timeStep);

        this.containerLeft = 0;
        this.windowLeft = 0;
    }

    bindContainerRef = ref => {
        this.containerRef = ref;
    }

    bindContainerFixedRef = ref => {
        this.fixedContainerRef = ref;
    }

    bindHeaderFixedRef = ref => {
        this.fixedHeaderRef = ref;
    }

    handleScrollContainer = event => {
        this.containerLeft = event.target.scrollLeft;
        this.translateFixed();
    }

    handleResize = () => {
        this.fixedContainerRef.style.width = this.containerRef.offsetWidth + 'px';
    }

    translateFixed = () => {
        this.fixedHeaderRef.style.transform = `translate(${-this.containerLeft}px, 0)`;
        this.fixedContainerRef.style.transform = `translate(${-this.windowLeft}px, 0)`;
    }

    handleScroll = () => {
        this.windowLeft = window.scrollX;
        this.translateFixed();
        const rect = this.containerRef.getBoundingClientRect();
        const fixedVisible = rect.top - topBarSettings.minHeight < 0;
        if (this.state.fixedVisible !== fixedVisible) {
            this.setState({
                fixedVisible,
            });
        }
    }

    componentDidMount() {
        this.windowLeft = window.scrollX;
        this.translateFixed();
        this.fixedContainerRef.style.width = this.containerRef.offsetWidth + 'px';
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {startDayTime, endDayTime, timeStep} = this.props;

        if (   nextProps.startDayTime !== startDayTime
            || nextProps.endDayTime !== endDayTime
            || nextProps.timeStep !== timeStep
        ) {
            this.timeSlots = ScheduleSheet.getTimeSlots(nextProps.startDayTime, nextProps.endDayTime, nextProps.timeStep);
        }
    }

    static getTimeSlots(startDayTime, endDayTime, timeStep) {
        let slots = [];
        let current = startDayTime.clone();

        while (current < endDayTime) {
            slots.push(current.clone());
            current.add(timeStep);
        }

        return slots;
    }

    renderColumnHeader({columns, columnTitle, onHeaderClick, columnStyle, columnWidth, classes}) {
        return columns.map((column, columnIdx) =>{
        const cS = columnStyle ? columnStyle(column, columnIdx) : {};


        return (
            <div
                className={classes.columnHeader}
                key={columnIdx}
                style={{...cS, width: columnWidth, minWidth: columnWidth, cursor: onHeaderClick ? 'pointer' : null}}
                onClick={e => onHeaderClick && onHeaderClick(column, columnIdx, e)}
            >
                {columnTitle ? columnTitle(column) : column.title}
            </div>
        )
    })}

    render() {
        const { classes, slotsInGroup, headerHeight, slotHeight, columns, events, columnWidth, renderEvent, onSlotClick,
            onSlotDbClick, columnTitle, timeStep, onSlotDropHover, onHeaderClick,
            onBeginDrag, onEndDrag, timeZone, showCurrentTime, onSlotContextMenu, columnStyle, printInfo, lines,
            markAdminGapSlots, startDayTime, endDayTime } = this.props;
        const { fixedVisible } = this.state;

        return (
            <div className={classes.container}>
                <div className={classes.timeGutter}>
                    <div style={{height: headerHeight}} className={classes.timeZone}>
                        <span className={classes.hidePrint}>GMT<br/>{timeZone}</span>
                        <span className={classes.showPrint}>{printInfo}</span>
                    </div>
                    <ScheduleTimeSlots
                        timeZone={timeZone}
                        showCurrentTime={showCurrentTime}
                        slots={this.timeSlots}
                        slotsInGroup={slotsInGroup}
                        slotHeight={slotHeight}
                        variant="header"
                        markAdminGapSlots={markAdminGapSlots}
                    />
                </div>
                <div className={classes.rightContainer} onScroll={this.handleScrollContainer} ref={this.bindContainerRef}>
                    <div className={cn(classes.rightContainerFixed, {[classes.rightContainerFixedVisible]: fixedVisible})} ref={this.bindContainerFixedRef}>
                        <div ref={this.bindHeaderFixedRef} className={classes.columnsHeaderContainer} style={{height: headerHeight}}>
                            {this.renderColumnHeader({columns, columnTitle, onHeaderClick, columnStyle, columnWidth, classes})}
                        </div>
                    </div>
                    <div className={classes.columnsHeaderContainer} style={{height: headerHeight}}>
                        {this.renderColumnHeader({columns, columnTitle, onHeaderClick, columnStyle, columnWidth, classes})}
                    </div>
                    <div className={classes.columnsContainer}>
                        {columns.map((column, columnIdx) =>{
                            return (
                                    <div className={classes.column} key={columnIdx} style={{width: columnWidth, minWidth: columnWidth}}>
                                        <ScheduleSheetEquipmentBreakTime
                                            slotHeight={slotHeight}
                                            columnWidth={columnWidth}
                                            startTime={startDayTime}
                                            endTime={endDayTime}
                                            timeStep={timeStep}
                                            equipment={column}
                                        />
                                        <ScheduleTimeSlots
                                            enabledSlots={column.enabledSlots}
                                            disabledSlots={column.disabledSlots}
                                            slots={this.timeSlots}
                                            slotsInGroup={slotsInGroup}
                                            slotHeight={slotHeight}
                                            column={column}
                                            onSlotClick={onSlotClick}
                                            onSlotDbClick={onSlotDbClick}
                                            onSlotContextMenu={onSlotContextMenu}
                                            onSlotDropHover={onSlotDropHover}
                                            columnIdx={columnIdx}
                                            yellow={Boolean(column.waiting && column.waiting.length)}
                                            transfer={column?.workShift?.find(ws => ws.activity === 'transfer')}
                                            lines={lines}
                                            markAdminGapSlots={markAdminGapSlots}
                                            lineColor={ column.lineColor }
                                            timeZone={timeZone}
                                        />
                                    </div>
                            )
                        })}
                        {events && renderEvent && events.map((event, eventIdx) => {
                            const elements = renderEvent(event, eventIdx, columns, this.timeSlots, timeStep);

                            if (Array.isArray(elements) && elements.length) {
                                return elements.map(({slotIdx, columnIdx, height, element, ...otherProps}, elementIdx) => {
                                    return <DraggableScheduleEvent
                                        key={`${eventIdx}-${elementIdx}`}
                                        width={columnWidth}
                                        height={height*slotHeight}
                                        left={columnIdx * columnWidth}
                                        top={slotIdx*slotHeight}
                                        event={event}
                                        onBeginDrag={onBeginDrag}
                                        onEndDrag={onEndDrag}
                                        {...otherProps}
                                    >{element}</DraggableScheduleEvent>
                                })
                            }

                            return null;
                        })}
                        {hasRole('ROLE_SCHEDULE_VISITS_CURRENT_LOCAL_TIME_LINE_SHOW') ?
                            <ScheduleSheetCurrentLocalTimeLine
                                slotHeight={slotHeight}
                                width={columnWidth * columns.length}
                                timeStep={timeStep}
                            />: null
                        }
                    </div>
                </div>
            </div>
        );
    }
}
