import {dataTableGroupValueStrategy} from "../../../../../services/common";

const debts = (currentVal, row) => {
    return {
        cash: dataTableGroupValueStrategy.arrayMoneySum('cash')(
            currentVal ? currentVal.cash : null,
            row.debts
        ),
        cashless: dataTableGroupValueStrategy.arrayMoneySum('cashless')(
            currentVal ? currentVal.cashless : null,
            row.debts
        ),
        total: dataTableGroupValueStrategy.arrayMoneySum('total')(
            currentVal ? currentVal.total : null,
            row.debts
        ),
    };
};

export default debts;
