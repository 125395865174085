import React from "react"
import { navLinks, createClosedPeriods, getClosedFinancialPeriodsReport } from "../../services/moneyFlow";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getParlours} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import { connect } from "react-redux"
import DataTable from "../../components/data-table/DataTable";
import {withTranslation} from "react-i18next";
import moment from "moment";

export default
@connect(() => ({}),{
    showMessage: messageDialogActions.show
})
@withTranslation()
class ClosedFinancialPeriodReport extends React.Component {

    state = {
        search: {
            businessUnitIds: []
        },
        businessUnits: [],
        periods: [],
        refreshTable: false,
        selected: []
    }

    dateFormat = 'DD.MM.YYYY HH:mm:ss'

    processValueDt = (dt) => dt ? moment(dt).utc().format(this.dateFormat) : null

    columnData = [
        {id: "businessUnitName", label: "Parlour"},
        {id: "creatorUsername", label: "Creator"},
        {id: "createdAt", label: "Created at", processValue: this.processValueDt},
        {id: "endedAt", label: "Ending", processValue: this.processValueDt}
    ]

    componentDidMount() {
        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({businessUnits: response.data})
                }
            })
    }

    handleChangeSearch = (event) => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value
            }
        })
    }

    handleFilter = () => {
        this.setState({
            refreshTable: !this.state.refreshTable
        })
    }

    handleClosePeriod = () => {
        const { selected } = this.state
        const { t } = this.props

        const request = {
            businessUnitIds: selected.map(period => period.id)
        }

        createClosedPeriods(request)
            .then((response) => {
                if (response.success) {
                    this.props.showMessage(t("Financial periods are closed"))
                    this.setState(state => ({
                        refreshTable: !state.refreshTable
                    }))
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    getTableData = (...params) => {
        const { search } = this.state

        return getClosedFinancialPeriodsReport(search, ...params)
    }

    handleSelect = (selected) => {
        this.setState({ selected })
    }

    render () {
        const { businessUnits, search, refreshTable, selected } = this.state
        const { t } = this.props

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks} />}>
                <MultiSelectFromItemsField
                    label={t('Parlour')}
                    name="businessUnitIds"
                    value={search.businessUnitIds}
                    onChange={this.handleChangeSearch}
                    fullWidth
                    items={businessUnits}
                />
                <ActionButton
                    onClick={this.handleFilter}
                    marginLabeledFields
                >
                    {t('Filter')}
                </ActionButton>
                <ActionButton
                    onClick={this.handleClosePeriod}
                    disabled={selected.length <= 0}
                    marginLabeledFields
                    fullWidth
                >
                    {t('Close financial period')}
                </ActionButton>
            </LeftBar>
            <Content title={t('Closed financial periods')}>
                <DataTable
                    columnData={this.columnData}
                    dataFunc={this.getTableData}
                    onSelect={this.handleSelect}
                    refresh={refreshTable}
                    autoload={false}
                    orderBy={"endedAt"}
                />
            </Content>
        </React.Fragment>
    }
}
