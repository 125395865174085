import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getTransactionsCash, navLinks} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {Link} from "react-router-dom";
import {loadMfrTransactionsCashFilter, saveMfrTransactionsCashFilter} from "../../services/localStorage";
import Tooltip from "@material-ui/core/Tooltip";
import {withTranslation} from "react-i18next";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";

const processReasonValue = value => {
    if (Array.isArray(value)) {
        return value.map((item, idx) => {
            let link;
            switch(item.type) {
                case 'Visit':
                    link = `/orders/view/${item.id}`;
                    break;
                case 'GoodStorageSale':
                    link = `/goods/sell/view/${item.id}`;
                    break;
                case 'PayDoc':
                    link = `/pay-docs/view/${item.id}`;
                    break;
                case 'PayDocRefill':
                    link = `/pay-docs/history/balance/${item.id}`;
                    break;

                default:
                    link = '#';
            }

            if (item.fullName) {
                return <span key={idx}>{item.typeText}: <Tooltip title={item.fullName}><Link key={idx} to={link}>{item.name}</Link></Tooltip></span>;
            }

            return <span key={idx}>{item.typeText}: <Link to={link}>{item.name}</Link></span>;
        })
    }

    return value;
};

const columnData = [
    { id: 'createdAt', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    { id: 'reasonClass', label: 'Deal (entity)' },
    { id: 'reasonName', label: 'Deal name', processValue: processReasonValue},
    { id: 'amountFormat', label: 'Amount' },
    { id: 'fromClass', label: 'From (type)' },
    { id: 'fromName', label: 'From' },
    { id: 'toClass', label: 'To (type)' },
    { id: 'toName', label: 'To' },
    { id: 'name', label: 'Name' },
    { id: 'businessUnitName', label: 'Business unit' },
    { id: 'creator', label: 'User' },
    { id: 'comment', label: 'Comment' },
];
const columnDataXlsx = [
    { id: 'createdAt', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    { id: 'reasonClass', label: 'Deal (entity)' },
    { id: 'reasonName', label: 'Deal name' },
    { id: 'debtReasonName', label: 'Debt name' },
    { id: 'amount.val', label: 'Amount' },
    { id: 'amount.cur', label: 'Currency' },
    { id: 'fromName', label: 'From' },
    { id: 'toName', label: 'To' },
    { id: 'name', label: 'Name' },
    { id: 'businessUnitName', label: 'Business unit' },
    { id: 'creator', label: 'User' },
    { id: 'comment', label: 'Comment' },
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class TransactionsCashListPage extends React.Component {
    constructor(props) {
        super(props);

        const search = loadMfrTransactionsCashFilter();
        if (search) {
            search.date_start = moment(search.date_start);
            search.date_end = moment(search.date_end);
        }

        this.state = {
            search: {
                search: null,
                date_start: moment(),
                date_end: moment(),
                businessUnitId: 0,
                toBusinessUnitIds: [],
                ...search,
            },
            refreshTable: false,
            businessUnits: [{ id: 0, name: this.props.t('---All---')}],
        };
    }

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        saveMfrTransactionsCashFilter(this.state.search);
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getTransactionsCash(search, ...params);
    };

    handleExport = () => {
        const { search, businessUnits } = this.state;
        const from = search.date_start;
        const to = search.date_end;
        const bu = search.businessUnitId ? businessUnits.find(item => item.id === search.businessUnitId).name : 'All';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnDataXlsx.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXlsx.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Transactions");
                    XLSX.writeFile(new_workbook, `Transactions ${bu} ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    transactionStyle = item => {
        if (item.direction === '+') {
            return {backgroundColor: '#e1ffde'}
        }
        if (item.direction === '-') {
            return {backgroundColor: '#ffd7e1'}
        }
        if (item.direction === 's-') {
            return {backgroundColor: '#cdcfff'}
        }
        if (item.direction === '=') {
            return {backgroundColor: '#ffe1c7'}
        }
    };

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t('Show moving by date interval')}
                        className={classes.filterControl}
                    />
                    <AnyField
                        id="parlour"
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        className={classes.filterControl}
                        label={t('Search')}
                    />
                    <MultiSelectFromItemsField
                        items={businessUnits}
                        emptySelectedLabel="---Не выбрано---"
                        label={t('To name')}
                        name={'toBusinessUnitIds'}
                        value={search.toBusinessUnitIds}
                        onChange={this.handleChange('toBusinessUnitIds')}
                        fullWidth
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Money moving')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        rowStyleFunc={this.transactionStyle}
                        autoload={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
