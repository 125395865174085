import React from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Grid, withStyles} from "@material-ui/core";
import {hasRole} from "../../services/user";
import {getCurrentCashBoxAndSafeValue} from "../../services/moneyFlow";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import cn from 'classnames';

export default
@withTranslation()
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
})
@withStyles({
    moneyState: {
        fontSize: 14
    }
})
class CashBoxAndSafeState extends React.Component
{
    static propTypes = {
        className: PropTypes.string,
    };

    state = {
        currentCashBoxAndSafeValue: null,
    };

    componentDidMount() {
        this.getCurrentCashBoxAndSafeValue(this.props.currentParlour);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentParlour !== this.props.currentParlour) {
            this.getCurrentCashBoxAndSafeValue(nextProps.currentParlour);
        }
    }

    getCurrentCashBoxAndSafeValue = parlour => {
        if (parlour) {
            this.setState({
                currentCashBoxAndSafeValue: null
            });
            getCurrentCashBoxAndSafeValue({'businessUnitId': parlour.id})
                .then(response => {
                    if (response.success) {
                        this.setState({
                            currentCashBoxAndSafeValue: response.data
                        });
                    }
                });
        }
    };

    render() {
        const { currentCashBoxAndSafeValue } = this.state;
        const { classes, t, className } = this.props;
        if (currentCashBoxAndSafeValue === null || Object.keys(currentCashBoxAndSafeValue).length === 0) {
            return null
        }
        if (!hasRole('ROLE_UI_TAB_MONEYFLOW_CURRENT_CASHBOX_SAFE_STATE')) {
            return null;
        }
        return <Grid item className={cn(classes.moneyState, className)}>
            { hasRole('ROLE_UI_TAB_MONEYFLOW') && hasRole('ROLE_UI_TAB_MONEYFLOW_SUMMARYDAYREPORT') ?
                <React.Fragment>
                    <Link to="/money-flow/summary-day-report">{t('In cash box')}:</Link> { currentCashBoxAndSafeValue.cash_box.join(' / ') }<br/>
                    {
                        hasRole('ROLE_UI_TAB_MONEYFLOW_CURRENT_SAFE_STATE')
                            && <React.Fragment><Link to="/money-flow/summary-day-report">{t('In safe')}:</Link> { currentCashBoxAndSafeValue.safe.join(' / ') }</React.Fragment>
                    }
                </React.Fragment>
                :
                <React.Fragment>
                    <div>{t('In cash box')}: { currentCashBoxAndSafeValue.cash_box.join(' / ') }</div>
                    {
                        hasRole('ROLE_UI_TAB_MONEYFLOW_CURRENT_SAFE_STATE') &&
                        <div>{t('In safe')}: {currentCashBoxAndSafeValue.safe.join(' / ')}</div>
                    }
                </React.Fragment>
            }
        </Grid>;
    }
}