import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid
} from '@material-ui/core';

import {navLinks} from "../../services/settings";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {createContactTarget} from "../../services/guests";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    container: {
        width: 700,
    },
});

class ViewContactTargetPage extends React.Component {

    state = {
        target: {
            name: null,
        },
    };

    componentDidMount() {
    }

    handleChange = prop => event => {
        const { target } = this.state;
        this.setState({ target: {...target, [prop]: event.target.value } });
    };

    handleSave = () => {
        createContactTarget(this.state.target)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/settings/targets`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes } = this.props;
        const { target } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton visible={!target.id} onClick={this.handleSave}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Тема взаимодействия">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={6}>
                            <AnyField
                                id="target"
                                label="Тема взаимодействия"
                                value={target.target}
                                fullWidth
                                onChange={this.handleChange('target')}
                                readOnly={target.id}
                            />
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {showMessage: messageDialogActions.show})(withStyles(styles)(ViewContactTargetPage));
