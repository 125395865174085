import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from "@material-ui/core";
import GuestField from "../../components/fields/GuestField";
import AnyField from "../../components/fields/AnyField";
import ShowField from "../../components/fields/ShowField";
import {withTranslation} from "react-i18next";
import {getDiscounts} from "../../services/calendar";
import {getPaymentTypes, getSale} from "../../services/sale";
import {recharge, removeRecharge, saveDeliveryPayDoc, update} from "../../services/payDocs";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import _ from 'lodash';

const deliveryCertificateClear = {
    id: null,
    guestId: null,
    amount: null,
    paymentType: null,
    discountId: null,
    saleId: null,
    businessUnitId: null,
    payDocId: null,
    payDocRefill: null,
};

@withTranslation()
@connect( null
,{
    showInfo: infoActions.show,
    showError: infoActions.showError,
})

class PayDocDeliveryDialog extends React.Component
{
    state = {
        deliveryCertificate: {...deliveryCertificateClear},
        discountsRefill: [],
        paymentTypes: [],
        sale: null
    }

    render() {
        const {deliveryCertificate, discountsRefill, paymentTypes, sale} = this.state;
        const {dialogDeliveryGuest, t, payDoc} = this.props;

        return <Dialog
            open={dialogDeliveryGuest}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="lock-dialog-title">{t('Send the payment document to the delivery')}</DialogTitle>
            <DialogContent >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GuestField
                            label={t("Guest")}
                            value={deliveryCertificate.guestId}
                            error={!deliveryCertificate.guestId}
                            onChange={this.handleDeliveryChange('guestId')}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            id="amount"
                            label={(payDoc.type.measure === 'money' ? t('payDoc.amountMoney') : t('payDoc.amountTime'))}
                            value={deliveryCertificate.amount}
                            onChange={this.handleDeliveryChange('amount')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            label={t('payDoc.discount')}
                            value={deliveryCertificate.discountId}
                            fullWidth
                            select
                            onChange={this.handleDeliveryChange('discountId')}
                        >
                            <MenuItem value={null}>{t('Not chosen')}</MenuItem>
                            {discountsRefill.map(discount => <MenuItem key={discount.id} value={discount.id}>{discount.name}</MenuItem>)};
                        </AnyField>
                    </Grid>
                    <Grid item xs={12}>
                        <AnyField
                            id="paymentType"
                            label={t('Payment type')}
                            value={ deliveryCertificate.paymentType}
                            fullWidth
                            onChange = { this.handleDeliveryChange('paymentType') }
                            select
                        >
                            {paymentTypes.map((payment, i) => {

                                    return payment.id !== 'paydoc' && <MenuItem key={i}
                                                                                value={payment.id}>{payment.text}</MenuItem>
                                }
                            )}
                        </AnyField>
                    </Grid>
                    {deliveryCertificate.amount && sale && sale.totalFormatted ?
                        <Grid item xs={12}>
                            <ShowField
                                label={t('For payment')}
                                value={sale && sale.totalFormatted}
                            />
                        </Grid>
                        : null
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleSendDelivery} disabled={!deliveryCertificate.paymentType || !deliveryCertificate.amount || !deliveryCertificate.guestId}  color="primary">OK</Button>
                <Button onClick={this.handleCloseDialog} color="primary">{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    }

    handleCloseDialog = () => {
        this.props.handleDialogDelivery(false)();

        removeRecharge(this.state.deliveryCertificate.payDocRefill)
            .then(() => {
                this.setState({
                    deliveryCertificate: {...deliveryCertificateClear}
                });
            })

    }

    componentDidMount() {
        getPaymentTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        paymentTypes: response.data,
                    })
                }
            })

        this.getDiscounts();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.payDoc) {
            this.setState({
                deliveryCertificate: {
                    ...this.state.deliveryCertificate,
                    businessUnitId: nextProps.payDoc.businessUnitId,
                    payDocId: nextProps.payDoc.id,
                }
            });
        }
    }

    deliveryCertificateCalculate = () => {
        const {deliveryCertificate} = this.state;

        if (
            !deliveryCertificate.businessUnitId ||
            !deliveryCertificate.guestId ||
            !deliveryCertificate.amount
        ) {
            return;
        }

        recharge(deliveryCertificate)
            .then(response => {
                if (response.success) {
                    this.setState({
                        deliveryCertificate: {
                            ...response.data,
                            payDocRefill: response.data.id,
                            paymentType: deliveryCertificate.paymentType,
                        },
                    }, this.getSale);
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            });
    }

    updateDataDebounce = _.debounce(this.deliveryCertificateCalculate, 1000);

    handleDeliveryChange = (prop) => (value) => {
        this.setState({
            deliveryCertificate: {
                ...this.state.deliveryCertificate,
                [prop]: value.target.value,
            }
        }, this.updateDataDebounce)
    }

    getSale = () => {
        if (this.state.deliveryCertificate.saleId) {
            getSale(this.state.deliveryCertificate.saleId)
                .then(response => {
                        if (response.success) {
                            this.setState({
                                sale: response.data,
                            })
                        }
                    }
                )
        }
    }

    handleSendDelivery = () => {
        const { deliveryCertificate } = this.state;
        const {t} = this.props;

        saveDeliveryPayDoc(deliveryCertificate)
            .then(response => {
                if (response.success) {
                    this.props.handleDialogDelivery(false)();

                    update(deliveryCertificate.payDocId, {status: 'delivery_guest', guestId: deliveryCertificate.guestId})
                        .then(response => {
                            if (response.success) {
                                this.props.loadDoc(deliveryCertificate.payDocId);
                                this.props.showInfo(t('Payment document has been sent for delivery'));
                                this.setState({
                                    deliveryCertificate: {
                                        guestId: null,
                                        amount: null,
                                        paymentType: null,
                                        discountId: null,
                                        saleId: null,
                                        businessUnitId: null,
                                        payDocId: null,
                                    }
                                });
                            } else {
                                this.props.showError(response.error ? response.error.message : response.message);
                            }
                        })
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            })

    }

    getDiscounts = () => {
        getDiscounts({
            forParlour: this.props.payDoc.businessUnitId,
            forSalableEntity: 'PayDoc'
        })
            .then(response => {
                if (response.success) {

                    let newState = {
                        discountsRefill:response.data,
                    };

                    this.setState( newState );
                }
            });
    }
}
export default PayDocDeliveryDialog