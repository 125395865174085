import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, DialogActions, Typography} from "@material-ui/core";
import ShowField from "../fields/ShowField";
import TimeField from "../fields/TimeField";
import Moment from 'moment';
import {extendMoment} from "moment-range";
import * as PropTypes from "prop-types";
import {
    activityTypes,
    saveActivityEmployee,
    BREAK_INDUSTRIAL_NECESSITY
} from "../../services/calendarEmployee";
import {withTranslation} from "react-i18next";
import messageDialogActions from "./messageDialog-acions";
import {connect} from "react-redux";
import {createChangeHandle} from "../../utils/helpers";
import SelectFromItemsField from "../fields/SelectFromItemsField";

const moment = extendMoment(Moment);

const POPUP_SPLIT = 'split';

@withTranslation()
@connect(()=>({}), {showMessage: messageDialogActions.show})
class SplitDialog extends React.Component {
    static propTypes = {
        parlours: PropTypes.array,
        selectedActivity: PropTypes.object,
        closeDialog: PropTypes.func,
        selectedMoment: PropTypes.object,
    }

    static defaultProps = {
        parlours: [],
        selectedActivity: null,
        selectedMoment: null,
    }

    constructor(props) {
        super(props);

        this.availableTimes = [15, 30, 45, 60].map(id => ({
            id,
            name: `${id} ${props.t('min')}`
        }));

        this.state = {
            splitTime: null,
            splitMoveTime: 15,
            splitParlour: null,
            filteredParlours: [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.splitDialogOpen !== this.props.splitDialogOpen && this.props.selectedActivity) {
            const { selectedMoment, selectedActivity, parlours } = this.props;
            const initSplitTime =
                selectedMoment ||
                moment(selectedActivity.timeStart).add(selectedActivity.duration / 2, 'minute').utcOffset(selectedActivity.parlour.timeZone);
            this.setState({
                splitTime: initSplitTime.clone(),
                splitParlour: null,
                splitMoveTime: 15,
                initSplitTime,
                filteredParlours: parlours.filter(parlour =>
                    parlour.id !== selectedActivity.parlour.id &&
                    parlour.regionId === selectedActivity.parlour.regionId
                ),
            })
        }
    }

    handleChange = createChangeHandle(this)

    getActivityTime = (newDuration = null) => {
        const { selectedActivity } = this.props

        const timeStart = selectedActivity && selectedActivity.timeStart != null ?
            moment(selectedActivity.timeStart)
                .utcOffset(selectedActivity.parlour.timeZone) :
            null
        const timeEnd = selectedActivity && selectedActivity.timeStart != null ?
            moment(selectedActivity.timeStart)
                .add(newDuration || selectedActivity.duration, 'minute')
                .utcOffset(selectedActivity.parlour.timeZone) :
            null

        return {
            timeStart,
            timeEnd
        }
    }

    getWorkTime = () => {
        const { selectedActivity } = this.props
        const { filteredParlours, splitParlour } = this.state
        const { timeStart } = this.getActivityTime()

        const currentWorkTime = selectedActivity?.parlour?.workTime?.[`${timeStart?.isoWeekday()}`]
        const splitParlourWorkTime = filteredParlours.find((item) => item.id === splitParlour)?.workTime?.[`${timeStart?.isoWeekday()}`]
        const currentWorkTimeEnd = currentWorkTime && timeStart ?
            timeStart.clone().startOf('day').add(moment.duration(currentWorkTime?.finish))
            : null
        const splitWorkTimeStart = splitParlourWorkTime && timeStart ?
            timeStart.clone().startOf('day').add(moment.duration(splitParlourWorkTime?.start))
            : null
        const splitWorkTimeEnd = splitParlourWorkTime && timeStart ?
            timeStart.clone().startOf('day').add(moment.duration(splitParlourWorkTime?.finish))
            : null

        return {
            currentWorkTimeEnd,
            splitWorkTimeStart,
            splitWorkTimeEnd
        }
    }

    render() {
        const { selectedActivity, t, splitDialogOpen } = this.props;
        const { splitTime, splitParlour, splitMoveTime, initSplitTime, filteredParlours } = this.state;
        const { timeStart, timeEnd } = this.getActivityTime()
        const { currentWorkTimeEnd, splitWorkTimeEnd, splitWorkTimeStart } = this.getWorkTime()
        const errorSplitTime = this.validateSplitTime(timeStart, timeEnd);

        return selectedActivity && selectedActivity.activity !== activityTypes.internship && (
            <Dialog open={splitDialogOpen} maxWidth="xs" fullWidth onClose={this.handleActionSplitDialogCancel}>
                <DialogTitle>{t("SplitDialog.splitTime")}</DialogTitle>
                <DialogContent>
                    {selectedActivity && timeStart ?
                        <>
                            {
                                selectedActivity.employee
                                ?
                                    <div><Typography variant="body2">
                                    {selectedActivity.employee.employeePostText}: {selectedActivity.employee.name}
                                    </Typography></div>
                                : null
                            }
                            <ShowField
                                fullWidth
                                label={t("SplitDialog.selectedBlock")}
                            >
                                {timeStart.format('HH:mm')}
                                -
                                {timeEnd.format('HH:mm')}
                            </ShowField>
                            <TimeField
                                label={t("SplitDialog.since")}
                                value={splitTime}
                                initialFocusedDate={initSplitTime}
                                onChange={this.handleChangeSplitTime}
                                fullWidth
                                minutesStep={15}
                                error={errorSplitTime}
                                helperText={splitWorkTimeStart && splitWorkTimeStart > splitTime ? t('SplitDialog.targetWorkTime', { time: splitWorkTimeStart.format('HH:mm')}) : null}
                            />
                            <SelectFromItemsField
                                label={t("SplitDialog.transferSalon")}
                                value={splitParlour}
                                name="splitParlour"
                                onChange={this.handleChange}
                                required
                                fullWidth
                                error={!splitParlour}
                                items={filteredParlours}
                                helperText={
                                    currentWorkTimeEnd && splitWorkTimeEnd && !currentWorkTimeEnd.isSame(splitWorkTimeEnd, 'minute') ?
                                        t('SplitDialog.workingTimeDifferent') :
                                        null
                                }
                            />
                            <SelectFromItemsField
                                items={this.availableTimes}
                                label={t("SplitDialog.timeMove")}
                                value={splitMoveTime}
                                name="splitMoveTime"
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </>
                        :null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleActionSplitDialogSuccess} color="primary" disabled={errorSplitTime || !splitParlour}>OK</Button>
                    <Button onClick={this.handleActionSplitDialogCancel} color="primary">{t('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleActionSplitDialogCancel = () => {
        this.props.closeDialog(POPUP_SPLIT);
        this.setState({
            splitTime: null,
            splitParlour: null,
            selectedActivity: null,
            splitMoveTime: 15,
        });
    }

    handleActionSplitDialogSuccess = () => {
        const { selectedActivity } = this.props;
        const { splitTime, splitParlour, splitMoveTime } = this.state;
        const { currentWorkTimeEnd, splitWorkTimeEnd } = this.getWorkTime()
        const currentDuration = currentWorkTimeEnd.isSame(splitWorkTimeEnd, 'minute') ?
            selectedActivity.duration :
            selectedActivity.duration - currentWorkTimeEnd.diff(splitWorkTimeEnd, 'minute')
        const { timeStart, timeEnd } = this.getActivityTime(currentDuration)
        const duration1 =
            splitTime.isSame(timeStart) ?
                currentDuration :
                splitTime.diff(timeStart, 'minutes') - splitMoveTime;
        const duration2 = timeEnd.diff(splitTime, 'minutes');

        let activitiesEmployee = [];

        if (splitTime.isSame(timeStart)) {
             activitiesEmployee.push({
                id: selectedActivity.id,
                employees: [selectedActivity.employee.id],
                duration: duration1,
                activity: selectedActivity.activity,
                parlour: splitParlour,
                timeStart: selectedActivity.timeStart,
                shift: selectedActivity.shift,
                approved: !!(selectedActivity.approved || selectedActivity.approved === undefined),
            });
        } else {
            if (duration1 > 0) {
                activitiesEmployee.push({
                    id: selectedActivity.id,
                    employees: [selectedActivity.employee.id],
                    duration: duration1,
                    activity: selectedActivity.activity,
                    parlour: selectedActivity.parlour.id,
                    timeStart: selectedActivity.timeStart,
                    shift: selectedActivity.shift,
                    approved: !!(selectedActivity.approved || selectedActivity.approved === undefined),
                });
            }

            activitiesEmployee.push({
                id: duration1 > 0 ? null : selectedActivity.id,
                employees: [selectedActivity.employee.id],
                duration: splitMoveTime,
                activity: activityTypes.break,
                breakType: BREAK_INDUSTRIAL_NECESSITY,
                parlour: selectedActivity.parlour.id,
                timeStart: moment(selectedActivity.timeStart).add(duration1, 'minute').utcOffset(selectedActivity.parlour.timeZone),
                shift: selectedActivity.shift,
                approved: !!(selectedActivity.approved || selectedActivity.approved === undefined),
            })

            activitiesEmployee.push({
                employees: [selectedActivity.employee.id],
                duration: duration2,
                activity: selectedActivity.activity,
                parlour: splitParlour,
                timeStart: splitTime.toISOString(),
                shift: selectedActivity.shift,
                approved: !!(selectedActivity.approved || selectedActivity.approved === undefined),
            });
        }

        saveActivityEmployee(activitiesEmployee)
            .then(response => {
                if (response.success) {
                    this.props.closeDialog(POPUP_SPLIT);

                    this.setState({
                        splitTime: null,
                        splitParlour: null,
                        selectedActivity: null,
                        splitMoveTime: 15,
                    });
                } else {
                    this.props.showMessage({
                        message: response.error ? response.error.message : response.message,
                        title: 'Ошибка создания табеля'
                    });
                }
            });
    }

    /**
     * @param {moment.Moment} timeStart
     * @param {moment.Moment} timeEnd
     * @returns {boolean}
     */
    validateSplitTime = (timeStart, timeEnd) => {
        const { splitTime, splitMoveTime } = this.state;
        const { selectedDayStart } = this.props;
        const { splitWorkTimeStart } = this.getWorkTime()

        return !splitTime
            || (
                !splitTime.isSame(selectedDayStart) &&
                splitTime.clone().subtract(splitMoveTime, 'minutes') < timeStart
               )
            || splitTime < timeStart
            || splitTime >= timeEnd
            || (splitWorkTimeStart && splitWorkTimeStart > splitTime);
    }

    handleChangeSplitTime = time => {
        const { selectedActivity } = this.props;

        if (selectedActivity && time) {
            const timeStart = moment(selectedActivity.timeStart).utcOffset(selectedActivity.parlour.timeZone)
            time.set({
                year: timeStart.get('year'),
                month: timeStart.get('month'),
                date: timeStart.get('date'),
                second: 0,
                millisecond: 0
            });
            time.utcOffset(selectedActivity.parlour.timeZone, true).startOf('minute');
        }

        this.setState({splitTime: time});
    }
}

export default SplitDialog;