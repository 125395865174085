import React from "react";
import {
    getCategories, getConsumptionGroup,
    getGoods,
    saveConsumptionGroup
} from "../../services/goods";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/goods";
import ShowField from "../../components/fields/ShowField";
import Content from "../../components/content/Content";
import {Grid} from "@material-ui/core";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import Typography from "@material-ui/core/Typography";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {withStyles} from "@material-ui/core/styles";
import {createChangeHandle} from "../../utils/helpers";

const emptyConsumptionGroup = {
    name: null,
    comment: null,
    goods: [],
};

const styles = theme => ({
    button: {
        marginTop: 28
    },
    heading: {
        marginTop: 15,
        marginBottom: 10
    }
});

const columnData = [
    { id: 'nameWithMass', label: 'Name' },
    { id: 'description', label: 'Description' },
    { id: 'massFormat', label: 'Mass' },
    { id: 'category.name', label: 'Category' },
];

@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
@withTranslation()
@withRouter
@withStyles(styles)
class ConsumptionGroupViewPage extends React.PureComponent
{
    state = {
        consumptionGroup: emptyConsumptionGroup,
        goods: [],
        categories: [],
        selectedGoodId: null,
        selectedCategoryId: null
    }

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getConsumptionGroup(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            consumptionGroup: response.data
                        })
                    }
                });
        }

        getCategories()
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.data,
                    })
                }
            });

        getGoods()
            .then(response => {
                if (response.success) {
                    this.setState({
                        goods: response.data,
                    })
                }
            });
    }

    handleChangeConsumption = createChangeHandle(this, 'consumptionGroup');
    handleChange = createChangeHandle(this);

    addGood = () => {
        let goods = [...this.state.consumptionGroup.goods];
        let selectedGood = this.state.goods.find(good => good.id === this.state.selectedGoodId);
        goods.push(selectedGood);
        this.setState({
            consumptionGroup: {
                ...this.state.consumptionGroup,
                goods: goods,
            },
            selectedGoodId: null
        });
    }

    deleteGood = (item, index) => {
        let goods = [...this.state.consumptionGroup.goods];
        goods.splice(index, 1);
        this.setState({
            consumptionGroup: {
                ...this.state.consumptionGroup,
                goods: goods,
            }
        });
    }

    saveConsumptionGroup = () => {
        const {t, showMessage, showInfo} = this.props;
        saveConsumptionGroup(this.state.consumptionGroup)
            .then(response => {
                if (response.success) {
                    showInfo(t('Consumption Group saved'));
                    this.setState({
                        consumptionGroup: response.data
                    });
                } else {
                    if (response.error && response.error.errors && response.error.errors.length) {
                        let errors = response.error.errors.map((error, idx) => <span key={idx}>{error.property} - {error.message}<br/></span>);
                        showMessage(errors);
                    } else {
                        showMessage(response.error ? response.error.message : response.message);
                    }

                }
            });
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/consumption-group`);
    };

    render() {
        const {
            consumptionGroup,
            goods,
            categories,
            selectedGoodId,
            selectedCategoryId
        } = this.state;

        const { t, classes } = this.props;

        let saveDisabled = !consumptionGroup.name;

        let filteredGoods = selectedCategoryId ? goods.filter(good => good.category.id === selectedCategoryId) : goods;

        return (
            <>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ShowField label={t('Creator')} value={consumptionGroup.creatorName} />
                    <ShowField label={t('Created at')} value={consumptionGroup.createdAt} dateFormat="DD.MM.YYYY HH:mm"/>
                    <ActionButton onClick={this.saveConsumptionGroup} disabled={saveDisabled}>{t('Save')}</ActionButton>
                    <ActionButton onClick={this.handleClickBack}>{t('Back')}</ActionButton>
                </LeftBar>
                <Content title={t('Consumption group card')}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <AnyField
                                value={consumptionGroup.name}
                                onChange={this.handleChangeConsumption}
                                name="name"
                                required
                                error={!consumptionGroup.name}
                                label={t('Name')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                value={consumptionGroup.comment}
                                onChange={this.handleChangeConsumption}
                                name="comment"
                                label={t('Comment')}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h5" className={classes.heading}>{t('Goods')}</Typography>
                    <DataTableLocal
                        columnData={ columnData }
                        data={ consumptionGroup.goods }
                        selector={false}
                        pagination={false}
                        disableSorting
                        onDelete={ this.deleteGood }
                    />
                    <Typography variant="h5" className={classes.heading}>{t('Add goods')}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <SelectFromItemsField
                                items={categories}
                                label={t('Category')}
                                value={selectedCategoryId}
                                valueField="id"
                                textField="name"
                                name="selectedCategoryId"
                                onChange={this.handleChange}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <AutocompleteSelectField
                                options={filteredGoods.map(item => ({value: item.id, label: `${item.nameWithMass}`}))}
                                value={selectedGoodId}
                                onChange={this.handleChange}
                                name="selectedGoodId"
                                label={t('Good')}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ActionButton onClick={this.addGood} disabled={!selectedGoodId} className={classes.button}>{t('Add good')}</ActionButton>
                        </Grid>
                    </Grid>
                </Content>
            </>
        );
    }
}

export default ConsumptionGroupViewPage;