import React from 'react';
import {
    Grid
} from '@material-ui/core';
import DataTable from "../../components/data-table/DataTable";
import {getReportExpired, getReportExpiredUrl} from "../../services/goods";
import ActionButton from "../../components/button/ActionButton";
import { connect } from "react-redux";

const columnData = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товаров' },
    { id: 'good.description', label: 'Описание товара', minWidth: 600 },
    { id: 'priceFormat', label: 'Цена товара' },
    { id: 'num', label: 'Номер экземпляра товара' },
    { id: 'expiration', label: 'Срок годности', dateFormat: "DD.MM.YYYY" },
    { id: 'currentMass', label: 'Текущая масса продукта' },
    { id: 'good.massFormat', label: 'Масса товара' },
    { id: 'good.purposeText', label: 'Предназначение' },
    { id: 'good.forParlour', label: 'Расход салона' },
    { id: 'good.forMaster', label: 'Расход мастера' },
    { id: 'good.expendable', label: 'Расходный товар' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'expiredDays', label: 'Количество дней просрочки' },
];

class ReportExpired extends React.Component
{
    render() {
        const { token } = this.props;

        return (
            <Grid container spacing={2}>
                <Grid item xs={9}>Просроченная косметика</Grid>
                <Grid item xs={3}><ActionButton href={getReportExpiredUrl({access_token: token.access_token, xls: 1})}>Выгрузить в Excel</ActionButton></Grid>
                <Grid item xs={12}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={getReportExpired}
                        selector={false}
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
});

export default connect(mapStateToProps, {})(ReportExpired);
