import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    withStyles
} from "@material-ui/core";
import { connect } from 'react-redux';
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import * as PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import DialogButton from "../../components/button/DialogButton";
import AnyField from "../../components/fields/AnyField";
import ActionButton from "../../components/button/ActionButton";

export default
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(theme => ({
    marginItem: {
        marginBottom: theme.spacing(2),
    },
}))
class VerifyClosingVisitBySmsDialog extends React.Component
{
    static propTypes = {
        data: PropTypes.object,
        handleSendCode: PropTypes.func,
        handleChange: PropTypes.func,
        handleSendAcceptCode: PropTypes.func,
        handleCancelCode: PropTypes.func,
    };

    handleCancelCode = () => {
        this.props.handleCancelCode();
    }

    handleChange = e => {
        this.props.handleChange(e);
    }

    handleSendCode = () => {
        this.props.handleSendCode();
    }

    handleSendAcceptCode = () => {
        this.props.handleSendAcceptCode();
    }

    render() {
        const { t, data } = this.props;

        return (
            <Dialog
                open={data.isVerify}
                onClose={this.handleClose}
                aria-labelledby="verify-visit--dialog-title"
                aria-describedby="visit-cancel-dialog-description"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="visit-cancel-dialog-title">{t('Close visit')}</DialogTitle>
                <DialogContent>
                    {
                        data.showCodeInput &&
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <AnyField
                                    label="Код"
                                    value={data.code}
                                    name="code"
                                    onChange={this.handleChange}
                                    required
                                    error={!data.code}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ActionButton
                                    style={{height: 38, marginTop: 22}}
                                    disabled={!Boolean(data.code)}
                                    onClick={this.handleSendAcceptCode}
                                >
                                    {t('Accept the code')}
                                </ActionButton>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>

                    <DialogButton
                        onClick={this.handleSendCode}
                        disabled={data.sentCode}>
                        {
                            data.sentCode
                                ? `Отправить повторно через ${data.timer} сек`
                                : t('Send code')
                        }
                    </DialogButton>

                    <DialogButton onClick={this.handleCancelCode} autoFocus>{t('Cancel')}</DialogButton>

                </DialogActions>
            </Dialog>

        );
    }
}