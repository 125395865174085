import React from 'react';
import {KeyboardDatePicker} from "@material-ui/pickers";
import * as PropTypes from 'prop-types';
import AnyField from "./AnyField";
import {ThemeProvider} from "@material-ui/styles";
import {dateFieldTheme} from "../../styles";

export default
class DateField2 extends React.Component
{
    static propTypes = {
        value: PropTypes.any,
        minDate: PropTypes.any,
        maxDate: PropTypes.any,
        initialFocusedDate: PropTypes.any,
        format: PropTypes.string,
        onChange: PropTypes.func,
        autoOk: PropTypes.bool,
        disablePast: PropTypes.bool,
        disableFuture: PropTypes.bool,
        animateYearScrolling: PropTypes.bool,
        labelFunc: PropTypes.func,
        renderDay: PropTypes.func,
        shouldDisableDate: PropTypes.func,
        allowKeyboardControl: PropTypes.bool,
        forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        fullWidth: PropTypes.bool,
        invalidDateMessage: PropTypes.node,
        minDateMessage: PropTypes.node,
        maxDateMessage: PropTypes.node,
        className: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        cancelLabel: PropTypes.string,
        todayLabel: PropTypes.string,
        mask: PropTypes.any,
        name: PropTypes.string,
        openTo: PropTypes.oneOf(['year', 'month', 'day']),
        views: PropTypes.arrayOf(PropTypes.oneOf(['year', 'month', 'day'])),
    };

    static defaultProps = {
        value: new Date(),
        format: 'DD.MM.YYYY',
        autoOk: true,
        minDate: '1900-01-01',
        maxDate: '2100-01-01',
        initialFocusedDate: undefined,
        disableFuture: false,
        disablePast: false,
        animateYearScrolling: false,
        allowKeyboardControl: true,
        renderDay: undefined,
        labelFunc: undefined,
        shouldDisableDate: undefined,
        forwardedRef: undefined,
        fullWidth: false,
        invalidDateMessage: 'Неверная дата',
        minDateMessage: 'Неверная дата',
        maxDateMessage: 'Неверная дата',
        cancelLabel: 'Отмена',
        todayLabel: 'Сегодня',
        mask: '__.__.____',
    };

    handleChange = date => {
        if (this.props.onChange) {
            this.props.onChange({target: { name: this.props.name, value: date }});
        }
    };

    render() {
        const { ...other } = this.props;

        return <ThemeProvider theme={dateFieldTheme}>
            <KeyboardDatePicker
                {...other}
                TextFieldComponent={AnyField}
                onChange={this.handleChange}


            />
        </ThemeProvider>;
    }
}
