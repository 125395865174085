import React from 'react';
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import * as PropTypes from 'prop-types';
import {history} from "../../history";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";

export default
@withRouter
@withTranslation()
class HistoryLeftFields extends React.Component
{
    static propTypes = {
        payDoc: PropTypes.object.isRequired,
    };

    handleClickBack = () => {
        const { payDoc } = this.props;

        history.push(`/pay-docs/view/${payDoc.id}`);
    };

    render() {
        const { payDoc, t } = this.props;

        return (
            <React.Fragment>
                <ShowField
                    label={t("Payment document")}
                    value={payDoc.num}
                />
                <ShowField
                    label={t("Type of payment document")}
                    value={payDoc.type ? payDoc.type.name : null}
                />
                <ShowField
                    label={t("Parlour")}
                    value={payDoc.parlour}
                />
                <ShowField
                    label={t("Balance")}
                    value={payDoc.balance}
                />
                <ShowField
                    label={t("Frozen")}
                    value={payDoc.frozen}
                />
                <ShowField
                    label={t("Guest phone")}
                    value={payDoc.phone}
                />
                <ShowField
                    label={t("Full name")}
                    value={payDoc.fio}
                    linkTo={`/guests/view/${payDoc.guestId}`}
                />
                <ShowField
                    label={t("Status")}
                    value={payDoc.status}
                />
                <br />
                <br />
                <ActionButton onClick={this.handleClickBack} visible={Boolean(payDoc.id)}>{t("Back")}</ActionButton>
            </React.Fragment>
        );
    }
}
