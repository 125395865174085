import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getHumanEmployees} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {navLinks} from "../../services/moneyFlow";
import moment from "moment";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {getBusinessUnitByRole} from "../../services/user";
import {getSalaryPreDebtsInfo, getSalaryEmployee, createSalaryDebts} from "../../services/salary";
import {
    dataTableGroupValueStrategy,
    formatArrayMoney, formatArrayMoneyForExcel,
    formatArrayString,
    formatMoney,
    fromToDates
} from "../../services/common";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import CheckboxField from "../../components/fields/CheckboxField";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup
} from "@material-ui/core";
import DateMonthField from "../../components/fields/DateMonthField";
import Typography from "@material-ui/core/Typography";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import infoActions from "../../components/info/info-actions";
import {Link} from "react-router-dom";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import {createChangeHandle} from "../../utils/helpers";

const notDetailedColumns = ['employeeName', 'day', 'businessUnitName', 'settingsName', 'startFrom', 'resultTotalFormat'];

const columnData = [
    { id: 'employeeName', label: 'Employee', processValue: (name, _, row) => {
        let fullName = '';
        if (row.rows && row.rows.length > 0 && (row.rows[0].firstName || row.rows[0].lastName || row.rows[0].thirdName)) {
            fullName = ` (${[row.rows[0].lastName, row.rows[0].firstName, row.rows[0].thirdName].join(' ')})`;
        }

        if (row.firstName || row.lastName || row.thirdName) {
            fullName = ` (${[row.lastName, row.firstName, row.thirdName].join(' ')})`;
        }

        return (name !== null ? name : '') + fullName;
    } },
    { id: 'day', label: 'Day', dateFormat: 'DD.MM.YYYY', groupStrategy: dataTableGroupValueStrategy.fromTo('day'), parentProcessValue: fromToDates('DD.MM.YYYY') },
    { id: 'businessUnitName', label: 'Business unit', groupStrategy: dataTableGroupValueStrategy.concat('businessUnitName')},
    { id: 'activityTextArr', label: 'Activity', processValue: val => val ? val.join(', ') : null, groupStrategy: dataTableGroupValueStrategy.appendArray('activityTextArr'), parentProcessValue: (arr) => _.uniq(arr).join(', ')},
    { id: 'activityDuration', label: 'Activity duration h', groupStrategy: dataTableGroupValueStrategy.sum('activityDuration'), processValue: value => Math.round(value*1000) / 1000.0},
    //{ id: 'resultWorkedShift', label: 'Activity duration shift', groupStrategy: dataTableGroupValueStrategy.sum('resultWorkedShift'), processValue: value => Math.round(value*1000) / 1000.0},
    //{ id: 'resultMonthWorkDays', label: 'WD in month', groupStrategy: dataTableGroupValueStrategy.same('resultMonthWorkDays'), summaryGroupStrategy: () => ''},
    //{ id: 'resultMonthWorkHours', label: 'WT in month', groupStrategy: dataTableGroupValueStrategy.same('resultMonthWorkHours'), summaryGroupStrategy: () => ''},
    { id: 'settingsName', label: 'Salary program', groupStrategy: dataTableGroupValueStrategy.concat('settingsName'), summaryGroupStrategy: () => ''},
    { id: 'startFrom', label: 'Start from', dateFormat: 'DD.MM.YYYY', groupStrategy: dataTableGroupValueStrategy.same('startFrom')},
    { id: 'settingsFunctionText', label: 'Salary function', groupStrategy: dataTableGroupValueStrategy.concat('settingsFunctionText'), summaryGroupStrategy: () => ''},
    { id: 'resultTotalFormat', label: 'Salary total', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultTotalWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultWageFormat', label: 'Salary wage', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultWageWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    //{ id: 'resultVariableFormat', label: 'Salary variable', groupStrategy:  dataTableGroupValueStrategy.arrayMoneySum('resultVariableWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentCreatedVisitsFormat', label: '% Visits created', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentCreatedVisitsWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentClosedVisitsFormat', label: '% Visits closed', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentClosedVisitsWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultHourCreatedVisitMoneyFormat', label: '% Visits hours', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultHourCreatedVisitMoneyWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultHourCreatedVisitMoneyNotMasterMoneyFormat', label: '% Visits hours (Not Master)', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultHourCreatedVisitMoneyNotMasterMoneyWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    //{ id: 'resultPercentVisitsFormat', label: '% Visits', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentVisitsWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentPayDocsFormat', label: '% PD', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentPayDocsWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentGoodsPDFormat', label: '% Goods PD', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentGoodsPDWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentGoodsMoneyFormat', label: '% Goods not PD', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentGoodsMoneyWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentIncomingFormat', label: '% Incoming', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentIncomingWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultWorkedHoursVisit', label: 'Visits hours', groupStrategy: dataTableGroupValueStrategy.sum('resultWorkedHoursVisit')},
    { id: 'resultWorkedHoursVisitNotMaster', label: 'Visits hours ( Not Master)', groupStrategy: dataTableGroupValueStrategy.sum('resultWorkedHoursVisitNotMaster')},
    { id: 'resultRqVisits', label: 'RQ Visits', groupStrategy: dataTableGroupValueStrategy.sum('resultRqVisits')},
    { id: 'resultRqHours', label: 'RQ hours', groupStrategy: dataTableGroupValueStrategy.sum('resultRqHours')},
    { id: 'resultRqFormat', label: 'RQ Total', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultRqWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultDelayedSaleFormat', label: 'Delayed sales', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultDelayedSaleWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'firstName', label: 'firstName' },
    { id: 'lastName', label: 'lastName' },
    { id: 'thirdName', label: 'thirdName' },
];

const columnDataNotDetailed = columnData.filter(row => notDetailedColumns.indexOf(row.id) !== -1);

const columnDataXlsx = [
    { id: 'employeeName', label: 'EmployeeShortName' },
    { id: 'employeeFullName', label: 'EmployeeFullName' },
    { id: 'day', label: 'Day', asDate: true },
    { id: 'businessUnitName', label: 'Business unit' },
    { id: 'activityText', label: 'Activity' },
    { id: 'activityDuration', label: 'Activity duration h' },
    { id: 'settingsName', label: 'Salary program' },
    { id: 'settingsFunctionText', label: 'Salary function' },
    { id: 'resultWage', label: 'Salary wage', processValue: formatArrayMoneyForExcel },
    //{ id: 'resultVariable', label: 'Salary variable', processValue: formatArrayMoneyForExcel },
    { id: 'resultTotalFormat', label: 'Salary total', processValue: formatArrayMoneyForExcel },
    //{ id: 'resultPercentVisits', label: '% Визиты', processValue: formatArrayMoneyForExcel },
    { id: 'resultPercentCreatedVisits', label: '% Визиты созд.', processValue: formatArrayMoneyForExcel},
    { id: 'resultPercentVisitsFormat', label: '% Визиты закр.', processValue: formatArrayMoneyForExcel},
    { id: 'resultHourCreatedVisitMoney', label: '% Визиты часы.', processValue: formatArrayMoneyForExcel},
    { id: 'resultHourCreatedVisitMoneyNotMasterMoney', label: '% Визиты часы. (не мастер)', processValue: formatArrayMoneyForExcel},
    { id: 'resultPercentPayDocs', label: '% ПД', processValue: formatArrayMoneyForExcel },
    { id: 'resultPercentGoodsPD', label: '% Товары ПД', processValue: formatArrayMoneyForExcel },
    { id: 'resultPercentGoodsMoney', label: '% Товары не ПД', processValue: formatArrayMoneyForExcel },
    { id: 'resultPercentIncoming', label: '% Выручка', processValue: formatArrayMoneyForExcel },
    { id: 'resultWorkedHoursVisit', label: 'Часы визитов' },
    { id: 'resultWorkedHoursVisitNotMaster', label: 'Часы визитов (не массаж)' },
    { id: 'resultDelayedSale', label: 'Отложенные продажи' },

];
const columnDataXlsxOld = [
    { id: 'employeeName', label: 'EmployeeShortName' },
    { id: 'employeeFullName', label: 'EmployeeFullName' },
    { id: 'day', label: 'Day', asDate: true },
    { id: 'businessUnitName', label: 'Business unit' },
    { id: 'activityText', label: 'Activity' },
    { id: 'activityDuration', label: 'Activity duration h' },
    { id: 'settingsName', label: 'Salary program' },
    { id: 'settingsFunctionText', label: 'Salary function' },
    { id: 'resultWage', label: 'Salary wage', processValue: formatArrayMoneyForExcel },
    { id: 'resultVariable', label: 'Salary variable', processValue: formatArrayMoneyForExcel },
    { id: 'resultTotalFormat', label: 'Salary total', processValue: formatArrayMoneyForExcel },
    // { id: 'resultPercentVisits', label: '% Визиты', processValue: formatArrayMoneyForExcel },
    { id: 'resultPercentVisitsFormat', label: '% Визиты закр.', processValue: formatArrayMoneyForExcel},
    { id: 'resultPercentPayDocs', label: '% ПД', processValue: formatArrayMoneyForExcel },
    { id: 'resultPercentGoodsPD', label: '% Товары ПД', processValue: formatArrayMoneyForExcel },
    { id: 'resultPercentGoodsMoney', label: '% Товары не ПД', processValue: formatArrayMoneyForExcel },
    { id: 'resultPercentIncoming', label: '% Выручка', processValue: formatArrayMoneyForExcel },
    { id: 'resultWorkedHoursVisit', label: 'Часы визитов' },
    { id: 'resultWorkedHoursVisitNotMaster', label: 'Часы визитов (не массаж)' },
    { id: 'resultDelayedSale', label: 'Отложенные продажи' },
];

const preDebtsColumns = [
    { id: 'employeeName', label: 'Employee', },
    { id: 'amount', label: 'Amount', processValue: formatMoney},
    { id: 'spendingTypeName', label: 'Spending type', processValue: (value) => value === null ? 'Не выбрано' : value},
    { id: 'month', label: 'Debt apply', dateFormat: 'MM.YYYY'},
    { id: 'existedDebt', label: 'Existed debt', processValue: (value) => value ? <Link to={`/money-flow/debt/id/${value.id}`} target="_blank">{value.createdAt} / {value.creator} / {formatMoney(value.total)}</Link> : null },
];

export default
@connect(null,{
    showMessage: messageDialogActions.show,
    showInfo: infoActions.show
})
@withStyles(theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
}))
@withTranslation()

class SalaryEmployeeListPage extends React.Component {

    constructor(props) {
        super(props);

        const querySearch = getFilterFromQuery();

        let date_start = moment();
        let date_end = moment();
        let businessUnitIds = [];
        let employeeBusinessUnitId = null;
        let employeeIds = [];
        let dateType = 'period';
        let month = moment().add(-10, 'day').startOf('month');
        let groupByDay = true;
        let employeeNameType = 'short';

        if (querySearch) {
            if (querySearch.date_start) {
                date_start = moment(querySearch.date_start);
            }
            if (querySearch.date_end) {
                date_end = moment(querySearch.date_end);
            }
            if (querySearch.businessUnitIds) {
                businessUnitIds = querySearch.businessUnitIds;
            }
            if (querySearch.employeeBusinessUnitId) {
                employeeBusinessUnitId = querySearch.employeeBusinessUnitId;
            }
            if (querySearch.employeeIds && querySearch.employeeIds.length) {
                employeeIds = querySearch.employeeIds;
            }

            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }

            if (querySearch.month) {
                month = moment(querySearch.month);
            }

            if (querySearch.groupByDay) {
                groupByDay = querySearch.groupByDay;
            }
        }

        this.state = {
            search: {
                date_start,
                date_end,
                businessUnitIds,
                employeeIds,
                dateType,
                month,
                employeeBusinessUnitId,
                groupByDay,
                employeeNameType
            },
            detailedView: false,
            refreshTable: false,
            businessUnits: [],
            employees: [],
            columnData: columnDataNotDetailed,
            firstShow: true,
            selected: [],
            preDebts: [],
            preDebtsModalOpen: false
        };
    }

    componentDidMount() {
        getBusinessUnitByRole('ROLE_SALARY_EMPLOYEE_GET')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
        getHumanEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    })
                }
            })
    }

    handleChange = createChangeHandle(this, 'search');

    handleClickFilter = () => {
        pushFilterToQuery(processMomentFieldInObject(this.state.search, ['date_start', 'date_end', 'month']));
        this.setState(state => ({
            refreshTable: !state.refreshTable,
            firstShow: false
        }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getSalaryEmployee(search, ...params);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleExport = () => {
        const { search, businessUnits, employees } = this.state;
        const { t } = this.props;
        const period = search.dateType === 'month' ?
            moment(search.month).format('MMMM YYYY') :
            moment(search.date_start).format('DD.MM.YYYY') + ' - ' +
            moment(search.date_end).format('DD.MM.YYYY')
        ;
        const bus = search.businessUnitIds ? businessUnits.filter(item => search.businessUnitIds.indexOf(item.id) !== -1).map(item => item.name).join(',') : '';
        const e = search.employeeIds.length ? employees.filter(item => search.employeeIds.indexOf(item.id) !== -1).map(item => item.name).join(',') : '';

        this.getTableData(search, 1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnDataXlsx.map(item => t(item.label)),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXlsx.map(item => {
                                let value = _.get(row, item.id);

                                if (item.processValue) {
                                    value = item.processValue(value);
                                }

                                if (item.asDate) {
                                    value = moment(value).toDate();
                                }

                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Salary");
                    XLSX.writeFile(new_workbook, `Salary ${bus} ${e} ${period}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleExportOld = () => {
        const { search, businessUnits, employees } = this.state;
        const { t } = this.props;
        const period = search.dateType === 'month' ?
            moment(search.month).format('MMMM YYYY') :
            moment(search.date_start).format('DD.MM.YYYY') + ' - ' +
            moment(search.date_end).format('DD.MM.YYYY')
        ;
        const bus = search.businessUnitIds ? businessUnits.filter(item => search.businessUnitIds.indexOf(item.id) !== -1).map(item => item.name).join(',') : '';
        const e = search.employeeIds.length ? employees.filter(item => search.employeeIds.indexOf(item.id) !== -1).map(item => item.name).join(',') : '';

        this.getTableData(search, 1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnDataXlsxOld.map(item => t(item.label)),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXlsxOld.map(item => {
                                let value = _.get(row, item.id);

                                if (item.processValue) {
                                    value = item.processValue(value);
                                }

                                if (item.asDate) {
                                    value = moment(value).toDate();
                                }

                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Salary");
                    XLSX.writeFile(new_workbook, `Salary ${bus} ${e} ${period}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    changeView = () => {
        const newView = !this.state.detailedView;
        this.setState({
            detailedView: newView,
            columnData: newView ? columnData : columnDataNotDetailed,
            refreshTable: !this.state.refreshTable
        });
    };

    getSalaryPreDebtsInfo = () => {
        let selected = this.state.selected.map(item => item.id);
        let params = { ...this.state.search, selected };
        getSalaryPreDebtsInfo(params)
            .then(response => {
                if (response.success) {
                    this.setState({
                        preDebts: response.data,
                        preDebtsModalOpen: true,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    closePreDebtsModal = () => {
        this.setState({
            preDebtsModalOpen: false
        })
    }

    rowsStyle = row => {
        if ( row.spendingTypeId === null || row.existedDebt !== null) {
            return {backgroundColor: '#ffd7e1'}
        } else {
            return {backgroundColor: '#e1ffde'}
        }
    };

    createSalaryDebts = () => {
        const { t } = this.props;
        createSalaryDebts(this.state.preDebts)
            .then(response => {
                if (response.success) {
                    this.setState({
                        preDebtsModalOpen: false,
                    });
                    this.props.showInfo(t('debts_created_count', {count: response.data.count}));
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleChangeEmployeeIds = event => {
        this.setState({
            ...this.state,
            search: {
                ...this.state.search,
                employeeIds: event.target.value
            }
        });
    }

    handleChangeEmployeeNameType = event => {
        this.setState({
            ...this.state,
            search: {
                ...this.state.search,
                employeeNameType: event.target.value,
                employeeIds: []
            }
        })
    }

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits, employees, detailedView, firstShow, selected, preDebtsModalOpen } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.dateType}
                            name="dateType"
                            onChange={this.handleChange}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="period" label="за период" />
                            <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
                        </RadioGroup>
                    </FormControl>
                    {search.dateType === 'period' ?
                        <DatePeriodField2
                            valueFrom={search.date_start}
                            valueTo={search.date_end}
                            maxDate={moment()}
                            nameFrom="date_start"
                            nameTo="date_end"
                            onChangeFrom={this.handleChange}
                            onChangeTo={this.handleChange}
                            className={classes.filterControl}
                        />
                        :
                        <DateMonthField
                            value={search.month}
                            name="month"
                            onChange={this.handleChange}
                            fullWidth
                        />
                    }
                    <SelectFromItemsField
                        label="Сотрудники бизнесс единицы"
                        value={search.employeeBusinessUnitId}
                        name="employeeBusinessUnitId"
                        onChange={this.handleChange}
                        fullWidth
                        className={classes.filterControl}
                        items={businessUnits}
                        nullable
                    />
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.employeeNameType}
                            name="employeeNameType"
                            onChange={this.handleChangeEmployeeNameType}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="short" label="Отображаемое имя" />
                            <FormControlLabel control={<Radio/>} value="full" label="ФИО"/>
                        </RadioGroup>
                    </FormControl>
                    {
                        search.employeeNameType === 'short' &&
                        <AutocompleteMultiSelectField
                            label={t('Employee')}
                            value={search.employeeIds}
                            name="employeeIds"
                            onChange={this.handleChangeEmployeeIds}
                            fullWidth
                            className={classes.filterControl}
                            options={employees}
                            placeholder="---Все---"
                        />
                    }
                    {
                        search.employeeNameType === 'full' &&
                        <AutocompleteMultiSelectField
                            label={t('Employee')}
                            value={search.employeeIds}
                            name="employeeIds"
                            onChange={this.handleChangeEmployeeIds}
                            fullWidth
                            className={classes.filterControl}
                            options={employees.filter(e => e.firstName || e.lastName || e.thirdName)
                                .map(e => {
                                    return {id: e.id, name: `${e.lastName ?? ''} ${e.firstName ?? ''} ${e.thirdName ?? ''}`}
                                })
                            }
                            placeholder="---Все---"
                        />
                    }
                    <AutocompleteMultiSelectField
                        label="Данные только бизнес юнита"
                        value={search.businessUnitIds}
                        name="businessUnitIds"
                        onChange={this.handleChange}
                        fullWidth
                        className={classes.filterControl}
                        options={businessUnits}
                        placeholder={"--Все--"}

                    />
                    <CheckboxField
                        label={t('Группировать по дням')}
                        value={!!search.groupByDay}
                        name="groupByDay"
                        onChange={this.handleChange}
                        className={classes.filterControl}
                    />
                    <CheckboxField
                        label={t('Detailed view')}
                        value={detailedView}
                        onChange={this.changeView}
                        className={classes.filterControl}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                    <ActionButton onClick={this.handleExportOld}>Excel старый</ActionButton>
                    <ActionButton onClick={this.getSalaryPreDebtsInfo} disabled={search.dateType === 'period' || !selected.length}>{ t('Create debts') }</ActionButton>
                </LeftBar>
                <Content title={t('Employee salary')}>
                    {!firstShow &&
                    <DataTable
                        columnData={this.state.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        pagination={false}
                        groupBy="employeeName"
                        onSelect={this.handleSelect}
                        disableSorting
                        summary
                        selector
                        selectOnlyParent
                        groupHiddenColumns={[{id: 'id'}]}
                        autoload={false}
                    />
                    }
                </Content>
                { preDebtsModalOpen && this.renderPreDebtsModal() }
            </React.Fragment>
        );
    };

    renderPreDebtsModal = () => {
        const { preDebts, preDebtsModalOpen } = this.state;
        const { t } = this.props;

        return (
            <Dialog
                open={preDebtsModalOpen}
                fullWidth
                maxWidth="lg"
                onClose={this.closePreDebtsModal}
            >
                <DialogTitle>{t('Debts will be created')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {preDebts.map((businessUnit) =>
                            <Grid item xs={12} key={businessUnit.businessUnitName}>
                                <Typography variant="h5">{businessUnit.businessUnitName}</Typography>
                                <DataTableLocal
                                    columnData={preDebtsColumns}
                                    data={businessUnit.debts}
                                    selector={false}
                                    pagination={false}
                                    disableSorting
                                    rowStyleFunc={this.rowsStyle}
                                />
                            </Grid>
                        )
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.createSalaryDebts} color="primary">{t("Create debts")}</Button>
                    <Button onClick={this.closePreDebtsModal} color="primary">{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
