import React from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, MenuItem
} from '@material-ui/core';
import {
    getPayDocsNew,
    setBalances,
    createRequest,
    requestTypes,
    navLinks,
    groupNames, groupPayDocsList
} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import _ from 'lodash';
import DataTableLocal from "../../components/data-table/DataTableLocal";
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import NumberFormatCurrency from "../../components/fields/NumberFormatCurrency";
import SymbolRub from "../../components/typography/SymbolRub";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import ShowField from "../../components/fields/ShowField";
import NumberRangeField from "../../components/fields/NumberRangeField";
import {getCounterparts} from "../../services/user";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'num', numeric: false, disablePadding: true, label: 'Identifier' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Guest phone' },
    { id: 'fio', numeric: false, disablePadding: false, label: 'Full name' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'parlour', numeric: false, label: 'Request from parlour' },
    { id: 'nominal', numeric: true, disablePadding: false, label: 'Nominal' },
];
const columnDataDialog = [
    { id: 'code', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'min', numeric: false, disablePadding: false, label: 'Min' },
    { id: 'max', numeric: false, disablePadding: false, label: 'Max' },
];

export default
@connect(null, {showMessage: messageDialogActions.show})
@withTranslation()
class MakePage extends React.Component {

    state = {
        search: '',
        searchFrom: '',
        searchTo: '',
        selected: [],
        balance: '',
        balanceDialogOpen: false,
        refreshData: false,
        makeDialogOpen: false,
        counterpartId: false,
        comment: '',
        progress: false,
        technicalProject: null,
        cardDesigns: [],
        counterparts: [],
        ranges: [],
    };

    debounceRefresh = _.debounce(()=>{
        this.setState(state => ({refreshData: !state.refreshData}));
    }, 500);

    componentDidMount() {
        getCounterparts()
            .then(response => {
                if (response.success) {
                    this.setState({
                        counterparts: response.data,
                    })
                }
            });
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });

        if (prop === 'searchFrom' || prop === 'searchTo') {
            this.debounceRefresh();
        }
    };

    handleClickSearch = () => {
        this.setState(state => ({refreshData: !state.refreshData}));
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleSetBalance = () => {
        this.setState({balance:'', balanceDialogOpen: true});
    };

    handleMake = () => {
        const withoutNominal = this.filterNominal(this.state.selected).filter(item => item.nominal === null || item.nominal === undefined);

        if (withoutNominal.length) {
            this.props.showMessage('Необходимо задать номиналы у ПД');

        } else {
            const { selected } = this.state;

            this.setState({
                makeDialogOpen: true,
                comment: '',
                technicalProject: null,
                cardDesigns: [],
                technicalProjectLoading: false,
                cardDesignsLoading: false,
                counterpartId: null,
                ranges: groupPayDocsList(selected),
            });

        }
    };

    filterNominal(selected) {
        return selected.filter(item => item.type.canSetNominal);
    }

    handleCloseBalanceDialog = ok => () => {
        const { selected, balance, refreshData } = this.state;

        if (!ok) {
            this.setState({balanceDialogOpen: false});
            return;
        }

        setBalances(selected.map(item => item.id), balance)
            .then(response => {
                if (response.success) {
                    this.setState({balanceDialogOpen: false, refreshData: !refreshData});
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    getTableData = (...params) => {
        const {search, searchFrom, searchTo} = this.state;
        return getPayDocsNew(search, searchFrom, searchTo, ...params);
    };

    handleCloseMakeDialog = ok => () => {
        const { selected, comment, technicalProject, cardDesigns, counterpartId } = this.state;

        if (!ok) {
            this.setState({
                makeDialogOpen: false,
            });
            return;
        }

        this.setState({
            progress: true
        });

        createRequest(
            selected.map(item => item.id),
            requestTypes.make,
            {
                comment,
                technicalProject: technicalProject ? technicalProject.id : null,
                cardDesigns: cardDesigns.map(item => item.id),
                counterpartId
            }
            )
            .then(response => {
                this.setState({
                    progress: false
                });
                if (response.success) {
                    this.props.history.push(`/pay-docs/requests/view/${response.data.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    render() {
        const { t } = this.props;
        const {
            search,
            searchFrom,
            searchTo,
            selected,
            balance,
            balanceDialogOpen,
            refreshData,
            makeDialogOpen,
            comment,
            progress,
            technicalProject,
            cardDesigns,
            counterpartId,
            counterparts,
            ranges
        } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search || ''}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label={t("Search for payment documents")}
                    />
                    <NumberRangeField
                        label={t("Search by number range of payment documents")}
                        valueFrom={searchFrom}
                        valueTo={searchTo}
                        onChangeFrom={this.handleChange('searchFrom')}
                        onChangeTo={this.handleChange('searchTo')}
                        label2Lines
                    />
                    <ShowField
                        label={t("Payment documents selected")}
                        value={selected.length}
                    />
                    <br />
                    <br />
                    <ActionButton disabled={!(selected.length && this.filterNominal(selected).length)} onClick={this.handleSetBalance}>Назначить сумму номинального сертификата</ActionButton>
                    <ActionButton disabled={!selected.length} onClick={this.handleMake}>Сформировать заявку в производство</ActionButton>
                </LeftBar>
                <Content title={t("Formation of the request in production")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/pay-docs/view/${item.id}`}
                        onSelect={this.handleSelect}
                        refresh={refreshData}
                    />
                </Content>
                <Dialog
                    open={balanceDialogOpen}
                    onClose={this.handleCloseBalanceDialog(false)}
                    aria-labelledby="balance-dialog-title"
                >
                    <DialogTitle id="balance-dialog-title">Назначить сумму номинального сертификата</DialogTitle>
                    <DialogContent>
                        <ShowField
                            label={t("Payment documents")}
                            value={groupNames(this.filterNominal(selected).map(item => item.num))}
                        />
                        <AnyField
                            autoFocus
                            id="balance"
                            label="Сумма"
                            value={balance}
                            onChange={this.handleChange('balance')}
                            InputProps={{
                                inputComponent: NumberFormatCurrency,
                                endAdornment: <SymbolRub/>,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseBalanceDialog(true)} disabled={!balance} color="primary">OK</Button>
                        <Button onClick={this.handleCloseBalanceDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={makeDialogOpen}
                    onClose={this.handleCloseMakeDialog(false)}
                    aria-labelledby="balance-dialog-title"
                    maxWidth={false}
                >
                    <DialogTitle id="balance-dialog-title">Подтверждение заявки на изготовление платежных документов</DialogTitle>
                    <DialogContent>
                        <DataTableLocal
                            data={ranges}
                            selector={false}
                            columnData={columnDataDialog}
                            pagination={true}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <AnyField
                                    autoFocus
                                    id="comment"
                                    label={t("Comment")}
                                    multiline
                                    rows="4"
                                    value={comment}
                                    onChange={this.handleChange('comment')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AnyField
                                    label="Контрагент"
                                    value={counterpartId}
                                    required
                                    error={!counterpartId}
                                    select
                                    fullWidth
                                    onChange={this.handleChange('counterpartId')}
                                >
                                    {counterparts.map(counterpart =>
                                        <MenuItem key={counterpart.id} value={counterpart.id}>
                                            {counterpart.username}
                                        </MenuItem>
                                    )};
                                </AnyField>
                            </Grid>
                            <Grid item xs={3}>
                                <UploadFilesField
                                    id="technicalProject"
                                    label="Техническое задание"
                                    value={technicalProject}
                                    onChange={this.handleChange('technicalProject')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <UploadFilesField
                                    id="cardDesigns"
                                    multiple
                                    label="Рубашки"
                                    onChange={this.handleChange('cardDesigns')}
                                    value={cardDesigns}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseMakeDialog(true)}  color="primary" disabled={!counterpartId}>Отправить в производство</Button>
                        <Button onClick={this.handleCloseMakeDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
