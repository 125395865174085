import React from 'react';
import {Route, Switch} from "react-router";
import CalendarDayPage from "./CalendarDayPage";
import ViewOrder from "./ViewOrder";
import HistoryChangePage from "../calendar/HistoryChangePage";
import CalendarWeekPage from "./CalendarWeekPage";
import ReportRequestedVisitsPage from "./ReportReuestedVisitsPage";
import ReportFullVisitsPage from "./ReportFullVisitsPage";
import CalendarMultiPage from "./CalendarMultiPage";
import ReportCanceledVisitsPage from "./ReportCanceledVisitsPage";

class CalendarPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/orders"             component={CalendarDayPage}/>
                <Route       path="/orders/week"              component={CalendarWeekPage}/>
                <Route       path="/orders/view"              component={ViewOrder}/>
                <Route       path="/orders/edit"              component={ViewOrder}/>
                <Route       path="/orders/history-change"      component={HistoryChangePage}/>
                <Route       path="/orders/report/requested"      component={ReportRequestedVisitsPage}/>
                <Route       path="/orders/report/full"      component={ReportFullVisitsPage}/>
                <Route       path="/orders/report/canceled"      component={ReportCanceledVisitsPage}/>
                <Route       path="/orders/multi"              component={CalendarMultiPage}/>
            </Switch>
        );
    }
}

export default CalendarPage;