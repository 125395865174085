import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    navLinks,
    moveStatuses,
    emptyMoveRequest,
    getMoveRequest,
    processMoveRequestAfterGet,
    saveMoveRequest,
    sendMoveRequestToCO,
    declineMoveRequest,
    acceptMoveRequest,
    orderStatuses,
    completeMoveRequest,
    setDeliveryMove, deliveryMoveRequest
} from "../../services/goods";
import Content from "../../components/content/Content";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import ShowField from "../../components/fields/ShowField";
import ActionButton from "../../components/button/ActionButton";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import ShowWithEditField from "../../components/fields/ShowWithEditField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {getParlours} from "../../services/organization";
import _ from 'lodash';
import goodsActions from "./goods-actions";
import GoodStorageField from "../../components/fields/GoodStorageField";
import {deliveryTypes, getDeliveryTypeText, getPayDelays} from "../../services/payDocs";
import NumberFormatCurrency from "../../components/fields/NumberFormatCurrency";
import SymbolRub from "../../components/typography/SymbolRub";
import UploadFilesField from "../../components/fields/UploadFilesField";
import DeliveryDialog from "../../components/dialogs/DeliveryDialog";
import {withRouter} from "react-router";
import {getBusinessUnitByRole} from "../../services/user";

const columnData = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товаров' },
    { id: 'priceFormat', number: true, label: 'Цена покупки' },
    { id: 'statusText', label: 'Статус товара в запросе' },
];

const styles = theme => ({
    container: {
        marginTop:  theme.spacing(2),
        minWidth: 600,
    },
    dialogContent: {
        paddingTop:  theme.spacing(2),
        minWidth: 600,
    },
});

@withRouter
class MoveViewPage extends React.Component
{
    state = {
        parlours: [],
        moveRequest: emptyMoveRequest,
        newStorageGoodId: null,
        newStorageGood: null,

        payDelays: [],

        deliveryDialogOpen: false,
    };

    componentDidMount() {
        const reId = new RegExp('/goods/move/view/([^/]+)$');
        const resultId = reId.exec(this.props.location.pathname);
        if (resultId.length !== 2) {
            //Error request
            return;
        }

        if (resultId[1] !== "0") {
            getMoveRequest(resultId[1])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            moveRequest: processMoveRequestAfterGet(response.data),
                        })
                    }
                });

        } else {
            if (this.props.movingStorageGoods && this.props.movingStorageGoods.length > 0) {
                this.setState({
                    moveRequest: {
                        ...this.state.moveRequest,
                        storageGoods: this.props.movingStorageGoods,
                    },
                });
                this.props.setMovingGoods([]);
            }
        }

        getParlours(1, -1)
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });

        getBusinessUnitByRole().then()

        getPayDelays()
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDelays: response.data,
                    });
                }
            });
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/move`);
    };

    handleHistoryClick = () => {
        this.props.history.push(`/goods/move/history-status/${this.state.moveRequest.id}`);

    };

    handleAdditionalClick = () => {
        this.props.history.push(`/goods/move/additional/${this.state.moveRequest.id}`);
    };


    handleChangeRequest = prop => event => {
        this.setState({
            moveRequest: {
                ...this.state.moveRequest,
                [prop]: event.target.value,
            }
        })
    };

    handleDelete = (grouped, idx) => {
        this.props.showConfirm({
            message: 'Вы уверены, что хотите убрать товар из запроса?',
            title: 'Удаление товара из запроса',
            onClose: this.handleCloseDelete(idx),
        })
    };

    handleCloseDelete = idx => ok => {
        const { moveRequest } = this.state;

        if (ok) {
            let storageGoods = [...moveRequest.storageGoods];
            _.remove(storageGoods, (value, index) => index === idx);

            this.setState({
                moveRequest: {
                    ...moveRequest,
                    storageGoods
                },
            });
        }
    };

    handleSave = () => {
        const { moveRequest } = this.state;

        saveMoveRequest({
            ...moveRequest,
            storageGoods: moveRequest.storageGoods.map(item => item.id),
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        moveRequest: processMoveRequestAfterGet(response.data),
                    });
                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeStorageGood = (event, newStorageGood) => {
        this.setState({
            newStorageGoodId: event.target.value,
            newStorageGood,
        })
    };

    handleAutoAddStorageGood = newStorageGood => {
        const { moveRequest } = this.state;

        if (newStorageGood) {
            if (moveRequest.storageGoods.filter(item => item.id === newStorageGood.id).length === 0) {
                const storageGoods = [...moveRequest.storageGoods, newStorageGood];
                this.setState({
                    moveRequest: {
                        ...moveRequest,
                        storageGoods
                    },
                    newStorageGoodId: null,
                    newStorageGood: null,
                });
            } else {
                this.setState({
                    newStorageGoodId: null,
                    newStorageGood: null,
                });
            }
        }

    };

    handleAddStorageGood = () => {
        const { moveRequest, newStorageGood, newStorageGoodId } = this.state;

        if (newStorageGood) {
            if (moveRequest.storageGoods.filter(item => item.id === newStorageGoodId).length === 0) {
                const storageGoods = [...moveRequest.storageGoods, newStorageGood];
                this.setState({
                    moveRequest: {
                        ...moveRequest,
                        storageGoods
                    },
                    newStorageGoodId: null,
                    newStorageGood: null,
                });
            } else {
                this.setState({
                    newStorageGoodId: null,
                    newStorageGood: null,
                });
            }
        }
    };

    handleMoveToCO = () => {
        this.props.showConfirm({
            message: 'Вы уверены, что хотите отправить заявку на согласование?',
            title: 'Отправка на согласование',
            onClose: this.handleCloseMoveToCO,
            showComment: true,
        });
    };

    handleCloseMoveToCO = (ok, comment) => {
        if (!ok) return;

        sendMoveRequestToCO({
            id: this.state.moveRequest.id,
            comment
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        moveRequest: processMoveRequestAfterGet(response.data),
                    });
                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleDecline = () => {
        this.props.showConfirm({
            message: 'Вы действительно хотитие отклонить заявку?',
            title: 'Отклонение заявки',
            onClose: this.handleDeclineClose,
            showComment: true,
        });
    };

    handleDeclineClose = (ok, comment) => {
        if (!ok) return;

        declineMoveRequest({
            id: this.state.moveRequest.id,
            comment
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        moveRequest: processMoveRequestAfterGet(response.data),
                    });
                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    openDeliveryDialog = () => {
        this.setState({
            deliveryDialogOpen: true
        });
    };

    closeDeliveryDialog = (ok, delivery) => {
        if (!ok) {
            this.setState({
                deliveryDialogOpen: false,
            });
            return;
        }

        setDeliveryMove(this.state.moveRequest.id, delivery).then(response => {
            if (response.success) {
                this.setState({
                    moveRequest: {
                        ...this.state.moveRequest,
                        delivery: response.data,
                    },
                    deliveryDialogOpen: false,
                });
            }
        });
    };

    handleChange = prop => event => {
        this.setState({
            [prop]: event.target.value,
        })
    };

    handleAccept = () => {
        this.props.showConfirm({
            message: 'Вы действительно хотитие согласовать заявку?',
            title: 'Согласование заявки',
            onClose: this.handleAcceptClose,
            showComment: true,
        });
    };

    handleAcceptClose = (ok, comment) => {
        if (!ok) return;

        acceptMoveRequest({
            id: this.state.moveRequest.id,
            comment
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        moveRequest: processMoveRequestAfterGet(response.data),
                    });
                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleDelivery = () => {
        this.setState({
            deliveryDialogOpen: true,
        });
    };

    setDeliveryStatus = () => {
        deliveryMoveRequest(this.state.moveRequest.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        moveRequest: processMoveRequestAfterGet(response.data),
                        deliveryDialogOpen: false,
                    });
                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleComplete = () => {
        this.props.showConfirm({
            message: 'Вы действительно хотитие принять товары?',
            title: 'Принять товары',
            onClose: this.handleCompleteClose,
        });
    };

    handleCompleteClose = ok => {
        if (!ok) return;

        completeMoveRequest({
            id: this.state.moveRequest.id,
            storageGoods: this.state.moveRequest.storageGoods.map(item => item.id),
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        moveRequest: processMoveRequestAfterGet(response.data),
                    });
                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes } = this.props;
        const { deliveryDialogOpen, moveRequest, newStorageGoodId } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    {this.renderLeftFields()}
                    <br/><br/>
                    <ActionButton visible={moveRequest.status !== orderStatuses.new && moveRequest.status !== orderStatuses.main_rejected && moveRequest.status !== orderStatuses.received} onClick={this.openDeliveryDialog}>{ moveRequest.delivery !== null ? 'Информация о доставке' : 'Применить доставку' }</ActionButton>
                    <ActionButton onClick={this.handleComplete} visible={moveRequest.status === moveStatuses.delivery}>Принять в салон</ActionButton>
                    <ActionButton onClick={this.setDeliveryStatus} visible={moveRequest.status === moveStatuses.main_ok}>Передать в доставку</ActionButton>
                    <ActionButton onClick={this.handleAccept} visible={moveRequest.status === moveStatuses.main_pending}>Согласовать</ActionButton>
                    <ActionButton onClick={this.handleDecline} visible={moveRequest.status === moveStatuses.main_pending}>Отклонить</ActionButton>
                    <ActionButton onClick={this.handleMoveToCO} visible={moveRequest.status === moveStatuses.new && moveRequest.id}>Передать на согласование</ActionButton>
                    <ActionButton onClick={this.handleSave} visible={moveRequest.status === moveStatuses.new} disabled={!moveRequest.parlour || !moveRequest.newParlour || moveRequest.storageGoods.length === 0}>Сохранить</ActionButton>
                    <ActionButton visible={moveRequest.status !== orderStatuses.new} onClick={this.handleHistoryClick}>История изменения статусов заказа</ActionButton>
                    <ActionButton visible={moveRequest.status !== orderStatuses.new} onClick={this.handleAdditionalClick}>Дополнительная информация по заказу</ActionButton>
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>
                <Content title="Запрос на передачу в другой салон">
                    <DataTableLocal
                        columnData={columnData}
                        data={moveRequest.storageGoods}
                        selector={false}
                        pagination={false}
                        onDelete={(moveRequest.status === moveStatuses.new ) ? this.handleDelete : null}
                    />
                    {moveRequest.status === moveStatuses.new ?
                        <Grid container spacing={2} className={classes.container} alignItems="flex-end">
                            <Grid item xs={6}>
                                <GoodStorageField
                                    label="Товар"
                                    value={newStorageGoodId}
                                    onChange={this.handleChangeStorageGood}
                                    excludeItemsIds={moveRequest.storageGoods.map(item => item.id)}
                                    onComplete={this.handleAutoAddStorageGood}
                                    additionalFilter={{parlour: moveRequest.parlour}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ActionButton fullWidth={false} onClick={this.handleAddStorageGood}
                                              disabled={!newStorageGoodId}>Добавить товар в запрос</ActionButton>
                            </Grid>
                        </Grid>
                        : null
                    }
                </Content>

                <DeliveryDialog open={deliveryDialogOpen} onClose={this.closeDeliveryDialog}
                                delivery={moveRequest.delivery} showInverseCheckbox={true}
                                businessUnit={moveRequest.businessUnit || {}}/>
            </React.Fragment>
        )
    }

    renderLeftFields() {
        const { moveRequest, parlours } = this.state;

        return (
            <React.Fragment>
                <ShowField
                    label="Номер запроса"
                    value={moveRequest.num}
                />
                <ShowField
                    label="Дата создания запроса"
                    value={moveRequest.createdAt}
                    dateFormat="DD.MM.YYYY"
                />
                <ShowField
                    label="Пользователь, создавший запрос"
                    value={moveRequest.creator.username}
                />
                <ShowField
                    label="Тел. пользователя, создавшего запрос"
                    value={moveRequest.creator.phone}
                />
                <AnyField
                    label="Салон"
                    value={moveRequest.parlour}
                    required
                    error={!moveRequest.parlour}
                    onChange={this.handleChangeRequest('parlour')}
                    select
                    fullWidth
                > {parlours.map(parlour => <MenuItem key={parlour.id} value={parlour.id}>{parlour.name}</MenuItem>)}
                </AnyField>
                <AnyField
                    label="Салон для передачи"
                    value={moveRequest.newParlour}
                    required
                    error={!moveRequest.newParlour}
                    onChange={this.handleChangeRequest('newParlour')}
                    select
                    fullWidth
                    readOnly={moveRequest.status !== moveStatuses.new}
                > {parlours.filter(item => item.id !== moveRequest.parlour).map(parlour => <MenuItem key={parlour.id} value={parlour.id}>{parlour.name}</MenuItem>)}
                </AnyField>
                <ShowWithEditField
                    label="Комментарий"
                    value={moveRequest.comment}
                    multiline
                    rows={4}
                    onChange={this.handleChangeRequest('comment')}
                />
                <ShowField
                    label="Статус"
                    value={moveRequest.statusText}
                />
            </React.Fragment>
        );
    }

    renderDeliveryDialog() {
        const { classes } = this.props;
        const { deliveryDialogOpen, deliveryType, deliveryName, deliveryWaybill, deliveryReceiptScan, deliveryCharges, deliveryComment } = this.state;

        return (
            <Dialog
                open={deliveryDialogOpen}
                onClose={this.handleCloseDeliveryDialog(false)}
                aria-labelledby="delivery-dialog-title"
            >
                <DialogTitle id="delivery-dialog-title">Передача в доставку</DialogTitle>
                <DialogContent className={classes.dialogContent} style={{width: 400}} >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                id="type"
                                value={deliveryType}
                                onChange={this.handleChange('deliveryType')}
                                select
                                label="Способ доставки"
                                fullWidth>
                                {Object.keys(deliveryTypes).map(type =>
                                    <MenuItem key={type} value={type}>
                                        {getDeliveryTypeText(type)}
                                    </MenuItem>
                                )}
                            </AnyField>
                        </Grid>
                        {deliveryType === deliveryTypes.transport_company ?
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <AnyField
                                        id="name"
                                        label="Название транспортной компании"
                                        value={deliveryName}
                                        multiline
                                        rows="2"
                                        onChange={this.handleChange('deliveryName')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AnyField
                                        id="waybill"
                                        label="Номер накладной"
                                        value={deliveryWaybill}
                                        onChange={this.handleChange('deliveryWaybill')}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <UploadFilesField
                                        label="Скан квитанции"
                                        id="deliveryReceiptScan"
                                        value={deliveryReceiptScan}
                                        onChange={this.handleChange('deliveryReceiptScan')}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AnyField
                                        id="charges"
                                        label="Стоимость"
                                        value={deliveryCharges}
                                        onChange={this.handleChange('deliveryCharges')}
                                        InputProps={{
                                            inputComponent: NumberFormatCurrency,
                                            endAdornment: <SymbolRub/>,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </React.Fragment>
                            : null
                        }
                        <Grid item xs={12}>
                            <AnyField
                                id="con"
                                label="Комментарий"
                                value={deliveryComment}
                                onChange={this.handleChange('deliveryComment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDeliveryDialog(true)} disabled={!deliveryType} color="primary">OK</Button>
                    <Button onClick={this.handleCloseDeliveryDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    movingStorageGoods: state.goods.movingStorageGoods,
});

const mapDispatchToProps = {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
    setMovingGoods: goodsActions.setMovingGoods,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MoveViewPage));
