import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    tableRow: {
        height: 32,
    },
    column: {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.palette.grey[400],
        borderBottomColor: theme.palette.grey[400],

        '&:last-child': {
            borderRight: 'none',
        },

    },
    stickyLeftColumn: {
        position: "sticky",
        left: 0,
        background: "white",
        border:'0.08em solid'
    }
});

@withStyles(styles)
@withTranslation()
class DataTableSummary extends React.PureComponent {

    static propTypes = {
        columnData: PropTypes.array.isRequired,
        filteredData: PropTypes.array.isRequired,
        stickyLeftColumn: PropTypes.bool
    }

    getColumnSum = (column) => {
        const {filteredData} = this.props;

        return _.sumBy(filteredData, column.id);
    }

    getColumnValue = (column, index) => {
        const {t} = this.props;

        if (index === 0) {
            return t('Summary');
        }

        if (column.id === 'summary') {
            return '';
        }

        return this.getColumnSum(column);
    }

    renderColumn = (column, index) => {
        const {classes, stickyLeftColumn} = this.props;

        return <TableCell key={column.id + index}
                          className={stickyLeftColumn && index === 0 ? classes.stickyLeftColumn : classes.column}>
            {this.getColumnValue(column, index)}
        </TableCell>;
    }

    render() {
        const {columnData, classes} = this.props;

        return <TableRow className={classes.tableRow}>
            {columnData.map((column, i) => this.renderColumn(column, i))}
        </TableRow>;
    }
}

export default DataTableSummary;
