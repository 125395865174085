import React from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid, List, ListItem, ListItemText,
    Popover,
} from "@material-ui/core";
import moment from "moment";
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import {
    createBusinessUnitAnnouncement,
    getBusinessUnitAnnouncements,
    removeBusinessUnitAnnouncement,
    updateBusinessUnitAnnouncement,
    getLastBusinessUnitAnnouncement
} from "../../services/organization";
import {InputAdornment} from "@material-ui/core";
import {IconButton} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import DeleteIcon from "@material-ui/icons/Delete";
import infoActions from "../../components/dialogs/messageDialog-acions"
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import AddIcon from "@material-ui/icons/Add";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/styles";
import {hasRole} from "../../services/user";

const emptyAnnouncement = {
    id: null,
    text: null
}
const dateTimeFormat = "Y-MM-DD HH:mm"
const styles = () => ({
    container: {
        width: "400px",
        alignItems: "center",
    },
    announcementList: {
        width: "350px",
        maxHeight: "600px",
    },
    noAnnouncementsMessage: {
        width: "200px",
        height: "50px",
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        alignItems: "center"
    },
    announcementListDetails: {
        lineHeight: "1.5",
        paddingTop: "5px",
        color: "grey"
    },
    announcementListActionButtons: {
        paddingTop:"4px"
    }
})

@withTranslation()
@connect(state => ({
    user: state.auth.user,
    businessUnit: state.common.currentParlour
    }),
    {
        showInfo: infoActions.show
    }
)

class ScheduleHeaderBusinessUnitAnnouncementsForm extends React.Component
{
    state = {
        announcements: [],

        openAnnouncementsPopup: false,
        openEditAnnouncementDialog: false,
        openCreateAnnouncementDialog: false,
        openConfirmRemoveAnnouncementDialog: false,

        announcementsPopupAnchorEl: null,

        lastAnnouncement: emptyAnnouncement,
        newAnnouncement: emptyAnnouncement,
        editAnnouncement: emptyAnnouncement,
        removeAnnouncement: emptyAnnouncement
    }

    componentDidMount() {

        if (this.props.businessUnit && this.props.businessUnit.id) {
            this.fetchLastAnnouncement()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if ((this.props.businessUnit && nextProps.businessUnit
            && this.props.businessUnit.id !== nextProps.businessUnit.id)
        ||
            (!this.props.businessUnit && nextProps.businessUnit))
        {
            this.fetchLastAnnouncement(nextProps.businessUnit.id)
        }
    }

    render() {

        const { t, classes } = this.props
        const disableActions = !hasRole("ROLE_ORGANIZATION_BUSINESSUNIT_ANNOUNCEMENT_SAVE")

        return (
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={10}>
                    <AnyField
                        value={this.state.lastAnnouncement.text}
                        fullWidth
                        placeholder={t("No announcements")}
                        readOnly
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position={"end"}
                                >
                                    <IconButton
                                        size="small"
                                        onClick={this.openAnnouncementsPopup}
                                    >
                                        <HistoryIcon fontSize={"small"} />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <ActionButton
                        onClick={this.openCreateAnnouncementDialog}
                        noMargin
                        disabled={disableActions}
                    >
                        <AddIcon />
                    </ActionButton>
                </Grid>

                {this.showAnnouncementsPopup()}
                {this.showCreateAnnouncementDialog()}
                {this.showEditAnnouncementDialog()}
                {this.showConfirmRemoveAnnouncementDialog()}

            </Grid>
        )
    }

    showAnnouncementsPopup = () => {

        const {
            openAnnouncementsPopup,
            announcementsPopupAnchorEl
        } = this.state
        const { t, classes } = this.props

        return <Popover
                    open={openAnnouncementsPopup}
                    anchorEl={announcementsPopupAnchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    onClose={() => this.setState({openAnnouncementsPopup: false})}
              >
                    {
                        this.isAnnouncementsExists() ?
                            this.showAnnouncementsList():
                            <div className={classes.noAnnouncementsMessage}>
                                {t("No announcements")}
                            </div>
                    }
                </Popover>
    }

    showAnnouncementsList = () => {

        const { announcements } = this.state
        const { classes } = this.props
        const disableActions = !hasRole("ROLE_ORGANIZATION_BUSINESSUNIT_ANNOUNCEMENT_SAVE")

        return <List
                    className={classes.announcementList}
                >
                    {announcements.map((announcement, i) =>  {
                        return <React.Fragment key={i}>
                                    <ListItem>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <ListItemText
                                                    primary={announcement.text}
                                                />
                                            </Grid>
                                            <Grid item xs={9} className={classes.announcementListDetails}>
                                                {announcement.creatorName}
                                                <br/>
                                                {moment(announcement.createdAt).format(dateTimeFormat)}
                                            </Grid>
                                            <Grid item xs={3} className={classes.announcementListActionButtons}>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="edit"
                                                    onClick={() => this.openEditAnnouncementDialogByIndex(i)}
                                                    disabled={disableActions}
                                                >
                                                    <EditIcon fontSize={"small"}  />
                                                </IconButton>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => this.openConfirmRemoveAnnouncementDialog(i)}
                                                    disabled={disableActions}
                                                >
                                                    <DeleteIcon fontSize={"small"}  />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    {announcements.length - 1 > i ?
                                        <Divider/>:
                                        null
                                    }
                        </React.Fragment>
                    })}
                </List>
    }

    showCreateAnnouncementDialog = () => {

        const { newAnnouncement, openCreateAnnouncementDialog} = this.state
        const { t, user } = this.props
        const now = moment().format(dateTimeFormat)

        return <Dialog
                    open={openCreateAnnouncementDialog}
                    onClose={() => this.setState({openCreateAnnouncementDialog: false})}
                >
                    <DialogTitle>{t("New announcement")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    value={newAnnouncement.text}
                                    name={"text"}
                                    fullWidth
                                    label={t("Message")}
                                    onChange={this.onChangeNewAnnouncement}
                                    multiline={true}
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    label={t("Creator")}
                                    value={user.username}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    label={t("Created date")}
                                    value={now}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.requestCreateAnnouncement}
                            disabled={newAnnouncement.text === null || newAnnouncement.text === ""}
                            color={"primary"}
                        >
                            {t("Create")}
                        </Button>
                        <Button
                            onClick={() => this.setState({openCreateAnnouncementDialog: false})}
                            color={"primary"}
                        >
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
    }

    showEditAnnouncementDialog = () => {

        const { editAnnouncement, openEditAnnouncementDialog } = this.state
        const { t } = this.props

        return <Dialog
                    open={openEditAnnouncementDialog}
                    onClose={() => this.setState({openEditAnnouncementDialog: false})}
                >
                    <DialogTitle>{t("Edit announcement")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    value={this.state.editAnnouncement.text}
                                    name={"text"}
                                    fullWidth
                                    label={t("Message")}
                                    onChange={this.onChangeEditAnnouncement}
                                    multiline={true}
                                    rows={3}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    value={this.state.editAnnouncement.creatorName}
                                    label={t("Creator")}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    value={moment(this.state.editAnnouncement.createdAt).format(dateTimeFormat)}
                                    disabled
                                    label={t("Created at")}
                                    fullWidth
                                    onChange={this.onChangeEditAnnouncement}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.requestUpdateAnnouncement}
                            disabled={editAnnouncement.text === null || editAnnouncement.text === ""}
                            color={"primary"}
                        >
                            {t("Save")}
                        </Button>
                        <Button
                            onClick={() => this.setState({openEditAnnouncementDialog: false})}
                            color={"primary"}
                        >
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
    }

    showConfirmRemoveAnnouncementDialog = () => {

        const { openConfirmRemoveAnnouncementDialog } = this.state
        const { t } = this.props

        return <Dialog
                    open={openConfirmRemoveAnnouncementDialog}
                    onClose={() => this.setState({
                        openConfirmRemoveAnnouncementDialog: false,
                        removeAnnouncement: emptyAnnouncement
                    })}
        >
                    <DialogTitle>{t("Remove announcement?")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    value={this.state.removeAnnouncement.text}
                                    label={t("Message")}
                                    multiline={true}
                                    fullWidth
                                    rows={3}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    fullWidth
                                    value={this.state.removeAnnouncement.creatorName}
                                    label={t("Creator")}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    fullWidth
                                    value={moment(this.state.removeAnnouncement.createdAt).format(dateTimeFormat)}
                                    label={t("Created at")}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.requestRemoveAnnouncement(this.state.removeAnnouncement.id)}
                            color={"primary"}
                        >
                            {t("Delete")}
                        </Button>
                        <Button
                            onClick={() => this.setState({openConfirmRemoveAnnouncementDialog: false})}
                            color={"primary"}
                        >
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
    }

    openCreateAnnouncementDialog = () => {
        this.setState({
            openCreateAnnouncementDialog: true
        })
    }

    openAnnouncementsPopup = (event) => {

        const announcementsPopupAnchorEl = event.currentTarget

        this.fetchAnnouncements()
            .then(
                () => {
                    this.setState({
                        announcementsPopupAnchorEl,
                        openAnnouncementsPopup: true
                    })
                }
            )
    }

    openEditAnnouncementDialogByIndex = (announcementIndex) => {

        const announcement = this.state.announcements[announcementIndex]

        this.setState({
            editAnnouncement: announcement,
            openAnnouncementsPopup: false,
            openEditAnnouncementDialog: true,
        })
    }

    openConfirmRemoveAnnouncementDialog = (index) => {
        this.setState({
            removeAnnouncement: this.state.announcements[index],
            openConfirmRemoveAnnouncementDialog: true,
            openAnnouncementsPopup: false
        })
    }

    onChangeNewAnnouncement = (event) => {

        this.setState({
            newAnnouncement: {
                [event.target.name]: event.target.value
            }
        })
    }

    onChangeEditAnnouncement = (event) => {

        const { editAnnouncement } = this.state

        this.setState({
            editAnnouncement: {
                ...editAnnouncement,
                [event.target.name]: event.target.value
            }
        })
    }

    fetchLastAnnouncement = (businessUnitId = this.props.businessUnit.id) => {

        getLastBusinessUnitAnnouncement(businessUnitId)
            .then((response) => {
                if (response.success && response.data !== null){
                    this.setState({
                        lastAnnouncement: response.data
                    })
                } else {
                    this.setState({
                        lastAnnouncement: emptyAnnouncement
                    })
                }
            })
    }

    fetchAnnouncements = () => {

        const { businessUnit } = this.props

        return getBusinessUnitAnnouncements(businessUnit.id)
            .then(
                (response) => {
                    if (response.success) {
                        this.setState({
                            announcements: response.data
                        })
                    } else {
                        this.props.showInfo(response.error.message)
                    }
                }
            )
    }

    requestCreateAnnouncement = () => {

        const { businessUnit } = this.props
        const { newAnnouncement } = this.state

        createBusinessUnitAnnouncement(newAnnouncement.text, businessUnit.id)
            .then(
                (response) => {
                    if (response.success) {
                        this.setState({
                            openCreateAnnouncementDialog: false,
                            newAnnouncement: emptyAnnouncement
                        })
                        this.fetchLastAnnouncement()
                    } else {
                        this.props.showInfo(response.error.message);
                    }
                }
            )
    }

    requestRemoveAnnouncement = (id) => {

        removeBusinessUnitAnnouncement(id)
            .then((response) => {
                if (response.success) {
                    let { announcements } = this.state
                    announcements = announcements.filter((announcement) => announcement.id !== id)
                    this.setState({announcements}, this.fetchLastAnnouncement)
                } else {
                    this.props.showInfo(response.error.message)
                }

                this.setState({openConfirmRemoveAnnouncementDialog: false})
            })
    }

    requestUpdateAnnouncement = () => {

        const { editAnnouncement, lastAnnouncement } = this.state

        updateBusinessUnitAnnouncement(editAnnouncement.text, editAnnouncement.id)
            .then((response) => {
                if (response.success) {
                    this.setState({
                            editAnnouncement: emptyAnnouncement,
                            openEditAnnouncementDialog: false
                        },
                        () => {
                            if (editAnnouncement.id === lastAnnouncement.id)
                                this.fetchLastAnnouncement()
                        })
                } else {
                    this.props.showInfo(response.error.message)
                }
            })
    }

    isAnnouncementsExists = () => {

        const { announcements } = this.state

        return !!announcements.length
    }
}

export default connect()(withStyles(styles)(ScheduleHeaderBusinessUnitAnnouncementsForm))