import React from "react"
import { navLinks, getClosedFinancialPeriodChangesReport, closedFinancialPeriodChangeEvents } from "../../services/moneyFlow";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getParlours} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import DataTable from "../../components/data-table/DataTable";
import moment from "moment";
import {withTranslation} from "react-i18next";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import {getUsers} from "../../services/user";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

export default
@withTranslation()
class ClosedFinancialPeriodChangesReport extends React.Component {

    state = {
        search: {
            businessUnitIds: [],
            dateStart: moment().startOf('month'),
            dateEnd: moment().endOf('day'),
            userIds: []
        },
        businessUnits: [],
        users: [],
        refreshTable: false
    }

    entitiesLinkTemplates = {
        Visit: "/orders/view/",
        PayDoc: "/pay-docs/view/",
        PayDocRefill: "/pay-docs/refill/",
        GoodStorageSale: "/goods/sell/view/",
        Debt: '/money-flow/debt/id/',
        SpendingDeal: '/money-flow/deal/id/',
        IncomingDeal: '/money-flow/deal/id/',
        ConvertDeal: '/money-flow/deal/id/',
    }

    processValueParentId = (_, t, row) => {
        if (this.entitiesLinkTemplates[row.parentClass]) {
            return row.event === closedFinancialPeriodChangeEvents.delete ?
                null:
                <a
                    href={this.entitiesLinkTemplates[row.parentClass] + row.parentId}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    >
                        {row.parentId}
                    </a>
        }

        return row.parentId
    }

    dateFormat = 'DD.MM.YYYY HH:mm:ss'

    columnData = [
        {id: "parentClass", label: "Changed object", processValue: (value) => this.props.t('ClosedFinancialPeriodChangeParentClass.' + value)},
        {id: "event", label: "Event", processValue: (value) => this.props.t('ClosedFinancialPeriodChangeEvent.' + value)},
        {id: "amount", label: "Sum"},
        {id: "parentId", label: "Link", processValue: this.processValueParentId},
        {id: "businessUnitName", label: "Parlour"},
        {id: "creatorName", label: "Creator"},
        {id: "periodRange", label: "Period"},
        {id: "dt", label: "Date and time", dateFormat: this.dateFormat},
    ]

    componentDidMount() {
        getParlours()
            .then(response => {
                this.setState({businessUnits: response.data})
            })
        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });
    }

    handleChangeSearch = (event) => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value
            }
        })
    }

    getTableData = (...params) => {
        const { search } = this.state

        return getClosedFinancialPeriodChangesReport(search, ...params)
    }

    handleFilter = () => {
        this.setState( state => ({
            refreshTable: !state.refreshTable
        }))
    }

    render() {
        const { search, businessUnits, users, refreshTable } = this.state
        const { t } = this.props

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks} />}>
                <DatePeriodField2
                    valueFrom={search.dateStart}
                    valueTo={search.dateEnd}
                    maxDate={moment()}
                    onChangeFrom={this.handleChangeSearch}
                    onChangeTo={this.handleChangeSearch}
                    label={t('Date')}
                    nameFrom={'dateStart'}
                    nameTo={'dateEnd'}
                />
                <MultiSelectFromItemsField
                    label={t('Parlour')}
                    name="businessUnitIds"
                    value={search.businessUnitIds}
                    onChange={this.handleChangeSearch}
                    fullWidth
                    items={businessUnits}
                />
                <AutocompleteMultiSelectField
                    label={t('Creator')}
                    value={search.userIds}
                    name="userIds"
                    onChange={this.handleChangeSearch}
                    fullWidth
                    options={users}
                    textField="username"
                />
                <ActionButton
                    onClick={this.handleFilter}
                >
                    {t('Filter')}
                </ActionButton>
            </LeftBar>
            <Content title={t('Closed financial period changes report')}>
                <DataTable
                    columnData={this.columnData}
                    dataFunc={this.getTableData}
                    selector={false}
                    refresh={refreshTable}
                    autoload={false}
                    orderBy={'dt'}
                    order={'desc'}
                />
            </Content>
        </React.Fragment>
    }
}
