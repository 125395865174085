import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getTaskPriorities, getDeliveryTasks, getTaskStatuses, getSystemTypes, navLinks} from "../../services/tasks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import moment from "moment";
import DatePeriodField from "../../components/fields/DatePeriodField";
import {withTranslation} from "react-i18next";
import CheckboxField from "../../components/fields/CheckboxField";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getBusinessUnits} from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {getUsers} from "../../services/user";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {getPayDocStatuses} from "../../services/payDocs";
import HLine from "../../components/left-bar/HLine";

export default
@withTranslation()
@connect(state => ({
    currentUser: state.auth.user,
}),{
    showMessage: messageDialogActions.show,
})
class DeliveryPage extends React.Component
{
    emptySearch =  {
        dateStart: moment(),
        dateEnd: moment(),
        createdDate: true,
        businessUnits: [],
        taskTypes: [],
        taskStatuses: [],
        taskPriorities: [],
        executor: null,
        creator: null,
        showArchived: false,
    }

    state = {
        search: this.emptySearch,
        businessUnits: [],
        taskTypes: [],
        taskStatuses: [],
        taskPriorities: [],
        payDocStatuses: [],
        users: [],
        refreshTable: false,
    };

    columnData = [
        { id: 'row_number', label: '№', disableSorting: true},
        {
            id: 'task_num',
            label: 'Task',
            linkTemplate: row => `/tasks/view/${row.task_id}`,
            processValue: (value, t, row) =>
            {
                return value + ' ('+row.task_status+')';
            }
        },
        {
            id: 'pay_doc_num',
            label: 'PayDoc',
            linkTemplate: row => `/pay-docs/view/${row.pay_doc_id}`,
            processValue: (value, t, row) =>
            {
                if(row.pay_doc_status !== null) {
                    let status = this.state.payDocStatuses.find(x => {return x.id === row.pay_doc_status});
                    if(status) status = status.text;
                    else status = row.pay_doc_status;
                    value = value + ' ('+status+')';
                }
                return value;
            }
        },
        { id: 'amount', label: 'Price'},
        { id: 'parlour', label: 'Parlour'},
        { id: 'task_type', label: 'Type'},
        { id: 'guest_name', label: 'Guest' },
        { id: 'guest_phone', label: 'Phone' },
        { id: 'task_description', label: 'Description' },
        { id: 'task_expect_date', label: 'Expect date', dateFormat: 'DD.MM.YYYY' },
        { id: 'task_executed_at', label: 'Executed date', dateFormat: 'DD.MM.YYYY' },
        { id: 'task_created_at', label: 'Created date', dateFormat: 'DD.MM.YYYY' },
        { id: 'task_executor', label: 'Executor'},
    ];

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });
        getSystemTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskTypes: response.data,
                    })
                }
            });
        getTaskStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskStatuses: response.data,
                    })
                }
            });
        getTaskPriorities()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskPriorities: response.data,
                    })
                }
            });
        getPayDocStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDocStatuses: response.data,
                    })
                }
            });
    }

    getTableData = (...params) => {
        const {search} = this.state;
        return getDeliveryTasks(processMomentFieldInObject(search, ['dateStart', 'dateEnd']), ...params);
    };

    handleAdd = () => {
        this.props.history.push('/tasks/view/0');
    };

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleClickResetFilter = () => {
        this.setState(state => ({
            search: this.emptySearch,
            refreshTable: !state.refreshTable,
        }));
    }

    tableLinkTemplate = task => `/tasks/view/${task.task_id}`;

    render() {
        const { t, currentUser } = this.props;
        const {search, refreshTable, taskTypes, taskStatuses, taskPriorities, users, businessUnits} = this.state;
        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <CheckboxField
                    label={t('Date of creation')}
                    value={search.createdDate}
                    onChange={this.handleChange('createdDate')}
                    margin="none"
                />
                <DatePeriodField
                    valueFrom={search.dateStart}
                    valueTo={search.dateEnd}
                    maxDate={moment()}
                    onChangeFrom={this.handleChange('dateStart')}
                    onChangeTo={this.handleChange('dateEnd')}
                    label={t('Date')}
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.businessUnits}
                    onChange={this.handleChange('businessUnits')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={taskTypes}
                    label={t('Type')}
                    value={search.taskTypes}
                    onChange={this.handleChange('taskTypes')}
                    valueField='id'
                    textField='text'
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={taskStatuses}
                    label={t('Status')}
                    value={search.taskStatuses}
                    onChange={this.handleChange('taskStatuses')}
                    valueField='id'
                    textField='title'
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={taskPriorities}
                    label={t('Priority')}
                    value={search.taskPriorities}
                    onChange={this.handleChange('taskPriorities')}
                    valueField='id'
                    textField='title'
                    fullWidth
                />
                <AutocompleteSelectField
                    label={t('Executor')}
                    value={search.executor}
                    name="executor"
                    onChange={this.handleChange('executor')}
                    fullWidth
                    options={users.map(user => ({value: user.id, label: user.username})).sort((a, b) => {
                        if (a.value === currentUser.id) {
                            return -1;
                        } else if (b.value === currentUser.id) {
                            return  1;
                        } else {
                            if (a.label < b.label) {
                                return -1;
                            } else {
                                return 1;
                            }
                        }
                    })}
                />
                <AutocompleteSelectField
                    label={t('Creator')}
                    value={search.creator}
                    name="creator"
                    onChange={this.handleChange('creator')}
                    fullWidth
                    options={users.map(user => ({value: user.id, label: user.username})).sort((a, b) => {
                        if (a.value === currentUser.id) {
                            return -1;
                        } else if (b.value === currentUser.id) {
                            return  1;
                        } else {
                            if (a.label < b.label) {
                                return -1;
                            } else {
                                return 1;
                            }
                        }
                    })}
                />
                <CheckboxField
                    label={t('Show archived')}
                    value={search.showArchived}
                    onChange={this.handleChange('showArchived')}
                />
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleClickResetFilter}>{t('Reset filter')}</ActionButton>
                <HLine/>
                <ActionButton onClick={this.handleAdd}>Создать задачу</ActionButton>
            </LeftBar>
            <Content title={t('Tasks')}>
                <DataTable
                    dataFunc={this.getTableData}
                    selector={false}
                    columnData={this.columnData}
                    linkTemplate={this.tableLinkTemplate}
                    refresh={refreshTable}
                    order="desc"
                    orderBy="task_num"
                />
            </Content>
        </React.Fragment>
    }
}
