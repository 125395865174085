import React from 'react';
import {Route, Switch} from "react-router";
import CreatePage from "./CreatePage";
import ViewPage from "./ViewPage";
import ListPage from "./ListPage";
import MakePage from "./MakePage";
import RegisterPage from "./RegisterPage";
import HistoryBalancePage from "./HistoryBalancePage";
import HistoryGuestPage from "./HistoryGuestPage";
import HistoryStatusPage from "./HistoryStatusPage";
import HistoryValidityPage from "./HistoryValidityPage";
import RequestsPage from "./RequestsPage";
import RequestViewPage from "./RequestViewPage";
import RequestHistoryStatusPage from "./RequestHistoryStatusPage";
import CheckPage from "./CheckPage";
import StatsPage from "./SaleStatsPage";
import RequestViewInfoPage from "./RequestViewInfoPage";
import PartnerPage from "./PartnerPage";
import PayDocTypeListPage from "./PayDocTypeListPage";
import PayDocTypeViewPage from "./PayDocTypeViewPage";
import CreatePayDoc from "./CreatePayDoc";
import PayDocReportBalancePage from "./PayDocReportBalancePage";
import PayDocReportSoldPage from "./PayDocReportSoldPage";
import PayDocReportDeliveryPage from "./PayDocReportDeliveryPage";
import RefillViewPage from "./RefillViewPage";
import PayDocReportActivatedPage from "./PayDocReportActivatedPage";
import HistoryBusinessUnitPage from "./HistoryBusinessUnitPage";
import FullHistoryEventsPage from "./FullHistoryEventsPage";
import PayDocReportHistoryBalancesPage from "./PayDocReportHistoryBalancesPage";
import HistoryPayDocTypePage from "./HistoryPayDocTypePage";
import PayDocPromoListPage from "./PayDocPromoListPage";
import PayDocPromoViewPage from './PayDocPromoViewPage';

class PayDocsPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/pay-docs"            component={ListPage}/>
                <Route       path="/pay-docs/create"     component={CreatePage}/>
                <Route       path="/pay-docs/view/:id"       component={ViewPage}/>
                <Route       path="/pay-docs/make"       component={MakePage}/>
                <Route       path="/pay-docs/register"   component={RegisterPage}/>
                <Route       path="/pay-docs/check"      component={CheckPage}/>
                <Route       path="/pay-docs/sale-stats" component={StatsPage}/>
                <Route       path="/pay-docs/partner"    component={PartnerPage}/>

                <Route       path="/pay-docs/history/pay-doc-type"   component={HistoryPayDocTypePage}/>
                <Route       path="/pay-docs/history/balance"   component={HistoryBalancePage}/>
                <Route       path="/pay-docs/history/guest"     component={HistoryGuestPage}/>
                <Route       path="/pay-docs/history/status"    component={HistoryStatusPage}/>
                <Route       path="/pay-docs/history/validity"  component={HistoryValidityPage}/>
                <Route       path="/pay-docs/history/business-unit"  component={HistoryBusinessUnitPage}/>

                <Route       path="/pay-docs/full-history-events"   component={FullHistoryEventsPage}/>

                <Route exact path="/pay-docs/requests"            component={RequestsPage}/>
                <Route       path="/pay-docs/requests/view"       component={RequestViewPage}/>
                <Route       path="/pay-docs/requests/view-info"  component={RequestViewInfoPage}/>

                <Route       path="/pay-docs/requests/history/status"  component={RequestHistoryStatusPage}/>

                <Route exact path="/pay-docs/types"            component={PayDocTypeListPage}/>
                <Route       path="/pay-docs/types/"           component={PayDocTypeViewPage}/>
                <Route       path="/pay-docs/create-one"           component={CreatePayDoc}/>

                <Route       path="/pay-docs/report/balance"   component={PayDocReportBalancePage}/>
                <Route       path="/pay-docs/report/history-balances"   component={PayDocReportHistoryBalancesPage}/>
                <Route       path="/pay-docs/report/sold"      component={PayDocReportSoldPage}/>
                <Route       path="/pay-docs/report/delivery"      component={PayDocReportDeliveryPage}/>
                <Route       path="/pay-docs/report/activated"      component={PayDocReportActivatedPage}/>

                <Route       path="/pay-docs/refill"           component={RefillViewPage}/>

                <Route exact path="/pay-docs/promo"            component={PayDocPromoListPage}/>
                <Route       path="/pay-docs/promo/"            component={PayDocPromoViewPage}/>
            </Switch>
        );
    }
}

export default PayDocsPage;