import React from 'react';
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    withStyles,
    DialogActions, Grid, Typography, MenuItem
} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import AnyField from "../fields/AnyField";
import ActionButton from "../button/ActionButton";
import { getGenders } from "../../services/guests";

export default
@withStyles({
    content: {
        textAlign: 'center',
    },
})
@withTranslation()
class GuestNameVerificationDialog extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        message: PropTypes.node,
        nameVerifyData: PropTypes.object,
        genders: PropTypes.object,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func
    }

    static defaultProps = {
        open: true,
        nameVerifyData: {
            guestName: '',
            guestLastName: ''
        }
    }

    state = {
        genders: []
    }

    componentDidMount() {
        getGenders()
            .then(response => {
                if (response.success) {
                    this.setState({
                        genders: response.data,
                    });
                }
            });
    }

    renderInvalidDataError = () => {
        const { t } = this.props

        return <Typography color="error" display="inline" variant="body4">
                    {t('Proprietary data entered, invalid characters present')}
               </Typography>
    }

    render() {
        const { open, nameVerifyData, onChange, onSubmit, t } = this.props;
        const { genders } = this.state;

        const regexp = /[0-9]|["№;%:?*.~!@#$%^&*(){}/\\_+=,<>|]/
        const isGuestNameInvalid = regexp.exec(nameVerifyData.guestName ? nameVerifyData.guestName.trim() : '')
        const isGuestLastNameInvalid = regexp.exec(nameVerifyData.guestLastName ? nameVerifyData.guestLastName.trim() : '')

        const disabled =
            !nameVerifyData.gender ||
            isGuestNameInvalid ||
            isGuestLastNameInvalid

        return (

            <Dialog open={open} maxWidth="xs">
                <DialogTitle>{t("Verify guest name")}</DialogTitle>
                <Grid container justify="center">
                    <Grid item xs={11}>
                        <Typography color="primary" align="center" variant="body1">{'Пример обращения к гостю в сообщении:'}</Typography>
                        <div style={{ textAlign: "center" }}>
                            <Typography color="textPrimary" display="inline" variant="body1">{'"Здравствуйте '}</Typography>
                            <Typography color="error" display="inline" variant="body1">{nameVerifyData.guestName}</Typography>
                            <Typography color="textPrimary" display="inline" variant="body1">{', ваш отдых запланирован..."'}</Typography>
                        </div>
                    </Grid>
                </Grid>
                <DialogContent>
                    <Grid container justify="flex-start">
                        <Grid item xs={12}>
                            <AnyField
                                label={t("Guest name")}
                                value={nameVerifyData.guestName}
                                onChange={onChange('guestName')}
                                fullWidth
                                error={isGuestNameInvalid}
                            />
                            {isGuestNameInvalid && this.renderInvalidDataError()}
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label={t("Guest lastname")}
                                value={nameVerifyData.guestLastName}
                                onChange={onChange('guestLastName')}
                                error={isGuestLastNameInvalid}
                                fullWidth
                            />
                            {isGuestLastNameInvalid && this.renderInvalidDataError()}
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="gender"
                                label={t("Gender")}
                                value={nameVerifyData.gender}
                                select
                                fullWidth
                                onChange={onChange('gender')}
                                error={!nameVerifyData.gender}
                            >
                                {genders.map(type => (
                                    <MenuItem key={type.id} value={type.id}>{type.text}</MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ActionButton
                        fullWidth={false}
                        noMargin
                        onClick={onSubmit}
                        disabled={disabled}
                    >
                        {t("Verify guest name")}
                    </ActionButton>
                </DialogActions>
                <DialogContent>
                    <Grid container justify="center">
                        <Grid item xs={11}>
                            <Typography color="primary" align="center" variant="body1">{'ВАЖНО!'}</Typography>
                            <Typography color="primary" align="center" variant="body1">{'Подтвержденное вами имя и фамилия гостя будут использоваться как обращение к гостю, в каждом смс сообщении.'}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        );
    }
}
