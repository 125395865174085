import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    navLinks,
    getIncomingTypes,
    getExternalDebt,
    saveExternalDebt,
    deleteExternalDebt,
    createDealForExternalDebt,
    dealIncomingTypeIds,
    getIncomingDealTypes,

} from "../../services/moneyFlow";
import AnyField from "../../components/fields/AnyField";
import {DialogActions, DialogContent, Grid, MenuItem} from "@material-ui/core";
import { emptyMoney} from "../../services/common";
import { processMomentFieldInObject } from "../../utils/moment-utils";
import infoActions from "../../components/info/info-actions";
import {
    getExternalSubjects,
    getJuridicalPersons,
    getCheckingAccounts,
    getHumanEmployees
} from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import MoneyField from "../../components/fields/MoneyField";
import {getBusinessUnitByRole, hasRole} from "../../services/user";
import {withTranslation} from "react-i18next";
import moment from "moment";
import DealsByReason from "./DealsByReason";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ShowField from "../../components/fields/ShowField";
import DateMonthField from "../../components/fields/DateMonthField";
import PercentField from "../../components/fields/PercentField";

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
@withTranslation()
class ExternalDebtViewPage extends React.Component {
    state = {
        externalSubjects: [],
        businessUnits: [],
        incomingTypes: [],
        juridicalPersonCheckingAccounts: [],
        externalSubjectCheckingAccounts: [],
        incomingDealTypes: [],
        employees: [],
        debt: {
            id: null,
            businessUnitId: null,
            reasonId: null,
            dealIncomingType: null,
            externalSubjectId: null,
            juridicalPersonId: null,
            externalSubjectCheckingAccountId: null,
            juridicalPersonCheckingAccountId: null,
            applyAt: moment().add(-10, 'days').endOf('months').startOf('day'),
            amount: {...emptyMoney},
            currentAmount: {...emptyMoney},
            comment: null,
            employeeId: null,
            modification: null
        },
        createDialogOpen: false,
        deal: {
            amount: {...emptyMoney},
            employeeId: null,
            externalSubjectId: null,
            companyEmployeeId: null,
            cancellationFact: null,
            juridicalPersonId: null,
            checkingAccountId: null,
            dealIncomingType: null,
            externalSubjectCheckingAccountId: null,
            juridicalPersonCheckingAccountId: null,
            modification: null
        },
        refreshDeals: false,
        trustedJuridicalPersons: [],
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            this.loadDebt(resultId[0]);
        }

        if (this.props.currentParlour) {
            this.setState({
                debt: {
                    ...this.state.debt,
                    businessUnitId: this.props.currentParlour.id,
                },
            }, this.getTrustedJuridicalPersons)
        }

        getExternalSubjects()
            .then(response => {
                if (response.success) {
                    this.setState({
                        externalSubjects: [
                            ...response.data
                        ],
                    });
                }
            });

        getBusinessUnitByRole('ROLE_MONEYFLOW_EXTERNAL_DEBT_MANAGE')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            ...response.data
                        ],
                    });
                }
            });

        getIncomingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        incomingTypes: [
                            ...response.data
                        ],
                    });
                }
        });

        getIncomingDealTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        incomingDealTypes: [
                            ...response.data
                        ],
                    });
            }
        });

        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [
                            ...response.data
                        ],
                    });
            }
        });
    }

    loadDebt = debtId => {
        getExternalDebt(debtId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        debt: response.data
                    }, () => {
                        this.getTrustedJuridicalPersons()
                        this.fetchExternalSubjectCheckingAccounts()
                        this.fetchJuridicalPersonCheckingAccounts()
                    });
                }
            });
    };

    getTrustedJuridicalPersons = () => {
        getJuridicalPersons({'trustedForBusinessUnit': this.state.debt.businessUnitId})
            .then(response => {
                if (response.success) {
                    this.setState({
                        trustedJuridicalPersons: response.data,
                    });
                }
            });
    }

    handleChangeDebt = prop => event => {
        let {value} = event.target
        const {
            debt
        } = this.state;

        this.setState({
            debt: {
                ...debt,
                [prop]: value,
            }
        }, () => {
            if (prop === 'businessUnitId') {
                this.getTrustedJuridicalPersons();
            }
            if (prop === 'juridicalPersonId') {
                this.setState({
                    debt: {
                        ...this.state.debt,
                        juridicalPersonCheckingAccountId: null
                    },
                    deal: {
                        ...this.state.deal,
                        juridicalPersonId: value,
                        juridicalPersonCheckingAccountId: null
                    }
                }, this.fetchJuridicalPersonCheckingAccounts)
            }
            if (prop === 'externalSubjectId') {
                this.setState({
                    debt: {
                        ...this.state.debt,
                        externalSubjectCheckingAccountId: null
                    },
                    deal: {
                        ...this.state.deal,
                        externalSubjectId: value,
                        externalSubjectCheckingAccountId: null
                    }
                }, this.fetchExternalSubjectCheckingAccounts)
            }
            if (prop === 'juridicalPersonCheckingAccountId') {
                this.setState({
                    deal: {
                        ...this.state.deal,
                        juridicalPersonCheckingAccountId: value
                    }
                })
            }
            if (prop === 'externalSubjectCheckingAccountId') {
                this.setState({
                    deal: {
                        ...this.state.deal,
                        externalSubjectCheckingAccountId: value
                    }
                })
            }
            if (prop === 'modification') {
                this.setState({
                    deal: {
                        ...this.state.deal,
                        modification: value
                    }
                })
            }
        });
    };

    handleSave = () => {
        saveExternalDebt(processMomentFieldInObject(this.state.debt, 'applyAt'))
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/money-flow/external-debt`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleDelete = () => {
        const { t } = this.props;

        this.props.showConfirm({
            message: t('Do you really want to remove the debt'),
            title: t('Debt deleting'),
            onClose: ok => {
                if (ok) {
                    deleteExternalDebt(this.state.debt.id)
                        .then(response => {
                            if (response.success) {
                                this.props.history.push(`/money-flow/external-debt`);
                            } else {
                                this.props.showMessage(response.error ? response.error.message : response.message);
                            }
                        });
                }
            }
        });
    };

    handleOpenDealDialog = () => {
        const { debt } = this.state;
        this.setState({
            deal: {
                amount: {...debt.currentAmount},
                employeeId: debt.employeeId,
                dealIncomingType: debt.dealIncomingType,
                externalSubjectId: debt.externalSubjectId,
                externalSubjectCheckingAccountId: debt.externalSubjectCheckingAccountId,
                companyEmployeeId: debt.companyEmployeeId,
                cancellationFact: null,
                juridicalPersonId: debt.juridicalPersonId,
                juridicalPersonCheckingAccountId: debt.juridicalPersonCheckingAccountId,
                checkingAccountId: null,
                modification: debt.modification
            },
            createDialogOpen: true
        })
    };

    handleChangeDeal = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        if (prop === 'dealIncomingType') {
            this.setState({
                debt: {
                    ...this.state.debt,
                    dealIncomingType: value
                }
            })
        }

        this.setState({
                    deal: {
                        ...this.state.deal,
                        [prop]: value,
                    }
                },
            () => {
                    if (prop === 'juridicalPersonId') {
                        this.resetDealCheckingAccountId()
                    }
                });
    };

    fetchJuridicalPersonCheckingAccounts = () => {
        const { debt } = this.state

        getCheckingAccounts({juridicalPersonId: debt.juridicalPersonId, active: true})
            .then((response) => {
                this.setState(
                    {
                        juridicalPersonCheckingAccounts: response.data,
                    }
                )
            })

    }

    fetchExternalSubjectCheckingAccounts = () => {
        const { debt } = this.state

        getCheckingAccounts({externalSubjectId: debt.externalSubjectId, active: true})
            .then((response) => {
                this.setState(
                    {
                        externalSubjectCheckingAccounts: response.data,
                    }
                )
            })
    }

    resetDealCheckingAccountId = () => {
        this.setState(
            {
                deal: {
                    ...this.state.deal,
                    checkingAccountId: null
                }
            }
        )
    }

    handleCreateDeal = () => {
        const { debt, deal } = this.state;

        createDealForExternalDebt({
            ...deal,
            employeeId: deal.employeeId === -1 ? null : deal.employeeId,
            externalDebtId: debt.id
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        createDialogOpen: false,
                        refreshDeals: !this.state.refreshDeals,
                    });
                    this.loadDebt(debt.id);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleCloseDialog = () => {
        this.setState({
            createDialogOpen: false,
        });
    }

    render() {
        const { t } = this.props;
        const {
            debt,
            externalSubjects,
            businessUnits,
            incomingTypes,
            incomingDealTypes,
            createDialogOpen,
            deal,
            refreshDeals,
            trustedJuridicalPersons,
            juridicalPersonCheckingAccounts,
            externalSubjectCheckingAccounts,
            employees
        } = this.state;

        const isDebtDataValid =
            !debt.businessUnitId ||
            !debt.reasonId ||
            !debt.dealIncomingType ||
            !debt.amount.val ||
            !debt.externalSubjectId ||
            !debt.externalSubjectCheckingAccountId ||
            !debt.applyAt ||
            (
                debt.dealIncomingType === dealIncomingTypeIds.incomingToCheckingAccount &&
                (!debt.juridicalPersonId || !debt.juridicalPersonCheckingAccountId)
            ) ||
            (
                [dealIncomingTypeIds.incomingToSafe, dealIncomingTypeIds.incomingToCashBox].includes(debt.dealIncomingType) &&
                debt.modification === null
            )

        const isDealValid =
            !deal.externalSubjectId ||
            !deal.dealIncomingType ||
            !deal.amount.val ||
            !deal.externalSubjectCheckingAccountId ||
            (
                deal.dealIncomingType === dealIncomingTypeIds.incomingToCheckingAccount &&
                (
                    !deal.juridicalPersonId ||
                    !deal.juridicalPersonCheckingAccountId
                )
            ) ||
            (
                [dealIncomingTypeIds.incomingToSafe, dealIncomingTypeIds.incomingToSafe].includes(deal.dealIncomingType) &&
                !deal.modification
            )

        let selectedBusinessUnit = businessUnits.find((item) => debt.businessUnitId === item.id);
        let currencies = selectedBusinessUnit ? [selectedBusinessUnit.mainCurrency] : [emptyMoney.cur];
        const result = { val: debt.amount.val * (1 - debt.modification), cur: debt.amount.cur };
        const dealResult = { val: deal.amount.val * (1 - deal.modification), cur: deal.amount.cur };

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ShowField
                        label={t('Current sum')}
                        value={debt.currentAmountFormat}
                        visible={Boolean(debt.id)}
                    />
                    <ActionButton
                        onClick={this.handleSave}
                        visible={hasRole('ROLE_MONEYFLOW_EXTERNAL_DEBT_MANAGE')}
                        disabled={isDebtDataValid}
                    >
                        {t('Save')}
                    </ActionButton>
                    <ActionButton
                        onClick={this.handleOpenDealDialog}
                        visible={hasRole('ROLE_MONEYFLOW_EXTERNAL_DEBT_MANAGE')}
                        disabled={!(debt.id && debt.currentAmount && debt.currentAmount.val > 0)}
                    >
                        {t('Write off debt')}
                    </ActionButton>
                    <ActionButton
                        onClick={this.handleDelete}
                        visible={hasRole('ROLE_MONEYFLOW_EXTERNAL_DEBT_MANAGE')}
                        disabled={!debt.id}
                    >
                        {t('Delete')}
                    </ActionButton>
                </LeftBar>
                <Content title={t("External debt card")}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                options={businessUnits.map(item => ({value: item.id, label: `${item.name}`}))}
                                label={t("Business unit")}
                                value={debt.businessUnitId}
                                required
                                error={!debt.businessUnitId}
                                onChange={this.handleChangeDebt('businessUnitId')}
                                fullWidth
                                readOnly={Boolean(debt.id)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Incoming type (type)")}
                                value={debt.reasonId}
                                required
                                error={!debt.reasonId}
                                onChange={this.handleChangeDebt('reasonId')}
                                fullWidth
                                options={incomingTypes.map(item => ({value: item.id, label: `${item.name}`}))}
                            />
                        </Grid>
                        {
                            debt.dealIncomingType === dealIncomingTypeIds.incomingToCashBox ?
                                <Grid item xs={3}>
                                    <AutocompleteSelectField
                                        label={t("Employee")}
                                        value={debt.employeeId}
                                        onChange={this.handleChangeDebt('employeeId')}
                                        fullWidth
                                        options={employees.map(item => ({value: item.id, label: `${item.name}`}))}
                                        isClearable
                                    />
                                </Grid> : null
                        }
                        {
                            debt.dealIncomingType === dealIncomingTypeIds.incomingToCheckingAccount ?  <>
                                <Grid item xs={3}>
                                    <AutocompleteSelectField
                                        label={t("Incoming juridical person")}
                                        value={debt.juridicalPersonId}
                                        error={!debt.juridicalPersonId}
                                        required
                                        onChange={this.handleChangeDebt('juridicalPersonId')}
                                        fullWidth
                                        options={trustedJuridicalPersons.map(item => ({value: item.id, label: `${item.fullName}`}))}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <AutocompleteSelectField
                                        label={t("Juridical person checking account")}
                                        value={debt.juridicalPersonCheckingAccountId}
                                        error={!debt.juridicalPersonCheckingAccountId}
                                        onChange={this.handleChangeDebt('juridicalPersonCheckingAccountId')}
                                        fullWidth
                                        required
                                        options={juridicalPersonCheckingAccounts.map(item => ({value: item.id, label: `${item.bankName} / ${item.currency} / ${item.juridicalPersonShortName}`}))}
                                    />
                                </Grid>
                            </> : null
                        }
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AnyField
                                id="type"
                                value={debt.dealIncomingType}
                                error={!debt.dealIncomingType}
                                onChange={this.handleChangeDebt('dealIncomingType')}
                                select
                                label={t("Payment type")}
                                required
                                fullWidth
                                readOnly={Boolean(debt.id)}>
                                {incomingDealTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <MoneyField
                                label={t('Sum')}
                                currencies={ currencies }
                                value={ debt.amount }
                                error = { !debt.amount }
                                required
                                onChange={this.handleChangeDebt('amount')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Counterparty (external subject)")}
                                value={debt.externalSubjectId}
                                required
                                onChange={this.handleChangeDebt('externalSubjectId')}
                                fullWidth
                                error={!debt.externalSubjectId}
                                options={externalSubjects.map(item => ({value: item.id, label: `${item.name}`}))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Counterparty checking account")}
                                value={debt.externalSubjectCheckingAccountId}
                                error={!debt.externalSubjectCheckingAccountId}
                                onChange={this.handleChangeDebt('externalSubjectCheckingAccountId')}
                                fullWidth
                                required
                                options={externalSubjectCheckingAccounts.map(item => ({value: item.id, label: `${item.bankName} / ${item.currency} / ${item.juridicalPersonShortName}`}))}
                            />
                        </Grid>
                        {
                            [dealIncomingTypeIds.incomingToCashBox, dealIncomingTypeIds.incomingToSafe].includes(debt.dealIncomingType) ? <>
                            <Grid item xs={3}>
                                <PercentField
                                    label={t("Commission in percents")}
                                    value={debt.modification}
                                    onChange={this.handleChangeDebt('modification')}
                                    fullWidth
                                    required
                                    error={(debt.modification === null || debt.modification === undefined)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <MoneyField
                                    label={t("Result")}
                                    value={ result }
                                    fullWidth
                                    readOnly
                                />
                            </Grid>
                            </> : null
                        }
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Comment")}
                                value={debt.comment}
                                onChange={this.handleChangeDebt('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateMonthField
                                label={t("Account for the month")}
                                onChange={this.handleChangeDebt('applyAt')}
                                value={debt.applyAt}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    {debt.id ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>Списания</Grid>
                            <Grid item xs={12}>
                                <DealsByReason
                                    reasonId={debt.id}
                                    refreshTable={refreshDeals}
                                />
                            </Grid>
                        </Grid>
                        : null
                    }
                    <Dialog
                        open={createDialogOpen}
                        onClose={this.handleCloseDialog}
                    >
                        <DialogTitle>{t('Create deal')}</DialogTitle>
                        <DialogContent style={{minHeight: 600, width: 400}}>
                            <MoneyField
                                label={t('Sum')}
                                currencies={ currencies }
                                value={ deal.amount }
                                error = { !deal.amount }
                                onChange={this.handleChangeDeal('amount')}
                                fullWidth
                            />
                            <AnyField
                                value={deal.dealIncomingType}
                                error={!deal.dealIncomingType}
                                onChange={this.handleChangeDeal('dealIncomingType')}
                                select
                                label={t("Payment type")}
                                required
                                fullWidth
                            >
                                {incomingDealTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>

                            <AutocompleteSelectField
                                label={t("Counterparty (external subject)")}
                                value={deal.externalSubjectId}
                                required
                                error={!deal.externalSubjectId}
                                onChange={this.handleChangeDebt('externalSubjectId')}
                                fullWidth
                                options={externalSubjects.map(item => ({value: item.id, label: `${item.name}`}))}
                            />
                            <AutocompleteSelectField
                                label={t("Counterparty checking account")}
                                value={deal.externalSubjectCheckingAccountId}
                                required
                                error={!deal.externalSubjectCheckingAccountId}
                                onChange={this.handleChangeDebt('externalSubjectCheckingAccountId')}
                                fullWidth
                                options={externalSubjectCheckingAccounts.map(item => ({value: item.id, label: `${item.bankName + ' / ' + item.juridicalPersonShortName}`}))}
                            />
                            {
                                [dealIncomingTypeIds.incomingToCheckingAccount].includes(debt.dealIncomingType) ? <>
                                    <AutocompleteSelectField
                                        label={t("Incoming juridical person")}
                                        value={deal.juridicalPersonId}
                                        required
                                        error={!deal.juridicalPersonId}
                                        onChange={this.handleChangeDebt('juridicalPersonId')}
                                        fullWidth
                                        options={trustedJuridicalPersons.map(item => ({value: item.id, label: `${item.shortName}`}))}
                                    />
                                    <AutocompleteSelectField
                                        label={t("Juridical person checking account")}
                                        value={deal.juridicalPersonCheckingAccountId}
                                        required
                                        error={!deal.juridicalPersonCheckingAccountId}
                                        onChange={this.handleChangeDebt('juridicalPersonCheckingAccountId')}
                                        fullWidth
                                        options={juridicalPersonCheckingAccounts.map(item => ({value: item.id, label: `${item.bankName + ' / ' + item.juridicalPersonShortName}`}))}
                                    />
                                </> : null
                            }
                            {
                                [dealIncomingTypeIds.incomingToCashBox, dealIncomingTypeIds.incomingToSafe].includes(debt.dealIncomingType) ? <>
                                    <PercentField
                                        label={t("Commission in percents")}
                                        value={debt.modification}
                                        onChange={this.handleChangeDebt('modification')}
                                        fullWidth
                                        required
                                        error={(debt.modification === null || debt.modification === undefined)}
                                    />
                                    <MoneyField
                                        label={t("Result")}
                                        value={ dealResult }
                                        fullWidth
                                        readOnly
                                    />
                                </> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <ActionButton
                                onClick={this.handleCreateDeal}
                                disabled={isDealValid}
                            >{t('OK')}</ActionButton>
                            <ActionButton onClick={this.handleCloseDialog}>{t('Cancel')}</ActionButton>
                        </DialogActions>
                    </Dialog>
                </Content>
            </React.Fragment>
        );
    }
}
