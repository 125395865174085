import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import BalanceChangesPage from "./BalanceChangesPage";
import TransactionsListPage from "./TransactionsListPage";
import ConvertDealPage from "./ConvertDealPage";
import SpendingDealPage from "./SpendingDealPage";
import IncomingDealPage from "./IncomingDealPage";
import LimitListPage from "./LimitListPage";
import LimitViewPage from "./LimitViewPage";
import DealOverratedListPage from "./DealOverratedListPage";
import EmployeeIncomingPage from "./EmployeeIncomingPage";
import SpendingReportByLimits from "./SpendingReportByLimits";
import CurrentBillsStatePage from "./CurrentBillsStatePage";
import VisitPaymentsReport from "./VisitPaymentsReport";
import CoursesListPage from "./CoursesListPage";
import CurrentBillsStateEmployeePage from "./CurrentBillsStateEmployeePage";
import TransactionsCashListPage from "./TransactionsCashListPage";
import TransactionsCashlessListPage from "./TransactionsCashlessListPage";
import BalancesCheckPage from "./BalancesCheckPage";
import SalaryEmployeeListPage from "./SalaryEmployeeListPage";
import SettlementHotelsPage from "./SettlementHotelsPage"
import ReferralPartnersReportPage from "./ReferralPartnersReportPage";
import DealListPage from "./DealListPage";
import DealViewPage from "./DealViewPage";
import ReferralPartnersReportFullPage from "./ReferralPartnersReportFullPage";
import BusinessUnitReportPage from "./BusinessUnitReportPage";
import FinanceReport from "./finance-report";
import SummaryDayReportNew from "./SummaryDayReportNew";
import SummaryReportPeriodNew from "./SummaryReportPeriodNew";
import DebtListPage from "./DebtListPage";
import ExternalDebtListPage from "./ExternalDebtListPage";
import DebtViewPage from "./DebtViewPage";
import ExternalDebtViewPage from "./ExternalDebtViewPage";
import DashboardReport from "./DashboardReport";
import GratuityReportPage from "./GratuityReportPage";
import SpendingReportPage from "./SpendingReportPage";
import MutualPayDocReports from "./MutualPayDocReports";
import DigitalizedMutualPayDocReports from "./DigitalizedMutualPayDocReports";
import SafeReport from "./SafeReport";
import PaymentLinksRerport from "./PaymentLinksReport";
import AdminsAndMastersDashboardReport from "./AdminsAndMastersDashboardReport";
import SpecialDiscountsReportPage from "./SpecialDiscountsReportPage";
import ClosedFinancialPeriodReport from "./ClosedFinancialPeriodReport";
import ClosedFinancialPeriodChangesReport from "./ClosedFinancialPeriodChangesReport";
import SpendingTransferPage from "./SpendingTransferPage"
import DeletedTransactionsListPage from "./DeletedTransactionsListPage";
import DeletedDealListPage from "./DeletedDealListPage";
import DeletedDebtListPage from "./DeletedDebtListPage";
import PayDocsBalanceCheckReport from "./PayDocsBalanceCheckReport";
import PayDocCommissionReport from "./PayDocCommissionReport";
import BusinessUnitBalanceReport from "./BusinessUnitBalanceReport";
import CurrentBillsStateInvestorPage from "./CurrentBillsStateInvestorPage";
import OSVSpendingTypeReport from "./OSVSpendingTypeReport";
import OSVCounterpartiesReport from "./OSVCounterpartiesReport";

export default class MoneyFlowPage extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/money-flow"            render={() => (<Redirect to="money-flow/transactions"/>)}/>
                <Route exact path="/money-flow/balance/changes"      component={BalanceChangesPage} />
                <Route exact path="/money-flow/transactions"      component={TransactionsListPage} />
                <Route exact path="/money-flow/deleted-transactions"      component={DeletedTransactionsListPage} />
                <Route exact path="/money-flow/deleted-deals"      component={DeletedDealListPage} />
                <Route exact path="/money-flow/deleted-debts"      component={DeletedDebtListPage} />
                <Route exact path="/money-flow/transactions-cash"      component={TransactionsCashListPage} />
                <Route exact path="/money-flow/transactions-cashless"      component={TransactionsCashlessListPage} />
                <Route exact path="/money-flow/convert"      component={ConvertDealPage} />
                <Route exact path="/money-flow/spending"      component={SpendingDealPage} />
                <Route exact path="/money-flow/spending-transfer"      component={SpendingTransferPage} />
                <Route exact path="/money-flow/incoming"      component={IncomingDealPage} />
                <Route exact path="/money-flow/limits"      component={LimitListPage} />
                <Route       path="/money-flow/limits/"      component={LimitViewPage} />
                <Route exact path="/money-flow/deals-overrated"      component={DealOverratedListPage} />
                <Route exact path="/money-flow/incoming-employee/report" component={EmployeeIncomingPage}/>
                <Route exact path="/money-flow/spending/report" component={SpendingReportByLimits}/>
                <Route exact path="/money-flow/summary-day-report-new" component={SummaryDayReportNew}/>
                <Route       path="/money-flow/summary-report-period-new" component={SummaryReportPeriodNew}/>
                <Route exact path="/money-flow/summary-day-report" component={SummaryDayReportNew}/>
                <Route       path="/money-flow/summary-report-period" component={SummaryReportPeriodNew}/>
                <Route exact path="/money-flow/bills/current-state" component={CurrentBillsStatePage}/>
                <Route exact path="/money-flow/bills/current-state-employee" component={CurrentBillsStateEmployeePage}/>
                    <Route exact path="/money-flow/bills/current-state-investor" component={CurrentBillsStateInvestorPage}/>
                    <Route exact path="/money-flow/visits-payments-report" component={VisitPaymentsReport}/>
                <Route exact path="/money-flow/safe-report" component={SafeReport}/>
                <Route exact path="/money-flow/courses" component={CoursesListPage}/>
                <Route exact path="/money-flow/balances-check" component={BalancesCheckPage}/>
                <Route exact path="/money-flow/salary" component={SalaryEmployeeListPage}/>
                <Route       path="/money-flow/settlement-hotels" component={SettlementHotelsPage}/>
                <Route exact path="/money-flow/referral-partners" component={ReferralPartnersReportPage}/>
                <Route exact path="/money-flow/deal" component={DealListPage}/>
                <Route       path="/money-flow/deal/id/" component={DealViewPage}/>
                <Route       path="/money-flow/deal/edit/" component={DealViewPage}/>
                <Route exact path="/money-flow/referral-partners-report" component={ReferralPartnersReportFullPage}/>
                <Route exact path="/money-flow/business-unit-report" component={BusinessUnitReportPage}/>
                <Route exact path="/money-flow/finance-report" component={FinanceReport}/>
                <Route exact path="/money-flow/debt" component={DebtListPage}/>
                <Route       path="/money-flow/debt/" component={DebtViewPage}/>
                <Route exact path="/money-flow/external-debt" component={ExternalDebtListPage}/>
                <Route       path="/money-flow/external-debt/" component={ExternalDebtViewPage}/>
                <Route       path="/money-flow/dashboard" component={DashboardReport}/>
                <Route       path="/money-flow/admins-and-masters-dashboard" component={AdminsAndMastersDashboardReport}/>
                <Route       path="/money-flow/gratuity-report" component={GratuityReportPage}/>
                <Route       path="/money-flow/spending-report" component={SpendingReportPage}/>
                <Route       path="/money-flow/mutual-pay-doc-reports" component={MutualPayDocReports}/>
                <Route       path="/money-flow/digitalized-mutual-pay-doc-reports" component={DigitalizedMutualPayDocReports}/>
                <Route exact path="/money-flow/payment-links-report" component={PaymentLinksRerport}/>
                <Route exact path="/money-flow/special-discounts-report" component={SpecialDiscountsReportPage}/>
                <Route exact path="/money-flow/closed-financial-periods-report" component={ClosedFinancialPeriodReport}/>
                <Route exact path="/money-flow/closed-financial-period-changes-report" component={ClosedFinancialPeriodChangesReport}/>
                <Route exact path="/money-flow/pay-docs-balance-check-report" component={PayDocsBalanceCheckReport}/>
                <Route exact path="/money-flow/pay-doc-commission-report" component={PayDocCommissionReport}/>
                <Route exact path="/money-flow/vgo/business-unit-balance-report" component={BusinessUnitBalanceReport}/>
                <Route exact path="/money-flow/vgo/osv-spending-type-report" component={OSVSpendingTypeReport}/>
                <Route exact path="/money-flow/vgo/osv-counterparties-report" component={OSVCounterpartiesReport}/>
            </Switch>
        );
    }
}
