import React from 'react';
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    root: {
        whiteSpace: 'nowrap',
    },
});

const NoWrap = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {children}
        </div>
    );
};

NoWrap.propTypes = {
    children: PropTypes.node,
};

export default NoWrap;