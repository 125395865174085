import React from "react";
import NoWrap from "./NoWrap";
import MoneyList from "./MoneyList";
import MoneyListDetailsDialog from "./MoneyListDetailsDialog";
import {Link} from "react-router-dom";
import {formatMoney, entityLinkTemplates, entityClassNames} from "../../../../../services/common";
import CorrectValue from "./CorrectValue";
import NonCorrectValue from "./NonCorrectValue";

const emptyReasonValue = '-'

const processReasonValue = (value, t, row) => {
    if (row.reasonClass === entityClassNames.sale) {
        return processSaleReasonValue(value, t, row)
    }

    if (row.reasonClass === entityClassNames.incomingType) {
        return processIncomingTypeReasonValue(value, t, row)
    }

    return emptyReasonValue
}

const processSaleReasonValue = (value, t, row) => {
    let saleEntities = '';
    if (value?.length) {
        saleEntities = ' (';
        saleEntities += value.map(it => it.typeText + (it.name ? ` ${it.name}` : '')).join(', ');
        saleEntities += ')';
    }

    return (
        <Link
            to={`${entityLinkTemplates.Sale}${row.reasonId}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {t('Sale')}{saleEntities}
        </Link>
    )
}

const processIncomingTypeReasonValue = (value, t, row) => {
    return (
        <Link
            to={`${entityLinkTemplates.IncomingDeal}${row.dId}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {t('Incoming')} ({row.reasonName})
        </Link>
    )
}

const processDetailsAmount = (value, t, row) => {
    const v = formatMoney(value);

    if (typeof row.correctAmount !== 'undefined') {

        if (row.correctAmount) {
            return <CorrectValue>{v}</CorrectValue>;
        }

        return <NonCorrectValue title={row.incomeAmount}>{v}</NonCorrectValue>;
    }

    return v;
};

const detailsColumns = [
    { id: 'tDate', dateFormat: 'DD.MM.YYYY HH:mm', label: 'Date' },
    { id: 'reasonInfo', label: 'Reason name', processValue: processReasonValue },
    { id: 'amount', label: 'Amount', processValue: processDetailsAmount },
]

const revenue = (value, t, row) => {
    if (!value) {
        return null;
    }

    const cashErrors = {};
    if (value.compareCash?.length) {
        value.compareCash.forEach(money => {
            if (money.val) {
                cashErrors[money.cur] = money.val;
            }
        })
    }
    const cashlessErrors = {};
    if (value.compareCashless?.length) {
        value.compareCashless.forEach(money => {
            if (money.val) {
                cashlessErrors[money.cur] = money.val;
            }
        })
    }

    if (value?.cashlessDetails?.length && (row.income?.details?.length || row.pdBalance?.detailsIn?.length)) {
        const cashlessIncome = row.income?.details?.filter(d => !d.isFree && d.type === 'cashless') || [];
        const pdPayments = [];
        if (row.pdBalance?.detailsIn?.length) {
            row.pdBalance.detailsIn.forEach(d => {
                d.payments.forEach(p => {
                    if (p.type === 'cashless') {
                        pdPayments.push({ ...p, pdId: d.pdId });
                    }
                })
            });
        }
        value.cashlessDetails.forEach(d => {
            if (d.hasOwnProperty('correctAmount')) {
                return;
            }
            let foundAmount = 0;
            const foundCi = cashlessIncome.filter(ci => ci.paymentId === d.paymentId);
            if (foundCi) {
                foundCi.forEach(ci => {
                    foundAmount += ci.amount.val;
                });
            }
            const foundPp = pdPayments.filter(pp => pp.id === d.paymentId);
            if (foundPp) {
                foundPp.forEach(pp => {
                    foundAmount += pp.amount.val;
                });
            }
            d.correctAmount = foundAmount === d.amount.val;
            d.incomeAmount = foundAmount;
        });
    }

    return <NoWrap>
        <MoneyList title="f.Total" value={value.total}/>
        <MoneyListDetailsDialog title="f.Cash" value={value.cash} detailsData={value.cashDetails} detailsTitle="f.Cash"
                                detailsColumns={detailsColumns} errors={cashErrors}/>
        <MoneyListDetailsDialog title="f.Cashless" value={value.cashless} detailsData={value.cashlessDetails}
                                detailsTitle="f.Cashless" detailsColumns={detailsColumns} errors={cashlessErrors}/>
        <MoneyListDetailsDialog title="f.Checking account" value={value.checkingAccount}
                                detailsData={value.checkingAccountDetails} detailsTitle="f.Checking account"
                                detailsColumns={detailsColumns}/>
    </NoWrap>;
};

export default revenue;
