import * as queryString from "query-string-object";
import baseRequest from "./baseReuqest";
import {currentSystemSpendingLimit, defaultCurrency, emptyDelivery, emptyMoney} from "./common";
import moment from "moment";
import _ from 'lodash';
import {processSearchDates} from "./moneyFlow";

export const navLinks = [
    { path: "/goods", text: "Товары и склад", role: "ROLE_UI_TAB_GOOD"},
    { path: "/goods/receipt", text: "Приемка товара", role: "ROLE_UI_TAB_GOOD_RECEIPT"},
    { path: "/goods/sell", text: "Продажа товара", role: "ROLE_UI_TAB_GOOD_SELL"},
    { path: "/goods/requests", text: "Запрос товара", role: "ROLE_UI_TAB_GOOD_REQUESTS"},
    { path: "/goods/orders", text: "Заказ товара", role: "ROLE_UI_TAB_GOOD_ORDERS"},
    { path: "/goods/move", text: "Перемещение товара", role: "ROLE_UI_TAB_GOOD_MOVE"},
    { path: "/goods/check", text: "Инвентаризация товаров", role: "ROLE_UI_TAB_GOOD_CHECK"},
    { path: "/goods/prices", text: "Цены товаров", role: "ROLE_UI_TAB_GOOD_PRICE"},
    { path: "/goods/reports", text: "Отчеты по товарам", role: "ROLE_UI_TAB_GOOD_REPORTS", group: 'Reports'},
    { path: "/goods/history", text: "История продажи и расходования", role: "ROLE_UI_TAB_GOOD_HISTORY", group: 'Reports'},
    { path: "/goods/report/sold", text: "Проданные товары", role: "ROLE_GOODS_REPORT_SALE", group: 'Reports'},
    { path: "/goods/invoice", text: "Invoices", role: "ROLE_UI_TAB_GOODSTORAGE_INVOICE"},
    { path: "/goods/report/cosmetics-movement", text: "Отчёт о движении косметики", role: "ROLE_GOODS_REPORT_SALE", group: 'Reports'},
    { path: "/goods/consumption-group", text: "Consumption groups", role: "ROLE_UI_TAB_CONSUMPTIONGROUP"},
    { path: "/goods/revision", text: "Revisions", role: "ROLE_GOOD_GOODSTORAGE_REVISION_GET"},
];

export function getGoods(search = null, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/goods', {
        query: { search: {...search, 'active': 'any'}, page, per_page, order, order_by },
    });
}

export function getGood(id) {
    return baseRequest(`/api/goods/id/${id}`);
}

export function createGood(good) {
    return baseRequest('/api/goods', {
        method: 'POST',
        body: JSON.stringify({...good}),
    });
}

export function updateGood(good) {
    return baseRequest(`/api/goods/id/${good.id}`, {
        method: 'PUT',
        body: JSON.stringify({...good}),
    });
}


export function getCategories(search = null, page = 1, per_page = -1) {
    let url = '/api/good/categories';
    const q = queryString.stringify({search, page, per_page});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function createCategory(category) {
    return baseRequest('/api/good/categories', {
        method: 'POST',
        body: JSON.stringify({...category}),
    });
}

export function updateCategory(category) {
    return baseRequest(`/api/good/categories/id/${category.id}`, {
        method: 'PUT',
        body: JSON.stringify({...category}),
    });
}

export function getGoodPurposes() {
    return baseRequest('/api/good/type/purposes');
}

export function getGoodStorageStatuses() {
    return baseRequest('/api/good/statuses');
}

export function getGoodStorageRequests(search, page, per_page, order, order_by)
{
    let url = '/api/good-storage/request';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getGoodStorageRequest(id) {
    return baseRequest(`/api/good-storage/request/${id}`);
}

export function processRequestAfterGet(request) {
    return {
        ...request,
        parlour: request.parlour ? request.parlour.id : null,
        goodStorageGrouped: request.goodStorageGrouped.map(item => ({...item, category: {...item.good.category}})),
    };
}


export function saveRequest(request) {
    if (request.id) {
        return baseRequest(`/api/good-storage/request/${request.id}`, {
            method: 'PUT',
            body: JSON.stringify({...request}),
        });
    } else {
        return baseRequest('/api/good-storage/request', {
            method: 'POST',
            body: JSON.stringify({...request}),
        });
    }
}

export function sendRequest(request) {
    return baseRequest(`/api/good-storage/request/send-co/${request.id}`, {
        method: 'PUT',
        body: JSON.stringify({...request}),
    });
}

export function declineRequest(request) {

    return baseRequest(`/api/good-storage/request/decline/${request.id}`, {
        method: 'PUT',
        body: JSON.stringify({...request}),
    });
}

export function getRequestHistoryStatus(id, page, per_page, order, order_by) {
    let url = `/api/good-storage/request/history/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}


export function getGroupedFromRequests(requestsIds) {
    return baseRequest('/api/good-storage/grouped/', {
        method: 'POST',
        body: JSON.stringify(requestsIds),
    });
}

export function createOrders(grouped, parentId) {

    let data = grouped.map(item => ({ ...item, parlour: item.parlourId, good: item.goodId }));

    if (parentId) {
        data = {
            parentId: parentId,
            data: data,
        }
    }

    return baseRequest('/api/good-storage/order/create-orders', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export function createOrder(data) {
    return baseRequest('/api/good-storage/order', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export function getOrders(search, page, per_page, order, order_by) {
    let url = '/api/good-storage/order';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);

}

export function getOrder(id) {
    return baseRequest(`/api/good-storage/order/${id}`).then((response) => {
        if (response.success) {
            return getLimits(response.data.parlour.id).then((limitData) => {
                response.data.amountTotalFormatForUseLimit = limitData.amountTotalFormatForUseLimit;
                response.data.amountTotalFormatForSaleLimit = limitData.amountTotalFormatForSaleLimit;
                return response;
            });
        }
    });
}

export function processOrderAfterGet(order) {
    return {
        ...order,
        price: { //TODO Тут с валютами надо подумать, просто так складывать нельзя
            val: order.goodStorageGroupedOrder.map(item => item.price && item.price.val ? parseFloat(item.price.val)*item.quantity : 0).reduce((a, b) => a + b, 0),
            cur: defaultCurrency,
        },
        goodStorageGroupedOrder: order.goodStorageGroupedOrder.map(item => {
            return {
                ...item,
                price: item.price || {...emptyMoney},
                usernamesRequests: _.uniq(item.goodStorageGrouped.map(item => item.request.creator.username)).join(','),
                phonesRequests: _.uniq(item.goodStorageGrouped.map(item => item.request.creator.phone)).join(','),
                numsRequests: item.goodStorageGrouped.map(item => item.request.num).join(','),
            }
        }),
        delivery: order.delivery || emptyDelivery
    };
}

function getLimits(businessUnitId) {
    if (!businessUnitId) {
        return;
    }

    let useLimit = currentSystemSpendingLimit(businessUnitId, 'good_storage_order_service');
    let saleLimit = currentSystemSpendingLimit(businessUnitId, 'good_storage_order_sale');
    return Promise.all([useLimit, saleLimit]).then(responses => {
        let amountTotalFormatForSaleLimit;
        let amountTotalFormatForUseLimit;

        if (responses[0].success) {
            amountTotalFormatForUseLimit = responses[0].data.currentAmountFormatted;
        } else {
            amountTotalFormatForUseLimit = responses[0].error ? responses[0].error.message : responses[0].message;
        }

        if (responses[1].success) {
            amountTotalFormatForSaleLimit = responses[1].data.currentAmountFormatted;
        } else {
            amountTotalFormatForSaleLimit = responses[1].error ? responses[1].error.message : responses[1].message;
        }

        return {
            amountTotalFormatForUseLimit: amountTotalFormatForUseLimit,
            amountTotalFormatForSaleLimit: amountTotalFormatForSaleLimit
        };
    });

}

export function getOrderHistoryStatus(id, page, per_page, order, order_by) {
    let url = `/api/good-storage/order/history/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getOrderHistoryDeliveryDate(id, page, per_page, order, order_by) {
    let url = `/api/good-storage/order/history/delivery-date/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function acceptOrder(order) {
    return baseRequest(`/api/good-storage/order/accept/${order.id}`, {
        method: 'PUT',
        body: JSON.stringify(order),
    }).then((response) => {
        if (response.success) {
            return getLimits(response.data.parlour.id).then((limitData) => {
                response.data.amountTotalFormatForUseLimit = limitData.amountTotalFormatForUseLimit;
                response.data.amountTotalFormatForSaleLimit = limitData.amountTotalFormatForSaleLimit;
                return response;
            });
        }

        return response;
    });


}

export function declineOrder(order) {
    return baseRequest(`/api/good-storage/order/decline/${order.id}`, {
        method: 'PUT',
        body: JSON.stringify(order),
    }).then((response) => {
        if (response.success) {
            return getLimits(response.data.parlour.id).then((limitData) => {
                response.data.amountTotalFormatForUseLimit = limitData.amountTotalFormatForUseLimit;
                response.data.amountTotalFormatForSaleLimit = limitData.amountTotalFormatForSaleLimit;
                return response;
            });
        }
    });
}

export function completeOrder(order) {
    return baseRequest(`/api/good-storage/order/complete/${order.id}`, {
        method: 'PUT',
        body: JSON.stringify(order),
    });
}

export function setOrderDeliveryDate(data) {
    return baseRequest(`/api/good-storage/order/delivery-date/${data.orderId}`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });
}

export function setDeliveryOrder(id, data) {
    return baseRequest(`/api/good-storage/order/delivery/${id}`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export function getOrderBlankUrl(id, parameters)
{
    let url = `/api/good-storage/order/blank/${id}`;
    const q = queryString.stringify(parameters);
    if (q) {
        url += `?${q}`;
    }

    return url;
}

export function getStorageOrderRest(id) {
    return baseRequest(`/api/good-storage/order/rest/${id}`);
}


export function getGoodStorages(search, page, per_page, order, order_by) {
    processSearchDates(search);
    return baseRequest('/api/goods-storage', {
        query: {search, page, per_page, order, order_by},
    });
}

export function getExpressDeliveryGoodPrices(id) {
    return baseRequest(`/api/good-price/express-delivery/${id}`);
}

export function getGoodStorage(id) {
    return baseRequest(`/api/good-storage/id/${id}`);
}

export function getReportExpiredUrl(parameters)
{
    let url = '/api/good-storage/report/expired';
    const q = queryString.stringify(parameters);
    if (q) {
        url += `?${q}`;
    }

    return url;
}

export function getReportExpired(page, per_page, order, order_by) {
    return baseRequest(getReportExpiredUrl({ page, per_page, order, order_by}));

}

export function getReportConsumptionUrl(parameters)
{
    let url = '/api/good-storage/report/consumption';
    const q = queryString.stringify(parameters);
    if (q) {
        url += `?${q}`;
    }

    return url;
}

export function getReportConsumption(search, page, per_page, order, order_by) {
    return baseRequest(getReportConsumptionUrl({ search, page, per_page, order, order_by}));

}

export function getReportMovingUrl(parameters)
{
    let url = '/api/good-storage/report/moving';
    const q = queryString.stringify(parameters);
    if (q) {
        url += `?${q}`;
    }

    return url;
}

export function getReportMoving(search, page, per_page, order, order_by) {
    return baseRequest(getReportMovingUrl({ search, page, per_page, order, order_by}));

}

export function getReportRestUrl(parameters)
{
    let url = '/api/good-storage/report/rest';
    const q = queryString.stringify(parameters);
    if (q) {
        url += `?${q}`;
    }

    return url;
}

export function getReportRest(search, page, per_page, order, order_by) {
    return baseRequest(getReportRestUrl({ search, page, per_page, order, order_by}));

}

export function cancelGoodStorage(data)
{
    return baseRequest(`/api/good-storage/cancel/`, {
        method: 'PUT',
        body: JSON.stringify(data),
    });

}

export function getGoodStorageConsumption(search, page, per_page, order, order_by) {
    let url = '/api/good-storage/consumption';
    processSearchDates(search);
    return baseRequest(url, {query: { search, page, per_page, order, order_by}});

}

export function createGoodStorageFast(goodStorage) {
    return baseRequest(`/api/good-storage-fast`, {
        method: 'POST',
        body: JSON.stringify({...goodStorage}),
    });
}

export function createGoodsStorageFast(data) {
    return baseRequest(`/api/goods-storage-fast`, {
        method: 'POST',
        body: JSON.stringify({...data}),
    });
}

export function updateGoodStorage(goodStorage) {
    return baseRequest(`/api/good-storage/${goodStorage.id}`, {
        method: 'PUT',
        body: JSON.stringify(goodStorage),
    });
}

export function setGoodStorageConsumption(goodStorage) {
    return baseRequest(`/api/good-storage/consumption/${goodStorage.id}`, {
        method: 'POST',
        body: JSON.stringify(goodStorage),
    });
}

export function sendRevision(revision) {
    return baseRequest(`/api/good-storage/revision`, {
        method: 'POST',
        body: JSON.stringify(revision),
    });
}

export function getRevisions(search, page, per_page, order, order_by) {
    return baseRequest(`/api/good-storage/revision`, {
        query: {search, page, per_page, order, order_by}
    });
}

export function getRevision(id) {
    return baseRequest(`/api/good-storage/revision/id/${id}`, {query: {include: 'consumptions'}});
}

export function getGoodStorageSales(search, page, per_page, order, order_by) {
    return baseRequest('/api/good-storage/sale', {
        query: {search, page, per_page, order, order_by}
    });
}

export function getGoodStorageSale(id) {
    return baseRequest(`/api/good-storage/sale/${id}`);
}

export function processSale(sale) {
    return {
        ...sale,
        parlour: sale.parlour.id,
        guest: sale.guest.id,
    };
}

export function createOrUpdateGoodStorageSale(sale) {
    return baseRequest('/api/good-storage/sale', {
        method: 'POST',
        body: sale,
    });
}

export function completeGoodStorageSale(sale) {
    return baseRequest(`/api/good-storage/sale/complete/${sale.id}`, {
        method: 'PUT',
        body: sale,
    });
}

export function cancelGoodStorageSale(id) {
    return baseRequest('/api/good-storage/sale', {
        method: 'DELETE',
        body: { id },
    })
}

export function changeDateGoodStorageSale(id, date) {
    return baseRequest('/api/good-storage/sale/created-at', {
        method: 'PUT',
        body: { id, date },
    })
}

export function getMoveRequests(search, page, per_page, order, order_by)
{
    let url = '/api/good-storage/move';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getMoveRequest(id) {
    return baseRequest(`/api/good-storage/move/${id}`);
}

export function processMoveRequestAfterGet(moveRequest) {
    return {
        ...moveRequest,
        businessUnit: moveRequest.parlour,
        parlour: moveRequest.parlour ? moveRequest.parlour.id : null,
        newParlour: moveRequest.newParlour ? moveRequest.newParlour.id : null,
        parlourName: moveRequest.parlour ? moveRequest.parlour.name : null,
        newParlourName: moveRequest.newParlour ? moveRequest.newParlour.name : null,
    };
}

export function saveMoveRequest(moveRequest) {
    if (moveRequest.id) {
        return baseRequest(`/api/good-storage/move/${moveRequest.id}`, {
            method: 'PUT',
            body: JSON.stringify({...moveRequest}),
        });
    }

    return baseRequest('/api/good-storage/move', {
        method: 'POST',
        body: JSON.stringify({...moveRequest}),
    });
}

export function sendMoveRequestToCO(moveRequest) {
    return baseRequest(`/api/good-storage/move/send-co/${moveRequest.id}`, {
        method: 'PUT',
        body: JSON.stringify({...moveRequest}),
    });
}

export function acceptMoveRequest(moveRequest) {
    return baseRequest(`/api/good-storage/move/accept/${moveRequest.id}`, {
        method: 'PUT',
        body: JSON.stringify({...moveRequest}),
    });
}

export function declineMoveRequest(moveRequest) {
    return baseRequest(`/api/good-storage/move/decline/${moveRequest.id}`, {
        method: 'PUT',
        body: JSON.stringify({...moveRequest}),
    });
}

export function deliveryMoveRequest(id) {
    return baseRequest(`/api/good-storage/set-delivery/${id}`, {
        method: 'PUT',
    });
}

export function setDeliveryMove(id, data) {
    return baseRequest(`/api/good-storage/move/delivery/${id}`, {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export function completeMoveRequest(moveRequest) {
    return baseRequest(`/api/good-storage/move/complete/${moveRequest.id}`, {
        method: 'PUT',
        body: JSON.stringify({...moveRequest}),
    });
}

export function getMoveHistoryStatus(id, page, per_page, order, order_by) {
    let url = `/api/good-storage/move/history/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getGoodPrices(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/good-price';

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getGoodsByBusinessUnits(search = {}, page = 1, per_page = -1, order = 'asc', order_by = 'name')
{
    let url = '/api/goods/by-business-units'
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getGoodPrice(id) {
    return baseRequest(`/api/good-price/id/${id}`);
}

export function saveGoodPrice(goodPrice) {
    return baseRequest('/api/good-price', {
        method: 'POST',
        body: JSON.stringify({...goodPrice}),
    });
}

export function getGoodPriceByGood(goodId) {
    return baseRequest(`/api/good-price/good/${goodId}`);
}


export const requestStatuses = {
    /** Новый */
    new: 'new',

    /** Согласование ЦО*/
    main_pending: 'main_pending',

    /** Отклонено ЦО*/
    main_rejected: 'main_rejected',

    /** Создан заказ */
    ordered: 'ordered',

    /** Частично созданный заказ */
    partly_ordered: 'partly_ordered',

    /** Закрыт */
    closed: 'closed',
};

export const emptyGood = {
    id: null,
    category: {
        id: null,
        name: null,
    },
    good: {
        id: null,
        name: null,
        description: null,
        massFormat: null,
    },
    price: {...emptyMoney},
    priceFormat: null,
    quantityStorage: '-',
    quantity: null,
    quantityDelivery: '-',
    quantityRecommend: '-',
    status: 'requested',
    statusText: 'В запросе',
    purpose: null,
    purposeText: null
};

export const emptyRequest = {
    id: null,
    num: null,
    createdAt: null,
    creator: {
        username: null,
        phone: null,
    },
    comment: null,
    status: requestStatuses.new,
    statusText: 'Новый',
    goodStorageGrouped: [],
    parlour: null,
    price: {...emptyMoney},
};

export const orderStatuses = {
    /** Новый */
    new: 'new',

    /** На подтверждении контрагентом */
    counterpart_pending: 'counterpart_pending',

    /** Подтверждено контрагентом */
    counterpart_ok: 'counterpart_ok',

    /** Отклонено контрагентом */
    counterpart_rejected: 'counterpart_rejected',

    /** Согласование бухгалтерией */
    accounting_pending: 'accounting_pending',

    /** Отклонено бухгалтерией */
    accounting_rejected: 'accounting_rejected',

    /** Отклонено ЦО */
    main_rejected: 'main_rejected',

    /** Ожидает поставки */
    delivery_awaiting: 'delivery_awaiting',

    /** Доставка */
    delivery: 'delivery',

    /** Получено салоном */
    received: 'received',

    /** Частично получен салоном */
    received_partly: 'received_partly',

    /** Лимит привышен */
    limit_overrated: 'limit_overrated'
};

export const emptyOrder = {
    id: null,
    num: null,
    createdAt: null,
    creator: {
        username: null,
        phone: null,
    },
    comment: null,
    status: orderStatuses.new,
    statusText: 'Новый',
    goodStorageGroupedOrder: [],
    parlour: {
        id: null,
        name: null,
    },
    price: {
        val: null,
        cur: null,
    },
    counterpart: {
        id: null,
        username: null,
    },
    amountDelayPay: {...emptyMoney},
    amountDelayPayFormat: null,
    amountPrePay: {...emptyMoney},
    amountPrePayFormat: null,
    amountTotal: {...emptyMoney},
    amountTotalFormat: null,

    amountTotalForUse: {...emptyMoney},
    amountTotalFormatForUse: null,
    amountTotalFormatForUseLimit: null,
    amountTotalForSale: {...emptyMoney},
    amountTotalFormatForSale: null,
    amountTotalFormatForSaleLimit: null,

    bill: null,
    delayPeriod: null,
    payTypes: null,

    delivery: emptyDelivery,

    invoice: null,
    consignmentNote: [],

    receiptComment: null,
    deliveryDate: null
};

export const emptyOrderGood = {
    id: null,
    good: {
        id: null,
        name: null,
        category: {
            id: null,
            name: null,
        },
        description: null,
        price: {...emptyMoney},
        quantity: {
            val: null,
            unit: 'G',
        },
        massFormat: null,
    },
    price: {...emptyMoney},
    quantity: null,
    purpose: null,
    purposeText: null,
    counterpartQuantity: null,
    parent: null,
    children: []
};

export const emptyStorageGood = {
    id: null,
    status: null,
    statusText: null,
    good: null,
    parlour: null,
    priceFormat: null,
    purchasePriceFormat: null,
    expiration: null,
    currentMass: null,
    currentMassFormatted: null,
    num: null,
    canUse: null,
    canUseText: null,
    useQueue: null,
    comment: null,
    createdAt: null,
    expiredDays: null,
    currentMassFact: null,
    purpose: null,
    purposeText: null,
    unitText: null
};

export const goodStorageStatuses = {
    /** В запросе на передачу */
    moving_request: 'moving_request',

    /** В доставке */
    delivery: 'delivery',

    /** Отклонен */
    rejected: 'rejected',

    /** Списан */
    removed: 'removed',

    /** Продан */
    sold: 'sold',

    /** В продаже */
    on_sale: 'on_sale',

    /** В салоне */
    in_storage: 'in_storage',

    /** Используется, тратится */
    using: 'using',
};

export const saleStatuses = {
    /** Новый */
    new: 'new',

    /** Оплачен */
    complete: 'complete',

    /** Возврат */
    refund: 'refund',

    /** Закрыт */
    closed: 'closed'
};

export const goodPurposes = {
    forSale: 'for_sale',
    forUse: 'for_use'
}

export const emptySale = {
    id: null,
    num: null,
    status: saleStatuses.new,
    statusText: 'Новый',
    comment: null,
    parlour: null,
    guest: null,
    storageGoods: [],
    createdAt: null,
    creator: {},
    bill: null,
    amountTotal: {...emptyMoney},
    amountTotalFormat: null,
    delayPeriodId: null,
    delayPeriod: null,
    deliveryType: null,
    transportCompany: null,
    waybillNumber: null,
    receiptScan: null,
    deliveryCharges: {...emptyMoney},
    deliveryChargesFormat: null,
    address: null,
    saleId: null,
    discountId: null,
};

export const emptyMoveRequest = {
    id: null,
    num: null,
    createdAt: moment(),
    creator: {
        username: null,
        phone: null,
    },
    comment: null,
    status: requestStatuses.new,
    statusText: 'Новый',
    storageGoods: [],
    newParlour: null,
    parlour: null,
    parlourName: null,
    newParlourName: null,
    delivery: emptyDelivery,
};

export const moveStatuses = {
/** Новый */
new: 'new',

/** Согласование ЦО */
main_pending: 'main_pending',

/** Согласовано ЦО */
main_ok: 'main_ok',

/** Отклонено ЦО */
main_rejected: 'main_rejected',

/** Доставка */
delivery: 'delivery',

/** Получено салоном */
received: 'received',
};

export function getGoodsReportSales(search, page, per_page, order, order_by) {
    return baseRequest('/api/good-storage/report/sale', {
        query: {search, page, per_page, order, order_by},
    });
}

export function getGoodStorageForRevision(search, page, per_page, order, order_by) {
    return baseRequest("/api/goods-storage/for-revision", {
        query: {search, page, per_page, order, order_by},
    });
}

export function getGoodStorageInvoices(search, page, per_page, order, order_by) {
    let dateFormat = 'YYYY-MM-DD';
    if (moment.isMoment(search.dateStart)) search.dateStart = search.dateStart.format(dateFormat);
    if (moment.isMoment(search.dateEnd)) search.dateEnd = search.dateEnd.format(dateFormat);
    return baseRequest("/api/good-storage/invoice", {
        query: {search, page, per_page, order, order_by},
    });
}

export function getGoodStorageInvoice(id) {
    return baseRequest(`/api/good-storage/invoice/id/${id}`);
}

function processGoodStorageInvoiceData(data) {
    let dateFormat = 'YYYY-MM-DD';
    if (moment.isMoment(data.applyAt)) data.applyAt = data.applyAt.format(dateFormat);
    if (data.scanId) data.scanId = data.scanId.id;
    return data;
}

export function saveGoodStorageInvoice(data) {
    return baseRequest("/api/good-storage/invoice", {
        method: 'POST',
        body: processGoodStorageInvoiceData(data),
    });
}

export function completeGoodStorageInvoice(data) {
    return baseRequest("/api/good-storage/invoice/complete", {
        method: 'PUT',
        body: processGoodStorageInvoiceData(data),
    });
}

export function deleteGoodStorageInvoice(data) {
    return baseRequest("/api/good-storage/invoice", {
        method: 'DELETE',
        body: data,
    });
}

export function removeGoodStorageInvoice(data) {
    return baseRequest("/api/good-storage/invoice-remove-storage", {
        method: 'DELETE',
        body: data,
    });
}

export function getGoodStorageInvoiceStatuses() {
    return baseRequest('/api/good-storage/invoice/statuses');
}

export function getConsumptionGroups(search, page, per_page, order, order_by) {
    return baseRequest("/api/good/consumption-groups", {
        query: {search, page, per_page, order, order_by},
    });
}

export function getConsumptionGroup(id) {
    return baseRequest(`/api/good/consumption-groups/id/${id}`);
}

export function saveConsumptionGroup(data) {
    return baseRequest("/api/good/consumption-groups", {
        method: 'POST',
        body: {...data, goods: data.goods.map(good => ({...good, category: good.category.id}))},
    });
}

export function getCosmeticsMovementReport(search, page, per_page, order, order_by) {
    return baseRequest("/api/good-storage/cosmetic-movement-report", {
        query: {search, page, per_page, order, order_by},
    });
}
export function uploadScanWithTelegram(id) {
    return baseRequest(`/api/good-storage/invoice/upload-scan-with-telegram/${id}`);
}


export function reopenGoodStorageSale(id) {
    return baseRequest(`/api/good-storage/sale/reopen/${id}`, {
        method: "PUT",
    });
}

export function getGoodStorageConsumptionTypes() {
    return baseRequest('/api/good-storage/consumption-types')
}

export function getMaterialsForCraft(search = null, page = 1, per_page = 9999, order = '', orderBy = '') {
    return baseRequest('/api/goods/materials-for-craft', {
        query: {search, page, per_page, order, orderBy},
    });
}
