import React from "react";
import NoWrap from "./NoWrap";
import MoneyList from "./MoneyList";

const otherSupply = (value, t, row) => {
    return <NoWrap>
        <MoneyList title="Club TT" value={value.clubTT}/>
        <MoneyList title="Lump sum" value={value.lumpSum}/>
        <MoneyList title="Consulting" value={value.consulting}/>
        <MoneyList title="Subscription fee" value={value.subscriptionFee}/>
        <MoneyList title="Other supply" value={value.otherSupply}/>
    </NoWrap>;
};

export default otherSupply;