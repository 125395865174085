import React from "react";
import {Route, Switch} from "react-router";
import TimeSearchPage from "./TimeSearchPage";
import LoadParlorsPage from "./LoadParlorsPage";
import LoadZvonobotFile from "./LoadZvonobotFile"
import GuestSearchPage from "./GuestSearchPage";

export default class CallCenterPage extends React.Component {
    render() {
        return <Switch>
            <Route exact path="/call-center" component={TimeSearchPage}/>
            <Route exact path="/call-center/guests-search" component={GuestSearchPage}/>
            <Route exact path="/call-center/load-parlors" component={LoadParlorsPage}/>
            <Route exact path="/call-center/load-zvonobot-file" component={LoadZvonobotFile}/>
        </Switch>
    }
}