import React from "react";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {withTranslation} from "react-i18next";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getGeneralProperties, getGeneralSettings, navLinks, saveGeneralSettings} from "../../services/settings";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {PROPERTIES_COMPONENTS} from "./GeneralSettingsPage";
import AnyField from "../../components/fields/AnyField";
import DataTable from "../../components/data-table/DataTable";

const columnData = [
    { id: "status", label: "Статус" },
    { id: "id", label: "Bitrix id" },
];

export default @connect(state => ({}), {
    showMessage: messageDialogActions.show,
})
@withTranslation()
class SettingBitrixPage extends React.Component {

    state = {
        tab: 0,
        values: {},
        properties: {
            bitrixTab: [],
        },
        refreshTable: false,
        tableData: [],
        status: null
    }

    componentDidMount() {
        getGeneralSettings()
            .then(response => {
                if (response.success) {
                    let statusesObj = JSON.parse(response.data.bitrixVisitStatuses ?? '{}');
                    this.setState({
                        ...this.state,
                        values: response.data,
                        tableData: Object.keys(statusesObj).map(s => ({status: s, id: statusesObj[s]}))
                    })
                }
            });

        getGeneralProperties()
            .then(response => {
                if (response.success) {
                    this.setState({
                        properties: {
                            bitrixTab: response.data.filter(prop => prop.id.startsWith('bitrix'))
                        }
                    })
                }
            });
    }

    handleChangeTabs = (event, value) => {
        this.setState({
            tab: parseInt(value)
        });
    }

    handleSave = () => {
        saveGeneralSettings(this.state.values)
            .then(response => {
                if (response.success) {
                    this.setState({
                        values: response.data,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    render() {
        const { tab, refreshTable, tableData, status } = this.state;
        const { t } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br/>
                    <ActionButton onClick={this.handleSave}>Сохранить</ActionButton>
                </LeftBar>
                <Content title={"BITRIX24"}>
                    <Tabs value={tab} onChange={this.handleChangeTabs}>
                        <Tab label={t("Common settings bx-link")} id={0} />
                        <Tab label="Статусы визитов" id={1} />
                    </Tabs>
                    <TabPanel index={0} value={tab}>
                        <Grid container spacing={2}>
                            {this.renderProperties('bitrixTab')}
                        </Grid>
                    </TabPanel>
                    <TabPanel index={1} value={tab}>
                        <Grid container spacing={2}>
                            {
                                tableData.length &&
                                <DataTable
                                    columnData={columnData}
                                    refresh={refreshTable}
                                    dataFunc={() => Promise.resolve({success: true, data: tableData})}
                                    selector={false}
                                    onClick={this.handleOpenTable}
                                />
                            }
                            {
                                status &&
                                <Dialog
                                    open={true}
                                    onClose={() => {}}
                                    aria-labelledby="dialog-title"
                                    aria-describedby="dialog-description"
                                >
                                    <DialogTitle id="dialog-title">{"Изменить статус"}</DialogTitle>
                                    <DialogContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <AnyField
                                                    label={"Статус"}
                                                    value={status.status}
                                                    name="status"
                                                    required
                                                    fullWidth
                                                    readOnly={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AnyField
                                                    label={"Bitrix24 id"}
                                                    value={status.id}
                                                    name="id"
                                                    onChange={this.handleChangeStatus}
                                                    required
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => {
                                            let { tableData, status } = this.state;

                                            tableData.forEach((s,i) => {
                                                if (s.status === status.status) {
                                                    tableData[i] = status
                                                }
                                            })

                                            const result = tableData.reduce((status, curr) => {
                                                status[curr.status] = curr.id;
                                                return status;
                                            }, {});

                                            saveGeneralSettings({
                                                ...this.state.values,
                                                bitrixVisitStatuses: JSON.stringify(result)
                                            })
                                                .then(response => {
                                                    if (response.success) {
                                                        this.setState({
                                                            values: response.data,
                                                            status: null
                                                        })
                                                    } else {
                                                        this.props.showMessage(response.error ? response.error.message : response.message);
                                                    }
                                                })

                                        }} color="primary">Сохранить</Button>
                                        <Button onClick={() => {
                                            this.setState({...this.state, status: null})
                                        }} color="primary">Отмена</Button>
                                    </DialogActions>
                                </Dialog>
                            }
                        </Grid>
                    </TabPanel>
                </Content>
            </React.Fragment>

        )
    }

    handleChange = event => {
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value,
            },
        });
    }

    handleChangeStatus = event => {
        this.setState({
            ...this.state,
            status: {
                ...this.state.status,
                [event.target.name]: parseInt(event.target.value)
            }
        })
    }

    handleOpenTable = status => { this.setState({...this.state, status})}

    renderProperties(tab) {
        const { properties, values } = this.state;

        const components = [];

        properties[tab].forEach(property => {
            let PropertyComponent = AnyField;
            let value = values[property.id]
            let handleChange = this.handleChange;

            if (PROPERTIES_COMPONENTS[property.id]) {
                PropertyComponent = PROPERTIES_COMPONENTS[property.id];
            }

            components.push(<Grid key={property.id} item xs={12}><PropertyComponent
                id={property.id}
                name={property.id}
                label={property.text}
                value={value}
                onChange={handleChange}
                readOnly={property.readOnly}
                helperText={property.readOnly ? 'Переопределено в .env' : null}
                fullWidth
            /></Grid>);
        });

        return components;
    }
}

class TabPanel extends React.Component {
    render() {
        const { children, value, index } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
            >
                {children}
            </div>
        );
    }
}