import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import DatePeriodField from "../../components/fields/DatePeriodField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getParlourRerportCard} from "../../services/calendarEmployee";
import XLSX from "xlsx";
import _ from "lodash";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";

export default
@withTranslation()
@connect(state => ({
}),{
   showMessage: messageDialogActions.show,
})
class ReportCardExportExcelDialog extends React.Component {

    state = {
        businessUnitIds: [],
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
        disableForm: false
    }

    static propTypes = {
        open: PropTypes.bool,
        businessUnits: PropTypes.array,
        onClose: PropTypes.func
    }
    static defaultProps = {
        open: false,
        onClose: f => f
    }

    columnDataXlsx = [
        {id: "employee_name", label: this.props.t("Post\\Employee")}
    ]

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            [prop]: value,
        });
    };

    handleExport = () => {

        const { t } = this.props
        const {startDate, endDate, businessUnitIds} = this.state

        const emptyRow = []
        const formattedFrom = moment(startDate).format("YYYY-MM-DD")
        const formattedTo = moment(endDate).format("YYYY-MM-DD")
        const columnDataXlsx = this.addDateRangeFieldsToColumnData(this.columnDataXlsx, formattedFrom, formattedTo)

        this.setState({disableForm: true})

        getParlourRerportCard(businessUnitIds, formattedFrom, formattedTo).then(
            response => {
                if (response.success) {

                    let new_workbook = XLSX.utils.book_new();

                    Object.keys(response.data).forEach( (buName, i) => {

                        let data = []

                        data.push([buName])
                        data.push([`${formattedFrom} - ${formattedTo}`])
                        data.push(emptyRow)

                        const first = columnDataXlsx[0];

                        columnDataXlsx.shift();
                        columnDataXlsx.unshift({label:'ФИО'});
                        columnDataXlsx.unshift({label:'Ник'});
                        columnDataXlsx.unshift(first);

                        data.push(columnDataXlsx.map(item => item.label))

                        columnDataXlsx.map(item => item.label)

                        Object.keys(response.data[buName]).forEach(employeePost => {

                            data.push(emptyRow)
                            data.push([t("EmployeePosts." + employeePost)])
                            data.push(emptyRow)

                            response.data[buName][employeePost].forEach((employeeActivities) => {

                                const employeeAndActivities = columnDataXlsx.map(item => {
                                    const value = _.get(employeeActivities, item.id);
                                    if (item.dateFormat) {
                                        return moment(value).format(item.dateFormat);
                                    }

                                    if (item.processValue) {
                                        return item.processValue(value, t);
                                    }

                                    return value
                                });

                                const first = employeeAndActivities[0];

                                employeeAndActivities.shift();
                                employeeAndActivities.unshift(employeeActivities.employee_full_name);
                                employeeAndActivities.unshift(employeeActivities.employee_name);
                                employeeAndActivities.unshift(first);

                                data.push(employeeAndActivities);
                            })
                        });

                        let worksheet = XLSX.utils.aoa_to_sheet(data);

                        XLSX.utils.book_append_sheet(new_workbook, worksheet, buName);
                    })
                    XLSX.writeFile(new_workbook, `${t("Report card")} ${formattedFrom} - ${formattedTo}.xlsx`);

                    this.props.onClose()
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }

                this.setState({disableForm: false})
            })
    };

    render() {
        const { startDate, endDate, businessUnitIds, disableForm } = this.state
        const { open, t, businessUnits, onClose } = this.props

        const disabled =
            !moment(startDate).isValid() ||
            !moment(endDate).isValid() ||
            !businessUnitIds.length ||
            endDate < startDate ||
            disableForm

        return <Dialog
                open={open}
                onClose={onClose}
                fullWidth
            >
                <DialogTitle>{t('Export to Excel')}</DialogTitle>
                <DialogContent>
                    <DatePeriodField
                        valueFrom={startDate}
                        valueTo={endDate}
                        onChangeFrom={this.handleChange('startDate')}
                        onChangeTo={this.handleChange('endDate')}
                        label={t("Unloading period")}
                        disabled={disableForm}
                    />
                    <MultiSelectFromItemsField
                        items={businessUnits}
                        label={t('Business unit')}
                        value={businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                        disabled={disableForm}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleExport} disabled={disabled}
                            color="primary">Excel</Button>
                    <Button onClick={onClose}
                            color="primary"
                            disabled={disableForm}
                    >{t('Close')}</Button>
                </DialogActions>
            </Dialog>
    }

    addDateRangeFieldsToColumnData = (columnData, startDate, endDate) =>
        columnData.concat(this.getDatesRange(startDate,endDate).map(
                (date) => {return {
                    id : moment(date).clone().format("YYYY-MM-DD"),
                    label: moment(date).clone().format("DD.MM"),
                    processValue: this.processEmployeeActivitites
                }}
            )
        )


    processEmployeeActivitites = (activities, t) => {

        if (typeof activities === 'object' && activities !== null) {
            let values = []

            if (activities.working) {
                values.push(activities.working)
            }

            if (activities.waiting) {
                values.push(t("ReportDailyActivities.waiting"))
            }

            if (activities.leave) {
                values.push(t("ReportDailyActivities.leave"))
            }

            if (activities.sick_leave) {
                values.push(t("ReportDailyActivities.sick_leave"))
            }

            return values.join(", ")
        } else {

            return activities
        }
    }

    getDatesRange = (startDate, endDate) => {
        var dates = [];

        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');

        while(currDate.diff(lastDate) <= 0) {
            dates.push(currDate.toISOString());
            currDate.add(1, 'days');
        }

        return dates;
    };
}