import React from 'react';
import AnyField from "./AnyField";
import * as PropTypes from 'prop-types';
import {IconButton, InputAdornment} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
        padding: 0,
    }
});

class SearchField extends React.PureComponent
{
    handleKeyPress = event => {
        if (event.key === 'Enter' && this.props.onClick) {
            this.props.onClick({});
        }
    };



    render(){
        const { label, value, onChange, onClick, classes, name } = this.props;

        return (
            <AnyField
                value={value}
                name={name}
                onChange={onChange}
                label={label}
                fullWidth
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Submit search"
                                onClick={onClick}
                                className={classes.button}
                            >
                                <Search />
                            </IconButton>
                        </InputAdornment>
                }}
                onKeyPress={this.handleKeyPress}
            />
        )
    }
}

SearchField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    name: PropTypes.string,
};

export default withStyles(styles)(SearchField);

