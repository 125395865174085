import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {navLinks} from "../../services/callCenter";
import moment from "moment";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import DatePeriodField from "../../components/fields/DatePeriodField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getBusinessUnits} from "../../services/organization";
import NumberRangeField from "../../components/fields/NumberRangeField";
import {loadParlours} from "../../services/organization";
import _ from "lodash";
import XLSX from "xlsx";

export default
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class LoadParlorsPage extends React.Component {
    state = {
        search: {
            dateFrom: moment(),
            dateTo: moment(),
            businessUnitIds: [],
            percentFrom: null,
            percentTo: null,
        },
        refreshTable: false,
        businessUnits: [],
    };

    columnData = [
        {id: 'date_field', label: 'Date', dateFormat: 'DD.MM.YYYY'},
        {id: 'parlour', label: 'Parlour'},
        {id: 'busy_hours', label: 'Used'},
        {id: 'free', label: 'Free load'},
        {id: 'unfree', label: 'Unfree load'},
        {id: 'max_hours', label: 'Maximum'},
        {id: 'percents', label: 'Per percents', processValue: value => value + '%'},
    ];

    columnDataXsl = [
        {id: 'date_field', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        {id: 'parlour', label: 'Parlour'},
        {id: 'busy_hours', label: 'Used'},
        {id: 'free', label: 'Free load'},
        {id: 'unfree', label: 'Unfree load'},
        {id: 'max_hours', label: 'Maximum'},
        {id: 'percents', label: 'Per percents'},
    ];

    getTableData = (...params) => {
        const {search} = this.state;
        return loadParlours(processMomentFieldInObject(search, ['dateFrom', 'dateTo']), ...params);
    };

    componentDidMount() {
        getBusinessUnits({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    handleExport = () => {
        const {search} = this.state;
        const rd = search.dateFrom.format('YYYY-MM-DD') + ' - ' + search.dateTo.format('YYYY-MM-DD');

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXsl.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXsl.map(item => {
                                const value = _.get(row, item.id);
                                if (item.dateFormat) {
                                    return moment(value).toDate();
                                }
                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Load Parlours");
                    XLSX.writeFile(new_workbook, `Load Parlours ${rd}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const {t} = this.props;
        const {refreshTable, search, businessUnits} = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField
                    valueFrom={search.dateFrom}
                    valueTo={search.dateTo}
                    onChangeFrom={this.handleChange('dateFrom')}
                    onChangeTo={this.handleChange('dateTo')}
                    label={t('Date')}
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.businessUnitIds}
                    onChange={this.handleChange('businessUnitIds')}
                    fullWidth
                />
                <NumberRangeField
                    label={t('Per percents')}
                    valueFrom={search.percentFrom}
                    valueTo={search.percentTo}
                    onChangeFrom={this.handleChange('percentFrom')}
                    onChangeTo={this.handleChange('percentTo')}
                />
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title='Загрузка салонов'>
                <DataTable
                    dataFunc={this.getTableData}
                    selector={false}
                    columnData={this.columnData}
                    refresh={refreshTable}
                />
            </Content>
        </React.Fragment>
    }
}
