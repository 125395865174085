import React, {memo, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import LeftBar from "../../../components/left-bar/LeftBar";
import Content from "../../../components/content/Content";
import NavigationLinks from "../../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../../services/moneyFlow";
import withProvider from "./ContextProvider";
import Filter from "./filter";
import RefreshButton from "./RefreshButton";
import Table from "./table";
import FinanceReportDetailsDialog from "./FinanceReportDetailsDialog";
import ExcelButton from "./ExcelButton";

const FinanceReport = () => {
    const { t } = useTranslation();

    const navigationLinks = useMemo(() => <NavigationLinks links={navLinks}/>, []);

    return (
        <>
            <LeftBar navigationLinks={navigationLinks}>
                <Filter/>
                <RefreshButton/>
                <ExcelButton/>
            </LeftBar>
            <Content title={t('Finance report')}>
                <Table/>
                <FinanceReportDetailsDialog/>
            </Content>
        </>
    );
};

export default memo(withProvider(FinanceReport));