import React from 'react';
import {
    getPayDocsPartner,
    navLinks
} from "../../services/payDocs";
import DataTable from "../../components/data-table/DataTable";
import _ from 'lodash';
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import SearchField from "../../components/fields/SearchField";
import NumberRangeField from "../../components/fields/NumberRangeField";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'num', numeric: false, disablePadding: true, label: 'Identifier' },
    { id: 'partner.username', numeric: false, disablePadding: false, label: 'Partner' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Guest phone' },
    { id: 'fio', numeric: false, disablePadding: false, label: 'Full name' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'nominalWithComment', disablePadding: false, label: 'Nominal' },
    { id: 'balance', numeric: true, disablePadding: false, label: 'Balance' },
    { id: 'validity', dateFormat: 'DD.MM.YYYY', label: 'Expiration date to' },
];

@withTranslation()
class PartnerPage extends React.Component {

    state = {
        search: '',
        searchFrom: '',
        searchTo: '',
        refreshTable: false,
        page: 0,
    };

    debounceRefresh = _.debounce(()=>{
        this.setState(state => ({refreshTable: !state.refreshTable, page: 0}));
    }, 500);

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });

        if (prop === 'searchFrom' || prop === 'searchTo') {
            this.debounceRefresh();
        }
    };

    handleClickSearch = () => {
        this.setState(state => ({refreshTable: !state.refreshTable, page: 0}));
    };

    getTableData = (...params) => {
        const {search, searchFrom, searchTo} = this.state;
        return getPayDocsPartner(search, searchFrom, searchTo, ...params);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    render() {
        const { t } = this.props;
        const { search, searchFrom, searchTo, refreshTable, page } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search || ''}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label={t("Search for payment documents")}
                    />
                    <NumberRangeField
                        label={t("Search by number range of payment documents")}
                        valueFrom={searchFrom}
                        valueTo={searchTo}
                        onChangeFrom={this.handleChange('searchFrom')}
                        onChangeTo={this.handleChange('searchTo')}
                        label2Lines
                    />
                    <br />
                    <br />
                </LeftBar>
                <Content title={t("Payment documents")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/pay-docs/view/${item.id}`}
                        onSelect={this.handleSelect}
                        refresh={refreshTable}
                        page={page}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(PartnerPage);
