import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    cancelGoodStorage, createGoodStorageFast,
    emptyStorageGood, getCategories, getGoodPriceByGood, getGoodPurposes, getGoods,
    getGoodStorages, getGoodStorageStatuses,
    goodStorageStatuses,
    navLinks, setGoodStorageConsumption, updateGoodStorage
} from "../../services/goods";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import infoActions from "../../components/info/info-actions";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import goodsActions from './goods-actions';
import {withRouter} from "react-router";
import MoneyField from "../../components/fields/MoneyField";
import NumberFormatCount from "../../components/fields/NumberFormatCount";
import {getBusinessUnitByRole, getUsers, hasRole} from "../../services/user";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import moment from "moment";
import DatePeriodField from "../../components/fields/DatePeriodField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import CheckboxField from "../../components/fields/CheckboxField";
import {Link} from "react-router-dom";
import _ from "lodash";
import XLSX from "xlsx";

const columnData = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товаров' },
    { id: 'purposeText', label: 'Предназначение' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'good.description', label: 'Описание товара' },
    { id: 'purchasePriceFormat', label: 'Цена закупки' },
    { id: 'priceFormat', label: 'Цена товара' },
    { id: 'num', label: 'Номер' },
    { id: 'expiration', label: 'Срок годности', dateFormat: "DD.MM.YYYY" },
    { id: 'statusText', label: 'Статус' },
    { id: 'currentMassFormatted', label: 'Количество' },
    { id: 'createdAt', label: 'Дата создания', dateFormat: "DD.MM.YYYY HH:mm" },
    { id: 'creator', label: 'Создатель' },
    { id: 'goodStorageSaleName', label: 'Продажа', linkTemplate: (item) => `/goods/sell/view/${item.goodStorageSaleId}`},
    { id: 'goodStorageOrderName', label: 'Заказ', linkTemplate: (item) => `/goods/orders/view/${item.goodStorageOrderId}`},
    { id: 'goodStorageRequests', label: 'Запросы', processValue: (requests) => requests && requests.map((item) => <div key={item.id}><Link to={`/goods/requests/view/${item.id}`}>{item.name}</Link></div> ) }
];

const columnDataXlsx = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товаров' },
    { id: 'purposeText', label: 'Предназначение' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'good.description', label: 'Описание товара' },
    { id: 'purchasePriceFormat', label: 'Цена закупки' },
    { id: 'priceFormat', label: 'Цена товара' },
    { id: 'num', label: 'Номер' },
    { id: 'expiration', label: 'Срок годности', dateFormat: "DD.MM.YYYY" },
    { id: 'statusText', label: 'Статус' },
    { id: 'currentMassFormatted', label: 'Количество' },
    { id: 'createdAt', label: 'Дата создания', dateFormat: "DD.MM.YYYY HH:mm" },
    { id: 'creator', label: 'Создатель' },
    { id: 'goodStorageSaleName', label: 'Продажа'},
    { id: 'goodStorageOrderName', label: 'Заказ'},
    { id: 'goodStorageRequests', label: 'Запросы' }
];

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginTop:  theme.spacing(3),
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    goodsList: {
        padding:  theme.spacing(1),
        marginTop:  theme.spacing(1),
        marginBottom:  theme.spacing(1),
    },
    filterControl: {

    }
});

export const emptyStorageGoodCreated = {
    category: null,
    good: null,
    price: null,
    purchasePrice: null,
    purpose: 'for_sale',
    quantity: 1,
    businessUnitId: null,
    comment: null,
    num: null,
    expressDelivery: false
};

const dateTypes = [
    { id: 'gs.createdAt', name: 'Дата создания товара'},
    { id: 'gs.expiration', name: 'Срок годности'},
    { id: 'gsr.createdAt', name: 'Дата создания запроса'},
    { id: 'gso.dateCreated', name: 'Дата создания заказа'},
    { id: 'gss.dateCreated', name: 'Дата создания продажи'},
];

@withRouter
class ListPage extends React.Component {

    state = {
        search: {
            search: null,
            statuses: [],
            dateStart: null,
            dateEnd: null,
            businessUnitIds: [],
            dateType: 'gs.createdAt',
            purpose: null,
            handCreated: false,
            creatorId: null
        },
        refreshTable: false,
        selected: [],

        dialogCancelOpen: false,
        cancelComment: '',

        dialogEditOpen: false,
        editingGoodStorage: emptyStorageGood,

        newGoodStorage: {...emptyStorageGoodCreated},
        dialogCreateOpen: false,
        categories: [],
        goods: [],
        purposes: [],
        statuses: [],
        parloursForSave: [],
        parloursForGet: [],
        users: []
    };

    currentGoodPrices = [];

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.user && nextProps.user !== this.props.user) {
            this.setState({
                refreshTable: !this.state.refreshTable,
            });
        }
    }

    componentDidMount() {
        getCategories()
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.data,
                    })
                }
            });

        getGoods()
            .then(response => {
                if (response.success) {
                    this.setState({
                        goods: response.data,
                    })
                }
            });

        getGoodPurposes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        purposes: response.data,
                    })
                }
            });

        getBusinessUnitByRole('ROLE_GOOD_GOODSTORAGE_SAVE')
            .then(response => {
                if (response.success) {
                    this.setState({
                        parloursForSave: response.data,
                    })
                }
            });


        getBusinessUnitByRole('ROLE_GOOD_GOODSTORAGE_GET')
            .then(response => {
                if (response.success) {
                    this.setState({
                        parloursForGet: response.data,
                    })
                }
            });

        getGoodStorageStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        statuses: response.data,
                    })
                }
            });

        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });

    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value
            }
        });
    };

    handleClickSearch = () => {
        this.setState({
            refreshTable: !this.state.refreshTable,
        })
    };

    handleExport = () => {
        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnDataXlsx.map(item => item.label ),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXlsx.map(item => {
                                if (item.id === 'goodStorageRequests' && row['goodStorageRequests']) {
                                    return row['goodStorageRequests'].map((item) => item.name ).join(",");
                                }
                                return _.get(row, item.id);
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Storage goods");
                    XLSX.writeFile(new_workbook, `Storage goods.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    getTableData = (...params) => {
        return getGoodStorages(this.state.search, ...params);
    };

    handleSelect = selected => {
        this.setState({
            selected,
        });
    };

    handleCloseCancelDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogCancelOpen: false,
            });
            return;
        }

        cancelGoodStorage({
            goodStorage: this.state.selected.filter(item => item.status === goodStorageStatuses.in_storage).map(item => item.id),
            comment: this.state.cancelComment,
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        refreshTable: !this.state.refreshTable,
                        selected: [],
                        dialogCancelOpen: false,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleCancel = () => {
        this.setState({
            dialogCancelOpen: true,
            cancelComment: null,
        })
    };

    handleMove = () => {
        this.props.setMovingGoods(this.state.selected.filter(item => item.status === goodStorageStatuses.in_storage));
        this.props.history.push('/goods/move/view/0');
    };

    handleCloseEditDialog = () => {
        this.setState({
            dialogEditOpen: false,
            refreshTable: !this.state.refreshTable,
        });
    };

    handleEdit = (goodStorage) => {
        this.setState({
            dialogEditOpen: true,
            editingGoodStorage: goodStorage,
        })
    };

    setDefaultGoodPrice = () => {
        const { businessUnitId } = this.state.newGoodStorage;
        if (businessUnitId) {
            let price = this.currentGoodPrices.find( (price) => price.businessUnitId === businessUnitId );

            this.setState({
                newGoodStorage: {
                    ...this.state.newGoodStorage,
                    price: price && price.salePrice ? { ...price.salePrice } : null
                }
            });

        }

    };

    handleChangeNewGoodStorage = (prop) => (event) => {

        if (prop === 'good' && this.state.newGoodStorage.good !== event.target.value) {
            getGoodPriceByGood(event.target.value)
                .then(response => {
                    if (response.success) {
                        this.currentGoodPrices = response.data;
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }


        this.setState({
            newGoodStorage: {
                ...this.state.newGoodStorage,
                [prop]: event.target.value
            }
        }, () => {
            if (prop === 'good' || prop === 'businessUnitId') {
                this.setDefaultGoodPrice();
            }
        });
    };

    handleCloseCreateDialog = (ok) => () => {
        if (!ok) {
            this.setState({
                dialogCreateOpen: false
            });
            return;
        }

        createGoodStorageFast(this.state.newGoodStorage)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogCreateOpen: false,
                        refreshTable: !this.state.refreshTable,
                        newGoodStorage: {...emptyStorageGoodCreated}
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });

    };

    handleUse = () => {
        const { editingGoodStorage } = this.state;

        updateGoodStorage({
            ...editingGoodStorage,
            good: editingGoodStorage.good.id,
            status: goodStorageStatuses.using,
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        editingGoodStorage: response.data,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleConsumption = () => {
        const { editingGoodStorage } = this.state;

        setGoodStorageConsumption(editingGoodStorage)
            .then(response => {
                if (response.success) {
                    this.setState({
                        editingGoodStorage: response.data,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes } = this.props;
        const { search, refreshTable, selected, parloursForGet, statuses, purposes, users } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        label="Поиск по товарам"
                        onClick={this.handleClickSearch}
                        fullWidth
                        className={classes.filterControl}
                    />
                    <DatePeriodField
                        valueFrom={search.dateStart}
                        valueTo={search.dateEnd}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('dateStart')}
                        onChangeTo={this.handleChange('dateEnd')}
                        label={'Показать по интервалу дат'}
                        className={classes.filterControl}
                    />
                    <SelectFromItemsField
                        label="Тип дат"
                        items={dateTypes}
                        value={search.dateType}
                        onChange={this.handleChange('dateType')}
                        className={classes.filterControl}
                        fullWidth
                    />
                    <MultiSelectFromItemsField
                        label="Салоны"
                        items={parloursForGet}
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        className={classes.filterControl}
                        fullWidth
                    />
                    <MultiSelectFromItemsField
                        label="Статусы"
                        value={search.statuses}
                        items={statuses}
                        textField="text"
                        onChange={this.handleChange('statuses')}
                        className={classes.filterControl}
                        fullWidth
                    />
                    <SelectFromItemsField
                        label="Предназначение"
                        items={purposes}
                        value={search.purpose}
                        textField="text"
                        onChange={this.handleChange('purpose')}
                        className={classes.filterControl}
                        fullWidth
                    />
                    <SelectFromItemsField
                        label="Создатель"
                        items={users}
                        value={search.creatorId}
                        textField="username"
                        onChange={this.handleChange('creatorId')}
                        className={classes.filterControl}
                        fullWidth
                    />
                    <CheckboxField
                        value={search.handCreated}
                        label="Только созданые вручную"
                        onChange={this.handleChange('handCreated')}
                        className={classes.filterControl}
                    />
                    <ActionButton onClick={this.handleClickSearch}>Отфильтровать</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                    <br />
                    <br />
                    <ActionButton onClick={() => this.setState({dialogCreateOpen: true})} visible={hasRole('ROLE_UI_TAB_GOOD_GOODSTORAGE_SAVE')}>Создать товар</ActionButton>
                    <ActionButton disabled={!selected.filter(item => item.status === goodStorageStatuses.in_storage).length} onClick={this.handleCancel}>Списать товар</ActionButton>
                    <ActionButton disabled={!selected.filter(item => item.status === goodStorageStatuses.in_storage).length} onClick={this.handleMove}>Передать в другой салон</ActionButton>
                </LeftBar>
                <Content title="Товары и склад">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector
                        refresh={refreshTable}
                        onSelect={this.handleSelect}
                        selected={selected}
                        onClick={this.handleEdit}
                    />
                </Content>
                {this.renderCancelDialog()}
                {this.renderEditDialog()}
                {this.renderCreateDialog()}
            </React.Fragment>
        );
    }

    renderCancelDialog() {
        const { classes } = this.props;
        const { dialogCancelOpen, cancelComment, selected } = this.state;

        return (
            <Dialog
                open={dialogCancelOpen}
                onClose={this.handleCloseCancelDialog(false)}
            >
                <DialogTitle>Подтверждение списания товаров</DialogTitle>
                <DialogContent>
                    Вы уверены, что хотете списать выбранные товары?
                    <Paper className={classes.goodsList}>{selected.filter(item => item.status === goodStorageStatuses.in_storage).map(item => item.good.name).join(', ')}</Paper>
                    <AnyField
                        id="cancelComment"
                        label="Укажите комментарий"
                        value={cancelComment}
                        onChange={this.handleChange('cancelComment')}
                        multiline
                        rows={4}
                        fullWidth
                        className={classes.textField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseCancelDialog(true)}  color="primary">Да</Button>
                    <Button onClick={this.handleCloseCancelDialog(false)} color="primary">Нет</Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderEditDialog() {
        //const { classes } = this.props;
        const { dialogEditOpen, editingGoodStorage } = this.state;

        return (
            <Dialog
                open={dialogEditOpen}
                onClose={this.handleCloseEditDialog}
            >
                <DialogTitle>Товар</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Группа товаров"
                                value={editingGoodStorage.good ? editingGoodStorage.good.category.name : null}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Наименование товара"
                                value={editingGoodStorage.good ? editingGoodStorage.good.name : null}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Описание товара"
                                value={editingGoodStorage.good ? editingGoodStorage.good.description : null}
                                readOnly
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Количество товара"
                                value={editingGoodStorage.good ? editingGoodStorage.good.massFormat : null}
                                readOnly
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Текущее количество"
                                value={editingGoodStorage.currentMass}
                                readOnly
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Закупочная цена"
                                value={editingGoodStorage.purchasePriceFormat}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Цена продажи/использования"
                                value={editingGoodStorage.priceFormat}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Номер"
                                value={editingGoodStorage.num}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Статус"
                                value={editingGoodStorage.statusText}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Салон"
                                value={editingGoodStorage.parlour ? editingGoodStorage.parlour.name : null}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseEditDialog}  color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        );
    }


    renderCreateDialog() {
        const { classes } = this.props;
        const { dialogCreateOpen, categories, goods, purposes, parloursForSave, newGoodStorage } = this.state;
        let currencies = [];
        if (newGoodStorage.businessUnitId && parloursForSave.length) {
            currencies = [parloursForSave.find((parlour) => parlour.id === newGoodStorage.businessUnitId).mainCurrency];
        }

        const filteredGoods =
            newGoodStorage.category ?
                goods.filter(good => good.category.id === newGoodStorage.category)
                :
                goods;

        return (
            <Dialog
                open={dialogCreateOpen}
                onClose={this.handleCloseCreateDialog(false)}
            >
                <DialogTitle>Добавление товара</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Группа товаров"
                                value={newGoodStorage.category}
                                onChange={this.handleChangeNewGoodStorage('category')}
                                select
                                fullWidth
                            >
                                {categories.map(category => <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteSelectField
                                label="Товар"
                                value={newGoodStorage.good}
                                required
                                error={!newGoodStorage.good}
                                onChange={this.handleChangeNewGoodStorage('good')}
                                fullWidth
                                options={filteredGoods.map(item => ({value: item.id, label: `${item.name}`}))}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Салон"
                                value={newGoodStorage.businessUnitId}
                                required
                                error={!newGoodStorage.businessUnitId}
                                onChange={this.handleChangeNewGoodStorage('businessUnitId')}
                                select
                                fullWidth
                            > {parloursForSave.map(parlour => <MenuItem key={parlour.id} value={parlour.id}>{parlour.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            <MoneyField
                                label="Цена"
                                currencies={currencies}
                                value={ newGoodStorage.price }
                                onChange={this.handleChangeNewGoodStorage('price')}
                                fullWidth
                                required
                                error={!newGoodStorage.price}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MoneyField
                                label="Цена закупки"
                                currencies={currencies}
                                value={ newGoodStorage.purchasePrice }
                                onChange={this.handleChangeNewGoodStorage('purchasePrice')}
                                fullWidth
                                required
                                error={!newGoodStorage.purchasePrice}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Количество"
                                value={newGoodStorage.quantity}
                                fullWidth
                                error={!newGoodStorage.quantity}
                                onChange={this.handleChangeNewGoodStorage('quantity')}
                                InputProps={{
                                    inputComponent: NumberFormatCount
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Предназначение"
                                value={newGoodStorage.purpose}
                                required
                                fullWidth
                                readOnly
                                onChange={this.handleChangeNewGoodStorage('purpose')}
                                select
                            >
                                {purposes.map(purpose => <MenuItem key={purpose.id} value={purpose.id}>{purpose.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={newGoodStorage.expressDelivery}
                                onChange={this.handleChangeNewGoodStorage('expressDelivery')}
                                label="Экспресс доставка"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Комментарий"
                                value={newGoodStorage.comment}
                                multiline
                                fullWidth
                                rows={4}
                                onChange={this.handleChangeNewGoodStorage('comment')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseCreateDialog(true)} disabled={ !newGoodStorage.good || !newGoodStorage.price || !newGoodStorage.quantity || !newGoodStorage.businessUnitId }  color="primary">OK</Button>
                    <Button onClick={this.handleCloseCreateDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}


const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
    setMovingGoods: goodsActions.setMovingGoods,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListPage));
