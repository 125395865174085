import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getPaymentLinksReport,
    navLinks
} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import XLSX from "xlsx";
import {formatMoney, getSalableEntityTypes, getPaymentStatuses} from "../../services/common";
import {Link} from "react-router-dom";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import CheckboxField from "../../components/fields/CheckboxField";
import {getUsers} from "../../services/user";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

export default
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class PaymentLinksReport extends React.Component {
    state = {
        search: {
            search: null,
            date_start: null,
            date_end: null,
            sortByVisitDate: false,
            saleBusinessUnitIds: [],
            employeeBusinessUnitIds: [],
            approveStatus: null,
            creatorIds: [],
            paymentStatusIds: []
        },
        salableEntityTypes: [],
        refreshTable: false,
        businessUnits: [],
        selected: [],
        users: [],
        paymentStatuses: []
    };

    processPaymentLinkUrl = link => {
        return <span><a href={link}>{this.props.t("Payment")}</a></span>;
    }

    processSalableEntitiesIds = salableEntitiesIds => {

        let {salableEntitiesLinkTemplates} = this
        if (typeof salableEntitiesIds === 'object' && salableEntitiesIds !== null) {
            let elements = []
                Object.keys(salableEntitiesIds).forEach((type) => {
                    if (salableEntitiesIds[type] != null
                        && salableEntitiesLinkTemplates[type]) {
                        if (elements.length > 0) {
                            elements.push(<br></br>);
                            elements.push(<br></br>);
                        }
                        elements.push(
                            <span>
                                <Link to={
                                    this.salableEntitiesLinkTemplates[type]
                                    + salableEntitiesIds[type]
                                }>
                                    {this.props.t(this.salableEntitiesLabels[type])}
                                </Link>
                            </span>
                        );
                    }
                })

            return elements
        }

        return salableEntitiesIds;
    };

    processSalableEntitiesIdsXlsx = salableEntitiesIds => {

        let {salableEntitiesLinkTemplates} = this
        if (typeof salableEntitiesIds === 'object' && salableEntitiesIds !== null) {
            let links = []
            Object.keys(salableEntitiesIds).forEach((type) => {
                    if (salableEntitiesIds[type] != null
                        && salableEntitiesLinkTemplates[type] ) {
                            links.push(
                                salableEntitiesLinkTemplates[type]
                                + salableEntitiesIds[type]
                            )
                    }
                }
            )

            return links.join()
        }

        return salableEntitiesIds;
    };

    salableEntitiesLinkTemplates = {
        visit: "/orders/view/",
        payDoc: "/pay-docs/view/",
        payDocRefill: "/pay-docs/refill/",
        goodStorageSale: "/goods/sell/view/",
    }

    salableEntitiesLabels = {
        visit: "Visit",
        payDoc: "PayDoc",
        payDocRefill: "PayDocRefill",
        goodStorageSale: "GoodStorageSale",
    }

    excludedSalableEntityTypes = ["Gratuity"]

    columnData = [
        { id: 'createdAt', label: 'paymentLink.createdAt', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'paidAt', label: 'pay.date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'payAmount', label: 'pay.amountPay', processValue: formatMoney},
        { id: 'payStatus', label: 'pay.status', processValue: (payStatus) => {return this.props.t("pay.statuses." + payStatus)}},
        { id: 'url', label: 'pay.url', processValue: this.processPaymentLinkUrl},
        { id: 'salableEntitiesIds',
            label: 'paymentLink.linkToVisitOrPayDocOrPayDocRefillOrGoodSaleStorage',
            processValue: this.processSalableEntitiesIds,
            multiline: true
        },
        { id: 'visitDate', label: 'Visited', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'guestFullnameAndPhone', label: 'Guest Fullname and Phone'},
        { id: 'saleBusinessUnitName', label: "Sale BU"},
        { id: 'employeeBusinessUnitName', label: "Employee BU"},
        { id: 'creatorUsername', label: 'paymentLink.creator'},
    ];

    columnDataXlsx = [
        { id: 'createdAt', label: 'paymentLink.createdAt', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'paidAt', label: 'pay.date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'payAmount', label: 'pay.amountPay', processValue: formatMoney},
        { id: 'payStatus', label: 'pay.status', processValue: (payStatus) => {return this.props.t("pay.statuses." + payStatus)}},
        { id: 'url', label: 'pay.url'},
        { id: 'salableEntitiesIds',
            label: 'paymentLink.linkToVisitOrPayDocOrPayDocRefillOrGoodSaleStorage',
            processValue: this.processSalableEntitiesIdsXlsx,
            multiline: true
        },
        { id: 'visitDate', label: 'Visited', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'guestFullnameAndPhone', label: 'Guest Fullname and Phone'},
        { id: 'saleBusinessUnitName', label: "Sale BU"},
        { id: 'employeeBusinessUnitName', label: "Employee BU"},
        { id: 'creatorUsername', label: 'paymentLink.creator'},
    ];

    componentDidMount() {
        const {t} = this.props;
        const {excludedSalableEntityTypes} = this;
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            ...response.data
                        ],
                    });
                }
            });
        getSalableEntityTypes()
            .then(response => {
                if (response.success) {
                    let salableEntityTypes = response.data
                    salableEntityTypes.forEach(function (type, index){
                        type.text = t(type.id)
                        if(excludedSalableEntityTypes.includes(type.id)){
                            salableEntityTypes.splice(index, 1)
                        }
                    })
                    this.setState({
                        salableEntityTypes: salableEntityTypes
                    });
                }
            });

        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });

        getPaymentStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        paymentStatuses: response.data,
                    })
                }
            });
    }

    handleChange = prop => event => {

        let value = null;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getPaymentLinksReport(search, ...params);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleExport = () => {
        const { search, businessUnits } = this.state;
        const from = search.date_start ? search.date_start : "";
        const to = search.date_end ? search.date_start : "";
        const buTitle = search.saleBusinessUnitIds.length ?
            businessUnits.filter((bu) => search.saleBusinessUnitIds.includes(bu.id)).map(bu => bu.name).join(", "):
            "All"

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => this.props.t(item.label)),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => {
                                let value = _.get(row, item.id);

                                if (item.dateFormat && value) {
                                    return moment(value).format(item.dateFormat);
                                }

                                if (item.processValue) {
                                    value = item.processValue(value, null, row);
                                }

                                return value;
                            })

                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Deals");
                    XLSX.writeFile(new_workbook, this.props.t("Payment links report") + ` ${from} - ${to} - ${buTitle}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    transactionStyle = item => {
        if (item.payStatus === 'paid') {
            return {backgroundColor: '#e1ffde'}
        }
        if (item.payStatus === 'processing') {
            return {backgroundColor: '#ffe1c7'}
        }
        if (item.payStatus === 'frozen') {
            return {backgroundColor: '#cdcfff'}
        }
        if (item.payStatus === 'burned') {
            return {backgroundColor: '#ffd7e1'}
        }
    };

    render() {
        const { classes, t } = this.props;
        const {
            refreshTable,
            search,
            businessUnits,
            users ,
            paymentStatuses
        } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t("Show links by date interval")}
                        className={classes.filterControl}
                        required={false}
                        maxDate={moment()}
                    />

                    <CheckboxField
                        label={t("By visit date")}
                        value={search.sortByVisitDate}
                        name="byVisitDate"
                        onChange={this.handleChange("sortByVisitDate")}
                        margin="none"
                    />

                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        label={t("Sale BU")}
                        value={search.saleBusinessUnitIds}
                        onChange={this.handleChange('saleBusinessUnitIds')}
                        fullWidth
                        className={classes.filterControl}
                        valueField={"id"}
                        textField={"name"}
                        nullable
                    />

                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        label={t("Employee BU")}
                        value={search.employeeBusinessUnitIds}
                        onChange={this.handleChange('employeeBusinessUnitIds')}
                        fullWidth
                        className={classes.filterControl}
                        valueField={"id"}
                        textField={"name"}
                        nullable
                    />

                    <AutocompleteMultiSelectField
                        label={t("paymentLink.creator")}
                        options={users}
                        value={search.creatorIds}
                        textField="username"
                        onChange={this.handleChange('creatorIds')}
                        className={classes.filterControl}
                        fullWidth
                        nullable
                    />

                    <MultiSelectFromItemsField
                        label={t("pay.status")}
                        items={paymentStatuses}
                        textField="text"
                        value={search.paymentStatusIds}
                        onChange={this.handleChange('paymentStatusIds')}
                        className={classes.filterControl}
                        fullWidth
                        nullable
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t("Payment links report")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        rowStyleFunc={this.transactionStyle}
                        autoload={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}