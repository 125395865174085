import {createActions} from 'redux-actions';
import {getUsers} from "../../services/user";


const {user} = createActions({
    USER: {
        FETCH_USER_BEGIN: undefined,
        FETCH_USER_SUCCESS: undefined,
        FETCH_USER_FAILURE: undefined,
    }
});

user.fetchUsers = () => dispatch => {
    dispatch(user.fetchUserBegin());

    getUsers()
        .then(response => {
            if (response.success) {
                return dispatch(user.fetchUserSuccess(response.data));
            }

            return dispatch(user.fetchUserFailure(response.error ? response.error.message : response.message));
        })
};

export default user;