import React from 'react';
import {getFacilities, navLinks} from "../../services/facilities";
import DataTable from "../../components/data-table/DataTable";
import {withRouter} from "react-router";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";

const columnData = [
    { id: 'code', label: 'Код услуги' },
    { id: 'typeText', label: 'Вид услуги' },
    { id: 'name', label: 'Наименование услуги' },
    { id: 'color', label: 'Цвет', color: true },
    { id: 'slim', label: 'Слим' },
    { id: 'durationsText', label: 'Длительности'},
    { id: 'variantsText', label: 'Варианты'},

];

export default
@withRouter
class ListPage extends React.Component {

    state = {
        search: '',
        refreshTable: false
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/facilities/edit/0`);
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getFacilities(search, ...params);
    };

    linkTemplate = item => {
        return `/facilities/view/${item.id}`;
    }

    render() {
        const { search, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search}
                        onChange={this.handleChange('search')}
                        label="Поиск услуги"
                        onClick={this.handleClickSearch}
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Услуги">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={this.linkTemplate}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
