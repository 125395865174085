import React from 'react';
import { Grid, withStyles} from "@material-ui/core";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    emptyOrder,
    getOrder,
    navLinks, processOrderAfterGet
} from "../../services/goods";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import { getPayTypeText} from "../../services/payDocs";
import OrderLeftFields from "./OrderLeftFields";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {withRouter} from "react-router";
import NumberFormatCurrency from "../../components/fields/NumberFormatCurrency";
import SymbolRub from "../../components/typography/SymbolRub";


const styles = theme => ({
    container: {
        width: 700,
        marginTop:  theme.spacing(1),
    },
    subtitle: {
        marginTop:  theme.spacing(2),
        marginBottom:  theme.spacing(1),
    },
});

@withRouter
class OrderAdditionalPage extends React.Component
{
    state = {
        order: emptyOrder,
    };

    componentDidMount() {
        const reId = new RegExp('/goods/orders/additional/([^/]+)$');
        const resultId = reId.exec(this.props.location.pathname);
        if (resultId.length !== 2) {
            //Error request
            return;
        }

        if (resultId[1] !== "0") {
            getOrder(resultId[1])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            order: processOrderAfterGet(response.data),
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/orders/view/${this.state.order.id}`);
    };

    render() {
        const { classes } = this.props;
        const { order } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <OrderLeftFields order={this.state.order}/>
                    <br/><br/>
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>

                <Content title="Дополнительная информация по заказу">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={6}>
                            <div className={classes.subtitle}>Информация для контрагента</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.subtitle}>Условия поставки</div>
                            <UploadFilesField
                                id="orderBillFile"
                                label="Счет"
                                value={order.bill}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.subtitle}>Условия оплаты</div>
                            <AnyField
                                id="payType"
                                label="Тип оплаты"
                                value={order.payTypes ? order.payTypes.map(type=>getPayTypeText(type)).join(', ') : ''}
                                fullWidth
                                readOnly
                            />
                            <AnyField
                                id="amountPrePay"
                                label="Сумма предоплаты"
                                value={order.amountPrePayFormat}
                                fullWidth
                                readOnly
                            />
                            <AnyField
                                id="amountDelayPay"
                                label="Сумма отсроченного платежа"
                                value={order.amountDelayPayFormat}
                                fullWidth
                                readOnly
                            />
                            <AnyField
                                id="amountTotal"
                                label="Общая сумма"
                                value={order.amountTotalFormat}
                                fullWidth
                                readOnly
                            />
                            <AnyField
                                id="amountPrePay"
                                label="Условия отсрочки"
                                value={order.delayPeriod}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.subtitle}>Условия доставки в салон</div>
                            <AnyField
                                value={order.delivery.typeText}
                                readOnly
                                label="Способ доставки"
                                fullWidth
                            />
                            <AnyField
                                id="charges"
                                label="Стоимость"
                                value={order.delivery.charges.val}
                                readOnly
                                InputProps={{
                                    inputComponent: NumberFormatCurrency,
                                    endAdornment: <SymbolRub/>,
                                }}
                                fullWidth
                            />
                            {order.delivery.type === 'transport_company' &&
                                <React.Fragment>
                                    <AnyField
                                        readOnly
                                        value={order.delivery.paymentType}
                                        label="Тип оплаты"
                                        fullWidth
                                    />
                                    <AnyField
                                        label="Транспортная компания/курьер"
                                        value={order.delivery.provider.name}
                                        readOnly
                                        fullWidth
                                    />
                                    <AnyField
                                        label="Номер накладной"
                                        value={order.delivery.waybillNumber}
                                        readOnly
                                        fullWidth
                                    />
                                    <UploadFilesField
                                        id="receiptScan"
                                        value={order.delivery.receiptScan}
                                        readOnly
                                        fullWidth
                                    />
                                </React.Fragment>
                            }
                            {order.delivery.type === 'courier' &&
                                <AnyField
                                    label="Курьер"
                                    value={order.delivery.courier.name}
                                    readOnly
                                    fullWidth
                                />
                            }
                            <AnyField
                                label="Комментарий"
                                value={order.delivery.comment}
                                readOnly
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(OrderAdditionalPage);
