import React from 'react';
import {withTranslation} from "react-i18next";
import AnyField from "./AnyField";
import NumberFormatDecimal3 from "./NumberFormatDecimal3";
import {InputAdornment} from "@material-ui/core";

export default
@withTranslation()
class DurationField extends React.Component
{
    render() {
        const { t, tReady, InputProps, ...otherProps } = this.props;

        return <AnyField
            InputProps={{
                ...InputProps,
                inputComponent: NumberFormatDecimal3,
                endAdornment: <InputAdornment position="end">{t('h')}</InputAdornment>
            }}
            {...otherProps}
        />;
    }
}