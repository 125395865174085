import React from 'react';
import * as PropTypes from "prop-types";
import {equalDates, processMomentFieldInObject, propTypeDuration, propTypeMoment} from "../../utils/moment-utils";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import dimensions from './ScheduleVisitsWeekDimensions';
import {
    getParlourTimes, getVisitCountGuestsPeriod,
    getVisits,
    getVisitStatusColor,
    updateVisit,
    visitStatuses,
    waitingMasterColor
} from "../../services/calendar";
import {colorLoad, getAdminsWorking, getEquipments, getMastersWorking, loadParlours} from "../../services/organization";
import _ from 'lodash';
import {activityTypes} from "../../services/calendarEmployee";
import ScheduleVisitsWeekHeaderSlots from "./ScheduleVisitsWeekHeaderSlots";
import ScheduleVisitsWeekElement from "./ScheduleVisitsWeekElement";
import ScheduleVisitsWeekSlot from "./ScheduleVisitsWeekSlot";
import {IconButton, Snackbar} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import socketActions from "../../socket/socket-actions";
import infoActions from "../../components/info/info-actions";
import {paymentStatus} from "../../services/sale";
import {hasRole} from "../../services/user";

export default
@withTranslation()
@connect(state => ({
    socketMessages: state.socket.messages,
    hiddenStatuses: state.calendar.hiddenStatuses,
}),{
    removeMessages: socketActions.removeMessages,
    showError: infoActions.showError,
    showInfo: infoActions.show,
})
@withStyles(theme => ({
    container: {
        display: 'flex',
        flex: '1 0 0%',
        position: 'relative',
        border: '1px solid #777',
    },
    timeGutter: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #777',
    },
    timeLabel: {
        paddingLeft:  theme.spacing(1),
        paddingRight:  theme.spacing(2),
    },
    timeSlotGroup: {
        borderTop: '1px solid #777',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    rightContainer: {
        width: '100%',
        overflowX: 'auto',

        '@media print': {
            overflow: 'hidden',
        },
    },
    columnsHeaderContainer: {
        flex: '1 0 0%',
        display: 'flex',
        position: 'relative',
        height: dimensions.headerHeight,
    },
    columnHeader: {
        padding:  '2px 3px',
        textAlign: 'center',
        borderRight: '1px solid #777',
        position: 'relative',
        overflow: 'hidden',
    },
    subColumnHeaders: {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    subColumnHeader: {
        fontSize: '8px',
        overflow: 'hidden',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        width: dimensions.subColumnWidth,
        minWidth: dimensions.subColumnWidth,
    },
    columnsContainer: {
        flex: '1 0 0%',
        display: 'flex',
        position: 'relative',
    },
    subColumns: {
        display: 'flex',
        borderRight: '1px solid #888',
    },
    subColumn: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px dotted #888',
        position: 'relative',
        width: dimensions.subColumnWidth,
        minWidth: dimensions.subColumnWidth,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #888',
        position: 'relative',
    },
    columnSlot: {
        borderBottom: '1px solid #ccc',
        '&:last-of-type': {
            borderBottom: 'none',
        },
        '&:hover': {
            background: '#ddd',
        }
    },
    timeZone: {
        fontSize: theme.typography.body2.fontSize,
        textAlign: 'center',
        height: dimensions.headerHeight,
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        }
    },
    showPrint: {
        display: 'none',

        '@media print': {
            display: 'block',
        }
    },
}))
class ScheduleVisitsWeek extends React.PureComponent
{
    static propTypes = {
        dateFrom: propTypeMoment,
        dateTo: propTypeMoment,

        onSlotClick: PropTypes.func,
        onSlotDbClick: PropTypes.func,
        onSlotContextMenu: PropTypes.func,

        selectedVisit: PropTypes.object,

        onVisitClick: PropTypes.func,
        onVisitDbClick: PropTypes.func,
        onVisitContextMenu: PropTypes.func,

        onHeaderClick: PropTypes.func,

        printInfo: PropTypes.any,
        showCurrentTime: PropTypes.bool,
        timeStep: propTypeDuration,

        parlour: PropTypes.object,
    };

    static defaultProps = {
        showCurrentTime: false,
        timeStep: moment.duration(15,'minutes'),
    };

    constructor(props) {
        super(props);

        const visitMessages = props.socketMessages.filter(message => message.entity === 'visit');
        if (visitMessages.length) {
            props.removeMessages(visitMessages.map(message => message.id));
        }

        this.state = {
            ...this.calcTimeSlots(),
            parlourLoads: [],
            subColumns: [],
            visits: [],
            elements: [],
            draggingIdx: -1,
            draggingVisit: null,
            isDragging: false,
            ctrlPressed: false,
            guestsVisitCount: {}
        };
    }

    componentDidMount() {
        this.getParlourLoads();
        this.getMasters();
        this.getVisits();

        document.addEventListener('keydown', this.handleKeyboardEvent);
        document.addEventListener('keyup', this.handleKeyboardEvent);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyboardEvent);
        document.removeEventListener('keyup', this.handleKeyboardEvent);
    }

    handleKeyboardEvent = event => {
        if (event.key === 'Control') {
            this.setState({
                ctrlPressed: event.type === 'keydown',
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.parlour, this.props.parlour)  ||
            !equalDates(prevProps.dateFrom, this.props.dateFrom) ||
            !equalDates(prevProps.dateTo, this.props.dateTo)) {
            setTimeout(() => {
                this.setState({
                    ...this.calcTimeSlots(),
                    parlourLoads: [],
                    // subColumns: [],
                    elements: [],
                    visits: [],
                    draggingIdx: -1,
                    draggingVisit: null,
                    isDragging: false,
                });
            }, 0);
            this.getParlourLoads();
            this.getMasters();
            this.getVisits();
        }

        if (!_.isEqual(prevProps.hiddenStatuses, this.props.hiddenStatuses)) {
            this.setState({
                ...this.calcVisits(this.state.visits),
            });
        }

        if (prevProps.socketMessages !== this.props.socketMessages) {
            let processedIds = [];
            let visits = [];
            let removeVisits = [];

            this.props.socketMessages.forEach(message => {
                if (message.entity && message.entity === 'visit') {
                    processedIds.push(message.id);
                    if (message.action === 'remove') {
                        removeVisits.push(message.entityId);
                    } else if (message.entityData) {
                        visits.push(message.entityData);
                    }
                }
            });

            visits = visits.filter(visit => visit.parlour && this.props.parlour && visit.parlour.id === this.props.parlour.id);
            visits.forEach(visit => this.processVisit(visit));

            visits = visits.filter(visit => visit.start.isBetween(this.props.dateFrom, this.props.dateTo));

            let draggingIdx = this.state.draggingIdx;
            let draggingVisit = this.state.draggingVisit;
            let draggingVisitId = draggingIdx >= 0 ? this.state.visits[draggingIdx].id : null;

            if (draggingVisitId) {
                if (visits.findIndex(newVisit => newVisit.id === draggingVisitId) >= 0 || removeVisits.indexOf(draggingVisitId) >= 0) {
                    draggingIdx = -1;
                    draggingVisitId = null;
                    draggingVisit = null;
                }
            }

            if (visits.length || removeVisits.length) {
                const oldFilteredVisits = this.state.visits.filter(oldVisit => visits.findIndex(newVisit => newVisit.id === oldVisit.id) === -1 && removeVisits.indexOf(oldVisit.id) === -1);

                if (draggingVisitId) {
                    draggingIdx = oldFilteredVisits.findIndex(oldVisit => oldVisit.id === draggingVisitId);
                }

                this.setState({
                    ...this.calcVisits([
                        ...oldFilteredVisits,
                        ...visits,
                    ]),
                    draggingIdx,
                    draggingVisit,
                });
            }

            if (processedIds.length) {
                this.props.removeMessages(processedIds);
            }
        }
    }

    calcTimeSlots() {
        const { dateFrom, dateTo, timeStep, parlour } = this.props;
        const timeZone = parlour ? parlour.timeZone : "+04:00";
        const { startTime, endTime } = parlour ?
            getParlourTimes(parlour, dateFrom) :
            {
                startTime: moment.duration("00:00"),
                endTime: moment.duration("23:59"),
            };

        let current = startTime.clone();
        const headerTimeSlots = [];
        const currentDayStart = moment().utcOffset(timeZone).startOf('day');
        let currentGroupBegin = current.clone();
        let currentGroupEnd = current.clone().add(timeStep);
        while (current < endTime) {
            const value = current.clone();
            if (value.minutes() === 0) {
                currentGroupBegin = current.clone();
                currentGroupEnd = current.clone();
                for (let i = 0; i < dimensions.slotsInGroup; i++) {
                    currentGroupEnd.add(timeStep);
                }
            }

            current.add(timeStep);
            headerTimeSlots.push({
                beginGroup: value.minutes() === 0,
                endGroup: current.minutes() === 0,
                value: value,
                valueEnd: current.clone(),
                fullValue: currentDayStart.clone().add(value),
                fullValueEnd: currentDayStart.clone().add(current),
                beginGroupValue: currentGroupBegin.clone(),
                endGroupValue: currentGroupEnd.clone(),
                beginGroupFullValue: currentDayStart.clone().add(currentGroupBegin),
                endGroupFullValue: currentDayStart.clone().add(currentGroupEnd),
                key: currentDayStart.clone().add(value).toISOString(),
            });

        }

        const columns = Array.from(moment.range(dateFrom, dateTo).by('days')).map(value => ({
            value,
            title: value.format("DD.MM.YYYY (dd)"),
            key: value.toISOString(),
        }));
        const columnTimeSlots = [];
        const colorLines = parlour && parlour.scheduleLines && parlour.scheduleLines.length ?
            parlour.scheduleLines.map(line => {
                const lineTime = moment(line.time).utcOffset(timeZone).startOf('minute');
                return {
                    time: moment.duration(lineTime.clone().diff(lineTime.clone().startOf('day'))),
                    color: line.color,
                };
            }) : [];
        columns.forEach((column, idx) => {
            const dayStart = column.value.clone().utcOffset(timeZone).startOf('day');
            columnTimeSlots[idx] = [];

            headerTimeSlots.forEach(slot => {
                const line = colorLines.find(line => line.time.asMinutes() === slot.value.asMinutes());
                columnTimeSlots[idx].push({
                    ...slot,
                    fullValue: dayStart.clone().add(slot.value),
                    fullValueEnd: dayStart.clone().add(slot.valueEnd),
                    beginGroupFullValue: dayStart.clone().add(slot.beginGroupValue),
                    endGroupFullValue: dayStart.clone().add(slot.endGroupValue),
                    style: line ? { background: line.color } : null,
                    key: dayStart.clone().add(slot.value).toISOString(),
                });
            });
        });

        return ({
            headerTimeSlots,
            columns,
            columnTimeSlots,
            timeZone,
        });
    }

    calcParlourLoads = loadsData => {
        const { columns, timeZone } = this.state;
        const parlourLoads = [];
        columns.forEach((column, columnIdx) => {
            const load = loadsData.find(item => column.value.isSame(moment(item.date_field).utcOffset(timeZone, true), 'day'));
            if (load && load.percents) {
                parlourLoads[columnIdx] = load.percents;
            }
        });

        return parlourLoads;
    }

    getParlourLoads() {
        if (this.props.parlour && this.props.parlour.id && hasRole('ROLE_CALLCENTER_TIMESEARCH')) {
            loadParlours(processMomentFieldInObject({
                dateFrom: this.props.dateFrom,
                dateTo: this.props.dateTo,
                businessUnitIds: [this.props.parlour.id]
            }, ['dateFrom', 'dateTo']), 1, -1, 'asc', 'date_field')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            parlourLoads: this.calcParlourLoads(response.data),
                        });
                    }
                });
        }
    }

    calcSubColumns = (masters, equipments, admins) => {
        const { parlour } = this.props;
        const { columns, timeZone } = this.state;
        const subColumns = [];

        //Преобразуем время в moment
        masters.forEach(master => {
            if (master.workShift) {
                master.workShift.forEach(ws => {
                    ws.timeStart = moment(ws.timeStart).utcOffset(timeZone).startOf('minute');
                    ws.timeEnd = moment(ws.timeEnd).utcOffset(timeZone).startOf('minute');
                });
            }
        });

        admins.forEach(admin => {
            if (admin.workShift) {
                admin.workShift.forEach(ws => {
                    ws.timeStart = moment(ws.timeStart).utcOffset(timeZone).startOf('minute');
                    ws.timeEnd = moment(ws.timeEnd).utcOffset(timeZone).startOf('minute');
                });
            }
        });

        columns.forEach((column, columnIdx) => {
            const adminSlots = [];
            admins.forEach(admin => {
                if (admin.workShift && Array.isArray(admin.workShift)) {
                    admin.workShift.forEach(workShift => {
                        if (column.value.isSame(workShift.timeStart, 'day')) {
                            if (workShift.parlour.id === parlour.id && (workShift.activity === activityTypes.working || workShift.activity === activityTypes.overtime)) {
                                adminSlots.push({
                                    day: column.value,
                                    start: workShift.timeStart,
                                    end: workShift.timeEnd,
                                });
                            }
                        }
                    });
                }
            });


            const columnMasters = [];
            masters.forEach(master => {
                const { workShift, ...otherMasterProps } = master;
                if (workShift) {
                    const columnWs = workShift.filter(ws => column.value.isSame(ws.timeStart, 'day'));
                    const hasWorkingInParlour = columnWs.some(ws =>
                        ws.parlour.id === parlour.id && (
                            ws.activity === activityTypes.waiting ||
                            ws.activity === activityTypes.working ||
                            ws.activity === activityTypes.overtime
                        )
                    );
                    if (columnWs && columnWs.length && hasWorkingInParlour) {
                        const columnMaster = otherMasterProps;
                        columnMaster.enabledSlots = [];
                        columnMaster.enabledColumns = [];
                        columnMaster.disabledSlots = [];
                        columnMaster.waiting = [];
                        columnMaster.headerStyle = null;
                        columnMaster.bodyStyle = null;
                        columnMaster.key = otherMasterProps.id;
                        columnMaster.adminSlots = adminSlots;

                        columnWs.forEach(ws => {

                            if (columnMaster.lineColor) {
                                columnMaster.headerStyle = { backgroundColor: columnMaster.lineColor };
                                columnMaster.bodyStyle = { backgroundColor: columnMaster.lineColor };
                            }

                            if (ws.parlour.id === parlour.id &&  ws.activity === activityTypes.waiting) {
                                columnMaster.waiting.push({
                                    day: column.value,
                                });
                                columnMaster.headerStyle = { backgroundColor: waitingMasterColor };
                                columnMaster.bodyStyle = { backgroundColor: waitingMasterColor };
                            }

                            if (ws.parlour.id === parlour.id && (ws.activity === activityTypes.working || ws.activity === activityTypes.waiting)) {
                                columnMaster.enabledSlots.push({
                                    day: column.value,
                                    start: ws.timeStart,
                                    end: ws.timeEnd,
                                });
                            } else if (ws.parlour.id === parlour.id && ws.activity === activityTypes.overtime ) {
                                columnMaster.enabledSlots.push({
                                    day: column.value,
                                    start: ws.timeStart,
                                    end: ws.timeEnd,
                                });
                            } else {
                                columnMaster.disabledSlots.push({
                                    day: column.value,
                                    start: ws.timeStart,
                                    end: ws.timeEnd,
                                    parlour: ws.parlour,
                                    activity: ws.activity,
                                    comment: ws.comment,
                                });
                            }
                        });

                        columnMasters.push(columnMaster);
                    }
                }
            });

            subColumns[columnIdx] = [
                ...columnMasters,
                ...equipments.map(equipment => ({
                    ...equipment,
                    disabledSlotsAll: true,
                    adminSlots,
                    headerStyle: equipment.type === 'barrel' ?
                        { background: '#ded166' } :
                        equipment.type === 'room' ?
                            {background: '#73cba7'} :
                            null,
                    key: equipment.id,
                })),
            ]
        });

        return {
            subColumns,
        };
    }

    getMasters() {
        if (this.props.parlour && this.props.parlour.id) {
            Promise.all([
                getMastersWorking({
                    parlour: this.props.parlour.id,
                    fromDate: this.props.dateFrom.toISOString(),
                    toDate: this.props.dateTo.toISOString(),
                }),
                getEquipments({
                    businessUnitId: this.props.parlour.id,
                    status: 'working'
                }),
                getAdminsWorking({
                    parlour: this.props.parlour.id,
                    fromDate: this.props.dateFrom.toISOString(),
                    toDate: this.props.dateTo.toISOString(),
                }),
                getVisitCountGuestsPeriod(
                    this.props.dateFrom,
                    this.props.dateTo,
                    this.props.parlour.id
                ),
            ]).then(([responseMasters, responseEquipments, responseAdmins, responseGuestsVisitCount]) => {
                let masters = [];
                let equipments = [];
                let admins = [];
                let guestsVisitCount = {};
                if (responseMasters.success) {
                    masters = responseMasters.data;
                }
                if (responseEquipments.success) {
                    equipments = responseEquipments.data;
                }
                if (responseAdmins.success) {
                    admins = responseAdmins.data;
                }
                if (responseGuestsVisitCount.success) {
                    guestsVisitCount = responseGuestsVisitCount.data;
                }

                this.setState({
                    ...this.calcSubColumns(masters, equipments, admins),
                    guestsVisitCount: guestsVisitCount
                }, () => {
                    this.setState(state => ({
                        ...this.calcVisits(state.visits),
                    }))
                });
            });
        }
    }

    calcVisit = (visit, visitIdx) => {
        const { columns, subColumns, columnTimeSlots, guestsVisitCount } = this.state;
        const { timeStep, hiddenStatuses } = this.props;

        const elements = [];

        visit.visitFacility.forEach((vf, vfIdx) => {
            if (hiddenStatuses && hiddenStatuses.indexOf(visit.status) >= 0) {
                return;
            }
            const columnIdx = columns.findIndex(column => column.value.isSame(vf.start, 'day'));
            if (columnIdx < 0) {
                // Не нашлась колонка
                return;
            }

            let colSubColumns = subColumns[columnIdx];
            const colTimeSlots = columnTimeSlots[columnIdx];
            if (!colTimeSlots) {
                // Нет слотов
                return;
            }

            if (!colSubColumns) {
                colSubColumns = [];
            }

            let isCash = false;
            let isCashless = false;
            let isPaymentProcessing = false;
            let firstTime = false;
            if (visit.sale && visit.sale.payments && visit.sale.payments.length) {
                visit.sale.payments.forEach(payment => {
                    if (payment.type === 'cash') {
                        isCash = true;
                    } else if (payment.type === 'cashless' && payment.status !== paymentStatus.processing) {
                        isCashless = true;
                    }
                    if (payment.status === paymentStatus.processing) {
                        isPaymentProcessing = true;
                    }
                });
            }

            let dateKey = vf.start.format('YYYY-MM-DD');
            if (visit.guest && visit.guest.id) {
                firstTime = guestsVisitCount && guestsVisitCount[dateKey] && guestsVisitCount[dateKey][visit.guest.id] === 0;
                if (guestsVisitCount[dateKey]) {
                    visit.guestPastVisitsCount = guestsVisitCount[dateKey][visit.guest.id];
                }

            }

            vf.master.forEach((master, masterIdx) => {
                let subColumnIdx = colSubColumns.findIndex(subColumn => subColumn.id === master.id);
                if (subColumnIdx < 0) {
                    // Нет нужного мастера
                    if (colSubColumns.length) {
                        return;
                    }

                    //Если нет ни одного мастера, пусть отображается
                    subColumnIdx = 0;
                }
                const slotIdx = colTimeSlots.findIndex(slot => slot.fullValue.isSame(vf.start, 'minute'))
                if (slotIdx < 0) {
                    // Нет подходящего слота
                    return;
                }

                let left = 0;
                for (let i = 0; i < columnIdx; i++) {
                    left += dimensions.getColumnWidthWeek(subColumns[i]);
                }
                left += subColumnIdx * dimensions.subColumnWidth ;

                elements.push({
                    slotIdx,
                    columnIdx,
                    subColumnIdx,
                    visitIdx,
                    vfIdx,
                    masterIdx,
                    height: vf.duration.asMinutes() / timeStep.asMinutes() * dimensions.slotHeight,
                    key: vf.id + '-' + master.id,
                    top: slotIdx * dimensions.slotHeight,
                    left: left,
                    style: {
                        background: getVisitStatusColor(visit.status),
                        zIndex: visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked ? 0 : '',
                        opacity: visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked ? 0.5 : null,
                    },
                    masterId: master.id,
                    masterRequested: master.requested,
                    visit: visit,
                    icons: {
                        isCash,
                        isCashless,
                        isPaymentProcessing,
                        isClubMember: visit.guest && visit.guest.isClubMember,
                        isOnline: visit.isOnline,
                        isRequested: master.requested,
                        firstTime
                    },
                    content: {
                        timeStartFormat: vf.start.format('HH:mm'),
                        timeEndFormat: vf.end.format('HH:mm'),
                    },
                    color: (vf.facility && vf.facility.color) || 'transparent'
                })
            });

            if (vf.equipmentId) {
                const subColumnIdx = colSubColumns.findIndex(subColumn => subColumn.id === vf.equipmentId);
                if (subColumnIdx < 0) {
                    // Нет нужного мастера
                    return;
                }
                const slotIdx = colTimeSlots.findIndex(slot => slot.fullValue.isSame(vf.start, 'minute'))
                if (slotIdx < 0) {
                    // Нет подходящего слота
                    return;
                }

                let left = 0;
                for (let i = 0; i < columnIdx; i++) {
                    left += dimensions.getColumnWidthWeek(subColumns[i]);
                }
                left += subColumnIdx * dimensions.subColumnWidth ;

                elements.push({
                    slotIdx,
                    columnIdx,
                    subColumnIdx,
                    visitIdx,
                    vfIdx,
                    masterIdx: -100,
                    height: vf.duration.asMinutes() / timeStep.asMinutes() * dimensions.slotHeight,
                    key: vf.id + '-' + vf.equipmentId,
                    top: slotIdx * dimensions.slotHeight,
                    left: left,
                    style: {
                        background: getVisitStatusColor(visit.status),
                        zIndex: visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked ? 0 : '',
                        opacity: visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked ? 0.5 : null,
                    },
                    masterId: null,
                    masterRequested: null,
                    disableDrag: true,
                    visit: visit,
                    icons: {
                        isCash,
                        isCashless,
                        isPaymentProcessing,
                        isClubMember: visit.guest && visit.guest.isClubMember,
                        isOnline: visit.isOnline,
                        firstTime
                    },
                    content: {
                        timeStartFormat: vf.start.format('HH:mm'),
                        timeEndFormat: vf.end.format('HH:mm'),
                    },
                    color: (vf.facility && vf.facility.color) || 'transparent'
                })
            }
        });

        return elements;
    }

    processVisit(visit) {
        const { timeZone } = this.state;
        visit.visitFacility.forEach(vf => {
            vf.start = moment(vf.start).utcOffset(timeZone);
            vf.duration = moment.duration(vf.duration, 'minutes');
            vf.end = vf.start.clone().add(vf.duration);

            if (!visit.start || vf.start < visit.start) {
                visit.start = vf.start.clone();
            }

            if (!visit.end || vf.end > visit.end) {
                visit.end = vf.end.clone();
            }
        });

        return visit;
    }

    calcVisits = visits => {

        let elements = [];

        visits.forEach((visit, visitIdx) => {
           elements = elements.concat(this.calcVisit(visit, visitIdx));
        })

        return {
            elements,
            visits,
        };
    }

    getVisits() {
        if (this.props.parlour && this.props.parlour.id) {
            getVisits({
                parlour: this.props.parlour.id,
                fromDate: this.props.dateFrom.toISOString(),
                toDate: this.props.dateTo.toISOString(),
            }).then(response => {
                if (response.success) {
                    const visits = response.data;
                    visits.forEach(visit => {
                        this.processVisit(visit);
                    });
                    this.setState({
                        ...this.calcVisits(visits),
                    });
                }
            })
        }
    }

    handleBeginDrag = (visitIdx) => {
        let { draggingIdx, visits } = this.state;

        if (draggingIdx < 0) {
            this.setState({
                isDragging: true,
                draggingIdx: visitIdx,
                draggingVisit: _.cloneDeep(visits[visitIdx]),
            });
        }
    };

    handleEndDrag = () => {
        this.setState({
            isDragging: false,
        })
    }

    handleSlotDropHover = (slotFullValue, subColumn, visitIdx, vfIdx, masterIdx) => {
        const { draggingVisit } = this.state;
        const vf = draggingVisit.visitFacility[vfIdx];
        const diffStart = slotFullValue.isSame(vf.start, 'minute') ? null : slotFullValue.clone().diff(vf.start);

        let changed = false;
        if (subColumn.id !== vf.master[masterIdx].id) {
            vf.master[masterIdx] = {...subColumn};
            changed = true;
        }

        if (changed || diffStart) {
            if (changed) {
                draggingVisit.visitFacility[vfIdx] = vf;
            }

            if (diffStart) {
                draggingVisit.visitFacility.forEach(vf => { vf.start.add(diffStart); vf.end.add(diffStart); });
                draggingVisit.start.add(diffStart);
                draggingVisit.end.add(diffStart);
            }

            this.setState({
                draggingVisit: {...draggingVisit},
            });
        }
    }
    handleSlotDropHoverDebounce = _.debounce(this.handleSlotDropHover, 10);

    handleAcceptDrag = () => {
        const { draggingIdx, draggingVisit, visits } = this.state;

        if (draggingIdx >= 0) {
            this.setState({
                draggingIdx: -1,
                draggingVisit: null,
            });

            updateVisit(draggingVisit)
                .then(response => {
                    if (response.success) {
                        visits[draggingIdx] = this.processVisit(response.data);
                        this.setState({
                            ...this.calcVisits(visits),
                        });
                        this.props.showInfo('Изменения успешно сохранены');
                    } else {
                        this.props.showError(response.error ? response.error.message : response.message);
                        this.getVisits();
                    }
                })
        }
    }

    handleDeclineDrag = () => {
        this.setState({
            draggingIdx: -1,
            draggingVisit: null,
        });
    }

    render() {
        const {
            classes,
            printInfo,
            onHeaderClick,
            onSlotClick,
            onSlotDbClick,
            onSlotContextMenu,
            t,
            onVisitClick,
            onVisitDbClick,
            onVisitContextMenu,
            selectedVisit,
            parlour,
        } = this.props;

        const {
            timeZone,
            columns,
            columnTimeSlots,
            headerTimeSlots,
            parlourLoads,
            subColumns,
            elements,
            draggingIdx,
            draggingVisit,
            isDragging,
            ctrlPressed,
        } = this.state;

        return <div className={classes.container}>
            <div className={classes.timeGutter}>
                <div className={classes.timeZone}>
                    <span className={classes.hidePrint}>GMT<br/>{timeZone}</span>
                    <span className={classes.showPrint}>{printInfo}</span>
                </div>
                <ScheduleVisitsWeekHeaderSlots slots={headerTimeSlots}/>
            </div>
            <div className={classes.rightContainer}>
                <div className={classes.columnsHeaderContainer}>
                    {columns.map((column, columnIdx) => {
                        const colSubColumns = subColumns[columnIdx];
                        const columnWidth = dimensions.getColumnWidthWeek(colSubColumns);
                        return <div
                            className={classes.columnHeader}
                            key={column.key}
                            style={{width: columnWidth, minWidth: columnWidth, cursor: onHeaderClick ? 'pointer' : null}}
                            onClick={event => onHeaderClick && onHeaderClick(column.value, event)}
                        >
                            <span>{column.title}</span>
                            {parlourLoads[columnIdx] ? <span style={{color: colorLoad(parlourLoads[columnIdx])}}> {parlourLoads[columnIdx] + '%'}</span> : null}
                            {colSubColumns && colSubColumns.length ?
                                <div className={classes.subColumnHeaders}>
                                    {colSubColumns.map((subColumn) => <div
                                        className={classes.subColumnHeader}
                                        key={subColumn.key}
                                        style={subColumn.headerStyle}
                                    >{subColumn.name}</div>)}
                                </div>
                                : null
                            }
                        </div>
                    })}
                </div>
                <div className={classes.columnsContainer}>
                    {columns.map((column, columnIdx) => {
                        const colSubColumns = subColumns[columnIdx];
                        const columnSlots = columnTimeSlots[columnIdx];
                        const columnWidth = dimensions.getColumnWidthWeek(colSubColumns);
                        return <div
                            className={classes.subColumns}
                            style={{width: columnWidth, minWidth: columnWidth}}
                            key={column.key}
                        >
                            {colSubColumns && colSubColumns.map((subColumn) =>
                                <div
                                    key={subColumn.key}
                                    className={classes.subColumn}
                                    style={subColumn.bodyStyle}
                                >
                                    {columnSlots.map((slot) => {
                                        let disabled = false;
                                        let disabledTitle = null;
                                        if (subColumn.disabledSlotsAll) {
                                            disabled = true;
                                        } else if (subColumn.enabledSlots && subColumn.enabledSlots.length) {
                                            if (subColumn.enabledSlots.findIndex(item => slot.fullValue.isBetween(item.start, item.end, 'minute', '[)')) < 0) {
                                                disabled = true;
                                            }

                                            if (disabled && subColumn.disabledSlots && subColumn.disabledSlots.length) {
                                                const dFound = subColumn.disabledSlots.find(item => slot.fullValue.isSame(item.start, 'minute'));
                                                if (dFound) {
                                                    disabledTitle = dFound.activity === 'working' ?
                                                        dFound.parlour.name :
                                                        t('Break') + (dFound.comment ? ' ' + dFound.comment : '');
                                                }
                                            }
                                        } else if (subColumn.disabledSlots && subColumn.disabledSlots.length) {
                                            if(subColumn.disabledSlots.find(item => slot.fullValue.isBetween(item.start, item.end, 'minute', '[)'))) {
                                                disabled = true;
                                            }
                                            const dFound = subColumn.disabledSlots.find(item => slot.fullValue.isSame(item.start, 'minute'));
                                            if (dFound) {
                                                disabledTitle = dFound.activity === 'working' ?
                                                    dFound.parlour.name :
                                                    t('Break') + (dFound.comment ? ' ' + dFound.comment : '');
                                            }
                                        }
                                        let adminGap = false;
                                        if (subColumn.adminSlots.findIndex(item => slot.fullValue.isBetween(item.start, item.end, 'minute', '[)')) < 0) {
                                            adminGap = true;
                                        }

                                        return <ScheduleVisitsWeekSlot
                                            key={slot.key}
                                            slot={slot}
                                            subColumn={subColumn}
                                            disabled={disabled}
                                            disabledTitle={disabledTitle}
                                            onSlotClick={onSlotClick}
                                            onSlotDbClick={onSlotDbClick}
                                            onSlotContextMenu={onSlotContextMenu}
                                            onSlotDropHover={this.handleSlotDropHoverDebounce}
                                            adminGap={parlour.showAdminGaps && adminGap}
                                        />;
                                    })}
                                </div>
                            )}
                        </div>;
                    })}

                    {elements.map(element => <ScheduleVisitsWeekElement
                        key={element.key}
                        element={element}
                        onBeginDrag={this.handleBeginDrag}
                        onEndDrag={this.handleEndDrag}
                        draggingIdx={draggingIdx}
                        isDraggingSource={isDragging}
                        ctrlPressed={ctrlPressed}
                        onVisitClick={onVisitClick}
                        onVisitDbClick={onVisitDbClick}
                        onVisitContextMenu={onVisitContextMenu}
                        selectedVisit={selectedVisit}
                    />)}

                    { draggingIdx >=0 && draggingVisit ?
                        this.calcVisit(draggingVisit, draggingIdx).map(element => <ScheduleVisitsWeekElement
                            key={element.key}
                            element={element}
                            onBeginDrag={this.handleBeginDrag}
                            onEndDrag={this.handleEndDrag}
                            draggingIdx={draggingIdx}
                            isDragTarget
                            isDraggingSource={isDragging}
                            onVisitClick={onVisitClick}
                            onVisitDbClick={onVisitDbClick}
                            onVisitContextMenu={onVisitContextMenu}
                            selectedVisit={selectedVisit}
                        />) :
                        null
                    }
                </div>
            </div>

            <Snackbar
                open={draggingIdx >= 0 && !isDragging}
                color=""
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={<span>{t("Transfer of the visit Are you sure")}</span>}
                action={[
                    <IconButton
                        key="accept-drag"
                        onClick={this.handleAcceptDrag}
                        color="inherit"
                    >
                        <CheckIcon />
                    </IconButton>,
                    <IconButton
                        key="cancel-drag"
                        onClick={this.handleDeclineDrag}
                        color="inherit"
                    >
                        <CancelIcon />
                    </IconButton>,
                ]}
            />
        </div>;
    }
}
