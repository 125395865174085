import React from "react";
import { Route, Switch } from "react-router";
import ReportMastersPage from "./ReportMastersPage";
import ReportCard from "./ReportCard";

const ReportCardNew = React.lazy(() =>
    import(
        /* webpackChunkName: "CalendarEmployee-ReportCard" */ "./report-card"
    )
);

class CalendarEmployeePage extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/report-card" component={ReportCard} />
                <Route exact path="/report-card/v2" component={ReportCardNew} />
                <Route
                    exact
                    path="/report-card/report-masters"
                    component={ReportMastersPage}
                />
            </Switch>
        );
    }
}

export default CalendarEmployeePage;
