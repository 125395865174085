import React from 'react';
import {withStyles} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import {sendTestTelegram} from "../../services/common";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";

export default
@connect(state => ({

}),{
    showInfo: infoActions.show,
    showError: infoActions.showError,
})
@withStyles(theme => ({
    container: {
        width: 500,
    }
}))
class TestTelegramPage extends React.Component
{
    state = {
        recipient: null,
        message: null,
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSend = () => {
        sendTestTelegram(this.state)
            .then(response => {
                if (response.success) {
                    this.props.showInfo('Message sent');
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            })
    };

    render() {
        const { classes } = this.props;
        const { recipient, message } = this.state;

        return <Content title="Test telegram">
            <div className={classes.container}>
                <AnyField
                    label="Recipient"
                    value={recipient}
                    name="recipient"
                    fullWidth
                    onChange={this.handleChange}
                />
                <AnyField
                    label="Message (HTML)"
                    value={message}
                    name="message"
                    error={!message}
                    required
                    fullWidth
                    multiline
                    rows={4}
                    onChange={this.handleChange}
                />
                <ActionButton disabled={!message} onClick={this.handleSend}>Send</ActionButton>
            </div>
        </Content>;
    }
}