import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits, getEmployeePosts} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getIncomingsReport, getIncomingTypes, navLinks} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import baseRequest from "../../services/baseReuqest"
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import {withTranslation} from "react-i18next";

const preColumnData = [
    { id: 'employeeName', label: 'Employee' },
    { id: 'employeePost', label: 'Employee post' },
    { id: 'businessUnitName', label: 'Business unit'},
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

const groupsBy = [
    { id: 'day', text: 'By days' },
    { id: 'month', text: 'By months' }
];

export default
@withStyles(styles)
@withTranslation()
class EmployeeIncomingPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: moment(),
            date_end: moment(),
            businessUnitId: 0,
            employeePostIds: [],
            incomingTypeIds: []
        },
        refreshTable: false,
        businessUnits: [],
        employeePosts: [],
        incomingTypes: [],
        groupBy: 'day',
        columnData: preColumnData,
        progress: false,
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });

        getEmployeePosts()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employeePosts: [
                            ...response.data
                        ],
                    });
                }
            });

        getIncomingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        incomingTypes: [
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleChangeState = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            [prop]: value,
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getIncomingsReport(this.state.groupBy, search, ...params)
            .then(response => {
                if (response.success) {

                    this.setState({
                        columnData: preColumnData.concat(response.data.columns)
                    });

                    return {
                        success: true,
                        data: response.success ? response.data.data : [],
                    };
                }

                return response;
            });
    };

    /*
    TODO: Временно для тестирования, потом надо убрать
     */
    handleUpdateTodayIncomings = () => {
        this.setState({
            progress: true,
        })
        baseRequest('/api/money-flow/incoming-employee', {method: 'POST'})
            .then(response => {
                this.setState({
                    progress: false,
                    refreshTable: !this.state.refreshTable,
                })
            });
    }

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits, columnData, groupBy, employeePosts, incomingTypes, progress } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t("Show changes by date interval")}
                        className={classes.filterControl}
                    />
                    <AnyField
                        id="parlour"
                        label={t("Business unit")}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <AnyField
                        value={search.employeePostIds}
                        onChange={this.handleChange('employeePostIds')}
                        label={t("Employee posts")}
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>{t('---All---')}</span>;
                                }

                                return employeePosts.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.text).join(', ');
                            },
                        }}
                        className={classes.filterControl}
                        fullWidth
                    >
                        {employeePosts.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={search.employeePostIds.indexOf(item.id) > -1} />
                                <ListItemText primary={item.text} />
                            </MenuItem>
                        ))}
                    </AnyField>
                    <AnyField
                        value={search.incomingTypeIds}
                        onChange={this.handleChange('incomingTypeIds')}
                        label={t("Incoming types")}
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>{t('---All---')}</span>;
                                }

                                return incomingTypes.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                            },
                        }}
                        className={classes.filterControl}
                        fullWidth
                    >
                        {incomingTypes.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={search.incomingTypeIds.indexOf(item.id) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </AnyField>
                    <AnyField
                        label={t("Group by")}
                        value={groupBy}
                        onChange={this.handleChangeState('groupBy')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {groupsBy.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                    </AnyField>
                    <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                    <br/><br/>
                    <ActionButton onClick={this.handleUpdateTodayIncomings} color="primary">{t("Refresh today incoming")}</ActionButton>
                </LeftBar>
                <Content title={t("Employee incoming")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        pagination={false}
                        disableSorting
                        autoload={false}
                    />
                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
