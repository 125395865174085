import baseRequest from "./baseReuqest";
import moment from "moment";

export const BREAK_INDUSTRIAL_NECESSITY = 'industrialNecessity';

export const navLinks = [
    { path: "/report-card", text: "Табель на месяц"},
    { path: "/report-card/report-masters", text: "Отчет по мастерам", role: "ROLE_CALENDAR_EMPLOYEE_REPORT_MASTER"},
];

export const activityTypeClear = 'clear';
export const activityTypeSplit = 'split';
export const activityTypeEdit = 'edit';

export const activityTypeNames = {
    working: 'Рабочие часы',
    leave: 'Отпуск',
    sick_leave: 'Больничный',
    break: 'Перерыв',
    overtime: 'Сверхурочные часы',
    waiting: 'Лист ожидания',
    internship: 'Стажировка',
    transfer: 'Технический мастер'
};

const activityTypeColors = {
    working: '#66ff66',
    working_other: '#b3ffb0',
    waiting: '#fff900',
    leave: '#ffc107',
    leave_other: '#ffc107',
    sick_leave: '#00ccff',
    sick_leave_other: '#00ccff',
    break: '#868A08',
    overtime: '#FE2E64',
    internship: '#992efe',
    transfer: '#434453'
};

export const activityTypes = {
    /** Рабочие часы */
    working: 'working',

    /** Лист ожидания */
    waiting: 'waiting',

    /** Отпуск */
    leave: 'leave',

    /** Больничный */
    sick_leave: 'sick_leave',

    /** Перерыв */
    break: 'break',

    /** Сверхурочные часы */
    overtime: 'overtime',

    /** Стажировка */
    internship: 'internship',

    /** Технический мастер */
    transfer: 'transfer'
};

export function getCalendarEmployees(search) {
    return baseRequest('/api/calendar-employees', {
        query: {search, per_page: -1}
    });
}

export function saveActivityEmployee(activities) {
    if (!Array.isArray(activities)) {
        activities = [activities];
    }

    return baseRequest('/api/calendar-employee/create', {
        method: 'POST',
        body: JSON.stringify({activities}),
    });
}

export function saveSlotEvent(params) {
    return baseRequest('/api/calendar-employee-event/create', {
        method: 'POST',
        body: JSON.stringify(params),
    });
}

export function removeActivityEmployee(id) {
    return baseRequest(`/api/calendar-employee/remove/${id}`,{
        method: 'DELETE'
    })
}

export function getActivityTypeText(status) {
    return activityTypeNames[status] ? activityTypeNames[status] : 'Unknown';
}

export function getActivityTypeColor(status, isSameParlour = true) {
    const statusWithParlour = status + (isSameParlour ? '' : '_other');
    return activityTypeColors[statusWithParlour] ? activityTypeColors[statusWithParlour] : '#fff';
}

export function getEvents() {
    return baseRequest('/api/calendar-employee/events', {
        query: {per_page: -1},
    });
}

export function eventCreate(event) {
    return baseRequest('/api/calendar-employee/event/create', {
        method: 'POST',
        body: JSON.stringify(event),
    });
}

export function saveApproved(activities) {
    activities = processedActivitiesApprove(activities);

    return baseRequest('/api/calendar-employee-approved/create', {
        method: 'PUT',
        body: JSON.stringify({activities})
    });
}

function processedActivitiesApprove(activitiesApprove) {
    return activitiesApprove.map( item => {
        return {
            ...item,
            parlour: item.parlour.id,
            shift: item.shift ? item.shift.id : null,
        }

    })
}

export function getCurrentWorkingAdministrators(data) {
    return baseRequest('/api/calendar-employee/administrators', {
        query: data
    });
}

export function getEmployeePostOrder(employeePost) {
    if (employeePost === 'master') {
        return 1;
    }

    return 9999;
}

export function getCalendarEmployeeActivities() {
    let url = '/api/calendar-employee/activities';

    return baseRequest(url)
}

/**
 *
 * @param period
 * @param {moment} [period.timeStart]
 * @param {moment} [period.timeEnd]
 * @param addedPeriod
 * @param {moment} [addedPeriod.start]
 * @param {moment} [addedPeriod.end]
 * @param parlour
 * @param master
 * @param {string} [master.id]
 * @param callback
 * @return boolean
 */
export function addActivityEmployee(period, addedPeriod, parlour, master, callback) {
    const periodStart = moment(period.timeStart).utcOffset(parlour.timeZone);
    const periodEnd = moment(period.timeEnd).utcOffset(parlour.timeZone);
    const addedPeriodDuration = addedPeriod.end.diff(addedPeriod.start, 'minutes');
    let activities = [];

    const activityType = period?.activity ?? activityTypes.working;

    if (periodStart.isSame(addedPeriod.start)) {
        activities.push({
            id: period.id,
            employees: [master.id],
            duration: period.duration - addedPeriodDuration,
            timeStart: addedPeriod.start.clone().add(addedPeriodDuration, 'minutes').toISOString(),
            activity: activityType,
            parlour: parlour.id,
            approved: true,
        });

        activities.push({
            employees: [master.id],
            duration: addedPeriodDuration,
            activity: activityTypes.break,
            parlour: parlour.id,
            timeStart: addedPeriod.start.toISOString(),
            approved: true,
            comment: addedPeriod.comment,
            breakType: addedPeriod.type,
        });
    } else if (periodEnd.isSame(addedPeriod.end)) {
        activities.push({
            id: period.id,
            employees: [master.id],
            duration: period.duration - addedPeriodDuration,
            timeStart: periodStart.toISOString(),
            activity: activityType,
            parlour: parlour.id,
            approved: true,
        });

        activities.push({
            employees: [master.id],
            duration: Math.round(addedPeriodDuration),
            activity: activityTypes.break,
            parlour: parlour.id,
            timeStart: addedPeriod.start.toISOString(),
            approved: true,
            comment: addedPeriod.comment,
            breakType: addedPeriod.type,
        });
    } else {
        activities.push({
            id: period.id,
            employees: [master.id],
            duration: Math.round(addedPeriod.start.diff(periodStart, 'minutes', true)),
            timeStart: periodStart.toISOString(),
            activity: activityType,
            parlour: parlour.id,
            approved: true,
        });

        activities.push({
            employees: [master.id],
            duration: Math.round(addedPeriod.end.diff(addedPeriod.start, 'minutes', true)),
            activity: activityTypes.break,
            parlour: parlour.id,
            timeStart: addedPeriod.start.toISOString(),
            approved: true,
            comment: addedPeriod.comment,
            breakType: addedPeriod.type,
        });

        activities.push({
            employees: [master.id],
            duration: Math.round(periodEnd.diff(addedPeriod.end, 'minutes', true)),
            activity: activityType,
            parlour: parlour.id,
            timeStart: addedPeriod.end.toISOString(),
            approved: true,
        });

    }

    saveActivityEmployee(activities)
        .then(response => {
            if (response.success) {
                callback && callback();
                return response.success;
            } else {
                return false;
            }
        })
}

/**
 *
 * @param timeStart
 * @param timeEnd
 * @param employeeId
 * @return bool
 */
export function checkTimeEmployee(timeStart, timeEnd, employeeId) {
    return baseRequest('/api/calendar-employee/check-time-employee', {
        query: {timeStart, timeEnd, employeeId}
    });
}

export function checkActivities(activities) {
    return baseRequest('/api/calendar-employee/check-activities', {
        method: "POST",
        body: { activities },
    });
}

export function getCalendarEmployeeBreakTypes() {
    let url = '/api/calendar-employee/breaks';

    return baseRequest(url);
}

const dateFormat = 'YYYY-MM-DD';

function processSearchDates(search) {
    if (moment.isMoment(search.date_start)) search.date_start = search.date_start.format(dateFormat);
    if (moment.isMoment(search.date_end)) search.date_end = search.date_end.format(dateFormat);
}

export function reportMasters(search, page, per_page) {
    processSearchDates(search);

    return baseRequest('/api/calendar-employee/report/master', {
        query: {search, page, per_page: per_page ? per_page : -1}
    });
}

export function getWorkShiftCurrentBusinessUnit()
{
    return baseRequest('/api/calendar-employee/current-business-unit');
}

export function createMomentToTime (time, day, timeZone = null) {
    if (!time) {
        return;
    }

    let parts = time.split(':');

    let result = day ? day.clone().set({'hour': parts[0], 'minute': parts[1], 'second': 0}) : moment({'hour': parts[0], 'minute': parts[1], 'second': 0});

    if (timeZone) {
        result.utcOffset(timeZone, true);
    }

    return result;
}

export function getDayWorkTime(day, parlour, shiftId = null) {
    let durationWork;

    let timeStart = createMomentToTime(parlour.workTime[day.isoWeekday()].start, day, parlour.timeZone);


    if (parlour && parlour.workShift && parlour.workShift.length) {
        let shift = parlour.workShift.find(itemShift => itemShift.id === shiftId);

        if (shift) {
            let shiftStart = createMomentToTime(shift.start);
            let shiftEnd = createMomentToTime(shift.end);

            durationWork = moment(shiftEnd).diff(shiftStart, 'minutes');
        }
    } else {
        let timeEnd = createMomentToTime(parlour.workTime[day.isoWeekday()].finish, day, parlour.timeZone);
        if (timeEnd < timeStart) {
            timeEnd.add(1, 'days');
        }
        durationWork = timeEnd.diff(timeStart, 'minutes');
    }

    return {
        dayTimeStart: timeStart,
        durationWork: durationWork,
    }
}

export function clearActivities(employeeId, from, to)
{
    return baseRequest('/api/calendar-employee/clear-activities', {
        method: "DELETE",
        body: {
            employeeId,
            from: from.toISOString(),
            to: to.toISOString(),
        }
    });
}

export function getParlourRerportCard(businessUnitIds, startDate, endDate)
{
    return baseRequest("/api/calendar-employee/parlour-report-card", {
        query: {
            businessUnitIds,
            startDate,
            endDate
        }
    });
}
