import {withRouter} from "react-router";
import {connect} from "react-redux";
import infoActions from "../info/info-actions";
import messageDialogActions from "./messageDialog-acions";
import confirmDialogActions from "./confirmDialog-acions";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    withStyles
} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {parseXLS} from "../../services/common";
import UploadFilesField from "../fields/UploadFilesField";
import ActionButton from "../button/ActionButton";
import {withTranslation} from "react-i18next";
import {spendingXLSXRowsToDTOArray} from "../../utils/dto";
import _ from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";

export default
@withRouter
@withTranslation()
@connect(state => ({
    token: state.auth.token,
}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
@withStyles(theme => ({
}))
class ImportDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        importFunc: PropTypes.func,
        title: PropTypes.string,
        keys: PropTypes.object,
        chunk: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.state = {
            importFileName: null,
            importSpending: [],
            importError: null,
            loading: false,
            errors: [],
            count: 0,
            chunks: [],
            progress: null,
        };

    }

    handleChangeFile = (event) => {
        this.setState({
            importSpending: [],
            importFileName: null,
            importError: null
        });
        let file = event.target.files[0];
        parseXLS(file).then((json) => {
            let DTOArray = spendingXLSXRowsToDTOArray(json, this.props.keys);
            if (Array.isArray(DTOArray)) {
                this.setState({
                    importSpending: DTOArray,
                    importFileName: file.name
                });
            } else {
                this.setState({
                    importError: DTOArray,
                });
            }
        });
    }

    processChunk = async (chunk) => {
        let response = await this.props.importFunc({rows: chunk});

        if (!response.success) {
            this.setState({
                errors: [
                    response.error ? response.error.message : response.message,
                ],
            })

            return;
        }

        this.setState({
            count: this.state.count + response.data.count,
            progress: ((this.state.count + response.data.count) / this.state.importSpending.length) * 100,
        });
    }

    importRows = async () => {
        const {t} = this.props;

        this.setState({
            loading: true,
            progress: 0,
        });

        const chunks = _.chunk(this.state.importSpending, this.props.chunk);

        while (chunks.length) {
            const chunk = chunks.shift();

            await this.processChunk(chunk);
        }

        this.onClose();
        this.props.showMessage(t("Rows imported") + this.state.count);
        this.setState({
            importSpending: [],
            importFileName: null,
            loading: false,
        });
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    columnsTitle = () => {
        return Object.values(this.props.keys).join(', ');
    }

    render() {
        const {importSpending, importFileName, importError, progress, loading} = this.state;
        const {open, title, t, classes} = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.onClose}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle>{ title }</DialogTitle>
                <DialogContent>
                    <p>Файл должен быть в формате XLSX и содержать колонки: {this.columnsTitle()}</p>
                    <Grid container spacing={2} className={classes.container}>
                        {!loading && <Grid item xs={4}>
                            <UploadFilesField
                                id="importFile"
                                value={null}
                                onChange={this.handleChangeFile}
                                disabled={loading}
                                notLoad
                            />
                        </Grid>}
                        <Grid item xs={8}>
                            {!!importSpending.length &&
                            <p>
                                {t("Filename")}: {importFileName}<br/>
                                {t("Rows will be imported")}: {importSpending.length}
                            </p>
                            }
                            {!!importError &&
                            <p style={{color: 'red'}}>{importError}</p>
                            }
                        </Grid>
                    </Grid>
                    <Grid>
                        {loading && <LinearProgress variant="determinate" value={progress}/>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!!importSpending.length && <ActionButton onClick={this.importRows}
                                   disabled={loading}
                                   color="primary"
                                   fullWidth={false}>{t("Do import")}</ActionButton>}
                    <ActionButton onClick={ this.onClose}
                                  disabled={loading}
                                  color="primary"
                                  fullWidth={false}>{t("Close")}</ActionButton>
                </DialogActions>
            </Dialog>
        );

    }
}