export function isValidRequiredL18nString(object) {
    for (const lang of ['ru', 'en', 'cs']) {
        const string = object[lang];

        if (typeof string === "undefined") {
            return false;
        }

        if (string.length === 0) {
            return false;
        }
    }

    return true;
}
