import React from 'react';
import PropTypes from "prop-types";
import HelpIcon from "@material-ui/icons/HelpOutline";
import MoneyValue from "./MoneyValue";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    link: {
        display: "block",
        marginLeft: 4,
    },
    linkIcon: {
        display: "block",
    },
});

const MoneyValueDetailsLink = ({ href, ...rest }) => {
    const classes = useStyles();

    return (
        <MoneyValue {...rest}>
            <a className={classes.link} href={href} target="_blank" rel="noopener noreferrer"><HelpIcon
                className={classes.linkIcon} fontSize="small"/></a>
        </MoneyValue>
    );
};

MoneyValueDetailsLink.propTypes = {
    title: PropTypes.string,
    value: PropTypes.shape({ val: PropTypes.number, cur: PropTypes.string }),
    href: PropTypes.string,
};

export default MoneyValueDetailsLink;