import React from 'react';
import {
    Grid, MenuItem
} from '@material-ui/core';
import {
    getBusinessUnits, getEmployeePosts,
    getHumanEmployees, getRegions,
    navLinks,
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import {
    deleteSalarySetting,
    getSalaryFunctions,
    getSalarySetting,
    getSalarySettings,
    getSalaryWageTypes,
    getSalaryWorkSchedules,
    getSalaryClosedSalesVariants,
    saveSalarySetting
} from "../../services/salary";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import Typography from "@material-ui/core/Typography";
import {withTranslation} from "react-i18next";
import MoneyField from "../../components/fields/MoneyField";
import {getCurrencies} from "../../services/common";
import PercentField from "../../components/fields/PercentField";
import DateIntervalField from "../../components/fields/DateIntervalField";
import DurationField from "../../components/fields/DurationField";
import CheckboxField from "../../components/fields/CheckboxField";
import DateField2 from "../../components/fields/DateField2";
import moment from "moment";
import {getSpendingTypes} from "../../services/moneyFlow";
import FloatField from "../../components/fields/FloatField";

const FIXED_WAGE_PLUS_PERCENT = 'fixed_wage_plus_percent';
const FIXED_WAGE_PLUS_RQ = 'fixed_wage_plus_rq';
const MANAGER_WAGE_PLUS_PERCENT = 'manager_wage_plus_percent';

const AVAILABLE_PARAMETERS = {
    [FIXED_WAGE_PLUS_PERCENT]: {
        wage: MoneyField,
        wageType: SelectFromItemsField,
        percentClosedVisit: PercentField,
        percentSellPayDoc: PercentField,
        useSaleBusinessUnitSellPayDoc: CheckboxField,
        percentSellGoodsMoney: PercentField,
        percentSellGoodsPD: PercentField,
        workSchedule: SelectFromItemsField,
        paymentPeriod: DateIntervalField,
        percentIncoming: PercentField,
        closedSalesVariant: SelectFromItemsField,
        workShiftDuration: DurationField,
        percentCreatedVisit: PercentField,
        hourCreatedVisit: MoneyField,
        hourCreatedVisitNotMaster: MoneyField,
        useVisitBusinessUnitHourCalc: CheckboxField,
        salaryDebtSpendingType: SelectFromItemsField,
        salaryDebtRoundSpendingType: SelectFromItemsField,
        salaryDebtRoundAmount: AnyField,
        delayedSalaryDays: FloatField,
        delayedSalaryPercents: PercentField
    },
    [FIXED_WAGE_PLUS_RQ]: {
        wage: MoneyField,
        wageType: SelectFromItemsField,
        rqHour: MoneyField,
        rqVisit: MoneyField,
        workSchedule: SelectFromItemsField,
        paymentPeriod: DateIntervalField,
        workShiftDuration: DurationField,
        workHourVisit: MoneyField,
        additionalWorkHourVisitExternal: MoneyField,
        salaryDebtSpendingType: SelectFromItemsField,
        salaryDebtRoundSpendingType: SelectFromItemsField,
        salaryDebtRoundAmount: AnyField,
        delayedSalaryDays: FloatField,
        delayedSalaryPercents: PercentField
    },
    [MANAGER_WAGE_PLUS_PERCENT]: {
        wage: MoneyField,
        wageType: SelectFromItemsField,
        percentClosedVisit: PercentField,
        percentSellPayDoc: PercentField,
        percentSellGoodsMoney: PercentField,
        percentSellGoodsPD: PercentField,
        workSchedule: SelectFromItemsField,
        paymentPeriod: DateIntervalField,
        percentIncoming: PercentField,
        workShiftDuration: DurationField,
        hourClosedVisit: MoneyField,
        hourClosedVisitNotMaster: MoneyField,
        hourCreatedVisit: MoneyField,
        hourCreatedVisitNotMaster: MoneyField,
        useVisitBusinessUnitHourCalc: CheckboxField,
        salaryDebtSpendingType: SelectFromItemsField,
        salaryDebtRoundSpendingType: SelectFromItemsField,
        salaryDebtRoundAmount: AnyField,
        delayedSalaryDays: FloatField,
        delayedSalaryPercents: PercentField
    },
};

export default
@withRouter
@connect(null, {
    showMessage: messageDialogActions.show
})
@withTranslation()
class SalarySettingViewPage extends React.Component {

    state = {
        salarySetting: {
            id: null,
            name: null,
            description: null,
            parentId: null,
            employeeId: null,
            businessUnitId: null,
            employeePost: null,
            function: null,
            parameters: null,
            active: true,
            startFrom: null,
        },
        employees: [],
        businessUnits: [],
        functions: [],
        salarySettings: [],
        employeePosts: [],
        currencies: [],
        wageTypes: [],
        workSchedules: [],
        regions: [],
        closedSalesVariants: [],
        spendingTypes: []
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getSalarySetting(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            salarySetting: response.data
                        })
                    }
                });
        }

        getHumanEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    })
                }
            });

        getEmployeePosts()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employeePosts: response.data,
                    })
                }
            });

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data.map(currency => currency.id),
                    });
                }
            });

        getSalaryFunctions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        functions: response.data,
                    })
                }
            });

        getSalaryWageTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        wageTypes: response.data,
                    })
                }
            });

        getSalaryWorkSchedules()
            .then(response => {
                if (response.success) {
                    this.setState({
                        workSchedules: response.data,
                    })
                }
            });

        getSalaryClosedSalesVariants()
            .then(response => {
                if (response.success) {
                    this.setState({
                        closedSalesVariants: response.data,
                    });
                }
            });

        getSalarySettings({})
            .then(response => {
                if (response.success) {
                    this.setState({
                        salarySettings: response.data,
                    })
                }
            });

        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    })
                }
            })

        getSpendingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypes: response.data,
                    })
                }
            })
    }

    handleChange = event => {
        const salarySetting = {...this.state.salarySetting};
        const prop = event.target.name;
        const value = event.target.value;

        if (prop === 'parentId' && salarySetting.parentId !== value) {
            const parent = this.state.salarySettings.find(item => item.id === value);
            salarySetting.function = parent ? parent.function : null;
        }

        salarySetting[prop] = value;

        this.setState({
            salarySetting,
        });
    };

    handleSave = () => {
        saveSalarySetting(this.getSettingsForSave())
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/salary-settings`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    getSettingsForSave = () => {
        const { salarySetting } = this.state;

        let processedParameters = null;

        if (salarySetting.parameters) {
            processedParameters = {};

            for (let parameter in salarySetting.parameters) {
                if (!salarySetting.parameters.hasOwnProperty(parameter)) {
                    continue;
                }

                const value = salarySetting.parameters[parameter];

                if (!(value && value.cur && (value.val === null || value.val === undefined))) {
                    processedParameters[parameter] = value;
                }
            }
        }

        return {
            ...salarySetting,
            startFrom: moment(salarySetting.startFrom).format('YYYY-MM-DD'),
            parameters: processedParameters,
        }
    };

    handleDelete = () => {
        deleteSalarySetting(this.state.salarySetting)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/salary-settings`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeParameter = event => {
        const salarySetting = {...this.state.salarySetting};

        if (!salarySetting.parameters) {
            salarySetting.parameters = {};
        }

        if (event.target.value === '' || event.target.value === null) {
            delete salarySetting.parameters[event.target.name];
        } else {
            salarySetting.parameters[event.target.name] = event.target.value;
        }

        this.setState({
            salarySetting
        });
    };

    renderParameters = () => {
        const { salarySetting, currencies, wageTypes, workSchedules, closedSalesVariants, spendingTypes } = this.state;
        const { t } = this.props;

        const functionParameters = AVAILABLE_PARAMETERS[salarySetting.function];

        if (functionParameters) {
            const fields = [];
            for (let parameter in functionParameters) {
                if (!functionParameters.hasOwnProperty(parameter)) {
                    continue;
                }
                const FieldComponent = functionParameters[parameter];
                const fieldProps = {
                    label: t("salaryParameters." + salarySetting.function + "." + parameter),
                    value: salarySetting.parameters && salarySetting.parameters[parameter],
                    name: parameter,
                    onChange: this.handleChangeParameter,
                    fullWidth: true,
                };

                if (FieldComponent === MoneyField) {
                    fieldProps.currencies = currencies;
                } else if (FieldComponent === SelectFromItemsField && parameter === 'wageType') {
                    fieldProps.items = wageTypes;
                    fieldProps.textField = 'text';
                } else if (FieldComponent === SelectFromItemsField && parameter === 'workSchedule') {
                    fieldProps.items = workSchedules;
                    fieldProps.textField = 'text';
                } else if (FieldComponent === SelectFromItemsField && parameter === 'closedSalesVariant') {
                    fieldProps.items = closedSalesVariants;
                    fieldProps.textField = 'text';
                } else if (FieldComponent === SelectFromItemsField && (parameter === 'salaryDebtSpendingType' || parameter === 'salaryDebtRoundSpendingType')) {
                    fieldProps.items = spendingTypes;
                    fieldProps.textField = 'name';
                }


                fields.push(<Grid item xs={4} key={parameter}><FieldComponent {...fieldProps}/></Grid>);
            }

            return fields;
        }

        return null;
    };

    render() {
        const { salarySetting, functions, businessUnits, employees, salarySettings, employeePosts, regions } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleSave} disabled={!salarySetting.name || !salarySetting.function}>Сохранить</ActionButton>
                    <ActionButton onClick={this.handleDelete} disabled={!salarySetting.id}>Удалить</ActionButton>
                </LeftBar>
                <Content title="Карточка настроек расчета ЗП">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <AnyField
                                id="name"
                                label="Название"
                                value={salarySetting.name}
                                fullWidth
                                error={!salarySetting.name}
                                onChange={this.handleChange}
                                name="name"
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Функция расчета"
                                value={salarySetting.function}
                                onChange={this.handleChange}
                                name="function"
                                fullWidth
                                select
                                required
                                error={!salarySetting.function}
                                readOnly={Boolean(salarySetting.parentId)}
                            >
                                {functions.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Родительские настройки"
                                value={salarySetting.parentId}
                                onChange={this.handleChange}
                                name="parentId"
                                fullWidth
                                items={salarySettings}
                                nullable
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                name="employeePost"
                                label="Должность"
                                value={salarySetting.employeePost}
                                onChange={this.handleChange}
                                fullWidth
                                items={employeePosts}
                                textField="text"
                                nullable
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                name="businessUnitId"
                                label="Бизнес юнит"
                                value={salarySetting.businessUnitId}
                                onChange={this.handleChange}
                                fullWidth
                                items={businessUnits}
                                nullable
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                name="employeeId"
                                label="Сотрудник"
                                value={salarySetting.employeeId}
                                onChange={this.handleChange}
                                fullWidth
                                items={employees}
                                nullable
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                name="regionId"
                                label="Регион"
                                value={salarySetting.regionId}
                                onChange={this.handleChange}
                                fullWidth
                                items={regions}
                                nullable
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                name="active"
                                label="Активна"
                                value={salarySetting.active}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateField2
                                name="startFrom"
                                value={salarySetting.startFrom}
                                onChange={this.handleChange}
                                label="Используется с"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}><Typography variant="subtitle2">Параметры</Typography></Grid>
                        {this.renderParameters()}
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
