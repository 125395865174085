import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import React from "react";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getRevision, navLinks} from "../../services/goods";
import Content from "../../components/content/Content";
import ShowField from "../../components/fields/ShowField";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import ActionButton from "../../components/button/ActionButton";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

const columnData = [
    { id: 'goodName', label: 'Good' },
    { id: 'categoryName', label: 'Category' },
    { id: 'deltaMoney', label: 'Delta price', disableSorting: true },
    { id: 'deltaMass', label: 'Delta mass', disableSorting: true },
];

@withRouter
@withStyles(styles)
@withTranslation()
class RevisionViewPage extends React.PureComponent
{
    state = {
        revision: {
            consumptions: []
        }
    }

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getRevision(resultId)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            revision: response.data,
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/revision`);
    };

    render() {
        const { revision } = this.state;
        const { t } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ShowField
                        label={t(`Business unit`)}
                        value={revision.businessUnitName}
                    />
                    <ShowField
                        label={t(`Creator`)}
                        value={revision.creatorName}
                    />
                    <ShowField
                        label={t(`Created at`)}
                        value={revision.createdAt}
                        dateFormat="DD.MM.YYYY HH:mm"
                    />
                    <ShowField
                        label={t(`Comment`)}
                        value={revision.comment}
                    />
                    <ActionButton onClick={this.handleClickBack}>{t('Back')}</ActionButton>
                </LeftBar>

                <Content title={t('Revision card')}>
                    <DataTableLocal
                        data={revision.consumptions}
                        columnData={columnData}
                        selector={false}
                        pagination={false}
                    />
                </Content>

            </React.Fragment>
        )
    }
}

export default RevisionViewPage;