import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/personalAccount";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import ShowField from "../../components/fields/ShowField";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import AnyField from "../../components/fields/AnyField";
import {changePassword} from "../../services/user";
import infoActions from "../../components/info/info-actions";
import CopyField from "../../components/fields/CopyField";

const styles = theme => {
    return {
        content: {
            padding:  theme.spacing(2)
        },
        contentItem: {
            paddingTop:  theme.spacing(2)
        }
    }
}

@withStyles(styles)
@withTranslation()
@connect(state => ({
    user: state.auth.user
}), {
    showInfo: infoActions.show,
    showError: infoActions.showError,
})

class ProfilePage extends React.Component
{
    state = {
        dialogChangePassword: false,
        oldPassword: null,
        newPassword: null,
        verificationNewPassword: null,
    }

    render() {
        const { dialogChangePassword, newPassword, oldPassword, verificationNewPassword } = this.state;
        const { t, user, classes } = this.props;
        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                </LeftBar>
                <Content title={t('Profile')}>
                    <Grid container spacing={2} className={classes.content}>
                        <Grid item xs={12}>
                            <ShowField
                                label={t("PersonalAccount.FullName")}
                                value={user.username}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.contentItem}>
                            <Button variant="contained" size="small" color="secondary" onClick={this.handleChangePassword(false)}>
                                Сменить пароль
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Ссылки</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            Ваша ссылка на продажу видеокурса "Курс массажа":
                        </Grid>
                        <Grid item xs={6}>
                            <CopyField
                                value={`https://tvoitaispa.ru/buy-lesson/?u=${user.id}`}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <p><b>Telegram бот</b></p>
                            <CopyField
                                value={"https://t.me/tvoitai_bot"}
                                fullWidth
                                label={"Ссылка"}
                            />
                            <CopyField
                                value={"@tvoitai_bot"}
                                fullWidth
                                label={"Имя"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p><b>Viber бот</b></p>
                            <CopyField
                                value={"https://tvoitaispa.ru/to_viber_bot.php"}
                                fullWidth
                                label={"Ссылка"}
                            />
                            <CopyField
                                value={"tvoitai"}
                                fullWidth
                                label={"Имя"}
                            />
                            <div style={{marginTop: 20}}>
                                <img src="/img/viber_qr.jpg" alt=""/>
                            </div>
                        </Grid>
                    </Grid>
                </Content>
                <Dialog
                    open={ dialogChangePassword }
                    aria-labelledby="sale-dialog-title"
                    aria-describedby="sale-dialog-description"
                >
                    <DialogTitle id="renewal-dialog-title">{t("Password сhange")}</DialogTitle>
                    <DialogContent>
                        <AnyField
                            label={t("Old password")}
                            value={oldPassword}
                            onChange={this.change('oldPassword')}
                            type="password"
                            fullWidth
                        />
                        <AnyField
                            label={t("New password")}
                            value={newPassword}
                            onChange={this.change('newPassword')}
                            type="password"
                            error={newPassword && verificationNewPassword && newPassword !== verificationNewPassword}
                            helperText={newPassword && newPassword.length < 4 ? 'Минимальная длина пароля 4 символа' : null}
                            fullWidth
                        />
                        <AnyField
                            label={t("Confirm new password")}
                            value={verificationNewPassword}
                            onChange={this.change('verificationNewPassword')}
                            type="password"
                            error={newPassword && verificationNewPassword && newPassword !== verificationNewPassword}
                            helperText={verificationNewPassword && newPassword !== verificationNewPassword ? 'Введенные пароли не совпадают' : null}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleChangePassword(true)}
                            disabled={ this.checkDisableSubmit() }
                            color="primary">
                            OK
                        </Button>
                        <Button onClick={this.handleChangePassword(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    handleChangePassword = (ok) => () => {
        if (ok) {
            const {oldPassword, newPassword} = this.state;
            const {t} = this.props;

            changePassword(oldPassword, newPassword)
                .then(response => {
                    if (response.success) {
                        if (response.data.result) {
                            this.props.showInfo(t('Password is changed'))
                        }

                        this.setState({
                            dialogChangePassword: false,
                            newPassword: null,
                            oldPassword: null,
                            verificationNewPassword: null,
                        })
                    } else {
                        this.props.showError(response.error ? t(response.error.message) : t(response.message));
                    }
                })
        } else {
            this.setState({
                dialogChangePassword: !this.state.dialogChangePassword,
                newPassword: null,
                oldPassword: null,
                verificationNewPassword: null,
            })
        }
    }

    change = (prop) => (event) => {
        this.setState( {
            [prop]: event.target.value
        })
    }

    checkDisableSubmit = () => {
        const {oldPassword, newPassword, verificationNewPassword} = this.state;

        return !oldPassword || !newPassword || newPassword.length < 4 || !verificationNewPassword || (newPassword && verificationNewPassword && newPassword !== verificationNewPassword)
    }
}

export default ProfilePage;