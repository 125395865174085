import React from 'react';
import {withTranslation} from "react-i18next";
import {
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
} from '@material-ui/core';
import AnyField from "../../components/fields/AnyField";

export default
@withTranslation()
class NominalBalance extends React.PureComponent
{   
    handleChange = e => {
        const { props } = this;
        props.onChange(e);
    }

    handleChangeIsBalance = e => {
        const { props } = this;

        props.onChange({target: {name: e.target.name, value: e.target.checked}});
    }

    render() {
        const { props } = this; 

        return (
            <FormControl fullWidth={true}>
                <AnyField
                    label={props.t('List of nominals separated by the symbol ;')}
                    value={props?.value}
                    name={props?.name}
                    required
                    onChange={this.handleChange}
                    fullWidth
                />                   
                
                <Typography color="error">{props.t('Attention! The nominals of all PD')}</Typography>
                
                <FormControlLabel
                    control={
                        <Checkbox checked={props?.isBalance} onChange={this.handleChangeIsBalance} name="isBalance"/>
                    }
                    label="Баланс"
                />
                
                <Typography color="error">Оставьте поле пустым если изменять баланс не нужно!</Typography>                 
            </FormControl>
        );
    }
}