import React from 'react';
import * as PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
} from '@material-ui/core';
import {formatGroupedRange, groupPayDocsList, saleBarter} from "../../services/payDocs";
import AnyField from "../../components/fields/AnyField";
import {getPartners} from "../../services/user";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import _ from 'lodash';
import moment from 'moment';
import DateField2 from "../../components/fields/DateField2";
import SelectCreateField from "../../components/fields/SelectCreateField";
import {getExternalOrganizationsWithPayDocPartner} from "../../services/organization";
import DialogButton from '../../components/button/DialogButton';
import NominalBalance from '../../components/fields/NominalBalance';
import { getArrayFromString } from '../../utils/balance';

export default
@connect(null, {showMessage: messageDialogActions.show})
@withTranslation()
class PayDocSellBarterDialog extends React.PureComponent
{
    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        payDocs: PropTypes.array,
    };

    static defaultProps = {
        open: false,
    };

    state = {
        partnerId: null,
        externalSubjectId: null,
        nominal: '100;200;300',
        comment: null,
        partners: [],
        validity: null,
        payDocType: {},
        ranges: [],
        externalSubjects: [],
        isBalance: false
    };

    handleCloseCancel = () => {
        if (this.props.onClose) {
            this.props.onClose(false);
        }
    }

    handleCloseSuccess = () => {
        const { partnerId, nominal, comment, validity, externalSubjectId, isBalance } = this.state;
        const { payDocs } = this.props;

        saleBarter({
            partnerId,
            nominal: getArrayFromString(nominal),
            comment,
            payDocIds: payDocs.map(payDoc => payDoc.id),
            validity,
            externalSubjectId,
            isBalance,
        }).then(response => {
            if (response.success) {
                if (this.props.onClose) {
                    this.props.onClose(true);
                }
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        })
    };

    loadData() {
        getPartners()
            .then(response => {
                if (response.success) {
                    this.setState({
                        partners: response.data,
                        partnerId: response.data && response.data.length === 1 ?
                            response.data[0].id : this.state.partnerId,
                    });
                }
            })

            this.loadExternalSubjects(null);
    }

    loadExternalSubjects = id => {
        getExternalOrganizationsWithPayDocPartner()
            .then(res => {
                if (res.success) {
                    this.setState({
                        externalSubjects: res.data.map(es => ({value: es.id, label: es.name})),
                    }, () => {
                        if (id) {
                            this.setState({
                                ...this.state,
                                externalSubjectId: id
                            })
                        }
                    })
                }
            });
    }

    updatePayDocsData() {
        const payDocType = this.props.payDocs && this.props.payDocs.length ?
            this.props.payDocs[0].type : {};
        this.setState({
            payDocType,
            ranges: groupPayDocsList(this.props.payDocs),
            validity: moment().add(payDocType.validityMonth || 6, 'month'),
        });
    }

    componentDidMount() {
        if (this.props.open) {
            this.loadData();
            this.updatePayDocsData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && this.props.open !== prevProps.open) {
            this.loadData();
        }

        if (!_.isEqual(this.props.payDocs, prevProps.payDocs)) {
            this.updatePayDocsData();
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleChangeNominal = e => {
        this.handleChange(e);
    }

    render() {
        const { open, t } = this.props;
        const { partnerId, nominal, comment, validity, ranges, externalSubjectId, externalSubjects } = this.state;
        const { state } = this;

        if (!open) {
            return null;
        }   

        return <Dialog open={open} onClose={this.handleCloseCancel} maxWidth="md">
            <DialogTitle>{t('Sale barter payment documents')}</DialogTitle>
            <DialogContent style={{minWidth: 600}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AnyField
                            label={t('Selected PD')}
                            multiline
                            rows={3}
                            readOnly
                            fullWidth
                            value={ranges.map(range => formatGroupedRange(range)).join(', ')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectCreateField 
                            name="externalSubjectId"
                            onChange={this.handleChange}
                            updateOptions={(id) => this.loadExternalSubjects(id)}
                            value={externalSubjectId}
                            options={externalSubjects}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <NominalBalance 
                            name="nominal"
                            value={state?.nominal}
                            isBalance={state?.isBalance}
                            onChange={this.handleChangeNominal}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DateField2
                            label={t("payDoc.validity")}
                            value={validity}
                            fullWidth
                            name="validity"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AnyField
                            label={t("Comment")}
                            value={comment}
                            fullWidth
                            name="comment"
                            onChange={this.handleChange}
                            multiline
                            rows={2}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <DialogButton fullWidth={false} onClick={this.handleCloseSuccess} disabled={!partnerId || !nominal}>OK</DialogButton>
                <DialogButton fullWidth={false} onClick={this.handleCloseCancel}>{t('Cancel')}</DialogButton>
            </DialogActions>
        </Dialog>
    }
}
