import React from 'react';
import { connect } from "react-redux";
import infoActions from "../../components/info/info-actions";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    emptyOrder,
    getOrder, getOrderHistoryStatus,
    navLinks, processOrderAfterGet
} from "../../services/goods";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import DataTable from "../../components/data-table/DataTable";
import OrderLeftFields from "./OrderLeftFields";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";

const columnData = [
    { id: 'dt', numeric: false, disablePadding: false, label: 'Дата и время', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'oldStatus', numeric: false, disablePadding: false, label: 'Старый статус' },
    { id: 'newStatus', numeric: false, disablePadding: false, label: 'Новый статус' },
    { id: 'user', numeric: false, disablePadding: false, label: 'Пользователь' },
    { id: 'comment', label: 'Комментарий' },
];

@withRouter
class HistoryOrderStatusPage extends React.Component
{
    state = {
        id: null,
        order: emptyOrder,
        refreshTable: false,
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            if (resultId[0] !== "0") {
                getOrder(resultId[0])
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                order: processOrderAfterGet(response.data),
                            })
                        }
                    });
            }
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/orders/view/${this.state.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getOrderHistoryStatus(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { refreshTable, order } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <OrderLeftFields order={order}/>
                    <br/><br/>
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>

                <Content title="История изменения статусов заказа">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        pagination={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}


export default connect(null, {showInfo: infoActions.show})(HistoryOrderStatusPage);
