import React from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem
} from "@material-ui/core";
import GuestField from "../../components/fields/GuestField";
import AnyField from "../../components/fields/AnyField";
import Pay from "../../components/pay/Pay";
import {getDiscounts} from "../../services/calendar";
import {
    recharge,
    completeRecharge,
    removeRecharge,
    update,
    payDocStatuses,
    findUnclosedRefillByPayDocId
} from "../../services/payDocs";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import _ from 'lodash';
import NumberFormatDecimal3 from "../../components/fields/NumberFormatDecimal3";
import {getParlours} from "../../services/organization";
import {getPaymentUnit, getSale, paymentStatus, salePayTypes} from "../../services/sale";
import {getWorkShiftCurrentBusinessUnit} from "../../services/calendarEmployee";
import {kkmService} from "../../services/kkm";
import {hasRole} from "../../services/user";

const emptyRechargeData = {
    amount: 0,
    saleId: null,
    guestId: null,
    id: null,
    payDocId: null,
    businessUnitId: null,
    visitBusinessUnitId: null,
    discountId: null,
    rechargeBySite: false
};


export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}),{
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showMessage: messageDialogActions.show,
})
@withTranslation()
class PayDocRefillDialog extends React.Component
{
    static propTypes = {
        open: PropTypes.bool,
        payDoc: PropTypes.object,
        onClose: PropTypes.func,
        rechargeBySite: PropTypes.bool,
    };

    static defaultProps = {
        open: false,
        payDoc: null,
        rechargeBySite: false
    };

    state = {
        rechargeData: emptyRechargeData,
        discounts: [],
        updating: false,
        refreshSale: false,
        fixedDiscount: false,
        parlours: [],
        paymentsNotPaid: false,
        currentBusinessUnitId: null,
        rechargeError: null,
        blockCancel: false,
        found: false,
        canPrintReceipt: false,
        siteBusinessUnitId: null,
        isVisitingSalon: false
    };

    componentDidMount() {
        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });

        getWorkShiftCurrentBusinessUnit()
            .then(response => {
                if (response.success) {
                    this.setState(state => ({
                        currentBusinessUnitId: response.data.id,
                        rechargeData: {
                            ...state.rechargeData,
                            businessUnitId: response.data.id ? response.data.id : state.rechargeData.businessUnitId,
                            visitBusinessUnitId: response.data.id ? response.data.id : state.rechargeData.visitBusinessUnitId,
                        }
                    }), this.updateData);
                }
            });
    }
    
    loadPaymentUnit() {
        const { siteBusinessUnitId } = this.state;

        if (siteBusinessUnitId !== null) {
            console.log('Already loaded');
            return;
        }

        const { payDoc } = this.props;

        const siteRequestBusinessUnitId = payDoc.businessUnitId;

        if (siteRequestBusinessUnitId == null) {
            // Ожидание загрузки остальных данных
            return;
        }

        getPaymentUnit(siteRequestBusinessUnitId)
            .then(response => {
                if (response.success) {
                    this.setState(state => ({
                        siteBusinessUnitId: response.data.id,
                    }))
                }
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.loadPaymentUnit();

        if (nextProps.open && !this.props.open) {
            const { payDoc } = this.props;
            const { currentBusinessUnitId } = this.state;
            let fixedDiscount = false;
            const rechargeData = {
                ...emptyRechargeData,
                payDocId: payDoc.id,
                guestId: payDoc.guestId,
                businessUnitId: currentBusinessUnitId || payDoc.businessUnitId,
                visitBusinessUnitId: currentBusinessUnitId || payDoc.businessUnitId
            };

            if (payDoc.type.fixedRefillDiscount) {
                rechargeData.discountId = payDoc.type.fixedRefillDiscount;
                fixedDiscount = true;
            }

            if (payDoc.type.refillLimits && payDoc.type.refillLimits.min) {
                rechargeData.amount = payDoc.type.refillLimits.min;
            }

            rechargeData.rechargeBySite = nextProps.rechargeBySite;
            if (nextProps.rechargeBySite) {
                rechargeData.businessUnitId = this.state.siteBusinessUnitId;
            }

            this.setState({
                fixedDiscount,
                rechargeData,
            });

            findUnclosedRefillByPayDocId(payDoc.id, nextProps.rechargeBySite)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            rechargeData: response.data,
                            found: true
                        }, this.updateData);
                    } else {
                        this.updateData();
                    }
                });       
        }

        if (nextProps.deliveryPayDoc && Object.keys(nextProps.deliveryPayDoc).length !== 0) {
            this.setState({
                rechargeData: {
                    amount: nextProps.deliveryPayDoc.amount,
                    guestId: nextProps.deliveryPayDoc.guestId,
                    saleId: nextProps.deliveryPayDoc.saleId,
                    discountId: nextProps.deliveryPayDoc.discountId,
                    businessUnitId: nextProps.deliveryPayDoc.businessUnitId,
                    payDocId: nextProps.deliveryPayDoc.payDocId,
                    id: nextProps.deliveryPayDoc.payDocRefill
                }
            });
        }
    }

    updateData = () => {
        if (
            !this.state.rechargeData.businessUnitId ||
            !this.state.rechargeData.guestId ||
            this.state.rechargeData.amount === undefined ||
            this.wrongAmount()
        ) {
            return;
        }
        getDiscounts({
            forParlour: this.state.rechargeData.businessUnitId,
            forSalableEntity: 'PayDocRefill'
        })
            .then(response => {
                if (response.success) {

                    let newState = {
                        discounts:response.data,
                    };

                    let discount = response.data.find(discount => discount.defaultSelected === true);

                    if (!this.state.fixedDiscount && !this.state.rechargeData.discountId && discount ) {
                        newState.rechargeData = {
                            ...this.state.rechargeData,
                            discountId: discount ? discount.id : null
                        };
                    }
                    this.setState( newState );
                }
            });

        if (!this.state.found) {

            this.setState({
                updating: true,
                rechargeError: null
            });

            recharge(this.state.rechargeData)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            rechargeData: response.data,
                            refreshSale: !this.state.refreshSale,
                            updating: false,
                        });
                    } else {
                        this.setState({
                            updating: false,
                            rechargeError: response.error ? response.error.message : response.message
                        });
                        this.props.showError(response.error ? response.error.message : response.message);
                    }


                });
        }

        this.setState({
            found: false
        });
    };

    updateDataDebounce = _.debounce(this.updateData, 500);

    handleChange = prop => event => {
        this.setState({
            rechargeData: {
                ...this.state.rechargeData,
                [prop]: event.target.value,
            },
        }, this.updateDataDebounce);
    };

    handleChangeBusinessUnit = parlour => {
        this.setState({
            rechargeData: {
                ...this.state.rechargeData,
                businessUnitId: parlour.target.value,
            },
        });
    };

    handleClose = ok => event => {
        if (this.state.blockCancel && !ok && this.props.payDoc.statusId === payDocStatuses.delivery_guest) {
            return;
        }

        const { onClose, t, payDoc } = this.props;

        if (this.state.updating) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        if (ok === 'close') {
            onClose && onClose(false)
            return;
        }

        if (!ok) {
            if (payDoc.statusId === payDocStatuses.delivery_guest) {
                onClose && onClose(ok)
                return;
            }

            if (this.state.rechargeData.id) {
                this.setState({
                    updating: true,
                });
                removeRecharge(this.state.rechargeData.id)
                    .then(response => {
                        this.setState({
                            updating: false,
                        });

                        if (response.success) {
                            onClose && onClose(ok);
                        } else {
                            this.props.showMessage(response.error ? response.error.message : response.message);
                        }
                    })
            } else {
                onClose && onClose(ok);
            }

            event.preventDefault();
            event.stopPropagation();
            return;
        }


        const doCompleteRecharge = () => {
            completeRecharge(this.state.rechargeData)
                .then(response => {
                    this.setState({
                        updating: false,
                    });

                    if (response.success) {
                        this.props.showInfo(t('Payment document refilled'));
                        onClose && onClose(ok);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }

        this.setState({
            updating: true,
        });
        if (payDoc.statusId === payDocStatuses.delivery_guest) {
            update(payDoc.id, {status: payDocStatuses.sold})
                .then(response => {
                    if (response.success) {
                        doCompleteRecharge();
                    } else {
                        this.setState({
                            updating: false,
                        });
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        } else {
            doCompleteRecharge();
        }


    };

    wrongAmount = () => {
        const { payDoc } = this.props;
        const { rechargeData } = this.state;
        let errorAmount = false;

        if (payDoc.type.refillLimits) {
            if (payDoc.type.refillLimits.min || payDoc.type.refillLimits.min === 0) {
                errorAmount = errorAmount || (parseFloat(rechargeData.amount) < payDoc.type.refillLimits.min);
            }

            if (payDoc.type.refillLimits.max || payDoc.type.refillLimits.max === 0) {
                errorAmount = errorAmount || (parseFloat(rechargeData.amount) < payDoc.type.refillLimits.max);
            }

            if (payDoc.type.refillLimits.multiple) {
                errorAmount = errorAmount || (parseFloat(rechargeData.amount) % payDoc.type.refillLimits.multiple !== 0)
            }
        }

        return errorAmount;
    };

    handlePayChange = (payments) => {
        const { rechargeData } = this.state
        let summ;

        if (payments.length > 0) {
            summ = payments.reduce(function (sumAmount, payment) {
                if (payment.status === 'paid')
                    return payment.amount.val + sumAmount;
                else
                    return sumAmount;
            }, 0);
        }

        if (summ === rechargeData.total.val && summ > 0)
            this.setState({
                canPrintReceipt: true
            });

        this.setState({
            paymentsNotPaid: payments.filter(payment => payment.status !== paymentStatus.paid).length > 0,
        });
    };

    getAmountErrorText = () => {
        const { t, payDoc } = this.props;
        const { rechargeError } = this.state;

        const txt = [];
        if (payDoc.type && payDoc.type.refillLimits && payDoc.type.refillLimits.min) {
            txt.push(t('payDoc.refillMin', {value: payDoc.type.refillLimits.min}))
        }
        if (rechargeError) {
            txt.push(rechargeError);
        }

        return txt.join(' ');
    }

    handleBlockCancel = (state) => {
        this.setState({
            blockCancel: state
        })
    }

    handlePrintReceipt = () => {
        const { payDoc, t } = this.props;
        const { rechargeData } = this.state;

        let saleId = rechargeData.saleId;
        let cash = 0;
        let cashless = 0;

        // hide button for avoiding double click
        this.setState({ canPrintReceipt: false },
            () => {
                getSale(saleId)
                    .then(response => {

                        if (response.success) {
                            response.data.payments.forEach((payment,) => {
                                if (payment.status === 'paid') {
                                    if (payment.type === "cashless")
                                        cashless += payment.amount.val;
                                    if (payment.type === "cash")
                                        cash += payment.amount.val;
                                }
                            });

                            let username = rechargeData.creatorName;
                            let item = {};
                            item.title = (payDoc.receiptName) ? payDoc.receiptName : t('Refill pay document №') + payDoc.num;

                            item.cost = response.data.total.val;

                            kkmService.registerCheck(username, cash, cashless, [item]).then(() => {
                                this.props.showInfo(t('Receipt printed'));
                            })
                        }
                    })
            }
        );
    }

    render() {
        const { open, t, payDoc } = this.props;
        const {
            rechargeData, discounts, updating, refreshSale, fixedDiscount, parlours, paymentsNotPaid,
            rechargeError, blockCancel, canPrintReceipt
        } = this.state;

        const errorAmount = this.wrongAmount();
        const deliveryGuest = payDoc.statusId === payDocStatuses.delivery_guest;

        let title = t('payDoc.refillTitle');
        if (deliveryGuest) {
            title = t('Delivery completion');
        }
        if (rechargeData.rechargeBySite) {
            title = t('Refill by site');
        }

        return <Dialog
            open={open}
            onClose={this.handleClose('close')}
            aria-labelledby="recharge-dialog-title"
            aria-describedby="recharge-dialog-description"
            maxWidth="md"
            disableBackdropClick={updating}
            disableEscapeKeyDown={updating}
        >
            <DialogTitle id="recharge-dialog-title">{ title }</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AnyField
                            label={t('payDoc.refillBusinessUnit')}
                            value={rechargeData.businessUnitId}
                            onChange={this.handleChangeBusinessUnit}
                            select
                            fullWidth
                            readOnly={
                                !hasRole('ROLE_PAYDOC_REFILL_CHANGE_BUSINESS_UNIT') ||
                                deliveryGuest ||
                                rechargeData.rechargeBySite
                            }
                        >
                            {parlours.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </AnyField>
                    </Grid>
                    <Grid item xs={6}>
                        <GuestField
                            label={t("Guest")}
                            value={rechargeData.guestId}
                            error={!rechargeData.guestId}
                            onChange={this.handleChange('guestId')}
                            required
                            readOnly={updating || deliveryGuest}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AnyField
                            label={(payDoc.type.measure === 'money' ? t('payDoc.amountMoney') : t('payDoc.amountTime'))}
                            value={rechargeData.amount}
                            required
                            error={!rechargeData.amount || errorAmount || Boolean(rechargeError)}
                            onChange={this.handleChange('amount')}
                            fullWidth
                            autoFocus
                            InputProps={{
                                inputComponent: NumberFormatDecimal3,
                            }}
                            readOnly={updating || !rechargeData.guestId || deliveryGuest}
                            onFocus={event => event.target.select()}
                            helperText={this.getAmountErrorText()}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AnyField
                            label={t('payDoc.discount')}
                            value={rechargeData.discountId}
                            fullWidth
                            select
                            onChange={this.handleChange('discountId')}
                            readOnly={fixedDiscount || updating || !rechargeData.guestId || deliveryGuest}
                        >
                            <MenuItem value={null}>{t('Not chosen')}</MenuItem>
                            {discounts.map(discount => <MenuItem key={discount.id} value={discount.id}>{discount.name}</MenuItem>)};
                        </AnyField>
                    </Grid>
                    {
                        payDoc.type.visitingSalon &&
                        <Grid item xs={6}>
                            <AnyField
                                label={t('payDoc.visitBusinessUnit')}
                                value={rechargeData.visitBusinessUnitId}
                                onChange={this.handleChange('visitBusinessUnitId')}
                                select
                                fullWidth
                                readOnly={
                                    !hasRole('ROLE_PAYDOC_REFILL_CHANGE_BUSINESS_UNIT') ||
                                    deliveryGuest
                                }
                            >
                                {parlours.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid> 
                    }
                    <Grid item xs={12}>
                        <Pay
                            saleId={rechargeData.saleId}
                            availablePaymentTypes={[salePayTypes.cash, salePayTypes.cashless]}
                            refreshSale={refreshSale}
                            disabled={updating || !rechargeData.guestId || errorAmount }
                            onChange={this.handlePayChange}
                            paymentType={this.props.deliveryPayDoc ? this.props.deliveryPayDoc.paymentType : null}
                            possibilityPayment={deliveryGuest}
                            blockCancel={this.handleBlockCancel}
                            disablePaymentLinks={!rechargeData.rechargeBySite}
                            onlyPaymentLinks={rechargeData.rechargeBySite}
                            customBonusPayDocGuest
                            enableCheckingAccountPaymentType={!rechargeData.rechargeBySite}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary"
                    disabled={ !canPrintReceipt }
                    onClick={this.handlePrintReceipt}
                >{t("Print Receipt")}</Button>
                <Button
                    onClick={this.handleClose(true)}
                    disabled={updating || !rechargeData.amount || !rechargeData.guestId || errorAmount || paymentsNotPaid || Boolean(rechargeError)}
                    color="primary"
                >{deliveryGuest ? t('Complete delivery') : t('payDoc.confirmRefill')}</Button>
                <Button
                    onClick={this.handleClose(false)} disabled={updating || (blockCancel && deliveryGuest) } color="primary">{t("Delete")}</Button>
                <Button
                    onClick={this.handleClose('close')} color="primary">{t("Close")}</Button>
            </DialogActions>
        </Dialog>;
    }
}