import {dataTableGroupValueStrategy} from "../../../../../services/common";

const otherSupply = (currentVal, row) => {
    return {
        clubTT: dataTableGroupValueStrategy.arrayMoneySum('clubTT')(
            currentVal ? currentVal.clubTT : null,
            row.otherSupply
        ),
        lumpSum: dataTableGroupValueStrategy.arrayMoneySum('lumpSum')(
            currentVal ? currentVal.lumpSum : null,
            row.otherSupply
        ),
        consulting: dataTableGroupValueStrategy.arrayMoneySum('consulting')(
            currentVal ? currentVal.consulting : null,
            row.otherSupply
        ),
        subscriptionFee: dataTableGroupValueStrategy.arrayMoneySum('subscriptionFee')(
            currentVal ? currentVal.subscriptionFee : null,
            row.otherSupply
        ),
        otherSupply: dataTableGroupValueStrategy.arrayMoneySum('otherSupply')(
            currentVal ? currentVal.otherSupply : null,
            row.otherSupply
        ),
    };
};

export default otherSupply;