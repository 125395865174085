import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks, TransactionsToGoogleSheets} from "../../services/settings";
import Content from "../../components/content/Content";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import DatePeriodField from "../../components/fields/DatePeriodField";
import ActionButton from "../../components/button/ActionButton";
import {Grid} from "@material-ui/core";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";

const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
});

export default @connect(state => ({}), {
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class UploadPeriodSettingsPage extends React.Component {
    state = {
        dialogEditOpen: false,
        Unload: {
            from: null,
            to: null,
            linkToGoogle: 'https://docs.google.com/spreadsheets/d/1KMd1FnyNA3ES56rsajxvpiUhN5s6h6s6fhpCJtIzx-U/edit#gid=0'
        }
    };


    getValueOrTime(date) {
        let value;
        if (date instanceof moment) {
            value = date.format("YYYY-MM-DD");
        } else
            value = date.target.value;

        return value;

    }

    handleChangeUnload = (prop) => event => {


        let value = this.getValueOrTime(event)

        this.setState({
            Unload: {
                ...this.state.Unload,
                [prop]: value,
            }
        });
    };

    handleUnload = () => {


        const {Unload} = this.state;

        TransactionsToGoogleSheets(Unload)
            .then(response => {
                if (response.success)
                    this.props.showMessage('Выгружено');
                else
                    this.props.showMessage(response.error ? response.error.message : response.message);

            });

    }

    render() {
        const {Unload} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    Nothing
                </LeftBar>
                <Content title="Принудительная выгрузка ДДС">
                        <Grid item xs={4}>
                            <DatePeriodField
                                valueFrom={Unload.from}
                                valueTo={Unload.to}
                                onChangeFrom={this.handleChangeUnload('from')}
                                onChangeTo={this.handleChangeUnload('to')}
                                label={t("Unloading period")}
                            />
                        </Grid>
                        <Grid item xs={8}>

                            <AnyField
                                label="Ссылка на гугл таблицу"
                                value={Unload.linkToGoogle}
                                onChange={this.handleChangeUnload('linkToGoogle')}
                                required
                                error={!Unload.linkToGoogle}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                    <Grid item xs={4}>
                        <ActionButton onClick={this.handleUnload}>Выгрузить</ActionButton>
                    </Grid>
                </Content>
            </React.Fragment>

        )
    }
}
