import React from "react";
import * as PropTypes from "prop-types";
import {withStyles} from '@material-ui/core';
import {DragSource} from "react-dnd";

export default
@DragSource(
    'SCHEDULE_EVENT',
    {
        beginDrag(props) {
            if (props.onBeginDrag) {
                props.onBeginDrag(props.event, props.ids);
            }

            return props
        },

        endDrag(props, monitor, component) {
            if (!monitor.didDrop()) {
                return;
            }
            const item = monitor.getItem();
            const dropResult = monitor.getDropResult();

            if (props.onEndDrag) {
                props.onEndDrag(item.event, dropResult.slot, dropResult.column);
            }
        },

        canDrag(props) {
            return props.canDrag;
        },
    },
    (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        }
    }
)
@withStyles({
    event: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 4,
        // transition: 'transform 0.3s linear',

        '&:hover': {
            zIndex: 5,
        },
    },
})
class DraggableScheduleEvent extends React.PureComponent
{
    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        left: PropTypes.number,
        top: PropTypes.number,
        children: PropTypes.element.isRequired,
        event: PropTypes.any,
        ids: PropTypes.any,
        canDrag:  PropTypes.bool,
        elementStyle: PropTypes.object,
        disabledDragColumns: PropTypes.array,
        disabledDragSlots: PropTypes.array,
        onBeginDrag: PropTypes.func,
        onEndDrag: PropTypes.func,
    }

    static defaultProps = {
        left: 0,
        top: 0,
        canDrag: false,
        disabledDragColumns: [],
        disabledDragSlots: [],
    }

    componentDidMount() {
        const img = new Image();
        img.src = '/img/drag-preview.png';
        img.onload = () => this.props.connectDragPreview(img);
    }

    render() {
        const { classes, children, width, height, left, top, isDragging, connectDragSource, elementStyle } = this.props;
        const transform = `translate3d(${left}px, ${top}px, 0)`;

        return connectDragSource(
            <div
                className={classes.event}
                style={{
                    ...elementStyle,
                    width,
                    zIndex: isDragging ? 1 : elementStyle.zIndex,
                    borderStyle: isDragging ? 'dotted' : elementStyle.borderStyle,
                    borderWidth: isDragging ? 1 : elementStyle.borderWidth,
                    height,
                    transform,
                }}
            >{children}</div>
        )
    }
}
