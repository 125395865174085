import baseRequest from './baseReuqest';
import getStore from "../store";

export function uploadFile(file) {
    const data = new FormData();
    data.append('file', file);

    return baseRequest('/api/storage/file',{
        method: 'POST',
        body: data
    }, false);
}

export function getDownloadLink(id) {

    const token = getStore().getState().auth.token;
    const accessToken =  token ? token.access_token : null;

    return `/api/storage/file/${id}?access_token=${accessToken}`;
}