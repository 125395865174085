import React from 'react';
import {Grid} from '@material-ui/core';
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {navLinks, getPayDocPromoList} from "../../services/payDocs";
import HLine from "../../components/left-bar/HLine";
import CheckboxField from "../../components/fields/CheckboxField";
import moment from "moment/moment";
import DateField from "../../components/fields/DateField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getRegions} from "../../services/organization";

const columnData = [
    { id: 'name', label: 'Название' },
    { id: 'description', label: 'Описание' },
    { id: 'type.code', label: 'Тип', linkTemplate: payDocPromo => `/pay-docs/types/${payDocPromo.type.id}` },
    { id: 'discountValue', label: 'Скидка' },
    { id: 'comment', label: 'Комментарий' },
    { id: 'dateStart', label: 'Дата начала', dateFormat: "DD.MM.YYYY HH:mm" },
    { id: 'dateEnd', label: 'Дата окончания', dateFormat: "DD.MM.YYYY HH:mm" },
    { id: 'isActive', label: 'Активна' },
];

class PayDocPromoListPage extends React.Component {

    state = {
        search: {
            name: null,
            dateStartFrom: null,
            dateStartTo: null,
            dateEndFrom: null,
            dateEndTo: null,
            active: false,
            regions: null
        },
        refreshTable: false,
        regions: [],
        chosenRegions: []
    };

    componentDidMount() {
        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    })
                }
            });
    };

    handleChange = event => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value,
            }
        });
    };

    handleChangeByName = prop => event => {
        let value = null;
        if (prop === 'regions') {
            this.setState({
                chosenRegions: event.target.value
            });
        }
        else if (event instanceof moment) value = event;
        else if (event != null) value = moment(event.target.value);

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/pay-docs/promo/0`);
    };

    getTableData = (...params) => {
        let {chosenRegions} = this.state;
        let search = Object.assign({}, this.state.search);
        if (search.dateStartFrom) search.dateStartFrom = search.dateStartFrom.format('DD.MM.YYYY');
        if (search.dateStartTo) search.dateStartTo = search.dateStartTo.endOf('day').format('DD.MM.YYYY HH:mm:ss');
        if (search.dateEndFrom) search.dateEndFrom = search.dateEndFrom.format('DD.MM.YYYY');
        if (search.dateEndTo) search.dateEndTo = search.dateEndTo.endOf('day').format('DD.MM.YYYY HH:mm:ss');
        if (chosenRegions.length > 0) search.regions = chosenRegions.join(',');
        return getPayDocPromoList(search, ...params);
    };

    editLink = item => `/pay-docs/promo/${item.id}`

    render() {
        const { search, refreshTable, regions, chosenRegions } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.name}
                        name="name"
                        onChange={this.handleChange}
                        onClick={this.handleClickSearch}
                        label="Поиск по названию"
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{paddingBottom: 0, paddingTop: 20}}>Дата начала</Grid>
                        <Grid item xs={6}>
                            <DateField
                                label="От"
                                value={search.dateStartFrom ? moment(search.dateStartFrom) : null}
                                onChange={this.handleChangeByName('dateStartFrom')}
                                name="dateStartFrom"
                                fullWidth
                                invalidDateMessage={''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateField
                                label="До"
                                value={search.dateStartTo ? moment(search.dateStartTo) : null}
                                onChange={this.handleChangeByName('dateStartTo')}
                                name="dateStartTo"
                                fullWidth
                                invalidDateMessage={''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{paddingBottom: 0, paddingTop: 15}}>Дата окончания</Grid>
                        <Grid item xs={6}>
                            <DateField
                                label="От"
                                value={search.dateEndFrom ? moment(search.dateEndFrom) : null}
                                onChange={this.handleChangeByName('dateEndFrom')}
                                name="dateEndFrom"
                                fullWidth
                                invalidDateMessage={''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateField
                                label="До"
                                value={search.dateEndTo ? moment(search.dateEndTo) : null}
                                onChange={this.handleChangeByName('dateEndTo')}
                                name="dateEndTo"
                                fullWidth
                                invalidDateMessage={''}
                            />
                        </Grid>
                    </Grid>
                    <MultiSelectFromItemsField
                        items={regions}
                        value={chosenRegions}
                        name={`regions`}
                        onChange={this.handleChangeByName('regions')}
                        label="Разрешить для регионов"
                        fullWidth
                        emptySelectedLabel="Все"
                    />
                    <CheckboxField
                        label="Отображать активные"
                        value={search.active}
                        name="active"
                        onChange={this.handleChange}
                        margin="none"
                    />
                    <ActionButton onClick={this.handleClickSearch}>Поиск</ActionButton>
                    <HLine/>
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Акции">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={this.editLink}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default PayDocPromoListPage;