import React from 'react';
import { connect } from "react-redux";
import infoActions from "../../components/info/info-actions";
import {
    CircularProgress,
    withStyles,
    Grid, TextField
} from "@material-ui/core";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    cancelGoodStorageSale, changeDateGoodStorageSale,
    completeGoodStorageSale,
    createOrUpdateGoodStorageSale,
    emptySale, getGoodStorages,
    getGoodStorageSale, goodStorageStatuses,
    navLinks,
    processSale, reopenGoodStorageSale, saleStatuses
} from "../../services/goods";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import GuestField from "../../components/fields/GuestField";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import _ from 'lodash';
import ShowField from "../../components/fields/ShowField";
import {formatMoney} from "../../services/common";
import GoodStorageField from "../../components/fields/GoodStorageField";
import {getParlours} from "../../services/organization";
import Pay from "../../components/pay/Pay";
import {withRouter} from "react-router";
import {getWorkShiftCurrentBusinessUnit} from "../../services/calendarEmployee";
import {getDiscounts} from "../../services/calendar";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {calculateSale, patchSale} from "../../services/sale";
import {hasRole} from "../../services/user";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DateField2 from "../../components/fields/DateField2";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {kkmService} from "../../services/kkm";
import {getGuest, verifyGuestName} from "../../services/guests";
import GuestNameVerificationDialog from "../../components/dialogs/GuestNameVerificationDialog";
import {
    getGeneralProperty
} from "../../services/settings";
import AnyField from "../../components/fields/AnyField";

const columnData = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товаров' },
    { id: 'soldPriceFormat', label: 'Цена товара' },
    { id: 'expiration', label: 'Срок годности', dateFormat: "DD.MM.YYYY" },
    { id: 'statusText', label: 'Статус' },
    { id: 'num', label: 'Номер экземпляра товара', maxWidth: 250, wordBreak: true },
    { id: 'count', label: 'Кол-во', maxWidth: 15 },
];

export default
@withTranslation()
@withRouter
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
    showError: infoActions.showError,
})
@withStyles(theme => ({
    container: {
        marginTop:  theme.spacing(2),
        minWidth: 600,
    },
    flexGrow: {
        flexGrow: 1,
    },
}))
class SellViewPage extends React.Component
{
    state = {
        sale: {...emptySale},
        loadedSale: {...emptySale},
        newStorageGoodId: null,
        newStorageGood: null,
        parlours: [],
        amountRest: null,
        currentBusinessUnitId: null,
        discounts: [],
        calculating: false,
        refreshSale: false,
        changeDateDialogOpen: false,
        changeDate: null,
        canPrintReceipt: false,
        receiptData: {
            username: '',
            cash: 0,
            cashless: 0,
            items: {},
            itemsType: 1
        },
        isPrintReceiptModalOpen: false,
        nameVerifyingData: {
            dialogOpen: false,
            guestName: '',
            guestLastName: '',
            gender: ''
        },
        settings: {},
        count: 1,
        goodStorageItems: []
    };

    componentDidMount() {
        if (this.props.currentParlour) {
            this.setState({
                sale: {
                    ...this.state.sale,
                    parlour: this.props.currentParlour.id,
                },
                loadedSale: {
                    ...this.state.loadedSale,
                    parlour: this.props.currentParlour.id,
                }
            })
        }

        const reId = new RegExp('/goods/sell/view/([^/]+)$');
        const resultId = reId.exec(this.props.location.pathname);
        if (resultId.length !== 2) {
            //Error request
            return;
        }

        let search = {'active': 1};
        if (resultId[1] !== "0") {
            getGoodStorageSale(resultId[1])
                .then(response => {
                    if (response.success) {
                        const sale = processSale(response.data);
                        this.setState({
                            sale,
                            loadedSale: {...sale},
                        }, this.updateDiscounts);
                    }
                });
            search = {};
        }

        getParlours(search)
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });

        getWorkShiftCurrentBusinessUnit()
            .then(response => {
                if (response.success) {
                    this.setState(state => ({
                        currentBusinessUnitId: response.data.id,
                        sale: {
                            ...state.sale,
                            parlour: response.data.id ? response.data.id : state.sale.parlour,
                        },
                        loadedSale: {
                            ...state.loadedSale,
                            parlour: response.data.id ? response.data.id : state.loadedSale.parlour,
                        },
                    }), this.updateDiscounts);
                }
            });

        getGeneralProperty('guestNameVerificationEnable').then(response => {
            if (response.success) {
                this.setState({
                    settings: {
                        ...this.state.settings,
                        "guestNameVerificationEnable" : response.data,
                    }
                })
            }
        })
    }

    handleBack = () => {
        this.props.history.push('/goods/sell');
    };

    handleChange = event => {
        const prop = event.target.name;

        this.setState({
            sale: {
                ...this.state.sale,
                [prop]: event.target.value,
            }
        }, () => {
            if (prop === 'parlour') {
                this.updateDiscounts();
            } else if (prop === 'discountId') {
                this.calcTotal();
            }
        })
    };

    updateDiscounts = () => {
        const { sale } = this.state;

        getDiscounts({
            forParlour: sale.parlour,
            forSalableEntity: 'GoodStorageSale'
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        discounts: response.data,
                    })
                }
            })
    };

    handleChangeStorageGood = (event, newStorageGood) => {
        this.setState({
            newStorageGoodId: event.target.value,
            newStorageGood,
        })

        if (newStorageGood?.parlour?.id && newStorageGood?.good?.name) {
            const search = {
                ...{parlour: newStorageGood?.parlour?.id, purpose: 'for_sale'},
                search: newStorageGood?.good?.name,
                statuses: [goodStorageStatuses.in_storage]};

            getGoodStorages(search, 1, 50, 'asc', 'suggestions')
                .then(response => {
                    if (response.success) {
                        this.setState(prev => ({
                            ...prev,
                            goodStorageItems: response.data
                                .filter(item => !Object.values(this.state.sale?.storageGoods ?? []).some(obj => obj?.id === item?.id)),
                            count: 1
                        }))
                    }
            });
        }

        if (!newStorageGood) {
            this.setState(prev => ({...prev, goodStorageItems: [], count: 1}));
        }
    };

    handleCloseDeleteDialog = storageGood => ok => {
        const { sale } = this.state;
        if (ok) {
            this.setState(prev => ({
                ...prev,
                sale: {
                    ...prev.sale,
                    storageGoods: Object.values(sale.storageGoods ?? []).filter(sg => sg?.good?.id !== storageGood?.good?.id)
                }
            }), () => {
                this.calculate();
            })
        }
    };

    handleDeleteStorageGood = (storageGood, idx) => {
        this.props.showConfirm({
            message: "Вы уверены, что хотите убрать товар из продажи?",
            title: "Удаление товара из продажи",
            onClose: this.handleCloseDeleteDialog(storageGood)
        });
    };

    calcTotal = () => {
        this.setState({
            calculating: true,
        });

        this.calculate();
    };

    getTotal = () => {
        const { sale } = this.state;

        return Object.values(sale.storageGoods ?? []).map(item => {
            if (item.price && item.price.val) {
                return parseFloat(item.price.val);
            }
            return 0;
        }).reduce((a, b) => a + b, 0);
    }

    calculate = () => {
        const { sale } = this.state;

        calculateSale({
            businessUnitId: sale.parlour,
            salableEntity: [
                {
                    discountId: sale.discountId,
                    price: this.getTotal(),
                    entity: 'GoodStorageSale'
                }
            ]
        }).then(response => {
            if (response.success) {
                this.setState({
                    calculating: false,
                    sale: {
                        ...sale,
                        amountTotal: response.data.total
                    },
                    count: 1
                });
            } else {
                this.setState({
                    calculating: false,
                });
                this.props.showError(response.error ? response.error.message : response.message);
            }
        });
    }

    handleAddStorageGood = () => {
        const { sale, newStorageGood, newStorageGoodId, count, goodStorageItems } = this.state;

        if (newStorageGood) {
            let newStorageGoods = [...Object.values(sale.storageGoods ?? []), newStorageGood];

            if (goodStorageItems.length === count) {
                newStorageGoods = [...Object.values(sale.storageGoods ?? []), ...goodStorageItems];
            }

            if (count > 1 && count !== goodStorageItems.length) {
                newStorageGoods = [
                    ...newStorageGoods,
                    ...goodStorageItems.filter(gsi => gsi.id !== newStorageGoodId).slice(0, count - 1)
                ]
            }
            this.setState({
                sale: {
                    ...sale,
                    storageGoods: newStorageGoods,
                },
                newStorageGoodId: null,
                newStorageGood: null,
                count: 1,
                goodStorageItems: []
            }, this.calcTotal);
        }
    };

    handleAutoAddStorageGood = newStorageGood => {
        const { sale } = this.state;

        if (newStorageGood) {
            if (Object.values(sale.storageGoods ?? []).filter(item => item.id === newStorageGood.id).length === 0) {
                const storageGoods = [...Object.values(sale.storageGoods ?? []), newStorageGood];
                this.setState({
                    sale: {
                        ...sale,
                        storageGoods,
                    },
                    newStorageGoodId: null,
                    newStorageGood: null,
                }, this.calcTotal);
            } else {
                this.setState({
                    newStorageGoodId: null,
                    newStorageGood: null,
                }, this.calcTotal);
            }
        }

    };

    handleSave = () => {
        createOrUpdateGoodStorageSale({
            ...this.state.sale,
            storageGoods: Object.values(this.state.sale.storageGoods ?? []).map(storageGood => storageGood.id),
        })
            .then(response => {
                const { showInfo, t } = this.props;
                const { settings } = this.state;

                if (response.success) {
                    const sale = processSale(response.data);
                    this.setState({
                        sale,
                        loadedSale: {...sale},
                        refreshSale: !this.state.refreshSale,
                    });

                    // ------- verifying guest name process --------
                    showInfo(t('Check guest name verifying status'));
                    getGuest(sale.guest).then((guestResponse) => {
                        if (
                            guestResponse.success &&
                            !_.isEmpty(settings) &&
                            settings.guestNameVerificationEnable
                        ) {
                            let guest = guestResponse.data;
                            if (!guest.nameVerified) {
                                this.setState({
                                    nameVerifyingData: {
                                        dialogOpen: true,
                                        guestName: guest.name,
                                        guestLastName: guest.lastName,
                                        gender: guest.gender,
                                        nameVerifyCallback: _.bind( () => { this.props.history.push(`/goods/sell/view/${response.data.id}`) }, this),
                                    }
                                });
                            } else {
                                this.props.history.push(`/goods/sell/view/${response.data.id}`);
                            }
                        } else {
                            this.props.history.push(`/goods/sell/view/${response.data.id}`);
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleComplete = () => {
        completeGoodStorageSale({
            ...this.state.sale,
            storageGoods: this.state.sale.storageGoods.map(storageGood => storageGood.id),
        })
            .then(response => {
                if (response.success) {
                    const sale = processSale(response.data);
                    this.setState({
                        sale,
                        loadedSale: {...sale},
                    });
                    this.handleBack();
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handlePayChange = (pays, amountRest) => {
        if (amountRest === 0 && pays.length > 0) {
            let [cash, cashless, items] = [0, 0, []]
            pays.forEach((pay) => {
                if (pay.status === 'paid') {
                    if (pay.type === 'cash')
                        cash += pay.amount.val;
                    if (pay.type === 'cashless')
                        cashless += pay.amount.val;
                }
            })

            let discount = 0;
            if (
                this.state.sale.discount &&
                this.state.sale.discount.type === 'percents' &&
                this.state.sale.discount.value > 0
            )
                discount = this.state.sale.discount.value;

            if (Object.values(this.state.sale.storageGoods ?? []).length > 0) {
                if (discount > 0) {
                    let [goodPrice, goodSumm] = [0, 0];
                    let goodsArrLength = Object.values(this.state.sale.storageGoods ?? []).length;

                    this.state.sale.storageGoods.forEach((good, index) => {
                        if (index < goodsArrLength - 1) {
                            goodPrice = parseInt(good.price.val * (1 - discount/100))
                            items.push({
                                title: good.good.name.slice(0, 63),
                                cost: goodPrice
                            })
                            goodSumm += goodPrice
                        } else {
                            items.push({
                                title: good.good.name.slice(0, 63),
                                cost: this.state.sale.total.val - goodSumm
                            })
                        }
                    })
                } else {
                    this.state.sale.storageGoods.forEach((good, index) => {
                        items.push({
                            title: good.good.name.slice(0, 63),
                            cost: good.price.val
                        })
                    })
                }
            }

            this.setState({
                receiptData: {
                    ...this.state.receiptData,
                    username: this.state.sale.creator.username,
                    cash: cash,
                    cashless: cashless,
                    items: items
                },
                canPrintReceipt: true
            })
        }

        this.setState({
            amountRest,
        });
    };

    handleReopen = () => {
        const { sale } = this.state;
        reopenGoodStorageSale(sale.id)
            .then(response => {
                if (response.success) {
                    const sale = processSale(response.data);
                    this.setState({
                        sale,
                        loadedSale: {...sale},
                        refreshSale: !this.state.refreshSale,
                    }, this.updateDiscounts);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    doCancelSale = ok => {
        if (!ok) {
            return;
        }

        cancelGoodStorageSale(this.state.sale.id)
            .then(response => {
                if (response.success) {
                    this.handleBack();
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleCancelSale = () => {
        this.props.showConfirm({
            message: "Вы уверены, что хотите отменить продажу?",
            title: "Отмена продажи",
            onClose: this.doCancelSale
        });
    }

    handlePrintReceipt = () => {

        const { receiptData } = this.state
        const { t } = this.props

        this.setState({
            canPrintReceipt: false
        }, () => {
                kkmService.registerCheck(
                    receiptData.username,
                    receiptData.cash,
                    receiptData.cashless,
                    receiptData.items,
                    receiptData.itemsType
                ).then(() => {
                    this.props.showInfo(t('Receipt printed'))
                    this.handleComplete()
                })
            }
        )
    }

    handlePrintRefundReceipt = () => {

        const { receiptData } = this.state
        const { t } = this.props

        kkmService.registerCheck(
            receiptData.username,
            receiptData.cash,
            receiptData.cashless,
            receiptData.items,
            receiptData.itemsType,
            true
        ).then(() => {
            this.setState({
                canPrintReceipt: false
            })
            this.props.showInfo(t('Receipt printed'))
        })
    }

    handleOpenPrintReceiptModal = () => {
        this.setState({
            isPrintReceiptModalOpen: true
        })
    }

    handlePrintReceiptModalApproved = () => {
        this.handlePrintRefundReceipt();
        this.setState({
            isPrintReceiptModalOpen: false
        })
    }

    handlePrintReceiptModalReject = () => {
        this.setState({
            isPrintReceiptModalOpen: false
        })
    }

    handleChangeVerifyGuestName = prop => event => {
        this.setState({
            nameVerifyingData: {
                ...this.state.nameVerifyingData,
                [prop]: event.target.value
            }
        })
    }

    handleVerifyGuestName = () => {
        let { nameVerifyingData, sale } = this.state;

        if (!sale.guest)
            return;

        let guest = {
            id: sale.guest,
            name: nameVerifyingData.guestName,
            lastName: nameVerifyingData.guestLastName,
            gender: nameVerifyingData.gender
        };

        verifyGuestName(guest).then(response => {
            if (response.success) {
                this.setState({
                    nameVerifyingData: {
                        ...nameVerifyingData,
                        dialogOpen: false,
                    }
                }, () => {
                    if (nameVerifyingData.nameVerifyCallback)
                        nameVerifyingData.nameVerifyCallback();
                })
            } else {
                if (nameVerifyingData.nameVerifyCallback)
                    nameVerifyingData.nameVerifyCallback();
            }
        })
    }

    handleChangeCount = event => {
        if (Number(event.target.value) >= 1 && Number(event.target.value <= this.state.goodStorageItems.length)) {
                this.setState({
                ...this.state,
                count: Number(event.target.value)
            })
        }
    }

    handlePatchComment = () => {
        if (!this.state.sale.id) {
            this.props.showError('Необходимо сохранить продажу чтобы изменять комментарий')
            return;
        }
        patchSale(this.state.sale.id, {comment: this.state.sale.comment}).then(response => {
            if (response.success) {
                this.setState(prev => ({
                    ...prev,
                    sale: {
                        ...prev.sale,
                        comment: response.data?.comment
                    }
                }))
                this.props.showInfo('Комментарий успешно изменен')
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        })
    }

    render() {
        const { classes, t } = this.props;
        const {
            sale, parlours, amountRest, currentBusinessUnitId,
            discounts, calculating, refreshSale, loadedSale,
            canPrintReceipt, isPrintReceiptModalOpen, nameVerifyingData, settings
        } = this.state;
        const readOnly = sale.status === saleStatuses.complete;

        const saleChanged = (sale.amountTotal.val ?? 0) !== (loadedSale.amountTotal.val ?? 0);
        return <>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ShowField
                    label="Номер"
                    value={sale.num}
                />
                <GuestField
                    label="Гость"
                    value={sale.guest}
                    required
                    error={!sale.guest}
                    name="guest"
                    onChange={this.handleChange}
                    readOnly={readOnly}
                />
                <SelectFromItemsField
                    label="Салон"
                    value={sale.parlour}
                    required
                    error={!sale.parlour}
                    name="parlour"
                    onChange={this.handleChange}
                    readOnly={Boolean(currentBusinessUnitId) || readOnly || (sale.storageGoods && sale.storageGoods.length > 0)}
                    fullWidth
                    items={parlours}
                />
                <SelectFromItemsField
                    items={discounts}
                    label="Скидка"
                    value={sale.discountId}
                    name="discountId"
                    onChange={this.handleChange}
                    nullable
                    fullWidth
                    readOnly={readOnly}
                />
                <ShowField
                    label="Обшая сумма"
                    value={formatMoney(sale.amountTotal)}
                    endAdornment={ calculating ? <CircularProgress size={20}/> : null }
                />
                <ShowField
                    label="Статус"
                    value={sale.statusText}
                    />
                <ShowField
                    label="Дата создания"
                    dateFormat="DD.MM.YYYY"
                    value={sale.createdAt}
                />
                <ActionButton onClick={this.handleSave} visible={sale.status !== saleStatuses.complete} disabled={sale.storageGoods.length === 0 || !sale.guest || !sale.parlour}>Сохранить</ActionButton>
                <ActionButton
                    onClick={this.handleComplete}
                    visible={!!sale.id && sale.status !== saleStatuses.complete}
                    disabled={amountRest !== 0 || saleChanged}
                >Завершить</ActionButton>
                <ActionButton
                    onClick={this.handleCancelSale}
                    visible={!!sale.id && sale.status !== saleStatuses.complete}
                >Удалить</ActionButton>
                <ActionButton onClick={this.handleReopen} visible={hasRole('ROLE_GOOD_GOODSTORAGESALE_REOPEN') && (sale.id && sale.status === saleStatuses.complete)}>Открыть</ActionButton>
                <ActionButton onClick={this.handleClickChangeDate} visible={hasRole('ROLE_GOOD_GOODSTORAGESALE_CHANGEDATE') && (sale.id && sale.status === saleStatuses.complete)}>Перенести</ActionButton>
                <ActionButton onClick={this.handleBack}>Назад</ActionButton>
                <ActionButton
                    onClick={this.handlePrintReceipt}
                    visible={canPrintReceipt && !!sale.id && sale.status !== saleStatuses.complete}
                >Напечатать чек</ActionButton>
                <ActionButton
                    onClick={this.handleOpenPrintReceiptModal}
                    visible={!!sale.id && sale.status !== saleStatuses.complete}
                >{t('Print refund receipt')}</ActionButton>
            </LeftBar>

            <Content title="Продажа товара">
                Товары в продаже:
                <DataTableLocal
                    data={this.getTableData(sale.storageGoods)}
                    columnData={columnData}
                    selector={false}
                    pagination={false}
                    onDelete={readOnly ? null : this.handleDeleteStorageGood}
                />
                {!readOnly ? this.renderAddStorageGood() : null}
                {!readOnly && sale.id && sale.saleId ?
                    <Pay
                        className={classes.container}
                        saleId={sale.saleId}
                        guestId={sale.guest}
                        onChange={this.handlePayChange}
                        amountTotal={sale.amountTotal ? sale.amountTotal.val : 0}
                        disablePayDocMeasures={['time']}
                        refreshSale={refreshSale}
                        disabled={saleChanged || readOnly}
                        filterPaymentForGoodsAndBalance={true}
                    />
                    : null
                }
                {saleChanged && <><br/><Typography variant="body2" color="error" gutterBottom>Сохраните продажу, чтобы провести оплату</Typography></>}
                {this.renderChangeDateDialog()}
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={7}>
                        <AnyField
                            label={t("Comment")}
                            value={sale.comment}
                            onChange={this.handleChange}
                            name='comment'
                            multiline
                            rows={8}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <ActionButton onClick={this.handlePatchComment}>Сохранить комментарий</ActionButton>
                    </Grid>
                </Grid>
            </Content>
            <Dialog
                open={isPrintReceiptModalOpen}
                onClose={this.handlePrintReceiptModalReject}
            >
                <DialogTitle id="alert-dialog-title">{"Провести возврат по кассе?"}</DialogTitle>
                <DialogActions>
                    <ActionButton fullWidth={false} onClick={this.handlePrintReceiptModalApproved}>{t('Yes')}</ActionButton>
                    <ActionButton fullWidth={false} onClick={this.handlePrintReceiptModalReject}>{t('No')}</ActionButton>
                </DialogActions>
            </Dialog>
            <GuestNameVerificationDialog
                open={
                    nameVerifyingData.dialogOpen &&
                    !_.isEmpty(settings) &&
                    settings.guestNameVerificationEnable
                }
                nameVerifyData={nameVerifyingData}
                onChange={this.handleChangeVerifyGuestName}
                onSubmit={this.handleVerifyGuestName}
            />
        </>;
    }

    renderAddStorageGood() {
        const { classes } = this.props;
        const { sale, newStorageGoodId } = this.state;

        if (!sale.parlour) {
            return <Grid container spacing={2} className={classes.container} alignItems="flex-end">
                <Grid item><Typography color="error">Необходимо указать салон</Typography></Grid>
            </Grid>;
        }

        return <Grid container spacing={2} className={classes.container} alignItems="flex-end">
            <Grid item className={classes.flexGrow}>
                <GoodStorageField
                    label="Товар"
                    value={newStorageGoodId}
                    onChange={this.handleChangeStorageGood}
                    excludeItemsIds={Object.values(sale.storageGoods ?? []).map(item => item.id)}
                    onComplete={this.handleAutoAddStorageGood}
                    additionalFilter={{parlour: sale.parlour, purpose: 'for_sale'}}
                    readOnly={!sale.parlour}
                />
            </Grid>
            <Grid item style={{display: 'center'}}>
                <AnyField
                    label='Кол-во'
                    onChange={this.handleChangeCount}
                    value={Number(this.state.count)}
                    style={{maxWidth: 70, paddingTop: 8}}
                    size='medium'
                    type='number'
                />
            </Grid>
            <Grid item style={{display: 'center'}}>
                <TextField
                    label='На складе'
                    readOnly={true}
                    value={Number(this.state.goodStorageItems.length ?? 0)}
                    style={{maxWidth: 70, paddingTop: 8}}
                    size='medium'
                />
            </Grid>
            <Grid item>
                <ActionButton
                    fullWidth={false}
                    onClick={this.handleAddStorageGood}
                    disabled={!newStorageGoodId}
                    noMargin
                    size="medium"
                >Добавить в продажу</ActionButton>
            </Grid>
        </Grid>
    }

    handleClickChangeDate = () => {
        this.setState({
            changeDateDialogOpen: true,
            changeDate: moment(this.state.createdAt),
        });
    }

    handleChangeDate = event => {
        this.setState({
            changeDate: event.target.value,
        });
    }

    handleClickChangeDateCancel = () => {
        this.setState({
            changeDateDialogOpen: false,
        });
    }

    handleClickChangeDateOk = () => {
        const { changeDate, sale } = this.state;
        if (!changeDate.isSame(sale.createdAt, 'day')) {
            changeDateGoodStorageSale(sale.id, changeDate)
                .then(response => {
                    if (response.success) {
                        const sale = processSale(response.data);
                        this.setState({
                            changeDateDialogOpen: false,
                            sale,
                            loadedSale: {...sale},
                            refreshSale: !this.state.refreshSale,
                        }, this.updateDiscounts);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        }
    }

    renderChangeDateDialog() {
        const { changeDateDialogOpen, changeDate, sale } = this.state;
        const { t } = this.props;
        const equalDates = changeDate && changeDate.isSame(sale.createdAt, 'day');

        return <Dialog open={changeDateDialogOpen} maxWidth="sm" onClose={this.handleClickChangeDateCancel}>
            <DialogTitle>{t('Change date')}</DialogTitle>
            <DialogContent>
                <ShowField
                    label="Текущая дата создания"
                    value={sale.createdAt}
                    dateFormat="DD.MM.YYYY"
                    fullWidth
                />
                <DateField2
                    label={t('Date')}
                    value={changeDate}
                    onChange={this.handleChangeDate}
                    required
                    error={!changeDate || equalDates}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <ActionButton fullWidth={false} disabled={!changeDate || equalDates} onClick={this.handleClickChangeDateOk}>OK</ActionButton>
                <ActionButton fullWidth={false} onClick={this.handleClickChangeDateCancel}>{t('Cancel')}</ActionButton>
            </DialogActions>
        </Dialog>;
    }

    getTableData(data) {
        const res = Object.values(data).reduce((acc, obj) => {
            const existingObj = acc.find(item => {
                return item?.good.id === obj?.good.id;
            });

            if (existingObj) {
                existingObj.count++;
                existingObj.num += `, ${obj.num}`;
            } else {
                acc.push({...obj, count: 1, num: obj.num});
            }
            return acc;
        }, []);

        return res.map(obj => {
            obj.num = obj?.num.split(', ').join(',');
            return obj;
        });
    }
}
