import React from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {getGuest, getVisits, navLinks} from "../../services/guests";
import ActionButton from "../../components/button/ActionButton";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";
import GuestLeftFields from "./GuestLeftFields";
import {withTranslation} from "react-i18next";
import {getVisitStatusColor, getVisitStatusText} from "../../services/calendar";
import {fade, lighten} from "@material-ui/core";

const columnData = [
    { id: 'start', numeric: false, disablePadding: false, label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'parlourName', numeric: false, disablePadding: false, label: 'Parlour' },
    { id: 'creatorName', numeric: false, disablePadding: false, label: 'Created by' },
    { id: 'facilities', numeric: false, disablePadding: false, label: 'Facilities' },
    { id: 'master', numeric: false, disablePadding: false, label: 'Master' },
    { id: 'priceFormat', numeric: false, disablePadding: false, label: 'Price' },
    { id: 'totalFormat', numeric: false, disablePadding: false, label: 'Cost' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status',
        processValue: (value, t, item) => {
            return t(getVisitStatusText(item.status))
        }
    }
]


export default
@withRouter
@withTranslation()
class HistoryVisitPage extends React.Component {

    state = {
        id: '',
        guest: {
            id: null,
            lastName: null,
            name: null,
            secondName: null,
            phone: null,
            payDocs: null,
        },
        refreshTable: false,
        visitStatuses: []
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getGuest(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: response.data
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/guests/view/${this.state.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getVisits(this.state.id, null, ...params) : getFalseResult();
    };


    rowStyle = item => {
        if(item.status === 'canceled') {
            return {backgroundColor: lighten('#ff0000', 0.6)}
        }
        return {backgroundColor: fade(getVisitStatusColor(item.status), 0.2)}
    };

    render() {
        const { t } = this.props;
        const { guest, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("History of visits")}/>}>
                    <GuestLeftFields guest={guest}/>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>{t("Back")}</ActionButton>
                </LeftBar>
                <Content title={t("History of visits")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        linkTemplate={visit => `/orders/view/${visit.id}`}
                        rowStyleFunc={this.rowStyle}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
