import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import reducer from './reducer'
import {
    loadCalendarBusinessUnitFilter,
    loadCalendarCurrentDate,
    loadCurrentParlour,
    loadHiddenStatuses,
    loadToken
} from "./services/localStorage";
import moment from "moment";

let store = null;

export default function getStore() {
    if (store) return store;

    const currentDate = loadCalendarCurrentDate() ? moment(loadCalendarCurrentDate()) : moment();
    const currentParlour = loadCurrentParlour();

    store = createStore(
        reducer,
        {
            auth: {
                isLoginPending: false,
                isLoginSuccess: false,
                loginError: null,
                user: null,
                isSuperAdmin: false,
                token: loadToken(),
                socket: null,
                roles: [],
            },
            calendar: {
                currentDate,
                hiddenStatuses: loadHiddenStatuses() || [],
                visits: [],
                multiColumns: [],
                businessUnitFilter: loadCalendarBusinessUnitFilter() ||
                    {
                        params:{
                            regions: [],
                            types: []
                        },
                        enabled: {
                            regions: false,
                            types: false
                        }
                    }
            },
            calendarEmployee: {
                currentDate,
            },
            common: {
                currentParlour,
            }
        },
        composeWithDevTools(applyMiddleware(
            thunk
        ))
    );

    return store;
}
