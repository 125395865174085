import React from 'react';
import AnyField from "./AnyField";
import NumberFormatCount from "./NumberFormatCount";

export default
class CountField extends React.PureComponent {
    static propTypes = {
        ...AnyField.propTypes,
    }

    render () {
        const { InputProps, ...otherProps } = this.props;

        return <AnyField
            {...otherProps}
            InputProps={{
                ...InputProps,
                inputComponent: NumberFormatCount,
            }}
        />;
    }
}