import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {navLinks, timeSearch} from "../../services/callCenter";
import moment from "moment";
import DateTimeField from "../../components/fields/DateTimeField";
import {withTranslation} from "react-i18next";
import {MenuItem} from "@material-ui/core";
import ActionButton from "../../components/button/ActionButton";
import { getMasters } from "../../services/organization";
import AnyField from "../../components/fields/AnyField";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {getBusinessUnitByRole} from "../../services/user";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {createVisit} from "../../services/calendar";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";

export default
@withTranslation()
@connect(null,{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class TimeSearchPage extends React.Component
{
    colors = {
        used: {value: 'used', text: this.props.t('Used')},
        canceled: {value: 'canceled', text: this.props.t('Canceled')},
        empty: {value: 'empty', text: this.props.t('Empty')}
    };

    times = [
        { value: 30, text: this.props.t('30 minutes')},
        { value: 45, text: this.props.t('45 minutes')},
        { value: 60, text: this.props.t('1 hour')},
        { value: 90, text: this.props.t('1 hour 30 minutes')},
        { value: 120, text: this.props.t('2 hours')},
        { value: 150, text: this.props.t('2 hours 30 minutes')},
        { value: 180, text: this.props.t('3 hours')},
    ];

    state = {
        search: {
            search: null,
            timeStart: moment().startOf('day'),
            timeEnd: moment().endOf('day'),
            businessUnitIds: [],
            employeeIds: [],
            color: this.colors.empty.value,
            duration: this.times[0].value,
        },
        refreshTable: false,
        businessUnits: [{ id: 0, name: this.props.t('---All---')}],
        employees: [{ id: 0, name: this.props.t('---All---')}],
    };

    createVisit = (row) => {
        if (row.color === this.colors.used) {
            return null;
        }

        let minLoadedMaster = row.masterData.reduce( (accumulator, currentValue) => {
            if (accumulator.loading > currentValue.loading) {
                return currentValue;
            }
            return accumulator;
        }, row.masterData[0]);

        createVisit({
            parlour: {id: row.parlourId},
            visitFacility: [{
                start: moment(row.sliceStartW3C),
                master: [{id: minLoadedMaster.id}],
            }]
        })
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/orders/edit/${response.data.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    columnData = [
        { id: 'parlourName', label: 'Parlour' },
        { id: 'sliceStartW3C', label: 'Day', processValue: value => moment(value).format('dddd') },
        { id: 'date', label: 'Date' },
        { id: 'time', label: 'Time', onClick: this.createVisit },
        { id: 'duration', label: 'Duration+time' },
        { id: 'name', label: 'Master', processValue: value => value.join(', ') },

        // { id: 'sliceStart', label: 'Slice start' },
        // { id: 'sliceEnd', label: 'Slice end' },

        { id: 'visits', label: 'Visits', multiline: true },
        { id: 'loading', label: 'Loading', processValue: value => value.join(', ') },
    ];

    getTableData = (...params) => {
        return timeSearch({...this.state.search}, ...params);
    };

    componentDidMount() {
        getMasters(null)
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });

        getBusinessUnitByRole('ROLE_CALLCENTER_TIMESEARCH', {'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });
    }

    rowStyle = item => {
        if (item.color === this.colors.used.value) {
            return {backgroundColor: '#ffd7e1'}
        } else if (item.color === this.colors.canceled.value) {
            return {backgroundColor: '#2eff69'}
        } else {
            return {backgroundColor: '#e1ffde'}
        }
    };

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    render() {
        const { t } = this.props;
        const { refreshTable, search, employees, businessUnits } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DateTimeField
                    onChange={ this.handleChange('timeStart') }
                    label={ t('Period start') }
                    value={ search.timeStart }
                    fullWidth
                />
                <DateTimeField
                    onChange={ this.handleChange('timeEnd') }
                    label={ t('Period end') }
                    value={ search.timeEnd }
                    fullWidth
                />
                <AnyField
                    value={search.businessUnitIds}
                    onChange={this.handleChange('businessUnitIds')}
                    label={t("Parlours")}
                    select
                    SelectProps={{
                        multiple: true,
                        displayEmpty: true,
                        renderValue: selected => {
                            if (selected.length === 0) {
                                return <span>{t('---All---')}</span>;
                            }

                            return businessUnits.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                        },
                    }}
                    fullWidth
                >
                    {businessUnits.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={search.businessUnitIds.indexOf(item.id) > -1} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))}
                </AnyField>
                <AnyField
                    value={search.employeeIds}
                    onChange={this.handleChange('employeeIds')}
                    label={t("Masters")}
                    select
                    SelectProps={{
                        multiple: true,
                        displayEmpty: true,
                        renderValue: selected => {
                            if (selected.length === 0) {
                                return <span>{t('---All---')}</span>;
                            }

                            return employees.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                        },
                    }}
                    fullWidth
                >
                    {employees.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={search.employeeIds.indexOf(item.id) > -1} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))}
                </AnyField>
                <SelectFromItemsField
                    items={Object.values(this.colors)}
                    valueField='value'
                    textField='text'
                    nullableText={t('---All---')}
                    nullable
                    label={t('Type')}
                    fullWidth
                    onChange={this.handleChange('color')}
                    value={search.color}
                />
                {search.color !== this.colors.used.value && search.color !== null &&
                    <SelectFromItemsField
                        items={this.times}
                        valueField='value'
                        textField='text'
                        nullableText={t('---All---')}
                        nullable
                        label={t('Duration')}
                        fullWidth
                        onChange={this.handleChange('duration')}
                        value={search.duration}
                    />
                }
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
            </LeftBar>
            <Content title={t("Time search")}>
                <DataTable
                    dataFunc={this.getTableData}
                    selector={false}
                    columnData={this.columnData}
                    //linkTemplate={task => `/schedule/visit/id/${task.id}`}
                    rowStyleFunc={this.rowStyle}
                    refresh={refreshTable}
                />
            </Content>
        </React.Fragment>
    }
}
