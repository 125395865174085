import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    createSpending,
    navLinks,
    getSpendingTypesByCurrentUserBusinessRole,
    currentSpendingLimit, importSpending
} from "../../services/moneyFlow";
import AnyField from "../../components/fields/AnyField";
import {Grid, MenuItem} from "@material-ui/core";
import {emptyMoney} from "../../services/common";
import infoActions from "../../components/info/info-actions";
import {
    getHumanEmployees,
    getExternalSubjects,
    getJuridicalPersons, getCheckingAccounts, getBanksByJuridicalPerson, getCheckingAccountsByBank, getInvestors
} from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import ShowField from "../../components/fields/ShowField";
import {getPaymentTypes} from "../../services/sale";
import _ from "lodash";
import MoneyField from "../../components/fields/MoneyField";
import {hasRole, getBusinessUnitByRole} from "../../services/user";
import {withTranslation} from "react-i18next";
import DateTimeField2 from "../../components/fields/DateTimeField2";
import DateField2 from "../../components/fields/DateField2";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import ImportDialogSpending from "./ImportDialogSpending";
import {getGeneralProperty, settingPropertyNames} from "../../services/settings";


const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
});

const emptySpending = {
    amount: {...emptyMoney},
    businessUnitId: null,
    comment: null,
    paymentType: null,
    externalSubjectId: null,
    reasonId: null,
    employeeId: null,
    createdAt: null,
    juridicalPersonId: null,
    cancellationFact: null,
    fromCheckingAccountId: null,
    bankId: null,
    checkingAccountId: null,
    investorId: null,
    companyEmployeeId: null
};

export default @connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class SpendingDealPage extends React.Component {
    state = {
        externalSubjects: [],
        grantedBusinessUnits: [],
        spendingTypes: [],
        paymentTypes: [],
        currentLimit: null,
        currentLimitValue: null,
        spending: {
            ...emptySpending
        },
        moneyEmployees: [],
        allEmployees: [],
        openImportModal: false,
        trustedJuridicalPersons: [],
        fromCheckingAccounts: [],
        banksByJP: [],
        distributionOfDividendsToSalonInvestorsSpendingTypeId: null,
        loanRepaymentSpendingTypeId: null,
        investors: []
    };

    componentDidMount() {
        getExternalSubjects()
            .then(response => {
                if (response.success) {
                    this.setState({
                        externalSubjects: [
                            ...response.data
                        ],
                    });
                }
            });

        getSpendingTypesByCurrentUserBusinessRole()
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypes: [
                            ...response.data
                        ],
                    });
                }
            });

        getPaymentTypes().then(response => {
            if (response.success) {
                this.setState({
                    paymentTypes: _.filter(response.data, function (o) {
                        if (!hasRole('ROLE_MONEYFLOW_DEAL_COMMONSPENDING_CASHLESS') && o.id === 'cashless') {
                            return false;
                        }
                        return o.id !== 'paydoc';
                    })
                });
            }
        });

        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        moneyEmployees: response.data,
                    });
                }
            });

        getHumanEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        allEmployees: response.data,
                    });
                }
            });

        getBusinessUnitByRole('ROLE_MONEYFLOW_DEAL_COMMONSPENDING')
            .then(response => {
                if (response.success) {
                    this.setState({
                        grantedBusinessUnits: response.data
                    })
                }
            });

        getGeneralProperty(settingPropertyNames.distributionOfDividendsToSalonInvestorsSpendingTypeId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        distributionOfDividendsToSalonInvestorsSpendingTypeId: response.data
                    })
                }
            })

        getGeneralProperty(settingPropertyNames.loanRepaymentSpendingTypeId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        loanRepaymentSpendingTypeId: response.data
                    })
                }
            })

        getInvestors()
            .then(response => {
                if (response.success) {
                    this.setState({
                        investors: response.data
                    })
                }
            });
    }

    getTrustedJuridicalPersons = () => {
        getJuridicalPersons({'trustedForBusinessUnit': this.state.spending.businessUnitId})
            .then(response => {
                if (response.success) {
                    this.setState({
                        trustedJuridicalPersons: response.data,
                    });
                }
            });
    }

    getFromCheckingAccounts = () => {
        if (!this.state.spending.juridicalPersonId) {
            this.setState({
                fromCheckingAccounts: [],
            });
            return;
        }


        getCheckingAccounts({'juridicalPersonId': this.state.spending.juridicalPersonId, active: true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        fromCheckingAccounts: response.data,
                    });
                }
            });
    }

    getBanksByJuridicalPerson(id) {
        getBanksByJuridicalPerson(id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        banksByJP: response.data
                    });
                }
            })
    }

    getCheckingAccountsByBank(bankId) {
        const {spending} = this.state
        getCheckingAccountsByBank(bankId, spending.juridicalPersonId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        fromCheckingAccounts: response.data
                    })
                }
            })
    }

    handleChangeBU() {
        this.getTrustedJuridicalPersons();
        this.setState(prevState => ({
            spending: {
                ...prevState.spending,
                juridicalPersonId: null
            }
        }));

        this.handleChangeJP(null);

    }

    handleChangeJP(juridicalPersonId) {
        this.setState(prevState => ({
            spending: {
                ...prevState.spending,
                bankId: null
            }
        }));
        if (juridicalPersonId === null)
            this.setState({
                banksByJP: []
            });
        else
            this.getBanksByJuridicalPerson(juridicalPersonId);


        this.handleChangeBank(null);

    }

    handleChangeBank(bankId) {

        this.setState(prevState => ({
            spending: {
                ...prevState.spending,
                fromCheckingAccountId: null
            }
        }));

        if (bankId === null)
            this.setState({
                fromCheckingAccounts: []
            });
        else
            this.getCheckingAccountsByBank(bankId);


    }

    handleChange = prop => event => {
        const {spending} = this.state
        this.setState({
            spending: {
                ...spending,
                [prop]: event.target.value,
            }
        }, () => {
            if (prop === 'reasonId' || prop === 'businessUnitId')
                this.currentSpendingLimit();

            if (prop === 'businessUnitId')
                this.handleChangeBU();

            if (prop === 'juridicalPersonId')
                this.handleChangeJP(event.target.value);

            if (prop === 'bankId')
                this.handleChangeBank(event.target.value)

        });
    };

    handleChangeMoney = prop => event => {
        this.setState({
            spending: {
                ...this.state.spending,
                [prop]: event.target.value
            }
        });
    };

    handleAdd = () => {
        const {t} = this.props;
        createSpending(this.state.spending)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Spending created'));
                    this.setState({
                        currentLimit: null,
                        currentLimitValue: null,
                        spending: {
                            ...emptySpending,
                            businessUnitId: this.props.currentParlour ? this.props.currentParlour.id : null,
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    currentSpendingLimit = () => {
        const {spending} = this.state;
        if (!spending.reasonId || !spending.businessUnitId) {
            this.setState({
                currentLimit: null,
                currentLimitValue: null
            });
            return;
        }
        currentSpendingLimit(spending.reasonId, spending.businessUnitId)
            .then(response => {
                let currentLimit;
                let currentLimitValue = null;
                if (response.success) {
                    currentLimit = response.data.currentAmountFormatted;
                    currentLimitValue = response.data.currentAmount.val;
                } else {
                    currentLimit = response.error ? response.error.message : response.message;
                }
                this.setState({
                    currentLimit: currentLimit,
                    currentLimitValue: currentLimitValue
                });
            });
    };

    render() {
        const {classes, t} = this.props;
        const {
            spending,
            externalSubjects,
            grantedBusinessUnits,
            spendingTypes,
            currentLimit,
            currentLimitValue,
            paymentTypes,
            moneyEmployees,
            allEmployees,
            openImportModal,
            trustedJuridicalPersons,
            fromCheckingAccounts,
            banksByJP,
            investors
        } = this.state;

        const isRequest = currentLimitValue !== null && spending.amount.val > currentLimitValue;
        const disabled =
            !spending.businessUnitId
            || !spending.reasonId
            || !spending.paymentType
            || !spending.amount.val
            || (spending.paymentType === 'cashless' && !spending.externalSubjectId)
            || (isRequest && !spending.comment)
            || (this.isInvestorFieldNeed() && !spending.investorId)

        let selectedBusinessUnit = grantedBusinessUnits.find((item) => spending.businessUnitId === item.id);
        let currencies = selectedBusinessUnit ? [selectedBusinessUnit.mainCurrency] : [emptyMoney.cur];

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>

                    <ShowField
                        label={t("Spending limit")}
                        value={currentLimit || t('Business unit and limit not selected')}
                    />

                    <ActionButton onClick={this.handleAdd}
                                  disabled={disabled}>{isRequest ? t('Create spending request') : t('Create spending')}</ActionButton>
                    <ActionButton onClick={() => {
                        this.setState({openImportModal: true})
                    }}>{t("Import spending")}</ActionButton>
                </LeftBar>
                <Content title={t("Create money spending")}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Business unit")}
                                value={spending.businessUnitId}
                                required
                                error={!spending.businessUnitId}
                                onChange={this.handleChange('businessUnitId')}
                                fullWidth
                                options={grantedBusinessUnits.map(item => ({
                                    value: item.id,
                                    label: `${item.name}`
                                }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Spending type")}
                                value={spending.reasonId}
                                required
                                error={!spending.reasonId}
                                onChange={this.handleChange('reasonId')}
                                fullWidth
                                options={spendingTypes.map(item => ({
                                    value: item.id,
                                    label: `${item.name} / ${item.category.name}`
                                }))}
                            />
                        </Grid>
                        {
                            this.isInvestorFieldNeed() &&
                                <Grid item xs={3}>
                                    <AutocompleteSelectField
                                        label={t("Investor")}
                                        value={spending.investorId}
                                        required
                                        error={!spending.investorId}
                                        onChange={this.handleChange('investorId')}
                                        fullWidth
                                        options={investors.map(item => ({
                                            value: item.id,
                                            label: `${item.name}`
                                        }))}
                                    />
                                </Grid>
                        }

                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                id="type"
                                value={spending.paymentType}
                                error={!spending.paymentType}
                                onChange={this.handleChange('paymentType')}
                                select
                                label={t("Payment type")}
                                required
                                fullWidth>
                                {paymentTypes.map(item => <MenuItem key={item.id}
                                                                    value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <MoneyField
                                label={t('Amount')}
                                currencies={currencies}
                                value={spending.amount}
                                error={!spending.amount}
                                onChange={this.handleChangeMoney('amount')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("External subject")}
                                value={spending.externalSubjectId}
                                required
                                error={spending.paymentType === 'cashless' && !spending.externalSubjectId}
                                onChange={this.handleChange('externalSubjectId')}
                                fullWidth
                                options={externalSubjects.map(item => ({value: item.id, label: `${item.name}`}))}
                            />
                        </Grid>
                        {spending.paymentType === 'cashless' &&
                        <>
                            <Grid item xs={3}>
                                <AutocompleteSelectField
                                    label={t("From juridical person")}
                                    value={spending.juridicalPersonId}
                                    onChange={this.handleChange('juridicalPersonId')}
                                    fullWidth
                                    options={trustedJuridicalPersons.map(item => ({
                                        value: item.id,
                                        label: `${item.fullName}`
                                    }))}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SelectFromItemsField
                                    label={t("Bank name")}
                                    value={spending.bankId}
                                    onChange={this.handleChange('bankId')}
                                    fullWidth
                                    items={banksByJP}
                                    nullable
                                    textField="bankName"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SelectFromItemsField
                                    label={'Расчетный счет'}
                                    value={spending.fromCheckingAccountId}
                                    onChange={this.handleChange('fromCheckingAccountId')}
                                    fullWidth
                                    items={fromCheckingAccounts}
                                    nullable
                                    textField="rs"
                                />
                            </Grid>
                        </>
                        }
                        {spending.paymentType === 'cash' && hasRole('ROLE_MONEYFLOW_DEAL_SPENDINGFROMEMPLOYEE') &&
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Employee's money")}
                                value={spending.employeeId}
                                onChange={this.handleChange('employeeId')}
                                fullWidth
                                options={moneyEmployees.map(item => ({value: item.id, label: `${item.name}`}))}
                                isClearable
                            />
                        </Grid>
                        }
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Company employee")}
                                value={spending.companyEmployeeId}
                                onChange={this.handleChange('companyEmployeeId')}
                                fullWidth
                                options={allEmployees.map(item => ({value: item.id, label: `${item.name}`}))}
                                isClearable
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Comment")}
                                value={spending.comment}
                                error={isRequest && !spending.comment}
                                required={isRequest && !spending.comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                        {spending.paymentType === 'cashless' &&
                        <Grid item xs={3}>
                            <DateField2
                                label={t("Cancellation fact")}
                                onChange={this.handleChange('cancellationFact')}
                                value={spending.cancellationFact}
                                fullWidth
                            />
                        </Grid>
                        }
                        {hasRole('ROLE_MONEYFLOW_DEAL_CREATED_AT') ?
                            <Grid item xs={3}>
                                <DateTimeField2
                                    label={t("Date ERP")}
                                    onChange={this.handleChange('createdAt')}
                                    value={spending.createdAt}
                                    fullWidth
                                />
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </Content>

                {openImportModal &&
                <ImportDialogSpending open={openImportModal} onClose={() => this.setState({openImportModal: false})}
                              title={t("Import spending")} importFunc={importSpending}/>}
            </React.Fragment>
        );
    }

    isInvestorFieldNeed = () => {
        const { spending, distributionOfDividendsToSalonInvestorsSpendingTypeId, loanRepaymentSpendingTypeId} = this.state
        return distributionOfDividendsToSalonInvestorsSpendingTypeId &&
                loanRepaymentSpendingTypeId &&
                spending.reasonId &&
                [distributionOfDividendsToSalonInvestorsSpendingTypeId, loanRepaymentSpendingTypeId]
                    .includes(spending.reasonId)
    }
}
