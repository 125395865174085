import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import {
    getActivityGroups,
    getIncomingTypes, getTypeActivities,
    saveIncomingType
} from "../../services/moneyFlow";
import SearchField from "../../components/fields/SearchField";
import CheckboxField from "../../components/fields/CheckboxField";
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

const columnData = [
    { id: 'name', label: 'Наименование' },
    { id: 'description', label: 'Описание' },
];

const emptyIncomingType = {
    id: null,
    name: null,
    description: null,
    showInFinanceReportOtherIncome: false,
    showInFinanceReportOtherSupply: false,
    countAccruedTrialBalanceByClients: false,
    activityGroup: null,
    activityType: null
};

export default
@connect(state => ({
}),{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class IncomingTypesListPage extends React.Component
{
    state = {
        incomingType: emptyIncomingType,
        dialogEditOpen: false,
        refreshTable: false,
        activityGroups: [],
        activityTypes: [],
        search: {
            search: null
        }
    };

    componentDidMount() {
        getActivityGroups()
            .then(response => {
                if(response.success){
                    this.setState({
                        activityGroups: response.data
                    })
                }
            })

        getTypeActivities()
            .then(response => {
                if(response.success){
                    this.setState({
                        activityTypes: response.data
                    })
                }
            })
    }

    getTableData = (...params) => {
        const {search} = this.state;
        return getIncomingTypes(search, ...params);
    };

    handleAdd = () => {
        this.setState({
            IncomingType: emptyIncomingType,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        saveIncomingType(this.state.incomingType)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleEdit = incomingType => {
        this.setState({
            incomingType,
            dialogEditOpen: true,
        })
    };

    handleChange = (prop) => event => {
        this.setState({
            incomingType: {
                ...this.state.incomingType,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    render() {
        const { dialogEditOpen, incomingType, refreshTable, search,
            activityGroups, activityTypes} = this.state;
        const { t } = this.props
        const disabled = !incomingType.name || !incomingType.activityType || !incomingType.activityGroup

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Статьи приходов">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog(false)}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Статья прихода</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Наименование прихода"
                                    value={incomingType.name}
                                    onChange={this.handleChange('name')}
                                    required
                                    error={!incomingType.name}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxField
                                    label={t('Display in income in the financial report')}
                                    value={incomingType.showInFinanceReportOtherIncome}
                                    onChange={this.handleChange('showInFinanceReportOtherIncome')}
                                    fullWidth
                                    margin="none"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxField
                                    label={t('Display in other supply in the financial report')}
                                    value={incomingType.showInFinanceReportOtherSupply}
                                    onChange={this.handleChange('showInFinanceReportOtherSupply')}
                                    fullWidth
                                    margin="none"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxField
                                    label={t('Count accrued in trial balance by clients')}
                                    value={incomingType.countAccruedTrialBalanceByClients}
                                    onChange={this.handleChange('countAccruedTrialBalanceByClients')}
                                    fullWidth
                                    margin="none"
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <SelectFromItemsField
                                    items={activityTypes}
                                    label="Вид деятельности"
                                    value={incomingType.activityType}
                                    name="activityType"
                                    onChange={this.handleChange('activityType')}
                                    fullWidth
                                    error={!incomingType.activityType}
                                />

                                <SelectFromItemsField
                                    items={activityGroups}
                                    label="Группа деятельности"
                                    value={incomingType.activityGroup}
                                    name="activityGroup"
                                    onChange={this.handleChange('activityGroup')}
                                    fullWidth
                                    error={!incomingType.activityGroup}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Описание прихода"
                                    value={incomingType.description}
                                    onChange={this.handleChange('description')}
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} disabled={disabled} color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
}
