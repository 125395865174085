import React, {useCallback, useContext, useEffect, useState} from 'react';
import {getBusinessUnitByRole} from "../../../../services/user";
import Context from "../Context";
import AutocompleteMultiSelectField from "../../../../components/fields/AutocompleteMultiSelectField";

const BusinessUnits = () => {
    const [businessUnits, setBusinessUnits] = useState([]);
    const { search: { businessUnitIds }, updateSearch } = useContext(Context);

    useEffect(() => {
        getBusinessUnitByRole('ROLE_MONEYFLOW_FINANCE_REPORT')
            .then(response => {
                if (response.success) {
                    setBusinessUnits(response.data)
                }
            });
    }, []);

    const handleChange = useCallback(event => {
        updateSearch(draftSearch => {
            draftSearch.businessUnitIds = event.target.value;
        })
    }, [updateSearch])

    return (
        <AutocompleteMultiSelectField
            options={businessUnits}
            emptySelectedLabel="---Не выбрано---"
            label="Салоны"
            value={businessUnitIds}
            onChange={handleChange}
            fullWidth
            textField={'name'}
            valueField={'id'}
            saveSelectedOrder
        />
    );
};

export default BusinessUnits;