import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {createCategory, getCategories, updateCategory} from "../../services/goods";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import CheckboxField from "../../components/fields/CheckboxField";


const columnData = [
    { id: 'name', label: 'Название' },
    { id: 'description', label: 'Описание' },
];

const styles = theme => ({
    textField: {
        marginTop:  theme.spacing(3),
    }
});

class GoodCategoriesListPage extends React.Component
{
    state = {
        category: {
            id: null,
            name: null,
            description: null,
            parent: null,
            activeForInvoice: true,
            craft: null,
        },
        dialogEditOpen: false,

        refreshTable: false,
    };

    handleAdd = () => {
        this.setState({
            category: {
                id: null,
                name: null,
                description: null,
                parent: null,
            },
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        if (ok) {
            if (this.state.category.id) {
                updateCategory(this.state.category)
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                dialogEditOpen: false,
                                refreshTable: !this.state.refreshTable,
                            });
                        } else {
                            this.props.showMessage(response.error ? response.error.message : response.message);
                        }
                    });
            } else {
                createCategory(this.state.category)
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                dialogEditOpen: false,
                                refreshTable: !this.state.refreshTable,
                            });
                        } else {
                            this.props.showMessage(response.error ? response.error.message : response.message);
                        }
                    });
            }
        }
    };

    handleChange = prop => event => {
        this.setState({
            category: {
                ...this.state.category,
                [prop]: event.target.value,
            }
        });
    };

    handleEdit = category => {
        this.setState({
            category: category,
            dialogEditOpen: true,
        })
    };

    getTableData = (...params) => {
        return getCategories(null, ...params);
    }

    render() {
        const { classes } = this.props;
        const { dialogEditOpen, category, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    ---
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Категории товаров">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog(false)}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Карточка категории товара</DialogTitle>
                    <DialogContent>
                        <AnyField
                            label="Название"
                            value={category.name}
                            onChange={this.handleChange('name')}
                            required
                            error={!category.name}
                            fullWidth
                            autoFocus
                            className={classes.textField}
                        />
                        <AnyField
                            label="Описание"
                            value={category.description}
                            onChange={this.handleChange('description')}
                            fullWidth
                            className={classes.textField}
                        />
                        <CheckboxField
                            label="Активна для на накладных"
                            value={category.activeForInvoice}
                            onChange={this.handleChange('activeForInvoice')}
                        />
                        <CheckboxField
                            label="Производство товаров"
                            value={category.craft}
                            onChange={this.handleChange('craft')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} disabled={!category.name} color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {showMessage: messageDialogActions.show})(withStyles(styles)(GoodCategoriesListPage));