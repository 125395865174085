import * as queryString from "query-string-object";
import baseRequest from './baseReuqest';
import getStore from "../store";

export function getUsers(page = 1, per_page = -1, order = 'asc', order_by = 'username') {
    let url = '/api/user';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getUsersFilter(search, page = 1, per_page = -1, order = 'asc', order_by = 'username') {
    let url = '/api/user';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getCurrentUser() {
    let url = '/api/user/current';
    const q = queryString.stringify({});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getPartners() {
    return baseRequest(`/api/user/partner`);
}

export function getCounterparts() {
    return baseRequest(`/api/user/counterpart`);
}

export function saveUser(user) {
    return baseRequest('/api/user', {
        method: 'POST',
        body: JSON.stringify({...user}),
    });
}

export function getBusinessRoles(search = null, page, per_page = -1, order, order_by = 'name') {
    return baseRequest(`/api/user/business-role`, {query: {search, page, per_page, order, order_by}});
}

export function getBusinessRole(id) {
    return baseRequest(`/api/user/business-role/id/${id}`,{
        query: {include: 'rolesDetailed'}
    });
}

export function saveBusinessRole(businessRole) {
    return baseRequest('/api/user/business-role', {
        method: businessRole.id ? 'PUT' : 'POST',
        body: JSON.stringify({...businessRole}),
        query: {include: 'rolesDetailed'}
    });
}

export function deleteBusinessRole(id) {
    return baseRequest(`/api/user/business-role/${id}`, {
        method: "DELETE",
        query: {include: 'rolesDetailed'}
    });
}

export function getRoles() {
    return baseRequest(`/api/user/roles`);
}

export function hasRole(role) {
    if (!role) {
        return true;
    }

    let state = getStore().getState();
    if (state.auth && state.auth.user) {
        return state.auth.user.roles.indexOf(role) !== -1;
    }

    return false;
}

export function getBusinessUnitByRole(role, search) {
    if (hasRole(role)) {
        return baseRequest('/api/organization/business-unit/roles', {
            query: {role, search}
        });
    }

    return new Promise(resolve => {
        resolve({ success: false, data: [], message: 'Access Denied.'})
    });
}

export function changePassword(oldPassword, newPassword) {
    return baseRequest('api/user/change-password', {
        method: 'PUT',
        body: JSON.stringify({oldPassword, newPassword})
    })
}

export const emptyUser = {
    id: null,
    username: null,
    password: null,
    isPartner: false,
    isCounterpart: false,
    phone: null,
    employeeId: null,
    businessRole: [],
    juridicalPersonId: null,
    locking: false,
    canLoginWithoutWorkShift: false,
    employeeMegaplanId: null,
    dailyReportSettings: null,
    employeeBitrixId: null,
    domRuEmployeeIds: [],
};

export const businessUnitsRoles = {
    summaryDayReport: 'ROLE_MONEYFLOW_SUMMARYDAYREPORT_GET'
};

export function requestRecoveryPassword(login) {
    return baseRequest('/api/user/password-recovery', {
            method: 'POST',
            body: JSON.stringify({login}),
        }
    )
}

export function passwordRecovery(newPassword, keyChangePassword) {
    return baseRequest('/api/user/password-recovery', {
        method: 'PUT',
        body: JSON.stringify({newPassword, keyChangePassword})
    });
}

export function checkAuthorizationParams({login, password}) {
    return baseRequest('/api/user/check-authorization-params', {
        method: 'POST',
        body: JSON.stringify({login, password})
    })
}

export function syncEmployeesMegaplan() {
    return baseRequest('/api/megaplan/sync', {
        method: 'PUT',
    });
}

export function getEmployeesMegaplan() {
    return baseRequest('/api/megaplan/employees');
}

export function syncEmployeesBitrix() {
    return baseRequest('/api/bitrix/sync-bitrix', {
        method: 'PUT',
    });
}

export function getEmployeesBitrix() {
    return baseRequest('/api/bitrix/employees-bitrix');
}

export function getDomRuEmployees() {
    return baseRequest('/api/dom-ru/dom-ru-employees');
}

export function syncDomRuEmployees() {
    return baseRequest('/api/dom-ru/dom-ru-sync', {
        method: 'PUT',
    });
}

export function isAuthUserBusinessRoleIdInArray(businessRolesIds) {

    if (!businessRolesIds.length) {
        return true;
    }
    
    let state = getStore().getState();

    if (state.auth && state.auth.user) {
        return businessRolesIds.indexOf(state.auth.user.businessRoleId) > -1;
    }

    return false;
}