import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Table, TableHead, TableRow, TableCell, TableBody, Fab
} from '@material-ui/core';
import {createPayDocs, getPayDocTypes, navLinks} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import NumberFormatCurrency from "../../components/fields/NumberFormatCurrency";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withTranslation} from "react-i18next";
import {getParlours} from "../../services/organization";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import CountField from "../../components/fields/CountField";
import {getCounterparts} from "../../services/user";
import CheckboxField from "../../components/fields/CheckboxField";
import {createChangeHandle} from "../../utils/helpers";

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
    showMessage: messageDialogActions.show
})
@withStyles(theme => ({
    table: {
        width: 600,
    },
    textField: {
        width: 300,
    },
    textFieldNum: {
        width: 150,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    button: {

    },
}))
@withTranslation()
class CreatePage extends React.Component {

    state = {
        requests: [{type: null, count: 0, nominal: null, balance: null}],
        businessUnits: [],
        businessUnitId: null,
        types: [],
        progress: false,
        counterparts: [],
        createRequest: false,
        counterpartId: null,
    };

    componentDidMount() {
        if (this.props.currentParlour) {
            this.setState({
                businessUnitId: this.props.currentParlour.id,
            });
        }

        getPayDocTypes({electronic: '0'})
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data.map(type => ({
                            ...type,
                            label: `"${type.code}" - ${type.name}`,
                        }))
                    });
                }
            });

        getParlours({active: 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getCounterparts()
            .then(response => {
                if (response.success) {
                    this.setState({
                        counterparts: response.data,
                    })
                }
            });
    }

    handleCreateClick = () => {
        const { requests, businessUnitId, createRequest, counterpartId } = this.state;
        const { t } = this.props;

        this.setState({
            progress: true
        });
        createPayDocs({ requests, businessUnitId, createRequest, counterpartId })
            .then(response => {
                this.setState({
                    progress: false
                });
                if (response.success) {
                    this.props.showMessage({message: t("Created request for payment documents") + ': ' + response.data, title: t("Creation of payment documents")});
                    this.setState({
                        requests: [{type: null, count: 0, nominal: null, balance: null}],
                    })

                } else {

                    this.props.showMessage({message: response.error ? response.error.message : response.message, title: t("Creation of payment documents")});
                }
            })
    };

    handleChange = createChangeHandle(this);

    handleChangeRequests = (idx, name) => event => {
        let { requests } = this.state;

        requests[idx][name] = event.target.value;

        this.setState({
            requests
        });
    };

    handleAdd = () => {
        const { requests }  = this.state;
        requests.push({type: null, count: 0, nominal: null, balance: null});

        this.setState({
            requests
        });
    };

    handleDel = idx => () => {
        const { requests }  = this.state;

        if (requests.length > 1) {
            _.remove(requests, (value, index) => index === idx);

            this.setState({
                requests
            });
        }
    };

    isRequestsOk = () => { 
        const { requests, businessUnitId, createRequest, counterpartId }  = this.state;
        let hasWrongLines = false;
        requests.forEach(request => {
            hasWrongLines = hasWrongLines || !request.type || !parseInt(request.count, 10);

            if (this.isNominal(request.type)) {
                hasWrongLines = hasWrongLines || (!request.nominal && !request.balance)
            }
        });

        return businessUnitId && requests.length && !hasWrongLines && (!createRequest || counterpartId);
    };

    isNominal = (typeId) => {
        const { types } = this.state;

        let type = types.find(type => type.id === typeId);

        return type && type.canSetNominal;
    };

    isBalance = (typeId) => {
        const {types} = this.state;

        let type = types.find(type => type.id === typeId);

        return type && type.balanceManufacture;
    }

    render() {
        const { classes, t } = this.props;
        const { requests, types, progress, businessUnitId, businessUnits, createRequest, counterpartId, counterparts } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SelectFromItemsField
                        items={businessUnits}
                        label={t("Parlour")}
                        value={businessUnitId}
                        error={!businessUnitId}
                        name="businessUnitId"
                        onChange={this.handleChange}
                        required
                        fullWidth
                    />
                    <CheckboxField
                        label={t("Create request")}
                        value={createRequest}
                        name="createRequest"
                        onChange={this.handleChange}
                        fullWidth
                    />
                    {createRequest &&
                        <SelectFromItemsField
                            label="Контрагент"
                            items={counterparts}
                            textField="username"
                            value={counterpartId}
                            required
                            error={!counterpartId}
                            name="counterpartId"
                            onChange={this.handleChange}
                            fullWidth
                        />
                    }

                    <ActionButton disabled={!this.isRequestsOk()} onClick={this.handleCreateClick}>{t("Create")}</ActionButton>
                </LeftBar>
                <Content title={t("Creation of an application for making payment documents")}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Type of payment document")}</TableCell>
                                <TableCell>{t("The number of created payment documents")}</TableCell>
                                <TableCell>{t("Nominal")}</TableCell>
                                <TableCell>{t("Balance")}</TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requests.map((req, idx) => {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell>
                                            <SelectFromItemsField
                                                items={types}
                                                name="type"
                                                value={req.type}
                                                error={!req.type}
                                                onChange={this.handleChangeRequests(idx, 'type')}
                                                className={classes.textField}
                                                textField="label"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <CountField
                                                value={req.count}
                                                name="count"
                                                error={!(req.count > 0)}
                                                onChange={this.handleChangeRequests(idx, 'count')}
                                                className={classes.textFieldNum}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            { this.isNominal(req.type) ?
                                                <AnyField
                                                    disabled={!!req.balance}
                                                    value={req.nominal}
                                                    error={req.nominal === null || isNaN(req.nominal)}
                                                    name="nominal"
                                                    onChange={this.handleChangeRequests(idx, 'nominal')}
                                                    className={classes.textFieldNum}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCurrency,
                                                    }}
                                                />
                                                : <div className={classes.textFieldNum}> </div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            { this.isBalance(req.type) ?
                                                <AnyField
                                                    disabled={!!req.nominal}
                                                    value={req.balance}
                                                    error={req.balance === null || isNaN(req.balance)}
                                                    name="balance"
                                                    onChange={this.handleChangeRequests(idx, 'balance')}
                                                    className={classes.textFieldNum}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCurrency,
                                                    }}
                                                />
                                                : <div className={classes.textFieldNum}> </div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Fab color="secondary" size="small" className={classes.button} disabled={requests.length === 1} onClick={this.handleDel(idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <br/>
                    <ActionButton fullWidth={false} onClick={this.handleAdd}><AddIcon className={classes.addButtonIcon}/>{t("Add")}</ActionButton>
                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
