import React from "react";
import NoWrap from "./NoWrap";
import MoneyList from "./MoneyList";
import MoneyListDetailsDialog from "./MoneyListDetailsDialog";
import {formatMoney} from "../../../../../services/common";

const spendingTransferIncomeDetailsColumnData = [
    { id: 'deal_id', label: 'ID' },
    { id: 'apply_at', label: 'Apply at', dateFormat: 'DD.MM.YYYY H:m:s' },
    { id: 'amount', processValue: formatMoney, label: 'Sum', getSortValue: value => value?.val },
    { id: 'reason_name', label: 'Deal name' }
];

const detailsDealsLinkTemplate = row => ({ to: `/money-flow/deal/id/${row.deal_id}`, target: '_blank' });

const gratuityDetailsStyle = {paddingLeft: 20}

const income = (value, t, row) => {
    if (!value) {
        return null;
    }

    const payDocOwnGratuityDetails = value.details?.filter(
        (d) => d.seType === 'gratuity' &&
            d.type === 'paydoc' &&
            d.payDocParlour === 'owner' &&
            ['paid'].indexOf(d.status) !== -1 &&
            !d.isFree
    ) || [];

    const payDocOtherGratuityDetails = value.details?.filter(
        (d) => d.seType === 'gratuity' &&
            d.type === 'paydoc' &&
            d.payDocParlour === 'other' &&
            ['paid'].indexOf(d.status) !== -1 &&
            !d.isFree
    ) || [];

    const payDocGratuityDetailsColumns = [
        { id: 'entityDate', dateFormat: 'DD.MM.YYYY HH:mm', label: 'Date' },
        { id: 'payDocNum', label: 'Payment document'},
        { id: 'amount', label: 'Amount', processValue: formatMoney },
        { id: 'payDocParlourInfo.name', label: 'Parlour' },
    ];

    return <NoWrap>
        <MoneyList title="f.Total" value={value.total}/>
        <MoneyList title="f.Visits" value={value.visit_total}/>
        <MoneyList title="f.Goods" value={value.goods_total}/>

        <MoneyList title="f.Gratuity" value={value.gratuity_total}/>
        <div style={gratuityDetailsStyle}>
            <MoneyList title="pay.typesShort.cash" value={value.gratuity_cash}/>
            <MoneyList title="pay.typesShort.cashless" value={value.gratuity_cashless}/>
            <MoneyListDetailsDialog
                detailsData={payDocOwnGratuityDetails}
                detailsColumns={payDocGratuityDetailsColumns}
                detailsTitle="f.PDOwner"
                                    title="f.PDOwner"
                                    value={value.gratuity_paydoc_own}
            />
            <MoneyListDetailsDialog
                detailsData={payDocOtherGratuityDetails}
                detailsColumns={payDocGratuityDetailsColumns}
                detailsTitle="f.PDOther"
                                    title="f.PDOther"
                                    value={value.gratuity_paydoc_other}
            />
        </div>

        <MoneyList title="f.PDBurnPaymentsOwner" value={value.paydoc_burned_payments_owner}/>
        <MoneyList title="f.PDBurnPaymentsOther" value={value.paydoc_burned_payments_other}/>
        <MoneyList title="f.PDBurnExpired" value={value.paydoc_burned}/>
        <MoneyListDetailsDialog title="Other income" value={value.spendingTransferIncomeTotal}
                                detailsTitle="Other income" detailsData={value.spendingTransferIncomeDetails}
                                detailsColumns={spendingTransferIncomeDetailsColumnData}
                                detailsLinkTemplate={detailsDealsLinkTemplate}/>
        <MoneyList title="f.VGO" value={value.vgo}/>
    </NoWrap>;
};

export default income;
