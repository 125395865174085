import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {getUsers} from "../../services/user";
import {
    getDeletedDeals,
    navLinks
} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import XLSX from "xlsx";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import DateMonthField from "../../components/fields/DateMonthField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import { getPaymentTypes } from "../../services/sale";
import { getDealReasonClassTypes, getBillParentClassTypes } from "../../services/common";
import {loadToState} from "../../utils/helpers";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

const dateTypes = {
    period: 'period',
    month: 'month'
}

export default
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class DeletedDealListPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: null,
            date_end: null,
            businessUnitId: 0,
            businessUnitIds: [],
            approveStatus: null,
            deletedByIds: [],
            dateType: dateTypes.period,
            month: moment().startOf('month'),
            paymentType: null,
            dealReasonClassTypeIds: [],
            fromBillParentClassTypeIds: [],
            toBillParentClassTypeIds: []
        },
        refreshTable: false,
        businessUnits: [],
        selected: [],
        users: [],
        paymentTypes: [],
        dealReasonClassTypes: [],
        billParentClassTypes: []
    };

    columnData = [
        { id: 'deletedAt', label: 'Deleted at', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'deletedByName', label: 'Deleted by'},
        { id: 'reasonName', label: 'Deal name'},
        { id: 'reasonClassText', label: 'Deal (entity)' },
        { id: 'amountFormat', label: 'Sum' },
        { id: 'transactionsShort', label: 'Transactions/limits', multiline: true },
        { id: 'approveStatusText', label: 'Status' },
        { id: 'comment', label: 'Comment' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss'}
    ];

    columnDataXlsx = [
        { id: 'deletedAt', label: 'Deleted at', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'deletedByName', label: 'Deleted by'},
        { id: 'reasonName', label: 'Deal name'},
        { id: 'reasonClassText', label: 'Deal (entity)' },
        { id: 'amountFormat', label: 'Sum' },
        { id: 'approveStatusText', label: 'Status' },
        { id: 'comment', label: 'Comment' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    ];

    componentDidMount() {
        const {t} = this.props;
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });
        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });
        loadToState(this, getPaymentTypes(),'paymentTypes')
        loadToState(this, getDealReasonClassTypes(),'dealReasonClassTypes')
        loadToState(this, getBillParentClassTypes(),'billParentClassTypes')
    }

    handleChange = prop => event => {

        let value = null;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getDeletedDeals({...search, deleted: true}, ...params);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleExport = () => {
        const { search, businessUnits } = this.state;
        const from = search.date_start;
        const to = search.date_end;
        const bu = search.businessUnitId ? businessUnits.find(item => item.id === search.businessUnitId).name : 'All';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Deals");
                    XLSX.writeFile(new_workbook, `Deals ${bu} ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    renderDateFilter = () => {
        const { search } = this.state
        const { t } = this.props

        return <>
            <FormControl component="fieldset" fullWidth>
                <RadioGroup
                    value={search.dateType}
                    onChange={this.handleChange('dateType')}
                    row
                >
                    <FormControlLabel control={<Radio/>} value={dateTypes.period} label="за период"/>
                    <FormControlLabel control={<Radio/>} value={dateTypes.month} label="за месяц"/>
                </RadioGroup>
            </FormControl>
            {
                search.dateType === dateTypes.period
                    ? <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t("Deleted at")}
                        required={false}
                      />
                    : <DateMonthField
                        value={search.month}
                        onChange={this.handleChange('month')}
                        fullWidth
                        label={t("Deleted at")}
                    />
            }
            </>
    }

    render() {
        const { t } = this.props;
        const {
            refreshTable,
            search,
            businessUnits,
            users,
            paymentTypes,
            dealReasonClassTypes,
            billParentClassTypes
        } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    {this.renderDateFilter()}
                    <MultiSelectFromItemsField
                        items={businessUnits}
                        label={t('Business unit')}
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                        valueField={'id'}
                        textField={'name'}
                    />
                    <SelectFromItemsField
                        label={t("Payment")}
                        value={search.paymentType}
                        items={paymentTypes}
                        valueField={'id'}
                        textField={'text'}
                        onChange={this.handleChange('paymentType')}
                        fullWidth
                        nullable
                    />
                    <MultiSelectFromItemsField
                        items={dealReasonClassTypes}
                        label={t('Deal (entity)')}
                        value={search.dealReasonClassTypeIds}
                        onChange={this.handleChange('dealReasonClassTypeIds')}
                        fullWidth
                        valueField={'id'}
                        textField={'text'}
                    />
                    <MultiSelectFromItemsField
                        items={billParentClassTypes}
                        label={t('From')}
                        value={search.fromBillParentClassTypeIds}
                        onChange={this.handleChange('fromBillParentClassTypeIds')}
                        fullWidth
                        textField={'text'}
                    />
                    <MultiSelectFromItemsField
                        items={billParentClassTypes}
                        label={t('To')}
                        value={search.toBillParentClassTypeIds}
                        onChange={this.handleChange('toBillParentClassTypeIds')}
                        fullWidth
                        textField={'text'}
                    />
                    <AutocompleteMultiSelectField
                        options={users}
                        label={t('User')}
                        value={search.deletedByIds}
                        onChange={this.handleChange('deletedByIds')}
                        fullWidth
                        valueField={'id'}
                        textField={'username'}
                        isClearable={true}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t("Deleted deals")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        disableSorting
                />
                </Content>
            </React.Fragment>
        );
    }
}
