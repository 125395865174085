import { handleActions } from 'redux-actions';
import userActions from './user-actions';

export default handleActions({
    [userActions.fetchUserBegin]: (state, action) => {
        return {...state, isUserLoading: true, userLoadingError: null };
    },
    [userActions.fetchUserSuccess]: (state, action) => {
        return {...state, isUserLoading: false, userLoadingError: null, users: action.payload };
    },
    [userActions.fetchUserFailure]: (state, action) => {
        return {...state, isUserLoading: true, userLoadingError: action.payload, users: [] };
    },


},{
    isUserLoading: false,
    userLoadingError: null,
    users: []
});