import React from 'react';
import * as PropTypes from 'prop-types';
import AnyField, {propTypes as anyFieldPropTypes} from "./AnyField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

export default
class MultiSelectFromItemsField extends React.PureComponent
{
    static propTypes = {
        ...anyFieldPropTypes,
        items: PropTypes.array.isRequired,
        valueField: PropTypes.string,
        textField: PropTypes.string,
        emptySelectedLabel: PropTypes.string,
    };

    static defaultProps = {
        valueField: 'id',
        textField: 'name',
        emptySelectedLabel: '---Все---',
    };

    render() {
        const {items, valueField, textField, children, value, emptySelectedLabel,  ...otherProps} = this.props;

        return <AnyField
            {...otherProps}
            value={value}
            select
            SelectProps={{
                multiple: true,
                displayEmpty: true,
                renderValue: selected => {
                    if (selected.length === 0) {
                        return <span style={{fontStyle: 'italic'}}>{emptySelectedLabel}</span>;
                    }

                    return items.filter(item => selected.indexOf(item[valueField]) > -1 ).map(item => item[textField]).join(', ');
                },
            }}
        >
            {items.map((item, idx) =>
                <MenuItem key={idx} value={item[valueField]}>
                    <Checkbox checked={value && Array.isArray(value) && value.indexOf(item[valueField]) > -1} />
                    <ListItemText primary={item[textField]} />
                </MenuItem>
            )}
        </AnyField>;
    }
}

