import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Radio, Grid, FormControlLabel} from "@material-ui/core";
import * as PropTypes from 'prop-types';
import {reviewVisit} from "../../services/calendar";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import AnyField from "../../components/fields/AnyField";
import {withTranslation} from "react-i18next";

export default
@connect(null, {
    showMessage: messageDialogActions.show
})
@withTranslation()
class ReviewDialog extends React.Component
{
    static propTypes = {
        open: PropTypes.bool,
        visitId: PropTypes.string,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        open: false,
    };

    state = {
        rating: null,
        review: null,
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.open && nextProps.open !== this.props.open) {
            this.setState({
                rating: null,
                review: null,
            });
        }
    }

    handleCloseDialog = ok => () => {
        if (!ok) {
            if (this.props.onClose) {
                this.props.onClose(ok);
            }
            return;
        }

        reviewVisit(this.props.visitId, this.state.rating, this.state.review)
            .then(response => {
                if (response.success) {
                    if (this.props.onClose) {
                        this.props.onClose(ok);
                    }
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleChange = prop => event => {
        this.setState({
            [prop]: event.target.value,
        });
    };

    render() {
        const { open, t } = this.props;
        const { rating, review } = this.state;

        return (
            <Dialog
                open={ open }
                onClose={this.handleCloseDialog(false)}
            >
                <DialogTitle>{t("Visit Review")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                            {[1,2,3,4,5].map(item =>
                                <FormControlLabel
                                    key={item}
                                    label={item}
                                    labelPlacement="top"
                                    control={
                                        <Radio
                                            checked={rating === `${item}`}
                                            onChange={this.handleChange('rating')}
                                            value={item}
                                            name="rating"
                                        />
                                    }
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                value={review}
                                onChange={this.handleChange('review')}
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialog(true)} color="primary" disabled={!rating || !review}>OK</Button>
                    <Button onClick={this.handleCloseDialog(false)} color="primary">{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}