import React from 'react';
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import * as PropTypes from 'prop-types';
import AnyField from "./AnyField";
import {ThemeProvider} from "@material-ui/styles";
import {dateFieldTheme} from "../../styles";

class DateTimeField extends React.Component
{
    render() {
        const { ...other } = this.props;

        return <ThemeProvider theme={dateFieldTheme}>
            <KeyboardDateTimePicker
                {...other}
                TextFieldComponent={AnyField}
            />
        </ThemeProvider>;
    }
}

DateTimeField.propTypes = {
    value: PropTypes.any,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    initialFocusedDate: PropTypes.any,
    format: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    autoOk: PropTypes.bool,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
    animateYearScrolling: PropTypes.bool,
    labelFunc: PropTypes.func,
    renderDay: PropTypes.func,
    shouldDisableDate: PropTypes.func,
    allowKeyboardControl: PropTypes.bool,
    forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    fullWidth: PropTypes.bool,
    invalidDateMessage: PropTypes.node,
    minDateMessage: PropTypes.node,
    maxDateMessage: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    cancelLabel: PropTypes.string,
    todayLabel: PropTypes.string,
    mask: PropTypes.any,
    minutesStep: PropTypes.number,
    ampm: PropTypes.bool,
};

DateTimeField.defaultProps = {
    value: new Date(),
    format: 'DD.MM.YYYY HH:mm',
    autoOk: true,
    minDate: '1900-01-01',
    maxDate: '2100-01-01',
    initialFocusedDate: undefined,
    disableFuture: false,
    disablePast: false,
    animateYearScrolling: false,
    allowKeyboardControl: true,
    renderDay: undefined,
    labelFunc: undefined,
    shouldDisableDate: undefined,
    forwardedRef: undefined,
    fullWidth: false,
    invalidDateMessage: 'Неверная дата',
    minDateMessage: 'Неверная дата',
    maxDateMessage: 'Неверная дата',
    cancelLabel: 'Отмена',
    todayLabel: 'Сегодня',
    mask: '__.__.____ __:__',
    ampm: false,
};


export default DateTimeField;
