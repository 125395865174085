import baseRequest from "./baseReuqest";
import moment from "moment";

export function getSalarySettings(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/salary/setting', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by},
    });
}
export function getSalarySettingsFull(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/salary/setting', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by, include: 'businessUnit,employee'},
    });
}

export function getSalarySetting(id) {
    return baseRequest(`/api/salary/setting/${id}`);
}

export function saveSalarySetting(salarySetting) {
    return baseRequest('/api/salary/setting', {
        method: 'POST',
        body: salarySetting,
    });
}

export function deleteSalarySetting(salarySetting) {
    return baseRequest('/api/salary/setting', {
        method: 'DELETE',
        body: salarySetting,
    });
}

export function getSalaryFunctions() {
    return baseRequest('/api/salary/function');
}

export function getSalaryWageTypes() {
    return baseRequest('/api/salary/wage-type');
}

export function getSalaryWorkSchedules() {
    return baseRequest('/api/salary/work-schedule');
}

export function getSalaryClosedSalesVariants() {
    return baseRequest('/api/salary/closed-sales-variant');
}

const dateFormat = 'YYYY-MM-DD';

function processSearchDates(search) {
    if (moment.isMoment(search.date_start)) search.date_start = search.date_start.format(dateFormat);
    if (moment.isMoment(search.date_end)) search.date_end = search.date_end.format(dateFormat);
    if (moment.isMoment(search.month)) search.month = search.month.format(dateFormat);
}

export function getSalaryEmployee(search, page = 1, per_page = -1, order = 'asc', order_by = 'day') {
    processSearchDates(search);
    return baseRequest('/api/salary/employee', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by},
    });
}

export function getSalaryPreDebtsInfo(search) {
    processSearchDates(search);
    return baseRequest('/api/salary/create-debts', {
        query: {search},
    });
}

export function createSalaryDebts(data) {
    return baseRequest('/api/salary/create-debts', {
        method: 'POST',
        body: data,
    });
}
