import React from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    emptyTask,
    getExtraFields,
    getTask,
    getTaskTypesForTask,
    navLinks,
    saveTask
} from "../../services/tasks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import Grid from "@material-ui/core/Grid";
import ShowField from "../../components/fields/ShowField";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {connect} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import UploadFilesField from "../../components/fields/UploadFilesField";
import DateTimeField2 from "../../components/fields/DateTimeField2";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {getBusinessUnits, getDepartments} from "../../services/organization";
import {getUsers, hasRole} from "../../services/user";
import GuestField from "../../components/fields/GuestField";
import PayDocField from "../../components/fields/PayDocField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {payDocStatuses} from "../../services/payDocs";
import DateField2 from "../../components/fields/DateField2";
import moment from "moment";
import HLine from "../../components/left-bar/HLine";
import FieldsList from "../../components/fields/FieldsList";

const EXTRA_FIELDS_TYPES = {
    text: AnyField,
    number: AnyField,
    visit: ShowField,
    date: DateField2,
    dateTime: DateTimeField2,
    guest: GuestField,
    payDoc: PayDocField,
    paymentType: SelectFromItemsField,
    deliveryType: SelectFromItemsField,
    paymentStatus: SelectFromItemsField,
    payDocBatch: FieldsList
};

export default
@connect(state => ({
    currentUser: state.auth.user,
}),{
    showMessage: messageDialogActions.show,
})
class ViewPage extends React.Component
{
    state = {
        task: emptyTask,
        parentTask: null,
        taskTypes: [],
        errors: {},
        users: [],
        businessUnits: [],
        departments: [],
        extraFields: [],
    };

    componentDidMount() {
        this.loadTask(this.props.location.pathname);


        getTaskTypesForTask()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskTypes: response.data,
                    })
                }
            });

        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getDepartments()
            .then(response => {
                if (response.success) {
                    this.setState({
                        departments: response.data,
                    })
                }
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.loadTask(nextProps.location.pathname);
        }
    }

    loadTask = path => {
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getTask(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            task: ViewPage.processTask(response.data),
                        }, () => this.loadExtraFields());
                    }
                })

        } else if (this.state.parentTask) {
            this.setState({
                task: {
                    ...emptyTask,
                    parent: this.state.parentTask,
                },
                parentTask: null,
            });
        }
    };

    loadExtraFields = () => {
        getExtraFields(this.state.task.type)
            .then(response => {
                if(response.success) {
                    this.setState({
                        extraFields: response.data,
                    })
                }
            });
    };

    handleSave = () => {
        this.doSaveTask(this.state.task);
    };

    handleCanceled = () => {
        const {task} = this.state;
        const { currentUser } = this.props;
        task.canceled = true;
        task.executor = currentUser.id
        this.setState({
            task: task,
        });
        this.doSaveTask(this.state.task);
    };

    handleRecovery = () => {
        const {currentUser} = this.props;
        const {task} = this.state;
        task.executor = currentUser.id
        task.canceled = false;
        this.setState({
            task: task,
        });
        this.doSaveTask(this.state.task);
    };

    doSaveTask = (task) => {
        saveTask(task)
            .then(response => {
                if (response.success) {
                    this.setState({
                        task: ViewPage.processTask(response.data),
                    });
                    this.props.history.push(`/tasks/view/${response.data.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);

                    if (response.error && response.error.errors) {
                        this.setState({
                            errors: response.error.errors,
                        })
                    }
                }
            })
    };

    static processTask(task) {
        return {
            ...task,
            executor: task.executor ? task.executor.id: null,
            executorBusinessUnit: task.executorBusinessUnit ? task.executorBusinessUnit.id : null,
            executorDepartment: task.executorDepartment ? task.executorDepartment.id : null,
        }
    }

    handleChange = event => {
        const prop = event.target.name;
        let resetProps = {};

        if (prop === 'type') {
            const type = this.state.taskTypes.find(type => type.id === event.target.value);

            resetProps = {
                ...resetProps,
                status: type ? type.startDefaultStatus : null,
                priority: null,
            }
        }

        this.setState({
            task: {
                ...this.state.task,
                [prop]: event.target.value,
                ...resetProps,
            },
        }, () => { if (prop === 'type') this.loadExtraFields(); });
    };

    handleNextStatus = nextStatus => () => {
        const { task } = this.state;
        this.doSaveTask({
            ...task,
            status: nextStatus,
        });
    };

    handleAddChild = () => {
        this.setState({
            parentTask: {...this.state.task},
        }, () => this.props.history.push('/tasks/view/0'));
    };

    handleChangeExtraFields = (name) => (event) => {
        let value = event.target.value;
        const {status} = this.state.task;

        if(moment.isMoment(value)) {
            value = value.toISOString();
        }

        const newExtraFieldsData = {
            [name]: value
        }

        this.setState({
            task: {
                ...this.state.task,
                status: status,
                extraFieldsData: {
                    ...this.state.task.extraFieldsData,
                    ...newExtraFieldsData
                }
            }
        })
    };

    renderExtraFields = () => {
        const {extraFields, task} = this.state;

        if (extraFields && extraFields.length) {
            const fields = [];
            extraFields.sort(function (a,b) {
                if (a.sort > b.sort) return 1;
                if (b.sort > a.sort) return -1;
                return 0;
            });
            extraFields.forEach(extraField => {
                if (EXTRA_FIELDS_TYPES.hasOwnProperty(extraField.type)) {
                    const FieldComponent = EXTRA_FIELDS_TYPES[extraField.type];
                    let fieldValue = null;
                    if (task.extraFieldsData && task.extraFieldsData.hasOwnProperty(extraField.name)) {
                        fieldValue = task.extraFieldsData[extraField.name];
                    }

                    let addProps = {};
                    switch (extraField.type) {
                        case 'guest':
                            addProps = {
                                value: fieldValue,
                                showCopyPhone: true
                            }
                            break;
                        case 'paymentType':
                            addProps = {
                                value: fieldValue,
                                items: [
                                    {id: 'cash', title: 'Наличный'},
                                    {id: 'cashless', title: 'Безналичный'},
                                ],
                                textField: 'title',
                                readOnly: true,
                            };
                            break;
                        case 'deliveryType':
                            addProps = {
                                value: fieldValue,
                                items: [
                                    {id: 'courier', title:'Курьер'},
                                    {id: 'pickup', title:'Самовывоз'},
                                ],
                                textField: 'title',
                                readOnly: true,
                            };
                            break;
                        case 'paymentStatus':
                            addProps = {
                                value: fieldValue,
                                items: [
                                    {id: 'new', title: 'Не оплачен'},
                                    {id: 'paid', title: 'Оплачен'}
                                ],
                                textField: 'title',
                                readOnly: true,
                            };
                            break;
                        case 'payDoc':
                            addProps = {
                                value: fieldValue,
                                filterStatus: payDocStatuses.for_sale, //TODO: Добавить настройки экста полей
                                filterBusinessUnits: [this.state.task.executorBusinessUnit]
                            };
                            break;
                        case 'visit':
                            addProps = {
                                value: fieldValue,
                                linkTo: `/orders/view/${fieldValue}`,
                            };
                            break;
                        case 'payDocBatch':
                            addProps = {
                                items: fieldValue || [],
                                emptyItem: {payDoc: ''},
                                headers: ['ПД'],
                                limit: task.extraFieldsData.payDocBatchCountItem,
                                fields: [(item, onChange) => <PayDocField
                                    onChange={onChange('payDoc')}
                                    name={'payDoc'}
                                    value={item.payDoc}
                                    filterStatus={payDocStatuses.for_sale}
                                    filterBusinessUnits={[this.state.task.executorBusinessUnit]}
                                    fullWidth
                                    required
                                    error={!item.payDoc}
                                />]
                            }
                            break;

                        case 'number':
                            addProps = {
                                value: fieldValue
                            }
                            break;

                        default:
                            addProps = {}
                    }

                    if(extraField.name === 'amount') addProps.readOnly = true;

                    const fieldProps = {
                        label: extraField.title,
                        name: extraField.name,
                        onChange: this.handleChangeExtraFields(extraField.name),
                        fullWidth: true,
                        ...addProps
                    };

                    fields.push(<Grid item xs={4} key={extraField.name}><FieldComponent {...fieldProps}/></Grid>);

                } else {
                    console.log('Unknown field type: ' + extraField.type);
                }
            });

            return fields;
        }

        return null;
    }

    render() {
        const { currentUser } = this.props;
        const { task, taskTypes, users, businessUnits, departments } = this.state;

        const currentType = taskTypes.find(type => type.id === task.type);

        const closingVisible = (task.description ?? '').match(/ПД:\s*(\d+)\s*шт/);

        let countPdBatch = 0;
        (task?.extraFieldsData?.payDocBatch ?? []).forEach(e => {
            if (e.hasOwnProperty('payDoc') && Boolean(e.payDoc)) {
                countPdBatch++;
            }
        })

        const pdCountDescription = ((closingVisible ?? []).length === 2) ?
            Number(closingVisible[1]) :
            0;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ShowField
                    label="Номер"
                    value={task.num}
                />
                <ShowField
                    label="Создана"
                    value={task.createdAt}
                    dateFormat="D MMMM YYYY HH:mm"
                />
                <ShowField
                    label="Создал"
                    value={task.creator ? task.creator.username : null}
                />
                <ShowField
                    label="Выполнена"
                    value={task.executedAt}
                    dateFormat="D MMMM YYYY HH:mm"
                    visible={Boolean(task.executedAt)}
                />
                <ShowField
                    label="Родительская задача"
                    value={task.parent ? task.parent.num : null}
                    visible={Boolean(task.parent && task.parent.id)}
                    linkTo={`/tasks/view/${task.parent && task.parent.id}`}
                />
                {task.nextWorkflow && task.nextWorkflow.length ? <HLine/> : null}
                {task.nextWorkflow.map((workflow, idx) => <ActionButton key={idx} onClick={this.handleNextStatus(workflow.toStatus)} visible={!task.canceled} disabled={countPdBatch < pdCountDescription} color="primary">{workflow.toStatusButton}</ActionButton>)}
                <HLine/>
                <ActionButton onClick={this.handleSave} visible={!task.canceled}>Сохранить</ActionButton>
                <ActionButton onClick={this.handleAddChild} visible={Boolean(task.id) && !task.canceled}>Добавить вложенную задачу</ActionButton>
                <ActionButton onClick={this.handleCanceled} visible={Boolean(task.id) && !task.canceled && hasRole('ROLE_TASK_CANCEL')}>Архивировать</ActionButton>
                <ActionButton onClick={this.handleRecovery} visible={Boolean(task.id) && task.canceled && hasRole('ROLE_TASK_CANCEL')}>Восстановить</ActionButton>
            </LeftBar>
            <Content title="Задача">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <AnyField
                            label="Тип"
                            value={task.type}
                            name="type"
                            fullWidth
                            onChange={this.handleChange}
                            select
                            required
                            error={!task.type}
                            readOnly={Boolean(task.id)}
                        >
                            {taskTypes.map(type => <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>)}
                        </AnyField>

                    </Grid>
                    <Grid item xs={4}>
                        <AnyField
                            label="Статус"
                            value={task.status}
                            name="status"
                            fullWidth
                            onChange={this.handleChange}
                            readOnly={Boolean(task.id)}
                            select
                        >
                            {currentType ? currentType.availableStatuses.map(type => <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>) : []}
                        </AnyField>

                    </Grid>
                    <Grid item xs={4}>
                        <AnyField
                            label="Приоритет"
                            value={task.priority}
                            name="priority"
                            fullWidth
                            onChange={this.handleChange}
                            select
                        >
                            {currentType ? currentType.availablePriorities.map(type => <MenuItem key={type.id} value={type.id}>{type.title}</MenuItem>) : []}
                        </AnyField>

                    </Grid>
                    <Grid item xs={9}>
                        <AnyField
                            label="Наименование"
                            value={task.title}
                            name="title"
                            fullWidth
                            onChange={this.handleChange}
                            required
                            error={!task.title}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DateTimeField2
                            label="Срок исполнения"
                            value={task.expectDate}
                            name="expectDate"
                            fullWidth
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <AutocompleteSelectField
                            label="Исполнитель"
                            value={task.executor}
                            name="executor"
                            onChange={this.handleChange}
                            fullWidth
                            options={users.map(user => ({value: user.id, label: user.username})).sort((a, b) => {
                                if (a.value === currentUser.id) {
                                    return -1;
                                } else if (b.value === currentUser.id) {
                                    return  1;
                                } else {
                                    if (a.label < b.label) {
                                        return -1;
                                    } else {
                                        return 1;
                                    }
                                }
                            })}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <AnyField
                            label="Назначить на бизнес юнит"
                            value={task.executorBusinessUnit}
                            onChange={this.handleChange}
                            name="executorBusinessUnit"
                            select
                            fullWidth
                        >
                            {businessUnits.map(unit => <MenuItem key={unit.id} value={unit.id}>{unit.name}</MenuItem>)}
                        </AnyField>
                    </Grid>
                    <Grid item xs={4}>
                        <AutocompleteSelectField
                            label="Назачить на департамет"
                            value={task.executorDepartment}
                            name="executorDepartment"
                            onChange={this.handleChange}
                            fullWidth
                            options={departments.map(department => ({value: department.id, label: department.name}))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {this.renderExtraFields()}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <AnyField
                            label="Описание"
                            value={task.description}
                            name="description"
                            fullWidth
                            multiline
                            rows={10}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <UploadFilesField
                            id="task_files"
                            value={task.files}
                            name="files"
                            onChange={this.handleChange}
                            label="Приложение"
                            multiple
                        />
                    </Grid>
                </Grid>
            </Content>
        </React.Fragment>
    }
}