import React from "react";
import * as PropTypes from 'prop-types';
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {getDealsByReason} from "../../services/moneyFlow";


export default
class DealsByReason extends React.Component {
    state = {
        deals: [],
    };

    static propTypes = {
        reasonId: PropTypes.string,
        refreshTable: PropTypes.bool,
    };

    static defaultProps = {
        refreshTable: false,
    }

    columnsData = [
        { id: 'reasonName', label: 'Deal name'},
        { id: 'reasonClassText', label: 'Deal (entity)' },
        { id: 'amountFormat', label: 'Sum' },
        { id: 'transactionsShort', label: 'Transactions/limits', multiline: true },
        { id: 'comment', label: 'Comment' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'creatorName', label: 'Creator' },
    ];

    componentDidMount() {
        if (this.props.reasonId) {
            this.loadDeals(this.props.reasonId)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.refreshTable !== this.props.refreshTable) {
            this.loadDeals(this.props.reasonId)
        }

        if (nextProps.reasonId !== this.props.reasonId) {
            this.loadDeals(nextProps.reasonId);
        }
    }

    loadDeals(reasonId) {
        getDealsByReason(reasonId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        deals: response.data,
                    });
                }
            });
    }

    render() {
        const { deals } = this.state;

        return <DataTableLocal
            data={deals}
            columnData={this.columnsData}
            linkTemplate={deal => `/money-flow/deal/id/${deal.id}`}
            selector={false}
            pagination={false}
        />
    }
}