import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getGoodStorageSales, getGoodStorageStatuses, navLinks} from "../../services/goods";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {withRouter} from "react-router";
import moment from "moment";
import _ from "lodash";
import XLSX from "xlsx";
import DatePeriodField from "../../components/fields/DatePeriodField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getBusinessUnits} from "../../services/organization";
import {getUsers} from "../../services/user";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {processMomentFieldInObject} from "../../utils/moment-utils";

const columnData = [
    { id: 'num', label: 'Номер продажи' },
    { id: 'amountTotalFormat', label: 'Сумма' },
    { id: 'discount.name', label: 'Скидка' },
    { id: 'createdAt', label: 'Дата создания', dateFormat: 'DD.MM.YYYY' },
    { id: 'creator.username', label: 'Пользователь' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'guest.fullName', label: 'Гость' },
    { id: 'guest.phone', label: 'Телефон гостя' },
    { id: 'statusText', label: 'Статус' },
    { id: 'paymentsCashFormat', label: 'Оплаты нал', disableSorting: true},
    { id: 'paymentsCashlessFormat', label: 'Оплаты б/н', disableSorting: true},
    { id: 'paymentsPdFormat', label: 'Оплаты ПД', disableSorting: true},
    { id: 'withVisitId', label: 'С визитом', linkTemplate: row => `/orders/view/${row.withVisitId}`, disableSorting: true },
    { id: 'comment', label: 'Комментарий', maxWidth: 250, wordBreak: true},
];
const columnDataXlsx = [
    { id: 'num', label: 'Номер продажи' },
    { id: 'amountTotal.val', label: 'Сумма' },
    { id: 'amountTotal.cur', label: 'Валюта' },
    { id: 'discount.name', label: 'Скидка' },
    { id: 'createdAt', label: 'Дата создания', dateFormat: 'DD.MM.YYYY' },
    { id: 'creator.username', label: 'Пользователь' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'guest.fullName', label: 'Гость' },
    { id: 'guest.phone', label: 'Телефон гостя' },
    { id: 'statusText', label: 'Статус' },
    { id: 'paymentsCashFormat', label: 'Оплаты нал'},
    { id: 'paymentsCashlessFormat', label: 'Оплаты б/н'},
    { id: 'paymentsPdFormat', label: 'Оплаты ПД'},
    { id: 'withVisitId', label: 'С визитом' },
];

@withRouter
@withTranslation()
@connect(state => ({
    currentUser: state.auth.user,
}),{
    showMessage: messageDialogActions.show,
})
class SellListPage extends React.Component
{

    state = {
        search: {
            dateStart: moment(),
            dateEnd: moment(),
            parlour: [],
            status: [],
            businessUnits: [],
            user: [],
        },
        businessUnits: [],
        users: [],
        status: [],
        refreshTable: false,
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });
        getGoodStorageStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        status: response.data,
                    })
                }
            });
    }

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState({
            refreshTable: !this.state.refreshTable,
        })
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getGoodStorageSales(processMomentFieldInObject(search, ['dateStart', 'dateEnd']), ...params);
    };

    handleAdd = () => {
        this.props.history.push(`/goods/sell/view/0`);
    };

    handleExport = () => {
        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnDataXlsx.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXlsx.map(item => {
                                const value = _.get(row, item.id);
                                if (item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                } else {
                                    return value;
                                }
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Goods sales");
                    XLSX.writeFile(new_workbook, `Goods sales.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    rowStyle = row => {
        return { width: 100};
    };

    render() {
        const { t } = this.props;
        const { search, refreshTable, businessUnits, users, status } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.dateStart}
                        valueTo={search.dateEnd}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('dateStart')}
                        onChangeTo={this.handleChange('dateEnd')}
                        label={t('Date')}
                    />

                    <MultiSelectFromItemsField
                        items={businessUnits}
                        label={t('Business unit')}
                        value={search.businessUnits}
                        onChange={this.handleChange('businessUnits')}
                        fullWidth
                    />

                    <MultiSelectFromItemsField
                        items={users}
                        label={t('User')}
                        value={search.user}
                        onChange={this.handleChange('user')}
                        fullWidth
                        valueField={'id'}
                        textField={'username'}
                    />

                    <MultiSelectFromItemsField
                        items={status}
                        label={t('Status')}
                        value={search.status}
                        onChange={this.handleChange('status')}
                        fullWidth
                        textField={'text'}
                    />

                    <ActionButton onClick={this.handleClickSearch}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleAdd}>Создать продажу</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>

                <Content title="Продажа товара">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/goods/sell/view/${item.id}`}
                        refresh={refreshTable}
                        order="desc"
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default SellListPage;
