import React from "react";
import {Divider, withStyles} from "@material-ui/core";
import * as cn from 'classnames';
import * as PropTypes from 'prop-types';

export default
@withStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    }
}))
class HLine extends React.PureComponent
{
    static propTypes = {
        marginBottom: PropTypes.bool,
    }

    static defaultProps = {
        marginBottom: false,
    }

    render() {
        const { classes, marginBottom } = this.props;
        return <Divider className={cn(classes.root, {[classes.marginBottom]: marginBottom})}/>
    }
}