import baseRequest from "./baseReuqest";

export function getWhatsAppChannels() {
    return baseRequest(`/api/whatsapp/channels`);
}

export function sendWhatsappMessage(data) {
    return baseRequest('/api/whatsapp/send', {
        method: "POST",
        body: data,
    });
}

export function initWebhookUrl() {
    return baseRequest(`/api/whatsapp/init-webhook`);
}