import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, InputAdornment} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AnyField from "./AnyField";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import * as PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    pointer: {
        cursor: 'pointer',
    },
    compactArrow: {
        paddingLeft: 1,
        paddingRight: 1
    },
});


export default @withStyles(styles)
class DiscountSelect extends React.PureComponent {

    static propTypes = {
        discounts: PropTypes.array,
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
        readOnly: PropTypes.bool,
        required: PropTypes.bool,
        error: PropTypes.any,
    };

    static defaultProps = {
        readOnly: false,
        required: false,
        showCopyPhone: false,
        showStar: false,
        discounts: []
    };

    state = {
        anchorEl: null,
        activeDiscountGroupKeys: [],
        discountsWithGroup: {},
        discountsWithoutGroup: [],
    };

    componentDidMount() {
        this.setDiscounts(this.props.discounts);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.discounts !== this.props.discounts) {
            this.setDiscounts(nextProps.discounts);
        }
    }

    setDiscounts = (discounts) => {
        let discountsWithoutGroup = [];
        let discountsWithGroup = {};
        discounts.forEach((discount) => {
            if (discount.group) {
                if (!discountsWithGroup[discount.groupName]) {
                    discountsWithGroup[discount.groupName] = [];
                }
                discountsWithGroup[discount.groupName].push(discount);
            } else {
                discountsWithoutGroup.push(discount);
            }
        });
        this.setState({
            discountsWithGroup: discountsWithGroup,
            discountsWithoutGroup: discountsWithoutGroup
        })
    }

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClickMenuButton = (event) => {
        if (this.props.readOnly) {
            return;
        }
        this.setAnchorEl(event.currentTarget);
    };

    handleChange(event) {
        this.props.onChange(event)
    }

    setAnchorEl(el) {
        this.setState({
            anchorEl: el
        });
    }

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    find(id) {
        return this.props.discounts.find((i) => i.id === id);
    }

    clickItem = (id) => (event) => {
        this.props.onChange({...event, target: {...event.target, value: id}});
        this.handleClose();

    }

    handlePanelsChange = (name) => (event, expanded) => {
        this.setState({
            activeDiscountGroupKeys:
                {
                    [name]: expanded
                }
        })
    }

    render() {
        const {anchorEl, activeDiscountGroupKeys} = this.state
        const {fullWidth} = this.props
        const {classes, value} = this.props;
        const selected = this.find(value);
        const menuIsOpen = Boolean(anchorEl);
        return (
            <>
                <AnyField
                    label={this.props.label}
                    value={selected?.name}
                    readOnly
                    fullWidth={fullWidth}
                    onClick={this.handleClickMenuButton}
                    InputProps={{
                        classes: {
                            input: classes.pointer
                        },
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="Copy"
                                onClick={this.copy}
                                className={classes.compactArrow}
                            >
                                <ArrowDropDown/>
                            </IconButton>
                        </InputAdornment>
                    }}
                />

                <Menu
                    id="discount-menu"
                    anchorEl={anchorEl}
                    open={menuIsOpen}
                    onClose={this.handleClose}
                    onChange={this.handleChange}

                >
                    {this.state.discountsWithoutGroup.map((item, i) => <MenuItem key={i}
                                                                                 value={item.id}
                                                                                 onClick={this.clickItem(item.id)}>{item.name}</MenuItem>)}
                    {

                        Object.keys(this.state.discountsWithGroup).map((group, j) =>
                            <ExpansionPanel key={group + j}
                                            onChange={this.handlePanelsChange(`panel___${group}__${j}`)}
                                            expanded={activeDiscountGroupKeys[`panel___${group}__${j}`]}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon/>}

                                    aria-controls="panel1a-content"
                                    //  defaultexpanded={true}
                                    id="panel1a-header"
                                >
                                    <Typography>{group}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        {this.state.discountsWithGroup[group]?.map((item, i) =>
                                            <MenuItem key={i}
                                                      value={item.id}
                                                      onClick={this.clickItem(item.id)}>{item.name}</MenuItem>)}

                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>)}
                </Menu>
            </>
        );
    }
}


