import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem, withStyles
} from "@material-ui/core";
import { connect } from 'react-redux';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {createOrders} from "../../services/goods";
import {getCounterparts} from "../../services/user";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import AnyField from "../../components/fields/AnyField"
import {withRouter} from "react-router";

const styles = theme => ({
    grid: {
        alignSelf: 'center'
    },
    dialogContent: {
        minWidth: 600,
    },
    tableTitle: {
        fontWeight: 'bold',
    },
    error: {
        color: theme.palette.error.main,
    },
    placeholder: {
        color: theme.palette.text.secondary,
    },
});

@withRouter
class CreateOrdersDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            counterparts: [],
            grouped: props.grouped,
        };

    }

    componentDidMount() {
        getCounterparts()
            .then(response => {
                if (response.success) {
                    this.setState({
                        counterparts: response.data,
                    })
                }
            });
    };

    handleCloseOrdersDialog = ok => () => {
        if (!ok) {
            this.props.onClose();
            return;
        }

        createOrders(this.state.grouped, this.props.parentId)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/goods/orders`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeCounterParts = (idx) => event => {
        let grouped = [...this.state.grouped];

        grouped[idx] = {
            ...grouped[idx],
            counterpart: event.target.value,
        };

        this.setState({
            grouped,
        });
    };

    render() {

        const { classes } = this.props;
        const { counterparts, grouped } = this.state;

        return (
            <Dialog
                open={true}
                onClose={this.handleCloseOrdersDialog(false)}
                scroll="body"
                maxWidth="lg"
            >
                <DialogTitle>Создание заказов из запросов</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} className={classes.tableTitle}>Наименование товара</Grid>
                        <Grid item xs={2} className={classes.tableTitle}>Предназначение</Grid>
                        <Grid item xs={2} className={classes.tableTitle}>Салон</Grid>
                        <Grid item xs={4} className={classes.tableTitle}>Контрагенты</Grid>
                        {grouped.map((groupGood, idx) => {
                            const selectedCounterparts = groupGood.counterpart || [];
                            return (
                                <React.Fragment key={idx}>
                                    <Grid item xs={4} className={classes.grid}>{groupGood.goodName}</Grid>
                                    <Grid item xs={2} className={classes.grid}>{groupGood.purposeText}</Grid>
                                    <Grid item xs={2} className={classes.grid}>{groupGood.parlourName}</Grid>
                                    <Grid item xs={4} className={classes.grid}>
                                        <AnyField
                                            id="select-multiple-counterparts"
                                            value={selectedCounterparts}
                                            onChange={this.handleChangeCounterParts(idx)}
                                            select
                                            SelectProps={{
                                                multiple: true,
                                                displayEmpty: true,
                                                renderValue: selected => {
                                                    if (selected.length === 0) {
                                                        return <span className={classes.placeholder}>Выберите контрагентов</span>;
                                                    }

                                                    return counterparts.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.username).join(', ');
                                                },
                                            }}
                                            fullWidth
                                        >
                                            {counterparts.map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Checkbox checked={selectedCounterparts.indexOf(item.id) > -1} />
                                                    <ListItemText primary={item.username} />
                                                </MenuItem>
                                            ))}
                                        </AnyField>
                                    </Grid>
                                </React.Fragment>
                            )})}
                    </Grid>
                    { (grouped.length !== grouped.filter(item => item.counterpart).length) ? <div className={classes.error}>Необходимо указать контагентов</div> : null }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseOrdersDialog(true)} disabled={grouped.length !== grouped.filter(item => item.counterpart).length}  color="primary">OK</Button>
                    <Button onClick={this.handleCloseOrdersDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
});

export default connect(mapStateToProps, {showInfo: infoActions.show, showMessage: messageDialogActions.show, showConfirm: confirmDialogActions.show})(withStyles(styles)(CreateOrdersDialog));
