import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/personalAccount";
import CalendarField from "../../components/fields/CalendarField";
import Content from "../../components/content/Content";
import {connect} from "react-redux";
import calendarEmployeeActions from "../calendarEmployee/calendarEmployee-actions";
import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    withStyles
} from "@material-ui/core";
import Timesheets from "../calendarEmployee/Timesheets";
import {
    activityTypeClear,
    activityTypes,
    getActivityTypeColor,
    getActivityTypeText,
} from "../../services/calendarEmployee";
import Moment from 'moment';
import {extendMoment} from "moment-range";

const moment = extendMoment(Moment);

const styles = theme => ({
    legendText: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.secondary,
        fontSize: '1rem',
        lineHeight: '1rem',
    },
})

@connect(state => ({
        currentDate: state.calendar.currentDate,
        user: state.auth.user,
    }),
    {
        setCurrentDate: calendarEmployeeActions.setCurrentDate,
    })

@withStyles(styles)

class PersonalReportCard extends React.Component
{
    state = {
        week: [],
        employee: {},
        parlour: null,
        activityType: null,
    }

    componentDidMount() {
        this.selectWeek(this.props.currentDate);

        if (this.props.user.employee) {
            this.setState({
                parlour: this.props.user.employee.parlour,
            })
        }
    }

    selectWeek(currentDate) {
        let weekday = currentDate.isoWeekday();
        let firstDay = moment(currentDate.format()).subtract(weekday - 1, 'days');
        let week = [];

        week.push(moment(firstDay.format()));

        for (let i = 1; i < 7; i++) {
            week.push(moment(firstDay.format()).add(i, 'days'));
        }

        this.setState({
            week: week,
        })

    }

    render() {
        const {currentDate, classes} = this.props;
        const {employee} = this.props.user;
        let {parlour, activityType} = this.state;
        let employeeSorted = {};

        if (employee) {
            employeeSorted[`${employee.employeePost}`] = [employee];
        }

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <CalendarField
                        value={currentDate}
                        onChange={this.handleDateChange}
                    />
                </LeftBar>
                <Content title="Мое расписание">
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="position"
                                    name="position"
                                    value={this.state.activityType}
                                    onChange={this.handleChangeActivityType}
                                    row
                                >
                                    {Object.keys(activityTypes).map((status, i)  =>
                                        <FormControlLabel
                                            value={status}
                                            key={i}
                                            control={<Radio style={{color: getActivityTypeColor(status),
                                                '&$checked': {
                                                    color: getActivityTypeColor(status),
                                                },}} />}
                                            label={getActivityTypeText(status)}
                                            labelPlacement="end"
                                            className={classes.legendText}
                                        />
                                    )}
                                    <FormControlLabel
                                        value={activityTypeClear}
                                        control={<Radio color="default" />}
                                        label="Очистить"
                                        labelPlacement="end"
                                        className={classes.legendText}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Timesheets
                                currentDate={currentDate}
                                employees={employeeSorted}
                                parlour = {parlour}
                                activityType={activityType}
                                />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        )
    }

    handleDateChange = date => {
        this.props.setCurrentDate(date);
    };

    handleChangeActivityType = event => {
        this.setState({
            activityType: event.target.value,
        })
    }
}

export default PersonalReportCard