import React from 'react'
import * as PropTypes from 'prop-types'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from "@material-ui/core";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import NumberRangeField from "../../components/fields/NumberRangeField";
import AnyField from "../../components/fields/AnyField";
import DateField2 from "../../components/fields/DateField2";
import GuestField from "../../components/fields/GuestField";
import moment from "moment";
import {createMultiplePayDocs, getPayDocStatuses, payDocStatuses} from "../../services/payDocs";
import {withTranslation} from "react-i18next";
import {getParlours} from "../../services/organization";
import infoActions from "../../components/info/info-actions"
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import { connect } from "react-redux"

const allowedPayDocStatuses = [
    payDocStatuses.for_sale,
    payDocStatuses.sold,
    payDocStatuses.active
]

export default
@withTranslation()
@connect(null,
    {
        showInfo: infoActions.show,
        showMessage: messageDialogActions.show
    }
)

class PayDocCreateMultiplePayDocsDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        onCreate: PropTypes.func,
        pdTypes: PropTypes.array
    }

    static defaultProps = {
        open: false
    }

    state = {
        dialogLoading: false,

        pdStatuses: [],
        businessUnits: [],

        payDocsParams: {
            businessUnitId: null,
            numIntFrom: null,
            numIntTo: null,
            typeId: null,
            status: allowedPayDocStatuses[0],
            validity: moment().add(6, "months"),
            canRefill: true
        }
    }

    componentDidMount() {

        this.setState({
            dialogLoading: true
        })

        getPayDocStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        pdStatuses: response.data
                    })
                } else {
                    this.showResponseError(response)
                }
            })
            .then(getParlours)
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                        dialogLoading: false
                    });
                } else {
                    this.setState({
                        dialogLoading: false,
                    });
                    this.showResponseError(response)
                }
            });
    }

    render() {

        const {
            pdStatuses,
            dialogLoading,
            payDocsParams
        } = this.state

        const { t, open, onClose, pdTypes } = this.props

        const isPayDocsParamsFilled =
            payDocsParams.businessUnitId
            && payDocsParams.numIntFrom
            && payDocsParams.numIntTo
            && payDocsParams.status
            && allowedPayDocStatuses.indexOf(payDocsParams.status) > -1
            && payDocsParams.typeId
            && (this.isGuestIdRequired() ?
                !!payDocsParams.guestId:
                true
            )

        return <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{t("Add certificates to the database")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SelectFromItemsField
                            label={t("Type of payment document")}
                            value={payDocsParams.typeId}
                            items={pdTypes}
                            name="typeId"
                            onChange={this.handleChangePayDocsParams}
                            fullWidth
                            autoSelectFirst
                            disabled={dialogLoading}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberRangeField
                            label={t("Number range")}
                            valueFrom={payDocsParams.numIntFrom}
                            valueTo={payDocsParams.numIntTo}
                            nameFrom="numIntFrom"
                            nameTo="numIntTo"
                            onChangeFrom={this.handleChangePayDocsParams}
                            onChangeTo={this.handleChangePayDocsParams}
                            disabled={dialogLoading}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectFromItemsField
                            label={t("Parlour")}
                            name={"businessUnitId"}
                            value={payDocsParams.businessUnitId}
                            onChange={this.handleChangePayDocsParams}
                            items={this.getAvailableBusinessUnitsByTypeId(payDocsParams.typeId)}
                            fullWidth
                            nullable
                            disabled={dialogLoading}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            label={t("Status")}
                            name="status"
                            value={payDocsParams.status}
                            onChange={this.handleChangePayDocsParams}
                            fullWidth
                            select
                            disabled={dialogLoading}
                        >
                            {pdStatuses.map(status =>
                                <MenuItem
                                    key={status.id}
                                    value={status.id}
                                    disabled={allowedPayDocStatuses.indexOf(status.id) === -1}
                                >
                                    {status.text}
                                </MenuItem>
                            )
                            }
                        </AnyField>
                    </Grid>
                    <Grid item xs={6}>
                        <DateField2
                            label={t("payDoc.validity")}
                            name={"validity"}
                            value={payDocsParams.validity}
                            onChange={this.handleChangePayDocsParams}
                            fullWidth
                            disabled={dialogLoading || (!this.isValidityCanBeSet())}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GuestField
                            label={t("Guest")}
                            value={payDocsParams.guestId}
                            name="guestId"
                            readOnly={dialogLoading}
                            onChange={this.handleChangePayDocsParams}
                            required={this.isGuestIdRequired()}
                            error={this.isGuestIdRequired() && !payDocsParams.guestId}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color={"primary"}
                    onClick={this.handleCreateMultiplePayDocs}
                    disabled={!isPayDocsParamsFilled}
                >
                    {t("Create")}
                </Button>
                <Button
                    color={"primary"}
                    onClick={onClose}
                >
                    {t("Cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    }

    handleChangePayDocsParams = (event) => {
        this.setState({
                payDocsParams: {
                    ...this.state.payDocsParams,
                    [event.target.name]: event.target.value
                }
            },
            () => {
                if (event.target.name === 'typeId') {
                    this.setState({
                        payDocsParams: {
                            ...this.state.payDocsParams,
                            businessUnitId: null
                        }
                    })
                }
            });
    };

    handleCreateMultiplePayDocs = () => {

        const {
            businessUnitId,
            numIntFrom,
            numIntTo,
            validity,
            status,
            typeId,
            guestId,
            canRefill
        } = this.state.payDocsParams

        const { t, onCreate, onClose } = this.props

        const request = {
            businessUnitId,
            numIntFrom,
            numIntTo,
            validity: this.isValidityCanBeSet() ? validity : null,
            status,
            typeId,
            guestId,
            canRefill
        }

        this.setState({
                dialogLoading: true
            },
            () => createMultiplePayDocs(request)
                .then(response => {
                    if (response.success) {
                        this.setState({
                                dialogLoading: false
                            },
                            () => {
                            this.props.showInfo(t("PD have been successfully added to the database!"))})
                            onCreate()
                            onClose()
                    } else {
                        this.setState({
                            dialogLoading: false
                        })
                        this.showResponseError(response)
                    }
                })
        )
    }

    getAvailableBusinessUnitsByTypeId = (typeId) => {

        const { businessUnits } = this.state
        const { pdTypes } = this.props

        const pdType = pdTypes.find(type => type.id === typeId)

        return pdType && businessUnits.length > 0 ?
            businessUnits.filter(
                businessUnit =>
                    pdType.availableInBusinessUnits.indexOf(businessUnit.id) > -1
            ):
            []
    }

    showResponseError = (response) =>
        this.props.showMessage(response.error ? response.error.message : response.message)

    isGuestIdRequired = () => {

        const { payDocsParams } = this.state

        return payDocsParams.status === payDocStatuses.sold || payDocsParams.status === payDocStatuses.active
    }

    isValidityCanBeSet = () => {
        const { payDocsParams } = this.state

        return payDocsParams.status !== payDocStatuses.for_sale
    }
}