import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, MenuItem
} from '@material-ui/core';
import {
    getBusinessUnits,
    getExternalSubject, getExternalSubjectTypes,
    getJuridicalPersons,
    getReferralPrograms, getRegions,
    navLinks, saveExternalSubject,
    getExternalSubjectTypeValues,getCommissionSchemes
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import CheckboxField from "../../components/fields/CheckboxField";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import PhoneField from "../../components/fields/PhoneField";
import {withTranslation} from "react-i18next";
import UploadFilesField from "../../components/fields/UploadFilesField";
import GuestField from "../../components/fields/GuestField";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {getUsers} from "../../services/user";
import PropertyReseller from "./common/PropertyReseller";
import {getCurrencies} from "../../services/common";

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    container: {
        width: 900,
        marginBottom: 10,
    },
    cb: {
        marginTop: 10,
    }
});

export default
@connect(null, {
    showMessage: messageDialogActions.show,
})
@withRouter
@withTranslation()
@withStyles(styles)
class ExternalSubjectViewPage extends React.Component {

    state = {
        externalSubject: {
            id: null,
            name: null,
            type: null,
            typeText: null,
            comment: null,
            juridicalPersonId: null,

            commissionFormula: null,
            commissionScheme: null,
            price: null,

            num: null,
            pin: null,
            phone: null,
            email: null,
            messengers: null,
            card: null,
            contract  : null,
            guestId: null,
            userId: null,
            payDocName: null,
            referralProgramId: null,
            businessUnitRegionId: null,
            businessUnitsIds: [],
            currency: null,
            payDocPartner: null,
            isClientOfCompany: false,
        },

        juridicalPersons: [],
        types: [],
        typeValues: [],
        businessUnits: [],
        users: [],
        programs: [],
        regions: [],
        commissionSchemes: [],
        currencies: [],
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getExternalSubject(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            externalSubject: response.data
                        })
                    }
                });
        }

        getJuridicalPersons()
            .then(response => {
                if (response.success) {
                    this.setState({
                        juridicalPersons: response.data,
                    })
                }
            });

        getExternalSubjectTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data,
                    })
                }
            });

        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data
                    })
                }
            });

        getReferralPrograms()
            .then(response => {
                if (response.success) {
                    this.setState({
                        programs: response.data
                    });
                }
            });

        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data
                    });
                }
            });

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getExternalSubjectTypeValues()
            .then(response => {
                if (response.success) {
                    this.setState({
                        typeValues: response.data,
                    })
                }
            });

        getCommissionSchemes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        commissionSchemes: response.data,
                    })
                }
            });

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data
                    });
                }
            });
    }

    handleChange = prop => event => {
        const { externalSubject } = this.state;
        this.setState({ externalSubject: {...externalSubject, [prop]: prop === 'phone' ? event : event.target.value} });
    };

    handleSave = () => {
        saveExternalSubject(this.state.externalSubject)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/external-subjects`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };
    handleChangePayDocPartner = event => {
        const { externalSubject } = this.state;
        this.setState({
                externalSubject: {
                    ...externalSubject,
                    payDocPartner : event.target.value,
                }
            }
        );
    };

    render() {
        const { classes, t } = this.props;
        const { externalSubject, juridicalPersons, types, users, programs, regions, businessUnits, typeValues,
            commissionSchemes, currencies} = this.state;

        const isReferralPartner = externalSubject.type === 'referral_partner';

        const saveDisabled = !externalSubject.name ||
                            !externalSubject.type ||
            (isReferralPartner &&
                (!externalSubject.pin || !externalSubject.phone || !externalSubject.guestId || !externalSubject.businessUnitRegionId ||
                    (!externalSubject.businessUnitRegionId && !externalSubject.referralProgramId) || !externalSubject.currency)
            );

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleSave} disabled={saveDisabled}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Карточка внешнего субъекта">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="name"
                                label="Название"
                                value={externalSubject.name}
                                fullWidth
                                error={!externalSubject.name}
                                onChange={this.handleChange('name')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Тип"
                                value={externalSubject.type}
                                onChange={this.handleChange('type')}
                                error={!externalSubject.type }
                                fullWidth
                                readOnly={!!externalSubject.id}
                                select
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                {types.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteSelectField
                                label="Юридическое лицо"
                                value={externalSubject.juridicalPersonId}
                                onChange={this.handleChange('juridicalPersonId')}
                                fullWidth
                                select
                                options={juridicalPersons.map(item => ({value: item.id, label: item.shortName}))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={6}>
                            <AnyField
                                id="comment"
                                label="Комментарий"
                                value={externalSubject.comment}
                                fullWidth
                                multiline={true}
                                rows={4}
                                onChange={this.handleChange('comment')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Доступно для салонов"
                                value={externalSubject.businessUnitsIds}
                                onChange={this.handleChange('businessUnitsIds')}
                                select
                                SelectProps={{
                                    multiple: true,
                                    displayEmpty: true,
                                    renderValue: selected => {
                                        if (selected.length === 0) {
                                            return <span className={classes.placeholder}>Не выбрано</span>;
                                        }

                                        return businessUnits.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                                    },
                                }}
                                fullWidth
                            >
                                {businessUnits.map(item => (
                                    <MenuItem key={item.id} value={item.id}>
                                        <Checkbox checked={ externalSubject.businessUnitsIds.indexOf(item.id) > -1 } />
                                        <ListItemText primary={item.name} />
                                    </MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!externalSubject.payDocPartner}
                                name="payDocPartner"
                                onChange={this.handleChangePayDocPartner}
                                label="Партнер ПД"
                                margin="none"
                            />
                        </Grid>
                    </Grid>

                    {
                        externalSubject.type === typeValues.RESELLER ?
                            <PropertyReseller
                                externalSubject = {externalSubject}
                                commissionSchemes = {commissionSchemes}
                                handleChange = { this.handleChange }
                                />
                                : null
                    }


                    <Grid item xs={4}>
                        <CheckboxField
                            value={externalSubject.isClientOfCompany}
                            name="isClientOfCompany"
                            onChange={this.handleChange("isClientOfCompany")}
                            label="Является клиентом компании"
                        />
                    </Grid>
                    {isReferralPartner &&
                        <React.Fragment>
                            <Typography variant="h5">Реферальный партнер</Typography>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={4}>
                                    <AnyField
                                        label="Номер"
                                        placeholder={'Автогенерация'}
                                        value={externalSubject.num}
                                        fullWidth
                                        required
                                        onChange={this.handleChange('num')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        label="PIN"
                                        value={externalSubject.pin}
                                        fullWidth
                                        required
                                        error={isReferralPartner && !externalSubject.pin}
                                        onChange={this.handleChange('pin')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <PhoneField
                                        label={t("Phone")}
                                        defaultCountry="ru"
                                        value={externalSubject.phone}
                                        onChange={this.handleChange('phone')}
                                        fullWidth
                                        error={isReferralPartner && !externalSubject.phone}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={4}>
                                    <GuestField
                                        label={t("Guest")}
                                        value={externalSubject.guestId}
                                        error={!externalSubject.guestId}
                                        onChange={this.handleChange('guestId')}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AutocompleteSelectField
                                        label="Является пользователем"
                                        value={externalSubject.userId}
                                        onChange={this.handleChange('userId')}
                                        fullWidth
                                        options={users.map(item => ({value: item.id, label: item.username}))}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        label="ПД для бартера (автоматически)"
                                        value={externalSubject.payDocName}
                                        fullWidth
                                        readOnly
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={4}>
                                    <AnyField
                                        label="Email"
                                        value={externalSubject.email}
                                        fullWidth
                                        onChange={this.handleChange('email')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        label="Карта"
                                        value={externalSubject.card}
                                        fullWidth
                                        onChange={this.handleChange('card')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        label="Основная валюта"
                                        value={externalSubject.currency}
                                        fullWidth
                                        error={!externalSubject.currency}
                                        onChange={this.handleChange('currency')}
                                        select
                                        required
                                    >
                                        <MenuItem value={null}>Не выбрано</MenuItem>
                                        {currencies.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={6}>
                                    <AnyField
                                        label="Регион"
                                        value={externalSubject.businessUnitRegionId}
                                        onChange={this.handleChange('businessUnitRegionId')}
                                        fullWidth
                                        error={ !externalSubject.businessUnitRegionId && !externalSubject.referralProgramId }
                                        select
                                    >
                                        <MenuItem value={null}>Не выбрано</MenuItem>
                                        {regions.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </AnyField>
                                    <AnyField
                                        label="Или индивидуальная реферальная программа"
                                        value={externalSubject.referralProgramId}
                                        onChange={this.handleChange('referralProgramId')}
                                        error={ !externalSubject.businessUnitRegionId && !externalSubject.referralProgramId }
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value={null}>Не выбрано</MenuItem>
                                        {programs.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        label="Мессенджеры"
                                        value={externalSubject.messengers}
                                        multiline
                                        rows={4}
                                        fullWidth
                                        onChange={this.handleChange('messengers')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <UploadFilesField
                                        id="contract"
                                        label="Договор"
                                        value={externalSubject.contract}
                                        onChange={this.handleChange('contract')}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Content>
            </React.Fragment>
        );
    }
}
