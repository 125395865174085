import dayWithBu from "./dayWithBu";
import parentDayWithBu from "./parentDayWithBu";
import income from "./income";
import spending from "./spending";
import profit from "./profit";
import debts from "./debts";
import incomeVisits from "./incomeVisits";
import incomeGoods from "./incomeGoods";
import incomingValue from "./incomingValue";
import revenue from "./revenue";
import otherSupply from "./otherSupply";
import dayMasterWorkload from "./dayMasterWorkload";
import pdBalance from "./pdBalance";
import distributionCheck from "./distributionCheck";
import vgo from "./vgo";

const process = {
    dayWithBu,
    parentDayWithBu,
    income,
    spending,
    profit,
    debts,
    incomeVisits,
    incomeGoods,
    vgo,
    incomingValue,
    revenue,
    otherSupply,
    dayMasterWorkload,
    pdBalance,
    distributionCheck,
};

export default process;
