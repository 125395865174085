import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import UsersListPage from "./UsersListPage";
import ContactTargetsPage from "./ContactTargetsPage";
import ViewContactTargetPage from "./ViewContactTargetPage";
import GoodCategoriesListPage from "./GoodCategoriesListPage";
import GoodsListPage from "./GoodsListPage";
import BusinessRolesListPage from "./BusinessRolesListPage";
import BusinessRolesViewPage from "./BusinessRolesViewPage";
import PricesListPage from "./PricesListPage";
import ViewPrices from "./ViewPrices";
import DiscountsPage from "./DiscountsPage";
import DiscountGroupListPage from "./DiscountGroupListPage";
import SpendingTypesListPage from "./SpendingTypesListPage";
import SpendingTypeCategoriesListPage from "./SpendingTypeCategoriesListPage";
import IncomingTypesListPage from "./IncomingTypesListPage";
import MedicalCardZonePrescriptionsListPage from "./MedicalCardZonePrescriptionsListPage";
import TestTelegramPage from "./TestTelegramPage";
import GeneralSettingsPage from "./GeneralSettingsPage";
import DiscountDateRestrictionPage from "./DiscountDateRestrictionPage";
import WhatsappPage from "./WhatsappPage";
import SystemTelegramChannelsPage from "./SystemTelegramChannelsPage";
import SystemEventsPage from "./SystemEventsPage";
import TypeActivityPage from './TypeActivityPage';
import ActivityGroupPage from './ActivityGroupPage';
import UploadPeriodSettingsPage from './UploadPeriodSettingsPage';
import SettingNameForActivity from "./SettingNameForActivity";
import ClubSubscriptionTypesPage from "./ClubSubscriptionTypesPage";
import VisitChannelsPage from "./visit-channels";
import DomRuPhonesPage from "./domRu-phones";
import SettingBitrixPage from "./SettingBitrixPage";
import MobileSettingsPage from "./MobileSettingsPage";
import ExecutorsPage from "./Executors";
import ExecutorDetailsPage from "./Executors/ExecutorDetailsPage";
import ExportsPage from "./ExportSqlDocs/ExportsPage";
import ExportDetailsPage from "./ExportSqlDocs/ExportDetailsPage";

class SettingsPage extends React.Component {
    render() {
        return (
            <Switch>

                <Route path="/settings/setting-name-for-activity" component={SettingNameForActivity}/>
                <Route path="/settings/type-activity" component={TypeActivityPage}/>
                <Route path="/settings/activity-group" component={ActivityGroupPage}/>
                <Route exact path="/settings" render={() => (<Redirect to="/settings/users"/>)}/>
                <Route exact path="/settings/users" component={UsersListPage}/>
                <Route exact path="/settings/targets" component={ContactTargetsPage}/>
                <Route path="/settings/targets/view" component={ViewContactTargetPage}/>

                <Route path="/settings/good-categories" component={GoodCategoriesListPage}/>
                <Route path="/settings/goods" component={GoodsListPage}/>
                <Route exact path="/settings/business-roles" component={BusinessRolesListPage}/>
                <Route path="/settings/business-roles/view" component={BusinessRolesViewPage}/>
                <Route path="/settings/discounts" component={DiscountsPage}/>
                <Route path="/settings/discount-date-restriction" component={DiscountDateRestrictionPage}/>
                <Route exact path="/settings/prices" component={PricesListPage}/>
                <Route path="/settings/prices/view" component={ViewPrices}/>
                <Route path="/settings/prices/edit" component={ViewPrices}/>
                <Route path="/settings/prices/copy" component={ViewPrices}/>
                <Route exact path="/settings/spending-types" component={SpendingTypesListPage}/>
                <Route exact path="/settings/spending-types/categories" component={SpendingTypeCategoriesListPage}/>
                <Route exact path="/settings/incoming-types" component={IncomingTypesListPage}/>
                <Route path="/settings/club-subscription-types" component={ClubSubscriptionTypesPage}/>
                <Route path="/settings/visit-channels-page" component={VisitChannelsPage}/>
                <Route exact path="/settings/medical-card-zone-prescription"
                       component={MedicalCardZonePrescriptionsListPage}/>
                <Route exact path="/settings/test-telegram" component={TestTelegramPage}/>
                <Route path="/settings/general" component={GeneralSettingsPage}/>
                <Route path="/settings/whatsapp" component={WhatsappPage}/>
                <Route path="/settings/discount-group" component={DiscountGroupListPage}/>
                <Route path="/settings/system-telegram-channels" component={SystemTelegramChannelsPage}/>
                <Route path="/settings/system-events" component={SystemEventsPage}/>
                <Route path="/settings/upload-period-settings" component={UploadPeriodSettingsPage}/>
                <Route path="/settings/dom-ru-phones" component={DomRuPhonesPage}/>
                <Route path="/settings/bitrix" component={SettingBitrixPage}/>
                <Route path="/settings/mobile" component={MobileSettingsPage}/>
                <Route exact path="/settings/executors" component={ExecutorsPage}/>
                <Route path="/settings/executor/view/:id" component={ExecutorDetailsPage}/>
                <Route exact path="/settings/exports" component={ExportsPage}/>
                <Route path="/settings/export/view/:id" component={ExportDetailsPage}/>
            </Switch>
        );
    }
}

export default SettingsPage;