import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getCurrentBillsStateInvestors, getCurrentBillsStateInvestorsCredits,
    getCurrentBillsStateInvestorsDebts,
    navLinks
} from "../../services/moneyFlow";
import {Grid} from "@material-ui/core";
import moment from "moment/moment";
import {getBusinessUnits, getInvestors} from "../../services/organization";
import CheckboxField from "../../components/fields/CheckboxField";
import ActionButton from "../../components/button/ActionButton";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import DataTable from "../../components/data-table/DataTable";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import {formatMoney} from "../../services/common";
import DateMonthField from "../../components/fields/DateMonthField";

const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@withStyles(styles)
@withTranslation()
class CurrentBillsStateInvestorPage extends React.Component {

    state = {
        search: {
            monthStart: moment().add(1, 'day').startOf('month'),
            monthEnd: moment().add(0, 'day').endOf('month'),
            businessUnitIds: [],
            investorIds: [],
            debts: false,
            dividends: false,
            credits: false
        },
        businessUnits: [],
        investors: [],
        refreshTable: true,
        refreshTableDebts: true,
    };

    columnData = [
        { id: 'investorName', label: 'Имя', width: '12.4%'},
        { id: 'businessUnitName', label: 'Салон', width: '12.4%' },
        { id: 'tableType', label: 'Тип отчета', width: '12.4%' },
        { id: 'debtStart', label: 'Переплата / долг на начало периода', processValue: formatMoney, width: '12.4%' },
        { id: 'accrued', label: 'Начислено', processValue: formatMoney, width: '12.4%' },
        { id: 'paid', label: 'Выплачено', processValue: formatMoney, width: '12.4%' },
        { id: 'debtEnd', label: 'Переплата / долг на конец периода', processValue: formatMoney, width: '12.4%' },
        { id: 'periodStart', label: 'Период', dateFormat: "MMM yyyy", width: '12.4%' },
    ];

    componentDidMount() {
        getBusinessUnits({'active': 1})
            .then(res => {
                if (res.success) {
                    this.setState({
                        ...this.state,
                        businessUnits: res.data
                    });
                }
            });

        getInvestors({})
            .then(res => {
                if (res.success) {
                    this.setState({
                        ...this.state,
                        investors: res.data
                    })
                }
            });
    }

    getTableData = (...params) => {
        const {search} = this.state;

        return getCurrentBillsStateInvestors(
            processMomentFieldInObject(search, ['date']), ...params
        );
    };

    getTableDataDebts = (...params) => {
        const {search} = this.state;

        return getCurrentBillsStateInvestorsDebts(
            processMomentFieldInObject(search, ['date']), ...params
        );
    };

    getTableDataCredits = (...params) => {
        const {search} = this.state;

        return getCurrentBillsStateInvestorsCredits(
            processMomentFieldInObject(search, ['date']), ...params
        );
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
        this.setState(state => ({ refreshTableDebts: !state.refreshTableDebts }));
    };

    handleClickTelegram = () => {
        // @todo: макет сообщения и сама высылка будет программироваться позже
    }

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: (event instanceof moment) ? event : event.target.value,
            }
        });
    };

    render() {
        const { t } = this.props;
        const { search, businessUnits, investors, refreshTable, refreshTableDebts } = this.state;
        const disabled = (!search.debts && !search.dividends && !search.credits)

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <DateMonthField
                                value={search.monthStart}
                                label='Месяц с'
                                onChange={this.handleChange('monthStart')}
                                fullWidth
                                format={'MMM YYYY'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateMonthField
                                value={search.monthEnd}
                                label='Месяц до'
                                onChange={this.handleChange('monthEnd')}
                                fullWidth
                                format={'MMM YYYY'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteMultiSelectField
                                label={t('Business units')}
                                value={search.businessUnitIds}
                                name="businessUnitIds"
                                onChange={this.handleChange('businessUnitIds')}
                                fullWidth
                                options={businessUnits}
                                placeholder={t('---All---')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteMultiSelectField
                                label={t('Investors')}
                                value={search.investorIds}
                                name="investorIds"
                                onChange={this.handleChange('investorIds')}
                                fullWidth
                                options={investors}
                                placeholder={t('---All---')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CheckboxField
                                name="dividends"
                                label="Дивиденды"
                                onChange={this.handleChange('dividends')}
                                value={search.dividends}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CheckboxField
                                name="debts"
                                label="Займ от учредителя"
                                onChange={this.handleChange('debts')}
                                value={search.debts}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CheckboxField
                                name="credits"
                                label="Кредит"
                                onChange={this.handleChange('credits')}
                                value={search.credits}
                            />
                        </Grid>
                    </Grid>
                    <ActionButton onClick={this.handleClickFilter} disabled={disabled}>{t('Filter')}</ActionButton>
                    <ActionButton>{t('Send report to telegram')}</ActionButton>
                </LeftBar>

                <Content title={t('Investor report')}>
                    {
                        search.dividends &&
                        <DataTable
                            columnData={this.columnData}
                            dataFunc={this.getTableData}
                            selector={false}
                            pagination={false}
                            autoload={false}
                            refresh={refreshTable}
                            rowStyleFunc={(row) => {
                                if (row.isTotal) {
                                    return {backgroundColor: '#eee'};
                                }
                            }}
                        />
                    }
                    {
                        search.debts &&
                        <DataTable
                            columnData={this.columnData}
                            dataFunc={this.getTableDataDebts}
                            selector={false}
                            pagination={false}
                            autoload={false}
                            refresh={refreshTableDebts}
                            rowStyleFunc={(row) => {
                                if (row.isTotal) {
                                    return {backgroundColor: '#eee' };
                                }
                            }}
                        />
                    }
                    {
                        search.credits &&
                        <DataTable
                            columnData={this.columnData}
                            dataFunc={this.getTableDataCredits}
                            selector={false}
                            pagination={false}
                            autoload={false}
                            refresh={refreshTableDebts}
                            rowStyleFunc={(row) => {
                                if (row.isTotal) {
                                    return {backgroundColor: '#eee' };
                                }
                            }}
                        />
                    }
                </Content>
            </React.Fragment>
        );
    }
}
