import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getDeals,
    navLinks
} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import XLSX from "xlsx";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

export default
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class DealListPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: null,
            date_end: null,
            businessUnitIds: [],
            approveStatus: null
        },
        refreshTable: false,
        businessUnits: [],
        selected: []
    };

    columnData = [
        { id: 'reasonName', label: 'Deal name'},
        { id: 'reasonClassText', label: 'Deal (entity)' },
        { id: 'amountFormat', label: 'Sum' },
        { id: 'transactionsShort', label: 'Transactions/limits', multiline: true },
        { id: 'approveStatusText', label: 'Status' },
        { id: 'comment', label: 'Comment' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    ];

    columnDataXlsx = [
        { id: 'reasonName', label: 'Deal name'},
        { id: 'reasonClassText', label: 'Deal (entity)' },
        { id: 'amountFormat', label: 'Sum' },
        { id: 'approveStatusText', label: 'Status' },
        { id: 'comment', label: 'Comment' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    ];

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value = null;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getDeals(search, ...params);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleExport = () => {
        const { search } = this.state;
        const from = search.date_start;
        const to = search.date_end;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Deals");
                    XLSX.writeFile(new_workbook, `Deals ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };


    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t("Show deals by date interval")}
                        className={classes.filterControl}
                        required={false}
                    />
                    <AutocompleteMultiSelectField
                        label={t("Business unit")}
                        value={search.businessUnitIds}
                        textField={'name'}
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                        options={businessUnits}
                        className={classes.filterControl}
                    />
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        className={classes.filterControl}
                        label={t("Search")}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t("Deals")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        autoload={false}
                        linkTemplate={ (row) => `/money-flow/deal/id/` + row.id }
                    />
                </Content>
            </React.Fragment>
        );
    }
}
