import React from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import { getFacility, getHistoryChange, navLinks} from "../../services/facilities";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";

const columnData = [
    { id: 'dt', numeric: false, disablePadding: false, label: 'Дата и время', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'user', numeric: false, disablePadding: false, label: 'Пользователь' },
    { id: 'field', numeric: false, disablePadding: false, label: 'Измененный параметр' },
    { id: 'oldValue', numeric: false, disablePadding: false, label: 'Значение "было"' },
    { id: 'newValue', numeric: false, disablePadding: false, label: 'Значение "стало"' },
];

@withRouter
class HistoryChangePage extends React.Component {

    state = {
        facility: {
            id: null,
            code: null,
            type: null,
            name: null,
            slim: false,
            active: false,
            description: '',
        },
        types: [],
        refreshTable: false,
    };


    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);

        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getFacility(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            facility: response.data
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/facilities/view/${this.state.facility.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getHistoryChange(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { facility, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink="История изменения"/>}>
                    <ShowField
                        label = "Вид услуги"
                        value = {facility.type}
                    />
                    <ShowField
                        label = "Наименование услуги"
                        value = {facility.name}
                    />

                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>
                <Content title="История изменения">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        order="desc"
                        orderBy="dt"
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default HistoryChangePage;
