import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    navLinks,
} from "../../services/settings";
import Content from "../../components/content/Content";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import CalendarField from "../../components/fields/CalendarField";
import moment from "moment";
import {
    getDiscounts,
    saveDiscountDateRestriction,
    getDiscountDateRestriction, getDiscountDateRestrictionHistory
} from "../../services/calendar";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import DataTable from "../../components/data-table/DataTable";
import { getBusinessUnits, getRegions } from "../../services/organization";
import AnyField from "../../components/fields/AnyField";
import CheckboxField from "../../components/fields/CheckboxField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DatePeriodField from "../../components/fields/DatePeriodField";
import { processMomentFieldInObject } from "../../utils/moment-utils";
import { emptyDiscountDateRestriction } from "../../entities/discount";
import TimeField from "../../components/fields/TimeField";

export default @withTranslation()
@connect(state => ({
    currentDate: state.calendar.currentDate,
}), {
    showMessage: messageDialogActions.show,
})
class DiscountDateRestrictionPage extends React.Component {
    state = {
        selectedDate: moment(),
        dialogEditOpen: false,
        dialogHistoryOpen: false,
        refreshTableByDay: false,
        refreshTableByFilter: false,
        refreshTableHistory: false,
        discounts: [],
        regions: [],
        businessUnits: [],
        weekDays: [],
        discountDateRestriction: {
            ...emptyDiscountDateRestriction
        },
        search: {
            dateStart: moment(),
            dateEnd: moment(),
            regions: [],
            parlours: [],
            discounts: [],
            daysOfWeek: [],
            active: false,
        },
        historyData: [],
    }

    componentDidMount() {
        Promise.all([
            getDiscounts({})
                .then(response => {
                    if (response.success) {
                        this.setState({
                            discounts: Array.isArray(response.data) ? response.data : [response.data],
                        })
                    }
                }),
            getRegions({})
                .then(response => {
                    if (response.success) {
                        this.setState({
                            regions: Array.isArray(response.data) ? response.data : [response.data],
                        })
                    }
                }),
            getBusinessUnits({})
                .then(response => {
                    if (response.success) {
                        this.setState({
                            businessUnits: Array.isArray(response.data) ? response.data : [response.data],
                        })
                    }
                }),
        ]).then(() => {
            this.setState({
                refreshTableByDay: !this.state.refreshTable,
                refreshTableByFilter: !this.state.refreshTable
            }, this.getWeekDays)
        })
    }

    processHistoryFieldValue = (value, t, row) => {
        switch (row.fieldId) {
            case 'discounts':
                return value && value.length > 0 ? value.map((id) => {
                    return this.state.discounts.find(t => t.id === id).name
                }).join(', ') : 'Все'
            case 'businessUnits':
                return value && value.length > 0 ? value.map((id) => {
                    return this.state.businessUnits.find(t => t.id === id).name
                }).join(', ') : 'Все'
            case 'businessUnitRegions':
                return value && value.length > 0 ? value.map((id) => {
                    return this.state.regions.find(t => t.id === id).name
                }).join(', ') : 'Все'
            case 'daysOfWeek':
                return value && value.map((id) => {
                    return this.state.weekDays.find(t => t.id === parseInt(id)).name
                }).join(', ')
            case 'dateTo':
                return value ? moment(value).format('YYYY-MM-DD') : ''
            case 'dateFrom':
                return value ? moment(value).format('YYYY-MM-DD') : ''
            case 'timeFrom':
                return value ? moment(value).format('HH:mm') : ''
            case 'timeTo':
                return value ? moment(value).format('HH:mm') : ''
            default:
                return value
        }
    }

    columnDataByDay = [
        { id: 'createdAt', label: 'Created at', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'creatorName', label: 'Creator' },
        {
            id: 'discounts',
            label: 'Discount',
            processValue: (discountIds) => {
                return discountIds.length === 0
                    ? 'Все'
                    : this.state.discounts
                        .filter(discount => {
                            return discountIds.includes(discount.id)
                        })
                        .map(discount => {
                            return discount.name
                        })
                        .join(', ')
            }
        },
        {
            id: 'businessUnits',
            label: 'Business unit',
            processValue: (businessUnitIds) => {
                return businessUnitIds.length === 0
                    ? 'Все'
                    : this.state.businessUnits
                        .filter(businessUnit => {
                            return businessUnitIds.includes(businessUnit.id)
                        })
                        .map(businessUnit => {
                            return businessUnit.name
                        })
                        .join(', ')
            }
        },
        {
            id: 'businessUnitRegions',
            label: 'Region',
            processValue: (businessUnitRegionIds) => {
                return businessUnitRegionIds.length === 0
                    ? 'Все'
                    : this.state.regions
                        .filter(businessUnitRegion => {
                            return businessUnitRegionIds.includes(businessUnitRegion.id)
                        })
                        .map(businessUnitRegion => {
                            return businessUnitRegion.name
                        })
                        .join(', ')
            }
        },
        { id: 'comment', label: 'Comment' },
    ];

    columnDataByFilter = [
        { id: 'createdAt', label: 'Created at', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'date', label: 'Date', dateFormat: 'DD.MM.YYYY' },
        { id: 'creatorName', label: 'Creator' },
        {
            id: 'discounts',
            label: 'Discount',
            processValue: (discountIds) => {
                return discountIds.length === 0
                    ? 'Все'
                    : this.state.discounts
                        .filter(discount => {
                            return discountIds.includes(discount.id)
                        })
                        .map(discount => {
                            return discount.name
                        })
                        .join(', ')
            }
        },
        {
            id: 'businessUnits',
            label: 'Business unit',
            processValue: (businessUnitIds) => {
                return businessUnitIds.length === 0
                    ? 'Все'
                    : this.state.businessUnits
                        .filter(businessUnit => {
                            return businessUnitIds.includes(businessUnit.id)
                        })
                        .map(businessUnit => {
                            return businessUnit.name
                        })
                        .join(', ')
            }
        },
        {
            id: 'businessUnitRegions',
            label: 'Region',
            processValue: (businessUnitRegionIds) => {
                return businessUnitRegionIds.length === 0
                    ? 'Все'
                    : this.state.regions
                        .filter(businessUnitRegion => {
                            return businessUnitRegionIds.includes(businessUnitRegion.id)
                        })
                        .map(businessUnitRegion => {
                            return businessUnitRegion.name
                        })
                        .join(', ')
            }
        },
        { id: 'comment', label: 'Comment' },
    ];

    columnDataHistory = [
        { id: 'dt', label: 'Created at', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'fieldName', label: 'Поле' },
        { id: 'user', label: 'User' },
        {
            id: 'oldValue',
            label: 'Было',
            processValue: this.processHistoryFieldValue
        },
        {
            id: 'newValue',
            label: 'Стало',
            processValue: this.processHistoryFieldValue
        }
    ];

    getTableDataByDay = (page, per_page, order) => {
        return getDiscountDateRestriction({ date: this.state.selectedDate.format('YYYY-MM-DD') }, page, per_page, order);
    };

    getTableDataByFilter = (page, per_page, order) => {
        return getDiscountDateRestriction(processMomentFieldInObject(this.state.search, ['dateStart', 'dateEnd']), page, per_page, order);
    };

    rowStyleFunction = (row) => {
        if (row.active) return { backgroundColor: 'rgb(102, 255, 102)' }
        else return { backgroundColor: 'gray' }
    }

    openEditDialog = () => {
        this.setState({
            dialogEditOpen: true,
        })
    }

    closeEditDialog = () => {
        this.setState({
            dialogEditOpen: false,
            discountDateRestriction: {
                ...emptyDiscountDateRestriction
            }
        })
    }

    getTableDataHistory = (page, per_page, order) => {
        return getDiscountDateRestrictionHistory({ restriction: this.state.discountDateRestriction.id }, page, per_page, order);
    };

    openHistoryDialog = () => {
        this.setState({
            dialogHistoryOpen: true,
            refreshTableHistory: !this.state.dialogHistoryOpen
        })
    }

    closeHistoryDialog = () => {
        this.setState({
            dialogHistoryOpen: false,
        })
    }

    getWeekDays = () => {
        let currentDate = this.props.currentDate;
        let weekDay = currentDate.startOf('week');
        let weekDays = [{ id: weekDay.isoWeekday(), name: weekDay.format('dd') }];

        for (let i = 0; i < 6; i++) {
            weekDay.add(1, 'day');
            weekDays.push({ id: weekDay.isoWeekday(), name: weekDay.format('dd') });
        }

        this.setState({
            weekDays: weekDays,
        })
    }

    handleEdit = discountDateRestriction => {
        this.setState({
            discountDateRestriction,
            dialogEditOpen: true,
        })
    };

    handleChangeCalendarDate = (date) => {
        this.setState({
            selectedDate: date,
            refreshTableByDay: !this.state.refreshTableByDay,
            discountDateRestriction: {
                ...emptyDiscountDateRestriction,
                date: date.format('YYYY-MM-DD'),
            }
        })
    }

    handleChange = (event) => {
        this.setState({
            discountDateRestriction: {
                ...this.state.discountDateRestriction,
                [event.target.name]: event.target.value,
            }
        })
    }

    handleChangeFrom = (date) => {
        this.setState({
            discountDateRestriction: {
                ...this.state.discountDateRestriction,
                dateFrom: date.format('YYYY-MM-DD'),
            }
        })
    }

    handleChangeTo = (date) => {
        this.setState({
            discountDateRestriction: {
                ...this.state.discountDateRestriction,
                dateTo: date.format('YYYY-MM-DD'),
            }
        })
    }

    handleChangeTimeFrom = (time) => {
        if (time) {
            time = moment(time).second(0);
            this.setState({
                discountDateRestriction: {
                    ...this.state.discountDateRestriction,
                    timeFrom: time.format(),
                }
            })
        }
    }

    handleChangeTimeTo = (time) => {
        if (time) {
            time = moment(time).second(0);
            this.setState({
                discountDateRestriction: {
                    ...this.state.discountDateRestriction,
                    timeTo: time.format(),
                }
            })
        }
    }

    handleChangeSearchDateStart = date => {
        this.setState({
            search: {
                ...this.state.search,
                dateStart: date,
            }
        })
    }

    handleChangeSearchDateEnd = date => {
        this.setState({
            search: {
                ...this.state.search,
                dateEnd: date,
            }
        })
    }

    handleChangeSearch = (event) => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value,
            }
        })
    }

    handleSave = () => {
        saveDiscountDateRestriction(this.state.discountDateRestriction)
            .then(response => {
                if (response.success) {
                    this.setState({
                        discountDateRestriction: emptyDiscountDateRestriction,
                        dialogEditOpen: false,
                        refreshTableByDay: !this.state.refreshTableByDay,
                        refreshTableByFilter: !this.state.refreshTableByFilter,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleFilter = () => {
        this.setState({
            refreshTableByFilter: !this.state.refreshTableByFilter,
        })
    }

    render() {
        const { t } = this.props;
        const {
            selectedDate,
            dialogEditOpen,
            dialogHistoryOpen,
            discounts,
            discountDateRestriction,
            refreshTableByDay,
            refreshTableByFilter,
            refreshTableHistory,
            businessUnits,
            regions,
            search,
            weekDays,
        } = this.state

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks} />}>
                <CalendarField
                    onChange={this.handleChangeCalendarDate}
                    value={selectedDate}
                />
                <ActionButton onClick={this.openEditDialog}>{t('Create')}</ActionButton>
                <Divider variant="middle" style={{ marginTop: 20, marginBottom: 15 }} />
                <DatePeriodField
                    valueFrom={search.dateStart}
                    valueTo={search.dateEnd}
                    onChangeFrom={this.handleChangeSearchDateStart}
                    onChangeTo={this.handleChangeSearchDateEnd}
                    label={t('Date')}
                />
                <MultiSelectFromItemsField
                    items={discounts}
                    label={t('Discount')}
                    value={search.discounts}
                    name="discounts"
                    onChange={this.handleChangeSearch}
                    fullWidth
                    valueField="id"
                    textField="name"
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.parlours}
                    name="parlours"
                    onChange={this.handleChangeSearch}
                    fullWidth
                    valueField="id"
                    textField="name"
                />
                <MultiSelectFromItemsField
                    items={regions}
                    label={t('Region')}
                    value={search.regions}
                    name="regions"
                    onChange={this.handleChangeSearch}
                    fullWidth
                    valueField="id"
                    textField="name"
                />
                <MultiSelectFromItemsField
                    items={weekDays}
                    value={search.daysOfWeek}
                    name="daysOfWeek"
                    onChange={this.handleChangeSearch}
                    label="Дни недели"
                    valueField="id"
                    textField="name"
                    fullWidth
                />
                <CheckboxField
                    label={'Активные'}
                    name="active"
                    value={search.active}
                    onChange={this.handleChangeSearch}
                />
                <CheckboxField
                    label={'Для внутрених (из ERP)'}
                    name="forInternal"
                    value={search.forInternal}
                    onChange={this.handleChangeSearch}
                />
                <CheckboxField
                    label={'Для внешних (виджет, сайт)'}
                    name="forExternal"
                    value={search.forExternal}
                    onChange={this.handleChangeSearch}
                />
                <ActionButton onClick={this.handleFilter}>{t('Filter')}</ActionButton>
            </LeftBar>
            <Content title="Ограничения для скидок">
                <Typography>
                    Ограничения для {selectedDate.format('DD.MM.YYYY')}
                </Typography>
                <DataTable
                    columnData={this.columnDataByDay}
                    dataFunc={this.getTableDataByDay}
                    refresh={refreshTableByDay}
                    onClick={this.handleEdit}
                    selector={false}
                    pagination={true}
                    rowStyleFunc={this.rowStyleFunction}
                />
                <Typography>
                    Поиск по фильтру
                </Typography>
                <DataTable
                    columnData={this.columnDataByFilter}
                    dataFunc={this.getTableDataByFilter}
                    refresh={refreshTableByFilter}
                    onClick={this.handleEdit}
                    selector={false}
                    pagination={true}
                    rowStyleFunc={this.rowStyleFunction}
                />
            </Content>

            <Dialog
                open={dialogEditOpen}
                onClose={this.closeEditDialog}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth={"md"}
                fullWidth
            >
                <DialogTitle id="dialog-title">Ограничение</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <DatePeriodField
                                valueFrom={discountDateRestriction.dateFrom}
                                valueTo={discountDateRestriction.dateTo}
                                onChangeFrom={this.handleChangeFrom}
                                onChangeTo={this.handleChangeTo}
                                label={t('Date')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelectFromItemsField
                                items={weekDays}
                                value={discountDateRestriction.daysOfWeek}
                                name="daysOfWeek"
                                onChange={this.handleChange}
                                label="Дни недели"
                                valueField="id"
                                textField="name"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TimeField
                                onChange={this.handleChangeTimeFrom}
                                value={moment.utc(discountDateRestriction.timeFrom)}
                                label={t('timeFrom')}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TimeField
                                onChange={this.handleChangeTimeTo}
                                value={moment.utc(discountDateRestriction.timeTo)}
                                label={t('timeTo')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label={t('Comment')}
                                name="comment"
                                value={discountDateRestriction.comment}
                                onChange={this.handleChange}
                                required
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelectFromItemsField
                                items={discounts}
                                value={discountDateRestriction.discounts}
                                name="discounts"
                                onChange={this.handleChange}
                                label={t('Discount')}
                                valueField="id"
                                textField="name"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelectFromItemsField
                                items={businessUnits}
                                value={discountDateRestriction.businessUnits}
                                name="businessUnits"
                                onChange={this.handleChange}
                                label={t('Business units')}
                                valueField="id"
                                textField="name"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelectFromItemsField
                                items={regions}
                                value={discountDateRestriction.businessUnitRegions}
                                name="businessUnitRegions"
                                onChange={this.handleChange}
                                label={t('Region')}
                                valueField="id"
                                textField="name"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                label={'Активно'}
                                name="active"
                                value={discountDateRestriction.active}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                label={'Для внутрених (ERP)'}
                                name="forInternal"
                                value={discountDateRestriction.forInternal}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                label={'Для внешних (виджет, сайт)'}
                                name="forExternal"
                                value={discountDateRestriction.forExternal}
                                onChange={this.handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!discountDateRestriction.id || businessUnits.length === 0 || discounts.length === 0 || regions.length === 0}
                        onClick={this.openHistoryDialog}
                        color="primary">
                        {t("History")}
                    </Button>
                    <Button onClick={this.handleSave} color="primary">{t("Save")}</Button>
                    <Button onClick={this.closeEditDialog} color="primary">{t("Close")}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={dialogHistoryOpen}
                onClose={this.closeHistoryDialog}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth={"md"}
                fullWidth
            >
                <DialogTitle id="dialog-title">{t("History")}</DialogTitle>
                <DialogContent>
                    <DataTable
                        columnData={this.columnDataHistory}
                        dataFunc={this.getTableDataHistory}
                        refresh={refreshTableHistory}
                        selector={false}
                        pagination={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeHistoryDialog} color="primary">{t("Close")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    }
}