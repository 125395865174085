import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    MenuItem,
    Typography,
    Fab,
    Grid
} from '@material-ui/core';
import {
    createParlour,
    navLinks,
    getBusinessUnitTypes,
    getRegions,
    getJuridicalPersons,
    getBusinessUnits,
    getBusinessUnit, getExternalSubjects, getEvotorStores, getInvestors, getHumanEmployees
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import TimeField from "../../components/fields/TimeField";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import moment from 'moment';
import _ from "lodash";
import {getFacilities, getPriceBy, priceStatus} from "../../services/facilities";
import {withRouter} from "react-router";
import * as cn from "classnames";
import Add from "@material-ui/icons/Add";
import {getConsumptionGroups, getGoods} from "../../services/goods";
import ClearIcon from "@material-ui/icons/Clear";
import {
    emptyMoney,
    getCurrencies,
    getEvents,
    getMessengers,
    getTimeZones,
    getTelegramChats,
    getGratuityQRImage, gratuityQRTypes
} from "../../services/common";
import MoneyField from "../../components/fields/MoneyField";
import NumberFormatDecimal3 from "../../components/fields/NumberFormatDecimal3";
import CheckboxField from "../../components/fields/CheckboxField";
import ColorField from "../../components/fields/ColorField";
import PercentField from "../../components/fields/PercentField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {getGuestMessengerTypes} from "../../services/guests";
import {getPaymentTypes} from "../../services/sale";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {Link} from "react-router-dom";
import {getPayDocTypes} from "../../services/payDocs";
import TransferListField from "../../components/fields/TransferListField";
import FieldsList from "../../components/fields/FieldsList";
import IntegerField from "../../components/fields/IntegerField";
import {withTranslation} from "react-i18next";
import {hasRole} from "../../services/user";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import PercentFieldDecimal from "../../components/fields/PercentFieldDecimal";
import PayDocTypeField from "../../components/fields/PayDocTypeField";

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    workTimeLabel: {
        textAlign: 'center'
    },
    workTimeLabel_textLeft: {
        textAlign: 'left'
    },
    workShiftBlockButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    blockTitleWorkSchedule: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    blockWorkTime: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    subheading: {
        marginBottom: 15,
        marginTop: 45
    },
    del: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonDel: {
        marginTop: 29,
    },
    colorField: {
        width: 100
    },
    field: {
        marginTop: 0,
    },
    payDocCommissionPeriodTable: {
        '& tbody tr' : {
            verticalAlign: 'top'
        },
    },
    autoCompleteMultiSelectField: {
        width: 300
    },
    investorSharePercentField: {
        width: 80,
    },
    investorShareMoneyField: {
        width: 100,
    }
});

const timeFormat = "HH:mm";
const defaultStart = moment("09:00", timeFormat);
const defaultFinish = moment("18:00", timeFormat);
const investorShareFormats = {
    amount: 'amount',
    formula: 'formula',
    percent: 'percent'
}

@withRouter
@withTranslation()
class ParlourViewPage extends React.Component {

    state = {
        parlour: {
            id: null,
            name: null,
            displayName: null,
            relaxTime: 15,
            type: null,
            workTime: {
                1: {
                    day: 1,
                    start: defaultStart,
                    finish: defaultFinish,
                },
                2: {
                    day: 2,
                    start: defaultStart,
                    finish: defaultFinish,
                },
                3: {
                    day: 3,
                    start: defaultStart,
                    finish: defaultFinish,
                },
                4: {
                    day: 4,
                    start: defaultStart,
                    finish: defaultFinish,
                },
                5: {
                    day: 5,
                    start: defaultStart,
                    finish: defaultFinish,
                },
                6: {
                    day: 6,
                    start: defaultStart,
                    finish: defaultFinish,
                },
                7: {
                    day: 7,
                    start: defaultStart,
                    finish: defaultFinish,
                }
            },
            visitGoodsConsumption: [],
            regionId: null,
            juridicalPersonId: null,
            parentId: null,
            address: null,
            coordinates: null,
            price1: null,
            price2: null,
            workShift: [],
            mainCurrency: null,
            usingCurrencies: [],
            usingCurrenciesCashless: [],
            courseModifications: {},
            hourCosts: {...emptyMoney},
            timeZone: null,
            onlineEntry: false,
            onlineEntryOnlyMail: false,
            subscription: [],
            checkGuestOverlaps: false,
            pickupAvailable: false,
            masterWorkTime: null,
            scheduleLines: [],
            active: true,
            external: false,
            exportDashboard: false,
            exportAdminsAndMastersDashboard: false,
            gratuityByPD: false,
            gratuityDefaultPercent: null,
            gratuityAdminPercent: null,
            gratuityBusinessUnitPercent: null,
            gratuityEnabled: false,
            bonusesEnabled: false,
            bonusesEdges: [],
            bonusPaymentMaxPercent: null,
            bonusChangeNotificationTypes: [],
            formulaForCalcRelaxTime: null,
            showAdminGaps: false,
            availablePaymentTypes: [],
            defaultResellerId: null,
            clearCashBoxOnCloseWorkShift: null,
            ignoreGoodConsumptions: false,
            availablePayDocTypes: [],
            availableFacilities: [],
            safeNotificationsEnabled: false,
            safeNotificationsPriority: 100,
            trustedJuridicalPersons: [],
            evotorStoreIds: [],
            rooms: [],
            timeFilterForWidget: [],
            payDocCommissionPeriods: [],
            reviewLink: null,
            whatsAppCreateVisitMessageComment: null,
            visitAutoConfirmation: false,
            printCheckEnable: false,
            scheduleStaffLinkLastPart: null,
            showAddressInCertificate:false,
            unloadFinancialActivitiesGoogle: false,
            createAcquiringSpendingDeal: false,
            displayInSchedule: false,
            confirmClosingVisitBySms: false,
            visitCommissionPeriods: [],
            passports: [],
            profitERPSharePeriods: [],
            profitInvestorSharePeriods: [],
            profitManagementCompanySharePeriods: [],
            managerId: null
        },
        businessUnitTypes: [],
        regions: [],
        juridicalPersons: [],
        managementCompanies: [],
        prices: [],
        goodStorageUsing: [],
        currencies: [],
        timeZones: [],
        messengers: [],
        events: [],
        telegramChats: [],
        guestMessengerTypes: [],
        paymentTypes: [],
        resellers: [],
        payDocTypes: [],
        consumptionGroups: [],
        facilities: [],
        evotorStores: [],
        autoCreatePayDocForCashless: false,
        autoCreatePayDocGift: false,
        autoCreatePayDocGiftValue: null,
        autoCreatePayDocGiftType: null,
        investors: [],
        employees: [],
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getBusinessUnit(resultId[0])
                .then(response => {
                    if (response.success) {

                        _.forOwn(response.data.workTime, value => {
                            value.start = moment(value.start, timeFormat);
                            value.finish = moment(value.finish, timeFormat);
                        });

                        _.forOwn(response.data.workShift, value => {
                            value.start = moment(value.start, timeFormat);
                            value.end = moment(value.end, timeFormat);
                        });

                        if ( !Object.keys(response.data.workTime).length ) {
                            response.data.workTime = this.state.parlour.workTime;
                        }

                        if (response.data.visitGoodsConsumption === null) {
                            response.data.visitGoodsConsumption = [];
                        }

                        if (response.data.scheduleLines) {
                            response.data.scheduleLines.forEach(line => {
                                line.time = moment(line.time).utcOffset(response.data.timeZone);
                            })
                        }

                        if (response.data.timeFilterForWidget) {
                            response.data.timeFilterForWidget.forEach(time => {
                                time.time = moment(time.time).utcOffset(response.data.timeZone);
                            })
                        }

                        this.setState({
                            parlour: response.data
                        })
                    }
                });
        }

        getBusinessUnitTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnitTypes: response.data,
                    })
                }
            });

        getRegions()
            .then(response => {
            if (response.success) {
                this.setState({
                    regions: response.data,
                })
            }
        });

        getJuridicalPersons({companyDepartment: true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        juridicalPersons: response.data,
                    })
                }
            });

        getBusinessUnits({'type': 'management_company'})
            .then(response => {
            if (response.success) {
                this.setState({
                    managementCompanies: response.data,
                })
            }
        });
        getPriceBy({status: priceStatus.active})
            .then(response => {
                if (response.success) {
                    this.setState({
                        prices: response.data
                    })
                }
            })

        getGoods()
            .then(response => {
                if (response.success) {
                    this.setState({
                        goodStorageUsing: response.data
                    });
                }
            });

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data
                    });
                }
            });

        getTimeZones()
            .then(response => {
                if (response.success) {
                    this.setState({
                        timeZones: response.data
                    });
                }
            });

        getMessengers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        messengers: response.data
                    })
                }
            });

        getEvents()
            .then(response => {
                if (response.success) {
                    this.setState({
                        events: response.data
                    })
                }
            });

        getTelegramChats()
            .then(response => {
                if (response.success) {
                    this.setState({
                        telegramChats: response.data
                    })
                }
            });

        getGuestMessengerTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        guestMessengerTypes: response.data
                    })
                }
            });

        getPaymentTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        paymentTypes: response.data,
                    });
                }
            });

        getExternalSubjects({'type': 'reseller', 'businessUnitId': resultId.length && resultId[0] !== '0' ? resultId[0] : null})
            .then(response => {
                if (response.success) {
                    this.setState( {
                        resellers: response.data
                    });
                }
            });

        getPayDocTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDocTypes: response.data,
                    });
                }
            })

        getConsumptionGroups()
            .then(response => {
                if (response.success) {
                    this.setState({
                        consumptionGroups: response.data,
                    })
                }
            });

        getFacilities(null, 1, -1, 'asc', 'code')
            .then(response => {
                if (response.success) {
                    this.setState({
                        facilities: response.data.map(facility => ({...facility, name: facility.code + ' - ' + facility.name})),
                    })
                }
            });

        getEvotorStores(null, 1, -1, 'asc', 'name')
            .then(response => {
                if (response.success) {
                    this.setState({
                        evotorStores: response.data,
                    })
                }
            })

        getInvestors()
            .then(response => {
                if (response.success) {
                    this.setState({
                        investors: response.data,
                    })
                }
            });

        getHumanEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [
                            { id: '0', name: '---Нет---'},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = event => {
        this.setState({
            parlour: {
                ...this.state.parlour,
                [event.target.name]: event.target.value,
            }
        });
    };

    handlePostChangeInvestorShareItem = (item, prop) => {
        const newItem = {...item}

        if (Object.values(investorShareFormats).includes(prop)) {
            const anotherFormats = Object.values(investorShareFormats).filter(f => prop !== f)

            anotherFormats.forEach(af => {
                newItem[af] = null
            })
        }

        return newItem
    }

    handleWorkTimeChange = (day, type) => date => {
        let workTime = this.state.parlour.workTime;
        workTime[day][type] = date;
        this.setState({
            parlour: {
                ...this.state.parlour,
                workTime: workTime,
            }
        });
    };

    handleWorkShiftChange = (type, shiftId) => date => {
        let {workShift} = this.state.parlour;
        workShift[shiftId][type] = date;

        this.setState({
            parlour: {
                ...this.state.parlour,
                workShift: workShift,
            }
        });
    };

    handleChangeCourseModifications = currency => event => {
        this.setState({
            parlour: {
                ...this.state.parlour,
                courseModifications: {
                    ...this.state.parlour.courseModifications,
                    [currency]: event.target.value,
                }
            }
        });
    };

    handleSave = () => {
        const { parlour } = this.state;
        let parsedWorkShift = [];

        parlour.workShift.forEach(shift => {
            if (shift.start && shift.end) {
                parsedWorkShift.push({...shift, start: shift.start.format(timeFormat), end: shift.end.format(timeFormat)})
            }

        });

        parlour.passports = parlour?.passports.map(passport => passport.file) ?? [];

        createParlour({
            ...parlour,
            workTime: _.mapValues(parlour.workTime, day => ({...day, start: day.start.format(timeFormat), finish: day.finish.format(timeFormat)})),
            workShift: parsedWorkShift,
            scheduleLines: parlour.scheduleLines.map(line => ({...line, time: moment(line.time).utcOffset(parlour.timeZone, true)})),
            timeFilterForWidget: parlour.timeFilterForWidget.map(time => ({...time, time: moment(time.time).utcOffset(parlour.timeZone, true)}))
        })
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/parlours`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleHistory = () => {
        this.props.history.push(`/organization/history-parlour/${this.state.parlour.id}`);
    };

    handleAddWorkShift = () => {
        let {workShift, workTime} = this.state.parlour;
        let start = null;
        let end = null;

        if (workShift === undefined) {
            workShift = [];
        }

        if (workShift.length === 0) {
            for (let dayWeek in workTime) {
                start = workTime[dayWeek].start;
                end = workTime[dayWeek].finish;
                break;
            }
        }

        workShift.push({start:start, end:end});

        this.setState({
            parlour: {
                ...this.state.parlour,
                workShift: workShift,
            }
        })
    };

    handleVisitGoodsConsumptionChange = (index, prop) => event => {
        let visitGoodsConsumption = [ ...this.state.parlour.visitGoodsConsumption ];
        visitGoodsConsumption[index][prop] = event.target.value;

        this.setState({
            parlour: {
                ...this.state.parlour,
                visitGoodsConsumption
            }
        });
    };

    handleVisitGoodsConsumptionDelete = (index) => () => {
        let visitGoodsConsumption = [ ...this.state.parlour.visitGoodsConsumption ];
        visitGoodsConsumption.splice(index, 1);

        this.setState({
            parlour: {
                ...this.state.parlour,
                visitGoodsConsumption
            }
        });
    };

    handleVisitGoodsConsumptionAdd = () => {
        this.setState({
            parlour: {
                ...this.state.parlour,
                visitGoodsConsumption: [
                    ...this.state.parlour.visitGoodsConsumption,
                    {
                        good: null,
                        consumptionGroup: null,
                        deltaMass: null
                    }
                ]
            }
        });
    };

    handleAddSubscription = () => {
        let { subscription } = this.state.parlour;
        let lengthSubscription = subscription.length;

        if (
            lengthSubscription > 0
            && (!subscription[lengthSubscription - 1]['messenger']
            || !subscription[lengthSubscription - 1]['recipient']
            || !subscription[lengthSubscription - 1]['event'])
        ) {
            return;
        }

        subscription.push({
            id: null,
            messenger: null,
            recipient: null,
            event: null
        });

        this.setState({
            parlour: {
                ...this.state.parlour,
                subscription: subscription
            }
        })
    };

    handleChangeSubscribe = (id, prop) => event => {
        let { subscription } = this.state.parlour;

        subscription[id][prop] = event.target.value;

        this.setState({
            parlour: {
                ...this.state.parlour,
                subscription
            }
        })
    };

    handleSubscribeDel = (idSubscribe) => () => {
        let { subscription } = this.state.parlour;

        subscription = subscription.filter((subscribe, id) => id !== idSubscribe);

        this.setState({
            parlour: {
                ...this.state.parlour,
                subscription: subscription,
            }
        })

    };

    handleScheduleLinesChange = (index, prop) => event => {
        let scheduleLines = [ ...this.state.parlour.scheduleLines ];

        scheduleLines[index][prop] = event && event.target ? event.target.value : event;

        this.setState({
            parlour: {
                ...this.state.parlour,
                scheduleLines
            }
        });
    };
    handleScheduleLinesDelete = (index) => () => {
        let scheduleLines = [ ...this.state.parlour.scheduleLines ];
        scheduleLines.splice(index, 1);

        this.setState({
            parlour: {
                ...this.state.parlour,
                scheduleLines
            }
        });
    };

    handleScheduleLinesAdd = () => {
        if (this.state.parlour.scheduleLines.length >= 25) {
            return;
        }

        this.setState({
            parlour: {
                ...this.state.parlour,
                scheduleLines: [
                    ...this.state.parlour.scheduleLines,
                    {
                        time: null,
                        color: null
                    }
                ]
            }
        });
    };

    handleBonusesEdgesChange = (index, prop) => event => {
        let bonusesEdges = [ ...this.state.parlour.bonusesEdges ];

        bonusesEdges[index][prop] = event && event.target ? event.target.value : event;

        this.setState({
            parlour: {
                ...this.state.parlour,
                bonusesEdges
            }
        });
    };

    handleBonusesEdgesDelete = (index) => () => {
        let bonusesEdges = [ ...this.state.parlour.bonusesEdges ];
        bonusesEdges.splice(index, 1);

        this.setState({
            parlour: {
                ...this.state.parlour,
                bonusesEdges
            }
        });
    };

    handleBonusesEdgesAdd = () => {
        this.setState({
            parlour: {
                ...this.state.parlour,
                bonusesEdges: [
                    ...this.state.parlour.bonusesEdges,
                    {
                        edge: null,
                        mod: null
                    }
                ]
            }
        });
    };

    handleRoomChange = (index, prop) => event => {
        let rooms = [ ...this.state.parlour.rooms ];

        rooms[index][prop] = event && event.target ? event.target.value : event;

        this.setState({
            parlour: {
                ...this.state.parlour,
                rooms
            }
        });
    };

    handleRoomDelete = (index) => () => {
        let rooms = [ ...this.state.parlour.rooms ];
        rooms.splice(index, 1);

        this.setState({
            parlour: {
                ...this.state.parlour,
                rooms
            }
        });
    };

    handleRoomAdd = () => {
        this.setState({
            parlour: {
                ...this.state.parlour,
                rooms: [
                    ...this.state.parlour.rooms,
                    {
                        id: null,
                        name: null,
                        count: 1
                    }
                ]
            }
        });
    }

    handleChangeImage = event => {
        const { parlour } = this.state;

        const files = [];
        if (event.target.value) {
            files.push(event.target.value);
        }

        this.setState({
            parlour: {
                ...parlour,
                passports: files.map(file => {
                    return { file: file }
                })
            }
        })
    }

    render() {
        const { classes, t } = this.props;
        const {
            parlour,
            businessUnitTypes,
            regions,
            juridicalPersons,
            managementCompanies,
            prices,
            currencies,
            timeZones,
            messengers,
            events,
            telegramChats,
            evotorStores,
            investors,
            employees
        } = this.state;

        const saveDisabled =
            !parlour.name ||
            parlour.relaxTime === null || parlour.relaxTime === '' ||
            ( parlour.type === 'parlour' && !parlour.parentId ) ||
            !parlour.juridicalPersonId ||
            !parlour.address ||
            _.values(parlour.workTime).filter(day => !day.start || !day.finish).length > 0 ||
            !parlour.mainCurrency ||
            !parlour.timeZone ||
            !parlour.hourCosts ||
            (parlour.price1 && parlour.price2 && parlour.price1 === parlour.price2) ||
            (!parlour.usingCurrencies.every(currency => parlour.courseModifications[currency])) ||
            Boolean(parlour.scheduleLines.filter(line => !line.color || !line.time).length) ||
            parlour.visitCommissionPeriods.find(
                period => !period.startedAt || !period.endedAt || !(period.amountPerHour.val > 0)
            ) ||
            parlour.profitERPSharePeriods.find(
                period => !period.startedAt || !period.endedAt
            ) ||
            parlour.profitManagementCompanySharePeriods.find(
                period => !period.startedAt || !period.endedAt
            ) ||
            parlour.profitInvestorSharePeriods.find(
                period =>
                    !period.startedAt ||
                    !period.endedAt ||
                    !(period.investorIds.length > 0) ||
                    (!period?.amount?.val && !period.percent && !period.formula)
            )

        let gratuityLinkQR = null;
        if (parlour.id) {
            gratuityLinkQR = getGratuityQRImage(parlour.id, gratuityQRTypes.parlour);
        }

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleSave} disabled={saveDisabled}>Сохранить</ActionButton>
                    <ActionButton onClick={this.handleHistory} disabled={!parlour.id}>История изменений</ActionButton>
                </LeftBar>
                <Content title="Карточка бизнес юнита">
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <AnyField
                                        name="name"
                                        label="Название"
                                        value={parlour.name}
                                        error={!parlour.name}
                                        required
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        name="displayName"
                                        label="Отображаемое название"
                                        value={parlour.displayName}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectFromItemsField
                                        label="Тип"
                                        value={parlour.type}
                                        name="type"
                                        onChange={this.handleChange}
                                        fullWidth
                                        error={!parlour.type}
                                        required
                                        items={businessUnitTypes}
                                        textField="text"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectFromItemsField
                                        label="Регион"
                                        value={parlour.regionId}
                                        name="regionId"
                                        onChange={this.handleChange}
                                        fullWidth
                                        items={regions}
                                        nullable
                                        nullableText="Не выбрано"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectFromItemsField
                                        label="Юридическое лицо"
                                        value={parlour.juridicalPersonId}
                                        name="juridicalPersonId"
                                        onChange={this.handleChange}
                                        error={!parlour.juridicalPersonId}
                                        fullWidth
                                        required
                                        items={juridicalPersons}
                                        textField="shortName"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectFromItemsField
                                        label="Управляющая компания"
                                        value={parlour.parentId}
                                        name="parentId"
                                        onChange={this.handleChange}
                                        error={parlour.type === 'parlour' && !parlour.parentId}
                                        required={parlour.type === 'parlour'}
                                        fullWidth
                                        items={managementCompanies}
                                        nullable
                                        nullableText="Не выбрано"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <AnyField
                                        name="address"
                                        label="Адрес"
                                        value={parlour.address}
                                        error={!parlour.address}
                                        required
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        name="coordinates"
                                        label="Координаты"
                                        value={parlour.coordinates}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AnyField
                                        name="formulaForCalcRelaxTime"
                                        label="Формула для расчёта времени отдыха мастера"
                                        value={parlour.formulaForCalcRelaxTime}
                                        required
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <AnyField
                                        name="relaxTime"
                                        label="Время отдыха мастера (мин)"
                                        value={parlour.relaxTime}
                                        error={parlour.relaxTime === null || parlour.relaxTime === ''}
                                        required
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectFromItemsField
                                        name="price1"
                                        label="Основной прайс"
                                        value={parlour.price1}
                                        fullWidth
                                        onChange={this.handleChange}
                                        items={prices}
                                        error={parlour.price1 && parlour.price2 && parlour.price1 === parlour.price2}
                                        nullable
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AutocompleteSelectField
                                        name="managerId"
                                        label="Менеджер"
                                        value={parlour.managerId}
                                        onChange={this.handleChange}
                                        fullWidth
                                        options={employees.map((employee) => ({value: employee.id, label: employee.name}))}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectFromItemsField
                                        name="price2"
                                        label="Клубный прайс"
                                        value={parlour.price2}
                                        fullWidth
                                        onChange={this.handleChange}
                                        items={prices}
                                        error={parlour.price1 && parlour.price2 && parlour.price1 === parlour.price2}
                                        nullable
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <MoneyField
                                        label="Стоимость часа работы мастера"
                                        value={parlour.hourCosts}
                                        currencies={[parlour.mainCurrency]}
                                        disableCurrencySelect
                                        error={!parlour.hourCosts}
                                        fullWidth
                                        name="hourCosts"
                                        onChange={this.handleChange}
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <AnyField
                                        label="Временная зона"
                                        value={parlour.timeZone}
                                        error={!parlour.timeZone}
                                        name="timeZone"
                                        onChange={this.handleChange}
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value={null}>Не выбрано</MenuItem>
                                        {timeZones.map(item => <MenuItem key={item.offset} value={item.offset}>{item.offset}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        name="masterWorkTime"
                                        label="Расчётная загрузка мастера в день"
                                        value={parlour.masterWorkTime}
                                        required
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        name="safeNotificationsPriority"
                                        label="Приоритетв отчёте по сейфу"
                                        value={parlour.safeNotificationsPriority}
                                        required
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <AnyField
                                        name="reviewLink"
                                        label="Ссылка на страницу отзывов"
                                        value={parlour.reviewLink}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <UploadFilesField
                                        name="passports"
                                        id="passports"
                                        label={t("Parlour passport")}
                                        image
                                        onChange={this.handleChangeImage}
                                        value={parlour?.passports.map(passport => passport.file)}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AnyField
                                        name="scheduleStaffLinkLastPart"
                                        label="Ссылка на расписание для сотрудников"
                                        value={parlour.scheduleStaffLinkLastPart}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                    <Typography variant="body1">Ссылка: {(parlour.scheduleStaffLinkLastPart) ? window.location.origin + "/staff-schedule/" + parlour.scheduleStaffLinkLastPart : 'Нет'}</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <AnyField
                                        name="whatsAppCreateVisitMessageComment"
                                        label="Коментарий к адресу в высылаемом сообщении"
                                        value={parlour.whatsAppCreateVisitMessageComment}
                                        fullWidth
                                        multiline={true}
                                        rows={4}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FieldsList
                                        items={parlour.timeFilterForWidget}
                                        emptyItem={{time: ''}}
                                        fullWidth
                                        label="Фильтр времен для виджета"
                                        name="timeFilterForWidget"
                                        onChange={this.handleChange}
                                        headers={['Время']}
                                        fields={[
                                            (item, onChange) => <TimeField
                                                value={item.time}
                                                name={'time'}
                                                onChange={onChange('time')}
                                                fullWidth
                                                required
                                                error={!item.time}
                                            />
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <FieldsList
                                        items={parlour.payDocCommissionPeriods}
                                        emptyItem={{startedAt: null, endedAt: null, commission: 0}}
                                        fullWidth
                                        label={t('Salon PD fee')}
                                        name="payDocCommissionPeriods"
                                        onChange={this.handleChange}
                                        headers={[t('Period'), t('Commission in percents')]}
                                        className={classes.payDocCommissionPeriodTable}
                                        disabled={!hasRole('ROLE_ORGANIZATION_BUSINESSUNIT_PAYDOC_COMMISSION_PERIOD_SAVE')}
                                        fields={[
                                            (item, onChange) =>
                                                <DatePeriodField2
                                                    valueFrom={moment(item.startedAt).utcOffset(0, false)}
                                                    valueTo={moment(item.endedAt).utcOffset(0,false)}
                                                    onChangeFrom={onChange('startedAt')}
                                                    onChangeTo={onChange('endedAt')}
                                                    className={classes.field}
                                                    invalidDateMessage={''}
                                                    disabled={!hasRole('ROLE_ORGANIZATION_BUSINESSUNIT_PAYDOC_COMMISSION_PERIOD_SAVE')}
                                                />,
                                            (item, onChange) =>
                                                <PercentField
                                                    value={item.commission}
                                                    required
                                                    fullWidth
                                                    name="commission"
                                                    onChange={onChange('commission')}
                                                    disabled={!hasRole('ROLE_ORGANIZATION_BUSINESSUNIT_PAYDOC_COMMISSION_PERIOD_SAVE')}
                                                />
                                            ]}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <FieldsList
                                        items={parlour.visitCommissionPeriods}
                                        emptyItem={{
                                            id: null,
                                            startedAt: null,
                                            endedAt: null,
                                            amountPerHour: {val: 0, cur: parlour.mainCurrency}
                                        }}
                                        fullWidth
                                        label={t('Visit commission')}
                                        name="visitCommissionPeriods"
                                        onChange={this.handleChange}
                                        headers={[t('Period'), t('Commission per hour')]}
                                        fields={[
                                            (item, onChange) =>
                                                <DatePeriodField2
                                                    valueFrom={moment(item.startedAt).utcOffset(0, false)}
                                                    valueTo={moment(item.endedAt).utcOffset(0,false)}
                                                    onChangeFrom={onChange('startedAt')}
                                                    onChangeTo={onChange('endedAt')}
                                                    className={classes.field}
                                                    invalidDateMessage={''}
                                                    required
                                                    checkValuesOrder
                                                />,
                                            (item, onChange) =>
                                                <MoneyField
                                                    value={item.amountPerHour}
                                                    error={!(item.amountPerHour.val > 0)}
                                                    required
                                                    fullWidth
                                                    name="amountPerHour"
                                                    onChange={onChange('amountPerHour')}
                                                />
                                            ]}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <FieldsList
                                        items={parlour.profitERPSharePeriods}
                                        emptyItem={{
                                            id: null,
                                            startedAt: null,
                                            endedAt: null,
                                            percent: 0
                                        }}
                                        fullWidth
                                        label={t('Business unit profit ERP share')}
                                        name="profitERPSharePeriods"
                                        onChange={this.handleChange}
                                        headers={[t('Period'), t('Percents')]}
                                        fields={[
                                            (item, onChange) =>
                                                <DatePeriodField2
                                                    valueFrom={moment(item.startedAt).utcOffset(0, false)}
                                                    valueTo={moment(item.endedAt).utcOffset(0,false)}
                                                    onChangeFrom={onChange('startedAt')}
                                                    onChangeTo={onChange('endedAt')}
                                                    className={classes.field}
                                                    invalidDateMessage={''}
                                                    required
                                                    checkValuesOrder
                                                />,
                                            (item, onChange) =>
                                                <PercentFieldDecimal
                                                    value={item.percent}
                                                    required
                                                    fullWidth
                                                    name="percent"
                                                    onChange={onChange('percent')}
                                                />
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <FieldsList
                                        items={parlour.profitManagementCompanySharePeriods}
                                        emptyItem={{
                                            id: null,
                                            startedAt: null,
                                            endedAt: null,
                                            percent: 0
                                        }}
                                        fullWidth
                                        label={t('Business unit profit management company share')}
                                        name="profitManagementCompanySharePeriods"
                                        onChange={this.handleChange}
                                        headers={[t('Period'), t('Percents')]}
                                        fields={[
                                            (item, onChange) =>
                                                <DatePeriodField2
                                                    valueFrom={moment(item.startedAt).utcOffset(0, false)}
                                                    valueTo={moment(item.endedAt).utcOffset(0,false)}
                                                    onChangeFrom={onChange('startedAt')}
                                                    onChangeTo={onChange('endedAt')}
                                                    className={classes.field}
                                                    invalidDateMessage={''}
                                                    required
                                                    checkValuesOrder
                                                />,
                                            (item, onChange) =>
                                                <PercentFieldDecimal
                                                    value={item.percent}
                                                    required
                                                    fullWidth
                                                    name="percent"
                                                    onChange={onChange('percent')}
                                                />
                                        ]}
                                    />
                                </Grid>
                                {hasRole('ROLE_ORGANIZATION_BUSINESS_UNIT_PROFIT_INVESTOR_SHARE_GET_SAVE') &&
                                    <Grid item xs={12}>
                                        <FieldsList
                                            items={parlour.profitInvestorSharePeriods}
                                            emptyItem={{
                                                id: null,
                                                startedAt: null,
                                                endedAt: null,
                                                percent: null,
                                                amount: null,
                                                formula: null,
                                                investorIds: []
                                            }}
                                            fullWidth
                                            label={t('Business unit profit investor share')}
                                            name="profitInvestorSharePeriods"
                                            onChange={this.handleChange}
                                            headers={[
                                                t('Period'),
                                                t('%'),
                                                t('Sum'),
                                                t('Formula'),
                                                t('Investors')
                                            ]}
                                            postChange={this.handlePostChangeInvestorShareItem}
                                            fields={[
                                                (item, onChange) =>
                                                    <DatePeriodField2
                                                        valueFrom={moment(item.startedAt).utcOffset(0, false)}
                                                        valueTo={moment(item.endedAt).utcOffset(0, false)}
                                                        onChangeFrom={onChange('startedAt')}
                                                        onChangeTo={onChange('endedAt')}
                                                        className={classes.field}
                                                        invalidDateMessage={''}
                                                        required
                                                        checkValuesOrder
                                                    />,
                                                (item, onChange) =>
                                                    <PercentField
                                                        value={item.percent}
                                                        required
                                                        className={classes.investorSharePercentField}
                                                        name="percent"
                                                        onChange={onChange('percent')}
                                                    />,
                                                (item, onChange) =>
                                                    <MoneyField
                                                        value={item.amount}
                                                        required
                                                        fullWidth
                                                        className={classes.investorShareMoneyField}
                                                        name="amount"
                                                        onChange={onChange('amount')}
                                                    />,
                                                (item, onChange) =>
                                                    <AnyField
                                                        value={item.formula}
                                                        required
                                                        fullWidth
                                                        name="formula"
                                                        onChange={onChange('formula')}
                                                    />,
                                                (item, onChange) =>
                                                    <AutocompleteMultiSelectField
                                                        value={item.investorIds}
                                                        error={item.investorIds.length < 1}
                                                        required
                                                        name="investorIds"
                                                        options={investors}
                                                        className={classes.autoCompleteMultiSelectField}
                                                        onChange={onChange('investorIds')}
                                                    />
                                            ]}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Выдавать подарок"
                                        value={parlour.autoCreatePayDocGift}
                                        name="autoCreatePayDocGift"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                {parlour.autoCreatePayDocGift ? <Grid item xs={12}>
                                    <PayDocTypeField
                                        label="Тип ПД"
                                        required={parlour.autoCreatePayDocGift}
                                        value={parlour.autoCreatePayDocGiftType}
                                        name="autoCreatePayDocGiftType"
                                        onChange={this.handleChange}
                                    />
                                </Grid> : null}
                                {parlour.autoCreatePayDocGift ? <Grid item xs={12}>
                                    <AnyField
                                        label="Баланс подарка за час массажа"
                                        required={parlour.autoCreatePayDocGift}
                                        value={parlour.autoCreatePayDocGiftValue}
                                        name="autoCreatePayDocGiftValue"
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid> : null}
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Активность"
                                        value={parlour.active}
                                        name="active"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Проверять наложение визитов гостей"
                                        value={parlour.checkGuestOverlaps}
                                        name="checkGuestOverlaps"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Доступен самовывоз"
                                        value={parlour.pickupAvailable}
                                        name="pickupAvailable"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Доступна онлайн запись"
                                        value={parlour.onlineEntry}
                                        name="onlineEntry"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Онлайн запись только с оповещением"
                                        value={parlour.onlineEntryOnlyMail}
                                        name="onlineEntryOnlyMail"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Выезное обслуживание"
                                        value={parlour.external}
                                        name="external"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Выгружать дашборд в гуглтаблицу"
                                        value={parlour.exportDashboard}
                                        name="exportDashboard"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Выгружать дашборд админов и мастеров в гуглтаблицу"
                                        value={parlour.exportAdminsAndMastersDashboard}
                                        name="exportAdminsAndMastersDashboard"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Отображать отсутствие администратора"
                                        value={parlour.showAdminGaps}
                                        name="showAdminGaps"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Игнорировать расход товаров"
                                        value={parlour.ignoreGoodConsumptions}
                                        name="ignoreGoodConsumptions"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Отчёт по сейфу"
                                        value={parlour.safeNotificationsEnabled}
                                        name="safeNotificationsEnabled"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Разрешить отправлять СМС сообщения клиентам"
                                        value={parlour.smsNotificationAvailable}
                                        name="smsNotificationAvailable"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Включить автоподтверждение визитов следующего дня"
                                        value={parlour.visitAutoConfirmation}
                                        name="visitAutoConfirmation"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Включить печать чеков"
                                        value={parlour.printCheckEnable}
                                        name="printCheckEnable"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Автоматическое создание электроного ПД при оплате безналом"
                                        value={parlour.autoCreatePayDocForCashless}
                                        name="autoCreatePayDocForCashless"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Показывать адрес на сертификатах"
                                        value={parlour.showAddressInCertificate}
                                        name="showAddressInCertificate"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Выгружать финансовую деятельность в гугл"
                                        value={parlour.unloadFinancialActivitiesGoogle}
                                        name="unloadFinancialActivitiesGoogle"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Создавать расход по % эквайринга"
                                        value={parlour.createAcquiringSpendingDeal}
                                        name="createAcquiringSpendingDeal"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Отображать в расписании"
                                        value={parlour.displayInSchedule}
                                        name="displayInSchedule"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="СМС-верификация закрытия визита"
                                        value={parlour.confirmClosingVisitBySms}
                                        name="confirmClosingVisitBySms"
                                        onChange={this.handleChange}
                                        margin="none"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FieldsList
                                        items={parlour.evotorStoreIds}
                                        emptyItem={{id: ''}}
                                        fullWidth
                                        label="Магазины Эвотор"
                                        name="evotorStoreIds"
                                        onChange={this.handleChange}
                                        headers={['Магазин']}
                                        fields={[
                                            (item, onChange) => <AnyField
                                                value={item.id}
                                                onChange={onChange('id')}
                                                fullWidth
                                                required
                                                error={!item.id}
                                                select
                                            >
                                                {evotorStores.map(item =>
                                                    <MenuItem key={item.id} value={item.uuid}>
                                                        {item.name}
                                                    </MenuItem>
                                                )}
                                            </AnyField>
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.subheading}>Валюты</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Основная валюта"
                                value={parlour.mainCurrency}
                                fullWidth
                                error={!parlour.mainCurrency}
                                name="mainCurrency"
                                onChange={this.handleChange}
                                items={currencies}
                                textField="text"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <MultiSelectFromItemsField
                                label="Доступные валюты"
                                value={parlour.usingCurrencies}
                                name="usingCurrencies"
                                onChange={this.handleChange}
                                emptySelectedLabel="Не выбрано"
                                items={currencies}
                                textField="text"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <MultiSelectFromItemsField
                                label="Доступные валюты (безнал)"
                                value={parlour.usingCurrenciesCashless}
                                name="usingCurrenciesCashless"
                                onChange={this.handleChange}
                                items={currencies}
                                textField="text"
                                emptySelectedLabel="Не выбрано"
                                fullWidth
                            />
                        </Grid>
                        { parlour.usingCurrencies.map( ( item ) => <Grid item xs={4} key={item}>
                                <AnyField
                                    label={ "Модификатор " + item }
                                    value={ parlour.courseModifications[item] }
                                    required
                                    error={ !parlour.courseModifications[item] }
                                    fullWidth
                                    InputProps={{
                                        inputComponent: NumberFormatDecimal3
                                    }}
                                    onChange={this.handleChangeCourseModifications(item)}
                                />
                            </Grid>
                        )}
                    </Grid>

                    { parlour.type === 'external_parlour' && this.renderExternalParlour()}

                    <Typography variant="h6" className={classes.subheading}>Чаевые</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <CheckboxField
                                        label="Чаевые включены"
                                        value={parlour.gratuityEnabled}
                                        name="gratuityEnabled"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <PercentField
                                        label="Процент чаевых от визита по умолчанию"
                                        value={parlour.gratuityDefaultPercent}
                                        required
                                        fullWidth
                                        name="gratuityDefaultPercent"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <PercentField
                                        label="Процент чаевых для админов"
                                        value={parlour.gratuityAdminPercent}
                                        required
                                        fullWidth
                                        name="gratuityAdminPercent"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <CheckboxField
                                        label="Оплата чаевых ПД"
                                        value={parlour.gratuityByPD}
                                        name="gratuityByPD"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <PercentField
                                        label="Процент чаевых салону"
                                        value={parlour.gratuityBusinessUnitPercent}
                                        required
                                        fullWidth
                                        name="gratuityBusinessUnitPercent"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {gratuityLinkQR &&
                            <Grid item xs={8}>
                                <p>Ссылка на оплату чаевых через сайт</p>
                                <img src={gratuityLinkQR} alt="QR" style={{width: 200}}/>
                            </Grid>
                            }
                        </Grid>
                    </Grid>

                    <Typography variant="h6" className={classes.subheading}>Подписчики на события</Typography>

                    <Grid container spacing={2}>
                        {
                            parlour.subscription && parlour.subscription.length > 0
                                ? parlour.subscription.map( (subscribe, idSubscribe) =>
                                    <Grid item xs={12} key={idSubscribe}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <AnyField
                                                    label="Событие"
                                                    value={subscribe.event}
                                                    fullWidth
                                                    onChange={this.handleChangeSubscribe(idSubscribe, 'event')}
                                                    select
                                                >
                                                    <MenuItem value={null}>Не выбрано</MenuItem>
                                                    {events.map(item => <MenuItem key={item.id}
                                                                                      value={item.id}>{item.text}</MenuItem>)}
                                                </AnyField>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <AnyField
                                                    label="Тип сообщения"
                                                    value={subscribe.messenger}
                                                    fullWidth
                                                    onChange={this.handleChangeSubscribe(idSubscribe, 'messenger')}
                                                    select
                                                >
                                                    <MenuItem value={null}>Не выбрано</MenuItem>
                                                    {messengers.map(item => <MenuItem key={item.id}
                                                                                      value={item.id}>{item.text}</MenuItem>)}
                                                </AnyField>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {
                                                    subscribe.messenger === 'telegram' && telegramChats.length > 0 ?
                                                        <AnyField
                                                            label="Получатель"
                                                            value={subscribe.recipient}
                                                            fullWidth
                                                            onChange={this.handleChangeSubscribe(idSubscribe, 'recipient')}
                                                            select
                                                        >
                                                            <MenuItem value={null}>Не выбрано</MenuItem>
                                                            {telegramChats.map(item => <MenuItem key={item.id} value={item.type+'#'+item.telegramId}>{item.title}</MenuItem>)}
                                                        </AnyField>
                                                    :
                                                        <AnyField
                                                            label="Получатель"
                                                            value={subscribe.recipient}
                                                            fullWidth
                                                            onChange={this.handleChangeSubscribe(idSubscribe, 'recipient')}
                                                        />
                                                }
                                            </Grid>
                                            <Fab size="small" color="secondary" className={classes.buttonDel} onClick={this.handleSubscribeDel(idSubscribe)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                        </Grid>
                                    </Grid>
                                )
                                : <Grid item xs={4}>Подписчики отсутствуют</Grid>
                        }


                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={this.handleAddSubscription}
                            >
                                <Add />
                                Добавить подписчика
                            </Button>
                        </Grid>
                    </Grid>



                    <Grid container spacing={2} alignItems="flex-start" >
                        { this.renderParlourWorkTime() }
                        <Grid item xs={4}>
                            <Typography variant="h6" className={classes.subheading}>Смены</Typography>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <Grid container spacing={2} className={classes.blockTitleWorkSchedule}>
                                        <Grid item xs={2} className={classes.workTimeLabel}> </Grid>
                                        <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Начало смены</Grid>
                                        <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Окончание смены</Grid>
                                    </Grid>
                                </Grid>
                                { parlour.workShift && Array.isArray(parlour.workShift)
                                    ? parlour.workShift.map( (itemWorkShift, id) =>
                                    <React.Fragment key={id}>
                                        <Grid item xs={2} style={{paddingTop: 21}}>
                                            {id + 1} смена
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TimeField
                                                value={itemWorkShift.start}
                                                autoOk
                                                onChange={this.handleWorkShiftChange('start', id)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TimeField
                                                value={itemWorkShift.end}
                                                autoOk
                                                onChange={this.handleWorkShiftChange('end', id)}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                    )
                                    : null
                                }
                                <Grid item xs={12} className={classes.workShiftBlockButton}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        onClick={this.handleAddWorkShift}
                                    >
                                        <Add />
                                        Добавить смену
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="flex-start" >
                        { this.renderScheduleLines() }

                        { this.renderUsingVisitGoods() }
                    </Grid>


                    { this.renderBonusesEdges() }
                    { this.renderRooms() }
                    { this.renderPayDocTypes() }
                    { this.renderFacilities() }
                    { this.renderJuridicalPersons() }
                </Content>
            </React.Fragment>
        );
    }

    renderUsingVisitGoods() {
        const {classes} = this.props;
        const {parlour, goodStorageUsing, consumptionGroups} = this.state;

        return (
            <React.Fragment>
                <Grid item xs={12}>
                <Typography variant="h6" className={classes.subheading}>Расход товаров на визит</Typography>
                { parlour.visitGoodsConsumption.map(  (good, index) => (
                    <Grid container spacing={2} key={index}>
                        <Grid item xs={4}>
                            <AnyField
                                value = { good.good }
                                label="Наименование товара"
                                fullWidth
                                select
                                onChange={this.handleVisitGoodsConsumptionChange(index, 'good')}
                            >
                                { goodStorageUsing.map((item, i) => <MenuItem key={i} value={item.id}>{item.name} / {item.nameWithMass} / {item.category.name}</MenuItem>) }
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                items={consumptionGroups}
                                onChange = { this.handleVisitGoodsConsumptionChange(index, 'consumptionGroup')}
                                value={ good.consumptionGroup }
                                fullWidth
                                textField="name"
                                label="Или расходная группа"
                                valueField="id"
                                nullableText="Не использовать"
                                nullable
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AnyField
                                label = "Количество товара"
                                value = { good.deltaMass }
                                fullWidth
                                onChange = {this.handleVisitGoodsConsumptionChange(index, 'deltaMass')}
                                InputProps={{
                                    inputComponent: NumberFormatDecimal3,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.del}>
                            <Fab color="secondary" className={classes.goodDel} onClick={this.handleVisitGoodsConsumptionDelete(index)} size={"small"}><ClearIcon/></Fab>
                        </Grid>
                    </Grid>
                )) }
                <ActionButton
                    fullWidth={false}
                    onClick={this.handleVisitGoodsConsumptionAdd}
                >
                    <Add/> Добавить материал
                </ActionButton>
                </Grid>
            </React.Fragment>
        );

    }


    renderParlourWorkTime() {
        const { classes } = this.props;
        const { parlour } = this.state;
        const days = {
            1: "Пн",
            2: "Вт",
            3: "Ср",
            4: "Чт",
            5: "Пт",
            6: "Сб",
            7: "Вс",
        };

        return (
            <Grid item xs={6} className={classes.blockWorkTime}>
                <Typography variant="h6" className={classes.subheading}>Рабочее время</Typography>
                <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.blockTitleWorkSchedule}>
                        <Grid item xs={2} className={classes.workTimeLabel}>День</Grid>
                        <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Начало работы</Grid>
                        <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Конец работы</Grid>
                    </Grid>
                </Grid>
                {_.transform(parlour.workTime, (result, value, day) => {
                    result.push(
                        <React.Fragment key={day}>
                            <Grid item xs={2} className={classes.workTimeLabel}>
                                {days[day]}
                            </Grid>
                            <Grid item xs={5}>
                                <TimeField
                                    value={value.start}
                                    required
                                    onChange={this.handleWorkTimeChange(day, 'start')}
                                    autoOk
                                    error={!value.start}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TimeField
                                    value={value.finish}
                                    required
                                    onChange={this.handleWorkTimeChange(day, 'finish')}
                                    autoOk
                                    error={!value.finish}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                },[])}
                </Grid>
            </Grid>
        );

    }

    renderScheduleLines() {
        const { classes } = this.props;
        const { parlour } = this.state;

        return (
            <Grid item xs={6}>
                <Typography variant="h6" className={classes.subheading}>Полоски на расписании</Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={2} className={classes.blockTitleWorkSchedule}>
                            <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Время</Grid>
                            <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Цвет</Grid>
                        </Grid>
                    </Grid>
                    {parlour.scheduleLines.map( (line, index) => <React.Fragment key={index}>
                            <Grid item xs={5}>
                                <TimeField
                                    value={line.time}
                                    required
                                    onChange={this.handleScheduleLinesChange(index, 'time')}
                                    autoOk
                                    error={!line.time}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <ColorField
                                    value={line.color}
                                    required
                                    onChange={this.handleScheduleLinesChange(index, 'color')}
                                    error={!line.color}
                                    className={classes.colorField}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Fab size="small" color="secondary" onClick={this.handleScheduleLinesDelete(index)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                            </Grid>
                        </React.Fragment>
                    )}
                    {parlour.scheduleLines.length === 0 &&
                        <Grid item xs={12}>
                            Пока полосок нет =(
                        </Grid>
                    }
                </Grid>
                <ActionButton
                    fullWidth={false}
                    onClick={this.handleScheduleLinesAdd}
                    className={classes.buttonDel}
                >
                    <Add/> Добавить полоску
                </ActionButton>
            </Grid>
        );
    }

    renderBonusesEdges() {
        const { classes } = this.props;
        const { parlour, guestMessengerTypes } = this.state;

        return <>
            <Typography variant="h6" className={classes.subheading}>Бонусы</Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <CheckboxField
                        label="Бонусы включены"
                        value={parlour.bonusesEnabled}
                        name="bonusesEnabled"
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <PercentField
                        label="Макс. процент для оплаты бонусами"
                        value={parlour.bonusPaymentMaxPercent}
                        required
                        fullWidth
                        name="bonusPaymentMaxPercent"
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MultiSelectFromItemsField
                        value={parlour.bonusChangeNotificationTypes}
                        label={"Отправлять уведомления о бонусах"}
                        fullWidth
                        name="bonusChangeNotificationTypes"
                        onChange={this.handleChange}
                        items={guestMessengerTypes}
                        valueField={"id"}
                        textField={"text"}
                        emptySelectedLabel={"Не отправлять"}
                    />

                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={2} className={classes.blockTitleWorkSchedule}>
                                <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Порог</Grid>
                                <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Проценты</Grid>
                            </Grid>
                        </Grid>
                        {parlour.bonusesEdges.map( (edge, index) => <React.Fragment key={index}>
                                <Grid item xs={5}>
                                    <AnyField
                                        value={edge.edge}
                                        required
                                        onChange={this.handleBonusesEdgesChange(index, 'edge')}
                                        error={edge.edge === null || edge.edge === ''}
                                        InputProps={{
                                            inputComponent: NumberFormatDecimal3,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <PercentField
                                        value={edge.mod}
                                        required
                                        onChange={this.handleBonusesEdgesChange(index, 'mod')}
                                        error={!edge.mod}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Fab size="small" color="secondary" onClick={this.handleBonusesEdgesDelete(index)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                </Grid>
                            </React.Fragment>
                        )}
                        {parlour.bonusesEdges.length === 0 &&
                        <Grid item xs={12}>
                            Пока бонусов нет =(
                        </Grid>
                        }
                    </Grid>

                    <ActionButton
                        fullWidth={false}
                        onClick={this.handleBonusesEdgesAdd}
                        className={classes.buttonDel}
                    >
                        <Add/> Добавить бонус
                    </ActionButton>
                </Grid>
            </Grid>
        </>;
    }

    renderRooms() {
        const { classes } = this.props;
        const { parlour } = this.state;
        return <>
            <Typography variant="h6" className={classes.subheading}>Комнаты</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={2} className={classes.blockTitleWorkSchedule}>
                                <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Название</Grid>
                                <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Кол-во мест</Grid>
                            </Grid>
                        </Grid>
                        {parlour.rooms.map( (room, index) => <React.Fragment key={index}>
                            <Grid item xs={5}>
                                <AnyField
                                    value={room.name}
                                    required
                                    onChange={this.handleRoomChange(index, 'name')}
                                    error={!room.name}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <IntegerField
                                    value={room.count}
                                    required
                                    onChange={this.handleRoomChange(index, 'count')}
                                    error={!room.count || room.count < 0}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Fab size="small" color="secondary" onClick={this.handleRoomDelete(index)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                            </Grid>
                        </React.Fragment>
                        )}
                        {parlour.rooms.length === 0 &&
                        <Grid item xs={12}>
                            Пока комнат нет =(
                        </Grid>
                        }
                    </Grid>

                    <ActionButton
                        fullWidth={false}
                        onClick={this.handleRoomAdd}
                        className={classes.buttonDel}
                    >
                        <Add/> Добавить комнату
                    </ActionButton>
                </Grid>
            </Grid>
        </>;
    }

    renderExternalParlour() {
        const { classes } = this.props;
        const { parlour, paymentTypes, resellers } = this.state;
        return <>
            <Typography variant="h6" className={classes.subheading}>Внешние салоны</Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <SelectFromItemsField
                        items={resellers}
                        label="Указывать партнёра в визите"
                        nullable
                        nullableText="Не указывать"
                        value={parlour.defaultResellerId}
                        fullWidth
                        name="defaultResellerId"
                        onChange={this.handleChange}
                    />
                    {parlour.defaultResellerId && <Link to={`/organization/external-subjects/view/${parlour.defaultResellerId}`}>Перейти в карточку партнёра</Link>}
                </Grid>
                <Grid item xs={4}>
                    <MultiSelectFromItemsField
                        value={parlour.availablePaymentTypes}
                        label="Доступные типы платежей"
                        fullWidth
                        name="availablePaymentTypes"
                        onChange={this.handleChange}
                        items={paymentTypes}
                        textField="text"
                        emptySelectedLabel="Не доступны"
                    />
                </Grid>
                <Grid item xs={4}>
                    <CheckboxField
                        value={parlour.clearCashBoxOnCloseWorkShift}
                        label="Обнулять кассу при закрытии смены"
                        name="clearCashBoxOnCloseWorkShift"
                        onChange={this.handleChange}
                    />
                </Grid>
            </Grid>
        </>;
    }

    renderPayDocTypes() {
        const { classes } = this.props;
        const { parlour, payDocTypes } = this.state;
        return <>
            <Typography variant="h6" className={classes.subheading}>Привязанные типы ПД</Typography>
            <TransferListField
                items={payDocTypes}
                name="availablePayDocTypes"
                value={parlour.availablePayDocTypes}
                onChange={this.handleChange}
            />
        </>;
    }

    renderFacilities() {
        const { classes } = this.props;
        const { parlour, facilities } = this.state;
        return <>
            <Typography variant="h6" className={classes.subheading}>Доступные услуги</Typography>
            <TransferListField
                items={facilities}
                name="availableFacilities"
                value={parlour.availableFacilities}
                onChange={this.handleChange}
            />
        </>;
    }

    renderJuridicalPersons() {
        const { classes } = this.props;
        const { parlour, juridicalPersons } = this.state;
        return <>
            <Typography variant="h6" className={classes.subheading}>Доверенные Юр. Лица</Typography>
            <TransferListField
                items={juridicalPersons}
                name="trustedJuridicalPersons"
                value={parlour.trustedJuridicalPersons}
                onChange={this.handleChange}
                textField="fullName"
            />
        </>;
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(withStyles(styles)(ParlourViewPage));
