import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    MenuItem, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import {
    createContact,
    getContactTargets,
    getContactTypes,
    getGuest,
    navLinks
} from "../../services/guests";
import {getUsers} from "../../services/user";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import DateField from "../../components/fields/DateField";
import {withRouter} from "react-router";
import GuestLeftFields from "./GuestLeftFields";
import {withTranslation} from "react-i18next";

export default
@connect(null, {showMessage: messageDialogActions.show})
@withStyles(theme => ({
    container: {
        width: 600,
    },
}))
@withRouter
@withTranslation()
class ContactPage extends React.Component {

    state = {
        id: '',
        guest: {
            id: null,
            lastName: null,
            name: null,
            secondName: null,
            phone: null,
            payDocs: null,
        },
        contactData: {
            targetId: null,
            targetComment: null,
            type: null,
            result: null,
            comment: null,
            files: [],
        },
        targets: [],
        types: [],

        dialogTaskOpen: false,
        task: {
            user: null,
            dateEnd: null,
            description: null,
        },
        users: [],
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0]});

            getGuest(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: response.data
                        })
                    }
                });
        }

        getContactTargets()
            .then(response => {
                if (response.success) {
                    this.setState({ targets: response.data });
                }
            });

        getContactTypes()
            .then(response => {
                if (response.success) {
                    this.setState({ types: response.data });
                }
            });

        getUsers(-1, -1)
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    });
                }
            })
    }

    handleClickBack = () => {
        this.props.history.push(`/guests/view/${this.state.id}`);
    };

    handleSave = () => {
        const {id, contactData } = this.state;

        createContact(id, contactData)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/guests/view/${this.state.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChange = prop => event => {
        const { contactData } = this.state;
        this.setState({
            contactData: {
                ...contactData,
                [prop]: event.target.value,
            }
        });
    };

    handleTask = () => {
        this.setState({
            dialogTaskOpen: true,
            task: {
                user: null,
                dateEnd: null,
                description: null,
            }
        })
    };

    handleTaskChange = prop => event => {
        const { task } = this.state;
        this.setState({
            task: {
                ...task,
                [prop]: event.target.value,
            }
        });
    };

    handleTaskChangeDate = prop => date => {
        const { task } = this.state;
        this.setState({
            task: {
                ...task,
                [prop]: date,
            }
        });
    };

    handleCloseTaskDialog = ok => () => {
        //TODO: Сохранение задачи
        this.setState({
            dialogTaskOpen: false,
        });
    };

    render() {
        const { classes, t } = this.props;
        const { guest, contactData, targets, types, dialogTaskOpen, task, users } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Interaction fixing")}/>}>
                    <GuestLeftFields guest={guest}/>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>{t("Back")}</ActionButton>
                    <ActionButton disabled={!contactData.targetId || (contactData.targetId === -1 && !contactData.targetComment) || !contactData.type || !contactData.result} onClick={this.handleSave}>{t("Save")}</ActionButton>
                    <ActionButton onClick={this.handleTask}>{t("Create task")}</ActionButton>
                </LeftBar>
                <Content title={t("Interaction fixing")}>
                    <div className={classes.container}>
                        <AnyField
                            id="targetId"
                            label={t("Interaction topic")}
                            value={contactData.targetId}
                            fullWidth
                            onChange={this.handleChange('targetId')}
                            select
                            required
                            error={!contactData.targetId}
                        >
                            {targets.map(item => (<MenuItem key={item.id} value={item.id}>{item.target}</MenuItem>))}
                            <MenuItem value={-1}>{t("Other")}</MenuItem>
                        </AnyField>
                        {contactData.targetId === -1 ?
                            <AnyField
                                id="targetComment"
                                label={t("Specify the purpose of the interaction")}
                                value={contactData.targetComment}
                                fullWidth
                                onChange={this.handleChange('targetComment')}
                                multiline
                                rows={6}
                                required
                                error={!contactData.targetComment}
                            />
                            : null
                        }
                        <AnyField
                            id="type"
                            label={t("Interaction type")}
                            value={contactData.type}
                            fullWidth
                            onChange={this.handleChange('type')}
                            select
                            required
                            error={!contactData.type}
                        >
                            {types.map(item => (<MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>))}
                        </AnyField>
                        <AnyField
                            id="result"
                            label={t("Interaction result")}
                            value={contactData.result}
                            fullWidth
                            onChange={this.handleChange('result')}
                            multiline
                            rows={6}
                            required
                            error={!contactData.result}
                        />
                        <AnyField
                            id="comment"
                            label={t("Comment")}
                            value={contactData.comment}
                            fullWidth
                            onChange={this.handleChange('comment')}
                            multiline
                            rows={6}
                        />
                        <UploadFilesField
                            id="files"
                            label={t("Attached files")}
                            value={contactData.files}
                            multiple
                            limit={30}
                            onChange={this.handleChange('files')}
                        />
                    </div>
                </Content>
                <Dialog
                    open={dialogTaskOpen}
                    onClose={this.handleCloseTaskDialog(false)}
                    aria-labelledby="dialog-task-title"
                    aria-describedby="dialog-task-description"
                >
                    <DialogTitle id="dialog-task-title">{t("New task")}</DialogTitle>
                    <DialogContent>

                        <AnyField
                            id="user"
                            label={t("Who is assigned the task")}
                            value={task.user}
                            multiline
                            rows="4"
                            onChange={this.handleTaskChange('user')}
                            select
                            required
                            fullWidth
                        >
                            {users.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.username}</MenuItem>
                            ))}
                        </AnyField>
                        <DateField
                            label={t("Period of execution")}
                            value={task.dateEnd}
                            onChange={this.handleTaskChangeDate('dateEnd')}
                            disablePast
                            fullWidth
                        />
                        <AnyField
                            id="user"
                            label={t("Formulation of the problem")}
                            fullWidth
                            value={task.description}
                            multiline
                            rows="4"
                            onChange={this.handleTaskChange('description')}
                            required
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseTaskDialog(true)} color="primary" disabled={!task.user || !task.dateEnd || !task.description}>OK</Button>
                        <Button onClick={this.handleCloseTaskDialog(false)} color="primary" >{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
