import React from 'react';
import * as PropTypes from 'prop-types';
import ShowField from "../../components/fields/ShowField";
import {getVisitMasterNames, getVisitStatusText, VISIT_DT_FORMAT} from "../../services/calendar";
import {Link} from "react-router-dom";
import {formatMoney} from "../../services/common";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Face from '@material-ui/icons/Face';
import moment from "moment";
import {formatDuration} from "../../components/fields/FacilityDurationField";

export default
@withTranslation()
@connect(state => ({
    currentParlour: state.common.currentParlour,
}))
@withStyles(theme => ({
    markValue: {
        color: theme.palette.primary.main,
    },
    markLabel: {
        color: '#000000',
        fontWeight: 500,
    },
    specialStatus: {
        maxHeight: 100,
        overflow: 'auto',
    },
}))
class CalendarLeftFields extends React.PureComponent
{
    static propTypes = {
        selectedMoment: PropTypes.object,
        selectedMaster: PropTypes.object,
        selectedVisit: PropTypes.object,
        selectedRoom: PropTypes.object,
    };

    getVisitFacilityNames = (visit) => {
        const { t } = this.props;
        if (visit && visit.visitFacility && visit.visitFacility.length) {
            return visit.visitFacility.map(vf => {
                if (vf.facility) {
                    let duration;

                    if (moment.isDuration(vf.duration)) {
                        duration = vf.duration.asMinutes();
                    } else if (vf.duration) {
                        duration = vf.duration;
                    }

                    return vf.facility.name + (duration ? ` (${formatDuration(t, duration)})` : '');
                }

                return null;
            }).join(', ');
        }

        return null;
    }

    render() {
        const { selectedMoment, selectedMaster, selectedRoom, selectedVisit, t, currentParlour, currentView, classes } = this.props;

        return <React.Fragment>
            <ShowField
                label={t("Visit begin")}
                value={selectedMoment ? selectedMoment.utcOffset(currentParlour.timeZone).format(VISIT_DT_FORMAT) : selectedVisit ? selectedVisit.start.utcOffset(currentParlour.timeZone).format(VISIT_DT_FORMAT) : null}

            />
            {currentView === 'master' ? (
                <ShowField
                    label={t("Master")}
                    value={selectedMaster ? selectedMaster.name : getVisitMasterNames(selectedVisit) }
                />
                ) : (
                <ShowField
                    label={t("Room")}
                    value={selectedRoom ? selectedRoom.label : getVisitMasterNames(selectedVisit) }
                />
                )}
            <ShowField
                label={t("Guest")}
            >
                {selectedVisit ? (selectedVisit.guest ? <React.Fragment><Link to={`/guests/view/${selectedVisit.guest.id}`}>{selectedVisit.guest.name}</Link>{ selectedVisit.guest.isClubMember && <Chip size="small" label={t('Club member')} color="primary" icon={<Face/>} />}</React.Fragment> : <div><i>Инкогнито</i></div>) : null}
            </ShowField>
            <ShowField
                label={t("Guest phone")}
                value={selectedVisit && selectedVisit.guest ? selectedVisit.guest.phone : null}
            />
            <ShowField
            label={t('Guest Birthday')}
            value={selectedVisit && selectedVisit.guest ? moment(selectedVisit.guest.birthDate).format('DD-MM-YYYY') : null}
            />
            <ShowField
                label={t("Past visits count")}
                value={selectedVisit && selectedVisit.guestPastVisitsCount !== undefined ? selectedVisit.guestPastVisitsCount : null}
            />
            <ShowField
                label={t("Parent visit")}
                value={t("Parent visit")}
                linkTo={selectedVisit ? '/orders/view/' + selectedVisit.parentId : ''}
                visible={Boolean(selectedVisit && selectedVisit.parentId)}
            />
            <ShowField
                label={t("Service")}
                value={this.getVisitFacilityNames(selectedVisit)}
                className={classes.markValue}
                labelClassName={classes.markLabel}
            />
            <ShowField
                label={t("Status")}
                value={selectedVisit && t(getVisitStatusText(selectedVisit.status))}
            />
            <ShowField
                label={t("Creator")}
                value={selectedVisit && selectedVisit.creator.username}
            />
            <ShowField
                label={t("Created")}
                value={selectedVisit && selectedVisit.createdAt}
                dateFormat={"DD.MM.YYYY HH:mm"}
            />
            <ShowField
                label={t("Visit channel")}
                value={selectedVisit && selectedVisit.visitChannel.name}
            />
            <ShowField
                label = {t("Cost of visit")}
                value =  { selectedVisit && selectedVisit.price ? formatMoney(selectedVisit.price) : '' }
                className={classes.markValue}
                labelClassName={classes.markLabel}
            />
            <ShowField
                label = {t("Full cost of visit")}
                value =  { selectedVisit && selectedVisit.fullVisitPrice ? selectedVisit.fullVisitPrice : '' }
                className={classes.markValue}
                labelClassName={classes.markLabel}
            />
            <ShowField
                label={t("Comment")}
                value={selectedVisit && selectedVisit.comment}
                className={classes.markValue}
                labelClassName={classes.markLabel}
            />
            <ShowField
                label={t("Cancel reason")}
                value={selectedVisit && selectedVisit.cancelReasonText}
                visible={Boolean(selectedVisit && selectedVisit.cancelReasonText)}
            />
            <ShowField
                label={t("Cancel comment")}
                value={selectedVisit && selectedVisit.cancelComment}
                visible={Boolean(selectedVisit && selectedVisit.cancelComment)}
            />
            <ShowField
                label={t("A discount")}
                visible={Boolean(selectedVisit && selectedVisit.discount)}
                value={selectedVisit && selectedVisit.discount && selectedVisit.discount.name}
            />
            <ShowField
                label={t("Special status")}
                visible={Boolean(selectedVisit && selectedVisit.guest && selectedVisit.guest.specialStatus)}
            >
                <div className={classes.specialStatus}>{selectedVisit && selectedVisit.guest ? selectedVisit.guest.specialStatus : null}</div>
            </ShowField>

            <ShowField
                label={t("Room")}
                visible={Boolean(selectedVisit && selectedVisit.roomDisplayName)}
                value={selectedVisit && selectedVisit.roomDisplayName}
            />
            <ShowField
                label={t("Room comment")}
                visible={Boolean(selectedVisit && selectedVisit.roomComment)}
                value={selectedVisit && selectedVisit.roomComment}
            />

        </React.Fragment>;
    }
}