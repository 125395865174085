import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import moment from "moment";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import _ from "lodash";
import { navLinks } from "../../services/settings";
import {
    getSystemEventNotificationTransport,
    getSystemNotificationEvent, getSystemNotificationEvents, getSystemTelegramChannels, saveSystemEventNotification
} from "../../services/common";
import AnyField from "../../components/fields/AnyField";
import {Button, Fab, Grid, withStyles} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/Add";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

export default
@withStyles( theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    workTimeLabel: {
        textAlign: 'center'
    },
    workTimeLabel_textLeft: {
        textAlign: 'left'
    },
    workShiftBlockButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    blockTitleWorkSchedule: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    blockWorkTime: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    subheading: {
        marginBottom: 15,
        marginTop: 45
    },
    del: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    buttonDel: {
        marginTop: 29,
    },
    colorField: {
        width: 100
    }
}))
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class SystemEventsPage extends React.Component {
    state = {
        search: {
            title: null,
        },
        emptySearch: true,
        loading: true,
        system: {
            subscription: []
        },
        systemEventNotificationTransport: [],
        systemNotificationEventType: [],
        availableSystemNotificationEventType: [],
        systemTelegramChannels: []
    }

    componentDidMount = () => {
        const { t } = this.props

        Promise.all(
            [
                getSystemNotificationEvent(),
                getSystemEventNotificationTransport(),
                getSystemTelegramChannels(),
                getSystemNotificationEvents()
            ]
        ).then(responses => {
            let addState = {}
            const { system, availableSystemNotificationEventType } = this.state

            if (responses[0].success)
                addState.systemNotificationEventType = responses[0].data.map(event => ({
                    value: event.id,
                    label: t(event.text)
                }))

            if (responses[1].success)
                addState.systemEventNotificationTransport = responses[1].data.map(transport => ({
                    value: transport.id,
                    label: t(transport.text)
                }))

            if (responses[2].success)
                addState.systemTelegramChannels = responses[2].data.map(channel => ({
                    value: channel.id,
                    id:  channel.id,
                    name: channel.title
                }))

            if (responses[3].success)
                addState.system = {
                    ...system,
                    subscription: responses[3].data.map( event => ({
                        ...event,
                        changed: false,
                        availableSystemNotificationEventType: availableSystemNotificationEventType
                    }))
                }

            if (!_.isEmpty(addState))
                this.setState({
                    ...addState,
                    loading: false
                }, () => { this.calculateAvailableEvents() })
        })
    }

    calculateAvailableEvents = () => {
        const { system, systemNotificationEventType } = this.state
        let availableSystemNotificationEventType = [];

        availableSystemNotificationEventType = systemNotificationEventType.filter( eventType => {
            return system.subscription.filter( subscription => { return subscription.eventType === eventType.value } ).length === 0
        })

        let subscriptions = system.subscription;

        if ( Array.isArray(subscriptions) && subscriptions.length > 0 )
            for (let subscriptionId in subscriptions)
                if (subscriptions[subscriptionId].eventType)
                    subscriptions[subscriptionId].availableSystemNotificationEventType = availableSystemNotificationEventType.concat(
                        systemNotificationEventType.filter( eventType => {
                            return subscriptions[subscriptionId].eventType === eventType.value
                        })
                    )

        this.setState({
            availableSystemNotificationEventType: availableSystemNotificationEventType,
            system: {
                ...system,
                subscription: subscriptions
            }
        })

    }

    handleSubscribeChange = prop => event => {
        let { subscription } = this.state.system

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        for (let subscriptionId in subscription)
            if (parseInt(subscriptionId) === parseInt(prop.subscriptionId)) {
                subscription[prop.subscriptionId][prop.name] = value
                subscription[prop.subscriptionId].changed = true
            }

        this.setState({
            system: {
                ...this.state.system,
                subscription: subscription
            }
        }, () => { this.calculateAvailableEvents() });
    };

    handleChange = () => {}

    handleAddSubscription = () => {
        let { subscription } = this.state.system;
        let { availableSystemNotificationEventType } = this.state;
        let lengthSubscription = subscription.length;

        if (
            lengthSubscription > 0
            && (!subscription[lengthSubscription - 1]['eventType']
                || !subscription[lengthSubscription - 1]['transport']
                || !subscription[lengthSubscription - 1]['telegramChats'].length)
        ) {
            return;
        }

        subscription.push({
            id: null,
            eventType: null,
            transport: 'telegram',
            telegramChats: [],
            changed: true,
            availableSystemNotificationEventType: availableSystemNotificationEventType
        });

        this.setState({
            system: {
                ...this.state.system,
                subscription: subscription
            }
        })
    };

    handleSubscribeDel = (idSubscribe) => () => {
        let { subscription } = this.state.system;

        subscription = subscription.filter((subscribe, id) => id !== idSubscribe);

        this.setState({
            system: {
                ...this.state.system,
                subscription: subscription,
            }
        })

    };

    handleSave = () => {
        const { system } = this.state
        const { showInfo, t, showError } = this.props

        let changedEvents = system.subscription.filter(
            (subscript) => {
                return (
                    subscript.changed &&
                    subscript.eventType &&
                    subscript.transport &&
                    subscript.telegramChats &&
                    subscript.telegramChats.length > 0
                )
            }
        )

        if (changedEvents.length > 0)
            saveSystemEventNotification({ 'systemEvents': changedEvents }).then(response => {

                console.log(response)

                if (response.success)
                    showInfo(t('System notification subscription changed successfully'))
                else
                    showError(t('Something goes wrong'))
            })
    }

    render() {
        const { t, classes } = this.props;
        const { system, search, systemEventNotificationTransport, systemTelegramChannels, emptySearch } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <AnyField
                    value={search.title}
                    onChange={this.handleChange('title')}
                    label={t('Channel name')}
                    fullWidth
                />
                {/* <CheckboxField
                    label={t('Show active')}
                    value={search.onlyActive}
                    onChange={this.handleChange('onlyActive')}
                />*/ }
                <ActionButton onClick={this.handleClickFilter} disabled={emptySearch}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleSave}>{t('Save')}</ActionButton>
            </LeftBar>
            <Content title={t("System events subscribers")}>
                <Grid container spacing={2}>
                    {
                        system.subscription && system.subscription.length > 0
                            ? system.subscription.map( (subscribe, idSubscribe) =>
                                <Grid item xs={12} key={idSubscribe}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <AutocompleteSelectField
                                                label={t('Event')}
                                                value={subscribe.eventType}
                                                required
                                                onChange={this.handleSubscribeChange({name: 'eventType', subscriptionId: idSubscribe})}
                                                fullWidth
                                                options={subscribe.availableSystemNotificationEventType}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AutocompleteSelectField
                                                label={t('Transport')}
                                                value={subscribe.transport}
                                                required
                                                onChange={this.handleSubscribeChange({name: 'transport', subscriptionId: idSubscribe})}
                                                fullWidth
                                                options={systemEventNotificationTransport}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AutocompleteMultiSelectField
                                                label={t('Telegram channels')}
                                                value={subscribe.telegramChats}
                                                onChange={this.handleSubscribeChange({name: 'telegramChats', subscriptionId: idSubscribe})}
                                                fullWidth
                                                isClearable={true}
                                                options={systemTelegramChannels}
                                            />
                                        </Grid>
                                        <Fab size="small" color="secondary" className={classes.buttonDel} onClick={this.handleSubscribeDel(idSubscribe)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                    </Grid>
                                </Grid>
                            )
                            : <Grid item xs={4}>{t('No system events subscribers')}</Grid>
                    }


                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={this.handleAddSubscription}
                        >
                            <Add />
                            {t("Add system events subscriber")}
                        </Button>
                    </Grid>
                </Grid>
            </Content>
        </React.Fragment>
    }
}
