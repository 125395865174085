import React from 'react';
import AnyField, {propTypes} from "./AnyField";

export default class ColorField extends React.Component
{
    static propTypes = propTypes;

    render() {
        const { inputProps, ...other } = this.props;

        return <AnyField
            type="color"
            inputProps={{
                ...inputProps,
                style: {
                    ...(inputProps ? inputProps.style : {}),
                    padding: 0,
                    height: 35,
                }
            }}
            {...other}
        />
    }
}
