import React, {memo, useContext} from 'react'
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DataTableLocal from "../../../components/data-table/DataTableLocal";
import Context from "./Context";

const FinanceReportDetailsDialog = () => {
    const { t } = useTranslation();
    const { detailsDialog: { open, title, data, columnsData, linkTemplate }, hideDetailsDialog } = useContext(Context);

    return (
        <Dialog open={open} onClose={hideDetailsDialog} maxWidth="lg">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DataTableLocal
                    data={data}
                    columnData={columnsData}
                    selector={false}
                    pagination={false}
                    linkTemplate={linkTemplate}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={hideDetailsDialog} color="primary" autoFocus>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(FinanceReportDetailsDialog)