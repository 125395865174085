import baseRequest from "./baseReuqest";
import * as queryString from "query-string-object";
import moment from "moment";
import _ from "lodash";
import {getFacilitiesVisit} from "./facilities";
import {processMomentFieldInObject} from "../utils/moment-utils";

export const navLinks = [
    {path: "/orders", text: "Daily schedule"},
    {path: "/orders/week", text: "Week schedule"},
    {path: "/orders/multi", text: "Custom schedule"},
    {
        path: "/orders/report/requested",
        text: "Requested visits",
        role: "ROLE_SCHEDULE_VISITSREPORTREQUESTED",
        group: "Reports"
    },
    {path: "/orders/report/full", text: "Full visits report", role: "ROLE_SCHEDULE_VISITSREPORTFULL", group: "Reports"},
    {path: "/orders/report/canceled", text: "Canceled visits report", role: "ROLE_SCHEDULE_VISITSREPORTFULL", group: "Reports"}
];

const dateFormat = 'YYYY-MM-DD';

export const VISIT_DT_FORMAT = "D MMMM YYYY HH:mm";

function processSearchDates(search) {
    if (moment.isMoment(search.date_start)) search.date_start = search.date_start.format(dateFormat);
    if (moment.isMoment(search.date_end)) search.date_end = search.date_end.format(dateFormat);
    if (moment.isMoment(search.month)) search.month = search.month.format(dateFormat);
    if (moment.isMoment(search.visitCreated_start)) search.visitCreated_start = search.visitCreated_start.format(dateFormat);
    if (moment.isMoment(search.visitCreated_end)) search.visitCreated_end = search.visitCreated_end.format(dateFormat);
}

export function createVisit(visit) {
    return baseRequest('/api/visit', {
        method: 'POST',
        body: JSON.stringify({...processVisit(visit)}),
    });
}

export function updateVisit(visit) {
    return baseRequest(`/api/visit/${visit.id}`, {
        method: 'PUT',
        body: JSON.stringify({...processVisit(visit)})
    })
}

export function copyVisitApi(visit) {
    return baseRequest(`/api/visit/copy`, {
        method: 'POST',
        body: JSON.stringify({...processVisit(visit)})
    })
}

export function calculateVisit(visit) {
    return baseRequest(`/api/visit/calculate`, {
        method: 'POST',
        body: JSON.stringify({...processVisit(visit)})
    })
}

export function extendVisit(id) {
    return baseRequest(`/api/visit/extend/${id}`, {
        method: 'PUT'
    })
}

export function extendVisitForGuestWithUnclosedVisits(id) {
    return baseRequest(`/api/visit/extend-for-guest-with-unclosed-visit/${id}`, {
        method: 'PUT'
    })
}

export function getVisits(search, page = 1, per_page = -1) {
    let url = '/api/visit';
    const q = queryString.stringify({search, page, per_page});

    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getVisitSnapshots(search, page = 1, per_page = -1) {
    let url = '/api/visit-snapshot';
    const q = queryString.stringify({search, page, per_page});

    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function cancelVisit(data) {
    let url = `/api/visit/cancel/${data.id}`;

    return baseRequest(url, {
        method: 'PUT',
        body: JSON.stringify(data)
    })
}

export function getVisit(id) {
    return baseRequest(`/api/visit/id/${id}`)
}

export function removeVisit(id) {
    return baseRequest(`/api/visit/remove/${id}`, {
        method: 'DELETE'
    })
}

export function removeCreatedVisit(id) {
    return baseRequest(`/api/visit/remove-created/${id}`, {
        method: 'DELETE'
    })
}

export function getDiscounts(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/sale/discount', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getDiscountsForVisit(search) {
    return baseRequest('/api/sale/discounts-for-visit', {
        query: {search}
    });
}

export function getDiscountTypes() {
    return baseRequest('/api/sale/discount/type');
}

export function getDiscountActiveTypes() {
    return baseRequest('/api/sale/discount/type-active');
}

export function saveDiscount(discount) {
    return baseRequest('/api/sale/discount', {
        method: 'POST',
        body: JSON.stringify({...discount}),
    });
}

export function saveDiscountDateRestriction(discountDateRestriction) {
    return baseRequest('/api/sale/discount-date-restriction', {
        method: 'POST',
        body: JSON.stringify({...discountDateRestriction}),
    });
}

export function getDiscountDateRestriction(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/sale/discount-date-restriction', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getDiscountDateRestrictionHistory(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/sale/discount-date-restriction-history', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function confirmVisit(id) {
    let url = `/api/visit/confirm/${id}`;

    return baseRequest(url, {
        method: 'PUT'
    })
}

export function startVisitAndSaveMedicalCardParts(data) {
    let url = `/api/visit/start/${data.visitId}`;

    return baseRequest(url, {
        method: 'PUT',
        body: JSON.stringify(data),
    })
}

export function provideVisit(id) {
    let url = `/api/visit/provide/${id}`;

    return baseRequest(url, {
        method: 'PUT'
    })
}

export function completeVisit(id) {
    let url = `/api/visit/complete/${id}`;

    return baseRequest(url, {
        method: 'PUT'
    })
}

export function getMedicalCardData(id) {
    return baseRequest(`/api/visit/medical-card/${id}`);
}

export function saveMedicalCardParts(data) {
    let url = `/api/visit/medical-card/${data.visitId}`;

    return baseRequest(url, {
        method: 'PUT',
        body: JSON.stringify(data),
    })
}

export function closeVisit(id) {
    let url = `/api/visit/close/${id}`;

    return baseRequest(url, {
        method: 'PUT',
    })
}

export function closeVisitWithoutCode(id) {
    let url = `/api/visit/close/without/code/${id}`;

    return baseRequest(url, {
        method: 'PUT',
    })
}

export function notifySmsGuestCloseVisit(data) {
    let url = `/api/visit/notify-sms-guest-close-visit`;

    return baseRequest(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
}

export function checkCodeForClosingVisit(data) {
    let url = `/api/visit/check-closing-code-for-visit`;

    return baseRequest(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
}

export function cancelCodeForClosingVisit(data) {
    let url = `/api/visit/cancel-sms-guest-close-visit`;

    return baseRequest(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
}

export function reviewVisit(id, rating, review) {
    let url = `/api/visit/review/${id}`;

    return baseRequest(url, {
        method: 'PUT',
        body: JSON.stringify({rating, review}),
    })
}

export function getHistoryChange(id, page, per_page, order, order_by) {
    let url = `/api/visit/history-change/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});

    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

function processVisit(visit) {
    return {
        ...visit,
        files: visit.files && Array.isArray(visit.files) ? visit.files.map(item => item.id) : [],
        parlour: visit.parlour && visit.parlour.id,
        visitFacility: visit.visitFacility.map(item => ({
            ...item,
            price: item.price ? `${item.price} RUB` : null,
            priceId: item.priceId ? item.priceId : null,
            duration: item.duration ? (moment.isDuration(item.duration) ? item.duration.asMinutes() : item.duration * 60) : null,
            start: item.start?.format() || moment().format(),
            facility: item.facility && typeof item.facility === "object" ? item.facility.id : item.facility,
        })),
        goodStorageSale: visit.goodStorageSale && visit.goodStorageSale.storageGoods ? {
                ...visit.goodStorageSale,
                guest: visit.guest ? (visit.guest.id ? visit.guest.id : visit.guest) : null,
                parlour: visit.parlour.id,
                storageGoods: visit.goodStorageSale.storageGoods.map(item => item.id),
            }
            : {},
        guest: visit.guest && typeof visit.guest === "object" ? visit.guest.id : visit.guest,
        price: visit.price ? (visit.price.val !== undefined && visit.price.cur !== undefined ? visit.price : `${visit.price} RUB`) : null,
    }
}

export const visitStatusNames = {
    new: 'New',
    created: 'Created',
    confirmed: 'Confirmed',
    risky: 'Risky',
    starting: 'Guest come in',
    providing: 'Providing',
    complete: 'Complete',
    closed: 'Closed',
    blocked: 'Blocked',
    canceled: 'Cancelled',
};

const visitStatusColors = {
    new: '#ffff99',
    created: '#00cc00',
    confirmed: '#ff9900',
    risky: '#ff5800',
    starting: '#66ff66',
    providing: '#00ccff',
    complete: '#4741ff',
    closed: '#a220a9',
    blocked: '#ff8080',
    canceled: '#9a9b99',
};

export const visitStatuses = {
    /** Новый */
    new: 'new',

    /** Создан (подтверждение создания визита) */
    created: 'created',

    /** Подтвержден */
    confirmed: 'confirmed',

    /** Рисковый */
    risky: 'risky',

    /** Гость пришел в салон */
    starting: 'starting',

    /** Услуга предоставляется */
    providing: 'providing',

    /** Услуга предоставлена */
    complete: 'complete',

    /** Закрыт */
    closed: 'closed',

    /** Заблокирован */
    blocked: 'blocked',

    /** Отменен */
    canceled: 'canceled',
};

export function getVisitStatuses() {
    let statuses = [];

    for (let statusKey in visitStatuses) {
        statuses.push(visitStatuses[statusKey]);
    }

    return statuses;
}

export function getVisitStatusText(status) {
    return status && visitStatusNames[status] ? visitStatusNames[status] : 'Unknown';
}

export function getVisitStatusColor(status) {
    return visitStatusColors[status] ? visitStatusColors[status] : '#fff';
}

export function fetchVisitStatuses() {
    return baseRequest('/api/common/schedule/visit-status');
}

export function getVisitChannels() {
    return baseRequest(`/api/visit/get-visit-channels`)
}

export function getVisitMasterNames(visit) {
    if (visit && visit.visitFacility && visit.visitFacility.length) {
        return _.uniq(_.flatten(visit.visitFacility.map(vf => vf.master.map(master => master.name)))).join(', ');
    }

    return null;
}

export function getRequestedVisits(search, page, per_page, order = 'asc', order_by = 'startFacility') {
    processSearchDates(search);

    return baseRequest('/api/visit/report/requested', {
        query: {search, page, per_page, order, order_by}
    });
}

export function getFullVisitsReport(search, signal, page, per_page, order = 'asc', order_by = 'startFacility') {
    processSearchDates(search);

    return baseRequest('/api/visit/report/full', {
        query: {search, page, per_page, order, order_by},
        signal,
    });
}

export function getReportSummaryVisits(search, signal = null) {
    processSearchDates(search);

    return baseRequest('/api/visit/report/full-summary', {
        query: {
            search: {
                dateStart: search.date_start,
                dateEnd: search.date_end,
                businessUnitIds: search.businessUnitIds
            }
        },
        signal,
    })
}

export function getCanceledVisitsReport(search, page, per_page, order = 'asc', order_by = 'id'){
    return baseRequest('/api/visit/report/canceled', {
        query: {search,page, per_page, order, order_by}
    });
}

export function getParlourTimes(parlour, date) {
    const weekDay = date ? date.isoWeekday() : 1;
    let startTime = moment.duration("00:00");
    let endTime = moment.duration("23:59");
    let relaxTime = moment.duration("00:15");

    if (parlour) {
        if (parlour.workTime[weekDay]) {
            const workDay = parlour.workTime[weekDay];
            if (workDay && workDay.start && workDay.finish) {
                startTime = moment.duration(workDay.start);
                endTime = moment.duration(workDay.finish);
                if (endTime < startTime) {
                    endTime.add(1, 'days');
                }
            }
        }

        if (parlour.relaxTime) {
            relaxTime = parlour.relaxTime;
        }
    }

    return {
        startTime,
        endTime,
        relaxTime,
    };
}

export function getVisitCancelReasons() {
    return baseRequest('/api/visit/cancel-reasons');
}

export function patchVisit(visitId, patchFields) {
    return baseRequest(`/api/visit/${visitId}`, {
        method: 'PATCH',
        body: patchFields,
    })
}

export const waitingMasterColor = 'rgba(255,249,0,0.36)';
export const transferMasterColor = 'rgb(67,68,83, 0.36)';

export function findFacilityPrice(facilities, visit, vf, newParlour) {
    const oldParlourFacilities = facilities && facilities[visit.parlour.id];
    if (!oldParlourFacilities) {
        throw new Error('Not old facilities');
    }
    const parlourFacilities = facilities && facilities[newParlour.id];
    if (!parlourFacilities) {
        throw new Error('Not new facilities');
    }
    const oldFacility = oldParlourFacilities.find(facility => facility.id === vf.facility.id);
    if (!oldFacility) {
        throw new Error('Not old facility');
    }
    const facility = parlourFacilities.find(facility => facility.id === vf.facility.id);
    if (!facility) {
        throw new Error('Not facility');
    }
    const oldFacilityPrice = oldFacility.prices.find(fp => fp.id === vf.priceId);
    if (!oldFacilityPrice) {
        throw new Error('Not old FP');
    }
    const newPrice = newParlour.prices[0];
    if (!newPrice) {
        throw new Error('Not new price');
    }
    const newFacilityPrice = facility.prices.find(fp =>
        fp.priceId === newPrice.id &&
        fp.time === oldFacilityPrice.time &&
        fp.variantId === oldFacilityPrice.variantId
    );

    if (!newFacilityPrice) {
        throw new Error('Not found new FP');
    }

    return newFacilityPrice.id;
}

export const availableToCutStatuses = [
    visitStatuses.created,
    visitStatuses.confirmed,
    visitStatuses.risky,
    visitStatuses.starting,
    visitStatuses.providing
];

export function cutVisit(props, selectedVisit) {
    const {t} = props;
    if (!selectedVisit) {
        return;
    }
    if (availableToCutStatuses.indexOf(selectedVisit.status) === -1) {
        props.showError(t('Visit with this status cannot be cut'));
        return;
    }
    props.setBuffer(selectedVisit, 'cut');
    props.showInfo(t('Visit cut out'));
}

export function copyVisit(props, selectedVisit) {
    const {t} = props;
    if (!selectedVisit) {
        return;
    }
    props.setBuffer(selectedVisit, 'copy');
    props.showInfo(t('Visit copied'));
}

export function pasteVisit(props, selectedVisit, selectedMoment, selectedMaster) {
    let cloneDeep = require('lodash/cloneDeep');

    const {t} = props;

    if (!props.buffer) {
        props.showError(t('Nothing to paste'));
        return;
    }

    if (!selectedMoment || !selectedMaster) {
        props.showError(t('No slot chosen to paste'));
        return;
    }

    if (selectedVisit) {
        props.showError(t('Selected slot is already used'));
        return;
    }

    let visitData = cloneDeep(props.buffer);

    const diffStart = visitData.start.isSame(selectedMoment) ? null : selectedMoment.diff(visitData.start);
    let newParlourHavePrice = true;

    visitData.visitFacility.forEach(vf => {
        if (diffStart) {
            vf.start.add(diffStart);
            vf.end.add(diffStart);
            vf.startDay = vf.start.clone().startOf('day');
        }

        if (vf.master.length === 1) {
            vf.master[0].id = selectedMaster.id;
        } else {
            props.showError(t('Visits with multiply masters cannot be cut out or copied'));
            return;
        }

        if (visitData.parlour.id !== props.currentParlour.id) {
            newParlourHavePrice = props.currentParlour.prices.find(price => price.id === vf.pricePriceId);
        }
    });

    const facilities = {};
    let waitFacilities = [];

    if (visitData.parlour.id !== props.currentParlour.id && !newParlourHavePrice) {
        [visitData.parlour.id, props.currentParlour.id].forEach(parlourId => {
            waitFacilities.push(
                new Promise(resolve => {
                    getFacilitiesVisit({
                        parlour: parlourId,
                        allPrices: true
                    }).then(response => {
                        if (response.success) {
                            facilities[parlourId] = response.data.map(facility => ({
                                ...facility,
                                prices: _.flatMap(facility.prices, price => {
                                    const {durations, ...priceFields} = price;
                                    return durations.map(duration => ({
                                        ...priceFields, ...duration
                                    }));
                                })
                            }));

                            resolve();
                        }
                    })
                })
            )
        });
    }

    Promise.all(waitFacilities).then(() => {
        if (visitData.parlour.id !== props.currentParlour.id) {
            if (!newParlourHavePrice) {
                visitData.visitFacility.forEach(vf => {
                    try {
                        vf.priceId = findFacilityPrice(facilities, visitData, vf, props.currentParlour);
                    } catch (e) {
                        props.showError(e.message);
                    }
                });
            }
            visitData.parlour = props.currentParlour;
        }

        if (props.bufferCutOrCopy === 'copy') {
            //visitData.id = null;
            visitData.status = visitStatuses.created;
            copyVisitApi(visitData)
                .then(response => {
                    if (response.success) {
                        props.showInfo(t('Copied visit pasted'));
                    } else {
                        props.showError(response.error ? response.error.message : response.message);
                    }
                })
        } else {
            updateVisit(visitData)
                .then(response => {
                    if (response.success) {
                        props.showInfo(t('Cut out visit pasted'));
                    } else {
                        props.showError(response.error ? response.error.message : response.message);
                    }
                })
        }
    });
}

export function continueVisit(id) {
    return baseRequest(`/api/visit/continue/${id}`, {
        method: 'POST'
    });
}

export function getVisitCountGuests(day, businessUnitId) {
    return baseRequest('/api/visit-count-guests', {
        query: {day, businessUnitId}
    });
}

export function getVisitCountGuestsPeriod(dayStart, dayEnd, businessUnitId) {
    let query = processMomentFieldInObject({dayStart, dayEnd, businessUnitId}, ['dayStart', 'dayEnd']);
    return baseRequest('/api/visit-count-guests-period', {
        query: query
    });
}

export function getGuestPastVisitsCountByVisit(id) {
    return baseRequest(`/api/visit-count-guests-by-visit/${id}`);
}

export function getGuestPastVisitCount(query) {
    return baseRequest(`/api/guest-past-visit-count`, {
        query: query
    });
}

export function checkUnclosedVisits(ids, start, end) {
    return baseRequest(`/api/visit/check-unclosed-visits`, {
        query: {ids, start, end}
    });
}

export function sendVisitInformationToGuest(id) {
    return baseRequest(`/api/visit/send-info-to-guest/${id}`)
}

export function getGuestsLastEntry(ids, guestId = null) {
    return baseRequest(`/api/visit/load-guests-last-entry`, {
        query: {ids, guestId}
    });
}

export function getContinueVisits(id) {
    return baseRequest(`/api/visit/continue/visits/${id}`)
}

export const discountTypeCustom = 'custom'
