import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getExternalSubjects} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getExternalDebts,
    getIncomingTypes,
    navLinks
} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import CheckboxField from "../../components/fields/CheckboxField";
import {getBusinessUnitByRole, hasRole} from "../../services/user";
import DateMonthField from "../../components/fields/DateMonthField";
import {FormControl, FormControlLabel,Radio, RadioGroup} from "@material-ui/core";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withTranslation()
class ExternalDebtListPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: null,
            date_end: null,
            month: null,
            dateType: 'period',
            businessUnitIds: [],
            employeeId: null,
            incomingTypesIds: [],
            unpaid: false,
            juridicalPersonIds: [],
            paymentTypes:[],
            externalSubjectIds: []
        },
        refreshTable: false,
        businessUnits: [],
        incomingTypes: [],
        externalSubjects:[],
    };

    columnData = [
        { id: 'amountFormat', label: 'Sum' },
        { id: 'applyAt', label: 'Debt apply', dateFormat: 'MMMM YYYY' },
        { id: 'businessUnitName', label: 'Business unit', disableSorting: true },
        { id: 'externalSubjectName', label: 'Counterparty (external subject)' },
        { id: 'juridicalPersonName', label: 'Juridical person', disableSorting: true },
        { id: 'juridicalPersonCheckingAccountRs', label: 'Juridical person checking account', disableSorting: true },
        { id: 'reasonName', label: 'Incoming type' },
        { id: 'comment', label: 'Comment', disableSorting: true },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss', disableSorting: true },
        { id: 'creatorName', label: 'Creator', disableSorting: true },
    ];

    componentDidMount() {
        getBusinessUnitByRole('ROLE_MONEYFLOW_EXTERNAL_DEBT_MANAGE')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data
                    });
                }
            });

        getIncomingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        incomingTypes: response.data
                    });
                }
            });

        getExternalSubjects()
            .then(response=>{
                if(response.success){
                    this.setState({
                        externalSubjects: response.data,
                    });

                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const { search } = this.state;
        return getExternalDebts(processMomentFieldInObject({
            ...search,
            date_start: search.date_start ? search.date_start.startOf('day') : null,
            date_end: search.date_end ? search.date_end.endOf('day') : null,
        }, ['date_start', 'date_end', 'month'],null), ...params);
    };

    handleCreate = () => {
        this.props.history.push(`/money-flow/external-debt/0`);
    };

    render() {
        const { t } = this.props;
        const {
            refreshTable,
            search,
            businessUnits,
            incomingTypes,
            externalSubjects
        } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.dateType}
                            onChange={this.handleChange('dateType')}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="period" label="за период" />
                            <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
                        </RadioGroup>
                    </FormControl>
                    {search.dateType === 'period' ?
                        <DatePeriodField
                            valueFrom={search.date_start}
                            valueTo={search.date_end}
                            onChangeFrom={this.handleChange('date_start')}
                            onChangeTo={this.handleChange('date_end')}
                            label={t("Show debts by date interval")}
                            required={false}
                        /> :
                        <DateMonthField
                            value={search.month}
                            onChange={this.handleChange('month')}
                            fullWidth
                        />
                    }
                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        label={t("Business unit")}
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                    />
                    <AutocompleteMultiSelectField
                        label={t("Counterparty (external subject)")}
                        value={search.externalSubjectIds}
                        required
                        onChange={this.handleChange('externalSubjectIds')}
                        fullWidth
                        options={externalSubjects}
                        valueField="id"
                        textField="name"
                    />
                    <AutocompleteMultiSelectField
                        label={t('Incoming type')}
                        value={search.incomingTypesIds}
                        name="incomingTypesIds"
                        onChange={this.handleChange('incomingTypesIds')}
                        fullWidth
                        options={incomingTypes}
                        placeholder={t('---All---')}
                        valueField="id"
                        textField="name"
                    />
                    <CheckboxField
                        label={t("Unpaid debts")}
                        value={search.unpaid}
                        onChange={this.handleChange('unpaid')}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                    <ActionButton
                        onClick={this.handleCreate} visible={hasRole('ROLE_MONEYFLOW_EXTERNAL_DEBT_MANAGE')}
                    >
                        {t("Create external debt")}
                    </ActionButton>
                </LeftBar>
                <Content title={t("External debts")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        linkTemplate={ (row) => `/money-flow/external-debt/id/` + row.id }
                        order="desc"
                        orderBy="createdAt"
                />
                </Content>
            </React.Fragment>
        );
    }
}
