import React from "react"
import LeftBar from "../../components/left-bar/LeftBar";
import {getVerifiedDataReport, navLinks, updateVerifiedData} from "../../services/guests";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import moment from "moment";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import ActionButton from "../../components/button/ActionButton";
import {withTranslation} from "react-i18next";
import Content from "../../components/content/Content";
import MaterialTable from "material-table";
import infoActions from "../../components/info/info-actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/styles";
import {employeeStatuses} from "../../services/organization";
import {getUsersFilter} from "../../services/user";

const dateFormat = "Y-MM-DD"

const styles = () => ({
    filterControl: {
        marginBottom: 15
    }
})

export default
@withStyles(styles)
@withTranslation()
@connect(null,
    {
        showError: infoActions.showError,
        show: infoActions.show
    })
class VerifiedDataReport extends React.Component {

    state = {
        search: {
            usersIds: [],
            dateFrom: moment().startOf("month").format(dateFormat),
            dateTo: moment().format(dateFormat),
        },
        users: [],
        verifiedData: [],
        isLoading: false
    }

    renderGender = (rowData) => this.genderLookup[rowData.gender]

    renderGuestPhone = (rowData) => (<a
                                        href={"view/" + rowData.id}
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                        >
                                            {rowData.phone}
                                        </a>)

    renderLastName = (rowData) => (this.isStringEmpty(rowData.lastName) ? this.emptyValue: rowData.lastName)

    renderName = (rowData) => (this.isStringEmpty(rowData.name)? this.emptyValue : rowData.name)

    isStringEmpty = (str) => !(str && str.length > 0 && str.replace(/\s/g, "") !== "")

    emptyValue = <span style={{color:"grey"}}>Не указано</span>

    genderLookup = {female: 'Женский', male: 'Мужской'}

    columnData = [
        {field: "phone", title: this.props.t("Guest phone"), editable: "never",
            render: this.renderGuestPhone
        },
        {field: "nameVerified", title: this.props.t("Verify status"), type: "boolean", cellStyle: {height: 40}},
        {field: "name", title: this.props.t("Guest name"), render: this.renderName},
        {
            field: "lastName",
            title: this.props.t("Guest lastname"),
            emptyValue: this.emptyValue,
            render: this.renderLastName },
        {
            field: "gender",
            title: this.props.t("Gender"),
            render: this.renderGender,
            lookup: this.genderLookup
        },
        {field: "birthDate", title: this.props.t("Date of Birth"), type: "date"},
        {field: "verifierName", title: this.props.t("Who verified"), editable: "never"},
        {field: "verifierPost", title: this.props.t("Who verified post"), editable: "never"},
        {field: "updaterName", title: this.props.t("Who updated"), editable: "never"},
        {field: "updaterPost", title: this.props.t("Who updated post"), editable: "never"},
    ]
        .map(column => ({...column, emptyValue: this.emptyValue}))

    tableOptions = {
        sorting: false,
        showTitle: false,
        pageSize: 50,
        pageSizeOptions: [10, 30 ,50 , 100, 150],
        emptyRowsWhenPaging: false,
        minBodyHeight: 700
    };

    tableLocalizationOptions = {
        toolbar: {
            searchTooltip: this.props.t('Search'),
            searchPlaceholder: this.props.t('Search'),
        },
        body: {
            emptyDataSourceMessage: this.props.t('No records to display')
        },
        pagination: {
            labelRowsSelect: this.props.t("MaterialTable.rows"),
            labelDisplayedRows: `{from}-{to} ${this.props.t("MaterialTable.of")} {count}`
        }
    }

    componentDidMount() {
        this.fetchUsers()
        this.fetchVerifiedData()
    }

    fetchVerifiedData = () => {

        const { search } = this.state

        this.setState({isLoading: true})

        getVerifiedDataReport(search)
            .then(response => {
                if (response.success) {
                    this.setState({
                        verifiedData: response.data,
                        isLoading: false
                    })
                }
            })
    }

    fetchUsers = () => {
        getUsersFilter({excludeEmployeeStatuses: [employeeStatuses.fired]})
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data
                    })
                }
            })
    }

    render() {

        const { search, isLoading, users } = this.state
        const { t, classes } = this.props

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField2
                    label={t("Date")}
                    nameFrom={'dateFrom'}
                    nameTo={'dateTo'}
                    valueFrom={search.dateFrom}
                    valueTo={search.dateTo}
                    onChangeFrom={this.handleChangeSearch}
                    onChangeTo={this.handleChangeSearch}
                    checkValuesOrder
                    maxDate={moment()}
                    className={classes.filterControl}
                />
                <br />
                <AutocompleteMultiSelectField
                    label={t("Who verified")}
                    name={"usersIds"}
                    value={search.usersIds}
                    options={users}
                    textField={"username"}
                    fullWidth
                    onChange={this.handleChangeSearch}
                    style={{marginBottom: "0px"}}
                    selectStyles={{menuList: (styles) => ({...styles, maxHeight: 500})}}
                    className={classes.filterControl}
                />
                <br />
                <br />
                <ActionButton
                    onClick={this.handleClickFilter}
                >
                    {t("Filter")}
                </ActionButton>
            </LeftBar>
            <Content title={t("Verified Data Report")}>
                <MaterialTable
                    columns={this.columnData.map((
                        c) => ({ ...c, tableData: undefined }))
                        // без этого могут быть проблемы с зависанием вкладки браузера
                        // если несколько раз обновлять данные таблицы
                    }
                    data={this.state.verifiedData}
                    cellEditable={this.cellEditableObj}
                    options={this.tableOptions}
                    isLoading={isLoading}
                    localization={this.tableLocalizationOptions}
                />
            </Content>
        </React.Fragment>
    }

    handleChangeSearch = (event) => {

        let value = event.target.value

        if (event.target.name === 'dateFrom' || event.target.name === 'dateTo') {
            value = value.format(dateFormat)
        }

        this.setState({
            search: {
                ...this.state.search,
                [event.target.name] : value
            }
        })
    }

    handleChange = (event) => {

        const { value } = event.target

        this.setState({
                [event.target.name] : value
        })
    }

    handleClickFilter = () => {
        this.fetchVerifiedData()
    }

    updateVerifiedData = (newValue, oldValue, rowData, columnDef) => {

        const { t } = this.props
        const { gender, birthDate, name, lastName, nameVerified, id } = rowData
        const request = { id, gender, birthDate: moment(birthDate).utc(), name, lastName, nameVerified, [columnDef.field]: newValue }

        return updateVerifiedData(request)
            .then(response => {
                if(response.success){
                    const { verifiedData } = this.state;

                    let updatedVerifiedData = verifiedData

                    Object.keys(response.data).forEach(key => {
                        updatedVerifiedData[rowData.tableData.id][key] = response.data[key]
                    })

                    this.setState({
                        verifiedData: updatedVerifiedData
                    })

                    this.props.show(t("Changes saved"))
                } else {
                    this.props.showError(response.error ? response.error.message : response.message)
                }
            })
    }

    cellEditableObj = { onCellEditApproved: this.updateVerifiedData };
}
