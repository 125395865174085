import React from "react";

const incomingValue = (item) => {
    let result = [];
    for (let currency in item) {
        if(!item.hasOwnProperty(currency)) {
            continue;
        }
        result.push(<span key={currency} style={{whiteSpace: 'nowrap'}}>{item[currency]} <br/></span>);
    }

    return result;
}

export default incomingValue;
