import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    emptyRequest,
    getGoodStorageRequest, getRequestHistoryStatus,
    navLinks,
    processRequestAfterGet
} from "../../services/goods";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import DataTable from "../../components/data-table/DataTable";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";

const columnData = [
    { id: 'dt', numeric: false, disablePadding: false, label: 'Дата и время', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'oldStatus', numeric: false, disablePadding: false, label: 'Старый статус' },
    { id: 'newStatus', numeric: false, disablePadding: false, label: 'Новый статус' },
    { id: 'user', numeric: false, disablePadding: false, label: 'Пользователь' },
];

@withRouter
class HistoryRequestStatusPage extends React.Component
{
    state = {
        id: null,
        request: emptyRequest,
        refreshTable: false,
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            if (resultId[0] !== "0") {
                getGoodStorageRequest(resultId[0])
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                request: processRequestAfterGet(response.data),
                            })
                        }
                    });
            }
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/requests/view/${this.state.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getRequestHistoryStatus(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    {this.renderLeftFields()}
                    <br/><br/>
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>

                <Content title="История изменения статусов запроса">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        pagination={false}
                    />
                </Content>
            </React.Fragment>
        );
    }

    renderLeftFields() {
        const { request } = this.state;

        return (
            <React.Fragment>
                <ShowField
                    label="Номер запроса"
                    value={request.num}
                />
                <ShowField
                    label="Дата создания запроса"
                    value={request.createdAt}
                    dateFormat="DD.MM.YYYY"
                />
                <ShowField
                    label="Пользователь, создавший запрос"
                    value={request.creator.username}
                />
                <ShowField
                    label="Тел. пользователя, создавшего запрос"
                    value={request.creator.phone}
                    label2Lines
                />
                <ShowField
                    label="Примерная стоимость запроса"
                    value={request.price.val}
                />
                <ShowField
                    label="Комментарий"
                    value={request.comment}
                />
                <ShowField
                    label="Статус"
                    value={request.statusText}
                />
            </React.Fragment>
        );
    }
}

export default HistoryRequestStatusPage;
