export const emptyGuestStats = {
    master: {
        id: null,
        name: null,
        weight: 0,
    },
    parlour: {
        id: null,
        name: null,
        weight: 0,
    },
    procedure: {
        id: null,
        name: null,
        weight: 0,
    },
    city: {
        id: null,
        name: null,
        weight: 0,
    },
};
