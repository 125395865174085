import baseRequest from "./baseReuqest";
import { camelCase } from "lodash";
import * as queryString from "query-string-object";

export function getReportPayDocDelivery(search, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc/report-pay-doc-delivery', {query: {search, page, per_page, order, order_by}})
}

export function getPayDocs(search, search_from, search_to, businessUnitIds, status, statuses, typeIds, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc', {
        query: {search, search_from, search_to, businessUnitIds, status, typeIds, page, per_page, order, order_by}
    });
}

export function getPayDocsByParent(id){
    return baseRequest(`/api/pay-doc/parent/${id}`);
}

export function getPayDocsByMain(id){
    return baseRequest(`/api/pay-doc/main/${id}`);
}

export function getPayDocsNew(search, search_from, search_to, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc', {
        query: {search, search_from, search_to, page, per_page, status: payDocStatuses.new, order, order_by}
    });
}
export function getPayDocsDelivery(search, search_from, search_to, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc', {
        query: {search, search_from, search_to, page, per_page, status: payDocStatuses.delivery, order, order_by}
    });
}
export function getPayDocsForSale(search, search_from, search_to, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc', {
        query: {search, search_from, search_to, page, per_page, status: payDocStatuses.for_sale, order, order_by}
    });
}
export function getPayDocsPartner(search, search_from, search_to, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc', {
        query: {search, search_from, search_to, page, per_page, isPartner: true, order, order_by}
    });
}

export function getPayDocsSaleStats(dateFrom, dateTo, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc/sale-stats', {
        query: {page, per_page, dateFrom, dateTo, order, order_by},
    });
}

export function getPayDoc(id) {
    return baseRequest(`/api/pay-doc/id/${id}`);
}

export function getPayDocTypes(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/pay-doc/type', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by},
    });
}

export function getPayDocPromoList(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/paydoc-promo/get', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by},
    });
}

export function createPayDocPromo(data) {
    return baseRequest('/api/paydoc-promo/create', {
        method: 'POST',
        body: data
    });
}

export function updatePayDocPromo(data) {
    return baseRequest('/api/paydoc-promo/update', {
        method: 'POST',
        body: data
    });
}

export function removePayDocPromo(id) {
    return baseRequest(`/api/paydoc-promo/remove/${id}`, {
        method: 'POST',
    });
}

export function getPayDocPromo(id) {
    return baseRequest(`/api/paydoc-promo/id/${id}`);
}

export function disablePayDocPromo(id) {
    return baseRequest(`/api/paydoc-promo/disable/${id}`, {
        method: 'POST'
    });
}
export function getPayDocTypesSecuredCreating() {
    return baseRequest('/api/pay-doc/type/secured-creating');
}

export function getPayDocType(id) {
    return baseRequest(`/api/pay-doc/type/id/${id}`);
}

export function savePayDocType(id, data) {
    return baseRequest(`/api/pay-doc/type/id/${id}`,{
        method: 'POST',
        body: data,
    });
}

export function getPayDocStatuses() {
    return baseRequest('/api/pay-doc/status');
}

export function getPayDocMeasures() {
    return baseRequest('/api/pay-doc/type/measures');
}

export function getPayDocVariants() {
    return baseRequest('/api/pay-doc/type/variants');
}

export function getPayDocAskPinRules() {
    return baseRequest('/api/pay-doc/type/pins');
}

export function getPayDocSubtypes(typeId) {

    return baseRequest(`/api/pay-doc/subtype/type/${typeId}`);
}

export function createPayDocs(data) {
    return baseRequest('/api/pay-doc', {
        method: 'POST',
        body: data,
    });
}

export function savePayDoc(data) {
    return baseRequest(`/api/pay-doc/save`, {
        method: 'POST',
        body: {...data},
    });
}

export function saveDeliveryPayDoc(deliveryCertificate) {
    return baseRequest('/api/pay-doc/delivery', {
        method: 'POST',
        body: {...deliveryCertificate},
    })
}

export function getDeliveryPayDoc(id) {
    return baseRequest(`/api/pay-doc/delivery/${id}`);
}

export function register(id, data) {
    return baseRequest(`/api/pay-doc/register/${id}`, {
        method: 'PUT',
        body: {...data},
    });
}

export function update(id, data) {
    return baseRequest(`/api/pay-doc/update/${id}`, {
        method: 'PUT',
        body: {...data},
    });
}


export function setBalances(selected, balance) {
    return baseRequest('/api/pay-doc/balances', {
        method: 'PUT',
        body: {selected, balance},
    });
}

export function registerSelected(selected) {
    return baseRequest('/api/pay-doc/register', {
        method: 'PUT',
        body: {selected},
    });
}

export function defect(selected) {
    return baseRequest('/api/pay-doc/defect', {
        method: 'PUT',
        body: {selected},
    });
}

export function sale(id, guest) {
    return baseRequest(`/api/pay-doc/sale/${id}`, {
        method: 'PUT',
        body: {...guest},
    });
}

export function resale(id,guest){
    return baseRequest(`/api/pay-doc/resale`,
        {
            method: 'POST',
            body: {id,...guest},
        }
        );
}

export function completeSale(data) {
    return baseRequest(`/api/pay-doc/complete-sale`, {
        method: 'PUT',
        body: {...data},
    });
}

export function lock(id, reason) {
    return baseRequest(`/api/pay-doc/lock`, {
        method: 'PUT',
        body: {id, reason},
    });
}

export function activate(id, guestId, pin) {
    return baseRequest('/api/pay-doc/activate', {
        method: 'PUT',
        body: {id, guestId, pin},
    });
}

export function recharge(data) {
    return baseRequest('/api/pay-doc/recharge', {
        method: 'PUT',
        body: data,
    });
}

export function removeRecharge(id) {
    return baseRequest(`/api/pay-doc/recharge/${id}`, {
        method: 'DELETE',
    });
}

export function findUnclosedRefillByPayDocId(id, rechargeBySite) {
    return baseRequest(`/api/pay-doc/recharge/find-unclosed/${id}`, {
        query: {rechargeBySite: rechargeBySite ? 1 : null},
    });
}

export function completeRecharge(data) {
    return baseRequest('/api/pay-doc/complete-recharge', {
        method: 'PUT',
        body: data,
    });
}

export function getHistoryBalance(id, page, per_page, order, order_by) {
    return baseRequest(`/api/pay-doc/history/balance/${id}`, {
        query: {page, per_page, order, order_by},
    });
}

export function getHistoryGuest(id, page, per_page, order, order_by) {
    return baseRequest(`/api/pay-doc/history/guest/${id}`, {
        query: {page, per_page, order, order_by}
    });
}

export function getHistorySale(id, page, per_page, order, order_by) {
    return baseRequest(`/api/pay-doc/history/sale/${id}`, {
        query: {page, per_page, order, order_by}
    });
}


export function getHistoryBusinessUnit(id, page, per_page, order, order_by) {
    return baseRequest(`/api/pay-doc/history/business-unit/${id}`, {
        query: {page, per_page, order, order_by}
    });
}

export function getHistoryStatus(id, page, per_page, order, order_by) {
    return baseRequest(`/api/pay-doc/history/status/${id}`, {
        query: {page, per_page, order, order_by}
    });
}

export function getHistoryValidity(id, page, per_page, order, order_by) {
    return baseRequest(`/api/pay-doc/history/validity/${id}`, {
        query: {page, per_page, order, order_by},
    });
}


export function getRequests(search, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc/request', {
        query: {search, page, per_page, order, order_by},
    });
}

export function getRequestTypes() {
    return baseRequest('/api/pay-doc/request/type');
}

export function getRequest(id) {
    return baseRequest(`/api/pay-doc/request/id/${id}`);
}

export function createRequest(selected, type, data) {
    return baseRequest('/api/pay-doc/request', {
        method: 'POST',
        body: {selected, type, ...data},
    });
}

export function getRequestPayDocs(id, page, per_page, order, order_by) {
    return baseRequest(`/api/pay-doc/request/docs/${id}`, {
        query: {page, per_page, order, order_by}
    });
}

export function getRequestPayDocsGrouped(id, page, per_page, order, order_by) {
    return baseRequest(`/api/pay-doc/request/docs-group/${id}`, {
        query: {page, per_page, order, order_by}
    });
}

export function setRequestComment(id, comment) {
    return baseRequest('/api/pay-doc/request/comment', {
        method: 'PUT',
        body: {id, comment},
    });
}

export function acceptRequest(id, data) {
    return baseRequest(`/api/pay-doc/request/accept/${id}`, {
        method: 'PUT',
        body: {...data},
    });
}

export function declineRequest(id, data) {
    return baseRequest(`/api/pay-doc/request/decline/${id}`, {
        method: 'PUT',
        body: {...data},
    });
}

export function deliveryRequest(id, data) {
    return baseRequest(`/api/pay-doc/request/delivery/${id}`, {
        method: 'PUT',
        body: {...data},
    });
}

export function fastCompleteRequest(id) {
    return baseRequest(`/api/pay-doc/request/fast-complete/${id}`, {
        method: 'PUT',
    });
}

export function fillNominalsRequest(id, nominals, isBalance) {
    return baseRequest('/api/pay-doc/request/fill-nominals', {
        method: 'PUT',
        body: {id, nominals, isBalance},
    });
}

export function getRequestHistoryStatus(id, page, per_page) {
    return baseRequest(`/api/pay-doc/request/history/status/${id}`, {
        query: {page, per_page},
    });
}

export function getPayDelays() {
    return baseRequest('/api/pay-doc/pay-delay', {
        query: {...arguments}
    });
}

export function getPayDocsByGuest(guestId, saleId) {
    return baseRequest('/api/pay-doc/for-payment', {
        query: {page: 1, per_page: -1,  guest: guestId, saleId},
    });
}

export const payDocStatuses = {
    new: 'new',
    making: 'making',
    made: 'made',
    in_main_office: 'in_main_office',
    delivery: 'delivery',
    for_sale: 'for_sale',
    delivery_guest: 'delivery_guest',
    sold: 'sold',
    active: 'active',
    expired: 'expired',
    renewal: 'renewal',
    liquidated: 'liquidated',
    locked: 'locked',
    defect: 'defect',
    burned: 'burned',
};

export const requestStatuses = {
    /** Запрос на подтверждение производства */
    request_production: 'request_production',

    /** Подтверждено производством */
    production_ok: 'production_ok',

    /** Отклонено производством */
    production_cancel: 'production_cancel',

    /** Согласование бухгалтерией */
    in_accounting: 'in_accounting',

    /** Отклонено ЦО */
    main_cancel: 'main_cancel',

    /** Отправлено в производство */
    sent_production: 'sent_production',

    /** Отклонено бухгалтерией */
    accounting_cancel: 'accounting_cancel',

    /** Принято в производство */
    in_production: 'in_production',

    /** Заявка снята производством */
    revert_production: 'revert_production',

    /** Доставка в ЦО*/
    delivery_main: 'delivery_main',

    /** Получено ЦО */
    main: 'main',

    /** Доставка в салон */
    delivery_parlour: 'delivery_parlour',

    /** Согласование ЦО*/
    in_main: 'in_main',

    /** Согласовано ЦО*/
    main_ok: 'main_ok',

    /** Превышен лимит */
    limit_overrated: 'limit_overrated'
};

export const requestTypes = {
    make: 'make',
    renewal: 'renewal',
    move: 'move',
};

export const deliveryTypes = {
    courier: 'courier',
    transport_company: 'transport_company'
};

export function getDeliveryTypeText($type)
{
    switch ($type) {
        case deliveryTypes.courier:
            return 'Курьер';

        case deliveryTypes.transport_company:
            return 'Транспортная компания';

        default:
            return '';
    }
}

export const payTypes = {
    /** Предоплата */
    pre_pay: 'pre_pay',
    /** Оплата с отсрочкой */
    delay_pay: 'delay_pay',
};

export const payDocMeasures = {
    time: 'time',
    money: 'money'
};

export function getPayTypeText($type) {
    switch ($type) {
        case payTypes.pre_pay:
            return 'Предоплата';

        case payTypes.delay_pay:
            return 'Оплата с отсрочкой';

        default:
            return '';
    }
}

export const navLinks = [
    { path: "/pay-docs", text: "Payment documents", role: "ROLE_UI_TAB_PAYDOCS"},
    { path: "/pay-docs/create", text: "Application for production of PD", role: "ROLE_UI_TAB_PAYDOCS_CREATE"},
    { path: "/pay-docs/create-one", text: "Create electron PD", role: "ROLE_UI_TAB_PAYDOC_SAVE_ONE"},
    { path: "/pay-docs/sale-stats", text: "PD implementation statistics", role: "ROLE_UI_TAB_PAYDOCS_SALESTATS"},
    { path: "/pay-docs/make", text: "Production PD", role: "ROLE_UI_TAB_PAYDOCS_MAKE"},
    { path: "/pay-docs/register", text: "Register PD", role: "ROLE_UI_TAB_PAYDOCS_REGISTER"},
    { path: "/pay-docs/requests", text: "Requests PD", role: "ROLE_UI_TAB_PAYDOCS_REQUESTS"},
    { path: "/pay-docs/check", text: "Audit PD", role: "ROLE_UI_TAB_PAYDOCS_CHECK"},
    { path: "/pay-docs/partner", text: "PD at the partner", role: "ROLE_UI_TAB_PAYDOCS_PARTNER"},
    { path: "/pay-docs/types", text: "PD Types", role: "ROLE_UI_TAB_PAYDOCS_TYPES"},

    { path: "/pay-docs/report/balance", group: "Reports", text: "Баланс", role: "ROLE_PAYDOC_REPORT_BALANCE"},
    { path: "/pay-docs/report/sold",    group: "Reports", text: "Продажи", role: "ROLE_PAYDOC_REPORT_SALE"},
    { path: "/pay-docs/report/delivery",    group: "Reports", text: "Доставка", role: "ROLE_PAYDOC_PAYDOC_GUESTDELIVERY"},
    { path: "/pay-docs/report/activated",    group: "Reports", text: "Активация", role: "ROLE_PAYDOC_REPORT_SALE"},
    { path: "/pay-docs/report/history-balances", group: "Reports", text: "История изменения баланса", role: "ROLE_PAYDOC_REPORT_BALANCE"},

    { path: "/pay-docs/promo", text: "Акции", role: "ROLE_PAYDOCS_PROMO"},
];

export function groupNames(names) {
    const sorted = names.sort();

    let result = '';
    let rangeBegun = false;
    let prevType = '';
    let prevNum = 0;
    let cnt = 0;
    let prevItem = '';
    sorted.forEach(item => {
        const type = item.substring(0,2);
        const num = parseInt(item.substring(2), 10);

        if (type !== prevType || Math.abs(num - prevNum) > 1) {
            if (rangeBegun && cnt > 1) {
                result += '-' + prevItem;
            }

            if (result !== '') {
                result += ', ';
            }

            result += item;

            rangeBegun = true;
            cnt = 0;
        }

        cnt++;

        prevType = type;
        prevNum = num;
        prevItem = item;
    });

    if (rangeBegun && cnt > 1) {
        result += '-' + sorted[sorted.length-1];
    }

    return result;
}

export function groupPayDocsList(payDocs) {
    const ranges = [];

    if (payDocs && Array.isArray(payDocs) && payDocs.length) {
        payDocs.sort((a, b) => {
            if (a.type.code < b.type.code) {
                return -1;
            }

            if (a.type.code > b.type.code) {
                return 1;
            }

            if (a.numInt < b.numInt) {
                return -1;
            }

            if (a.numInt > b.numInt) {
                return 1;
            }

            return 0;
        }).forEach(payDoc => {
            let range = ranges.find(range => range.code === payDoc.type.code && payDoc.numInt >= range.min - 1 && payDoc.numInt <= range.max + 1);
            if (range) {
                if (payDoc.numInt === range.min - 1) {
                    range.min = payDoc.numInt;
                } else if (payDoc.numInt === range.max + 1) {
                    range.max = payDoc.numInt;
                }

            } else {
                range = {
                    code: payDoc.type.code,
                    min: payDoc.numInt,
                    max: payDoc.numInt,
                };

                ranges.push(range);
            }
        });
    }

    return ranges;
}

export function formatGroupedRange(range) {
    if (range) {
        if (range.min === range.max) {
            return range.code + range.min.toString().padStart(6, '0');
        } else {
            return range.code + range.min.toString().padStart(6, '0') + '-' + range.max.toString().padStart(6, '0');
        }
    }

    return '';
}

export function checkRemovePayDoc(id) {
    let url = `/api/pay-doc/check/remove/${id}`;

    return baseRequest(url);
}

export function removePayDoc(id) {
    return baseRequest(`/api/pay-doc/remove/${id}`, {
        method: 'DELETE',
    });
}

export function getPayDocReportBalance(search, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc/report/balance', {
        query: {search, page, per_page, order, order_by}
    });
}

export function getPayDocReportSale(search, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc/report/sale', {
        query: {search, page, per_page, order, order_by}
    });
}

export function getPayDocReportActivated(search, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc/report/activated', {
        query: {search, page, per_page, order, order_by}
    });
}

export function saleBarter(data) {
    return baseRequest(`/api/pay-doc/sale-barter`, {
        method: 'PUT',
        body: {...data},
    });
}

export function getPayDocRefill(id) {
    return baseRequest(`/api/pay-doc/recharge/${id}`);
}

export function rechargeChangeDate(refillId, date) {
    return baseRequest('/api/pay-doc/recharge/change-date', {
        method: 'POST',
        body: { refillId, date },
    });
}

export function rechargeChangeCreator(refillId, creatorId) {
    return baseRequest('/api/pay-doc/recharge/change-creator', {
        method: 'POST',
        body: { refillId, creatorId },
    });
}

export function cancelPayDocSale(id) {
    return baseRequest(`/api/pay-doc/sale/cancel/${id}`, {
        method: 'POST',
    });
}

export function unfreezeRest(id) {
    return baseRequest(`/api/pay-doc/unfreeze-rest/${id}`, {
        method: 'PUT',
    });
}

export function changeValidity(body) {
    return baseRequest('/api/pay-doc/validity', {
        method: 'PUT',
        body,
    });
}

export function saveAdditionalGuests(body) {
    return baseRequest('/api/pay-doc/save-additional-guests', {
        method: 'PUT',
        body,
    });
}

export function sendSertificateByEmail(id, email = null, regionId = null) {
    return baseRequest(`/api/pay-doc/send-sertificate-by-mail`,{
        method: 'POST',
        body: {
            id: id,
            email: email,
            regionId: regionId
        },
    });
}

export function importPayDocs(guests) {
    return baseRequest('/api/pay-doc/import', {
        method: "POST",
        body: guests,
    });
}

export function refundMoney(data) {
    return baseRequest('/api/pay-doc/refund-money', {
        method: 'POST',
        body: data,
    });
}

export function getPayDocByNum(num) {
    return baseRequest(`/api/pay-doc/find-by-num/${num}`);
}

export function moveBalanceRequest(body) {
    return baseRequest('/api/pay-doc/move-balance', {
        method: 'PUT',
        body,
    });
}

export function getFullHistoryEvents(id) {
    return baseRequest(`/api/pay-doc/full-history-events/${id}`);
}

export function getPayDocRefillsByPayDocId(payDocId) {
    return baseRequest(`/api/pay-doc/refills/${payDocId}`);
}

export function extractBitrixDataFromQuery(searchPath) {
    const returnData = {};

    if (!searchPath) {
        return returnData;
    }

    const parameters = queryString.parse(searchPath.replace(/^\?/, ''));

    Object.keys(parameters).forEach(k => {
        returnData[camelCase(k)] = parameters[k];
    });

    return returnData;
}

export const payDocTypeCodes =  {
    // для оплат визитов по апи
    oo : 'ОО',

    // для просто оплат на след. день или в другой слаон
    pd : 'ПО',

    // для публичных ссылок
    op : 'ОП',

    // бонусы
    bonuses : 'БОДБ'
};

export function getPayDocHistoryBalanceTrans() {
    return baseRequest('/api/pay-doc/history/balance/trans');
}

export function getPayDocReportHistoryBalances(search, page, per_page, order, order_by) {
    return baseRequest('/api/pay-doc/report/history-balances', {
        query: {search, page, per_page, order, order_by}
    });
}

export function createMultiplePayDocs(payDocParams) {
    return baseRequest("/api/pay-doc/create-multiple-pay-docs", {
        method: "POST",
        body: payDocParams
    })
}


export function getPayDocTypeHistory(id, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
let url = `/api/pay-doc/history-pay-doc-type/${id}`;
    const q = queryString.stringify({page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function changeStatusPayDocForRequest(data) {
    return baseRequest('/api/pay-doc/change-status-for-request', {
        method: 'POST',
        body: data,
    });
}
