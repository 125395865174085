import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getCurrentBillsState, navLinks} from "../../services/moneyFlow";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import {withTranslation} from "react-i18next";
import DateField2 from "../../components/fields/DateField2";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import CheckboxField from "../../components/fields/CheckboxField";

const columnData = [
    { id: 'name', label: 'Bill name' },
    { id: 'businessUnit', label: 'Parlour' },
    { id: 'billType', label: 'Bill type' },
    { id: 'currentAmount', label: 'Balance' },
    { id: 'bankName', label: 'Bank name' },
    { id: 'shortName', label: 'Juridical person' },
    { id: 'isAcquiring', label: 'Acquiring' },
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@withStyles(styles)
@withTranslation()
class CurrentBillsStatePage extends React.Component {

    state = {
        search: {
            date: moment(),
            businessUnitId: 0,
            byCancellationFact: false
        },
        refreshTable: false,
        businessUnits: [{ id: 0, name: this.props.t('---All---')}],
        progress: false,
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getCurrentBillsState(processMomentFieldInObject(search, ['date']), ...params);
    };


    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits, progress } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <AnyField
                        id="parlour"
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <DateField2
                        value={search.date}
                        onChange={this.handleChange('date')}
                        label={t("Date")}
                        fullWidth
                    />
                    <CheckboxField
                        label={t("Take into account the actual date of write-off")}
                        value={search.byCancellationFact}
                        onChange={this.handleChange('byCancellationFact')}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{ t('Filter') }</ActionButton>
                </LeftBar>
                <Content title={t('Unit account status')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        pagination={false}
                        autoload={false}
                    />
                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
