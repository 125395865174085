import React from 'react';
import {getFullHistoryEvents, getPayDoc, navLinks} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import HistoryLeftFields from "./HistoryLeftFields";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {getSalableEntityLink, salableEntityClasses} from "../../services/sale";
import {hasRole} from "../../services/user";

function processObjectReferenceValue(_, t, row) {
    const value = [];

    if (row.visits && row.visits.length) {
        row.visits.forEach(visitId => {
            value.push(<Link key={visitId} to={`/orders/view/${visitId}`}>{t('Visit')}</Link>);
        })
    }
    if (row.goodStorageSales && row.goodStorageSales.length) {
        row.goodStorageSales.forEach(gssId => {
            value.push(<Link key={gssId} to={`/goods/sell/view/${gssId}`}>{t('Sale')}</Link>);
        })
    }

    if (row.refillPayments && row.refillPayments.length) {
        if (hasRole('ROLE_PAYDOC_PAYDOC_RECHARGE_GET')) {
            value.push(<Link key={row.payDocRefillId}
                to={getSalableEntityLink(salableEntityClasses.payDocRefill, row.payDocRefillId)}>{t('View')}</Link>);
        }

        row.refillPayments.forEach(payment => {
            value.push(<div>{payment.businessUnitName} {t(`pay.types.${payment.type}`)}: {payment.amount}</div>)
        })
    } else {
        if (row.formattedAmount) {
            value.push(<br/>)
            value.push(<span>{t("Sum") + ' ' + row.formattedAmount}</span>)
        }
    }

    if (value.length) {
        value.unshift(<span>: </span>)
    }

    if (row.saleId && hasRole('ROLE_UI_TAB_TOOLS')) {
        value.unshift(<Link key={row.saleId} to={`/tools/sales/${row.saleId}`}>{t("fullHistoryPayDocEvents.trans." + row.tranValue)}</Link>)
    } else {
        if(row.tranValue) {
            value.unshift(<span>{t("fullHistoryPayDocEvents.trans." + row.tranValue)}</span>);
        }
    }

    if (row.payDocRequestId) {
        value.push(<Link key={row.payDocRequestId} to={`/pay-docs/requests/view/${row.payDocRequestId}`}>{`/pay-docs/requests/view/${row.payDocRequestId}`}</Link>)
    }

    return value;
}

const processParamValue = (_, t, row) => {
    let value = _
    let paramsSeparator = ": "
    let getTranslateBalanceParam = (balanceParam) => t(balanceParam[0].toUpperCase() + balanceParam.slice(1))

    if (row.param === "balance") {
        value = []
        Object.keys(_).forEach((balanceParam, i) => {
            if (i > 0) {
                value.push(<br/>)
            }
            value.push(<span>
                          {getTranslateBalanceParam(balanceParam) + paramsSeparator + _[balanceParam]}
                      </span>)
        })
    }

    if (row.param === "status" && _) {
        value = t("payDocStatuses." + _)
    }

    return value
}

@withRouter
@withTranslation()
class FullHistoryEventsPage extends React.Component {

    state = {
        id: '',
        payDoc: {
            id: null,
            num: null,
            type: null,
            statusId: null,
            status: null,
            place: null,
            pin: null,
            phone: null,
            fio: null,
            balance: null,
            validity: null,
            canRefill: false
        },
        refreshTable: false,
    };

    columnData = [
        { id: 'dt', label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'param', label: 'Changed parameter', processValue: param => this.props.t("fullHistoryPayDocEvents.params." + param)},
        { id: 'oldValue', label: 'Value "was"', processValue: processParamValue },
        { id: 'newValue', label: 'Value "became"', processValue: processParamValue },
        { id: 'username', label: 'User' },
        { id: 'businessUnitName', label: 'Parlour' },
        { id: 'objectReference', label: 'Object reference', processValue: processObjectReferenceValue },
        { id: 'comment', label: 'Comment' },
    ];

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getPayDoc(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            payDoc: response.data
                        })
                    }
                });
        }
    }

    getTableData = (...params) => {
        return this.state.id ? getFullHistoryEvents(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { t } = this.props;
        const { payDoc, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("History of replenishment and expenditure")}/>}>
                    <HistoryLeftFields payDoc={payDoc}/>
                </LeftBar>
                <Content title={t("Full history events")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        pagination={false}
                        disableSorting
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default FullHistoryEventsPage;
