import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import { getBusinessUnits } from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import { getLimits, navLinks, getSpendingTypes } from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import { MenuItem } from "@material-ui/core";
import { withRouter } from "react-router";

const columnData = [
    { id: 'spendingType.name', label: 'Статья расхода' },
    { id: 'startDate', label: 'Действует с', dateFormat: 'MMMM YYYY' },
    { id: 'amountFormatted', label: 'Лимит' },
    { id: 'businessUnitName', label: 'Бизнес юнит' },
    { id: 'comment', label: 'Комментарий' },
    { id: 'createdAt', label: 'Создано', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
];

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

export default
@withRouter
@withStyles(styles)
class LimitListPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: null,
            date_end: null,
            businessUnitId: 0,
            spendingTypeId: 0,
        },
        refreshTable: false,
        businessUnits: [{ id: 0, name: '---Все---' }],
        spendingTypes: [{ id: 0, name: '---Все---' }],
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: '---Все---' },
                            ...response.data
                        ],
                    });
                }
            });
        getSpendingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypes: [
                            { id: 0, name: '---Все---' },
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value = null;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const { search } = this.state;
        return getLimits(search, ...params);
    };

    handleAdd = () => {
        this.props.history.push(`/money-flow/limits/0`);
    };

    render() {
        const { classes } = this.props;
        const { refreshTable, search, businessUnits, spendingTypes } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} />}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={"Показать лимиты по дате"}
                        className={classes.filterControl}
                        required={false}
                    />
                    <AnyField
                        id="parlour"
                        label="Бизнес юнит"
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <AnyField
                        id="parlour"
                        label="Статья расхода"
                        value={search.spendingTypeId}
                        onChange={this.handleChange('spendingTypeId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {spendingTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        className={classes.filterControl}
                        label="Поиск"
                    />
                    <ActionButton onClick={this.handleClickFilter}>Отфильтровать</ActionButton>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Установить лимит</ActionButton>
                </LeftBar>
                <Content title="Бюджет">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        linkTemplate={(item) => `/money-flow/limits/${item.id}`}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
