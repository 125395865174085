import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import ParlourListPage from "./ParlourListPage";
import ParlourViewPage from "./ParlourViewPage";
import MasterListPage from "./MasterListPage";
import MasterViewPage from "./MasterViewPage";
import JuridicalPersonListPage from "./JuridicalPersonListPage";
import JuridicalPersonViewPage from "./JuridicalPersonViewPage";
import CheckingAccountListPage from "./CheckingAccountListPage";
import CheckingAccountViewPage from "./CheckingAccountViewPage";
import DepartmentListPage from "./DepartmentListPage";
import DepartmentViewPage from "./DepartmentViewPage";
import RegionListPage from "./RegionListPage";
import HistoryEmployeePage from "./HistoryEmployeePage";
import HistoryParlour from "./HistoryParlourPage";
import ExternalSubjectListPage from "./ExternalSubjectListPage";
import ExternalSubjectViewPage from "./ExternalSubjectViewPage";
import SalarySettingListPage from "./SalarySettingListPage";
import SalarySettingViewPage from "./SalarySettingViewPage";
import ReferralProgramListPage from "./ReferralProgramListPage";
import ReferralProgramViewPage from "./ReferralProgramViewPage";
import EquipmentListPage from "./EquipmentListPage";
import EquipmentViewPage from "./EquipmentViewPage";
import EmployeeSpecializationListPage from "./EmployeeSpecializationListPage";
import EmployeeSpecializationViewPage from "./EmployeeSpecializationViewPage";
import BanksPage from "./BanksPage";
import InvestorsPage from "./InvestorsPage";

class OrganizationPage extends React.Component {

    render() {
        return (
            <Switch>
                <Route exact path="/organization"               render={() => (<Redirect to="/organization/parlours"/>)}/>
                <Route exact path="/organization/parlours"      component={ParlourListPage} />
                <Route       path="/organization/parlours/view" component={ParlourViewPage} />
                <Route exact path="/organization/masters"      component={MasterListPage} />
                <Route       path="/organization/masters/view" component={MasterViewPage} />
                <Route exact path="/organization/juridical-persons" component={JuridicalPersonListPage} />
                <Route exact path="/organization/banks" component={BanksPage} />
                <Route       path="/organization/juridical-persons/view" component={JuridicalPersonViewPage} />
                <Route exact path="/organization/checking-accounts" component={CheckingAccountListPage} />
                <Route       path="/organization/checking-accounts/view" component={CheckingAccountViewPage} />
                <Route exact path="/organization/departments" component={DepartmentListPage} />
                <Route       path="/organization/departments/view" component={DepartmentViewPage} />
                <Route exact path="/organization/regions" component={RegionListPage} />
                <Route       path="/organization/investors" component={InvestorsPage} />
                <Route       path="/organization/history-employee" component={HistoryEmployeePage} />
                <Route       path="/organization/history-parlour" component={HistoryParlour} />
                <Route exact path="/organization/external-subjects" component={ExternalSubjectListPage} />
                <Route       path="/organization/external-subjects/view" component={ExternalSubjectViewPage} />
                <Route exact path="/organization/salary-settings" component={SalarySettingListPage} />
                <Route       path="/organization/salary-settings/" component={SalarySettingViewPage} />
                <Route exact path="/organization/referral-programs" component={ReferralProgramListPage} />
                <Route       path="/organization/referral-programs/view" component={ReferralProgramViewPage} />
                <Route exact path="/organization/equipments" component={EquipmentListPage} />
                <Route       path="/organization/equipments/view" component={EquipmentViewPage} />
                <Route exact path="/organization/employee-specializations" component={EmployeeSpecializationListPage} />
                <Route       path="/organization/employee-specializations/view" component={EmployeeSpecializationViewPage} />
            </Switch>
        );
    }
}

export default OrganizationPage;
