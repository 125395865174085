import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {
    navLinks, referralPartnerInfo
} from "../../services/personalAccount";
import {Grid, Typography} from "@material-ui/core";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import ShowField from "../../components/fields/ShowField";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import ActionButton from "../../components/button/ActionButton";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    },
    container: {
        marginTop: 12,
        paddingBottom: 20,
        maxWidth: 900,
        borderBottom: '1px solid #ccc',
    },
    error: {
        color: 'red',
        whiteSpace: 'pre-line',
    },
    textRight: {
        textAlign: 'right'
    }
});

const columnData = [
    { id: 'date', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    { id: 'parlour', label: 'Parlour' },
    { id: 'bonus', label: 'Bonus' },

];

export default
@withStyles(styles)
@withTranslation()
@withRouter
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
    showMessage: messageDialogActions.show,
})
class ReferralPartnerPersonalInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            info: {
                name: null,
                profitAllTime: null,
                payDocStatus: null,
                programName: null,
                calculatingTick: null,
                count: null,
                asReferral: null,
                asCreator: null,
                duration: null,
                profitTotal: null,
                profitCreator: null,
                profitReferral: null,
                profitMoney: null,
                profitBarter: null,
                lastCalc: null,
                periodsCount: null,
                willBeCalcTo: null,
                visits: [],
                countAllTime: null,
                asCreatorAllTime: null,
                asReferralAllTime: null,
                durationAllTime: null,
                visitsAllTime: [],
            },
            view: 'current',
            progress: false
        };
    }

    componentDidMount() {
        referralPartnerInfo()
            .then(response => {
                if (response.success) {
                    this.setState({
                        info: response.data,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    toggleView = () => {
        this.setState({
            view: this.state.view === 'current' ? 'all' : 'current',
        });
    };

    render() {
        const { classes, t } = this.props;
        const { info, progress, view } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ShowField
                        label={t("All time earned")}
                        value={info.profitAllTime}
                    />
                    <ShowField
                        value={info.profitTotal}
                        label={t("Current payment period(s) earned total")}
                    />
                    <ShowField
                        value={info.profitMoney}
                        label={t("As money")}
                    />
                    <ShowField
                        value={info.profitBarter}
                        label={t("As barter")}
                    />
                    <ShowField
                        value={info.payDocStatus}
                        label={t("Payment Document status")}
                    />
                </LeftBar>
                <Content title={t("Referral partner state")}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>{t("Common")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={info.name}
                                label={t("Name")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={info.programName}
                                label={t("Program")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={info.calculatingTick}
                                label={t("Payment period (days)")}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>{t("Dates")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {info.lastCalc ?
                                <ShowField
                                    value={info.lastCalc}
                                    label={t("Last payout")}
                                    dateFormat="DD.MM.YYYY"
                                />
                                :
                                <ShowField
                                    value="-"
                                    label={t("Last payout")}
                                />
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={info.periodsCount}
                                label={t("Periods included")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={info.willBeCalcTo}
                                label={t("Nearest payout")}
                                dateFormat="DD.MM.YYYY"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={7}>
                            <Typography variant="h6" gutterBottom>{ view === 'current' ?  t("Current period visits") : t("All time visits")}</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.textRight}>
                            <ActionButton onClick={this.toggleView} fullWidth={false} noMargin={true}>{ view === 'current' ?  t("Show all time visits") : t("Show current period visits")}</ActionButton>
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={view === 'current' ? info.count : info.countAllTime}
                                label={t("Count")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={view === 'current' ? info.asReferral : info.asReferralAllTime}
                                label={t("As partner")}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={view === 'current' ? info.asCreator : info.asCreatorAllTime}
                                label={t("As creator")}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={view === 'current' ? info.duration : info.durationAllTime}
                                label={t("Duration (minutes)")}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <DataTableLocal
                                columnData={columnData}
                                data={view === 'current' ? info.visits : info.visitsAllTime}
                                selector={false}
                                pagination={false}
                                />
                        </Grid>
                    </Grid>
                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}