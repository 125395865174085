import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {FormControl, InputLabel} from "@material-ui/core";
import ReactPhoneInput from "react-phone-input-2";
import classNames from "classnames";
import {isValidPhoneNumber} from "../../utils/phone-utils";
import {emptyFieldColor, fillFieldColor} from "../../services/constants";

const styles = theme => {
    return {
        root: {
            marginTop:  theme.spacing(1),
        },
        withLabel: {
            marginTop:  theme.spacing(3),
        },
        phoneField: {
            display: 'inline-flex',
        },
        errorBorder: {
            borderColor: theme.palette.error.main + ' !important',
        },
        errorText: {
            color: theme.palette.error.main,
            marginTop:  theme.spacing(1),
        },
        dropdownButton: {
            borderRadius: '0 !important',
            left: '1px !important',
            top: '1px !important',
            bottom: '1px !important',
            border: 'none !important',
        },
    };
};

class PhoneField extends React.Component
{
    render()
    {
        const {fullWidth, value, onChange, defaultCountry, required, className, label, classes, readOnly } = this.props;
        let {error, errorText} = this.props;

        if (value && !errorText && !isValidPhoneNumber(value)) {
            errorText = 'Указан неверный номер';
        }

        return (
            <FormControl fullWidth={fullWidth} className={classNames(classes.root,{[classes.withLabel]: label}, className)}>
                { label ? <InputLabel shrink variant="outlined" error={error || Boolean(errorText)}>{label}</InputLabel> : null }
                <ReactPhoneInput
                    containerClass={classNames('react-tel-input', classes.phoneField)}
                    inputClass={classNames({[classes.errorBorder]: error || errorText})}
                    inputStyle={{
                        width: '100%',
                        borderRadius: 0,
                        background: value && isValidPhoneNumber(value) ? fillFieldColor: emptyFieldColor,
                    }}
                    country={defaultCountry}
                    value={value === null ? '' : value}
                    onChange={onChange}
                    inputExtraProps={{
                        required: required,
                    }}
                    disabled={readOnly}
                    buttonClass={classes.dropdownButton}
                />
                { errorText ?
                    <div className={classes.errorText}>{errorText}</div>
                    : null
                }
            </FormControl>
        );
    }
}


PhoneField.propTypes = {
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    defaultCountry: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.any,
    error: PropTypes.bool,
    errorText: PropTypes.any,
};


PhoneField.defaultProps = {
    fullWidth: false,
    required: false,
    error: false,
};

export default withStyles(styles)(PhoneField);
