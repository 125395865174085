import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    createCheckingAccountCorrection,
    navLinks
} from "../../services/moneyFlow";
import AnyField from "../../components/fields/AnyField";
import {Grid, MenuItem} from "@material-ui/core";
import {emptyMoney} from "../../services/common";
import infoActions from "../../components/info/info-actions";
import MoneyField from "../../components/fields/MoneyField";
import {withTranslation} from "react-i18next";
import moment from "moment";
import DateTimeField2 from "../../components/fields/DateTimeField2";
import {
    getBusinessUnits,
    getJuridicalPersons,
    getCheckingAccounts
} from "../../services/organization";
import {loadToState} from "../../utils/helpers";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

const emptyCorrection = {
    amount: {...emptyMoney},
    businessUnitId: null,
    comment: null,
    createdAt: moment(),
    checkingAccountId: null,
    juridicalPersonId: null,
};

export default
@connect(null,{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class AddCheckingAccountCorrectionPage extends React.Component {
    state = {
        businessUnits: [],
        correction: {
            ...emptyCorrection
        },
        juridicalPersons: [],
        checkingAccounts: [],
    };

    componentDidMount() {

        getBusinessUnits({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {
        this.setState({
            correction: {
                ...this.state.correction,
                [prop]: event.target.value,
            }
        }, () => {
                if (prop === 'businessUnitId') {
                    this.fetchTrustedJuridicalPersons()
                }
                if (prop === 'juridicalPersonId') {
                    this.fetchCheckingAccounts()
                }
            }
        );
    };

    fetchTrustedJuridicalPersons = () => {
        loadToState(
            this,
            getJuridicalPersons({'trustedForBusinessUnit': this.state.correction.businessUnitId}),
            'juridicalPersons'
        )
    }

    fetchCheckingAccounts = () => {
        loadToState(
            this,
            getCheckingAccounts({'juridicalPersonId': this.state.correction.juridicalPersonId, active: true}),
            'checkingAccounts'
        )
    }

    handleAdd = () => {
        const { t } = this.props;
        createCheckingAccountCorrection(this.state.correction)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Correction created'));
                    this.setState({
                        correction: {
                            ...emptyCorrection,
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes, t } = this.props;
        const { correction, businessUnits, checkingAccounts, juridicalPersons } = this.state;
        const disabled =
            !correction.checkingAccountId ||
            !correction.amount.val ||
            !correction.createdAt

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAdd} disabled={disabled}>{t('Add')}</ActionButton>
                </LeftBar>
                <Content title={t("Checking account correction")}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                id="parlour"
                                label={t("Business unit")}
                                value={correction.businessUnitId}
                                required
                                error={!correction.businessUnitId}
                                onChange={this.handleChange('businessUnitId')}
                                fullWidth
                                select
                            >
                                {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Juridical person")}
                                value={correction.juridicalPersonId}
                                required
                                error={!correction.juridicalPersonId}
                                onChange={this.handleChange('juridicalPersonId')}
                                fullWidth
                                select
                            >
                                {juridicalPersons.map(item => <MenuItem key={item.id} value={item.id}>{item.shortName}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Checking account")}
                                value={correction.checkingAccountId}
                                required
                                error={!correction.checkingAccountId}
                                onChange={this.handleChange('checkingAccountId')}
                                fullWidth
                                select
                            >
                                {checkingAccounts.map(item => <MenuItem key={item.id} value={item.id}>{item.bankName}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <MoneyField
                                label={t('Amount')}
                                currencies={
                                correction.checkingAccountId
                                    ? [checkingAccounts.find(ca => ca.id === correction.checkingAccountId)?.currency]
                                    : []
                                }
                                value={ correction.amount }
                                error={ !(correction.amount && correction.amount.val)}
                                required
                                onChange={this.handleChange('amount')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateTimeField2
                                label={t('Datetime')}
                                value={correction.createdAt}
                                error={!correction.createdAt}
                                required
                                onChange={this.handleChange('createdAt')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Comment")}
                                value={correction.comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
