import React from "react";
import NoWrap from "./NoWrap";
import MoneyList from "./MoneyList";
import {formatMoney} from "../../../../../services/common";
import MoneyListDetailsDialog from "./MoneyListDetailsDialog";

const payDocDetailsColumns = [
    { id: 'entityDate', dateFormat: 'DD.MM.YYYY HH:mm', label: 'Date' },
    { id: 'payDocNum', label: 'Payment document', linkTemplate: (item) => `/pay-docs/view/${item.payDocId}` },
    { id: 'amount', label: 'Amount', processValue: formatMoney },
    { id: 'payDocParlourInfo.name', label: 'Parlour' },
];

const incomeGoods = (value) => {
    const pdOwnDetails = value.details?.filter(
        (d) => d.seType === 'goods' && d.type === 'paydoc' && d.payDocParlour === 'owner' && d.status === 'paid' && !d.isFree
    ) || [];
    const pdOtherDetails = value.details?.filter(
        (d) => d.seType === 'goods' && d.type === 'paydoc' && d.payDocParlour === 'other' && d.status === 'paid' && !d.isFree
    ) || [];

    return <NoWrap>
        <MoneyList title="f.Cash" value={value.goods_cash}/>
        <MoneyList title="f.Cashless" value={value.goods_cashless}/>
        <MoneyListDetailsDialog detailsData={pdOwnDetails} detailsColumns={payDocDetailsColumns} detailsTitle="f.PDOwner"
                                title="f.PDOwner" detailsLinkTemplate={(row) => `/goods/sell/view/${row.entityId}`}
                                value={value.goods_paydoc_own}/>
        <MoneyListDetailsDialog detailsData={pdOtherDetails} detailsColumns={payDocDetailsColumns} detailsTitle="f.PDOther"
                                title="f.PDOther" detailsLinkTemplate={(row) => `/goods/sell/view/${row.entityId}`}
                                value={value.goods_paydoc_other}/>
    </NoWrap>;
};

export default incomeGoods;
