import React from 'react';
import {Route, Switch} from "react-router";
import ListPage from "./ListPage";
import ViewPage from "./ViewPage";
import HistoryContactPage from "./HistoryContactPage";
import HistoryChangePage from "./HistoryChangePage";
import HistoryVisitPage from "./HistoryVisitPage";
import HistoryBuyPage from "./HistoryBuyPage";
import ContactPage from "./ContactPage";
import FinancialCardPage from "./FinancialCardPage";
import WhatsAppMessagesPage from "./WhatsAppMessagesPage";
import VerifiedDataReport from "./VerifiedDataReport"
import ReportCloseVisitMessagesAndVisitRatingsPage from "./ReportCloseVisitMessagesAndVisitRatingsPage";
import ClubSubscriptionHistory from "./ClubSubscriptionHistory";

class GuestsPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/guests"           component={ListPage}/>
                <Route exact path="/guests/verified-data-report"    component={VerifiedDataReport}/>
                <Route exact path="/guests/report-close-visit-messages"    component={ReportCloseVisitMessagesAndVisitRatingsPage}/>

                <Route       path="/guests/view"      component={ViewPage}/>
                <Route       path="/guests/edit"      component={ViewPage}/>
                <Route       path="/guests/contact"   component={ContactPage}/>
                <Route       path="/guests/financial-card"   component={FinancialCardPage}/>

                <Route       path="/guests/history-contact"  component={HistoryContactPage}/>
                <Route       path="/guests/history-buy"      component={HistoryBuyPage}/>
                <Route       path="/guests/history-visit"    component={HistoryVisitPage}/>
                <Route       path="/guests/history-change"   component={HistoryChangePage}/>
                <Route       path="/guests/history-club"     component={ClubSubscriptionHistory}/>

                <Route       path="/guests/whats-app-messages"   component={WhatsAppMessagesPage}/>
            </Switch>
        );
    }
}

export default GuestsPage;