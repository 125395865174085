import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {importZvonobot, navLinks, zvonobotCSVRowsToDTOArray} from "../../services/callCenter";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import UploadFilesField from "../../components/fields/UploadFilesField"
import {parseXLS} from "../../services/common";
import ActionButton from "../../components/button/ActionButton";

export default
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class LoadZvonobotFile extends React.Component {

    state = {
        importFileName: null,
        importData: [],
        importError: null,
        importOffsetRows: 11
    }

    handleChange = prop => event => {
        this.setState({
            [prop]: event.target.value,
        })
    };

    handleChangeFile = (event) => {
        const {importOffsetRows} = this.state;
        let file = event.target.files[0];

        this.setState({
            importFileName: null,
            importData: [],
            importError: null
        });

        parseXLS(file).then((json) => {
            let DTOArray = zvonobotCSVRowsToDTOArray(json.slice(importOffsetRows));
            if (Array.isArray(DTOArray)) {
                this.setState({
                    importData: DTOArray,
                    importFileName: file.name
                });
            } else {
                this.setState({
                    importError: DTOArray,
                });
            }
        });
    }

    handleImport = () => {
        const { t } = this.props;
        importZvonobot( {'rows': this.state.importData} )
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t("Rows imported") + response.data.count);
                    this.setState({
                        importData: [],
                        importFileName: null,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    render() {
        const {t} = this.props;
        const {importFileName, importData, importError} = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <div></div>
            </LeftBar>
            <Content title={t('Do import')}>
                <UploadFilesField
                    id="importFile"
                    label={t('File')}
                    value={null}
                    onChange={this.handleChangeFile}
                    notLoad
                />
                <ActionButton onClick={this.handleImport} disabled={!importData.length} color="primary"
                              fullWidth={false}>{t("Do import")}</ActionButton>
                {!!importData.length &&
                <p>
                    {t("Filename")}: {importFileName}<br/>
                    {t("Rows will be imported")}: {importData.length}
                </p>
                }
                {!!importError &&
                <p style={{color: 'red'}}>{importError}</p>
                }
            </Content>
        </React.Fragment>
    }
}