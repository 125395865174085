import React from "react";
import {withRouter} from "react-router";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getConsumptionGroups, navLinks} from "../../services/goods";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

const columnData = [
    { id: 'name', label: 'Name', },
    { id: 'comment', label: 'Comment', },
    { id: 'creatorName', label: 'Creator', },
    { id: 'createdAt', label: 'Created at', dateFormat: "DD.MM.YYYY HH:mm"},
];


@withRouter
@withStyles(styles)
@withTranslation()
class ConsumptionGroupListPage extends React.PureComponent
{
    state = {
        search: {
            search: null
        },
        refreshTable: false
    };

    handleAdd = () => {
        this.props.history.push('/goods/consumption-group/view/0');
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    }

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleClickFilter = () => {
        this.setState(state => ({
            refreshTable: !state.refreshTable,
        }));
    };

    getTableData = (...params) => {
        const { search } = this.state;
        return getConsumptionGroups({...search}, ...params);
    }

    render() {
        const {
            search,
            refreshTable,
        } = this.state;

        const { t, classes } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label={t('Search')}
                        className={classes.filterControl}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleAdd}>{t('Create')}</ActionButton>
                </LeftBar>
                <Content title={t('Consumption groups')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        linkTemplate={(row) => `/goods/consumption-group/view/${row.id}`}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default ConsumptionGroupListPage;