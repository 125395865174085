import React from "react";
import NoWrap from "./NoWrap";
import MoneyList from "./MoneyList";

const debts = (isShowDebts) => (value) => {

    if (!isShowDebts) {
        return 'Долги отображаются только за полный месяц';
    }

    return <NoWrap>
        <MoneyList title="f.Total" value={value.total} />
        <MoneyList title="f.Cash" value={value.cash} />
        <MoneyList title="f.Cashless" value={value.cashless} />
    </NoWrap>
}

export default debts;