import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getBill, getTransactions, navLinks} from "../../services/moneyFlow";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {loadToState} from "../../utils/helpers";
import RadioGroupField from "../../components/fields/RadioGroupField";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import ShowField from "../../components/fields/ShowField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";


const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class TransactionsListPage extends React.Component {
    constructor(props) {
        super(props);

        this.dateTypes = [
            { id: 'createdAt', label: props.t('by date created')},
            { id: 'applyAt', label: props.t('by date apply')},
        ];

        const querySearch = getFilterFromQuery();

        let date_start = moment();
        let date_end = moment();
        let businessUnitId = null;
        let dateType = this.dateTypes[0].id;
        let billId = null;
        let toBusinessUnitIds = [];

        if (querySearch) {
            if (querySearch.date_start) {
                date_start = moment(querySearch.date_start)
            }

            if (querySearch.date_end) {
                date_end = moment(querySearch.date_end)
            }

            if (querySearch.businessUnitId) {
                businessUnitId = querySearch.businessUnitId
            }

            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }

            if (querySearch.billId) {
                billId = querySearch.billId
            }

            if (querySearch.toBusinessUnitIds) {
                toBusinessUnitIds = querySearch.toBusinessUnitIds
            }
        }

        this.state = {
            search: {
                search: null,
                date_start,
                date_end,
                businessUnitId,
                dateType,
                billId,
                toBusinessUnitIds
            },
            refreshTable: false,
            businessUnits: [],
            bill: null,
        };
    }

    processSoldName = (item) => {
        const { t } = this.props;

        if (!item) {
            return null;
        }

        return t(item.class) + ': ' + item.id;
    };

    processSoldLink = (item) => {
        if (!item.sold) {
            return null;
        }

        switch (item.sold.class) {
            case 'Visit':
                return '/orders/view/' + item.sold.id;
            case 'GoodStorageSale':
                return '/goods/sell/view/' + item.sold.id;
            case 'PayDoc':
                return '/pay-docs/view/' + item.sold.id;
            case 'PayDocRefill':
                return '/pay-docs/history/balance/' + item.sold.id;
            default:
                return null;
        }
    };

    columnData = [
        { id: 'createdAt', label: 'Created date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'reasonClass', label: 'Deal (entity)' },
        { id: 'reasonName', label: 'Deal name' },
        { id: 'sold', label: 'Sold', processValue: this.processSoldName, linkTemplate: this.processSoldLink },
        { id: 'amountFormat', label: 'Amount' },
        { id: 'from.parentClassText', label: 'From (entity)' },
        { id: 'fromParentName', label: 'From name' },
        { id: 'from.typeText', label: 'From (bill)' },
        { id: 'to.parentClassText', label: 'To (entity)' },
        { id: 'toParentName', label: 'To name' },
        { id: 'to.typeText', label: 'To (bill)' },
        { id: 'name', label: 'Name' },
        { id: 'statusText', label: 'Status' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'creator.username', label: 'User' },
        { id: 'comment', label: 'Comment' },
        { id: 'applyAt', label: 'Account for the month', dateFormat: 'MMMM YYYY'},
    ];

    columnDataXlsx = [
        { id: 'createdAt', label: 'Created date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'reasonClass', label: 'Deal (entity)' },
        { id: 'reasonName', label: 'Deal name' },
        { id: 'soldText', label: 'Sold' },
        { id: 'amount.val', label: 'Amount' },
        { id: 'amount.cur', label: 'Currency' },
        { id: 'from.parentClassText', label: 'From (entity)' },
        { id: 'fromParentName', label: 'From name' },
        { id: 'from.typeText', label: 'From (bill)' },
        { id: 'to.parentClassText', label: 'To (entity)' },
        { id: 'toParentName', label: 'To name' },
        { id: 'to.typeText', label: 'To (bill)' },
        { id: 'name', label: 'Name' },
        { id: 'statusText', label: 'Status' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'creator.username', label: 'User' },
        { id: 'comment', label: 'Comment' },
        { id: 'applyAt', label: 'Account for the month', dateFormat: 'MMMM YYYY'},
    ];

    componentDidMount() {
        loadToState(this, getBusinessUnits(), 'businessUnits')
        if (this.state?.search?.billId) {
            loadToState(this, getBill(this.state?.search?.billId), 'bill')
        }
    }

    handleChange = ({ target }) => {
        this.setState({
            search: {
                ...this.state.search,
                [target.name]: target.value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        pushFilterToQuery(processMomentFieldInObject(search, ['date_start', 'date_end']));
        return getTransactions(search, ...params);
    };

    handleExport = () => {
        const { search, businessUnits, bill } = this.state;
        const businessUnitName = businessUnits?.find(item => item.id === search.businessUnitId)?.name;
        const { t } = this.props;
        const from = moment.isMoment(search.date_start) ? search.date_start.format("YYYY-MM-DD") : search.date_start;
        const to = moment.isMoment(search.date_end) ? search.date_end.format("YYYY-MM-DD") : search.date_end;
        const bu = businessUnitName ? ` ${businessUnitName}` : '';
        const billName = search.billId && bill ? ` ${bill.parentName} ${bill.typeText}` : '';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => t(item.label) ),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => {
                                let value = _.get(row, item.id);

                                if (item.dateFormat) {
                                    value = moment(value).format(item.dateFormat);
                                }

                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Transactions");
                    XLSX.writeFile(new_workbook, `Transactions${bu}${billName} ${from}-${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits, bill } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField2
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        maxDate={moment()}
                        nameFrom="date_start"
                        nameTo="date_end"
                        onChangeFrom={this.handleChange}
                        onChangeTo={this.handleChange}
                        label={t('Show moving by date interval')}
                    />
                    <RadioGroupField
                        items={this.dateTypes}
                        value={search.dateType}
                        name="dateType"
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <SelectFromItemsField
                        items={businessUnits}
                        nullable
                        nullableText={t('---All---')}
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        name="businessUnitId"
                        onChange={this.handleChange}
                        fullWidth
                        select
                    />
                    <AnyField
                        value={search.search}
                        name="search"
                        onChange={this.handleChange}
                        fullWidth
                        className={classes.filterControl}
                        label={t('Search')}
                    />
                    <ShowField
                        label={t('By bill')}
                        value={`${bill?.parentName} ${bill?.typeText}`}
                        visible={!!(search?.billId && bill)}
                    />
                    <MultiSelectFromItemsField
                        items={businessUnits}
                        emptySelectedLabel="---Не выбрано---"
                        label={t('To name')}
                        name={'toBusinessUnitIds'}
                        value={search.toBusinessUnitIds}
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Money moving')}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        autoload={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
