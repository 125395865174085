import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    emptyUser,
    saveUser,
    getBusinessRoles,
    getUsersFilter,
    syncEmployeesMegaplan,
    getEmployeesMegaplan,
    getEmployeesBitrix, syncEmployeesBitrix,
    getDomRuEmployees, syncDomRuEmployees
} from "../../services/user";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import { getHumanEmployees, getJuridicalPersons} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import PhoneField from "../../components/fields/PhoneField";
import SearchField from "../../components/fields/SearchField";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import CheckboxField from "../../components/fields/CheckboxField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";

const columnData = [
    { id: 'username', label: 'Имя' },
    { id: 'employee', label: 'Сотрудник', linkTemplate: (user) => user.employeeId ? `/organization/masters/view/${user.employeeId}` : ''},
    { id: 'isPartner', label: 'Партнер' },
    { id: 'isCounterpart', label: 'Контрагент' },
    { id: 'phone', label: 'Телефон' },
    { id: 'businessRole', label: 'Бизнес роль', linkTemplate: (user) => `/settings/business-roles/view/${user.businessRoleId}` },
    { id: 'id', label: 'ID' },
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

export default
@connect(null, {
    showMessage: messageDialogActions.show
})
@withStyles(styles)
class UsersListPage extends React.Component {
    state = {
        search: {
            search: null,
            businessRoleId: null
        },

        user: emptyUser,
        dialogEditOpen: false,

        refreshTable: false,
        businessRoles: [{ id: 0, name: '---Нет---'}],
        employees: [],
        juridicalPersons: [],
        employeesMegaplan: [],
        employeesBitrix: [],
        domRuEmployees: [],
    };

    componentDidMount() {
        getBusinessRoles()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessRoles: [
                            { id: '0', name: '---Нет---'},
                            ...response.data
                        ],
                    });
                }
            });

        getHumanEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [
                            { id: '0', name: '---Нет---'},
                            ...response.data
                        ],
                    });
                }
            });

        getJuridicalPersons({companyDepartment: true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        juridicalPersons: [
                            { id: '0', name: '---Нет---'},
                            ...response.data
                        ],
                    });
                }
            });

        getEmployeesMegaplan()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employeesMegaplan: response.data
                    });
                }
            });

        getEmployeesBitrix()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employeesBitrix: response.data
                    });
                }
            });
        getDomRuEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        domRuEmployees: response.data
                    });
                }
            });
    }

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getUsersFilter(search, ...params);
    };

    handleClickSyncMegaplan = () => {
        syncEmployeesMegaplan()
            .then(response => {
                if (response.success) {
                    this.props.showMessage(`Обновлено пользователей: ${response.data}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleClickSyncBitrix = () => {
        syncEmployeesBitrix()
            .then(response => {
                if (response.success) {
                    this.props.showMessage(`Обновлено пользователей: ${response.data}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleClickSyncDomRu = () => {
        syncDomRuEmployees()
            .then(response => {
                if (response.success) {
                    this.props.showMessage(`Обновлено пользователей: ${response.data}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleAdd = () => {
        this.setState({
            user: emptyUser,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        if (ok) {
            let data = {
                ...this.state.user,
                businessRoleId: this.state.user.businessRoleId === '0' ? null : this.state.user.businessRoleId
            };
            saveUser(data)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            dialogEditOpen: false,
                            refreshTable: !this.state.refreshTable,
                        });
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleEdit = user => {
        this.setState({
            user,
            dialogEditOpen: true,
        })
    };



    handleChange = (prop) => event => {
        this.setState({
            user: {
                ...this.state.user,
                [prop]: event.target.value,
            }
        });
    };

    handleChangePhone = (prop) => phone => {
        this.setState({
            user: {
                ...this.state.user,
                [prop]: phone,
            }
        });
    };

    render() {
        const { classes } = this.props;
        const { search, dialogEditOpen, user, refreshTable, businessRoles, employees, juridicalPersons, employeesMegaplan, employeesBitrix, domRuEmployees } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <AutocompleteSelectField
                        label="Бизнес роль"
                        value={search.businessRoleId}
                        onChange={this.handleChangeSearch('businessRoleId')}
                        fullWidth
                        options={businessRoles.map(item => ({value: item.id, label: item.name}))}
                        />
                    <ActionButton onClick={this.handleClickSearch}>Отфильтровать</ActionButton>
                    <br />
                    <br />

                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                    <br />
                    <br />

                    <ActionButton onClick={this.handleClickSyncMegaplan}>Синхронизировать с мегапланом</ActionButton>
                    <ActionButton onClick={this.handleClickSyncBitrix}>Синхронизировать с Bitrix24</ActionButton>
                    <ActionButton onClick={this.handleClickSyncDomRu}>Синхронизировать с Дом.Ру</ActionButton>
                </LeftBar>
                <Content title="Пользователи">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        rowStyleFunc={user => (user.locking ? {backgroundColor: '#aa8c8c'} : null)}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog(false)}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Карточка пользователя</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Email"
                                    value={user.email}
                                    onChange={this.handleChange('email')}
                                    required
                                    error={!user.email}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Пароль"
                                    value={user.password}
                                    onChange={this.handleChange('password')}
                                    fullWidth
                                    required={!user.id}
                                    error={!user.id && !user.password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Имя"
                                    value={user.username}
                                    onChange={this.handleChange('username')}
                                    fullWidth
                                    required
                                    error={!user.username}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteSelectField
                                    label="Является сотрудником"
                                    value={user.employeeId}
                                    onChange={this.handleChange('employeeId')}
                                    fullWidth
                                    options={employees.map((employee) => ({value: employee.id, label: employee.name}))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Бизнес роль"
                                    value={user.businessRoleId || '0'}
                                    onChange={this.handleChange('businessRoleId')}
                                    fullWidth
                                    select
                                >
                                    {businessRoles.map(item => <MenuItem key={item.id}
                                                                         value={item.id}>{item.name}</MenuItem>)}
                                </AnyField>
                            </Grid>
                            <Grid item xs={4}>
                                <CheckboxField
                                    value={user.isPartner}
                                    onChange={this.handleChange('isPartner')}
                                    label="Партнер"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CheckboxField
                                    value={user.isCounterpart}
                                    onChange={this.handleChange('isCounterpart')}
                                    label="Контрагент"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CheckboxField
                                    value={user.canLoginWithoutWorkShift}
                                    onChange={this.handleChange('canLoginWithoutWorkShift')}
                                    label="Вход без графика"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteSelectField
                                    label="Добавить в ежедневный отчет Online/Mobile"
                                    value={Number(user?.dailyReportSettings)}
                                    fullWidth
                                    onChange={this.handleChange('dailyReportSettings')}
                                    options={[
                                        {
                                            value: null,
                                            label: 'Нет'
                                        },
                                        {
                                            value: 1,
                                            label: 'Добавить к Online'
                                        }, {
                                            value: 2,
                                            label: 'Только выводить'
                                        }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectFromItemsField
                                    label="Сотрудник в мегаплане"
                                    value={user.employeeMegaplanId}
                                    onChange={this.handleChange('employeeMegaplanId')}
                                    fullWidth
                                    items={employeesMegaplan}
                                    nullable
                                    textField="name"
                                    valueField="id"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectFromItemsField
                                    label="Сотрудник в Bitrix24"
                                    value={user.employeeBitrixId}
                                    onChange={this.handleChange('employeeBitrixId')}
                                    fullWidth
                                    items={employeesBitrix}
                                    nullable
                                    textField="name"
                                    valueField="id"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MultiSelectFromItemsField
                                    items={domRuEmployees}
                                    label="Сотрудник в Дом.Ру"
                                    value={user.domRuEmployeeIds}
                                    fullWidth
                                    emptySelectedLabel=""
                                    onChange={this.handleChange('domRuEmployeeIds')}
                                />
                            </Grid>
                            { user.isCounterpart &&
                            <Grid item xs={12}>
                                <AnyField
                                    label="Юридическое лицо"
                                    value={user.juridicalPersonId}
                                    onChange={this.handleChange('juridicalPersonId')}
                                    error={!user.juridicalPersonId && user.isCounterpart}
                                    fullWidth
                                    select
                                >
                                    {juridicalPersons.map(item => <MenuItem key={item.id}
                                                                            value={item.id}>{item.shortName}</MenuItem>)}
                                </AnyField>
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <PhoneField
                                    defaultCountry="ru"
                                    label="Телефон"
                                    value={user.phone}
                                    onChange={this.handleChangePhone('phone')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Public api key"
                                    value={user.apiKey}
                                    onChange={this.handleChange('apiKey')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxField
                                    value={user.locking}
                                    onChange={this.handleChange('locking')}
                                    label="Заблокировать пользователя"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} disabled={!user.email || !user.username || (!user.id && !user.password) || (!user.juridicalPersonId && user.isCounterpart)} color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
