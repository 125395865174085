import _ from "lodash";
import {dataTableGroupValueStrategy} from "../../../../../services/common";

const groupPdIncomePayments = (currentVal, newPayments) => {
    if (newPayments?.length) {
        if (!currentVal) {
            currentVal = []
        }
        newPayments.forEach(payment => {
            const found = currentVal.find(current => current.key === payment.key)
            if (found) {
                found.amount.val += payment.amount.val
            } else {
                currentVal.push(_.cloneDeep(payment))
            }
        })

        currentVal.sort((a, b) => a.sortKey.localeCompare(b.sortKey))
    }

    return currentVal
}

const pdBalance = (currentVal, row) => {
    return {
        amountIn: dataTableGroupValueStrategy.moneySum('amountIn')(
            currentVal?.amountIn,
            row.pdBalance
        ),
        detailsIn: dataTableGroupValueStrategy.appendArray('detailsIn')(
            currentVal?.detailsIn,
            row.pdBalance
        ),
        incomePayments: groupPdIncomePayments(currentVal?.incomePayments, row.pdBalance?.incomePayments),
        amountOut: dataTableGroupValueStrategy.moneySum('amountOut')(
            currentVal?.amountOut,
            row.pdBalance
        ),
        amountOutDetails: dataTableGroupValueStrategy.appendArray('amountOutDetails')(
            currentVal?.amountOutDetails,
            row.pdBalance
        ),
        frozenDetails: dataTableGroupValueStrategy.same('frozenDetails')(
            currentVal?.frozenDetails,
            row.pdBalance
        ),
        amountFreeze: dataTableGroupValueStrategy.moneySum('amountFreeze')(
            currentVal?.amountFreeze,
            row.pdBalance
        ),
        amountFreezeDetails: dataTableGroupValueStrategy.appendArray('amountFreezeDetails')(
            currentVal?.amountFreezeDetails,
            row.pdBalance
        ),
        amountFreezePay: dataTableGroupValueStrategy.moneySum('amountFreezePay')(
            currentVal?.amountFreezePay,
            row.pdBalance
        ),
        amountFreezePayDetails: dataTableGroupValueStrategy.appendArray('amountFreezePayDetails')(
            currentVal?.amountFreezePayDetails,
            row.pdBalance
        ),
        amountUnfreeze: dataTableGroupValueStrategy.moneySum('amountUnfreeze')(
            currentVal?.amountUnfreeze,
            row.pdBalance
        ),
        amountBurn: dataTableGroupValueStrategy.moneySum('amountBurn')(
            currentVal?.amountBurn,
            row.pdBalance
        ),
        amountBurnFrozen: dataTableGroupValueStrategy.moneySum('amountBurnFrozen')(
            currentVal?.amountBurnFrozen,
            row.pdBalance
        ),
        amountRefund: dataTableGroupValueStrategy.moneySum('amountRefund')(
            currentVal?.amountRefund,
            row.pdBalance
        ),
        amountCorrection: dataTableGroupValueStrategy.moneySum('amountCorrection')(
            currentVal?.amountCorrection,
            row.pdBalance
        ),
        balance: dataTableGroupValueStrategy.same('balance')(
            currentVal?.balance,
            row.pdBalance
        ),
        frozen: dataTableGroupValueStrategy.same('frozen')(
            currentVal?.frozen,
            row.pdBalance
        ),
    };
};

export default pdBalance;