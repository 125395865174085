import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import moment from "moment";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import DatePeriodField from "../../components/fields/DatePeriodField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getBusinessUnits} from "../../services/organization";
import _ from "lodash";
import XLSX from "xlsx";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import {loadCloseVisitMessageReportData, navLinks} from "../../services/guests";

export default
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class ReportCloseVisitMessagesAndVisitRatingsPage extends React.Component {
    state = {
        search: {
            dateFrom: moment(),
            dateTo: moment(),
            businessUnitIds: [],
            messageStatus: null,
        },
        refreshTable: false,
        businessUnits: [],
        messageStatuses: [
            {
                id : 0,
                value: 'Not sent'
            },
            {
                id : 1,
                value: 'Sent'
            },
            {
                id : 2,
                value: 'Message received'
            },
            {
                id : 3,
                value: 'Read'
            }
        ]
    };

    columnData = [
        {
            id: 'name',
            label: 'Guest Name',
            processValue: value => {
                if (typeof value === "object" && value?.name && value?.id) {
                    return <a href={'/guests/view/' + value.id} target="_blank" rel="noopener noreferrer">{value.name}</a>
                } else
                    return value
            }
        },
        {id: 'phone', label: 'Guest Phone'},
        {id: 'send_message_date', label: 'Date send message', dateFormat: 'DD.MM.YYYY'},
        {id: 'send_message_status', label: 'Message status', processValue: value => (!value) ? '-' : value },
        {id: 'visit', label: 'Visit', processValue: value => <a href={'/orders/view/' + value} target="_blank" rel="noopener noreferrer">Визит</a>},
        {id: 'visit_rating', label: 'Visit rating', processValue: value => (!value) ? '-' : value },
        {id: 'parlour', label: 'Parlour'},
        {id: 'master', label: 'Master'},
        {id: 'visit_start', label: 'Start visit date', dateFormat: 'DD.MM.YYYY'},
        {id: 'visit_serial_number', label: 'Visit serial number'},
        {id: 'close_by_name', label: 'Closed by'},
        {
            id: 'visit_bitrix_id',
            label: 'BxId Deal',
            processValue: value => {
                if (value) {
                    return <a href={'https://tvoitai.bitrix24.ru/crm/deal/details/' + value + '/'} target="_blank"
                              rel="noopener noreferrer">Сделка</a>
                } else
                    return value
            }
        },
        {id: 'employee_okk', label: 'Employee okk'},
        {id: 'feedback_status', label: 'Feedback Status'},
        {id: 'administrator_score', label: 'Administrator score'},
        {id: 'master_score', label: 'Master score'},
        {id: 'cleanliness_business_unit_score', label: 'Cleanliness Saloon score'},
        {id: 'calculated_grade', label: 'Calculated grade'},
        {id: 'recommend_us_score', label: 'Recommend us score'},
        {id: 'created_at', label: 'Date create score', dateFormat: 'DD.MM.YYYY'},
        {id: 'comment_okk', label: 'Comment OKK'},
        {id: 'impressions', label: 'Impressions'},
        {id: 'lack', label: 'Lack'},
        {id: 'call_okk_date', label: 'Call OKK date', dateFormat: 'DD.MM.YYYY'},
        {
            id: 'call_okk',
            label: 'Call OKK',
            processValue: value => {
                if (value) {
                    return <a href={value}>Скачать запись разговора</a>
                } else
                    return value
            }
        },
        {id: 'payment_status', label: 'Payment status'},
    ];

    columnDataXsl = [
        {id: 'name', label: 'Guest Name', processValue: value => (!value) ? '-' : value.name},
        {id: 'phone', label: 'Guest Phone'},
        {id: 'send_message_date', label: 'Date send message', dateFormat:'DD-MM-YYYY'},
        {id: 'send_message_status', label: 'Message status'},
        {id: 'visit', label: 'Visit', processValue: value => (!value) ? '-' : 'https://tt.nk-soft.com/orders/view/orders/view/' + value},
        {id: 'visit_rating', label: 'Visit rating'},
        {id: 'parlour', label: 'Parlour'},
        {id: 'master', label: 'Master'},
        {id: 'visit_start', label: 'Start visit date', dateFormat:'DD-MM-YYYY'},
        {id: 'visit_serial_number', label: 'Visit serial number'},
        {id: 'close_by_name', label: 'Closed by'},
        {
            id: 'visit_bitrix_id',
            label: 'BxId Deal',
            processValue: value => (!value) ? '-' : 'https://tvoitai.bitrix24.ru/crm/deal/details/' + value + '/'
        },
        {id: 'employee_okk', label: 'Employee okk'},
        {id: 'feedback_status', label: 'Feedback Status'},
        {id: 'administrator_score', label: 'Administrator score'},
        {id: 'master_score', label: 'Master score'},
        {id: 'cleanliness_business_unit_score', label: 'Cleanliness Saloon score'},
        {id: 'calculated_grade', label: 'Calculated grade'},
        {id: 'recommend_us_score', label: 'Recommend us score'},
        {id: 'created_at', label: 'Date create score', dateFormat:'DD-MM-YYYY'},
        {id: 'comment_okk', label: 'Comment OKK'},
        {id: 'impressions', label: 'Impressions'},
        {id: 'lack', label: 'Lack'},
        {id: 'call_okk_date', label: 'Call OKK date', dateFormat:'DD-MM-YYYY'},
        {id: 'call_okk', label: 'Call OKK'},
        {id: 'payment_status', label: 'Payment status'},
    ];

    getTableData = (...params) => {
        const {search} = this.state;
        return loadCloseVisitMessageReportData(processMomentFieldInObject(search, ['dateFrom', 'dateTo']), ...params);
    };

    componentDidMount() {
        getBusinessUnits({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    handleExport = () => {
        const {search} = this.state;
        const {t} = this.props;
        const rd = search.dateFrom.format('YYYY-MM-DD') + ' - ' + search.dateTo.format('YYYY-MM-DD');

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXsl.map(item => t(item.label)),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXsl.map(item => {
                                const value = _.get(row, item.id);

                                if (value && item.dateFormat) {
                                    return moment(value).format('DD-MM-YYYY');
                                } else if (!value) {
                                    return ''
                                }

                                if (
                                    item.processValue &&
                                    typeof item.processValue === 'function'
                                )
                                    return item.processValue(value);

                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Load Parlours");
                    XLSX.writeFile(new_workbook, `Load Parlours ${rd}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const {t} = this.props;
        const {refreshTable, search, businessUnits, messageStatuses} = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField
                    valueFrom={search.dateFrom}
                    valueTo={search.dateTo}
                    onChangeFrom={this.handleChange('dateFrom')}
                    onChangeTo={this.handleChange('dateTo')}
                    label={t('Date')}
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.businessUnitIds}
                    onChange={this.handleChange('businessUnitIds')}
                    fullWidth
                />
                <AnyField
                    id="messageStatus"
                    label={t('Message status')}
                    value={search.messageStatus}
                    onChange={this.handleChange('messageStatus')}
                    fullWidth
                    select
                >
                    {messageStatuses.map( item => <MenuItem key={item.id} value={item.id}>{t(item.value)}</MenuItem>)}
                </AnyField>
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title={t("Report close visit messages and visit ratings")}>
                <DataTable
                    dataFunc={this.getTableData}
                    selector={false}
                    columnData={this.columnData}
                    refresh={refreshTable}
                />
            </Content>
        </React.Fragment>
    }
}
