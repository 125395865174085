import React from 'react';
import {
    getRequest,
    navLinks,
    getRequestPayDocs
} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {getFalseResult} from "../../services/common";

const columnData = [
    { id: 'num', numeric: false, label: 'Identifier' },
    { id: 'type.name', numeric: false, label: 'Type of payment document' },
    { id: 'pin', numeric: false, label: 'PIN-код' },
    { id: 'parlour', numeric: false, label: 'Parlour' },
    { id: 'nominal', numeric: true, label: 'Nominal' },
    { id: 'balance', numeric: true, label: 'Balance' },
];

@withRouter
@withTranslation()
class RequestViewInfoPage extends React.Component {

    state = {
        request: {
            id: null,
            num: '',
            status: '',
            nextPositiveStatus: '',
            nextNegativeStatus: '',
            type: {
                id: null,
                name: null,
            },

            price: null,
            bill: null,
            payTypes: null,
            amountPrePay: null,
            amountDelayPay: null,
            amountTotal: null,
            delayPeriodId: null,
            delayPeriod: null,

            mainDeliveryType: null,
            mainTransportCompany: null,
            mainWaybillNumber: null,
            mainReceiptScan: null,
            mainDeliveryCharges: null,
            deliveryType: null,
            transportCompany: null,
            waybillNumber: null,
            receiptScan: null,
            deliveryCharges: null,
            newParlourId: null,
            newParlourName: null,
        },
        refreshTable: false,
    };


    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.loadRequest(resultId[0]);
        }
    }

    loadRequest = (id) => {
        getRequest(id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        request: response.data,
                        refreshTable: !this.state.refreshTable,
                    })
                }
            });
    };

    handleBack = () => {
        this.props.history.push(`/pay-docs/requests/view/${this.state.request.id}`);
    };

    getTableData = (...params) => {
        return this.state.request.id ? getRequestPayDocs(this.state.request.id, ...params) : getFalseResult();
    };

    render() {
        const { t } = this.props;
        const { request, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Additional information on the request")}/>}>
                    <ShowField
                        label={t("Request #")}
                        value={request.num}
                    />
                    <ShowField
                        label={t("Last comment")}
                        value={request.comment}
                    />
                    <ShowField
                        label={t("Status")}
                        value={request.status}
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleBack}>{t("Back")}</ActionButton>
                </LeftBar>
                <Content title={t("Additional information on the request")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/pay-docs/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default RequestViewInfoPage;
