import React from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {getGuest, getHistoryChange, navLinks} from "../../services/guests";
import ActionButton from "../../components/button/ActionButton";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";
import GuestLeftFields from "./GuestLeftFields";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'dt', numeric: false, disablePadding: false, label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'user', numeric: false, disablePadding: false, label: 'User' },
    { id: 'field', numeric: false, disablePadding: false, label: 'Changed parameter' },
    { id: 'oldValue', numeric: false, disablePadding: false, label: 'Value "was"' },
    { id: 'newValue', numeric: false, disablePadding: false, label: 'Value "became"' },
];

export default
@withRouter
@withTranslation()
class HistoryChangePage extends React.Component {

    state = {
        id: '',
        guest: {
            id: null,
            lastName: null,
            name: null,
            secondName: null,
            phone: null,
            payDocs: null,

            dateCreated: null,
            creator: null,
        },
        refreshTable: false,
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getGuest(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: response.data
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/guests/view/${this.state.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getHistoryChange(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { t } = this.props;
        const { guest, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("History of change of parameters")}/>}>
                    <GuestLeftFields guest={guest}/>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>{t("Back")}</ActionButton>
                </LeftBar>
                <Content title={t("History of change of parameters")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        order="desc"
                        orderBy="dt"
                    />
                </Content>
            </React.Fragment>
        );
    }
}
