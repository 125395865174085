import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {
    getDashboardAdminAndMasterReport,
    navLinks,
    getAdminsAndMastersDashBoardGoogleLink
} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem, Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {getFalseResult} from "../../services/common";
import _ from "lodash";
import XLSX from "xlsx";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    table: {
        marginBottom: 30
    },
    h6: {
        fontSize: '1rem'
    }
});

export default
@withStyles(styles)
@withTranslation()
@connect(null,{
    showMessage: messageDialogActions.show,
})
class AdminsAndMastersDashboardReport extends React.Component {
    state = {
        search: {
            dayStart: moment(),
            dayEnd: moment(),
            businessUnitIds: [],
        },
        businessUnits: [],
        columnData: [],
        data: {
            admins: [],
            parlours: [],
            masters: [],
            managers: []
        },
        progress: false,
        link: null
    };

    componentDidMount() {
        const {t} = this.props;
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: t('Not chosen')},
                            ...response.data
                        ],
                    });
                }
            });

        getAdminsAndMastersDashBoardGoogleLink()
            .then(response => {
                if (response.success) {
                    this.setState({
                        link: response.data.link
                    });
                }
            });

        //this.getTableData();
    }

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleChangeState = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            [prop]: value,
        });
    };

    handleClickFilter = () => {
        this.getTableData();
    };

    getTableData = () => {
        const {search} = this.state;

        if (search.dayStart && search.dayEnd) {
            return getDashboardAdminAndMasterReport(search)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            columnData: response.data.columns,
                            data: response.data.data,
                        });
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        } else {
            return getFalseResult();
        }
    };

    handleExport = () => {
        const {columnData, data} = this.state;

        const preDataXls = [
            //First row - headers
            columnData.map(item => item.label),
        ];

        let dataXls = Object.assign([], preDataXls);

        data.admins.forEach(admin => {
            dataXls.push([admin.name]);
            admin.data.forEach(row => {
                dataXls.push(
                    columnData.map(item => _.get(row, item.id))
                );
            });
        });

        data.parlours.forEach(parlour => {
            dataXls.push([parlour.name]);
            parlour.data.forEach(row => {
                dataXls.push(
                    columnData.map(item => _.get(row, item.id))
                );
            });
        });

        let dataXlsMasters = Object.assign([], preDataXls);

        data.masters.forEach(master => {
            dataXlsMasters.push([master.name]);
            master.data.forEach(row => {
                dataXlsMasters.push(
                    columnData.map(item => _.get(row, item.id))
                );
            });
        });

        const new_workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.aoa_to_sheet(dataXls);
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Админы");

        const worksheet2 = XLSX.utils.aoa_to_sheet(dataXlsMasters);
        XLSX.utils.book_append_sheet(new_workbook, worksheet2, "Мастера");

        XLSX.writeFile(new_workbook, `AdminsAndMastersDashboardReport.xlsx`);
    }

    render() {
        const { classes, t } = this.props;
        const { search, businessUnits, columnData, data, progress, link } = this.state;
        const disabled = !search.dayStart || !search.dayEnd;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.dayStart}
                        valueTo={search.dayEnd}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('dayStart')}
                        onChangeTo={this.handleChange('dayEnd')}
                        label={t("Show changes by date interval")}
                        className={classes.filterControl}
                    />
                    <AnyField
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        label={t("Parlours")}
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>{t('Not chosen')}</span>;
                                }

                                return businessUnits.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                            },
                        }}
                        className={classes.filterControl}
                        fullWidth
                    >
                        {businessUnits.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={search.businessUnitIds.indexOf(item.id) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </AnyField>
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport} disabled={disabled || !data.admins.length}>Excel</ActionButton>
                    <ActionButton href={link} targetBlank>Google</ActionButton>
                </LeftBar>
                <Content title={t('Admin and Masters Dashboard')}>
                    {data.admins.length !== 0 &&
                        <React.Fragment>
                            <Typography variant="h6" gutterBottom>Админы</Typography>
                            {data.admins.map((admin, index) => (
                                <div className={classes.table} key={index}>
                                    <Typography variant="h6" gutterBottom className={classes.h6}>{admin.name}</Typography>
                                    <Typography variant="h6" gutterBottom className={classes.h6}>{admin.parlourName}</Typography>
                                    <DataTableLocal
                                        columnData={columnData}
                                        data={admin.data}
                                        selector={false}
                                        pagination={false}
                                        disableSorting
                                        rowStyleFunc={(user, index) => (index % 2 === 0 ? {backgroundColor: '#ccc'} : null)}
                                    />
                                </div>
                            ))}
                        </React.Fragment>
                    }
                    {data.parlours.length !== 0 &&
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>Салоны</Typography>
                        {data.parlours.map((admin, index) => (
                            <div className={classes.table} key={index}>
                                <Typography variant="h6" gutterBottom className={classes.h6}>{admin.name}</Typography>
                                <DataTableLocal
                                    columnData={columnData}
                                    data={admin.data}
                                    selector={false}
                                    pagination={false}
                                    disableSorting
                                    rowStyleFunc={(user, index) => (index % 2 === 0 ? {backgroundColor: '#ccc'} : null)}
                                />
                            </div>
                        ))}
                    </React.Fragment>
                    }
                    {data.masters.length !== 0 &&
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>Мастера</Typography>
                        {data.masters.map((admin, index) => (
                            <div className={classes.table} key={index}>
                                <Typography variant="h6" gutterBottom className={classes.h6}>{admin.name}</Typography>
                                <DataTableLocal
                                    columnData={columnData}
                                    data={admin.data}
                                    selector={false}
                                    pagination={false}
                                    disableSorting
                                    rowStyleFunc={(user, index) => (index % 2 === 0 ? {backgroundColor: '#ccc'} : null)}
                                />
                            </div>
                        ))}
                    </React.Fragment>
                    }
                    {data.managers.length !== 0 &&
                        <React.Fragment>
                            <Typography variant="h6" gutterBottom>Менеджеры</Typography>
                            {data.managers.map((manager, index) => (
                                <div className={classes.table} key={index}>
                                    <Typography variant="h6" gutterBottom className={classes.h6}>{manager.name}</Typography>
                                    <Typography variant="h6" gutterBottom className={classes.h6}>{manager.parlourName}</Typography>
                                    <DataTableLocal
                                        columnData={columnData}
                                        data={manager.data}
                                        selector={false}
                                        pagination={false}
                                        disableSorting
                                        rowStyleFunc={(user, index) => (index % 2 === 0 ? {backgroundColor: '#ccc'} : null)}
                                    />
                                </div>
                            ))}
                        </React.Fragment>
                    }
                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
