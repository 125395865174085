import React, {useState, useEffect, useCallback} from "react";
import {useParams, useHistory, useRouteMatch} from "react-router-dom";
import AnyField from "../../../components/fields/AnyField";
import {Grid} from "@material-ui/core";
import Content from "../../../components/content/Content";
import LeftBar from "../../../components/left-bar/LeftBar";
import NavigationLinks from "../../../components/navigation-links/NavigationLinks";
import ActionButton from "../../../components/button/ActionButton";
import {
    getExecutor,
    createExecutor,
    updateExecutor,
    deleteExecutor,
    getExecutingQueryDuration,
    navLinks
} from "../../../services/settings";
import {useDispatch} from "react-redux";
import infoActions from "../../../components/info/info-actions";
import {useTranslation} from "react-i18next";
import ShowField from "../../../components/fields/ShowField";
import moment from "moment";

function ExecutorDetailsPage() {
    const {t} = useTranslation();
    const {id} = useParams();
    const history = useHistory();

    const [executor, setExecutor] = useState({
        id: "",
        comment: "",
        name: "",
        request: "",
        creator: "",
        createdAt: ""
    });
    const [initialExecutor, setInitialExecutor] = useState({});
    const [isEditing, setIsEditing] = useState(id === "create");

    const dispatch = useDispatch();
    const showInfo = useCallback((message) => dispatch(infoActions.show(message)), [dispatch]);
    const showError = useCallback((message) => dispatch(infoActions.showError(message)), [dispatch]);

    const [queryDuration, setQueryDuration] = useState(0);
    const [response, setResponse] = useState("");
    const [responseCount, setResponseCount] = useState(0);

    const fetchExecutor = useCallback(async () => {
        try {
            const fetchedExecutor = await getExecutor(id);
            const formattedCreatedAt = moment(fetchedExecutor.data.createdAt).format("DD.MM.YYYY HH:mm");
            setExecutor({
                ...fetchedExecutor.data,
                createdAt: formattedCreatedAt
            });
            setInitialExecutor({
                ...fetchedExecutor.data,
                createdAt: formattedCreatedAt
            });
        } catch (error) {
            console.error("Error fetching executor:", error);
            showError("Произошла ошибка при получении данных экзекьютора.");
        }
    }, [id, showError]);

    useEffect(() => {
        if (id !== "create") {
            fetchExecutor();
        }
    }, [id, fetchExecutor]);

    const handleChangeRequestField = (e) => {
        const {name, value} = e.target;
        const lowerCaseValue = value.toLowerCase();
        const forbiddenCommands = ["insert", "delete", "truncate", "drop", "alter", "references"];
        const containsForbiddenCommand = forbiddenCommands.some(command =>
            lowerCaseValue.includes(command)
        );

        if (containsForbiddenCommand) {
            showError("Введена недопустимая SQL команда");
            return;
        }

        if (name === "request" && value.length > 3500) {
            showError("Превышен лимит в 3500 символ");
            return;
        }

        setExecutor((prevExecutor) => ({
            ...prevExecutor,
            [name]: value
        }));
    };

    const handleChangeCommentField = (e) => {
        const {name, value} = e.target;

        setExecutor((prevExecutor) => ({
            ...prevExecutor,
            [name]: value
        }));
    };

    const handleChangeNameField = (e) => {
        const {name, value} = e.target;
        const allowedCharsRegex = /^[a-z0-9_]*$/;

        if (allowedCharsRegex.test(value)) {
            setExecutor((prevExecutor) => ({
                ...prevExecutor,
                [name]: value
            }));
        } else {
            let invalidChar = value.match(/[^a-z0-9_]/)[0];

            if (invalidChar === ' ') {
                invalidChar = 'Пробел';
            }

            const errorMessage = `Недопустимый символ: ${invalidChar}`;

            showError(errorMessage);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const executeRequest = async () => {
        try {
            const startTime = Date.now();
            const response = await getExecutingQueryDuration(executor);

            if (response && response.data) {
                const responseData = response.data;
                const responseString = responseData.map((item) => {
                    return `${item.phone}`;
                }).join("\n");

                setResponse(responseString);
                setResponseCount(responseData.length);

                const endTime = Date.now();
                setQueryDuration((endTime - startTime) / 1000);
            } else {
                showError("Произошла ошибка при выполнении запроса: " + response.error.message);
            }
        } catch (error) {
            console.error("Error executing request", error);

            if (response.error && response.error.message) {
                showError("Произошла ошибка при выполнении запроса: " + response.error.message);
            }
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setExecutor(initialExecutor);
        if (!executor.name && !executor.comment && !executor.request) {
            history.push({
                pathname: `/settings/executors`,
            });
        }
    };

    const match = useRouteMatch();
    const currentPath = match.url;

    const handleSave = async () => {
        try {
            let response;

            if (!executor.name || !executor.comment || !executor.request) {
                showError("Заполните все поля");
                return;
            }

            if (executor.request.length > 3500) {
                showError("Превышен лимит в 3500 символ");
                return;
            }

            const lowerCaseValue = executor.request.toLowerCase();

            const forbiddenCommands = ["insert", "delete", "truncate", "drop", "alter", "references"];
            const containsForbiddenCommand = forbiddenCommands.some(command =>
                lowerCaseValue.includes(command)
            );

            const forbiddenCommandsRegex = /\b(create|update)\b/;
            const containsForbiddenCommandRegex = forbiddenCommandsRegex.test(lowerCaseValue);

            if (containsForbiddenCommand || containsForbiddenCommandRegex) {
                showError("Введена недопустимая SQL команда");
                return;
            }

            if (id === "create") {
                response = await createExecutor(executor);
            } else {
                response = await updateExecutor(executor);
            }

            if (response && response.success && response.data && response.data.id) {
                const newId = response.data.id;
                const newPath = currentPath.replace("create", newId);
                history.push(newPath);

                showInfo("Executor успешно сохранен");
            } else {
                showError("Произошла ошибка при сохранении экзекьютора: " + response.error.message);
                return;
            }

            setIsEditing(false);
        } catch (error) {
            console.error("Error saving executor:", error);
            showError("Произошла ошибка при сохранении экзекьютора: " + response.error.message);
        }
    };

    const handleBack = () => {
        history.push({
            pathname: `/settings/executors`,
        });
    };

    const handleDelete = () => {
        try {
            deleteExecutor(executor.id)
                .then(response => {
                    if (response.success) {
                        showInfo(`Экзекьютор ${executor.name} удален`)
                        history.push('/settings/executors')
                    } else {
                        showError("Произошла ошибка при удалении экзекьютора.");
                    }
                });
        } catch (error) {
            console.error("Error deleting executor:", error);
        }
    };

    return (
        <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ShowField
                    label={t("Executors.Creator")}
                    value={executor.creator}
                />
                <ShowField
                    label={t("Executors.Date create")}
                    value={executor.createdAt}
                />
                {isEditing ? (
                    <>
                        <ActionButton onClick={handleSave}>{t("Executors.Save")}</ActionButton>
                        <ActionButton onClick={handleCancel}>{t("Executors.Cancel")}</ActionButton>
                    </>
                ) : (
                    <>
                        <ActionButton onClick={handleEdit}>{t("Executors.Edit")}</ActionButton>
                        <ActionButton onClick={handleBack}>{t("Executors.Go back")}</ActionButton>
                        <ShowField></ShowField>
                        <ActionButton onClick={handleDelete}>{t("Executors.Delete")}</ActionButton>
                    </>
                )}
            </LeftBar>

            <Content>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AnyField
                            label={t("Executors.Comment")}
                            value={executor.comment}
                            name="comment"
                            onChange={handleChangeCommentField}
                            readOnly={!isEditing}
                            fullWidth
                            inputProps={{style: {color: isEditing ? "black" : "gray"}}}
                            required
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            label={t("Executors.Name")}
                            value={executor.name}
                            name="name"
                            onChange={handleChangeNameField}
                            readOnly={!isEditing}
                            fullWidth
                            inputProps={{style: {color: isEditing ? "black" : "gray"}}}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AnyField
                            label={t("Executors.SQL-request")}
                            value={executor.request}
                            name="request"
                            onChange={handleChangeRequestField}
                            readOnly={!isEditing}
                            inputProps={{
                                style: {height: "250px", color: isEditing ? "black" : "gray"}
                            }}
                            fullWidth
                            multiline
                            rows={4}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ActionButton onClick={executeRequest} disabled={isEditing}>
                            {t("Executors.Execute request")}
                        </ActionButton>
                        <div style={{marginTop: "10px", position: "relative"}}>
                            <ShowField>
                                {t("Executors.Request executing duration")}: {queryDuration} сек.
                            </ShowField>
                            {response ?
                                <ShowField>{t("Executors.Response count")}: {responseCount}</ShowField> :
                                <ShowField>{t("Executors.Response count")}: 0</ShowField>}
                        </div>
                        <AnyField
                            label={t("Executors.SQL-query response")}
                            value={response}
                            name="response"
                            readOnly
                            inputProps={{style: {height: "250px", color: "gray"}}}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </Content>
        </React.Fragment>
    );
}

export default ExecutorDetailsPage;