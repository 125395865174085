import React from 'react';
import {getGuests, importGuests, navLinks, uniteGuestsAction} from "../../services/guests";
import LeftBar from "../../components/left-bar/LeftBar";
import DataTable from "../../components/data-table/DataTable";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid} from "@material-ui/core";
import GuestField from "../../components/fields/GuestField";
import Add from "@material-ui/icons/Add";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ClearIcon from "@material-ui/icons/Clear";
import {hasRole} from "../../services/user";
import ImportDialog from "../../components/dialogs/ImportDialog";
import {guestKeys} from "../../entities/importKeys";

export default @withRouter
@withTranslation()
@connect(null, {
    showMessage: messageDialogActions.show
})
class ListPage extends React.Component {

    state = {
        search: '',
        refreshTable: false,
        dialogUniteGuest: false,
        openImportModal: false,
        uniteGuests: {
            mainGuest: null,
            fromGuests: [{
                id: null
            }]
        }
    };

    columnData = [
        {id: 'phone', numeric: false, disablePadding: false, label: 'Guest phone'},
        {id: 'fullName', numeric: false, disablePadding: false, label: 'Full name'},
        {id: 'isClubMember', label: 'Club member'},
        {id: 'birthDate', numeric: false, disablePadding: false, label: 'Date of Birth', dateFormat: "DD.MM.YYYY"},
        {
            id: 'lastContactDate',
            numeric: false,
            disablePadding: false,
            label: 'Last interaction date',
            dateFormat: "DD.MM.YYYY"
        },
    ];


    handleChange = prop => event => {
        this.setState({[prop]: event.target.value});
    };

    handleChangeMainGuest = event => {

        const {uniteGuests} = this.state;

        this.setState({
            uniteGuests: {
                ...uniteGuests,
                mainGuest: event.target.value
            }
        })
    }

    handleChangeUniteGuest = event => {
        const guestIdxStr = event.target.name.split('#').pop();
        const guestIdx = parseInt(guestIdxStr);
        const {uniteGuests} = this.state;
        const currentGuest = uniteGuests.fromGuests[guestIdx];
        uniteGuests.fromGuests[guestIdx] = {
            ...currentGuest,
            id: event.target.value,
        };
        this.setState({
            uniteGuests: {
                ...uniteGuests,
                fromGuests: uniteGuests.fromGuests
            }
        });
    }

    handleClickSearch = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    handleAdd = () => {
        this.props.history.push(`/guests/edit/0`);
    };

    handleDeleteGuest = (guestIdx) => () => {
        const {uniteGuests} = this.state;
        uniteGuests.fromGuests.splice(guestIdx, 1);
        this.setState({
            uniteGuests: {
                ...uniteGuests,
                fromGuests: uniteGuests.fromGuests

            }
        });
    }
    handleUniteGuest = () => {
        this.setState({
            dialogUniteGuest: true
        })
    }
    handleCloseUnitGuestDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogUniteGuest: false
            })
            return;
        }
        const {uniteGuests} = this.state;

        uniteGuestsAction(uniteGuests)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogUniteGuest: false,
                        uniteGuests: {
                            mainGuest: null,
                            fromGuests: [{
                                id: null
                            }]
                        }
                    })
                    this.props.showMessage('Гости объеденены');
                } else {
                    this.props.showMessage('Ошибка объединения');
                }
            })

    }

    handleAddGuest = () => {

        const {uniteGuests} = this.state;

        this.setState({
            uniteGuests: {
                ...uniteGuests,
                fromGuests: [
                    ...(uniteGuests.fromGuests ?? []),
                    {
                        id: null
                    }
                ]
            }
        });

    }

    getTableData = (...params) => {
        const {search} = this.state;
        return getGuests(search, ...params);
    };

    handleImportDialog = () => {
        this.setState({ openImportModal: true });
    }

    render() {
        const {t} = this.props;
        const {
            search,
            refreshTable,
            dialogUniteGuest,
            uniteGuests,
            openImportModal,
        } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search}
                        onChange={this.handleChange('search')}
                        label={t("Search by guest")}
                        onClick={this.handleClickSearch}
                    />
                    <br/>
                    <br/>
                    <ActionButton onClick={this.handleAdd}>{t("Add")}</ActionButton>
                    <ActionButton onClick={this.handleUniteGuest}>{'Объединить гостей'}</ActionButton>
                    <ActionButton onClick={this.handleImportDialog} visible={hasRole('ROLE_GUEST_GUEST_IMPORT')}>{'Импортировать'}</ActionButton>
                </LeftBar>
                <Content title={t("Guests")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/guests/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                        autoload={false}
                    />
                    <Dialog open={dialogUniteGuest}
                            onClose={this.handleCloseUnitGuestDialog(false)}
                            fullWidth
                            maxWidth="md"
                    >
                        <DialogTitle id="sale-dialog-title">Объединение гостей</DialogTitle>
                        <DialogContent>
                            <div>
                                <Grid container spacing={2}>
                                    <React.Fragment>
                                        <Grid item xs={4}>
                                            <GuestField
                                                label={'Основной гость'}
                                                value={uniteGuests.mainGuest}
                                                error={!uniteGuests.mainGuest}
                                                onChange={this.handleChangeMainGuest}
                                                required
                                            />
                                        </Grid>
                                    </React.Fragment>
                                    <React.Fragment>
                                        {uniteGuests.fromGuests.map((guest, guestId) =>
                                            <React.Fragment
                                                key={guestId}>
                                                <Grid item xs={6}
                                                      style={{float: "right", marginLeft: !guestId ? 0 : '33.25%'}}>
                                                    <GuestField
                                                        label={'Объеденить с'}
                                                        name={`gu#${guestId}`}
                                                        value={guest.id}
                                                        error={!uniteGuests.fromGuests}
                                                        onChange={this.handleChangeUniteGuest}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={1} style={{marginTop: 20, float: "right"}}>
                                                    <Fab size="small" color="secondary"
                                                         disabled={uniteGuests.fromGuests.length <= 1}
                                                         onClick={this.handleDeleteGuest(guestId)}><ClearIcon
                                                    /></Fab>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                        }
                                        {/*)}*/}
                                    </React.Fragment>
                                    <ActionButton
                                        fullWidth={false}
                                        inGridItem12
                                        onClick={this.handleAddGuest}
                                    >
                                        <Add/> Добавить
                                    </ActionButton>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleCloseUnitGuestDialog(true)}
                                color="primary"
                                disabled={typeof uniteGuests.fromGuests.find(guest => guest.id === null) !== 'undefined' || !uniteGuests.mainGuest}
                            >Подтвердить
                                объединение</Button>
                            <Button onClick={this.handleCloseUnitGuestDialog(false)}
                                    color="primary">{t("Cancel")}</Button>
                        </DialogActions>
                    </Dialog>

                </Content>
                {openImportModal && <ImportDialog
                    open={openImportModal}
                    onClose={() => this.setState({openImportModal: false})}
                    title={'Импорт гостей'}
                    importFunc={importGuests}
                    keys={guestKeys}
                    chunk={20}/> }
            </React.Fragment>
        );
    }
}
