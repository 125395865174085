import React from "react";
import moment from "moment";
import { getBusinessUnits, getExternalSubjects, getRegions } from "../../services/organization";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getPayDocReportBalance,
    getPayDocStatuses,
    getPayDocTypes,
    navLinks,
} from "../../services/payDocs";
import LeftBar from "../../components/left-bar/LeftBar";
import DataTable from "../../components/data-table/DataTable";
import Content from "../../components/content/Content";
import { withTranslation } from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import _ from "lodash";
import XLSX from "xlsx";
import { processMomentFieldInObject } from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import CheckboxField from "../../components/fields/CheckboxField";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import DateTimeField2 from "../../components/fields/DateTimeField2";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import { createChangeHandle, loadToState } from "../../utils/helpers";
import AnyField from "../../components/fields/AnyField";
import { hasRole } from "../../services/user";

const columnData = [
    { id: "num", label: "Номер" },
    { id: "measure.text", label: "Тип" },
    { id: "payDocType", label: "Тип ПД" },
    { id: "balance", label: "Баланс" },
    { id: "burned", label: "Сгорело" },
    { id: "validity", label: "Срок действия", dateFormat: "DD.MM.YYYY" },
    { id: "daysValidity", label: "Дней до конца срока" },
    { id: "businessUnit", label: "Салон" },
    {
        id: "guest", label: "Гость", linkTemplate: (row) => ({
            to: `/guests/view/${row.guestId}`,
            target: "_blank",
        }),
    },
    { id: "status.text", label: "Статус" },
    { id: "dateCreated", label: "Дата создания", dateFormat: "DD.MM.YYYY" },
    { id: "dateSold", label: "Дата продажи", dateFormat: "DD.MM.YYYY" },
    { id: "dateActive", label: "Дата активации", dateFormat: "DD.MM.YYYY" },
    { id: "externalSubjectName", label: "External subject" },
];

export default @withTranslation()
class PayDocReportBalancePage extends React.Component {
    state = {
        search: {
            search: null,
            reportDate: moment().startOf("hour"),
            regionIds: [],
            businessUnitIds: [],
            typeIds: [],
            onlyWithBalance: false,
            statuses: [],
            periodSaleStart: null,
            periodSaleEnd: null,
            externalSubjectIds: null,
            mod: null,
        },
        refreshTable: false,
        businessUnits: [],
        regions: [],
        types: [],
        statuses: [],
        externalSubjects: [],
    };

    componentDidMount() {
        loadToState(this, getBusinessUnits(), "businessUnits");
        loadToState(this, getRegions(), "regions");
        loadToState(this, getPayDocTypes(), "types");
        loadToState(this, getPayDocStatuses(), "statuses");
        loadToState(this, getExternalSubjects({ "type": "external_organization" }), "externalSubjects");
    }

    handleChange = createChangeHandle(this, "search");

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const { search } = this.state;

        return getPayDocReportBalance(
            processMomentFieldInObject(
                search,
                ["periodSaleStart", "periodSaleEnd"],
            ),
            ...params,
        );
    };

    handleExport = () => {
        const { search } = this.state;
        const rd = moment.isMoment(search.reportDate) ?
            search.reportDate.format("YYYY-MM-DD_HH-mm") :
            search.reportDate;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnData.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnData.map(item => {
                                const value = _.get(row, item.id);
                                if (value && item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                } else {
                                    return value;
                                }
                            }),
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "PayDocs Balance");
                    XLSX.writeFile(new_workbook, `PayDocs Balance ${rd}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    pdLinkTemplate = (row) => ({
        to: `/pay-docs/view/${row.id}`,
        target: "_blank",
    });

    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits, regions, types, statuses, externalSubjects } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DateTimeField2
                    label="Дата и время"
                    name="reportDate"
                    value={search.reportDate}
                    onChange={this.handleChange}
                    fullWidth
                    maxDate={moment()}
                />
                <DatePeriodField2
                    label={t("Period sales")}
                    nameFrom="periodSaleStart"
                    nameTo="periodSaleEnd"
                    valueFrom={search.periodSaleStart}
                    valueTo={search.periodSaleEnd}
                    onChangeFrom={this.handleChange}
                    onChangeTo={this.handleChange}
                    maxDate={moment()}
                />
                <MultiSelectFromItemsField
                    label={t("Region")}
                    name="regionIds"
                    value={search.regionIds}
                    onChange={this.handleChange}
                    fullWidth
                    items={regions}
                />
                <MultiSelectFromItemsField
                    label={t("Business unit")}
                    name="businessUnitIds"
                    value={search.businessUnitIds}
                    onChange={this.handleChange}
                    fullWidth
                    items={businessUnits}
                />
                <MultiSelectFromItemsField
                    label={t("PD Types")}
                    name="typeIds"
                    value={search.typeIds}
                    onChange={this.handleChange}
                    fullWidth
                    items={types}
                />
                <CheckboxField
                    label="C положительным балансом"
                    name="onlyWithBalance"
                    value={search.onlyWithBalance}
                    onChange={this.handleChange}
                />
                <MultiSelectFromItemsField
                    label={t("Payment Document status")}
                    name="statuses"
                    value={search.statuses}
                    onChange={this.handleChange}
                    fullWidth
                    items={statuses}
                    textField="text"
                />
                <AutocompleteMultiSelectField
                    label={t("External subject")}
                    name="externalSubjectIds"
                    onChange={this.handleChange}
                    value={search.externalSubjectIds}
                    fullWidth
                    options={externalSubjects}
                />
                {hasRole("ROLE_PAYDOC_PAYDOC_SAVE_MOD") && (
                    <AnyField
                        label="mod"
                        name="mod"
                        onChange={this.handleChange}
                        value={search.mod}
                        fullWidth
                    />
                )}
                <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title="Балансы ПД">
                <DataTable
                    columnData={columnData}
                    dataFunc={this.getTableData}
                    selector={false}
                    refresh={refreshTable}
                    autoload={false}
                    orderBy="num"
                    linkTemplate={this.pdLinkTemplate}
                />
            </Content>
        </React.Fragment>;
    }
}