import React from 'react';
import {
    MenuItem,
    Grid
} from '@material-ui/core';
import moment from 'moment';
import {connect} from "react-redux";
import calendarActions from "../calendar/calendar-actions";
import {
    getLastWorkShift,
    getParlourByScheduleStaffCode,
    getParloursWithLoad,
    startWorkShift
} from "../../services/organization";
import {
    closeVisit, completeVisit, confirmVisit, copyVisit,
    cutVisit, pasteVisit, provideVisit, removeCreatedVisit
} from "../../services/calendar";
import Content from "../../components/content/Content";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import infoActions from "../../components/info/info-actions";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {hasRole} from "../../services/user";
import {getCalendarEmployeeBreakTypes} from "../../services/calendarEmployee";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import commonActions from "../../common-actions";
import _ from "lodash";
import StuffScheduleVisits from "../calendar/StuffScheduleVisits";

const POPUP_CONFIRM = 'confirm';
const POPUP_COME = 'come';
const POPUP_BEGIN = 'begin';
const POPUP_COMPLETE = 'complete';
const POPUP_CANCEL = 'cancel';
const POPUP_CLOSE = 'close';
const POPUP_EDIT = 'edit';
const POPUP_REVIEW = 'review';
const POPUP_ADD_BREAK = 'add break';
const POPUP_SPLIT = 'split';
const POPUP_DELETE = 'delete';
const POPUP_CUT = 'cut';
const POPUP_COPY = 'copy';
const POPUP_PASTE = 'paste';

export default
@withTranslation()
@withRouter
@connect(state => ({
    currentDate: state.calendar.currentDate,
    currentParlour: state.common.currentParlour,
    user: state.auth.user,
    visits: state.calendar.visits,
    buffer: state.calendar.buffer,
    bufferCutOrCopy: state.calendar.bufferCutOrCopy
}), {
    setCurrentDate: calendarActions.setCurrentDate,
    setCurrentParlour: commonActions.setCurrentParlour,
    setBuffer: calendarActions.setBuffer,
    showMessage: messageDialogActions.show,
    showError: infoActions.showError,
    showInfo: infoActions.show,
    showConfirm: confirmDialogActions.show,
})
class StuffSchedulePage extends React.Component {

    state = {
        selectedVisit: null,
        selectedMoment: null,
        selectedMaster: {
            id: null,
            name: null
        },
        visitAnchorEl: null,
        slotAnchorEl: null,
        reloadVisits: false,
        reloadMasters: false,
        dialogReviewOpen: false,
        openMedicalCardDialogVisitId: false,
        workShift: null,
        closeWorkShiftDialogOpen: false,
        workShiftBreakDialogOpen: false,
        masters: [],
        parlours: [],
        workBreak: {
            start: null,
            end: null,
            comment: null,
            type: null,
        },
        selectedActivity: {
            timeStart: null,
            timeEnd: null,
            duration: null,
        },
        breakTypes: [],
        splitDialogOpen: false,
        openCancelDialog: false,
        toggleChangeAdminDialog: false,
        pasteConfirmBar: false,
        parlourSelectedPrintData: {
            name: null,
            date: null
        },
        reloadPageTimerId: null
    };

    componentDidMount() {
        // TODO: нужен глубокий рефактор всей ветки, здесь много лишних методов, но ходу определить каких - нельзя
        const { showError } = this.props
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const parlourCode = reId.exec(path);

        if (parlourCode) {
            getParlourByScheduleStaffCode(parlourCode).then(response => {
                if (response.success) {

                    // for test purpose
                    // const date = new moment().add(-3, 'days')
                    const date = new moment()

                    this.props.setCurrentParlour({...response.data})
                    this.props.setCurrentDate(date)

                    this.setState({
                        parlourSelectedPrintData: {
                            name: response.data.name,
                            date: date.format('L')
                        }
                    }, () => {
                        this.getCurrentWorkShift();
                        this.getParloursWithLoad();
                    })

                    // ------- reload page every hour avoiding reload manually ---------
                    const timerId = setTimeout(() => {
                        document.location.reload();
                    }, 1000 * 60 * 60);

                    if (timerId)
                        this.setState({
                            reloadPageTimerId: timerId
                        })

                } else {
                    if (response?.error?.message)
                        showError('Error: ' + response.error.message)
                }
            })
        }

    }

    componentWillUnmount() {
        if (this.state.reloadPageTimerId)
            clearTimeout(this.state.reloadPageTimerId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentParlour !== this.props.currentParlour) {
            this.getCurrentWorkShift();
            this.updateSelectedActivity();
        }

        if (prevProps.currentDate !== this.props.currentDate) {
            this.getParloursWithLoad();
        }

        if (prevState.selectedMaster !== this.state.selectedMaster) {
            this.updateSelectedActivity();
        }
    }

    handleKeyboardEvent = (event) => {
        if (event.ctrlKey && event.code === 'KeyX') {
            this.cutVisit();
        }

        if (event.ctrlKey && event.code === 'KeyC') {
            this.copyVisit();
        }

        if (event.ctrlKey && event.code === 'KeyV') {
            this.pasteVisit();
        }
    }

    cutVisit = () => {
        cutVisit(this.props, this.state.selectedVisit);
    }

    copyVisit = () => {
        copyVisit(this.props, this.state.selectedVisit);
    }

    pasteVisit = () => {
        if (!this.props.buffer) {
            return;
        }

        this.setState({
            pasteConfirmBar: true
        })
    }

    handleAcceptPaste = () => {
        this.setState({
            pasteConfirmBar: false
        }, pasteVisit(this.props, this.state.selectedVisit, this.state.selectedMoment, this.state.selectedMaster));
    }

    handleDeclinePaste = () => {
        this.setState({
            pasteConfirmBar: false
        })
    }

    setMasters = (masters) => {
        this.setState({
            masters: masters,
        })
    };

    updateSelectedActivity() {
        this.setState({
            selectedActivity: {
                ...this.state.selectedActivity,
                parlour: this.props.currentParlour,
                employee: this.state.selectedMaster,
            }
        })
    }

    getParloursWithLoad = () => {
        const { currentDate } = this.props;
        getParloursWithLoad(processMomentFieldInObject({
            dateFrom: currentDate,
            dateTo: currentDate,
            active: 1
        }, ['dateFrom', 'dateTo']))
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    });
                }
            });
    };

    processWorkShift(workShift) {
        if (workShift && workShift.started) {
            workShift.started = moment(workShift.started);
        }

        return workShift;
    }

    getCurrentWorkShift = () => {
        const { currentParlour } = this.props;

        if (currentParlour) {
            getLastWorkShift(currentParlour.id)
                .then(response => {
                    this.setState({
                        workShift: response.success ? this.processWorkShift(response.data) : null
                    });
                });
        }
    };

    startWorkShift = _.debounce(()=> {
        const { t, currentParlour } = this.props;
        startWorkShift(currentParlour.id)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Work shift is started'));
                    this.setState({
                        workShift: this.processWorkShift(response.data)
                    });
                    this.printStartWorkShiftReceipt()
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }, 500);

    printStartWorkShiftReceipt = () => {

        const { t, currentParlour } = this.props;

        if ( !currentParlour.printCheckEnable )
            return;

        this.setState({
            progressMessage: t('Check printing in progress')
        })

    }

    openWorkShiftSplitDialog = () => {
        const { selectedMoment, breakTypes } = this.state;
        const { currentParlour } = this.props;

        if (breakTypes.length === 0) {
            getCalendarEmployeeBreakTypes()
                .then(response => {
                    if (response.success) {
                        this.setState({
                            workShiftBreakDialogOpen: true,
                            workBreak: {
                                start: selectedMoment.utcOffset(currentParlour.timeZone),
                                end: selectedMoment.clone().add(1, 'hours').utcOffset(currentParlour.timeZone),
                                type: response.data[1].id,
                            },
                            breakTypes: response.data,
                        })
                    }
                });
        } else {
            this.setState({
                workShiftBreakDialogOpen: true,
                workBreak: {
                    start: selectedMoment.utcOffset(currentParlour.timeZone),
                    end: selectedMoment.clone().add(1, 'hours').utcOffset(currentParlour.timeZone),
                    type: breakTypes[1].id,
                }
            })
        }
    }

    handleDateChange = date => {
        this.setState({
            selectedMoment: null,
            selectedMaster: null,
            selectedVisit: null,
        });
        this.props.setCurrentDate(date);
    };

    handleVisitContextMenu = (visit, event) => {
        event.preventDefault();

        this.setState({
            selectedVisit: visit,
            visitAnchorEl: event.currentTarget,
        });
    };

    handleSlotContextMenu = (value, master, event) => {
        event.preventDefault();

        const selectedActivity = master.workShift.find(
            activity => moment(activity.timeStart).isSameOrBefore(value) && moment(activity.timeEnd).isAfter(value)
        );

        this.setState({
            selectedMoment: value,
            selectedMaster: master,
            selectedVisit: null,
            slotAnchorEl: event.currentTarget,
            selectedActivity: {
                ...selectedActivity,
                parlour: this.props.currentParlour,
                employee: master,
            },
        })
    }

    handleVisitDbClick = visit => {
        this.setState({selectedVisit: visit, selectedMoment: null, selectedMaster: null}, this.handleEditVisit);
    };

    handleSlotClick = (value, master) => {
        this.setState({selectedMoment: value, selectedMaster: master, selectedVisit: null});
    };

    handleEditVisit = () => {
        this.props.history.push(`/orders/view/${this.state.selectedVisit.id}`);
    };

    reloadVisits = () => {
        this.setState({
            reloadVisits: !this.state.reloadVisits,
        });
    };

    reloadMasters = () => {
        this.setState({
            reloadMasters: !this.state.reloadMasters,
        });
    };

    handlePopupMenuClose = key => () => {
        const { selectedVisit } = this.state;
        const { t } = this.props;

        this.setState({
            visitAnchorEl: null,
            slotAnchorEl: null,
        });

        switch (key) {
            case POPUP_CONFIRM:
                confirmVisit(selectedVisit.id)
                    .then(response => {
                        if (response.success) {
                            this.reloadVisits();
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;
            case POPUP_COME:
                this.setState({
                    openMedicalCardDialogVisitId: selectedVisit.id
                });

                break;

            case POPUP_BEGIN:
                provideVisit(selectedVisit.id)
                    .then(response => {
                        if (response.success) {
                            this.reloadVisits();
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_COMPLETE:
                completeVisit(selectedVisit.id)
                    .then(response => {
                        if (response.success) {
                            this.reloadVisits();
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_CANCEL:
                this.setState({
                    openCancelDialog: true
                });
                break;

            case POPUP_CLOSE:
                closeVisit(selectedVisit.id)
                    .then(response => {
                        if (response.success) {
                            this.reloadVisits();
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_REVIEW:
                this.setState({
                    dialogReviewOpen: true,
                });
                break;

            case POPUP_EDIT:
                this.handleEditVisit();
                break;

            case POPUP_ADD_BREAK:
                this.openWorkShiftSplitDialog();
                break;

            case POPUP_SPLIT:
                this.setState({
                    splitDialogOpen: true,
                });
                break;

            case POPUP_DELETE:
                this.props.showConfirm({
                    message: <span>{t('Do you really want to delete a visit')}?<br/>{t('The visit will be permanently deleted')}.<br/>{t('This operation cannot be undone')}!</span>,
                    title: <span>{t('Delete visit')}</span>,
                    onClose: this.handleCloseRemoveVisit
                });
                break;

            case POPUP_CUT:
                this.cutVisit();
                break;

            case POPUP_COPY:
                this.copyVisit();
                break;

            case POPUP_PASTE:
                this.pasteVisit();
                break;

            default:
                break;
        }
    };

    handleCloseRemoveVisit = ok => {
        if (ok) {
            const { t } = this.props;

            removeCreatedVisit(this.state.selectedVisit.id)
                .then(response => {
                    if (response.success) {
                        this.props.showInfo(t('Visit deleted') + '!');
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleCloseReviewDialog = () => {
        this.setState({
            dialogReviewOpen: false,
        });
    };

    closeMedicalCardDialog = (ok) => {
        this.setState({
            openMedicalCardDialogVisitId: false
        });

        if (ok) {
            this.reloadVisits();
        }

    };

    openCloseWorkShiftDialog = () => {
        this.setState({
            closeWorkShiftDialogOpen: true
        });
    };

    onCloseWorkShiftModalClosed = (workShiftData) => {
        const {t} = this.props;

        if (workShiftData === false) {
            this.setState({
                closeWorkShiftDialogOpen: false
            });
            return;
        }

        this.props.showInfo(t('Work shift is closed'));
        this.setState({
            workShift: workShiftData,
            closeWorkShiftDialogOpen: false
        });
        this.props.setCurrentParlour({...this.props.currentParlour});

        this.printCloseWorkShiftReceipt();
    };

    printCloseWorkShiftReceipt = () => {
        const { t, currentParlour } = this.props;

        if ( !currentParlour.printCheckEnable )
            return;

        this.setState({
            progressMessage: t('Check printing in progress')
        })

    }

    render() {
        const { currentDate, t, currentParlour} = this.props;
        const {
            selectedVisit,
            reloadVisits,
            reloadMasters,
            parlourSelectedPrintData
        } = this.state;

        return (
            <React.Fragment>
                <Content title={t("Schedule") + ' - ' + t('Parlour') + ": '" + parlourSelectedPrintData.name + '\' ' + t('Date') + ": " + parlourSelectedPrintData.date} hidePrintTitle>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {currentParlour ?
                                <StuffScheduleVisits
                                    date={currentDate.clone().utcOffset(currentParlour.timeZone, true).startOf('day')}
                                    selectedVisit = {selectedVisit}
                                    reloadVisits={reloadVisits}
                                    reloadMasters={reloadMasters}
                                />
                            : null}
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }

    closeDialog = (name) => {
        switch (name) {
            case POPUP_SPLIT:
                this.setState({
                    splitDialogOpen: false,
                }, () => { this.reloadMasters()});
                break;

            default:
                break;
        }
    };

    renderVisitMenuItem(title, key, statuses = [], roleOrCondition = true)
    {
        const { selectedVisit } = this.state;
        let enabled = roleOrCondition;

        if (typeof roleOrCondition === 'string' || roleOrCondition instanceof String) {
            enabled = hasRole(roleOrCondition);
        }

        if ( selectedVisit && (!statuses.length || statuses.indexOf(selectedVisit.status) >= 0) && enabled ) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }

    renderSlotMenuItem(title, key)
    {
        const { selectedMoment } = this.state;

        if ( selectedMoment ) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }

}
