import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits, getHumanEmployees, getDepartments} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    MenuItem,
    Typography
} from "@material-ui/core";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import {
    getFullVisitsReport,
    navLinks,
    getReportSummaryVisits,
    getDiscounts, fetchVisitStatuses
} from "../../services/calendar";
import {loadVrFullVisitsFilter, saveVrFullVisitsFilter} from "../../services/localStorage";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShowField from "../../components/fields/ShowField";
import CheckboxField from "../../components/fields/CheckboxField";
import {getPaymentTypes} from "../../services/sale";
import {getPayDocVariants} from "../../services/payDocs";
import {loadToState} from "../../utils/helpers";
import infoActions from "../../components/info/info-actions";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

const columnData = [
    { id: 'id', label: 'Visit', processValue: value => (value || '').substring(0, 7) + '...' },
    { id: 'status', label: 'Visit status' },
    { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'creator', label: 'User' },
    { id: 'totalFormat', label: 'Cost of visit' },
    { id: 'facilityName', label: 'Service' },
    { id: 'startFacility', label: 'Start of service', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'durationMin', label: 'Duration' },
    { id: 'master', label: 'Master' },
    { id: 'guestName', label: 'Guest' },
    { id: 'guestPhone', label: 'Guest phone' },
    { id: 'parlour', label: 'Parlour' },
    { id: 'comment', label: 'Comment' },
    { id: 'discount', label: 'Discount' },
    { id: 'closedAt', label: 'Closed', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'closedBy', label: 'Closed by' },
    { id: 'payments_cash', label: 'pay.types.cash' },
    { id: 'payments_cashless', label: 'pay.types.cashless' },
    {
        id: 'payments_paydocs',
        label: 'Actually written off from all PD',
        processValue: item => (<pre style={{ whiteSpace: 'pre' }}>{item}</pre>)
    },
    {
        id: 'payments_paydoc_name',
        label: 'pay.types.paydoc',
        processValue: item => (<pre style={{ whiteSpace: 'pre' }}>{item}</pre>)
    },
    {
        id: 'payments_paydoc_value',
        label: 'withheldPayDoc',
        processValue: item => (<pre style={{ whiteSpace: 'pre' }}>{item}</pre>)
    },
    {
        id: 'payments_paydoc_value_act',
        label: 'withheldPayDocAct',
        processValue: item => (<pre style={{ whiteSpace: 'pre' }}>{item}</pre>)
    },
    { id: 'resellerName', label: 'Reseller' },
    { id: 'resellerComment', label: 'Reseller comment' },
    { id: 'referralPartner', label: 'Referral partner' },
    { id: 'createDuration', label: 'Create duration' },
    { id: 'cancelReasonComment', label: 'Cancel comment' },
];


const columnDataXlsx = [
    { id: 'id', label: 'Visit', processValue: value => (value || '').substring(0, 7) + '...' },
    { id: 'status', label: 'Visit status' },
    { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'creator', label: 'User' },
    { id: 'total.val', label: 'Cost of visit' },
    { id: 'total.cur', label: 'Cost of visit' },
    { id: 'facilityName', label: 'Service' },
    { id: 'startFacility', label: 'Start of service', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'durationMin', label: 'Duration' },
    { id: 'master', label: 'Master' },
    { id: 'guestName', label: 'Guest' },
    { id: 'guestPhone', label: 'Guest phone' },
    { id: 'parlour', label: 'Parlour' },
    { id: 'comment', label: 'Comment' },
    { id: 'discount', label: 'Discount' },
    { id: 'closedAt', label: 'Closed', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'closedBy', label: 'Closed by' },
    { id: 'payments_cash', label: 'pay.types.cash' },
    { id: 'payments_cashless', label: 'pay.types.cashless' },
    { id: 'payments_paydocs', label: 'Actually written off from all PD'},
    { id: 'payments_paydoc_name', label: 'pay.types.paydoc' },
    { id: 'payments_paydoc_value', label: 'withheldPayDoc' },
    { id: 'payments_paydoc_value_act', label: 'withheldPayDocAct' },
    { id: 'resellerName', label: 'Reseller' },
    { id: 'resellerComment', label: 'Reseller comment' },
    { id: 'referralPartner', label: 'Referral partner' },
    { id: 'createDuration', label: 'Create duration' },
    { id: 'cancelReasonComment', label: 'Cancel comment' },
];

const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    container: {
        marginTop: 0,
        maxWidth: 900,
        borderBottom: '1px solid #ccc',
    },
    reportVisits__item: {
        paddingTop: 0,
        paddingBottom: 8,
    },
    reportVisitsItem__title: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    reportVisitsItem__value: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    customPanel: {
        minHeight: 'unset !important',
        height: '30px !important'
    },
    titlePanel: {
        marginTop: 0,
        marginBottom: 0,
    },
    checkboxFilter: {
        marginTop: 0,
    }
});

const departmentsValue = [
    { id: 'call', text: 'Колл-центр' },
    { id: 'administrator', text: 'Администраторы' },
    { id: 'online', text: 'Онлайн' },
    { id: 'reseller', text: 'Партнеры' },
    { id: 'department_okk', text: 'Подразделение ОКК' },
]

const departments = {
    call: 'call',
    administrator: 'administrator',
    online: 'online',
    reseller: 'reseller',
    department_ook: 'department_okk'
}

const departmentOKKName = 'ОКК'

export default @connect(null, {
    showMessage: messageDialogActions.show,
    showInfo: infoActions.show,
})
@withStyles(styles)
@withTranslation()
class ReportFullVisitsPage extends React.Component {
    abortTableData = null;
    abortSummary = null;

    constructor(props) {
        super(props);

        const search = loadVrFullVisitsFilter();
        if (search) {
            search.date_start = moment();
            search.date_end = moment();
        }

        this.state = {
            search: {
                search: null,
                date_start: moment(),
                date_end: moment(),
                businessUnitIds: [],
                departmentCreator: null,
                creators: [],
                status: [],
                discounts: [],
                type: null,
                paymentType: null,
                payDocVariant: null,
                dateCreated: false,
                linkedVisits: false,
                hasReferral: false,
                ...search,
                departmentIds: []
            },
            reportSummaryVisits: null,
            refreshTable: false,
            businessUnits: [{ id: 0, name: this.props.t('---All---') }],
            employees: [],
            statuses: [],
            types: [
                { id: null, text: '---Все---' },
                { id: 'payment', text: 'Платный' },
                { id: 'withoutPayment', text: 'Бесплатный' }
            ],
            paymentTypes: [],
            payDocVariants: [],
            discounts: [],
            panelExpanded: false,
            departments: []
        };
    }

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: null, name: this.props.t('---All---') },
                            ...response.data
                        ],
                    });
                }
            });

        this.getEmployee();

        getPaymentTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        paymentTypes: [{ id: null, text: this.props.t('---All---') }, ...response.data]
                    })
                }
            })

        getPayDocVariants()
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDocVariants: [{ id: 0, text: this.props.t('---All---') }, ...response.data],
                    })
                }
            })

        getDiscounts({ active: 'active,not_active', forSalableEntity: 'Visit' })
            .then(response => {
                if (response.success) {
                    this.setState({
                        discounts: [...response.data],
                    })
                }
            })

        fetchVisitStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        statuses: [...response.data],
                    })
                }
            })
        loadToState(this, getDepartments(), 'departments')
    }

    componentWillUnmount() {
        this.abortTableData && this.abortTableData.abort();
        this.abortSummary && this.abortSummary.abort();
    }

    isSummaryNotAvailable = () => {
        const { search } = this.state;
        return search.search
            || search.departmentCreator
            || search.creators.length > 0
            || search.creators.length > 0
            || search.status.length > 0
            || search.discounts.length > 0
            || search.type
            || search.paymentType
            || search.payDocVariant
            || search.dateCreated
            || search.linkedVisits
    }

    getSummaryReportVisits = () => {
        const { search } = this.state;

        this.abortSummary && this.abortSummary.abort();
        this.abortSummary = new AbortController();

        if (this.isSummaryNotAvailable()) {
            this.setState({ reportSummaryVisits: null });
            return;
        }

        getReportSummaryVisits(search, this.abortSummary.signal)
            .then(response => {
                if (response.success) {
                    this.setState({
                        reportSummaryVisits: {
                            ...response.data,
                            'cntFactCanceled': {
                                ...response.data.cntFactCanceled,
                                'time_fact_canceled': moment.duration(response.data.cntFactCanceled.time_fact_canceled)
                            },
                        }
                    })
                }
            })
    }

    handleChange = prop => event => {
        const { search } = this.state;
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        let newState = {
            search: {
                ...search,
                [prop]: value,
            }
        };

        if (prop === 'paymentType') {
            newState.search.payDocVariant = null;
        }

        if (prop === 'panelExpanded') {
            if (this.isSummaryNotAvailable()) {
                this.props.showInfo('Сводные данные при данных фильтрах недоступны!');
                return;
            }

            newState = {
                ...this.state,
                [prop]: !this.state.panelExpanded
            }
        }

        if (prop === 'departmentCreator') {
            newState.search.creators = [];
            this.setState(newState, this.getEmployee);
        } else {
            this.setState(newState);
        }
    };

    getEmployee = () => {
        const { departmentCreator } = this.state.search;
        let search = {};

        if (departmentCreator === 'department_okk') {
            search['departmentName'] = departmentOKKName
        } else {
            search['employeePost'] = this.getDepartmentsForKey(departmentCreator);
        }

        getHumanEmployees(search)
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [{ id: null, name: this.props.t('---All---') }, ...response.data]
                    })
                }
            })
    }

    getDepartmentsForKey = (keyDepartment) => {
        let result;

        switch (keyDepartment) {
            case departments.call:
                result = ['operator', 'caller', 'caller_chief', 'operator_chat', 'operator_call_expert'];
                break;
            case departments.administrator:
                result = ['administrator', 'senior_administrator'];
                break;
            default:
                result = [
                    'operator',
                    'caller',
                    'caller_chief',
                    'administrator',
                    'senior_administrator',
                    'operator_chat',
                    'operator_call_expert'
                ];
                break;
        }

        return result;
    }

    handleClickFilter = () => {
        saveVrFullVisitsFilter(this.state.search);
        this.getSummaryReportVisits();
        this.setState(state => ({ refreshTable: !state.refreshTable, panelExpanded: false, }));
    };

    getTableData = (...params) => {
        const { search } = this.state;

        this.abortTableData && this.abortTableData.abort();
        this.abortTableData = new AbortController();

        const searchRequest = {
            ...search,
            date_start: !search.dateCreated && search.date_start,
            date_end: !search.dateCreated && search.date_end,
            visitCreated_start: search.dateCreated && search.date_start,
            visitCreated_end: search.dateCreated && search.date_end,
            employeePostsCreator: [departments.call, departments.administrator].find(department => department === search.departmentCreator)
                ? this.getDepartmentsForKey(search.departmentCreator)
                : null,
            employeeDepartmentName: departments.department_ook === search.departmentCreator ? departmentOKKName : null,
            resellersCreator: search.departmentCreator === departments.reseller,
            onlineCreator: search.departmentCreator === departments.online,
            visitStatus: search.status,
            visitType: search.type,
            paymentType: search.paymentType,
            payDocVariant: search.payDocVariant,
        };

        return getFullVisitsReport(searchRequest, this.abortTableData.signal, ...params);
    };

    handleExport = () => {
        const { t } = this.props;
        const { search, businessUnits } = this.state;
        const from = search.date_start;
        const to = search.date_end;
        const bu = search.businessUnitIds && search.businessUnitIds.length ?
            (search.businessUnitIds.length < 3 ?
                    businessUnits.filter(item => search.businessUnitIds.indexOf(item.id) !== -1).map((parlour => parlour.name)).join(',')
                    :
                    'Many'
            )
            :
            'All';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    let columnData = [];
                    let report = [];
                    let maxCountPayDoc = response.data.reduce((count, item) => {
                        let itemCount = 0;

                        if (item.payments_paydoc_name) {
                            itemCount = item.payments_paydoc_name.split('\n').length;
                        }

                        return count >= itemCount ? count : itemCount;
                    }, 1);

                    if (maxCountPayDoc > 1) {
                        for (let key in columnDataXlsx) {
                            let itemColumn = columnDataXlsx[key];

                            if (itemColumn.id === 'payments_paydoc_name') {
                                for (let index = 0; index < maxCountPayDoc; index++) {
                                    columnData.push({ label: 'pay.types.paydoc', id: 'payments_paydoc_name_' + index });
                                    columnData.push({ label: 'withheldPayDoc', id: 'payments_paydoc_value_' + index });
                                    columnData.push({
                                        label: 'withheldPayDocAct',
                                        id: 'payments_paydoc_value_act_' + index
                                    });
                                }
                            } else if (['payments_paydoc_value', 'payments_paydoc_value_act'].indexOf(itemColumn.id) === -1) {
                                columnData.push(itemColumn);
                            }
                        }

                        for (let key in response.data) {
                            let itemRow = {};

                            for (let property in response.data[key]) {
                                if (['payments_paydoc_name', 'payments_paydoc_value', 'payments_paydoc_value_act'].indexOf(property) === -1) {
                                    itemRow[property] = response.data[key][property];
                                }
                            }

                            if (response.data[key].payments_paydoc_name) {
                                let itemPayDoc = response.data[key].payments_paydoc_name.split('\n');
                                let itemPayDocValue = response.data[key].payments_paydoc_value.split('\n');
                                let itemPayDocValueAct = response.data[key].payments_paydoc_value_act.split('\n');

                                for (let index = 0; index < itemPayDoc.length; index++) {
                                    itemRow['payments_paydoc_name_' + index] = itemPayDoc[index];
                                    itemRow['payments_paydoc_value_' + index] = itemPayDocValue[index];
                                    itemRow['payments_paydoc_value_act_' + index] = itemPayDocValueAct[index];
                                }
                            }

                            report.push({ ...itemRow });
                        }
                    } else {
                        columnData = [...columnDataXlsx];
                        report = [...response.data];
                    }

                    const data = [
                        //First row - headers
                        columnData.map(item => t(item.label)),
                    ];

                    report.forEach(row => {
                        data.push(
                            columnData.map(item => {
                                const value = _.get(row, item.id);

                                if (item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                }

                                if (item.id.indexOf('payments_paydoc_value_act') !== -1 && value) {
                                    return parseFloat(value)
                                }

                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Full Visits");
                    XLSX.writeFile(new_workbook, `Full Visits ${bu} ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleInputPress = event => {
        if (event.key === 'Enter') {
            this.handleClickFilter();
        }
    };

    render() {
        const { classes, t } = this.props;
        const {
            refreshTable, search, businessUnits, reportSummaryVisits, employees, statuses, types,
            paymentTypes, payDocVariants, discounts, panelExpanded, departments
        } = this.state;

        let countCreater = {
            administrator: reportSummaryVisits && reportSummaryVisits.createrVisits.find(item => item.role === 'administrator'),
            online: reportSummaryVisits && reportSummaryVisits.createrVisits.find(item => item.role === 'online'),
            reseller: reportSummaryVisits && reportSummaryVisits.createrVisits.find(item => item.role === 'reseller'),
            call: reportSummaryVisits && reportSummaryVisits.createrVisits.find(item => item.role === 'call'),
        }

        let payedVisits = reportSummaryVisits && reportSummaryVisits.fullSummaryReport.find(item => item.payed_type === 'payed');
        let freeVisits = reportSummaryVisits && reportSummaryVisits.fullSummaryReport.find(item => item.payed_type === 'free');

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t('Show visits by date interval')}
                        className={classes.filterControl}
                    />
                    <CheckboxField
                        label="Выбирать по дате создания"
                        value={search.dateCreated}
                        onChange={this.handleChange('dateCreated')}
                        className={classes.checkboxFilter}
                    />
                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        textField={'name'}
                        label={'Салоны'}
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                        value={search.businessUnitIds}
                    />
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        className={classes.filterControl}
                        label={t('Search')}
                        onKeyPress={this.handleInputPress}
                    />
                    <AnyField
                        value={search.departmentCreator}
                        label={'Подразделение'}
                        onChange={this.handleChange('departmentCreator')}
                        select
                        fullWidth
                    >
                        {
                            [{
                                id: null,
                                text: this.props.t('---All---')
                            }, ...departmentsValue].map((department, idx) => (
                                <MenuItem key={idx} value={department.id}>
                                    {department.text}
                                </MenuItem>
                            ))
                        }
                    </AnyField>
                    <AutocompleteMultiSelectField
                        options={employees}
                        textField={'name'}
                        label={'Сотрудник'}
                        onChange={this.handleChange('creators')}
                        fullWidth
                        readOnly={[departments.reseller, departments.online].indexOf(search.departmentCreator) !== -1}
                        value={search.creators}
                    />
                    <MultiSelectFromItemsField
                        items={statuses}
                        label={t('Status')}
                        value={search.status}
                        textField="text"
                        onChange={this.handleChange('status')}
                        fullWidth
                    />
                    <AnyField
                        select
                        fullWidth
                        label='Тип'
                        onChange={this.handleChange('type')}
                        value={search.type}
                    >
                        {
                            types.map((type, idx) => (
                                <MenuItem key={idx} value={type.id}>
                                    {type.text}
                                </MenuItem>
                            ))
                        }
                    </AnyField>
                    <AnyField
                        select
                        fullWidth
                        label='Тип оплаты'
                        onChange={this.handleChange('paymentType')}
                        value={search.paymentType}
                    >
                        {
                            paymentTypes.map((type, idx) => (
                                <MenuItem key={idx} value={type.id}>
                                    {type.text}
                                </MenuItem>
                            ))
                        }
                    </AnyField>
                    {
                        search.paymentType === 'paydoc'
                            ?
                            <AnyField
                                select
                                fullWidth
                                label='Тип платежного документа'
                                onChange={this.handleChange('payDocVariant')}
                                value={search.payDocVariant}
                            >
                                {
                                    payDocVariants.map((type, idx) => (
                                        <MenuItem key={idx} value={type.id}>
                                            {type.text}
                                        </MenuItem>
                                    ))
                                }
                            </AnyField>
                            : null
                    }
                    <AnyField
                        select
                        fullWidth
                        label='Скидка'
                        onChange={this.handleChange('discounts')}
                        value={search.discounts}
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>---Все---</span>;
                                }

                                return discounts.filter(item => selected.indexOf(item.id) > -1).map(item => item.name).join(', ');
                            },
                        }}
                    >
                        {
                            discounts.map((discount, idx) => (
                                <MenuItem key={idx} value={discount.id}>
                                    <Checkbox checked={search.discounts.indexOf(discount.id) > -1}/>
                                    <ListItemText primary={discount.name}/>
                                </MenuItem>
                            ))
                        }
                    </AnyField>
                    <CheckboxField
                        label="Только связанные"
                        value={search.linkedVisits}
                        onChange={this.handleChange('linkedVisits')}
                    />
                    <CheckboxField
                        label="Только с реферальным партнером"
                        value={search.hasReferral}
                        onChange={this.handleChange('hasReferral')}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Full visits report')}>
                    <ExpansionPanel expanded={panelExpanded} onChange={this.handleChange('panelExpanded')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}
                                               className={classes.customPanel}><Typography variant="h6" gutterBottom
                                                                                           className={classes.titlePanel}>{t("Summary data")}</Typography></ExpansionPanelSummary>
                        {reportSummaryVisits && reportSummaryVisits.cntCanceled ?
                            reportSummaryVisits.cntCanceled.map((item, idx) => {
                                return (
                                    <ExpansionPanelDetails className={classes.reportVisits__item} key={idx}>
                                        <Grid container spacing={2} className={classes.container}>
                                            {
                                                !idx
                                                    ?
                                                    <Grid item xs={12} className={classes.reportVisitsItem__title}>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            Общее число отмененных визитов
                                                        </Typography>
                                                    </Grid>
                                                    : null
                                            }
                                            {reportSummaryVisits.cntCanceled.length > 1
                                                ?
                                                <Grid item xs={3}>
                                                    <ShowField
                                                        value={(item && item.currency)}
                                                        label={'Валюта'}
                                                    />
                                                </Grid>
                                                : null
                                            }
                                            <Grid item xs={3}>
                                                <ShowField
                                                    value={(item && item.cnt_canceled) || 0}
                                                    label={'Количество(шт)'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <ShowField
                                                    value={(item && parseFloat(item.cnt_canceled_money).toFixed(2)) || 0}
                                                    label={'Сумма'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <ShowField
                                                    value={(item && moment.duration(item.cnt_canceled_duration).asMinutes()) || 0}
                                                    label={'Время(мин)'}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                )
                            })
                            : null
                        }

                        <ExpansionPanelDetails className={classes.reportVisits__item}>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={12} className={classes.reportVisitsItem__title}>
                                    <Typography variant="subtitle2" gutterBottom>Фактически отмененные
                                        визиты</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(reportSummaryVisits && reportSummaryVisits.cntFactCanceled && reportSummaryVisits.cntFactCanceled.cnt_fact_canceled) || 0}
                                        label={'Количество(шт)'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(reportSummaryVisits
                                            && reportSummaryVisits.cntFactCanceled
                                            && reportSummaryVisits.cntFactCanceled.sum_fact_canceled
                                            && parseFloat(reportSummaryVisits.cntFactCanceled.sum_fact_canceled).toFixed(2)) || 0}
                                        label={'Сумма'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(reportSummaryVisits && reportSummaryVisits.cntFactCanceled && reportSummaryVisits.cntFactCanceled.time_fact_canceled.asMinutes()) || 0}
                                        label={'Время (мин)'}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails className={classes.reportVisits__item}>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={12} className={classes.reportVisitsItem__title}>
                                    <Typography variant="subtitle2" gutterBottom>Создано</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(countCreater.call && countCreater.call.count_visits) || 0}
                                        label={'Колл-центр'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(countCreater.administrator && countCreater.administrator.count_visits) || 0}
                                        label={'Администраторы'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(countCreater.online && countCreater.online.count_visits) || 0}
                                        label={'Онлайн'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(countCreater.reseller && countCreater.reseller.count_visits) || 0}
                                        label={'Партнеры'}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails className={classes.reportVisits__item}>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={12} className={classes.reportVisitsItem__title}>
                                    <Typography variant="subtitle2" gutterBottom>Оплаченные визиты</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(payedVisits && payedVisits.count_payed) || 0}
                                        label={'Количество(шт)'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(payedVisits && parseFloat(payedVisits.sum_payed).toFixed(2)) || 0}
                                        label={'Сумма'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(payedVisits && moment.duration(payedVisits.duration).asHours()) || 0}
                                        label={'Часы'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(payedVisits && parseFloat(payedVisits.avg_payed).toFixed(2)) || 0}
                                        label={'Средний чек'}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails className={classes.reportVisits__item}>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={12} className={classes.reportVisitsItem__title}>
                                    <Typography variant="subtitle2" gutterBottom>Безоплатные визиты</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(freeVisits && freeVisits.count_payed) || 0}
                                        label={'Количество(шт)'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(freeVisits && parseFloat(freeVisits.sum_payed).toFixed(2)) || 0}
                                        label={'Сумма'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(freeVisits && moment.duration(freeVisits.duration).asHours()) || 0}
                                        label={'Часы'}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails className={classes.reportVisits__item}>
                            <Grid container spacing={2} className={classes.container}>
                                <Grid item xs={12} className={classes.reportVisitsItem__title}>
                                    <Typography variant="subtitle2" gutterBottom>Гости</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(reportSummaryVisits && reportSummaryVisits.onlySalesIncoming) || 0}
                                        label={'Улица'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={(reportSummaryVisits && reportSummaryVisits.visitsWithSalesIncoming) || 0}
                                        label={'Салон'}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        linkTemplate={item => `/orders/view/${item.id}`}
                        numeration={true}
                        autoload={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
