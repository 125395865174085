import {useContext, useMemo} from "react";
import {dataTableGroupValueStrategy} from "../../../../services/common";
import groupStrategy from "./group-strategy";
import process from "./process";
import Context from "../Context";

const useColumnData = () => {
    const { search } = useContext(Context);
    const isShowDebts = search.dateType === 'month';

    return useMemo(() => [
        {
            id: 'day',
            label: 'Date',
            groupStrategy: dataTableGroupValueStrategy.fromTo('day'),
            processValue: process.dayWithBu,
            parentProcessValue: process.parentDayWithBu,
        },
        {
            id: 'income',
            label: 'Доход',
            processValue: process.income,
            groupStrategy: groupStrategy.income
        },
        {
            id: 'spending',
            label: 'Расход',
            processValue: process.spending(isShowDebts),
            groupStrategy: groupStrategy.spending
        },
        {
            id: 'profit',
            label: 'Прибыль',
            processValue: process.profit,
            groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('profit')
        },
        { id: 'debts', label: 'Долги', processValue: process.debts(isShowDebts), groupStrategy: groupStrategy.debts },
        {
            id: 'profitWithDebts',
            label: 'Прибыль с долгами',
            processValue: process.profit,
            groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('profitWithDebts')
        },
        {
            id: 'spending2',
            label: 'Расход 2',
            processValue: process.spending(isShowDebts),
            groupStrategy: groupStrategy.spending2
        },
        {
            id: 'debts2',
            label: 'Долги 2',
            processValue: process.debts(isShowDebts),
            groupStrategy: groupStrategy.debts2
        },
        {
            id: 'profitWithDebts2',
            label: 'Прибыль с долгами 2',
            processValue: process.profit,
            groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('profitWithDebts2')
        },
        {
            id: 'incomeVisits',
            label: 'Доход услуги',
            processValue: process.incomeVisits,
            groupStrategy: groupStrategy.incomeVisits
        },
        {
            id: 'incomeGoods',
            label: 'Доход товар',
            processValue: process.incomeGoods,
            groupStrategy: groupStrategy.incomeGoods
        },
        {
            id: 'vgo',
            label: 'ВГО',
            processValue: process.vgo,
            groupStrategy: groupStrategy.vgo
        },
        {
            id: 'masterHourAvg',
            label: 'Avg hour cost',
            groupStrategy: groupStrategy.masterHourAvg,
            parentProcessValue: (value) => {
                return (value && value.count) ? Math.round(value.val / value.count * 100) / 100 : null;
            }
        },
        {
            id: 'mastersWorkLoad', label: 'Masters work load',
            processValue: process.dayMasterWorkload,
            groupStrategy: groupStrategy.masterWorkload,
        },
        { id: 'v6', label: 'Загрузка оборудования' },
        { id: 'revenue', label: 'Выручка', processValue: process.revenue, groupStrategy: groupStrategy.revenue },
        { id: 'otherSupply', label: 'Прочие поступления', processValue: process.otherSupply, groupStrategy: groupStrategy.otherSupply },
        {
            id: 'pdBalance',
            label: 'Депозит ПД',
            processValue: process.pdBalance,
            groupStrategy: groupStrategy.pdBalance
        },
        {
            id: 'distributionCheck',
            label: 'Проверка распределения',
            processValue: process.distributionCheck,
            groupStrategy: groupStrategy.distributionCheck
        },
        {
            id: 'clearMoneyFlow',
            label: 'Чистый денежный поток',
            processValue: process.profit,
            groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('clearMoneyFlow')
        },
        {
            id: 'clearMoneyFlowDebts',
            label: 'Выручка – все расходы',
            processValue: process.profit,
            groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('clearMoneyFlowDebts')
        },

        {
            id: 'cash_box',
            label: 'In cash box',
            processValue: process.incomingValue,
            groupStrategy: dataTableGroupValueStrategy.same('cash_box')
        },
        {
            id: 'safe',
            label: 'In safe',
            processValue: process.incomingValue,
            groupStrategy: dataTableGroupValueStrategy.same('safe')
        },
    ], [isShowDebts]);
}

export default useColumnData;
