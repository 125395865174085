import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
    Grid, CircularProgress
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import {withTranslation} from "react-i18next";
import {getWhatsAppChannels, initWebhookUrl, sendWhatsappMessage} from "../../services/whatsapp";
import _ from "lodash";

const emptyMessage = {
    phone: null,
    message: null
}

export default
@withTranslation()
@connect(state => ({

}),{
    showInfo: infoActions.show,
    showError: infoActions.showError,
})
@withStyles(theme => ({
    container: {
        width: 500,
    }
}))
class WhatsappPage extends React.Component
{
    state = {
        message: {
            ...emptyMessage
        },
        channels: {
            loading: true,
            tableRows: []
        }
    };

    componentDidMount() {
        getWhatsAppChannels().then( response => {
            const { channels } = this.state
            const { showError } = this.props

            if (response.success) {
                let channelsTableRows = []

                if (response?.data && response.data.length > 0)
                    _.forEach(response.data, channel => {
                        if (channel.transport === 'whatsapp')
                            channelsTableRows.push({
                                id: channel.channelId,
                                transport: channel.transport,
                                phone: channel.plainId,
                                state: channel.state
                            })
                    })

                if (channelsTableRows.length > 0)
                    this.setState({
                        channels: {
                            ...channels,
                            loading: false,
                            tableRows: channelsTableRows
                        }
                    })
            } else {
                this.setState({
                    channels: {
                        ...channels,
                        loading: false
                    }
                }, () => { showError('Can\'t load whatsapp channels') })
            }
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleMessageChange = event => {
        this.setState({
            message: {
                ...this.state.message,
                [event.target.name]: event.target.value,
            }
        });
    };

    handleSend = () => {
        const { message } = this.state
        const { showError, showInfo, t } = this.props

        sendWhatsappMessage(message)
            .then(response => {
                if (response.success) {
                    this.setState({
                        message: {
                            ...emptyMessage
                        }
                    }, () => { showInfo('Message sent') })
                } else {
                    showError(response.error ? response.error.message : t("Can't send whats app message"))
                }
            })
    };

    handleInitWebhookUrl = () => {
        const { showError, showInfo, t } = this.props

        initWebhookUrl()
            .then(response => {
                if (response.success) {
                    let msg = t('Success') + ( (!!response?.data?.status) ? ` - Response status: ${response?.data?.status}` : '' )
                    showInfo(msg)
                } else {
                    showError(response.error ? response.error.message : t("Can't init webhook url"))
                }
            })
    };

    render() {
        const { classes, t } = this.props;
        const { message, channels } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={this.handleInitWebhookUrl}>Зарегестрировать адрес для приема вебхуков</ActionButton>
            </LeftBar>
            <Content title="Whatsapp настройки">
                <Grid container spacing={2}>
                    <Grid item sx={6}>
                        <Typography variant="h6">Отправка тестового сообщения</Typography>
                        <div className={classes.container}>
                            <AnyField
                                label="Phone"
                                value={message.phone}
                                name="phone"
                                fullWidth
                                onChange={this.handleMessageChange}
                            />
                            <AnyField
                                label="Message"
                                value={message.message}
                                name="message"
                                error={!message.message}
                                required
                                fullWidth
                                multiline
                                rows={4}
                                onChange={this.handleMessageChange}
                            />
                            <ActionButton disabled={!message.message || !message.phone} onClick={this.handleSend}>Send</ActionButton>
                        </div>
                    </Grid>
                    <Grid item sx={6}>
                    <Typography variant="h6">Список каналов whatsapp</Typography>
                        {(channels.loading) ?
                            <CircularProgress/>
                            :
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Id</TableCell>
                                            <TableCell align="right">Телефон</TableCell>
                                            <TableCell align="right">Состояние</TableCell>
                                            <TableCell align="right">Транспорт</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {channels.tableRows.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="right">{row.phone}</TableCell>
                                                <TableCell align="right">{t(row.state)}</TableCell>
                                                <TableCell align="right">{row.transport}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Инструкция по добавлению новго канала whatsapp</Typography>
                        <ul>
                            <li>Офромить полностью канала в wazzup:
                                <ul>
                                    <li>Добавить новый канал Whatsapp Web</li>
                                    <li>Отсканировать QR код</li>
                                    <li>Проверить что канал активен в личном кабинете wazzup</li>
                                    <li>Вернуться на страницу настроек Whatsapp в ERP</li>
                                </ul>
                            </li>
                            <li>В списке каналов выше найти активный и скопировать его id</li>
                            <li>id канала прописать на странице общих настроек ЕРП</li>
                            <li>Выслать себе тестовое сообщение на этой странице чтобы понять что все работает</li>
                        </ul>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("FAQ")}</Typography>
                        <ul>
                            <li><b>Перестали уходить сообщения?</b></li>
                            <li>Проверить есть ли активный (статус: active) канал в списке всех каналов выше</li>
                            <li>Если нет то идти в личный кабинет wazzup и проерять что там с каналом</li>
                        </ul>
                    </Grid>
                </Grid>
            </Content>
        </React.Fragment>
    }
}