import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getPrices} from "../../services/facilities";
import SearchField from "../../components/fields/SearchField";

const columnData = [
    { id: 'code', label: 'Идентификатор прайса' },
    { id: 'name', label: 'Название прайса' },
    { id: 'statusText', label: 'Статус'},
    { id: 'hourCostFormatted', label: 'Стоимость часа'},
];


export default
class PricesListPage extends React.Component
{
    state = {
        search: null,
    }

    handleChangeSearch = event => {
        this.setState({
            search: event.target.value,
            refreshTable: false,
        })
    }

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    }

    getTableData = (...params) => {
        const {search} = this.state;

        return getPrices(search, ...params)
    }

    render() {
        const { search, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search}
                        onChange={this.handleChangeSearch}
                        label="Поиск услуги"
                        onClick={this.handleClickSearch}
                    />
                    <ActionButton onClick={() => {this.props.history.push(`/settings/prices/edit/0`)}}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Прайсы">
                    <DataTable
                        columnData={ columnData }
                        dataFunc={ this.getTableData }
                        linkTemplate={(item) => `/settings/prices/view/${item.id}`}
                        selector={false}
                        onClick={ this.handleEdit }
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
            )
    }
}
