import React from 'react';
import { connect } from "react-redux";
import infoActions from "../../components/info/info-actions";
import {
    Button,
    Dialog,
    DialogActions,
    withStyles,
    DialogTitle,
    DialogContent,
    Grid, MenuItem, Checkbox, ListItemText
} from "@material-ui/core";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    acceptOrder, declineOrder,
    emptyOrder,
    getOrder,
    navLinks, orderStatuses, processOrderAfterGet,
    emptyOrderGood, getOrderBlankUrl,
    setOrderDeliveryDate, getStorageOrderRest, setDeliveryOrder
} from "../../services/goods";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import AnyField from "../../components/fields/AnyField";
import { emptyMoney, formatMoney, getCurrencies} from "../../services/common";
import NumberFormatCount from "../../components/fields/NumberFormatCount";
import {getPayDelays, getPayTypeText, payTypes} from "../../services/payDocs";
import OrderLeftFields from "./OrderLeftFields";
import UploadFilesField from "../../components/fields/UploadFilesField";
import DateField from "../../components/fields/DateField";
import CreateOrdersDialog from "./CreateOrdersDialog";
import _ from "lodash"
import DeliveryDialog from "../../components/dialogs/DeliveryDialog";
import {withRouter} from "react-router";
import MoneyField from "../../components/fields/MoneyField";

const columnData = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товаров' },
    { id: 'purposeText', number: true, label: 'Предназначение' },
    { id: 'priceFormat', number: true, label: 'Цена покупки' },
    { id: 'counterpartQuantity', label: 'Доступное количество контрагента' },
    { id: 'quantityStorage', label: 'Остаток на складе' },
    { id: 'quantity', label: 'Количество товаров в заказе' },
    { id: 'quantityDelivery', label: 'Количество товаров в поставке' },
    { id: 'quantityRecommend', label: 'Рекомендованное количество' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'comment', label: 'Комментарий' },
    { id: 'usernamesRequests', label: 'Пользователь, создавший запрос' },
    { id: 'phonesRequests', label: 'Телефон пользователя, создавшего запрос' },
    { id: 'numsRequests', label: 'Номер запроса' },
];

const styles = theme => ({
    dialogContent: {
        minWidth: 600,
    }
});

@withRouter
class OrderViewPage extends React.Component
{
    state = {
        order: emptyOrder,

        dialogDeclineOpen: false,
        declineComment: null,

        dialogCounterpartOpen: false,
        counterpartBill: null,
        counterpartComment: null,

        dialogEditOpen: false,
        editingGood: emptyOrderGood,
        editingIndex: null,
        currencies: [],

        dialogPayOpen: false,
        payDelays: [],
        payDataTypes: [],
        payDataAmountPre: {...emptyMoney},
        payDataAmountDelay: {...emptyMoney},
        payDataAmountTotal: {...emptyMoney},
        payDataPayDelay: null,
        payDataComment: null,

        deliveryDialogOpen: false,

        dialogAccountingOpen: false,
        accountingComment: null,
        paymentOrder: null,

        dialogDeliveryDateOpen: false,
        deliveryDateComment: null,
        deliveryDate: null,
        createOrdersDialogProps: {
            parentId: null,
            grouped: [],
            dialogOrdersOpen: false,
        }
    };



    componentDidMount() {
        const reId = new RegExp('/goods/orders/view/([^/]+)$');
        const resultId = reId.exec(this.props.location.pathname);
        if (resultId.length !== 2) {
            //Error request
            return;
        }

        if (resultId[1] !== "0") {
            getOrder(resultId[1])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            order: processOrderAfterGet(response.data),
                        });


                    }
                });
        }

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data,
                    })
                }
            });


        getPayDelays()
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDelays: response.data,
                    });
                }
            });

    };

    handleClickBack = () => {
        this.props.history.push(`/goods/orders`);
    };

    handleHistoryClick = () => {
        this.props.history.push(`/goods/orders/history-status/${this.state.order.id}`);

    };

    handleCloseCounterpartConfirm = ok => {
        if (ok) {
            acceptOrder({id: this.state.order.id})
                .then(response => {
                    if (response.success) {
                        this.setState({
                            order: processOrderAfterGet(response.data),
                        });

                        this.props.showInfo('Передано контрагенту на согласование');
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleToCounterpart = () => {
        this.props.showConfirm({
            title: 'Формирование заказа',
            message: 'Отправить заказ на согласование контрагенту?',
            onClose: this.handleCloseCounterpartConfirm,
        });
    };

    handleDecline = () => {
        this.setState({
            dialogDeclineOpen: true,
            declineComment: null,
        })
    };

    handleChange = prop => event => {
        this.setState({
            [prop]: event.target.value,
        })
    };

    handleChangeDate = prop => event => {
        this.setState({
            [prop]: event.format(),
        })
    };

    handleCloseDeclineDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogDeclineOpen: false,
            });
            return;
        }

        declineOrder({id: this.state.order.id, comment: this.state.declineComment})
            .then(response => {
                if (response.success) {
                    this.setState({
                        order: processOrderAfterGet(response.data),
                        dialogDeclineOpen: false,
                    });

                    this.props.showInfo('Заказ отклонен');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleAdditionalClick = () => {
        this.props.history.push(`/goods/orders/additional/${this.state.order.id}`);
    };

    handleAccept = () => {
        const { order } = this.state;

        switch (order.status) {
            case orderStatuses.counterpart_pending:
                //Check prices
                if (order.goodStorageGroupedOrder.filter(grouped => grouped.price.val).length !== order.goodStorageGroupedOrder.length ||
                    order.goodStorageGroupedOrder.filter(grouped => grouped.counterpartQuantity).length !== order.goodStorageGroupedOrder.length
                ) {
                    this.props.showError('Необходимо заполнить цены и количества товаров в таблице');
                    return;
                }

                if (!order.deliveryDate) {
                    this.handleOpenDialogDeliveryDate();
                    return;
                }

                this.setState({
                    dialogCounterpartOpen: true,
                    counterpartBill: null,
                    counterpartComment: null,
                });
                break;

            case orderStatuses.counterpart_ok:
                this.setState({
                    dialogPayOpen: true,

                    payDataTypes: [],
                    payDataAmountPre: {...emptyMoney},
                    payDataAmountDelay: {...emptyMoney},
                    payDataAmountTotal: this.state.order.amountTotal || {...emptyMoney},
                    payDataPayDelay: null,
                    payDataComment: null,
                });
                break;

            case orderStatuses.accounting_pending:
                this.setState({
                    dialogAccountingOpen: true,

                    accountingComment: null,
                    paymentOrder: null,
                });
                break;

            case orderStatuses.limit_overrated:
                acceptOrder({
                    id: this.state.order.id,
                }).then(response => {
                        if (response.success) {
                            this.setState({
                                order: processOrderAfterGet(response.data),
                            });
                            this.props.showInfo('Превышение лимита подтверждено.');
                        } else {
                            this.props.showMessage(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            default:
                this.props.showMessage('В разработке');
        }
    };


    handleCloseCounterpartDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogCounterpartOpen: false,
            });
            return;
        }

        acceptOrder({
            id: this.state.order.id,
            bill: this.state.counterpartBill ? this.state.counterpartBill.id : null,
            comment: this.state.counterpartComment,
            goodStorageGroupedOrder: this.state.order.goodStorageGroupedOrder.map(item => ({
                ...item,
                good: item.good.id,
                goodStorageGrouped: item.goodStorageGrouped.map(item => item.id),
            })),
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        order: processOrderAfterGet(response.data),
                        dialogCounterpartOpen: false,
                    });

                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleEdit = (editingGood, itemIdx)  => {
        this.setState({
            dialogEditOpen: true,
            editingGood,
            editingIndex: itemIdx,
        });
    };

    handleEditChange = (prop, field) => event => {
        const value = event.target.value;

        if (field) {
            this.setState({
                editingGood: {
                    ...this.state.editingGood,
                    [prop]: {
                        ...this.state.editingGood[prop],
                        [field]: value,
                    },
                }
            });
        } else {
            this.setState({
                editingGood: {
                    ...this.state.editingGood,
                    [prop]: value,
                }
            });
        }
    };

    handleCloseEditDialog = ok => () => {
        const { editingIndex, editingGood, order } = this.state;

        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }


        let goodStorageGroupedOrder = [...order.goodStorageGroupedOrder];
        goodStorageGroupedOrder[editingIndex] = {
            ...editingGood,
            priceFormat: formatMoney(editingGood.price),
        };

        this.setState({
            order: processOrderAfterGet({
                ...order,
                goodStorageGroupedOrder
            }),
            dialogEditOpen: false,
        });
    };


    handleClosePayDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogPayOpen: false,
            });
            return;
        }

        acceptOrder({
            id: this.state.order.id,

            payTypes: this.state.payDataTypes,
            amountPrePay: this.state.payDataAmountPre,
            amountDelayPay: this.state.payDataAmountDelay,
            amountTotal: this.state.payDataAmountTotal,
            delayPeriod: this.state.payDataPayDelay,
            comment: this.state.payDataComment,
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        order: processOrderAfterGet(response.data),
                        dialogPayOpen: false,
                    });
                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleCloseAccountingDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogAccountingOpen: false,
            });
            return;
        }


        acceptOrder({
            id: this.state.order.id,
            comment: this.state.accountingComment,
            paymentOrder: this.state.paymentOrder ? this.state.paymentOrder.id : null,
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        order: processOrderAfterGet(response.data),
                        dialogAccountingOpen: false,
                    });

                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    openDeliveryDialog = () => {
        this.setState({
            deliveryDialogOpen: true
        });
    };

    closeDeliveryDialog = (ok, delivery) => {
        if (!ok) {
            this.setState({
                deliveryDialogOpen: false,
            });
            return;
        }

        setDeliveryOrder(this.state.order.id, {...delivery, receiptScanId: delivery.receiptScan ? delivery.receiptScan.id : null}).then(response => {
            if (response.success) {
                this.setState({
                    order: {
                        ...this.state.order,
                        delivery: response.data,
                    },
                    deliveryDialogOpen: false,
                });

                if (this.state.order.status === orderStatuses.delivery_awaiting) {
                    acceptOrder({id: this.state.order.id})
                        .then(response => {
                            if (response.success) {
                                this.setState({
                                    order: processOrderAfterGet(response.data),
                                });

                                this.props.showInfo('Изменения сохранены');
                            } else {
                                this.props.showMessage(response.error ? response.error.message : response.message);
                            }
                        });
                }
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        });
    };

    handleToDelivery = () => {
        acceptOrder({id: this.state.order.id})
            .then(response => {
                if (response.success) {
                    this.setState({
                        order: processOrderAfterGet(response.data),
                    });

                    this.props.showInfo('Заказ в доставке!');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleOpenDialogDeliveryDate = () => {
        this.setState({
            dialogDeliveryDateOpen: true
        });
    };

    handleCloseDeliveryDateDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogDeliveryDateOpen: false,
            });
            return;
        }

        setOrderDeliveryDate({
            orderId: this.state.order.id,
            newDeliveryDate: this.state.deliveryDate,
            comment: this.state.deliveryDateComment,

        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        order: {
                            ...this.state.order,
                            deliveryDate: this.state.deliveryDate
                        },
                        dialogDeliveryDateOpen: false,
                    });

                    this.props.showInfo('Изменения сохранены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleCloseCreateOrdersDialog = () => {
        this.setState({
            createOrdersDialogProps: {
                ...this.state.createOrdersDialogProps,
                dialogOrdersOpen: false
            }
        });
    };

    createOrdersFromRest = () => {
        getStorageOrderRest(this.state.order.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        createOrdersDialogProps: {
                            parentId: this.state.order.id,
                            grouped: response.data,
                            dialogOrdersOpen: true
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });

    };

    rowStyle = row => {
        if (!row.price.val) {
            return { background: '#ffb3b7'};
        }


        return null;
    };

    orderHasRest = () => {
        let rest = false;
        _.forEach(this.state.order.goodStorageGroupedOrder, function(goodStorageGroupedOrder) {
            if (goodStorageGroupedOrder.counterpartQuantity < goodStorageGroupedOrder.quantity) {
                rest = true;
                return false;
            }
        });

        return rest;
    };

    render() {
        const { token } = this.props;
        const { order, createOrdersDialogProps, deliveryDialogOpen } = this.state;

        const notAllowChangeDeliveryDate = [orderStatuses.new, orderStatuses.received, orderStatuses.main_rejected, orderStatuses.accounting_rejected, orderStatuses.counterpart_rejected, orderStatuses.delivery];

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <OrderLeftFields order={order}/>
                    <br/><br/>
                    <ActionButton visible={order.status === orderStatuses.delivery_awaiting} onClick={this.handleToDelivery}>Передать в доставку</ActionButton>
                    <ActionButton visible={order.status === orderStatuses.new} onClick={this.handleToCounterpart}>На согласование контрагенту</ActionButton>
                    <ActionButton
                        visible={[
                            orderStatuses.counterpart_pending,
                            orderStatuses.counterpart_ok,
                            orderStatuses.limit_overrated
                        ].filter(item => item === order.status).length > 0}
                        onClick={this.handleAccept}
                    >Подтвердить заказ</ActionButton>
                    <ActionButton
                        visible={[
                            orderStatuses.accounting_pending,
                        ].filter(item => item === order.status).length > 0}
                        onClick={this.handleAccept}
                    >Подтвердить оплату</ActionButton>
                    <ActionButton visible={order.status !== orderStatuses.new && order.status !== orderStatuses.counterpart_pending && order.status !== orderStatuses.received} onClick={this.openDeliveryDialog}>{ order.delivery.id ? 'Информация о доставке' : 'Применить доставку' }</ActionButton>
                    <ActionButton
                        visible={[
                            orderStatuses.counterpart_pending,
                            orderStatuses.counterpart_ok,
                            orderStatuses.accounting_pending,
                            orderStatuses.limit_overrated
                            //orderStatuses.delivery_awaiting, // тут по идее уже нельзя отклонить
                        ].filter(item => item === order.status).length > 0}
                        onClick={this.handleDecline}
                    >Отклонить заказ</ActionButton>
                    <ActionButton visible={ notAllowChangeDeliveryDate.indexOf(order.status) === -1 } onClick={this.handleOpenDialogDeliveryDate}>Изменить дату доставки</ActionButton>
                    <ActionButton visible={order.status !== orderStatuses.new} onClick={this.handleHistoryClick}>История изменения статусов заказа</ActionButton>
                    <ActionButton visible={order.status !== orderStatuses.new} onClick={this.handleAdditionalClick}>Дополнительная информация по заказу</ActionButton>
                    <ActionButton href={getOrderBlankUrl(order.id, {access_token: token.access_token, xls: 1})}>Выгрузить в Excel</ActionButton>
                    <ActionButton visible={this.orderHasRest()} onClick={this.createOrdersFromRest}>Создать заказ из остатков</ActionButton>
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>

                <Content title="Просмотр заказа">
                    <DataTableLocal
                        columnData={columnData}
                        data={order.goodStorageGroupedOrder}
                        selector={false}
                        pagination={false}
                        onClick={order.status === orderStatuses.counterpart_pending || order.status === orderStatuses.new ? this.handleEdit : null}
                        rowStyleFunc={order.status === orderStatuses.counterpart_pending ? this.rowStyle : null}
                    />
                </Content>
                {this.renderEditDialog()}
                {this.renderDeclineDialog()}
                {this.renderCounterpartDialog()}
                {this.renderPayDialog()}
                {this.renderAccountingDialog()}
                {this.renderDeliveryDateDialog()}
                { createOrdersDialogProps.dialogOrdersOpen && <CreateOrdersDialog {...createOrdersDialogProps} onClose={this.handleCloseCreateOrdersDialog} /> }
                <DeliveryDialog open={deliveryDialogOpen} onClose={this.closeDeliveryDialog} delivery={order.delivery} businessUnit={order.parlour} />
            </React.Fragment>
        );
    }

    renderDeclineDialog() {
        const { dialogDeclineOpen, declineComment } = this.state;
        return (
            <Dialog
                open={dialogDeclineOpen}
                onClose={this.handleCloseDeclineDialog(false)}
            >
                <DialogTitle>Отклонение заказа</DialogTitle>
                <DialogContent>
                    Вы уверены, что хотите отклонить заказ?
                    <AnyField
                        id="declineComment"
                        label="Укажите комментарий"
                        value={declineComment}
                        onChange={this.handleChange('declineComment')}
                        multiline
                        rows={4}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDeclineDialog(true)}  color="primary">Да</Button>
                    <Button onClick={this.handleCloseDeclineDialog(false)} color="primary">Нет</Button>
                </DialogActions>
            </Dialog>
        );
    }


    renderEditDialog() {
        const { classes } = this.props;
        const { dialogEditOpen, editingGood, order } = this.state;
        const currencies = [order.parlour.mainCurrency];
        return (
            <Dialog
                open={dialogEditOpen}
                onClose={this.handleCloseEditDialog(false)}
            >
                <DialogTitle>Редактирование позиции</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Группа товара"
                                value={editingGood.good.category.name}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Наименование товара"
                                value={editingGood.good.name}
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Описание товара"
                                value={editingGood.good.description}
                                readOnly
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Количество товара"
                                value={editingGood.good.massFormat}
                                readOnly
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MoneyField
                                label="Цена покупки"
                                currencies={ currencies }
                                value={ editingGood.price }
                                onChange={this.handleEditChange('price')}
                                fullWidth
                                autoFocus
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Доступное количество"
                                value={editingGood.counterpartQuantity}
                                fullWidth
                                onChange={this.handleEditChange('counterpartQuantity')}
                                InputProps={{
                                    inputComponent: NumberFormatCount
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Количество"
                                value={editingGood.quantity}
                                fullWidth
                                onChange={this.handleEditChange('quantity')}
                                InputProps={{
                                    inputComponent: NumberFormatCount
                                }}
                                required
                                readOnly
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseEditDialog(true)} disabled={!parseInt(editingGood.quantity, 10) || !editingGood.price.val}  color="primary">OK</Button>
                    <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderCounterpartDialog() {
        const { dialogCounterpartOpen, counterpartBill, counterpartComment } = this.state;

        return (
            <Dialog
                open={dialogCounterpartOpen}
                onClose={this.handleCloseCounterpartDialog(false)}
            >
                <DialogTitle>Подтверждение контрагентом</DialogTitle>
                <DialogContent style={{width: 360}}>
                    <UploadFilesField
                        id="counterpartBill"
                        label="Счет"
                        value={counterpartBill}
                        onChange={this.handleChange('counterpartBill')}
                    />
                    <AnyField
                        id="con"
                        label="Комментарий"
                        value={counterpartComment}
                        onChange={this.handleChange('counterpartComment')}
                        multiline
                        rows={4}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseCounterpartDialog(true)} disabled={!counterpartBill} color="primary">OK</Button>
                    <Button onClick={this.handleCloseCounterpartDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderPayDialog() {
        const { classes } = this.props;
        const { dialogPayOpen, payDataTypes, payDelays, payDataAmountPre, payDataAmountDelay, payDataAmountTotal, payDataPayDelay, payDataComment, order } = this.state;
        const currencies = [order.parlour.mainCurrency];

        return (
            <Dialog
                open={dialogPayOpen}
                onClose={this.handleClosePayDialog(false)}
                aria-labelledby="pay-dialog-title"
            >
                <DialogTitle id="pay-dialog-title">Подтверждение заказа</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Тип оплаты"
                                id="payTypes"
                                value={payDataTypes || []}
                                onChange={this.handleChange('payDataTypes')}
                                select
                                SelectProps={{
                                    multiple: true,
                                    renderValue: selected => selected.map(type => getPayTypeText(type)).join(', '),
                                }}
                                fullWidth
                            >
                                {Object.keys(payTypes).map(type =>
                                    <MenuItem key={type} value={type}>
                                        <Checkbox checked={payDataTypes.indexOf(type) > -1} />
                                        <ListItemText primary={getPayTypeText(type)} />
                                    </MenuItem>
                                )}
                            </AnyField>
                        </Grid>
                        {payDataTypes.indexOf(payTypes.pre_pay) >= 0 ?
                            <Grid item xs={12}>
                                <MoneyField
                                    label="Сумма предоплаты"
                                    currencies={ currencies }
                                    value={ payDataAmountPre }
                                    onChange={this.handleChange('payDataAmountPre')}
                                    fullWidth
                                />
                            </Grid>
                            : null
                        }
                        {payDataTypes.indexOf(payTypes.delay_pay) >= 0 ?
                            <Grid item xs={12}>
                                <MoneyField
                                    currencies={ currencies }
                                    label="Сумма отсроченного платежа"
                                    value={ payDataAmountDelay }
                                    onChange={this.handleChange('payDataAmountDelay')}
                                    fullWidth
                                />
                            </Grid>
                            : null
                        }
                        <Grid item xs={12}>
                            <MoneyField
                                currencies={ currencies }
                                label="Общая сумма"
                                value={ payDataAmountTotal }
                                onChange={this.handleChange('payDataAmountTotal')}
                                fullWidth
                            />
                        </Grid>
                        {payDataTypes.indexOf(payTypes.delay_pay) >= 0 ?
                            <Grid item xs={12}>
                                <AnyField
                                    id="payDelay"
                                    value={payDataPayDelay}
                                    onChange={this.handleChange('payDataPayDelay')}
                                    fullWidth
                                    select
                                    label="Условия отсрочки">
                                    {payDelays.map(payDelay =>
                                        <MenuItem key={payDelay.id} value={payDelay.id}>
                                            {payDelay.name}
                                        </MenuItem>
                                    )}
                                </AnyField>
                            </Grid>
                            : null
                        }
                        <Grid item xs={12}>
                            <AnyField
                                id="comment"
                                label="Комментарий"
                                value={payDataComment}
                                onChange={this.handleChange('payDataComment')}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClosePayDialog(true)} disabled={!payDataTypes.length || (payDataTypes.indexOf(payTypes.delay_pay) >= 0 && !payDataPayDelay) } color="primary">OK</Button>
                    <Button onClick={this.handleClosePayDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderAccountingDialog() {
        const { dialogAccountingOpen, accountingComment, paymentOrder} = this.state;

        return (
            <Dialog
                open={dialogAccountingOpen}
                onClose={this.handleCloseAccountingDialog(false)}
            >
                <DialogTitle>Подтверждение оплаты</DialogTitle>
                <DialogContent style={{width: 360}}>
                    <UploadFilesField
                        label="Платежное поручение"
                        id="paymentOrder"
                        value={paymentOrder}
                        onChange={this.handleChange('paymentOrder')}

                    />
                    <AnyField
                        label="Комментарий"
                        value={accountingComment}
                        onChange={this.handleChange('accountingComment')}
                        multiline
                        rows={4}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseAccountingDialog(true)} color="primary">OK</Button>
                    <Button onClick={this.handleCloseAccountingDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>

        );
    }

    renderDeliveryDateDialog() {
        const { deliveryDateComment, deliveryDate, dialogDeliveryDateOpen } = this.state;

        return (
            <Dialog
                open={dialogDeliveryDateOpen}
                onClose={this.handleCloseCounterpartDialog(false)}
            >
                <DialogTitle>Изменить дату поставки</DialogTitle>
                <DialogContent style={{width: 360}}>
                    <DateField
                        value={deliveryDate}
                        label="Дата поставки"
                        onChange={this.handleChangeDate('deliveryDate')}
                        disablePast={true}
                        fullWidth
                    />
                    <AnyField
                        id="con"
                        label="Комментарий"
                        value={deliveryDateComment}
                        onChange={this.handleChange('deliveryDateComment')}
                        multiline
                        rows={4}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDeliveryDateDialog(true)} disabled={!deliveryDate} color="primary">OK</Button>
                    <Button onClick={this.handleCloseDeliveryDateDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}


const mapStateToProps = state => ({
    token: state.auth.token,
});

export default connect(mapStateToProps, {showInfo: infoActions.show, showError: infoActions.showError, showMessage: messageDialogActions.show, showConfirm: confirmDialogActions.show})(withStyles(styles)(OrderViewPage));
