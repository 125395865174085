import React from 'react';
import { connect } from "react-redux";
import infoActions from "../../components/info/info-actions";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles} from "@material-ui/core";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getGoodPurposes,
    getGoodStorageForRevision,
    navLinks,
    sendRevision
} from "../../services/goods";
import Content from "../../components/content/Content";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {withRouter} from "react-router";
import {getBusinessUnitByRole} from "../../services/user";
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import ActionButton from "../../components/button/ActionButton";
import MaterialTable from "material-table";
import {getFalseResult} from "../../services/common";
import _ from "lodash";
import XLSX from "xlsx";


const styles = theme => ({
    textField: {
        marginTop:  theme.spacing(3),
    },
    dialogContent: {
        paddingTop:  theme.spacing(2),
        minWidth: 600,
    },
});

export default
@withRouter
@withTranslation()
@withStyles(styles)
@connect(state => ({

}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class CheckPage extends React.Component
{
    state = {
        search: {
            search: null,
            businessUnitId: null,
            purpose: null
        },

        goods: [],
        selectedGoods: [],
        purposes: [],

        dialogOpen: false,
        comment: null,

        businessUnits: [],
    };

    columnDataXlsx = [
        { id: 'name', label: 'Товар'},
        { id: 'categoryName', label: 'Категория'},
        { id: 'businessUnitName', label: 'Салон'},
        { id: 'purposeText', label: 'Предназначение'},
        { id: 'expendable', label: 'Расходный товар'},
        { id: 'restFormatted', label: 'Остаток'},
    ];

    componentDidMount() {
        getBusinessUnitByRole('ROLE_GOOD_GOODSTORAGE_CHECK')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                        search: {
                            ...this.state.search,
                        }
                    })
                }
            });

        getGoodPurposes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        purposes: response.data,
                    })
                }
            });

        this.getTableData();
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleChangeSearch = (event) => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value
            }
        }, this.getTableData);
    };

    getTableData = () => {
        const {search} = this.state;

        if (!search.businessUnitId) {
            return getFalseResult();
        }

        getGoodStorageForRevision(search, -1, -1)
            .then(response => {
                if (response.success) {
                        this.setState({
                            goods: response.data
                        });
                }
            })
    };

    handleExport = () => {
        const { search, businessUnits } = this.state;
        const { t } = this.props;
        const bu = search.businessUnitId ? businessUnits.find(item => item.id === search.businessUnitId).name : '';

        getGoodStorageForRevision(search, -1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => {
                                const value = _.get(row, item.id);
                                if (item.processValue) {
                                    return item.processValue(value, t);
                                }
                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Inventory");
                    XLSX.writeFile(new_workbook, `Inventory ${bu}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleCloseDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogOpen: false,
            });
            return;
        }

        sendRevision({
            businessUnitId: this.state.search.businessUnitId,
            purpose: this.state.search.purpose,
            comment: this.state.comment,
            goods: this.state.selectedGoods
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogOpen: false,
                        goods: response.data,
                        selectedGoods: []
                    });
                    this.props.showMessage(`Товары списаны.`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleSendReport = () => {
        this.setState({
            dialogOpen: true,
            comment: null,
        })
    };

    onCellEditApproved = (newValue, oldValue, rowData, columnDef) => {
        return new Promise((resolve) => {
            let goods = [...this.state.goods];
            goods[rowData.tableData.id][columnDef.field] = columnDef.field === 'consumption' ? this.processConsumptionValue(newValue) : newValue;
            let selectedGoods = goods.filter(good => !!good.consumption);
            this.setState({
                goods: goods,
                selectedGoods: selectedGoods
            }, resolve)
        });
    }

    processConsumptionValue = (value) => parseFloat(value.replace(',', '.'))

    cellEditableObj = { onCellEditApproved: this.onCellEditApproved };

    tableOptions = {
        sorting: true,
        showTitle: false,
        paging: false,
        padding: 'dense'
    };

    tableLocalizationOptions = {
        toolbar: {
            searchTooltip: this.props.t('Search'),
            searchPlaceholder: this.props.t('Search'),
        },
    };

    render() {
        const { search, businessUnits, goods, selectedGoods, purposes } = this.state;
        const { t } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SelectFromItemsField
                        items={businessUnits}
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        name="businessUnitId"
                        onChange={this.handleChangeSearch}
                        nullableText={'Не выбрано'}
                        nullable
                        fullWidth
                    />
                    <SelectFromItemsField
                        items={purposes}
                        label={t('Purpose')}
                        value={search.purpose}
                        name="purpose"
                        onChange={this.handleChangeSearch}
                        nullableText={'Не выбрано'}
                        valueField="id"
                        textField="text"
                        nullable
                        fullWidth
                    />
                    <ActionButton onClick={this.handleSendReport} disabled={!selectedGoods.length}>Списать</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>

                <Content title="Инвентаризация товаров">
                    <MaterialTable
                        columns={[
                            {field: 'name', title: 'Товар', editable: 'never'},
                            {field: 'categoryName', title: 'Категория', editable: 'never'},
                            {field: 'businessUnitName', title: 'Салон', editable: 'never'},
                            {field: 'purposeText', title: 'Предназначение', editable: 'never'},
                            {field: 'expendable', title: 'Расходный товар', editable: 'never', type: 'boolean'},
                            {field: 'restFormatted', title: 'Остаток', editable: 'never'},
                            {
                                field: 'consumption', title: 'Списать',
                            },
                            {
                                field: 'comment', title: 'Комментарий', initialEditValue: ''
                            }
                        ]}
                        data={goods}
                        cellEditable={this.cellEditableObj}
                        options={this.tableOptions}
                        localization={this.tableLocalizationOptions}
                    />
                </Content>
                { selectedGoods.length && this.renderDialog()}
            </React.Fragment>
        );
    }

    renderDialog() {
        const { classes } = this.props;
        const { dialogOpen, comment, selectedGoods } = this.state;
        return (
            <Dialog
                open={dialogOpen}
                onClose={this.handleCloseDialog(false)}
            >
                <DialogTitle>Списать товары</DialogTitle>
                <DialogContent>
                    Вы уверены, что хотите списать эти товары?
                    <ul>
                        { selectedGoods.map( (good, index) => <li key={index}>{good.name} {good.restFormatted} - {good.consumption}</li>) }
                    </ul>
                    <AnyField
                        label="Укажите комментарий"
                        value={comment}
                        onChange={this.handleChange('comment')}
                        multiline
                        rows={4}
                        fullWidth
                        className={classes.textField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialog(true)}  color="primary">Списать</Button>
                    <Button onClick={this.handleCloseDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
