import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import {
    getPriceById,
    updatePrice,
    createPrice,
    priceStatusName,
    priceStatus, fillPrice
} from "../../services/facilities";
import ShowField from "../../components/fields/ShowField";
import infoActions from "../../components/info/info-actions";
import {withRouter} from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import NumberFormatCurrency from "../../components/fields/NumberFormatCurrency";
import {emptyMoney, getCurrencies} from "../../services/common";
import {createChangeHandle} from "../../utils/helpers";
import MoneyField from "../../components/fields/MoneyField";


const styles = theme => {
    return {
        container: {
          maxWidth: 600,
        },
        title: {
            margin: `${ theme.spacing(4)}px 0 ${ theme.spacing(2)}px`,
        },
        fillDialog: {
            width: 300,
        },
    }
};

export default
@withRouter
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
class ViewPrice extends React.Component
{
    state = {
        price: {
            id: null,
            name: null,
            status: null,
            code: null,
            parloursPrice1: [],
            parloursPrice2: [],
            facilities: [],
            priceCopiedId: null,
            hourCost: {...emptyMoney},
        },
        editing: false,
        currencies: [],

        fillDialogOpen: false,
        hourPrice: 0,
    };

    componentDidMount() {
        this.init(this.props.location.pathname);
        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data.map(currency => currency.id),
                    });
                }
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.init(nextProps.location.pathname);
        }
    }

    init (path) {
        const reId = new RegExp('/settings/prices/(view|edit|copy)/([^/]+)$');
        const resultId = reId.exec(path);

        if (!Array.isArray(resultId) || resultId.length !== 3) {
            //Error request
            return;
        }

        this.setState({
            editing: resultId[1] === 'edit' || resultId[1] === 'copy',
        })

        if (resultId[2] && resultId[2] !== '0') {
            getPriceById(resultId[2])
                .then( response => {
                    if (response.success){

                        if (resultId[1] === 'copy') {
                            response.data = {
                                ...response.data,
                                id: null,
                                code: null,
                                status: priceStatus['draft'],
                                statusText: priceStatusName['draft'],
                                parloursPrice2: [],
                                parloursPrice1: [],
                                name: response.data.name + ' (копия)',
                                priceCopiedId: resultId[2],
                                hourCost: {...emptyMoney},
                            };
                        }

                        this.setState({
                            price: response.data,
                        })
                    }
                })
        }
    }

    handleChange = createChangeHandle(this, 'price')

    handleSave = () => {
        const { price } = this.state;

        if (price.id) {
            updatePrice(price)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/settings/prices/view/${response.data.id}`);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        } else {
            createPrice(price)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/settings/prices/view/${response.data.id}`);

                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        }
    }

    handleUpdateStatus = (status) => () => {
        const { price } = this.state;
        price.status = status;

        updatePrice(price)
            .then(response => {
                if (response.success) {
                    this.setState({
                        price: response.data,
                    });
                    this.props.showInfo('Статус изменен');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    renderLeftFields() {
        const { price } = this.state;

        return (
            <React.Fragment>
                {price.code ?
                    <ShowField
                        label="Идентификатор прайса"
                        value={price.code}
                    />
                    : null
                }
                {price.name ?
                    <ShowField
                        label="Название прайса"
                        value={price.name}
                    />
                    : null
                }
                {price.status && price.status ?
                    <ShowField
                        label="Статус"
                        value={priceStatusName[price.status]}
                    />
                    : null
                }
            </React.Fragment>
        );
    }

    handleChangeHourPrice = event => {
        this.setState({
            hourPrice: event.target.value,
        });
    };

    handleCloseFillDialog = ok => () => {
        if (!ok) {
            this.setState({
                fillDialogOpen: false,
            });
            return;
        }

        const { price, hourPrice } = this.state;

        fillPrice({id: price.id, hourPrice })
            .then(response => {
                if (response.success) {
                    this.setState({
                        price: response.data,
                        fillDialogOpen: false,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    render() {
        const { price, editing, fillDialogOpen, hourPrice, currencies } = this.state;
        const { classes } = this.props;

        let parlours = price.parloursPrice1.concat(price.parloursPrice2);

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    { this.renderLeftFields() }
                    <ActionButton onClick={this.handleSave} visible={editing}>Сохранить</ActionButton>
                    <ActionButton onClick={() => this.props.history.push(`/settings/prices/edit/${price.id}`)} visible={!editing}>Редактировать</ActionButton>
                    <ActionButton
                        onClick={this.handleUpdateStatus(priceStatus.active)}
                        visible={price.status === priceStatus.draft}
                    >
                        Опубликовать
                    </ActionButton>
                    <ActionButton
                        onClick={this.handleUpdateStatus(priceStatus.inactive)}
                        visible={price.status === priceStatus.active}
                    >
                        Отключить
                    </ActionButton>
                    <ActionButton
                        onClick={this.handleUpdateStatus(priceStatus.active)}
                        visible={price.status === priceStatus.inactive}
                    >
                        Активировать
                    </ActionButton>
                    <ActionButton
                        onClick={() => {this.props.history.push(`/settings/prices/copy/${price.id}`)}}
                        visible={!editing}
                    >
                        Копировать
                    </ActionButton>
                    <ActionButton
                        onClick={() => this.setState({fillDialogOpen: true})}
                        visible={Boolean(price.id)}
                    >Заполнить цены</ActionButton>
                    <ActionButton onClick={() => { price.id && editing ?
                        this.props.history.push(`/settings/prices/view/${price.id}`)
                        : this.props.history.push(`/settings/prices`)
                    }}>Назад</ActionButton>
                </LeftBar>
                <Content title="Прайс">
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <AnyField
                                value={price.name}
                                name="name"
                                fullWidth
                                onChange={this.handleChange}
                                label="Название прайса"
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MoneyField
                                value={price.hourCost}
                                name="hourCost"
                                fullWidth
                                onChange={this.handleChange}
                                label="Стоимость часа"
                                readOnly={!editing}
                                currencies={currencies}
                            />
                        </Grid>
                        {parlours && Array.isArray(parlours) && parlours.length ?
                            <Grid item xs={4}>
                                <Typography variant="h6" className={classes.title}>Салоны</Typography>
                                <List>
                                    {parlours.map(value => (
                                        <ListItem key={value.id} button>
                                            <ListItemText primary={value.name}/>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            : null
                        }
                        {price.facilities && Array.isArray(price.facilities) && price.facilities.length ?
                            <Grid item xs={8}>
                                <Typography variant="h6" className={classes.title}>Услуги</Typography>
                                <List>
                                    {price.facilities.map(value => (
                                        <ListItem key={value.id} button>
                                            <ListItemText primary={value.name}/>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            :null
                        }
                    </Grid>
                </Content>
                <Dialog
                    open={fillDialogOpen}
                >
                    <DialogTitle>Заполнение прайса</DialogTitle>
                    <DialogContent className={classes.fillDialog}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Внимание!</Typography>
                                Все цены для данного прайса будут заменены на вновь рассчитанные, данную операцию отменить нельзя
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Стоимость одного часа"
                                    value={hourPrice}
                                    onChange={this.handleChangeHourPrice}
                                    InputProps={{
                                        inputComponent: NumberFormatCurrency,
                                        // TODO: Валюта прайса
                                        // endAdornment: <SymbolRub/>,
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseFillDialog(true)} disabled={!hourPrice} color="primary">OK</Button>
                        <Button onClick={this.handleCloseFillDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

