import io from "socket.io-client";
import getStore from "../store";
import socketActions from "./socket-actions";

class Socket {

    constructor() {
        this.socket = null;
    }

    connect() {
        const auth = getStore().getState().auth;
        if (auth.token && auth.token.access_token && !this.socket) {
            this.socket = io.connect('', {path: '/ws', query: { token: auth.token.access_token, userId: auth.user ? auth.user.id : null } });

            this.socket.on('connect', () => {
                getStore().dispatch(socketActions.connected());

                auth.socket = this.socket.id;
            });

            this.socket.on('reconnect', () => {

            });

            this.socket.on('message', message => {
                try {
                    getStore().dispatch(socketActions.receiveMessage(JSON.parse(message)));
                } catch (e) {
                    console.log(e);
                }
            });

        }
    }
}

const socket = new Socket();

export default socket;
