import React, {useCallback, useContext} from 'react';
import {useTranslation} from "react-i18next";
import ActionButton from "../../../components/button/ActionButton";
import {processMomentFieldInObject} from "../../../utils/moment-utils";
import {pushFilterToQuery} from "../../../history";
import Context from "./Context";

const RefreshButton = () => {
    const { t } = useTranslation();
    const { search, setRefreshTable, isValidPeriod, isValidBusinessUnits } = useContext(Context);

    const handleClick = useCallback(() => {
        pushFilterToQuery(processMomentFieldInObject(search, ['dayStart', 'dayEnd', 'month']));

        setRefreshTable(prevState => !prevState);

    }, [search, setRefreshTable]);

    return (
        <ActionButton
            onClick={handleClick}
            disabled={!isValidPeriod || !isValidBusinessUnits}
        >
            {t('Refresh')}
        </ActionButton>
    );
};

export default RefreshButton;