import React from 'react';
import * as PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Paper,
    Checkbox,
    IconButton
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import DataTableHead from "./DataTableHead";
import DataTableCell from "./DataTableCell";
import {loadTlDataTableParams, saveTlDataTableParams} from "../../services/localStorage";
import memoize from 'lodash/memoize';
import isEqual from 'lodash/isEqual';
import DataTableSummary from "./DataTableSummary";
import {withTranslation} from "react-i18next";
import _ from 'lodash';
import TableContainer from "@material-ui/core/TableContainer";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    table: {
        borderColor: theme.palette.grey[400],
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableRow: {
        height: 32,
    },
    checkbox: {
        height: 32,
    },
    borderBottom: {
        borderBottomColor: theme.palette.grey[400],
    },
    stickyLeftColumn: {
        position: "sticky",
        left: 0,
        background: "white"
    },
    stickyContainer: {
        height: '80vh'
    }
});

@withTranslation()
class DataTableLocal extends React.PureComponent {

    constructor(props) {
        super(props);

        const params = loadTlDataTableParams();

        this.state = {
            order: props.order ? props.order : 'asc',
            orderBy: props.orderBy ? props.orderBy : props.columnData[0].id,
            selected: props.selected ? props.selected : [],
            page: 0,
            rowsPerPage: params && params.rows ? params.rows : 30,
            total: props.data.length,
            filteredData: []
        };
    }

    componentDidMount() {
        this.updateFilteredData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.selected, this.props.selected)) {
            this.setState({selected: this.props.selected});
        }
        if (prevProps.data !== this.props.data ||
            prevState.order !== this.state.order ||
            prevState.orderBy !== this.state.orderBy ||
            prevState.page !== this.state.page ||
            prevState.rowsPerPage !== this.state.rowsPerPage
        ) {
            this.updateFilteredData()
        }

        if (prevProps.data?.length !== this.props.data?.length) {
            this.setState({
                total: this.props.data?.length || 0
            })
        }
    }

    updateFilteredData() {
        const {page, rowsPerPage, order, orderBy} = this.state
        const {disableSorting, pagination, data, summary} = this.props

        let filteredData = data?.map((item, _sourceItemIdx) => ({...item, _sourceItemIdx})) || [];

        if (!disableSorting) {
            filteredData.sort(this.getSorting(order, orderBy));
        }

        if (pagination) {
            filteredData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }

        if (summary) {
            filteredData.map((row) => {
                row.summary = _.sumBy(Object.values(row), r => parseInt(r) ? r : 0) - row._sourceItemIdx;
                return row;
            });
        }

        this.setState({
            filteredData
        })
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = this.state.order;

        if (this.state.orderBy === property) {
            order = order === 'desc' ? 'asc' : 'desc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = () => {
        const {selected} = this.state;
        let newSelected = [];
        if (!(selected && selected.length)) {
            newSelected = [...this.props.data];
        }
        this.setState({selected: newSelected});
        if (this.props.onSelect) this.props.onSelect(newSelected);
    };

    handleClick = memoize((item) => () => {
        const {selector} = this.props;
        const {selected} = this.state;

        if (!selector) {
            return;
        }

        const selectedIndex = selected.findIndex(sel => sel.id === item.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});

        if (this.props.onSelect) this.props.onSelect(newSelected);
    });

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
        saveTlDataTableParams({rows: event.target.value});
    };

    handleDelete = (item, itemIdx) => () => {
        this.props.onDelete(item, itemIdx);
        this.updateFilteredData();
    };

    isSelected = item => this.state.selected.findIndex(sel => sel.id === item.id) !== -1;


    sortCompare = (a, b) => {
        if (a < b) {
            return -1
        }

        if (a > b) {
            return 1
        }

        return 0
    }

    getSorting = (order, orderBy) => {
        const {columnData} = this.props
        const getSortValue = columnData?.find(col => col.id === orderBy)?.getSortValue

        if (getSortValue) {
            if (order === 'desc') {
                return (a, b) => this.sortCompare(getSortValue(b[orderBy]), getSortValue(a[orderBy]))
            }
            return (a, b) => this.sortCompare(getSortValue(a[orderBy]), getSortValue(b[orderBy]))
        }

        if (order === 'desc') {
            return (a, b) => this.sortCompare(b[orderBy], a[orderBy])
        }
        return (a, b) => this.sortCompare(a[orderBy], b[orderBy])
    }

    getColumnData() {
        const {summary, t} = this.props;
        let {columnData} = this.props;

        if (summary) {
            columnData = [...columnData, {
                id: 'summary',
                label: t('Summary'),
                width: 100,
            }];
        }

        return columnData;
    }

    render() {
        const {
            classes,
            selector,
            linkTemplate,
            data,
            onClick,
            pagination,
            rowStyleFunc,
            cellStyleFunc,
            onDelete,
            disableSorting,
            summary,
            stickyHeader,
            stickyLeftColumn,
            height,
            tableStyle
        } = this.props;
        const {order, orderBy, selected, rowsPerPage, page, total, filteredData} = this.state;
        const emptyRows = pagination ? Math.min(10 - Math.min(rowsPerPage, total - page * rowsPerPage), 0) : 0;
        const columnData = this.getColumnData();

        return (
            <Paper className={classes.root} square>
                <div className={classes.tableWrapper}>
                    <TableContainer className={stickyHeader ? classes.stickyContainer : ''} style={{height:height}}>
                        <Table className={classes.table} aria-labelledby="tableTitle" stickyHeader={stickyHeader} style={tableStyle}>
                            <DataTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleRequestSort}
                                rowCount={data.length}
                                columnData={columnData}
                                selector={selector}
                                deleteColumn={!!onDelete}
                                disableSorting={disableSorting}
                                sticky
                                stickyFirstHeader={stickyLeftColumn}
                            />
                            <TableBody>
                                {filteredData
                                    .map((item, itemIdx) => {
                                        const isSelected = this.isSelected(item);
                                        const style = rowStyleFunc ? rowStyleFunc(item, itemIdx) : null;
                                        return (
                                            <TableRow
                                                hover
                                                onClick={this.handleClick(item)}
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={item._sourceItemIdx}
                                                selected={isSelected}
                                                className={classes.tableRow}
                                                style={style}
                                            >
                                                {selector ?
                                                    <TableCell padding="checkbox" className={classes.borderBottom}>
                                                        <Checkbox checked={isSelected} className={classes.checkbox}/>
                                                    </TableCell>
                                                    : null
                                                }
                                                {onDelete ?
                                                    <TableCell padding="checkbox" className={classes.borderBottom}>
                                                        <IconButton onClick={this.handleDelete(item, itemIdx)}
                                                                    size="small"><Delete fontSize="small"/></IconButton>
                                                    </TableCell>
                                                    : null
                                                }
                                                {columnData.map((col, idx) =>
                                                    <DataTableCell
                                                        className={stickyLeftColumn && idx === 0 ? classes.stickyLeftColumn : ''}
                                                        key={idx}
                                                        idx={idx}
                                                        col={col}
                                                        itemRow={item}
                                                        itemIdx={itemIdx}
                                                        linkTemplate={linkTemplate}
                                                        onClick={onClick}
                                                        cellStyleFunc={cellStyleFunc}
                                                    />)}
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 49 * emptyRows}}>
                                        <TableCell colSpan={columnData.length}/>
                                    </TableRow>
                                )}
                                {summary ? <DataTableSummary columnData={columnData}
                                                             stickyLeftColumn={stickyLeftColumn}
                                                             filteredData={filteredData}></DataTableSummary> : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {pagination ?
                    <TablePagination
                        component="div"
                        labelRowsPerPage="Записей на странице:"
                        labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Предыдущая страница',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Следующая страница',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 30, 50, 100, 150]}
                    />
                    :
                    null
                }
            </Paper>
        );
    }
}

DataTableLocal.propTypes = {
    columnData: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    data: PropTypes.array,
    onSelect: PropTypes.func,
    refresh: PropTypes.bool,
    selector: PropTypes.bool,
    onTotalChange: PropTypes.func,
    selected: PropTypes.array,
    onClick: PropTypes.func,
    pagination: PropTypes.bool,
    rowStyleFunc: PropTypes.func,
    onDelete: PropTypes.func,
    disableSorting: PropTypes.bool,
    linkTemplate: PropTypes.func,
    stickyHeader: PropTypes.bool,
    stickyLeftColumn: PropTypes.bool,
    height: PropTypes.string,
    processValue: PropTypes.func,
    cellStyleFunc: PropTypes.func,
    tableStyle: PropTypes.object
};

DataTableLocal.defaultProps = {
    refresh: false,
    selector: true,
    pagination: true,
    summary: false,
    disableSorting: false,
    stickyHeader: false,
    stickyLeftColumn: false,
    height: '',
    cellStyleFunc: f => f,
    tableStyle: {}
};

export default withStyles(styles)(DataTableLocal);
