import React from "react";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import { connect } from "react-redux";
import {
    navLinks,
    getDeal, getTransactions, removeDeal, updateDeal, emptyDeal, getSpendingTypes, isSpendingDeal,
} from "../../services/moneyFlow";
import {
    getInvestors,
    getHumanEmployees,
    getExternalSubjects
} from "../../services/organization";
import AnyField from "../../components/fields/AnyField";
import { Grid } from "@material-ui/core";
import infoActions from "../../components/info/info-actions";
import PageTitle from "../../components/typography/PageTitle";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import { withRouter } from "react-router";
import MoneyField from "../../components/fields/MoneyField";
import moment from "moment";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import { withTranslation } from "react-i18next";
import DateMonthField from "../../components/fields/DateMonthField";
import DateTimeField2 from "../../components/fields/DateTimeField2";
import { hasRole } from "../../services/user";
import { loadToState } from "../../utils/helpers";
import DealViewTransactionEditDialog from "./DealViewTransactionEditDialog";
import { cloneDeep } from "lodash";
import { formatMoney } from "../../services/common";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";

const columnData = [
    { id: "createdAt", label: "Date", dateFormat: "DD.MM.YYYY HH:mm:ss" },
    { id: "reasonClass", label: "Deal (entity)" },
    { id: "reasonName", label: "Deal name" },
    { id: "amountFormat", label: "Amount" },
    { id: "from.parentClassText", label: "From (entity)" },
    { id: "fromParentName", label: "From name" },
    { id: "from.typeText", label: "From (bill)" },
    { id: "to.parentClassText", label: "To (entity)" },
    { id: "toParentName", label: "To name" },
    { id: "to.typeText", label: "To (bill)" },
    { id: "name", label: "Name" },
    { id: "statusText", label: "Status" },
    { id: "businessUnitName", label: "Business unit" },
    { id: "creator.username", label: "User" },
    { id: "comment", label: "Comment" },
    { id: "id", label: "ID" },
];

export default @withRouter
@connect(state => ({
    currentUser: state.auth.user,
}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
})
@withTranslation()
class DealViewPage extends React.Component {
    state = {
        deal: emptyDeal,
        editing: false,
        spendingTypes: [],
        transactions: [],
        dialogEditTransactionData: null,
        investors: [],
        employees: [],
        externalSubjects: []
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.init();
        }
    }

    init() {
        const path = this.props.location.pathname;
        const reId = new RegExp("/money-flow/deal/(id|edit)(?:/([^/]+))?$");
        const resultId = reId.exec(path);

        const editing = resultId[1] === "edit";

        this.setState({
            editing,
        });

        if (resultId.length && resultId[2] !== "0") {
            getDeal(resultId[2])
                .then(response => {
                    if (response.success) {
                        const deal = response.data;
                        this.setState({ deal });

                        loadToState(this, getTransactions({ dealId: deal.id }), "transactions");
                        loadToState(this, getInvestors(),"investors");
                        loadToState(this, getHumanEmployees(),"employees");
                        loadToState(this, getExternalSubjects(),"externalSubjects");

                        if (editing && isSpendingDeal(deal)) {
                            loadToState(
                                this,
                                getSpendingTypes({ showInactive: 1 }),
                                "spendingTypes",
                                (items) => items.map((st) => ({ value: st.id, label: st.name })),
                            );
                        }
                    }
                });
        }
    }

    handleRemove = () => {
        const { t } = this.props;
        this.props.showConfirm({
            message: t("This day balances and later will be recalculated"),
            title: t("Deal Deleting"),
            onClose: this.remove,
        });
    };

    handleEdit = () => {
        const { deal } = this.state;
        this.props.history.push(`/money-flow/deal/edit/${deal.id}`);
    };

    handleSave = () => {
        const { t } = this.props;
        this.props.showConfirm({
            message: t("This day balances and later will be recalculated"),
            title: t("Save"),
            onClose: this.save,
        });
    };


    handleCancelEdit = () => {
        const { deal } = this.state;

        this.props.history.push(`/money-flow/deal/id/${deal.id}`);
    };

    save = ok => {
        const { deal, transactions } = this.state;
        const { t } = this.props;

        if (!ok) {
            return;
        }

        const patchDealData = {
            id: deal.id,
            applyAt: deal.applyAt,
            createdAt: deal.createdAt,
            cancellationFact: deal.cancellationFact,
            amount: deal.amount,
            transactionsCreatedAt: deal.transactionsCreatedAt,
            reasonId: deal.reasonId,
            investorId: deal.investorId,
            transactions: transactions
                .filter(tr => tr.changed)
                .map(tr => ({
                    id: tr.id,
                    amount: tr.amount,
                    fromParentId: tr.from.parentId,
                    toParentId: tr.to.parentId,
                })),
        };

        updateDeal(patchDealData)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t("Saved"));
                    this.props.history.push(`/money-flow/deal/id/${deal.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    remove = ok => {
        const { t } = this.props;

        if (!ok) {
            return;
        }

        removeDeal(this.state.deal.id)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t("Deleted and recalculated"));
                    this.props.history.push(`/money-flow/deal/`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeDeal = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            deal: {
                ...this.state.deal,
                [prop]: value,
            },
        });
    };

    handleClickEditTransaction = (transaction, idx, e) => {
        e.preventDefault();
        const { editing, deal } = this.state;
        if (editing && isSpendingDeal(deal)) {
            this.setState({
                dialogEditTransactionData: cloneDeep(transaction),
            });
        }
    };

    handleCloseEditTransaction = () => {
        this.setState({
            dialogEditTransactionData: null,
        });
    };

    handleSubmitEditTransaction = (newTransaction) => {
        const transactions = cloneDeep(this.state.transactions);
        const idx = transactions.findIndex(_ => _.id === newTransaction.id);
        transactions[idx].amount = newTransaction.amount;
        transactions[idx].amountFormat = formatMoney(newTransaction.amount);
        transactions[idx].from.parentId = newTransaction.from.parentId;
        transactions[idx].fromParentName = newTransaction.fromParentName;
        transactions[idx].to.parentId = newTransaction.to.parentId;
        transactions[idx].toParentName = newTransaction.toParentName;
        transactions[idx].changed = true;
        this.setState({
            dialogEditTransactionData: null,
            transactions,
        });
    };

    render() {
        const { t, currentUser } = this.props;
        const {
            deal,
            transactions,
            editing,
            dialogEditTransactionData,
            spendingTypes ,
            investors,
            employees,
            externalSubjects
        } = this.state;
        const saveDisabled = !deal.createdAt || !deal.applyAt || !deal.transactionsCreatedAt;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton
                        onClick={this.handleEdit}
                        visible={!editing}
                        disabled={!hasRole("ROLE_MONEYFLOW_DEAL_UPDATE") || !deal.id}
                    >
                        {t("Edit")}
                    </ActionButton>
                    <ActionButton
                        onClick={this.handleSave}
                        visible={editing}
                        disabled={saveDisabled || !hasRole("ROLE_MONEYFLOW_DEAL_UPDATE")}
                    >
                        {t("Save")}
                    </ActionButton>
                    <ActionButton
                        onClick={this.handleCancelEdit}
                        visible={editing}
                        disabled={!hasRole("ROLE_MONEYFLOW_DEAL_UPDATE")}
                    >
                        {t("Cancel")}
                    </ActionButton>
                    <ActionButton
                        onClick={this.handleRemove}
                        visible={
                            deal.reasonClass === "SpendingType" ||
                            deal.reasonClass === "BusinessUnit" ||
                            deal.reasonClass === "Debt" ||
                            deal.reasonClass === "IncomingType"
                        }
                        disabled={editing}
                    >
                        {t("Delete")}
                    </ActionButton>
                </LeftBar>
                <Content title={t("Deal card")}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <MoneyField
                                label={t("Sum")}
                                value={deal.amount}
                                onChange={this.handleChangeDeal("amount")}
                                readOnly={!editing || !isSpendingDeal(deal)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Business unit")}
                                value={deal.businessUnitName}
                                readOnly
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateTimeField2
                                label={t("Created")}
                                onChange={this.handleChangeDeal("createdAt")}
                                value={deal.createdAt}
                                disabled={!editing}
                                fullWidth
                                error={!deal.createdAt}
                            />
                        </Grid>
                    </Grid>
                    {editing && isSpendingDeal(deal) ? (
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <AutocompleteSelectField
                                    label={t("Spending type")}
                                    value={deal.reasonId}
                                    onChange={this.handleChangeDeal("reasonId")}
                                    options={spendingTypes}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <AnyField
                                    label={t("Reason ID")}
                                    value={deal.reasonId}
                                    readOnly
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AnyField
                                    label={t("Reason class")}
                                    value={deal.reasonClassText}
                                    readOnly
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AnyField
                                    label={t("Reason name")}
                                    value={deal.reasonName}
                                    readOnly
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Status")}
                                value={deal.approveStatusText}
                                readOnly
                                fullWidth
                            />
                            <AutocompleteSelectField
                                    label={t('Investor')}
                                    textField={'name'}
                                    value={deal.investorId}
                                    onChange={this.handleChangeDeal('investorId')}
                                    fullWidth
                                    options={investors.map(item => ({value: item.id, label: `${item.name}`}))}
                                    readOnly={!editing || !currentUser.isSuperAdmin}
                                 />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Comment")}
                                value={deal.comment}
                                readOnly
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateMonthField
                                label={t("Account for the month")}
                                value={deal.applyAt}
                                fullWidth
                                required
                                onChange={this.handleChangeDeal("applyAt")}
                                disabled={!editing}
                                error={!deal.applyAt}
                            />
                            <DateTimeField2
                                label={t("Cancellation fact")}
                                value={deal.cancellationFact}
                                onChange={this.handleChangeDeal("cancellationFact")}
                                disabled={!editing}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            {editing &&
                                <DateTimeField2
                                    label={t("Transaction creation date")}
                                    onChange={this.handleChangeDeal("transactionsCreatedAt")}
                                    value={deal.transactionsCreatedAt}
                                    disabled={!editing}
                                    fullWidth
                                    error={!deal.transactionsCreatedAt}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t('Company employee')}
                                value={deal.companyEmployeeId}
                                onChange={this.handleChangeDeal('companyEmployeeId')}
                                fullWidth
                                options={employees.map(item => ({value: item.id, label: `${item.name}`}))}
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("External subject")}
                                value={deal.externalSubjectId}
                                onChange={this.handleChangeDeal('externalSubjectId')}
                                fullWidth
                                options={externalSubjects.map(item => ({value: item.id, label: `${item.name}`}))}
                                readOnly
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PageTitle children={t("Transactions")}/>
                        </Grid>
                        <Grid item xs={12}>
                            <DataTableLocal
                                data={transactions}
                                columnData={columnData}
                                selector={false}
                                onClick={editing && isSpendingDeal(deal) ? this.handleClickEditTransaction : undefined}
                            />
                        </Grid>
                    </Grid>
                    {!!dialogEditTransactionData && (
                        <DealViewTransactionEditDialog
                            transaction={dialogEditTransactionData}
                            onClose={this.handleCloseEditTransaction}
                            onSubmit={this.handleSubmitEditTransaction}
                        />
                    )}
                </Content>
            </React.Fragment>
        );
    }
}
