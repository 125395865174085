import React from 'react';
import {withStyles, Grid, FormControl, InputLabel} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import classNames from "classnames";
import AnyField from "./AnyField";
import NumberFormatCount from "./NumberFormatCount";
import {getLabelTransform} from "../../styles";

const styles = theme => ({
    addSeparator: {
        position: 'relative',

        '&::after': {
            content: '"-"',
            position: 'absolute',
            height: '100%',
            display: 'flex',
            width: 16,
            top: 0,
            right: -8,
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    field: {
        marginTop: 0,
    },
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    withLabel2Lines: {
        marginTop:  theme.spacing(5),
    },
    label2Lines: {
        '&.shrink': {
            transform: getLabelTransform(-35),
        },
    },
});

class NumberRangeField extends React.Component
{
    fieldsInputProps = {
        inputComponent: NumberFormatCount,
    }

    render() {
        const {
            className,
            label,
            valueFrom,
            valueTo,
            nameFrom,
            nameTo,
            onChangeFrom,
            onChangeTo,
            disabled,
            required,
            readOnly,
            label2Lines,
            classes
        } = this.props;

        return (
            <FormControl
                className={classNames(classes.root,{[classes.withLabel]: label, [classes.withLabel2Lines]: label2Lines}, className)}
                disabled={disabled}
            >
                { label ? <InputLabel shrink disabled={disabled} required={required} variant="outlined" classes={{root: label2Lines ? classes.label2Lines : null, shrink: 'shrink'}}>{label}</InputLabel> : null }
                    <Grid container spacing={2} justify="space-between" alignItems="flex-start">
                        <Grid item xs={6} className={classes.addSeparator}>
                            <AnyField
                                value={valueFrom}
                                name={nameFrom}
                                onChange={onChangeFrom}
                                className={classes.field}
                                disabled={disabled}
                                readOnly={readOnly}
                                InputProps={this.fieldsInputProps}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                value={valueTo}
                                name={nameTo}
                                onChange={onChangeTo}
                                className={classes.field}
                                disabled={disabled}
                                readOnly={readOnly}
                                InputProps={this.fieldsInputProps}
                            />
                        </Grid>
                    </Grid>
            </FormControl>
        );
    }
}

NumberRangeField.propTypes = {
    valueFrom: PropTypes.any,
    valueTo: PropTypes.any,
    nameFrom: PropTypes.string,
    nameTo: PropTypes.string,
    onChangeFrom: PropTypes.func.isRequired,
    onChangeTo: PropTypes.func.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    label2Lines: PropTypes.bool,
};

NumberRangeField.defaultProps = {

};

export default withStyles(styles)(NumberRangeField);