import { handleActions } from 'redux-actions';
import authActions from './auth-actions';
import {loadRedirectUri, saveRedirectUri, saveToken} from "../../services/localStorage";

export default handleActions({
    [authActions.loginBegin]: (state, action) => {
        return {...state, isLoginPending: true, isLoginSuccess: false, loginError: null, };
    },

    [authActions.loginError]: (state, action) => {
        return {...state, isLoginPending: false, isLoginSuccess: false, loginError: action.payload };
    },

    [authActions.loginSuccess]: (state, action) => {
        saveToken(action.payload);
        return {...state, isLoginPending: false, isLoginSuccess: true, loginError: null, token: action.payload };
    },

    [authActions.setUser]: (state, action) => {
        return {...state, user: action.payload };
    },

    [authActions.setLogout]: (state, action) => {
        saveToken(null);
        return {...state, token: null, user: null };
    },

    [authActions.setRedirectUri]: (state, action) => {
        saveRedirectUri(action.payload);
        return {...state, redirectUri: action.payload};
    }
},{
    isLoginPending: false,
    isLoginSuccess: false,
    loginError: null,
    token: null,
    user: null,
    redirectUri: loadRedirectUri(),
})