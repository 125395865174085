import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid
} from '@material-ui/core';
import {
    emptyReferralLoyaltyPeriod,
    getReferralProgram,
    navLinks, saveReferralProgram,
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import NumberFormatDecimal3 from "../../components/fields/NumberFormatDecimal3";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {withTranslation} from "react-i18next";
import FieldsList from "../../components/fields/FieldsList";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {getDiscounts} from "../../services/calendar";

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    container: {
        width: 900,
        marginBottom: 10,
    },
    cb: {
        marginTop: 10,
    },
    helperParamList: {
        margin: 0,
        paddingInlineStart: '16px',
    }
});

export default
@connect(null, {
    showMessage: messageDialogActions.show,
})
@withRouter
@withTranslation()
@withStyles(styles)
class ReferralProgramViewPage extends React.Component {

    state = {
        referralProgram: {
            id: null,
            name: null,
            asPartnerFormula: null,
            asCreatorFormula: null,
            percentsToMoney: null,
            percentsToBarter: null,
            calculatingTick: null,
            referralLoyaltyPeriod: [],
        },
        discounts: [],
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getReferralProgram(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            referralProgram: response.data
                        })
                    }
                });

            getDiscounts()
                .then(response => {
                    if(response.success) {
                        this.setState({
                            discounts: response.data
                        })
                    }
                })
        }

    }

    handleChange = prop => event => {
        const { referralProgram } = this.state;
        this.setState({ referralProgram: {...referralProgram, [prop]: event.target.value} });
    };

    handleSave = () => {
        saveReferralProgram(this.state.referralProgram)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/referral-programs`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    formulaHelperText = () => {
        const { classes } = this.props;
        return (
            <div>
                <div>Доступные параметры:</div>
                <ul className={classes.helperParamList}>
                    <li><b>sum</b> - сумма сделки</li>
                    <li><b>visitDuration</b> - длительность визита (ч.)</li>
                </ul>
                <div>Например: <code>sum*0.06</code> будет значить что бонус - 6%</div>
            </div>
        )
    }

    render() {
        const { classes } = this.props;
        const { referralProgram, discounts } = this.state;

        const saveDisabled = !referralProgram.name ||
            !referralProgram.asPartnerFormula ||
            !referralProgram.asCreatorFormula ||
            !referralProgram.percentsToMoney ||
            !referralProgram.percentsToBarter ||
            !referralProgram.calculatingTick;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleSave} disabled={saveDisabled}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Карточка реферальной программы">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                label="Название"
                                value={referralProgram.name}
                                fullWidth
                                error={!referralProgram.name}
                                onChange={this.handleChange('name')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Процент прибыли в деньги"
                                value={referralProgram.percentsToMoney}
                                fullWidth
                                error={!referralProgram.percentsToMoney}
                                onChange={this.handleChange('percentsToMoney')}
                                required
                                InputProps={{
                                    inputComponent: NumberFormatDecimal3,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Процент прибыли в бартер"
                                value={referralProgram.percentsToBarter}
                                fullWidth
                                error={!referralProgram.percentsToBarter}
                                onChange={this.handleChange('percentsToBarter')}
                                required
                                InputProps={{
                                    inputComponent: NumberFormatDecimal3,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <FieldsList
                                items={referralProgram.referralLoyaltyPeriod}
                                emptyItem={emptyReferralLoyaltyPeriod}
                                fullWidth
                                label="Периоды реферальной лояльности"
                                onChange={this.handleChange('referralLoyaltyPeriod')}
                                headers={['День от', 'День до', 'Бонус как партнера визита', 'Бонус как создателя визита', 'Процент прибыли в деньги', 'Процент прибыли в бартер', 'Скидка']}
                                fields={[
                                    (item, onChange) => <AnyField
                                        value={item.dayFrom}
                                        onChange={onChange('dayFrom')}
                                        InputProps={{
                                            inputComponent: NumberFormatDecimal3,
                                        }}
                                        fullWidth
                                        required
                                        error={!item.dayFrom}
                                    />,
                                    (item, onChange) => <AnyField
                                        value={item.dayTo}
                                        onChange={onChange('dayTo')}
                                        InputProps={{
                                            inputComponent: NumberFormatDecimal3,
                                        }}
                                        fullWidth
                                        required
                                        error={!item.dayTo}
                                    />,
                                    (item, onChange) => <AnyField
                                        value={item.asPartnerFormula}
                                        fullWidth
                                        onChange={onChange('asPartnerFormula')}
                                        multiline
                                        required
                                        error={!item.asPartnerFormula}
                                        rows={4}
                                        helperText={this.formulaHelperText()}
                                    />,
                                    (item, onChange) => <AnyField
                                        value={item.asCreatorFormula}
                                        fullWidth
                                        onChange={onChange('asCreatorFormula')}
                                        multiline
                                        required
                                        error={!item.asCreatorFormula}
                                        rows={4}
                                        helperText={this.formulaHelperText()}
                                    />,
                                    (item, onChange) => <AnyField
                                        helperText="Процент прибыли в деньги"
                                        value={item.percentsToMoney}
                                        fullWidth
                                        onChange={onChange('percentsToMoney')}
                                        required
                                        error={!item.percentsToMoney}
                                        InputProps={{
                                            inputComponent: NumberFormatDecimal3,
                                        }}
                                    />,
                                    (item, onChange) => <AnyField
                                        helperText="Процент прибыли в бартер"
                                        value={item.percentsToBarter}
                                        fullWidth
                                        onChange={onChange('percentsToBarter')}
                                        required
                                        error={!item.percentsToBarter}
                                        InputProps={{
                                            inputComponent: NumberFormatDecimal3,
                                        }}
                                    />,
                                    (item, onChange) => <SelectFromItemsField
                                        value={item.discount}
                                        items={discounts}
                                        onChange={onChange('discount')}
                                        valueField={'id'}
                                        textField={'name'}
                                        nullable={true}
                                        nullValue={null}
                                        nullableText={'No'}
                                        fullWidth
                                    />,
                                ]}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Бонус как партнера визита"
                                value={referralProgram.asPartnerFormula}
                                fullWidth
                                onChange={this.handleChange('asPartnerFormula')}
                                multiline
                                required
                                error={!referralProgram.asPartnerFormula}
                                rows={4}
                                helperText={this.formulaHelperText()}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Бонус как создателя визита"
                                value={referralProgram.asCreatorFormula}
                                fullWidth
                                onChange={this.handleChange('asCreatorFormula')}
                                required
                                error={!referralProgram.asCreatorFormula}
                                rows={4}
                                helperText={this.formulaHelperText()}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Минимальный период расчета"
                                value={referralProgram.calculatingTick}
                                fullWidth
                                error={!referralProgram.calculatingTick}
                                onChange={this.handleChange('calculatingTick')}
                                required
                                InputProps={{
                                    inputComponent: NumberFormatDecimal3,
                                }}
                                helperText="в днях"
                            />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}