import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {withStyles} from "@material-ui/core";
import _ from "lodash";

export default
@withStyles(theme => ({
    buttonColumn: {
        width: 80,
    },
    listColumn: {
        width: 'calc(50% - 45px)',
    },
    paper: {
        overflow: 'auto',
        height: 300,
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}))
class TransferListField extends React.PureComponent
{
    static propTypes = {
        items: PropTypes.array.isRequired,
        value: PropTypes.array,
        valueField: PropTypes.string,
        textField: PropTypes.string,
        onChange: PropTypes.func,
        name: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        valueField: 'id',
        textField: 'name',
    };

    state = {
        valueItems: [],
        filteredItems: [],
        checkedLeft: [],
        checkedRight: [],
    }

    updateItems = () => {
        const {items, valueField, value, textField} = this.props;
        let valueItems = [];
        if (value && value.length) {
            if (items && items.length) {
                valueItems = value.map(valueItem => {
                    const item = items.find(item => item[valueField] === valueItem);
                    if (item) {
                        return {...item};
                    } else {
                        return {
                            [valueField]: valueItem,
                            [textField]: valueItem,
                        };
                    }
                })
            } else {
                valueItems = value.map(valueItem => ({
                    [valueField]: valueItem,
                    [textField]: valueItem,
                }));
            }
        }
        valueItems = valueItems.sort((a,b) => a[textField] < b[textField] ? -1 : a[textField] > b[textField] ? 1 : 0);
        this.setState({
            valueItems,
            filteredItems: value && value.length ?
                (items && items.length ? items.filter(item => value.indexOf(item[valueField]) === -1) : [])
                : items,
        })
    }

    componentDidMount() {
        this.updateItems();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!(_.isEqual(prevProps.items, this.props.items) && _.isEqual(prevProps.value, this.props.value))) {
            this.updateItems();
        }
    }

    handleMoveRightAll = () => {
        const { onChange, name } = this.props;

        if (onChange) {
            onChange({
                target: {name, value: [] }
            });
            this.setState({ checkedLeft: [] });
        }
    }

    handleMoveRight = () => {
        const { value, onChange, name } = this.props;
        const { checkedLeft } = this.state;

        if (onChange && checkedLeft.length) {
            onChange({
                target: {name, value: [...value.filter(item => checkedLeft.indexOf(item) === -1)] }
            });
            this.setState({ checkedLeft: [] });
        }
    }

    handleMoveLeftAll = () => {
        const { value, onChange, name, valueField } = this.props;
        const { filteredItems } = this.state;

        if (onChange && filteredItems.length) {
            onChange({
                target: {name, value: [...value, ...filteredItems.map(item => item[valueField])] }
            });
            this.setState({ checkedRight: [] });
        }
    }

    handleMoveLeft = () => {
        const { value, onChange, name } = this.props;
        const { checkedRight } = this.state;

        if (onChange && checkedRight.length) {
            onChange({
                target: {name, value: [...value, ...checkedRight] }
            });
            this.setState({ checkedRight: [] });
        }
    }

    handleToggleLeftItem = event => {
        const id = event.currentTarget.getAttribute('itemid');
        const checkedLeft = [...this.state.checkedLeft];
        const idx = checkedLeft.indexOf(id);
        if ( idx === -1) {
            checkedLeft.push(id);

        } else {
            checkedLeft.splice(idx, 1);
        }

        this.setState({
            checkedLeft,
        });
    }

    handleToggleRightItem = event => {
        const id = event.currentTarget.getAttribute('itemid');
        const checkedRight = [...this.state.checkedRight];
        const idx = checkedRight.indexOf(id);
        if ( idx === -1) {
            checkedRight.push(id);

        } else {
            checkedRight.splice(idx, 1);
        }

        this.setState({
            checkedRight,
        });
    }

    render() {
        const { classes, value, onChange, disabled } = this.props;
        const { filteredItems, checkedLeft, checkedRight } = this.state;

        return <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item className={classes.listColumn}>{this.renderLeftList()}</Grid>
            <Grid item className={classes.buttonColumn}>
                <Grid container direction="column" alignItems="center">
                    <Button variant="outlined" size="small" onClick={this.handleMoveRightAll} disabled={!(value && value.length && onChange) || disabled} className={classes.button}>≫</Button>
                    <Button variant="outlined" size="small" onClick={this.handleMoveRight} disabled={!(checkedLeft.length && onChange) || disabled} className={classes.button}>&gt;</Button>
                    <Button variant="outlined" size="small" onClick={this.handleMoveLeft} disabled={!(checkedRight.length && onChange) || disabled} className={classes.button}>&lt;</Button>
                    <Button variant="outlined" size="small" onClick={this.handleMoveLeftAll} disabled={!(filteredItems.length && onChange) || disabled} className={classes.button}>≪</Button>
                </Grid>
            </Grid>
            <Grid item className={classes.listColumn}>{this.renderRightList()}</Grid>
        </Grid>
    }

    renderLeftList() {
        const { classes, valueField, textField, disabled } = this.props;
        const { checkedLeft, valueItems } = this.state;
        return <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {valueItems.map(item => {
                    return <ListItem key={item[valueField]} role="listitem" itemID={item[valueField]} button onClick={this.handleToggleLeftItem}>
                        <ListItemIcon>
                            <Checkbox
                                checked={checkedLeft.indexOf(item[valueField]) !== -1}
                                tabIndex={-1}
                                disableRipple
                                disabled={disabled}
                            />
                        </ListItemIcon>
                        <ListItemText primary={item[textField]}/>
                    </ListItem>
                })}
            </List>
        </Paper>;
    }

    renderRightList() {
        const { classes, valueField, textField, disabled } = this.props;
        const { filteredItems, checkedRight } = this.state;
        return <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {filteredItems.map(item => {
                    return <ListItem dense key={item[valueField]} itemID={item[valueField]} role="listitem" button onClick={this.handleToggleRightItem}>
                        <ListItemIcon>
                            <Checkbox
                                checked={checkedRight.indexOf(item[valueField]) !== -1}
                                tabIndex={-1}
                                disableRipple
                                disabled={disabled}
                            />
                        </ListItemIcon>
                        <ListItemText primary={item[textField]}/>
                    </ListItem>
                })}
            </List>
        </Paper>;
    }
}