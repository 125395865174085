import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import infoActions from "../info/info-actions";
import messageDialogActions from "./messageDialog-acions";
import confirmDialogActions from "./confirmDialog-acions";
import * as PropTypes from "prop-types";
import {getSummaryVisitData} from "../../services/moneyFlow";
import {Dialog, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ShowField from "../fields/ShowField";
import DataTableLocal from "../data-table/DataTableLocal";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    },
    container: {
        marginTop: 12,
        paddingBottom: 20,
        maxWidth: 1200,
        borderBottom: '1px solid #ccc',
    },
    error: {
        color: 'red',
        whiteSpace: 'pre-line',
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center'
    }
});

const columnData = [
    { id: 'serviceType', label: 'Кусок' },
    { id: 'visitFacilityName', label: 'Услуга' },
    { id: 'initialPrice', label: 'Изначальная цена куска' },
    { id: 'price', label: 'Цена куска после модификации' },
    { id: 'amount', label: 'Оплачено деньгами' },
    { id: 'initialDuration', label: 'Изначальная длительность' },
    { id: 'duration', label: 'Длительность после модификации' },
    { id: 'amountTime', label: 'Оплачено временем' },
    { id: 'isNotApplyDiscount', label: 'Скидка не применяется' },
    { id: 'type', label: 'Тип' },
    { id: 'measure', label: 'Мера ПД' },
    { id: 'realMoneyIncoming', label: 'Реальный приход' },
    { id: 'realMastersLoading', label: 'Реальная загрузка' },
];

export default
@withRouter
@withStyles(styles)
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
class SummaryVisitDataDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        visitId: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            cashIncoming: null,
            cashlessIncoming: null,
            payDocIncoming: null,
            totalIncoming: null,
            realMoneyIncoming: null,
            isGuestParlour: false,
            workload: [],
            slices: []
        };

    }

    componentDidMount() {
        getSummaryVisitData(this.props.visitId)
            .then(response => {
                if (response.success) {
                    this.setState(response.data);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    render() {
        const {classes, open} = this.props;
        const {
            cashIncoming,
            cashlessIncoming,
            payDocIncoming,
            totalIncoming,
            isGuestParlour,
            realMoneyIncoming,
            workload,
            slices
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.props.onClose}
                aria-labelledby="summary-visit-info"
                fullWidth
                maxWidth={"lg"}
            >
                <DialogTitle id="summary-visit-info">Суммарная информация по визиту ({ isGuestParlour ? 'Гость салон' : 'Гость улица' })</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Деньги</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={cashIncoming}
                                multiline
                                label="Поступивший нал"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={cashlessIncoming}
                                multiline
                                label="Поступивший безнал"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={payDocIncoming}
                                multiline
                                label="ПД"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={totalIncoming}
                                multiline
                                label="Всего"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={realMoneyIncoming}
                                multiline
                                label="Реальный приход"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Загрузка в часах</Typography>
                        </Grid>
                        {Object.values(workload).map((master) => (
                        <Grid item xs={4} key={master.name}>
                            <ShowField
                                value={`${master.unfree} / ${master.free} / ${master.total}`}
                                multiline
                                label={`${master.name} (Плат / БП / Всего)`}
                            />
                        </Grid>
                        ))}
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Куски</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DataTableLocal
                                columnData={columnData}
                                data={slices}
                                selector={false}
                                disableSorting
                                pagination={false}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

}