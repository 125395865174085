import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {
    Grid,
    Fab,
    Typography, TableCell, TableBody
} from '@material-ui/core';
import {
    createFacility,
    getFacilityTypes,
    getFacility,
    updateFacility,
    navLinks,
    getPriceBy, priceStatus,
} from "../../services/facilities";
import { connect } from 'react-redux';
import infoActions from '../../components/info/info-actions';
import { withStyles } from '@material-ui/core/styles';
import ShowField from "../../components/fields/ShowField";
import AnyField from "../../components/fields/AnyField";
import Add from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {getCategories, getConsumptionGroups, getGoods} from "../../services/goods";
import {
    getEmployeeSpecializations,
    getEquipmentTypes,
    getParlours
} from "../../services/organization";
import {withRouter} from "react-router";
import CheckboxField from "../../components/fields/CheckboxField";
import FieldLabel from "../../components/fields/FieldLabel";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MoneyField from "../../components/fields/MoneyField";
import {getCurrencies} from "../../services/common";
import * as cn from 'classnames';
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import FloatPositiveField from "../../components/fields/FloatPositiveField";
import TransferListField from "../../components/fields/TransferListField";
import {createChangeHandle} from "../../utils/helpers";
import ColorField from "../../components/fields/ColorField";
import FacilityDurationField from "../../components/fields/FacilityDurationField";
import _ from "lodash";
import UploadFilesField from "../../components/fields/UploadFilesField";
import IntegerField from "../../components/fields/IntegerField";
import {isValidRequiredL18nString} from "../../utils/validators";

const masterCountItems = [
    {id: 1, name: '1'},
    {id: 2, name: '2'},
];

export default
@withRouter
@connect(() => ({

}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show
})
@withStyles(theme => ({
    content: {
        paddingTop:  theme.spacing(2)
    },
    delButtonIcon: {
        fontSize: 20,
    },
    facilityPriceCosmetic: {
        marginTop: 24,
    },
    parlourListItem: {
        padding: 0,
    },
    parlourCheckbox: {
        padding: 5,
        marginRight: -2 *  theme.spacing(1),
    },
    priceColumn: {
        width: 180,
        verticalAlign: 'top',
        borderBottom: 'none',
    },
    priceColumnFirst: {
        width: 200,
        verticalAlign: 'top',
        borderBottom: 'none',
    },
    priceColumnHeader: {
        fontSize: '0.9rem',
        textAlign: 'center',
        fontWeight: 'normal',
    },
    priceStartRow: {
        borderTop: '2px solid ' + theme.palette.divider,
    },
    detButtonField: {
        width: 'calc(100% - 40px)',
        marginLeft: 5,
    },
    buttonDel: {
        width: 35,
        height: 35,
    },
    priceHourRow: {
        backgroundColor: '#5a9de1',
    },
    checkBox: {
        marginLeft: 10
    }
}))
class ViewPage extends React.Component {

    state = {
        facility: {
            id: null,
            code: null,
            type: null,
            name: null,
            nameAll: {},
            slim: false,
            description: null,
            descriptionAll: [],
            prices: [],
            goods: [],
            businessUnits: [],
            variants: [],
            mastersCount: 1,
            needEquipmentType: null,
            notApplyDiscounts: false,
            notCountForMasters: false,
            masterSpecializations: [],
            guestsCount: 1,
            forWidget: false,
            forMobile: false,
            color: null,
            relaxNotNeeded: false,
            nameForReceipt: null,
            images: [],
            notificationAll: [],
            ignoreInMasterHourAvg: false,
            isExotic: false,
            isContinueOnly: false,
            notRequireBreakAfter: false,
        },
        types: [],
        editing: false,
        categories: [],
        goods: [],
        goodsCategory: [],
        prices: [],
        businessUnits: [],
        equipmentTypes: [],
        currencies: [],
        specializations: [],
        consumptionGroups: [],
    };

    componentDidMount() {
        this.init();

        getCategories()
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.data,
                    })
                }
            });

        getGoods()
            .then(response => {
                if (response.success) {
                    this.setState({
                        goods: response.data,
                    })
                }
            });

        getFacilityTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data,
                    })
                }
            });

        getPriceBy({status: [
                priceStatus.active,
                priceStatus.inactive,
                priceStatus.draft,
                ]
                })
            .then(response => {
                if (response.success) {
                    this.setState({
                        prices: response.data,
                    })
                }
            });

        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getEquipmentTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        equipmentTypes: response.data,
                    })
                }
            });

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data.map(currency => currency.id),
                    });
                }
            });

        getEmployeeSpecializations()
            .then(response => {
                if (response.success) {
                    this.setState({
                        specializations: response.data,
                    })
                }
            });

        getConsumptionGroups()
            .then(response => {
                if (response.success) {
                    this.setState({
                        consumptionGroups: response.data,
                    })
                }
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.init();
        }
    }

    init() {
        const reId = new RegExp('/facilities/(view|edit)/([^/]+)$');
        const resultId = reId.exec(this.props.location.pathname);
        if (resultId.length !== 3) {
            //Error request
            return;
        }

        this.setState({
            editing: resultId[1] === 'edit',
        });

        if (resultId[2] !== "0") {
	        getFacility(resultId[2])
		        .then(response => {
			        if (response.success) {
				        this.setState({
					        facility: this.processFacility(response.data),
				        })
			        }
		        });
        }
    }

    processFacility(facility) {
        facility.prices.forEach(priceGroup => {
            priceGroup.durations.forEach((duration, idx) => {
                duration.idx = idx;
                duration.time = Math.round(duration.time * 60);
            });
        });

        return facility;
    }

    handleChange = createChangeHandle(this, 'facility');

    handleChangeI18n = event => {
        const [prop, locale] = event.target.id.split('#');
        this.setState({
            facility: {
                ...this.state.facility,
                [prop]: {
                    ...this.state.facility[prop],
                    [locale]: event.target.value,
                }
            }
        });
    };

    static isValidCode(code) {
        const regexp = /^[a-z0-9]{0,9}$/i;
        return regexp.test(code)
    }

    handleChangeDurationPrice = event => {
        const [ field, priceGroupIdxStr, priceIdxStr ] = event.target.name.split('#');
        const priceGroupIdx = parseInt(priceGroupIdxStr);
        const priceIdx = parseInt(priceIdxStr);
        const { facility } = this.state;
        const prices = [...facility.prices];
        prices[priceGroupIdx].durations = [
            ...prices[priceGroupIdx].durations,
        ];
        prices[priceGroupIdx].durations[priceIdx] = {
            ...prices[priceGroupIdx].durations[priceIdx],
            [field]: event.target.value,
        };
        this.setState({
            facility: {
                ...facility,
                prices,
            }
        });
    };

    handleChangePriceGroup = event => {
        const [ field, priceGroupIdxStr ] = event.target.name.split('#');
        const priceGroupIdx = parseInt(priceGroupIdxStr);
        const { facility } = this.state;
        const prices = [...facility.prices];
        prices[priceGroupIdx] = {
            ...prices[priceGroupIdx],
            [field]: event.target.value,
        };
        this.setState({
            facility: {
                ...facility,
                prices,
            }
        });
    };

    handleChangeGood = (field, id) => event => {
        if (id >= 0 && field) {
            const facilityGoods = this.state.facility.goods;
            const { goodsCategory, goods } = this.state;

            facilityGoods[id] = {...facilityGoods[id], [field] : event.target.value};

            if (field === 'category') {
                goodsCategory[id] = goods.filter(good => good.category.id === event.target.value)
            }

            if (field === 'createSale') {
                if (event.target.value) {
                    facilityGoods[id] = {...facilityGoods[id], amount: 0}
                } else {
                    facilityGoods[id] = {...facilityGoods[id], saleAmount: 0}
                }
            }

            this.setState({
                facility: {
                    ...this.state.facility,
                    goods: facilityGoods
                },
                goodsCategory: goodsCategory
            });
        }
    };

    handlePriceAddDuration = event => {
        const priceGroupIdx = parseInt(event.currentTarget.getAttribute('itemid'));
        const { facility } = this.state;
        const prices = facility.prices;
        prices[priceGroupIdx].durations = [
            ...prices[priceGroupIdx].durations,
            {
                time: null,
                price1: null,
                price2: null,
                price3: null,
                idx: prices[priceGroupIdx].durations.length,
            }
        ];

        this.setState({
            facility: {
                ...facility,
                prices,
            }
        });

    };

    handleGoodAdd = () => {
        this.setState(state => ({
            facility: {
                ...state.facility,
                goods: [
                    ...state.facility.goods,
                    {
                        id: null,
                        good:null,
                        consumptionGroup:null,
                        category: null,
                        amount: null,
                        businessUnitsIds: []
                    }
                ],
            }
        }));
    };

    handlePriceAdd = () => {
        this.setState(state => ({
            facility: {
                ...state.facility,
                prices: [
                    ...state.facility.prices,
                    {
                        priceId: null,
                        variantId: null,
                        priceCosmetic: null,
                        durations: [],
                    }
                ],
            }
        }));
    };

    handleGoodDel = idx => () => {
        let goods = [...this.state.facility.goods];

        if (goods[idx].id == null) {
            goods.splice(idx, 1);
        } else {
            goods[idx].status = 'del';
        }

        this.setState(state =>({
            facility: {
                ...state.facility,
                goods
            }
        }));
    };

    handlePriceDel = event => {
        const priceGroupIdx = parseInt(event.currentTarget.getAttribute('itemid'));
        const prices = [...this.state.facility.prices];

        if (prices[priceGroupIdx].durations.some(duration => duration.id)) {
            prices[priceGroupIdx].status = 'del';
        } else {
            prices.splice(priceGroupIdx, 1);
        }

        this.setState(state => ({
            facility: {
                ...state.facility,
                prices,
            }
        }))
    };

    handleCancelSave = () => {
        if (this.state.facility.id && this.state.editing) {
            this.props.history.push(`/facilities/view/${this.state.facility.id}`);
        } else {
            this.props.history.push(`/facilities`);
        }
    };

    handleSave = () => {
        const facility = _.cloneDeep(this.state.facility);
        facility.prices.forEach(priceGroup => {
            priceGroup.durations.forEach(duration => {
                duration.time = duration.time / 60;
            });
        });

        facility.images = facility.images.map(image => image.file)

        if (facility.id) {
            updateFacility(facility)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/facilities/view/${response.data.id}`);
                        this.props.showInfo('Изменения сохранены');
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        } else {
            createFacility(facility)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/facilities/view/${response.data.id}`);
                        this.props.showInfo('Услуга добавлена');
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleEdit = () => {
        this.props.history.push(`/facilities/edit/${this.state.facility.id}`);
    };

    handleHistory = () => {
        this.props.history.push(`/facilities/history-change/${this.state.facility.id}`);
    };

    handleChangeParlour = event => {
        const id = event.currentTarget.getAttribute('itemid');
        const { facility } = this.state;
        let { businessUnits } = this.state.facility;
        let indexBusinessUnit = businessUnits.indexOf(id);
        let businessUnitsProcessed;


        if (indexBusinessUnit === -1) {
            businessUnits.push(id);
        } else {
                businessUnitsProcessed = [];

            businessUnits.forEach((item, id) => {
                if (id !== indexBusinessUnit) {
                    businessUnitsProcessed.push(item);
                }
            })
        }

        this.setState({
            facility: {
                ...facility,
                businessUnits: businessUnitsProcessed === undefined ? businessUnits : businessUnitsProcessed,
            }
        })
    };

    handleChangeImage = event => {
        const { facility } = this.state;
        this.setState({
            facility: {
                ...facility,
                images: event.target.value.map(file => {
                    return { file: file}
                })
            }
        })
    }

    isSaveDisabled() {
        const { facility } = this.state;

        return !isValidRequiredL18nString(facility.nameAll)
            || !facility.type
            || !ViewPage.isValidCode(facility.code)
            || (facility.id && !facility.code);
    }

    render() {
        const { classes } = this.props;
        const { facility, editing, types, businessUnits, equipmentTypes, specializations } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink="Карточка услуги"/>}>
                    {this.renderLeftFields()}
                    <br />
                    <br />
                    <React.Fragment>
                        {editing ? (
                            <ActionButton onClick={this.handleSave} disabled={this.isSaveDisabled()}>{facility.id ? 'Сохранить' : 'Добавить'}</ActionButton>
                            ) : (
                            <ActionButton onClick={this.handleEdit}>Редактировать</ActionButton>
                            )}
                        <ActionButton onClick={this.handleCancelSave}>Отмена</ActionButton>
                        <ActionButton visible={!!facility.id} onClick={this.handleHistory}>История изменения параметров</ActionButton>
                    </React.Fragment>
                </LeftBar>
                <Content title="Карточка услуги">
                    <Grid container spacing={2} className={classes.content}>
                        <Grid item xs={12}><Typography variant="subtitle1">Основные параметры</Typography></Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#en"
                                label="Наименование услуги, EN"
                                value={facility.nameAll.en}
                                required
                                fullWidth
                                readOnly={!editing}
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#ru"
                                label="Наименование услуги, RU"
                                value={facility.nameAll.ru}
                                required
                                fullWidth
                                readOnly={!editing}
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#cs"
                                label="Наименование услуги, CS"
                                value={facility.nameAll.cs}
                                required
                                fullWidth
                                readOnly={!editing}
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                name="shortDescriptionAll#en"
                                label="Краткое описание EN"
                                value={ facility.shortDescriptionAll ? facility.shortDescriptionAll.en: null }
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="1"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                name="shortDescriptionAll#ru"
                                label="Краткое описание RU"
                                value={ facility.shortDescriptionAll ? facility.shortDescriptionAll.ru: null }
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="1"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                name="shortDescriptionAll#cz"
                                label="Краткое описание CZ"
                                value={ facility.shortDescriptionAll ? facility.shortDescriptionAll.cz: null }
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="1"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AnyField
                                name="code"
                                label="Код услуги"
                                value={facility.code}
                                required
                                fullWidth
                                disabled={!editing}
                                onChange={this.handleChange}
                                error={!ViewPage.isValidCode(facility.code) || (facility.id && !facility.code)}
                                helperText={ViewPage.isValidCode(facility.code) ? '' : 'Не более 9 латинских букв и цифр'}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SelectFromItemsField
                                items={types}
                                name="type"
                                label="Вид услуги"
                                value={facility.type}
                                fullWidth
                                required
                                select
                                disabled={!editing}
                                onChange={this.handleChange}
                                textField="text"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <MultiSelectFromItemsField
                                name="masterSpecializations"
                                items={specializations}
                                emptySelectedLabel="Необходимо указать"
                                value={facility.masterSpecializations}
                                onChange={this.handleChange}
                                label="Требуемые специализации"
                                fullWidth
                                required
                                error={!facility.masterSpecializations || !facility.masterSpecializations.length}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SelectFromItemsField
                                items={masterCountItems}
                                value={facility.mastersCount}
                                name="mastersCount"
                                onChange={this.handleChange}
                                label="Количество мастеров"
                                required
                                error={!facility.mastersCount}
                                fullWidth
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AnyField
                                name="guestsCount"
                                label="Количество гостей"
                                value={facility.guestsCount}
                                type="number"
                                fullWidth
                                multiline
                                disabled={!editing}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SelectFromItemsField
                                name="needEquipmentType"
                                items={equipmentTypes}
                                value={facility.needEquipmentType}
                                nullable
                                nullableText="Не требуется"
                                onChange={this.handleChange}
                                label="Оборудование"
                                fullWidth
                                textField="text"
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ColorField
                                label="Цвет на расписании"
                                value={facility.color}
                                name="color"
                                disabled={!editing}
                                fullWidth
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <UploadFilesField
                                id="images"
                                name="images"
                                label="Изображения"
                                image
                                onChange={this.handleChangeImage}
                                value={facility.images.map(image => image.file)}
                                multiple
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={2}>
                            <CheckboxField
                                name="notApplyDiscounts"
                                label="Не считать скидку"
                                value={facility.notApplyDiscounts}
                                onChange={this.handleChange}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CheckboxField
                                name="ignoreInMasterHourAvg"
                                label="Не учитывать при расчете средней стоимости часа"
                                value={facility.ignoreInMasterHourAvg}
                                onChange={this.handleChange}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxField
                                name="notCountForMasters"
                                label="Не учитывать для мастера"
                                value={facility.notCountForMasters}
                                onChange={this.handleChange}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxField
                                name="relaxNotNeeded"
                                value={facility.relaxNotNeeded}
                                onChange={this.handleChange}
                                label="Не требует перерыва перед"
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxField
                                name="notRequireBreakAfter"
                                label="Не требует перерыва после"
                                value={facility.notRequireBreakAfter}
                                onChange={this.handleChange}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <CheckboxField
                                name="slim"
                                value={facility.slim}
                                onChange={this.handleChange}
                                label="Слим"
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={2}>
                            <CheckboxField
                                name="forWidget"
                                value={facility.forWidget}
                                onChange={this.handleChange}
                                label="Для виджета"
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxField
                                name="forMobile"
                                value={facility.forMobile}
                                onChange={this.handleChange}
                                label="Для мобильного приложения"
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxField
                                name="isExotic"
                                label="Экзотическая услуга"
                                value={facility.isExotic}
                                onChange={this.handleChange}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CheckboxField
                                name="isContinueOnly"
                                label="Только для продления"
                                value={facility.isContinueOnly}
                                onChange={this.handleChange}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="descriptionAll#en"
                                label="Описание EN"
                                value={ facility.descriptionAll ? facility.descriptionAll.en: null }
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="4"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="notificationAll#en"
                                label="Уведомление при добавлении EN"
                                value={facility.notificationAll?.en}
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="4"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="descriptionAll#ru"
                                label="Описание RU"
                                value={ facility.descriptionAll ? facility.descriptionAll.ru: null }
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="4"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="notificationAll#ru"
                                label="Уведомление при добавлении RU"
                                value={facility.notificationAll?.ru}
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="4"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="descriptionAll#cz"
                                label="Описание CZ"
                                value={ facility.descriptionAll ? facility.descriptionAll.cz: null }
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="4"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="notificationAll#cz"
                                label="Уведомление при добавлении CZ"
                                value={facility.notificationAll?.cz}
                                fullWidth
                                multiline
                                disabled={!editing}
                                rows="4"
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                id="nameForReceipt"
                                label="Наименование для чека"
                                value={ facility.nameForReceipt }
                                fullWidth
                                disabled={!editing}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderVariants()}
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderGoods()}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Доступна для салонов</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TransferListField
                                items={businessUnits}
                                value={facility.businessUnits}
                                name="businessUnits"
                                onChange={this.handleChange}
                                disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {this.renderPrices()}
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }

    renderLeftFields() {
        const { facility, types } = this.state;
        const type = types.find(item => item.id === facility.type);

        return (
            <React.Fragment>
                {
                    facility.id
                    &&  <ShowField
                            label="Код услуги"
                            value={facility ? facility.code : null}
                        />
                }
                <ShowField
                    label = "Вид услуги"
                    value = { type ? type.text : null }
                />
            </React.Fragment>
        );
    }

    handleAddVariant = () => {
        const { facility } = this.state;

        this.setState({
            facility: {
                ...facility,
                variants: [
                    ...(facility.variants ? facility.variants : []),
                    {
                        id: null,
                        nameAll: {
                            ru: null,
                            en: null,
                            cs: null,
                        },
                    }
                ]
            }
        })
    };

    handleChangeVariant = event => {
        const [ locale, variantIdxStr ] = event.target.name.split('#');
        const variantIdx = parseInt(variantIdxStr);
        const { facility } = this.state;
        const currentVariant = facility.variants[variantIdx];
        facility.variants[variantIdx] = {
            ...currentVariant,
            nameAll: {
                ...currentVariant.nameAll,
                [locale]: event.target.value,
            },
        };

        this.setState({facility});
    };

    handleDelVariant = event => {
        const variantIdx = parseInt(event.currentTarget.getAttribute('itemid'));
        const { facility } = this.state;
        const variants = [...facility.variants];
        variants.splice(variantIdx, 1);
        this.setState({
            facility: {
                ...facility,
                variants,
            }
        });
    };

    handleChangeDuration = oldDuration => event => {
        const { facility } = this.state;
        facility.prices.forEach(priceGroup => {
            priceGroup.durations.forEach(price => {
                if (price.time === oldDuration) {
                    price.time = event.target.value;
                }
            })
        });

        this.setState({
            facility,
        });
    };

    renderVariants() {
        const { facility, editing } = this.state;

        return <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="subtitle1">Варианты оказания улуги</Typography></Grid>
            { facility.variants && facility.variants.length ?
                <React.Fragment>
                    <Grid item xs={3}><FieldLabel>Наименование, RU</FieldLabel></Grid>
                    <Grid item xs={3}><FieldLabel>Наименование, EN</FieldLabel></Grid>
                    <Grid item xs={3}><FieldLabel>Наименование, CS</FieldLabel></Grid>
                    <Grid item xs={3}> </Grid>
                    {facility.variants.map((variant, variantIdx) => <React.Fragment key={variantIdx}>
                        <Grid item xs={3}>
                            <AnyField
                                name={`ru#${variantIdx}`}
                                value={variant.nameAll.ru}
                                onChange={this.handleChangeVariant}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                name={`en#${variantIdx}`}
                                value={variant.nameAll.en}
                                onChange={this.handleChangeVariant}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                name={`cs#${variantIdx}`}
                                value={variant.nameAll.cs}
                                onChange={this.handleChangeVariant}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ActionButton noMargin visible={editing} itemID={variantIdx} onClick={this.handleDelVariant}>Удалить</ActionButton>
                        </Grid>
                    </React.Fragment>)}
                </React.Fragment>
            :null}
            <ActionButton
                fullWidth={false}
                inGridItem12
                onClick={this.handleAddVariant}
                visible={editing}
                >
                <Add/> Добавить вариант
            </ActionButton>

        </Grid>;
    }

    handlePriceDelDuration = (priceGroupIdx, priceIdx) => () => {
        const { facility } = this.state;
        const durations = facility.prices[priceGroupIdx].durations;
        if (durations[priceIdx].id) {
            durations[priceIdx].status = 'del';
        } else {
            facility.prices[priceGroupIdx].durations = [...facility.prices[priceGroupIdx].durations];
            facility.prices[priceGroupIdx].durations.splice(priceIdx, 1);

            facility.prices[priceGroupIdx].durations.forEach((duration, idx) => {
                duration.idx = idx;
            });
        }

        this.setState({
            facility,
        });
    };

    renderPrices() {
        const { classes } = this.props;
        const { facility, editing, prices, currencies } = this.state;

        return <React.Fragment>
            <Typography variant="subtitle1">Стоимость услуги</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.priceColumnHeader}>Прайс/вариант</TableCell>
                        <TableCell className={classes.priceColumnHeader}>Косметика</TableCell>
                        <TableCell className={classes.priceColumnHeader}>Длительность, ч.</TableCell>
                        <TableCell className={classes.priceColumnHeader}>Стоимость до 14:00</TableCell>
                        <TableCell className={classes.priceColumnHeader}>Стоимость 14:00 - 18:00</TableCell>
                        <TableCell className={classes.priceColumnHeader}>Стоимость с 18:00</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {facility.prices.map((priceGroup, priceGroupIdx) => {
                        if (priceGroup.status && priceGroup.status === 'del') {
                            return null;
                        }

                        const durations = priceGroup.durations.filter(duration => !duration.status || duration.status !== 'del');
                        const visibleDurations = durations.length;
                        const firstDuration = durations.shift();

                        return <React.Fragment key={priceGroupIdx}>
                            <TableRow className={classes.priceStartRow}>
                                <TableCell className={classes.priceColumnFirst} rowSpan={visibleDurations || null}>
                                    <SelectFromItemsField
                                        name={`priceId#${priceGroupIdx}`}
                                        items={prices}
                                        value={priceGroup.priceId}
                                        fullWidth
                                        disabled={!editing}
                                        onChange={this.handleChangePriceGroup}
                                    />
                                    {facility.variants && facility.variants.length ?
                                        <SelectFromItemsField
                                            name={`variantId#${priceGroupIdx}`}
                                            items={facility.variants}
                                            value={priceGroup.variantId}
                                            fullWidth
                                            disabled={!editing}
                                            onChange={this.handleChangePriceGroup}
                                        />
                                        : null}
                                </TableCell>
                                <TableCell className={classes.priceColumn} rowSpan={visibleDurations || null}>
                                    <MoneyField
                                        name={`priceCosmetic#${priceGroupIdx}`}
                                        value={priceGroup.priceCosmetic}
                                        currencies={currencies}
                                        disabled={!editing}
                                        onChange={this.handleChangePriceGroup}
                                        disableCurrencySelect
                                    />
                                </TableCell>
                                {visibleDurations ?
                                    this.renderPriceColumns(priceGroupIdx, firstDuration)
                                    :
                                    <TableCell colSpan={4}> </TableCell>
                                }
                            </TableRow>
                            {durations.map(duration =>
                                <TableRow key={duration.idx}>
                                    {this.renderPriceColumns(priceGroupIdx, duration)}
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <ActionButton visible={editing} fullWidth={false} itemID={priceGroupIdx}
                                                  onClick={this.handlePriceDel}>
                                        Удалить прайс
                                    </ActionButton>
                                </TableCell>
                                <TableCell colSpan={4}>
                                    <ActionButton visible={editing} fullWidth={false} itemID={priceGroupIdx}
                                                  onClick={this.handlePriceAddDuration}>
                                        <Add/> Добавить длительность
                                    </ActionButton>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    })}
                </TableBody>
            </Table>
            <ActionButton visible={editing} fullWidth={false} onClick={this.handlePriceAdd}>
                <span><Add /> Добавить прайс</span>
            </ActionButton>
        </React.Fragment>
    }

    renderPriceColumns(priceGroupIdx, duration) {
        const { classes } = this.props;
        const { editing, currencies } = this.state;
        const isHourDuration = duration.time === 60;

        return <>
            <TableCell className={cn(classes.priceColumn, {[classes.priceHourRow]: isHourDuration})}>
                <Fab size="small" color="secondary" className={classes.buttonDel}
                     disabled={!editing}
                     onClick={this.handlePriceDelDuration(priceGroupIdx, duration.idx)}><ClearIcon
                    className={classes.delButtonIcon}/></Fab>
                <FacilityDurationField
                    disabled={!editing}
                    name={`time#${priceGroupIdx}#${duration.idx}`}
                    value={duration.time}
                    required
                    error={!duration.time}
                    onChange={this.handleChangeDurationPrice}
                    className={classes.detButtonField}
                />
            </TableCell>
            <TableCell className={cn(classes.priceColumn, {[classes.priceHourRow]: isHourDuration})}>
                <MoneyField
                    name={`price1#${priceGroupIdx}#${duration.idx}`}
                    value={duration.price1}
                    onChange={this.handleChangeDurationPrice}
                    currencies={currencies}
                    disabled={!editing}
                    disableCurrencySelect
                />
            </TableCell>
            <TableCell className={cn(classes.priceColumn, {[classes.priceHourRow]: isHourDuration})}>
                <MoneyField
                    name={`price2#${priceGroupIdx}#${duration.idx}`}
                    value={duration.price2}
                    onChange={this.handleChangeDurationPrice}
                    currencies={currencies}
                    disabled={!editing}
                    disableCurrencySelect
                />
            </TableCell>
            <TableCell className={cn(classes.priceColumn, {[classes.priceHourRow]: isHourDuration})}>
                <MoneyField
                    name={`price3#${priceGroupIdx}#${duration.idx}`}
                    value={duration.price3}
                    onChange={this.handleChangeDurationPrice}
                    currencies={currencies}
                    disabled={!editing}
                    disableCurrencySelect
                />
            </TableCell>
        </>
    }

    renderGoods() {
        const { classes } = this.props;
        const { facility, editing, categories, goodsCategory, goods, businessUnits, consumptionGroups } = this.state;

        return <React.Fragment>
            <Typography variant="subtitle1">Используемые товары</Typography>
            { facility.goods && facility.goods.length ?
                <Grid container spacing={1}>
                    {facility.variants && facility.variants.length ? <Grid item xs={2}><FieldLabel>Вариант услуги</FieldLabel></Grid> : null}
                    <Grid item xs={2}><FieldLabel>Группа товара</FieldLabel></Grid>
                    <Grid item xs={2}><FieldLabel>Наименование товара</FieldLabel></Grid>
                    <Grid item xs={2}><FieldLabel>Или расходная группа</FieldLabel></Grid>
                    <Grid item xs={2}><FieldLabel>Только для салонов</FieldLabel></Grid>
                    <Grid item xs={1}><FieldLabel>Продажа</FieldLabel></Grid>
                    <Grid item xs={1}><FieldLabel>Расход</FieldLabel></Grid>
                    <Grid item xs={1}><FieldLabel>Создавать продажу</FieldLabel></Grid>
                    <Grid item xs={12}/>
                    {facility.goods.map((good, id) => (
                            good.status && good.status === 'del' ?
                                null :
                                <React.Fragment key={id}>
                                    {facility.variants && facility.variants.length ?
                                        <Grid item xs={2}>
                                            <SelectFromItemsField
                                                items={facility.variants}
                                                value={facility.goods[id].variantId }
                                                fullWidth
                                                disabled={!editing}
                                                onChange={this.handleChangeGood('variantId', id)}
                                            />
                                        </Grid>
                                    :null}
                                    <Grid item xs={2}>
                                        <SelectFromItemsField
                                            items={categories}
                                            value = { facility.goods[id].category }
                                            fullWidth
                                            readOnly={!editing}
                                            onChange = { this.handleChangeGood('category', id)}
                                            select
                                            disabled={!editing}
                                            textField="name"
                                            valueField="id"
                                            nullableText="Не использовать"
                                            nullable
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <AutocompleteSelectField
                                            options={goodsCategory.length && goodsCategory[id] ? goodsCategory[id].map(item => ({value: item.id, label: `${item.nameWithMass}`})) : goods.map(item => ({value: item.id, label: `${item.nameWithMass}`}))}
                                            value={facility.goods[id].good}
                                            onChange={this.handleChangeGood('good', id)}
                                            fullWidth
                                            placeholder="Не использовать"
                                            isClearable
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <SelectFromItemsField
                                            items={consumptionGroups}
                                            onChange = { this.handleChangeGood('consumptionGroup', id)}
                                            disabled={!editing}
                                            readOnly={!editing}
                                            value={ facility.goods[id].consumptionGroup }
                                            fullWidth
                                            textField="name"
                                            valueField="id"
                                            nullableText="Не использовать"
                                            nullable
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <MultiSelectFromItemsField
                                            items={businessUnits}
                                            onChange = { this.handleChangeGood('businessUnitsIds', id)}
                                            disabled={!editing}
                                            readOnly={!editing}
                                            value={ facility.goods[id].businessUnitsIds }
                                            fullWidth
                                            textField="name"
                                            valueField="id"
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IntegerField
                                            value = { facility.goods[id].saleAmount }
                                            fullWidth
                                            readOnly={!editing}
                                            name={`${id}#saleAmount`}
                                            onChange = { this.handleChangeGood('saleAmount', id)}
                                            disabled={!editing || !facility.goods[id].createSale}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <FloatPositiveField
                                            value = { facility.goods[id].amount }
                                            fullWidth
                                            readOnly={!editing}
                                            name={`${id}#amount`}
                                            onChange = { this.handleChangeGood('amount', id)}
                                            disabled={!editing || facility.goods[id].createSale}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CheckboxField
                                            value = { facility.goods[id].createSale }
                                            fullWidth
                                            readOnly={!editing}
                                            name={`${id}#createSale`}
                                            onChange = { this.handleChangeGood('createSale', id)}
                                            disabled={!editing}
                                            margin="none"
                                            className={classes.checkBox}
                                        />
                                    </Grid>
                                    <Fab size="small" color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleGoodDel(id)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                    <Grid item xs={12}/>
                                </React.Fragment>
                        ))
                    }
                </Grid>
            : null }
            <ActionButton visible={editing} fullWidth={false} onClick={this.handleGoodAdd}>
                <Add/> Добавить товар
            </ActionButton>
        </React.Fragment>;
    }
}
