import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import DataTable from "../../components/data-table/DataTable";
import {navLinks} from "../../services/settings";
import {createDiscountGroup, getDiscountGroups} from "../../services/sale";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";


import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";

const columnData = [
    {id: 'name', numeric: false, disablePadding: false, label: 'Название'},
];

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight: theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    checkBox: {
        marginTop: 15
    },
    del: {
        display: 'flex',
        alignItems: 'flex-end'
    },
});

const emptyRegion = {
    id: null,
    name: null,
    minCashValues: [],
    cashChangeInMainCurrency: false,
    referralProgramId: null,
    hasRiskyStatus: false,
    subscriptions: [],
    botConfirmVisitsTime: null,
    botCreateUnconfirmedVisitsTaskTime: null,
    hasPaymentLinks: false,
    visitCreateTime: 2,
    spendingSpreadSheetId: null,
    autoCreatePayDocForCashless: false,
    autoCreatePayDocGift: false,
    timeZone: null
};

const emptyMinCashValue = {
    currency: null,
    value: 1
}

@withTranslation()
@connect(null, {
    showMessage: messageDialogActions.show
})
class DiscountGroupListPage extends React.Component {

    state = {
        search: {
            search: null
        },
        region: emptyRegion,
        dialogEditOpen: false,
        refreshTable: false,
        currencies: [],
        programs: [],
        vat: null,
        regionEvents: [],
        messengers: [],
        timeZones: []
    };

    componentDidMount() {
    }


    handleChange = (prop) => event => {
        if (prop === 'vat' && !(+event.target.value)) {
            return;
        }

        this.setState({
            region: {
                ...this.state.region,
                [prop]: event.target.value,
            }
        });
    };

    handleEdit = region => {
        this.setState({
            region,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        createDiscountGroup(this.state.region)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeSubArr = (prop, subProp, index) => event => {
        let subArr = [...this.state.region[prop]];
        subArr[index][subProp] = event.target.value;
        this.setState({
            region: {
                ...this.state.region,
                [prop]: subArr
            },
        });
    };

    handleDeleteSubArr = (prop, index) => () => {
        let subArr = [...this.state.region[prop]];
        subArr.splice(index, 1);
        this.setState({
            region: {
                ...this.state.region,
                [prop]: subArr
            },
        });
    };

    handleAddSubArr = (prop) => () => {
        let subArr = [...this.state.region[prop]];
        subArr.push({...emptyMinCashValue});
        this.setState({
            region: {
                ...this.state.region,
                [prop]: subArr
            },
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    handleAdd = () => {
        this.setState({
            region: emptyRegion,
            dialogEditOpen: true,
        })
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getDiscountGroups(search, ...params);
    };

    render() {

        const {search, dialogEditOpen, refreshTable, region} = this.state;
        const {t} = this.props;

        const disabled = !region.name

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <SearchField
                    value={search.search}
                    onChange={this.handleChangeSearch('search')}
                    onClick={this.handleClickSearch}
                    label={t("Search")}
                />
                <br/>
                <br/>
                <ActionButton onClick={this.handleAdd}>{t("Add")}</ActionButton>
            </LeftBar>
            <Content title={t("DiscountGroups.title")}>
                <DataTable
                    columnData={columnData}
                    dataFunc={this.getTableData}
                    refresh={refreshTable}
                    onClick={this.handleEdit}
                    selector={false}
                />
            </Content>

            <Dialog
                open={dialogEditOpen}
                onClose={this.handleCloseEditDialog(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="dialog-title">{t("DiscountGroups.card")}</DialogTitle>
                <DialogContent>
                    <Grid item xs={8}>
                        <AnyField
                            label={t("DiscountGroups.cardName")}
                            value={region.name}
                            onChange={this.handleChange('name')}
                            required
                            error={!region.name}
                            fullWidth
                            autoFocus
                        />
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseEditDialog(true)} disabled={disabled} color="primary">OK</Button>
                    <Button onClick={this.handleCloseEditDialog(false)} color="primary">{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>;
    }
}

export default withStyles(styles)(DiscountGroupListPage);
