import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Grid, Checkbox
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import {
    getDiscountActiveTypes, getDiscounts,
    getDiscountTypes,
    saveDiscount
} from "../../services/calendar";
import CheckboxField from "../../components/fields/CheckboxField";
import {getParlours, getRegions} from "../../services/organization";
import ListItemText from "@material-ui/core/ListItemText";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {
    getDiscountGroups,
    getDiscountHandlers,
    getDiscountNumberTypes,
    getSalableEntityClasses
} from "../../services/sale";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import SearchField from "../../components/fields/SearchField";
import {withTranslation} from "react-i18next";
import UploadFilesField from "../../components/fields/UploadFilesField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import {
    getBusinessRoles
} from "../../services/user";
import DatePeriodField from "../../components/fields/DatePeriodField";
import {emptyDiscount} from "../../entities/discount";
import DurationSliderField from "../../components/fields/DurationSliderField";
import moment from "moment/moment";

@withTranslation()
@connect(() => ({}), {
    showMessage: messageDialogActions.show
})

class DiscountsPage extends React.Component {
    state = {
        search: {
            showActive: false,
            search: null,
            group: null/* for  filtering  by  group */
        },
        discount: emptyDiscount,
        dialogEditOpen: false,

        refreshTable: false,
        discountTypes: [],
        discountGroups: [],
        regions: [],
        parlours: [],
        activeTypes: [],
        entityClasses: [],
        numberTypes: [],
        handlers: [],
        businessRoles: []
    };

    columnData = [
        {id: 'name', label: 'Наименование'},
        {id: 'num', label: 'Номер'},
        {id: 'typeText', label: 'Тип'},
        {id: 'value', label: 'Размер'},
        {id: 'handler', label: 'Обработчик'},
        {id: 'numberRequired', label: 'Номер обязателен'},
        {id: 'activeText', label: 'Активная'},
        {id: 'defaultSelected', label: 'По умолчанию'},
        {id: 'businessUnitsNames', label: 'Салоны'},
        {id: 'businessUnitRegionsNames', label: 'Регионы'},
        {id: 'forSalableEntitiesText', label: 'Доступна для'},
        {id: 'special', label: 'Специальная'},
        {id: 'id', label: 'ID'},
    ];

    componentDidMount() {
        getDiscountTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        discountTypes: response.data,
                    })
                }
            });
        getDiscountGroups()
            .then(response => {
                if (response.success) {
                    this.setState({
                        discountGroups: response.data,
                    })
                }
            });

        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    })
                }
            });

        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });

        getDiscountActiveTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        activeTypes: response.data,
                    })
                }
            });

        getSalableEntityClasses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        entityClasses: response.data,
                    });
                }
            });

        getDiscountNumberTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        numberTypes: response.data,
                    })
                }
            });

        getDiscountHandlers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        handlers: response.data,
                    })
                }
            })

        getBusinessRoles()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessRoles: response.data,
                    })
                }
            })
    }

    handleAdd = () => {
        this.setState({
            discount: emptyDiscount,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        if (ok) {
            saveDiscount(this.state.discount)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            dialogEditOpen: false,
                            refreshTable: !this.state.refreshTable,
                        });
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleEdit = discount => {
        this.setState({
            discount,
            dialogEditOpen: true,
        })
    };

    handleChange = (prop, field) => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event?.target?.value ?? null;
        }

        if (field) {
            this.setState({
                discount: {
                    ...this.state.discount,
                    [prop]: {
                        ...this.state.discount[prop],
                        [field]: value,
                    },
                }
            });
        } else {
            this.setState({
                discount: {
                    ...this.state.discount,
                    [prop]: value,
                }
            });
        }

    };

    handleDurationChange = (event, value) => {
        this.setState({
            discount: {
                ...this.state.discount,
                durationFrom: value[0],
                durationTo: value[1],
            }
        });
    };

    markInactive = discount => {
        if (discount.active !== 'active') {
            return {
                background: '#ccc',
            };
        }
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getDiscounts({...search, active: '*'}, ...params);
    }

    getAutoApplyTypes = () => {
        return [
            // TODO: Должно забирать все варианты с бэкенда, но пока было реализовано только мобильное.
            {id: 'mobile', text: 'В мобильном приложении'},
            {id: 'widget_online', text: 'В виджете онлайн-записи'},
        ];
    }

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    }
    handleFilterChangeSearch = prop => event => {
        this.setState({
            refreshTable: !this.state.refreshTable,
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    }

    handleClickSearch = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    isSaveDisabled() {
        const { discount } = this.state;

        return !discount.name || !discount.type || !discount.type;
    }

    render() {
        const {
            search,
            dialogEditOpen,
            discount,
            refreshTable,
            discountTypes,
            discountGroups,
            parlours,
            regions,
            activeTypes,
            entityClasses,
            numberTypes,
            handlers,
            businessRoles
        } = this.state;
        const {t} = this.props;
        const $isUsingDiscount = false; // TODO Добавить проверку на использование

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <Grid item xs={12}>
                        <SelectFromItemsField
                            items={discountGroups}
                            nullable
                            onChange={this.handleFilterChangeSearch('group')}
                            fullWidth
                            label={t("DiscountGroups.group")}
                            valueField="id"
                            value={search.group}
                        />
                    </Grid>
                    <CheckboxField
                        label={t('Show only active')}
                        value={search.showActive}
                        onChange={this.handleFilterChangeSearch('showActive')}
                    />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Скидки">
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        disableSorting
                        rowStyleFunc={this.markInactive}
                        orderBy="num"
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog(false)}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Карточка скидки</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Наименование"
                                    value={discount.name}
                                    onChange={this.handleChange('name')}
                                    required
                                    error={!discount.name}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Название/Краткое описание/Промокод"
                                    value={discount.title}
                                    onChange={this.handleChange('title')}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Номер"
                                    value={discount.num}
                                    onChange={this.handleChange('num')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AnyField
                                    label="Тип"
                                    value={discount.type}
                                    onChange={this.handleChange('type')}
                                    readOnly={$isUsingDiscount}
                                    fullWidth
                                    select
                                >
                                    {discountTypes.map(type => <MenuItem key={type.id}
                                                                         value={type.id}>{type.text}</MenuItem>)}
                                </AnyField>
                            </Grid>
                            {discount.type === 'custom' &&
                                <Grid item xs={4}>
                                    <SelectFromItemsField
                                        items={handlers}
                                        label="Обработчик"
                                        value={discount.handler}
                                        onChange={this.handleChange('handler')}
                                        required={discount.type === 'custom'}
                                        readOnly={$isUsingDiscount}
                                        error={discount.type === 'custom' && !discount.handler}
                                        fullWidth
                                        nullable
                                        nullableText="Не используется"
                                    />
                                </Grid>
                            }
                            <Grid item xs={4}>
                                <AnyField
                                    label="Размер"
                                    value={discount.value}
                                    readOnly={$isUsingDiscount}
                                    onChange={this.handleChange('value')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectFromItemsField
                                    items={discountGroups}
                                    nullable
                                    onChange={this.handleChange('group')}
                                    fullWidth
                                    label={t("DiscountGroups.group")}
                                    valueField="id"
                                    value={discount.group}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <SelectFromItemsField
                                    items={activeTypes}
                                    value={discount.active}
                                    onChange={this.handleChange('active')}
                                    label="Активная"
                                    fullWidth
                                    textField="text"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <AnyField
                                    label="Макс. кол-во использований одним гостем"
                                    value={discount.maxLimit}
                                    onChange={this.handleChange('maxLimit')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DatePeriodField
                                    valueFrom={discount.availableFrom}
                                    valueTo={discount.availableTo}
                                    onChangeFrom={this.handleChange('availableFrom')}
                                    onChangeTo={this.handleChange('availableTo')}
                                    label="Срок действия"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DurationSliderField
                                    valueFrom={discount.durationFrom ?? 0}
                                    valueTo={discount.durationTo ?? 0}
                                    min={0}
                                    max={240}
                                    step={15}
                                    onChange={this.handleDurationChange}
                                    label="Продолжительность визита"></DurationSliderField>
                            </Grid>
                            <Grid item xs={12}>
                                <MultiSelectFromItemsField
                                    items={entityClasses}
                                    value={discount.forSalableEntities}
                                    onChange={this.handleChange('forSalableEntities')}
                                    textField="text"
                                    fullWidth
                                    label="Доступна для"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    value={discount.businessUnitsIds}
                                    onChange={this.handleChange('businessUnitsIds')}
                                    label="Только для салонов"
                                    select
                                    SelectProps={{
                                        multiple: true,
                                        displayEmpty: true,
                                        renderValue: selected => {
                                            if (selected.length === 0) {
                                                return <span>Все</span>;
                                            }

                                            return parlours.filter(item => selected.indexOf(item.id) > -1).map(item => item.name).join(', ');
                                        },
                                    }}
                                    fullWidth
                                >
                                    {parlours.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            <Checkbox checked={discount.businessUnitsIds.indexOf(item.id) !== -1}/>
                                            <ListItemText primary={item.name}/>
                                        </MenuItem>
                                    ))}
                                </AnyField>
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    value={discount.businessUnitRegionsIds}
                                    onChange={this.handleChange('businessUnitRegionsIds')}
                                    label="Только для регионов"
                                    select
                                    SelectProps={{
                                        multiple: true,
                                        displayEmpty: true,
                                        renderValue: selected => {
                                            if (selected.length === 0) {
                                                return <span>Все</span>;
                                            }

                                            return regions.filter(item => selected.indexOf(item.id) > -1).map(item => item.name).join(', ');
                                        },
                                    }}
                                    fullWidth
                                >
                                    {regions.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            <Checkbox
                                                checked={discount.businessUnitRegionsIds.indexOf(item.id) !== -1}/>
                                            <ListItemText primary={item.name}/>
                                        </MenuItem>
                                    ))}
                                </AnyField>
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteMultiSelectField
                                    value={discount.businessRolesIds}
                                    onChange={this.handleChange('businessRolesIds')}
                                    valueField="id"
                                    textField="name"
                                    fullWidth
                                    label="Только для ролей"
                                    options={businessRoles}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectFromItemsField
                                    items={this.getAutoApplyTypes()}
                                    value={discount.autoApply}
                                    onChange={this.handleChange('autoApply')}
                                    label="Автоматически применять"
                                    fullWidth
                                    textField="text"
                                    nullable
                                    nullableText="Не применять"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectFromItemsField
                                    items={numberTypes}
                                    value={discount.numberType}
                                    onChange={this.handleChange('numberType')}
                                    label="Тип номера скидки"
                                    fullWidth
                                    textField="text"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CheckboxField
                                    label="Заполнение номера обязательно"
                                    value={discount.numberRequired}
                                    onChange={this.handleChange('numberRequired')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CheckboxField
                                    label="Выбрана по умолчанию"
                                    value={discount.defaultSelected}
                                    onChange={this.handleChange('defaultSelected')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CheckboxField
                                    label="Специальная"
                                    value={discount.special}
                                    onChange={this.handleChange('special')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CheckboxField
                                    label="Отображать в мобильном приложении"
                                    value={discount.showInMobile}
                                    onChange={this.handleChange('showInMobile')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CheckboxField
                                    label="Учитывать скидку при смс верификации при закрытии визита"
                                    value={discount.verifySmsClosingVisit}
                                    onChange={this.handleChange('verifySmsClosingVisit')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CheckboxField
                                    label="Может применяться с дополнительным прайсом"
                                    value={discount.allowUseWithClubPrice}
                                    onChange={this.handleChange('allowUseWithClubPrice')}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <UploadFilesField
                                    label="Изображение"
                                    value={discount.image}
                                    id={discount.id}
                                    onChange={this.handleChange('image')}
                                    image
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <AnyField
                                    label="Описание"
                                    value={discount.externalDescription}
                                    onChange={this.handleChange('externalDescription')}
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} disabled={this.isSaveDisabled()}
                                color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
}

export default DiscountsPage;