import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import {
    getSpendingTypeCategories,
    saveSpendingTypeCategory
} from "../../services/moneyFlow";
import SearchField from "../../components/fields/SearchField";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

const columnData = [
    { id: 'name', label: 'Наименование' },
];

const emptySpendingTypeCategory = {
    id: null,
    name: null,
};

export default
@connect(state => ({
}),{
    showMessage: messageDialogActions.show
})
@withStyles(styles)
class SpendingTypeCategoriesListPage extends React.Component
{
    state = {
        spendingTypeCategory: emptySpendingTypeCategory,
        dialogEditOpen: false,
        refreshTable: false,
        search: {
            search: null
        }
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getSpendingTypeCategories(search, ...params);
    };

    handleAdd = () => {
        this.setState({
            spendingTypeCategory: emptySpendingTypeCategory,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        saveSpendingTypeCategory(this.state.spendingTypeCategory)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleEdit = spendingTypeCategory => {
        this.setState({
            spendingTypeCategory,
            dialogEditOpen: true,
        })
    };

    handleChange = (prop) => event => {
        this.setState({
            spendingTypeCategory: {
                ...this.state.spendingTypeCategory,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    render() {
        const { dialogEditOpen, spendingTypeCategory, refreshTable, search } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Категории статей расходов">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog(false)}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Категория статей расходов</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Наименование категорий"
                                    value={spendingTypeCategory.name}
                                    onChange={this.handleChange('name')}
                                    required
                                    error={!spendingTypeCategory.name}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} disabled={!spendingTypeCategory.name} color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
}
