import {withRouter} from "react-router";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    withStyles
} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {parseXLS} from "../../services/common";
import {duplicateSpendings} from "../../services/moneyFlow";
import UploadFilesField from "../../components/fields/UploadFilesField";
import ActionButton from "../../components/button/ActionButton";
import {withTranslation} from "react-i18next";
import CheckboxField from "../../components/fields/CheckboxField";
import {moneyFlowKeys} from "../../entities/importKeys";
import {spendingXLSXRowsToDTOArray} from "../../utils/dto";


export default @withRouter
@withTranslation()
@connect(state => ({
    token: state.auth.token,
}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
@withStyles(theme => ({}))
class ImportDialogSpending extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        importFunc: PropTypes.func,
        title: PropTypes.string
    };

    state = {
        importFileName: null,
        importSpending: [],
        importError: null,
        loading: false,
        existsDealsDialogOpen: false,
        duplicatedDealIds: []
    }


    handleChangeFile = (event) => {
        this.setState({
            importSpending: [],
            importFileName: null,
            importError: null
        });
        let file = event.target.files[0];
        parseXLS(file).then((json) => {
            let DTOArray = spendingXLSXRowsToDTOArray(json, moneyFlowKeys);
            if (Array.isArray(DTOArray)) {
                this.setState({
                    importSpending: DTOArray,
                    importFileName: file.name
                });
            } else {
                this.setState({
                    importError: DTOArray,
                });
            }
        });
    }


    duplicateRows = () => () => {
        const {t} = this.props
        const {importSpending, duplicatedDealIds} = this.state
        duplicatedDealIds.forEach((value) => {
            if (!value.duplicate)
                delete importSpending[value.id];
        });
        let spendings = {...importSpending};
        duplicateSpendings({'rows': spendings, 'isDuplicating': true})
            .then(response => {
                if (response.success) {
                    this.onClose();
                    this.props.showMessage(t("Rows imported") + response.data.count);
                    this.setState({
                        importSpending: [],
                        importFileName: null,
                        loading: false,
                        duplicatedDealIds: [],
                        existsDealsDialogOpen: false

                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    importRows = () => {
        const {importFunc, t} = this.props;
        this.setState({
            loading: true,
        });
        importFunc({'rows': this.state.importSpending})
            .then(response => {
                if (response.success === 'exists') {

                    let duplicatedDealIds = [];
                    response.deals.forEach((value, key) => {
                        duplicatedDealIds.push({id: value, duplicate: false})
                    })


                    this.setState({
                        duplicatedDealIds: duplicatedDealIds,
                        existsDealsDialogOpen: true,
                    })
                } else if (response.success) {
                    this.onClose();
                    this.props.showMessage(t("Rows imported") + response.data.count);
                    this.setState({
                        importSpending: [],
                        importFileName: null,
                        loading: false
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handleChangeDulicateDeal = (idx) => () => {
        const {duplicatedDealIds} = this.state


        duplicatedDealIds.forEach((value,index)=>{
            if(value.id === idx)
                duplicatedDealIds[index] = {
                    ...duplicatedDealIds[index],
                    duplicate: !duplicatedDealIds[index].duplicate
                }
        })

        this.setState((prevState) => {
            return ({
                ...prevState,
                duplicatedDealIds: duplicatedDealIds
            })
        });
    }

    render() {
        const {
            importSpending,
            importFileName,
            importError,
            loading,
            existsDealsDialogOpen,
            duplicatedDealIds
        } = this.state;
        const {open, title, t, classes} = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.onClose}
                    fullWidth={true}
                    maxWidth={"sm"}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} className={classes.container}>
                            <Grid item xs={4}>
                                <UploadFilesField
                                    id="importFile"
                                    label={t("File")}
                                    value={null}
                                    onChange={this.handleChangeFile}
                                    notLoad
                                />
                            </Grid>
                            <Grid item xs={8}>
                                {!!importSpending.length &&
                                <p>
                                    {t("Filename")}: {importFileName}<br/>
                                    {t("Rows will be imported")}: {importSpending.length}
                                </p>
                                }
                                {!!importError &&
                                <p style={{color: 'red'}}>{importError}</p>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <p>
                                {!!loading && <CircularProgress/>}
                            </p>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ActionButton onClick={this.importRows} disabled={!importSpending.length} color="primary"
                                      fullWidth={false}>{t("Do import")}</ActionButton>
                        <ActionButton onClick={this.onClose} color="primary"
                                      fullWidth={false}>{t("Close")}</ActionButton>
                    </DialogActions>
                </Dialog>
                <Dialog open={existsDealsDialogOpen}
                        fullWidth={true}
                        maxWidth={"sm"}
                >
                    <DialogTitle>{t("Existing deals")}</DialogTitle>
                    <DialogContent>
                        <Grid>
                            {(duplicatedDealIds.length) > 0 &&
                            (duplicatedDealIds.reduce((results, value) => {
                                results[value.id] = importSpending[value.id];
                                return results;
                            }, [])).map((value, key) => {
                                return (<div>
                                    <Grid style={{ marginBottom: '1em'}}>
                                        <div style={{flexGrow: 1,float: "left", width: "30em", height: "3em"}}>
                                            Строка
                                            : {key + 2} - {value.paymentTypeName} - {value.applyAt} - {value.amount}
                                        </div>
                                        <div style={{
                                            marginTop: '-2.3em',
                                            marginLeft: '-1em',float: "left", width: "3em", height: "3em"
                                        }}>
                                            <CheckboxField
                                                value={duplicatedDealIds.find(el=> el.id === key).duplicate}
                                                onChange={this.handleChangeDulicateDeal(key)}
                                            />
                                        </div>
                                    </Grid>
                                </div>);
                            })
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ActionButton color="primary" fullWidth={false}
                                      onClick={this.duplicateRows()}>Импортировать</ActionButton>
                        <ActionButton onClick={() => {
                            this.setState({existsDealsDialogOpen: false})
                        }} color="primary" fullWidth={false}>{t("Close")}</ActionButton>
                    </DialogActions>
                </Dialog>
            </div>
        );

    }
}