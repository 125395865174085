import React, {useCallback, useContext, useMemo} from 'react';
import {dataTableGroupValueStrategy, getFalseResult} from "../../../../services/common";
import {processMomentFieldInObject} from "../../../../utils/moment-utils";
import {getFinanceReport} from "../../../../services/moneyFlow";
import DataTable from "../../../../components/data-table/DataTable";
import Context from "../Context";
import useColumnData from "./useColumnData";

const Table = () => {
    const { refreshTable, search, isValidPeriod, isValidBusinessUnits, setTableData } = useContext(Context);

    const columnData = useColumnData();

    const getTableData = useCallback(() => {
        if (isValidPeriod && isValidBusinessUnits) {
            return getFinanceReport(processMomentFieldInObject(search, ['dayStart', 'dayEnd', 'month']));
        }

        return getFalseResult();
    }, [isValidBusinessUnits, isValidPeriod, search])

    const groupHiddenColumns = useMemo(() => [
        { id: 'businessUnitId', groupStrategy: dataTableGroupValueStrategy.same('businessUnitId') }
    ], []);

    const handleDataFetch = useCallback(response => {
        setTableData(() => response.data)
    }, [setTableData])

    return (
        <DataTable
            columnData={columnData}
            dataFunc={getTableData}
            selector={false}
            refresh={refreshTable}
            pagination={false}
            disableSorting
            groupBy="businessUnitName"
            groupHiddenColumns={groupHiddenColumns}
            autoload={false}
            fixedOrderGroupKey="businessUnitId"
            fixedOrderGroupValues={search.businessUnitIds}
            stickyLeftColumn
            stickyHeader
            onDataFetch={handleDataFetch}
        />
    );
};

export default Table;