import moment from "moment";

export function getMastersName(visitFacility) {
    if (visitFacility) {
        return visitFacility[0].master.map(item => item.name).join(', ');
    }

    return '';
}

export function getVisitTimeString(visitFacility) {
    let visitTime = '';

    if (visitFacility && moment.isMoment(visitFacility[0].start)) {
        let visitEnd = visitFacility[0].start.clone();

        visitTime += visitFacility[0].start.format('DD.MM.YYYY');

        visitFacility.forEach(( facility ) => {
            if (facility.duration) {
                visitEnd.add(facility.duration, 'hours');
            }
        })

        visitTime += ' c ' + visitFacility[0].start.format('HH:mm') + ' до ' + visitEnd.format('HH:mm');
    }

    return visitTime;
}

export function getVisitDurationHours(visitFacilities) {
    let duration = 0;

    visitFacilities.forEach((facility) => {
        if (facility.duration) {
            duration += facility.duration;
        }
    })

    return duration;
}

export function getVisitDurationMinutes(visitFacilities) {
    return getVisitDurationHours(visitFacilities) * 60;
}
