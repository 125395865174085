import React from 'react';
import * as PropTypes from 'prop-types';
import moment from "moment";
import {Calendar} from "@material-ui/pickers";
import {Paper} from "@material-ui/core";

export default
class CalendarField extends React.Component
{
    static propTypes = {
        value: PropTypes.instanceOf(moment),
        onChange: PropTypes.func,
    }

    render() {
        const { value, onChange } = this.props;

        return (
            <Paper style={{ overflow: 'hidden' }}>
                <Calendar date={value} onChange={onChange} allowKeyboardControl={false}/>
            </Paper>
        );
    }
}
