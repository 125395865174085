import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getPayDocCommissionReport,
    navLinks
} from "../../services/moneyFlow";
import moment from "moment";
import {FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import DateMonthField from "../../components/fields/DateMonthField";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import CheckboxField from "../../components/fields/CheckboxField";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {getBusinessUnits} from "../../services/organization";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

export default @connect(() => ({}), {
    showMessage: messageDialogActions.show,
})
@withTranslation()
class PayDocCommissionReport extends React.Component {

    constructor(props) {
        super(props);

        let businessUnitIds = [];

        if (props.currentParlour) {
            businessUnitIds.push(props.currentParlour.id);
        }

        const querySearch = getFilterFromQuery();

        let startedAt = moment().startOf('week');
        let endedAt = moment();
        let dateType = 'period';
        let month = moment().add(-10, 'day').startOf('month');

        if (querySearch) {
            if (querySearch.startedAt) {
                startedAt = moment(querySearch.startedAt);
            }

            if (querySearch.endedAt) {
                endedAt = moment(querySearch.endedAt);
            }

            if (querySearch.businessUnitIds) {
                businessUnitIds = querySearch.businessUnitIds;
            }

            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }

            if (querySearch.month) {
                month = moment(querySearch.month);
            }
        }

        this.state = {
            search: {
                startedAt: startedAt,
                endedAt: endedAt,
                month: month,
                dateType: dateType,
                businessUnitIds: businessUnitIds
            },
            show: {
                incomingCommission: true,
                spendingCommission: true
            },
            businessUnits: [],
            report: {
                spending: {
                    columns: [],
                    data: []
                },
                incoming: {
                    columns: [],
                    data: []
                }
            }
        };

    }

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });

    }

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeShow = prop => event => {
        this.setState({
            show: {
                ...this.state.show,
                [prop]: event.target.value,
            }
        });
    };

    handleClickFilter = () => {
        pushFilterToQuery(processMomentFieldInObject(this.state.search, ['startedAt', 'endedAt', 'month']));
        this.getTableData()
    };

    getTableData = () => {
        const {search} = this.state;
        return getPayDocCommissionReport(processMomentFieldInObject(search, ['startedAt', 'endedAt', 'month']))
            .then(response => {
                if (response.success) {
                    this.setState({
                        report: response.data
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    render() {
        const {t} = this.props;
        const {search, show, report, businessUnits} = this.state;
        const disableFilterButton =
            !search.businessUnitIds.length
            || (search.dateType === 'period' && (!search.startedAt || !search.endedAt))
            || (search.dateType === 'month' && (!search.month))

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.dateType}
                            onChange={this.handleChange('dateType')}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="period" label="за период"/>
                            <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
                        </RadioGroup>
                    </FormControl>
                    {search.dateType === 'period' ?
                        <DatePeriodField2
                            valueFrom={search.startedAt}
                            valueTo={search.endedAt}
                            maxDate={moment()}
                            onChangeFrom={this.handleChange('startedAt')}
                            onChangeTo={this.handleChange('endedAt')}
                            checkValuesOrder
                        /> :
                        <DateMonthField
                            value={search.month}
                            onChange={this.handleChange('month')}
                            fullWidth
                        />
                    }
                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        label={t('Business unit')}
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                    />
                    <CheckboxField
                        label={t('Revenue')}
                        value={show.incomingCommission}
                        onChange={this.handleChangeShow('incomingCommission')}
                    />
                    <CheckboxField
                        label={t('Spending')}
                        value={show.spendingCommission}
                        onChange={this.handleChangeShow('spendingCommission')}
                    />
                    <ActionButton onClick={this.handleClickFilter} disabled={disableFilterButton}>{t("Filter")}</ActionButton>
                </LeftBar>
                <Content title={t("PayDoc Commission Report")}>
                    {report.incoming.data.length && show.incomingCommission
                        ?
                        <React.Fragment key={'incomingCommission'}>
                            <Typography variant="h6" gutterBottom>{t('Revenue')}</Typography>
                            <DataTableLocal
                                columnData={report.incoming.columns}
                                data={report.incoming.data}
                                selector={false}
                                pagination={false}
                                disableSorting
                            />
                        </React.Fragment>
                        : null
                    }
                    {report.spending.data.length && show.spendingCommission
                        ?
                        <React.Fragment key={'spendingCommission'}>
                            <Typography variant="h6" gutterBottom>{t('Spending')}</Typography>
                            <DataTableLocal
                                columnData={report.spending.columns}
                                data={report.spending.data}
                                selector={false}
                                pagination={false}
                                disableSorting
                            />
                        </React.Fragment>
                        : null
                    }
                </Content>
            </React.Fragment>
        );
    }
}
