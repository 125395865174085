import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import moment from "moment";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import _ from "lodash";
import {loadSystemTelegramChannelsList, navLinks, toggleChannelSystemStatus} from "../../services/settings";
import {getEvents, getRegionEvents} from "../../services/common";
import AnyField from "../../components/fields/AnyField";
import CheckboxField from "../../components/fields/CheckboxField";

export default
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class SystemTelegramChannelsPage extends React.Component {
    state = {
        search: {
            title: null,
            onlyActive: true
        },
        refreshTable: false,
        businessUnitEvents: [],
        regionEvents: []
     };

    columnData = [
        {id: 'telegram_id', label: 'Id telegram channel'},
        {id: 'title', label: 'Title'},
        {id: 'deactivated', label: 'Deactivated'},
        {id: 'type', label: 'Type'},
        {
            id: 'system_channel',
            label: 'System channel',
            processValue: value => {
                const { t } = this.props
                return (value) ? value : t('No')
            }
        },
        {
            id: 'business_unit_events',
            label: 'Business unit events',
            processValue: value => {
                const { t } = this.props
                let returnArr = []

                if ( Array.isArray(value) )
                    for (let id in value) {
                        /**
                         * string businessUnit.business_unit
                         * array[string] businessUnitRegion.events
                         */
                        let businessUnit = value[id]

                        if (
                            typeof businessUnit === 'object' &&
                            businessUnit?.business_unit &&
                            businessUnit?.events && businessUnit?.events.length > 0
                        ) {
                            let eventsStr = businessUnit?.events.map(
                                val => {
                                    if (this.state.businessUnitEvents && this.state.businessUnitEvents.length > 0) {
                                        let businessUnitEventSelected = this.state.businessUnitEvents.filter(businessUnitEvent => (businessUnitEvent.id === val))[0];
                                        return (businessUnitEventSelected?.text) ? businessUnitEventSelected?.text : val
                                    } else
                                        return val
                                }
                            ).join(', ');

                            returnArr.push(<p key={businessUnit?.business_unit}>{t('Parlour')} {businessUnit?.business_unit}: {eventsStr} </p>)
                        }
                    }

                return returnArr
            }
        },
        {
            id: 'region_events',
            label: 'Region events',
            processValue: value => {
                const { t } = this.props
                let returnArr = []

                if ( Array.isArray(value) )
                    for (let id in value) {
                        /**
                         * string businessUnitRegion.business_unit_region
                         * array[string] businessUnitRegion.region_events
                         */
                        let businessUnitRegion = value[id]

                        if (
                            typeof businessUnitRegion === 'object' &&
                            businessUnitRegion?.business_unit_region &&
                            businessUnitRegion?.region_events && businessUnitRegion?.region_events.length > 0
                        ) {
                            let eventsStr = businessUnitRegion?.region_events.map(
                                val => {
                                    if (this.state.regionEvents && this.state.regionEvents.length > 0) {
                                        let regionEventSelected = this.state.regionEvents.filter(regionEvent => (regionEvent.id === val))[0];
                                        return (regionEventSelected?.text) ? regionEventSelected?.text : val
                                    } else
                                        return val
                                }
                            ).join(', ');

                            returnArr.push(<p key={businessUnitRegion?.business_unit_region}>{t('Region')} {businessUnitRegion?.business_unit_region}: {eventsStr} </p>)
                        }
                    }

                return returnArr
            }
        },
        {
            id: "system_data",
            label: "Change channel system status",
            processValue: systemData => {
                const { t } = this.props

                if (typeof systemData === 'object')
                    if (systemData.can_be_system)
                        return <ActionButton onClick={ () => { this.changeChannelSystemStatus(systemData.channel_id) } }>{t('Toggle channel system status')}</ActionButton>
                    else
                        return <p>{t('Can not be system channel because of the attached events')}</p>
            }
        }
    ];

    changeChannelSystemStatus = (channelId) => {
        const { showInfo, showError, t } = this.props

        toggleChannelSystemStatus(channelId).then(response => {
            if (response.success)
                if (response.data.result)
                    showInfo(t('Channel system status changed'))
                else
                    showError(t('Channel system didnt change'))
            else
                showError('Error: ')

            this.setState({
                refreshTable: !this.state.refreshTable
            })
        })
    }

    getTableData = (...params) => {
        const { search } = this.state;
        return loadSystemTelegramChannelsList(search, ...params);
    };

    componentDidMount() {
        Promise.all([getEvents(), getRegionEvents()]).then(responses => {
            let addState = {}

            if (responses[0].success)
                addState.businessUnitEvents = responses[0].data

            if (responses[1].success)
                addState.regionEvents = responses[1].data

            if (!_.isEmpty(addState))
                this.setState({
                    ...addState,
                    refreshTable: !this.state.refreshTable
                })
        })
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    render() {
        const {t} = this.props;
        const {refreshTable, search} = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <AnyField
                    value={search.title}
                    onChange={this.handleChange('title')}
                    label={t('Channel name')}
                    fullWidth
                />
                <CheckboxField
                    label={t('Show active')}
                    value={search.onlyActive}
                    onChange={this.handleChange('onlyActive')}
                />
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
            </LeftBar>
            <Content title={t("Telegram channels")}>
                <DataTable
                    dataFunc={this.getTableData}
                    selector={false}
                    columnData={this.columnData}
                    refresh={refreshTable}
                />
            </Content>
        </React.Fragment>
    }
}
