export const workingHoursType = {
    fulltime: 'fulltime',
    from_8_to_18: 'from_8_to_18',
    from_9_to_21: 'from_9_to_21',
    from_10_to_22: 'from_10_to_22',
};

export const workingHours = [
    {
        id: workingHoursType.fulltime,
        name: 'Полный день',
        hour: null,
        duration: null,
    },
    {
        id: workingHoursType.from_8_to_18,
        name: 'с 8 до 18',
        hour: 8,
        duration: 600,
    },
    {
        id: workingHoursType.from_9_to_21,
        name: 'с 9 до 21',
        hour: 9,
        duration: 720,
    },
    {
        id: workingHoursType.from_10_to_22,
        name: 'с 10 до 22',
        hour: 10,
        duration: 720,
    },
];
