import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getPayDocsBalanceCheckReport, navLinks} from "../../services/moneyFlow";
import moment from "moment";
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import {withTranslation} from "react-i18next";
import DateMonthField from "../../components/fields/DateMonthField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import {formatMoney} from "../../services/common";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";

const columnData = [
    { id: 'buName', label: 'Business unit' },
    { id: 'monthName', label: 'Period' },
    { id: 'prevPDBalance', label: 'Previous month balance', processValue: formatMoney },
    {
        id: 'refill',
        label: 'PayDocRefill',
        subColumns: [
            { id: 'cash', label: 'pay.types.cash', processValue: formatMoney },
            { id: 'cashless', label: 'pay.types.cashless', processValue: formatMoney },
            { id: 'checking_account', label: 'pay.types.checking_account', processValue: formatMoney },
        ]
    },
    {
        id: 'served',
        label: 'Served',
        subColumns: [
            { id: 'summaryPaydocOwn', label: 'f.PDOwner', processValue: formatMoney },
            { id: 'servedByAnotherBusinessUnitAmount', label: 'Own paydocs in other business units', processValue: formatMoney},
        ]
    },
    {
        id: 'burned',
        label: 'Burned amount',
        subColumns: [
            { id: 'payDocBurnedPaymentsOwner', label: 'f.PDBurnPaymentsOwner', processValue: formatMoney },
            { id: 'payDocBurned', label: 'f.PDBurnExpired', processValue: formatMoney },
            { id: 'amountBurnFrozenInOtherBu', label: 'Own paydocs in other business units', processValue: formatMoney },
        ]
    },
    { id: 'refund', label: 'Money back', processValue: formatMoney },
    { id: 'correction', label: 'Correction', processValue: formatMoney },
    { id: 'remainderCalculation', label: 'Remainder calculation', processValue: formatMoney },
    { id: 'currentPDBalance', label: 'Balance', processValue: formatMoney },
    { id: 'frozen', label: 'Frozen', processValue: formatMoney },
    { id: 'erpBalance', label: 'ERP Balance', processValue: formatMoney },
    { id: 'difference', label: 'Difference', processValue: formatMoney },
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@withStyles(styles)
@withTranslation()
class PayDocsBalanceCheckReport extends React.Component {
    state = {
        search: {
            month: moment.now(),
            businessUnitIds: [],
            startDate: moment.now(),
            endDate: moment.now(),
            dateType: 'month'
        },
        refreshTable: false,
        businessUnits: [],
        progress: false,
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
    }

    handleChangeSearch = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = () => {
        const {search} = this.state;
        return getPayDocsBalanceCheckReport({
                ...search,
                month: moment(search.month).format('YYYY-MM-DD'),
                startDate: moment(search.startDate).format('YYYY-MM-DD'),
                endDate: moment(search.endDate).format('YYYY-MM-DD'),
            }
        );
    }

    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits, progress } = this.state;
        const disabled = !search.businessUnitIds.length || !search.month
        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.dateType}
                            onChange={this.handleChangeSearch('dateType')}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="period" label="за период"/>
                            <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
                        </RadioGroup>
                    </FormControl>
                    {search.dateType === 'month' &&
                        <DateMonthField
                            value={search.month}
                            onChange={this.handleChangeSearch('month')}
                            label={t('Period')}
                            fullWidth
                        />
                    }
                    {search.dateType === 'period' &&
                        <DatePeriodField2
                            onChangeTo={this.handleChangeSearch('endDate')}
                            onChangeFrom={this.handleChangeSearch('startDate')}
                            valueFrom={search.startDate}
                            valueTo={search.endDate}
                            label={t('Period')}
                            maxDate={moment.now()}
                        />
                    }
                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        onChange={this.handleChangeSearch('businessUnitIds')}
                        label={t('Business unit')}
                        value={search.businessUnitIds}
                        fullWidth

                    />
                    <ActionButton
                        onClick={this.handleClickFilter}
                        disabled={disabled}
                    >
                        {t('Filter')}
                    </ActionButton>
                </LeftBar>
                <Content title={t('PayDocs balance check report')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        pagination={false}
                        autoload={false}
                    />
                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
