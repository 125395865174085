import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/organization";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import SearchField from "../../components/fields/SearchField";
import {withTranslation} from "react-i18next";
import {
    createOrUpdateInvestor,
    emptyInvestor,
    getInvestors,
} from "../../services/organization";
import CheckboxField from "../../components/fields/CheckboxField";

const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
});

const columnData = [
    {id: 'name', label: 'Name'}
];

export default @connect(state => ({}), {
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class InvestorsPage extends React.Component {
    state = {
        dialogEditOpen: false,
        refreshTable: false,
        search: {
            search: null
        },
        investor: emptyInvestor
   };

    getTableData = (...params) => {
        const { search } = this.state;
        return getInvestors(search.search, ...params);
    };

    handleAdd = () => {
        this.setState({
            investor: emptyInvestor,
            dialogEditOpen: true
        })
    };

    handleCloseEditDialog = () => {
       this.setState({
           dialogEditOpen: false,
           investor: emptyInvestor
       })
    };

    handleOpenEditDialog = investor => {
        this.setState({
            investor,
            dialogEditOpen: true
        })
    };

    handleChangeInvestor = (prop) => event => {
        this.setState({
            investor: {
                ...this.state.investor,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    handleCreateOrUpdateInvestor = () => {
        const { investor } = this.state

        createOrUpdateInvestor(investor)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                        investor: emptyInvestor
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    render() {
        const { dialogEditOpen, investor, refreshTable, search } = this.state;
        const { t } = this.props

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label={t('Search')}
                    />
                    <br/>
                    <br/>
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title={t("Investors")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleOpenEditDialog}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                    maxWidth="xs" fullWidth
                >
                    <DialogTitle id="dialog-title">{t('Investor')}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    label={t("Name")}
                                    value={investor.name}
                                    onChange={this.handleChangeInvestor('name')}
                                    required
                                    error={!investor.name}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <CheckboxField
                                name="canHaveMoney"
                                label="Может иметь деньги"
                                value={investor.canHaveMoney}
                                onChange={this.handleChangeInvestor('canHaveMoney')}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCreateOrUpdateInvestor}
                                disabled={!investor.name}
                                color="primary"
                        >
                            {t('Ok')}
                        </Button>
                        <Button
                            onClick={this.handleCloseEditDialog}
                            color="primary"
                        >
                            {t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}
