import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {
    forcedExportDashboardToGoogle,
    getDashBoardGoogleLink,
    getDashboardReport,
    navLinks
} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {DialogActions, DialogContent, InputAdornment, MenuItem, Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {getFalseResult} from "../../services/common";
import _ from "lodash";
import XLSX from "xlsx";
import {withTranslation} from "react-i18next";
import NumberFormatCount from "../../components/fields/NumberFormatCount";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {connect} from "react-redux";
import DateMonthField from "../../components/fields/DateMonthField";
import {hasRole} from "../../services/user";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    table: {
        marginBottom: 30
    },
});

export default
@withStyles(styles)
@withTranslation()
@connect(null, {
    showMessage: messageDialogActions.show,
})
class DashboardReport extends React.Component {
    state = {
        search: {
            dayStart: moment(),
            dayEnd: moment(),
            businessUnitIds: [],
            days: null
        },
        businessUnits: [],
        columnData: [],
        data: [],
        progress: false,
        link: null,

        chartSelectedLines: [],
        chartBusinessUnits: [],
        chartData: [],

        isForcedExportDialogOpen: false,
        forcedExport: {
            month: null
        }
    };

    chartLines = [
        'totalIncome',
        'goodsIncome',
        'visitsIncome'
    ]

    chartColors = [
        "#0088FE",
        "#00C49F",
        "#FFBB28",
        "#FF8042",
        "#85c0ff",
        "#56BD15",
        "#bd1515",
        "#531886",
        "#fa67ff"
    ]

    componentDidMount() {
        const {t} = this.props;

        this.setState({
            chartSelectedLines: [this.chartLines[0]]
        })

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: t('Not chosen')},
                            ...response.data
                        ],
                    });
                }
            });

        getDashBoardGoogleLink()
            .then(response => {
                if (response.success) {
                    this.setState({
                        link: response.data.link
                    });
                }
            });

        //this.getTableData();
    }

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleChangeState = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            [prop]: value,
        });
    };

    handleClickFilter = () => {
        this.getTableData();
    };

    getTableData = () => {
        const {search} = this.state;

        if (search.dayStart && search.dayEnd && search.businessUnitIds.length) {
            return getDashboardReport(search.dayStart.format('YYYY-MM-DD'), search.dayEnd.format('YYYY-MM-DD'), search.businessUnitIds, search.days)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            columnData: response.data.columns,
                            data: response.data.data,
                            chartBusinessUnits: response.data.chartBusinessUnits,
                            chartData: response.data.chartData
                        });
                    }
                });
        } else {
            return getFalseResult();
        }
    };

    handleExport = () => {
        const {columnData, data} = this.state;

        const dataXls = [
            //First row - headers
            columnData.map(item => item.label),
        ];

        data.forEach(businessUnit => {
            dataXls.push([businessUnit.name]);
            businessUnit.data.forEach(row => {
                dataXls.push(
                    columnData.map(item => _.get(row, item.id))
                );
            });
        });

        const worksheet = XLSX.utils.aoa_to_sheet(dataXls);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Dashboard");
        XLSX.writeFile(new_workbook, `Dashboard.xlsx`);
    }

    getChartLines = () => {
        const { chartLines, chartColors } = this
        const { t } = this.props
        const { chartSelectedLines, chartBusinessUnits } = this.state
        let colorIndex = 0
        let keyIndex = 0
        let lines = []

        chartLines.forEach((chartLine) => {
            if (chartSelectedLines.indexOf(chartLine) > - 1 ) {
                chartBusinessUnits.forEach((buChart, buIndex) => {
                    lines.push(
                        <Line
                            key={keyIndex}
                            name={`${buChart.name} - ${t("dashboardChart." + chartLine)}`}
                            unit={` ${buChart.currencies ? buChart.currencies : "" }`}
                            type="monotone"
                            dataKey={chartLine + buIndex}
                            stroke={chartColors[colorIndex]}
                        />
                    )

                    keyIndex++
                    colorIndex++
                    if (colorIndex >= chartColors.length) {
                        colorIndex = 0
                    }
                })
            }
        })

        return lines
    }

    renderForcedExportDialog = () => {
        const { isForcedExportDialogOpen, forcedExport } = this.state
        const { t } = this.props

        return <Dialog
            open={isForcedExportDialogOpen}
            onClose={ () => this.setState({isForcedExportDialogOpen: false})}
            fullWidth
        >
            <DialogTitle>{t('Forced export')}</DialogTitle>
            <DialogContent>
                <DateMonthField
                    value={forcedExport.month}
                    onChange={this.handleChangeForcedExport('month')}
                />
            </DialogContent>
            <DialogActions>
                <ActionButton
                    onClick={this.forcedExportDashboardToGoogle}
                    disabled={!forcedExport.month}
                >
                    {t('OK')}
                </ActionButton>
                <ActionButton
                    onClick={() => this.setState({isForcedExportDialogOpen: false})}
                >
                    {t('Cancel')}
                </ActionButton>
            </DialogActions>
        </Dialog>
    }

    forcedExportDashboardToGoogle = () => {
        const { forcedExport } = this.state
        const { t } = this.props

        this.setState({progress: true})

        forcedExportDashboardToGoogle(forcedExport)
            .then((response) => {
                    this.setState({
                        progress: false,
                        isForcedExportDialogOpen: false
                    })

                    if (response.success) {
                        this.props.showMessage(t('Dashboard exported'));
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                }
            )
    }

    handleChangeForcedExport = prop => event => {
        let value = null;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            forcedExport: {
                ...this.state.forcedExport,
                [prop]: value,
            }
        });
    }

    handleClickOpenForcedExportDialog = () => {
        this.setState({isForcedExportDialogOpen: true})
    }

    render() {
        const { classes, t } = this.props;
        const { search, businessUnits, columnData, data, progress, link } = this.state
        const { chartBusinessUnits, chartData, chartSelectedLines } = this.state;
        const disabled = !search.dayStart || !search.dayEnd || !search.businessUnitIds.length;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.dayStart}
                        valueTo={search.dayEnd}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('dayStart')}
                        onChangeTo={this.handleChange('dayEnd')}
                        label={t("Show changes by date interval")}
                        className={classes.filterControl}
                    />
                    <AnyField
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        label={t("Parlours")}
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>{t('Not chosen')}</span>;
                                }

                                return businessUnits.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                            },
                        }}
                        className={classes.filterControl}
                        fullWidth
                    >
                        {businessUnits.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={search.businessUnitIds.indexOf(item.id) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </AnyField>
                    <AnyField
                        label={t("Show sum values every days")}
                        value={search.days}
                        onChange={this.handleChange('days')}
                        fullWidth
                        InputProps={{
                            inputComponent: NumberFormatCount,
                            endAdornment: <InputAdornment position="end">{t('days')}</InputAdornment>
                        }}
                    />
                    <AnyField
                        value={chartSelectedLines}
                        onChange={this.handleChangeState("chartSelectedLines")}
                        label={t("Parameters displayed on the graph")}
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>{t('Not chosen')}</span>;
                                }

                                return this.chartLines.filter(item =>
                                    chartSelectedLines.indexOf(item) > -1
                                ).map(item => t("dashboardChart." + item)).join(', ');
                            },
                        }}
                        className={classes.filterControl}
                        fullWidth
                    >
                        {this.chartLines.map((item, i) => (
                            <MenuItem key={i} value={item}>
                                <Checkbox checked={chartSelectedLines.indexOf(item) > -1}/>
                                <ListItemText primary={t("dashboardChart." + item)} />
                            </MenuItem>
                        ))}
                    </AnyField>

                    {this.renderForcedExportDialog()}

                    <ActionButton onClick={this.handleClickFilter} disabled={disabled}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport} disabled={disabled || !data.length}>Excel</ActionButton>
                    <ActionButton onClick={this.handleClickOpenForcedExportDialog} disabled={!hasRole('ROLE_REPORT_DASHBOARD_FORCED_EXPORT')}>{t('Forced export')}</ActionButton>
                    <ActionButton href={link} targetBlank>Google</ActionButton>
                </LeftBar>
                <Content title={t('Dashboard')}>
                    {!data.length && <p>{t('Chose parlours')}</p>}
                    {chartData.length > 0 && chartBusinessUnits.length > 0 &&
                        <ResponsiveContainer width={"100%"} height={300}>
                            <LineChart data={chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                                <YAxis type="number" domain={[0, 'auto']}  />
                                <Tooltip />
                                <Legend
                                    layout={"vertical"}
                                    align={"right"}
                                    verticalAlign={"middle"}
                                    width={350} />
                                {this.getChartLines()}
                            </LineChart>
                        </ResponsiveContainer>
                    }
                    {data.map( (parlour, index) => (
                        <React.Fragment key={index}>
                            <div className={classes.table}>
                                <Typography variant="h6" gutterBottom>{ parlour.name }</Typography>
                                <DataTableLocal
                                    columnData={columnData}
                                    data={parlour.data}
                                    selector={false}
                                    pagination={false}
                                    disableSorting
                                    rowStyleFunc={(user, index) => (index%2 === 0 ? {backgroundColor: '#ccc'} : null)}
                                />
                            </div>
                        </React.Fragment>
                    ) )}

                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
