import NoWrap from "./NoWrap";
import React from "react";
import MoneyList from "./MoneyList";

const spending = (isShowDebts) => (value) => {
    if (!value) {
        return null;
    }

    return <NoWrap>
        <MoneyList title="f.Total" value={value.total}>
            {isShowDebts && <MoneyList title="of which debts" value={value.debtTotal} />}
        </MoneyList>
        <MoneyList title="f.Cash" value={value.cash}>
            {isShowDebts && <MoneyList title="of which debts" value={value.debtCash} />}
        </MoneyList>
        <MoneyList title="f.Cashless" value={value.cashless}>
            {isShowDebts && <MoneyList title="of which debts" value={value.debtCashless} />}
        </MoneyList>
    </NoWrap>
};

export default spending;