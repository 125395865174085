import React from "react";
import NoWrap from "./NoWrap";
import BoolValue from "./BoolValue";

const distributionCheck = (value, t, row) => {

    return <NoWrap>
        <div>
            <BoolValue
                title={t("f.Cash")}
                value={value.cash}
                translateValuePrefix={'f.DistributionCheckValue.'}
            />
        </div>
        <div>
            <BoolValue
                title={t("f.Cashless")}
                value={value.cashless}
                translateValuePrefix={'f.DistributionCheckValue.'}
            />
        </div>
    </NoWrap>;
};

export default distributionCheck;
