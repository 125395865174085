import React from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    withStyles,
} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import ActionButton from "../../components/button/ActionButton";
import {hasRole} from "../../services/user";
import {getDownloadLink} from "../../services/storage";
import Draggable from "react-draggable";

export default
@withTranslation()
@connect((state) => ({
    parlour: state.common.currentParlour
}), {
    showError: infoActions.showError
})
@withStyles(() => ({
    root: {
        '& .MuiDialog-paper': {
            cursor: 'move',
        },
        '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiDialogTitle-root': {
            userSelect: 'none',
        },
    },
    backgroundOverlay: {
        position: 'fixed',
        width: '100%',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: 1299,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
}))

class ParlourPassport extends React.Component
{
    state = {
        openParlourPassportDialog: false,
    };

    render() {
        const {t} = this.props

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {
                        hasRole('ROLE_UI_SHOW_CALENDAR_PARLOUR_PASSPORT') &&
                        <ActionButton
                            style={{marginTop: -4, marginLeft: 10}}
                            onClick={this.handleOpenParlourPassportDialog(true)}
                        >
                            {t("Parlour passport")}
                        </ActionButton>                            
                    }                            
                </Grid>

                {this.renderParlourPassportDialog()}
            </Grid>
        )
    }

    handleOpenParlourPassportDialog = ok => () => {
        if (!ok) {
            this.setState({
                openParlourPassportDialog: false
            })
            return
        }

        const { parlour, t } = this.props;

        if (parlour?.passports && parlour?.passports.length !== 0) {
            this.setState({ openParlourPassportDialog: true });
        } else {
            this.props.showError(t("Non parlour passport message"));
        }
    }

    renderParlourPassportDialog = () => {
        const { openParlourPassportDialog } = this.state
        const { t, parlour, classes } = this.props

        const customBounds = {
            left: -700,
            top: -400,
            right: 700,
            bottom: 400,
        };

        return (
            <>
                {openParlourPassportDialog && <div className={classes.backgroundOverlay}></div>}
                    <Draggable
                        handle=".MuiPaper-root.MuiDialog-paper"
                        bounds={customBounds}
                    >
                        <Dialog
                            open={openParlourPassportDialog}
                            aria-labelledby="showPassport-dialog-title"
                            classes={{ root: classes.root }}
                        >
                            <DialogTitle id="showPassport-dialog-title" classes={{ root: classes.root }}>{t("Parlour passport")}</DialogTitle>
                            <DialogContent>
                                {parlour?.passports && parlour?.passports.length > 0 && (
                                    <img
                                        width={'100%'}
                                        src={getDownloadLink(parlour.passports[0].file.id)}
                                        className={parlour.passports[0].file.image}
                                        alt={parlour.passports[0].file.name}
                                        style={{pointerEvents: 'none'}}
                                    />
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" onClick={this.handleOpenParlourPassportDialog(false)}>
                                    {t("Cancel")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Draggable>
            </>
        );
    }
}