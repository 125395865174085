import { handleActions } from "redux-actions";
import socketActions from "./socket-actions";


export default handleActions({
    [socketActions.receiveMessage]: (state, action) => {
        return {
            ...state,
            messages: [...state.messages, action.payload],
        }
    },

    [socketActions.connected]: (state) => {
        return {
            ...state,
            connected: true,
        }
    },

    [socketActions.removeMessages]: (state, action) => {
        const idsForRemove = Array.isArray(action.payload) ? action.payload : [action.payload];

        return {
            ...state,
            messages: state.messages.filter(message => idsForRemove.indexOf(message.id) === -1),
        }
    }

}, {
    messages: [],
    connected: false,
})
