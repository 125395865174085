import React from 'react';
import {getRequestHistoryStatus, getRequest, navLinks} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";

const columnData = [
    { id: 'dt', numeric: false, disablePadding: false, label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'oldStatus', numeric: false, disablePadding: false, label: 'Старый статус' },
    { id: 'newStatus', numeric: false, disablePadding: false, label: 'Новый статус' },
    { id: 'user', numeric: false, disablePadding: false, label: 'User' },
    { id: 'comment', numeric: false, disablePadding: false, label: 'Comment' },
];

@withRouter
class RequestHistoryStatusPage extends React.Component {

    state = {
        request: {
            id: null,
            num: null,
            comment: null,
            status: null,
        },
        refreshTable: false
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            getRequest(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            request: response.data,
                            refreshTable: !this.state.refreshTable,
                        })
                    }
                });
        }
    }

    getTableData = (...params) => {
        return this.state.request.id ? getRequestHistoryStatus(this.state.request.id, ...params) : getFalseResult();
    };

    handleClickBack = () => {
        this.props.history.push(`/pay-docs/requests/view/${this.state.request.id}`);
    };

    render() {
        const { request, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink="История изменения статуса заявки"/>}>
                    <ShowField
                        label="Заявка №"
                        value={request.num}
                    />
                    <ShowField
                        label="Комментарий"
                        value={request.comment}
                    />
                    <ShowField
                        label="Статус"
                        value={request.status}
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>
                <Content title="История изменения статуса заявки">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default RequestHistoryStatusPage;
