import getStore from "../store";
import authActions from "../pages/login/auth-actions";
import backgroundProcessActions from '../components/background-progress/background-process-actions';
import * as queryString from "query-string-object";
import {history} from "../history";
import _ from "lodash";
import moment from "moment";

const STATUS_CODE_UNAUTHORIZED = 401;
let refreshingToken = false;

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getAccessToken() {
    const token = getStore().getState().auth.token;
    return token ? token.access_token : null;
}

function getSocketId() {
    return getStore().getState().auth.socket;
}

function getRefreshToken() {
    const token = getStore().getState().auth.token;
    return token ? token.refresh_token : null;
}


/**
 * @typedef ApiResponse
 * @property {bool} success
 * @property {any} data
 * @property {any} error
 */

/**
 * baseRequest
 * @param {string} url
 * @param params
 * @param [params.headers]
 * @param {('GET'|'POST'|'PUT'|'DELETE'|'PATCH')} [params.method]
 * @param {object} [params.query]
 * @param {object|string} [params.body]
 * @param {boolean|null} [params.notBackgroundProcess]
 * @param {AbortSignal|null} [params.signal]
 * @param {boolean} isJson
 * @returns {Promise<Response|ApiResponse>}
 */
async function baseRequest(url, params = {}, isJson = true) {
    if (!params.headers) {
        params.headers = new Headers();
    }

    if (getAccessToken() && !params.headers.has('Authorization')) {
        params.headers.set('Authorization', `Bearer ${getAccessToken()}`);
    }

    if (getSocketId()) {
        params.headers.set('X-Socket-ID', getSocketId());
    }

    if (!params.method) {
        params.method = 'GET';
    }

    if (['PUT', 'POST', 'DELETE', 'PATCH'].indexOf(params.method) >= 0) {
        if (isJson) {
            params.headers.set('Content-Type', 'application/json');

            if (params.body && typeof params.body === 'object') {
                params.body = JSON.stringify(params.body);
            }
        } else if (
            params.headers.get('Content-Type') === 'application/x-www-form-urlencoded' &&
            params.body && typeof params.body === 'object'
        ) {
            params.body = queryString.stringify(params.body);
        }
    }

    if (params.query) {
        _.forOwn(params.query, (value, key, object) => {
            if (moment.isMoment(value)) {
                object[key] = value.toISOString();
            } else if (_.isObject(value)) {
                // Пока сделаю анализ двух уровней
                _.forOwn(value, (value2, key2, object2) => {
                    if (moment.isMoment(value2)) {
                        object2[key2] = value2.toISOString();
                    }
                })
            }
        });

        const queryStrIndex = url.indexOf('?');
        let queryObj = {};
        if (queryStrIndex >= 0) {
            queryObj = queryString.parse(url.substring(queryStrIndex+1));
            url = url.substring(0, queryStrIndex);
        }
        queryObj = {...queryObj, ...params.query};
        url = url + '?' + queryString.stringify(queryObj);
    }

    if (!(params && params.notBackgroundProcess)) {
        getStore().dispatch(backgroundProcessActions.startProcess());
    }
    let response;
    try {
        let tryNumber = 1;
        while (tryNumber < 3) {
            response = await fetch(new Request(url, params));

            if (response.status === STATUS_CODE_UNAUTHORIZED && getRefreshToken()) {
                if (refreshingToken) {
                    await sleep(1000);
                    params.headers.set('Authorization', `Bearer ${getAccessToken()}`);
                } else {
                    refreshingToken = true;
                    const refreshResponse = await refresh(getRefreshToken());

                    if (refreshResponse.access_token) {
                        getStore().dispatch(authActions.loginSuccess(refreshResponse));
                        params.headers.set('Authorization', `Bearer ${refreshResponse.access_token}`);
                        refreshingToken = false;
                    } else {
                        refreshingToken = false;
                        break;
                    }
                }
            } else {
                break;
            }

            tryNumber++
        }

        if (!(params && params.notBackgroundProcess)) {
            getStore().dispatch(backgroundProcessActions.finishProcess());
        }
    } catch (e) {
        if (!(params && params.notBackgroundProcess)) {
            getStore().dispatch(backgroundProcessActions.finishProcess());
        }
        return new Promise(resolve => resolve({
            success: false,
            error: {
                message: e.message,
            },
            data: null,
        }));
    }

    if (response.status === STATUS_CODE_UNAUTHORIZED && getAccessToken()) {
        if (!new RegExp("^/login").test(history.location.pathname)) {
            getStore().dispatch(authActions.setRedirectUri(history.location.pathname));
        }
        getStore().dispatch(authActions.logout());
    }

    if (response.headers.get('Content-Type') === 'application/json') {
        return response.json();
    }

    if (response.status >= 500) {
        return new Promise(resolve => resolve({
            success: false,
            error: {
                message: response.statusText,
            },
            data: null,
        }));
    }
    return response;
}

function refresh(refreshToken) {
    const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;

    return baseRequest('/oauth/v2/token', {
        method: 'POST',
        body: {
            client_id: clientId,
            client_secret: clientSecret,
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        },
        headers:  new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
    }, false);
}

export default baseRequest;