export const calendarActionTypes = {
    SET_CURRENT_DATE: '[Calendar] Set current date',
    SET_VISITS: '[Calendar] Set visits',
    SET_HIDDEN_STATUSES: '[Calendar] Set hidden statuses',
    SET_MULTI_COLUMNS: '[Calendar] Set multi columns',
    SET_BUFFER: '[Calendar] Set buffer',
    SET_SHOW_TRANSFERRED_VISITS: '[Calendar] Show transferred visits',
    SET_TRANSFERRED_VISITS: '[Calendar] Set transferred visits',
    SET_BUSINESS_UNIT_FILTER: '[Calendar] Set business unit filter'
}

export const setCurrentDate = date => ({
    type: calendarActionTypes.SET_CURRENT_DATE,
    date,
})

export const setVisits = visits => ({
    type: calendarActionTypes.SET_VISITS,
    visits
})

export const setHiddenStatuses = statuses => ({
    type: calendarActionTypes.SET_HIDDEN_STATUSES,
    statuses
})

export const setMultiColumns = columns => ({
    type: calendarActionTypes.SET_MULTI_COLUMNS,
    columns
})

export const setBuffer = (visit, cutOrCopy) => ({
    type: calendarActionTypes.SET_BUFFER,
    visit,
    cutOrCopy
})

export const setTransferredVisits = (transferredVisits) => ({
    type: calendarActionTypes.SET_TRANSFERRED_VISITS,
    transferredVisits
})

export const _setShowTransferredVisits = (showTransferredVisits) => ({
    type: calendarActionTypes.SET_SHOW_TRANSFERRED_VISITS,
    showTransferredVisits
})

export const setShowTransferredVisits = (showTransferredVisits) => (dispatch, getState) => {
    const state = getState()
    if(showTransferredVisits) {
        dispatch(setVisits([...state.calendar.visits.filter(v => !v.isTransferred), ...state.calendar.transferredVisits]))
    } else {
        dispatch(setVisits(state.calendar.visits.filter(v => !v.isTransferred)))
    }
    return dispatch(_setShowTransferredVisits(showTransferredVisits))
}

export const setBusinessUnitFilter = (filter) => ({
    type: calendarActionTypes.SET_BUSINESS_UNIT_FILTER,
    filter
})

export const calendarActions = {
    setCurrentDate,
    setVisits,
    setHiddenStatuses,
    setMultiColumns,
    setBuffer,
    setTransferredVisits,
    setShowTransferredVisits,
    setBusinessUnitFilter
}

export default calendarActions;
