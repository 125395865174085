import React from 'react';
import * as PropTypes from 'prop-types';
import ShowField from "../../components/fields/ShowField";
import {emptyOrder, orderStatuses} from "../../services/goods";
import {formatMoney} from "../../services/common";
import {IconButton, InputAdornment} from "@material-ui/core";
import History from "@material-ui/icons/History";
import {withRouter} from "react-router";

@withRouter
class OrderLeftFields extends React.Component
{

    handleClickHistoryDeliveryDate = () => {
        this.props.history.push(`/goods/orders/history-delivery-date/${this.props.order.id}`);
    };

    render() {
        const { order } = this.props;

        return (
            <React.Fragment>
                <ShowField
                    label="Номер заказа"
                    value={order.num}
                />
                <ShowField
                    label="Дата создания заказа"
                    value={order.createdAt}
                    dateFormat="DD.MM.YYYY"
                />
                <ShowField
                    label="Пользователь, создавший заказ"
                    value={order.creator.username}
                />
                <ShowField
                    label="Тел. пользователя, создавшего заказ"
                    value={order.creator.phone}
                />
                <ShowField
                    label={order.status === orderStatuses.new ? "Примерная стоимость заказа" : "Стоимость заказа"}
                    value={formatMoney(order.price)}
                />
                <ShowField
                    label="Цена товаров для использования"
                    value={`${order.amountTotalFormatForUse || ''}${order.amountTotalFormatForUseLimit ? " (Лимит: "+order.amountTotalFormatForUseLimit+")" : ""}`}
                />
                <ShowField
                    label="Цена товаров для продажи"
                    value={ (order.amountTotalFormatForSale || '') + (order.amountTotalFormatForSaleLimit ? " (Лимит: "+order.amountTotalFormatForSaleLimit+")" : "")}
                />
                <ShowField
                    label="Контрагент"
                    value={order.counterpart.username}
                />
                <ShowField
                    label="Комментарий"
                    value={order.comment}
                />
                <ShowField
                    label="Планируемая дата поставки"
                    value={order.deliveryDate}
                    dateFormat="DD.MM.YYYY"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="История даты доставки"
                                onClick={this.handleClickHistoryDeliveryDate}
                            ><History /></IconButton>
                        </InputAdornment>
                    }
                />
                <ShowField
                    label="Статус"
                    value={order.statusText}
                />
                {order.parent && <ShowField
                    label="Родительский заказ"
                    value={order.parent.num}
                    linkTo={`/goods/orders/view/${order.parent.id}`}
                />}
                {order.children && order.children.map((child, idx) => (<ShowField
                    key={idx}
                    label={idx === 0 ? 'Дочерние заказы' : ''}
                    value={child.num}
                    linkTo={`/goods/orders/view/${child.id}`}
                />))}
            </React.Fragment>
        );
    }
}

OrderLeftFields.propTypes = {
    order: PropTypes.object.isRequired,
};

OrderLeftFields.defaultProps = {
    order: emptyOrder,
};

export default OrderLeftFields;
