import React from "react";
import * as PropTypes from "prop-types";
import {propTypeDuration} from "../../utils/moment-utils";
import moment from "moment";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

const lineHeight = 5
const refreshRateMs = 15000
const lineColor = '#fd4363'

export default
@withStyles(theme => ({
    container: {
        position: "absolute"
    },
    line: {
        backgroundColor: lineColor,
        height: lineHeight,
        position: "absolute",
        zIndex: 999,
    }
}))
@connect(state => ({
    calendarCurrentDate: state.calendar.currentDate,
    currentParlour: state.common.currentParlour
}), {})
class ScheduleSheetCurrentLocalTimeLine extends React.Component {

    state = {
        refresh: true
    }

    static propTypes = {
        width: PropTypes.number,
        slotHeight: PropTypes.number,
        timeStep: propTypeDuration,
    }

    static defaultProps = {
        width: 0,
        slotHeight: 0,
        timeStep: moment.duration(),
    }

    componentDidMount() {
       this.interval = setInterval(() => this.setState({ refresh: !this.state.refresh }), refreshRateMs);
    }

    componentWillUnmount() {
       clearInterval(this.interval);
    }

    isWorkTime = () => {
        const { currentParlour } = this.props
        const now = moment().utcOffset(currentParlour.timeZone)
        const weekday = now.weekday() + 1

        const workTimes =  Object.values(currentParlour.workTime)
            .filter(wt => {
                    let start = moment(now.format('Y-MM-DD') + ' ' + wt.start + currentParlour.timeZone)
                    let end = moment(now.format('Y-MM-DD') + ' ' + wt.finish + currentParlour.timeZone)

                    return parseInt(wt.day) === weekday && now.isBetween(start, end)
        })

        return workTimes.length
    }

    isSelectCurrentDay = () => {
        const { calendarCurrentDate, currentParlour } = this.props

        return calendarCurrentDate.startOf('day').isSame(moment().startOf('day').utcOffset(currentParlour.timeZone))
    }

    getTopPosition = () => {
        const { slotHeight, timeStep, currentParlour } = this.props
        const currentWeekday = (moment().weekday() + 1)
        const workTime = Object.values(currentParlour.workTime).find(wt => parseInt(wt.day) === currentWeekday)

        if (workTime) {
            const startDayTime = moment.duration(workTime.start)
            const nowWithoutStartDayTime = moment().utcOffset(currentParlour.timeZone).subtract(startDayTime)
            const passedFromStartDayTime = nowWithoutStartDayTime.valueOf() - nowWithoutStartDayTime.startOf('day')
            const topPosition = Math.floor(passedFromStartDayTime / timeStep.valueOf()) * slotHeight - (lineHeight / 2)

            return topPosition
        } else {
            return 0
        }
    }

    render() {
        const { width, classes } = this.props
        const lineStyle = {
            width,
            top: this.getTopPosition()
        }

        return this.isWorkTime() && this.isSelectCurrentDay()
                ? <div className={classes.container}>
                    <div className={classes.line} style={lineStyle}/>
                  </div>
                :null
    }
}