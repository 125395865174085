import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getCategories,
    getGoods,
    getGoodStorageConsumption,
    getGoodStorageConsumptionTypes,
    navLinks
} from "../../services/goods";
import Content from "../../components/content/Content";
import moment from "moment";
import DataTable from "../../components/data-table/DataTable";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import {createChangeHandle} from "../../utils/helpers";
import {getBusinessUnitByRole} from "../../services/user";
import ActionButton from "../../components/button/ActionButton";
import _ from "lodash";
import XLSX from "xlsx";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import {withTranslation} from "react-i18next";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";

const consumptionTypes = {
    goodStorageSale: "goodStorageSale",
    visit: "visit",
    goodStorageRevision: "goodStorageRevision",
    visitFacility: "visitFacility"
}

function typeCellLink(row) {
    let link = null;
    switch (row.type) {
        case consumptionTypes.goodStorageSale:
            link = `/goods/sell/view/${row.typeId}`;
            break;
        case consumptionTypes.goodStorageRevision:
            link = `/goods/revision/view/${row.typeId}`;
            break;
        case consumptionTypes.visit:
        case consumptionTypes.visitFacility:
            link = `/orders/view/${row.typeId}`;
            break;
        default:
    }
    return link;
}

@withTranslation()
class HistoryPage extends React.Component
{
    typeCell = (value) => {
        let type = this.state.types.find(type => type.id === value);
        return type ? type.text : null;
    }

    columnData = [
        { id: 'goodName', label: 'Good' },
        { id: 'categoryName', label: 'Category' },
        { id: 'deltaMoney', label: 'Delta price', disableSorting: true },
        { id: 'deltaMass', label: 'Delta mass', disableSorting: true },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'creator.username', label: 'Creator' },
        { id: 'type', label: 'Type', disableSorting: true, processValue: this.typeCell, linkTemplate: typeCellLink},
        { id: 'createdAt', label: 'Date', dateFormat: "DD.MM.YYYY  HH:mm:ss" },
    ];

    columnDataXlsx = [
        {id: 'goodName', label: 'Good'},
        {id: 'categoryName', label: 'Category'},
        {id: 'deltaMoney', label: 'Delta price', disableSorting: true},
        {id: 'deltaMass', label: 'Delta mass', disableSorting: true},
        {id: 'businessUnitName', label: 'Business unit'},
        {id: 'creator.username', label: 'Creator'},
        {id: 'type', label: 'Type', disableSorting: true, processValue: this.typeCell},
        {id: 'createdAt', label: 'Date', dateFormat: "DD.MM.YYYY  HH:mm:ss"},
    ];

    state = {
        search: {
            dateFrom: moment().subtract(7,'days'),
            dateTo: moment(),
            businessUnitsIds: [],
            categoryIds: [],
            goodIds: [],
            types: []
        },
        businessUnits: [],
        goods: [],
        categories: [],
        refreshTable: false,
        types: []
    };

    handleChange = createChangeHandle(this, 'search');

    componentDidMount() {
        getBusinessUnitByRole('ROLE_GOOD_GOODSTORAGE_CONSUMPTION_GET')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getCategories()
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.data,
                    })
                }
            });

        getGoods()
            .then(response => {
                if (response.success) {
                    this.setState({
                        goods: response.data,
                    })
                }
            });

        getGoodStorageConsumptionTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data,
                    })
                }
            });
    }

    getTableData = (...params) => {
        return getGoodStorageConsumption(this.state.search, ...params);
    };

    handleRefresh = () => {
        this.setState(state => ({
            refreshTable: !state.refreshTable,
        }))
    }

    handleExport = () => {
        const { search, businessUnits } = this.state;
        let bu = search.businessUnitsIds ? businessUnits.filter(bu => search.businessUnitsIds.indexOf(bu.id) !== -1).map(bu => bu.name).join(', ') : 'All';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => {
                                const value = _.get(row, item.id);
                                if (item.processValue) {
                                    return item.processValue(value, null, row);
                                }
                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Spending");
                    XLSX.writeFile(new_workbook, `Consumption history ${bu} ${search.dateFrom} - ${search.dateTo}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { search, refreshTable, businessUnits, categories, goods, types } = this.state;
        const { t } = this.props;

        return <>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField2
                    label={t('Period')}
                    valueFrom={search.dateFrom}
                    valueTo={search.dateTo}
                    nameFrom="dateFrom"
                    nameTo="dateTo"
                    onChangeFrom={this.handleChange}
                    onChangeTo={this.handleChange}
                />
                <AutocompleteMultiSelectField
                    options={businessUnits}
                    value={search.businessUnitsIds}
                    name="businessUnitsIds"
                    onChange={this.handleChange}
                    label={t('Business units')}
                    fullWidth
                />
                <AutocompleteMultiSelectField
                    options={categories}
                    value={search.categoryIds}
                    name="categoryIds"
                    onChange={this.handleChange}
                    label={t('Categories')}
                    fullWidth
                />
                <AutocompleteMultiSelectField
                    options={goods}
                    value={search.goodIds}
                    name="goodIds"
                    onChange={this.handleChange}
                    label={t('Goods')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={types}
                    value={search.types}
                    name="types"
                    onChange={this.handleChange}
                    label={t('Types')}
                    valueField="id"
                    textField="text"
                    fullWidth
                />
                <ActionButton onClick={this.handleRefresh}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>

            <Content title={t('Consumption history')}>
                <DataTable
                    columnData={this.columnData}
                    dataFunc={this.getTableData}
                    refresh={refreshTable}
                    selector={false}
                    orderBy="createdAt"
                    order="desc"
                    autoload={false}
                />
            </Content>
        </>
    }
}

export default HistoryPage;
