import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    MenuItem,
    InputAdornment,
    TextField,
    IconButton, LinearProgress
} from "@material-ui/core";
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import BackspaceRounded from "@material-ui/icons/BackspaceRounded";
import {emptyFieldColor, fillFieldColor} from "../../services/constants";
import { connect } from "react-redux";
import messageDialogActions from "../dialogs/messageDialog-acions";
import classNames from "classnames";
import {emptyStorageGood, getGoodStorage, getGoodStorages, goodStorageStatuses} from "../../services/goods";
import moment from 'moment';
import ReactDOM from 'react-dom';

const styles = theme => ({
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    imgLoading: {
        height: 30,
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    textField: {
        marginTop:  theme.spacing(2),
    },
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    endAdornment: {
        marginRight: - theme.spacing(1),
    },
    textFieldContainer: {
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        bottom: 1,
        left: 1,
        right: 1,
    },
});

@connect(null, {
    showMessage: messageDialogActions.show
})
@withStyles(styles)
class GoodStorageField extends React.Component
{
    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
        readOnly: PropTypes.bool,
        excludeItemsIds: PropTypes.array,
        onComplete: PropTypes.func,
        debounceTimeout: PropTypes.number,
        additionalFilter: PropTypes.object,
        error: PropTypes.bool,
        helperText: PropTypes.node,
    }

    static defaultProps = {
        readOnly: false,
        excludeItemsIds: [],
        debounceTimeout: 200,
        additionalFilter: {},
        error: false,
    }

    state = {
        inputValue: '',
        suggestions: [],
        isLoading: false,
        storageGood: emptyStorageGood,
        foundStorageGood: null,
        enterPressed: false,
        waitingFetch: false,
    };

    componentDidMount() {
        this.loadStorageGood(this.props.value)
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.state.storageGood.id) {
            this.loadStorageGood(nextProps.value);
        }
    }

    loadStorageGood = (id) => {
        if (id) {
            getGoodStorage(id)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            storageGood: response.data,
                            inputValue: this.getSuggestionValue(response.data),
                        })
                    }
                });
        } else {
            this.setState({
                storageGood: emptyStorageGood,
                inputValue: '',
            });
        }
    };

    handleClear = () => {
        if (this.props.readOnly) {
            return;
        }

        this.setState({
            storageGood: emptyStorageGood,
            inputValue: '',
            enterPressed: false,
        });

        if (this.props.onChange) {
            this.props.onChange( { target: { value: null } }, null );
        }
    };

    renderInputComponent = inputProps => {
        const { classes, readOnly, error, helperText } = this.props;
        const { storageGood } = this.state;
        const { inputRef = () => {}, ref, loading, ...other } = inputProps;

        return (
            <div className={classes.textFieldContainer}>
                <TextField
                    fullWidth
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                            this.input = node;
                        },
                        endAdornment: storageGood.id ?
                            <InputAdornment position="end" className={classes.endAdornment}>
                                <IconButton
                                    aria-label="Очистить"
                                    onClick={this.handleClear}
                                >
                                    <BackspaceRounded fontSize="small"  />
                                </IconButton>
                            </InputAdornment>
                            : null,
                        readOnly: !!storageGood.id  || readOnly,
                        style: {
                            background: storageGood.id ? fillFieldColor : emptyFieldColor,
                        }
                    }}
                    {...other}
                    error={error}
                    helperText={helperText}
                />
                {loading ?
                    <LinearProgress variant="query" className={classes.progress}/>
                    : null
                }
            </div>
        )
    };

    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const label = this.getSuggestionValue(suggestion);
        const matches = match(label, query);
        const parts = parse(label, matches);

        return (
            <MenuItem selected={isHighlighted} component="div" dense>
                <div>
                    {parts.map((part, index) => {
                        return part.highlight
                            ? <span key={String(index)} style={{fontWeight: 500}}>{part.text}</span>
                            : <strong key={String(index)} style={{fontWeight: 300}}>{part.text}</strong>;
                    })}
                </div>
            </MenuItem>
        );
    };

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            waitingFetch: true,
        });
        this.debounceLoadSuggestions(value);
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
            waitingFetch: false,
        });
    };

    loadSuggestions = value => {
        this.setState({
            isLoading: true,
        });
        const encodedValue = encodeURIComponent(value)
        getGoodStorages({...this.props.additionalFilter, search: encodedValue, statuses: [goodStorageStatuses.in_storage]}, 1, 50, 'asc', 'suggestions')
            .then(response => {
                this.setState({
                    isLoading: false,
                    waitingFetch: false,
                });
                if (response.success) {
                    let storageGoods = response.data;

                    if (this.props.excludeItemsIds && this.props.excludeItemsIds.length) {
                        _.remove(storageGoods, good => this.props.excludeItemsIds.filter(item => item === good.id).length > 0);
                    }

                    const equalStorageGoods = storageGoods.filter(item => item.num === value);

                    if (this.state.enterPressed ) {
                        if (equalStorageGoods.length === 1) {
                            this.onSuggestionSelected({}, {suggestion: equalStorageGoods[0], method: 'enter'})
                        } else if (storageGoods.length > 0) {
                            this.setState({
                                suggestions: storageGoods,
                                enterPressed: false,
                            });
                        } else {
                            this.handleClear();
                        }
                    } else {
                        this.setState({
                            suggestions: storageGoods,
                        });
                    }
                }
            })
    };

    debounceLoadSuggestions = _.debounce(this.loadSuggestions, this.props.debounceTimeout);

    getSuggestionValue(suggestion) {
        const expiration = suggestion.expiration ? ' (' + moment(suggestion.expiration).format('DD.MM.YYYY') + ')' : '';
        const price = suggestion.priceFormat ? ' (' + suggestion.priceFormat + ') ' : '';

        return `${suggestion.num}: ${suggestion.good.category.name} - ${suggestion.good.name}${price}${expiration}`;
    }

    onSuggestionSelected = (event, { suggestion, method }) => {
        this.inSelect = true;

        if (this.props.onComplete && (this.state.enterPressed || method === 'enter') ) {
            this.props.onComplete(suggestion);

            this.handleClear();
        } else {
            this.setState({
                storageGood: suggestion,
                inputValue: this.getSuggestionValue(suggestion),
            });

            if (this.props.onChange) {
                this.props.onChange( { target: { value: suggestion.id } }, suggestion );
            }
        }

        this.inSelect = false;
    };

    shouldRenderSuggestions = value => {
        const { storageGood } = this.state;


        return value.length >= 1 && !storageGood.id;
    };

    handleInputPress = event => {
        if (event.key !== 'Enter' || this.inSelect) {
            return;
        }

        if (this.state.waitingFetch) {
            this.setState({
                enterPressed: true,
            });
        } else {
            if (this.state.storageGood.id) {
                if (this.props.onComplete) {
                    this.props.onComplete(this.state.storageGood);
                }
            }
        }
    };

    renderSuggestionsContainer = ({containerProps, children}) => {
        // this.input is the input ref as received from Autosuggest
        if (this.input) {
            const inputCoords = this.input.getBoundingClientRect();
            const style = {
                position: 'absolute',
                left: inputCoords.left + window.scrollX, // adding scrollX and scrollY to get the coords wrt document instead of viewport
                top: inputCoords.bottom + window.scrollY,
                // maxHeight: '264px',
                overflow: 'auto',
                zIndex: 4,
                width: inputCoords.width,
            };

            return ReactDOM.createPortal(<Paper {...containerProps} style={style}>
                {children}
            </Paper>, document.body);
        }

        return null;
    }

    render() {
        const { classes, className, label } = this.props;
        const { suggestions, inputValue, isLoading } = this.state;

        return (
            <div className={classNames(classes.root,{[classes.withLabel]: label}, className)}>
                <Autosuggest
                    inputProps={{
                        label: label,
                        loading: isLoading,
                        value: inputValue,
                        onChange: event => this.setState({ inputValue: event.target.value }),
                        onKeyPress: this.handleInputPress,
                    }}
                    renderInputComponent={this.renderInputComponent}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    renderSuggestion={this.renderSuggestion}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    getSuggestionValue={this.getSuggestionValue}
                    theme={{
                        suggestionsList: classes.suggestionsList,
                    }}
                    onSuggestionSelected={this.onSuggestionSelected}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                />
            </div>
        );
    }
}

export default GoodStorageField;
