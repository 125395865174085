import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    navLinks,
    getGeneralSettings,
    saveGeneralSettings, getMaintenanceInfoSettings, updateMaintenanceInfo
} from "../../services/settings";
import Content from "../../components/content/Content";
import {Grid} from "@material-ui/core";
import CheckboxField from "../../components/fields/CheckboxField";
import {withTranslation} from "react-i18next";
import AnyField from "../../components/fields/AnyField";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import {devices} from "../../utils/enums";

const titles = {
    showClub: "Показать подписку",
    inviteClubTitle: "Заголовое предложения о вступление в клуб ",
    inviteClubSubTitle: "Подзаголовок предложения о вступление в клуб",
    enableClubPrice: "Изменение цен для участников",
    enableClubGoods: "Изменение товаров для участников",
    enableClubPromotional: "Показать акции для участников",
};

const sortFields = [
    'maintenanceActive',
    'maintenanceMinVersion',
    'maintenanceTextButton',
    'maintenanceUrlButton',
    'maintenanceText',
];

const booleanField = 'maintenanceActive';

export default
@withTranslation()
@connect(null, {
    showMessage: messageDialogActions.show,
})
@withStyles(() => ({
    info: {
        marginTop: 10,
        paddingLeft: 10,
    }
}))
class MobileSettingsPage extends React.Component {
    state = {
        tabVal:0,
        mobile: {},
        values: null,
        settings: [],
        settingsIos: [],
        settingsAndroid: [],
    }

    componentDidMount() {
        getMaintenanceInfoSettings()
            .then(response => {
                if (response.success) {
                    this.setSettingsForDevices(response.data);

                    this.setState({
                        ...this.state,
                        settings: response.data
                    })
                }
            });
        getGeneralSettings()
            .then(response => {
                if (response.success) {
                    this.setState({
                        mobile: JSON.parse(response.data?.settingMobileClub ?? '{}'),
                        values: response.data
                    })
                }
            });
    }

    handleChangeMobile = event => {
        const { mobile } = this.state;

        this.setState({
            ...this.state,
            mobile: {
                ...mobile,
                [event.target.name]: event.target.value
            }
        })
    }

    handleSaveMaintenance = () => {
        updateMaintenanceInfo({settings: [...this.state.settingsAndroid, ...this.state.settingsIos]})
            .then(response => {
                if (response.success) {
                    this.setSettingsForDevices(response.data)
                }
            })
    }

    handleChangeMaintenance = prop => event => {
        const { settings } = this.state;

        settings.forEach(s => {
            console.log(event.target.value);
            if (s.name === prop.toString()) {
                s.value = event.target.value
            }
        })

        this.setSettingsForDevices(settings);

        this.setState({
            ...this.state,
            settings: settings
        })
    }

    handleSave = () => {
        const { values, mobile } = this.state;

        saveGeneralSettings({
            ...values,
            settingMobileClub: JSON.stringify(mobile)
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        values: response.data,
                    }, this.handleSaveMaintenance)
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })

    }

    tabHandleChange = (event, value) => {
        this.setState({
            tabVal: parseInt(value)
        });
    }

    render() {
        const { tabVal, mobile } = this.state;
        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={this.handleSave}>Сохранить</ActionButton>
            </LeftBar>
            <Content title="Общие настройки">
                <Tabs value={tabVal} onChange={this.tabHandleChange}>
                    <Tab label="Общие" id={0} />
                    <Tab label="Клуб" id={1} />
                    <Tab label="Maintanance Mode" id={2} />
                </Tabs>
                <TabPanel index={0} value={tabVal}>
                    <Grid container spacing={2}>
                    </Grid>
                </TabPanel>
                <TabPanel index={1} value={tabVal}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                value={mobile?.inviteClubTitle}
                                label={titles.inviteClubSubTitle}
                                name={"inviteClubTitle"}
                                onChange={this.handleChangeMobile}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                value={mobile?.inviteClubSubTitle}
                                label={titles.inviteClubSubTitle}
                                name={"inviteClubSubTitle"}
                                onChange={this.handleChangeMobile}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                value={mobile?.showClub ?? false}
                                label={titles.showClub}
                                name={"showClub"}
                                fullWidth
                                onChange={this.handleChangeMobile}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                value={mobile?.enableClubPrice ?? false}
                                label={titles.enableClubPrice}
                                name={"enableClubPrice"}
                                fullWidth
                                onChange={this.handleChangeMobile}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                value={mobile?.enableClubGoods ?? false}
                                label={titles.enableClubGoods}
                                name={"enableClubGoods"}
                                fullWidth
                                onChange={this.handleChangeMobile}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                value={mobile?.enableClubPromotional}
                                label={titles.enableClubPromotional}
                                name={"enableClubPromotional"}
                                fullWidth
                                onChange={this.handleChangeMobile}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel index={2} value={tabVal}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {
                                this.state.settingsAndroid.map(sAndroid => {
                                    return `${booleanField}${devices.Android}` === sAndroid.name ?
                                        <Grid item xs={12}>
                                            <CheckboxField
                                                label={sAndroid.text}
                                                value={Boolean(sAndroid.value)}
                                                onChange={this.handleChangeMaintenance(sAndroid.name)}
                                            />
                                        </Grid> :
                                        <Grid item xs={12}>
                                            <AnyField
                                                id={sAndroid.text}
                                                label={sAndroid.text}
                                                className={sAndroid.name}
                                                value={sAndroid.value}
                                                fullWidth
                                                onChange={this.handleChangeMaintenance(sAndroid.name)}
                                            />
                                        </Grid>
                                })
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {
                                this.state.settingsIos.map(settingIos => {
                                    return `${booleanField}${devices.Ios}` === settingIos.name ?
                                        <Grid item xs={12}>
                                            <CheckboxField
                                                label={settingIos.text}
                                                value={Boolean(settingIos.value)}
                                                onChange={this.handleChangeMaintenance(settingIos.name)}
                                            />
                                        </Grid> :
                                        <Grid item xs={12}>
                                            <AnyField
                                                id={settingIos.text}
                                                label={settingIos.text}
                                                className={settingIos.name}
                                                value={settingIos.value}
                                                fullWidth
                                                onChange={this.handleChangeMaintenance(settingIos.name)}
                                            />
                                        </Grid>
                                })
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
            </Content>
        </React.Fragment>
    }

    setSettingsForDevices(settings) {
        const settingsIos = this.getSortSettings(
            settings.filter(obj => obj.name.includes(devices.Ios)),
            devices.Ios
        )

        const settingsAndroid = this.getSortSettings(
            settings.filter(obj => obj.name.includes(devices.Android)),
            devices.Android
        )

        this.setState({
            ...this.state,
            settingsIos: settingsIos,
            settingsAndroid: settingsAndroid
        })
    }

    getSortSettings(settings, device) {
        return settings.filter(obj => obj.name
            .includes(device))
            .sort(function(a, b) {
                let indexA = sortFields.map(field => field + device).indexOf(a.name);
                let indexB = sortFields.map(field => field + device).indexOf(b.name);

                return indexA - indexB;
            });
    }
}

class TabPanel extends React.Component {
    render() {
        const { children, value, index } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
            >
                {children}
            </div>
        );
    }
}