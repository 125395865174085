import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles
} from "@material-ui/core";
import { connect } from 'react-redux';
import AnyField from "../../components/fields/AnyField";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {cancelVisit, getVisitCancelReasons} from "../../services/calendar";
import * as PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {createChangeHandle} from "../../utils/helpers";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import DialogButton from "../../components/button/DialogButton";

export default
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(theme => ({
    marginItem: {
        marginBottom: theme.spacing(2),
    },
}))
class VisitCancelDialog extends React.Component
{
    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        visitId: PropTypes.string,
    };

    state = {
        data: {
            comment: null,
            reason: null,
        },
        reasons: []
    };

    componentDidMount() {
        getVisitCancelReasons()
            .then(response => {
                if (response.success) {
                    this.setState({
                        reasons: response.data
                            .filter(item => ([
                                'pregnancy',
                                'work',
                                'family',
                                'too_late',
                                'got_cheaper',
                                'no_money',
                                // 'not_came', // 2) а потом было требование показать, пришлось так
                                'mission'
                            ].indexOf(item.id) === -1)) // 1) было требывание скрыть, пришлось так
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleCloseCancel = () => {
        this.props.onClose(false);
    }

    handleCloseSuccess = () => {
        cancelVisit({
            'id': this.props.visitId,
            'reason': this.state.data.reason,
            'comment': this.state.data.comment,
        })
            .then(response => {
                if (response.success) {
                    this.props.showInfo(this.props.t('Visit canceled'));
                    this.props.onClose(true);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleChange = createChangeHandle(this, 'data')

    render() {
        const { open, t, classes } = this.props;
        const { data, reasons } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleCloseCancel}
                aria-labelledby="visit-cancel-dialog-title"
                aria-describedby="visit-cancel-dialog-description"
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="visit-cancel-dialog-title">{t('Cancel visit')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="visit-cancel-dialog-description" variant="subtitle1" className={classes.marginItem}>{t('Visit will be canceled(blocked)')}</DialogContentText>
                    <SelectFromItemsField
                        items={reasons}
                        label={t('Cancel reason')}
                        value={data.reason}
                        name="reason"
                        fullWidth
                        required
                        error={!data.reason}
                        select
                        onChange = {this.handleChange}
                        className={classes.marginItem}
                        textField="text"
                    />
                    <AnyField
                        label={t('Cancel comment')}
                        value={data.comment}
                        name="comment"
                        onChange={this.handleChange}
                        multiline
                        required={data.reason === 'other'}
                        error={data.reason === 'other' && !data.comment}
                        rows={4}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <DialogButton onClick={this.handleCloseSuccess} disabled={!data.reason || (data.reason === 'other' && !data.comment) }>{t('Yes')}</DialogButton>
                    <DialogButton onClick={this.handleCloseCancel} autoFocus>{t('No')}</DialogButton>
                </DialogActions>
            </Dialog>

        );
    }
}