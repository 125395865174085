import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import {getRequestedVisits, navLinks} from "../../services/calendar";
import {loadVrRequestedVisitsFilter, saveVrRequestedVisitsFilter} from "../../services/localStorage";

const columnData = [
    { id: 'startFacility', label: 'Start of service', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    { id: 'durationFacility', label: 'Duration' },
    { id: 'facilityName', label: 'Service' },
    { id: 'master', label: 'Master' },
    { id: 'parlourName', label: 'Parlour' },
    { id: 'visitStatus', label: 'Visit status' },
    { id: 'totalFormat', label: 'Cost of visit' },
    { id: 'visitComment', label: 'Comment' },
    { id: 'creator', label: 'User' },
    { id: 'id', label: 'Visit', linkTemplate: item => `/orders/view/${item.id}`, processValue: value => (value || '').substring(0, 7) + '...'}
];
const columnDataXlsx = [
    ...columnData,

];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class ReportRequestedVisitsPage extends React.Component {
    constructor(props) {
        super(props);

        const search = loadVrRequestedVisitsFilter();
        if (search) {
            search.date_start = moment(search.date_start);
            search.date_end = moment(search.date_end);
        }

        this.state = {
            search: {
                search: null,
                date_start: moment().startOf('month'),
                date_end: moment().endOf('month'),
                businessUnitId: 0,
                ...search
            },
            refreshTable: false,
            businessUnits: [{ id: 0, name: this.props.t('---All---')}],
        };
    }

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        saveVrRequestedVisitsFilter(this.state.search);
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getRequestedVisits(search, ...params);
    };

    handleExport = () => {
        const { t } = this.props;
        const { search, businessUnits } = this.state;
        const from = search.date_start;
        const to = search.date_end;
        const bu = search.businessUnitId ? businessUnits.find(item => item.id === search.businessUnitId).name : 'All';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnDataXlsx.map(item => t(item.label)),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXlsx.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Requested Visits");
                    XLSX.writeFile(new_workbook, `Requested Visits ${bu} ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleInputPress = event => {
        if (event.key === 'Enter') {
            this.handleClickFilter();
        }
    }

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t('Show visits by date interval')}
                        className={classes.filterControl}
                    />
                    <AnyField
                        id="parlour"
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        className={classes.filterControl}
                        label={t('Search')}
                        onKeyPress={this.handleInputPress}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Requested visits')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
