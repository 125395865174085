import React from 'react';
import {withStyles, Grid, FormControl, InputLabel} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import classNames from "classnames";
import {getLabelTransform} from "../../styles";
import DateField2 from "./DateField2";
import moment from "moment";

const styles = theme => ({
    addSeparator: {
        position: 'relative',

        '&::after': {
            content: '"-"',
            position: 'absolute',
            height: '100%',
            display: 'flex',
            width: 16,
            top: 0,
            right: -8,
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    field: {
        marginTop: 0,
    },
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    withLabel2Lines: {
        marginTop:  theme.spacing(5),
    },
    label2Lines: {
        '&.shrink': {
            transform: getLabelTransform(-35),
        },
    },
});

const dateType = PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
]);

export default
@withStyles(styles)
class DatePeriodField2 extends React.PureComponent
{
    static propTypes = {
        valueFrom: dateType,
        valueTo: dateType,
        minDate: dateType,
        maxDate: dateType,
        onChangeFrom: PropTypes.func.isRequired,
        onChangeTo: PropTypes.func.isRequired,
        label: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        label2Lines: PropTypes.bool,
        checkValuesOrder: PropTypes.bool,
        nameFrom: PropTypes.string,
        nameTo: PropTypes.string,
        invalidDateMessage: PropTypes.any
    };

    static defaultProps = {
        minDate: '1900-01-01',
        maxDate: '2100-01-01',
        checkValuesOrder: false,
        invalidDateMessageFrom: undefined
    };

    render() {
        const {
            className,
            label,
            valueFrom, valueTo,
            onChangeFrom, onChangeTo,
            minDate, maxDate,
            disabled,
            required,
            label2Lines,
            classes,
            checkValuesOrder,
            nameFrom,
            nameTo,
            invalidDateMessage
        } = this.props;

        return (
            <FormControl
                className={classNames(classes.root,{[classes.withLabel]: label}, className)}
                disabled={disabled}
            >
                { label ? <InputLabel shrink disabled={disabled} required={required} variant="outlined" classes={{root: label2Lines ? classes.label2Lines : null, shrink: 'shrink'}}>{label}</InputLabel> : null }
                    <Grid container spacing={2} justify="space-between" alignItems="flex-start">
                        <Grid item xs={6} className={classes.addSeparator}>
                            <DateField2
                                value={valueFrom}
                                name={nameFrom}
                                onChange={onChangeFrom}
                                minDate={minDate}
                                maxDate={maxDate}
                                className={classes.field}
                                disabled={disabled}
                                invalidDateMessage={invalidDateMessage}
                                error={checkValuesOrder && valueFrom && valueTo && moment(valueFrom).isAfter(valueTo)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateField2
                                value={valueTo}
                                name={nameTo}
                                onChange={onChangeTo}
                                minDate={minDate}
                                maxDate={maxDate}
                                className={classes.field}
                                disabled={disabled}
                                invalidDateMessage={invalidDateMessage}
                                error={checkValuesOrder && valueFrom && valueTo && moment(valueFrom).isAfter(valueTo)}
                            />
                        </Grid>
                    </Grid>
            </FormControl>
        );
    }
}
