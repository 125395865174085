import React from 'react';
import * as PropTypes from 'prop-types';
import ShowField from "../../components/fields/ShowField";
import {getFullName} from "../../services/guests";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Chip from "@material-ui/core/Chip";
import Face from '@material-ui/icons/Face';
import classNames from "classnames";
import moment from "moment";
import PayDocsLeftFields from "./PayDocsLeftFields";
export default
@withTranslation()
@withStyles(theme => ({
    payDoc: {
        fontSize: theme.typography.body2.fontSize,
        border: '1px solid #777',
        borderRadius: '3px',
        textAlign: 'center',
        padding: '1px 2px',
        marginRight: '2px',

        '&:last-child': {
            marginRight: 0,
        }
    },
    topBadge: {
        marginBottom: 5
    }
}))
class GuestLeftFields extends React.Component
{
    static propTypes = {
        guest: PropTypes.any,
    };

    getPayDocsShowField = (payDocs, label, collapsed = true) =>
        <ShowField
            label={label}
            flexWrap
            collapsible={collapsed}
        >
            {payDocs && payDocs.map(payDoc => (
                <div key={payDoc.id}
                     className={
                         classNames(
                             this.props.classes.payDoc,
                             {[this.props.payDocNoValidity]: moment(payDoc.validity).isBefore(moment())}
                         )}>
                    <Link to={`/pay-docs/view/${payDoc.id}`}>{payDoc.num}</Link><br/>{payDoc.balanceText}
                </div>
            ))}
        </ShowField>

    render() {
        const { guest, t, classes } = this.props;

        return <React.Fragment>
            { guest.isClubMember && <Chip size="small" label={t('Club member')} color="primary" className={classes.topBadge} icon={<Face/>} />}
            <ShowField
                label={t("Guest phone")}
                value={guest.phone}
            />
            <ShowField
                label={t("Full name")}
                value={getFullName(guest)}
            />
            <ShowField
                label={t("Date of creation")}
                value={guest.dateCreated}
                dateFormat="DD.MM.YYYY"
            />
            <ShowField
                label={t("Created by")}
                value={guest.creator}
            />

            <PayDocsLeftFields payDocs={guest.payDocs} />

        </React.Fragment>;
    }
}