import React from 'react';
import {withTranslation} from "react-i18next";
import LeftBar from "../../components/left-bar/LeftBar";
import DateByTypeRadioSelectField from "../../components/fields/DateByTypeRadioSelectField";
import Content from "../../components/content/Content";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getOsvCounterpartiesReport,
    navLinks
} from "../../services/moneyFlow";
import {FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import moment from "moment/moment";
import {getBusinessUnits, getExternalSubjects, getHumanEmployees} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import DataTable from "../../components/data-table/DataTable";
import {formatArrayMoney, formatMoney} from "../../services/common";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import CheckboxField from "../../components/fields/CheckboxField";
import {dateByTypeRadioSelectFieldDateTypes} from '../../services/common'
import {loadToState} from '../../utils/helpers'
import {getFilterFromQuery, pushFilterToQuery} from "../../history";

const counterpartyTypes = {
    employee: 'employee',
    externalSubject: 'externalSubject'
}

const dateFormat = "MMM yyyy"

export default
@withTranslation()
class OSVCounterpartiesReport extends React.Component {

    constructor(props) {
        super(props);

        const querySearch = getFilterFromQuery();

        let startedAt = moment().startOf('week');
        let endedAt = moment();
        let month = moment();
        let dateType = dateByTypeRadioSelectFieldDateTypes.month;
        let counterpartyType = counterpartyTypes.employee
        let businessUnitIds = []
        let employeeIds = []
        let externalSubjectIds = []
        let hideZeroAmounts = false

        if (querySearch) {
            if (querySearch.startedAt) {
                startedAt = moment(querySearch.startedAt);
            }

            if (querySearch.endedAt) {
                endedAt = moment(querySearch.endedAt);
            }

            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }

            if (querySearch.month) {
                month = querySearch.month;
            }

            if (querySearch.counterpartyType) {
                counterpartyType = querySearch.counterpartyType;
            }

            if (querySearch.businessUnitIds) {
                businessUnitIds = querySearch.businessUnitIds;
            }

            if (querySearch.employeeIds) {
                employeeIds = querySearch.employeeIds;
            }

            if (querySearch.externalSubjectIds) {
                externalSubjectIds = querySearch.externalSubjectIds;
            }

            if (querySearch.hideZeroAmounts) {
                hideZeroAmounts = querySearch.hideZeroAmounts === 'true' ? true : false;
            }
        }

        this.state = {
            search: {
                dateType,
                month,
                startedAt,
                endedAt,
                businessUnitIds,
                employeeIds,
                externalSubjectIds,
                counterpartyType,
                hideZeroAmounts
            },
            refreshTable: false,
            businessUnits: [],
            externalSubjects: [],
            employees: []
        };
    }

    processMoneyArray = (array) => {
        const amounts = array.filter(m => m.val > 0)

        if (amounts.length === 0) {
            return formatMoney(array[0])
        }

        if (amounts.length === 1) {
            return formatMoney(amounts[0])
        }

        if (amounts.length > 1) {
            return formatArrayMoney(amounts)
        }
    }

    columnData = [
        { id: 'month', label: 'Date', dateFormat: dateFormat},
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'counterpartyName', label: `${this.props.t('Counterparty')}/${this.props.t('Employee')}` },
        { id: 'startSaldo', label: 'Saldo at the beginning of the period', processValue: this.processMoneyArray },
        { id: 'accrued', label: 'Accrued', processValue: this.processMoneyArray },
        { id: 'paid', label: 'Paid', processValue: this.processMoneyArray },
        { id: 'endSaldo', label: 'Saldo at the end of period', processValue: this.processMoneyArray },
    ];

    componentDidMount() {
        loadToState(this, getBusinessUnits(), 'businessUnits')
        loadToState(this, getExternalSubjects(), 'externalSubjects')
        loadToState(this, getHumanEmployees(), 'employees')
    }

    handleChange = prop => event => {
        const value = (event instanceof moment) ?
            event :
            event.target.value;

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        }, () => {
            if (prop === 'counterpartyType' ) {
                this.setState({
                    search: {
                        ...this.state.search,
                        employeeIds: [],
                        externalSubjectIds: []
                    }
                })
            }
        });
    };

    handleClickFilter = () => {
        pushFilterToQuery(
            processMomentFieldInObject(
                this.state.search,
                [
                    'startedAt',
                    'endedAt',
                    'month',
                    'dateType',
                    'counterpartyType',
                    'businessUnitIds',
                    'employeeIds',
                    'hideZeroAmounts',
                ]
        ));
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;

        return getOsvCounterpartiesReport(
            processMomentFieldInObject(search, ['startedAt', 'endedAt', 'month']), ...params
        );
    };

    render() {
        const { t } = this.props;
        const {
            search,
            businessUnits,
            externalSubjects,
            employees,
            refreshTable
        } = this.state;

        const disableFilterButton = search.startedAt > search.endedAt

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DateByTypeRadioSelectField
                                onChange={this.handleChange}
                                startedAt={search.startedAt}
                                endedAt={search.endedAt}
                                dateType={search.dateType}
                                month={search.month}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteMultiSelectField
                                options={businessUnits}
                                label={t("Business unit")}
                                value={search.businessUnitIds}
                                placeholder={t('---All---')}
                                onChange={this.handleChange('businessUnitIds')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>{t('Search by')}</FormLabel>
                            <RadioGroup
                                value={search.counterpartyType}
                                onChange={this.handleChange('counterpartyType')}
                                row
                            >
                                <FormControlLabel control={<Radio/>} label={t('External subject')} value={counterpartyTypes.externalSubject}/>
                                <FormControlLabel control={<Radio/>} label={t('Employee')} value={counterpartyTypes.employee}/>
                            </RadioGroup>
                            {
                                search.counterpartyType === counterpartyTypes.externalSubject &&
                                <AutocompleteMultiSelectField
                                    options={externalSubjects}
                                    label={t("External subject")}
                                    value={search.externalSubjectIds}
                                    placeholder={t('---All---')}
                                    onChange={this.handleChange('externalSubjectIds')}
                                    fullWidth
                                />
                            }
                            {
                                search.counterpartyType === counterpartyTypes.employee &&
                                <AutocompleteMultiSelectField
                                    options={employees}
                                    label={t("Employee")}
                                    value={search.employeeIds}
                                    placeholder={t('---All---')}
                                    onChange={this.handleChange('employeeIds')}
                                    fullWidth
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                label={t('Скрывать при нулевых начислениях/списаниях')}
                                value={search.hideZeroAmounts}
                                onChange={this.handleChange('hideZeroAmounts')}
                            />
                        </Grid>
                    </Grid>
                    <ActionButton
                        onClick={this.handleClickFilter}
                        disabled={disableFilterButton}
                    >
                        {t('Filter')}
                    </ActionButton>
                </LeftBar>

                <Content title={t('OSV Counterparties Report')}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        autoload={false}
                        orderBy={'businessUnitName'}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
