import {dataTableGroupValueStrategy} from "../../../../../services/common";

const spending = (currentVal, row) => {
    return {
        cash: dataTableGroupValueStrategy.arrayMoneySum('cash')(
            currentVal ? currentVal.cash : null,
            row.spending
        ),
        cashless: dataTableGroupValueStrategy.arrayMoneySum('cashless')(
            currentVal ? currentVal.cashless : null,
            row.spending
        ),
        total: dataTableGroupValueStrategy.arrayMoneySum('total')(
            currentVal ? currentVal.total : null,
            row.spending
        ),
        debtCash: dataTableGroupValueStrategy.arrayMoneySum('debtCash')(
            currentVal ? currentVal.debtCash : null,
            row.spending
        ),
        debtCashless: dataTableGroupValueStrategy.arrayMoneySum('debtCashless')(
            currentVal ? currentVal.debtCashless : null,
            row.spending
        ),
        debtTotal: dataTableGroupValueStrategy.arrayMoneySum('debtTotal')(
            currentVal ? currentVal.debtTotal : null,
            row.spending
        ),
        otherCash: dataTableGroupValueStrategy.arrayMoneySum('otherCash')(
            currentVal ? currentVal.otherCash : null,
            row.spending
        ),
        otherCashless: dataTableGroupValueStrategy.arrayMoneySum('otherCashless')(
            currentVal ? currentVal.otherCashless : null,
            row.spending
        ),
        otherTotal: dataTableGroupValueStrategy.arrayMoneySum('otherTotal')(
            currentVal ? currentVal.otherTotal : null,
            row.spending
        ),
    };
};

export default spending;
