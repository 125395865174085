import React, { memo, useCallback, useEffect, useState } from "react";
import types from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteVisitChannel, updateOrCreateVisitChannel } from "../../../services/settings";
import infoActions from "../../../components/info/info-actions";
import confirmDialogActions from "../../../components/dialogs/confirmDialog-acions";
import AnyField from "../../../components/fields/AnyField";
import produce from "immer";
import IntegerField from "../../../components/fields/IntegerField";
import CheckboxField from "../../../components/fields/CheckboxField";
import { getUsers } from "../../../services/user";
import AutocompleteSelectField from "../../../components/fields/AutocompleteSelectField";


function EditDialog({ data, onClose, onSubmit }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showConfirm = useCallback((options) => dispatch(confirmDialogActions.show(options)), [dispatch]);
    const showError = useCallback((message) => dispatch(infoActions.showError(message)), [dispatch]);
    const showInfo = useCallback((message) => dispatch(infoActions.show(message)), [dispatch]);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers().then((response) => {
            if (response.success) {
                setUsers(response.data.map(user => ({ value: user.id, label: user.username })));
            }
        });
    }, []);

    const [visitChannel, setVisitChannel] = useState(data || {});

    const handleDelete = useCallback((ok) => {
        if (ok) {
            deleteVisitChannel(visitChannel.id)
                .then(response => {
                    if (response.success) {
                        onSubmit();
                        showInfo("Удалено");
                    } else
                        showError(response?.error ? response.error.message : response?.message);
                });
        }
    }, [onSubmit, showError, showInfo, visitChannel.id]);

    const handleConfirmDelete = useCallback(() => {
        showConfirm({
            message: "Уверены что хотите удалить",
            title: "Удаление",
            onClose: handleDelete,
        });
    }, [handleDelete, showConfirm]);

    const handleChange = useCallback((e) => {
        const name = e.target.name;
        const value = e.target.value;

        setVisitChannel(produce(draft => {
            draft[name] = value;
        }));
    }, []);

    const handleOk = useCallback(() => {
        updateOrCreateVisitChannel(visitChannel)
            .then(response => {
                if (response.success) {
                    onSubmit();
                } else
                    showError(response?.error ? response.error.message : response?.message);
            });
    }, [onSubmit, showError, visitChannel]);

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">{t("VisitChannel.Visit channel")}
                {visitChannel.id &&
                    <Button onClick={handleConfirmDelete}
                            color="primary"
                            style={{ float: "right" }}>Удалить</Button>
                }
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AnyField
                            label={t("VisitChannel.Name of channel")}
                            value={visitChannel.name}
                            name="name"
                            onChange={handleChange}
                            required
                            error={!visitChannel.name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <IntegerField
                            label={t("VisitChannel.Sort")}
                            value={visitChannel.sort}
                            name="sort"
                            onChange={handleChange}
                            required
                            error={!visitChannel.sort}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CheckboxField
                            label={t("VisitChannel.Is active channel")}
                            value={visitChannel.active}
                            name="active"
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AnyField
                            label={t("VisitChannel.channelBitrixId")}
                            value={visitChannel.channelBitrixId}
                            name="channelBitrixId"
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteSelectField
                            label={t("VisitChannel.onlineChannelUserId")}
                            value={visitChannel.onlineChannelUserId}
                            name="onlineChannelUserId"
                            onChange={handleChange}
                            fullWidth
                            isClearable={true}
                            options={users}
                            menuPosition="fixed"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary"
                        disabled={!(visitChannel.name && visitChannel.sort)}>Сохранить</Button>
                <Button onClick={onClose} color="primary">Отмена</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    data: types.object,
    onClose: types.func,
    onSubmit: types.func,
};

export default memo(EditDialog);