import {createActions} from 'redux-actions';
import {login} from "../../services/auth";
import {getCurrentUser} from "../../services/user";
import getStore from "../../store";
import {history} from "../../history";
import {loadRedirectUri} from "../../services/localStorage";

const auth = createActions({
    LOGIN_BEGIN: undefined,
    LOGIN_SUCCESS: undefined,
    LOGIN_ERROR: undefined,
    SET_USER: undefined,
    SET_LOGOUT: undefined,
    SET_REDIRECT_URI: undefined,
});

auth.login = (email, password) => dispatch => {
        dispatch(auth.loginBegin());

        login(email, password)
            .then(response => {
                if (response.access_token) {

                    dispatch(auth.loginSuccess(response));

                    const redirectUri = getStore().getState().auth.redirectUri || loadRedirectUri();
                    if (redirectUri) {
                        dispatch(auth.setRedirectUri(null));
                        history.push(redirectUri);
                    } else {
                        history.push("/");
                    }
                } else {
                    return dispatch(auth.loginError(response.error ? response.error.message : response.message));
                }
            });
};

auth.fetchCurrentUser = () => dispatch => {
    getCurrentUser()
        .then(response => {
            if (response.success) {
                return dispatch(auth.setUser(response.data));
            }
        })
};

auth.logout = () => dispatch => {
    dispatch(auth.setLogout());
    window.localStorage.clear();
    return history.push("/login");
};

export default auth;
