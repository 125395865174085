import React from 'react';
import { Tabs, Tab, Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import {
    getGuest,
    navLinks
} from "../../services/guests";
import {getGuestFinancialCard} from "../../services/moneyFlow";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import {withRouter} from "react-router";
import GuestLeftFields from "./GuestLeftFields";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {formatMoney} from "../../services/common";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {PieChart, Pie, Cell, Legend} from 'recharts';

export default
@connect(null, {showMessage: messageDialogActions.show})
@withStyles(theme => ({
    container: {
        width: 600,
    },
    pieChartStub: {
        width: "100%",
        height: "300px",
        textAlign: "center",
        verticalAlign: "middle",
        lineHeight: "300px"
    },
    pieChartGridContainer: {
        justifyContent: "center",
        alignItems: "center"
    }
}))
@withRouter
@withTranslation()

class FinancialCardPage extends React.Component {

    defaultSelectedTab = 'goodStorageSales'

    state = {
        id: '',
        guest: {
            id: null,
            lastName: null,
            name: null,
            secondName: null,
            phone: null,
            payDocs: null,
        },
        financialCard: {
            totalAmounts: [],
            data: {
                goodStorageSales: {
                    entities: [],
                    totalAmounts: []
                },
                payDocs: {
                    entities: [],
                    totalAmounts: []
                },
                payDocRefillsPaidPayments: {
                    entities: [],
                    totalAmounts: []
                },
                gratuities: {
                    entities: [],
                    totalAmounts: []
                },
                activeAndFreePayDocs: {
                    entities: [],
                    totalAmounts: []
                },
                activePayDocs: {
                    entities: [],
                    totalAmounts: []
                },
                timeVisitSlices: {
                    entities: [],
                    totalAmounts: []
                },
                moneyVisitSlices: {
                    entities: [],
                    totalAmounts: []
                },
                cashAndCashlessVisitSlices: {
                    entities: [],
                    totalAmounts: []
                },
                refunds: {
                    entities: [],
                    totalAmounts: []
                }
            }
        },
        selectedTab: this.defaultSelectedTab
    };

    pieChartFinancialCardColors = {
        goodStorageSales: "#0088FE",
        payDocRefillsPaidPayments:"#00C49F",
        gratuities: "#FFBB28",
        activeAndFreePayDocs: "#FF8042",
        activePayDocs: "#85c0ff",
        cashAndCashlessVisitSlices: "#56BD15",
        timeVisitSlices: "#bd1515",
        moneyVisitSlices: "#531886",
        refunds: "#fa67ff"
    }

    processSaleIdValue = id => <Link to={`/tools/sales/${id}`} target="_blank" >{id}</Link>

    processPayDocRefillIdValue = id => <Link to={`/pay-docs/refill/${id}`} target="_blank" >{id}</Link>

    processPayDocIdValue = id => <Link to={"/pay-docs/view/" + id} target={"_blank"}>{id}</Link>

    processVisitIdValue = id => <Link to={`/orders/view/${id}`} target="_blank" >{id}</Link>

    goodStorageSalesColumnData = [
        { id: 'saleId', label: 'Sale', processValue: this.processSaleIdValue },
        { id: 'amountTotalFormat', label: 'pay.amount' },
        { id: 'discount.name', label: 'A discount' },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY' },
        { id: 'creator.username', label: 'Creator' },
        { id: 'parlour.name', label: 'Parlour' },
        { id: 'guest.fullName', label: 'Guest' },
        { id: 'guest.phone', label: 'Guest phone' },
        { id: 'statusText', label: 'Status' },
        { id: 'paymentsCashFormat', label: 'pay.types.cash', disableSorting: true},
        { id: 'paymentsCashlessFormat', label: 'pay.types.cashless', disableSorting: true},
        { id: 'paymentsPdFormat', label: 'pay.types.paydoc', disableSorting: true},
        { id: 'withVisitId', label: 'With visit', linkTemplate: row => `/orders/view/${row.withVisitId}`, disableSorting: true },
    ]

    payDocRefillsPaidPaymentsColumnData = [
        { id: 'saleRefillPayDocId', label: 'Payment document', processValue: this.processPayDocIdValue},
        { id: 'saleRefillPayDocNum', label: 'payDoc.number'},
        { id: 'saleId', label: 'Sale', processValue: this.processSaleIdValue},
        { id: 'saleBusinessUnitName', label: 'Parlour'},
        { id: 'type', label: 'pay.type', processValue: (type) => this.props.t('pay.types.' + type)},
        { id: 'amountFormatted', label: 'pay.amount'},
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY'},
        { id: 'creatorName', label: 'Creator'},
    ]

    gratuitiesColumnData = [
        {
            id: 'saleId',
            label: 'Sale',
            processValue: this.processSaleIdValue
        },
        { id: 'businessUnitName', label: 'Parlour'},
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'formattedTotal', label: 'pay.amount'},
    ]

    visitSlicesColumnData = [
        { id: 'visitId', label: 'Visit', processValue: this.processVisitIdValue },
        { id: 'visitStatus', label: 'Visit status', processValue: status => this.props.t(status[0].toUpperCase() + status.substring(1))},
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'creatorUsername', label: 'Creator'},
        { id: 'visitFacilityName', label: 'Service' },
        { id: 'visitFacilityStart', label: 'Start of service', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'visitFacilityDuration', label: 'Duration (minutes)'},
        { id: 'masterName', label: 'Master'},
        { id: 'businessUnitName', label: 'Parlour'},
        { id: 'paymentType', label: 'Payment type', processValue: type => this.props.t("pay.types." + type)},
        { id: 'realMoneyIncomingFormatted', label: 'pay.amount'},
    ]

    payDocsColumnData = [
        { id: 'num', numeric: false, label: 'Identifier' },
        { id: 'phone', numeric: false, disablePadding: false, label: 'Guest phone' },
        { id: 'fio', numeric: false, disablePadding: false, label: 'Full name', linkTemplate: payDoc => `/guests/view/${payDoc.guestId}` },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'nominalWithComment', disablePadding: false, label: 'Nominal' },
        { id: 'balanceText', numeric: true, disablePadding: false, label: 'Balance' },
        { id: 'validity', dateFormat: 'DD.MM.YYYY', label: 'Expiration date to' },
        { id: 'parlour', label: 'Parlour' },
        { id: 'creator.username', label: 'Creator' },
        { id: 'createdAt', label: 'Created date', dateFormat: 'DD.MM.YYYY' },
    ];

    dealsColumnData = [
        { id: 'reasonName', label: 'Deal name'},
        { id: 'reasonClassText', label: 'Deal (entity)' },
        { id: 'amountFormat', label: 'Sum' },
        { id: 'transactionsShort', label: 'Transactions/limits', multiline: true },
        { id: 'approveStatusText', label: 'Status' },
        { id: 'comment', label: 'Comment' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    ]

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0]});

            getGuest(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: response.data
                        })

                        getGuestFinancialCard(this.state.guest.id).then(response => {
                            if (response.success) {
                                this.setState({
                                    financialCard: response.data
                                })
                            }
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/guests/view/${this.state.id}`);
    };

    handleTabsOnClick = (event, newValue) => {
        this.setState({
            selectedTab: newValue
            });
    }

    handlePieChartSectorOnClick = (event) => this.setState({selectedTab : event.payload.payload.name})

    showDataTableLocal = (columnData, data) =>

        <DataTableLocal
            columnData={columnData}
            data={ data }
            selector={false}
            pagination={false}
            disableSorting
        />

    showGoodStorageSales = () => this.showDataTableLocal(this.goodStorageSalesColumnData, this.state.financialCard.data.goodStorageSales.entities)

    showPayDocRefillsPaidPayments = () => this.showDataTableLocal(this.payDocRefillsPaidPaymentsColumnData, this.state.financialCard.data.payDocRefillsPaidPayments.entities)

    showGratuities = () => this.showDataTableLocal(this.gratuitiesColumnData, this.state.financialCard.data.gratuities.entities)

    showActiveAndFreePaydocs = () => this.showDataTableLocal(this.payDocsColumnData, this.state.financialCard.data.activeAndFreePayDocs.entities)

    showActivePaydocs = () => this.showDataTableLocal(this.payDocsColumnData, this.state.financialCard.data.activePayDocs.entities)

    showTimeMeasureVisitSlices = () => this.showDataTableLocal(this.visitSlicesColumnData, this.state.financialCard.data.timeVisitSlices.entities)

    showMoneyMeasureVisitSlices = () => this.showDataTableLocal(this.visitSlicesColumnData, this.state.financialCard.data.moneyVisitSlices.entities)

    showCashAndCashlessMeasureVisitSlices = () => this.showDataTableLocal(this.visitSlicesColumnData, this.state.financialCard.data.cashAndCashlessVisitSlices.entities)

    showRefunds = () => this.showDataTableLocal(this.dealsColumnData, this.state.financialCard.data.refunds.entities)

    getPieChartSectorLabel = entry => `
        ${this.props.t("financialCard." + entry.name)} - 
        ${formatMoney({val: entry.value, cur: entry.cur})} 
    `

    getPieChartDataByCurrency = (currency) => {
        let chartData = []
        let { financialCard } = this.state
        Object.keys(financialCard.data).forEach(
            (collectionName) => {
                if (financialCard.data[collectionName].totalAmounts.length) {
                    let totalAmountsByCurrency = financialCard.data[collectionName].totalAmounts.filter(
                        (amount) => amount.cur === currency
                    )
                    if (totalAmountsByCurrency.length && totalAmountsByCurrency.filter((amount) => amount.val > 0).length) {
                        chartData.push(
                            {
                                name: collectionName,
                                value: totalAmountsByCurrency.reduce((sum, payment) => sum += payment.val, 0),
                                colorFill: this.pieChartFinancialCardColors[collectionName],
                                cur: currency
                            }
                        )
                    }
                }
            }
        )

        return chartData
    }

    render() {
        const { t, classes } = this.props;
        const { guest, financialCard } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Interaction fixing")}/>}>
                    <GuestLeftFields guest={guest}/>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>{t("Back")}</ActionButton>
                </LeftBar>

                <Content title={this.props.t("Detailing of spent funds")}>
                    {!financialCard.totalAmounts.length &&
                        <div className={classes.pieChartStub}>
                            {this.props.t("No information about the money spent")}
                        </div>
                    }

                    {Object.keys(financialCard.totalAmounts).map( (totalAmountIndex, i) => {
                        let currency = financialCard.totalAmounts[totalAmountIndex].cur

                        return <Grid container key={i} className={classes.pieChartGridContainer}>
                                    <Grid item xs={2} style={{textAlign: "center"}}>
                                        <b>
                                            {t("Total")} {formatMoney(financialCard.totalAmounts[totalAmountIndex])}
                                        </b>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <PieChart width={1200} height={300}>
                                            <Pie
                                                data={this.getPieChartDataByCurrency(currency)}
                                                cx="30%"
                                                cy="50%"
                                                labelLine={true}
                                                label={this.getPieChartSectorLabel}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                dataKey="value"
                                                onClick={this.handlePieChartSectorOnClick}
                                                isAnimationActive={false}
                                            >
                                                {this.getPieChartDataByCurrency(currency).map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.colorFill} />
                                                ))}
                                            </Pie>
                                            <Legend
                                                layout="vetical"
                                                verticalAlign="middle"
                                                align="right"
                                                onClick={(item) => this.setState({selectedTab: item.id})}
                                                payload={
                                                    Object.keys(this.state.financialCard.data).map(
                                                        item => ({
                                                            id: item,
                                                            type: "square",
                                                            color: this.pieChartFinancialCardColors[item],
                                                            value: `${this.props.t('financialCard.' + item)}`
                                                        })
                                                    )
                                                }
                                            />
                                        </PieChart>
                                    </Grid>
                        </Grid>
                    })}

                    <Tabs
                        value={this.state.selectedTab}
                        onChange={this.handleTabsOnClick}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {Object.keys(this.state.financialCard.data).map(
                                (collectionName, i) =>
                                    <Tab key={i} label={t("financialCard." + collectionName)} value={collectionName}/>
                            )}
                    </Tabs>
                    {this.state.selectedTab === 'goodStorageSales' && this.showGoodStorageSales()}
                    {this.state.selectedTab === 'payDocRefillsPaidPayments' && this.showPayDocRefillsPaidPayments()}
                    {this.state.selectedTab === 'gratuities' && this.showGratuities()}
                    {this.state.selectedTab === 'activeAndFreePayDocs' && this.showActiveAndFreePaydocs()}
                    {this.state.selectedTab === 'activePayDocs' && this.showActivePaydocs()}
                    {this.state.selectedTab === 'timeVisitSlices' && this.showTimeMeasureVisitSlices()}
                    {this.state.selectedTab === 'moneyVisitSlices' && this.showMoneyMeasureVisitSlices()}
                    {this.state.selectedTab === 'cashAndCashlessVisitSlices' && this.showCashAndCashlessMeasureVisitSlices()}
                    {this.state.selectedTab === 'refunds' && this.showRefunds()}
                </Content>
            </React.Fragment>
        );
    }
}
