import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    navLinks,
    createIncoming,
    getIncomingDealTypes,
    getIncomingTypes,
    dealIncomingTypeIds,
    emptyDeal
} from "../../services/moneyFlow";
import AnyField from "../../components/fields/AnyField";
import {Grid, MenuItem} from "@material-ui/core";
import {emptyMoney} from "../../services/common";
import infoActions from "../../components/info/info-actions";
import {
    getBusinessUnits,
    getCheckingAccounts,
    getExternalOrganizations,
    getHumanEmployees,
    getInvestors
} from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import MoneyField from "../../components/fields/MoneyField";
import {hasRole} from "../../services/user";
import DateTimeField2 from "../../components/fields/DateTimeField2";
import {withTranslation} from "react-i18next";
import {loadToState} from "../../utils/helpers";
import {getJuridicalPersons} from "../../services/organization";

const incomingInvestor = {
    founder: 'Помощь учредителя',
    debt: 'Займ',
}

const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
});

const emptyIncoming = {...emptyDeal};

export default @withTranslation()
@connect(state => ({}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
class IncomingDealPage extends React.Component {
    state = {
        businessUnits: [],
        incomingDealTypes: [],
        incomingTypes: [],
        checkingAccounts: [],
        employees: [],
        incoming: {
            ...emptyIncoming,
            externalSubjectId: null,// externalOrganization
        },
        investors: [],
        showInvestors: false,
        juridicalPersons: [],
        externalOrganizations: []
    };

    componentDidMount() {
        getBusinessUnits({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            ...response.data
                        ],
                    });
                }
            });

        getIncomingDealTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        incomingDealTypes: [
                            ...response.data
                        ],
                    });
                }
            });

        getIncomingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        incomingTypes: [
                            ...response.data
                        ],
                    });
                }
            });

        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [
                            ...response.data
                        ],
                    });
                }
            });

        getInvestors()
            .then(response => {
                if (response.success) {
                    this.setState({
                        investors: [
                            ...response.data
                        ]
                    })
                }
            });

        loadToState(this, getJuridicalPersons(), 'juridicalPersons')
        loadToState(this, getExternalOrganizations(), 'externalOrganizations')
    }

    handleChange = prop => event => {
        this.setState({
                incoming: {
                    ...this.state.incoming,
                    [prop]: event.target.value,
                }
            },
            () => this.handleChangeUpdate(prop, event)
        );
    };

    handleChangeUpdate = (prop, event) => {
        const {incoming} = this.state;

        const selectIncoming = this.state.incomingTypes.find(it => it.id === incoming.reasonId);

        const show = selectIncoming?.name === incomingInvestor.debt || selectIncoming?.name === incomingInvestor.founder;

        if (prop === 'juridicalPersonId') {
            getCheckingAccounts({'juridicalPersonId': event.target.value, active: true})
                .then(response => {
                    this.setState({
                        incoming: {
                            ...this.state.incoming,
                            checkingAccountId: null
                        },
                        checkingAccounts: [
                            ...response.data
                        ],
                    });
                });
        }

        this.setState({
            incoming: {
                ...incoming,
                investorId: !show ? null : incoming.investorId
            },
            showInvestors: show
        });
    }

    handleChangeMoney = prop => event => {
        this.setState({
            incoming: {
                ...this.state.incoming,
                [prop]: event.target.value
            }
        });
    };

    handleAdd = () => {
        createIncoming(this.state.incoming)
            .then(response => {
                if (response.success) {
                    this.props.showInfo('Приход создан.');
                    this.setState({
                        incoming: {
                            ...emptyIncoming,
                            externalSubjectId: null
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const {classes, t} = this.props;
        const {
            incoming,
            businessUnits,
            incomingDealTypes,
            incomingTypes,
            employees,
            checkingAccounts,
            investors,
            juridicalPersons,
            externalOrganizations
        } = this.state;
        const disabledInvestor = (incomingTypes.find(
                it => it.id === incoming.reasonId)?.name === incomingInvestor.founder && !incoming.investorId
        );

        const disabled = !incoming.businessUnitId ||
            !incoming.incomingDealType ||
            !incoming.amount.val ||
            !incoming.reasonId ||
            disabledInvestor;

        let selectedBusinessUnit = businessUnits.find((item) => incoming.businessUnitId === item.id);
        let currencies = selectedBusinessUnit ? selectedBusinessUnit.usingCurrencies : [emptyMoney.cur];

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAdd} disabled={disabled}>Создать приход</ActionButton>
                </LeftBar>
                <Content title="Создать приход ДС">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Business unit")}
                                value={incoming.businessUnitId}
                                required
                                error={!incoming.businessUnitId}
                                onChange={this.handleChange('businessUnitId')}
                                fullWidth
                                options={businessUnits.map(item => ({
                                    value: item.id,
                                    label: `${item.name}`
                                }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                label="Тип"
                                value={incoming.incomingDealType}
                                required
                                error={!incoming.incomingDealType}
                                onChange={this.handleChange('incomingDealType')}
                                fullWidth
                                select
                            >
                                {incomingDealTypes.map(item => <MenuItem key={item.id}
                                                                         value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        {incoming.incomingDealType === 'INCOMING_TO_CHECKING_ACCOUNT' && <>
                            <Grid item xs={3}>
                                <AutocompleteSelectField
                                    label={t("Juridical person")}
                                    value={incoming.juridicalPersonId}
                                    name="juridicalPersonId"
                                    error={!incoming.juridicalPersonId}
                                    onChange={this.handleChange('juridicalPersonId')}
                                    fullWidth
                                    select
                                    required
                                    options={juridicalPersons?.map(item => ({value: item.id, label: item.shortName}))}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AnyField
                                    label="Расчетный счет"
                                    value={incoming.checkingAccountId}
                                    required
                                    error={incoming.incomingDealType === 'INCOMING_TO_CHECKING_ACCOUNT' && !incoming.checkingAccountId}
                                    onChange={this.handleChange('checkingAccountId')}
                                    fullWidth
                                    select
                                >
                                    {checkingAccounts.map(item => <MenuItem key={item.id}
                                                                            value={item.id}>{item.bankName} / {item.currency} / {item.juridicalPersonShortName}</MenuItem>)}
                                </AnyField>
                            </Grid>
                        </>}
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <MoneyField
                                label="Сумма"
                                currencies={currencies}
                                value={incoming.amount}
                                error={!incoming.amount.val}
                                onChange={this.handleChangeMoney('amount')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Incoming type")}
                                value={incoming.reasonId}
                                name="reasonId"
                                error={!incoming.reasonId}
                                onChange={this.handleChange('reasonId')}
                                fullWidth
                                select
                                required
                                options={incomingTypes.map(item => ({value: item.id, label: item.name}))}
                            />
                        </Grid>
                        {
                            incoming.incomingDealType === dealIncomingTypeIds.incomingToCashBox &&
                            hasRole('ROLE_MONEYFLOW_DEAL_SPENDINGFROMEMPLOYEE') &&
                            <Grid item xs={3}>
                                <AutocompleteSelectField
                                    label={t('To employee')}
                                    value={incoming.toEmployeeId}
                                    onChange={this.handleChange('toEmployeeId')}
                                    fullWidth
                                    options={employees.map(item => ({value: item.id, label: `${item.name}`}))}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                label="Комментарий"
                                value={incoming.comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                        {hasRole('ROLE_MONEYFLOW_DEAL_CREATED_AT') ?
                            <Grid item xs={3}>
                                <DateTimeField2
                                    label={t("Date")}
                                    onChange={this.handleChange('createdAt')}
                                    value={incoming.createdAt}
                                    fullWidth
                                />
                            </Grid>
                            :
                            null
                        }
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("External organization")}
                                value={incoming.externalSubjectId}
                                name="externalOrganization"
                                onChange={this.handleChange('externalSubjectId')}
                                fullWidth
                                select
                                options={externalOrganizations?.map(item => ({value: item.id, label: item.name}))}
                            />
                        </Grid>
                        {
                            this.state.showInvestors &&
                            <Grid item xs={3}>
                                <AutocompleteSelectField
                                    label="Инвестор"
                                    onChange={this.handleChange('investorId')}
                                    value={incoming.investorId}
                                    fullWidth
                                    options={
                                        investors.map(investor => ({
                                            value: investor.id,
                                            label: investor.name,
                                        }))
                                    }
                                    error={disabledInvestor}
                                />
                            </Grid>
                        }
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
