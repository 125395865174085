export const moneyFlowKeys = {
    amount: 'Сумма',
    businessUnitName: 'Наименование салона',
    comment: 'Комментарий',
    paymentTypeName: 'Нал/безнал',
    externalSubjectName: 'Контрагент',
    reasonName: 'Наименование',
    employeeName: 'Контрагент',
    applyAt: 'Дата',
    juridicalPersonName: 'Юр. лицо',
    cancellationFact: 'Дата списания',
    fromCheckingAccountName: 'РС',
    investorName : 'Инвестор',
    juridicalPersonINN : 'ИНН ЮЛ контрагента',
    juridicalPersonCheckingAccountRs : 'Р/С ЮЛ контрагента',
}

export const guestKeys = {
    phone: 'Телефон',
    name: 'Имя',
    regionId: 'Регион',
}

export const payDocKeys = {
    numInt: 'Num',
    phone: 'Гость',
    money: 'Баланс',
    typeId: 'Тип',
    validity: 'Срок действия',
    parlourId: 'Салон',
}
