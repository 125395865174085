import {dataTableGroupValueStrategy} from "../../../../../services/common";

const vgo = (currentVal, row) => {
    return {
        visit_commission: dataTableGroupValueStrategy.arrayMoneySum('visit_commission')(
            currentVal ? currentVal.visit_commission : null,
            row.vgo
        ),
        incoming_pay_doc_commission: dataTableGroupValueStrategy.arrayMoneySum('incoming_pay_doc_commission')(
            currentVal ? currentVal.incoming_pay_doc_commission : null,
            row.vgo
        ),
        masters_rent: dataTableGroupValueStrategy.arrayMoneySum('masters_rent')(
            currentVal ? currentVal.masters_rent : null,
            row.vgo
        ),
        intragroup_marketing: dataTableGroupValueStrategy.arrayMoneySum('intragroup_marketing')(
            currentVal ? currentVal.intragroup_marketing : null,
            row.vgo
        )
    };
};

export default vgo;
