import React from 'react';
import moment from "moment";
import {getBusinessUnits} from "../../services/organization";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getPayDocHistoryBalanceTrans,
    getPayDocReportHistoryBalances,
    navLinks
} from "../../services/payDocs";
import LeftBar from "../../components/left-bar/LeftBar";
import DataTable from "../../components/data-table/DataTable";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import _ from "lodash";
import XLSX from "xlsx";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import CheckboxField from "../../components/fields/CheckboxField";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import {createChangeHandle, loadToState} from "../../utils/helpers";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import {formatMoney} from "../../services/common";
import {Link} from "react-router-dom";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";

const visitViewPath = '/orders/view/';
const goodSaleViewPath = '/goods/sell/view/';

const processVisits = (visits) => {
    return visits?.map(visit => (
        <Link to={`${visitViewPath}${visit.id}`} key={visit.id}>
            <div>{visit.businessUnitName}</div>
            <div>{moment(visit.start).format("DD.MM.YYYY HH:mm")}</div>
            {visit?.closed && <div>Закрыт: {moment(visit.closed).format("DD.MM.YYYY HH:mm")}</div>}
        </Link>
    ))
}

const processGoodSales = (goodSales) => {
    return goodSales?.map(goodSale => (
        <Link to={`${goodSaleViewPath}${goodSale.id}`} key={goodSale.id}>
            <div>{goodSale.businessUnitName} {goodSale.num}</div>
            <div>{moment(goodSale.created).format("DD.MM.YYYY HH:mm")}</div>
        </Link>
    ))
}

const columnData = [
    { id: 'dt', label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'num', label: 'Номер', linkTemplate: (row) => `/pay-docs/view/${row.payDocId}` },
    { id: 'tran.text', label: 'Action' },
    { id: 'amount', label: 'Изменение' },
    { id: 'measure.text', label: 'Тип' },
    { id: 'amountReal', label: 'В деньгах', processValue: formatMoney },
    { id: 'businessUnitName', label: 'Салон ПД' },
    { id: 'username', label: 'User' },
    { id: 'visits', label: 'Visits', processValue: processVisits },
    { id: 'goodSales', label: 'Sales', processValue: processGoodSales },
];

const TRAN_BURN_FROZEN_PAYMENT = 'burn_frozen_payment';

export default @withTranslation()
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
    showError: infoActions.showError,
})
class PayDocReportHistoryBalancesPage extends React.Component {
    constructor(props) {
        super(props);

        let businessUnitIds = [];

        if (props.currentParlour) {
            businessUnitIds.push(props.currentParlour.id);
        }

        const querySearch = getFilterFromQuery();

        let dayStart = moment().startOf('week');
        let dayEnd = moment();
        let tran = [];
        let excludeFree = null;
        let autoload = false;
        let burnedInBU = null;

        if (querySearch) {
            autoload = Object.keys(querySearch).length > 0;

            if (querySearch.dayStart) {
                dayStart = moment(querySearch.dayStart);
            }

            if (querySearch.dayEnd) {
                dayEnd = moment(querySearch.dayEnd);
            }

            if (querySearch.businessUnitIds) {
                businessUnitIds = querySearch.businessUnitIds;
            }

            if (querySearch.tran) {
                tran = querySearch.tran;
            }

            if (querySearch.excludeFree) {
                excludeFree = !!querySearch.excludeFree;
            }

            if (querySearch.burnedInBU && tran?.length === 1 && tran[0] === TRAN_BURN_FROZEN_PAYMENT) {
                burnedInBU = querySearch.burnedInBU;
                if (!querySearch.businessUnitIds) {
                    businessUnitIds = [];
                }
            }
        }

        this.state = {
            search: {
                dayStart,
                dayEnd,
                businessUnitIds,
                tran,
                excludeFree,
                burnedInBU,
            },
            businessUnits: [],
            trans: [],
            refreshTable: false,
            autoload,
        }
    }

    componentDidMount() {
        loadToState(this, getBusinessUnits(), 'businessUnits')
        loadToState(this, getPayDocHistoryBalanceTrans(), 'trans')
    }

    handleChange = createChangeHandle(this, 'search')

    handleClickFilter = () => {
        const { search } = this.state;

        if (search.burnedInBU && !(search.tran?.length === 1 && search.tran[0] === TRAN_BURN_FROZEN_PAYMENT)) {
            this.setState({
                search: {
                    ...search,
                    burnedInBU: null,
                }
            });
        }

        if (search.dayStart.isAfter(search.dayEnd)) {
            this.props.showError('Неверный интервал дат');
        }

        pushFilterToQuery(processMomentFieldInObject(search, ['dayStart', 'dayEnd']));

        this.setState(state => ({ refreshTable: !state.refreshTable }));
    }

    getTableData = (...params) => {
        const { search } = this.state;

        return getPayDocReportHistoryBalances(
            processMomentFieldInObject(
                search,
                ['dayStart', 'dayEnd']
            ),
            ...params
        )
    }

    handleExport = () => {
        const { t } = this.props;
        const { search } = this.state;
        const baseUrl = window?.location?.origin;

        if (search.dayStart.isAfter(search.dayEnd)) {
            this.props.showError('Неверный интервал дат');
        }

        const rd = moment.isMoment(search.dayStart) ?
            search.dayStart.format('YYYY-MM-DD_HH-mm') :
            search.dayStart;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnData.map(item => t(item.label)),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnData.map(item => {
                                const value = _.get(row, item.id);
                                if (value && item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                } else if (item.processValue === formatMoney) {
                                    return value.val
                                } else if (item.processValue === processVisits) {
                                    return value?.map(visit => `${baseUrl}${visitViewPath}${visit.id}`).join(' ')
                                } else if (item.processValue === processGoodSales) {
                                    return value?.map(goodSale => `${baseUrl}${goodSaleViewPath}${goodSale.id}`).join(' ')
                                } else {
                                    return value;
                                }
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "PayDocs History Balances");
                    XLSX.writeFile(new_workbook, `PayDocs History Balances ${rd}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    render() {
        const { t } = this.props;
        const { autoload, refreshTable, search, businessUnits, trans } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField2
                    label="Период"
                    nameFrom="dayStart"
                    nameTo="dayEnd"
                    valueFrom={search.dayStart}
                    valueTo={search.dayEnd}
                    onChangeFrom={this.handleChange}
                    onChangeTo={this.handleChange}
                    maxDate={moment()}
                />
                <MultiSelectFromItemsField
                    label="Салон ПД"
                    name="businessUnitIds"
                    value={search.businessUnitIds}
                    onChange={this.handleChange}
                    fullWidth
                    items={businessUnits}
                />
                <CheckboxField
                    label="Исключить бесплатные"
                    name="excludeFree"
                    value={search.excludeFree}
                    onChange={this.handleChange}
                />
                <MultiSelectFromItemsField
                    label="Операции"
                    name="tran"
                    value={search.tran}
                    onChange={this.handleChange}
                    fullWidth
                    items={trans}
                    textField="text"
                />
                {search.tran?.length === 1 && search.tran[0] === TRAN_BURN_FROZEN_PAYMENT && (
                    <SelectFromItemsField
                        items={businessUnits}
                        fullWidth
                        label="Сгорели платежи в салоне"
                        nullable
                        nullableText="Любом"
                        value={search.burnedInBU}
                        onChange={this.handleChange}
                        name="burnedInBU"
                    />
                )}
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title="История балансов ПД">
                <DataTable
                    columnData={columnData}
                    dataFunc={this.getTableData}
                    selector={false}
                    refresh={refreshTable}
                    disableSorting
                    autoload={autoload}
                />
            </Content>
        </React.Fragment>;
    }
}