import React from 'react';
import {
    MenuItem,
    Grid,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent, DialogActions, Button, Typography, Snackbar, IconButton, DialogContentText, FormControl
} from '@material-ui/core';
import moment from 'moment';
import {connect} from "react-redux";
import calendarActions from "./calendar-actions";
import {getBusinessUnitRooms, getLastWorkShift, getParloursWithLoad, startWorkShift} from "../../services/organization";
import {
    availableToCutStatuses, cancelCodeForClosingVisit, checkCodeForClosingVisit,
    closeVisit, completeVisit, confirmVisit, copyVisit,
    createVisit, cutVisit,
    navLinks, notifySmsGuestCloseVisit, pasteVisit, patchVisit, provideVisit, removeCreatedVisit, VISIT_DT_FORMAT,
    visitStatuses
} from "../../services/calendar";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import ShowField from "../../components/fields/ShowField";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import CalendarField from "../../components/fields/CalendarField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import infoActions from "../../components/info/info-actions";
import ReviewDialog from "./ReviewDialog";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import MedicalCardDialog from "./MedicalCardDialog";
import ScheduleHeader from "./ScheduleHeader";
import {hasRole} from "../../services/user";
import TimeField from "../../components/fields/TimeField";
import {activityTypes, addActivityEmployee, getCalendarEmployeeBreakTypes, removeActivityEmployee} from "../../services/calendarEmployee";
import CloseWorkShiftModal from "./CloseWorkShiftModal";
import AnyField from "../../components/fields/AnyField";
import SplitDialog from "../../components/dialogs/SplitDialog";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import CalendarLeftFields from "./CalendarLeftFields";
import VisitCancelDialog from "./VisitCancelDialog";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import ChangeAdminWorkShiftModal from './ChangeAdminWorkShiftModal';
import ScheduleVisits from "./ScheduleVisits";
import ScheduleVisitsRooms from "./ScheduleVisitsRooms";
import commonActions from "../../common-actions";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {createChangeHandle} from "../../utils/helpers";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import _ from "lodash";
import {kkmService} from "../../services/kkm";
import { extractBitrixDataFromQuery } from "../../services/payDocs";
import VerifyClosingVisitBySmsDialog from "./VerifyClosingVisitBySmsDialog";

const POPUP_CONFIRM = 'confirm';
const POPUP_COME = 'come';
const POPUP_BEGIN = 'begin';
const POPUP_COMPLETE = 'complete';
const POPUP_CANCEL = 'cancel';
const POPUP_CLOSE = 'close';
const POPUP_EDIT = 'edit';
const POPUP_REVIEW = 'review';
const POPUP_ADD_BREAK = 'add break';
const POPUP_SPLIT = 'split';
const POPUP_DELETE = 'delete';
const POPUP_CUT = 'cut';
const POPUP_COPY = 'copy';
const POPUP_PASTE = 'paste';
const POPUP_REMOVE_BREAK = 'remove break'

export default
@withTranslation()
@withRouter
@connect(state => ({
    currentDate: state.calendar.currentDate,
    currentParlour: state.common.currentParlour,
    user: state.auth.user,
    visits: state.calendar.visits,
    buffer: state.calendar.buffer,
    bufferCutOrCopy: state.calendar.bufferCutOrCopy
}), {
    setCurrentDate: calendarActions.setCurrentDate,
    setCurrentParlour: commonActions.setCurrentParlour,
    setBuffer: calendarActions.setBuffer,
    showMessage: messageDialogActions.show,
    showError: infoActions.showError,
    showInfo: infoActions.show,
    showConfirm: confirmDialogActions.show,
})
class CalendarDayPage extends React.Component {

    state = {
        selectedVisit: null,
        selectedMoment: null,
        selectedMaster: {
            id: null,
            name: null
        },
        selectedRoom: {
            value: null,
            label: null,
        },
        visitAnchorEl: null,
        slotAnchorEl: null,
        reloadVisits: false,
        reloadMasters: false,
        dialogReviewOpen: false,
        openMedicalCardDialogVisitId: false,
        workShift: null,
        closeWorkShiftDialogOpen: false,
        workShiftBreakDialogOpen: false,
        masters: [],
        rooms: [],
        parlours: [],
        workBreak: {
            start: null,
            end: null,
            comment: null,
            type: null,
        },
        selectedActivity: {
            timeStart: null,
            timeEnd: null,
            duration: null,
        },
        breakTypes: [],
        splitDialogOpen: false,
        openCancelDialog: false,
        toggleChangeAdminDialog: false,
        pasteConfirmBar: false,
        currentView: 'master',
        open: false,
        dateSelected: false,
        verifyClosingBySms: {
            isVerify: false,
            sentCode: false,
            code: null,
            id: null,
            timer: 60,
            showCodeInput: false
        },
        newVisitRoom: null,
        roomsLeftBars: []
    };

    componentDidMount() {
        this.getCurrentWorkShift();
        this.getParloursWithLoad();

        document.addEventListener('keydown', this.handleKeyboardEvent);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentParlour !== this.props.currentParlour) {
            this.getCurrentWorkShift();
            this.updateSelectedActivity();
        }

        if (prevProps.currentDate !== this.props.currentDate) {
            this.getParloursWithLoad();
        }

        if (prevState.selectedMaster !== this.state.selectedMaster) {
            this.updateSelectedActivity();
        }
    }

    handleKeyboardEvent = (event) => {
        if (event.ctrlKey && event.code === 'KeyX') {
            this.cutVisit();
        }

        if (event.ctrlKey && event.code === 'KeyC') {
            this.copyVisit();
        }

        if (event.ctrlKey && event.code === 'KeyV') {
            this.pasteVisit();
        }
    }

    cutVisit = () => {
        cutVisit(this.props, this.state.selectedVisit);
    }

    copyVisit = () => {
        copyVisit(this.props, this.state.selectedVisit);
    }

    pasteVisit = () => {
        if (!this.props.buffer) {
            return;
        }

        this.setState({
            pasteConfirmBar: true
        })
    }

    handleAcceptPaste = () => {
        this.setState({
            pasteConfirmBar: false
        }, pasteVisit(this.props, this.state.selectedVisit, this.state.selectedMoment, this.state.selectedMaster));
    }

    handleDeclinePaste = () => {
        this.setState({
            pasteConfirmBar: false
        })
    }

    setMasters = (masters) => {
        this.setState({
            masters: masters,
        })
    };

    setRooms = (rooms) => {
        this.setState({
            rooms: rooms,
        })
    };

    updateSelectedActivity() {
        this.setState({
            selectedActivity: {
                ...this.state.selectedActivity,
                parlour: this.props.currentParlour,
                employee: this.state.selectedMaster,
            }
        })
    }

    getParloursWithLoad = () => {
        const { currentDate } = this.props;
        getParloursWithLoad(processMomentFieldInObject({
            dateFrom: currentDate,
            dateTo: currentDate,
            active: 1
        }, ['dateFrom', 'dateTo']))
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    });
                }
            });
    };

    processWorkShift(workShift) {
        if (workShift && workShift.started) {
            workShift.started = moment(workShift.started);
        }

        return workShift;
    }

    getCurrentWorkShift = () => {
        const { currentParlour } = this.props;

        if (currentParlour) {
            getLastWorkShift(currentParlour.id)
                .then(response => {
                    this.setState({
                        workShift: response.success ? this.processWorkShift(response.data) : null
                    });
                });
        }
    };

    startWorkShift = _.debounce(()=> {
        const { t, currentParlour } = this.props;
        startWorkShift(currentParlour.id)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Work shift is started'));
                    this.setState({
                        workShift: this.processWorkShift(response.data)
                    });
                    this.printStartWorkShiftReceipt()
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }, 500);

    printStartWorkShiftReceipt = () => {

        const { t, user, currentParlour } = this.props;

        if ( !currentParlour.printCheckEnable )
            return;

        this.setState({
            progressMessage: t('Check printing in progress')
        })

        kkmService.openShift(user.username, '11111111').then(() => {
            this.setState({
                progressOpen: false,
            })
        })
    }

    openWorkShiftSplitDialog = () => {
        const { selectedMoment, breakTypes } = this.state;
        const { currentParlour } = this.props;

        if (breakTypes.length === 0) {
            getCalendarEmployeeBreakTypes()
                .then(response => {
                    if (response.success) {
                        this.setState({
                            workShiftBreakDialogOpen: true,
                            workBreak: {
                                start: selectedMoment.utcOffset(currentParlour.timeZone),
                                end: selectedMoment.clone().add(1, 'hours').utcOffset(currentParlour.timeZone),
                                type: response.data[1].id,
                            },
                            breakTypes: response.data,
                        })
                    }
                });
        } else {
            this.setState({
                workShiftBreakDialogOpen: true,
                workBreak: {
                    start: selectedMoment.utcOffset(currentParlour.timeZone),
                    end: selectedMoment.clone().add(1, 'hours').utcOffset(currentParlour.timeZone),
                    type: breakTypes[1].id,
                }
            })
        }
    }

    closeWorkShiftSplitDialog = () => {
        this.setState({
            workShiftBreakDialogOpen: false
        })
    }


    handleDateChange = date => {
        this.setState({
            selectedMoment: null,
            selectedMaster: null,
            selectedVisit: null,
        });
        this.props.setCurrentDate(date);
    };

    handleVisitClick = visit => {
        this.setState({
            selectedVisit: visit,
            selectedMoment: null,
            selectedMaster: null,
        }, () => {
            getBusinessUnitRooms(visit.parlour.id)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            roomsLeftBars: response.data,
                        })
                    }
                });
        });
    };

    handleVisitContextMenu = (visit, event) => {
        event.preventDefault();

        this.setState({
            selectedVisit: visit,
            visitAnchorEl: event.currentTarget,
        });
    };

    handleSlotContextMenu = (value, master, event) => {
        const { currentView } = this.state;
        event.preventDefault();

        if (currentView === 'master'){
            const selectedActivity = master.workShift.find(
                activity => moment(activity.timeStart).isSameOrBefore(value) && moment(activity.timeEnd).isAfter(value)
            );

            this.setState({
                selectedMoment: value,
                selectedMaster: master,
                selectedVisit: null,
                slotAnchorEl: event.currentTarget,
                selectedActivity: {
                    ...selectedActivity,
                    parlour: this.props.currentParlour,
                    employee: master,
                },
            })
        }
    }

    handleVisitDbClick = visit => {
        this.setState({selectedVisit: visit, selectedMoment: null, selectedMaster: null, selectedRoom: null}, this.handleEditVisit);
    };

    handleSlotClick = (value, master) => {
        const { currentView } = this.state;

        if (currentView === 'master') {
        this.setState({selectedMoment: value, selectedMaster: master, selectedRoom: null, selectedVisit: null});
        } else {
            this.setState({selectedMoment: value, selectedMaster: null, selectedRoom: master, selectedVisit: null});
        }
    };

    handleSlotDbClick = (value, master) => {
        const { currentView } = this.state;

        if (currentView === 'master') {
        this.setState({
            selectedMoment: value,
            selectedMaster: master,
            selectedRoom: null,
            selectedVisit: null,
        }, this.handleAddVisit);
        } else {
            this.setState({
                selectedMoment: value,
                selectedMaster: null,
                selectedRoom: master,
                selectedVisit: null,
            }, this.handleShowDialog);
        }
    };

    handleShowDialog = () => {

        this.setState({
            open: true,
        });
    }

    handleAddVisit = () => {
        const { currentParlour } = this.props;
        const { selectedMoment, selectedMaster, selectedRoom } = this.state;

        let [search, query] = [this.props.location.search, ''];
        if (search.indexOf('bitrix') !== -1) {
            const reBitrixData = new RegExp('(bitrix[^=]+=[^&]+)', 'g');
            const bitrixArgsArr = search.trimLeft('?').match(reBitrixData);

            if (bitrixArgsArr.length > 0)
                query = bitrixArgsArr.join('&');
        }

        if (currentParlour && selectedMoment && selectedMaster) {
            createVisit({
                parlour: currentParlour,
                roomId: selectedRoom?.value,
                visitFacility: [{
                    start: selectedMoment,
                    master: [selectedMaster],
                }]
            })
                .then(response => {
                    if (response.success) {
                        if (query)
                            this.props.history.push(`/orders/edit/${response.data.id}?${query}`);
                        else
                            this.props.history.push(`/orders/edit/${response.data.id}`);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleSave = () => {
        const { currentParlour } = this.props;
        const { selectedMoment, selectedMaster, selectedRoom } = this.state;

        const queryParameters = extractBitrixDataFromQuery(this.props.location.search);

        if (currentParlour && selectedMoment && selectedRoom) {
            createVisit({
                parlour: currentParlour,
                visitChannelBitrixId: queryParameters?.visitChannelBitrixId,
                roomId: selectedRoom?.value,
                visitFacility: [{
                    start: selectedMoment,
                    master: [selectedMaster],
                }]
            })
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/orders/edit/${response.data.id}${this.props.location.search}`);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        }

        this.setState({
            open: false,
            dateSelected: false,
        })
    };

    handleMasterChange = (event) => {
        const { masters } = this.state;

        const selectedMasterId = event.target.value;
        const selectedMaster = masters.find(master => master.id === selectedMasterId);

        this.setState({ selectedMaster });
    }

    handleAddBreak = () => {
        const { currentParlour } = this.props;
        const { selectedMaster, workBreak } = this.state;

        const validateError = this.validateWorkBreak();
        if (validateError) {
            this.props.showError(validateError);
        }

        const workShift = this.selectWorkShift();

        addActivityEmployee(workShift, workBreak, currentParlour, selectedMaster, this.reloadMasters);

        //закрываем диалоговое окно
        this.closeWorkShiftSplitDialog();
    }

    handleEditVisit = () => {
        this.props.history.push(`/orders/view/${this.state.selectedVisit.id}`);
    };

    reloadVisits = () => {
        this.setState({
            reloadVisits: !this.state.reloadVisits,
        });
    };

    reloadMasters = () => {
        this.setState({
            reloadMasters: !this.state.reloadMasters,
        });
    };

    handlePopupMenuClose = key => () => {
        const { selectedVisit } = this.state;
        const { t } = this.props;

        this.setState({
            visitAnchorEl: null,
            slotAnchorEl: null,
        });

        switch (key) {
            case POPUP_CONFIRM:
                confirmVisit(selectedVisit.id)
                    .then(response => {
                        if (response.success) {
                            this.reloadVisits();
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;
            case POPUP_COME:
                this.setState({
                    openMedicalCardDialogVisitId: selectedVisit.id
                });

                break;

            case POPUP_BEGIN:
                provideVisit(selectedVisit.id)
                    .then(response => {
                        if (response.success) {
                            this.reloadVisits();
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_COMPLETE:
                completeVisit(selectedVisit.id)
                    .then(response => {
                        if (response.success) {
                            this.reloadVisits();
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_CANCEL:
                this.setState({
                    openCancelDialog: true
                });
                break;

            case POPUP_CLOSE:
                closeVisit(selectedVisit.id)
                    .then(response => {
                        if (response.success) {
                            const { isVerify } = response?.data;

                            this.setState({
                                ...this.state,
                                verifyClosingBySms: response.data
                            });

                            if (!isVerify) {
                                this.reloadVisits();
                            }
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_REVIEW:
                this.setState({
                    dialogReviewOpen: true,
                });
                break;

            case POPUP_EDIT:
                this.handleEditVisit();
                break;

            case POPUP_ADD_BREAK:
                this.openWorkShiftSplitDialog();
                break;

            case POPUP_SPLIT:
                this.setState({
                    splitDialogOpen: true,
                });
                break;

            case POPUP_DELETE:
                this.props.showConfirm({
                    message: <span>{t('Do you really want to delete a visit')}?<br/>{t('The visit will be permanently deleted')}.<br/>{t('This operation cannot be undone')}!</span>,
                    title: <span>{t('Delete visit')}</span>,
                    onClose: this.handleCloseRemoveVisit
                });
                break;

            case POPUP_CUT:
                this.cutVisit();
                break;

            case POPUP_COPY:
                this.copyVisit();
                break;

            case POPUP_PASTE:
                this.pasteVisit();
                break;

            case POPUP_REMOVE_BREAK:
                this.props.showConfirm({
                    message: <span>{'Вы действительно хотите удалить перерыв? Перерыв будет удален безвозвратно. Эту операцию нельзя отменить!'}</span>,
                    title: <span>{'Удалить перерыв'}</span>,
                    onClose: this.handleCloseRemoveBreak
                });
                break;    

            default:
                break;
        }
    };

    handleCloseRemoveBreak = ok => {
        const {selectedActivity}  = this.state;

        if (!ok || !selectedActivity.id) return;

        removeActivityEmployee(selectedActivity.id).then(response => {
            if (response.success) {
                this.reloadMasters();
                this.props.showInfo('Перерыв успешно удален.');
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        });
    }

    handleCloseRemoveVisit = ok => {
        if (ok) {
            const { t } = this.props;

            removeCreatedVisit(this.state.selectedVisit.id)
                .then(response => {
                    if (response.success) {
                        this.props.showInfo(t('Visit deleted') + '!');
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleCloseReviewDialog = () => {
        this.setState({
            dialogReviewOpen: false,
        });
    };

    closeMedicalCardDialog = (ok) => {
        this.setState({
            openMedicalCardDialogVisitId: false
        });

        if (ok) {
            this.reloadVisits();
        }

    };

    openCloseWorkShiftDialog = () => {
        this.setState({
            closeWorkShiftDialogOpen: true
        });
    };

    handleChangeAdminDialogOpen = () => {
        this.setState({
            toggleChangeAdminDialog: true
        });
    }

    handleChangeAdminDialogClose = () => {
        this.setState({
            toggleChangeAdminDialog: false
        });
    }

    onCloseWorkShiftModalClosed = (workShiftData) => {
        const {t} = this.props;

        if (workShiftData === false) {
            this.setState({
                closeWorkShiftDialogOpen: false
            });
            return;
        }

        this.props.showInfo(t('Work shift is closed'));
        this.setState({
            workShift: workShiftData,
            closeWorkShiftDialogOpen: false
        });
        this.props.setCurrentParlour({...this.props.currentParlour});

        this.printCloseWorkShiftReceipt();
    };

    printCloseWorkShiftReceipt = () => {
        const { t, user, currentParlour } = this.props;

        if ( !currentParlour.printCheckEnable )
            return;

        this.setState({
            progressMessage: t('Check printing in progress')
        })

        kkmService.closeShift(user.username, '11111111').then(() => {
            this.setState({
                progressOpen: false,
            })
        })
    }

    handleChangeRoomVisit = (event) => {
        this.setState({
            newVisitRoom: event.target.value,
        });

        this.props.showConfirm({
            message: 'Вы уверены что хотите изменить комнату у визита?',
            title: 'Сменить комнату',
            onClose: this.changeRoomVisit
        });
    }

    changeRoomVisit = (ok) => {
        if(!ok) {
            return
        }

        const {selectedVisit, newVisitRoom} = this.state;

        patchVisit(selectedVisit.id, {roomId: newVisitRoom})
            .then(response => {
                if (response.success){
                    this.setState(prev => ({
                        ...prev,
                        selectedVisit: {
                            ...prev.selectedVisit,
                            roomId: newVisitRoom
                        }
                    }))
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            })
    }

    render() {
        const { currentDate, t, currentParlour} = this.props;
        const {
            selectedMoment, selectedActivity,
            selectedVisit, toggleChangeAdminDialog,
            selectedMaster,
            visitAnchorEl,
            slotAnchorEl,
            reloadVisits,
            reloadMasters,
            dialogReviewOpen,
            splitDialogOpen,
            openMedicalCardDialogVisitId,
            workShift,
            closeWorkShiftDialogOpen,
            workShiftBreakDialogOpen,
            parlours,
            pasteConfirmBar,
            currentView,
            selectedRoom,
            open,
            masters,
            verifyClosingBySms,
        } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>} hidePrint>
                    <ShowField
                        label={t("Work shift")}
                        value={ workShift ? ( workShift.closed ? t("Closed") + ' ' + moment(workShift.closed).utcOffset(currentParlour.timeZone).format(VISIT_DT_FORMAT) : t("Opened") + ' ' + moment(workShift.started).utcOffset(currentParlour.timeZone).format(VISIT_DT_FORMAT) ) : t('Not exist') }
                    />
                    <CalendarLeftFields
                        currentView={this.state.currentView}
                        selectedRoom={selectedRoom}
                        selectedMoment={selectedMoment}
                        selectedMaster={selectedMaster}
                        selectedVisit={selectedVisit}
                    />
                    {
                        selectedVisit &&
                        <SelectFromItemsField
                            items={this.state.roomsLeftBars}
                            label={t("Room")}
                            value={selectedVisit.roomId}
                            onChange={this.handleChangeRoomVisit}
                            textField="displayName"
                            fullWidth
                        />
                    }
                    <br/>
                    <br/>
                    <ActionButton
                        visible={(!workShift || workShift.closed) && hasRole('ROLE_UI_TAB_ORGANIZATION_WORKSHIFT_START')}
                        onClick={this.startWorkShift}>{t("Open work shift")}</ActionButton>
                    <ActionButton
                        visible={workShift && !workShift.closed && hasRole('ROLE_UI_TAB_ORGANIZATION_WORKSHIFT_START')}
                        onClick={this.handleChangeAdminDialogOpen}>{t("Change administrator")}</ActionButton>
                    <ActionButton
                        visible={workShift && !workShift.closed && hasRole('ROLE_UI_TAB_ORGANIZATION_WORKSHIFT_START')}
                        onClick={this.openCloseWorkShiftDialog}>{t("Close work shift")}</ActionButton>

                    <ActionButton
                        disabled={!selectedMoment || (currentView === 'master' && !selectedMaster) || (currentView !== 'master' && !selectedRoom)} visible={!selectedVisit}
                        onClick={currentView === 'master' ? this.handleAddVisit : this.handleShowDialog}>{t("Add visit")}</ActionButton>
                    <ActionButton
                        disabled={!selectedMoment || !selectedMaster} visible={!selectedVisit}
                        onClick={this.openWorkShiftSplitDialog}>{t("Add break")}</ActionButton>
                    <ActionButton
                        visible={Boolean(selectedVisit)}
                        onClick={this.handleEditVisit}>{t("Edit visit")}</ActionButton>
                    <ActionButton visible={false}>Распечатать расписание</ActionButton>
                    <br/>
                    <br/>
                    <CalendarField
                        value={currentDate}
                        onChange={this.handleDateChange}
                    />
                </LeftBar>
                <Content title={t("Schedule")} hidePrintTitle>
                    <Grid container spacing={1}>
                        <ScheduleHeader
                            parlours={parlours}
                            switchButton={
                                <ActionButton
                                    noMargin
                                    onClick={() => {
                                        this.setState({
                                            currentView: this.state.currentView === 'master' ? 'room' : 'master',
                                        });
                                    }}
                                >
                                    {currentView === 'master' ? t('Display by room') : t('Display by master')}
                                </ActionButton>}/>
                        <Grid item xs={12}>
                            {currentParlour && (currentView === 'master' ? (
                                <ScheduleVisits
                                    date={currentDate.clone().utcOffset(currentParlour.timeZone, true).startOf('day')}
                                    selectedVisit={selectedVisit}
                                    onVisitClick={this.handleVisitClick}
                                    onVisitDbClick={this.handleVisitDbClick}
                                    onSlotClick={this.handleSlotClick}
                                    onSlotDbClick={this.handleSlotDbClick}
                                    onVisitContextMenu={this.handleVisitContextMenu}
                                    onSlotContextMenu={this.handleSlotContextMenu}
                                    reloadVisits={reloadVisits}
                                    reloadMasters={reloadMasters}
                                    setMasters={this.setMasters}
                                />
                                )
                                : (
                                    <>
                                    <Dialog open={open} onClose={this.handleClose}>
                                        <DialogTitle>{t("Choose a master")}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {t("Please select a master from the list of free masters")}
                                            </DialogContentText>
                                            <FormControl>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography>{t("Master")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <SelectFromItemsField
                                                            value={selectedMaster?.id}
                                                            name="master"
                                                            fullWidth
                                                            items={masters}
                                                            onChange={this.handleMasterChange}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} color="primary">
                                                {t("Cancel")}
                                            </Button>
                                            <Button
                                                onClick={this.handleSave}
                                                color="primary"
                                                disabled={!selectedMaster?.id}>
                                                {t("Save")}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <ScheduleVisitsRooms
                                        date={currentDate.clone().utcOffset(currentParlour.timeZone, true).startOf('day')}
                                        selectedVisit={selectedVisit}
                                        onVisitClick={this.handleVisitClick}
                                        onVisitDbClick={this.handleVisitDbClick}
                                        onSlotClick={this.handleSlotClick}
                                        onSlotDbClick={this.handleSlotDbClick}
                                        onVisitContextMenu={this.handleVisitContextMenu}
                                        onSlotContextMenu={this.handleSlotContextMenu}
                                        reloadVisits={reloadVisits}
                                        reloadMasters={reloadMasters}
                                        setMasters={this.setMasters}
                                    />
                                    </>
                                ))}
                        </Grid>
                    </Grid>
                </Content>
                <Menu
                    id="visit-popup-menu"
                    anchorEl={visitAnchorEl}
                    open={Boolean(visitAnchorEl)}
                    onClose={this.handlePopupMenuClose(null)}
                >
                    {this.renderVisitMenuItem(t("Confirm"), POPUP_CONFIRM, [visitStatuses.created, visitStatuses.risky], Boolean(selectedVisit && selectedVisit.parlour && selectedVisit.parlour.hasRiskyStatus))}
                    {this.renderVisitMenuItem(t("Guest came"), POPUP_COME, [visitStatuses.confirmed, visitStatuses.risky, visitStatuses.created], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderVisitMenuItem(t("Start of service"), POPUP_BEGIN, [visitStatuses.starting], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderVisitMenuItem(t("End of service"), POPUP_COMPLETE, [visitStatuses.providing], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderVisitMenuItem(t("Close"), POPUP_CLOSE, [visitStatuses.created, visitStatuses.confirmed, visitStatuses.starting, visitStatuses.providing, visitStatuses.complete], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))/*TODO: Временно разрешено*/}
                    {this.renderVisitMenuItem(t("Cancel"), POPUP_CANCEL, [visitStatuses.confirmed, visitStatuses.created, visitStatuses.risky])}
                    {this.renderVisitMenuItem(t("View"), POPUP_EDIT, Object.keys(visitStatuses))}
                    {this.renderVisitMenuItem(t("Leave feedback"), POPUP_REVIEW, [visitStatuses.closed])}
                    {this.renderVisitMenuItem(t("Delete"), POPUP_DELETE, [visitStatuses.created, visitStatuses.confirmed, visitStatuses.starting, visitStatuses.providing, visitStatuses.complete], 'ROLE_SCHEDULE_VISIT_REMOVE')}
                    {this.renderVisitMenuItem(t("Cut"), POPUP_CUT, availableToCutStatuses, 'ROLE_SCHEDULE_VISIT_SAVE')}
                    {this.renderVisitMenuItem(t("Copy"), POPUP_COPY, [], 'ROLE_SCHEDULE_VISIT_SAVE')}
                </Menu>
                {
                    selectedActivity.activity === activityTypes.break &&
                    <Menu
                        id="visit-popup-menu"
                        anchorEl={slotAnchorEl}
                        open={Boolean(slotAnchorEl)}
                        onClose={this.handlePopupMenuClose(null)}
                    >
                        {this.renderBreakSlotMenuItem('Удалить перерыв', POPUP_REMOVE_BREAK)}
                    </Menu>
                }
                {
                    selectedActivity.activity !== activityTypes.break &&
                    <Menu
                        id="visit-popup-menu"
                        anchorEl={slotAnchorEl}
                        open={Boolean(slotAnchorEl)}
                        onClose={this.handlePopupMenuClose(null)}
                    >
                        {this.renderSlotMenuItem(t('Add break'), POPUP_ADD_BREAK)}
                        {this.renderSlotMenuItem(t('CalendarEmployee.SplitTime'), POPUP_SPLIT)}
                        {this.renderSlotMenuItem(t('Paste'), POPUP_PASTE)}
                    </Menu>
                }
                <ReviewDialog
                    open={dialogReviewOpen}
                    visitId={selectedVisit ? selectedVisit.id : null}
                    onClose={this.handleCloseReviewDialog}
                />
                {openMedicalCardDialogVisitId &&
                    <MedicalCardDialog open={!!openMedicalCardDialogVisitId} visitId={openMedicalCardDialogVisitId}
                                       onClose={this.closeMedicalCardDialog} startVisit={true}/>}
                {closeWorkShiftDialogOpen && <CloseWorkShiftModal onModalClosed={this.onCloseWorkShiftModalClosed}
                                                                  currentDate={workShift.started}
                                                                  businessUnitName={workShift.businessUnitName}/>}
                {workShiftBreakDialogOpen && this.renderBreakDialog()}

                <ChangeAdminWorkShiftModal
                    toggleDialog={toggleChangeAdminDialog}
                    funcCloseDialog={this.handleChangeAdminDialogClose}
                    currentDate={currentDate}
                    businessUnitName={workShift ? workShift.businessUnitName : ''}
                />

                <SplitDialog
                    splitDialogOpen={splitDialogOpen}
                    parlours={parlours}
                    selectedMoment={selectedMoment}
                    selectedActivity={selectedActivity}
                    closeDialog={this.closeDialog}
                />
                <Snackbar
                    open={pasteConfirmBar}
                    color=""
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    message={<span>{t("Paste visit in this slot Are you sure")}</span>}
                    action={[
                        <IconButton
                            key="accept-paste"
                            onClick={this.handleAcceptPaste}
                            color="inherit"
                        >
                            <CheckIcon/>
                        </IconButton>,
                        <IconButton
                            key="cancel-paste"
                            onClick={this.handleDeclinePaste}
                            color="inherit"
                        >
                            <CancelIcon/>
                        </IconButton>,
                    ]}
                />
                {this.state.openCancelDialog && <VisitCancelDialog onClose={() => {
                    this.setState({openCancelDialog: false})
                }} open={this.state.openCancelDialog} visitId={selectedVisit.id}/>}
                { verifyClosingBySms.isVerify &&
                    <VerifyClosingVisitBySmsDialog
                        data={verifyClosingBySms}
                        handleSendCode={this.handleSendCode}
                        handleChange={this.handleChangeVerifyClosingBySms}
                        handleSendAcceptCode={this.handleSendAcceptCode}
                        handleCancelCode={this.handleCancelCode}/> }
            </React.Fragment>
        );
    }
    handleCancelCode = () => {
        cancelCodeForClosingVisit(this.state.verifyClosingBySms).then(res => {
            if (res.success) {
                this.setState({
                    ...this.state,
                    verifyClosingBySms: res.data
                });
            } else {
                this.props.showError(res.error ? res.error.message : res.message);
            }
        })
    }

    handleSendAcceptCode = () => {
        checkCodeForClosingVisit(this.state.verifyClosingBySms).then(res => {
            if (res.success) {
                this.props.showInfo('Закрытие визита')

                closeVisit(res?.data.id)
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                ...this.state,
                                verifyClosingBySms: response.data
                            });

                            this.reloadVisits();

                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
            } else {
                this.props.showError(res.error ? res.error.message : res.message);
            }
        })
    }

    handleChangeVerifyClosingBySms = e => {
        this.setState({
            verifyClosingBySms: {
                ...this.state.verifyClosingBySms,
                [e.target.name]: e.target.value,
            }
        })
    }

    handleSendCode = () => {
        const { t } = this.props;

        notifySmsGuestCloseVisit(this.state.verifyClosingBySms).then(res => {
            if (res.success) {
                this.setState({
                    verifyClosingBySms: {
                        ...res.data,
                        timer: 60
                    }
                })

                let countdown = setInterval(() => {
                    let timer = this.state.verifyClosingBySms.timer - 1;
                    this.setState({
                        verifyClosingBySms: {
                            ...this.state.verifyClosingBySms,
                            timer: timer
                        }
                    });

                    if (timer === 0) {
                        clearInterval(countdown);
                        this.setState({
                            verifyClosingBySms: {
                                ...this.state.verifyClosingBySms,
                                sentCode: false,
                                timer: 60
                            }
                        });
                    }
                }, 1000);

                this.setState({
                    verifyClosingBySms: {
                        ...this.state.verifyClosingBySms,
                        showCodeInput: true,
                    }
                });

                this.props.showInfo(t('SMS code sent to guest'))
            } else {
                this.props.showMessage(res.error ? res.error.message : res.message);
            }
        })
    }

    closeDialog = (name) => {
        switch (name) {
            case POPUP_SPLIT:
                this.setState({
                    splitDialogOpen: false,
                }, () => {
                    this.reloadMasters()
                });
                break;

            default:
                break;
        }
    };

    renderVisitMenuItem(title, key, statuses = [], roleOrCondition = true) {
        const {selectedVisit} = this.state;
        let enabled = roleOrCondition;

        if (typeof roleOrCondition === 'string' || roleOrCondition instanceof String) {
            enabled = hasRole(roleOrCondition);
        }

        if (selectedVisit && (!statuses.length || statuses.indexOf(selectedVisit.status) >= 0) && enabled) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }

    renderSlotMenuItem(title, key) {
        const {selectedMoment} = this.state;

        if (selectedMoment) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }

    renderBreakSlotMenuItem(title, key) {
        const {selectedMoment} = this.state;

        if (selectedMoment) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }

    /**
     * @param part
     * @returns {string|null}
     */
    validateWorkBreak = (part) => {
        const {workBreak, selectedMaster} = this.state;
        const {t, currentParlour, visits} = this.props;

        if (selectedMaster.id === null) {
            return null;
        }

        const workShift = this.selectWorkShift(part);

        if (!workShift) {
            return t('Invalid time period') + '.';
        }

        //проверим не попадают ли визиты в интервал перерыва
        if (workBreak.end && workBreak.start && visits && visits.length) {
            const haveVisitsOverlaps = visits.some(visit => {
                if ([visitStatuses.blocked, visitStatuses.canceled].indexOf(visit.status) !== -1) {
                    return false;
                }

                return visit.visitFacility.some(visitFacility => {
                    let master = visitFacility.master.filter(master => master.id === selectedMaster.id);

                    if (master.length < 1) {
                        return false;
                    }

                    return !!((workBreak.start.isSameOrBefore(visitFacility.start) && workBreak.end.isAfter(visitFacility.start))
                        || (workBreak.start.isBefore(visitFacility.end) && workBreak.end.isSameOrAfter(visitFacility.end)));
                });
            });

            if (haveVisitsOverlaps) {
                return t('A visit falls within the break interval') + '.';
            }
        }

        const workShiftStart = moment(workShift.timeStart).utcOffset(currentParlour.timeZone);
        const workShiftEnd = moment(workShift.timeEnd).utcOffset(currentParlour.timeZone);

        let wrongTimeInterval;

        if (part) {
            wrongTimeInterval = workShiftStart.isAfter(workBreak[part]) || workShiftEnd.isBefore(workBreak[part]);
        } else {
            wrongTimeInterval =
                workShiftStart.isAfter(workBreak.start)
                || workShiftEnd.isBefore(workBreak.start)
                || workShiftStart.isAfter(workBreak.end)
                || workShiftEnd.isBefore(workBreak.end)
            ;
        }

        if (wrongTimeInterval) {
            return t('Invalid time period') + '.';
        }

        return null;
    }

    selectWorkShift(part) {
        const {currentParlour} = this.props;
        const {masters, workBreak, selectedMaster} = this.state;

        let master = masters.find(itemMaster => selectedMaster.id === itemMaster.id);
        let workShift = master.workShift.filter(itemWorkShift => {
            let workShiftStart = moment(itemWorkShift.timeStart).utcOffset(currentParlour.timeZone);
            let workShiftEnd = moment(itemWorkShift.timeEnd).utcOffset(currentParlour.timeZone);

            return itemWorkShift.parlour.id === currentParlour.id
                && workShiftStart.isSameOrBefore((part && workBreak[part]) || workBreak.start)
                && workShiftEnd.isSameOrAfter((part && workBreak[part]) || workBreak.start);
        });

        return workShift.pop();
    }

    handleChangeWorkBreak = createChangeHandle(this, 'workBreak');

    handleChangeWorkBreakTime = (time, name) => {
        const {currentParlour} = this.props;
        const {workBreak, selectedMoment} = this.state;

        if (time) {
            time.utcOffset(currentParlour.timeZone, true);
            time.set({
                year: selectedMoment.get('year'),
                month: selectedMoment.get('month'),
                date: selectedMoment.get('date'),
                second: 0,
                millisecond: 0
            });
        }

        this.setState({
            workBreak: {
                ...workBreak,
                [name]: time
            }
        });
    }

    renderBreakDialog() {
        const {workShiftBreakDialogOpen, selectedMaster, workBreak, breakTypes} = this.state;
        const {t} = this.props;

        return (
            <Dialog open={workShiftBreakDialogOpen} onClose={this.closeWorkShiftSplitDialog}>
                <DialogTitle>{t('Add break')}</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography variant="body2">
                            {t('Master')}: {selectedMaster.name}
                        </Typography>
                    </div>
                    <TimeField
                        label={t('Calendar.since')}
                        value={workBreak.start}
                        name="start"
                        onChange={this.handleChangeWorkBreakTime}
                        fullWidth
                        minutesStep={15}
                        error={this.validateWorkBreak('start') !== null}
                    />
                    <TimeField
                        label={t('Calendar.to')}
                        value={workBreak.end}
                        name="end"
                        onChange={this.handleChangeWorkBreakTime}
                        fullWidth
                        minutesStep={15}
                        error={this.validateWorkBreak('end') !== null}
                    />
                    <AnyField
                        label={t('Calendar.comment')}
                        value={workBreak.comment}
                        name="comment"
                        fullWidth
                        multiline
                        rows="4"
                        onChange={this.handleChangeWorkBreak}
                    />
                    <SelectFromItemsField
                        label="Тип перерыва"
                        value={workBreak.type}
                        name="type"
                        fullWidth
                        items={breakTypes}
                        onChange={this.handleChangeWorkBreak}
                        textField="text"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleAddBreak} color="primary">OK</Button>
                    <Button onClick={this.closeWorkShiftSplitDialog} color="primary">{t('Cancel')}</Button>
                </DialogActions>
            </Dialog>

        );
    }
}
