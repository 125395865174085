import React from 'react';
import { Grid, IconButton, withStyles } from "@material-ui/core";
import ActionButton from "../../components/button/ActionButton";
import moment from "moment";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import calendarActions from "./calendar-actions";
import commonActions from "../../common-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import infoActions from "../../components/info/info-actions";
import { getCurrentWorkingAdministrators } from "../../services/calendarEmployee";
import CashBoxAndSafeState from "./CashBoxAndSafeState";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";
import cn from 'classnames';
import { colorLoad } from "../../services/organization";
import ScheduleLegend from "./ScheduleLegend";
import ScheduleHeaderBusinessUnitAnnouncementsForm from "./ScheduleHeaderBusinessUnitAnnouncementsForm";
import ParlourPassport from "./ParlourPassport";
import ScheduleHeaderBusinessUnitFilter from "./ScheduleHeaderBusinessUnitFilter"
import Select, { components } from 'react-select';

const Control = ({ children, ...props }) => {
    return (
        <components.Control {...props}>
            <span style={{ marginLeft: '.4rem' }}>Салон</span>
            {children}
        </components.Control>
    );
};

const SingleValue = ({ data }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '97%' }}>
        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: 5 }}>
            <span style={{ fontSize: '14px', paddingTop: 3 }}>{data.label}</span><br />
            <span style={{ fontSize: '11px', lineHeight: '8px' }}>{data.address}</span>
        </div>

        {
            data.load !== null && data.load !== undefined &&
            <div style={{ flex: 1, textAlign: 'right' }}>
                <span style={{ color: colorLoad(data.load) }}>{'(' + data.load + '%)'}</span>
            </div>
        }
    </div>
);

const FormatLabel = ({ label, address, load }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
            <span style={{ fontSize: '14px', paddingTop: 3 }}>{label}</span><br />
            <span style={{ fontSize: '11px', lineHeight: '8px' }}>{address}</span>
        </div>
        
        {
            load !== null && load !== undefined &&
            <div style={{ marginTop: -5 }}>
                <span style={{ fontSize: 16, color: colorLoad(load) }}>{'(' + load + '%)'}</span>
            </div>
        }
    </div>
);


const selectStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999, padding: 0, margin: 0, height: '715px' }),
    control: (styles, { theme: { colors } }) => ({
        ...styles,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        paddingLeft: '1rem',
        backgroundColor: 'rgb(225, 255, 212)',
        width: '400px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: data.index % 2 ? '#e0e0e0' : 'rgba(0, 0, 0, 0.04)',
            color: 'black',
        };
    },
};



export default
@withTranslation()
@connect(state => ({
    currentDate: state.calendar.currentDate,
    currentParlour: state.common.currentParlour,
    businessUnitFilter: state.calendar.businessUnitFilter,
}), {
    setCurrentDate: calendarActions.setCurrentDate,
    setCurrentParlour: commonActions.setCurrentParlour,
    showMessage: messageDialogActions.show,
    showError: infoActions.showError,
})
@withStyles(theme => ({
    moneyState: {
        fontSize: 14
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        },
    },
    parlourLabel: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    parlourList: {
        '& > li:nth-child(2n)': {
            backgroundColor: theme.palette.grey["300"],
        },
    },
    parlourField: {
        width: 400,
    },
    workingAdministrators: {
        maxWidth: 200
    }
}))
class ScheduleHeader extends React.Component {

    static propTypes = {
        parlours: PropTypes.array,
        setHeaderData: PropTypes.func,
    };

    static defaultProps = {
        parlours: [],
    };

    state = {
        workingAdministrators: [],
    };

    componentDidMount() {
        this.getCurrentWorkingAdministrators(this.props.currentParlour);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

        const nextFilteredParlours = this.filterParlours(nextProps.parlours, nextProps.businessUnitFilter)
        const firstNextFilteredParlour = nextFilteredParlours.length > 0 ? nextFilteredParlours[0] : null;

        if (nextProps.currentParlour !== this.props.currentParlour) {
            this.getCurrentWorkingAdministrators(nextProps.currentParlour);
        }

        if (nextProps.parlours !== this.props.parlours) {
            if (firstNextFilteredParlour && !this.props.currentParlour) {
                this.props.setCurrentParlour(firstNextFilteredParlour);
            }
        }

        const isCurrentParlourExistInNextFilteredParlours =
            this.props.currentParlour
            && !!nextFilteredParlours
                .find(parlour => parlour.id === this.props.currentParlour.id)

        if (!isCurrentParlourExistInNextFilteredParlours
            && firstNextFilteredParlour) {
            this.props.setCurrentParlour(firstNextFilteredParlour)
        }
    }

    handleChangeParlour = selectedOption => {
        if (selectedOption.value) {
            this.props.setCurrentParlour(
                this.props.parlours.length ?
                    this.props.parlours.find(item => item.id === selectedOption.value) :
                    null
            );
        }
    };

    handleToday = () => {
        const date = moment();
        this.props.setCurrentDate(date);
    };

    handlePrevDay = () => {
        const date = this.props.currentDate.clone().subtract(1, 'days');
        this.props.setCurrentDate(date);
    };

    handleNextDay = () => {
        const date = this.props.currentDate.clone().add(1, 'days');
        this.props.setCurrentDate(date);
    };

    getCurrentWorkingAdministrators = parlour => {
        if (parlour) {
            this.setState({
                workingAdministrators: []
            });
            getCurrentWorkingAdministrators({
                'moment': moment().format(),
                'businessUnitId': parlour.id,
            })
                .then(response => {
                    if (response.success) {
                        this.setState({
                            workingAdministrators: response.data
                        });
                    }
                })
        }
    };

    filterParlours = (parlours, businessUnitFilter) => {

        if (businessUnitFilter.params.regions.length > 0 && businessUnitFilter.enabled.regions) {
            parlours = parlours.filter(parlour => businessUnitFilter.params.regions.indexOf(parlour.regionId) > -1)
        }

        if (businessUnitFilter.params.types.length > 0 && businessUnitFilter.enabled.types) {
            parlours = parlours.filter(parlour => businessUnitFilter.params.types.indexOf(parlour.type) > -1)
        }

        return parlours
    }

    render() {
        const { t, currentDate, currentParlour, classes, parlours, businessUnitFilter, switchButton } = this.props;
        const filteredParlours = this.filterParlours(parlours, businessUnitFilter);

        const forSelectParlours = filteredParlours
            .filter(bu => bu.displayInSchedule)
            .map((bu, index) => {
                const newBu = {};
                newBu.value = bu.id;
                newBu.label = bu.name;
                newBu.address = bu.address;
                newBu.load = bu.load;
                newBu.index = index + 1;

                return newBu;
            });

        return <React.Fragment>
            <Grid item className={classes.hidePrint}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <ActionButton noMargin fullWidth={false} disabled={moment().isSame(currentDate, 'day')} onClick={this.handleToday}>{t("Today")}</ActionButton>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={this.handlePrevDay}><KeyboardArrowLeft fontSize="small" /></IconButton>
                        <IconButton onClick={this.handleNextDay}><KeyboardArrowRight fontSize="small" /></IconButton>
                    </Grid>
                    <Grid item>
                        <span>{currentDate.format("D MMMM YYYY")}</span><br /><span>{currentDate.format("dddd")}</span>
                    </Grid>
                    <Grid item className={classes.hidePrint}>
                        <ScheduleHeaderBusinessUnitFilter />
                    </Grid>
                    <Grid item className={classes.hidePrint}>
                        <Select
                            maxMenuHeight={715}
                            value={forSelectParlours.find(p => p.value === currentParlour?.id)}
                            isSearchable
                            placeholder={'Выберите салон'}
                            options={forSelectParlours}
                            onChange={this.handleChangeParlour}
                            formatOptionLabel={FormatLabel}
                            components={{ SingleValue, Control }}
                            filterOption={(option, inputValue) =>
                                option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                                option.data.address.toLowerCase().includes(inputValue.toLowerCase())
                            }
                            styles={selectStyles}
                        />
                    </Grid>
                    <Grid item className={classes.hidePrint}>
                        <CashBoxAndSafeState className={classes.hidePrint} />
                    </Grid>
                    {this.renderWorkingAdministrators()}
                    <Grid item className={classes.hidePrint}>
                        <ScheduleHeaderBusinessUnitAnnouncementsForm />
                    </Grid>
                    <Grid item className={classes.hidePrint}>
                        {switchButton}
                    </Grid>
                    <Grid item className={classes.hidePrint}>
                        <ParlourPassport />
                    </Grid>
                    <Grid item xs={12} className={classes.hidePrint}>
                        <ScheduleLegend hasRiskyStatus={currentParlour && currentParlour.hasRiskyStatus} />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>;
    }

    renderWorkingAdministrators() {
        const { workingAdministrators } = this.state;
        const { classes, t } = this.props;

        if (!workingAdministrators.length) {
            return null;
        }

        return (
            <Grid item className={cn(classes.moneyState, classes.hidePrint, classes.workingAdministrators)}>
                {t('Today administrators')}:<br /> {workingAdministrators.map((employee, index) => (<React.Fragment key={index}>{index !== 0 ? ', ' : ''}<Link to={'/organization/masters/view/' + employee.id}>{employee.name}</Link></React.Fragment>))}
            </Grid>
        );
    }
}