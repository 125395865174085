import moment from 'moment';

export function durationAsHHmm(duration) {
    const interval = 5 * 60 * 1000;
    duration = moment.duration(Math.round(duration.asMilliseconds() / interval) * interval);

    return `${duration.hours().toString().padStart(2,'0')}:${duration.minutes().toString().padStart(2,'0')}`
}

export function durationAsAllHHmm(duration) {
    const interval = 5 * 60 * 1000;
    duration = moment.duration(Math.round(duration.asMilliseconds() / interval) * interval);

    return `${Math.trunc(duration.asHours()).toString().padStart(2,'0')}:${duration.minutes().toString().padStart(2,'0')}`
}

export function durationAsMMss(duration) {
    return `${duration.minutes().toString().padStart(2,'0')}:${duration.seconds().toString().padStart(2,'0')}`
}

export function propTypeMoment(props, propName, componentName) {
    if (!moment.isMoment(props[propName]) && props[propName] !== undefined) {
        return new Error(
            `Invalid prop '${propName}' supplied to '${componentName}'. Received '${typeof props[propName]}'`,
        );
    }
    return undefined;
}

export function propTypeDuration(props, propName, componentName) {
    if (!moment.isDuration(props[propName]) && props[propName] !== undefined) {
        return new Error(
            `Invalid prop '${propName}' supplied to '${componentName}'. Received '${typeof props[propName]}'`,
        );
    }
    return undefined;
}

export function processMomentFieldInObject(object, fieldNames, dateFormat = 'YYYY-MM-DD') {
    const processObjectField = (result, fieldName, dateFormat) => {
        if (result && result[fieldName] && moment.isMoment(result[fieldName])) {
            result[fieldName] = encodeURIComponent(result[fieldName].format(dateFormat));
        }
    };

    const result = {...object};
    if (Array.isArray(fieldNames)) {
        fieldNames.forEach(fieldName => processObjectField(result, fieldName, dateFormat));
    } else {
        processObjectField(result, fieldNames, dateFormat);
    }

    return result;
}

export function equalDates(date1, date2, granularity = 'minute') {
    if (!date1 && !date2) {
        return true;
    }

    if (date1 && !date2) {
        return false;
    }

    if (!date1 && date2) {
        return false;
    }

    return date1.isSame(date2, granularity);
}

export function convertTimeZoneStringToNumber(timeZoneString) {

    const moduleRegexp = '\\+|\\-'
    const hoursRegexp = '^\\d*'
    const minutesRegexp = ':\\d*'

    let module = timeZoneString.match(moduleRegexp)[0]
    timeZoneString = timeZoneString.replace(module[0], '')

    const hour = parseInt(timeZoneString.match(hoursRegexp))
    const minutes = parseInt(timeZoneString.match(minutesRegexp)[0].replace(':','')) / 60

    let timeZoneNumber = hour + minutes

    if (module === '-') {
        timeZoneNumber = -timeZoneNumber
    }

    return timeZoneNumber
}