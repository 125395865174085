import React from 'react';
import * as PropTypes from 'prop-types';
import CheckboxField from "./CheckboxField";

export default
class SelectListField extends React.Component
{
    static propTypes = {
        items: PropTypes.array.isRequired,
        value: PropTypes.array,
        valueField: PropTypes.string,
        textField: PropTypes.string,
        onChange: PropTypes.func,
        name: PropTypes.string,
    };

    static defaultProps = {
        valueField: 'id',
        textField: 'name',
    };

    handleChange = event => {
        const id = event.target.name;
        const { value, onChange, name } = this.props;
        if (onChange) {
            if (value.indexOf(id) >= 0) {
                onChange({target: {value: value.filter(i => i !== id), name}});
            } else {
                onChange({target: {value: [...value, id], name}});
            }
        }
    };

    render() {
        const {items, valueField, textField, value} = this.props;

        return <div>
            {Boolean(items && items.length) &&
            items.map((item, itemIdx) =>
                <div key={itemIdx}>
                    <CheckboxField
                        margin="none"
                        name={item[valueField]}
                        value={value.indexOf(item[valueField]) >= 0}
                        label={item[textField]}
                        onChange={this.handleChange}
                    />
                </div>
            )}
            {!Boolean(items && items.length) && Boolean(value && value.length) &&
            value.map((item, itemIdx) =>
                <div key={itemIdx}>
                    <CheckboxField
                        margin="none"
                        name={item}
                        value={true}
                        label={item}
                        onChange={this.handleChange}
                    />
                </div>
            )}
        </div>;
    }
}

