import React from 'react';
import {
    Grid
} from '@material-ui/core';
import {
    getEmployeeSpecialization,
    navLinks, saveEmployeeSpecialization,
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import {isValidRequiredL18nString} from "../../utils/validators";

export default
@connect(null, {
    showMessage: messageDialogActions.show
})
@withRouter
class EmployeeSpecializationViewPage extends React.Component {

    state = {
        specialization: {
            id: null,
            nameAll: {},
        },
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getEmployeeSpecialization(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            specialization: response.data
                        })
                    }
                });
        }
    }

    handleChangeI18n = event => {
        const [prop, locale] = event.target.id.split('#');
        this.setState({
            specialization: {
                ...this.state.specialization,
                [prop]: {
                    ...this.state.specialization[prop],
                    [locale]: event.target.value,
                }
            }
        });
    };

    handleSave = () => {
        saveEmployeeSpecialization(this.state.specialization)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/employee-specializations`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    isSaveDisabled() {
        const { specialization } = this.state;

        return !isValidRequiredL18nString(specialization.nameAll);
    }

    render() {
        const { specialization } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleSave} disabled={this.isSaveDisabled()}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Карточка специализации">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#en"
                                label="Название, EN"
                                value={specialization.nameAll.en}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#ru"
                                label="Название, RU"
                                value={specialization.nameAll.ru}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#cs"
                                label="Название, CS"
                                value={specialization.nameAll.cs}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
