import React from 'react';
import {Route, Switch} from "react-router";
import ListPage from "./ListPage";
import RequestListPage from "./RequestListPage";
import RequestViewPage from "./RequestViewPage";
import ReceiptPage from "./ReceiptPage";
import OrderListPage from "./OrderListPage";
import OrderViewPage from "./OrderViewPage";
import ReportsPage from "./ReportsPage";
import HistoryRequestStatusPage from "./HistoryRequestStatusPage";
import HistoryPage from "./HistoryPage";
import CreateOrdersPage from "./CreateOrdersPage";
import CheckPage from "./CheckPage";
import HistoryOrderStatusPage from "./HistoryOrderStatusPage";
import OrderAdditionalPage from "./OrderAdditionalPage";
import ReceiptViewPage from "./ReceiptViewPage";
import SellListPage from "./SellListPage";
import SellViewPage from "./SellViewPage";
import MoveListPage from "./MoveListPage";
import MoveViewPage from "./MoveViewPage";
import MoveAdditionalPage from "./MoveAdditionalPage";
import HistoryMoveStatusPage from "./HistoryMoveStatusPage";
import HistoryOrderDeliveryDatePage from "./HistoryOrderDeliveryDatePage";
import GoodPricesListPage from "./GoodPricesListPage";
import GoodPricesViewPage from "./GoodPricesViewPage";
import GoodsReportSoldPage from "./GoodsReportSoldPage";
import InvoiceListPage from "./InvoiceListPage";
import InvoiceViewPage from "./InvoiceViewPage";
import ConsumptionGroupListPage from "./ConsumptionGroupListPage";
import ConsumptionGroupViewPage from "./ConsumptionGroupViewPage";
import CosmeticsMovementReportPage from "./CosmeticsMovementReportPage";
import RevisionListPage from "./RevisionListPage";
import RevisionViewPage from "./RevisionViewPage";


class GoodsPage extends React.Component {

    render() {

        return (
            <Switch>
                <Route exact path="/goods"           component={ListPage}/>
                <Route exact path="/goods/requests"  component={RequestListPage}/>
                <Route       path="/goods/requests/view"  component={RequestViewPage}/>
                <Route       path="/goods/requests/history-status"  component={HistoryRequestStatusPage}/>
                <Route exact path="/goods/orders"  component={OrderListPage}/>
                <Route exact path="/goods/orders/create"  component={CreateOrdersPage}/>
                <Route       path="/goods/orders/view"  component={OrderViewPage}/>
                <Route       path="/goods/orders/additional"  component={OrderAdditionalPage}/>
                <Route       path="/goods/orders/history-status"  component={HistoryOrderStatusPage}/>
                <Route       path="/goods/orders/history-delivery-date"  component={HistoryOrderDeliveryDatePage}/>
                <Route exact path="/goods/receipt"  component={ReceiptPage}/>
                <Route       path="/goods/receipt/view"  component={ReceiptViewPage}/>
                <Route exact path="/goods/sell"  component={SellListPage}/>
                <Route       path="/goods/sell/view"  component={SellViewPage}/>
                <Route       path="/goods/reports"  component={ReportsPage}/>
                <Route       path="/goods/history"  component={HistoryPage}/>
                <Route       path="/goods/check"  component={CheckPage}/>
                <Route exact path="/goods/move"  component={MoveListPage}/>
                <Route       path="/goods/move/view"  component={MoveViewPage}/>
                <Route       path="/goods/move/additional"  component={MoveAdditionalPage}/>
                <Route       path="/goods/move/history-status"  component={HistoryMoveStatusPage}/>
                <Route exact path="/goods/prices"  component={GoodPricesListPage}/>
                <Route       path="/goods/prices/"  component={GoodPricesViewPage}/>
                <Route       path="/goods/report/sold"  component={GoodsReportSoldPage}/>
                <Route exact path="/goods/invoice"  component={InvoiceListPage}/>
                <Route       path="/goods/invoice/view/"  component={InvoiceViewPage}/>
                <Route exact path="/goods/consumption-group" component={ConsumptionGroupListPage}/>
                <Route path="/goods/consumption-group/view/" component={ConsumptionGroupViewPage}/>
                <Route       path="/goods/report/cosmetics-movement"  component={CosmeticsMovementReportPage}/>
                <Route exact path="/goods/revision"  component={RevisionListPage}/>
                <Route       path="/goods/revision/view"  component={RevisionViewPage}/>
            </Switch>
        );
    }
}

export default GoodsPage;