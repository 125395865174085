import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {navLinks} from "../../services/personalAccount";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {getBusinessUnitByRole} from "../../services/user";
import {getSalaryEmployee} from "../../services/salary";
import {dataTableGroupValueStrategy, formatArrayMoney, formatArrayString, fromToDates} from "../../services/common";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import _ from "lodash";

const columnData = [
    //{ id: 'employeeName', label: 'Employee' },
    { id: 'day', label: 'Day', dateFormat: 'DD.MM.YYYY', groupStrategy: dataTableGroupValueStrategy.fromTo('day'), parentProcessValue: fromToDates('DD.MM.YYYY') },
    { id: 'businessUnitName', label: 'Business unit', groupStrategy: dataTableGroupValueStrategy.concat('businessUnitName')},
    { id: 'resultTotalFormat', label: 'Salary total', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultTotalWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    //{ id: 'activityText', label: 'Activity', groupStrategy: dataTableGroupValueStrategy.concat('activityText')},
    //{ id: 'activityDuration', label: 'Activity duration h', groupStrategy: dataTableGroupValueStrategy.sum('activityDuration'), processValue: value => Math.round(value*1000) / 1000.0},
    //{ id: 'resultWorkedShift', label: 'Activity duration shift', groupStrategy: dataTableGroupValueStrategy.sum('resultWorkedShift'), processValue: value => Math.round(value*1000) / 1000.0},
];

const dynamicColumnData = [
    { id: 'resultWageFormat', label: 'Salary wage', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultWageWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    //{ id: 'resultVariableFormat', label: 'Salary variable', groupStrategy:  dataTableGroupValueStrategy.arrayMoneySum('resultVariableWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentCreatedVisitsFormat', label: '% Visits created', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentCreatedVisitsWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentClosedVisitsFormat', label: '% Visits closed', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentClosedVisitsWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultHourCreatedVisitMoneyFormat', label: '% Visits hours', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultHourCreatedVisitMoneyWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultHourCreatedVisitMoneyNotMasterMoneyFormat', label: '% Visits hours (Not Master)', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultHourCreatedVisitMoneyNotMasterMoneyWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    //{ id: 'resultPercentVisitsFormat', label: '% Visits', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentVisitsWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentPayDocsFormat', label: '% PD', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentPayDocsWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentGoodsPDFormat', label: '% Goods PD', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentGoodsPDWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentGoodsMoneyFormat', label: '% Goods not PD', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentGoodsMoneyWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultPercentIncomingFormat', label: '% Incoming', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultPercentIncomingWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
    { id: 'resultWorkedHoursVisit', label: 'Visits hours', groupStrategy: dataTableGroupValueStrategy.sum('resultWorkedHoursVisit')},
    { id: 'resultWorkedHoursVisitNotMaster', label: 'Visits hours ( Not Master)', groupStrategy: dataTableGroupValueStrategy.sum('resultWorkedHoursVisitNotMaster')},
    { id: 'resultRqVisits', label: 'RQ Visits', groupStrategy: dataTableGroupValueStrategy.sum('resultRqVisits')},
    { id: 'resultRqHours', label: 'RQ hours', groupStrategy: dataTableGroupValueStrategy.sum('resultRqHours')},
    { id: 'resultRqFormat', label: 'RQ Total', groupStrategy: dataTableGroupValueStrategy.arrayMoneySum('resultRqWithoutRound'), parentProcessValue: formatArrayMoney, processValue: formatArrayString},
]

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});



export default
@connect(state => ({
    currentUser: state.auth.user,
}), {
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class SalaryReportPage extends React.Component {

    constructor(props) {
        super(props);

        const {currentUser} = this.props;

        const querySearch = getFilterFromQuery();

        let date_start = moment();
        let date_end = moment();
        let businessUnitId = null;
        let employeeId = currentUser.employee ? currentUser.employee.id : null;
        let dateType = 'period';

        if (querySearch) {
            if (querySearch.date_start) {
                date_start = moment(querySearch.date_start);
            }
            if (querySearch.date_end) {
                date_end = moment(querySearch.date_end);
            }
            if (querySearch.businessUnitId) {
                businessUnitId = querySearch.businessUnitId;
            }
            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }
        }

        this.state = {
            search: {
                dateType,
                date_start,
                date_end,
                businessUnitId,
                employeeIds: [employeeId],
                groupByDay: true
            },
            refreshTable: false,
            businessUnits: [],
            columnDataPrepared: [...columnData],
            showTable: !!employeeId,
        };
    }

    componentDidMount() {
        getBusinessUnitByRole('ROLE_SALARY_EMPLOYEE_GET')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        pushFilterToQuery(processMomentFieldInObject(this.state.search, ['date_start', 'date_end']));
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getSalaryEmployee(search, ...params)
            .then(response => {
                return new Promise((resolve, reject) => {
                    if (response.success) {
                        let columnDataPrepared = [ ...columnData ];
                        dynamicColumnData.forEach(col => {
                            if ( ! _.every(response.data, (el) => {
                                    let value = el[col.id];
                                    if (Array.isArray(value)) {
                                        if ( value.length < 1 ) {
                                            return true;
                                        }
                                        return _.every(value, (val) => !parseFloat(val));
                                    }
                                    return !parseFloat(value);
                                })
                            ) {
                                columnDataPrepared.push(col);
                            }
                        });

                        this.setState({
                            columnDataPrepared: columnDataPrepared
                        }, () => resolve(response))
                    } else {
                        reject(response);
                    }
                });

            });
    };

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, businessUnits, showTable, columnDataPrepared } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        minDate={moment('2020-04', 'YYYY-MM')}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t('Show moving by date interval')}
                        className={classes.filterControl}
                    />
                    <SelectFromItemsField
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        className={classes.filterControl}
                        items={businessUnits}
                        nullable
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                </LeftBar>
                <Content title={t('Employee salary')}>
                    {
                        showTable ?
                            <DataTable
                                columnData={columnDataPrepared}
                                dataFunc={this.getTableData}
                                refresh={refreshTable}
                                pagination={false}
                                groupBy="employeeName"
                                disableSorting
                                summary
                                selector={false}
                                selectOnlyParent={false}
                                groupHiddenColumns={[{ id: 'id' }]}
                            /> :
                            <p>Не доступно</p>
                    }
                </Content>
            </React.Fragment>
        );
    }
}
