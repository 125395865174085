import React, {Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {createConvert, getConvertDealTypes, navLinks} from "../../services/moneyFlow";
import AnyField from "../../components/fields/AnyField";
import {Grid, MenuItem} from "@material-ui/core";
import { emptyMoney} from "../../services/common";
import infoActions from "../../components/info/info-actions";
import {
    getBusinessUnits,
    getCheckingAccounts, getCheckingAccountsWithTrusted,
    getHumanEmployees, getJuridicalPersons
} from "../../services/organization";
import MoneyField from "../../components/fields/MoneyField";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {withTranslation} from "react-i18next";
import PercentField from "../../components/fields/PercentField";
import NumberFormatDecimal5 from "../../components/fields/NumberFormatDecimal5";
import {hasRole} from "../../services/user";
import DateTimeField2 from "../../components/fields/DateTimeField2";


const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

const emptyConvert = {
    amount: {...emptyMoney},
    businessUnitId: null,
    comment: null,
    convertType: null,
    checkingAccountId: null,
    toCurrency: null,
    modification: null,
    toCheckingAccountId: null,
    employeeId: null,
    toEmployeeId: null,
    createdAt: null,
    toBusinessUnitId: null,
    juridicalPersonId: null
};

const showCheckingAccountsType = [
    'cashBoxToCheckingAccount',
    'safeToCheckingAccount',
    'safeMCToCheckingAccount'
];

const checkingAccountsFromSelectedJuridicalPersonConvertTypes = [
    'checkingAccountToEmployee',
    'employeeToCheckingAccount'
]


export default
@connect(state => ({

}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class ConvertDealPage extends React.Component {
    state = {
        convertTypes: [],
        businessUnits: [],
        checkingAccounts: [],
        convert: {
            ...emptyConvert
        },
        employees: [],
        juridicalPersons: [],
        toBusinessUnitCheckingAccounts: []
    };

    componentDidMount() {
        getConvertDealTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        convertTypes: response.data,
                    });
                }
            });

        getBusinessUnits({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });

        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    });
                }
            });

        getJuridicalPersons()
            .then(response=>{
                if(response.success){
                    this.setState({
                        juridicalPersons: response.data,
                    });
                }
            });
    }

    handleChange = prop => event => {
        const { convert } = this.state

        if (prop === 'businessUnitId' && !checkingAccountsFromSelectedJuridicalPersonConvertTypes.includes(convert.convertType)) {
            getCheckingAccounts({'businessUnitId': event.target.value, active: true})
                .then(response => {
                    this.setState({
                        convert: {
                            ...this.state.convert,
                            checkingAccountId: null
                        },
                        checkingAccounts: response.data,
                    });
                });
        }

        if (prop === 'juridicalPersonId' && checkingAccountsFromSelectedJuridicalPersonConvertTypes.includes(convert.convertType) ) {
            getCheckingAccounts({'juridicalPersonId': event.target.value, active: true})
                .then(response => {
                    this.setState({
                        convert: {
                            ...this.state.convert,
                            checkingAccountId: null,
                            toCheckingAccountId: null
                        },
                        checkingAccounts: response.data,
                    });
                });
        }

        if (prop === 'toBusinessUnitId') {
            getCheckingAccountsWithTrusted({'businessUnitId': event.target.value, active: true})
                .then(response => {
                    this.setState({
                        convert: {
                            ...this.state.convert,
                            toCheckingAccountId: null
                        },
                        toBusinessUnitCheckingAccounts: response.data,
                    });
                });
        }

        this.setState({
            convert: {
                ...this.state.convert,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeMoney = prop => event => {
        this.setState({
            convert: {
                ...this.state.convert,
                [prop]: event.target.value,
            }
        });
    };

    handleAdd = () => {
        const { t } = this.props;
        createConvert(this.state.convert)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Money moved'));
                    this.setState({
                        convert: {
                            ...emptyConvert
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    renderCourseField = (errorAndCondition = true) => {
        const { convert } = this.state
        const { t } = this.props

        return <AnyField
                    label={t("Exchange course")}
                    value={convert.modification}
                    onChange={this.handleChange('modification')}
                    InputProps={{
                        inputComponent: NumberFormatDecimal5,
                    }}
                    fullWidth
                    required
                    error={ errorAndCondition && (convert.modification === null || convert.modification === undefined)}
                />
    }

    renderBusinessUnitField = (fieldName) => {
        const { convert, businessUnits } = this.state
        const { t } = this.props

        return <AutocompleteSelectField
                    label={t("Business unit")}
                    value={convert[fieldName]}
                    required
                    error={!convert[fieldName]}
                    onChange={this.handleChange(fieldName)}
                    fullWidth
                    options={businessUnits.map(item => ({
                        value: item.id,
                        label: `${item.name}`
                    }))}
                />
    }

    renderCheckingAccountField = (fieldName, checkingAccounts, label = "Checking account" ) => {
        const { convert } = this.state
        const { t } = this.props

        return <AnyField
            label={t(label)}
            value={convert[fieldName]}
            required
            error={!convert[fieldName]}
            onChange={this.handleChange(fieldName)}
            fullWidth
            select
        >
            {checkingAccounts.map(item => <MenuItem key={item.id} value={item.id}>{item.bankName} / {item.currency} / {item.juridicalPersonShortName}</MenuItem>)}
        </AnyField>
    }

    renderJuridicalPersonField = (juridicalPersons) => {
        const { convert } = this.state
        const { t } = this.props

        return <AutocompleteSelectField
            label={t("Juridical person")}
            value={convert.juridicalPersonId}
            name="juridicalPersonId"
            error={!convert.juridicalPersonId}
            onChange={this.handleChange('juridicalPersonId')}
            fullWidth
            select
            required
            options={juridicalPersons?.map(item => ({value: item.id, label: item.shortName}))}
        />
    }

    renderEmployeeField = () => {
        const { convert, employees } = this.state
        const { t } = this.props

        return <AutocompleteSelectField
                    label={t("Employee")}
                    value={convert.employeeId}
                    error={!convert.employeeId}
                    required
                    onChange={this.handleChange('employeeId')}
                    fullWidth
                    options={employees.map(item => ({value: item.id, label: item.name}))}
                />
    }

    renderCommissionInPercents = () => {
        const { convert } = this.state
        const { t } = this.props

        return <PercentField
                label={t("Commission in percents")}
                value={convert.modification}
                onChange={this.handleChange('modification')}
                fullWidth
                required
                error={(convert.modification === null || convert.modification === undefined)}
            />
    }

    renderResultField = (result) => {
        const { t } = this.props

        return <MoneyField
                    label={t("Result")}
                    value={ result }
                    fullWidth
                    readOnly
                />
    }

    render() {
        const { classes, t } = this.props;
        const {
            convert,
            convertTypes,
            businessUnits,
            checkingAccounts,
            toBusinessUnitCheckingAccounts,
            employees ,
            juridicalPersons
        } = this.state;

        const showCheckingAccountField = showCheckingAccountsType.indexOf(convert.convertType) !== -1;
        const showConvertCashFields = ['convertCashBoxes', 'convertSafes', 'convertEmployee'].indexOf(convert.convertType) !== -1;
        const isConvertCheckingAccounts = ['convertCheckingAccounts', 'checkingAccountToCashBox', 'checkingAccountToEmployee', 'employeeToCheckingAccount'].indexOf(convert.convertType) !== -1;
        const isConvertCheckingAccountsBetweenBusinessUnits = ['convertCheckingAccountsBetweenBusinessUnits'].indexOf(convert.convertType) !== -1;
        const hideToCheckingAccount = ['checkingAccountToCashBox', 'checkingAccountToEmployee'].indexOf(convert.convertType) !== -1;
        const hideFromCheckingAccount = ['employeeToCheckingAccount'].indexOf(convert.convertType) !== -1;
        const showPercentsModification = ['checkingAccountToCashBox', 'checkingAccountToEmployee', 'employeeToCheckingAccount' ].indexOf(convert.convertType) !== -1;

        let selectedBusinessUnit = businessUnits.find((item) => convert.businessUnitId === item.id);
        let currencies;
        if (isConvertCheckingAccounts) {
            let selectedCheckingAccount = checkingAccounts.find(
                (item) => convert.checkingAccountId === item.id || convert.toCheckingAccountId === item.id
            )
            currencies = selectedCheckingAccount ? [selectedCheckingAccount.currency] : [emptyMoney.cur];
        } else {
            currencies = selectedBusinessUnit ? selectedBusinessUnit.usingCurrencies : [emptyMoney.cur];
        }

        const showEmployee = [
            'cashBoxToEmployee',
            'employeeToCashBox',
            'safeToEmployee',
            'employeeToSafe',
            'convertEmployee',
            'checkingAccountToEmployee',
            'employeeToEmployee',
            'employeeToCheckingAccount'
        ].indexOf(convert.convertType) >= 0;

        const showToEmployee = [
            'employeeToEmployee'
        ].indexOf(convert.convertType) >= 0;

        let result = null;

        if (isConvertCheckingAccounts) {
            if (showPercentsModification) {
                result = { val: convert.amount.val * (1-convert.modification), cur: convert.amount.cur };
            } else {
                let currencyResult = convert.toCheckingAccountId ? checkingAccounts.find(item => item.id === convert.toCheckingAccountId).currency : null;
                result = {val: convert.amount.val * convert.modification, cur: currencyResult};
            }
        }

        if (isConvertCheckingAccountsBetweenBusinessUnits) {
            let currencyResult = convert.toCheckingAccountId ? toBusinessUnitCheckingAccounts.find(item => item.id === convert.toCheckingAccountId).currency : null;
            result = {val: convert.amount.val * convert.modification, cur: currencyResult};
        }

        if (showConvertCashFields) {
            result = { val: convert.amount.val * convert.modification, cur: convert.toCurrency };
        }

        const disabledButton =
            !convert.businessUnitId ||
            !convert.convertType ||
            !convert.amount.val ||
            (showConvertCashFields && (!convert.toCurrency || !convert.modification)) ||
            (
                isConvertCheckingAccounts &&
                (
                    convert.modification === null ||
                    convert.modification === undefined ||
                    ( !convert.toCheckingAccountId && !hideToCheckingAccount ) ||
                    ( !convert.checkingAccountId && !hideFromCheckingAccount )
                )
            ) ||
            (showEmployee && !convert.employeeId) ||
            (showToEmployee && !convert.toEmployeeId) ||
            (isConvertCheckingAccountsBetweenBusinessUnits && (
                !convert.toCheckingAccountId ||
                !convert.toBusinessUnitId ||
                !convert.checkingAccountId ||
                !convert.modification)
            )
        ;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAdd} disabled={disabledButton}>{t("Create money moving")}</ActionButton>
                </LeftBar>
                <Content title={t("Create money moving")}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            {this.renderBusinessUnitField('businessUnitId')}
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Type")}
                                value={convert.convertType}
                                required
                                error={!convert.convertType}
                                onChange={this.handleChange('convertType')}
                                fullWidth
                                select
                            >
                                {convertTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <MoneyField
                                label={t("Amount")}
                                currencies={ currencies }
                                value={ convert.amount }
                                error = { !convert.amount.val }
                                onChange={this.handleChangeMoney('amount')}
                                fullWidth
                                required
                            />
                        </Grid>
                        {isConvertCheckingAccountsBetweenBusinessUnits &&
                            <Fragment>
                                <Grid item xs={3}>
                                    {this.renderCheckingAccountField('checkingAccountId', checkingAccounts)}
                                </Grid>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid item xs={3}>
                                    {this.renderBusinessUnitField('toBusinessUnitId')}
                                </Grid>
                                <Grid item xs={3}>
                                    {this.renderCheckingAccountField('toCheckingAccountId', toBusinessUnitCheckingAccounts)}
                                </Grid>
                                <Grid item xs={6}/>
                                <Grid item xs={2}>
                                    {this.renderCourseField()}
                                </Grid>
                                <Grid item xs={2}>
                                    {this.renderResultField(result)}
                                </Grid>
                            </Fragment>
                        }
                        {showCheckingAccountField &&
                            <Grid item xs={3}>
                                <AnyField
                                    label={t("Checking account")}
                                    value={convert.checkingAccountId}
                                    required
                                    error={showCheckingAccountField && !convert.checkingAccountId}
                                    onChange={this.handleChange('checkingAccountId')}
                                    fullWidth
                                    select
                                >
                                    {checkingAccounts.map(item => <MenuItem key={item.id} value={item.id}>{item.bankName} / {item.currency} / {item.juridicalPersonShortName}</MenuItem>)}
                                </AnyField>
                            </Grid>
                        }
                        {showConvertCashFields &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <AnyField
                                        label={t("To currency")}
                                        value={convert.toCurrency}
                                        required
                                        error={showConvertCashFields && !convert.toCurrency}
                                        onChange={this.handleChange('toCurrency')}
                                        fullWidth
                                        select
                                    >
                                        {currencies.map(item => convert.amount.cur !== item && <MenuItem key={item} value={item}>{item}</MenuItem> )}
                                    </AnyField>
                                </Grid>
                            </React.Fragment>
                        }
                        {showEmployee &&
                            <Grid item xs={3}>
                                <AutocompleteSelectField
                                    label={t("Employee")}
                                    value={convert.employeeId}
                                    error={!convert.employeeId}
                                    required
                                    onChange={this.handleChange('employeeId')}
                                    fullWidth
                                    options={employees.map(item => ({value: item.id, label: item.name}))}
                                />
                            </Grid>
                        }
                        {showToEmployee &&
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("To employee")}
                                value={convert.toEmployeeId}
                                error={!convert.toEmployeeId}
                                required
                                onChange={this.handleChange('toEmployeeId')}
                                fullWidth
                                options={employees.map(item => ({value: item.id, label: item.name}))}
                            />
                        </Grid>
                        }
                    </Grid>

                    { isConvertCheckingAccounts || showConvertCashFields ?
                    <React.Fragment>
                        <Grid container spacing={2} className={classes.container}>
                            {
                                checkingAccountsFromSelectedJuridicalPersonConvertTypes.includes(convert.convertType) &&
                                <Grid item xs={2}>
                                    {this.renderJuridicalPersonField(juridicalPersons)}
                                </Grid>
                            }
                            {isConvertCheckingAccounts && !hideFromCheckingAccount ?
                                <Grid item xs={2}>
                                    <AnyField
                                        label={t("From checking account")}
                                        value={convert.checkingAccountId}
                                        required
                                        error={isConvertCheckingAccounts && !convert.checkingAccountId}
                                        onChange={this.handleChange('checkingAccountId')}
                                        fullWidth
                                        select
                                    >
                                        {checkingAccounts.map(item => <MenuItem key={item.id}
                                                                                value={item.id}>{item.bankName} / {item.currency} / {item.juridicalPersonShortName}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                                : null
                            }
                            {isConvertCheckingAccounts && !hideToCheckingAccount ?
                                <Grid item xs={2}>
                                    <AnyField
                                        label={t("To checking account")}
                                        value={convert.toCheckingAccountId}
                                        required
                                        error={isConvertCheckingAccounts && !convert.toCheckingAccountId}
                                        onChange={this.handleChange('toCheckingAccountId')}
                                        fullWidth
                                        select
                                    >
                                        {checkingAccounts.map(item => convert.checkingAccountId !== item.id &&
                                            <MenuItem key={item.id}
                                                      value={item.id}>{item.bankName} / {item.currency} / {item.juridicalPersonShortName}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                                : null
                            }
                            { !showPercentsModification ?
                                <Grid item xs={2}>
                                    <AnyField
                                        label={t("Exchange course")}
                                        value={convert.modification}
                                        onChange={this.handleChange('modification')}
                                        InputProps={{
                                            inputComponent: NumberFormatDecimal5,
                                        }}
                                        fullWidth
                                        required
                                        error={ isConvertCheckingAccounts && (convert.modification === null || convert.modification === undefined)}
                                    />
                                </Grid>
                            :
                                <Grid item xs={2}>
                                    <PercentField
                                        label={t("Commission in percents")}
                                        value={convert.modification}
                                        onChange={this.handleChange('modification')}
                                        fullWidth
                                        required
                                        error={ isConvertCheckingAccounts && (convert.modification === null || convert.modification === undefined)}
                                        />
                                </Grid>
                            }
                            <Grid item xs={2}>
                                <MoneyField
                                    label={t("Result")}
                                    value={ result }
                                    fullWidth
                                    readOnly
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    :null}

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Comment")}
                                value={convert.comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                        {hasRole('ROLE_MONEYFLOW_DEAL_CREATED_AT') ?
                            <Grid item xs={3}>
                                <DateTimeField2
                                    label={t("Date")}
                                    onChange={this.handleChange('createdAt')}
                                    value={convert.createdAt}
                                    fullWidth
                                />
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
