import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import {
    debtLevels, getActivityGroups,
    getSpendingTypeCategories,
    getSpendingTypes, getSystemSpendingTypes, getTypeActivities,
    saveSpendingType
} from "../../services/moneyFlow";
import SearchField from "../../components/fields/SearchField";
import CheckboxField from "../../components/fields/CheckboxField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {createChangeHandle} from "../../utils/helpers";
import {getBusinessRoles} from "../../services/user";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

const columnData = [
    { id: 'name', label: 'Наименование' },
    { id: 'category.name', label: 'Категория' },
    { id: 'description', label: 'Описание' },
    { id: 'debtLvl', label: 'Уровень долга' },
    { id: 'systemText', label: 'Системный тип расхода' },
];

const emptySpendingType = {
    id: null,
    name: null,
    description: null,
    categoryId: null,
    active: true,
    debtLvl: 1,
    activityGroup: null,
    activityType: null,
    businessRoleIds: []
};

export default
@connect(() => ({
}),{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
class SpendingTypesListPage extends React.Component
{
    state = {
        spendingType: emptySpendingType,
        dialogEditOpen: false,
        refreshTable: false,
        spendingTypeCategories: [],
        activityGroups: [],
        activityTypes: [],
        search: {
            search: null
        },
        systemTypes: [],
        businessRoles: []
    };

    componentDidMount() {
        getSpendingTypeCategories()
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypeCategories: response.data,
                    })
                }
            });
        getActivityGroups()
            .then(response=>{
                if(response.success){
                    this.setState({
                        activityGroups : response.data
                    })
                }
            })

        getTypeActivities()
            .then(response=>{
                if(response.success){
                    this.setState({
                        activityTypes: response.data
                    })
                }
            })
        getSystemSpendingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        systemTypes: response.data,
                    })
                }
            })
        getBusinessRoles()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessRoles: response.data,
                    })
                }
            })
    }

    getTableData = (...params) => {
        const {search} = this.state;
        return getSpendingTypes({...search, showInactive: true}, ...params);
    };

    handleAdd = () => {
        this.setState({
            spendingType: emptySpendingType,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialogCancel = () => {
        this.setState({
            dialogEditOpen: false,
        });
    }

    handleCloseEditDialogSuccess = () => {
        saveSpendingType(this.state.spendingType)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleEdit = spendingType => {
        this.setState({
            spendingType,
            dialogEditOpen: true,
        })
    };

    handleChange = createChangeHandle(this, 'spendingType')
    handleChangeSearch = createChangeHandle(this, 'search')


    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    rowStyleFunc = row => ({background: !row.active ? '#dbb3b3' : null})

    render() {
        const { dialogEditOpen, spendingType, refreshTable, spendingTypeCategories, search, systemTypes
        ,activityGroups, activityTypes, businessRoles} = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        name="search"
                        onChange={this.handleChangeSearch}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Статьи расходов">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        rowStyleFunc={this.rowStyleFunc}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialogCancel}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Статья расхода</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SelectFromItemsField
                                    items={spendingTypeCategories}
                                    label="Категория расхода"
                                    value={spendingType.categoryId}
                                    name="categoryId"
                                    onChange={this.handleChange}
                                    fullWidth
                                    error={!spendingType.categoryId}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Наименование расхода"
                                    value={spendingType.name}
                                    name="name"
                                    onChange={this.handleChange}
                                    required
                                    error={!spendingType.name}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectFromItemsField
                                    items={debtLevels}
                                    label={"Уровень"}
                                    value={spendingType.debtLvl}
                                    name="debtLvl"
                                    onChange={this.handleChange}
                                    fullWidth
                                    required
                                    error={!spendingType.debtLvl}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Описание расхода"
                                    value={spendingType.description}
                                    name="description"
                                    onChange={this.handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                                <Grid item xs={10}>
                                    <SelectFromItemsField
                                        items={activityTypes}
                                        label="Вид деятельности"
                                        value={spendingType.activityType}
                                        name="activityType"
                                        onChange={this.handleChange}
                                        fullWidth
                                    />

                                    <SelectFromItemsField
                                        items={activityGroups}
                                        label="Группа деятельности"
                                        value={spendingType.activityGroup}
                                        name="activityGroup"
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                    <AutocompleteMultiSelectField
                                        options={businessRoles}
                                        value={spendingType.businessRoleIds}
                                        name={`businessRoleIds`}
                                        onChange={this.handleChange}
                                        label="Разрешить для ролей"
                                        fullWidth
                                        emptySelectedLabel="Все"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxField
                                    label="Активна"
                                    value={spendingType.active}
                                    name="active"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectFromItemsField
                                    label="Системный тип расхода"
                                    name="system"
                                    items={systemTypes}
                                    value={spendingType.system}
                                    onChange={this.handleChange}
                                    nullable
                                    nullableText="Не используется"
                                    textField="text"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialogSuccess} disabled={!spendingType.name || !spendingType.categoryId || !spendingType.debtLvl} color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialogCancel} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
}
