import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    MenuItem,
    InputAdornment,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton, LinearProgress
} from "@material-ui/core";
import Autosuggest from 'react-autosuggest/dist/Autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import {createGuest, getGuest, getGuests, searchByPhoneGuest} from "../../services/guests";
import BackspaceRounded from "@material-ui/icons/BackspaceRounded";
import ForwardIcon from "@material-ui/icons/Forward";
import AnyField from "./AnyField";
import PhoneField from "./PhoneField";
import {isValidPhoneNumber} from "../../utils/phone-utils";
import {emptyFieldColor, fillFieldColor} from "../../services/constants";
import { connect } from "react-redux";
import messageDialogActions from "../dialogs/messageDialog-acions";
import classNames from "classnames";
import DateField2 from "./DateField2";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Face from '@material-ui/icons/Face';
import CopyField from "./CopyField";
import {createChangeHandle} from "../../utils/helpers";
import DialogButton from "../button/DialogButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";

const styles = theme => ({
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    imgLoading: {
        height: 30,
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    endAdornment: {
        marginRight: -theme.spacing(1) / 2,
    },
    textFieldContainer: {
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        bottom: 1,
        left: 1,
        right: 1,
    },
});

export default
@connect(null, {
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class GuestField extends React.PureComponent
{
    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
        readOnly: PropTypes.bool,
        required: PropTypes.bool,
        error: PropTypes.any,
        showCopyPhone: PropTypes.bool,
        name: PropTypes.string,
        showStar: PropTypes.bool,
        showPrevStar: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        readOnly: false,
        required: false,
        showCopyPhone: false,
        showStar: false
    };

    state = {
        inputValue: '',
        suggestions: [],
        isLoading: false,
        guest: {
            id: null,
            fullName: null,
            phone: null,
        },
        newGuest: {
            id: null,
            name: null,
            lastName: null,
            phone: null,
            birthDate: null,
        },
        foundGuest: null,
        dialogAddOpen: false,
    };

    componentDidMount() {
        this.loadGuest(); 
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== this.state.guest.id) {
            this.loadGuest();
        }
    }

    loadGuest = () => {
        if (this.props.value) {
            getGuest(this.props.value, true)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: response.data,
                            inputValue: this.getSuggestionValue(response.data),
                        })
                    }
                });
        } else {
            if (this.state.guest.id) {
                this.setState({
                    guest: {
                        id: null,
                        name: null,
                        lastName: null,
                        phone: null,
                        birthDate: null,
                        fullName: null,
                    },
                    inputValue: '',
                });
            }
        }
    };

    handleClear = () => {
        if (this.props.readOnly) {
            return;
        }

        this.setState({
            guest: {
                id: null,
                name: null,
                lastName: null,
                phone: null,
                birthDate: null,
                fullName: null,
            },
            inputValue: '',
        });

        if (this.props.onChange) {
            this.props.onChange( { target: { name: this.props.name, value: null } }, null );
        }
    };

    renderInputComponent = inputProps => {
        const { classes, readOnly, t, showCopyPhone, showStar, showPrevStar } = this.props;
        const { guest } = this.state;
        const { inputRef = () => {}, ref, loading, ...other } = inputProps;

        return (
            <div className={classes.textFieldContainer}>
                <TextField
                    fullWidth
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                        endAdornment: guest.id ?
                            <React.Fragment>
                                {showPrevStar &&
                                    <InputAdornment position="end" className={classes.endAdornment}>
                                        <Tooltip title={t("Guest was more than 2 months ago")}><StarIcon fontSize="small" style={{ color: 'yellow' }}/></Tooltip>
                                    </InputAdornment>
                                }
                                {showStar &&
                                <InputAdornment position="end" className={classes.endAdornment}>
                                    <Tooltip title={t("First time guest")}><StarIcon fontSize="small" style={{ color: '#f00' }}/></Tooltip>
                                </InputAdornment>
                                }
                                {guest.isClubMember &&
                                <InputAdornment position="end" className={classes.endAdornment}>
                                    <Tooltip title={t("Club member")}><Face fontSize="small"/></Tooltip>
                                </InputAdornment>
                                }
                                {showCopyPhone ? <InputAdornment position="end" className={classes.endAdornment}>
                                    <CopyField
                                        value={guest.phone}
                                        fullWidth
                                    />
                                </InputAdornment> : null}
                                <InputAdornment position="end" className={classes.endAdornment}>
                                    <Link to={`/guests/view/${guest.id}`} style={{display: 'flex'}}><Tooltip title={t("Go to guest card")}><ForwardIcon fontSize="small"/></Tooltip></Link>
                                </InputAdornment>
                                {!readOnly &&
                                    <InputAdornment position="end" className={classes.endAdornment}>
                                        <IconButton
                                            aria-label={t("Clear")}
                                            onClick={this.handleClear}
                                            title={t("Clear")}
                                            size="small"
                                        >
                                            <BackspaceRounded fontSize="small"/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            </React.Fragment>
                            : null,
                        readOnly: !!guest.id  || readOnly,
                        style: {
                            background: guest.id ? fillFieldColor : emptyFieldColor,
                        }
                    }}
                    {...other}
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
                {loading ?
                    <LinearProgress variant="query" className={classes.progress}/>
                    : null
                }
            </div>
        )
    };

    renderSuggestion(suggestion, { query, isHighlighted }) {
        if (suggestion.id === -1) {
            return <MenuItem selected={isHighlighted} component="div" dense>
                <ListItemIcon><AddCircleIcon fontSize="small"/></ListItemIcon>
                <Typography variant="inherit">{suggestion.fullName}</Typography>
            </MenuItem>;
        }

        const label = suggestion.id === -1 ? suggestion.fullName : `${suggestion.fullName} (${suggestion.phone})`;
        const matches = match(label, query);
        const parts = parse(label, matches);

        return (
            <MenuItem selected={isHighlighted} component="div" dense>
                    {parts.map((part, index) => {
                        return part.highlight
                            ? <span key={String(index)} style={{fontWeight: 500}}>{part.text}</span>
                            : <strong key={String(index)} style={{fontWeight: 300}}>{part.text}</strong>;
                    })}
            </MenuItem>
        );
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debounceLoadSuggestions(value);
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    loadSuggestions = value => {
        const { t } = this.props;

        this.setState({
            isLoading: true,
        });

        getGuests(value, 1, 20, 'asc', 'fullName')
            .then(response => {
                this.setState({
                    isLoading: false,
                });
                if (response.success) {
                    this.setState({
                        suggestions: [...response.data, {id: -1, fullName: t("Add a new guest")}]
                    })
                }
            })
    };

    debounceLoadSuggestions = _.debounce(this.loadSuggestions, 500);

    getSuggestionValue(suggestion) {
        return suggestion.fullName;
    }

    onSuggestionSelected = (event, { suggestion }) => {
        const { inputValue } = this.state;

        if (suggestion.id === -1) {
            this.setState({
                newGuest: {
                    id: null,
                    name: null,
                    lastName: null,
                    phone: inputValue ? inputValue.replace(/\D/g, '') : null,
                    birthDate: null,
                    fullName: null,
                },
                inputValue: '',
                dialogAddOpen: true,
                foundGuest: null,
            });
        } else {
            this.setState({
                guest: suggestion,
                inputValue: suggestion.fullName,
            });

            if (this.props.onChange) {
                this.props.onChange( { target: { name: this.props.name, value: suggestion.id } }, suggestion );
            }
        }
    };

    shouldRenderSuggestions = value => {
        const { guest } = this.state;
        return value.length >= 2 && !guest.id;
    };

    handleCloseAddDialogCancel = () => {
        this.setState({
            inputValue: '',
            dialogAddOpen: false,
        });
    }

    handleCloseAddDialogSuccess = () => {
        createGuest(this.state.newGuest)
            .then(response => {
                if (response.success) {
                    const guest = response.data;
                    this.setState({
                        guest,
                        inputValue: this.getSuggestionValue(guest),
                        dialogAddOpen: false,
                    });

                    if (this.props.onChange) {
                        this.props.onChange( { target: { name: this.props.name, value: guest.id } }, guest );
                    }
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleChange = createChangeHandle(this, 'newGuest');

    searchGuest(phone) {
        searchByPhoneGuest(phone, this.state.newGuest.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        foundGuest: response.data,
                    });
                } else {
                    this.setState({
                        foundGuest: null,
                    });
                }
            });
    }

    searchGuestDebounce = _.debounce(this.searchGuest, 500);

    handleChangeMainPhone = phone => {
        this.setState(state => ({ newGuest: { ...state.newGuest, phone } }) );

        this.searchGuestDebounce(phone);
    };

    handleChangeInput = event => {
        this.setState({
            inputValue: event.target.value,
        })
    }

    renderSuggestionsContainer = options => {
        return <Paper {...options.containerProps} square >{options.children}</Paper>;
    }

    render() {
        const { classes, className, label, required, error, t, disabled} = this.props;
        const { suggestions, inputValue, isLoading, newGuest, dialogAddOpen, foundGuest } = this.state;

        return (
            <div className={classNames(classes.root,{[classes.withLabel]: label}, className)}>
                <Autosuggest
                    inputProps={{
                        label: label,
                        loading: isLoading,
                        value: inputValue,
                        onChange: this.handleChangeInput,
                        required: required,
                        error: error,
                        disabled: disabled,
                    }}
                    renderInputComponent={this.renderInputComponent}
                    suggestions={suggestions}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    renderSuggestion={this.renderSuggestion}
                    getSuggestionValue={this.getSuggestionValue}
                    theme={{
                        suggestionsList: classes.suggestionsList,
                    }}
                    onSuggestionSelected={this.onSuggestionSelected}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                />
                <Dialog
                    open={dialogAddOpen}
                    onClose={this.handleCloseAddDialogCancel}
                >
                    <DialogTitle>{t("Add guest")}</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <PhoneField
                                    label={t("Phone")}
                                    defaultCountry="ru"
                                    value={newGuest.phone}
                                    onChange={this.handleChangeMainPhone}
                                    fullWidth
                                    error={!(newGuest.phone && isValidPhoneNumber(newGuest.phone))}
                                    errorText={foundGuest ? <span>Гость уже существует.</span> : null}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    id="name"
                                    label={t("Name, middle name")}
                                    value={newGuest.name}
                                    name="name"
                                    fullWidth
                                    required
                                    onChange={this.handleChange}
                                    error={!newGuest.name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateField2
                                    label={t("Date of Birth")}
                                    value={newGuest.birthDate}
                                    name="birthDate"
                                    onChange={this.handleChange}
                                    disableFuture
                                    openTo={newGuest.birthDate ? 'date' : 'year'}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnyField
                                    id="lastName"
                                    label={t("Surname")}
                                    value={newGuest.lastName}
                                    name="lastName"
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={this.handleCloseAddDialogSuccess} disabled={
                            !newGuest.name ||
                            !newGuest.phone ||
                            !(newGuest?.phone?.length === 11) ||
                            !isValidPhoneNumber(newGuest.phone) ||
                            foundGuest
                        }>OK</DialogButton>
                        <DialogButton onClick={this.handleCloseAddDialogCancel}>{t("Cancel")}</DialogButton>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
