import React from 'react';
import {getPayDocsSaleStats, navLinks} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import moment from "moment";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import ShowField from "../../components/fields/ShowField";
import DatePeriodField from "../../components/fields/DatePeriodField";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'code', numeric: false, label: 'Type of payment document' },
    { id: 'parlour', numeric: false, label: 'Parlour' },
    { id: 'cnt', numeric: true, disablePadding: false, label: 'Quantity' },
];

export default
@withTranslation()
class SaleStatsPage extends React.Component {

    state = {
        total: 0,
        dateFrom: moment().subtract(7,'days'),
        dateTo: moment(),
        refreshTable: false,
    };


    handleDateChange = prop => date => {
        this.setState(state => ({ [prop]: date, refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const { dateFrom, dateTo } = this.state;

        return getPayDocsSaleStats(dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD'),...params);
    };

    handleDataFetch = (response) => {
        this.setState({
            total: response.meta && response.meta.count_total ? response.meta.count_total : 0,
        });
    };

    render() {
        const { t } = this.props;
        const { total, dateFrom, dateTo, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ShowField
                        label={t("The total number of displayed payment documents")}
                        value={total}
                        label2Lines
                    />
                    <DatePeriodField
                        label={t("Period")}
                        valueFrom={dateFrom}
                        onChangeFrom={this.handleDateChange('dateFrom')}
                        valueTo={dateTo}
                        onChangeTo={this.handleDateChange('dateTo')}
                    />
                    <br />
                    <br />
                </LeftBar>
                <Content title={t("Payment documents")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        onDataFetch={this.handleDataFetch}
                    />
                </Content>
            </React.Fragment>
        );
    }
}