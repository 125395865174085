import React from 'react';
import {
    Grid, withStyles
} from '@material-ui/core';
import DataTable from "../../components/data-table/DataTable";
import {getReportRest, getReportRestUrl} from "../../services/goods";
import ActionButton from "../../components/button/ActionButton";
import { connect } from "react-redux";
import moment from "moment";
import DateField from "../../components/fields/DateField";
import DatePeriodField from "../../components/fields/DatePeriodField";

const columnData = [
    { id: 'name'                , label:'Наименование товара' },
    { id: 'category'            , label:'Группа товаров' },
    { id: 'description'         , label:'Описание товара', minWidth: 600 },
    { id: 'restMoneyFormatted'  , label:'Остаток в денежных единицах' },
    { id: 'restMassFormatted'   , label:'Остаток в массе' },
    { id: 'restCount'           , label:'Остаток в шт.' },
    { id: 'parlour'             , label:'Салон' },
];

const styles = theme => ({
    minWidth: {
        minWidth: 830,
    },
});

class ReportRest extends React.Component
{
    state = {
        from: moment().startOf('day').subtract(30, 'days'),
        to: moment().startOf('day').subtract(1, 'days'),
        target: moment().startOf('day').add(1, 'days'),
        refreshTable: false,
        now: moment().startOf('day')
    };

    getPeriod = () => {
        const { from, to, target } = this.state;
        return {
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD'),
            target: target.format('YYYY-MM-DD'),
        }
    };

    getTableData = (...params) => {
        return getReportRest(this.getPeriod(), ...params);
    };

    handleDateChange = prop => date => {
        this.setState({
            [prop]: date,
            refreshTable: !this.state.refreshTable,
        })
    };

    render() {
        const { token, classes } = this.props;
        const { from, to, refreshTable, target } = this.state;

        return (
            <Grid container spacing={2} className={classes.minWidth}>
                <Grid item xs={12}>Расчет остатков на заданное число</Grid>
                <Grid item xs={3}>
                    <DateField
                        label="Остаток на число"
                        value={target}
                        onChange={this.handleDateChange('target')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DatePeriodField
                        label="В качестве базы для расчета использовать период"
                        valueFrom={from}
                        valueTo={to}
                        onChangeFrom={this.handleDateChange('from')}
                        onChangeTo={this.handleDateChange('to')}
                    />
                </Grid>
                <Grid item xs={3}><ActionButton href={getReportRestUrl({access_token: token.access_token, xls: 1, search: this.getPeriod()})} marginLabeledFields>Выгрузить в Excel</ActionButton></Grid>
                <Grid item xs={12}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
});

export default connect(mapStateToProps, {})(withStyles(styles)(ReportRest));
