import React from 'react';
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    root: {
        color: 'red',
    },
});

const NonCorrectValue = ({ children, title }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} title={title}>
            {children}
        </div>
    );
};

NonCorrectValue.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
};

export default NonCorrectValue;