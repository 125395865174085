import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    withStyles
} from '@material-ui/core';
import {connect} from "react-redux";
import MoneyField from "../../components/fields/MoneyField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {withTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {getCurrentCashBoxAndSafeValue, getPaymentsIncoming} from "../../services/moneyFlow";
import {endWorkShift} from "../../services/organization";
import _ from "lodash";
import * as PropTypes from "prop-types";
import {getParlourTimes, getVisits, visitStatuses} from "../../services/calendar";
import {getNotCompletedTasks} from "../../services/tasks";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogButton from "../../components/button/DialogButton";
import {hasRole} from "../../services/user";
import {optionalSalePayTypes} from "../../services/sale";

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
    showMessage: messageDialogActions.show
})
@withStyles({
    visitsOpened__block: {
        border: '1px solid #f00',
        color: '#f00',
        marginTop: 0,
    },
    visitsOpened__title: {
        color: 'red',
    },
    visitsOpened__item: {
        color: 'red',
    },
    removeBottomPadding: {
        paddingBottom: '0 !important',
    },
})
@withTranslation()
class CloseWorkShiftModal extends React.PureComponent
{
    static propTypes = {
        onModalClosed: PropTypes.func,
        currentDate: PropTypes.any,
        businessUnitName: PropTypes.string,
    };

    state = {
        closeWorkShiftMoneyData: {},
        loading: true,
        visitsOpened: [],
        notCompletedTasks: [],
        errors: [],
    };

    componentDidMount() {
        this.openCloseWorkShiftDialog();
    }

    handleChangeCloseWorkShiftMoneyData = event => {
        const closeWorkShiftMoneyData = {...this.state.closeWorkShiftMoneyData};
        const [type, currency] = event.target.name.split('~');
        closeWorkShiftMoneyData[type][currency].fact = event.target.value;
        closeWorkShiftMoneyData[type][currency].delta = {
            val: closeWorkShiftMoneyData[type][currency].fact.val - closeWorkShiftMoneyData[type][currency].system.val,
            cur: closeWorkShiftMoneyData[type][currency].system.cur
        };

        closeWorkShiftMoneyData.total[currency].fact = {
            val: closeWorkShiftMoneyData.cash[currency].fact.val*1 + (closeWorkShiftMoneyData.cashless[currency] ? closeWorkShiftMoneyData.cashless[currency].fact.val*1 : 0),
            cur: closeWorkShiftMoneyData[type][currency].fact.cur
        };

        closeWorkShiftMoneyData.total[currency].delta = {
            val: closeWorkShiftMoneyData.total[currency].fact.val - closeWorkShiftMoneyData.total[currency].system.val,
            cur: closeWorkShiftMoneyData.total[currency].system.cur
        };

        this.setState({
            closeWorkShiftMoneyData
        });
    };

    openCloseWorkShiftDialog = () => {
        const { currentParlour, currentDate } = this.props;
        const promises = [];
        const parlourTimes = getParlourTimes(currentParlour, currentDate);
        const errors = [];

        promises[0] = getPaymentsIncoming(currentParlour.id, currentDate)
            .then(response => {
                if (response.success) {
                    return response.data;
                }
                errors.push(response.error ? response.error.message : response.message);
                return {};
            });

        promises[1] = getCurrentCashBoxAndSafeValue({businessUnitId: currentParlour.id, noFormat: '1', day: currentDate})
            .then(response => {
                if (response.success) {
                    return response.data;
                }
                errors.push(response.error ? response.error.message : response.message);
                return {};
            });

        promises[2] = getVisits({
            parlour: currentParlour.id,
            fromDate: currentDate.clone().hours(parlourTimes.startTime.hours()).minutes(parlourTimes.startTime.minutes()).seconds(0).millisecond(0).toISOString(),
            toDate: currentDate.clone().hours(parlourTimes.endTime.hours()).minutes(parlourTimes.endTime.minutes()).seconds(0).millisecond(0).toISOString(),
            statuses: [
                visitStatuses.providing,
                visitStatuses.starting,
                visitStatuses.complete,
                visitStatuses.risky,
                visitStatuses.confirmed,
                visitStatuses.created,
                visitStatuses.new
            ]
        })
            .then(response => {
                if (response.success) {
                    return response.data;
                }

                errors.push(response.error ? response.error.message : response.message);
                return [];
            });

        if (hasRole('ROLE_TASK_GET')) {
            promises[3] = getNotCompletedTasks(currentParlour.id, currentDate)
                .then(response => {
                    if (response.success) {
                        return response.data;
                    }

                    errors.push(response.error ? response.error.message : response.message);
                    return [];
                });
        } else {
            promises[3] = new Promise(resolve => {
                resolve([]);
            });
        }

        Promise.all(promises).then(([incoming, cashBoxSafe, visitsOpened, notCompletedTasks]) => {
            const preCloseWorkShiftMoneyData = {...incoming, ...cashBoxSafe};
            const closeWorkShiftMoneyData = {};

            //fill all types and currencies
            Object.keys(preCloseWorkShiftMoneyData).forEach( (type) => {
                let currencyKey = type === 'cashless' ? 'usingCurrenciesCashless' : 'usingCurrencies';
                currentParlour[currencyKey].forEach( (currency) => {
                    let emptyMoney = { val: 0, cur:currency };

                    if (!closeWorkShiftMoneyData[type]) {
                        closeWorkShiftMoneyData[type] = {};
                    }

                    if (!closeWorkShiftMoneyData[type][currency]) {
                        closeWorkShiftMoneyData[type][currency] = {};
                    }

                    if ( !preCloseWorkShiftMoneyData[type][currency] ) {
                        closeWorkShiftMoneyData[type][currency].system = {...emptyMoney};
                    } else {
                        closeWorkShiftMoneyData[type][currency].system = {...preCloseWorkShiftMoneyData[type][currency]};
                    }
                    closeWorkShiftMoneyData[type][currency].fact = {...emptyMoney};
                    closeWorkShiftMoneyData[type][currency].delta = {...emptyMoney};

                    if (type === optionalSalePayTypes.checking_account) {
                        closeWorkShiftMoneyData[type][currency].fact.val = closeWorkShiftMoneyData[type][currency].system.val
                    }
                })
            });

            this.setState({
                closeWorkShiftMoneyData,
                visitsOpened,
                loading: false,
                notCompletedTasks,
                errors,
            });

        });
    };

    handleCloseWorkShiftDialogCancel = () => {
        this.props.onModalClosed(false);
    };

    handleCloseWorkShiftDialogSuccess = () => {
        const { currentParlour } = this.props;
        const { closeWorkShiftMoneyData } = this.state;
        endWorkShift({ businessUnitId: currentParlour.id, closeWorkShiftMoneyData })
            .then(response => {
                if (response.success) {
                    this.props.onModalClosed(response.data);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleFocus = event => {
        event.target.select();
    }

    render() {
        const { t, classes, currentDate, businessUnitName } = this.props;
        const { closeWorkShiftMoneyData, loading, visitsOpened, notCompletedTasks, errors } = this.state;
        const types = {
            cash: t("Incoming cash"),
            cashless: t("Incoming cashless"),
            total: t("Incoming total"),
            cash_box: t("Cashbox"),
            checking_account: t("Checking account")
        };

        const buttonEnabled = _.every( Object.values(closeWorkShiftMoneyData), (type) => {
            return _.every( Object.values(type), (money) => {
                return money.fact.val !== '' && money.fact.val !== null;
            });
        }) && errors.length === 0;

        return <Dialog open={true} onClose={this.handleCloseWorkShiftDialogCancel} maxWidth="sm" fullWidth>
            <DialogTitle>{t('Close work shift')} {currentDate.format('DD.MM.YYYY')} {businessUnitName}</DialogTitle>
            <DialogContent>
                {loading ?
                    <>
                        <LinearProgress/>
                    </>
                    : errors.length ?
                        <>
                            <Typography variant="subtitle1" color="error">{t('Data loading errors')}: </Typography>
                            {errors.map((error, idx) => <Typography key={idx} color="error">{error}</Typography>)}
                        </>
                        : <>
                            {
                                visitsOpened && Array.isArray(visitsOpened) && visitsOpened.length > 0
                                    ?
                                    <React.Fragment>
                                        <Grid container spacing={2} className={classes.visitsOpened__block}>
                                            <Grid item xs={12} className={classes.removeBottomPadding}>
                                                <Typography variant="h6" gutterBottom={false} className={classes.visitsOpened__title}>
                                                    {t('Calendar.openVisits')}
                                                </Typography>
                                            </Grid>
                                            {visitsOpened.map((visit, index) =>
                                                <Grid item xs={12} key={index}>
                                                    <a href={'/orders/view/' + visit.id} className={classes.visitsOpened__item}>
                                                        {visit.guest ? visit.guest.fullName : 'Инкогнито'}
                                                    </a>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                    : null
                            }
                            {
                                notCompletedTasks && Array.isArray(notCompletedTasks) && notCompletedTasks.length > 0
                                    ?
                                    <React.Fragment>
                                        <Grid container spacing={2} className={classes.visitsOpened__block}>
                                            <Grid item xs={12} className={classes.removeBottomPadding}>
                                                <Typography variant="h6" gutterBottom={false} className={classes.visitsOpened__title}>
                                                    {t('Calendar.notCompletedTasks')}
                                                </Typography>
                                            </Grid>
                                            {notCompletedTasks.map((task, index) =>
                                                <Grid item xs={12} key={index}>
                                                    <a href={'/tasks/view/' + task.id} className={classes.visitsOpened__item}>
                                                        {task.title} №{task.num}
                                                    </a>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                    : null
                            }
                            {Object.keys(types).map((type) => {
                                if (closeWorkShiftMoneyData[type]) {
                                    const valuesCount = Object.keys(closeWorkShiftMoneyData[type]).length;
                                    const itemBlocks = Math.min(Math.trunc(12 / valuesCount), 6);
                                    return <Grid container spacing={2} key={type}>
                                        <Grid item xs={12} className={classes.removeBottomPadding}>
                                            <Typography variant="h6" gutterBottom={false}>{types[type]}</Typography>
                                        </Grid>
                                        {Object.keys(closeWorkShiftMoneyData[type]).map((currency) =>
                                            <Grid item xs={itemBlocks} key={type + currency}>
                                                <MoneyField
                                                    value={closeWorkShiftMoneyData[type][currency].fact}
                                                    name={`${type}~${currency}`}
                                                    onChange={this.handleChangeCloseWorkShiftMoneyData}
                                                    fullWidth
                                                    readOnly={type === 'total'}
                                                    required
                                                    onFocus={this.handleFocus}
                                                    disableCurrencySelect
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                }

                                return null;
                            })}
                        </>
                }

            </DialogContent>
            <DialogActions>
                <DialogButton onClick={this.handleCloseWorkShiftDialogSuccess} disabled={!buttonEnabled}>{t('Close work shift')}</DialogButton>
                <DialogButton onClick={this.handleCloseWorkShiftDialogCancel}>{t('Cancel')}</DialogButton>
            </DialogActions>
        </Dialog>;

    }
}
