import React from "react";
import * as PropTypes from "prop-types";
import ScheduleSheet from "./ScheduleSheet";
import {getAdminsWorking, getEquipments, getMastersWorking, loadParlours} from "../../services/organization";
import {
    getVisitCountGuests,
    getParlourTimes,
    getVisits,
    getVisitSnapshots,
    getVisitStatusColor,
    updateVisit,
    visitStatuses
} from "../../services/calendar";
import * as cn from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import {connect} from "react-redux";
import socketActions from "../../socket/socket-actions";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import infoActions from "../../components/info/info-actions";
import {IconButton, Snackbar, Tooltip} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import {withTranslation} from "react-i18next";
import LinkIcon from "@material-ui/icons/Link";
import OnlineIcon from "@material-ui/icons/Public";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import {equalDates, processMomentFieldInObject} from "../../utils/moment-utils";
import calendarActions from "./calendar-actions";
import { activityTypes } from "../../services/calendarEmployee";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import Face from '@material-ui/icons/Face';
import {paymentStatus} from "../../services/sale";
import {hasRole} from "../../services/user";
import {getMastersAllLoadingByParlourAndDay} from "../../services/moneyFlow";
import HistoryIcon from '@material-ui/icons/History';
import StarIcon from "@material-ui/icons/Star";
import {durationAsHHmm} from "../../utils/moment-utils";


const moment = extendMoment(Moment);

export default
@connect(state => ({
    socketMessages: state.socket.messages,
    visits: state.calendar.visits,
    transferredVisits: state.calendar.transferredVisits,
    parlour: state.common.currentParlour,
    hiddenStatuses: state.calendar.hiddenStatuses,
    showTransferredVisits: state.calendar.showTransferredVisits,
}),{
    removeMessages: socketActions.removeMessages,
    showError: infoActions.showError,
    showInfo: infoActions.show,
    setVisits: calendarActions.setVisits,
    setTransferredVisits: calendarActions.setTransferredVisits,
    setShowTransferredVisits: calendarActions.setShowTransferredVisits,
})
@withStyles({
    event: {
        padding: '2px 3px',
        border: '1px solid #888',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        boxSizing: 'border-box',
        lineHeight: 1.1,

        '@media print': {
            borderColor: '#000',
        }
    },
    eventWithColorFacilityLine: {
        paddingLeft: 20,
    },
    facilityLine: {
        width: 7,
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    eventHasLinkedVisits: {
        '@media screen': {
            //boxShadow: 'inset 0px 0px 5px 2px rgba(122,95,42,1)',
            borderColor: 'blue',
            borderWidth: 2,
        }
    },
    eventCurrent: {
        borderColor: '#333',
        borderWidth: 2,
    },
    eventLabelTime: {
        fontSize: '0.7rem',
        fontWeight: 'bold',
    },
    eventLabel: {
        fontSize: '0.7rem',
    },
    eventIcons: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    eventIcon: {
        fontSize: '13px',
    },
    starIcon: {
        fontSize: '13px',
        color: '#f00'
    },
    masterRating: {
        fontSize: '13px',
        position: 'absolute',
        top: 0,
        textAlign: 'center',
        width: '100%',
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 2
    },
    masterRatingStar: {
        fontSize: 15,
        color: '#FFC839'
    },
    loadDuration: {
        fontSize: '13px',
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        width: '100%',
        left: 0,
        display: 'flex',
        justifyContent: 'space-around'
    },
    loadDurationUnfree: {
        color: '#6f88df',
        transform: 'translateY(-32px);'
    },
    loadDurationFree: {
        color: '#890015',
        transform: 'translateY(-32px);'
    },
    showSchedule: {
        display: 'none',
        '@media print': {
            display: 'block',
        }
    },
    showPrint: {
        '@media print': {
            display: 'none',
        },
    },
    markPair: {
        '@media screen': {
            borderRadius: '50%',
            left: '50%',
            top: '50%',
            position: 'absolute',
            width: 10,
            height: 10,
            background: 'red',
            border: '1px solid black',
        }
    },
    eventStriped: {
        backgroundImage: 'url(/img/striped-red.png)',
    },
    roomComment: {
        position: 'absolute',
        right: -1,
        bottom: -1,
        fontSize: 12,
        fontWeight: 500,
        color: '#fff',
        border: '1px solid black',
        padding: '1px 2px',
    },
})
@withTranslation()
class StuffScheduleVisits extends React.PureComponent
{
    static propTypes = {
        date: PropTypes.instanceOf(moment).isRequired,

        reloadVisits: PropTypes.bool,
        reloadMasters: PropTypes.bool,

        onSlotClick: PropTypes.func,
        onSlotDbClick: PropTypes.func,
        onSlotContextMenu: PropTypes.func,

        selectedVisit: PropTypes.object,

        onVisitClick: PropTypes.func,
        onVisitDbClick: PropTypes.func,
        onVisitContextMenu: PropTypes.func,

        onHeaderClick: PropTypes.func,

        setMasters: PropTypes.func,
    };

    static defaultProps = {
        reloadVisits: false,
        reloadMasters: false,
    };

    constructor(props) {
        super(props);

        const visitMessages = props.socketMessages.filter(message => message.entity === 'visit');
        if (visitMessages.length) {
            props.removeMessages(visitMessages.map(message => message.id));
        }

        this.state = {
            masters: [],
            loadParlours: [],

            startTime: moment.duration("00:00"),
            endTime: moment.duration("23:59"),
            relaxTime: 15,

            reloadVisits: false,
            reloadMasters: false,

            draggingIdx: -1,
            ctrlPressed: false,

            equipments: [],
            lines: [],
            adminSlots: [],
            mastersLoadData: [],

            guestsVisitCount: {}
        };

        this.mastersMap = {};
        this.equipmentsMap = {};
        this.bufferColumn = -1;
    }

    componentDidMount() {
        this.setParlourTimes(this.props.parlour, this.props.date)
            .then(() => {
                this.loadMasters(this.props);
                this.loadVisits(this.props);
                this.loadSnapshots(this.props)
                this.setLines(this.props);
                this.loadGuestsVisitCount(this.props);
            });

        this.loadParlours(this.props.parlour, this.props.date)

        this.getMastersLoadData();

        document.addEventListener('keydown', this.handleKeyboardEvent);
        document.addEventListener('keyup', this.handleKeyboardEvent);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyboardEvent);
        document.removeEventListener('keyup', this.handleKeyboardEvent);
    }

    handleKeyboardEvent = event => {
        if (event.key === 'Control') {
            this.setState({
                ctrlPressed: event.type === 'keydown',
            });
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.parlour !== this.props.parlour ||
            !equalDates(nextProps.date, this.props.date)
        ) {
            this.setParlourTimes(nextProps.parlour, nextProps.date)
                .then(() => {
                    this.loadMasters(nextProps);
                    this.loadVisits(nextProps);
                    this.loadSnapshots(nextProps);
                    this.loadGuestsVisitCount(nextProps);
                });
            this.loadParlours(nextProps.parlour, nextProps.date)
        }

        if (nextProps.parlour !== this.props.parlour) {
            this.setLines(nextProps);
        }

        if (nextProps.reloadVisits !== this.props.reloadVisits) {
            this.loadVisits(nextProps);
            this.loadSnapshots(nextProps);
            this.loadGuestsVisitCount(nextProps);
        }

        if (nextProps.reloadMasters !== this.props.reloadMasters) {
            this.loadMasters(nextProps);
        }

        if (nextProps.socketMessages !== this.props.socketMessages) {
            let processedIds = [];
            let visits = [];
            let removeVisits = [];

            nextProps.socketMessages.forEach(message => {
                if (message.entity && message.entity === 'visit') {
                    processedIds.push(message.id);
                    if (message.action === 'remove') {
                        removeVisits.push(message.entityId);
                    } else if (message.entityData) {
                        visits.push(message.entityData);
                    }
                }
            });

            visits = this.processingVisit(visits.filter(visit => visit.parlour && this.props.parlour && visit.parlour.id === this.props.parlour.id));
            const visitsFilter = this.getVisitsFilter(this.props);
            visits = visits.filter(visit => visit.start.isBetween(visitsFilter.fromDate, visitsFilter.toDate, 'minute', '[]'));

            let draggingIdx = this.state.draggingIdx;
            let draggingVisitId = draggingIdx >= 0 ? this.props.visits[draggingIdx].id : null;

            if (draggingVisitId) {
                if (visits.findIndex(newVisit => newVisit.id === draggingVisitId) >= 0 || removeVisits.indexOf(draggingVisitId) >= 0) {
                    draggingIdx = -1;
                    draggingVisitId = null;
                }
            }

            if (visits.length || removeVisits.length) {
                const oldFilteredVisits = this.props.visits.filter(oldVisit => visits.findIndex(newVisit => newVisit.id === oldVisit.id) === -1 && removeVisits.indexOf(oldVisit.id) === -1);

                if (draggingVisitId) {
                    draggingIdx = oldFilteredVisits.findIndex(oldVisit => oldVisit.id === draggingVisitId);
                }

                this.props.setVisits([
                    ...oldFilteredVisits,
                    ...visits,
                ]);

                this.setState({
                    draggingIdx,
                });

                this.getMastersLoadData();
            }

            if (processedIds.length) {
                this.props.removeMessages(processedIds);
            }
        }
    }

    getMastersLoadData = () => {
        const { startTime } = this.state;
        getMastersAllLoadingByParlourAndDay({
            'day': this.props.date.clone().startOf('day').add(startTime).toISOString(),
            'parlourId': this.props.parlour.id
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        mastersLoadData: response.data,
                    });
                }
            });
    }

    loadParlours(parlour, date) {
        if (hasRole('ROLE_CALLCENTER_TIMESEARCH')) {
            loadParlours(processMomentFieldInObject({
                dateFrom: date,
                dateTo: date.clone().endOf('day'),
                businessUnitIds: [parlour.id]
            }, ['dateFrom', 'dateTo']), 1, -1, 'asc', 'date_field')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            loadParlours: response.data,
                        });
                    }
                });
        }
    }

    getVisitsFilter = (props) => {
        const { startTime, endTime } = this.state;
        return {
            parlour: props.parlour ? props.parlour.id : null,
            fromDate: props.date.clone().startOf('day').add(startTime).toISOString(),
            toDate: props.date.clone().startOf('day').add(endTime).toISOString(),
        };
    };

    setLines = props => {
        if (props.parlour && props.parlour.id) {
            let lines = props.parlour.scheduleLines.map(line => {
                let time = moment(line.time).utcOffset(props.parlour.timeZone);
                return {
                    time: time,
                    color: line.color
                };
            });
            this.setState({
                lines: lines
            })
        }
    };

    loadMasters = props => {
        if (props.parlour && props.parlour.id) {
            getMastersWorking(this.getVisitsFilter(props) )
                .then(response => {
                    if (response.success) {
                        let masters = response.data;

                        masters = masters.filter((master) => {
                            return (
                                master.workShift &&
                                !(
                                    master.workShift.length === 1 &&
                                    master.workShift[0].activity === 'waiting'
                                )
                            )
                        })

                        this.mastersMap = {};
                        masters.forEach((master, masterIdx) => {
                            this.mastersMap[master.id] = masterIdx;
                            master.enabledSlots = [];
                            master.enabledColumns = [];
                            master.disabledSlots = [];
                            master.waiting = [];
                            if (master.workShift && Array.isArray(master.workShift)) {
                                master.workShift.forEach(workShift => {
                                    const start = moment(workShift.timeStart).utcOffset(props.parlour.timeZone).seconds(0);
                                    const end = moment(workShift.timeEnd).utcOffset(props.parlour.timeZone).seconds(0);
                                    if (props.date.isSame(start, 'day')) {
                                        if (workShift.parlour.id === props.parlour.id &&  workShift.activity === activityTypes.waiting) {
                                            master.waiting.push({
                                                day: props.date,
                                            });
                                        }

                                        if (workShift.parlour.id === props.parlour.id && (workShift.activity === activityTypes.working || workShift.activity === activityTypes.waiting)) {
                                            master.enabledSlots.push({
                                                day: props.date,
                                                start,
                                                end,
                                                startDuration: start.diff(props.date),
                                                endDuration: end.diff(props.date),
                                            });
                                        } else if (workShift.parlour.id === props.parlour.id && workShift.activity === activityTypes.overtime ) {
                                            master.enabledSlots.push({
                                                day: props.date,
                                                start,
                                                end,
                                                startDuration: start.diff(props.date),
                                                endDuration: end.diff(props.date),
                                            });
                                        } else {
                                            master.disabledSlots.push({
                                                day: props.date,
                                                start,
                                                end,
                                                startDuration: start.diff(props.date),
                                                endDuration: end.diff(props.date),
                                                parlour: workShift.parlour,
                                                activity: workShift.activity,
                                                comment: workShift.comment,
                                            });
                                        }
                                    }
                                });

                                if (master.enabledSlots.length) {
                                    master.enabledColumns.push(props.date);
                                }
                            }

                        });
                        this.setState({
                            masters
                        }, () => {
                            this.props.setMasters && this.props.setMasters(masters);
                            this.getMastersLoadData();
                        });

                    }
                });

            getEquipments({businessUnitId: props.parlour.id, status: 'working'})
                .then(response => {
                    if (response.success) {
                        const equipments = response.data;

                        this.equipmentsMap = {};
                        equipments.forEach((equipment, equipmentIdx) => {
                            this.equipmentsMap[equipment.id] = equipmentIdx;

                            equipment.enabledSlots = [];
                            equipment.enabledColumns = [];
                            equipment.disabledSlots = [];

                            const start = moment().utcOffset(props.parlour.timeZone).startOf('day');
                            const end = start.clone().add(1, 'hour');
                            equipment.enabledSlots.push({
                                day: props.date,
                                start,
                                end,
                                startDuration: start.diff(props.date),
                                endDuration: end.diff(props.date),
                            });
                        });

                        this.setState({
                            equipments,
                        });
                    }
                });

            getAdminsWorking(this.getVisitsFilter(props) )
                .then(response => {
                    if (response.success) {
                        const adminSlots = [];
                        response.data.forEach(admin => {
                            if (admin.workShift && Array.isArray(admin.workShift)) {
                                admin.workShift.forEach(workShift => {
                                    const start = moment(workShift.timeStart).utcOffset(props.parlour.timeZone).seconds(0);
                                    const end = moment(workShift.timeEnd).utcOffset(props.parlour.timeZone).seconds(0);
                                    if (props.date.isSame(start, 'day')) {

                                        if (workShift.parlour.id === props.parlour.id && (workShift.activity === activityTypes.working || workShift.activity === activityTypes.overtime)) {
                                            adminSlots.push({
                                                day: props.date,
                                                start,
                                                end,
                                                startDuration: start.diff(props.date),
                                                endDuration: end.diff(props.date),
                                            });
                                        }
                                    }
                                });
                            }
                        });
                        this.setState({
                            adminSlots,
                        });
                    }
                });
        } else {
            this.setState({
                masters: [],
                equipments: [],
                adminSlots: [],
            })
        }
    };

    loadVisits = (props) => {
        if (props.parlour && props.parlour.id) {
            this.props.setVisits([]);

            this.setState({
                draggingIdx: -1,
            });
            getVisits(this.getVisitsFilter(props))
                .then(response => {
                    if (response.success) {
                        if (response.data.length && response.data[0].parlour && response.data[0].parlour.id === this.props.parlour.id) {
                            this.props.setVisits(this.processingVisit(response.data));
                            this.setState({
                                draggingIdx: -1,
                            });
                        }
                    }
                });
        } else {
            this.props.setVisits([]);
            this.setState({
                draggingIdx: -1,
            });
        }
    };

    loadSnapshots(props) {
        this.props.setTransferredVisits([]);
        getVisitSnapshots(this.getVisitsFilter(props))
            .then(response => {
                if (response.success) {
                    if (response.data.length && response.data[0].parlour && response.data[0].parlour.id === this.props.parlour.id) {
                        this.props.setTransferredVisits(this.processingVisit(response.data));
                        if(this.props.showTransferredVisits) {
                            this.props.setShowTransferredVisits(true)
                        }
                    }
                }
            })
    }

    loadGuestsVisitCount(props) {
        const { startTime } = this.state;
        let day = props.date.clone().startOf('day').add(startTime).format('YYYY-MM-DD');
        let businessUnitId = props.parlour.id;
        getVisitCountGuests(day, businessUnitId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        guestsVisitCount: response.data,
                    });
                }
            });
    }

    processingVisit(visits) {
        const { parlour } = this.props;
        visits.forEach(visit => {
            visit.visitFacility.forEach(vf => {
                vf.start = moment(vf.start).utcOffset(parlour.timeZone);
                vf.startDay = vf.start.clone().startOf('day');
                vf.duration = moment.duration(vf.duration, 'minutes');
                vf.end = vf.start.clone().add(vf.duration);

                if (!visit.start || vf.start < visit.start) {
                    visit.start = vf.start.clone();
                }

                if (!visit.end || vf.end > visit.end) {
                    visit.end = vf.end.clone();
                }
            });
        });
        return visits;
    }

    setParlourTimes(parlour, date) {
        return new Promise(resolve => {
            this.setState(getParlourTimes(parlour, date), () => { resolve(); });
        })

    }

    isPairWithSelected(visit) {
        const { selectedVisit } = this.props;

        if (selectedVisit) {
            return visit.parentId === selectedVisit.id
                || visit.id       === selectedVisit.parentId
                || (selectedVisit.hasChildren && selectedVisit.id === visit.id)
                || (visit.parentId && selectedVisit.parentId && visit.parentId === selectedVisit.parentId);
        }

        return false;
    }

    renderVisitDay = (visit, eventIdx, columns, timeSlots, timeStep, isOldVisit = false) => {
        const { classes, selectedVisit, onVisitClick, onVisitDbClick, onVisitContextMenu, hiddenStatuses, date } = this.props;
        const { draggingIdx, ctrlPressed, guestsVisitCount } = this.state;

        let elements = [];

        if (hiddenStatuses && hiddenStatuses.indexOf(visit.status) >= 0) {
            return elements;
        }
        let isCash = false;
        let isCashless = false;
        let isPaymentProcessing = false;
        if (visit.sale && visit.sale.payments && visit.sale.payments.length) {
            visit.sale.payments.forEach(payment => {
                if (payment.type === 'cash') {
                    isCash = true;
                } else if (payment.type === 'cashless' && payment.status !== paymentStatus.processing) {
                    isCashless = true;
                }
                if (payment.status === paymentStatus.processing) {
                    isPaymentProcessing = true;
                }
            });
        }

        let firstTime = false;
        if (visit.guest && visit.guest.id) {
            firstTime = guestsVisitCount && guestsVisitCount[visit.guest.id] === 0;
            visit.guestPastVisitsCount = guestsVisitCount[visit.guest.id]; // чото не очень как то тут втыкать наверное
        }

        const dayStart = date.clone().startOf('day');
        let roomText = [];
        /*
            if (visit.roomName) {

                roomText.push(visit.roomName);
            }
            if (visit.roomComment) {
                roomText.push(visit.roomComment);
            }
        */
        roomText = roomText.join(', ');

        visit.visitFacility.forEach((vf, vfIdx) => {
            const vfColumnsIdx = vf.master.map(master => this.mastersMap[master.id]);
            const color = (vf.facility && vf.facility.color) || 'transparent';

            vf.master.forEach((master, masterIdx) => {
                const columnIdx = this.mastersMap[master.id] === undefined ? columns.length : this.mastersMap[master.id];

                elements.push({
                    slotIdx: timeSlots.findIndex(slot => vf.start.clone().subtract(slot).diff(dayStart) < timeStep),
                    columnIdx,
                    height: vf.duration.asMinutes() / timeStep.asMinutes(),
                    ids: [eventIdx, vfIdx, masterIdx],
                    element:
                        <div
                            key={`${vf.id}-${master.id}`}
                            className={cn(classes.event, {
                                [classes.eventHasLinkedVisits]: (visit.parentId || visit.hasChildren),
                                [classes.eventCurrent]: (selectedVisit && visit.id === selectedVisit.id),
                                [classes.eventStriped]: visit.hasWaitingMasters,
                                [classes.eventWithColorFacilityLine]: !!(vf.facility && vf.facility.color)
                            })}
                            style={{
                                borderColor: !isOldVisit && draggingIdx === eventIdx ? '#000000' : null,
                                borderWidth: !isOldVisit && draggingIdx === eventIdx ? 2 : null,
                            }}
                            onClick={e => onVisitClick && onVisitClick(visit, e)}
                            onDoubleClick={e => onVisitDbClick && onVisitDbClick(visit, e)}
                            onContextMenu={e => !e.altKey && onVisitContextMenu && onVisitContextMenu(visit, e)}
                        >
                            <div className={classes.facilityLine} style={{backgroundColor: color}}/>
                            <div
                                className={cn(classes.eventLabelTime, classes.showSchedule)}
                            >{vf.start.format('HH:mm')} - {vf.end.format('HH:mm')}{visit.isRB ? <small> РБ</small> : null}{
                                columnIdx === columns.length ?
                                    <span> {master.name}</span>
                                    : null
                            }</div>
                            {visit.status === visitStatuses.new ?
                                <div className={classes.eventLabel}>
                                    {visit.creator.username}<br/>
                                    {visit.creator.phone}
                                </div>
                                :
                                <div className={classes.eventLabel}>
                                    <div className={classes.showPrint}>
                                        {vf.facility ? <div>{visit.guest && visit.guest.genderPrint ? visit.guest.genderPrint : <span>&nbsp;&nbsp;&nbsp;</span>} {vf.facility.code} - {vf.duration.asHours()}h</div> : null}
                                        {visit.guest ? <div>{visit.guest.name}</div> : <div><i>Инкогнито</i></div>}
                                    </div>
                                    <div className={classes.showSchedule}>
                                        <div>
                                            {visit.guest && visit.guest.genderPrint ? <span>{visit.guest.genderPrint}&nbsp;</span> : null}
                                            {visit.guest ? <span>{visit.guest.fullName}</span> : <i>Инкогнито</i>}
                                            { (vf.duration.asMinutes() / timeStep.asMinutes() < 3 && vf.facility) ? <span> {vf.facility.code}-{vf.facility.name}</span> : null }
                                        </div>
                                        { (vf.duration.asMinutes() / timeStep.asMinutes() >= 3 && vf.facility) ? <div>{vf.facility.code}-{vf.facility.name}</div> : null}
                                    </div>
                                </div>
                            }
                            <div className={classes.eventIcons}>
                                {firstTime ? <Tooltip title="First time guest"><StarIcon className={classes.starIcon}/></Tooltip> : null}
                                {isCash ? <Tooltip title="Cash"><MoneyIcon className={classes.eventIcon}/></Tooltip> : null}
                                {isCashless ? <Tooltip title="Credit card"><CreditCardIcon className={classes.eventIcon}/></Tooltip> : null}
                                {isPaymentProcessing ? <Tooltip title="Have processing payment(s)"><HourglassEmptyIcon className={classes.eventIcon}/></Tooltip> : null}
                                {visit.guest && visit.guest.isClubMember ? <Tooltip title="Club member"><Face className={classes.eventIcon}/></Tooltip> : null}
                                {visit.isOnline ? <Tooltip title="Online"><OnlineIcon className={classes.eventIcon}/></Tooltip> : null }
                                {master.requested ? <Tooltip title="RQ"><LinkIcon className={classes.eventIcon}/></Tooltip> : null }
                                {visit.isTransferred ? <Tooltip title="Snapshot"><HistoryIcon className={classes.eventIcon}/></Tooltip> : null }
                            </div>
                            { this.isPairWithSelected(visit) ?
                                <div className={classes.markPair}> </div>
                            : null}
                            { roomText ? <div className={classes.roomComment}>{roomText}</div> : null }
                        </div>,
                    elementStyle: {
                        background: getVisitStatusColor(visit.status),
                        zIndex: !ctrlPressed && (visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked || isOldVisit || visit.isTransferred) ? 0 : '',
                        opacity: isOldVisit  ? 0.3 : (!ctrlPressed && (visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked || visit.isTransferred) ? 0.5 : null),
                    },
                    disabledDragColumns:
                        master.requested ?
                            _.range(columns.length).filter(idx => idx !== columnIdx)
                            : vfColumnsIdx.filter(idx => idx !== columnIdx),
                    disabledDragSlots: [],
                    canDrag: visit.isTransferred ? false :
                        !isOldVisit && [visitStatuses.created, visitStatuses.confirmed, visitStatuses.risky, visitStatuses.starting, visitStatuses.providing].indexOf(visit.status) >= 0
                        && (draggingIdx < 0 || draggingIdx === eventIdx),
                })
            });

            if (vf.equipmentId) {
                const columnIdx =
                    this.equipmentsMap[vf.equipmentId] === undefined ?
                        null
                        :
                        Object.keys(this.mastersMap).length + this.equipmentsMap[vf.equipmentId];

                if (columnIdx !== null) {

                    elements.push({
                        slotIdx: timeSlots.findIndex(slot => vf.start.clone().subtract(slot).diff(dayStart) < timeStep),
                        columnIdx,
                        height: vf.duration.asMinutes() / timeStep.asMinutes(),
                        ids: [eventIdx, vfIdx, -100],
                        element:
                            <div
                                key={`${vf.id}-${vf.equipmentId}`}
                                className={cn(classes.event, {[classes.eventCurrent]: (selectedVisit && visit.id === selectedVisit.id)})}
                                style={{
                                    borderColor: !isOldVisit && draggingIdx === eventIdx ? '#000000' : null,
                                    borderWidth: !isOldVisit && draggingIdx === eventIdx ? 2 : null
                                }}
                                onClick={e => onVisitClick && onVisitClick(visit, e)}
                                onDoubleClick={e => onVisitDbClick && onVisitDbClick(visit, e)}
                                onContextMenu={e => !e.altKey && onVisitContextMenu && onVisitContextMenu(visit, e)}
                            >
                                <div
                                    className={cn(classes.eventLabelTime, classes.showSchedule)}
                                >{vf.start.format('HH:mm')} - {vf.end.format('HH:mm')}{
                                    columnIdx === columns.length ?
                                        <span> {vf.equipmentText}</span>
                                        : null
                                }</div>
                                {visit.status === visitStatuses.new ?
                                    <div className={classes.eventLabel}>
                                        Создает: {visit.creator.username}<br/>
                                        {visit.creator.phone}
                                    </div>
                                    :
                                    <div className={classes.eventLabel}>
                                        <div className={classes.showPrint}>
                                            {vf.facility ?
                                                <div>{visit.guest && visit.guest.genderPrint ? visit.guest.genderPrint :
                                                    <span>&nbsp;&nbsp;&nbsp;</span>} {vf.facility.code} - {vf.duration.asHours()}h</div> : null}
                                            {visit.guest ? <div>{visit.guest.name}</div> : <div><i>Инкогнито</i></div>}
                                        </div>
                                        <div className={classes.showSchedule}>
                                            {visit.guest ? <div>{visit.guest.fullName}</div> :
                                                <div><i>Инкогнито</i></div>}
                                        </div>
                                    </div>
                                }
                                <div className={classes.eventIcons}>
                                    {visit.isOnline ? <Tooltip title="Online"><OnlineIcon
                                        className={classes.eventIcon}/></Tooltip> : null}
                                </div>
                                {this.isPairWithSelected(visit) ?
                                    <div className={classes.markPair}> </div>
                                    : null}
                                { roomText ? <div className={classes.roomComment}>{roomText}</div> : null }
                            </div>,
                        elementStyle: {
                            background: getVisitStatusColor(visit.status),
                            zIndex: !ctrlPressed && (visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked || isOldVisit) ? 0 : '',
                            opacity: isOldVisit ? 0.3 : (!ctrlPressed && (visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked) ? 0.5 : null),
                        },
                        disabledDragColumns: _.range(columns.length), //TODO: Перенос в оборудовании
                        disabledDragSlots: [],
                        canDrag: false, //TODO: Перенос в оборудовании
                    })
                }
            }
        });

        if (visit.oldVisit) {
            elements = elements.concat(this.renderVisitDay(visit.oldVisit, eventIdx, columns, timeSlots, timeStep, true));
        }

        return elements;
    };

    handleSlotDropHover = (slot, column, event, ids) => {
        const { date } = this.props;
        const dayStart = date.clone().startOf('day');

        const [visitIdx, vfIdx, masterIdx] = ids;
        const vf = event.visitFacility[vfIdx];
        const newStart = dayStart.clone().add(slot);
        const diffStart = newStart.isSame(vf.start) ? null : newStart.diff(vf.start);
        let changed = false;
        if (column.id !== vf.master[masterIdx].id) {
            vf.master[masterIdx] = {...column};
            changed = true;
        }

        if (changed || diffStart) {
            let { visits } = this.props;

            if (changed) {
                visits[visitIdx].visitFacility[vfIdx] = vf;
            }

            if (diffStart) {
                visits[visitIdx].visitFacility.forEach(vf => { vf.start.add(diffStart); vf.end.add(diffStart); vf.startDay = vf.start.clone().startOf('day'); });
                visits[visitIdx].start.add(diffStart);
                visits[visitIdx].end.add(diffStart);
            }

            this.props.setVisits([...visits]);
        }
    };

    handleSlotDropHoverDebounce = _.debounce(this.handleSlotDropHover, 50);

    handleBeginDrag = (visit, [visitIdx, vfIdx, masterIdx]) => {
        let { draggingIdx } = this.state;
        let { visits } = this.props;

        if (draggingIdx < 0 && !visits[visitIdx].oldVisit) {
            visits[visitIdx].oldVisit = _.cloneDeep(visits[visitIdx]);

            this.props.setVisits([...visits]);
            this.setState({
                draggingIdx: visitIdx
            });
        }
    };

    handleAcceptDrag = () => {
        let { draggingIdx } = this.state;
        let { visits } = this.props;

        if (draggingIdx >= 0) {
            this.setState({
                draggingIdx: -1
            });

            updateVisit(visits[draggingIdx])
                .then(response => {
                    if (response.success) {
                        const newVisit = this.processingVisit([response.data])[0];

                        this.props.setVisits([
                            ...this.props.visits.filter(oldVisit => oldVisit.id !== newVisit.id),
                            newVisit,
                        ]);
                        this.loadSnapshots(this.props)
                        this.props.showInfo('Изменения успешно сохранены');
                    } else {
                        this.props.showError(response.error ? response.error.message : response.message);
                        this.loadVisits(this.props);
                        this.loadSnapshots(this.props);
                        this.loadGuestsVisitCount(this.props);
                    }
                })
        }
    };

    handleDeclineDrag = () => {
        let { draggingIdx } = this.state;
        let { visits } = this.props;

        if (draggingIdx >= 0) {
            visits[draggingIdx] = visits[draggingIdx].oldVisit;

            this.props.setVisits([...visits]);
            this.setState({
                draggingIdx: -1
            });
        }
    };

    renderColumnTitle = (column) => {
        const {classes} = this.props;

        return <>
            <span>{column.name}</span>
            <div className={classes.masterRating}>
                {Array.from(Array(column.rating || 0)).map((x, i) =>
                    <StarIcon key={i} className={classes.masterRatingStar}/>
                )}
            </div>
            <div className={classes.loadDuration}>
                <span>{durationAsHHmm(moment.duration(column.loadDuration || 0, 'minutes'))}</span>
            </div>
        </>;
    }

    handleSlotClick = (column, slot, event) => {
        const { onSlotClick, date } = this.props;
        if (onSlotClick) {
            onSlotClick(date.clone().startOf("day").add(slot), column, event);
        }
    }

    handleSlotDbClick = (column, slot, event) => {
        const { onSlotDbClick, date } = this.props;
        if (onSlotDbClick) {
            onSlotDbClick(date.clone().startOf("day").add(slot), column, event);
        }
    }

    handleSlotContextMenu = (column, slot, event) => {
        const { onSlotContextMenu, date } = this.props;
        if (onSlotContextMenu) {
            onSlotContextMenu(date.clone().startOf("day").add(slot), column, event);
        }
    }

    getColumnStyle = column =>
        column.lineColor ? {background: column.lineColor} :
            column.type === 'barrel' ?
                {background: '#ded166'}
                :
                column.type === 'room' ?
                    {background: '#73cba7'}
                    :
                    (column.waiting && column.waiting.length) ?
                        {background: '#fff200'}
                        :
                        {}

    render() {
        const { date, onHeaderClick, t, parlour, visits } = this.props;
        const { startTime, endTime, masters, draggingIdx, equipments, lines, adminSlots, mastersLoadData } = this.state;

        masters.forEach(master => {
            let masterDuration = 0;
            let masterDurationFree = 0;
            let masterDurationUnfree = 0;

            if (mastersLoadData[master.id]) {
                masterDuration = mastersLoadData[master.id].totalDuration * 60;
                masterDurationFree = mastersLoadData[master.id].free * 60;
                masterDurationUnfree = masterDuration - masterDurationFree;
            } else {

                visits.forEach(visit => {
                    if (visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked) {
                        //continue
                        return true;
                    }
                    visit.visitFacility.forEach(vf => {
                        if (vf.master.findIndex(item => item.id === master.id) >= 0 && !(vf.facility && vf.facility.notCountForMasters)) {
                            masterDuration += vf.duration;
                        }
                    });
                });
            }

            master.loadDuration = masterDuration;
            master.loadDurationFree = masterDurationFree;
            master.loadDurationUnfree = masterDurationUnfree;
        });

        let columns;

        if (equipments && equipments.length) {
            columns = [...masters, ...equipments];
        } else {
            columns = [...masters];
        }

        return <React.Fragment>
            <ScheduleSheet
                printInfo={date.format('DD.MM YYYY')}
                timeZone={parlour ? parlour.timeZone : "+04:00"}
                lines={lines}
                showCurrentTime={date.diff(moment().startOf('day'), 'days') === 0}
                startDayTime={startTime}
                endDayTime={endTime}
                columns={columns}
                columnTitle={this.renderColumnTitle}
                columnWidth={150}
                columnStyle={this.getColumnStyle}
                onSlotClick={this.handleSlotClick}
                onSlotDbClick={this.handleSlotDbClick}
                onSlotDropHover={this.handleSlotDropHoverDebounce}
                events={[...visits,]}
                onSlotContextMenu={this.handleSlotContextMenu}
                renderEvent={this.renderVisitDay}
                onHeaderClick={onHeaderClick}
                onBeginDrag={this.handleBeginDrag}
                markAdminGapSlots={parlour.showAdminGaps && adminSlots}
            />
            <Snackbar
                open={draggingIdx >= 0}
                color=""
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={<span>{t("Transfer of the visit Are you sure")}</span>}
                action={[
                    <IconButton
                        key="accept-drag"
                        onClick={this.handleAcceptDrag}
                        color="inherit"
                    >
                        <CheckIcon />
                    </IconButton>,
                    <IconButton
                        key="cancel-drag"
                        onClick={this.handleDeclineDrag}
                        color="inherit"
                    >
                        <CancelIcon />
                    </IconButton>,
                ]}
            />
        </React.Fragment>
    }
}
