import React from 'react';
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {navLinks} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import {getFalseResult} from "../../services/common";
import {getBusinessUnit, getBusinessUnitHistory } from "../../services/organization";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";

@withRouter
@withTranslation()
class HistoryParlourPage extends React.Component {

    state = {
        id: '',
        businessUnit: {
            id: null,
            name: null
        },
        refreshTable: false,
    };

    columnData = [
        { id: 'dt', numeric: false, disablePadding: false, label: 'Дата и время', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'user', numeric: false, disablePadding: false, label: 'Пользователь' },
        { id: 'field', numeric: false, disablePadding: false, label: 'Измененный параметр' },
        { id: 'oldValue', numeric: false, disablePadding: false, label: 'Значение "было"' },
        { id: 'newValue', numeric: false, disablePadding: false, label: 'Значение "стало"' },
    ];

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getBusinessUnit(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            businessUnit: response.data
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/organization/parlours/view/${this.state.id}`);
    };

    getTableData = (...params) => {
        return this.state.id ? getBusinessUnitHistory(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { businessUnit, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink="История изменения"/>}>
                    <ShowField
                        label="Имя"
                        value={businessUnit.name}
                        fullWidth
                    />
                    {businessUnit.parlour &&
                        <ShowField
                            label="Бизнес юнит"
                            value={businessUnit.parlour.name}
                            fullWidth
                        />
                    }
                    <br />
                    <br />
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>
                <Content title="История изменения БЮ">
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        order="desc"
                        orderBy="dt"
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default HistoryParlourPage;
