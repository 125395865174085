import React from 'react';
import { connect } from "react-redux";
import infoActions from "../../components/info/info-actions";
import {withStyles} from "@material-ui/core";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/goods";
import Content from "../../components/content/Content";
import {Redirect, Route, Switch, withRouter} from "react-router";
import ActionButton from "../../components/button/ActionButton";
import ReportExpired from "./ReportExpired";
import ReportConsumption from "./ReportConsumption";
import ReportRest from "./ReportRest";
import ReportMoving from "./ReportMoving";


const styles = theme => ({

});

@withRouter
class ReportsPage extends React.Component
{

    handleConsumption = () => {
        this.props.history.push('/goods/reports/consumption');
    };

    handleRest = () => {
        this.props.history.push('/goods/reports/rest');
    };

    handleMoving = () => {
        this.props.history.push('/goods/reports/moving');
    };

    handleExpired = () => {
        this.props.history.push('/goods/reports/expired');
    };

    render() {
        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleConsumption}>Расчет потребления за единицу времени</ActionButton>
                    <ActionButton onClick={this.handleRest}>Расчет остатков на заданное число</ActionButton>
                    <ActionButton onClick={this.handleMoving}>Движение средств по косметике</ActionButton>
                    <ActionButton onClick={this.handleExpired}>Просроченная косметика</ActionButton>
                </LeftBar>

                <Content title="Отчеты по товарам и складу">
                    <Switch>
                        <Route exact path="/goods/reports"          render={() => (<Redirect to="/goods/reports/expired"/>)}/>
                        <Route       path="/goods/reports/consumption"  component={ReportConsumption}/>
                        <Route       path="/goods/reports/rest"  component={ReportRest}/>
                        <Route       path="/goods/reports/moving"  component={ReportMoving}/>
                        <Route       path="/goods/reports/expired"  component={ReportExpired}/>
                    </Switch>
                </Content>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {showInfo: infoActions.show})(withStyles(styles)(ReportsPage));
