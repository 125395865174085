import React, { memo, useCallback, useState } from "react";
import LeftBar from "../../../components/left-bar/LeftBar";
import NavigationLinks from "../../../components/navigation-links/NavigationLinks";
import {
    getVisitChannelForTable,
    navLinks,
} from "../../../services/settings";
import Content from "../../../components/content/Content";
import DataTable from "../../../components/data-table/DataTable";
import ActionButton from "../../../components/button/ActionButton";
import { useTranslation } from "react-i18next";
import VisitChannelEditDialog from "./VisitChannelEditDialog";

const columnData = [
    { id: "name", label: "VisitChannel.Name of channel" },
    { id: "sort", label: "VisitChannel.Sort" },
    { id: "active", label: "VisitChannel.Is active channel" },
    { id: "channelBitrixId", label: "VisitChannel.channelBitrixId" },
    { id: "onlineChannelUserId", label: "VisitChannel.onlineChannelUserId" },
];

const emptyVisitChannel = {
    id: null,
    name: "",
    sort: null,
    active: true,
    channelBitrixId: "",
    onlineChannelUserId: null,
};

export default memo(function VisitChannelPage() {
    const { t } = useTranslation();

    const [refreshTable, setRefreshTable] = useState(false);
    const [dialogData, setDialogData] = useState(null);


    const openVisitChannelDialog = () => {
        setDialogData(emptyVisitChannel);
    };

    const openEditVisitChannelDialog = useCallback((visitChannel) => {
        setDialogData(visitChannel);
    }, []);

    const getTableData = useCallback((...params) => {
        return getVisitChannelForTable(...params);
    }, []);

    const handleClose = useCallback(() => {
        setDialogData(null);
    }, []);

    const handleSubmit = useCallback(() => {
        setDialogData(null);
        setRefreshTable(prevState => !prevState);
    }, []);

    return (
        <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={openVisitChannelDialog}>{t("Add")}</ActionButton>
            </LeftBar>
            <Content title={t("VisitChannel.Visit channels")}>
                <DataTable
                    columnData={columnData}
                    dataFunc={getTableData}
                    selector={false}
                    refresh={refreshTable}
                    onClick={openEditVisitChannelDialog}
                />
            </Content>
            {!!dialogData && (
                <VisitChannelEditDialog
                    data={dialogData}
                    onClose={handleClose}
                    onSubmit={handleSubmit}
                />
            )}
        </React.Fragment>
    );
});
