import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Checkbox,
    FormControlLabel,
    ListItemText, Grid,
    Typography,
    Paper
} from '@material-ui/core';
import {
    acceptRequest,
    declineRequest,
    deliveryRequest,
    getRequest,
    requestStatuses,
    setRequestComment,
    deliveryTypes,
    getDeliveryTypeText,
    requestTypes,
    navLinks,
    payTypes,
    getPayTypeText,
    getPayDelays,
    getRequestPayDocsGrouped,
    fastCompleteRequest,
    fillNominalsRequest,
    getPayDocStatuses,
    payDocStatuses,
    changeStatusPayDocForRequest,
} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import {getParlours} from "../../services/organization";
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import NumberFormatCurrency from "../../components/fields/NumberFormatCurrency";
import SymbolRub from "../../components/typography/SymbolRub";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {getDownloadLink} from "../../services/storage";
import AnyField from "../../components/fields/AnyField";
import {currentSystemSpendingLimit, emptyMoney, getFalseResult} from "../../services/common"
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import MoneyField from "../../components/fields/MoneyField";
import {hasRole} from "../../services/user";
import {createChangeHandle} from "../../utils/helpers";
import _ from "lodash";
import baseRequest from "../../services/baseReuqest";
import downloader from "../../services/downloader";
import DateField2 from "../../components/fields/DateField2";
import { getArrayFromString } from '../../utils/balance';

const columnData = [
    { id: 'num', numeric: false, label: 'Identifier' },
    { id: 'type', numeric: false, label: 'Type of payment document' },
    { id: 'cnt', numeric: false, label: 'Amount' },
    { id: 'parlour', numeric: false, label: 'Request from parlour' },
    { id: 'nominal', numeric: true, label: 'Nominal' },
    { id: 'balance', numeric: true, label: 'Balance' },
];

const styles = theme => ({
    container: {
        width: 700,
        marginTop:  theme.spacing(1),
    },
    subtitle: {

    },
    warningMessage: {
        textAlign: 'center',
        margin:  theme.spacing(2),
        padding:  theme.spacing(1),
        background: theme.palette.error.light,
    },
});

@connect(state => ({
    user: state.auth.user,
}), {
    showMessage: messageDialogActions.show
})
@withRouter
@withTranslation()
class RequestViewPage extends React.Component {

    state = {
        refreshTable: false,
        request: {
            id: null,
            num: '',
            status: '',
            nextPositiveStatus: '',
            nextNegativeStatus: '',
            type: {
                id: null,
                name: null,
            },

            price: null,
            bill: null,
            payTypes: null,
            amountPrePay: null,
            amountDelayPay: null,
            amountTotal: null,
            delayPeriodId: null,
            delayPeriod: null,

            mainDeliveryType: null,
            mainTransportCompany: null,
            mainWaybillNumber: null,
            mainReceiptScan: null,
            mainDeliveryCharges: null,
            deliveryType: null,
            transportCompany: null,
            waybillNumber: null,
            receiptScan: null,
            deliveryCharges: null,
            newParlourId: null,
            newParlourName: null,

            technicalProject: null,
            cardDesigns: [],
            managedCompanyId: null,
            counterpartName: null,
            counterpartId: null
        },
        limit: null,
        limitText: '',
        comment: '',
        commentDialogOpen: false,
        deliveryDialogOpen: false,
        deliveryDialogTitle: '',
        deliveryData: {
            type: deliveryTypes.courier,
            name: '',
            waybill: '',
            receiptScan: null,
            charges: 0,
            parlour: null,
            toParlour: false,
            comment: null,
        },
        parlours: [],
        payData: {
            types: [],
            amountPre: null,
            amountDelay: null,
            amountTotal: null,
            payDelay: null,
            comment: null,
        },
        payDelays: [],
        payDialogOpen: false,
        payDialogLoading: false,


        productionData: {
            price: null,
            bill: null,
            comment: null,
        },
        productionDialogOpen: false,

        progress: false,

        confirmDialogOpen: false,
        confirmComment: null,
        confirmAccept: false,

        fillNominalsDialogOpen: false,
        nominalsList: '',
        isBalance: true,

        changeStatusDialogOpen: false,
        sendPayDocStatuses: [],
        sendPayDocStatus: {
            payDocStatusId: null,
            validity: new Date()
        }
    };


    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.loadRequest(resultId[0]);
        }
    }

    loadRequest = (id) => {
        getRequest(id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        request: response.data,
                        refreshTable: !this.state.refreshTable,
                    });

                    if (!response.data.managedCompanyId) {
                        return;
                    }
                    currentSystemSpendingLimit(response.data.managedCompanyId, 'paydoc_request_make')
                        .then(response => {
                            if (response.success) {
                                this.setState({
                                    limitText: response.data.currentAmountFormatted,
                                    limit: response.data.currentAmount,
                                });
                            } else {
                                this.setState({
                                    limitText: response.error.message,
                                });
                            }
                        });
                }
            });

            getPayDocStatuses().then(response => {
                if (response.success) {
                    this.setState({
                        sendPayDocStatuses: response.data
                    })
                }
            })
    };

    handleChange = createChangeHandle(this)

    handleClickComment = () => {
        this.setState(state => ({commentDialogOpen: true, comment: state.request.comment }));
    };

    handleChangeBalance = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.checked
        });
    }

    checkStatuses = (statuses) => {
        return statuses.indexOf(this.state.request.statusId) >= 0;
    };

    handleCloseCommentDialog = ok => () => {
        const { request, comment } = this.state;

        if (!ok) {
            this.setState({commentDialogOpen: false});
            return;
        }

        setRequestComment(request.id, comment)
            .then(response => {
                if (response.success) {
                    this.setState({commentDialogOpen: false, request: response.data});
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    showPayDialog = () => {
        this.setState({
            payData: {
                types: [],
                amountPre: null,
                amountDelay: null,
                amountTotal: null,
                payDelay: null,
            },
            payDelays: [],
            payDialogOpen: true,
            payDialogLoading: true,
        });

        getPayDelays()
            .then(response => {
                this.setState({payDialogLoading: false});

                if (response.success) {
                    this.setState({
                        payDelays: response.data,
                    });
                }
            });
    };

    showProductionDialog = () => {
        this.setState({
            productionData: {
                price: null,
                bill: null,
                priceLoading: false,
                billLoading: false,
            },
            productionDialogOpen: true,
        });
    };

    handleDocsExport = () => {
        const {
            request,
        } = this.state;

        return baseRequest(`/api/pay-doc-export/docs/${ request.id }`, { method: "GET" }, false)
            .then(async (response) => {
                downloader(await response.blob(), 'export.xlsx');
            });
    }

    handlePinExport = () => {
        const {
            request,
        } = this.state;

        return baseRequest(`/api/pay-doc-export/pins/${ request.id }`, { method: "GET" }, false)
            .then(async (response) => {
                console.log(response);
                downloader(await response.blob(), 'export.xlsx');
            });
    }

    handleConfirm = () => {
        const { request } = this.state;

        if (request.statusId === requestStatuses.production_ok) {
            this.showPayDialog();
        } else if (request.statusId === requestStatuses.request_production) {
            this.showProductionDialog();
        } else {

            this.setState({
                confirmDialogOpen: true,
                confirmAccept: true,
                confirmComment: null
            });
        }
    };

    handleDecline = () => {
        this.setState({
            confirmDialogOpen: true,
            confirmAccept: false,
            confirmComment: null
        });
    };

    handleDelivery = () => {
        const { request } = this.state;
        const toParlour = request.statusId === requestStatuses.main || request.statusId === requestStatuses.main_ok;

        this.setState({
            deliveryDialogOpen: true,
            deliveryDialogTitle: toParlour ? 'Платежные документы будут переданы в салон' : 'Передача в доставку',
            deliveryDialogLoading: toParlour,
            deliveryData: {
                type: deliveryTypes.courier,
                name: '',
                waybill: '',
                receiptScan: null,
                receiptScanLoading: false,
                charges: 0,
                parlour: request.newParlourId,
                toParlour
            },
            parlours: [],
        });

        if (toParlour) {
            getParlours()
                .then(response => {
                    if (response.success) {
                        this.setState({
                            deliveryDialogLoading: false,
                            parlours: response.data,
                        });
                    } else {
                        this.setState({
                            deliveryDialogLoading: false,
                        });
                    }
                });
        }
    };

    handleGet = () => {
        const { request } = this.state;

        this.setState({
            progress: true
        });
        acceptRequest(request.id, {})
            .then(response => {
                this.setState({
                    progress: false,
                });
                if (response.success) {
                    this.loadRequest(request.id)
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleHistory = () => {
        this.props.history.push(`/pay-docs/requests/history/status/${this.state.request.id}`);
    };

    handleInfo = () => {
        this.props.history.push(`/pay-docs/requests/view-info/${this.state.request.id}`);
    };

    handleCloseDeliveryDialog = ok => () => {
        const { request, deliveryData } = this.state;

        if (!ok) {
            this.setState({
                deliveryDialogOpen: false,
            });
            return;
        }



        this.setState({
            progress: true
        });
        deliveryRequest(request.id, {...deliveryData, receiptScan: deliveryData.receiptScan ? deliveryData.receiptScan.id : null }  )
            .then(response => {
                this.setState({
                    progress: false,
                });

                if (response.success) {
                    this.setState({
                        request: response.data,
                        refreshTable: !this.state.refreshTable,
                        deliveryDialogOpen: false,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleDeliveryDialogChange = prop => event => {
        const { deliveryData } = this.state;

        this.setState({
            deliveryData: {
                ...deliveryData,
                [prop]: event.target.value,
            }
        });
    };

    handlePayDialogChange = prop => event => {
        const { payData } = this.state;

        this.setState({
            payData: {
                ...payData,
                [prop]: event.target.value,
            }
        });

    };

    handleClosePayDialog = ok => () => {
        const { request, payData } = this.state;
        if (!ok) {
            this.setState({
                payDialogOpen: false,
            });
            return;
        }

        acceptRequest(request.id, payData)
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDialogOpen: false,
                    });

                    this.loadRequest(request.id);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    getTableData = (...params) => {
        return this.state.request.id ? getRequestPayDocsGrouped(this.state.request.id, ...params) : getFalseResult();
    };

    handleCloseProductionDialog = ok => () => {
        const { request, productionData } = this.state;

        if (!ok) {
            this.setState({
                productionDialogOpen: false,
            });
            return;
        }

        acceptRequest(request.id, {
                ...productionData,
                price: productionData.price ? productionData.price.id : null,
                bill: productionData.bill ? productionData.bill.id : null,
            })
            .then(response => {
                if (response.success) {
                    this.setState({
                        productionDialogOpen: false,
                    });

                    this.loadRequest(request.id);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleCloseConfirmDialog = ok => () => {
        const { confirmAccept, confirmComment, request } = this.state;

        if (!ok) {
            this.setState({
                confirmDialogOpen: false,
            });
            return;
        }

        if (confirmAccept) {
            acceptRequest(request.id, {comment: confirmComment})
                .then(response => {
                    if (response.success) {
                        this.loadRequest(request.id);
                        this.setState({
                            confirmDialogOpen: false,
                        });
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        } else {
            declineRequest(request.id, {comment: confirmComment})
                .then(response => {
                    if (response.success) {
                        this.loadRequest(request.id);
                        this.setState({
                            confirmDialogOpen: false,
                        });
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleProductionDialogChange = prop => event => {
        const value = event.target.value;

        this.setState(state => ({
            productionData: {
                ...state.productionData,
                [prop]: value,
            }
        }));
    };

    handleFastComplete = () => {
        const { request } = this.state;
        fastCompleteRequest(request.id)
            .then(response => {
                if (response.success) {
                    this.loadRequest(request.id);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleFillNominals = () => {
        const { nominalsList } = this.state; 
        this.setState({
            nominalsList: nominalsList || '100;200;300',
            fillNominalsDialogOpen: true,
        })
    }

    handleCancelFillNominalsDialog = () => {
        this.setState({
            fillNominalsDialogOpen: false,
        })
    }

    handleSuccessFillNominalssDialog = () => {
        const { nominalsList, request, isBalance } = this.state;
        fillNominalsRequest(
            request.id,
            getArrayFromString(nominalsList),
            isBalance
        ).then(response => {
                if (response.success) {
                    this.setState({
                        fillNominalsDialogOpen: false,
                    })
                    this.loadRequest(request.id);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })

    }

    checkNominalsList = () => {
        const { nominalsList } = this.state;
        if (nominalsList) {
            const nominals = nominalsList.split(';');
            return nominals.every(nominal => {
                nominal = nominal.trim();
                if (nominal) {
                    const n = _.toNumber(nominal.replace(',', '.'));
                    return !isNaN(n) && _.isNumber(n);
                }

                return false;
            });
        }

        return false;
    }

    handleChangePayDocStatus = event => {
        const {sendPayDocStatus} = this.state;

        this.setState({
            sendPayDocStatus: {
                payDocStatusId: event.target.value,
                validity: sendPayDocStatus.validity ?? new Date()
            }
        });
    }

    handleChangePayDocValidity = event => {
        const {sendPayDocStatus} = this.state;

        this.setState({
            sendPayDocStatus: {
                payDocStatusId: sendPayDocStatus.payDocStatusId ?? null,
                validity: event.target.value
            }
        });

    }

    handleSendPayDocStatus = () => {
        const {sendPayDocStatuses} = this.state;

        const status = sendPayDocStatuses.find((status) => {
            return status.id === payDocStatuses.sold
        });
            
        this.setState({
            changeStatusDialogOpen: true,
            sendPayDocStatus: {
                payDocStatusId: status ? status.id : null,
                validity: new Date()
            } 
        });
    }

    handleCloseChangeStatus = ok => () => {
        if (!ok) {
            this.setState({changeStatusDialogOpen: false});
            return;
        }

        const {sendPayDocStatus, request} = this.state;
    
        const data = {
            requestId: request.id,
            status: sendPayDocStatus.payDocStatusId,
            validity: sendPayDocStatus.validity
        };

        changeStatusPayDocForRequest(data)
            .then(response => {
                let _this = this;
                
                if (response.success) {
                    this.props.showMessage('Статус ПД успешно изменен!');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }

                this.setState({
                }, () => {
                    setTimeout(() => {
                        _this.setState({
                            changeStatusDialogOpen: false
                        });
                    }, 2000)
                });
            });
    }

    render() {
        const { classes, t } = this.props;
        const {
            refreshTable,
            request,
            comment,
            commentDialogOpen,
            deliveryDialogOpen,
            deliveryDialogTitle,
            deliveryData,
            deliveryDialogLoading,
            parlours,
            progress,
            payData,
            payDialogOpen,
            payDialogLoading,
            payDelays,
            productionData,
            productionDialogOpen,
            changeStatusDialogOpen,
            confirmDialogOpen,
            confirmComment,
            confirmAccept,
            limitText,
            fillNominalsDialogOpen,
            nominalsList,
            isBalance,
            sendPayDocStatus,
            sendPayDocStatuses,
        } = this.state;

        const currency = request.currency;
        const fastComplete = hasRole('ROLE_PAYDOC_PAYDOCREQUEST_FAST_COMPLETE') && (
            Boolean(request.nextPositiveStatus) ||
            (this.checkStatuses([requestStatuses.in_production, requestStatuses.main]) && request.type.id === requestTypes.make) ||
            (this.checkStatuses([requestStatuses.main_ok]) && request.type.id === requestTypes.move)
        );
        const fillNominals = hasRole('ROLE_PAYDOC_PAYDOCREQUEST_FILL_NOMINALS') && request.type.id === requestTypes.make && (
            Boolean(request.nextPositiveStatus) ||
            this.checkStatuses([requestStatuses.in_production, requestStatuses.main])
        );

        let titleType = '...';
        if (request.type.id) {
            switch (request.type.id) {
                case requestTypes.make:
                    titleType = 'изготовление';
                    break;
                case requestTypes.renewal:
                    titleType = 'продление';
                    break;
                case requestTypes.move:
                    titleType = 'перемещение';
                    break;
                default:
                    titleType = request.type.name;
            }
        }

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink="Просмотр заявки"/>}>
                    <ShowField
                        label="Request #"
                        value={request.num}
                    />
                    <ShowField
                        label={t("Last comment")}
                        value={request.comment}
                    />
                    <ShowField
                        label={t("Status")}
                        value={request.status}
                    />
                    <ShowField
                        label={t("Limit")}
                        value={limitText}
                    />
                    <ShowField
                        label={t("Counterpart")}
                        value={request.counterpartName}
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleHistory}>{t("History of changing request statuses")}</ActionButton>
                    <ActionButton onClick={this.handleDocsExport}>{t("Upload to Excel")}</ActionButton>
                    <ActionButton onClick={this.handlePinExport}>{t("Number and PIN in Excel")}</ActionButton>
                    <ActionButton visible={Boolean(request.nextPositiveStatus) && this.checkStatuses([requestStatuses.limit_overrated])} onClick={this.handleConfirm}>{t('Confirm exceeding the limit')}</ActionButton>
                    <ActionButton visible={Boolean(request.nextPositiveStatus) && this.checkStatuses([requestStatuses.request_production, requestStatuses.production_ok, requestStatuses.in_accounting, requestStatuses.in_main])} onClick={this.handleConfirm}>{t('Confirm the request')}</ActionButton>
                    <ActionButton visible={Boolean(request.nextPositiveStatus) && this.checkStatuses([requestStatuses.sent_production])} onClick={this.handleConfirm}>{t('Take into production')}</ActionButton>
                    <ActionButton visible={(this.checkStatuses([requestStatuses.in_production, requestStatuses.main]) && request.type.id === requestTypes.make) || (this.checkStatuses([requestStatuses.main_ok]) && request.type.id === requestTypes.move)} onClick={this.handleDelivery}>{t('Transfer to delivery')}</ActionButton>
                    <ActionButton visible={Boolean(request.nextNegativeStatus) && this.checkStatuses([requestStatuses.request_production, requestStatuses.production_ok, requestStatuses.in_accounting, requestStatuses.in_main])} onClick={this.handleDecline}>{t('Reject the request')}</ActionButton>
                    <ActionButton visible={Boolean(request.nextNegativeStatus) && this.checkStatuses([requestStatuses.sent_production, requestStatuses.in_production])} onClick={this.handleDecline}>{t('Withdraw the request')}</ActionButton>
                    <ActionButton visible={this.checkStatuses([requestStatuses.delivery_main])} onClick={this.handleGet}>{t('Get payment documents')}</ActionButton>
                    <ActionButton onClick={this.handleInfo}>{t('Additional information on the request')}</ActionButton>
                    <ActionButton visible={fastComplete} onClick={this.handleFastComplete}>{t('Complete and register')}</ActionButton>
                    <ActionButton visible={fillNominals} onClick={this.handleFillNominals}>{t('Fill in the nominals')}</ActionButton>
                    <ActionButton visible={this.checkStatuses([requestStatuses.delivery_parlour])} onClick={this.handleSendPayDocStatus}>{t('Change the status of paydoc')}</ActionButton>
                </LeftBar>
                <Content title={`Заявка на ${titleType} платежных документов`}>
                    <Grid container spacing={2} className={classes.container}>
                        {request.type.id === requestTypes.make ?
                            <Grid item xs={3}>
                                <div className={classes.subtitle}>Информация для производителя</div>
                                <UploadFilesField
                                    id="technicalProject"
                                    label="Техническое задание"
                                    value={request.technicalProject}
                                    readOnly
                                />
                                <UploadFilesField
                                    id="cardDesigns"
                                    label="Рубашки"
                                    value={request.cardDesigns}
                                    readOnly
                                />
                            </Grid>
                            : null
                        }
                        {request.type.id === requestTypes.make ?
                            <Grid item xs={3}>
                                <div className={classes.subtitle}>Условия изготовления</div>
                                <UploadFilesField
                                    id="price"
                                    label="Бланк согласования"
                                    value={request.price}
                                    readOnly
                                />
                                <UploadFilesField
                                    id="bill"
                                    label="Счет"
                                    value={request.bill}
                                    readOnly
                                />
                            </Grid>
                            : null
                        }
                        {request.type.id === requestTypes.make ?
                            <Grid item xs={6}>
                                <div className={classes.subtitle}>Условия оплаты</div>
                                <AnyField
                                    id="payType"
                                    label={t('Payment type')}
                                    value={request.payTypes ? request.payTypes.map(type=>getPayTypeText(type)).join(', ') : ''}
                                    fullWidth
                                    readOnly
                                />
                                <MoneyField
                                    label="Сумма предоплаты"
                                    value={request.amountPrePay || {...emptyMoney}}
                                    readOnly
                                    fullWidth
                                />
                                <MoneyField
                                    label="Сумма отсроченного платежа"
                                    value={request.amountDelayPay || {...emptyMoney}}
                                    fullWidth
                                    readOnly
                                />
                                <MoneyField
                                    label="Общая сумма"
                                    value={request.amountTotal || {...emptyMoney}}
                                    fullWidth
                                    readOnly
                                />
                                <AnyField
                                    id="amountPrePay"
                                    label="Условия отсрочки"
                                    value={request.delayPeriod}
                                    fullWidth
                                    readOnly
                                />
                            </Grid>
                            : null
                        }
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        {request.type.id === requestTypes.make ?
                            <Grid item xs={6}>
                                <div className={classes.subtitle}>Условия доставки в центральный офис</div>
                                <AnyField
                                    id="mainDeliveryType"
                                    label="Способ доставки"
                                    value={getDeliveryTypeText(request.mainDeliveryType)}
                                    fullWidth
                                    readOnly
                                />
                                <AnyField
                                    id="mainTransportCompany"
                                    label="Название транспортной компании"
                                    value={request.mainTransportCompany}
                                    fullWidth
                                    multiline
                                    rows="2"
                                    readOnly
                                />
                                <AnyField
                                    id="mainWaybillNumber"
                                    label="Номер накладной"
                                    value={request.mainWaybillNumber}
                                    fullWidth
                                    readOnly
                                />
                                <UploadFilesField
                                    id="mainReceiptScan"
                                    label="Скан квитанции"
                                    value={request.mainReceiptScan}
                                    readOnly
                                />
                                <AnyField
                                    id="mainDeliveryCharges"
                                    label="Стоимость"
                                    value={request.mainDeliveryCharges}
                                    fullWidth
                                    readOnly
                                />
                            </Grid>
                            : null
                        }
                        {request.type.id === requestTypes.make || request.type.id === requestTypes.move ?
                            <Grid item xs={6}>
                                <div className={classes.subtitle}>Условия доставки в салон</div>
                                <AnyField
                                    id="newParlourName"
                                    label="Салон"
                                    value={request.newParlourName}
                                    fullWidth
                                    readOnly
                                />
                                <AnyField
                                    id="deliveryType"
                                    label="Способ доставки"
                                    value={getDeliveryTypeText(request.deliveryType)}
                                    fullWidth
                                    readOnly
                                />
                                <AnyField
                                    id="transportCompany"
                                    label="Название транспортной компании"
                                    value={request.transportCompany}
                                    fullWidth
                                    multiline
                                    rows="2"
                                    readOnly
                                />
                                <AnyField
                                    id="waybillNumber"
                                    label="Номер накладной"
                                    value={request.waybillNumber}
                                    fullWidth
                                    readOnly
                                />
                                <UploadFilesField
                                    id="receiptScan"
                                    label="Скан квитанции"
                                    value={request.receiptScan}
                                    readOnly
                                />
                                <AnyField
                                    id="deliveryCharges"
                                    label="Стоимость"
                                    value={request.deliveryCharges}
                                    fullWidth
                                    readOnly
                                />
                            </Grid>
                            : null
                        }
                    </Grid>
                    <div>Документы в заявке:</div>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        pagination={false}
                    />
                </Content>
                <Dialog
                    open={commentDialogOpen}
                    onClose={this.handleCloseCommentDialog(false)}
                    aria-labelledby="balance-dialog-title"
                >
                    <DialogTitle id="balance-dialog-title">Редактировать комментарий</DialogTitle>
                    <DialogContent>
                        <AnyField
                            autoFocus
                            multiline
                            rows="4"
                            label="Комметарий"
                            value={comment}
                            name="comment"
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseCommentDialog(true)} disabled={!comment && request.comment === comment} color="primary">{t('Send')}</Button>
                        <Button onClick={this.handleCloseCommentDialog(false)} color="primary">{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={payDialogOpen}
                    onClose={this.handleClosePayDialog(false)}
                    aria-labelledby="pay-dialog-title"
                >
                    <DialogTitle id="pay-dialog-title">Подтверждение заявки на производство</DialogTitle>
                    <DialogContent>
                        <Paper elevation={2} className={classes.warningMessage}>
                            <Typography variant="h6">Внимание!</Typography>
                            <Typography variant="subtitle1">Согласующий несёт материальную ответственность<br/>за данные в файле <a href={getDownloadLink(request.price ? request.price.id : null)}>«Бланк согласования»</a></Typography>
                        </Paper>

                        <AnyField
                            id="payTypes"
                            label="Тип оплаты"
                            value={payData.types || []}
                            onChange={this.handlePayDialogChange('types')}
                            disabled={payDialogLoading}
                            select
                            SelectProps={{
                                multiple: true,
                                renderValue: selected => selected.map(type => getPayTypeText(type)).join(', '),
                            }}
                            fullWidth
                        >
                            {Object.keys(payTypes).map(type =>
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={payData.types.indexOf(type) > -1} />
                                    <ListItemText primary={getPayTypeText(type)} />
                                </MenuItem>
                            )}
                        </AnyField>
                        {payData.types.indexOf(payTypes.pre_pay) >= 0 ?
                            <MoneyField
                                label="Сумма предоплаты"
                                currencies={ [currency] }
                                value={ payData.amountPre || {...emptyMoney} }
                                onChange={this.handlePayDialogChange('amountPre')}
                                fullWidth
                            />
                            : null
                        }
                        {payData.types.indexOf(payTypes.delay_pay) >= 0 ?
                            <MoneyField
                                label="Сумма отсроченного платежа"
                                currencies={ [currency] }
                                disabled={payDialogLoading}
                                value={ payData.amountDelay || {...emptyMoney} }
                                onChange={this.handlePayDialogChange('amountDelay')}
                                fullWidth
                            />
                            : null
                        }
                        <MoneyField
                            label="Общая сумма"
                            currencies={ [currency] }
                            disabled={payDialogLoading}
                            value={ payData.amountTotal || {...emptyMoney} }
                            onChange={this.handlePayDialogChange('amountTotal')}
                            fullWidth
                        />
                        {payData.types.indexOf(payTypes.delay_pay) >= 0 ?
                            <AnyField
                                id="payDelay"
                                value={payData.payDelay}
                                onChange={this.handlePayDialogChange('payDelay')}
                                disabled={payDialogLoading}
                                select
                                label="Условия отсрочки"
                                fullWidth>
                                {payDelays.map(payDelay =>
                                    <MenuItem key={payDelay.id} value={payDelay.id}>
                                        {payDelay.name}
                                    </MenuItem>
                                )}
                            </AnyField>
                            : null
                        }
                        <AnyField
                            id="comm"
                            label="Комментарий"
                            value={payData.comment}
                            onChange={this.handlePayDialogChange('comment')}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClosePayDialog(true)} disabled={!payData.types.length || (payData.types.indexOf(payTypes.delay_pay) >= 0 && !payData.payDelay) } color="primary">{t('Send')}</Button>
                        <Button onClick={this.handleClosePayDialog(false)} color="primary">{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={deliveryDialogOpen}
                    onClose={this.handleCloseDeliveryDialog(false)}
                    aria-labelledby="delivery-dialog-title"
                >
                    <DialogTitle id="delivery-dialog-title">{deliveryDialogTitle}</DialogTitle>
                    <DialogContent style={{width: 360}}>
                        {deliveryData.toParlour ?
                            <AnyField
                                id="parlour"
                                value={deliveryData.parlour}
                                onChange={this.handleDeliveryDialogChange('parlour')}
                                disabled={deliveryDialogLoading}
                                select
                                label="Салон"
                                fullWidth
                            >
                                {parlours.map(parlour =>
                                    <MenuItem key={parlour.id} value={parlour.id}>
                                        {parlour.name}
                                    </MenuItem>
                                )}
                            </AnyField>
                            : null
                        }
                        <AnyField
                            id="type"
                            value={deliveryData.type}
                            onChange={this.handleDeliveryDialogChange('type')}
                            disabled={deliveryDialogLoading}
                            select
                            label="Способ доставки"
                            fullWidth
                        >
                            {Object.keys(deliveryTypes).map(type =>
                                <MenuItem key={type} value={type}>
                                    {getDeliveryTypeText(type)}
                                </MenuItem>
                            )}
                        </AnyField>
                        {deliveryData.type === deliveryTypes.transport_company ?
                            <React.Fragment>
                                <AnyField
                                    id="name"
                                    label="Название транспортной компании"
                                    value={deliveryData.name}
                                    disabled={deliveryDialogLoading}
                                    multiline
                                    rows="2"
                                    onChange={this.handleDeliveryDialogChange('name')}
                                    fullWidth
                                />
                                <AnyField
                                    id="waybill"
                                    label="Номер накладной"
                                    value={deliveryData.waybill}
                                    disabled={deliveryDialogLoading}
                                    onChange={this.handleDeliveryDialogChange('waybill')}
                                    fullWidth
                                />
                                <UploadFilesField
                                    id="receiptScan"
                                    label="Скан квитанции"
                                    value={deliveryData.receiptScan}
                                    onChange={this.handleDeliveryDialogChange('receiptScan')}
                                />
                                <AnyField
                                    id="charges"
                                    label="Стоимость"
                                    value={deliveryData.charges}
                                    disabled={deliveryDialogLoading}
                                    onChange={this.handleDeliveryDialogChange('charges')}
                                    fullWidth
                                    InputProps={{
                                        inputComponent: NumberFormatCurrency,
                                        endAdornment: <SymbolRub/>,
                                    }}
                                />
                            </React.Fragment>
                            : null
                        }
                        <AnyField
                            id="comm"
                            label="Комментарий"
                            value={deliveryData.comment}
                            onChange={this.handleDeliveryDialogChange('comment')}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDeliveryDialog(true)} disabled={deliveryData.toParlour && !deliveryData.parlour} color="primary">{t('Send')}</Button>
                        <Button onClick={this.handleCloseDeliveryDialog(false)} color="primary">{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={productionDialogOpen}
                    onClose={this.handleCloseProductionDialog(false)}
                    aria-labelledby="production-dialog-title"
                >
                    <DialogTitle id="production-dialog-title">Подтверждение производства</DialogTitle>
                    <DialogContent style={{width: 360}}>
                        <UploadFilesField
                            id="price"
                            label="Бланк согласования"
                            value={productionData.price}
                            onChange={this.handleProductionDialogChange('price')}
                        />
                        <UploadFilesField
                            id="bill"
                            label="Счет"
                            value={productionData.bill}
                            onChange={this.handleProductionDialogChange('bill')}
                        />
                        <AnyField
                            id="cmm"
                            label="Комментарий"
                            value={productionData.comment}
                            onChange={this.handleProductionDialogChange('comment')}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseProductionDialog(true)} color="primary">{t('Send')}</Button>
                        <Button onClick={this.handleCloseProductionDialog(false)} color="primary">{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={confirmDialogOpen}
                    onClose={this.handleCloseConfirmDialog(false)}
                    aria-labelledby="confirm-dialog-title"
                >
                    <DialogTitle id="confirm-dialog-title">{confirmAccept ? 'Подтверждение' : 'Отклонение'} заявки</DialogTitle>
                    <DialogContent style={{minWidth: 400}}>
                        <AnyField
                            label="Комментарий"
                            value={confirmComment}
                            name="confirmComment"
                            onChange={this.handleChange}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmDialog(true)}  color="primary">{t('Send')}</Button>
                        <Button onClick={this.handleCloseConfirmDialog(false)} color="primary">{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={fillNominalsDialogOpen}
                    onClose={this.handleCancelFillNominalsDialog}
                    aria-labelledby="fillNominals-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="fillNominals-dialog-title">{t('Fill in the nominals')}</DialogTitle>
                    <DialogContent>
                        <AnyField
                            label={t('List of nominals separated by the symbol ;')}
                            value={nominalsList}
                            name="nominalsList"
                            error={!this.checkNominalsList()}
                            required
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <Typography color="error">{t('Attention! The nominals of all PD')}</Typography>
                        <FormControlLabel className={classes.textField}
                            control={
                                <Checkbox checked={isBalance} onChange={this.handleChangeBalance} name="isBalance"/>
                            }
                            label="Баланс"
                        />
                        <Typography variant="poster" color="error">Оставьте поле пустым если изменять баланс не нужно!</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSuccessFillNominalssDialog} disabled={!this.checkNominalsList()} color="primary">{t('Send')}</Button>
                        <Button onClick={this.handleCancelFillNominalsDialog} color="primary">{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={changeStatusDialogOpen}
                    aria-labelledby="changeStatus-dialog-title"
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle id="changeStatus-dialog-title">Изменить статус ПД</DialogTitle>
                    <DialogContent>
                         <AnyField
                            id="payDocStatus"
                            label="Статус ПД"
                            onChange={this.handleChangePayDocStatus}
                            value={sendPayDocStatus.payDocStatusId}
                            select
                            fullWidth
                        >
                            {sendPayDocStatuses.map(status =>
                                <MenuItem
                                    key={status.id}
                                    value={status.id}
                                >{status.text}</MenuItem>)}
                        </AnyField>
                        <DateField2
                            label={t("payDoc.validity")}
                            value={sendPayDocStatus.validity}
                            onChange={this.handleChangePayDocValidity}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseChangeStatus(true)}
                            color="primary"
                        >{t("Send")}</Button>
                        <Button
                            onClick={this.handleCloseChangeStatus(false)}
                            color="primary"
                        >{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(RequestViewPage);
