import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    MenuItem,
    FormControlLabel,
    Checkbox,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ButtonBase,
    Fab, IconButton
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import Face from '@material-ui/icons/Face';
import Add from '@material-ui/icons/Add';
import {
    createGuest,
    defaultLoyalty,
    getFullName,
    getGenders,
    getGuest,
    getLinkTypes,
    getLoyalties,
    getPhoneTypes,
    navLinks,
    updateGuest,
    getKnows,
    searchByPhoneGuest,
    removeGuest,
    getVisits,
    getContacts,
    sendClubPayLink,
    sendUnsubscribeClub
} from "../../services/guests";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import infoActions from '../../components/info/info-actions';
import { connect } from 'react-redux';
import {getMasters, getParlours, getRegions} from "../../services/organization";
import {isValidPhoneNumber} from '../../utils/phone-utils';
import classNames from 'classnames';
import PhoneField from "../../components/fields/PhoneField";
import ShowField from "../../components/fields/ShowField";
import AnyField from "../../components/fields/AnyField";
import GuestField from "../../components/fields/GuestField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import DateField from "../../components/fields/DateField";
import {getFacilities} from "../../services/facilities";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {hasRole} from "../../services/user";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import {getDiscountActiveTypes, getDiscountTypes, discountTypeCustom} from "../../services/calendar";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import NumberFormatDecimal from "../../components/fields/NumberFormatDecimal";
import CheckboxField from "../../components/fields/CheckboxField";
import PayDocsLeftFields from "./PayDocsLeftFields";
import {loadToState} from "../../utils/helpers";
import _ from "lodash";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import SendIcon from "@material-ui/icons/Send";
import ViewPageTabNalegke from "./viewPageTabs/nalegke"
import ViewPageTabStats from "./viewPageTabs/stats"

const styles = theme => ({
    container: {
        minWidth: 600,
    },
    textField: {
        marginTop:  theme.spacing(2),
    },
    textFieldWithButton:
    {
        width: 'calc(100% - 40px)',
    },
    noTopMargin: {
        marginTop: 0,
    },
    listHeader: {
        marginBottom: 0,
        paddingBottom: '0 !important',
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    buttonExpand: {
        marginTop:  theme.spacing(1),
        marginBottom:  theme.spacing(1),
        marginRight:  theme.spacing(1),
    },
    buttonAdd: {
        marginTop:  theme.spacing(1),
        width: 300,
    },
    buttonDel: {
        marginTop: 0,
        width: 35,
        height: 35,
        marginLeft: 5,
    },
    inputLabel: {
        fontSize: '1rem',
        color: 'rgba(0, 0, 0, 0.54)',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    greenFilled: {
        background: 'linear-gradient(to right, green, #ffffff 16px)'
    },
    yellowFilled: {
        background: 'linear-gradient(to right, yellow, #ffffff 16px)'
    },
    redFilled: {
        background: 'linear-gradient(to right, red, #ffffff 16px)'
    },
    clickable: {
        cursor: 'pointer',
    },
    sectionGreen: {
        color: 'green',
    },
    sectionYellow: {
        color: 'yellow',
    },
    sectionRed: {
        color: 'red',
    },
    socialIcon: {
        width: 35,
        height: 35,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 -35px',
        opacity: 0.7,
        marginTop: 0,
    },
    iconViber: {
        backgroundImage: 'url(/img/viber.png)',
    },
    iconWhatsapp: {
        backgroundImage: 'url(/img/whatsapp.png)',
    },
    iconTelegram: {
        backgroundImage: 'url(/img/telegram.png)',
    },
    iconActive: {
        backgroundPosition: '0 0',
        opacity: 1,
    },
    payDoc: {
        fontSize: theme.typography.body2.fontSize,
        border: '1px solid #777',
        borderRadius: '3px',
        textAlign: 'center',
        padding: '1px 2px',
        marginRight: '2px',

        '&:last-child': {
            marginRight: 0,
        }
    },
    payDocNoValidity: {
        color: 'red',
        '& a': {
            color: 'red'
        }
    },
    discountContainer: {
        paddingBottom: 15,
        marginBottom: 15,
        borderBottom: '1px solid #ccc'
    },
    delContainer: {
        alignItems: 'center',
        display: 'flex',
        textAlign: 'center'
    },
    topBadge: {
        marginBottom: 5
    },
    notifySmsLabel: {
        marginTop: 10,
    },
    sendButton: {
        top: '21px',
        right: '-6px',
        padding: '20px',
        width: '34px',
        height: '34px',
    },
    sendLink:{
        '& label':{
            fontSize: '12.5px'
        }
    }
});

const allSections = [
    'connection',
    'address',
    'visit',
    'other',
    'favorite',
    'activity',
    'link',
    'stats',
    'discounts',
    'nalegke',
];

const emptyDiscount = {
    id: null,
    num: null,
    name: null,
    value: null,
    active: true,
    expiredAt: null,
    numberRequired: false,
    maxLimit: null,
    type: null
};

export default
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
})
@withStyles(styles)
@withRouter
@withTranslation()
class ViewPage extends React.Component {

    state = {
        guest: {
            id: null,
            lastName: null,
            name: null,
            secondName:null,

            gender: null,
            birthDate: null,
            specialStatus: null,

            phone: null,

            phones: [],
            skype: null,
            email: null,

            odnoklassniki: null,
            vkontakte: null,
            facebook: null,
            instagram: null,

            regionId: null,
            regionName: null,
            livingCity: null,
            livingAddress: null,

            workAddress: null,
            work: null,

            convenientParloursHome: [],
            convenientParloursWork: [],

            knows: null,
            knowsOther: null,
            advice: null,
            adviceType: null,

            memoryDates: [],

            tags: null,
            comment: null,
            isCold: false,
            loyalty: defaultLoyalty,
            loyaltyComment: 'По умолчанию',

            favoriteMasters: [],
            notFavoriteMasters: [],
            favoriteParlours: [],
            favoriteProcedures: [],

            lastCallDate: null,
            nextCallDate: null,

            interestHotHour: false,
            convenientHotHours: null,

            agreementCall: true,
            agreementPolicy: false,
            agreementCallReason: null,

            guestLinks: [],

            convenientDays: null,
            convenientHours: null,

            payDocs: null,
            additionalPayDocs: null,

            temporaryParlour: null,

            dateCreated: null,
            creator: null,

            discounts: [],
            isClubMember: false,
            agreementSMS: false,
            notifyGuestBySms: true,
            bitrixId: null,
            nameVerified: false,
            phoneVerified: true,
            hasMobile: false,
            messengers: [],
        },
        sendToMessengers: [],
        sendToUnsubscribeMessengers: [],
        types: [],
        phoneTypes: [],
        loyalties: [],
        parlours: [],
        masters: [],
        procedures: [],
        linkTypes: [],
        editing: false,
        genders: [],
        knows: [],
        expandedSections: [],
        foundGuest: null,
        activeTypes: [],
        lastContact: null,
        prevVisit: null,
        nextVisit: null,
        bitrixBaseLink: 'https://tvoitai.bitrix24.ru/crm/contact/details/',
        regions: [],
        discountTypes: [],
    };

    componentDidMount() {
        this.init(this.props.location.pathname);

        getDiscountActiveTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        activeTypes: response.data,
                    })
                }
            });

        getDiscountTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        discountTypes: response.data,
                    })
                }
            });

        getPhoneTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        phoneTypes: response.data,
                    });
                }
            });

        getLoyalties()
            .then(response => {
                if (response.success) {
                    this.setState({
                        loyalties: response.data,
                    });
                }
            });

        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    });
                }
            });

        getMasters()
            .then(response => {
                if (response.success) {
                    this.setState({
                        masters: response.data,
                    });
                }
            });

        getFacilities(null, 1, -1, 'asc', 'name')
            .then(response => {
                if (response.success) {
                    this.setState({
                        procedures: response.data,
                    });
                }
            });

        getLinkTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        linkTypes: response.data,
                    });
                }
            });

        getGenders()
            .then(response => {
                if (response.success) {
                    this.setState({
                        genders: response.data,
                    });
                }
            });

        getKnows()
            .then(response => {
                if (response.success) {
                    this.setState({
                        knows: response.data,
                    });
                }
            });

        loadToState(this, getRegions(), 'regions');
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.init(nextProps.location.pathname);
        }
    }

    init(path) {
        const reId = new RegExp('/guests/(view|edit)/([^/]+)$');
        const resultId = reId.exec(path);
        const guestId = resultId[2];
        if (resultId.length !== 3) {
            //Error request
            return;
        }

        this.setState({
            editing: resultId[1] === 'edit',
            expandedSections: [],
        });

        if (guestId !== "0") {
            getGuest(guestId)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            guest: {
                                ...response.data,
                                medicalCardParts: response.data.medicalCardParts || {},
                                notifyGuestBySms: true,
                            }
                        })
                    }
                });
            getVisits(
                guestId,
                {
                    excludedStatuses: ['canceled']
                },
                1, 2, 'desc', 'start')
                .then((response => {
                    if (response.success) {
                        this.setState({
                            nextVisit: response.data.find((visit) => {
                                return moment(visit.start).isAfter(moment())
                            }),
                            prevVisit: response.data.find((visit) => {
                                return moment(visit.start).isBefore(moment())
                            })
                        });
                    }
                }))
            getContacts(
                guestId,
                1, 1, 'desc', 'dateCreated')
                .then(response => {
                    if(response.success) {
                        this.setState({
                            lastContact: response.data ? response.data[0] : null
                        })
                    }
                });
        }
    }

    handleChange = (prop, idx, field) => event => {
        if (idx >= 0 && field) {
            const items = this.state.guest[prop];
            items[idx] = { ...items[idx], [field]: event.target.value };

            this.setState({
                guest: {
                    ...this.state.guest,
                    [prop]: items,
                }
            });
        } else {
            const array = ['name', 'lastName'];
            this.setState({
                guest: {
                    ...this.state.guest,
                    [prop]: event.target.value,
                    nameVerified : !array.includes(prop),
                },
            });
        }
    };

    handleChangeDate = (prop, idx, field) => date => {
        if (idx >= 0 && field) {
            const items = this.state.guest[prop];
            items[idx] = { ...items[idx], [field]: date };

            this.setState({
                guest: {
                    ...this.state.guest,
                    [prop]: items,
                }
            });
        } else {
            this.setState({
                guest: {
                    ...this.state.guest,
                    [prop]: date,
                }
            });
        }
    };

    handleChangeCb = (prop, idx, field) => event => {
        if (idx >= 0 && field) {
            const items = this.state.guest[prop];
            items[idx] = { ...items[idx], [field]: event.target.checked };

            this.setState({
                guest: {
                    ...this.state.guest,
                    [prop]: items,
                }
            });
        } else {
            this.setState({
                guest: {
                    ...this.state.guest,
                    [prop]: event.target.checked,
                }
            });
        }

    };

    handleIconClick = (prop, idx, field) => () => {
        if (idx >= 0 && field) {
            const items = this.state.guest[prop];
            items[idx] = { ...items[idx], [field]: !items[idx][field]};

            this.setState({
                guest: {
                    ...this.state.guest,
                    [prop]: items,
                }
            });
        } else {
            this.setState({
                guest: {
                    ...this.state.guest,
                    [prop]: !this.state.guest[prop],
                }
            });
        }

    };

    handleSave = () => {
        const {t} = this.props;

        if (this.state.guest.id) {
            updateGuest(this.state.guest)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/guests/view/${response.data.id}`);
                        this.props.showInfo(t("Changes saved"));
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        } else {
            createGuest(this.state.guest)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/guests/view/${response.data.id}`);
                        this.props.showInfo(t("New guest added"));
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleCancelSave = () => {
        if (this.state.guest.id) {
            this.props.history.push(`/guests/view/${this.state.guest.id}`);
        } else {
            this.props.history.push(`/guests`);
        }
    };

    handleEdit = () => {
        this.props.history.push(`/guests/edit/${this.state.guest.id}`);
    };

    handleHistory = type => () => {
        this.props.history.push(`/guests/history-${type}/${this.state.guest.id}`);
    };

    handleWaMessages = () => {
        this.props.history.push(`/guests/whats-app-messages/${this.state.guest.id}`);
    };

    handleContact = () => {
        this.props.history.push(`/guests/contact/${this.state.guest.id}`);
    };

    handleFinancialCard = () => {
        this.props.history.push(`/guests/financial-card/${this.state.guest.id}`);
    };

    handlePhoneAdd = () => {
        this.setState(state => ({
            guest: {
                ...state.guest,
                phones: [...state.guest.phones, {id: null, number: null, type: null}],
            }
        }));
    };

    handlePhoneDel = idx => () => {
        let phones = [...this.state.guest.phones];
        phones.splice(idx,1);

        this.setState(state =>({
            guest: {
                ...state.guest,
                phones
            }
        }));
    };

    handleFavoriteAdd = prop => () => {
        this.setState(state => ({
            guest: {
                ...state.guest,
                [prop]: [...state.guest[prop], {id: null}],
            }
        }));
    };

    handleFavoriteDel = (prop, idx) => () => {
        let items = [...this.state.guest[prop]];
        items.splice(idx,1);

        this.setState(state =>({
            guest: {
                ...state.guest,
                [prop]: items,
            }
        }));
    };

    handleMemoryDateAdd = () => {
        this.setState(state => ({
            guest: {
                ...state.guest,
                memoryDates: [...state.guest.memoryDates, {date: null, comment: null}],
            }
        }));
    };

    handleMemoryDateDel = (idx) => () => {
        let memoryDates = [...this.state.guest.memoryDates];
        memoryDates.splice(idx,1);

        this.setState(state =>({
            guest: {
                ...state.guest,
                memoryDates,
            }
        }));
    };

    handleConvenientAdd = prop => () => {
        this.setState(state => ({
            guest: {
                ...state.guest,
                [prop]: [...state.guest[prop], {parlourId: null, travelTime: null}],
            }
        }));
    };

    handleConvenientDel = (prop, idx) => () => {
        let items = [...this.state.guest[prop]];
        items.splice(idx,1);

        this.setState(state =>({
            guest: {
                ...state.guest,
                [prop]: items,
            }
        }));
    };

    handleLinkAdd = () => {
        this.setState(state => ({
            guest: {
                ...state.guest,
                guestLinks: [...state.guest.guestLinks, {date: null, comment: null}],
            }
        }));
    };

    handleLinkDel = (idx) => () => {
        let guestLinks = [...this.state.guest.guestLinks];
        guestLinks.splice(idx,1);

        this.setState(state =>({
            guest: {
                ...state.guest,
                guestLinks,
            }
        }));
    };

    isSectionFilled = section => {
        const { guest } = this.state;

        switch (section) {
            case 'connection': {
                if (guest.skype && guest.email && Array.isArray(guest.phones) && guest.phones.length) {
                    return 1;
                } else if (guest.skype || guest.email || (Array.isArray(guest.phones) && guest.phones.length)) {
                    return -1;
                }
                break;
            }

            case 'address': {
                if (guest.livingCity && guest.livingAddress && guest.work && guest.workAddress && guest.regionId) {
                    return 1;
                } else if (guest.livingCity || guest.livingAddress || guest.work || guest.workAddress || guest.regionId) {
                    return -1;
                }
                break;
            }

            case 'visit': {
                if (guest.temporaryParlour && guest.convenientParloursHome.length && guest.convenientParloursWork.length) {
                    return 1;
                } else if (guest.temporaryParlour || guest.convenientParloursHome.length || guest.convenientParloursWork.length) {
                    return -1;
                }
                break;
            }

            case 'other': {
                if (guest.knows && guest.knowsOther && guest.advice && guest.adviceType && guest.memoryDates.length && guest.loyalty && guest.loyaltyComment && guest.tags && guest.comment && guest.agreementCallReason) {
                    return 1;
                } else if (guest.knows || guest.knowsOther || guest.advice || guest.adviceType || guest.memoryDates.length || guest.loyalty || guest.loyaltyComment || guest.tags || guest.comment || guest.agreementCallReason) {
                    return -1;
                }
                break;
            }

            case 'favorite': {
                if (guest.favoriteMasters.length && guest.notFavoriteMasters.length && guest.favoriteParlours.length && guest.favoriteProcedures.length) {
                    return 1;
                } else if (guest.favoriteMasters.length || guest.notFavoriteMasters.length || guest.favoriteParlours.length || guest.favoriteProcedures.length) {
                    return -1;
                }
                break;
            }

            case 'activity': {
                if (guest.lastCallDate && guest.nextCallDate && guest.interestHotHour && guest.convenientHotHours) {
                    return 1;
                } else if (guest.lastCallDate || guest.nextCallDate || guest.interestHotHour || guest.convenientHotHours) {
                    return -1;
                }
                break;
            }

            case 'link': {
                if (guest.guestLinks.length) {
                    return 1;
                } else if (guest.guestLinks.length) {
                    return -1;
                }
                break;
            }

            case 'discounts': {
                if (guest.discounts.length) {
                    return 1;
                }
                return 0;
            }

            default:
                return null;
        }

        return 0;
    };

    getSectionClass = section => {
        const { classes } = this.props;

        switch (this.isSectionFilled(section)) {
            case 1:
                return classes.greenFilled;
            case -1:
                return classes.yellowFilled;
            case 0:
                return classes.redFilled;

            default:
                return null;
        }
    };

    getArrowClass = section => {
        const { classes } = this.props;

        switch (this.isSectionFilled(section)) {
            case 1:
                return classes.sectionGreen;
            case -1:
                return classes.sectionYellow;
            case 0:
                return classes.sectionRed;

            default:
                return null;
        }
    };

    handleExpand = section => (event, expanded) => {
        const idx = this.state.expandedSections.indexOf(section);
        if (!expanded && idx >= 0) {
            let newSections = [...this.state.expandedSections];
            newSections.splice(idx, 1);
            this.setState({
                expandedSections: newSections,
            });
        } else if (expanded && idx < 0) {
            this.setState({
                expandedSections: [section], //[...this.state.expandedSections, section],
            });
        }
    };

    handleExpandAll = () => {
        this.setState({
            expandedSections: [...allSections],
        })
    };

    handleCollapseAll = () => {
        this.setState({
            expandedSections: [],
        })
    };

    isExpanded = section => {
        return this.state.expandedSections.indexOf(section) >= 0;
    };

    handleChangeMainPhone = phone => {
        this.setState(
            state => ({ guest: { ...state.guest, phone } }),
            () => {
                const isHomePhone = phone[0] === '7' && (phone[1] === '3' || phone[1] === '4' || phone[1] === '8');
                const isMobilePhone = phone[0] === '7' && phone[1] === '9';

                switch (true) {
                    case isHomePhone:
                        this.setState(state => ({
                            guest: {
                                ...this.state.guest,
                                agreementSMS: false,
                                agreementCall: true
                            }
                        }))
                        break;
                    case isMobilePhone:
                        this.setState(state => ({
                            guest: {
                                ...this.state.guest,
                                agreementSMS: true,
                                agreementCall: true
                            }
                        }))
                        break;
                    default:
                        this.setState(state => ({
                            guest: {
                                ...this.state.guest,
                                agreementSMS: false,
                                agreementCall: false
                            }
                        }))
                        break;
                }

                this.searchGuestDebounce(phone)
            }
        );
    };

    searchGuest = (phone) => {
        searchByPhoneGuest(phone, this.state.guest.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        foundGuest: response.data,
                    });
                } else {
                    this.setState({
                        foundGuest: null,
                    });
                }
            });
    }

    searchGuestDebounce = _.debounce(this.searchGuest, 500)

    handleRemove = () => {
        const { t } = this.props;
        this.props.showConfirm({
            message: t("Are you sure you want to remove the guest"),
            title: t("Remove guest"),
            onClose: this.handleRemoveConfirmClose
        });
    };

    handleRemoveConfirmClose = ok => {
        const { t } = this.props;

        if (ok) {
            removeGuest(this.state.guest.id)
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/guests`);
                        this.props.showInfo(t("Changes saved"));
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                })
        }
    };

    handleChangeSendToMessengers = (e) =>{
        let value = e.target.value
        this.setState(prev=>({
            ...prev,
            sendToMessengers : value
        }));

    }

    handleChangeSendToUnsubscribeMessengers = (e) =>{
        this.setState(prev => ({
            ...prev,
            sendToUnsubscribeMessengers : e.target.value
        }));

    }

    sendClubPayLink = () =>{
        const {sendToMessengers, guest} = this.state
        if(!sendToMessengers.length) {
            this.props.showMessage('You haven\'t chosen messenger to send link');
            return;
        }
        let guestClubPayLink = {
            guestId: guest.id,
            sendToMessengers
        }
        sendClubPayLink(guestClubPayLink)
            .then(response=>{
                if(response.success){
                    this.props.showMessage(response.data.message);
                }else{
                    this.props.showMessage(response.error ? response.error.message : response.message )
                }
            })
    }

    handleSendUnsubscribeClub = () =>{
        const {sendToUnsubscribeMessengers, guest} = this.state

        if(!sendToUnsubscribeMessengers.length) {
            this.props.showMessage('You haven\'t chosen messenger to send link');
            return;
        }

        let guestClubPayLink = {
            guestId: guest.id,
            sendToUnsubscribeMessengers
        }

        sendUnsubscribeClub(guestClubPayLink)
            .then(response => {
                if(response.success){
                    this.props.showMessage(response.data.message);
                }else{
                    this.props.showMessage(response.error ? response.error.message : response.message )
                }
            })
    }

    getSendLinkMessengers = () => {
        const {guest} = this.state;
        let messengers = guest.messengers;
        return messengers.filter((item)=>{return item.type !== 'email'});
    }


    toClubSubscriptionHistory = () => {
        this.props.history.push(`/guests/history-club/${this.state.guest.id}`);
    }


    render() {
        const { classes, t } = this.props;
        const { guest, editing, expandedSections, foundGuest, sendToMessengers, sendToUnsubscribeMessengers } = this.state;

        const disabledSave = !(guest.name && guest.phone && isValidPhoneNumber(guest.phone) && guest.loyaltyComment)
            || (guest.interestHotHour && !guest.convenientHotHours)
            || foundGuest
            || guest.discounts.filter(discount => !discount.name || discount.value === null).length
            || guest.name?.length < 2;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Guest card")}/>}>
                    {this.renderLeftFields()}
                    <br />
                    <br />
                    {editing ?
                        <React.Fragment>
                            <ActionButton onClick={this.handleSave} disabled={Boolean(disabledSave)}>{guest.id ? t("Save") : t("Register")}</ActionButton>
                            <ActionButton onClick={this.handleCancelSave}>Отмена</ActionButton>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {guest.messengers.length ? <Grid item xs={12} style={{display:'flex'}}>
                                <MultiSelectFromItemsField
                                    label={t('Send link to join club')}
                                    items={this.getSendLinkMessengers()}
                                    value={sendToMessengers}
                                    onChange={this.handleChangeSendToMessengers}
                                    valueField="type"
                                    textField="name"
                                    emptySelectedLabel={t('pay.choose')}
                                    fullWidth={true}
                                    className={classes.sendLink}
                                />

                                <IconButton
                                    aria-label="Copy"
                                    onClick={this.sendClubPayLink}
                                    disabled={!sendToMessengers.length}
                                    className={classes.sendButton}
                                >
                                    <SendIcon/>
                                </IconButton>
                            </Grid> : null}
                            {
                                guest.messengers.length &&
                                <Grid item xs={12} style={{display:'flex'}}>
                                    <MultiSelectFromItemsField
                                        label={t('Send link for self-unsubscribe')}
                                        items={this.getSendLinkMessengers()}
                                        value={sendToUnsubscribeMessengers}
                                        onChange={this.handleChangeSendToUnsubscribeMessengers}
                                        valueField="type"
                                        textField="name"
                                        emptySelectedLabel={t('pay.choose')}
                                        fullWidth={true}
                                        className={classes.sendLink}
                                    />

                                    <IconButton
                                        aria-label="Copy"
                                        onClick={this.handleSendUnsubscribeClub}
                                        disabled={!sendToUnsubscribeMessengers.length}
                                        className={classes.sendButton}
                                    >
                                        <SendIcon/>
                                    </IconButton>
                                </Grid>
                            }
                            <ActionButton visible={!!guest.id} onClick={this.handleEdit}>{t("Edit parameters")}</ActionButton>
                            <ActionButton visible={!!guest.id} onClick={this.handleContact}>{t("Fix interaction")}</ActionButton>
                            <ActionButton visible={!!guest.id} onClick={this.handleHistory('contact')}>{t("Interaction history")}</ActionButton>
                            <ActionButton visible={!!guest.id} onClick={this.handleHistory('visit')}>{t("History of visits")}</ActionButton>
                            <ActionButton visible={!!guest.id} onClick={this.handleHistory('buy')}>{t("Purchase history")}</ActionButton>
                            <ActionButton visible={!!guest.id} onClick={this.handleHistory('change')}>{t("History of change of parameters")}</ActionButton>
                            <ActionButton visible={!!guest.id} onClick={this.handleWaMessages}>{t("WhatsApp messages")}</ActionButton>
                            <ActionButton visible={!!guest.id && hasRole('ROLE_MONEYFLOW_GUEST_FINANCIAL_CARD')} onClick={this.handleFinancialCard}>{t("Financial card")}</ActionButton>
                            <ActionButton visible={!!guest.id} onClick={this.toClubSubscriptionHistory}>{t("GuestClub.GuestSubscriptions")}</ActionButton>
                            <ActionButton visible={!!guest.id && hasRole('ROLE_GUEST_GUEST_DELETE')} onClick={this.handleRemove} color="primary">{t("Remove")}</ActionButton>
                        </React.Fragment>
                    }
                    <div>

                    </div>
                </LeftBar>
                <Content title={t("Guest card")}>
                    {editing ? this.renderSectionMain() : null}
                    <ActionButton className={classes.buttonExpand} fullWidth={false} onClick={this.handleExpandAll} disabled={expandedSections.length === allSections.length}>{t("Expand all")}<ExpandMore/></ActionButton>
                    <ActionButton className={classes.buttonExpand} fullWidth={false} onClick={this.handleCollapseAll} disabled={expandedSections.length === 0}>{t("Collapse all")}<ExpandLess/></ActionButton>
                    {this.renderSectionConnection()}
                    {this.renderSectionAddress()}
                    {this.renderSectionVisit()}
                    {this.renderSectionOther()}
                    {this.renderSectionFavorite()}
                    {this.renderSectionActivity()}
                    {this.renderSectionLink()}
                    {this.renderSectionDiscounts()}
                    {this.renderSectionReadOnly("stats", t("Statistics"))}
                    {this.renderSectionReadOnly("nalegke", t("Nalegke discounts"))}
                </Content>
            </React.Fragment>
        );
    }

    renderLeftFields() {
        const { t, classes } = this.props;
        const { guest, genders, prevVisit, nextVisit, lastContact } = this.state;

        const gender = genders.find(item => item.id === guest.gender);

        return (
            <React.Fragment>
                { guest.isClubMember && <Chip size="small" label={t('Club member')} color="primary" className={classes.topBadge} icon={<Face/>} />}
                <ShowField
                    label={t("Guest phone")}
                    value={guest.phone ? `${guest.phone} ${guest.phoneVerified ? "(верифицирован)" : "(не верифицирован)"}` : ''}
                />
                { guest.phones
                    .filter(phone => phone.type === 'temp')
                    .map(phone => (<ShowField
                            label={t("Temporary phone")}
                            value={phone.number}
                        />
                    ))
                }
                <ShowField
                    label={t("Full name")}
                    value={getFullName(guest)}
                />
                {guest.subscriptions && guest.subscriptions.length ?
                    <ShowField
                        label={t("BotGuest subscriptions")}
                        value={guest.subscriptions.join(', ')}
                    />
                : null }

                <ShowField
                    label={t("Gender")}
                    value={gender ? gender.text : null}
                />
                <ShowField
                    label={t("Date of Birth")}
                    value={guest.birthDate}
                    dateFormat="DD.MM.YYYY"
                />
                <ShowField
                    label={t("Special status")}
                    value={guest.specialStatus}
                />
                <ShowField
                    label={t("Consent to the call")}
                    value={guest.agreementCall ? t("Yes") : t("No")}
                />
                <ShowField
                    label={t("Agreement SMS")}
                    value={guest.agreementSMS ? t("Yes") : t("No")}
                />
                <ShowField
                    label={t("Consent to the processing of personal data")}
                    value={guest.agreementPolicy ? t("Yes") : t("No")}
                    label2Lines
                />
                <ShowField
                    label={t("Guest name verified")}
                    value={guest.nameVerified ? t("Yes") : t("No")}
                />
                <ShowField
                    label={t("Guest phone verified")}
                    value={guest.phoneVerified ? t("Yes") : t("No")}
                />
                <ShowField
                    label={t("Last interaction date")}
                    value={lastContact ? lastContact.dateCreated : null}
                    dateFormat="DD.MM.YYYY"
                />
                <ShowField
                    label={t("Date of previous visit")}
                    value={prevVisit ? prevVisit.start : null}
                    dateFormat="DD.MM.YYYY"
                    linkTo={prevVisit ? `/orders/view/${prevVisit.id}` : null}
                />
                <ShowField
                    label={t("Date of next visit")}
                    value={nextVisit ? nextVisit.start : null}
                    dateFormat="DD.MM.YYYY"
                    linkTo={nextVisit ? `/orders/view/${nextVisit.id}` : null}
                />

                <PayDocsLeftFields payDocs={guest.payDocs} />

                <ShowField
                    label={t("Additional payment documents")}
                    flexWrap
                >
                    { guest.additionalPayDocs ? guest.additionalPayDocs.map(payDoc =>(
                        <div key={payDoc.id} className={classNames(classes.payDoc, {[classes.payDocNoValidity]: moment(payDoc.validity).isBefore(moment())})}><Link to={`/pay-docs/view/${payDoc.id}`}>{payDoc.num}</Link><br/>{payDoc.balanceText}</div>
                    )) : null }
                </ShowField>
                <ShowField
                    label={t("Bitrix id")}
                    value={guest.bitrixId ? <a href={this.state.bitrixBaseLink + guest.bitrixId + '/'} target='_blank' rel='noopener noreferrer'>{guest.bitrixId}</a>: t("No")}
                />
                <ShowField
                    label={"Есть мобильное приложение"}
                    value={guest.hasMobile ? t("Yes") : t("No")}
                />
            </React.Fragment>
        );
    }

    renderSectionMain() {
        const { classes, t } = this.props;
        const { guest, editing, genders, foundGuest } = this.state;
        const sectionClass = this.getSectionClass('main');

        return (
            <ExpansionPanel defaultExpanded className={sectionClass}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('main')} fontSize="large"/>}>{t("General information")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <PhoneField
                                label={t("Phone")}
                                defaultCountry="ru"
                                value={guest.phone}
                                onChange={this.handleChangeMainPhone}
                                fullWidth
                                error={!(guest.phone && isValidPhoneNumber(guest.phone))}
                                errorText={foundGuest ? <span>{t("Guest already exists")} <Link to={`/guests/edit/${foundGuest.id}`}>{t("Go to card")}</Link></span> : null}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="name"
                                label={t("Name, middle name")}
                                value={guest.name}
                                fullWidth
                                required
                                onChange={this.handleChange('name')}
                                error={guest.name?.length < 2}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="lastName"
                                label={t("Surname")}
                                value={guest.lastName}
                                fullWidth
                                onChange={this.handleChange('lastName')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="gender"
                                label={t("Gender")}
                                value={guest.gender}
                                fullWidth
                                select
                                onChange={this.handleChange('gender')}
                                readOnly={!editing}
                            >
                                {genders.map(type => (
                                    <MenuItem key={type.id} value={type.id}>{type.text}</MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="specialStatus"
                                label={t("Special status")}
                                value={guest.specialStatus}
                                fullWidth
                                onChange={this.handleChange('specialStatus')}
                                readOnly={!editing}
                            >
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <DateField
                                label={t("Date of Birth")}
                                value={guest.birthDate}
                                format="DD.MM.YYYY"
                                onChange={this.handleChangeDate('birthDate')}
                                disableFuture
                                openTo={guest.birthDate ? 'date' : 'year'}
                                readOnly={!editing}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CheckboxField
                                name="isClubMember"
                                label={t('Club member')}
                                value={guest.isClubMember}
                                onChange={this.handleChange('isClubMember')}
                            /></Grid>
                        <Grid item xs={3}>
                            <CheckboxField
                                name="nameVerified"
                                label={t('Guest name verified')}
                                value={guest.nameVerified}
                                onChange={this.handleChange('nameVerified')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CheckboxField
                                name="phoneVerified"
                                label={t('Guest phone verified')}
                                value={guest.phoneVerified}
                                onChange={this.handleChange('phoneVerified')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel className={classes.textField}
                                              control={
                                                  <Checkbox
                                                      checked={guest.agreementCall}
                                                      onChange={this.handleChangeCb('agreementCall')}
                                                      value="agreementCall"
                                                  />
                                              }
                                              label={t("Consent to the call")}
                                              disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CheckboxField
                                name="agreementSMS"
                                label={t('Agreement SMS')}
                                value={guest.agreementSMS}
                                onChange={this.handleChange('agreementSMS')}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    renderSectionConnection() {
        const { classes, t } = this.props;
        const { guest, editing, phoneTypes } = this.state;
        const sectionClass = this.getSectionClass('connection');

        return (
            <ExpansionPanel className={sectionClass} expanded={this.isExpanded('connection')} onChange={this.handleExpand('connection')}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('connection')} fontSize="large"/>}>{t("Contacts")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("Phone")}</div></Grid>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("Phone type")}</div></Grid>
                                { guest.phones ?
                                    guest.phones.map((phone, idx) =>(
                                        <React.Fragment key={idx}>
                                            <Grid item xs={4}>
                                                <PhoneField
                                                    defaultCountry="ru"
                                                    value={phone.number}
                                                    onChange={this.handleChangeDate('phones', idx, 'number')}
                                                    className={classes.noTopMargin}
                                                    fullWidth
                                                    readOnly={!editing}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ButtonBase className={classNames(classes.socialIcon, classes.iconViber, {[classes.iconActive]: phone.viber })} onClick={ editing ? this.handleIconClick('phones', idx, 'viber') : null}/>
                                                <ButtonBase className={classNames(classes.socialIcon, classes.iconWhatsapp, {[classes.iconActive]: phone.whatsapp })} onClick={ editing ? this.handleIconClick('phones', idx, 'whatsapp') : null}/>
                                                <ButtonBase className={classNames(classes.socialIcon, classes.iconTelegram, {[classes.iconActive]: phone.telegram })} onClick={ editing ? this.handleIconClick('phones', idx, 'telegram') : null}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <AnyField
                                                    id={"phoneType" + idx}
                                                    className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                                    value={phone.type}
                                                    select
                                                    onChange={this.handleChange('phones', idx, 'type')}
                                                    readOnly={!editing}
                                                    error={!phone.type}
                                                >
                                                    {phoneTypes.map(type => (
                                                        <MenuItem key={type.id} value={type.id}>{type.text}</MenuItem>
                                                    ))}
                                                </AnyField>
                                                <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handlePhoneDel(idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                    : null
                                }
                                <Grid item xs={12}>
                                    <ActionButton className={classes.buttonAdd} visible={editing} onClick={this.handlePhoneAdd}>
                                        <Add className={classes.addButtonIcon} />
                                        Добавить телефон
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <AnyField
                                        id="skype"
                                        label="Skype"
                                        value={guest.skype}
                                        fullWidth
                                        onChange={this.handleChange('skype')}
                                        readOnly={!editing}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        id="email"
                                        label="Email"
                                        value={guest.email}
                                        fullWidth
                                        onChange={this.handleChange('email')}
                                        readOnly={!editing}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        id="odnoklassniki"
                                        label="Одноклассники"
                                        value={guest.odnoklassniki}
                                        fullWidth
                                        onChange={this.handleChange('odnoklassniki')}
                                        readOnly={!editing}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        id="vkontakte"
                                        label="Вконтакте"
                                        value={guest.vkontakte}
                                        fullWidth
                                        onChange={this.handleChange('vkontakte')}
                                        readOnly={!editing}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        id="facebook"
                                        label="Facebook"
                                        value={guest.facebook}
                                        fullWidth
                                        onChange={this.handleChange('facebook')}
                                        readOnly={!editing}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        id="instagram"
                                        label="Instagram"
                                        value={guest.instagram}
                                        fullWidth
                                        onChange={this.handleChange('instagram')}
                                        readOnly={!editing}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    renderSectionAddress() {
        const {t} = this.props;
        const {guest, editing, regions} = this.state;
        const sectionClass = this.getSectionClass('address');

        return (
            <ExpansionPanel className={sectionClass} expanded={this.isExpanded('address')} onChange={this.handleExpand('address')}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('address')} fontSize="large"/>}>{t("Addresses")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <SelectFromItemsField
                                items={regions}
                                nullable
                                id="regionId"
                                label={t("Region")}
                                value={guest.regionId}
                                fullWidth
                                onChange={this.handleChange('regionId')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="livingCity"
                                label={t("City of residence")}
                                value={guest.livingCity}
                                fullWidth
                                onChange={this.handleChange('livingCity')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="livingAddress"
                                label={t("Residential address")}
                                value={guest.livingAddress}
                                fullWidth
                                onChange={this.handleChange('livingAddress')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="workAddress"
                                label={t("Work address")}
                                value={guest.workAddress}
                                fullWidth
                                onChange={this.handleChange('workAddress')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="work"
                                label={t("Place of work")}
                                value={guest.work}
                                fullWidth
                                onChange={this.handleChange('work')}
                                readOnly={!editing}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    renderSectionVisit() {
        const {classes, t} = this.props;
        const {guest, editing, parlours} = this.state;
        const sectionClass = this.getSectionClass('visit');

        return (
            <ExpansionPanel className={sectionClass} expanded={this.isExpanded('visit')} onChange={this.handleExpand('visit')}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('visit')} fontSize="large"/>}>{t("Comfortable parlour to visit")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("From home")}</div></Grid>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("Travel time to salon from home")}</div></Grid>
                                {guest.convenientParloursHome ?
                                    guest.convenientParloursHome.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            <Grid item xs={6}>
                                                <AnyField
                                                    id={"convenientParlourHome" + idx}
                                                    value={item.parlourId}
                                                    className={classes.noTopMargin}
                                                    fullWidth
                                                    onChange={this.handleChange('convenientParloursHome', idx, 'parlourId')}
                                                    select
                                                    readOnly={!editing}
                                                    error={!item.parlourId}
                                                >
                                                    {parlours.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </AnyField>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <AnyField
                                                    id={"convenientParlourHomeTravelTime" + idx}
                                                    className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                                    value={item.travelTime}
                                                    fullWidth
                                                    onChange={this.handleChange('convenientParloursHome', idx, 'travelTime')}
                                                    readOnly={!editing}
                                                />
                                                <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleConvenientDel('convenientParloursHome', idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                    : null
                                }
                                <Grid item xs={12}>
                                    <ActionButton fullWidth={false} className={classes.buttonAdd} visible={editing} onClick={this.handleConvenientAdd('convenientParloursHome')}>
                                        <Add className={classes.addButtonIcon} />
                                        {t("Add parlour")}
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("From the job")}</div></Grid>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("Travel time to salon from work")}</div></Grid>
                                {guest.convenientParloursWork ?
                                    guest.convenientParloursWork.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            <Grid item xs={6}>
                                                <AnyField
                                                    id={"convenientParlourWork" + idx}
                                                    value={item.parlourId}
                                                    className={classes.noTopMargin}
                                                    fullWidth
                                                    onChange={this.handleChange('convenientParloursWork', idx, 'parlourId')}
                                                    select
                                                    readOnly={!editing}
                                                    error={!item.parlourId}
                                                >
                                                    {parlours.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </AnyField>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <AnyField
                                                    id={"convenientParlourWorkTravelTime" + idx}
                                                    className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                                    value={item.travelTime}
                                                    fullWidth
                                                    onChange={this.handleChange('convenientParloursWork', idx, 'travelTime')}
                                                    readOnly={!editing}
                                                />
                                                <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleConvenientDel('convenientParloursWork', idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                    : null
                                }
                                <Grid item xs={12}>
                                    <ActionButton fullWidth={false} className={classes.buttonAdd} visible={editing} onClick={this.handleConvenientAdd('convenientParloursWork')}>
                                        <Add className={classes.addButtonIcon} />
                                        {t("Add parlour")}
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={3}>
                            <AnyField
                                id="temporaryParlour"
                                label={t("Temporary parlour")}
                                value={guest.temporaryParlour}
                                fullWidth
                                onChange={this.handleChange('temporaryParlour')}
                                select
                                readOnly={!editing}
                            >
                                {parlours.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={9}>{/*Empty*/}</Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    renderSectionOther() {
        const {classes, t} = this.props;
        const {guest, editing, linkTypes, loyalties, knows} = this.state;
        const sectionClass = this.getSectionClass('other');

        return (
            <ExpansionPanel className={sectionClass} expanded={this.isExpanded('other')} onChange={this.handleExpand('other')}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('other')} fontSize="large"/>}>{t("Other information")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AnyField
                                id="knows"
                                label={t("How did you find out about TT")}
                                value={guest.knows}
                                fullWidth
                                onChange={this.handleChange('knows')}
                                readOnly={!editing}
                                select
                            >
                                {knows.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="knowsOther"
                                label={t("How did you find (additional)")}
                                value={guest.knowsOther}
                                fullWidth
                                onChange={this.handleChange('knowsOther')}
                                readOnly={!editing}
                                required={guest.knows === 'other'}
                                error={guest.knows === 'other' && !guest.knowsOther}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <GuestField
                                id="advice"
                                label={t("Who advised")}
                                value={guest.advice}
                                fullWidth
                                onChange={this.handleChange('advice')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="adviceType"
                                label={t("Who advised Social graph")}
                                value={guest.adviceType}
                                fullWidth
                                onChange={this.handleChange('adviceType')}
                                readOnly={!editing}
                                select
                            >
                                {linkTypes.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("Memorial date")}</div></Grid>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("Memorable date Comment")}</div></Grid>
                                {guest.memoryDates ?
                                    guest.memoryDates.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            <Grid item xs={6}>
                                                <DateField
                                                    value={item.date}
                                                    onChange={this.handleChangeDate('memoryDates', idx, 'date')}
                                                    disabled={!editing}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <AnyField
                                                    id={"memoryDatesComment" + idx}
                                                    className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                                    value={item.comment}
                                                    fullWidth
                                                    onChange={this.handleChange('memoryDates', idx, 'comment')}
                                                    readOnly={!editing}
                                                    error={!item.comment}
                                                />
                                                <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleMemoryDateDel(idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                    : null
                                }
                                <Grid item xs={12}>
                                    <ActionButton fullWidth={false} className={classes.buttonAdd} visible={editing} onClick={this.handleMemoryDateAdd}>
                                        <Add className={classes.addButtonIcon} />
                                        {t("Add date")}
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={3}>
                            <AnyField
                                id="loyalty"
                                label={t("Loyalty")}
                                value={guest.loyalty}
                                fullWidth
                                onChange={this.handleChange('loyalty')}
                                select
                                readOnly={!editing}
                                error={!guest.loyalty}
                            >
                                {loyalties.map(item => (
                                    <MenuItem key={item.id} value={item.id} >{item.text}</MenuItem>
                                ))}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="loyaltyComment"
                                label={t("Reason loyalty")}
                                value={guest.loyaltyComment}
                                fullWidth
                                onChange={this.handleChange('loyaltyComment')}
                                readOnly={!editing}
                                required
                                error={!guest.loyaltyComment}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="tags"
                                label={t("Tags")}
                                value={guest.tags}
                                fullWidth
                                onChange={this.handleChange('tags')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="comment"
                                label={t("Note")}
                                value={guest.comment}
                                fullWidth
                                onChange={this.handleChange('comment')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="agreementCallReason"
                                label={t("Reason for changing the call consent")}
                                value={guest.agreementCallReason}
                                fullWidth
                                onChange={this.handleChange('agreementCallReason')}
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel className={classes.textField}
                                              control={
                                                  <Checkbox
                                                      checked={guest.isCold}
                                                      onChange={this.handleChangeCb('isCold')}
                                                      value="isCold"
                                                  />
                                              }
                                              label={t("Cold")}
                                              disabled={!editing}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    renderSectionFavorite() {
        const {classes, t} = this.props;
        const {guest, editing, parlours, masters, procedures} = this.state;
        const sectionClass = this.getSectionClass('favorite');

        return (
            <ExpansionPanel className={sectionClass} expanded={this.isExpanded('favorite')} onChange={this.handleExpand('favorite')}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('favorite')} fontSize="large"/>}>{t("Preferences")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <div className={classes.inputLabel}>{t("Favorite master")}</div>
                            {guest.favoriteMasters ?
                                guest.favoriteMasters.map((item, idx) => (
                                    <div key={idx} className={classes.textField}>
                                        <AnyField
                                            id={"favoriteMasters" + idx}
                                            className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                            value={item.id}
                                            fullWidth
                                            onChange={this.handleChange('favoriteMasters', idx, 'id')}
                                            select
                                            readOnly={!editing}
                                        >
                                            {masters.map(item => (
                                                <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem>
                                            ))}
                                        </AnyField>
                                        <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleFavoriteDel('favoriteMasters', idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                    </div>
                                ))
                                : null
                            }
                            <ActionButton fullWidth={false} className={classes.buttonAdd} visible={editing} onClick={this.handleFavoriteAdd('favoriteMasters')}>
                                <Add className={classes.addButtonIcon} />
                                {t("Add master")}
                            </ActionButton>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.inputLabel}>{t("Unloved master")}</div>
                            {guest.notFavoriteMasters ?
                                guest.notFavoriteMasters.map((item, idx) => (
                                    <div key={idx} className={classes.textField}>
                                        <AnyField
                                            id={"notFavoriteMasters" + idx}
                                            className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                            value={item.id}
                                            fullWidth
                                            onChange={this.handleChange('notFavoriteMasters', idx, 'id')}
                                            select
                                            readOnly={!editing}
                                        >
                                            {masters.map(item => (
                                                <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem>
                                            ))}
                                        </AnyField>
                                        <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleFavoriteDel('notFavoriteMasters', idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                    </div>
                                ))
                                : null
                            }
                            <ActionButton fullWidth={false} className={classes.buttonAdd} visible={editing} onClick={this.handleFavoriteAdd('notFavoriteMasters')}>
                                <Add className={classes.addButtonIcon} />
                                {t("Add master")}
                            </ActionButton>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.inputLabel}>{t("Favorite parlour")}</div>
                            {guest.favoriteParlours ?
                                guest.favoriteParlours.map((item, idx) => (
                                    <div key={idx} className={classes.textField}>
                                        <AnyField
                                            id={"favoriteParlours" + idx}
                                            className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                            value={item.id}
                                            fullWidth
                                            onChange={this.handleChange('favoriteParlours', idx, 'id')}
                                            select
                                            readOnly={!editing}
                                        >
                                            {parlours.map(item => (
                                                <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem>
                                            ))}
                                        </AnyField>
                                        <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleFavoriteDel('favoriteParlours', idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                    </div>
                                ))
                                : null
                            }
                            <ActionButton fullWidth={false} className={classes.buttonAdd} visible={editing} onClick={this.handleFavoriteAdd('favoriteParlours')}>
                                <Add className={classes.addButtonIcon} />
                                {t("Add parlour")}
                            </ActionButton>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.inputLabel}>{t("Favorite procedure")}</div>
                            {guest.favoriteProcedures ?
                                guest.favoriteProcedures.map((item, idx) => (
                                    <div key={idx} className={classes.textField}>
                                        <AnyField
                                            id={"favoriteProcedures" + idx}
                                            className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                            value={item.id}
                                            fullWidth
                                            onChange={this.handleChange('favoriteProcedures', idx, 'id')}
                                            select
                                            readOnly={!editing}
                                        >
                                            {procedures.map(item => (
                                                <MenuItem key={item.id} value={item.id} >{item.name}</MenuItem>
                                            ))}
                                        </AnyField>
                                        <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleFavoriteDel('favoriteProcedures', idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                    </div>
                                ))
                                : null
                            }
                            <ActionButton fullWidth={false} className={classes.buttonAdd} visible={editing} onClick={this.handleFavoriteAdd('favoriteProcedures')}>
                                <Add className={classes.addButtonIcon} />
                                {t("Add procedure")}
                            </ActionButton>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    renderSectionActivity() {
        const {classes, t} = this.props;
        const {guest, editing} = this.state;
        const sectionClass = this.getSectionClass('activity');

        return (
            <ExpansionPanel className={sectionClass} expanded={this.isExpanded('activity')} onChange={this.handleExpand('activity')}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('activity')} fontSize="large"/>}>{t("Activities")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Date of the previous call")}
                                value={guest.lastCallDate}
                                fullWidth
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Date of the next call")}
                                value={guest.nextCallDate}
                                fullWidth
                                readOnly={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel className={classes.textField}
                                              control={
                                                  <Checkbox
                                                      checked={guest.interestHotHour}
                                                      onChange={this.handleChangeCb('interestHotHour')}
                                                      value="interestHotHour"
                                                  />
                                              }
                                              label={t("Is the burning hour interesting")}
                                              disabled={!editing}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="convenientHotHours"
                                label={t("Comfortable burning clock")}
                                value={guest.convenientHotHours}
                                fullWidth
                                onChange={this.handleChange('convenientHotHours')}
                                readOnly={!editing}
                                required={guest.interestHotHour}
                                error={guest.interestHotHour && !guest.convenientHotHours}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    renderSectionLink() {
        const {classes, t} = this.props;
        const {guest, editing, linkTypes} = this.state;
        const sectionClass = this.getSectionClass('link');

        return (
            <ExpansionPanel className={sectionClass} expanded={this.isExpanded('link')} onChange={this.handleExpand('link')}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('link')} fontSize="large"/>}>{t("Connect with other guests")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("Guest")}</div></Grid>
                                <Grid item xs={6} className={classes.listHeader}><div className={classes.inputLabel}>{t("Communication type")}</div></Grid>
                                {guest.guestLinks ?
                                    guest.guestLinks.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            <Grid item xs={6}>
                                                <GuestField
                                                    id={"guestLinks" + idx + "id" }
                                                    value={item.guestId}
                                                    fullWidth
                                                    onChange={this.handleChange('guestLinks', idx, 'guestId')}
                                                    readOnly={!editing}
                                                    className={classes.noTopMargin}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <AnyField
                                                    id={"guestLinks" + idx + "type" }
                                                    className={classNames(classes.noTopMargin, classes.textFieldWithButton)}
                                                    value={item.linkType}
                                                    fullWidth
                                                    onChange={this.handleChange('guestLinks', idx, 'linkType')}
                                                    select
                                                    readOnly={!editing}
                                                >
                                                    {linkTypes.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>
                                                    ))}
                                                </AnyField>
                                                <Fab color="secondary" className={classes.buttonDel} disabled={!editing} onClick={this.handleLinkDel(idx)}><ClearIcon className={classes.delButtonIcon}/></Fab>
                                            </Grid>
                                        </React.Fragment>
                                    ))
                                    : null
                                }
                                <Grid item xs={12}>
                                    <ActionButton fullWidth={false} className={classes.buttonAdd} visible={editing} onClick={this.handleLinkAdd}>
                                        <Add className={classes.addButtonIcon} />
                                        {t("Add guest")}
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    addPersonalDiscount = () => {
        const { guest } = this.state;
        let discounts = [...guest.discounts];
        discounts.push({...emptyDiscount});
        this.setState({
            guest: {
                ...guest,
                discounts: discounts
            }
        });
    };

    handleDiscountChange = (index, prop) => (event) => {
        const { guest } = this.state;
        let discounts = [...guest.discounts];
        discounts[index][prop] = moment.isMoment(event) ? event : event.target.value;
        this.setState({
            guest: {
                ...guest,
                discounts: discounts
            }
        });
    };

    handleDiscountDelete = (index) => () => {
        const { guest } = this.state;
        let discounts = [...guest.discounts];
        discounts.splice(index, 1);
        this.setState({
            guest: {
                ...guest,
                discounts: discounts
            }
        });
    };

    renderSectionDiscounts() {
        const { classes, t } = this.props;
        const { guest, editing, activeTypes, discountTypes } = this.state;
        const sectionClass = this.getSectionClass('discounts');
        const canEdit = editing && hasRole('ROLE_GUEST_GUEST_SAVE_PERSONAL_DISCOUNT');

        return (
            <ExpansionPanel className={sectionClass}>
                <ExpansionPanelSummary expandIcon={<ExpandMore className={this.getArrowClass('main')} fontSize="large"/>}>{t("Personal discounts")}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {!editing && !guest.discounts.length ? t('No discounts') : ''}
                            {guest.discounts.map( (discount, index) => (
                            <Grid container spacing={2} key={index} className={classes.discountContainer}>
                                <Grid item xs={11}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <AnyField
                                                label={t("Title")}
                                                value={discount.name}
                                                onChange={this.handleDiscountChange(index,'name')}
                                                required
                                                error={ !discount.name }
                                                fullWidth
                                                readOnly={!canEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <SelectFromItemsField
                                                items={discountTypes}
                                                value={discount.type}
                                                onChange={this.handleDiscountChange(index,'type')}
                                                label={t("Type")}
                                                fullWidth
                                                textField="text"
                                                readOnly={!canEdit}
                                                disabled={discount.type === discountTypeCustom}
                                                disabledValues={[discountTypeCustom]}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AnyField
                                                label={t("Value")}
                                                value={discount.value}
                                                onChange={this.handleDiscountChange(index,'value')}
                                                fullWidth
                                                required
                                                error={ discount.value === null }
                                                InputProps={{
                                                    inputComponent: NumberFormatDecimal,
                                                }}
                                                readOnly={!canEdit}
                                                disabled={discount.type === discountTypeCustom}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DateField
                                                label={t("Expired at")}
                                                value={discount.expiredAt}
                                                onChange={this.handleDiscountChange(index,'expiredAt')}
                                                fullWidth
                                                disabled={!canEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SelectFromItemsField
                                                items={activeTypes}
                                                value={discount.active}
                                                onChange={this.handleDiscountChange(index,'active')}
                                                label={t("Active")}
                                                fullWidth
                                                textField="text"
                                                readOnly={!canEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <AnyField
                                                label="Макс. кол-во использований одним гостем"
                                                value={discount.maxLimit}
                                                onChange={this.handleDiscountChange(index,'maxLimit')}
                                                fullWidth
                                                readOnly={!canEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CheckboxField
                                                label="Специальная"
                                                value={discount.special}
                                                onChange={this.handleDiscountChange(index,'special')}
                                                readOnly={!canEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CheckboxField
                                                label="Может применяться с дополнительным прайсом"
                                                value={discount.allowUseWithClubPrice}
                                                onChange={this.handleDiscountChange(index,'allowUseWithClubPrice')}
                                                readOnly={!canEdit}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {canEdit &&
                                <Grid item xs={1} className={classes.delContainer}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className={classes.del}>
                                            <Fab color="secondary" onClick={this.handleDiscountDelete(index)}
                                                 size={"small"}><ClearIcon/></Fab>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                            </Grid>
                            ) )}
                            {canEdit &&
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <ActionButton
                                        fullWidth={false}
                                        onClick={this.addPersonalDiscount}
                                    >
                                        <Add/>
                                        {t('Add personal discount')}
                                    </ActionButton>
                                </Grid>
                                <Grid item xs={3}>
                                    <CheckboxField
                                        className={classes.notifySmsLabel}
                                        name="notifyGuestBySms"
                                        label={t('Notify guest by sms')}
                                        value={guest.notifyGuestBySms}
                                        onChange={this.handleChange('notifyGuestBySms')}
                                    />
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    getTabComponent(key) {
        const { guest } = this.state;

        switch (key) {
            case "nalegke":
                return <ViewPageTabNalegke guest={guest} />;
            case "stats":
                return <ViewPageTabStats guest={guest} />;
            default:
                return null;
        }
    }

    renderSectionReadOnly(key, title) {
        return (
            <ExpansionPanel
                className={this.getSectionClass(key)}
                expanded={this.isExpanded(key)}
                onChange={this.handleExpand(key)}>
                <ExpansionPanelSummary expandIcon={<ExpandMore fontSize="large"/>}>
                    { title }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    { this.isExpanded(key) && this.state.guest.id !== null ? this.getTabComponent(key) : ''}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}
