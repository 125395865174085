import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    createGood,
    getCategories,
    getGood,
    getGoodPurposes,
    getGoods,
    getMaterialsForCraft, goodPurposes,
    updateGood
} from "../../services/goods";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import MassField from "../../components/fields/MassField";
import {getMeasureUnits} from "../../services/common";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import SearchField from "../../components/fields/SearchField";
import CheckboxField from "../../components/fields/CheckboxField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import HLine from "../../components/left-bar/HLine";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import CancelIcon from "@material-ui/icons/Cancel";
import infoActions from "../../components/info/info-actions";


const columnData = [
    { id: 'name', label: 'Наименование' },
    { id: 'category.name', label: 'Группа' },

    { id: 'massFormat', label: 'Количество товара'},
    { id: 'description', label: 'Описание' },
    { id: 'purposeText', label: 'Предназначение' },
    { id: 'forParlour', label: 'Расход салона' },
    { id: 'forMaster', label: 'Расход мастера' },
    { id: 'expendable', label: 'Расходный товар' },
    { id: 'active', label: 'Активен' },
    { id: 'createdAt', label: 'Создан', dateFormat: 'DD.MM.YYYY hh:mm' },
];

const emptyGood = {
    id: null,
    category: null,
    name: null,
    description: null,
    mass: {
        val: null,
        unit: 'G',
    },
    purpose: null,
    forMaster: null,
    forParlour: null,
    expendable: null,
    active: true,
    materialsForCraft: []
};

export default
@connect(null,{
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
    showError: infoActions.showError,
})
class GoodsListPage extends React.Component
{
    state = {
        good: emptyGood,
        dialogEditOpen: false,
        refreshTable: false,
        categories: [],
        units: [],
        purposes: [],
        search: {
            search: null,
        },
        showCraft: false,
        materialsForCraft: [],
        goodCategory: null,
        goodPurpose: null
    };

    componentDidMount() {
        getCategories()
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.data,
                    })
                }
            });

        getGoodPurposes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        purposes: response.data,
                    })
                }
            });

        getMeasureUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        units: response.data,
                    })
                }
            })
    }

    handleAdd = () => {
        this.setState({
            good: emptyGood,
            dialogEditOpen: true,
        })
    };

    handleCloseEditDialogCancel = () => {
        this.setState({
            dialogEditOpen: false,
            showCraft: false
        });
    }

    handleCloseEditDialogSuccess = () => {
        const {good} = this.state;

        if (good.id) {
            if ((this.state.good.materialsForCraft.length > 0 && this.state.good.id) &&
                (this.state.goodCategory !== good.category || good.purpose !== this.state.goodPurpose) &&
                !(Boolean(this.state.categories.find(c => c.id === this.state.good.category)?.craft) &&
                    this.state.good.purpose === goodPurposes.forSale)
            )
            {
                this.props.showConfirm({
                    message: 'Вы пытаетесь изменить параметр производимого товара. Все настройки расходных материалов будут сброшены',
                    title: 'Внимание',
                    onClose: (ok) => {
                        if (!ok) {
                            return;
                        }

                       this.closeGoodDialog(true);
                    },
                });
            } else {
                this.closeGoodDialog(false);
            }
        } else {
            createGood(good)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            dialogEditOpen: false,
                            refreshTable: !this.state.refreshTable,
                        });
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    closeGoodDialog = (isEmptyMaterials) => {
        updateGood({
            ...this.state.good,
            materialsForCraft: isEmptyMaterials ? [] : this.state.good.materialsForCraft
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleEdit = good => {
        this.setGood(good.id);
        this.setMaterialsForCraft(good.category.id);
    };

    setMaterialsForCraft = (categoryId, name = null) => {
        if (categoryId) {
            getMaterialsForCraft({category: categoryId})
                .then(response => {
                    if (response.success) {
                        this.setState(prev => ({
                            ...prev,
                            materialsForCraft: response.data
                        }), () => {
                            if (name === 'category') {
                                if (!this.state.good.id) {
                                    this.setState(prev => ({
                                        ...prev,
                                        good: {
                                            ...prev.good,
                                            materialsForCraft: []
                                        }
                                    }))
                                } else {
                                    if  (categoryId !== this.state.goodCategory) {
                                        this.setState(prev => ({
                                            ...prev,
                                            good: {
                                                ...prev.good,
                                                materialsForCraft: []
                                            }
                                        }))
                                    } else {
                                        getGood(this.state.good.id)
                                            .then(response => {
                                                if (response.success) {
                                                    this.setState(prev => ({
                                                        ...prev,
                                                        good: {
                                                            ...response.data,
                                                            category: response.data.category.id
                                                        },
                                                    }))
                                                } else {
                                                    this.props.showMessage(response.error ? response.error.message : response.message);
                                                }
                                            });
                                    }
                                }
                            }
                        })
                    }
                });
        }
    }

    setGood = (goodId) => {
        if (goodId) {
            getGood(goodId)
                .then(response => {
                    if (response.success) {
                        let good = response.data;

                        this.setState({
                            good: {
                                ...good,
                                category: good.category.id
                            },
                            dialogEditOpen: true,
                            showCraft: good.purpose === goodPurposes.forSale &&
                                Boolean(this.state.categories.find(c => c.id === good.category.id)?.craft),
                            goodCategory: good.category.id,
                            goodPurpose: good.purpose
                        })
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    }

    handleChange = event => {
        let value = event.target.value;
        let name = event.target.name;
        if (name === 'unit') {
            value = {
                val: 1,
                unit: value
            };
            name = 'mass';
        }

        this.setState({
            good: {
                ...this.state.good,
                [name]: value,
            }
        }, () => {
            if (name === 'expendable' && value) {
                this.setState({
                    good: {
                        ...this.state.good,
                        purpose: 'for_use',
                        materialsForCraft: []
                    }
                }, this.setShowCraft);
            }

            if (name !== 'expendable') {
                this.setShowCraft()
            }

            this.setMaterialsForCraft(this.state.good.category, name);
        });
    };

    setShowCraft = () => {
        this.setState(prev => ({
            ...prev,
            showCraft: Boolean(this.state.categories.find(c => c.id === this.state.good.category)?.craft) &&
                this.state.good.purpose === goodPurposes.forSale,
        }))
    }

    handleRefresh = () => {
        this.setState({
            refreshTable: !this.state.refreshTable,
        })
    }

    handleChangeSearch = event => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value,
            }
        })
    }

    getDataTable = (...params) => {
        const { search } = this.state;
        return getGoods(search, ...params);
    }

    addMaterialForCraft = () => {
        this.setState(prev => ({
            ...prev,
            good: {
                ...prev.good,
                materialsForCraft: [
                    ...prev.good.materialsForCraft,
                    {
                        id: null,
                        count: 1
                    }
                ]
            }
        }))
    }

    goodMaterialsForCraftChange = index => event => {
        if (this.state.good.materialsForCraft.find(mc => mc.id === event.target.value)) {
            this.props.showError('Данный товар уже есть в списке материалов');
            return;
        }

        const goodId = event.target.value;
        if (goodId) {
            let material = this.state.materialsForCraft.find(item => item.id === goodId);

            const addedMaterial = {id: material?.id, count: 1};

            let indexMaterial = this.state.good.materialsForCraft[index];
            if (indexMaterial) {
                const materialsForCraft = this.state.good.materialsForCraft;

                materialsForCraft[index] = addedMaterial;

                this.setState(prev => ({
                    ...prev,
                    good: {
                        ...prev.good,
                        materialsForCraft: materialsForCraft
                    }
                }))
            }
        }
    }

    goodMaterialsForCraftChangeCount = index => event => {
        if (event.target.value < 1) {
            return;
        }

        let materialsForCraft = this.state.good.materialsForCraft;

        materialsForCraft[index].count = event.target.value;

        this.setState(prev => ({
            ...prev,
            good: {
                ...prev.good,
                materialsForCraft: materialsForCraft
            }
        }))
    }

    goodMaterialsForCraftRemove = (goodId) => () => {
        const { materialsForCraft } = this.state.good;

        const index = materialsForCraft.findIndex(function(item) {
            return item.id === goodId;
        });

        if (index !== -1) {
            materialsForCraft.splice(index, 1);
        }

        this.setState(prev => ({
            ...prev,
            good: {
                ...prev.good,
                materialsForCraft: materialsForCraft
            }
        }))
    }

    render() {
        const { dialogEditOpen, good, refreshTable, categories, units, purposes, search, showCraft } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        name="search"
                        value={search.search}
                        onClick={this.handleRefresh}
                        onChange={this.handleChangeSearch}
                    />
                    <SelectFromItemsField
                        items={categories}
                        value={search.category}
                        name="category"
                        onChange={this.handleChangeSearch}
                        label="Категория"
                        fullWidth
                        nullable
                        nullableText="Все"
                    />
                    <SelectFromItemsField
                        label="Предназначение"
                        value={search.purpose}
                        name="purpose"
                        onChange={this.handleChangeSearch}
                        fullWidth
                        items={purposes}
                        textField="text"
                        nullable
                        nullableText="Все"
                    />
                    <ActionButton onClick={this.handleRefresh}>Отфильтровать</ActionButton>
                    <HLine/>
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Товары">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getDataTable}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialogCancel}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Карточка товара</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SelectFromItemsField
                                    label="Группа товаров"
                                    value={good.category}
                                    name="category"
                                    onChange={this.handleChange}
                                    fullWidth
                                    error={!good.category}
                                    required
                                    items={categories}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Наименование товара"
                                    value={good.name}
                                    name="name"
                                    onChange={this.handleChange}
                                    required
                                    error={!good.name}
                                    fullWidth
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Описание товара"
                                    value={good.description}
                                    name="description"
                                    onChange={this.handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                { good.expendable ?
                                    <SelectFromItemsField
                                        items={units}
                                        label="Единица измерения"
                                        name="unit"
                                        value={good.mass.unit}
                                        onChange={this.handleChange}
                                        nullableText={'Не выбрано'}
                                        valueField="id"
                                        textField="text"
                                        nullable
                                        fullWidth
                                    />
                                    :
                                    <MassField
                                        label="Количество товара"
                                        value={good.mass}
                                        name="mass"
                                        units={units}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <SelectFromItemsField
                                    label="Предназначение"
                                    value={good.purpose}
                                    name="purpose"
                                    onChange={this.handleChange}
                                    fullWidth
                                    items={purposes}
                                    textField="text"
                                    readOnly={good.expendable}
                                    nullable
                                    nullableText="Все"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxField
                                    label="Расходный товар (срок годности не указывается)"
                                    value={good.expendable}
                                    name="expendable"
                                    margin="none"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CheckboxField
                                    label="Расход мастера"
                                    value={good.forMaster}
                                    name="forMaster"
                                    margin="none"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CheckboxField
                                    label="Расход салона"
                                    value={good.forParlour}
                                    name="forParlour"
                                    onChange={this.handleChange}
                                    margin="none"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CheckboxField
                                    label="Активен"
                                    value={good.active}
                                    name="active"
                                    margin="none"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            {
                                showCraft &&
                                good.materialsForCraft.map((gm, index) => {
                                    return <>
                                        <Grid key={gm.id} item xs={6}>
                                            <SelectFromItemsField
                                                value={gm.id}
                                                onChange={this.goodMaterialsForCraftChange(index)}
                                                items={this.state.materialsForCraft}
                                                name="id"
                                                fullWidth
                                                required
                                                label="Материал"
                                            />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}>
                                            <AnyField
                                                label='Кол-во'
                                                onChange={this.goodMaterialsForCraftChangeCount(index)}
                                                value={Number(gm.count)}
                                                name="count"
                                                fullWidth
                                                type="number"
                                                onKeyPress={event => {
                                                    if (event.key === ',') {
                                                        event.preventDefault()
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                style={{marginTop: 16.5}}
                                                key="cancel-paste"
                                                onClick={this.goodMaterialsForCraftRemove(gm.id)}
                                                color="inherit"
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                    </>
                                })
                            }
                            {
                                showCraft &&
                                (
                                    <Grid container>
                                       <Grid item xs={12}>
                                           <ActionButton onClick={this.addMaterialForCraft}>+ Добавить материалы</ActionButton>
                                       </Grid>
                                   </Grid>
                                )
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseEditDialogSuccess}
                            disabled={!good.name ||
                                !good.category ||
                                (!good.mass.val && good.purpose === goodPurposes.forSale) ||
                                (!good.mass.val && good.purpose === goodPurposes.forUse) ||
                                (!good.mass.val && good.purpose === null) ||
                                !good.mass.unit}
                            color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialogCancel} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
}
