import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import DataTableHead from "./DataTableHead";
import DataTableCell from "./DataTableCell";
import ActionButton from "../button/ActionButton";
import _ from "lodash";
import SubdirectoryArrowRight from "@material-ui/icons/SubdirectoryArrowRight";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import cn from 'classnames';
import LinearProgress from "@material-ui/core/LinearProgress";
import {loadTlDataTableParams, saveTlDataTableParams} from "../../services/localStorage";
import {withTranslation} from "react-i18next";

export default
@withStyles(theme => ({
    root: {
        width: '100%',
        marginTop:  theme.spacing(1),
        borderColor: theme.palette.grey[400],
        borderWidth: 1,
        borderStyle: 'solid',
        position: 'relative',
    },
    tableWrapper: {
        overflowX: 'auto',
        position: 'relative',
    },
    tableRow: {
        height: 32,
    },
    tableRowSummary: {
        fontWeight: 'bold',
    },
    borderBottom: {
        borderBottomColor: theme.palette.grey[400],
    },
    checkbox: {
        height: 32,
    },
    error: {
        color: theme.palette.error.main,
        padding:  theme.spacing(2),
    },
    columnSubdirectory: {
        fontSize: 16,
        textAlign: 'center',
        padding: '0 5px',
        '& svg': {
            fontSize: 16,
        }
    },
    loading: {
        display: 'none',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0,0.2)',
    },
    loadingShow: {
        display: 'block',
    },
    progress: {
        height: 2,
    },
    stickyLeftColumn: {
        position: "sticky",
        left: 0,
        background: "white"
    },
    stickyContainer: {
        height: '80vh'
    }
}))
@withTranslation()
class DataTable extends React.PureComponent
{
    static propTypes = {
        columnData: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string,
            numeric: PropTypes.bool,
            disablePadding: PropTypes.bool,
            minWidth: PropTypes.any,
            linkTemplate: PropTypes.func,
            onClick: PropTypes.func,
            multiline: PropTypes.bool,
            processValue: PropTypes.func,
            parentProcessValue: PropTypes.func,
            files: PropTypes.bool,
            color: PropTypes.any,
            colorField: PropTypes.string,
            dateFormat: PropTypes.string,
            groupStrategy: PropTypes.func,
            summaryGroupStrategy: PropTypes.func,
            disableSorting: PropTypes.bool,
        })).isRequired,
        dataFunc: PropTypes.func,
        onSelect: PropTypes.func,
        refresh: PropTypes.bool,
        selector: PropTypes.bool,
        onTotalChange: PropTypes.func,
        selected: PropTypes.array,
        page: PropTypes.number,
        linkTemplate: PropTypes.func,
        onDataFetch: PropTypes.func,
        order: PropTypes.oneOf(['asc', 'desc']),
        orderBy: PropTypes.string,
        onClick: PropTypes.func,
        pagination: PropTypes.bool,
        resetSelectedOnRefresh: PropTypes.bool,
        disableSorting: PropTypes.bool,
        rowStyleFunc: PropTypes.func,
        groupBy: PropTypes.string,
        selectOnlyParent: PropTypes.bool,
        numeration: PropTypes.bool,
        summary: PropTypes.bool,
        summaryTop: PropTypes.bool,
        groupHiddenColumns: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            groupStrategy: PropTypes.func,
            summaryGroupStrategy: PropTypes.func,
        })),
        autoload: PropTypes.bool,
        fixedOrderGroupValues: PropTypes.array,
        fixedOrderGroupKey: PropTypes.string,
        stickyHeader: PropTypes.bool,
        stickyLeftColumn: PropTypes.bool,
        loading: PropTypes.bool,
    }

    static defaultProps = {
        refresh: false,
        selector: true,
        pagination: true,
        resetSelectedOnRefresh: true,
        groupBy: null,
        selectOnlyParent: false,
        numeration: false,
        summary: false,
        summaryTop: false,
        groupHiddenColumns: [],
        autoload: true,
        fixedOrderGroupValues: [],
        fixedOrderGroupKey: null,
        stickyHeader: false,
        stickyLeftColumn: false,
        loading: false,
    }

    constructor(props) {
        super(props);

        const params = loadTlDataTableParams();
        this.state = {
            order: props.order ? props.order : 'asc',
            orderBy: props.orderBy ? props.orderBy : props.columnData[0].id,
            selected: props.selected ? props.selected : [],
            page: props.page ? props.page : 0,
            rowsPerPage: params && params.rows ? params.rows : 30,
            total: 0,
            data: [],
            fetchingError: null,
            loading: false,
            lastSelectIdx: null,

            // groups
            openedGroups: {},
            summaryRow: {},
        };
    }

    componentDidMount() {
        if(this.props.autoload) {
            this.loadData(this.state.page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
        }
    }

    handleRepeat = () => {
        this.loadData(this.state.page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { refresh, selected, resetSelectedOnRefresh } = this.props;
        const { page } = this.state;

        if (prevProps.refresh !== refresh) {
            if (resetSelectedOnRefresh) {
                this.setState({selected: []});
                if (this.props.onSelect) this.props.onSelect([]);
            }
            this.setState({page: 0}, () => this.loadData(this.state.page, this.state.rowsPerPage, this.state.order, this.state.orderBy))
        }

        if (prevProps.selected !== selected) {
            this.setState({ selected });
        }

        if (this.props.page !== undefined && this.props.page !== prevProps.page && this.props.page !== page) {
            this.setState({ page: this.props.page });
        }
    }

    isDependenciesNotLoaded = () => {
        return this.props.loading;
    }

    loadData = (page, rowsPerPage, order, orderBy) => {
        const { dataFunc, onTotalChange, onDataFetch, groupBy, selectOnlyParent} = this.props;

        this.setState({
            loading: true,
        });

        dataFunc(page + 1, rowsPerPage, order, orderBy)
            .then(response => {
                let total = 0;
                if (response.success) {
                    if (groupBy) {
                        const [data, openedGroups, summaryRow] = this.groupRows(response.data);
                        total = selectOnlyParent ? Object.keys(openedGroups).length : data.length;
                        this.setState({
                            data,
                            fetchingError: null,
                            loading: false,
                            openedGroups: openedGroups,
                            summaryRow,
                            total,
                        });
                    } else {
                        total = response.meta && response.meta.pagination ? response.meta.pagination.total : -1;
                        this.setState({
                            data: response.data,
                            total,
                            fetchingError: null,
                            loading: false,
                        });
                    }
                } else {
                    this.setState({
                        data: [],
                        total,
                        fetchingError: response.error ? response.error.message : response.message,
                        loading: false,
                    })
                }

                if (onDataFetch) onDataFetch(response);
                if (onTotalChange) onTotalChange(total);
            });
    };

    groupRows = (rows) => {
        const {
            groupBy,
            columnData,
            summary,
            t,
            groupHiddenColumns,
            fixedOrderGroupValues,
            fixedOrderGroupKey
        } = this.props;
        const groupedRows = {};
        const openedGroups = {};
        const summaryRow = { isParentRow: true };
        const summaryText = t('Summary');

        const columnDataAll = [...columnData, ...groupHiddenColumns];

        if (!columnDataAll.find(col => col.id === groupBy)) {
            columnDataAll.push({ id: groupBy });
        }

        rows.forEach(row => {
            let key = row[groupBy];
            if ( !groupedRows[key] ) {
                groupedRows[key] = {
                    rows: [],
                    isParentRow: true
                };
                openedGroups[key] = false;
            }

            groupedRows[key].rows.push(row);

            columnDataAll.forEach(col => {
                if (col.id === groupBy) {
                    groupedRows[key][col.id] = row[col.id];
                    if (summary) {
                        if (col.summaryGroupStrategy) {
                            const summaryGroupFunc = col.summaryGroupStrategy;
                            summaryRow[col.id] = summaryGroupFunc(summaryRow[col.id], row);
                        } else {
                            summaryRow[col.id] = summaryText;
                        }
                    }
                    return;
                }

                if (col.groupStrategy) {
                    const groupFunc = col.groupStrategy;
                    groupedRows[key][col.id] = groupFunc(groupedRows[key][col.id], row);
                } else {
                    groupedRows[key][col.id] = row[col.id];
                }

                if (summary) {
                    let groupFunc;
                    if (col.summaryGroupStrategy) {
                        groupFunc = col.summaryGroupStrategy;
                    } else if (col.groupStrategy) {
                        groupFunc = col.groupStrategy;
                    }

                    if (groupFunc) {
                        summaryRow[col.id] = groupFunc(summaryRow[col.id], row);
                    } else {
                        summaryRow[col.id] = row[col.id];
                    }
                }
            });
        });

        let data = _.flatMap(groupedRows, (row) => {
            return [row, ...row.rows];
        });

        data.sort(this.alphabeticalGroupSort)

        if (fixedOrderGroupValues.length && fixedOrderGroupKey) {
            data.sort(this.fixedOrderGroupSort)
        }

        return [
            data,
            openedGroups,
            summaryRow
        ];
    };

    alphabeticalGroupSort = (a, b) => {
        const { groupBy } = this.props
        const valueA = a[groupBy].toUpperCase();
        const valueB = b[groupBy].toUpperCase();

        return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
    }

    fixedOrderGroupSort = (a, b) => {
        const { fixedOrderGroupValues, fixedOrderGroupKey } = this.props

        return fixedOrderGroupValues.indexOf(a[fixedOrderGroupKey]) - fixedOrderGroupValues.indexOf(b[fixedOrderGroupKey])
    }

    toggleRowGroup = (key) => (event) => {
        event.stopPropagation();
        const {openedGroups} = this.state;
        this.setState({
            openedGroups: {
                ...this.state.openedGroups,
                [key]: !openedGroups[key]
            }
        });
    };

    getAllData = () => {
        const { order, orderBy, total } = this.state;
        return this.props.dataFunc(1, total, order, orderBy)
            .then(response => {
                if (response.success) {
                    return response.data;
                }
            });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = this.state.order;

        if (this.state.orderBy === property) {
            order = order === 'desc' ? 'asc' : 'desc';
        }

        this.setState({ order, orderBy });
        this.loadData(this.state.page, this.state.rowsPerPage, order, orderBy);
    };

    handleSelectAllClick = () => {
        const { groupBy, selectOnlyParent } = this.props;
        const { data, selected } = this.state;
        if (selected && selected.length) {
            this.setState({selected: []});
            if (this.props.onSelect) this.props.onSelect([]);
        } else {
            if (groupBy) {
                let selected;
                if (selectOnlyParent) {
                    selected = data.filter(item => item.isParentRow);
                } else {
                    selected = [...data];
                }
                this.setState({selected});
                if (this.props.onSelect) this.props.onSelect(selected);
            } else {
                this.getAllData()
                    .then(data => {
                        if (data && data.length) {
                            this.setState({selected: data});
                            if (this.props.onSelect) this.props.onSelect(data);
                        }
                    });
            }
        }
    };

    handleClick = event => {
        const { selector } = this.props;
        if (!selector) {
            return;
        }

        const itemIdx = parseInt(event.currentTarget.getAttribute('itemid'));
        const { selected, data, lastSelectIdx } = this.state;
        const newSelected = [...selected];

        if (event && event.shiftKey && lastSelectIdx >= 0 && itemIdx !== lastSelectIdx) {
            const start = itemIdx > lastSelectIdx ? lastSelectIdx + 1 : itemIdx;
            const end = itemIdx > lastSelectIdx ? itemIdx : lastSelectIdx - 1;
            for (let idx = start; idx <= end; idx++) {
                const item = data[idx];
                const selectedIndex = newSelected.findIndex(sel => sel.id === item.id);
                if (selectedIndex === -1) {
                    newSelected.push(item);
                } else {
                    newSelected.splice(selectedIndex, 1);
                }
            }


            event.stopPropagation();
            event.preventDefault();
        } else {
            const item = data[itemIdx];
            const selectedIndex = newSelected.findIndex(sel => sel.id === item.id);
            if (selectedIndex === -1) {
                newSelected.push(item);
            } else {
                newSelected.splice(selectedIndex, 1);
            }
        }

        this.setState({selected: newSelected, lastSelectIdx: itemIdx});
        if (this.props.onSelect) this.props.onSelect(newSelected);
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
        this.loadData(page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
        saveTlDataTableParams({ rows: event.target.value });
        this.loadData(this.state.page, event.target.value, this.state.order, this.state.orderBy);
    };

    render() {
        const { classes, columnData, selector, linkTemplate, onClick, pagination, disableSorting, rowStyleFunc, groupBy, selectOnlyParent, numeration, summary, summaryTop, stickyHeader, stickyLeftColumn } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, total, data, fetchingError, openedGroups, loading, summaryRow } = this.state;
        let numberRows =  (page * rowsPerPage) + 1;

        return (
            <Paper className={classes.root} square>
                <div className={classes.tableWrapper}>
                    { fetchingError ?
                        <Paper className={classes.error}>
                            <div>{ fetchingError }</div>
                            <div><ActionButton fullWidth={false} onClick={this.handleRepeat}>Повторить запрос</ActionButton></div>
                        </Paper>
                        :
                        <TableContainer className={stickyHeader ? classes.stickyContainer : ''}>
                            <Table aria-labelledby="tableTitle" stickyHeader={stickyHeader}>
                                <DataTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={total}
                                    columnData={columnData}
                                    selector={selector}
                                    groupBy={!!groupBy}
                                    disableSorting={disableSorting}
                                    numeration={numeration}
                                    stickyFirstHeader={stickyLeftColumn}
                                />
                                <TableBody>
                                    {summary && summaryRow && summaryTop &&
                                    <TableRow
                                        className={cn(classes.tableRow, classes.tableRowSummary)}
                                    >
                                        <TableCell padding="checkbox" className={classes.columnSubdirectory}>
                                        </TableCell>

                                        {selector ?
                                            <TableCell padding="checkbox" className={classes.borderBottom}>
                                            </TableCell>
                                            : null
                                        }

                                        {numeration ?
                                            <TableCell padding="checkbox" className={classes.borderBottom}>
                                            </TableCell>
                                            : null
                                        }
                                        {columnData.map( (col, idx) =>
                                            <DataTableCell idx={idx} col={col} key={idx} itemRow={summaryRow} itemIdx={-1} className={classes.tableRowSummary}/>
                                        )}
                                    </TableRow>
                                    }
                                    {this.isDependenciesNotLoaded() ? null : data
                                        .map((item, itemIdx) => {
                                            const selectedItem = selected.find(sel => sel.id && sel.id === item.id);
                                            const style = rowStyleFunc ? rowStyleFunc(item, itemIdx) : null;
                                            const rowVisible = !groupBy || item.isParentRow || openedGroups[ item[groupBy] ];
                                            const hasSelector = (selector && !selectOnlyParent) || (selector && selectOnlyParent && item.isParentRow);
                                            let key = item.id ? item.id : itemIdx;
                                            if (!rowVisible) {
                                                return null;
                                            }
                                            if (groupBy && openedGroups[ item[groupBy] ]) {
                                                key = `sub_${key}_${itemIdx}`;
                                            }
                                            return (
                                                <TableRow
                                                    hover
                                                    itemID={itemIdx}
                                                    onClick={this.handleClick}
                                                    role="checkbox"
                                                    aria-checked={!!selectedItem}
                                                    tabIndex={-1}
                                                    key={key}
                                                    selected={!!selectedItem}
                                                    className={classes.tableRow}
                                                    style={style}
                                                >
                                                    {item.isParentRow &&
                                                    <TableCell padding="checkbox" className={classes.columnSubdirectory}>
                                                        {openedGroups[item[groupBy]] ?
                                                            <Remove onClick={this.toggleRowGroup(item[groupBy])}/>
                                                            :
                                                            <Add onClick={this.toggleRowGroup(item[groupBy])}/>
                                                        }
                                                    </TableCell>
                                                    }

                                                    { groupBy && !item.isParentRow ?
                                                        <TableCell padding="checkbox" className={classes.columnSubdirectory}
                                                        colSpan={selector && selectOnlyParent ? 2 : 1}>
                                                            <SubdirectoryArrowRight/>
                                                        </TableCell>
                                                    : null }

                                                    {hasSelector ?
                                                        <TableCell padding="checkbox" className={classes.borderBottom}>
                                                            <Checkbox checked={!!selectedItem} className={classes.checkbox} disableFocusRipple disableRipple disableTouchRipple/>
                                                        </TableCell>
                                                        : null
                                                    }

                                                    {numeration ?
                                                        <DataTableCell col={{id:'number'}} key={'number'} idx={0} itemRow={{number: numberRows++}}/>
                                                        : null
                                                    }

                                                    {columnData.map( (col, idx) =>
                                                        <DataTableCell
                                                            className={stickyLeftColumn && idx === 0 ? classes.stickyLeftColumn : ''}
                                                            key={idx}
                                                            idx={idx}
                                                            col={col}
                                                            itemRow={col.fromSelected ? selectedItem : item}
                                                            itemIdx={itemIdx}
                                                            linkTemplate={linkTemplate}
                                                            onClick={onClick}/>
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                    {summary && summaryRow && !summaryTop &&
                                        <TableRow
                                            className={cn(classes.tableRow, classes.tableRowSummary)}
                                        >
                                            <TableCell padding="checkbox" className={classes.columnSubdirectory}>
                                            </TableCell>

                                            {selector ?
                                                <TableCell padding="checkbox" className={classes.borderBottom}>
                                                </TableCell>
                                                : null
                                            }

                                            {numeration ?
                                                <TableCell padding="checkbox" className={classes.borderBottom}>
                                                </TableCell>
                                                : null
                                            }
                                            {columnData.map( (col, idx) =>
                                                <DataTableCell idx={idx} col={col} key={idx} itemRow={summaryRow} itemIdx={-1} className={classes.tableRowSummary}/>
                                            )}
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </div>
                {pagination ?
                    <TablePagination
                        component="div"
                        labelRowsPerPage="Записей на странице:"
                        labelDisplayedRows={({from, to, count}) => `${from}-${to} из ${count}`}
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Предыдущая страница',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Следующая страница',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 30, 50, 100, 150]}
                    />
                    : null
                }
                <div className={cn(classes.loading, {[classes.loadingShow]: loading})}><LinearProgress className={classes.progress} /></div>
            </Paper>
        );
    }
}
