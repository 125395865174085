import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {
    detailedCashlessSummaryDayReport,
    detailedCashSummaryDayReport, detailedCheckingAccountSummaryDayReport,
    generateSalableEntityLinkForDataTable, getSpendingReport,
    getSummaryDayReportNew,
    navLinks, spendingReportDateTypes, moneyFlowEntitiesLinkTemplates
} from "../../services/moneyFlow";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Typography} from "@material-ui/core";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import DateField from "../../components/fields/DateField";
import ShowField from "../../components/fields/ShowField";
import {getBusinessUnitByRole, businessUnitsRoles} from "../../services/user";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {Link} from "react-router-dom";
import {columnDataSales, salePayTypes} from "../../services/sale";
import {Check, Remove, Add} from "@material-ui/icons";
import {formatMoney} from "../../services/common";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    },
    container: {
        marginTop: 12,
        paddingBottom: 20,
        maxWidth: 1200,
        borderBottom: '1px solid #ccc',
    },
    error: {
        color: 'red',
        whiteSpace: 'pre-line',
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center'
    }
});

const columnData = [
    { id: 'evotorAmount', label: 'Эвотор', minWidth: 100 },
    { id: 'amount', label: 'Сумма', minWidth: 100 },
    { id: 'amountCheck', label: 'Сходиться', minWidth: 100, processValue: (value, t, row) => {
            if(value === 'Да') {
                return <Check style={{ color: '#4caf50' }}/>
            }
            return <Remove />
    } },
    { id: 'reasonName', label: 'Причина' },
    { id: 'visits', label: 'Визиты', linkTemplate: generateSalableEntityLinkForDataTable('visits') },
    { id: 'goodStorageSales', label: 'Продажи', linkTemplate: generateSalableEntityLinkForDataTable('goodStorageSales') },
    { id: 'paydocSales.num', label: 'Продажи ПД', linkTemplate: generateSalableEntityLinkForDataTable('paydocSales') },
    { id: 'paydocRefills.num', label: 'Пополнения ПД', linkTemplate: generateSalableEntityLinkForDataTable('paydocRefills') },
    { id: 'createdAt', label: 'Создано', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    { id: 'creator', label: 'Пользователь'},
    { id: 'comment', label: 'Комментарий'},
    { id: 'dealId', label: 'Сделка', processValue: id =>
            <a href={moneyFlowEntitiesLinkTemplates.deal + id} target="_blank" rel="noopener noreferrer">Сделка</a>
    }
];

const cashBoxSpendingColumnData = [
    { id: 'amount', label: 'Sum', minWidth: 100, processValue: formatMoney },
    { id: 'spendingType', label: 'Spending type' },
    { id: 'spendingTypeCategory', label: 'Spending type category' },
    { id: 'applyAt', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'source', label: 'Source'},
    { id: 'comment', label: 'Comment'},
];

const payDocsDeliveryColumnData = [
    { id: 'num', label: 'Номер', linkTemplate: (row) => `/pay-docs/view/${row.id}`},
    { id: 'moneyText', label: 'Тип', },
    { id: 'amount', label: 'Сумма', },
    { id: 'creator', label: 'Пользователь', },
    { id: 'createdAt', label: 'Дата', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
];

const emptyDayReport = {
    admin: [],
    day: null,
    cash: {},
    cashless: {},
    checking_account: {},
    total: {},
    evotor: {},
    resellerDebt: null,
    mastersWorkLoad: [],
    parlourGuests: {
        money: {
            free: 0,
            unfree: 0,
            total: 0,
            pd: 0
        },
        guests: {
            free: 0,
            unfree: 0,
            total: 0,
        },
        duration: {
            free: 0,
            unfree: 0,
            total: 0,
        },
        avg: {
            free: 0,
            unfree: 0,
            total: 0,
        },
        masterHourAvg: 0,
        masterHourAvgPG: 0
    },
    streetGuests: {
        money: 0,
        guests: 0,
        duration: 0,
        avg: 0,
    },
    parlourGuestsSales: {
        visits: 0,
        payDocs: 0,
        payDocRefills: 0,
        goodStorageSales: 0,
        total: 0,
        avg: 0,
        avgSales: 0,
        count: 0
    },
    streetGuestsSales: {
        visits: 0,
        payDocs: 0,
        payDocRefills: 0,
        goodStorageSales: 0,
    },
    cash_box: [],
    safe: [],
    repeaters: {},
    payDocsInDelivery: {},
    income: {},
    pdBalance: {},
    cashBoxSpendingAmounts: []
};

export default
@withStyles(styles)
@withTranslation()
@withRouter
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {})
class SummaryDayReportNew extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            day: moment(),
            businessUnitId: props.currentParlour && props.currentParlour.id,
            dayReport:  emptyDayReport,
            businessUnits: [],
            progress: false,
            error: null,
            detailedCashDialogOpen: false,
            detailedCashlessDialogOpen: false,
            detailedCheckingAccountDialogOpen: false,
            detailedCashBoxSpendingDialogOpen: false,
            detailedCashTransactions: [],
            detailedCashlessTransactions: [],
            detailedCheckingAccountTransactions: [],
            detailedCashBoxSpending: [],
            overSalesOpened: false,
            payDocsInDeliveryDialogOpen: false,
            payDocsInDeliveryDialogTitle: null,
            payDocsInDeliveryDialogData: [],
            salesDetailed: [],
            salesDetailedTitle: null,
            salesDetailedDialogOpen: false
        };

    }

    componentDidMount() {
        getBusinessUnitByRole(businessUnitsRoles.summaryDayReport)
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                        businessUnitId: response.data.length && !this.state.businessUnitId ? response.data[0].id : this.state.businessUnitId
                    }, this.getData);
                }
            });
    }


    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            [prop]: value,
        }, this.getData);
    };

    getData = () => {
        this.setState({
            progress: true,
            error: null,
        });
        getSummaryDayReportNew(this.state.day, this.state.businessUnitId)
            .then(response => {
                if (response.success) {
                    this.setState({
                        progress: false,
                        dayReport: response.data
                    });
                } else {
                    this.setState({
                        progress: false,
                        dayReport:  emptyDayReport,
                        error: response.error ? response.error.message : response.message
                    });
                }
            });
    };

    handleDetailedCashDialogOpen = () => {
        this.setState({
            progress: true,
        });

        detailedCashSummaryDayReport(this.state.day, this.state.businessUnitId)
            .then(response => {
                this.setState({
                    detailedCashDialogOpen: true,
                    progress: false,
                    detailedCashTransactions: response.data
                });
            });
    };

    handleDetailedCashlessDialogOpen = () => {
        this.setState({
            progress: true,
        });

        detailedCashlessSummaryDayReport(this.state.day, this.state.businessUnitId)
            .then(response => {
                this.setState({
                    detailedCashlessDialogOpen: true,
                    progress: false,
                    detailedCashlessTransactions: response.data
                });
            });
    };

    handleDetailedCheckingAccountDialogOpen = () => {
        this.setState({
            progress: true,
        });

        detailedCheckingAccountSummaryDayReport(this.state.day, this.state.businessUnitId)
            .then(response => {
                this.setState({
                    detailedCheckingAccountDialogOpen: true,
                    progress: false,
                    detailedCheckingAccountTransactions: response.data
                });
            });
    };

    handlePayDocsInDeliveryDialogOpen = (money, type, title) => () => {
        const { dayReport } = this.state;
        let data = [];
        if (dayReport.payDocsInDelivery.data && dayReport.payDocsInDelivery.data.length) {
            data = dayReport.payDocsInDelivery.data.filter( (row) => {
                if (type === 'today') {
                    return ( money === null || row.money === money) && row.type === type;
                } else {
                    return ( money === null || row.money === money);
                }
            });
        }
        this.setState({
            payDocsInDeliveryDialogOpen: true,
            payDocsInDeliveryDialogTitle: title,
            payDocsInDeliveryDialogData: data
        });
    };

    handleDetailedSales = (title, key) => () => {
        this.setState({
            salesDetailed: [ ...this.state.dayReport.parlourGuestsSales[key + 'Detailed'] ],
            salesDetailedTitle: title,
            salesDetailedDialogOpen: true,
        });
    };

    handleDetailedCashBoxSpendingDialogOpen = () => {
        this.setState({
            progress: true,
        });

        let search = {
            dateStart : moment(this.state.day).format("YYYY-MM-DD"),
            dateEnd : moment(this.state.day).format("YYYY-MM-DD"),
            dateType : spendingReportDateTypes.period,
            paymentTypes: [salePayTypes.cash],
            businessUnits : [this.state.businessUnitId]
        }

        getSpendingReport(search)
            .then(response => {
                this.setState({
                    detailedCashBoxSpending: response.data,
                    progress: false,
                    detailedCashBoxSpendingDialogOpen: true
                })
        });
    };

    render() {
        const { classes, t } = this.props;
        const { day, businessUnitId, businessUnits, progress, dayReport, error,
            detailedCashDialogOpen, detailedCashlessDialogOpen, detailedCashTransactions, detailedCashlessTransactions,
            payDocsInDeliveryDialogOpen, payDocsInDeliveryDialogTitle, payDocsInDeliveryDialogData,
            salesDetailed, salesDetailedTitle, salesDetailedDialogOpen,
            detailedCashBoxSpendingDialogOpen, detailedCashBoxSpending,
            detailedCheckingAccountDialogOpen, detailedCheckingAccountTransactions
        } = this.state;

        const selectedBusinessUnit = businessUnits.length && businessUnitId ? businessUnits.find(bu => bu.id === businessUnitId) : {};

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DateField
                        value={day}
                        onChange={this.handleChange('day')}
                        maxDate={moment()}
                        fullWidth
                        label={"Дата"}
                        className={classes.filterControl}
                    />
                    <AnyField
                        id="parlour"
                        label="Бизнес юнит"
                        value={businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                </LeftBar>
                <Content title="Суммарный отчет за день">
                    { error && <div className={classes.error}>{error}</div>}
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Общее</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={ this.renderWorkingAdministrators() }
                                label="Администраторы"
                                flexWrap
                                />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.day}
                                label="Дата"
                                dateFormat="DD.MM.YYYY"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Приход</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={Object.values(dayReport.cash).join("\n")}
                                multiline
                                label="Поступившая наличка"
                                onClick={ this.handleDetailedCashDialogOpen }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={Object.values(dayReport.cashless).join("\n")}
                                multiline
                                label="Поступивший безнал"
                                onClick={ this.handleDetailedCashlessDialogOpen }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={Object.values(dayReport.checking_account).join("\n")}
                                multiline
                                label="Оплата на р/c"
                                onClick={ this.handleDetailedCheckingAccountDialogOpen }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={Object.values(dayReport.total).join("\n")}
                                multiline
                                label="Общая поступившая сумма"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.cash_box.join("\n")}
                                multiline
                                label="Денег в кассе"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.safe.join("\n")}
                                multiline
                                label="Денег в сейфе"
                            />
                        </Grid>
                        {selectedBusinessUnit.type === 'external_parlour' &&
                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.resellerDebt}
                                multiline
                                label="Принято партнёром за визиты"
                            />
                        </Grid>
                        }
                        <Grid item xs={4}>
                            <ShowField
                                value={ dayReport.income.paydoc_burned && dayReport.income.paydoc_burned.join(' / ') }
                                multiline
                                label="Сгорело ПД"
                            />
                        </Grid>
                        {dayReport.pdBalance.balance &&
                            <Grid item xs={4}>
                                <ShowField
                                    value={`Баланс: ${dayReport.pdBalance.balance} \nПришло: ${dayReport.pdBalance.amountIn} \nУшло: ${dayReport.pdBalance.amountOut}`}
                                    multiline
                                    label="Баланс ПД"
                                />
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <ShowField
                                value={ Object.values(dayReport.cashBoxSpendingAmounts).join("\n") }
                                multiline
                                label={t("Expenses per day from the cash box")}
                                onClick={ this.handleDetailedCashBoxSpendingDialogOpen }
                            />
                        </Grid>
                    </Grid>
                    {!!dayReport.mastersWorkLoad.length &&
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Загрузка мастеров в часах (Плат / БП / Всего)</Typography>
                        </Grid>
                        {dayReport.mastersWorkLoad.map((item, idx) => (
                            <React.Fragment key={idx}>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={`${item.unfree} / ${item.free} / ${item.total}`}
                                        label={item.name}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                        <Grid item xs={12}>&nbsp;</Grid>
                        <Grid item xs={6}>
                            <ShowField
                                value={dayReport.parlourGuests.masterHourAvgPG}
                                label="Средняя стоимость часа мастера (Гости салон)"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ShowField
                                value={dayReport.parlourGuests.masterHourAvg}
                                label="Средняя стоимость часа мастера"
                            />
                        </Grid>
                    </Grid>
                    }
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Гости салон (Плат / БП / Всего)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={`${dayReport.parlourGuests.money.unfree} (${dayReport.parlourGuests.money.pd}) / ${dayReport.parlourGuests.money.free} / ${dayReport.parlourGuests.money.total}`}
                                label="Деньги (Плат (ПД) / БП / Всего)"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={`${dayReport.parlourGuests.guests.unfree} / ${dayReport.parlourGuests.guests.free} / ${dayReport.parlourGuests.guests.total}`}
                                label="Гости"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={`${dayReport.parlourGuests.duration.unfree} / ${dayReport.parlourGuests.duration.free} / ${dayReport.parlourGuests.duration.total}`}
                                label="Продолжительность"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={`${dayReport.parlourGuests.avg.unfree} / ${dayReport.parlourGuests.avg.free} / ${dayReport.parlourGuests.avg.total}`}
                                label="Средний чек визита"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom className={classes.subtitle}>Допродажи ( Всего: {dayReport.parlourGuestsSales.total} / Ср.чек. гостей: {dayReport.parlourGuestsSales.avg} / Ср.чек. продаж: {dayReport.parlourGuestsSales.avgSales})&nbsp;&nbsp;&nbsp;
                                {this.state.overSalesOpened ?
                                    <Remove onClick={() => this.setState({overSalesOpened: false})}/>
                                    :
                                    <Add onClick={() => this.setState({overSalesOpened: true})}/>
                                }
                            </Typography>
                        </Grid>
                        {this.state.overSalesOpened &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={dayReport.parlourGuestsSales.visits}
                                        label="Визиты"
                                        onClick={ this.handleDetailedSales("Визиты", "visits") }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={dayReport.parlourGuestsSales.payDocs}
                                        label="ПД"
                                        onClick={ this.handleDetailedSales("ПД", "payDocs") }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={dayReport.parlourGuestsSales.payDocRefills}
                                        label="Пополнения ПД"
                                        onClick={ this.handleDetailedSales("Пополнения ПД", "payDocRefills") }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={dayReport.parlourGuestsSales.goodStorageSales}
                                        label="Товары"
                                        onClick={ this.handleDetailedSales("Товары", "goodStorageSales") }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <ShowField
                                        value={dayReport.parlourGuestsSales.count}
                                        label="Всего продаж"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Гости улица</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.streetGuests.money}
                                label="Деньги"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.streetGuests.guests}
                                label="Гостей"
                            />
                        </Grid>
                        {/*<Grid item xs={3}>*/}
                        {/*    <ShowField*/}
                        {/*        value={dayReport.streetGuests.duration}*/}
                        {/*        label="Продолжительность"*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.streetGuests.avg}
                                label="Средний чек визита"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Продажи улица</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.streetGuestsSales.visits}
                                label="Визиты"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.streetGuestsSales.payDocs}
                                label="ПД"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.streetGuestsSales.payDocRefills}
                                label="Пополнения ПД"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.streetGuestsSales.goodStorageSales}
                                label="Товары"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Повторники (план / факт)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.repeaters.repeatersCount + ' / ' + dayReport.repeaters.repeatersCountFact}
                                label="Гости"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.repeaters.repeatersVisitCount + ' / ' + dayReport.repeaters.repeatersVisitCountFact}
                                label="Визиты"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.repeaters.repeatersVisitDuration + ' / ' + dayReport.repeaters.repeatersVisitDurationFact}
                                label="Продолжительность"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.repeaters.repeatersVisitTotalFormatted + ' / ' + dayReport.repeaters.repeatersVisitTotalFactFormatted}
                                label="Деньги"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ShowField
                                value={dayReport.repeaters.repeatersConversion}
                                label="Конверсия"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.repeaters.allGuestsCount}
                                label="Всего гостей было (не инкогнито)"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.repeaters.repeatersFutureCount}
                                label="Записалось дальше"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ShowField
                                value={dayReport.repeaters.repeatersFutureConversion}
                                label="Конверсия записей"
                            />
                        </Grid>
                    </Grid>


                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>ПД в доставке</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.payDocsInDelivery.totalCash}
                                label="Всего в доставке (Нал)"
                                onClick={ this.handlePayDocsInDeliveryDialogOpen('cash', 'total', 'Всего в доставке (Нал)') }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.payDocsInDelivery.totalCashless}
                                label="Всего в доставке (Безнал)"
                                onClick={ this.handlePayDocsInDeliveryDialogOpen('cashless', 'total', 'Всего в доставке (Безнал)') }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.payDocsInDelivery.total}
                                label="Всего в доставке (Всего)"
                                onClick={ this.handlePayDocsInDeliveryDialogOpen(null, 'total', 'Всего в доставке (Всего)') }
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.payDocsInDelivery.todayCash}
                                label="Оформлено сегодня (Нал)"
                                onClick={ this.handlePayDocsInDeliveryDialogOpen('cash', 'today', 'Оформлено сегодня (Нал)') }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.payDocsInDelivery.todayCashless}
                                label="Оформлено сегодня (Безнал)"
                                onClick={ this.handlePayDocsInDeliveryDialogOpen('cashless', 'today', 'Оформлено сегодня (Безнал)') }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ShowField
                                value={dayReport.payDocsInDelivery.today}
                                label="Оформлено сегодня (Всего)"
                                onClick={ this.handlePayDocsInDeliveryDialogOpen(null, 'today', 'Оформлено сегодня (Всего)') }
                            />
                        </Grid>

                    </Grid>

                </Content>
                <ProgressDialog open={progress}/>

                <Dialog
                    open={detailedCashDialogOpen}
                    onClose={ () => { this.setState({ detailedCashDialogOpen: false }) } }
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle>Детализация поступившей налички</DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle2" gutterBottom>Сумма эватор: {dayReport.evotor ? formatMoney(dayReport.evotor.cash) : null}</Typography>
                        <DataTableLocal
                            columnData={columnData}
                            data={detailedCashTransactions}
                            selector={false}
                            pagination={false}
                            disableSorting
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ () => { this.setState({ detailedCashDialogOpen: false }) } } color="primary">{t("Close")}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={detailedCashlessDialogOpen}
                    onClose={ () => { this.setState({ detailedCashlessDialogOpen: false }) } }
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle>Детализация поступившего безнала</DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle2" gutterBottom>Сумма эватор: {dayReport.evotor ? formatMoney(dayReport.evotor.cashless) : null}</Typography>
                        <DataTableLocal
                            columnData={columnData}
                            data={detailedCashlessTransactions}
                            selector={false}
                            pagination={false}
                            disableSorting
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ () => { this.setState({ detailedCashlessDialogOpen: false }) } } color="primary">{t("Close")}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={detailedCheckingAccountDialogOpen}
                    onClose={ () => { this.setState({ detailedCheckingAccountDialogOpen: false }) } }
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle>Оплата на р/с</DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle2" gutterBottom>Сумма эватор: {dayReport.evotor ? formatMoney(dayReport.evotor.cashless) : null}</Typography>
                        <DataTableLocal
                            columnData={columnData}
                            data={detailedCheckingAccountTransactions}
                            selector={false}
                            pagination={false}
                            disableSorting
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ () => { this.setState({detailedCheckingAccountDialogOpen : false }) } } color="primary">{t("Close")}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={payDocsInDeliveryDialogOpen}
                    onClose={ () => { this.setState({ payDocsInDeliveryDialogOpen: false }) } }
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle>{payDocsInDeliveryDialogTitle}</DialogTitle>
                    <DialogContent>
                        <DataTableLocal
                            columnData={payDocsDeliveryColumnData}
                            data={payDocsInDeliveryDialogData}
                            selector={false}
                            pagination={false}
                            linkTemplate={(row) => `/pay-docs/view/${row.id}`}
                            disableSorting

                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ () => { this.setState({ payDocsInDeliveryDialogOpen: false }) } } color="primary">{t("Close")}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={salesDetailedDialogOpen}
                    onClose={ () => { this.setState({ salesDetailedDialogOpen: false }) } }
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle>{salesDetailedTitle}</DialogTitle>
                    <DialogContent>
                        <DataTableLocal
                            columnData={columnDataSales}
                            data={salesDetailed}
                            selector={false}
                            pagination={false}
                            disableSorting
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ () => { this.setState({ salesDetailedDialogOpen: false }) } } color="primary">{t("Close")}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={detailedCashBoxSpendingDialogOpen}
                    onClose={ () => { this.setState({ detailedCashBoxSpendingDialogOpen: false }) } }
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle>{t('Detailing of expenses from the cash desk')}</DialogTitle>
                    <DialogContent>
                        <DataTableLocal
                            columnData={cashBoxSpendingColumnData}
                            data={detailedCashBoxSpending}
                            selector={false}
                            pagination={false}
                            disableSorting
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={ () => { this.setState({ detailedCashBoxSpendingDialogOpen: false }) } }
                            color="primary"
                        >
                            {t("Close")}
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }

    renderWorkingAdministrators() {
        const { dayReport } = this.state;

        if (!dayReport.admin.length) {
            return null;
        }

        return (
            dayReport.admin.map((employee, index) => ( <React.Fragment key={index}>{index !== 0 ? ', ' : ''}<Link to={ '/organization/masters/view/' + employee.id}>{employee.name}</Link></React.Fragment> ))
        );
    }
}
