import {dataTableGroupValueStrategy} from "../../../../../services/common";

const incomeGoods = (currentVal, row) => {
    return {
        goods_cash: dataTableGroupValueStrategy.arrayMoneySum('goods_cash')(
            currentVal ? currentVal.goods_cash : null,
            row.income
        ),
        goods_cashless: dataTableGroupValueStrategy.arrayMoneySum('goods_cashless')(
            currentVal ? currentVal.goods_cashless : null,
            row.income
        ),
        goods_paydoc_own: dataTableGroupValueStrategy.arrayMoneySum('goods_paydoc_own')(
            currentVal ? currentVal.goods_paydoc_own : null,
            row.income
        ),
        goods_paydoc_other: dataTableGroupValueStrategy.arrayMoneySum('goods_paydoc_other')(
            currentVal ? currentVal.goods_paydoc_other : null,
            row.income
        ),
        details: dataTableGroupValueStrategy.appendArray('details')(
            currentVal ? currentVal.details: [],
            row.income
        ),
    };
};

export default incomeGoods;
