import React, {useState} from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    deleteClubSubscriptionTypes,
    getClubSubscriptionTypes,
    navLinks,
    updateOrCreateSubscriptionType
} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import SearchField from "../../components/fields/SearchField";
import {withTranslation} from "react-i18next";
import EditDialog from "../../components/dialogs/EditDialog";
import {formatMoney} from "../../services/common";
import infoActions from "../../components/info/info-actions";


const columnData = [
    {id: 'name', label: 'GuestClub.Name of subscription'},
    {
        id: 'price',
        label: 'GuestClub.Payment per month',
        type: 'money',
        processValue: (value) => value && value.val ? formatMoney(value) : null
    },
    {id: 'active', label: 'GuestClub.Is active subscription', type: 'bool'}
]

const emptySubscriptionType = {
    id: null,
    name: '',
    price: null,
    active: false
}


function ClubSubscriptionTypesPage(props) {
    const {t, showError, showInfo} = props;

    const [state, setState] = useState({
        subscriptionType: emptySubscriptionType,
        search: '',
        refreshTable: false,
        openDialog: false
    });




    const handleChange = (prop) => event => {
        let value = typeof event?.target?.value !== "undefined" ? event.target.value : event;
        setState(prev => ({
            ...prev,
            [prop]: value
        }))
    }



    const handleSubscriptionTypeChange = (prop) => event => {
        let value = typeof event?.target?.value !== "undefined" ? event.target.value : event;

        setState(prev => ({
            ...prev,
            subscriptionType: {
                ...prev.subscriptionType,
                [prop]: value
            }
        }))
    }

    const openAddSubscriptionTypeDialog = () => {
        setState(prev => ({
            ...prev,
            openDialog: true,
            subscriptionType: emptySubscriptionType
        }))
    };

    const openEditSubscriptionTypeDialog = subscriptionType => {
        setState(prev => ({
            ...prev,
            openDialog: true,
            subscriptionType: subscriptionType
        }))
    };

    const deleteSubscriptionTypeCloseDialog = ok => {
        if (ok) {
            const {subscriptionType} = state
            deleteClubSubscriptionTypes(subscriptionType.id)
                .then(response => {
                    if (response.success) {
                        setState((prev) => ({
                            ...prev,
                            refreshTable: !prev.refreshTable,
                            openDialog: false
                        }))
                        showInfo('Удалено')
                    } else
                        showError(response?.error ? response.error.message : response?.message)
                })
        }
    }

    /** Also make refresh after add, update or delete ClubSubscriptionType  */
    const handleRefreshTable = () => {
        setState(prev => ({
            ...prev,
            refreshTable: !prev.refreshTable
        }));
    }

    const closeEditSubscriptionTypeDialog = (ok) => () => {

        if (ok) {
            const {subscriptionType} = state
            updateOrCreateSubscriptionType(subscriptionType)
                .then(response => {
                    if (response.success) {
                        handleRefreshTable();
                        setState(prev => ({
                            ...prev,
                            openDialog: false,
                        }));
                    }else
                        showError(response?.error ? response.error.message : response?.message)
                })
        }else
            setState(prev => ({
                ...prev,
                openDialog: false,
            }));
    };




    const getTableData = (...params) => {
        const {search} = state
        return getClubSubscriptionTypes(search, ...params);
    }


    const {refreshTable, search, openDialog, subscriptionType} = state;


    return (
        <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <SearchField
                    value={search}
                    onChange={handleChange('search')}
                    onClick={handleRefreshTable}
                    label={t("Search")}
                />
                <br/>
                <br/>
                <ActionButton onClick={openAddSubscriptionTypeDialog}>{t("Add")}</ActionButton>
            </LeftBar>
            <Content title={t("GuestClub.Types of club subscription")}>
                <DataTable
                    columnData={columnData}
                    dataFunc={getTableData}
                    selector={false}
                    refresh={refreshTable}
                    onClick={openEditSubscriptionTypeDialog}
                />
            </Content>
            <EditDialog
                columns={columnData}
                open={openDialog}
                closeDialog={closeEditSubscriptionTypeDialog}
                values={subscriptionType}
                onChange={handleSubscriptionTypeChange}
                onDelete={deleteSubscriptionTypeCloseDialog}
            />
        </React.Fragment>

    )
}


export default withTranslation()(connect(state => ({}), {
    showMessage: messageDialogActions.show,
    showError: infoActions.showError,
    showInfo: infoActions.show
})(ClubSubscriptionTypesPage));