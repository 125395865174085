/* global KkmServer*/
import React from 'react'
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class KkmService {
    // Номер устройства. Если 0 то первое не блокированное на сервере
    device = 0

    init(showMessage) {
        this.showMessage = showMessage

        if (!window.KkmServer) {
            return false
        }

        // Функция вызываемая при выполнении команды.  Подсталяется в команды если не указанно в функции "Execute()" перым параметром
        KkmServer.DefaultSettings.funCallBack = this.defaultCallback;
        // Таймаут выполнения команды по умолчанию в сек.
        // Если 0 - то берется по дефолту 30 сек.
        // Если 1 - произойдет возврат сразу после постановки команды на выполнение. Проверить результат можно командой "GetRezult"
        KkmServer.DefaultSettings.Timeout = 30;
        // ФИО  и ИНН продавца. Подставляется в команды если в них не задано явно
        // KkmServer.DefaultSettings.CashierName = "Иванов И.И.";
        // KkmServer.DefaultSettings.CashierVATIN = "430601071197";
        // Email отправителя чеков
        // KkmServer.DefaultSettings.SenderEmail = "sochi@mama.com";
        // Установка ключа суб-лицензии по умолчанию: ВНИМАНИЕ: ключ суб-лицензии вы должны генерить у себя на сервере!!!!
        // KkmServer.DefaultSettings.KeySubLicensing = "";
    }

    defaultCallback(result) {
        let status = ''
        if (result.Status === 0) {
            status = 'Ok'
        } else if (result.Status === 1) {
            status = 'Выполняется'
        } else if (result.Status === 2) {
            status = 'Ошибка!'
        } else if (result.Status === 3) {
            status = 'Данные не найдены!'
        }

        if (this.showMessage) {
            this.showMessage({
                title: 'Результат ККМ',
                message: (
                    <>
                        <div><b>Статус:</b> {status}</div>
                        {result.Error ? <div><b>Ошибка:</b> {result.Error}</div> : null}
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>JSON ответа:</ExpansionPanelSummary>
                            <ExpansionPanelDetails><pre>{JSON.stringify(result, null, 2)}</pre></ExpansionPanelDetails>
                        </ExpansionPanel>
                    </>
                )
            })
        }
    }

    getCurrentState() {
        return new Promise(resolve => {
            if (!window.KkmServer) {
                resolve(false)
                return
            }
            KkmServer.GetDataKKT(this.device).Execute(result => {
                resolve(result?.Status === 0 && result?.Info?.Active)
            })
        })
    }

    /**
     * Печать чеков c использованием функций расширения
     * @param {string} username
     * @param {number} cash
     * @param {number} cashless
     * @param {array} items
     * @param {number} itemsType Признак предмета расчета. тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
     *                  1: "ТОВАР (наименование и иные сведения, описывающие товар)"
     *                  2: "ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
     *                  3: "РАБОТА (наименование и иные сведения, описывающие работу)"
     *                  4: "УСЛУГА (наименование и иные сведения, описывающие услугу)"
     *                  5: "СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
     *                  6: "ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
     *                  7: "ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
     *                  8: "ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
     *                  9: "ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
     *                  10: "ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
     *                  11: "АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
     *                  12: "СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
     *                  13: "ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
     *                  14: "ИМУЩЕСТВЕННОЕ ПРАВО" (передача имущественных прав)
     *                  15: "ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД"
     *                  16: "СТРАХОВЫЕ ВЗНОСЫ" (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
     *                  17: "ТОРГОВЫЙ СБОР" (суммы уплаченного торгового сбора)
     *                  18: "КУРОРТНЫЙ СБОР"
     *                  19: "ЗАЛОГ"
     * @param {boolean} refund
     * @returns {Promise<boolean>}
     */
    registerCheck( username, cash, cashless, items, itemsType = 4, refund = false) {
        return new Promise((resolve) => {
            if (!window.KkmServer) {
                resolve(false)
                return
            }
            // Подготовка данных команды, параметры (TypeCheck = 0, NumDevice = 0, InnKkm = "", CashierName = "")
            const Data = KkmServer.GetDataCheck(0, this.device, "", "", "");

            //***********************************************************************************************************
            // ПОЛЯ ПОИСКА УСТРОЙСТВА
            //***********************************************************************************************************
            // Номер устройства. Если 0 то первое не блокированное на сервере
            Data.NumDevice = this.device;
            // ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
            // Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
            Data.InnKkm = '';
            //---------------------------------------------
            // Заводской номер ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
            Data.KktNumber = '';
            // **********************************************************************************************************

            // Время (сек) ожидания выполнения команды.
            //Если За это время команда не выполнилась в статусе вернется результат "NotRun" или "Run"
            //Проверить результат еще не выполненной команды можно командой "GetRezult"
            //Если не указано или 0 - то значение по умолчанию 60 сек.
            // Поле не обязательно. Это поле можно указывать во всех командах
            Data.Timeout = 30;
            // Это фискальный или не фискальный чек
            Data.IsFiscalCheck = true;
            // Тип чека;
            // 0 – продажа;                             10 – покупка;
            // 1 – возврат продажи;                     11 - возврат покупки;
            // 8 - продажа только по ЕГАИС (обычный чек ККМ не печатается)
            // 9 - возврат продажи только по ЕГАИС (обычный чек ККМ не печатается)
            if (!refund)
                Data.TypeCheck = 0;
            else
                Data.TypeCheck = 1;
            // Не печатать чек на бумагу
            Data.NotPrint = false; //true,
            // Количество копий документа
            Data.NumberCopies = 0;
            // Продавец, тег ОФД 1021
            Data.CashierName = username;
            // ИНН продавца тег ОФД 1203
            // Data.CashierVATIN = "430601071197";

            // заготовка под количество товаров больше одного
            // if (items.length > 0)
            //     items.forEach((item, index) => {
            //         if (typeof item.count == 'undefined' || parseInt(item.count) === 0)
            //             items[index].count = 1;
            //     })

            items.forEach((item) => {
                // Добавление печати фискальной строки
                Data.AddRegisterString(
                    // НаименованиеТовара(64 символа)
                    item.title,
                    // Количество (3 знака после запятой)
                    1,
                    // ЦенаБезСкидки (2 знака после запятой)
                    item.cost,
                    // СуммаСтроки (2 знака после запятой)
                    item.cost,
                    // СтавкаНДС(0(НДС 0%), 10 (НДС 10%), 20 (НДС 20%), -1(НДС не облагается), 120 (НДС 20/120), 110 (НДС 10/110))
                    -1,
                    // Отдел
                    0,
                    // Код товара EAN13 - не обязательно
                    '',
                    // Признак способа расчета. тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
                    // 1: "ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
                    // 2: "ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)"
                    // 3: "АВАНС"
                    // 4: "ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
                    // 5: "ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
                    // 6: "ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)"
                    // 7: "ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )"
                    4,
                    // Признак предмета расчета. тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
                    // 1: "ТОВАР (наименование и иные сведения, описывающие товар)"
                    // 2: "ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
                    // 3: "РАБОТА (наименование и иные сведения, описывающие работу)"
                    // 4: "УСЛУГА (наименование и иные сведения, описывающие услугу)"
                    // 5: "СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
                    // 6: "ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
                    // 7: "ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
                    // 8: "ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
                    // 9: "ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
                    // 10: "ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
                    // 11: "АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
                    // 12: "СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
                    // 13: "ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
                    // 14: "ИМУЩЕСТВЕННОЕ ПРАВО" (передача имущественных прав)
                    // 15: "ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД"
                    // 16: "СТРАХОВЫЕ ВЗНОСЫ" (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
                    // 17: "ТОРГОВЫЙ СБОР" (суммы уплаченного торгового сбора)
                    // 18: "КУРОРТНЫЙ СБОР"
                    // 19: "ЗАЛОГ"
                    itemsType,
                    //штрих - код маркировки товара со сканера(нужно настроить сканер так чтобы не проглатывал управляющие символы)
                    //КИЗ(контрольный идентификационный знак) товарной номенклатуры Тег ОФД 1162(честный знак), можно не указывать
                    // Поддерживаются ШК:
                    // Без идентификатора экземпляра товара: EAN8, EAN13, ITF14
                    // С идентификатором экземпляра товара: GS1, ШК шуб, ШК табачной продукции., ЕГАИС-2, ЕГАИС-3
                    // "0104300943734342212413195240818240640291ffd092MDEwNDMwMDk0MzczNDM__",
                    // Единица измерения предмета расчета. Можно не указывать
                    // "пара",
                    // Цифровой код страны происхождения товара (CountryOfOrigin) в соответствии с Общероссийским классификатором стран мира 3 симв. Тег 1230
                    // "156",
                    // Регистрационный номер таможенной декларации (CustomsDeclaration) 32 симв. Тег 1231
                    // "54180656/1345865/3435625/23",
                    // Сумма акциза (ExciseAmount) с учетом копеек, включенная в стоимость предмета расчета Тег 1229
                    // 0.01,
                    // Дополнительный реквизит предмета расчета тег 1191, Только для ФФД 1.1 !
                    // ''
                );
            })

            if (cash) {
                // Наличная оплата (2 знака после запятой)
                Data.Cash = Math.round(cash * 100) / 100.0;
            }
            if (cashless) {
                // Сумма электронной оплаты (2 знака после запятой)
                Data.ElectronicPayment = Math.round(cashless * 100) / 100.0;
            }


            // Скидываем данные об агенте - т.к.у Вас невярнека ККТ не зарегистрирована как Агент.
            Data.AgentSign = null;
            Data.AgentData = null;
            Data.PurveyorData = null;
            //
            for (var i = 0; i < Data.CheckStrings.length; i++) {
                if (Data.CheckStrings[i] !== undefined && Data.CheckStrings[i].Register !== undefined) {
                    Data.CheckStrings[i].Register.AgentSign = null;
                    Data.CheckStrings[i].Register.AgentData = null;
                    Data.CheckStrings[i].Register.PurveyorData = null;
                }
            }

            // Вызов команды
            this.lastIdCommand = Data.Execute((result) => {
                if (result?.Status === 0) {
                    resolve(true)
                } else {
                    this.defaultCallback(result)
                    resolve(false)
                }
            }).IdCommand;

            // Возвращается JSON:
            //{
            //    "CheckNumber": 3,           // Номер документа
            //    "SessionNumber": 1,         // Номер смены
            //    "SessionCheckNumber": 1,    // Номер чека в смене
            //    "URL": "https://ofd-ya.ru/getFiscalDoc?kktRegId=0000000000061716&fiscalSign=839499349",
            //    "QRCode": "t=20190101T195300&s=0.03&fn=9999078900002838&i=3&fp=839499349&n=1",
            //    "Command": "RegisterCheck",
            //    "Cash": 0, // Оплачено наличными
            //    "ElectronicPayment": 3.02, // Оплачено электронноо
            //    "AdvancePayment": 0, // Оплачено предоплатой (зачетом аванса)
            //    "Credit": 0, // постоплатой(в кредит)
            //    "CashProvision": 0, // встречным предоставлением (сертификаты, др. мат.ценности)
            //    "Error": "", // Текст ошибки если была - обязательно показать пользователю - по содержанию ошибки можно в 90% случаях понять как ее устранять
            //    "Message": "", // Сообщение пользователю - Если строка не пустая - ее нужно отобразить пользователю
            //    "Status": 0, // Ok = 0, Run(Запущено на выполнение) = 1, Error = 2, NotFound(устройство не найдено) = 3, NotRun = 4
            //    "IdCommand": "dd261969-4190-1125-26cd-aaf5c213c0e3",
            //    "NumDevice": 2
            //}
        })
    }

    openShift(username, inn) {
        return new Promise((resolve) => {
            if (!window.KkmServer) {
                resolve(false)
                return
            }

            let commandUniqId = Math.floor(Math.random() * 1000) + "openshift";

            const Data = KkmServer.GetDataCheck(0, this.device, "", "", "");

            Data.Command = "OpenShift";
            // Номер устройства. Если 0 то первое не блокированное на сервере
            Data.NumDevice = this.device;
            // Id устройства. Строка. Если = "" то первое не блокированное на сервере
            Data.IdDevice =  "";
            // Продавец, тег ОФД 1021
            Data.CashierName = username;
            // ИНН продавца тег ОФД 1203
            // Data.CashierVATIN = inn;
            // Не печатать чек на бумаг
            Data.NotPrint = false;
            // Уникальный идентификатор команды. Любая строока из 40 символов - должна быть уникальна для каждой подаваемой команды
            // По этому идентификатору можно запросить результат выполнения команды
            Data.IdCommand = commandUniqId;

            // Возвращается JSON:
            // {
            //    "CheckNumber": 1,    // Номер документа
            //    "SessionNumber": 23, // Номер смены
            //    "QRCode": "t=20170904T141100&fn=9999078900002287&i=108&fp=605445600",
            //    "Command": "OpenShift",
            //    "Error": "",  // Текст ошибки если была - обязательно показать пользователю - по содержанию ошибки можно в 90% случаях понять как ее устранять
            //    "Status": 0   // Ok = 0, Run(Запущено на выполнение) = 1, Error = 2, NotFound(устройство не найдено) = 3, NotRun = 4
            // }

            // Вызов команды
            this.lastIdCommand = Data.Execute((result) => {
                if (result?.Status === 0) {
                    resolve(true)
                } else {
                    this.defaultCallback(result)
                    resolve(false)
                }
            }).IdCommand;

        })
    }

    closeShift(username, inn) {
        return new Promise((resolve) => {
            if (!window.KkmServer) {
                resolve(false)
                return
            }

            let commandUniqId = Math.floor(Math.random() * 1000) + '_close';

            const Data = KkmServer.GetDataCheck(0, this.device, "", "", "");

            Data.Command = "CloseShift";
            // Номер устройства. Если 0 то первое не блокированное на сервере
            Data.NumDevice = this.device;
            // Id устройства. Строка. Если = "" то первое не блокированное на сервере
            Data.IdDevice =  "";
            // Продавец, тег ОФД 1021
            Data.CashierName = username;
            // ИНН продавца тег ОФД 1203
            // Data.CashierVATIN = inn;
            // Не печатать чек на бумаг
            Data.NotPrint = false;
            // Уникальный идентификатор команды. Любая строока из 40 символов - должна быть уникальна для каждой подаваемой команды
            // По этому идентификатору можно запросить результат выполнения команды
            Data.IdCommand = commandUniqId;

            // Возвращается JSON:
            //{
            //    "CheckNumber": 1,    // Номер документа
            //    "SessionNumber": 23, // Номер смены
            //    "QRCode": "t=20170904T141100&fn=9999078900002287&i=108&fp=605445600",
            //    "Command": "CloseShift",
            //    "Error": "",  // Текст ошибки если была - обязательно показать пользователю - по содержанию ошибки можно в 90% случаях понять как ее устранять
            //    "Status": 0   // Ok = 0, Run(Запущено на выполнение) = 1, Error = 2, NotFound(устройство не найдено) = 3, NotRun = 4
            //}

            // Вызов команды
            this.lastIdCommand = Data.Execute((result) => {
                if (result?.Status === 0) {
                    resolve(true)
                } else {
                    this.defaultCallback(result)
                    resolve(false)
                }
            }).IdCommand;
        })
    }
}

export const kkmService = new KkmService()
