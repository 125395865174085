import {dataTableGroupValueStrategy} from "../../../../../services/common";

const distributionCheck = (currentVal, row) => {
    return {
        cash: dataTableGroupValueStrategy.isAllTrue('cash')(
            currentVal ? currentVal.cash : null,
            row.distributionCheck
        ),
        cashless: dataTableGroupValueStrategy.isAllTrue('cashless')(
            currentVal ? currentVal.cashless : null,
            row.distributionCheck
        )
    };
};

export default distributionCheck;