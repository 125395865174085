import baseRequest from "./baseReuqest";
import {processSearchDates} from "./moneyFlow";
import moment from "moment";

export const navLinks = [
    { path: "/call-center", text: "Поиск по времени", role: "ROLE_UI_TAB_CALLCENTER"},
    { path: "/call-center/load-parlors", text: "Загрузка салонов", role: "ROLE_UI_TAB_CALLCENTER"},
    { path: "/call-center/load-zvonobot-file", text: "Загрузить файл", role: "ROLE_UI_TAB_CALLCENTER"},
    { path: "/call-center/guests-search", text: "Guests search", role: "ROLE_CALLCENTER_GUESTSEARCH"},
];

export function timeSearch(search, page, per_page, order = 'asc', order_by = 'name') {
    processSearchDates(search);

    return baseRequest('/api/call-center/time-search', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function importZvonobot(data) {
    return baseRequest('/api/call-center/import/zvonobot', {
        method: "POST",
        body: data,
    });
}

export function zvonobotCSVRowsToDTOArray(data) {
    let keys = {
        0: {
            label: 'Номер',
            key: 'phone'
        },
        3: {
            label: 'Дата',
            key: 'date'
        },
        7: {
            label: 'Ответ',
            key: 'answer'
        }
    };

    let DTOArray = [];
    let error = false;

    data.forEach((row) => {
        let dto = {};
        row = Object.values(row);

        for (let [index, value] of Object.entries(keys)) {
            index = Number(index);

            if (typeof row[index] === 'undefined') {
                error = 'Колонка "' + value.label + '" не существует';
                return false;
            }

            dto[value.key] = row[index];
        }

        DTOArray.push(dto);
    });

    if (error) {
        return error;
    }

    return DTOArray;
}

export function guestsSearch(search, page, per_page, order = 'asc', order_by = 'name') {
    const dateFormat = 'YYYY-MM-DD';

    for (let searchProp in search) {
        if (!search.hasOwnProperty(searchProp)) {
            continue;
        }

        if (moment.isMoment(search[searchProp])) search[searchProp] = search[searchProp].format(dateFormat);

        if (typeof search[searchProp] === 'object' && search[searchProp] !== null) {
            for (let nestedProp in search[searchProp]) {
                if (!search[searchProp].hasOwnProperty(nestedProp)) {
                    continue;
                }
                if (moment.isMoment(search[searchProp][nestedProp])) search[searchProp][nestedProp] =
                    search[searchProp][nestedProp].format(dateFormat);
            }
        }
    }

    return baseRequest('/api/call-center/guests-search', {
        query: {...search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function createSegment(segment) {
    return baseRequest('/api/call-center/create-segment', {
        method: 'POST',
        body: segment
    })
}
