import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import {
    getEquipment, getEquipmentStatuses, getEquipmentTypes,
    getParlours,
    navLinks, saveEquipment,
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import { withRouter } from "react-router";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import moment from "moment";
import DateTimeField2 from "../../components/fields/DateTimeField2";
import * as cn from "classnames";
import TimeField from "../../components/fields/TimeField";
import { isValidRequiredL18nString } from "../../utils/validators";
import { loadToState } from "../../utils/helpers";

const places = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
];

const breaks = [15, 30, 45, 60];

const intervalToMinutes = (interval) => interval ? moment.duration(interval).asMinutes() : null;
const minutesToInterval = (minutes) => minutes ? moment.duration(minutes, "minutes").toISOString() : null;
const atTimeZoneUi = (dt, tz) => dt ? moment(dt).utcOffset(tz).utcOffset(moment().utcOffset(), true) : null;
const atTimeZoneBU = (dt, tz) => dt ? moment(dt).utcOffset(tz, true).toISOString() : null;

export default @withRouter
@connect(
    null
    , {
        showMessage: messageDialogActions.show,
    })
@withStyles({
    container: {
        width: 900,
        marginBottom: 10,
    },
    workTimeLabel: {
        textAlign: "center",
    },
    workTimeLabel_textLeft: {
        textAlign: "left",
    },
    blockWorkTime: {
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
})
class EquipmentViewPage extends React.Component {

    state = {
        equipment: {
            id: null,
            nameAll: {},
            type: null,
            status: null,
            businessUnitId: null,
            numberGuestsSameTime: 1,
            betweenGuestsBreak: null,
            equipmentBreakStartedAt: null,
            equipmentBreakEndedAt: null,
            workTime: null,
        },
        types: [],
        statuses: [],
        parlours: [],
        breakOptions: [],
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp("[^/]+$");
        const resultId = reId.exec(path);
        const id = resultId?.[0] || "0";

        this.setState({
            breakOptions: breaks.map(id => ({ id, name: `${id}` })),
        });

        loadToState(this, getParlours(), "parlours");
        loadToState(this, getEquipmentTypes(), "types");
        loadToState(this, getEquipmentStatuses(), "statuses");

        if (id !== "0") {
            getEquipment(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            equipment: {
                                ...response.data,
                                betweenGuestsBreak: intervalToMinutes(response.data.betweenGuestsBreak),
                                equipmentBreakStartedAt: atTimeZoneUi(response.data.equipmentBreakStartedAt, response.data.businessUnit.timeZone),
                                equipmentBreakEndedAt: atTimeZoneUi(response.data.equipmentBreakEndedAt, response.data.businessUnit.timeZone),
                            },
                        });
                    }
                });
        }
    }

    handleChange = event => {
        const prop = event.target.name;
        const value = event.target.value;

        this.setState({
            equipment: {
                ...this.state.equipment,
                [prop]: value,
            },
        });
    };

    handleChangeI18n = event => {
        const [prop, locale] = event.target.id.split("#");
        this.setState({
            equipment: {
                ...this.state.equipment,
                [prop]: {
                    ...this.state.equipment[prop],
                    [locale]: event.target.value,
                },
            },
        });
    };

    handleSave = () => {
        const { parlours, equipment } = this.state;
        const parlour = parlours.find(p => p.id === equipment.businessUnitId);

        saveEquipment({
            ...equipment,
            betweenGuestsBreak: minutesToInterval(equipment.betweenGuestsBreak),
            equipmentBreakStartedAt: atTimeZoneBU(equipment.equipmentBreakStartedAt, parlour.timeZone),
            equipmentBreakEndedAt: atTimeZoneBU(equipment.equipmentBreakEndedAt, parlour.timeZone),
        })
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/equipments`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    isSaveDisabled() {
        const { equipment } = this.state;

        return !isValidRequiredL18nString(equipment.nameAll)
            || !equipment.type
            || !equipment.status
            || !equipment.businessUnitId;
    }

    render() {
        const { classes } = this.props;
        const { equipment, types, statuses, parlours, breakOptions } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br/>
                    <br/>
                    <ActionButton onClick={this.handleSave} disabled={this.isSaveDisabled()}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Карточка оборудования салона">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#en"
                                label="Наименование, EN"
                                value={equipment.nameAll.en}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#ru"
                                label="Наименование, RU"
                                value={equipment.nameAll.ru}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="nameAll#cs"
                                label="Наименование, CS"
                                value={equipment.nameAll.cs}
                                required
                                fullWidth
                                onChange={this.handleChangeI18n}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Тип оборудования"
                                items={types}
                                textField="text"
                                value={equipment.type}
                                name="type"
                                onChange={this.handleChange}
                                required
                                error={!equipment.type}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Статус"
                                items={statuses}
                                textField="text"
                                value={equipment.status}
                                name="status"
                                onChange={this.handleChange}
                                required
                                error={!equipment.status}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Салон"
                                items={parlours}
                                value={equipment.businessUnitId}
                                name="businessUnitId"
                                onChange={this.handleChange}
                                required
                                error={!equipment.businessUnitId}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                items={places}
                                label="Количество мест"
                                value={equipment.numberGuestsSameTime}
                                name="numberGuestsSameTime"
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                items={breakOptions}
                                label="Перерыв между гостями (мин)"
                                value={equipment.betweenGuestsBreak}
                                name="betweenGuestsBreak"
                                onChange={this.handleChange}
                                fullWidth
                                nullable
                            />
                        </Grid>
                        <Grid item xs={4}/>
                        <Grid item xs={4}>
                            <DateTimeField2
                                label="Начало перерыва в оборудовании"
                                value={equipment.equipmentBreakStartedAt}
                                name="equipmentBreakStartedAt"
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeField2
                                label="Конец перерыва в оборудовании"
                                value={equipment.equipmentBreakEndedAt}
                                name="equipmentBreakEndedAt"
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="flex-start">
                        {this.renderParlourWorkTime()}
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }

    handleChangeWorkTime = (day, type) => date => {
        const { state } = this;

        let workTime = Object.values(state.equipment.workTime || {});

        if (date) {
            if (!workTime.find(wt => wt.day === day)) {
                workTime.push(date ? { day: day, [type]: date.utc(true) } : null);
            } else {
                workTime = workTime.map(wt => {
                    if (wt.day === day) {
                        wt[type] = date ? date.utc(true) : null;
                    }

                    return wt;
                });
            }
        } else {
            workTime = workTime.filter(wt => wt.day !== day.toString());
        }


        this.setState({
            ...state,
            equipment: {
                ...state.equipment,
                workTime: workTime.sort((wt1, wt2) => wt1?.day - wt2?.day),
            },
        });
    };

    renderParlourWorkTime() {
        const { classes } = this.props;
        const { equipment } = this.state;

        const workTime = Object.values(equipment.workTime ?? [])
            .sort((wt1, wt2) => wt1.day - wt2.day);

        return (
            <Grid item xs={6} className={classes.blockWorkTime}>
                <Typography variant="h6" className={classes.subheading}>Рабочее время</Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={2} className={classes.blockTitleWorkSchedule}>
                            <Grid item xs={2} className={classes.workTimeLabel}>День</Grid>
                            <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Начало
                                работы</Grid>
                            <Grid item xs={5} className={cn(classes.workTimeLabel, classes.workTimeLabel_textLeft)}>Конец
                                работы</Grid>
                        </Grid>
                    </Grid>
                    {
                        Array.apply(0, Array(7)).map((_, i) => {
                            return {
                                day: (i + 1).toString(),
                                format: moment(i, "e").startOf("week").isoWeekday(i + 1).format("dd"),
                            };
                        }).map((weekDay) => {
                            const workTimeInWeek = workTime.find(wt => wt.day === weekDay.day);

                            return (
                                <React.Fragment key={weekDay.day}>
                                    <Grid item xs={2} className={classes.workTimeLabel}>
                                        {weekDay.format}
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TimeField
                                            value={workTimeInWeek ? moment(workTimeInWeek.start, "HH:mm") : null}
                                            required
                                            onChange={this.handleChangeWorkTime(weekDay.day, "start")}
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TimeField
                                            value={workTimeInWeek ? moment(workTimeInWeek.finish, "HH:mm") : null}
                                            required
                                            onChange={this.handleChangeWorkTime(weekDay.day, "finish")}
                                            autoOk
                                        />
                                    </Grid>
                                </React.Fragment>
                            );
                        })
                    }
                </Grid>
            </Grid>
        );
    }
}
