import React from "react";
import AnyField from "./AnyField";
import * as PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import {CircularProgress, withStyles} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

function getCurrencyText(currency) {
    switch (currency) {
        case 'RUB': return '₽';
        case 'EUR': return '€';
        case 'USD': return '$';
        case 'CZK': return 'Kč';
        default: return currency;
    }
}

function NumberFormatMoney(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=" "
            decimalScale={2}
            decimalSeparator=","
            isNumericString
        />
    );
}

@withStyles({
    button: {
        padding: 5,
        marginRight: -8,
        minWidth: 0,
        textTransform: 'none'
    },
})
class MoneyField extends React.PureComponent
{
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currencies &&
            this.props.currencies.length &&
            this.props.value &&
            this.props.currencies.indexOf( this.props.value.cur ) !== -1 ) {
            return;
        }

        if (this.props.currencies &&
            this.props.currencies.length &&
            (!this.props.value || this.props.currencies.indexOf( this.props.value.cur ) === -1)
        ) {
            if (this.props.onChange) {
                this.props.onChange({
                    target: {
                        name: this.props.name,
                        value: {...this.props.value, cur: this.props.currencies[0]}
                    }
                });
            }
        }
    }

    handleClick = event => {
        if (this.props.readOnly || this.props.disableCurrencySelect) {
            return;
        }
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = (currency) => () => {
        this.setState({
            anchorEl: null,
        });
        if (currency && this.props.onChange) {
            this.props.onChange({target: {
                name: this.props.name,
                value: {...this.props.value, cur: currency}
            }});
        }
    };

    handleChange = event => {
        if (this.props.onChange) {
            let value = event.target.value !== '' ? parseFloat(event.target.value) : null;
            this.props.onChange({ target: {
                name: this.props.name,
                value: this.props.changeOnlyValue ? value : {...this.props.value, val: value}
            }});
        }
    };

    render() {
        const { classes, InputProps, onChange, value, disableCurrencySelect, updatingValue, changeOnlyValue, ...otherProps } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const currencies = this.props.currencies || (this.props.value && [this.props.value.cur]) || [];

        return (
            <AnyField
                value = {value ? value.val : null}
                InputProps={{
                    ...InputProps,
                    inputComponent: NumberFormatMoney,
                    startAdornment: updatingValue ? <InputAdornment position="start"><CircularProgress size={25}/></InputAdornment> : null,
                    endAdornment: Boolean(currencies.length) && currencies[0] && <React.Fragment>
                                        <Button onClick={this.handleClick} className={classes.button}>
                                            { value ? getCurrencyText(value.cur) : ' ' } { !this.props.readOnly && !disableCurrencySelect && <ArrowDropDown fontSize="small"/> }
                                        </Button>
                                        <Menu anchorEl={anchorEl} open={open} onClose={ this.handleClose(false) }>
                                            {currencies.map(currency => (
                                                <MenuItem key={currency} selected={value && currency === value.cur} onClick={this.handleClose(currency)}>
                                                    {getCurrencyText(currency)}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </React.Fragment>
                }}
                {...otherProps}
                onChange={this.handleChange}
            />
        );
    }
}

MoneyField.propTypes = {
    ...AnyField.propTypes,
    currencies: PropTypes.array,
    disableCurrencySelect: PropTypes.bool,
    value: PropTypes.object,
    updatingValue: PropTypes.bool,
    changeOnlyValue: PropTypes.bool,
};

MoneyField.defaultProps = {
    disableCurrencySelect: false,
    updatingValue: false,
    changeOnlyValue: false
};

export default MoneyField;