import React from 'react';
import * as PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";

export default @withTranslation()
class PayDocAskPinDialog extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
    };

    state = {
        pin: null,
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const {t, onClose} = this.props;
        const {pin} = this.state;

        return <Dialog open={true} onClose={this.handleCloseCancel} maxWidth="md">
            <DialogTitle>{t('PIN code')}</DialogTitle>
            <DialogContent>
                <AnyField value={pin} fullWidth name="pin" onChange={this.handleChange}/>
            </DialogContent>
            <DialogActions>
                <ActionButton fullWidth={false} onClick={onClose(true, pin)}>{t('OK')}</ActionButton>
                <ActionButton fullWidth={false} onClick={onClose(false)}>{t('Cancel')}</ActionButton>
            </DialogActions>
        </Dialog>
    }
}
