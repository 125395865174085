import React from 'react';
import * as cn from "classnames";
import * as PropTypes from 'prop-types';
import {durationAsHHmm, propTypeDuration, propTypeMoment} from "../../utils/moment-utils";
import {withStyles} from "@material-ui/core";
import dimensions from './ScheduleVisitsMultiDimensions';
import moment from "moment";
import Typography from "@material-ui/core/Typography";

export default
@withStyles(theme => ({
    slot: {
        zIndex: 2,
        height: dimensions.slotHeight,
    },
    beginGroup: {
        borderTop: '1px solid #777',
    },
    label: {
        paddingLeft:  theme.spacing(1),
        paddingRight:  theme.spacing(2),
    },
}))
class ScheduleVisitsMultiHeaderSlots extends React.PureComponent {
    static propTypes = {
        slots: PropTypes.arrayOf(PropTypes.shape({
            beginGroup: PropTypes.bool,
            endGroup: PropTypes.bool,
            value: propTypeDuration,
            valueEnd: propTypeDuration,
            fullValue: propTypeMoment,
            fullValueEnd: propTypeMoment,
            beginGroupValue: propTypeDuration,
            endGroupValue: propTypeDuration,
            beginGroupFullValue: propTypeMoment,
            endGroupFullValue: propTypeMoment,
        })).isRequired,
        showCurrentTime: PropTypes.bool,
    }

    static defaultProps = {
        showCurrentTime: true,
    };

    render() {
        const {
            slots,
            classes,
            showCurrentTime
        } = this.props;

        return <>
            {slots.map((slot, slotIdx) => {
                const isNow =showCurrentTime && moment().isBetween(slot.beginGroupFullValue,slot.endGroupFullValue);
                return <div
                    key={slot.key}
                    className={cn(classes.slot, {[classes.beginGroup]: slot.beginGroup})}
                >{slot.beginGroup ?
                    <Typography color={isNow ? 'primary' : 'initial'} className={classes.label}>{durationAsHHmm(slot.value)}</Typography> :
                    null
                }</div>
            })}
        </>;
    }
}