import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {getRegions} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getSafeReport, navLinks} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import {Typography} from "@material-ui/core";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import DataTableLocal from "../../components/data-table/DataTableLocal";
import _ from "lodash";
import XLSX from "xlsx";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {withTranslation} from "react-i18next";
import {
    formatArrayMoneyForExcelMultiline,
    formatArrayMoneyMultiLine
} from "../../services/common";
import CheckboxField from "../../components/fields/CheckboxField";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    table: {
        marginBottom: 30
    },
});

export default
@withStyles(styles)
@withTranslation()
class SafeReport extends React.Component {
    state = {
        search: {
            dateStart: moment(),
            dateEnd: moment(),
            regionIds: [],
            externalParlours: false
        },
        regions: [],
        columnData: [],
        data: [],
        progress: false,
    };

    componentDidMount() {
        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    });
                }
            });
    }

    handleExport = () => {
        const {search, regions} = this.state;
        const { t } = this.props;
        const from = search.dateStart;
        const to = search.dateEnd;

        const bu = search.regionIds.length ? regions.filter(item => search.regionIds.indexOf(item.id) !== -1).map(bu => bu.name).join(', ') : 'All';

        getSafeReport(search)
            .then(response => {
                if (response.success) {
                    let data = [];

                    response.data.data.forEach(bu => {
                        data.push([bu.name]);
                        data.push(response.data.columns.map(item => item.label));
                        bu.data.forEach(row => {
                            data.push(
                                response.data.columns.map(item => {
                                    const value = _.get(row, item.id);
                                    if (item.id !== 'name') {
                                        item.processValue = formatArrayMoneyForExcelMultiline;
                                    }
                                    if (item.processValue) {
                                        return item.processValue(value, t);
                                    }
                                    return value;
                                })
                            );
                        });
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Safe report");
                    XLSX.writeFile(new_workbook, `Safe report ${bu} ${from}-${to}.xlsx`);
                }
            });

    }

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleChangeState = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            [prop]: value,
        });
    };

    handleClickFilter = () => {
        this.getTableData();
    };

    getTableData = () => {
        const {search} = this.state;
        return getSafeReport(search)
            .then(response => {
                if (response.success) {
                    this.setState({
                        columnData: response.data.columns.map( col => {
                            if (col.id !== 'name') {
                                col.processValue = formatArrayMoneyMultiLine;
                            }
                            col.multiline = true;
                            return col;
                        }),
                        data: response.data.data,
                    });
                }
            });
    };

    render() {
        const { classes, t } = this.props;
        const { search, regions, columnData, data, progress } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.dateStart}
                        valueTo={search.dateEnd}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('dateStart')}
                        onChangeTo={this.handleChange('dateEnd')}
                        label={t("Date interval")}
                        className={classes.filterControl}
                    />
                    <MultiSelectFromItemsField
                        items={regions}
                        label={t('Regions')}
                        value={search.regionIds}
                        onChange={this.handleChange('regionIds')}
                        valueField="id"
                        textField="name"
                        fullWidth
                    />
                    <CheckboxField
                        value={search.externalParlours}
                        label={t('Show external parlours')}
                        onChange={this.handleChange('externalParlours')}
                        fullWidth
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Safe report')}>
                    {
                        data.map((region, index) => (
                            <React.Fragment key={index}>
                                <div className={classes.table}>
                                    <Typography variant="h6" gutterBottom>{ region.name }</Typography>
                                    <DataTableLocal
                                        columnData={columnData}
                                        data={region.data}
                                        selector={false}
                                        pagination={false}
                                        disableSorting
                                    />
                                </div>
                            </React.Fragment>
                        ))
                    }

                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
