import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {
    FormControlLabel, Radio, RadioGroup,
} from "@material-ui/core";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import {
    navLinks,
    getVisitCancelReasons, getCanceledVisitsReport,
} from "../../services/calendar";
import infoActions from "../../components/info/info-actions";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import DateField from "../../components/fields/DateField";
import {getUsers} from "../../services/user";
import DateMonthField from "../../components/fields/DateMonthField";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";

const columnData = [
    {id: 'id', label: 'Visit', processValue: value => (value || '').substring(0, 7) + '...'},
    {id: 'status', label: 'Visit status'},
    {id: 'created_at', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    {id: 'username', label: 'User'},
    {id: 'total', label: 'Cost of visit'},
    {id: 'facilities', label: 'Service'},
    {id: 'visit_start', label: 'Start of service', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    {id: 'duration', label: 'Duration'},
    {id: 'master_name', label: 'Master'},
    {id: 'guest_name', label: 'Guest'},
    {id: 'guest_phone', label: 'Guest phone'},
    {id: 'parlour_name', label: 'Parlour'},
    {id: 'visit_comment', label: 'Comment'},
    {id: 'cancel_date', label: 'Cancel date'},
    {id: 'canceled_by_user', label: 'Canceled by user'},
    {id: 'cancel_reason', label: 'Cancel reason'},
    {id: 'visit_cancel_comment', label: 'Cancel comment'},
    {id: 'is_future_visit', label: 'Is future visit'},
    {id: 'future_visit_creator', label: 'Future visit creator'},
    {id: 'future_visit_id', label: 'Future visit', linkTemplate: item => `/orders/view/${item.future_visit_id}`}
];


const columnDataXlsx = [
    ...columnData
];

const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    container: {
        marginTop: 0,
        maxWidth: 900,
        borderBottom: '1px solid #ccc',
    },
    reportVisits__item: {
        paddingTop: 0,
        paddingBottom: 8,
    },
    reportVisitsItem__title: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    reportVisitsItem__value: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    customPanel: {
        minHeight: 'unset !important',
        height: '30px !important'
    },
    titlePanel: {
        marginTop: 0,
        marginBottom: 0,
    },
    checkboxFilter: {
        marginTop: 0,
    }
});


export default @connect(null, {
    showMessage: messageDialogActions.show,
    showInfo: infoActions.show,
})
@withStyles(styles)
@withTranslation()
class ReportCanceledVisitsPage extends React.Component {


    state = {
        search: {
            search: null,
            dateType: 'period',
            date_start: moment(),
            date_end: moment(),
            month: moment(),
            businessUnitIds: [],
            departmentCreator: null,
            creators: [],
            type: null,
            departmentIds: [],
            cancelReasonsSelected: [],
            cancelDate: null,
            cancelUserIds: []
        },
        reportSummaryVisits: null,
        refreshTable: false,
        businessUnits: [{id: 0, name: this.props.t('---All---')}],
        employees: [],
        panelExpanded: false,
        departments: [],
        cancelReasons: [],
        cancelUsers: []
    };


    componentDidMount() {

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            {id: null, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });

        getVisitCancelReasons()
            .then(response => {
                if (response.success)
                    this.setState({
                        cancelReasons: response.data
                    })
            })

        getUsers()
            .then(response => {
                if (response.success)
                    this.setState({
                        cancelUsers: response.data
                    })
            })

    }


    handleChange = prop => event => {
        const {search} = this.state;
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event?.target?.value ?? null;
        }

        let newState = {
            search: {
                ...search,
                [prop]: value,
            }
        };

        this.setState(newState);

    };


    handleClickFilter = () => {
        this.setState(state => ({refreshTable: !state.refreshTable, panelExpanded: false,}));
    };

    getTableData = (...params) => {
        const {search} = this.state;


        let data = getCanceledVisitsReport(search, ...params);
        return data;
    };

    handleExport = () => {
        const {t} = this.props;
        const {search, businessUnits} = this.state;
        const from = search.date_start;
        const to = search.date_end;
        const bu = search.businessUnitIds && search.businessUnitIds.length ?
            (search.businessUnitIds.length < 3 ?
                    businessUnits.filter(item => search.businessUnitIds.indexOf(item.id) !== -1).map((parlour => parlour.name)).join(',')
                    :
                    'Many'
            )
            :
            'All';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    let columnData = [];
                    let report = [];
                    let maxCountPayDoc = response.data.reduce((count, item) => {
                        let itemCount = 0;

                        if (item.payments_paydoc_name) {
                            itemCount = item.payments_paydoc_name.split('\n').length;
                        }

                        return count >= itemCount ? count : itemCount;
                    }, 1);

                    if (maxCountPayDoc > 1) {
                        for (let key in columnDataXlsx) {
                            let itemColumn = columnDataXlsx[key];

                            if (itemColumn.id === 'payments_paydoc_name') {
                                for (let index = 0; index < maxCountPayDoc; index++) {
                                    columnData.push({label: 'pay.types.paydoc', id: 'payments_paydoc_name_' + index});
                                    columnData.push({label: 'withheldPayDoc', id: 'payments_paydoc_value_' + index});
                                    columnData.push({
                                        label: 'withheldPayDocAct',
                                        id: 'payments_paydoc_value_act_' + index
                                    });
                                }
                            } else if (['payments_paydoc_value', 'payments_paydoc_value_act'].indexOf(itemColumn.id) === -1) {
                                columnData.push(itemColumn);
                            }
                        }

                        for (let key in response.data) {
                            let itemRow = {};

                            for (let property in response.data[key]) {
                                if (['payments_paydoc_name', 'payments_paydoc_value', 'payments_paydoc_value_act'].indexOf(property) === -1) {
                                    itemRow[property] = response.data[key][property];
                                }
                            }

                            if (response.data[key].payments_paydoc_name) {
                                let itemPayDoc = response.data[key].payments_paydoc_name.split('\n');
                                let itemPayDocValue = response.data[key].payments_paydoc_value.split('\n');
                                let itemPayDocValueAct = response.data[key].payments_paydoc_value_act.split('\n');

                                for (let index = 0; index < itemPayDoc.length; index++) {
                                    itemRow['payments_paydoc_name_' + index] = itemPayDoc[index];
                                    itemRow['payments_paydoc_value_' + index] = itemPayDocValue[index];
                                    itemRow['payments_paydoc_value_act_' + index] = itemPayDocValueAct[index];
                                }
                            }

                            report.push({...itemRow});
                        }
                    } else {
                        columnData = [...columnDataXlsx];
                        report = [...response.data];
                    }

                    const data = [
                        //First row - headers
                        columnData.map(item => t(item.label)),
                    ];

                    report.forEach(row => {
                        data.push(
                            columnData.map(item => {
                                const value = _.get(row, item.id);

                                if (item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                }

                                if (item.id.indexOf('payments_paydoc_value_act') !== -1 && value) {
                                    return parseFloat(value)
                                }

                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Full Visits");
                    XLSX.writeFile(new_workbook, `Full Visits ${bu} ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleInputPress = event => {
        if (event.key === 'Enter') {
            this.handleClickFilter();
        }
    };

    render() {
        const {classes, t} = this.props;
        const {
            refreshTable, search, businessUnits, cancelReasons, cancelUsers
        } = this.state;


        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>

                    <RadioGroup
                        value={search.dateType}
                        onChange={this.handleChange('dateType')}
                        row
                    >
                        <FormControlLabel control={<Radio/>} label={t('For the period')} value='period'/>
                        <FormControlLabel control={<Radio/>} label={t('Per month')} value='month'/>
                    </RadioGroup>


                    {search.dateType === 'period' &&
                    <DatePeriodField2
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t('Show visits by date interval')}
                        className={classes.filterControl}
                        checkValuesOrder
                    />
                    }
                    {search.dateType === 'month' && <DateMonthField
                        value={search.month}
                        label={t('Show monthly visits')}
                        onChange={this.handleChange('month')}
                        fullWidth

                    />}

                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        textField={'name'}
                        label={'Салоны'}
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                        value={search.businessUnitIds}
                    />
                    <AutocompleteMultiSelectField
                        options={cancelReasons}
                        textField={'text'}
                        label={t('Cancel reasons')}
                        onChange={this.handleChange('cancelReasonsSelected')}
                        fullWidth
                        value={search.cancelReasonsSelected}
                    />
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        className={classes.filterControl}
                        label={t('Search')}
                        onKeyPress={this.handleInputPress}
                    />
                    <AutocompleteMultiSelectField
                        options={cancelUsers}
                        textField={'username'}
                        label={t('cancelled by user')}
                        onChange={this.handleChange('cancelUserIds')}
                        fullWidth
                        value={search.cancelUserIds}
                    />
                    <DateField
                        label={t('Cancellation date')}
                        value={search.cancelDate}
                        onChange={this.handleChange('cancelDate')}
                        fullWidth
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Canceled visits report')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        linkTemplate={item => `/orders/view/${item.id}`}
                        numeration={true}
                        autoload={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
