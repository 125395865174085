import baseRequest from "./baseReuqest";
import * as queryString from 'query-string-object';

export const employeeStatuses = {
    working: 'working',
    vacation: 'vacation',
    sick: 'sick',
    fired: 'fired'
}

export const emptyReferralLoyaltyPeriod = {
    dayFrom: null,
    dayTo: null,
    asPartnerFormula: null,
    asCreatorFormula: null,
    percentsToMoney: null,
    percentsToBarter: null,
    calculatingTick: null,
}

export const emptyInvestor = {
    id: null,
    name: null,
    canHaveMoney: false
}

export function getParlours(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/parlour';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getParloursForResale() {
    return baseRequest('/api/organization/parlours-for-resale');
}

export function loadParlours(search, page, per_page, order, order_by) {
    return baseRequest('/api/organization/load-parlours', {
        query: {search, page, per_page, order, order_by}
    });
}

export function colorLoad(load) {
    let color;
    switch (true) {
        case load > 80:
            color = '#00FF04';
            break;
        case load > 60:
            color = '#ffc107';
            break;
        case load > 30:
            color = '#ff7e00';
            break;
        default:
            color = '#ff1200';
    }
    return color;
}

export function getParloursWithLoad(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/organization/parlours-with-load', {
        query: {search, page, per_page, order, order_by}
    });
}

export function getParlour(id) {
    return baseRequest(`/api/organization/parlour/id/${id}`);
}

export function createParlour(parlour) {
    return baseRequest('/api/organization/business-unit', {
        method: 'POST',
        body: JSON.stringify({...parlour}),
    });
}

export function getBusinessUnit(id) {
    return baseRequest(`/api/organization/business-unit/id/${id}`);
}

export function getBusinessUnits(search = null, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/business-unit';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getEmployees(search, page = 1, per_page = -1, order, order_by) {
    let url = '/api/organization/employee';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getEmployeesForWorksheet(search) {
    return baseRequest('/api/organization/employee-worksheet', {
        query: {search}
    });
}

export function getHumanEmployees(search = {}, order = 'asc', order_by = 'name') {
    let url = '/api/organization/employee';
    const q = queryString.stringify({search: {...search}, page: 1, per_page: -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getEmployee(id) {
    return baseRequest(`/api/organization/employee/id/${id}`);
}

export function getMasters(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/master';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getMastersWorking(params) {
    return baseRequest('/api/organization/master-working', {
        query: {...params, include: 'workShift'}
    });
}

export function getAdminsWorking(params) {
    return baseRequest('/api/organization/admins-working', {
        query: {...params, include: 'workShift'}
    });
}

export function getMaster(id) {
    return baseRequest(`/api/organization/master/id/${id}`);
}

export function createMaster(master) {
    return baseRequest('/api/organization/employee', {
        method: 'POST',
        body: JSON.stringify({...master}),
    });
}


export function getRegions(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/region';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function createRegion(region) {
    return baseRequest('/api/organization/region', {
        method: 'POST',
        body: JSON.stringify({...region}),
    });
}


export function getDepartments(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/department';
    const q = queryString.stringify({search, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getDepartment(id) {
    return baseRequest(`/api/organization/department/id/${id}`);
}

export function createDepartment(department) {
    return baseRequest('/api/organization/department', {
        method: 'POST',
        body: JSON.stringify({...department}),
    });
}


export function getJuridicalPersons(search, page = 1, per_page = -1, order = 'asc', order_by = 'shortName') {
    let url = '/api/organization/juridical-person';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getExternalOrganizations(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/external-organizations';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}



export function getJuridicalPerson(id) {
    return baseRequest(`/api/organization/juridical-person/id/${id}`);
}

export function createJuridicalPerson(juridicalPerson) {
    return baseRequest('/api/organization/juridical-person', {
        method: 'POST',
        body: JSON.stringify({...juridicalPerson}),
    });
}


export function getCheckingAccounts(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/checking-account';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url, {});
}

export function getCheckingAccountsWithTrusted(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/checking-account-with-trusted';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url, {});
}

export function getCheckingAccount(id) {
    return baseRequest(`/api/organization/checking-account/id/${id}`);
}

export function createCheckingAccount(checkingAccount) {
    return baseRequest('/api/organization/checking-account', {
        method: 'POST',
        body: JSON.stringify({...checkingAccount}),
    });
}


export function getBusinessUnitTypes() {
    return baseRequest('/api/organization/types');
}

export function getEmployeePosts() {
    return baseRequest('/api/organization/employee-posts');
}

export function getEmployeeStatuses() {
    return baseRequest('/api/organization/employee-types');
}


export function getEmployeeHistory(id, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = `/api/organization/history-employee/${id}`;
    const q = queryString.stringify({page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getBusinessUnitHistory(id, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = `/api/organization/history-business-unit/${id}`;
    const q = queryString.stringify({page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getExternalSubjects(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/organization/external-subject', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getExternalOrganizationsWithPayDocPartner(
    page = 1, per_page = -1,
    order = 'asc', order_by = 'name'
) {
    return baseRequest('/api/organization/external-organizations-with-pay-doc-partner', {
        query: {page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getExternalSubject(id) {
    return baseRequest(`/api/organization/external-subject/id/${id}`);
}

export function saveExternalSubject(externalSubject) {
    return baseRequest('/api/organization/external-subject', {
        method: 'POST',
        body: JSON.stringify({...externalSubject}),
    });
}

export function getExternalSubjectTypes() {
    return baseRequest('/api/organization/external-subject-types');
}

export function getExternalSubjectTypeValues() {
    return baseRequest('/api/organization/external-subject-type-values');
}

export function getCommissionSchemes() {
    return baseRequest('/api/organization/external-subject-commission-schemes');
}

export function getCommissionSchemeValues() {
    return baseRequest('/api/organization/external-subject-commission-scheme-values');
}

export function getLastWorkShift(id) {
    return baseRequest(`/api/work-shift/${id}`);
}

export function startWorkShift(id) {
    return baseRequest(`/api/work-shift/start/${id}`, {
        method: 'POST'
    });
}

export function endWorkShift(data) {
    return baseRequest(`/api/work-shift/end/${data.businessUnitId}`, {
        method: 'PUT',
        body: {...data},
    });
}

export function getReferralPartners(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/organization/referral-partner', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function createReferralPartner(referralPartner) {
    return baseRequest('/api/organization/referral-partner', {
        method: 'POST',
        body: JSON.stringify({...referralPartner}),
    });
}

export function getReferralPrograms(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = '/api/organization/referral-programs';
    const q = queryString.stringify({search, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getReferralProgram(id) {
    return baseRequest(`/api/organization/referral-programs/id/${id}`);
}

export function saveReferralProgram(referralProgram) {
    return baseRequest('/api/organization/referral-programs', {
        method: 'POST',
        body: JSON.stringify({...referralProgram}),
    });
}

export const navLinks = [
    {path: "/organization/parlours", text: "Бизнес Юниты", role: "ROLE_UI_TAB_ORGANIZATION_BUSINESSUNITS"},
    {path: "/organization/masters", text: "Сотрудники", role: "ROLE_UI_TAB_ORGANIZATION_EMPLOYEES"},
    {path: "/organization/departments", text: "Подразделения", role: "ROLE_UI_TAB_ORGANIZATION_DEPARTMENTS"},
    {
        path: "/organization/juridical-persons",
        text: "Юридические лица",
        role: "ROLE_UI_TAB_ORGANIZATION_JURIDICALPERSONS"
    },
    {path: "/organization/banks", text: "Банки", role: "ROLE_UI_TAB_ORGANIZATION_JURIDICALPERSONS"},
    {
        path: "/organization/checking-accounts",
        text: "Расчетные счета",
        role: "ROLE_UI_TAB_ORGANIZATION_CHECKINGACCOUNTS"
    },
    {
        path: "/organization/external-subjects",
        text: "Внешние субъекты",
        role: "ROLE_UI_TAB_ORGANIZATION_EXTERNALSUBJECTS"
    },
    {path: "/organization/regions", text: "Регионы", role: "ROLE_UI_TAB_ORGANIZATION_REGIONS"},
    {
        path: "/organization/investors",
        text: "Investors",
        role: "ROLE_UI_TAB_ORGANIZATION"
    },
    {
        path: "/organization/salary-settings",
        text: "Настройки расчёта ЗП",
        role: "ROLE_UI_TAB_ORGANIZATION_SALARY_SETTINGS"
    },
    {
        path: "/organization/referral-programs",
        text: "Реферальные программы",
        role: "ROLE_UI_TAB_ORGANIZATION_REFERRAL_PROGRAM"
    },
    {path: "/organization/equipments", text: "Оборудование салонов", role: "ROLE_UI_TAB_ORGANIZATION_EQUIPMENT"},
    {
        path: "/organization/employee-specializations",
        text: "Специализации сотрудников",
        role: "ROLE_UI_TAB_ORGANIZATION_EMPLOYEE_SPECIALIZATION"
    }
];

export function getEquipmentTypes() {
    return baseRequest('/api/organization/equipment/type');
}

export function getEquipmentStatuses() {
    return baseRequest('/api/organization/equipment/status')
}

export function getEquipments(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/organization/equipment', {
        query: {search, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getEquipment(id) {
    return baseRequest(`/api/organization/equipment/${id}`);
}

export function saveEquipment(equipment) {
    return baseRequest('/api/organization/equipment', {
        method: "POST",
        body: equipment,
    });
}

export const emptyRegionSubscriptions = {
    id: null,
    regionEvent: null,
    messenger: null,
    recipient: null,
};

export const emptySocialNetworkLink = {
    id: null,
    link: null,
    code: null,
}

export function getEmployeeSpecializations(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/organization/employee-specialization', {
        query: {search, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getEmployeeSpecialization(id) {
    return baseRequest(`/api/organization/employee-specialization/${id}`);
}

export function saveEmployeeSpecialization(employeeSpecialization) {
    return baseRequest('/api/organization/employee-specialization', {
        method: "POST",
        body: employeeSpecialization,
    });
}

export function sendDayReport(closeWorkShiftMoneyData, businessUnitId, newUserLogin, oldUserName) {
    return baseRequest('/api/organization/send-day-report', {
        method: 'POST',
        body: JSON.stringify({closeWorkShiftMoneyData, businessUnitId, changeAdmin: {newUserLogin, oldUserName}}),
    })
}


export function getEvotorStores(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/organization/get-evotor-stores', {
        query: {search, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export const employeePostMaster = 'master';

export const employeePostAdministrator = 'administrator';

export function getBusinessUnitRooms(id) {
    return baseRequest(`/api/organization/rooms/${id}`);
}

export function createBusinessUnitAnnouncement(text, businessUnitId) {

    return baseRequest("/api/organization/business-unit-announcements", {
        method: "POST",
        body: {
            text,
            businessUnitId
        }
    })
}

export function updateBusinessUnitAnnouncement(text, id) {

    return baseRequest("/api/organization/business-unit-announcements", {
        method: "POST",
        body: {
            text,
            id
        }
    })
}

export function getBusinessUnitAnnouncements(businessUnitId) {

    return baseRequest("/api/organization/business-unit-announcements/" + businessUnitId)
}

export function removeBusinessUnitAnnouncement(id) {

    return baseRequest(`/api/organization/business-unit-announcements/${id}`, {
        method: "DELETE"
    })
}

export function getLastBusinessUnitAnnouncement(businessUnitId) {

    return baseRequest(`/api/organization/get-last-business-unit-announcement/${businessUnitId}`)
}

export function getParlourByScheduleStaffCode(code) {
    return baseRequest(`/api/organization/parlour/staff-schedule-code/${code}`);
}

export function getRegionsForCert() {
    return baseRequest(`/api/organization/business-unit-regions-for-cert`);
}

export function getBanks(search, page, per_page, order='asc', order_by = 'name'){
    let url = '/api/organization/get-all-banks';

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if(q){
        url+= `?${q}`;
    }
    return baseRequest(url);
}

export function createBank(bank)
{
    return baseRequest('/api/organization/create-bank',{
        method: 'POST',
        body: JSON.stringify({...bank})
    })
}

export function updateBank(bank){
    return baseRequest('/api/organization/update-bank', {
        method: 'POST',
        body: JSON.stringify({...bank})
        })
}

export function deleteBank(id){
    return baseRequest(`/api/organization/delete-bank/${id}`)
}

export function getBanksByJuridicalPerson(id){
    return baseRequest(`/api/organization/banks-by-juridical-person/${id}`)
}

export function getCheckingAccountsByBank(bankId, juridicalPersonId){
    return baseRequest(`/api/organization/checking-account-by-bank`,{
        method: "POST",
        body:{
            bankId: bankId,
            juridicalPersonId: juridicalPersonId
        }
    });
}

export function createOrUpdateInvestor(params) {
    return baseRequest(`/api/organization/investors`, {
        method: "POST",
        body: params
    })
}

export function getInvestors(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    return baseRequest('/api/organization/investors', {
        query: {search, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getReadOnlyForGuestField(id){
    return baseRequest(`/api/organization/read-only-for-guest-field/${id}`)
}