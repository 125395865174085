import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import {
    createActivityGroup, deleteActivityGroup,
    getActivityGroups, updateActivityGroup
} from "../../services/moneyFlow";
import SearchField from "../../components/fields/SearchField";
import {withTranslation} from "react-i18next";

const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
});

const columnData = [
    {id: 'name', label: 'Наименование'},
];

const emptyActivityGroup = {
    id: null,
    name: null
};

export default @connect(state => ({}), {
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class ActivityGroupPage extends React.Component {
    state = {
        ActivityGroup: emptyActivityGroup,
        dialogEditOpen: false,
        refreshTable: false,
        search: {
            search: null
        },
        typeDialog: null
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getActivityGroups(search, ...params);
    };

    handleAdd = () => {
        this.setState({
            ActivityGroup: emptyActivityGroup,
            dialogEditOpen: true,
            typeDialog: 'create'
        })
    };

    handleCloseEditDialog = ok => () => {
        const {ActivityGroup, typeDialog} = this.state
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }
        if (typeDialog === 'create')
            createActivityGroup(ActivityGroup)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            dialogEditOpen: false,
                            refreshTable: !this.state.refreshTable,
                        });
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });

        if (typeDialog === 'update')
            updateActivityGroup(ActivityGroup)
                .then(response => {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable
                    })
                    this.props.showMessage(response.error ? response.error.message : response.message);
                })
    };

    handleEdit = ActivityGroup => {
        this.setState({
            ActivityGroup,
            dialogEditOpen: true,
            typeDialog: 'update'
        })
    };

    handleChange = (prop) => event => {
        this.setState({
            ActivityGroup: {
                ...this.state.ActivityGroup,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleDelete = () => (event) => {
        const {ActivityGroup}= this.state
        deleteActivityGroup(ActivityGroup.id)
            .then(response =>{
                    this.props.showMessage(response.error ? response.error.message : response.message);
                    this.setState({
                        refreshTable: !this.state.refreshTable
                    })
            })
    }



    handleClickSearch = () => {
        this.setState(state => ({refreshTable: !state.refreshTable}));
    };

    render() {
        const {dialogEditOpen, ActivityGroup, refreshTable, search, typeDialog} = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <br/>
                    <br/>
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Группы деятельности">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                    />
                </Content>
                <Dialog
                    open={dialogEditOpen}
                    onClose={this.handleCloseEditDialog(false)}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Группа деятельности</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    label="Наименование"
                                    value={ActivityGroup.name}
                                    onChange={this.handleChange('name')}
                                    required
                                    error={!ActivityGroup.name}
                                    fullWidth
                                    autoFocus

                                />
                                {(typeDialog === 'update') && <Button onClick={this.handleDelete()} color="primary">Delete</Button>}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} disabled={!ActivityGroup.name}
                                color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        )
    }
}
