import React from 'react';
import {withStyles} from "@material-ui/core";

export default
@withStyles(theme => ({
    root: {
        color: theme.palette.text.secondary,
    },
}))
class FieldLabel extends React.Component
{
    render() {
        const { children, classes } = this.props;

        return <div className={classes.root}>{children}</div>;
    }
}