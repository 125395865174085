import React from 'react';
import {Grid, withStyles} from "@material-ui/core";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    emptyMoveRequest,
    getMoveRequest,
    navLinks, processMoveRequestAfterGet
} from "../../services/goods";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {getDeliveryTypeText} from "../../services/payDocs";
import MoveLeftFields from "./MoveLeftFields";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {withRouter} from "react-router";


const styles = theme => ({
    container: {
        width: 700,
        marginTop:  theme.spacing(1),
    },
    fileTitle: {
        fontSize: theme.typography.caption.fontSize,// 16 * 0.75,
        marginTop:  theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.54)',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
    },
    fileLink: {
        display: 'block',
        marginTop:  theme.spacing(0.5),
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
    },
    subtitle: {
        marginTop:  theme.spacing(2),
        marginBottom:  theme.spacing(1),
    },
    textField: {
        marginTop:  theme.spacing(3),
    },
});

@withRouter
class MoveAdditionalPage extends React.Component
{
    state = {
        moveRequest: emptyMoveRequest,
    };

    componentDidMount() {
        const reId = new RegExp('/goods/move/additional/([^/]+)$');
        const resultId = reId.exec(this.props.location.pathname);
        if (resultId.length !== 2) {
            //Error request
            return;
        }

        if (resultId[1] !== "0") {
            getMoveRequest(resultId[1])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            moveRequest: processMoveRequestAfterGet(response.data),
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/move/view/${this.state.moveRequest.id}`);
    };

    render() {
        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <MoveLeftFields moveRequest={this.state.moveRequest}/>
                    <br/><br/>
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>

                <Content title="Дополнительная информация по запросу">
                    {this.renderMainBlock()}
                </Content>
            </React.Fragment>
        );
    }

    renderMainBlock()
    {
        const { classes } = this.props;
        const { moveRequest } = this.state;

        return (
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={6}>
                    <div className={classes.subtitle}>Условия доставки в салон</div>
                    <AnyField
                        id="newParlourName"
                        label="Салон"
                        className={classes.textField}
                        value={moveRequest.newParlourName}
                        fullWidth
                        readOnly
                    />
                    <AnyField
                        id="deliveryType"
                        label="Способ доставки"
                        className={classes.textField}
                        value={getDeliveryTypeText(moveRequest.deliveryType)}
                        fullWidth
                        readOnly
                    />
                    <AnyField
                        id="transportCompany"
                        label="Название транспортной компании"
                        className={classes.textField}
                        value={moveRequest.transportCompany}
                        fullWidth
                        multiline
                        rows="2"
                        readOnly
                    />
                    <AnyField
                        id="waybillNumber"
                        label="Номер накладной"
                        className={classes.textField}
                        value={moveRequest.waybillNumber}
                        fullWidth
                        readOnly
                    />
                    <UploadFilesField
                        id="receiptScanFiles"
                        label="Скан квитанции"
                        value={moveRequest.receiptScan}
                        readOnly
                    />
                    <AnyField
                        id="deliveryCharges"
                        label="Стоимость"
                        className={classes.textField}
                        value={moveRequest.deliveryChargesFormat}
                        fullWidth
                        readOnly
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(MoveAdditionalPage);
