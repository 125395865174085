import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {
    getReferralPartnersCalcInfo,
    getReferralPartnersReport,
    navLinks,
    referralPartnersPay
} from "../../services/moneyFlow";
import moment from "moment";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import {withTranslation} from "react-i18next";
import {dataTableGroupValueStrategy, formatMoney, fromToDates} from "../../services/common";
import {getBusinessUnits, getHumanEmployees, getReferralPartners, getRegions} from "../../services/organization";
import AnyField from "../../components/fields/AnyField";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem, Typography
} from "@material-ui/core";
import MoneyField from "../../components/fields/MoneyField";
import {getPaymentTypes} from "../../services/sale";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import _ from "lodash";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";

const columnData = [
    { id: 'name', label: 'Referral partner' },
    { id: 'num', label: 'Referral code', },
    { id: 'visit', label: 'Visit', linkTemplate: (item) => `/orders/view/` + item.visit, groupStrategy: dataTableGroupValueStrategy.count },
    { id: 'businessUnitName', label: 'Parlour', groupStrategy: dataTableGroupValueStrategy.concat('businessUnitName') },
    { id: 'total', label: 'Total', groupStrategy: dataTableGroupValueStrategy.moneySum('total'), processValue: formatMoney },
    { id: 'profit', label: 'Partner profit', groupStrategy: dataTableGroupValueStrategy.moneySum('profit'), processValue: formatMoney },
    { id: 'duration', label: 'Duration', groupStrategy: dataTableGroupValueStrategy.sum('duration') },
    { id: 'start', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss', groupStrategy: dataTableGroupValueStrategy.fromTo('start'), parentProcessValue: fromToDates('DD.MM.YYYY HH:mm:ss') },
    { id: 'asReferral', label: 'Referral', groupStrategy: dataTableGroupValueStrategy.sum('asReferral') },
    { id: 'asCreator', label: 'Creator', groupStrategy: dataTableGroupValueStrategy.sum('asCreator') },
    { id: 'programName', label: 'Program', groupStrategy: dataTableGroupValueStrategy.same('programName') },
    { id: 'calculatingTick', label: 'Tick', groupStrategy: dataTableGroupValueStrategy.same('calculatingTick') },
    { id: 'lastCalc', label: 'Last pay', dateFormat: 'DD.MM.YYYY HH:mm:ss', groupStrategy: dataTableGroupValueStrategy.same('lastCalc') },
    { id: 'periodsCount', label: 'Periods included', groupStrategy: dataTableGroupValueStrategy.same('periodsCount') },
    { id: 'willBeCalcTo', label: 'Will be payed to', dateFormat: 'DD.MM.YYYY HH:mm:ss', groupStrategy: dataTableGroupValueStrategy.same('willBeCalcTo') },
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    modalSection: {
        marginBottom: 30,
        borderBottom: '1px solid #ccc',
        paddingBottom: 30
    }
});

export default
@withStyles(styles)
@withTranslation()
@connect(null,{
    showMessage: messageDialogActions.show,
})
class ReferralPartnersReportPage extends React.Component {
    state = {
        search: {
            search: null,
            // dateStart: moment(),
            // dateEnd: moment(),
            referralPartnersIds: [],
            region: null,
            businessUnits: [],
        },
        refreshTable: false,
        progress: false,
        referralPartners: [],
        dialogCreatePay: false,
        selected: [],
        calcInfo: [],
        paymentTypes: [],
        employees: [],
        regions: [],
        businessUnits: [],
    };

    componentDidMount() {
        getReferralPartners()
            .then(response => {
                if (response.success) {
                    this.setState({
                        referralPartners: response.data
                    })
                }
            });

        getPaymentTypes().then(response => {
            if (response.success) {
                this.setState({
                    paymentTypes: response.data.filter(o => o.id !== 'paydoc')
                });
            }
        });

        getHumanEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    });
                }
            });

        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    })
                }
            });

        getBusinessUnits({region: this.state.search.region})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });


    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        }, function () {
            if(prop === 'region') {
                getBusinessUnits({region: value})
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                businessUnits: response.data,
                            })
                        }
                    });
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = () => {
        const {search} = this.state;
        return getReferralPartnersReport(search);
    };

    handleCloseCreatePayDialog = (ok) => () => {

        if (!ok) {
            this.setState({
                dialogCreatePay: false
            });
            return;
        }

        referralPartnersPay({'data': this.state.calcInfo})
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogCreatePay: false,
                        refreshTable: true,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    calculate = () => {
        const {selected} = this.state;
        let params = {
            'referralPartnersIds': selected.map(partner => partner.id)
        };
        getReferralPartnersCalcInfo(params)
            .then(response => {
                if (response.success) {
                    this.setState({
                        calcInfo: response.data,
                        dialogCreatePay: true
                    })
                }
            });

    };

    handleChangeCalcInfo = (index, key) => (event) => {
        let calcInfo = [...this.state.calcInfo];
        calcInfo[index][key] = event.target.value;
        this.setState({
            calcInfo: calcInfo
        });
    };

    render() {
        const { t } = this.props;
        const { refreshTable, search, progress, referralPartners, selected, regions, businessUnits } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <AutocompleteMultiSelectField
                        options={referralPartners}
                        value={search.referralPartnersIds}
                        name="referralPartnersIds"
                        onChange={this.handleChange('referralPartnersIds')}
                        label={t('Partners')}
                        fullWidth
                    />
                    <br/><br/>
                    <SelectFromItemsField
                        items={regions}
                        label={t('Region')}
                        value={search.region}
                        onChange={this.handleChange('region')}
                        fullWidth
                        nullable
                        nullableText={t("---All---")}
                    />
                    <MultiSelectFromItemsField
                        items={businessUnits}
                        label={t('Business unit')}
                        value={search.businessUnits}
                        onChange={this.handleChange('businessUnits')}
                        fullWidth
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.calculate} disabled={!selected.length}>{t('Calculate for selected')}</ActionButton>
                </LeftBar>
                <Content title={t('Referral partners report')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        pagination={false}
                        groupBy="name"
                        onSelect={this.handleSelect}
                        selectOnlyParent
                        groupHiddenColumns={[
                            { id: 'id' }
                        ]}
                    />
                </Content>
                <ProgressDialog open={progress}/>
                { this.dialogCreatePay() }
            </React.Fragment>
        );
    }

    dialogCreatePay = () => {
        const { classes, t } = this.props;
        const { dialogCreatePay, calcInfo, paymentTypes, employees } = this.state;

        if (!calcInfo.length) {
            return null;
        }

        const disabledOk = _.some( calcInfo, (item) => item.paymentType === null );

        return (
            <Dialog
                open={dialogCreatePay}
                onClose={this.handleCloseCreatePayDialog(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth={"lg"}
                fullWidth
            >
                <DialogTitle id="dialog-title">{t("Calculate for selected")}</DialogTitle>
                <DialogContent>
                    {calcInfo.map((partner, index) => (
                        <Grid container spacing={2} key={partner.id} className={classes.modalSection}>
                            <Grid item xs={12}>
                                <Typography variant="h5">{ partner.name }</Typography>
                            </Grid>
                            { Object.values(partner.profitTotal).map((businessUnit) => (
                                <React.Fragment key={businessUnit.id}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">{ businessUnit.name }</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MoneyField
                                            label={t('Total')}
                                            value={businessUnit.sumTotal}
                                            readOnly
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MoneyField
                                            label={t('Barter from total')}
                                            value={businessUnit.sumBarter}
                                            readOnly
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <MoneyField
                                            label={t('Money from total')}
                                            value={businessUnit.sumMoney}
                                            readOnly
                                            fullWidth
                                        />
                                    </Grid>
                                </React.Fragment>
                                ))
                            }
                            <Grid item xs={12}>
                                <Typography variant="h6">{t('Payment')}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <AnyField
                                    value={partner.paymentType}
                                    onChange={this.handleChangeCalcInfo(index, 'paymentType')}
                                    select
                                    label={t('Pay money part')}
                                    required
                                    fullWidth>
                                    {paymentTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                                </AnyField>
                            </Grid>
                            {partner.paymentType === 'cash' &&
                                <React.Fragment>
                                    <Grid item xs={3}>
                                        <AutocompleteSelectField
                                            label={t('Employee\'s money')}
                                            value={partner.employeeId}
                                            onChange={this.handleChangeCalcInfo(index, 'employeeId')}
                                            fullWidth
                                            options={employees.map(item => ({value: item.id, label: `${item.name}`}))}
                                            isClearable
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <p>{t('If not selected it will be payed from parlours cash boxes')}</p>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>
                    ))}



                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseCreatePayDialog(true)} color="primary" disabled={disabledOk}>{t("Ok")}</Button>
                    <Button onClick={this.handleCloseCreatePayDialog(false)} color="primary">{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>
        );
    }


}
