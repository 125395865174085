import React from 'react';
import {
    Button,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import {defect, getPayDocsDelivery, groupNames, navLinks, registerSelected} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import _ from 'lodash';
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import ShowField from "../../components/fields/ShowField";
import NumberRangeField from "../../components/fields/NumberRangeField";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'num', numeric: false, disablePadding: true, label: 'Identifier' },
    { id: 'parlour', numeric: false, label: 'Parlour' },
];

@withTranslation()
class RegisterPage extends React.Component {

    state = {
        search: '',
        searchFrom: '',
        searchTo: '',
        selected: [],
        registerDialogOpen: false,
        refreshData: false,
        progress: false,
        defectDialogOpen: false,
    };

    debounceRefresh = _.debounce(()=>{
        this.setState(state => ({refreshData: !state.refreshData}));
    }, 500);

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });

        if (prop === 'searchFrom' || prop === 'searchTo') {
            this.debounceRefresh();
        }
    };

    handleClickSearch = () => {
        this.setState(state => ({refreshData: !state.refreshData}));
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleRegister = () => {
        this.setState({registerDialogOpen: true});
    };

    handleCloseRegisterDialog = ok => () => {
        const { selected, refreshData } = this.state;

        if (!ok) {
            this.setState({registerDialogOpen: false});
            return;
        }

        this.setState({
            progress: true,
        });
        registerSelected(selected.map(item => item.id))
            .then(response => {
                this.setState({
                    progress: false
                });
                if (response.success) {
                    this.setState({registerDialogOpen: false, refreshData: !refreshData, selected: []});
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    getTableData = (...params) => {
        const {search, searchFrom, searchTo} = this.state;
        return getPayDocsDelivery(search, searchFrom, searchTo, ...params);
    };

    handleDefect = () => {
        this.setState({defectDialogOpen: true});
    };

    handleCloseDefectDialog = ok => () => {
        const { selected, refreshData } = this.state;

        if (!ok) {
            this.setState({defectDialogOpen: false});
            return;
        }

        this.setState({
            progress: true,
        });
        defect(selected.map(item => item.id))
            .then(response => {
                this.setState({
                    progress: false
                });
                if (response.success) {
                    this.setState({defectDialogOpen: false, refreshData: !refreshData, selected: []});
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { t } = this.props;
        const { search, searchFrom, searchTo, refreshData, registerDialogOpen, selected, progress, defectDialogOpen } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search || ''}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label={t("Search for payment documents")}
                    />
                    <NumberRangeField
                        label={t("Search by number range of payment documents")}
                        valueFrom={searchFrom}
                        valueTo={searchTo}
                        onChangeFrom={this.handleChange('searchFrom')}
                        onChangeTo={this.handleChange('searchTo')}
                        label2Lines
                    />
                    <ShowField
                        label={t("Payment documents selected")}
                        value={selected.length}
                        label2Lines
                    />
                    <br />
                    <br />
                    <ActionButton disabled={!selected.length} onClick={this.handleRegister}>{t("Take to the salon")}</ActionButton>
                    <ActionButton disabled={!selected.length} onClick={this.handleDefect}>{t("Reject PD")}</ActionButton>
                </LeftBar>
                <Content title={t("Receipt payment documents")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        onSelect={this.handleSelect}
                        refresh={refreshData}
                    />
                </Content>
                <Dialog
                    open={registerDialogOpen}
                    onClose={this.handleCloseRegisterDialog(false)}
                    aria-labelledby="register-dialog-title"
                >
                    <DialogTitle id="register-dialog-title">{t("Confirmation of the receipt of payment document")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="register-dialog-description">{groupNames(selected.map(item => item.num))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseRegisterDialog(true)} color="primary">OK</Button>
                        <Button onClick={this.handleCloseRegisterDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={defectDialogOpen}
                    onClose={this.handleCloseDefectDialog(false)}
                    aria-labelledby="defect-dialog-title"
                >
                    <DialogTitle id="defect-dialog-title">{t("Confirm move into the defect")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="defect-dialog-description">{groupNames(selected.map(item => item.num))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDefectDialog(true)} color="primary">OK</Button>
                        <Button onClick={this.handleCloseDefectDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(RegisterPage);
