const masterHourAvg = (currentVal, row) => {
    currentVal = currentVal || {
        val: 0,
        count: 0,
    };
    if (row.masterHourAvg) {
        currentVal.count++;
        currentVal.val += row.masterHourAvg;
    }
    return currentVal;
};

export default masterHourAvg;
