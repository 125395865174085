import moment from "moment/moment";

export const emptyDiscount = {
    id: null,
    name: null,
    image: {id: null, name: null, size: 0},
    num: null,
    type: null,
    value: 0,
    maxLimit: null,
    handler: null,
    numberRequired: false,
    numberAutoCalculated: false,
    businessUnitsIds: [],
    businessUnitRegionsIds: [],
    businessRolesIds: [],
    active: 'active',
    forSalableEntities: ['Visit'],
    numberType: 'text',
    defaultSelected: false,
    autoApply: null,
    title: null,
    special: false,
    group: null,
    externalDescription: null,
    showInMobile : false,
    availableFrom: moment().format('YYYY-MM-DD'),
    availableTo: moment().format('YYYY-MM-DD'),
    durationFrom: null,
    durationTo: null,
    verifySmsClosingVisit: true,
    allowUseWithClubPrice: false,
};

export const emptyDiscountDateRestriction = {
    id: null,
    dateFrom: moment().format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
    timeFrom: moment(),
    timeTo: moment(),
    createdAt: moment(),
    creator: null,
    comment: "",
    active: true,
    forInternal: true,
    forExternal: true,
    discounts: [],
    businessUnits: [],
    businessUnitRegions: [],
    daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
}
