import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    createEmployeeCashBoxCorrection,
    navLinks
} from "../../services/moneyFlow";
import AnyField from "../../components/fields/AnyField";
import {Grid, MenuItem} from "@material-ui/core";
import {emptyMoney, getCurrencies} from "../../services/common";
import infoActions from "../../components/info/info-actions";
import {getBusinessUnits, getHumanEmployees} from "../../services/organization";
import MoneyField from "../../components/fields/MoneyField";
import {withTranslation} from "react-i18next";
import moment from "moment";
import DateTimeField2 from "../../components/fields/DateTimeField2";


const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

const emptyCorrection = {
    amount: {...emptyMoney},
    businessUnitId: null,
    comment: null,
    createdAt: moment(),
    employeeId: null,
};

export default
@connect(null,{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class AddEmployeeCashBoxCorrectionPage extends React.Component {
    state = {
        businessUnits: [],
        correction: {
            ...emptyCorrection
        },
        employees: [],
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            ...response.data
                        ],
                    });
                }
            });

        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    })
                }
            });

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data.map(currency => currency.id),
                    });
                }
            })
    }

    handleChange = prop => event => {
        this.setState({
            correction: {
                ...this.state.correction,
                [prop]: event.target.value,
            }
        });
    };

    handleAdd = () => {
        const { t } = this.props;
        createEmployeeCashBoxCorrection(this.state.correction)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Correction created'));
                    this.setState({
                        correction: {
                            ...emptyCorrection,
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes, t } = this.props;
        const { correction, businessUnits, employees, currencies } = this.state;
        const disabled = !correction.businessUnitId || !correction.amount.val;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAdd} disabled={disabled}>{t('Create correction')}</ActionButton>
                </LeftBar>
                <Content title={t("Create correction")}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                id="parlour"
                                label={t("Business unit")}
                                value={correction.businessUnitId}
                                required
                                error={!correction.businessUnitId}
                                onChange={this.handleChange('businessUnitId')}
                                fullWidth
                                select
                            >
                                {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="employeeId"
                                label={t("Employee")}
                                value={correction.employeeId}
                                required
                                error={!correction.employeeId}
                                onChange={this.handleChange('employeeId')}
                                fullWidth
                                select
                            >
                                {employees.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <MoneyField
                                label={t('Amount')}
                                currencies={ currencies }
                                value={ correction.amount }
                                error={ !(correction.amount && correction.amount.val)}
                                required
                                onChange={this.handleChange('amount')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateTimeField2
                                label={t('Datetime')}
                                value={ correction.createdAt }
                                error={ !(correction.createdAt)}
                                required
                                onChange={this.handleChange('createdAt')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                label={t("Comment")}
                                value={correction.comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
