import * as queryString from 'query-string-object';
import baseRequest from "./baseReuqest";
import moment from "moment";

export const navLinks = [
    { path: "/guests", text: "Guests"},
    { path: "/guests/verified-data-report", text: "Verified Data Report"},
    { path: "/guests/report-close-visit-messages", text: "Close visit message and visit rating"},
];

export function getGuests(search, page, per_page, order, order_by) {
    let url = '/api/guest';
    const q = queryString.stringify({search, page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getGuest(id, lite = false) {
    return baseRequest(`/api/guest/id/${id}`, {
        query: { lite },
    });
}

export function getGuestStats(id) {
    return baseRequest(`/api/guest/id/${id}/stats`);
}

export function getGuestNalegke(guestId, discountId, visitId) {
    let url = '/api/guest/nalegke';
    const q = queryString.stringify({guestId, discountId, visitId});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function searchByPhoneGuest(phone, currentGuestId) {
    let url = '/api/guest/phone';
    const q = queryString.stringify({phone, currentGuestId});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function importGuests(guests) {
    return baseRequest('/api/guest/import', {
        method: "POST",
        body: guests,
    });
}

function processGuest(guest) {
    if (guest.birthDate) {
        return {
            ...guest,
            birthDate: guest.birthDate instanceof moment ? guest.birthDate.format('YYYY-MM-DD') : guest.birthDate,
        }
    }

    return guest
}

export function updateGuest(guest) {
    return baseRequest(`/api/guest/id/${guest.id}`, {
        method: 'PUT',
        body: JSON.stringify({...processGuest(guest)}),
    });
}

export function createGuest(guest) {
    return baseRequest('/api/guest', {
        method: 'POST',
        body: JSON.stringify({...processGuest(guest)}),
    });
}

export function getPhoneTypes() {
    return baseRequest('/api/guest/type/phone');
}

export function getLoyalties() {
    return baseRequest('/api/guest/type/loyalty');
}

export function getLinkTypes() {
    return baseRequest('/api/guest/type/link');
}

export function getHistoryChange(id, page, per_page, order, order_by) {
    let url = `/api/guest/history-change/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getContactTypes() {
    return baseRequest('/api/guest/type/contact');
}

export function getContactTargets() {
    return baseRequest('/api/guest/type/target');
}

export function createContactTarget(target) {
    return baseRequest(`/api/guest/type/target`, {
        method: 'POST',
        body: JSON.stringify({...target}),
    });
}

export function getContacts(id, page, per_page, order, order_by) {
    let url = `/api/guest/contact/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function createContact(id, contact) {
    return baseRequest(`/api/guest/contact/${id}`, {
        method: 'POST',
        body: JSON.stringify({...contact}),
    });
}

export function getVisits(id, search, page, per_page, order, order_by) {
    let url = `/api/guest/visit/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}


export function getFullName(guest) {
    const names = [];
    if (guest.lastName) names.push(guest.lastName);
    if (guest.name) names.push(guest.name);
    if (guest.secondName) names.push(guest.secondName);

    return names.join(' ');
}

export function getGenders() {
    return baseRequest('/api/guest/type/gender');
}

export function getKnows() {
    return baseRequest('/api/guest/type/knows');
}

export const defaultLoyalty = 'level2';

export function getMedicalCardZones() {
    return baseRequest('/api/guest/type/medical-card-zones');
}

export function getMedicalCardZonePrescriptions(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/guest/medical-card-zone-prescription';

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function saveMedicalCardZonePrescription(data) {
    return baseRequest('/api/guest/medical-card-zone-prescription', {
        method: 'POST',
        body: JSON.stringify({...data}),
    });
}

export function removeGuest(id)
{
    return baseRequest(`/api/guest/id/${id}`, { method: "DELETE" });
}

export function getGuestsInDayWithPersonalDiscounts(visitId) {
    return baseRequest(`/api/guest/in-day-with-discounts/${visitId}`);
}

export function getGuestMessengerTypes() {
    return baseRequest(`/api/guest/messenger-types`);
}

export function getSales(id, search, page, per_page, order, order_by) {
    let url = `/api/guest/buy-history/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getWhatsAppMessages(id, search, page, per_page, order, order_by) {
    let url = `/api/guest/whats-app-messages/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getGuestByBitrixId(bitrixId) {
    return baseRequest(`/api/guest/bitrix-id/${bitrixId}`);
}

export function verifyGuestName(guest) {
    return baseRequest(`/api/guest/verify-name/${guest.id}`, {
        method: 'POST',
        body: JSON.stringify({...guest}),
    });
}

export function getVerifiedDataReport(search, page, per_page) {
    let url = '/api/guest/get-verified-data-report';

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1});
    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function updateVerifiedData({ id, nameVerified, gender, name, lastName, birthDate }){
    return baseRequest("/api/guest/update-verified-data",{
        method: "POST",
        body: {
            id,
            nameVerified,
            gender,
            name,
            lastName,
            birthDate
        }
    })
}

export function loadCloseVisitMessageReportData(search, page, per_page, order, order_by) {
    return baseRequest('/api/guest/load-close-visit-message-report', {
        query: {search, page, per_page, order, order_by}
    });
}

export function uniteGuestsAction(uniteGuests){
    return baseRequest('/api/guest/unite-guests',{
        method: 'PUT',
        body:uniteGuests
    });
}

export function sendClubPayLink(guestClubPayLink){
    return baseRequest('/api/guest/send-club-pay-link',{
        method:'POST',
        body: JSON.stringify(guestClubPayLink)
    });
}

export function getClubSubscriptionHistory(id, page = 1, per_page = -1, order = 'asc', order_by = 'name'){
    let addUrl = `?id=${id}&page=${page}&per_page=${per_page ? per_page : -1}&order=${order}&order_by=${order_by}`;
    return baseRequest(`/api/guest-club/club-subscriptions-by-user${addUrl}`);
}

export function sendUnsubscribeClub(guestClubPayLink){
    return baseRequest('/api/guest/send-club-unsubscribe-link',{
        method:'POST',
        body: JSON.stringify(guestClubPayLink)
    });
}