import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import {Grid} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import AnyField from "../fields/AnyField";
import CheckboxField from "../fields/CheckboxField";
import ActionButton from "../button/ActionButton";
import {connect} from "react-redux";
import infoActions from "../info/info-actions";
import messageDialogActions from "../dialogs/messageDialog-acions";
import * as queryString from "query-string-object";
import {getCurrentUser} from "../../services/user";
import {getBxLinkFunnelStages, getBxLinkFunnels} from "../../services/bx-link";
import {createChangeHandle, loadToState} from "../../utils/helpers";
import SelectFromItemsField from "../fields/SelectFromItemsField";
import {createSegment} from "../../services/callCenter";
import produce from "immer";

const emptySegment = {
    id: null,
    name: null,
    filterParams: null,
    enable: false,
    executor: {
        id: null,
        executorType: null,
        properties: {}
    },
    mappingFunnel: {
        funnelId: null,
        stageNew: null,
        stageInProgress: null,
        stageNotDialing: null,
        stageSuccess: null,
        stageFalse: null,
    },
    executorData: {},
    timezone: "+04:00",
    erpCreator: {}
}

export default
@withStyles(theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingTop:  theme.spacing(1),
        paddingLeft:  theme.spacing(2),
        paddingRight:  theme.spacing(2),
        paddingBottom:  theme.spacing(1),
        minWidth: 0, // So the Typography noWrap works

        '@media print': {
            backgroundColor: 'transparent',
        },
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        }
    },
}))
@connect(null,{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withTranslation()
class CrnSegment extends React.Component
{
    // children: PropTypes.any.isRequired,
    static propTypes = {
        loading: PropTypes.bool,
        visible: PropTypes.bool,
        searchParams: PropTypes.object
    };

    static defaultProps = {
        loading: false,
        visible: true,
        searchParams: {}
    };

    state = {
        segment: {
            ...emptySegment
        },
        editing: false,
        executors: {
            properties: null,
            names: null,
            selectChildren: [],
            selectedExecutorPropsFields: []
        },
        funnels: [],
        stages: [],
    };

    componentDidMount() {
        loadToState(
            this,
            getBxLinkFunnels(),
            'funnels',
            (data) => Object.values(data)
        )

        // Текущего пользователя лучше получать при создании сегмента на бэке
        getCurrentUser().then( (response) => {
            if (response?.data) {
                this.setState({
                    segment: {
                        ...this.state.segment,
                        erpCreator: {
                            id: response.data.id,
                            username: response.data.username
                        }
                    }
                })
            }
        })

    }

    handleSegmentSimpleChange = createChangeHandle(this, 'segment')

    handleTimezoneChange = event => {

        // test if timezone is +04:00, now only + timezone is allowed
        if (
            !/^\+?\d?\d?:?\d?\d?$/.test(event.target.value) ||
            (typeof event.target.value[0] != 'undefined' && event.target.value[0] !== '+') ||
            (typeof event.target.value[3] != 'undefined' && event.target.value[3] !== ':')
        )
            return;

        this.setState({
            segment: {
                ...this.state.segment,
                timezone: event.target.value,
            }
        });
    }

    handleSegmentFunnelChange = event => {
        const { value } = event.target;

        this.setState(produce(draft => {
            draft.segment.mappingFunnel.funnelId = value;
            draft.segment.mappingFunnel.stageNew = null;
            draft.segment.mappingFunnel.stageInProgress = null;
            draft.segment.mappingFunnel.stageNotDialing = null;
            draft.segment.mappingFunnel.stageSuccess = null;
            draft.segment.mappingFunnel.stageFalse = null;
        }))

        getBxLinkFunnelStages(value).then(response => {
            if (response.success) {
                this.setState(() => ({
                    stages: Object.values(response.data),
                }))
            }
        })
    }

    handleSegmentStageChange = event => {
        const { value, name } = event.target;

        this.setState(produce(draft => {
            draft.segment.mappingFunnel[name] = value;
        }))
    }

    createSegment = () => {

        const { segment } = this.state;
        const { showInfo, searchParams, t } = this.props;

        let searchParamsString = queryString.stringify(searchParams);

        // TODO: later get this data from crn response with executors data
        let isCheckSearchParamsString = true;
        if (
            segment.executor.executorType ==='custom_kvv_ktv_ksv_one_way_whatsapp_messages'
        ){
            isCheckSearchParamsString = false;
        }

        if (isCheckSearchParamsString && !searchParamsString) {
            showInfo(t('Can not create Segment - no filter params found'))
            return
        }

        this.setState({
            segment: {
                ...segment,
                filterParams: searchParamsString
            },
        }, () => {

            createSegment(this.state.segment).then((response) => {
                if (response.success) {
                    this.setState({
                        segment: {
                            ...emptySegment
                        },
                    })
                    showInfo(t('Segment created'))
                }else {
                    showInfo('Can\'t create Segment: ' + ((response?.error?.message) ? response.error.message : ' unknown error'))
                }
            })
        })
    }

    isSegmentCanBeSaved = () => {
        const { segment } = this.state;

        const { name, mappingFunnel} = segment;

        const { funnelId, stageInProgress, stageFalse, stageNew, stageNotDialing, stageSuccess } = mappingFunnel;

        console.log(name, funnelId, stageInProgress, stageFalse, stageNew, stageNotDialing, stageSuccess);
        return name && funnelId && stageInProgress && stageFalse && stageNew && stageNotDialing && stageSuccess;
    }

    render() {
        const { t, visible } = this.props;
        const { segment, funnels, executors, stages } = this.state;

        if (!visible) {
            return null;
        }

        return (
            <React.Fragment>
                <Typography component={"div"}>{t("Create Segment by filter parameters")}</Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <AnyField
                            name="name"
                            label={t("Segment name")}
                            value={segment.name}
                            onChange={this.handleSegmentSimpleChange}
                            fullWidth
                            required={true}
                            error={!segment.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AnyField
                            id="timezone"
                            label={(t("Timezone") + " (" + t("example") + ": +04:00)")}
                            value={segment.timezone}
                            onChange={this.handleTimezoneChange}
                            fullWidth
                            required={true}
                            error={!segment.timezone}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CheckboxField
                            name="enable"
                            label={t("Enable")}
                            value={segment.enable}
                            onChange={this.handleSegmentSimpleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={12} item>
                        <Typography variant={"subtitle2"}>{t("Stage Mapping")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectFromItemsField
                            items={funnels}
                            textField="text"
                            name="mappingFunnel.funnelId"
                            fullWidth
                            error={!segment.mappingFunnel.funnelId}
                            value={segment.mappingFunnel.funnelId}
                            onChange={this.handleSegmentFunnelChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectFromItemsField
                            items={stages}
                            textField="text"
                            name="stageNew"
                            label={t("Stage New")}
                            value={segment.mappingFunnel.stageNew}
                            onChange={this.handleSegmentStageChange}
                            fullWidth
                            disabled={!segment.mappingFunnel.funnelId}
                            error={!segment.mappingFunnel.stageNew}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectFromItemsField
                            items={stages}
                            textField="text"
                            name="stageInProgress"
                            label={t("Stage In Progress")}
                            value={segment.mappingFunnel.stageInProgress}
                            onChange={this.handleSegmentStageChange}
                            fullWidth
                            // required
                            disabled={!segment.mappingFunnel.funnelId}
                            error={!segment.mappingFunnel.stageInProgress}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectFromItemsField
                            items={stages}
                            textField="text"
                            name="stageNotDialing"
                            label={t("Stage Not Dialing")}
                            value={segment.mappingFunnel.stageNotDialing}
                            onChange={this.handleSegmentStageChange}
                            fullWidth
                            // required
                            disabled={!segment.mappingFunnel.funnelId}
                            error={!segment.mappingFunnel.stageNotDialing}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectFromItemsField
                            items={stages}
                            textField="text"
                            name="stageSuccess"
                            label={t("Stage Success")}
                            value={segment.mappingFunnel.stageSuccess}
                            onChange={this.handleSegmentStageChange}
                            fullWidth
                            // required
                            disabled={!segment.mappingFunnel.funnelId}
                            error={!segment.mappingFunnel.stageSuccess}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectFromItemsField
                            items={stages}
                            textField="text"
                            name="stageFalse"
                            label={t("Stage False")}
                            value={segment.mappingFunnel.stageFalse}
                            onChange={this.handleSegmentStageChange}
                            fullWidth
                            // required
                            disabled={!segment.mappingFunnel.funnelId}
                            error={!segment.mappingFunnel.stageFalse}
                        />
                    </Grid>
                    {
                        executors.selectedExecutorPropsFields.map(prop => {
                            return <AnyField
                                key={prop.propName}
                                label={t('crn.' + prop.propName)}
                                value={segment.executor.properties[prop.propName]}
                                fullWidth
                                rows={4}
                                required={false}
                                error={!segment.executor.properties[prop.propName]}
                                {...prop.additionalParams}
                            />
                        })
                    }
                    <ActionButton
                        onClick={this.createSegment}
                        disabled={!this.isSegmentCanBeSaved()}
                    >
                        {t("Create Segment")}
                    </ActionButton>
                </Grid>
            </React.Fragment>
        )
    }
}
