import React from 'react';
import * as PropTypes from "prop-types";
import {processMomentFieldInObject, propTypeDuration, propTypeMoment} from "../../utils/moment-utils";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import dimensions from './ScheduleVisitsMultiDimensions';
import {
    findFacilityPrice,
    getParlourTimes,
    getVisits,
    getVisitStatusColor,
    updateVisit,
    visitStatuses,
    waitingMasterColor
} from "../../services/calendar";
import {
    colorLoad,
    getAdminsWorking,
    getEquipments,
    getMastersWorking,
    getParlours,
    loadParlours
} from "../../services/organization";
import _ from 'lodash';
import {activityTypes} from "../../services/calendarEmployee";
import ScheduleVisitsMultiHeaderSlots from "./ScheduleVisitsMultiHeaderSlots";
import ScheduleVisitsMultiElement from "./ScheduleVisitsMultiElement";
import ScheduleVisitsMultiSlot from "./ScheduleVisitsMultiSlot";
import {IconButton, Snackbar} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import socketActions from "../../socket/socket-actions";
import infoActions from "../../components/info/info-actions";
import {paymentStatus} from "../../services/sale";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import DateField2 from "../../components/fields/DateField2";
import calendarActions from "./calendar-actions";
import AddIcon from "@material-ui/icons/Add";
import {v4 as uuidv4} from 'uuid';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import commonActions from "../../common-actions";
import {getFacilitiesVisit} from "../../services/facilities";
import {hasRole} from "../../services/user";

export default @withTranslation()
@connect(state => ({
    socketMessages: state.socket.messages,
    hiddenStatuses: state.calendar.hiddenStatuses,
    columns: state.calendar.multiColumns,
}), {
    removeMessages: socketActions.removeMessages,
    showError: infoActions.showError,
    showInfo: infoActions.show,
    setColumns: calendarActions.setMultiColumns,
    setCurrentDate: calendarActions.setCurrentDate,
    setCurrentParlour: commonActions.setCurrentParlour,
})
@withStyles(theme => ({
    container: {
        display: 'flex',
        flex: '1 0 0%',
        position: 'relative',
        border: '1px solid #777',
    },
    timeGutter: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #777',
    },
    timeLabel: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    timeSlotGroup: {
        borderTop: '1px solid #777',
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    rightContainer: {
        width: '100%',
        overflowX: 'auto',

        '@media print': {
            overflow: 'hidden',
        },
    },
    columnsHeaderContainer: {
        flex: '1 0 0%',
        display: 'flex',
        position: 'relative',
        height: dimensions.headerHeight,
    },
    columnHeader: {
        padding: '2px 3px',
        textAlign: 'center',
        borderRight: dimensions.rightColumnBorderWidth + 'px solid ' + theme.palette.primary.main,
        position: 'relative',
    },
    columnHeaderControls: {
        display: 'block',
        textAlign: 'left',
    },
    deleteColumnButton: {
        textAlign: 'center',
    },
    subColumnHeaders: {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    subColumnHeader: {
        fontSize: '10px',
        overflow: 'hidden',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        width: dimensions.subColumnWidth,
        minWidth: dimensions.subColumnWidth,
    },
    columnsContainer: {
        flex: '1 0 0%',
        display: 'flex',
        position: 'relative',
    },
    subColumns: {
        display: 'flex',
        borderRight: dimensions.rightColumnBorderWidth + 'px solid ' + theme.palette.primary.main,
    },
    subColumn: {
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px dotted #888',
        position: 'relative',
        width: dimensions.subColumnWidth,
        minWidth: dimensions.subColumnWidth,
    },
    timeZone: {
        fontSize: theme.typography.body2.fontSize,
        textAlign: 'center',
        height: dimensions.headerHeight,
    },
    hidePrint: {
        '@media print': {
            display: 'none',
        }
    },
    showPrint: {
        display: 'none',

        '@media print': {
            display: 'block',
        }
    },
    parlourLabel: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
}))
class ScheduleVisitsMulti extends React.PureComponent {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape({
            parlour: PropTypes.object,
            parlourId: PropTypes.string,
            date: PropTypes.instanceOf(moment),
            dateEnd: propTypeMoment,
        })),

        onSlotClick: PropTypes.func,
        onSlotDbClick: PropTypes.func,
        onSlotContextMenu: PropTypes.func,

        selectedVisit: PropTypes.object,

        onVisitClick: PropTypes.func,
        onVisitDbClick: PropTypes.func,
        onVisitContextMenu: PropTypes.func,

        onHeaderClick: PropTypes.func,

        printInfo: PropTypes.any,
        showCurrentTime: PropTypes.bool,
        timeStep: propTypeDuration,
    };

    static defaultProps = {
        showCurrentTime: false,
        timeStep: moment.duration(15, 'minutes'),
    };

    constructor(props) {
        super(props);

        const visitMessages = props.socketMessages.filter(message => message.entity === 'visit');
        if (visitMessages.length) {
            props.removeMessages(visitMessages.map(message => message.id));
        }

        this.state = {
            ...this.calcTimeSlots(),
            parlours: [],
            parlourLoads: [],
            subColumns: [],
            elements: [],
            visits: [],
            draggingIdx: -1,
            draggingVisit: null,
            isDragging: false,
            ctrlPressed: false,
            facilities: {},
        };
    }

    componentDidMount() {
        this.getParlours();
        this.getParlourLoads();
        this.getMasters();
        this.getFacilities();

        document.addEventListener('keydown', this.handleKeyboardEvent);
        document.addEventListener('keyup', this.handleKeyboardEvent);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyboardEvent);
        document.removeEventListener('keyup', this.handleKeyboardEvent);
    }

    handleKeyboardEvent = event => {
        if (event.key === 'Control') {
            this.setState({
                ctrlPressed: event.type === 'keydown',
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.columns, this.props.columns)) {
            if (this.props.columns && this.props.columns.length === 1 && this.props.columns[0].parlour) {
                const timeZone = this.props.columns[0].parlour.timeZone;
                if (this.state.timeZone !== timeZone) {
                    this.setState({
                        timeZone,
                    });
                }
            }

            // С setTimeout не так заметно подвисание интерфейса
            setTimeout(() => {
                this.setState({
                    ...this.calcTimeSlots(),
                    parlourLoads: [],
                    elements: [],
                    visits: [],
                    draggingIdx: -1,
                    draggingVisit: null,
                    isDragging: false,
                });
            }, 0);
            this.getParlourLoads();
            this.getMasters();
            this.getFacilities();
        }

        if (!_.isEqual(prevProps.hiddenStatuses, this.props.hiddenStatuses)) {
            this.setState({
                ...this.calcVisits(this.state.visits),
            });
        }

        if (prevProps.socketMessages !== this.props.socketMessages) {
            let processedIds = [];
            let visits = [];
            let removeVisits = [];

            this.props.socketMessages.forEach(message => {
                if (message.entity && message.entity === 'visit') {
                    processedIds.push(message.id);
                    if (message.action === 'remove') {
                        removeVisits.push(message.entityId);
                    } else if (message.entityData) {
                        visits.push(message.entityData);
                    }
                }
            });

            visits = visits.filter(visit =>
                visit.parlour && this.props.columns.some(column => column.parlour && visit.parlour.id === column.parlour.id)
            );
            visits.forEach(visit => this.processVisit(visit));

            visits = visits.filter(visit => {
                const column = this.props.columns.find(column => column.parlour && column.parlour.id === visit.parlour.id);
                return visit.start.isBetween(column.date, column.dateEnd);
            });

            let draggingIdx = this.state.draggingIdx;
            let draggingVisit = this.state.draggingVisit;
            let draggingVisitId = draggingIdx >= 0 ? this.state.visits[draggingIdx].id : null;

            if (draggingVisitId) {
                if (visits.findIndex(newVisit => newVisit.id === draggingVisitId) >= 0 || removeVisits.indexOf(draggingVisitId) >= 0) {
                    draggingIdx = -1;
                    draggingVisitId = null;
                    draggingVisit = null;
                }
            }

            if (visits.length || removeVisits.length) {
                const oldFilteredVisits = this.state.visits.filter(oldVisit => visits.findIndex(newVisit => newVisit.id === oldVisit.id) === -1 && removeVisits.indexOf(oldVisit.id) === -1);

                if (draggingVisitId) {
                    draggingIdx = oldFilteredVisits.findIndex(oldVisit => oldVisit.id === draggingVisitId);
                }

                this.setState({
                    ...this.calcVisits([
                        ...oldFilteredVisits,
                        ...visits,
                    ]),
                    draggingIdx,
                    draggingVisit,
                });
            }

            if (processedIds.length) {
                this.props.removeMessages(processedIds);
            }
        }
    }

    calcTimeSlots() {
        const {columns, timeStep} = this.props;
        let timeZone = null;
        let allStartTime = null;
        let allEndTime = null;
        columns.forEach(column => {
            if (!timeZone && column.parlour && column.parlour.timeZone) {
                timeZone = column.parlour.timeZone;
            }

            if (!timeZone) {
                timeZone = "+04:00";
            }

            if (column.parlour) {
                const {startTime, endTime} = getParlourTimes(column.parlour, column.date);
                if (!allStartTime) {
                    allStartTime = startTime.clone();
                } else if (startTime < allStartTime) {
                    allStartTime = startTime.clone();
                }
                if (!allEndTime) {
                    allEndTime = endTime.clone();
                } else if (endTime > allEndTime) {
                    allEndTime = endTime.clone();
                }
            }
        });

        if (!allStartTime || !allEndTime) {
            const {startTime, endTime} = getParlourTimes(null, null);
            if (!allStartTime) {
                allStartTime = startTime;
            }
            if (!allEndTime) {
                allEndTime = endTime;
            }
        }

        let current = allStartTime.clone();
        const headerTimeSlots = [];
        const currentDayStart = moment().utcOffset(timeZone).startOf('day');
        let currentGroupBegin = current.clone();
        let currentGroupEnd = current.clone().add(timeStep);
        while (current < allEndTime) {
            const value = current.clone();
            if (value.minutes() === 0) {
                currentGroupBegin = current.clone();
                currentGroupEnd = current.clone();
                for (let i = 0; i < dimensions.slotsInGroup; i++) {
                    currentGroupEnd.add(timeStep);
                }
            }

            current.add(timeStep);
            headerTimeSlots.push({
                beginGroup: value.minutes() === 0,
                endGroup: current.minutes() === 0,
                value: value,
                valueEnd: current.clone(),
                fullValue: currentDayStart.clone().add(value),
                fullValueEnd: currentDayStart.clone().add(current),
                beginGroupValue: currentGroupBegin.clone(),
                endGroupValue: currentGroupEnd.clone(),
                beginGroupFullValue: currentDayStart.clone().add(currentGroupBegin),
                endGroupFullValue: currentDayStart.clone().add(currentGroupEnd),
                key: currentDayStart.clone().add(value).toISOString(),
            });

        }

        const columnTimeSlots = [];
        columns.forEach((column, idx) => {
            const colorLines = column.parlour && column.parlour.scheduleLines && column.parlour.scheduleLines.length ?
                column.parlour.scheduleLines.map(line => {
                    const lineTime = moment(line.time).utcOffset(timeZone).startOf('minute');
                    return {
                        time: moment.duration(lineTime.clone().diff(lineTime.clone().startOf('day'))),
                        color: line.color,
                    };
                }) : [];


            const {startTime, endTime} = getParlourTimes(column.parlour, column.date);
            columnTimeSlots[idx] = [];

            headerTimeSlots.forEach(slot => {
                const line = colorLines.find(line => line.time.asMinutes() === slot.value.asMinutes());
                columnTimeSlots[idx].push({
                    ...slot,
                    fullValue: column.date.clone().add(slot.value),
                    fullValueEnd: column.date.clone().add(slot.valueEnd),
                    beginGroupFullValue: column.date.clone().add(slot.beginGroupValue),
                    endGroupFullValue: column.date.clone().add(slot.endGroupValue),
                    style: line ? {background: line.color} : null,
                    key: column.date.clone().add(slot.value).toISOString(),
                    disabled: !(slot.value.asMinutes() >= startTime.asMinutes() && slot.value.asMinutes() < endTime.asMinutes()),
                });
            });
        });

        return ({
            headerTimeSlots,
            columns,
            columnTimeSlots,
            timeZone,
        });
    }

    getParlours() {
        getParlours({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    });
                }
            });
    }

    getParlourLoads() {
        const promises = [];
        const {columns} = this.props;

        if (hasRole('ROLE_CALLCENTER_TIMESEARCH')) {

            columns.forEach(column => {
                promises.push(loadParlours(processMomentFieldInObject({
                    dateFrom: column.date,
                    dateTo: column.dateEnd,
                    active: 1,
                }, ['dateFrom', 'dateTo']), 1, -1, 'asc', 'date_field'));
            })

            if (promises.length) {
                Promise.all(promises).then(results => {
                    const parlourLoads = [];
                    results.forEach((result, columnIdx) => {
                        if (result.success) {
                            parlourLoads[columnIdx] = result.data;
                        }
                    });

                    this.setState({
                        parlourLoads,
                    });
                })
            }
        }
    }

    calcSubColumns = (masters, equipments, column, admins) => {
        const {timeZone} = this.state;

        //Преобразуем время в moment
        masters.forEach(master => {
            if (master.workShift) {
                master.workShift.forEach(ws => {
                    ws.timeStart = moment(ws.timeStart).utcOffset(timeZone).startOf('minute');
                    ws.timeEnd = moment(ws.timeEnd).utcOffset(timeZone).startOf('minute');
                });
            }
        });

        admins.forEach(admin => {
            if (admin.workShift) {
                admin.workShift.forEach(ws => {
                    ws.timeStart = moment(ws.timeStart).utcOffset(timeZone).startOf('minute');
                    ws.timeEnd = moment(ws.timeEnd).utcOffset(timeZone).startOf('minute');
                });
            }
        });

        const adminSlots = [];
        admins.forEach(admin => {
            if (admin.workShift && Array.isArray(admin.workShift)) {
                admin.workShift.forEach(workShift => {
                    if (column.date.isSame(workShift.timeStart, 'day')) {
                        if (workShift.parlour.id === column.parlour.id && (workShift.activity === activityTypes.working || workShift.activity === activityTypes.overtime)) {
                            adminSlots.push({
                                day: column.value,
                                start: workShift.timeStart,
                                end: workShift.timeEnd,
                            });
                        }
                    }
                });
            }
        });

        const columnMasters = [];
        masters.forEach(master => {
            const {workShift, ...otherMasterProps} = master;
            if (workShift) {
                const columnWs = workShift.filter(ws => column.date.isSame(ws.timeStart, 'day'));
                if (columnWs && columnWs.length) {
                    const columnMaster = otherMasterProps;
                    columnMaster.enabledSlots = [];
                    columnMaster.enabledColumns = [];
                    columnMaster.disabledSlots = [];
                    columnMaster.waiting = [];
                    columnMaster.headerStyle = null;
                    columnMaster.bodyStyle = null;
                    columnMaster.key = otherMasterProps.id;
                    columnMaster.adminSlots = adminSlots;

                    columnWs.forEach(ws => {
                        if (ws.parlour.id === column.parlour.id && columnMaster.lineColor) {
                            columnMaster.headerStyle = {backgroundColor: columnMaster.lineColor};
                            columnMaster.bodyStyle = {backgroundColor: columnMaster.lineColor};
                        }

                        if (ws.parlour.id === column.parlour.id && ws.activity === activityTypes.waiting) {
                            columnMaster.waiting.push({
                                day: column.date.value,
                            });
                            columnMaster.headerStyle = {backgroundColor: waitingMasterColor};
                            columnMaster.bodyStyle = {backgroundColor: waitingMasterColor};
                        }

                        if (ws.parlour.id === column.parlour.id && (ws.activity === activityTypes.working || ws.activity === activityTypes.waiting)) {
                            columnMaster.enabledSlots.push({
                                day: column.date.value,
                                start: ws.timeStart,
                                end: ws.timeEnd,
                            });
                        } else if (ws.parlour.id === column.parlour.id && ws.activity === activityTypes.overtime) {
                            columnMaster.enabledSlots.push({
                                day: column.date.value,
                                start: ws.timeStart,
                                end: ws.timeEnd,
                            });
                        } else {
                            columnMaster.disabledSlots.push({
                                day: column.date.value,
                                start: ws.timeStart,
                                end: ws.timeEnd,
                                parlour: ws.parlour,
                                activity: ws.activity,
                                comment: ws.comment,
                            });
                        }
                    });

                    columnMasters.push(columnMaster);
                }
            }
        });

        return [
            ...columnMasters,
            ...equipments.map(equipment => ({
                ...equipment,
                disabledSlotsAll: true,
                adminSlots,
                headerStyle: equipment.type === 'barrel' ?
                    {background: '#ded166'} :
                    equipment.type === 'room' ?
                        {background: '#73cba7'} :
                        null,
                key: equipment.id,
            })),
        ];
    }

    getMasters() {
        const {columns} = this.props;
        const promises = [];
        const promisesColumns = [];
        columns.forEach((column, columnIdx) => {
            if (column.parlour) {
                promises.push(getMastersWorking({
                    parlour: column.parlour.id,
                    fromDate: column.date.toISOString(),
                    toDate: column.dateEnd.toISOString(),
                }));
                promises.push(getEquipments({
                    businessUnitId: column.parlour.id,
                    status: 'working'
                }));
                promises.push(getAdminsWorking({
                    parlour: column.parlour.id,
                    fromDate: column.date.toISOString(),
                    toDate: column.dateEnd.toISOString(),
                }));
                promisesColumns.push(columnIdx);
            }
        });

        if (promises.length) {
            Promise.all(promises).then(results => {
                const subColumns = [];
                for (let i = 0; i < results.length; i += 3) {
                    const columnIdx = promisesColumns[i / 3];
                    const column = columns[columnIdx];
                    let masters = [];
                    let equipments = [];
                    let admins = [];
                    if (results[i].success) {
                        masters = results[i].data;
                    }
                    if (results[i + 1].success) {
                        equipments = results[i + 1].data;
                    }
                    if (results[i + 2].success) {
                        admins = results[i + 2].data;
                    }
                    subColumns[columnIdx] = this.calcSubColumns(masters, equipments, column, admins);
                }

                this.setState({
                    subColumns,
                }, () => {
                    this.getVisits();
                });
            })
        }
    }

    getFacilities() {
        const {columns} = this.props;
        const promises = [];
        const promisesParlourIds = [];
        _.uniq(columns.filter(column => column.parlour).map(column => column.parlour.id)).forEach(parlourId => {
            promises.push(getFacilitiesVisit({
                parlour: parlourId,
                allPrices: true
            }));
            promisesParlourIds.push(parlourId);
        });

        if (promises.length) {
            Promise.all(promises).then(results => {
                const facilities = {};
                for (let i = 0; i < results.length; i++) {
                    const parlourId = promisesParlourIds[i];
                    if (results[i].success) {
                        facilities[parlourId] = results[i].data.map(facility => ({
                            ...facility,
                            prices: _.flatMap(facility.prices, price => {
                                const {durations, ...priceFields} = price;
                                return durations.map(duration => ({
                                    ...priceFields, ...duration
                                }));
                            })
                        }));
                    }
                }
                this.setState({
                    facilities,
                });
            })
        }
    }

    calcVisit = (visit, visitIdx) => {
        const {columns, subColumns, columnTimeSlots} = this.state;
        const {timeStep, hiddenStatuses} = this.props;

        const elements = [];

        columns.forEach((column, columnIdx) => {
            if (column.parlourId !== visit.parlour.id) {
                // Не нашлась колонка
                return;
            }

            visit.visitFacility.forEach((vf, vfIdx) => {
                if (hiddenStatuses && hiddenStatuses.indexOf(visit.status) >= 0) {
                    return;
                }
                if (!column.date.isSame(vf.start, 'day')) {
                    // Не нашлась колонка
                    return;
                }

                const colSubColumns = subColumns[columnIdx];
                const colTimeSlots = columnTimeSlots[columnIdx];

                if (!colSubColumns || !colTimeSlots) {
                    // Нет колонок с мастрами
                    return;
                }

                let isCash = false;
                let isCashless = false;
                let isPaymentProcessing = false;
                if (visit.sale && visit.sale.payments && visit.sale.payments.length) {
                    visit.sale.payments.forEach(payment => {
                        if (payment.type === 'cash') {
                            isCash = true;
                        } else if (payment.type === 'cashless' && payment.status !== paymentStatus.processing) {
                            isCashless = true;
                        }
                        if (payment.status === paymentStatus.processing) {
                            isPaymentProcessing = true;
                        }
                    });
                }

                let left = 0;
                for (let i = 0; i < columnIdx; i++) {
                    left += subColumns[i] ? subColumns[i].length * dimensions.subColumnWidth + dimensions.rightColumnBorderWidth : 0;
                }

                vf.master.forEach((master, masterIdx) => {
                    const subColumnIdx = colSubColumns.findIndex(subColumn => subColumn.id === master.id);
                    if (subColumnIdx < 0) {
                        // Нет нужного мастера
                        return;
                    }
                    const slotIdx = colTimeSlots.findIndex(slot => slot.fullValue.isSame(vf.start, 'minute'))
                    if (slotIdx < 0) {
                        // Нет подходящего слота
                        return;
                    }

                    elements.push({
                        slotIdx,
                        columnIdx,
                        subColumnIdx,
                        visitIdx,
                        vfIdx,
                        masterIdx,
                        height: vf.duration.asMinutes() / timeStep.asMinutes() * dimensions.slotHeight,
                        key: vf.id + '-' + master.id + '-' + columnIdx,
                        top: slotIdx * dimensions.slotHeight,
                        left: left + subColumnIdx * dimensions.subColumnWidth,
                        style: {
                            background: getVisitStatusColor(visit.status),
                            zIndex: visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked ? 0 : '',
                            opacity: visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked ? 0.5 : null,
                        },
                        masterId: master.id,
                        masterRequested: master.requested,
                        visit: visit,
                        vf: vf,
                        icons: {
                            isCash,
                            isCashless,
                            isPaymentProcessing,
                            isClubMember: visit.guest && visit.guest.isClubMember,
                            isOnline: visit.isOnline,
                            isRequested: master.requested,
                        },
                        content: {
                            timeStartFormat: vf.start.format('HH:mm'),
                            timeEndFormat: vf.end.format('HH:mm'),
                            guest: visit.guest ? visit.guest.fullName : null,
                            guestPhone: visit.guest && visit.guest.phone ? visit.guest.phone.replace(/\s+/g, '') : null,
                        },
                    })
                });

                if (vf.equipmentId) {
                    const subColumnIdx = colSubColumns.findIndex(subColumn => subColumn.id === vf.equipmentId);
                    if (subColumnIdx < 0) {
                        // Нет нужного мастера
                        return;
                    }
                    const slotIdx = colTimeSlots.findIndex(slot => slot.fullValue.isSame(vf.start, 'minute'))
                    if (slotIdx < 0) {
                        // Нет подходящего слота
                        return;
                    }

                    elements.push({
                        slotIdx,
                        columnIdx,
                        subColumnIdx,
                        visitIdx,
                        vfIdx,
                        masterIdx: -100,
                        height: vf.duration.asMinutes() / timeStep.asMinutes() * dimensions.slotHeight,
                        key: vf.id + '-' + vf.equipmentId + '-' + columnIdx,
                        top: slotIdx * dimensions.slotHeight,
                        left: left + subColumnIdx * dimensions.subColumnWidth,
                        style: {
                            background: getVisitStatusColor(visit.status),
                            zIndex: visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked ? 0 : '',
                            opacity: visit.status === visitStatuses.canceled || visit.status === visitStatuses.blocked ? 0.5 : null,
                        },
                        masterId: null,
                        masterRequested: null,
                        disableDrag: true,
                        visit: visit,
                        vf: vf,
                        icons: {
                            isCash,
                            isCashless,
                            isPaymentProcessing,
                            isClubMember: visit.guest && visit.guest.isClubMember,
                            isOnline: visit.isOnline,
                        },
                        content: {
                            timeStartFormat: vf.start.format('HH:mm'),
                            timeEndFormat: vf.end.format('HH:mm'),
                            guest: visit.guest ? visit.guest.fullName : null,
                            guestPhone: visit.guest && visit.guest.phone ? visit.guest.phone.replace(/\s+/g, '') : null,
                        },
                    })
                }
            });
        });

        return elements;
    }

    processVisit(visit) {
        const {timeZone} = this.state;
        visit.visitFacility.forEach(vf => {
            vf.start = moment(vf.start).utcOffset(timeZone);
            vf.duration = moment.duration(vf.duration, 'minutes');
            vf.end = vf.start.clone().add(vf.duration);

            if (!visit.start || vf.start < visit.start) {
                visit.start = vf.start.clone();
            }

            if (!visit.end || vf.end > visit.end) {
                visit.end = vf.end.clone();
            }
        });

        return visit;
    }

    calcVisits = visits => {

        let elements = [];

        visits.forEach((visit, visitIdx) => {
            elements = elements.concat(this.calcVisit(visit, visitIdx));
        })

        return {
            elements,
            visits,
        };
    }

    getVisits() {
        const parlourIds = [];
        let fromDate = null;
        let toDate = null;
        this.props.columns.forEach(column => {
            if (column.parlour && column.parlour.id && parlourIds.indexOf(column.parlour.id) < 0) {
                parlourIds.push(column.parlour.id);
            }

            if (fromDate) {
                if (column.date < fromDate) {
                    fromDate = column.date.clone();
                }
            } else {
                fromDate = column.date.clone();
            }


            if (toDate) {
                if (column.dateEnd > toDate) {
                    toDate = column.dateEnd.clone();
                }
            } else {
                toDate = column.dateEnd.clone();
            }
        });

        if (parlourIds.length && fromDate && toDate) {
            getVisits({
                parlours: parlourIds,
                fromDate: fromDate.toISOString(),
                toDate: toDate.toISOString(),
            }).then(response => {
                if (response.success) {
                    const visits = response.data;
                    visits.forEach(visit => {
                        this.processVisit(visit);
                    });
                    this.setState({
                        ...this.calcVisits(visits),
                    });
                }
            })
        }
    }

    handleBeginDrag = (visitIdx) => {
        let {draggingIdx, visits} = this.state;

        if (draggingIdx < 0) {
            this.setState({
                isDragging: true,
                draggingIdx: visitIdx,
                draggingVisit: _.cloneDeep(visits[visitIdx]),
            });
        }
    };

    handleEndDrag = () => {
        this.setState({
            isDragging: false,
        })
    }

    handleSlotDropHover = (slotFullValue, subColumn, element, column) => {
        const {facilities} = this.state;
        const {vfIdx, masterIdx} = element;
        const draggingVisit = {...this.state.draggingVisit};
        const vf = draggingVisit.visitFacility[vfIdx];
        const diffStart = slotFullValue.isSame(vf.start, 'minute') ? null : slotFullValue.clone().diff(vf.start);

        let changed = false;
        if (subColumn.id !== vf.master[masterIdx].id) {
            vf.master[masterIdx] = {...subColumn};
            changed = true;
        }

        if (column.parlourId !== draggingVisit.parlour.id) {
            const havePrice = column.parlour.prices.find(price => price.id === vf.pricePriceId);

            // Если в целевом салоне нет выбранного в услуге прайса
            if (!havePrice) {
                // Попробуем найти стоимость в прайсе целевого салона
                try {
                    vf.priceId = findFacilityPrice(facilities, draggingVisit, vf, column.parlour);
                } catch (e) {
                    // TODO Выводить что-то пользователю и запрещать перенос визита
                    console.log(e);
                }
            }
            draggingVisit.parlour = {...column.parlour};
        }


        if (changed || diffStart) {
            if (changed) {
                draggingVisit.visitFacility[vfIdx] = vf;
            }

            if (diffStart) {
                draggingVisit.visitFacility.forEach(vf => {
                    vf.start.add(diffStart);
                    vf.end.add(diffStart);
                });
                draggingVisit.start.add(diffStart);
                draggingVisit.end.add(diffStart);
            }

            this.setState({
                draggingVisit: {...draggingVisit},
            });
        }
    }
    handleSlotDropHoverDebounce = _.debounce(this.handleSlotDropHover, 10);

    handleAcceptDrag = () => {
        const {draggingIdx, draggingVisit, visits} = this.state;

        if (draggingIdx >= 0) {
            this.setState({
                draggingIdx: -1,
                draggingVisit: null,
            });

            updateVisit(draggingVisit)
                .then(response => {
                    if (response.success) {
                        visits[draggingIdx] = this.processVisit(response.data);
                        this.setState({
                            ...this.calcVisits(visits),
                        });
                        this.props.showInfo('Изменения успешно сохранены');
                    } else {
                        this.props.showError(response.error ? response.error.message : response.message);
                        this.getVisits();
                    }
                })
        }
    }

    handleDeclineDrag = () => {
        this.setState({
            draggingIdx: -1,
            draggingVisit: null,
        });
    }

    handleChangeColumn = event => {
        const {parlours, timeZone} = this.state;
        let columns = [...this.props.columns];
        const [prop, columnIdxStr] = event.target.name.split('#');
        const columnIdx = parseInt(columnIdxStr);
        if (prop === 'parlour') {
            const parlourId = event.target.value;
            const parlour = parlours.find(parlour => parlour.id === parlourId);
            let date = columns[columnIdx].date.clone();
            let dateEnd = columns[columnIdx].dateEnd.clone();
            if (parlour.timeZone !== timeZone) {
                date = date.utcOffset(parlour.timeZone).startOf('day');
                dateEnd = dateEnd.utcOffset(parlour.timeZone).endOf('day');
                this.setState({
                    timeZone: parlour.timeZone,
                });
            }
            columns[columnIdx] = {
                ...columns[columnIdx],
                parlourId,
                parlour,
                date,
                dateEnd,
            };
            if (columnIdx === 0) {
                this.props.setCurrentParlour(parlour);
                this.props.setCurrentDate(date);
            }
        } else if (prop === 'date') {
            const newDate = event.target.value;
            if (!newDate?.isValid()) {
                return;
            }
            const date = newDate.utcOffset(timeZone).startOf('day');
            columns[columnIdx] = {
                ...columns[columnIdx],
                date,
                dateEnd: date.clone().endOf('day'),
            };
            if (columnIdx === 0) {
                this.props.setCurrentDate(date);
            }
        }
        this.props.setColumns(columns);
    }

    handleAddColumn = () => {
        const {timeZone} = this.state;
        const {columns} = this.props;
        const date = columns && columns.length ? columns[columns.length - 1].date.clone() : moment().utcOffset(timeZone).startOf('day');
        this.props.setColumns([...columns, {date, dateEnd: date.clone().endOf('day')}]);
    }

    getColumnKey(column, columnIdx) {
        return (column.parlourId || uuidv4()) + '-' + column.date.format('YYMMDD') + '-' + columnIdx;
    }

    handleDeleteColumn = event => {
        const columnIdx = parseInt(event.currentTarget.getAttribute('aria-colindex'));
        const columns = [...this.props.columns];
        if (columns.length > 1) {
            columns.splice(columnIdx, 1);
            this.props.setColumns(columns);
        }
    }

    parlourItemLabel = (parlour, fieldName) => {
        const {classes} = this.props;
        const columnIdx = parseInt(fieldName.split('#')[1]);
        const {parlourLoads} = this.state;
        if (parlourLoads && parlourLoads[columnIdx] && parlourLoads[columnIdx].length) {
            const load = parlourLoads[columnIdx].find(load => load.parlour_id === parlour.id);
            if (load) {
                return <div className={classes.parlourLabel}>
                    {parlour.name}
                    <span style={{marginLeft: 10, color: colorLoad(load.percents)}}>{'(' + load.percents + '%)'}</span>
                </div>;
            }
        }
        return parlour.name;
    }

    render() {
        const {
            classes,
            printInfo,
            onHeaderClick,
            onSlotClick,
            onSlotDbClick,
            onSlotContextMenu,
            t,
            onVisitClick,
            onVisitDbClick,
            onVisitContextMenu,
            selectedVisit,
        } = this.props;

        const {
            timeZone,
            columns,
            columnTimeSlots,
            headerTimeSlots,
            subColumns,
            elements,
            draggingIdx,
            draggingVisit,
            isDragging,
            ctrlPressed,
            parlours,
        } = this.state;

        // пока уберу фильтрацию чтобы больше не писали в саппорт
        // let filteredParlours = parlours;
        // if (columns && columns.length > 1 && columns[0].parlour) {
        //     const tz = columns[0].parlour.timeZone;
        //     filteredParlours = parlours.filter(parlour => parlour.timeZone === tz);
        // }

        return <div className={classes.container}>
            <div className={classes.timeGutter}>
                <div className={classes.timeZone}>
                    <span className={classes.hidePrint}>GMT<br/>{timeZone}</span>
                    <span className={classes.showPrint}>{printInfo}</span>
                </div>
                <ScheduleVisitsMultiHeaderSlots slots={headerTimeSlots}/>
            </div>
            <div className={classes.rightContainer}>
                <div className={classes.columnsHeaderContainer}>
                    {columns.map((column, columnIdx) => {
                        const colSubColumns = subColumns[columnIdx];
                        const columnWidth = dimensions.getColumnWidth(colSubColumns);
                        return <div
                            className={classes.columnHeader}
                            key={this.getColumnKey(column, columnIdx)}
                            style={{
                                width: columnWidth,
                                minWidth: columnWidth,
                                cursor: onHeaderClick ? 'pointer' : null
                            }}
                            onClick={event => onHeaderClick && onHeaderClick(column.value, event)}
                        >
                            <div className={classes.columnHeaderControls}>
                                <div className={classes.deleteColumnButton}>
                                    <IconButton
                                        size="small"
                                        onClick={this.handleDeleteColumn}
                                        aria-colindex={columnIdx}
                                        color="primary"
                                        disabled={columns.length < 2}
                                    ><DeleteIcon/></IconButton>
                                </div>
                                <SelectFromItemsField
                                    items={parlours}
                                    value={column.parlourId}
                                    onChange={this.handleChangeColumn}
                                    name={'parlour#' + columnIdx}
                                    fullWidth
                                    customItemLabel={this.parlourItemLabel}
                                    stripedItems
                                />
                                <DateField2
                                    value={column.date}
                                    onChange={this.handleChangeColumn}
                                    name={'date#' + columnIdx}
                                    fullWidth
                                />
                            </div>
                            {colSubColumns && colSubColumns.length ?
                                <div className={classes.subColumnHeaders}>
                                    {colSubColumns.map(subColumn => <div
                                        className={classes.subColumnHeader}
                                        key={subColumn.key}
                                        style={subColumn.headerStyle}
                                    >{subColumn.name}</div>)}
                                </div>
                                : null
                            }
                        </div>
                    })}
                    {columns.length < dimensions.maxColumns ?
                        <div>
                            <IconButton color="primary" size="small"
                                        onClick={this.handleAddColumn}><AddIcon/></IconButton>
                        </div>
                        : null
                    }
                </div>
                <div className={classes.columnsContainer}>
                    {columns.map((column, columnIdx) => {
                        const colSubColumns = subColumns[columnIdx];
                        const columnSlots = columnTimeSlots[columnIdx];
                        const columnWidth = dimensions.getColumnWidth(colSubColumns);
                        return <div
                            className={classes.subColumns}
                            style={{width: columnWidth, minWidth: columnWidth}}
                            key={this.getColumnKey(column, columnIdx)}
                        >
                            {colSubColumns && colSubColumns.map(subColumn =>
                                <div
                                    key={subColumn.key}
                                    className={classes.subColumn}
                                    style={subColumn.bodyStyle}
                                >
                                    {columnSlots.map(slot => {
                                        let disabled = false;
                                        let disabledTitle = null;
                                        if (subColumn.disabledSlotsAll) {
                                            disabled = true;
                                        } else if (subColumn.enabledSlots && subColumn.enabledSlots.length) {
                                            if (subColumn.enabledSlots.findIndex(item => slot.fullValue.isBetween(item.start, item.end, 'minute', '[)')) < 0) {
                                                disabled = true;
                                            }

                                            if (disabled && subColumn.disabledSlots && subColumn.disabledSlots.length) {
                                                const dFound = subColumn.disabledSlots.find(item => slot.fullValue.isSame(item.start, 'minute'));
                                                if (dFound) {
                                                    disabledTitle = dFound.activity === 'working' ?
                                                        dFound.parlour.name :
                                                        t('Break') + (dFound.comment ? ' ' + dFound.comment : '');
                                                }
                                            }
                                        } else if (subColumn.disabledSlots && subColumn.disabledSlots.length) {
                                            if (subColumn.disabledSlots.find(item => slot.fullValue.isBetween(item.start, item.end, 'minute', '[)'))) {
                                                disabled = true;
                                            }
                                            const dFound = subColumn.disabledSlots.find(item => slot.fullValue.isSame(item.start, 'minute'));
                                            if (dFound) {
                                                disabledTitle = dFound.activity === 'working' ?
                                                    dFound.parlour.name :
                                                    t('Break') + (dFound.comment ? ' ' + dFound.comment : '');
                                            }
                                        }
                                        let adminGap = false;
                                        if (subColumn.adminSlots.findIndex(item => slot.fullValue.isBetween(item.start, item.end, 'minute', '[)')) < 0) {
                                            adminGap = true;
                                        }

                                        return <ScheduleVisitsMultiSlot
                                            key={slot.key}
                                            slot={slot}
                                            column={column}
                                            subColumn={subColumn}
                                            disabled={disabled}
                                            disabledTitle={disabledTitle}
                                            onSlotClick={onSlotClick}
                                            onSlotDbClick={onSlotDbClick}
                                            onSlotContextMenu={onSlotContextMenu}
                                            onSlotDropHover={this.handleSlotDropHoverDebounce}
                                            adminGap={column.parlour.showAdminGaps && adminGap}
                                        />;
                                    })}
                                </div>
                            )}
                        </div>;
                    })}

                    {elements.map(element => <ScheduleVisitsMultiElement
                        key={element.key}
                        element={element}
                        onBeginDrag={this.handleBeginDrag}
                        onEndDrag={this.handleEndDrag}
                        draggingIdx={draggingIdx}
                        isDraggingSource={isDragging}
                        ctrlPressed={ctrlPressed}
                        onVisitClick={onVisitClick}
                        onVisitDbClick={onVisitDbClick}
                        onVisitContextMenu={onVisitContextMenu}
                        selectedVisit={selectedVisit}
                    />)}

                    {draggingIdx >= 0 && draggingVisit ?
                        this.calcVisit(draggingVisit, draggingIdx).map(element => <ScheduleVisitsMultiElement
                            key={element.key}
                            element={element}
                            onBeginDrag={this.handleBeginDrag}
                            onEndDrag={this.handleEndDrag}
                            draggingIdx={draggingIdx}
                            isDragTarget
                            isDraggingSource={isDragging}
                            onVisitClick={onVisitClick}
                            onVisitDbClick={onVisitDbClick}
                            onVisitContextMenu={onVisitContextMenu}
                            selectedVisit={selectedVisit}
                        />) :
                        null
                    }
                </div>
            </div>

            <Snackbar
                open={draggingIdx >= 0 && !isDragging}
                color=""
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                message={<span>{t("Transfer of the visit Are you sure")}</span>}
                action={[
                    <IconButton
                        key="accept-drag"
                        onClick={this.handleAcceptDrag}
                        color="inherit"
                    >
                        <CheckIcon/>
                    </IconButton>,
                    <IconButton
                        key="cancel-drag"
                        onClick={this.handleDeclineDrag}
                        color="inherit"
                    >
                        <CancelIcon/>
                    </IconButton>,
                ]}
            />
        </div>;
    }
}
