import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {reportBalancesCheck, navLinks, updateBalances} from "../../services/moneyFlow";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'billId', label: 'billId'},
    { id: 'day', label: 'day' },
    { id: 'amountDay', label: 'amountDay'},
    { id: 'amountCumulative', label: 'amountCumulative' },
    { id: 'name', label: 'name' },
    { id: 'type', label: 'type' },
    { id: 'amountBalanceFormat', label: 'amountBalance' },
    { id: 'balanceId', label: 'balanceId' },
    { id: 'currency', label: 'currency' },
    { id: 'diff', label: 'diff' },
];

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withTranslation()
class BalancesCheckPage extends React.Component {
    state = {
        search: {
        },
        refreshTable: false,
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return reportBalancesCheck(search, ...params);
    };

    handleClickUpdateBalances = () => {
        updateBalances()
            .then(response => {
                if (response.success) {
                    this.props.showMessage('Балансы обновлены');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    render() {
        const { t } = this.props;
        const { refreshTable } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleClickUpdateBalances} color="secondary">Обновить балансы</ActionButton>
                </LeftBar>
                <Content title={t('Balances check')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
