import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import AnyField from "../../components/fields/AnyField";
import {Grid, MenuItem} from "@material-ui/core";
import {defaultCurrency, emptyMoney, getFalseResult} from "../../services/common";
import infoActions from "../../components/info/info-actions";
import {getBusinessUnits} from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {withRouter} from "react-router";
import {
    getGoodPrice,
    getGoods,
    saveGoodPrice,
    navLinks,
    getGoodStorages,
    goodStorageStatuses
} from "../../services/goods";
import MoneyField from "../../components/fields/MoneyField";
import CheckboxField from "../../components/fields/CheckboxField";
import DataTable from "../../components/data-table/DataTable";
import Typography from "@material-ui/core/Typography";

const emptyGoodPrice = {
    id: null,
    goodId: null,
    businessUnitId: null,
    expressDelivery: false,
    salePrice: {...emptyMoney},
    usePrice: {...emptyMoney},
};

const columnData = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товаров' },
    { id: 'purposeText', label: 'Предназначение' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'good.description', label: 'Описание товара' },
    { id: 'purchasePriceFormat', label: 'Цена закупки' },
    { id: 'priceFormat', label: 'Цена товара' },
    { id: 'num', label: 'Номер' },
    { id: 'expiration', label: 'Срок годности', dateFormat: "DD.MM.YYYY" },
    { id: 'statusText', label: 'Статус' },
    { id: 'currentMassFormatted', label: 'Количество' },
    { id: 'createdAt', label: 'Дата создания', dateFormat: "DD.MM.YYYY HH:mm" },
    { id: 'creator', label: 'Создатель' },
];

export default
@withRouter
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class GoodPricesViewPage extends React.Component {
    state = {
        businessUnits: [],
        goods: [],
        goodPrice: {
            ...emptyGoodPrice
        },
        refreshTable: false,
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getGoodPrice(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            goodPrice: response.data,
                            refreshTable: !this.state.refreshTable,
                        })
                    }
                });
        }

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            ...response.data
                        ],
                    });
                }
            });

        getGoods()
            .then(response => {
                if (response.success) {
                    this.setState({
                        goods: response.data.map(good => ({
                            value: good.id,
                            label: `${good.nameWithMass} [${good.category.name}]`,
                        })),
                    });
                }
            });
    }

    handleChange = prop => event => {
        this.setState({
            goodPrice: {
                ...this.state.goodPrice,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeMoney = prop => event => {
        this.setState({
            goodPrice: {
                ...this.state.goodPrice,
                [prop]: {
                    val: event.target.value,
                    cur: defaultCurrency
                }
            }
        });
    };

    handleAdd = () => {
        saveGoodPrice(this.state.goodPrice)
            .then(response => {
                if (response.success) {
                    this.props.showInfo('Цена сохранена.');
                    this.props.history.push(`/goods/prices`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    getGoodStorageForGood = (...params) => {
        const { goodPrice } = this.state;
        if (goodPrice.id) {
            return getGoodStorages({
                statuses: [goodStorageStatuses.in_storage],
                goodPriceId: goodPrice.id
            }, ...params);
        }

        return getFalseResult();
    }

    render() {
        const { goodPrice, businessUnits, goods, refreshTable } = this.state;
        const disabled = !goodPrice.businessUnitId || !goodPrice.goodId || (!goodPrice.salePrice && !goodPrice.usePrice);
        const selectedBusinessUnit = businessUnits.find((businessUnit) => goodPrice.businessUnitId === businessUnit.id);
        let currency = null;
        if (selectedBusinessUnit) {
            currency = selectedBusinessUnit.mainCurrency;
        }

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAdd} disabled={disabled}>{ goodPrice.id ? "Изменить цену" : "Создать цену" }</ActionButton>
                </LeftBar>
                <Content title="Карточка цены товара">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AutocompleteSelectField
                                label="Товар"
                                value={goodPrice.goodId}
                                required
                                error={!goodPrice.goodId}
                                onChange={this.handleChange('goodId')}
                                fullWidth
                                options={goods}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="parlour"
                                label="Бизнес юнит"
                                value={goodPrice.businessUnitId}
                                required
                                error={!goodPrice.businessUnitId}
                                onChange={this.handleChange('businessUnitId')}
                                fullWidth
                                select
                            >
                                {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <MoneyField
                                label="Цена для продажи"
                                value={goodPrice.salePrice}
                                required
                                error={!goodPrice.salePrice && !goodPrice.usePrice}
                                currencies={[currency]}
                                onChange={this.handleChange('salePrice')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MoneyField
                                label="Цена для использования"
                                value={goodPrice.usePrice}
                                required
                                currencies={[currency]}
                                error={!goodPrice.salePrice && !goodPrice.usePrice}
                                onChange={this.handleChange('usePrice')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CheckboxField
                                value={goodPrice.expressDelivery}
                                onChange={this.handleChange('expressDelivery')}
                                label="Экспресс доставка"
                            />
                        </Grid>
                        {goodPrice.id ?
                            <>
                                <Grid item xs={12}><Typography variant="subtitle1">Товары на складе с этой ценой</Typography></Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        columnData={columnData}
                                        dataFunc={this.getGoodStorageForGood}
                                        refresh={refreshTable}
                                        selector={false}
                                    />
                                </Grid>
                            </>
                            : null
                        }
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}
