import React from "react";
import NoWrap from "./NoWrap";
import MoneyList from "./MoneyList";

const vgo = (value) => {

    return <NoWrap>
        <MoneyList title="f.VisitCommission" value={value.visit_commission}/>
        <MoneyList title="f.IncomingPayDocCommission" value={value.incoming_pay_doc_commission}/>
        <MoneyList title="f.MastersRent" value={value.masters_rent}/>
        <MoneyList title="f.IntragroupMarketing" value={value.intragroup_marketing}/>
    </NoWrap>;
};

export default vgo;
