import React from 'react';
import {
    Button,
    Dialog, DialogTitle, DialogContent, DialogActions, MenuItem
} from '@material-ui/core';
import {
    createRequest,
    requestTypes,
    getPayDocs,
    payDocStatuses, navLinks, groupNames, getPayDocTypes, importPayDocs
} from "../../services/payDocs";
import DataTable from "../../components/data-table/DataTable";
import _ from 'lodash';
import {getParlours} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import NumberRangeField from "../../components/fields/NumberRangeField";
import AnyField from "../../components/fields/AnyField";
import ShowField from "../../components/fields/ShowField";
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {hasRole} from "../../services/user";
import PayDocSellBarterDialog from "./PayDocSellBarterDialog";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import PayDocChangeValidityDialog from "./PayDocChangeValidityDialog";
import PayDocCreateMultiplePayDocsDialog from './PayDocCreateMultiplePayDocsDialog'
import PayDocsSaleDialog from "./PayDocsSaleDialog"
import PayDocsEqualRefillsDialog from "./PayDocsEqualRefillsDialog"
import moment from "moment";
import ImportDialog from "../../components/dialogs/ImportDialog";
import {payDocKeys} from "../../entities/importKeys";


const defaultFilter = {
    search: '',
    searchFrom: '',
    searchTo: '',
    status: null,
    pdType: null,
};

export default
@connect(null, {showMessage: messageDialogActions.show})
@withTranslation()
class ListPage extends React.PureComponent {
    constructor(props) {
        super(props);

        const filter = getFilterFromQuery(defaultFilter);

        this.state = {
            search: '',
            searchFrom: '',
            searchTo: '',
            status: null,
            refreshTable: false,
            selected: [],
            openImportModal: false,
            moveDialogOpen: false,
            moveDialogLoading: false,
            parlour: null,
            comment: null,
            parlours: [],
            page: 0,
            pdType: null,
            pdTypes: [],
            sellBarterDialogOpen: false,
            ...filter,
            payDocStatusesItems: Object.keys(payDocStatuses).map(status => ({
                id: status,
                name: props.t("payDocStatuses." + status),
            })),
            changeValidityDialogOpen: false,
            createMultiplePayDocsDialogOpen: false,

            dialogSalePayDocsOpen: false,
            dialogEqualPayDocsRefillsOpen: false,
            saleAndEqualRefillPayDocsGuestId: null
        };
    }

    columnData = [
        { id: 'num', numeric: false, disablePadding: true, label: 'Identifier' },
        { id: 'phone', numeric: false, disablePadding: false, label: 'Guest phone' },
        { id: 'fio', numeric: false, disablePadding: false, label: 'Full name', linkTemplate: payDoc => `/guests/view/${payDoc.guestId}` },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'nominalWithComment', disablePadding: false, label: 'Nominal' },
        { id: 'balanceText', numeric: true, disablePadding: false, label: 'Balance' },
        { id: 'validity', label: 'Expiration date to', processValue: dt => dt ? moment(dt).utcOffset(0, false).format('DD.MM.YYYY') : null },
        { id: 'parlour', label: 'Parlour' },
        { id: 'creator.username', label: 'Creator' },
        { id: 'createdAt', label: 'Created date', dateFormat: 'DD.MM.YYYY' },
        { id: 'comment', label: 'Comment' },
        { id: 'externalSubjectName', label: 'External subject' },
    ];

    getFilterObject = () => {
        const {search, searchFrom, searchTo, status, pdType} = this.state;

        return {search, searchFrom, searchTo, status, pdType};
    };

    refreshTable = () => {
        pushFilterToQuery(this.getFilterObject());
        this.setState(state => ({refreshTable: !state.refreshTable, page: 0}));
    };

    debounceRefresh = _.debounce(this.refreshTable, 500);

    handleChange = event => {
        const prop = event.target.name;
        this.setState({ [prop]: event.target.value });

        if (prop === 'searchFrom' || prop === 'searchTo' || prop === 'status' || prop === 'pdType') {
            this.debounceRefresh();
        }
    };

    componentDidMount() {
        getPayDocTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        pdTypes: response.data,
                    })
                }
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search !== this.props.location.search) {
            const filter = getFilterFromQuery(defaultFilter);
            if (!_.isEqual(filter, this.getFilterObject())) {
                this.setState({
                    ...filter,
                    refreshTable: !this.state.refreshTable,
                    page: 0,
                });
            }
        }
    }

    getTableData = (...params) => {
        const {search, searchFrom, searchTo, status, pdType} = this.state;
        return getPayDocs(search, searchFrom, searchTo, null,status === '*' ? null : status, null, [pdType], ...params);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    checkStatuses(selected) {
        const filtered = selected.filter(item => item.statusId === payDocStatuses.for_sale);
        return selected.length && selected.length === filtered.length;
    }

    handleMove = () => {
        this.setState({
            moveDialogOpen: true,
            moveDialogLoading: true,
            parlour: null,
            comment: null,
            parlours: [],
        });

        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        moveDialogLoading: false,
                        parlours: response.data,
                    });
                } else {
                    this.setState({
                        moveDialogLoading: false,
                    });
                }
            });
    };

    handleMoveDialogChange = prop => event => {
        this.setState({
            [prop]: event.target.value,
        })
    };

    handleCloseMoveDialog = ok => () => {
        const {parlour, comment} = this.state;

        if (!ok) {
            this.setState({
                moveDialogOpen: false
            });
            return;
        }

        createRequest(this.state.selected.map(item => item.id), requestTypes.move, {parlour, comment})
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/pay-docs/requests/view/${response.data.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    canSelectedSellBarter = () => {
        const { selected } = this.state;
        return hasRole('ROLE_PAYDOC_PAYDOC_SALE_BARTER') &&
            Array.isArray(selected) && Boolean(selected.length) && selected.every(payDoc => {
            return (payDoc?.type.isBarter || payDoc?.type.variant === "free") &&
                [payDocStatuses.for_sale, payDocStatuses.sold, payDocStatuses.active, payDocStatuses.expired, payDocStatuses.burned].indexOf(payDoc.statusId) >= 0 &&
                (payDoc.balance === 0 || payDoc.balance === null) &&
                (payDoc.frozen === 0 || payDoc.frozen === null) &&
                payDoc.typeId === selected[0].typeId
        });
    };

    handleSellBarter = () => {
        this.setState({
            sellBarterDialogOpen: true,
        });
    };

    handleCloseSellBarter = ok => {
        this.setState({
            refreshTable: ok ? !this.state.refreshTable : this.state.refreshTable,
            sellBarterDialogOpen: false,
        });
    };

    tableLinkTemplate = (item) => {
        const { bitrix_id, bitrix_user_id } = this.state;

        // add get parameters if link from bitrix24
        if (bitrix_id && bitrix_user_id) {
            return `/pay-docs/view/${item.id}?bitrix_id=${bitrix_id}&bitrix_user_id=${bitrix_user_id}`;
        } else {
            return `/pay-docs/view/${item.id}`;
        }
    };

    canChangeValidity = () => {
        const { selected } = this.state;
        return hasRole('ROLE_PAYDOC_PAYDOC_CHANGE_VALIDITY') &&
            Array.isArray(selected) && Boolean(selected.length) && selected.every(payDoc => {
                return [payDocStatuses.sold, payDocStatuses.active].indexOf(payDoc.statusId) >= 0;
            });
    }

    handleChangeValidity = () => {
        this.setState({
            changeValidityDialogOpen: true,
        });
    }

    handleCloseChangeValidity = ok => {
        this.setState({
            refreshTable: ok ? !this.state.refreshTable : this.state.refreshTable,
            changeValidityDialogOpen: false,
        });
    }

    handleCreateMultiplePayDocsDialogOpen = () => {

        this.setState({
            createMultiplePayDocsDialogOpen: true,
        })
    }

    handleCreateMultiplePayDocsDialogClose = () => {

        this.setState({
            createMultiplePayDocsDialogOpen: false,
        })

        this.debounceRefresh();
    }

    isSelectedPayDocsCanBeSaleAndEqualRefills = () => {
        const { selected } = this.state

        return selected.length > 0 &&
            selected.every(pd =>
                pd.type.measure === selected[0].type.measure &&
                pd.statusId === payDocStatuses.for_sale &&
                pd.canRefill &&
                !pd.saleId
            )
    }

    handleImportDialog = () => {
        this.setState({ openImportModal: true });
    }

    render() {
        const { t } = this.props;
        const {
            search,
            searchFrom,
            searchTo,
            refreshTable,
            selected,
            moveDialogOpen,
            moveDialogLoading,
            parlour,
            comment,
            parlours,
            page,
            status,
            pdType,
            pdTypes,
            sellBarterDialogOpen,
            payDocStatusesItems,
            changeValidityDialogOpen,
            createMultiplePayDocsDialogOpen,
            dialogSalePayDocsOpen,
            dialogEqualPayDocsRefillsOpen,
            saleAndEqualRefillPayDocsGuestId,
            openImportModal,
        } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search || ''}
                        name="search"
                        onChange={this.handleChange}
                        onClick={this.refreshTable}
                        label={t("Search for payment documents")}
                    />
                    <NumberRangeField
                        label={t("Search by number range of payment documents")}
                        valueFrom={searchFrom}
                        valueTo={searchTo}
                        nameFrom="searchFrom"
                        nameTo="searchTo"
                        onChangeFrom={this.handleChange}
                        onChangeTo={this.handleChange}
                        label2Lines
                    />
                    <SelectFromItemsField
                        items={payDocStatusesItems}
                        label={t("Status")}
                        value={status}
                        name="status"
                        onChange={this.handleChange}
                        fullWidth
                        nullable
                        nullableText={t("payDocStatuses.all")}
                    />
                    <SelectFromItemsField
                        label={t("Type of payment document")}
                        value={pdType}
                        items={pdTypes}
                        name="pdType"
                        onChange={this.handleChange}
                        fullWidth
                        nullable
                    />
                    <br />
                    <br />
                    <ActionButton disabled={!this.checkStatuses(selected)} visible={hasRole('ROLE_PAYDOC_PAYDOCREQUEST_MOVE')} onClick={this.handleMove}>{t("Transfer to another salon")}</ActionButton>
                    <ActionButton
                        onClick={this.handleCreateMultiplePayDocsDialogOpen}
                        visible={hasRole('ROLE_PAYDOC_CREATE_MULTIPLE_PAYDOCS')}
                    >
                        {t("Add certificates to the database")}
                    </ActionButton>
                    <ActionButton visible={this.canSelectedSellBarter()} onClick={this.handleSellBarter}>{t('Sell barter')}</ActionButton>
                    <ActionButton visible={this.canChangeValidity()} onClick={this.handleChangeValidity}>{t('Change validity')}</ActionButton>
                    <ActionButton
                        visible={
                            hasRole("ROLE_PAYDOC_SALE_AND_EQUAL_REFILLS_PAYDOCS")
                        }
                        onClick={() => this.setState({dialogSalePayDocsOpen: true})}
                        disabled={!this.isSelectedPayDocsCanBeSaleAndEqualRefills()}
                    >
                        {t('Sale and refill')}
                    </ActionButton>
                    <ActionButton onClick={this.handleImportDialog} visible={hasRole('ROLE_GUEST_GUEST_IMPORT')}>{'Импортировать'}</ActionButton>
                </LeftBar>
                <Content title={t("Payment documents")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={this.tableLinkTemplate}
                        onSelect={this.handleSelect}
                        refresh={refreshTable}
                        page={page}
                        autoload={false}
                    />
                </Content>
                {openImportModal && <ImportDialog
                    open={openImportModal}
                    onClose={() => this.setState({openImportModal: false})}
                    title={'Импорт ПД'}
                    importFunc={importPayDocs}
                    keys={payDocKeys}
                    chunk={20}/> }
                <Dialog
                    open={moveDialogOpen}
                    onClose={this.handleCloseMoveDialog(false)}
                    aria-labelledby="move-dialog-title"
                >
                    <DialogTitle id="move-dialog-title">{t("Payment documents will be transferred to the salon")}</DialogTitle>
                    <DialogContent>
                        <ShowField
                            label={t("Payment documents")}
                            value={groupNames(selected.map(item => item.num))}
                        />
                        <AnyField
                            id="parlour"
                            label={t("Parlour")}
                            value={parlour}
                            onChange={this.handleMoveDialogChange('parlour')}
                            disabled={moveDialogLoading}
                            select
                            fullWidth
                        >
                            {parlours.map(parlour =>
                                <MenuItem key={parlour.id} value={parlour.id}>
                                    {parlour.name}
                                </MenuItem>
                            )}
                        </AnyField>
                        <AnyField
                            id="comment"
                            label={t("Comment")}
                            value={comment}
                            disabled={moveDialogLoading}
                            multiline
                            fullWidth
                            rows="3"
                            onChange={this.handleMoveDialogChange('comment')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseMoveDialog(true)} disabled={!parlour} color="primary">OK</Button>
                        <Button onClick={this.handleCloseMoveDialog(false)} color="primary">{t("Cancel")}</Button>
                    </DialogActions>
                </Dialog>
                {sellBarterDialogOpen &&
                <PayDocSellBarterDialog
                    open={sellBarterDialogOpen}
                    onClose={this.handleCloseSellBarter}
                    payDocs={selected}
                />
                }
                {changeValidityDialogOpen &&
                <PayDocChangeValidityDialog
                    open={changeValidityDialogOpen}
                    onClose={this.handleCloseChangeValidity}
                    payDocs={selected}
                />
                }
                {createMultiplePayDocsDialogOpen &&
                    <PayDocCreateMultiplePayDocsDialog
                        open={createMultiplePayDocsDialogOpen}
                        pdTypes={pdTypes}
                        onClose={() => this.setState({createMultiplePayDocsDialogOpen: false})}
                        onCreate={this.debounceRefresh}
                    />
                }
                <PayDocsSaleDialog
                    open={dialogSalePayDocsOpen}
                    payDocs={selected}
                    onClose={() => this.setState({dialogSalePayDocsOpen: false})}
                    onCompleteSale={() => {
                        this.setState({
                            dialogSalePayDocsOpen: false,
                            dialogEqualPayDocsRefillsOpen: true
                        })
                    }}
                    onChangeGuestId={(guestId) => {
                        this.setState({saleAndEqualRefillPayDocsGuestId: guestId})
                    }}
                />
                <PayDocsEqualRefillsDialog
                    open={dialogEqualPayDocsRefillsOpen}
                    payDocs={selected}
                    onClose={() => {this.setState({dialogEqualPayDocsRefillsOpen: false})}}
                    onCompleteRefill={() => {this.setState({dialogEqualPayDocsRefillsOpen: false})}}
                    guestId={saleAndEqualRefillPayDocsGuestId}
                />
            </React.Fragment>
        );
    }
}
