import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getHumanEmployees} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getCurrentBillsStateEmployees, navLinks} from "../../services/moneyFlow";
import moment from "moment";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import {withTranslation} from "react-i18next";
import DateField2 from "../../components/fields/DateField2";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {hasRole} from "../../services/user";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@withStyles(styles)
@withTranslation()
class CurrentBillsStateEmployeePage extends React.Component {
    state = {
        search: {
            date: moment(),
            employeeIds: []
        },
        refreshTable: false,
        employees: [{ id: 0, name: this.props.t('---All---')}],
        progress: false,
    };

    componentDidMount() {
        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getCurrentBillsStateEmployees(processMomentFieldInObject(search, ['date']), ...params);
    };

    columnData = [
        { id: 'employee', label: 'Employee' },
        { id: 'name', label: 'Bill name' },
        { id: 'currentAmount', label: 'Balance', linkTemplate: (row) => {
            if (!hasRole('ROLE_MONEYFLOW_TRANSACTION_GET')) {
                return null
            }
            const {date} = this.state.search;
            const dateStr = date.format("YYYY-MM-DD");
            return `/money-flow/transactions?billId=${row.id}&date_start=2019-01-01&date_end=${dateStr}`;
        } },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:SS' },
    ];


    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, employees, progress } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <MultiSelectFromItemsField 
                        items={employees}
                        label={t('Employee')}
                        value={search.employeeIds}
                        onChange={this.handleChange('employeeIds')}
                        fullWidth
                        className={classes.filterControl}
                    />
                    <DateField2
                        value={search.date}
                        onChange={this.handleChange('date')}
                        label={t("Date")}
                        fullWidth
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                </LeftBar>
                <Content title={t('Employee bills state')}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        pagination={false}
                        autoload={false}
                    />
                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
