import React from 'react';
import moment from "moment";
import {getBusinessUnits, getRegions} from "../../services/organization";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getPayDocReportActivated,
    getPayDocStatuses,
    getPayDocTypes,
    navLinks
} from "../../services/payDocs";
import LeftBar from "../../components/left-bar/LeftBar";
import DataTable from "../../components/data-table/DataTable";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import _ from "lodash";
import XLSX from "xlsx";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import DatePeriodField from "../../components/fields/DatePeriodField";
import CheckboxField from "../../components/fields/CheckboxField";

export default
@withTranslation()
class PayDocReportActivatedPage extends React.Component
{
    state = {
        search: {
            search: null,
            dateStart: moment(),
            dateEnd: moment(),
            periodSaleStart: null,
            periodSaleEnd: null,
            regionIds: [],
            businessUnitIds: [],
            types: [],
            positiveBalance: false
        },
        refreshTable: false,
        businessUnits: [],
        regions: [],
        types: [],
        statuses: [],
    };

    processAction = value => {
        const { t } = this.props;
        return t('pdAction.' + value);
    };

    columnData = [
        {id: "row_number", label: '№'},
        {id: 'num', label: 'Номер'},
        {id: 'dateSale', label: 'Дата реализации ', dateFormat: 'DD.MM.YYYY'},
        {id: 'dateActive', label: 'Дата активации  ', dateFormat: 'DD.MM.YYYY'},
        {id: 'prevGuest', label: 'Предыдущий гость'},
        {id: 'prevGuestPhone', label: 'Телефон гость'},
        {id: 'guest', label: 'Текущий гость'},
        {id: 'guestPhone', label: 'Телефон гость'},
        {id: 'balance', label: 'Баланс'},
        {id: 'dayToDeadline', label: 'Дней до окончания срока '},
        {id: 'deadline', label: 'Дата окончания срока действия ', dateFormat: 'DD.MM.YYYY'},
    ];

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    });
                }
            });
        getPayDocTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data,
                    })
                }
            });
        getPayDocStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        statuses: response.data,
                    })
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getPayDocReportActivated(processMomentFieldInObject(search, ['dateStart', 'dateEnd', 'periodSaleStart', 'periodSaleEnd']), ...params);
    };

    handleExport = () => {
        const { search } = this.state;
        const rd = moment.isMoment(search.reportDate) ? search.reportDate.format('YYYY-MM-DD') : search.reportDate;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnData.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnData.map(item => {
                                const value = _.get(row, item.id);
                                if (item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                } else {
                                    return value;
                                }
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "PayDocs Activated");
                    XLSX.writeFile(new_workbook, `PayDocs Activated ${rd}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits, regions, types} = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField
                    valueFrom={search.dateStart}
                    valueTo={search.dateEnd}
                    maxDate={moment()}
                    onChangeFrom={this.handleChange('dateStart')}
                    onChangeTo={this.handleChange('dateEnd')}
                    label={t('Activation date')}
                />
                <DatePeriodField
                    valueFrom={search.periodSaleStart}
                    valueTo={search.periodSaleEnd}
                    maxDate={moment()}
                    onChangeFrom={this.handleChange('periodSaleStart')}
                    onChangeTo={this.handleChange('periodSaleEnd')}
                    label={t('Period sales')}
                />
                <MultiSelectFromItemsField
                    items={regions}
                    label={t('Region')}
                    value={search.regionIds}
                    onChange={this.handleChange('regionIds')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.businessUnitIds}
                    onChange={this.handleChange('businessUnitIds')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={types}
                    label={t('Payment document')}
                    value={search.types}
                    onChange={this.handleChange('types')}
                    fullWidth
                />
                <CheckboxField
                    label="Только с положительным балансом"
                    value={search.positiveBalance}
                    onChange={this.handleChange('positiveBalance')}
                />
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title="Платежные документы">
                <DataTable
                    columnData={this.columnData}
                    dataFunc={this.getTableData}
                    selector={false}
                    refresh={refreshTable}
                    disableSorting
                />
            </Content>
        </React.Fragment>;
    }
}