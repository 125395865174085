import moment from "moment";
import React from "react";
import {durationAsAllHHmm} from "../../../../../utils/moment-utils";

const dayMasterWorkload = value => {
    if (!value || value.length === 0) {
        return '';
    }


    const tableBody = value.sort((a, b) => {
        if (a.name === 'Всего') {
            return -2
        } else if (b.name === 'Всего') {
            return 2;
        } else if (a.name === 'Загрузка на мастера') {
            return -1;
        } else if (b.name === 'Загрузка на мастера') {
            return 1;
        }

        return a.name.localeCompare(b.name);
    }).map((master, index) => {

        /**
         * Приведение времени загрузки мастеров к кратности 5 минутам
         */
        const interval = 5 * 60 * 1000;

        let masterTotal = moment.duration(master.total, 'hours').asMilliseconds();
        let masterFree = moment.duration(master.free, 'hours').asMilliseconds();
        let masterUnfree = moment.duration(master.unfree, 'hours').asMilliseconds();

        let sum = moment.duration(Math.round(masterTotal / interval) * interval);
        let free = moment.duration(Math.round(masterFree / interval) * interval);
        let unFree = moment.duration(Math.round(masterUnfree / interval) * interval);

        if (master.name === 'Загрузка на мастера' && master.count) {
            let masterTotalAvg = moment.duration(master.total / master.count, 'hours').asMilliseconds();
            let masterFreeAvg = moment.duration(master.free / master.count, 'hours').asMilliseconds();
            let masterUnfreeAvg = moment.duration(master.unfree / master.count, 'hours').asMilliseconds();

            sum = moment.duration(Math.round(masterTotalAvg / interval) * interval);
            free = moment.duration(Math.round(masterFreeAvg / interval) * interval);
            unFree = moment.duration(Math.round(masterUnfreeAvg / interval) * interval);
        }

        return <tr key={index}>
            <td>{master.name}</td>
            <td>{durationAsAllHHmm(sum)}</td>
            <td>{durationAsAllHHmm(unFree)}</td>
            <td>{durationAsAllHHmm(free)}</td>
        </tr>;
    });

    return <table>
        <thead>
        <tr>
            <th></th>
            <th>&Sigma;</th>
            <th>пл</th>
            <th>б/п</th>
        </tr>
        </thead>
        <tbody>{tableBody}</tbody>
    </table>
};

export default dayMasterWorkload;
