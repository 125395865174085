import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    createSpendingTransfer,
    navLinks,
    getSpendingTypes,
    getIncomingDealTypes,
    dealIncomingTypeIds,
    dealSpendingTypeIds,
    emptyDeal,
    getIncomingTypes
} from "../../services/moneyFlow";
import AnyField from "../../components/fields/AnyField";
import {Divider, Fab, Grid, MenuItem} from "@material-ui/core";
import {emptyMoney, formatArrayMoney} from "../../services/common";
import infoActions from "../../components/info/info-actions";
import {
    getBusinessUnits,
    getHumanEmployees,
    getJuridicalPersons,
    getCheckingAccounts
} from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import MoneyField from "../../components/fields/MoneyField";
import {hasRole} from "../../services/user";
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import {getPaymentTypes} from "../../services/sale";
import {paymentTypes as paymentTypeConsts} from "../../services/sale";
import DateTimeField2 from "../../components/fields/DateTimeField2";

const styles = () => ({
    delButton: {top:18},
    dealForm: {marginBottom: 30, marginLeft: 2},
    divider: {marginTop: 30, marginBottom: 30}
});

const emptySpendingTransfer = {
    spendings: [{...emptyDeal}],
    incomings: [{...emptyDeal}],
    spendingReasonId: null,
    incomingReasonId: null,
    comment: null
}

const dealSpendingTransferTypes = {
    incomings: 'incomings',
    spendings: 'spendings',
}

const dealPaymentTypePropertyName = {
    paymentType: 'paymentType',
    incomingDealType: 'incomingDealType',
}

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class SpendingTransferPage extends React.Component {

    state = {
        spendingTransfer: {...emptySpendingTransfer},

        businessUnits: [],
        spendingTypes: [],
        incomingTypes: [],
        employees: [],
        trustedJuridicalPersons: [],
        dealIncomingTypes: [],
        paymentTypes:[],

        spendingTrustedJuridicalPersonsByIndex: [],
        incomingTrustedJuridicalPersonsByIndex: [],

        spendingCheckingAccountsByIndex: [],
        incomingCheckingAccountsByIndex: [],
    };

    componentDidMount() {
        getBusinessUnits({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            ...response.data
                        ],
                    });
                }
            });

        getSpendingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypes: [
                            ...response.data
                        ],
                    });
                }
        });

        getIncomingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        incomingTypes: [
                            ...response.data
                        ],
                    });
                }
        });

        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    });
                }
            });

        getIncomingDealTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        dealIncomingTypes: response.data,
                    });
                }
            });

        getPaymentTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        paymentTypes: response.data,
                    });
                }
            });
    }

    fetchSpendingTrustedJuridicalPersons = (spendingIndex) => {
        this.fetchTrustedJuridicalPersons(
            spendingIndex,
            dealSpendingTransferTypes.spendings,
            'spendingTrustedJuridicalPersonsByIndex'
        )
    }

    fetchIncomingTrustedJuridicalPersons = (i, businessUnitId) => {
        this.fetchTrustedJuridicalPersons(
            i,
            dealSpendingTransferTypes.incomings,
            'incomingTrustedJuridicalPersonsByIndex'
        )
    }

    fetchTrustedJuridicalPersons = (dealIndex, dealSpendingTransferType, propertyToSaveName) => {
        const { spendingTransfer } = this.state

        getJuridicalPersons({'trustedForBusinessUnit': spendingTransfer[dealSpendingTransferType][dealIndex].businessUnitId})
            .then(response => {
                if (response.success) {
                    this.setArrayElementState(
                        propertyToSaveName,
                        dealIndex,
                        response.data
                    )
                }
            });
    }

    fetchSpendingCheckingAccountsByIndex = (i) => {
        this.fetchCheckingAccounts(
            i,
            dealSpendingTransferTypes.spendings,
            'spendingCheckingAccountsByIndex'
        )
    }

    fetchIncomingCheckingAccountsByIndex = (i) => {
        this.fetchCheckingAccounts(
            i,
            dealSpendingTransferTypes.incomings,
            'incomingCheckingAccountsByIndex'
        )
    }

    fetchCheckingAccounts = (dealIndex, dealSpendingTransferType, propertyToSaveName) => {
        const { spendingTransfer } = this.state

        getCheckingAccounts({
            'juridicalPersonId': spendingTransfer[dealSpendingTransferType][dealIndex].juridicalPersonId, active: true
        })
            .then(response => {
                if (response.success) {
                    this.setArrayElementState(
                        propertyToSaveName,
                        dealIndex,
                        response.data
                    )
                }
            });
    }

    handleChangeSpending = (dealIndex, prop) => event => {
        this.setSpendingTransferDealState(
            dealSpendingTransferTypes.spendings,
            dealIndex,
            event.target.value,
            prop,
            () => {
                if( prop === 'businessUnitId') {
                    this.fetchSpendingTrustedJuridicalPersons(dealIndex);
                }

                if ( prop === 'juridicalPersonId' ) {
                    this.fetchSpendingCheckingAccountsByIndex(dealIndex);
                }
        })
    };

    handleChangeIncoming = (dealIndex, prop) => event => {
        this.setSpendingTransferDealState(
            dealSpendingTransferTypes.incomings,
            dealIndex,
            event.target.value,
            prop,
            () => {
                if( prop === 'businessUnitId') {
                    this.fetchIncomingTrustedJuridicalPersons(dealIndex, event.target.value);
                }

                if ( prop === 'juridicalPersonId' ) {
                    this.fetchIncomingCheckingAccountsByIndex(dealIndex);
                }
        })
    };

    setSpendingTransferDealState = (
        dealSpendingTransferType,
        dealIndex,
        value,
        dealPropertyName,
        callback = (f) => f
    ) =>  {
        const { spendingTransfer } = this.state

        const newValue = [...spendingTransfer[dealSpendingTransferType]]
        newValue[dealIndex][dealPropertyName] = value

        if ( dealPropertyName === 'paymentType' || dealPropertyName === 'incomingDealType' ) {
            newValue[dealIndex]['employeeId'] = null
            newValue[dealIndex]['toEmployeeId'] = null
            newValue[dealIndex]['juridicalPersonId'] = null
            newValue[dealIndex]['checkingAccountId'] = null
            newValue[dealIndex]['fromCheckingAccountId'] = null
            newValue[dealIndex]['toCheckingAccountId'] = null
        }

        this.setState({
            spendingTransfer: {
                ...spendingTransfer,
                [dealSpendingTransferType]: newValue
            }
        }, callback)
    }

    setArrayElementState = (
        property,
        index,
        value,
        subProperty = null,
        callback = (f) => f
    ) => {
        let newValue = [...this.state[property]]
        if (subProperty) {
            newValue[index][subProperty] = value
        } else {
            newValue[index] = value
        }

        this.setState({
            [property]: newValue
        }, callback)
    }

    handleChangeSpendingTransfer = prop => event => {
        this.setState({
            spendingTransfer: {
                ...this.state.spendingTransfer,
                [prop]: event.target.value,
            }
        })
    };

    handleCreateSpendingTransfer = () => {
        const { spendingTransfer } = this.state
        const { t } = this.props;

        createSpendingTransfer(spendingTransfer)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Spending transfer created'));
                    this.setState({
                        spendingTransfer: {
                            spendings: [{...emptyDeal}],
                            incomings: [{...emptyDeal}],
                            spendingReasonId: null,
                            incomingReasonId: null,
                            comment: null
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    isSpendingAndIncomingDealTypeDifferent = (i) => {
        const { spendingTransfer } = this.state
        const { spendings } = this.state.spendingTransfer

        return (
                    [dealSpendingTypeIds.spendingFromSafe, dealSpendingTypeIds.spendingFromCash]
                        .includes(spendings[i].spendingDealType) &&
                    spendingTransfer.dealIncomingType === dealIncomingTypeIds.incomingToCheckingAccount
               ) ||
               (
                   spendings[i].spendingDealType === dealSpendingTypeIds.spendingFromCheckingAccount &&
                   [dealIncomingTypeIds.incomingToSafe, dealIncomingTypeIds.incomingToCashBox]
                        .includes(spendingTransfer.dealIncomingType)
               )
    }

    handleDeleteSpending = (i) => {
        const { spendings } = this.state.spendingTransfer
        let newSpendings = [...spendings]
        newSpendings.splice(i, 1)
        this.setState({spendingTransfer: {...this.state.spendingTransfer, spendings: newSpendings}})
    }


    handleDeleteIncoming = (i) => {
        const { incomings } = this.state.spendingTransfer
        let newIncomings = [...incomings]
        newIncomings.splice(i, 1)
        this.setState({spendingTransfer: {...this.state.spendingTransfer, incomings: newIncomings}})
    }

    renderSpending = (spending, i) => {
        const {
            businessUnits,
            spendingTypes,
            spendingTrustedJuridicalPersonsByIndex,
            employees,
            spendingCheckingAccountsByIndex,
            paymentTypes
        } = this.state
        const { spendingReasonId } = this.state.spendingTransfer
        const {t, classes} = this.props
        const filteredPaymentTypes = paymentTypes.filter(
            pt => [paymentTypeConsts.cash, paymentTypeConsts.cashless].includes(pt.id)
        )
        let selectedBusinessUnit = businessUnits.find((item) => spending.businessUnitId === item.id);
        let currencies = selectedBusinessUnit ? [selectedBusinessUnit.mainCurrency] : [emptyMoney.cur];

        return <Grid container spacing={2} key={i} className={classes.dealForm}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <AutocompleteSelectField
                            label={t("Business unit where the expense will be")}
                            value={spending.businessUnitId}
                            required
                            error={!spending.businessUnitId}
                            onChange={this.handleChangeSpending(i, 'businessUnitId')}
                            fullWidth
                            options={businessUnits.map(item => ({value: item.id, label: item.name}))}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteSelectField
                            label={t("Spending type")}
                            value={spendingReasonId}
                            required
                            error={!spendingReasonId}
                            onChange={this.handleChangeSpendingTransfer('spendingReasonId')}
                            fullWidth
                            disabled={i > 0}
                            options={spendingTypes.map(item => ({
                                value: item.id,
                                label: `${item.name} / ${item.category.name}`
                            }))}
                        />
                    </Grid>
                    {
                        i > 0 &&
                        <Grid item xs={3}>
                            <Fab
                                color="secondary"
                                onClick={() => this.handleDeleteSpending(i)}
                                size={"small"}
                                className={classes.delButton}
                            >
                                <ClearIcon/>
                            </Fab>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={3}>
                        <SelectFromItemsField
                            label={t("Payment")}
                            value={spending.paymentType}
                            items={filteredPaymentTypes}
                            valueField={'id'}
                            textField={'text'}
                            onChange={this.handleChangeSpending(i, 'paymentType')}
                            fullWidth
                            required
                            error={!spending.paymentType}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MoneyField
                            label={t('Amount')}
                            currencies={currencies}
                            value={spending.amount}
                            error={!spending.amount}
                            onChange={this.handleChangeSpending(i, 'amount')}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.container}>
                    {paymentTypeConsts.cash === spending.paymentType && hasRole('ROLE_MONEYFLOW_DEAL_SPENDINGFROMEMPLOYEE') &&
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("Employee's money")}
                                value={spending.employeeId}
                                onChange={this.handleChangeSpending(i, 'employeeId')}
                                fullWidth
                                options={employees.map(item => ({value: item.id, label: `${item.name}`}))}
                                isClearable
                            />
                        </Grid>
                    }
                </Grid>
                {spending.paymentType === paymentTypeConsts.cashless &&
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label={t("From juridical person")}
                                value={spending.juridicalPersonId}
                                onChange={this.handleChangeSpending(i, 'juridicalPersonId')}
                                fullWidth
                                options={
                                    spendingTrustedJuridicalPersonsByIndex[i]
                                        ? spendingTrustedJuridicalPersonsByIndex[i].map(item => ({
                                            value: item.id,
                                            label: `${item.fullName}`
                                        }))
                                        : []
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SelectFromItemsField
                                label={t("From checking account")}
                                value={spending.fromCheckingAccountId}
                                onChange={this.handleChangeSpending(i, 'fromCheckingAccountId')}
                                fullWidth
                                items={
                                    spendingCheckingAccountsByIndex[i]
                                        ? spendingCheckingAccountsByIndex[i]
                                        : []
                                }
                                nullable
                                textField="bankName"
                            />
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                            <DateTimeField2
                                label={t("Cancellation fact")}
                                value={spending.cancellationFact}
                                onChange={this.handleChangeSpending(i, "cancellationFact")}
                                fullWidth
                                error={!spending.cancellationFact}
                                required
                            />
                    </Grid>
                </Grid>
        </Grid>
    }

    renderSpendings = () => {
        const { t, classes } = this.props
        const { spendings } = this.state.spendingTransfer
        const totalAmount = formatArrayMoney(this.sumMoneyArray(spendings.map(i => i.amount)))

        return <>
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={3}>
                        <Typography variant={"subtitle1"}>{t('Spending')}</Typography>
                    </Grid>
                </Grid>
                {spendings.map((spending, i) => this.renderSpending(spending, i))}
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={2}>
                        <ActionButton onClick={this.handleAddSpending}>{t('Add spending')}</ActionButton>
                    </Grid>
               </Grid>
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={2}>
                        <Typography variant={"subtitle2"}>{t('Total spending') + ': ' + totalAmount}</Typography>
                    </Grid>
               </Grid>
        </>
    }

    handleAddSpending = () => {
        this.handleAddDeal(dealSpendingTransferTypes.spendings)
    }

    handleAddIncoming = () => {
        this.handleAddDeal(dealSpendingTransferTypes.incomings)
    }

    handleAddDeal = (dealSpendingTransferType) => {
        const { spendingTransfer } = this.state
        const subProperty = dealSpendingTransferType === dealSpendingTransferTypes.spendings
            ? dealPaymentTypePropertyName.paymentType
            : dealPaymentTypePropertyName.incomingDealType
        const firstSelectedPaymentType = spendingTransfer[dealSpendingTransferType].find(s => s[subProperty])?.[subProperty] ?? null
        const firstSelectedEmployeeId = spendingTransfer[dealSpendingTransferType].find(s => s.employeeId)?.employeeId ?? null

        this.setState({
            spendingTransfer: {
                ...this.state.spendingTransfer,
                [dealSpendingTransferType]:
                    [
                        ...this.state.spendingTransfer[dealSpendingTransferType],
                        {
                            ...emptyDeal,
                            [subProperty]: firstSelectedPaymentType,
                            employeeId: firstSelectedEmployeeId
                        }
                    ]
            }

        })
    }

    isFormDataInvalid() {
        const {
            spendings, incomings, incomingReasonId, spendingReasonId
        } = this.state.spendingTransfer

        return !this.isTotalEqual() ||
               !spendingReasonId ||
               !incomingReasonId ||
                (spendings.find(s =>
                         !s.businessUnitId ||
                         !s.paymentType ||
                         !s.amount.val ||
                         !s.cancellationFact ||
                         !s.amount.cur ||
                            (s.paymentType === paymentTypeConsts.cashless &&
                                (!s.juridicalPersonId || !s.fromCheckingAccountId)
                            )
                 )) ||
                (incomings.find(s =>
                         !s.businessUnitId ||
                         !s.incomingDealType ||
                         !s.amount.val ||
                         !s.cancellationFact ||
                         !s.amount.cur ||
                            (s.incomingDealType === dealIncomingTypeIds.incomingToCheckingAccount &&
                                (!s.juridicalPersonId || !s.checkingAccountId)
                            )
                 ))
    }

    isTotalEqual = () => {
        const { spendings, incomings } = this.state.spendingTransfer

        let spendingAmounts = {}
        let incomingAmounts = {}
        let isEqual = true;

        spendings.forEach(s => {
            spendingAmounts[s.amount.cur] = spendingAmounts[s.amount.cur] ?? 0;
            spendingAmounts[s.amount.cur] += s.amount.val;
        })

        incomings.forEach(i => {
            incomingAmounts[i.amount.cur] = incomingAmounts[i.amount.cur] ?? 0;
            incomingAmounts[i.amount.cur] += i.amount.val;
        })

        if (spendingAmounts.length !== incomingAmounts.length) {
            isEqual = false
        }

        Object.keys(spendingAmounts).forEach(spendingCur => {
            if (
                !incomingAmounts[spendingCur] ||
                (spendingAmounts[spendingCur]).toFixed(2) !== (incomingAmounts[spendingCur]).toFixed(2)
            ) {
                isEqual = false
            }
        })

        Object.keys(incomingAmounts).forEach(incomingCur => {
            if (
                !spendingAmounts[incomingCur] ||
                (spendingAmounts[incomingCur]).toFixed(2) !== (incomingAmounts[incomingCur]).toFixed(2)
            ) {
                isEqual = false
            }
        })

        return isEqual
    }

    sumMoneyArray(array) {
        let result = []
        let a = [...array]
        a.forEach(money => {
                let i = result.findIndex(money2 => money2.cur === money.cur)
                if (i >= 0) {
                    result[i].val = (result[i].val ?? 0) + (money.val ?? 0)
                } else {
                    result.push({...money, val: money.val ?? 0})
                }
        })

        return result
    }

    renderIncomings() {
        const { classes, t } = this.props;
        const { incomings } = this.state.spendingTransfer
        const totalAmount = formatArrayMoney(this.sumMoneyArray(incomings.map(i => i.amount)))

        return <>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={3}>
                    <Typography variant={"subtitle1"}>{t('Incoming')}</Typography>
                </Grid>
            </Grid>
            {incomings.map((incoming, i) => this.renderIncoming(incoming, i))}
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={2}>
                    <ActionButton onClick={this.handleAddIncoming}>{t('Add incoming')}</ActionButton>
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={2}>
                    <Typography variant={"subtitle2"}>
                        {t('Total incoming') + ': ' + totalAmount}
                    </Typography>
                </Grid>
            </Grid>
        </>
    }

    renderIncoming = (incoming, i) => {
        const {
            businessUnits,
            incomingTypes,
            employees,
            dealIncomingTypes,
            incomingCheckingAccountsByIndex,
            incomingTrustedJuridicalPersonsByIndex
        } = this.state
        const { incomingReasonId } = this.state.spendingTransfer
        const {t, classes} = this.props

      let selectedBusinessUnit = businessUnits.find((item) => incoming.businessUnitId === item.id);
      let currencies = selectedBusinessUnit ? [selectedBusinessUnit.mainCurrency] : [emptyMoney.cur];

        return <Grid container spacing={2} key={i} className={classes.dealForm}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <AutocompleteSelectField
                        label={t("Income recipient")}
                        value={incoming.businessUnitId}
                        required
                        error={!incoming.businessUnitId}
                        onChange={this.handleChangeIncoming(i, 'businessUnitId')}
                        fullWidth
                        options={businessUnits.map(item => ({value: item.id, label: item.name}))}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AutocompleteSelectField
                        label={t("Incoming type")}
                        value={incomingReasonId}
                        required
                        error={!incomingReasonId}
                        onChange={this.handleChangeSpendingTransfer('incomingReasonId')}
                        fullWidth
                        disabled={i > 0}
                        options={incomingTypes.map(item => ({
                            value: item.id,
                            label: `${item.name}`
                        }))}
                    />
                </Grid>
                {
                    i > 0 &&
                    <Grid item xs={3}>
                        <Fab
                            color="secondary"
                            onClick={() => this.handleDeleteIncoming(i)}
                            size={"small"}
                            className={classes.delButton}
                        >
                            <ClearIcon/>
                        </Fab>
                    </Grid>
                }
            </Grid>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={3}>
                    <AnyField
                        id="type"
                        value={incoming.incomingDealType}
                        error={!incoming.incomingDealType}
                        onChange={this.handleChangeIncoming(i, 'incomingDealType')}
                        select
                        label={t("Payment type")}
                        required
                        fullWidth>
                        {dealIncomingTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                    </AnyField>
                </Grid>
                <Grid item xs={3}>
                    <MoneyField
                        label={t('Amount')}
                        currencies={currencies}
                        value={incoming.amount}
                        error={!incoming.amount}
                        onChange={this.handleChangeIncoming(i, 'amount')}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.container}>
                {
                    incoming.incomingDealType === dealIncomingTypeIds.incomingToCashBox &&
                    hasRole('ROLE_MONEYFLOW_DEAL_SPENDINGFROMEMPLOYEE') &&
                    <Grid item xs={3}>
                        <AutocompleteSelectField
                            label={t("To employee")}
                            value={incoming.toEmployeeId}
                            onChange={this.handleChangeIncoming(i, 'toEmployeeId')}
                            fullWidth
                            options={employees.map(item => ({value: item.id, label: `${item.name}`}))}
                            isClearable
                        />
                    </Grid>
                }
            </Grid>
                {
                    incoming.incomingDealType === dealIncomingTypeIds.incomingToCheckingAccount &&
                    <Grid container spacing={2} className={classes.container}>
                            <Grid item xs={3}>
                                <AutocompleteSelectField
                                    label={t("Juridical person")}
                                    value={incoming.juridicalPersonId}
                                    onChange={this.handleChangeIncoming(i, 'juridicalPersonId')}
                                    fullWidth
                                    options={
                                        incomingTrustedJuridicalPersonsByIndex[i]
                                            ? incomingTrustedJuridicalPersonsByIndex[i].map(item => ({
                                                value: item.id,
                                                label: `${item.fullName}`
                                            }))
                                            : []
                                    }
                                />
                            </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                id="toCheckingAccountId"
                                value={incoming.checkingAccountId}
                                error={!incoming.checkingAccountId}
                                onChange={this.handleChangeIncoming(i, 'checkingAccountId')}
                                select
                                label={t("Recipient checking account")}
                                required
                                fullWidth
                            >
                                {(incomingCheckingAccountsByIndex[i] ?? []).map(
                                    item => <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.bankName + ' / ' + item.juridicalPersonShortName}
                                    </MenuItem>
                                )}
                            </AnyField>
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={3}>
                        <DateTimeField2
                            label={t("Cancellation fact2")}
                            value={incoming.cancellationFact}
                            error={!incoming.cancellationFact}
                            required
                            onChange={this.handleChangeIncoming(i, "cancellationFact")}
                            fullWidth
                        />
                    </Grid>
                </Grid>
        </Grid>
    }

    renderCommon () {
        const { t } = this.props
        const { spendingTransfer } = this.state
        return <Grid container spacing={2}>
                <Grid item xs={3}>
                    <AnyField
                        label={t("Comment")}
                        value={spendingTransfer.comment}
                        required={!spendingTransfer.comment}
                        onChange={this.handleChangeSpendingTransfer('comment')}
                        multiline
                        rows={4}
                        fullWidth
                    />
                </Grid>
        </Grid>
    }
    render(){
        const { t, classes } = this.props;
        const disabled = this.isFormDataInvalid()

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton
                        onClick={this.handleCreateSpendingTransfer}
                        disabled={disabled}
                    >
                        {t('Create spending transfer')}
                    </ActionButton>
                </LeftBar>
                <Content title={t("Spending transfer")}>
                    {this.renderSpendings()}
                    <Divider className={classes.divider}/>
                    {this.renderIncomings()}
                    {this.renderCommon()}
                </Content>
            </React.Fragment>
        );
    }
}
