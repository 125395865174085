import {withTranslation} from "react-i18next";
import React from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import AnyField from "../../../components/fields/AnyField";
import {getGuestStats} from "../../../services/guests";
import {emptyGuestStats} from "../../../entities/guest";
import * as PropTypes from "prop-types";

export default @withTranslation()
class ViewPageTabStats extends React.PureComponent {
    static propTypes = {
        guest: PropTypes.object,
    };

    state = {
        stats: emptyGuestStats,
        loading: true,
    };

    componentDidMount() {
        getGuestStats(this.props.guest.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        stats: response.data,
                        loading: false,
                    })
                }
            });
    }

    render() {
        const {t} = this.props;
        const {guest} = this.props;
        const {stats} = this.state;

        return (
            this.state.loading
                ? <CircularProgress/>
                : <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <AnyField
                            label={t("Convenient days to visit")}
                            value={guest.convenientDays}
                            fullWidth
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AnyField
                            label={t("Convenient time to visit")}
                            value={guest.convenientHours}
                            fullWidth
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AnyField
                            label={t("Frequent master")}
                            value={stats.master.name}
                            fullWidth
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AnyField
                            label={t("Frequent parlour")}
                            value={stats.parlour.name}
                            fullWidth
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AnyField
                            label={t("Frequent procedure")}
                            value={stats.procedure.name}
                            fullWidth
                            readOnly
                        />
                    </Grid>
                </Grid>)
    }
}