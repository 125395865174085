import React from 'react'
import DatePeriodField2 from "./DatePeriodField2";
import moment from "moment/moment";
import DateMonthField from "./DateMonthField";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import * as PropTypes from "prop-types";
import {dateByTypeRadioSelectFieldDateTypes} from '../../services/common'

export default
@withTranslation()
class DateByTypeRadioSelectField extends React.Component {

    static propTypes = {
        onChange: PropTypes.func,
        dateType : PropTypes.string,
        startedAt: PropTypes.any,
        endedAt: PropTypes.any,
        month: PropTypes.any
    };

    static defaultProps = {
        onChange: f => f
    };

    render() {

        const {
            t,
            onChange,
            dateType,
            startedAt,
            endedAt,
            month
        } = this.props

        return (
            <>
            <RadioGroup
                value={dateType}
                onChange={onChange('dateType')}
                row
            >
                <FormControlLabel control={<Radio/>} label={t('For the period')} value={dateByTypeRadioSelectFieldDateTypes.period}/>
                <FormControlLabel control={<Radio/>} label={t('Per month')} value={dateByTypeRadioSelectFieldDateTypes.month}/>
            </RadioGroup>
            {dateType === dateByTypeRadioSelectFieldDateTypes.period
                ?   <DatePeriodField2
                        valueFrom={startedAt}
                        valueTo={endedAt}
                        maxDate={moment()}
                        onChangeFrom={onChange('startedAt')}
                        onChangeTo={onChange('endedAt')}
                        checkValuesOrder
                    />
                :   <DateMonthField
                        value={month}
                        onChange={onChange('month')}
                        fullWidth
                    />
            }
            </>
        )
    }
}