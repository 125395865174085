import baseRequest from "./baseReuqest";

export const navLinks = [
    {path: "/settings/users", text: "Пользователи", role: "ROLE_UI_TAB_SETTINGS_USERS"},
    {path: "/settings/setting-name-for-activity", text: "Операции для выгрузки", role: "ROLE_SETTING_EXPENSES_INCOMING", group: 'Setting up expenses/receipts'},
    {path: "/settings/type-activity", text: "Виды деятельности", role: "ROLE_SETTING_EXPENSES_INCOMING", group: 'Setting up expenses/receipts'},
    {path: "/settings/activity-group", text:"Группы деятельности", role: "ROLE_SETTING_EXPENSES_INCOMING", group:  'Setting up expenses/receipts'},
    {path: "/settings/upload-period-settings", text: "Принудительная выгрузка ДДС", role: "ROLE_UI_TAB_SETTINGS_GENERAL", group: 'Setting up expenses/receipts'},
    {path: "/settings/business-roles", text: "Бизнес роли", role: "ROLE_UI_TAB_SETTINGS_BUSINESSROLE"},
    {path: "/settings/targets", text: "Темы взаимодействия", role: "ROLE_UI_TAB_SETTINGS_TARGETS"},
    {path: "/settings/goods", text: "Товары", role: "ROLE_UI_TAB_SETTINGS_GOODS"},
    {path: "/settings/good-categories", text: "Категории товаров", role: "ROLE_UI_TAB_SETTINGS_GOODCATEGORIES"},
    {path: "/settings/prices", text: "Прайсы", role: "ROLE_UI_TAB_SETTINGS_PRICES"},
    {path: "/settings/discounts", text: "Скидки", role: "ROLE_UI_TAB_SETTINGS_DISCOUNTS"},
    {path: "/settings/discount-group", text: "Группы скидок", role: "ROLE_UI_TAB_SETTINGS_GENERAL"},
    {path: "/settings/discount-date-restriction", text: "Ограничения скидок", role: "ROLE_UI_TAB_SETTINGS_GENERAL"},
    {path: "/settings/spending-types", text: "Статьи расходов", role: "ROLE_UI_TAB_SETTINGS_SPENDINGTYPES", group: 'Setting up expenses/receipts'},
    {
        path: "/settings/spending-types/categories",
        text: "Категории расходов",
        role: "ROLE_UI_TAB_SETTINGS_SPENDINGTYPESCATEGORIES",
        group: 'Setting up expenses/receipts'
    },
    {path: "/settings/incoming-types", text: "Статьи приходов", role: "ROLE_UI_TAB_SETTINGS_INCOMINGTYPES", group: 'Setting up expenses/receipts'},
    {path: "/settings/club-subscription-types", text: "Типы подписок", role: "ROLE_UI_TAB_SETTINGS_CLUB_SUBSCRIPTION_TYPES"},
    {path: "/settings/visit-channels-page", text: "Каналы-источники визитов", role: "ROLE_UI_TAB_SETTINGS_VISIT_CHANNELS"},

    {
        path: "/settings/medical-card-zone-prescription",
        text: "Предписания (Мед.Карты)",
        role: "ROLE_UI_TAB_SETTINGS_INCOMINGTYPES"
    },
    {path: "/settings/general", text: "Общие настройки", role: "ROLE_UI_TAB_SETTINGS_GENERAL"},
    {path: "/settings/whatsapp", text: "Whatsapp настройки", role: "ROLE_UI_TAB_SETTINGS_GENERAL"},
    {path: "/settings/system-telegram-channels", text: "Телеграм каналы", role: "ROLE_UI_TAB_SETTINGS_TELEGRAM_CHANNELS"},
    {path: "/settings/system-events", text: "Системные события", role: "ROLE_UI_TAB_SETTINGS_SYSTEM_EVENTS"},
    {path: "/settings/dom-ru-phones", text: "Дом.Ру телефоны", role: "ROLE_UI_TAB_SETTINGS_DOM_RU_PHONES"},
    {path: "/settings/bitrix", text: "BITRIX24"},
    {path: "/settings/mobile", text: "Настройки МП"},
    {path: "/settings/executors", text: "EXECUTORS", role: "ROLE_ANALYST"},
    {path: "/settings/exports", text: "Экспорт в гугл-таблицы", role: "ROLE_ANALYST"},
];

export const settingPropertyNames = {
    distributionOfDividendsToSalonInvestorsSpendingTypeId: 'distributionOfDividendsToSalonInvestorsSpendingTypeId',
    loanRepaymentSpendingTypeId: 'loanRepaymentSpendingTypeId'
}

export function getGeneralSettings() {
    return baseRequest('/api/settings/general');
}

export function checkPasswordRecovery() {
    return baseRequest('/api/settings/password-recovery')
}

export function getGeneralProperties() {
    return baseRequest('/api/settings/general/properties');
}

export function getGeneralProperty(propName) {
    return baseRequest(`/api/settings/general/property/${propName}`);
}


export function saveGeneralSettings(settings) {
    return baseRequest('/api/settings/general',{
        method: 'PUT',
        body: settings,
    });
}

export function loadSystemTelegramChannelsList(search, page, per_page, order, order_by) {
    return baseRequest('/api/telegram/load-system-telegram-channels-list', {
        query: {search, page, per_page, order, order_by}
    });
}

export function toggleChannelSystemStatus(channelId) {
    return baseRequest(`/api/telegram/toggle-system-channel-status/id/${channelId}`);
}

export function TransactionsToGoogleSheets(data){
    return baseRequest('/api/settings/upload-period-settings', {
        method: 'POST',
        body: data,
    });
}

export function getClubSubscriptionTypes(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let addUrl = `?search=${search}&page=${page}&per_page=${per_page ? per_page : -1}&order=${order}&order_by=${order_by}`;
    return baseRequest(`/api/guest-club/get-club-subscription-types${addUrl}`)
}

export function updateOrCreateSubscriptionType(subscription) {

    return baseRequest('/api/guest-club/update-or-create-subscription-type', {
        method: "POST",
        body: JSON.stringify(subscription)
    })
}

export function deleteClubSubscriptionTypes(id){
    return baseRequest(`/api/guest-club/delete-subscription-type/${id}`);
}

export function getVisitChannelForTable(page = 1, per_page = -1, order = 'asc', order_by = 'sort') {
    let addUrl = `?page=${page}&per_page=${per_page ? per_page : -1}&order=${order}&order_by=${order_by}`;
    return baseRequest(`/api/visit/get-visit-channels-for-table${addUrl}`)
}

export function updateOrCreateVisitChannel(visitChannel) {

    return baseRequest('/api/visit/update-or-create-visit-channel', {
        method: "POST",
        body: JSON.stringify(visitChannel)
    })
}

export function deleteVisitChannel(id){
    return baseRequest(`/api/visit/delete-visit-channel/${id}`);
}

export function getDomRuPhonesForTable(page = 1, per_page = -1, order = 'asc', order_by = 'sort') {
    let addUrl = `?page=${page}&per_page=${per_page ? per_page : -1}&order=${order}&order_by=${order_by}`;
    return baseRequest(`/api/domru/get-dom-ru-phones${addUrl}`)
}

export function updateDomRuPhone(domRuPhone) {

    return baseRequest('/api/domru/update-dom-ru-phone', {
        method: "POST",
        body: JSON.stringify(domRuPhone)
    })
}

export function syncDomRuPhones() {
    return baseRequest('/api/dom-ru/dom-ru-phones-sync', {
        method: 'PUT',
    });
}

export function getMaintenanceInfoSettings(id){
    return baseRequest(`/api/settings/maintenance-info`);
}

export function saveMaintenanceInfo(array){
    return baseRequest(`/api/settings/maintenance-info`);
}

export function updateMaintenanceInfo(data) {
    return baseRequest('/api/settings/maintenance-info', {
        method: 'POST',
        body: JSON.stringify(data),
    })
}

export function getExecutorsForTable(page = 1, per_page = -1, order = 'asc', order_by = 'sort') {
    let addUrl = `?page=${page}&per_page=${per_page ? per_page : -1}&order=${order}&order_by=${order_by}`;
    return baseRequest(`/api/executors${addUrl}`)
}

export function getExportsSqlDocsForTable(page = 1, per_page = -1, order = 'asc', order_by = 'sort') {
    let addUrl = `?page=${page}&per_page=${per_page ? per_page : -1}&order=${order}&order_by=${order_by}`;
    return baseRequest(`/api/export/get${addUrl}`)
}

export function getExecutor(id){
    return baseRequest(`/api/executor/${id}`);
}

export function getExport(id){
    return baseRequest(`/api/export-get/${id}`);
}

export function createExecutor(executor) {

    return baseRequest('/api/executor/create', {
        method: "POST",
        body: JSON.stringify(executor)
    })
}

export function createExport(exportState) {

    return baseRequest('/api/export/create', {
        method: "POST",
        body: JSON.stringify(exportState)
    })
}

export function updateExecutor(executor) {

    return baseRequest('/api/executor/update', {
        method: "POST",
        body: JSON.stringify(executor)
    })
}

export function updateExport(exportState) {

    return baseRequest('/api/export/update', {
        method: "POST",
        body: JSON.stringify(exportState)
    })
}
export function deleteExecutor(id) {
    return baseRequest(`/api/executor/delete/${id}`);
}

export function deleteExport(id) {
    return baseRequest(`/api/export/delete/${id}`);
}

export function getExecutingQueryDuration(executor) {

    return baseRequest('/api/executor/duration?per_page=-1', {
        method: "POST",
        body: JSON.stringify(executor)
    })
}


export function executeExport(id) {
    return baseRequest(`/api/export/execute/${id}`)
}