import React from "react"
import * as PropTypes from "prop-types"
import {getParlours} from "../../services/organization";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import {withTranslation} from "react-i18next";
import GuestField from "../../components/fields/GuestField";
import {paymentStatus, salePayTypes} from "../../services/sale";
import NumberFormatDecimal3 from "../../components/fields/NumberFormatDecimal3";
import {
    updateSaleOfEqualPayDocsRefills,
    createSaleOfEqualPayDocsRefills,
    closeSaleOfEqualPayDocsRefills,
    removeSaleOfEqualPayDocsRefills
} from "../../services/sale";
import Pay from "../../components/pay/Pay";
import _ from "lodash";
import infoActions from "../../components/info/info-actions";
import {connect} from "react-redux";
import {saleStatuses} from "../../services/goods";
import {getDiscounts} from "../../services/calendar";
import CheckboxField from "../../components/fields/CheckboxField";
import {getGeneralProperty} from "../../services/settings";
import {getWorkShiftCurrentBusinessUnit} from "../../services/calendarEmployee";

const blankSale = {
    id: null,
    businessUnitId: null,
    businessUnitName: null,
    visitBusinessUnitId: null,
    guestId: null,
    total: {
        val: 0,
        cur: null
    }
}

const blankRefill = {
    measure: null,
    amountByOne: 0,
    discountId: null,
    onlyPaymentLink: false,
}

const blankState = {
    open: false,
    updating: false,
    refreshSale: false,
    paymentsNotPaid: false,
    onlyProcessingPayments: false,
    rechargeError: null,
    siteBusinessUnitId: null,
    currentBusinessUnitId: null,
    sale: blankSale,
    refill: blankRefill
}
export default
@connect(() => ({}),
    {
        showError: infoActions.showError,
        showInfo: infoActions.show
    }
)
@withTranslation()
class PayDocsEqualRefillsDialog extends React.PureComponent {

    static propTypes = {
        open: PropTypes.bool,
        payDocs: PropTypes.array,
        onClose: PropTypes.func,
        onCompleteRefill: PropTypes.func,
        guestId: PropTypes.string
    }

    static defaultProps = {
        open: false,
        payDocs: [],
        guestId: null
    }

    state = {
        parlours: [],
        discounts: [],
        ...blankState
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        const measure = nextProps.payDocs.length > 0 ? nextProps.payDocs[0].type.measure: null
        const businessUnitId = nextProps.payDocs.length > 0 ? nextProps.payDocs[0].businessUnitId : null

        let state = prevState
        state.refill.measure = measure

        if (nextProps.open !== prevState.open) {
            state.open = nextProps.open
            state.sale = blankSale
            state.refill = blankRefill

            if (nextProps.guestId) {
                state.sale.guestId = nextProps.guestId
            }

        }

        if (businessUnitId && !prevState.sale.businessUnitId) {
            state.sale.businessUnitId = businessUnitId
            state.sale.visitBusinessUnitId = businessUnitId
        }

        return state
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.isDialogOpened(prevProps)) {
            getWorkShiftCurrentBusinessUnit()
                .then(response => {
                    if (response.success && response.data.id) {
                        this.setState(state => ({
                                sale: {
                                    ...state.sale,
                                    businessUnitId: response.data.id
                                }
                            }),
                            this.fetchDiscounts
                        );
                    } else {
                        if (this.state.sale.businessUnitId) {
                            this.fetchDiscounts()
                        }
                    }
                });

            getParlours()
                .then(response => {
                    if (response.success) {
                        this.setState({
                            parlours: response.data
                        })
                    }
                })

            getGeneralProperty('siteBusinessUnitId')
                .then(response => {
                    if (response.success) {
                        this.setState(state => ({
                            siteBusinessUnitId: response.data,
                        }))
                    }
                });
        }

        if(prevState.sale.businessUnitId !== this.state.sale.businessUnitId &&
            this.state.sale.businessUnitId &&
            this.state.sale.visitBusinessUnitId &&
            this.props.open
        ) {
            this.fetchDiscounts(this.state.sale.visitBusinessUnitId)
        }
    }

    isDialogOpened = (prevProps) => {
        return !prevProps.open && this.props.open
    }

    handleChangeSale = (event) => {

        const { sale, refill } = this.state

        let newState = {
            sale: {
                ...sale,
                [event.target.name] : event.target.value
            }
        }

        if (event.target.name === 'visitBusinessUnitId') {
            newState.refill = {
                ...refill,
                discountId: null
            }
        }

        this.setState(
            newState,
            this.updateDataDebounce
        )
    }

    handleChangeRefill = (event) => {

        let newState = {
            refill:{
                ...this.state.refill,
                [event.target.name]: event.target.value
            }
        }

        if (event.target.name === 'onlyPaymentLink' && event.target.value) {
            newState.sale = {
                ...this.state.sale,
                discountId: null
            }
            newState.sale.businessUnitId = this.state.siteBusinessUnitId
            this.fetchDiscounts(this.state.businessUnitId)
        }

        this.setState(
            newState,
            this.updateDataDebounce
        )
    }

    handleCloseSale = () => {

        const { t } = this.props

        this.setUpdating(true)

        closeSaleOfEqualPayDocsRefills(this.state.sale.id)
            .then(response => {

                this.setUpdating(false)

                if (response.success) {
                    this.props.showInfo(t("PayDocs was successful refilled"))
                    this.props.onCompleteRefill()
                    this.props.onClose()
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            })
    }

    handleChangePay = (payments) => {
        const { sale } = this.state
        let summ;

        if (payments.length > 0) {
            summ = payments.reduce(function (sumAmount, payment) {
                if (payment.status === 'paid')
                    return payment.amount.val + sumAmount;
                else
                    return sumAmount;
            }, 0);
        }

        if (summ === sale.total.val && summ > 0)
            this.setState({
                canPrintReceipt: true
            });

        this.setState({
            paymentsNotPaid: payments.filter(payment => payment.status !== paymentStatus.paid).length > 0,
            onlyProcessingPayments: payments.length > 0 && payments.every(p => p.status === paymentStatus.processing)
        });
    };

    handleRemoveSale = () => {

        const { sale } = this.state

        removeSaleOfEqualPayDocsRefills(sale.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        sale: blankSale,
                    })

                    this.handleCloseDialog()

                } else {
                    this.props.showError(response.error ? response.error.message : response.message);                }
            })
    }

    handleCloseDialog = () => {

        const { onlyProcessingPayments } = this.state

        if (this.isSaleUnclosed() && !onlyProcessingPayments) {
            this.handleRemoveSale()
        }

        this.props.onClose()
    }

    createOrUpdateSale = () => {

        const { payDocs } = this.props
        const { id, guestId, businessUnitId, visitBusinessUnitId } = this.state.sale
        const { amountByOne, discountId } = this.state.refill
        if (
            payDocs.length > 0 &&
            guestId &&
            businessUnitId &&
            visitBusinessUnitId &&
            amountByOne &&
            amountByOne > 0
        ) {
            const request = {
                guestId,
                payDocIds: payDocs.map(pd => pd.id),
                businessUnitId,
                visitBusinessUnitId,
                amountByOne,
                saleId: id,
                discountId
            }

            this.setUpdating(true, {rechargeError: null})

            if (request.saleId) {
                updateSaleOfEqualPayDocsRefills(request)
                    .then(this.processCreateOrUpdateSaleResponse)
            } else {
                createSaleOfEqualPayDocsRefills(request)
                    .then(this.processCreateOrUpdateSaleResponse)
            }
        }
    }

    processCreateOrUpdateSaleResponse = (response) => {
        if (response.success) {
            this.setState({
                sale: {
                    ...response.data,
                    visitBusinessUnitId: this?.state?.sale?.visitBusinessUnitId
                },
                refreshSale: !this.state.refreshSale
            })
        } else {
            this.setState(
                {
                    rechargeError: response.error ? response.error.message : response.message
                }
            )
            this.props.showError(response.error ? response.error.message : response.message);
        }
        this.setUpdating(false)
    }

    fetchDiscounts = (businessUnitId) => {
        getDiscounts({
            forParlour: businessUnitId,
            forSalableEntity: 'PayDocRefill'
        })
            .then(response => {
                if (response.success) {
                    let newState = {
                        discounts:response.data,
                    };

                    let discount = response.data.find(discount => discount.defaultSelected === true);

                    if (discount) {
                        newState.discountId = discount.id
                    }

                    this.setState( newState );
                }
            });
    }

    updateDataDebounce = _.debounce(this.createOrUpdateSale, 500)

    setUpdating = (updating, stateParams = {}) => {
        this.setState({
            ...stateParams,
            updating
        })
    }

    isSaleUnclosed = () => {

        const { sale } = this.state

        return sale.id && sale.status !== saleStatuses.closed
    }

    render() {

        const {
            parlours,
            discounts,
            sale,
            updating,
            refreshSale,
            paymentsNotPaid,
            rechargeError,
        } = this.state

        const {
            measure,
            amountByOne,
            discountId,
            onlyPaymentLink
        } = this.state.refill

        const { t, payDocs, open } = this.props

        return <Dialog
                    open={open}
                    fullWidth
                    onClose={this.handleCloseDialog}
                    maxWidth="md"
                    disableBackdropClick={updating}
                    disableEscapeKeyDown={updating}
            >
                <DialogTitle>{t("payDoc.refillTitle2")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AnyField
                                label={t('payDoc.refillBusinessUnit')}
                                value={sale.businessUnitId}
                                name={"businessUnitId"}
                                onChange={this.handleChangeSale}
                                select
                                fullWidth
                                disabled={updating}
                                readOnly={onlyPaymentLink}
                            >
                                {parlours.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <GuestField
                                label={t("Guest")}
                                value={sale.guestId}
                                error={!sale.guestId}
                                name={"guestId"}
                                onChange={this.handleChangeSale}
                                required
                                readOnly={updating}
                            />
                        </Grid>
                    <Grid item xs={2}>
                        <AnyField
                            value={payDocs.length}
                            label={t("Count of PD")}
                            error={payDocs.length === 0}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AnyField
                            label={(measure === 'money' ? t('payDoc.amountMoneyByOne') : t('payDoc.amountTimeByOne'))}
                            value={amountByOne}
                            required
                            error={!sale.total.val || Boolean(rechargeError)}
                            name={"amountByOne"}
                            onChange={this.handleChangeRefill}
                            fullWidth
                            InputProps={{
                                inputComponent: NumberFormatDecimal3,
                            }}
                            readOnly={updating}
                            onFocus={event => event.target.select()}
                            helperText={rechargeError}
                        />
                    </Grid>
                        <Grid item xs={2}>
                            <AnyField
                                label={t("Total money")}
                                value={sale.total.val}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AnyField
                                label={t('payDoc.discount')}
                                value={discountId}
                                fullWidth
                                select
                                name={"discountId"}
                                onChange={this.handleChangeRefill}
                                readOnly={updating || !sale.businessUnitId}
                            >
                                <MenuItem value={null}>{t('Not chosen')}</MenuItem>
                                {discounts.map(discount => <MenuItem key={discount.id} value={discount.id}>{discount.name}</MenuItem>)};
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <AnyField
                                label={t('payDoc.visitBusinessUnit')}
                                value={sale.visitBusinessUnitId}
                                name={"visitBusinessUnitId"}
                                onChange={this.handleChangeSale}
                                disabled={updating}
                                readOnly={onlyPaymentLink}
                                select
                                fullWidth
                            >
                                {parlours.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid> 
                        <Grid item xs={6}>
                            <CheckboxField
                                value={onlyPaymentLink}
                                label={t("Generate a link to payment")}
                                name={"onlyPaymentLink"}
                                onChange={this.handleChangeRefill}
                                margin={"none"}
                            />
                        </Grid>
                    <Grid item xs={12}>
                        <Pay
                            saleId={sale.id}
                            availablePaymentTypes={
                                onlyPaymentLink ?
                                [salePayTypes.cashless]:
                                [salePayTypes.cash, salePayTypes.cashless]
                            }
                            refreshSale={refreshSale}
                            disabled={updating || !sale.guestId || amountByOne <= 0 || Boolean(rechargeError)}
                            onChange={this.handleChangePay}
                            onlyPaymentLinks={onlyPaymentLink}
                            disablePaymentLinks={!onlyPaymentLink}
                            enableCheckingAccountPaymentType={!onlyPaymentLink}
                        />
                    </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={updating || paymentsNotPaid || !sale.id}
                        color={"primary"}
                        onClick={this.handleCloseSale}
                    >
                        {t("payDoc.confirmRefill")}
                    </Button>
                    <Button
                        disabled={updating || !sale.id}
                        color={"primary"}
                        onClick={this.handleRemoveSale}
                    >
                        {t("Remove")}
                    </Button>
                    <Button
                        disabled={updating}
                        color={"primary"}
                        onClick={this.handleCloseDialog}
                    >
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>
    }
}