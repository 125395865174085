import baseRequest from "./baseReuqest";
import * as queryString from 'query-string-object';
import moment from "moment";
import {emptyMoney} from "./common";
import {paymentTypes} from "./sale";

export const navLinks = [
    { path: "/money-flow/bills/current-state", text: "Unit account status", role: "ROLE_UI_TAB_MONEYFLOW_BILL_CURRENTSTATE", group: "Reports" },
    { path: "/money-flow/bills/current-state-employee", text: "Employee bills state", role: "ROLE_UI_TAB_MONEYFLOW_BILL_CURRENTSTATE_EMPLOYEE", group: "Reports" },
    { path: "/money-flow/balance/changes", text: "Balance changes", role: "ROLE_UI_TAB_MONEYFLOW_BALANCECHANGES", group: "Reports"},
    { path: "/money-flow/transactions", text: "Money moving", role: "ROLE_UI_TAB_MONEYFLOW_BALANCECHANGES", group: "Reports" },
    { path: "/money-flow/transactions-cash", text: "Cash moving", role: "ROLE_UI_TAB_MONEYFLOW_BALANCECHANGES", group: "Reports" },
    { path: "/money-flow/transactions-cashless", text: "Cashless moving", role: "ROLE_UI_TAB_MONEYFLOW_BALANCECHANGES", group: "Reports" },
    { path: "/money-flow/convert", text: "Create money transfer", role: "ROLE_UI_TAB_MONEYFLOW_CONVERT" },
    { path: "/money-flow/spending", text: "Create money spending", role: "ROLE_UI_TAB_MONEYFLOW_SPENDING" },
    { path: "/money-flow/spending-transfer", text: "Create spending transfer", role: "ROLE_UI_TAB_MONEYFLOW_SPENDING_TRANSFER" },
    { path: "/money-flow/incoming", text: "Create money incoming", role: "ROLE_UI_TAB_MONEYFLOW_INCOMING" },
    { path: "/money-flow/limits", text: "Budget", role: "ROLE_UI_TAB_MONEYFLOW_LIMITS" },
    { path: "/money-flow/deals-overrated", text: "Overrun requests", role: "ROLE_UI_TAB_MONEYFLOW_DEALSOVERRATED" },
    { path: "/money-flow/incoming-employee/report", text: "Employee incoming", role: "ROLE_UI_TAB_MONEYFLOW_INCOMINGEMPLOYEE_REPORT", group: "Reports" },
    { path: "/money-flow/spending/report", text: "Budget limits report", role: "ROLE_UI_TAB_MONEYFLOW_SPENDINGTYPE_REPORT", group: "Reports" },
    { path: "/money-flow/summary-day-report", text: "Summary day report", role: "ROLE_UI_TAB_MONEYFLOW_SUMMARYDAYREPORT", group: "Reports" },
    { path: "/money-flow/summary-report-period", text: "Summary report for period", role: "ROLE_UI_TAB_MONEYFLOW_SUMMARYDAYREPORT_PERIOD", group: "Reports" },
    { path: "/money-flow/finance-report", text: "Finance report", role: "ROLE_UI_TAB_MONEYFLOW_FINANCEREPORT", group: "Reports" },
    { path: "/money-flow/visits-payments-report", text: "Visit payments report", role: "ROLE_UI_TAB_MONEYFLOW_VISITSPAYMENTSREPORT", group: "Reports" },
    { path: "/money-flow/salary", text: "Salary report", role: "ROLE_UI_TAB_MONEYFLOW_SALARYREPORT", group: "Reports" },
    { path: "/money-flow/referral-partners-report", text: "Referral partners payments", role: "ROLE_UI_TAB_ORGANIZATION_REFERRAL_PARTNER_REPORTFULL_GET", group: "Reports" },
    { path: "/money-flow/deal", text: "Deals", role: "ROLE_UI_TAB_MONEYFLOW_DEAL_GET", group: "Reports" },
    { path: "/money-flow/dashboard", text: "Dashboard", role: "ROLE_MONEYFLOW_DASHBOARD_REPORT", group: "Reports" },
    { path: "/money-flow/admins-and-masters-dashboard", text: "Admin and Masters Dashboard", role: "ROLE_MONEYFLOW_DASHBOARD_REPORT", group: "Reports" },
    { path: "/money-flow/gratuity-report", text: "Gratuity", role: "ROLE_SALE_GRATUITYREPORT", group: "Reports" },
    { path: "/money-flow/spending-report", text: "Spending report", role: "ROLE_UI_TAB_MONEYFLOW_DEAL_GET", group: "Reports" },
    { path: "/money-flow/settlement-hotels", text: "SettlementHotels", role: "ROLE_UI_TAB_MONEYFLOW_SETTLEMENTHOTELS", group: 'Mutual reports' },
    { path: "/money-flow/mutual-pay-doc-reports", text: "Mutual reports PayDoc", role: "ROLE_UI_TAB_MONEYFLOW_DEAL_GET", group: "Mutual reports" },
    { path: "/money-flow/courses", text: "Courses", role: "ROLE_UI_TAB_MONEYFLOW_COURSES" },
    { path: "/money-flow/referral-partners", text: "Referral partners", role: "ROLE_UI_TAB_ORGANIZATION_REFERRAL_PARTNER_REPORT_GET" },
    { path: "/money-flow/debt", text: "Debts", role: "ROLE_UI_TAB_MONEYFLOW_DEBTS" },
    { path: "/money-flow/external-debt", text: "External debts", role: "ROLE_MONEYFLOW_EXTERNAL_DEBT_MANAGE" , group: "Settlements with counterparties" },
    { path: "/money-flow/safe-report", text: "Safe report", role: "ROLE_MONEYFLOW_SAFE_REPORT", group: "Reports" },
    { path: "/money-flow/payment-links-report", text: "Payment links report", role: "ROLE_MONEYFLOW_PAYMENT_LINKS_REPORT", group: "Reports" },
    { path: "/money-flow/special-discounts-report", text: "Special discounts report", role: "ROLE_ANALYTICS_SPECIAL_DISCOUNTS_REPORT", group: "Reports" },
    { path: "/money-flow/closed-financial-periods-report", text: "Closed financial periods", role: "ROLE_MONEYFLOW_CLOSED_FINANCIAL_PERIOD_CREATE_CHANGE_REPORTS" },
    { path: "/money-flow/closed-financial-period-changes-report", text: "Closed financial period changes report", role: "ROLE_MONEYFLOW_CLOSED_FINANCIAL_PERIOD_CREATE_CHANGE_REPORTS", group: "Reports" },
    { path: "/money-flow/deleted-transactions", text: "Transactions", role: "ROLE_MONEYFLOW_DELETED_OPERATIONS_GET", group: "Deleted operations" },
    { path: "/money-flow/deleted-deals", text: "Deals", role: "ROLE_MONEYFLOW_DELETED_OPERATIONS_GET", group: "Deleted operations" },
    { path: "/money-flow/deleted-debts", text: "Debts", role: "ROLE_MONEYFLOW_DELETED_OPERATIONS_GET", group: "Deleted operations" },
    { path: "/money-flow/pay-docs-balance-check-report", text: "PayDocs balance check report", role: "ROLE_MONEYFLOW_PAY_DOCS_BALANCE_CHECK_REPORT", group: "Reports"},
    { path: "/money-flow/pay-doc-commission-report", text: "PayDoc Commission Report", role: "ROLE_UI_TAB_MONEYFLOW_PAYDOCCOMMISSIONREPORT", group: "Mutual reports"},
    { path: "/money-flow/vgo/business-unit-balance-report", text: "Business Unit Balance Report", role: "ROLE_MONEYFLOW_BUSINESS_UNIT_BALANCE_REPORT", group: "VGO"},
    { path: "/money-flow/vgo/osv-spending-type-report", text: "OSV Spending Type Report", role: "ROLE_MONEYFLOW_OSV_SPENDING_TYPE_REPORT", group: "VGO"},
    { path: "/money-flow/vgo/osv-counterparties-report", text: "OSV Counterparties Report", role: "ROLE_MONEYFLOW_OSV_COUNTERPARTIES_REPORT", group: "VGO"},
    { path: "/money-flow/bills/current-state-investor", text: "Investor report", role: "ROLE_UI_TAB_MONEYFLOW_BILL_CURRENTSTATE_INVESTOR", group: "VGO" }
];

export const dealApprovedStatuses = {
    approved: 'approved',
    pending: 'pending',
    rejected: 'rejected',
    completed: 'completed',
};

export const dealConvertTypes = {
    employeeToCheckingAccount: 'employeeToCheckingAccount'
}

export const emptyDeal = {
    id: null,
    amount: {...emptyMoney},
    reasonId: null,
    reasonClass: null,
    reasonClassText: null,
    reasonName: null,
    approveStatus: null,
    approveStatusText: null,
    comment: null,
    businessUnitName: null,
    createdAt: null,
    applyAt: null,
    transactionsCreatedAt: null,
    cancellationFact: null,
    investorName: null,
    businessUnitId: null,
    incomingDealType: null,
    checkingAccountId: null,
    employeeId: null,
    toEmployeeId: null,
    fromCheckingAccountId: null,
    juridicalPersonId: null,
    paymentType: null,
    investorId: null,
    companyEmployeeId: null
}

const dateFormat = 'YYYY-MM-DD';

export const debtPaymentTypes = [paymentTypes.cash, paymentTypes.cashless]

export function processSearchDates(search) {
    if (moment.isMoment(search.date_start)) search.date_start = search.date_start.format(dateFormat);
    if (moment.isMoment(search.date_end)) search.date_end = search.date_end.format(dateFormat);
    if (moment.isMoment(search.month)) search.month = search.month.format(dateFormat);
    if (moment.isMoment(search.dateStart)) search.dateStart = search.dateStart.format(dateFormat);
    if (moment.isMoment(search.dateEnd)) search.dateEnd = search.dateEnd.format(dateFormat);
    if (moment.isMoment(search.timeStart)) search.timeStart = encodeURIComponent(search.timeStart.format());
    if (moment.isMoment(search.timeEnd)) search.timeEnd = encodeURIComponent(search.timeEnd.format());
}

export function getBalanceChanges(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/balance/changes';

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getTransactions(search, page, per_page, order = 'asc', order_by = 'name') {
    processSearchDates(search);

    return baseRequest('/api/money-flow/transaction', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getGratuityReport(search, page, per_page, order = 'asc', order_by = 'name') {
    processSearchDates(search);

    return baseRequest('/api/gratuity-report', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getTransactionsCash(search, page, per_page, order = 'asc', order_by = 'name') {
    processSearchDates(search);

    return baseRequest('/api/money-flow/transaction-cash', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getTransactionsCashless(search, page, per_page, order = 'asc', order_by = 'name') {
    processSearchDates(search);

    return baseRequest('/api/money-flow/transaction-cashless', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getConvertDealTypes() {
    return baseRequest('/api/money-flow/convert/types');
}

export function createConvert(convertData) {
    return baseRequest('/api/money-flow/deal/convert-deal', {
        method: 'POST',
        body: JSON.stringify({...convertData, createdAt : convertData.createdAt ? convertData.createdAt.toISOString() : null}),
    });
}

export function createSpending(spendingData) {

    return baseRequest('/api/money-flow/deal/spending', {
        method: 'POST',
        body: JSON.stringify({...spendingData, createdAt : spendingData.createdAt ? spendingData.createdAt.toISOString() : null}),
    });

}

export function getIncomingDealTypes() {
    return baseRequest('/api/money-flow/incoming/types');
}

export function getSpendingDealTypes() {
    return baseRequest('/api/money-flow/spending/types');
}

export function createIncoming(incomingData) {
    return baseRequest('/api/money-flow/deal/incoming', {
        method: 'POST',
        body: JSON.stringify({...incomingData, createdAt : incomingData.createdAt ? incomingData.createdAt.toISOString() : null}),
    });
}

export function getSystemSpendingTypes() {
    return baseRequest('/api/money-flow/spending-type-system');
}
export function getSpendingTypes(search, page, per_page, order = 'asc', order_by = 'name') {
    return baseRequest('/api/money-flow/spending-type', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}
export function getSpendingTypesByCurrentUserBusinessRole(search, page, per_page, order = 'asc', order_by = 'name') {
    return baseRequest('/api/money-flow/spending-type/current-user-business-role', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function saveSpendingType(spendingType) {
    return baseRequest('/api/money-flow/spending-type', {
        method: 'POST',
        body: JSON.stringify({...spendingType}),
    });
}

export function getSpendingTypeCategories(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/spending-type/category';

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function saveSpendingTypeCategory(spendingTypeCategory) {
    return baseRequest('/api/money-flow/spending-type/category', {
        method: 'POST',
        body: JSON.stringify({...spendingTypeCategory}),
    });
}


export function getIncomingTypes(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/incoming-type';

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getTypeActivities(search, page, per_page, order='asc', order_by = 'name'){
    let url = '/api/activity/type-activities';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if(q){
        url+= `?${q}`;
    }
    return baseRequest(url);
}

export function getActivityGroups(search, page, per_page, order='asc', order_by = 'name'){
    let url = '/api/activity/activity-group';

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if(q){
        url+= `?${q}`;
    }
    return baseRequest(url);
}


export function saveIncomingType(incomingType) {
    return baseRequest('/api/money-flow/incoming-type', {
        method: 'POST',
        body: JSON.stringify({...incomingType}),
    });
}

export function createTypeActivity(typeActivity){
    return baseRequest('/api/activity/type-activity/create',{
        method: 'POST',
        body: JSON.stringify({...typeActivity})
    });
}

export function updateTypeActivity(typeActivity){
    return baseRequest('/api/activity/type-activity/update', {
            method: 'POST',
            body: JSON.stringify(typeActivity)
        }
        )
}

export function createActivityGroup(groupActivity){
    return baseRequest('/api/activity/activity-group/create',{
        method: 'POST',
        body: JSON.stringify({...groupActivity})
    })
}

export function updateActivityGroup(groupActivity){
    return baseRequest('/api/activity/activity-group/update',{
        method: 'POST',
        body: JSON.stringify(groupActivity)
    })
}


export function getLimits(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/limit';

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getLimit(id) {
    return baseRequest(`/api/money-flow/limit/id/${id}`);
}

export function saveLimit(limit) {
    return baseRequest('/api/money-flow/limit', {
        method: 'POST',
        body: JSON.stringify({...limit}),
    });
}

export function getLimitMonthlyByLimitId(id, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/limit-monthly';

    url += `/${id}`;

    const q = queryString.stringify({page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function currentSpendingLimit(spendingTypeId, businessUnitId) {
    let url = '/api/money-flow/limit/current';
    return baseRequest(url, {
        method: 'POST',
        body: JSON.stringify({spendingTypeId, businessUnitId}),
    });
}

export function getDealsOverrated(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/deal-overrated';

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getDeals(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/deal';

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getDeal(id) {
    let url = '/api/money-flow/deal/id/' + id;
    return baseRequest(url);
}


export function updateDeal(deal) {
    return baseRequest('/api/money-flow/deal', {
        method: "PATCH",
        body: deal
    });
}

export function removeDeal(id) {
    let url = '/api/money-flow/deal/id/' + id;
    return baseRequest(url, {
        method: 'DELETE'
    });
}

export function getDealApproveStatuses() {
    return baseRequest('/api/money-flow/deal/approve-statuses');
}

export function changeDealsStatus(selected, type) {
    let urls = {
        approve: '/api/money-flow/deal-overrated/approve',
        reject: '/api/money-flow/deal-overrated/reject',
        complete: '/api/money-flow/deal-overrated/complete',
    };
    return baseRequest(urls[type], {
        method: 'PUT',
        body: JSON.stringify(selected),
    });
}

export function getIncomingsReport(groupBy, search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/incoming-employee/report';
    if (groupBy === 'month') {
        url = '/api/money-flow/incoming-employee/report/month';
    }

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getSpendingsReportByLimits(search, page, per_page, order = 'asc', order_by = 'name') {

    let url = '/api/money-flow/spending-type/report';

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getSummaryDayReportNew(day, businessUnitId) {
    if (day instanceof moment) {
        day = day.format(dateFormat);
    }
    return baseRequest('/api/money-flow/summary-day-report-new', {
        query: { day, businessUnitId }
    });
}

export function getSummaryReportPeriodNew( dayStart, dayEnd, businessUnitIds) {
    let url = '/api/money-flow/summary-report-period-new';

    const q = queryString.stringify({dayStart, dayEnd, businessUnitIds});
    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function getDashboardReport( dayStart, dayEnd, businessUnitIds, days) {
    let url = '/api/money-flow/dashboard-report';

    const q = queryString.stringify({dayStart, dayEnd, businessUnitIds, days});
    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function getDashboardAdminAndMasterReport(search) {
    let url = '/api/money-flow/admins-and-masters-dashboard';

    return baseRequest(url, {
        query: { search }
    });
}

export function getAdminsAndMastersDashBoardGoogleLink() {
    return baseRequest('/api/money-flow/admins-and-masters-dashboard/google-link');
}

export function detailedCashSummaryDayReport(day, businessUnitId) {
    let url = '/api/money-flow/summary-day-report/cash';

    if (day instanceof moment) day = day.format(dateFormat);

    const q = queryString.stringify({day, businessUnitId});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function detailedCashlessSummaryDayReport(day, businessUnitId) {
    let url = '/api/money-flow/summary-day-report/cashless';

    if (day instanceof moment) day = day.format(dateFormat);

    const q = queryString.stringify({day, businessUnitId});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function detailedCheckingAccountSummaryDayReport(day, businessUnitId) {
    let url = '/api/money-flow/summary-day-report/checking-account';

    if (day instanceof moment) day = day.format(dateFormat);

    const q = queryString.stringify({day, businessUnitId});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getCurrentBillsState(search) {
    let url = '/api/money-flow/bill/current-state';
    const q = queryString.stringify({search});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getCurrentBillsStateEmployees(search, page, per_page, order, order_by) {
    return baseRequest('/api/money-flow/bill/current-state-employee', {
        query: { search, order, order_by }
    });
}

export function getCurrentCashBoxAndSafeValue(query) {
    return baseRequest('/api/money-flow/current-cashbox-safe-state', { query });
}

export function getVisitsPaymentReport(search) {

    processSearchDates(search);

    let url = '/api/visit/payments-report';
    const q = queryString.stringify({search});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getSafeReport(search) {
    processSearchDates(search);

    let url = '/api/money-flow/safe-report';

    return baseRequest(url, {
        query: search
    });
}

export function getSettlementHotels(search) {
    let url = '/api/visit/settlement-hotels';
    const q = queryString.stringify({search});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function getCourses(search, page = 1, per_page = -1, order = 'asc', order_by = 'date') {
    let url = '/api/money-flow/courses';

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function copyCourses() {
    return baseRequest('/api/money-flow/courses/copy', {
        method: 'POST',
    });
}

const SalableEntityDataTableKeys = {
    visits: 'visits',
    goodStorageSales: 'goodStorageSales',
    paydocSales: 'paydocSales',
    paydocRefills: 'paydocRefills',
};

export function generateSalableEntityLinkForDataTable( key ) {
    return (item) => {

        switch (key) {
            case SalableEntityDataTableKeys.visits:
                return '/orders/view/' + item[SalableEntityDataTableKeys.visits];
            case SalableEntityDataTableKeys.goodStorageSales:
                return '/goods/sell/view/' + item[SalableEntityDataTableKeys.goodStorageSales];
            case SalableEntityDataTableKeys.paydocSales:
                return item[SalableEntityDataTableKeys.paydocSales] && '/pay-docs/view/' + item[SalableEntityDataTableKeys.paydocSales].id;
            case SalableEntityDataTableKeys.paydocRefills:
                return item[SalableEntityDataTableKeys.paydocRefills] && '/pay-docs/history/balance/' + item[SalableEntityDataTableKeys.paydocRefills].id;
            default:
                return null;
        }
    };

}

export function reportBalancesCheck(search, page, per_page, order = 'asc', order_by = 'name') {
    processSearchDates(search);

    return baseRequest('/api/money-flow/balances-check', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getPaymentsIncoming(businessUnitId, date = null) {
    return baseRequest('/api/money-flow/payments-incoming', {
        query: { businessUnitId, date}
    });
}

export function getReferralPartnersReport(search) {
    processSearchDates(search);

    return baseRequest('/api/organization/referral-partners/report', {
        query: search
    });
}

export function getReferralPartnersReportFull(search) {
    processSearchDates(search);

    return baseRequest('/api/organization/referral-partners/report-full', {
        query: search
    });
}

export function getReferralPartnersCalcInfo(search) {
    processSearchDates(search);

    return baseRequest('/api/organization/referral-partners/calc-info', {
        query: search
    });
}

export function referralPartnersPay(data) {
    return baseRequest('/api/organization/referral-partners/pay', {
        body: JSON.stringify(data),
        method: 'POST'
    });
}

export function createCashBoxCorrection(correction) {
    return baseRequest('/api/money-flow/deal/cash-box-correction', {
        method: 'POST',
        body: correction,
    });
}

export function createEmployeeCashBoxCorrection(correction) {
    return baseRequest('/api/money-flow/deal/employee-cash-box-correction', {
        method: 'POST',
        body: correction,
    });
}

export function createCheckingAccountCorrection(correction) {
    return baseRequest('/api/money-flow/deal/checking-account-correction', {
        method: 'POST',
        body: correction,
    });
}

export function getFinanceReport(search) {
    return baseRequest('/api/money-flow/finance-report',{
        query: {search}
    });
}

export function getSummaryVisitData(id) {
    return baseRequest(`/api/money-flow/summary-visit-data/${id}`);
}

export function getVisitReceiptData(id) {
    return baseRequest(`/api/money-flow/visit-receipt-data/${id}`);
}



export function getDebts(search, page, per_page, order = 'asc', order_by = 'name') {
    return baseRequest('/api/money-flow/debt', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getExternalDebts(search, page, per_page, order = 'asc', order_by = 'name') {
    return baseRequest('/api/money-flow/external-debt', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getDeletedDebts(search, page, per_page, order = 'asc', order_by = 'name') {
    return baseRequest('/api/money-flow/deleted-debt', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getDebt(id) {
    return baseRequest('/api/money-flow/debt/id/' + id);
}

export function getExternalDebt(id) {
    return baseRequest('/api/money-flow/external-debt/id/' + id);
}

export function saveDebt(debt) {
    return baseRequest('/api/money-flow/debt', {
        method: "POST",
        body: debt,
    });
}

export function saveExternalDebt(debt) {
    return baseRequest('/api/money-flow/external-debt', {
        method: "POST",
        body: debt,
    });
}

export function deleteDebt(id) {
    return baseRequest('/api/money-flow/debt/' + id, {
        method: "DELETE",
    });
}

export function deleteExternalDebt(id) {
    return baseRequest('/api/money-flow/external-debt/' + id, {
        method: "DELETE",
    });
}

export function getDealsByReason(id) {
    return baseRequest('/api/money-flow/deal/reason/' + id);
}

export function createDealForDebt(deal) {
    return baseRequest('/api/money-flow/debt/deal', {
        method: "POST",
        body: deal,
    });
}

export function createDealForExternalDebt(deal) {
    return baseRequest('/api/money-flow/external-debt/deal', {
        method: "POST",
        body: deal,
    });
}

export function updateBalances() {
    return baseRequest('/api/money-flow/balance/update', {
        method: "POST",
    });
}

export function importSpending(spending) {
    return baseRequest('/api/money-flow/import/spending', {
        method: "POST",
        body: spending,
    });
}

export function importDebts(debt) {
    return baseRequest('/api/money-flow/import/debts', {
        method: "POST",
        body: debt,
    });
}

export function duplicateSpendings(duplicates){
    return baseRequest('/api/money-flow/duplicate/spendings',{
        method: "POST",
        body: duplicates
    })
}

export function getSpendingReport(search, page, per_page, order = 'asc', order_by = 'apply_at') {
    let url = '/api/money-flow/spending-report';

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getDashBoardGoogleLink() {
    return baseRequest('/api/money-flow/dashboard-report/google-link');
}

export function getMutualPayDocReports(search) {
    let url = '/api/report/mutual-pay-doc-reports';

    processSearchDates(search);

    const q = queryString.stringify({search});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function forcedExportDashboardToGoogle(params) {

    return baseRequest('/api/report/forced-export-dashboard-to-google', {
        method: "POST",
        body: params
    });
}

export function getDigitalizedMutualPayDoc(search) {
    let url = '/api/report/digitalized-mutual-pay-doc-reports';

    processSearchDates(search);

    const q = queryString.stringify({search});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getMastersAllLoadingByParlourAndDay(params) {
    return baseRequest('/api/money-flow/masters-all-loading', {
        query: params,
        notBackgroundProcess: true
    });
}

export const debtLevels = [
    {id: 1, name: '1'},
    {id: 2, name: '2'},
    {id: 3, name: '3'},
]

export function getPaymentLinksReport(search, page, per_page, order = 'asc', order_by = 'name') {
    processSearchDates(search);

    let url = '/api/money-flow/payment-links-report';

    return baseRequest(url, {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getGuestFinancialCard(id) {

    let url = '/api/money-flow/guest-financial-card/' + id;

    return baseRequest(url);
}

export const spendingReportDateTypes = {
    month: 'month',
    period: 'period'
};

export function specialDiscountsReport(search) {
    return baseRequest('/api/analytics/special-discounts', {
        query: search,
        notBackgroundProcess: true
    });
}

export function createClosedPeriods(data) {
    return baseRequest("/api/money-flow/closed-financial-periods", {
        method: "POST",
        body: data
    })
}

export function getClosedFinancialPeriodsReport(search, page, per_page, order = 'asc', order_by = 'endedAt') {
    return baseRequest("/api/money-flow/closed-financial-periods-report",{
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    })
}

export function getClosedFinancialPeriodChangesReport(search, page, per_page, order = 'desc', order_by = 'dt') {
    return baseRequest("/api/money-flow/closed-financial-period-changes-report",{
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    })
}

export const closedFinancialPeriodChangeEvents = {
    delete: 'delete'
}

export function getBill(id) {
    return baseRequest(`/api/money-flow/bill/id/${id}`);
}

export const dealIncomingTypeIds = {
    incomingToCashBox: 'INCOMING_TO_CASHBOX',
    incomingToSafe: 'INCOMING_TO_SAFE',
    incomingToCheckingAccount: 'INCOMING_TO_CHECKING_ACCOUNT'
}

export const dealSpendingTypeIds = {
    spendingFromCash: 'SPENDING_FROM_CASHBOX',
    spendingFromSafe: 'SPENDING_FROM_SAFE',
    spendingFromCheckingAccount: 'SPENDING_FROM_CHECKING_ACCOUNT'
}

export const dealSpendingTransferRecipientTypeIds = {
    businessUnit: 'business_unit',
    externalSubject: 'external_subject'
}

export function createSpendingTransfer(data) {
    return baseRequest('/api/money-flow/deal/spending-transfer', {
        method: 'POST',
        body:  data
    });
}

export const moneyFlowEntitiesLinkTemplates = {
    deal: '/money-flow/deal/id/'
}

export function getDeletedTransactions(search, page, per_page, order = 'asc', order_by = 'name') {
    processSearchDates(search);

    return baseRequest('/api/money-flow/deleted-transaction', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}

export function getDeletedDeals(search, page, per_page, order = 'asc', order_by = 'name') {
    let url = '/api/money-flow/deleted-deal';

    processSearchDates(search);

    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function getReasonClassesDeal(){
    return baseRequest('/api/settings/get-reason-classes-deal');
}

export function saveSettingNameForActivity(settingNames){
    return baseRequest('/api/settings/save-setting-name-activity',{
        method:'POST',
        body: JSON.stringify(settingNames)
    })
}

export function getDealTypes(){
    return baseRequest('/api/settings/get-deal-types');
}

export function deleteActivityGroup(id){
    return baseRequest(`/api/activity/activity-group/delete/${id}`);
}

export function deleteActivityType(id){
    return baseRequest(`/api/activity/activity-type/delete/${id}`);
}

export function getPayDocsBalanceCheckReport(search){
    return baseRequest('/api/money-flow/pay-docs-balance-check-report', {
        query: search
    })
}

export function getPayDocCommissionReport(search) {
    return baseRequest('/api/report/pay-doc-commission-report', {
        query: search
        })
}

export function getBusinessUnitBalanceReport(params) {
    return baseRequest('/api/money-flow/business-unit-balance-report', {
        query: params
        })
}

export function safeSafe(){
    return baseRequest('/api/money-flow/safe-safe');
}

export function getCurrentBillsStateInvestors(search, page, per_page, order, order_by) {
    return baseRequest('/api/money-flow/bill/current-state-investor', {
        query: { search, order, order_by }
    });
}

export function getCurrentBillsStateInvestorsDebts(search, page, per_page, order, order_by) {
    return baseRequest('/api/money-flow/bill/current-state-investor-debts', {
        query: { search, order, order_by }
    });
}

export function getCurrentBillsStateInvestorsCredits(search, page, per_page, order, order_by) {
    return baseRequest('/api/money-flow/bill/current-state-investor-credits', {
        query: { search, order, order_by }
    });
}

export function isSpendingDeal(deal) {
    return deal?.reasonClass === 'SpendingType';
}

export function getOsvSpendingType(search, page, per_page, order, order_by) {
    return baseRequest('/api/money-flow/bill/osv-spending-type', {
        query: { search, order, order_by }
    });
}

export function getOsvCounterpartiesReport(search, page, per_page, order, order_by) {
    return baseRequest('/api/money-flow/bill/osv-counterparties-report', {
        query: {search, page, per_page: per_page ? per_page : -1, order, order_by}
    });
}