import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, InputAdornment, MenuItem
} from '@material-ui/core';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import {
    navLinks,
    getPayDocTypesSecuredCreating, extractBitrixDataFromQuery, createMultiplePayDocs, payDocStatuses
} from "../../services/payDocs";
import {getExternalSubjects, getParlours} from "../../services/organization";
import GuestField from "../../components/fields/GuestField";
import DateField2 from "../../components/fields/DateField2";
import MoneyField from "../../components/fields/MoneyField";
import moment from "moment";
import {withTranslation} from "react-i18next";
import {hasRole} from "../../services/user";
import NumberFormatCount from "../../components/fields/NumberFormatCount";
import CheckboxField from "../../components/fields/CheckboxField";
import {getWorkShiftCurrentBusinessUnit} from "../../services/calendarEmployee";
import * as cn from "classnames";
import NumberFormatDecimal from "../../components/fields/NumberFormatDecimal";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {getGuest, getGuestByBitrixId, verifyGuestName} from "../../services/guests";
import infoActions from "../../components/info/info-actions";
import ProgressDialog from "../../components/dialogs/ProgressDialog";
import GuestNameVerificationDialog from "../../components/dialogs/GuestNameVerificationDialog";
import {
    getGeneralProperty
} from "../../services/settings";
import _ from "lodash";
import PayDocsEqualRefillsDialog from "./PayDocsEqualRefillsDialog";
import CountField from "../../components/fields/CountField";
import PayDocField from "../../components/fields/PayDocField";

const styles = theme => ({
    container: {
        width: 900,
        marginBottom: 10,
    },
    wrongParlour: {
        color: 'red',
    },
});

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
    showMessage: messageDialogActions.show,
    showInfo: infoActions.show,
})
@withStyles(styles)
@withTranslation()
@withRouter
class CreatePayDoc extends React.Component {

    state = {
        payDocParams: {
            typeId: null,
            validity: null,
            businessUnitId: null,
            nominal: 0,
            guestId: null,
            canRefill: false,
            comment: null,
            numInt: null,
            externalSubjectId: null,
            bitrixId: null,
            bitrixUserId: null,
            count: 1,
            mainId: null,
        },
        payDocs: [],
        types: [],
        businessUnits: [],
        externalSubjects: [],
        isBitrix24Data: false,
        bitrixRequest: {
            loading: false,
            result: null
        },
        nameVerifyingData: {
            dialogOpen: false,
            guestName: '',
            guestLastName: '',
            gender: '',
            redirectLink: '',
            openRefillPayDocsDialog: false
        },
        settings: {},
        openRefillPayDocsDialog: false
    };

    componentDidMount() {
        const { t } = this.props

        if (this.props.currentParlour && !this.state.payDocParams.businessUnitId) {
            this.setState({
                payDocParams: {
                    ...this.state.payDocParams,
                    businessUnitId: this.props.currentParlour.id,
                },
            });
        }

        getParlours({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getPayDocTypesSecuredCreating()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data,
                    })
                }
            });
        getWorkShiftCurrentBusinessUnit()
            .then(response => {
                if (response.success && response.data.id) {
                    this.setState(state => ({
                        payDoc: {
                            ...state.payDoc,
                            businessUnitId: response.data.id,
                        }
                    }));
                }
            });

        getExternalSubjects({'type': 'external_organization'})
            .then(response => {
                if (response.success) {
                    this.setState({
                        externalSubjects: response.data.map(es => ({value: es.id, label: es.name})),
                    })
                }
            });

        // get Bitrix24 data from query string
        let search = this.props.location.search;
        if (search.indexOf('bitrix') !== -1 && this.state.payDocs.length === 0) {

            // get param modified from 'bitrix_id' to 'bitrixId'
            let bitrixData = extractBitrixDataFromQuery(search);

            if (bitrixData.bitrixId && bitrixData.bitrixUserId) {
                this.setState({
                    isBitrix24Data: true,
                    payDocParams: {
                        ...this.state.payDocParams,
                        count: 1,
                        bitrixId: bitrixData.bitrixId,
                        bitrixUserId: bitrixData.bitrixUserId,
                    }
                })

                // autofill guest field in sale action
                this.setState({
                    bitrixRequest: {
                        ...this.state.bitrixRequest,
                        loading: true,
                    }
                }, () => {
                        getGuestByBitrixId(bitrixData.bitrixUserId)
                            .then((response) => {
                                if (response.success) {
                                    this.setState({
                                        payDocParams: {
                                            ...this.state.payDocParams,
                                            guestId: response.data.id
                                        },
                                        bitrixRequest: {
                                            loading: false,
                                            result: true
                                        }
                                    })
                                } else {
                                    this.setState({
                                        bitrixRequest: {
                                            loading: false,
                                            result: false
                                        }
                                    }, () => {
                                        this.props.showInfo((t('Could not find guest with bitrix id') + ': ' + bitrixData.bitrixUserId))
                                    });
                                }
                            })
                    }
                );
            }
        }

        getGeneralProperty('guestNameVerificationEnable').then(response => {
            if (response.success) {
                this.setState({
                    settings: {
                        ...this.state.settings,
                        "guestNameVerificationEnable" : response.data,
                    }
                })
            }
        })
    }

    handleChange = prop => event => {
        const { payDocParams, types } = this.state;
        let newPayDocParams = {...payDocParams, [prop]: event.target.value};
        if (prop === 'typeId') {
            newPayDocParams.nominal = null;
            const type = types.find(type => type.id === newPayDocParams.typeId);
            if (type && newPayDocParams.typeId !== payDocParams.typeId) {
                if (type.validityMonth ) {
                    newPayDocParams.validity = type.validityByDateOrMonth;
                }

                newPayDocParams.canRefill = type.canRechargeDefault;
            }
        }

        if (prop === 'count') {
            newPayDocParams.count = parseInt(newPayDocParams.count)
        }

        if (prop === 'count' && event.target.value > 1) {
            newPayDocParams.numInt = null
        }

        this.setState({ payDocParams: newPayDocParams });
    };

    handleChangeNominalMoney = () => (event) => {
        const { payDocParams } = this.state;
        this.setState({ payDocParams: {...payDocParams, nominal: event.target.value} });
    };

    handleChangeNominalTime = (time) => {
        let minutes = time.minutes() + 60 * moment().hours();
        const { payDoc } = this.state;
        this.setState({ payDoc: {...payDoc, nominal: minutes} });
    };

    getNominalMoment = () => {
        const { nominal } = this.state.payDoc;
        let time = moment().startOf('day');

        if (!nominal) {
            return time;
        }
        return time.add(nominal, 'minutes');
    };

    handleSave = refill => () => {
        let { t, showInfo } = this.props;
        let payDocParams = {...this.state.payDocParams};
        if (payDocParams.nominal && payDocParams.nominal.cur) {
            payDocParams.nominal = payDocParams.nominal.val;
        }
        createMultiplePayDocs(payDocParams)
            .then(response => {
                const { settings } = this.state;

                if (response.success) {
                    this.setState({payDocs: response.data})

                    if (payDocParams.count > 1 && !refill) {
                        this.props.showMessage(t("PD have been successfully added to the database!"))
                    }

                    // ----- verifying guest name process ------
                    showInfo(t('Check guest name verifying status'));
                    getGuest(payDocParams.guestId).then((guestResponse) => {
                        if (
                            guestResponse.success &&
                            !_.isEmpty(settings) &&
                            settings.guestNameVerificationEnable
                        ) {
                            let guest = guestResponse.data;
                            if (!guest.nameVerified) {
                                this.setState({
                                    nameVerifyingData: {
                                        dialogOpen: true,
                                        guestName: guest.name,
                                        guestLastName: guest.lastName,
                                        gender: guest.gender,
                                        redirectLink: payDocParams.count === 1 ?
                                            `/pay-docs/view/${response.data[0].id}${refill ? '#refillDialog' : ''}`:
                                            null,
                                        openRefillPayDocsDialog: refill
                                    }
                                });
                            } else {
                                if (payDocParams.count === 1) {
                                    this.props.history.push(`/pay-docs/view/${response.data[0].id}${refill ? '#refillDialog' : ''}`);
                                }
                                if (refill && payDocParams.count > 1) {
                                    this.openRefillPayDocsDialog()
                                }
                            }
                        } else {
                            if (payDocParams.count === 1) {
                                this.props.history.push(`/pay-docs/view/${response.data[0].id}${refill ? '#refillDialog' : ''}`);
                            }
                            if (refill && payDocParams.count > 1) {
                                this.openRefillPayDocsDialog()
                            }
                        }
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChangeVerifyGuestName = prop => event => {
        this.setState({
            nameVerifyingData: {
                ...this.state.nameVerifyingData,
                [prop]: event.target.value
            }
        })
    }

    handleVerifyGuestName = () => {
        let { nameVerifyingData, payDocParams } = this.state;

        let guest = {
            id: payDocParams.guestId,
            name: nameVerifyingData.guestName,
            lastName: nameVerifyingData.guestLastName,
            gender: nameVerifyingData.gender
        };

        verifyGuestName(guest).then(response => {
            if (response.success) {
                this.setState({
                    nameVerifyingData: {
                        ...nameVerifyingData,
                        dialogOpen: false,
                    },
                    openRefillPayDocsDialog: nameVerifyingData.openRefillPayDocsDialog
                }, () => {
                    if (this.state.nameVerifyingData.redirectLink) {
                        this.props.history.push(this.state.nameVerifyingData.redirectLink);
                    }
                })
            } else {
                if (this.state.nameVerifyingData.redirectLink){
                    this.props.history.push(this.state.nameVerifyingData.redirectLink);
                }
            }
        })
    }

    openRefillPayDocsDialog = () => {
        this.setState({
            openRefillPayDocsDialog: true
        })
    }

    render() {
        const { classes, t } = this.props;
        const { payDocParams, businessUnits, types, externalSubjects, bitrixRequest, nameVerifyingData, settings, openRefillPayDocsDialog, payDocs } = this.state;
        const selectedType = types.find( (type) => type.id === payDocParams.typeId ) || {};
        const availableInBusinessUnits = selectedType && selectedType.availableInBusinessUnits ? selectedType.availableInBusinessUnits : [];
        const wrongBusinessUnit = Boolean(
            availableInBusinessUnits.length &&
            payDocParams.businessUnitId &&
            availableInBusinessUnits.indexOf(payDocParams.businessUnitId) === -1
        );
        const saveDisabled =
            !payDocParams.guestId ||
            !payDocParams.businessUnitId ||
            !payDocParams.typeId ||
            !payDocParams.validity ||
            (selectedType.canSetNominal && !payDocParams.nominal) ||
            (payDocParams.numInt && parseInt(payDocParams.numInt) > 999999) ||
            wrongBusinessUnit ||
            !payDocParams.count ||
            payDocParams.count <= 0 ||
            (payDocParams.count > 1 && !hasRole('ROLE_PAYDOC_CREATE_MULTIPLE_PAYDOCS')) ||
            (payDocParams.count === 1 && !hasRole('ROLE_PAYDOC_PAYDOC_SAVE_ONE'))
        const saveAndRefilllDisabled = saveDisabled ||
            (!selectedType || !selectedType.canRechargeDefault || !payDocParams.canRefill)
        const selectedParlour = businessUnits.find( (parlour) => parlour.id === payDocParams.businessUnitId ) || {};

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton
                        onClick={this.handleSave(false)}
                        disabled={saveDisabled}>{t('Save')}
                    </ActionButton>
                    <ActionButton
                        onClick={this.handleSave(true)}
                        disabled={saveAndRefilllDisabled}
                    >
                        {t('Save and refill')}
                    </ActionButton>
                </LeftBar>
                <Content title={t('payDoc.createElectron')}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                label={t("Type")}
                                value={payDocParams.typeId}
                                required
                                error={!payDocParams.typeId}
                                onChange={this.handleChange('typeId')}
                                fullWidth
                                select
                            >
                                {types.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={2}>
                            <CountField
                                label={t("Count of PD")}
                                value={payDocParams.count}
                                onChange={this.handleChange("count")}
                                disabled={!!payDocParams.bitrixId || !!payDocParams.bitrixUserId}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <GuestField
                                label={t("Guest")}
                                required
                                error={!payDocParams.guestId}
                                value={payDocParams.guestId}
                                onChange={ this.handleChange('guestId') }
                            />
                        </Grid>
                        {selectedType && selectedType.variant === 'barter' &&
                            <Grid item xs={4}>
                                <AutocompleteSelectField
                                    label={t("External subject")}
                                    onChange={ this.handleChange('externalSubjectId') }
                                    value={ payDocParams.externalSubjectId }
                                    options={ externalSubjects }
                                    required={ selectedType.isExternalSubjectRequired }
                                    error={ !payDocParams.externalSubjectId }
                                    fullWidth
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                label={t("Parlour")}
                                value={payDocParams.businessUnitId}
                                required
                                error={!payDocParams.businessUnitId || wrongBusinessUnit}
                                helperText={wrongBusinessUnit ? t("Wrong parlor for PD") : null}
                                onChange={this.handleChange('businessUnitId')}
                                fullWidth
                                select
                            >
                                {businessUnits.map(item =>
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        className={cn({[classes.wrongParlour]: availableInBusinessUnits.length && availableInBusinessUnits.indexOf(item.id) === -1})}
                                    >{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            {!!selectedType.canSetMain ?
                                <PayDocField
                                    label="Основной сертификат"
                                    required
                                    error={!payDocParams.mainId}
                                    value={payDocParams.mainId}
                                    filterStatuses={[
                                        payDocStatuses.burned,
                                        payDocStatuses.active,
                                        payDocStatuses.expired,
                                        payDocStatuses.sold
                                    ]}
                                    filterType={selectedType.mainTypes}
                                    onChange={ this.handleChange('mainId') }
                                />
                                : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <DateField2
                                label={t("Expiration date to")}
                                value={moment(payDocParams.validity).utcOffset(0, false)}
                                required
                                error={!payDocParams.validity}
                                fullWidth
                                onChange={this.handleChange('validity')}
                                disablePast
                            />
                        </Grid>
                        {selectedType && selectedType.canSetNominal ?
                            <Grid item xs={4}>
                                {selectedType.measure === 'money' && payDocParams.businessUnitId && payDocParams.typeId ?
                                    <MoneyField
                                        label={t("Nominal") + ' ' + t('payDoc.nominalMoney')}
                                        currencies={[selectedParlour.mainCurrency]}
                                        onChange={this.handleChangeNominalMoney()}
                                        value={payDocParams.nominal || {
                                            val: payDocParams.nominal,
                                            cur: selectedParlour.mainCurrency
                                        }}
                                        error={!payDocParams.nominal}
                                        fullWidth
                                        disabled={!payDocParams.businessUnitId || !payDocParams.typeId}
                                        required
                                    />
                                    :
                                    <AnyField
                                        label={t("Nominal") + ' ' + t('payDoc.nominalTime')}
                                        value={payDocParams.nominal}
                                        required
                                        error={!payDocParams.nominal}
                                        disabled={!payDocParams.businessUnitId || !payDocParams.typeId}
                                        onChange={this.handleChange('nominal')}
                                        fullWidth
                                        InputProps={{
                                            inputComponent: NumberFormatDecimal
                                        }}
                                    />
                                }
                            </Grid>
                            : null
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <CheckboxField
                            disabled={!(selectedType && selectedType.canRechargeDefault)}
                            value={Boolean(payDocParams.canRefill)}
                            onChange={this.handleChange('canRefill')}
                            label={t("Allowed to replenish")}
                            margin={"none"}
                        />
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                label={t("Comment")}
                                value={payDocParams.comment}
                                multiline
                                rows={4}
                                fullWidth
                                onChange={this.handleChange('comment')}
                            />
                        </Grid>
                        { hasRole('ROLE_PAYDOC_PAYDOC_SAVE_ONE_NUMBER') ?
                            <Grid item xs={4}>
                                <AnyField
                                    label={t("Number")}
                                    value={payDocParams.numInt}
                                    fullWidth
                                    placeholder={t("Autofill")}
                                    onChange={this.handleChange('numInt')}
                                    InputProps={{
                                        inputComponent: NumberFormatCount,
                                        startAdornment:
                                            payDocParams.typeId && selectedType.code ?
                                                <InputAdornment position="start">{selectedType.code}</InputAdornment>
                                                : null

                                    }}
                                    error={payDocParams.numInt && parseInt(payDocParams.numInt) > 999999}
                                    disabled={payDocParams.count > 1}
                                />
                                <AnyField
                                    label={t("Received bitrix id")}
                                    value={this.state.payDocParams.bitrixId}
                                    visible={this.state.isBitrix24Data}
                                    disabled={true}
                                    fullWidth
                                />
                                <AnyField
                                    label={t("Received bitrix user id")}
                                    value={this.state.payDocParams.bitrixUserId}
                                    visible={this.state.isBitrix24Data}
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>
                            : null
                        }
                    </Grid>
                    <ProgressDialog open={bitrixRequest.loading} message={t('Finding user by bitrix id')}/>
                    <GuestNameVerificationDialog
                        open={
                            nameVerifyingData.dialogOpen &&
                            !_.isEmpty(settings) &&
                            settings.guestNameVerificationEnable
                        }
                        nameVerifyData={nameVerifyingData}
                        onChange={this.handleChangeVerifyGuestName}
                        onSubmit={this.handleVerifyGuestName}
                    />
                    <PayDocsEqualRefillsDialog
                        open={openRefillPayDocsDialog}
                        payDocs={payDocs}
                        onClose={() => this.setState({openRefillPayDocsDialog: false})}
                        onCompleteRefill={() => this.setState({openRefillPayDocsDialog: false})}
                        guestId={payDocParams.guestId}
                        />
                </Content>
            </React.Fragment>
        );
    }
}
