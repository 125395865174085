import React from 'react';
import AnyField, { propTypes as anyFieldPropTypes} from "./AnyField";
import NumberFormatCount from "./NumberFormatCount";
import {FormControl, Grid, InputAdornment, InputLabel, withStyles} from "@material-ui/core";
import {getLabelTransform} from "../../styles";
import classNames from "classnames";
import moment from "moment";

export default
@withStyles(theme => ({
    field: {
        marginTop: 0,
    },
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    withLabel2Lines: {
        marginTop:  theme.spacing(5),
    },
    label2Lines: {
        '&.shrink': {
            transform: getLabelTransform(-35),
        },
    },
}))
class DateIntervalField extends React.Component
{
    static propTypes = {
        ...anyFieldPropTypes,
    };

    state = {
        anchorEl: null,
        duration: {
            days: null,
            months: null,
        }
    };

    componentDidMount() {
        this.processValue(this.props.value);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.props.value) {
            this.processValue(nextProps.value);
        }
    }

    processValue(value) {
        const duration = {...this.state.duration};
        const mDuration = moment.duration(value);
        if (mDuration && mDuration.isValid()) {
            duration.days = mDuration._days;
            duration.months = mDuration._months;

            this.setState({
                duration
            });
        }
    }

    createIsoDuration(duration) {
        return `P0Y${duration.months || 0}M${duration.days || 0}DT0H0M0S`;
    }

    handleChange = event => {
        const duration = {...this.state.duration};

        duration[event.target.name] = event.target.value;

        this.setState({
            duration
        });

        if (this.props.onChange) {
            this.props.onChange({ target: {
                name: this.props.name,
                value: this.createIsoDuration(duration),
            }});
        }
    };

    render() {
        const { value, InputProps, classes, className, label, fullWidth, disabled, required, label2Lines, ...otherProps } = this.props;
        const { duration } = this.state;

        return <FormControl
            className={classNames(classes.root,{[classes.withLabel]: label}, className)}
            disabled={disabled}
        >
            { label ? <InputLabel shrink disabled={disabled} required={required} variant="outlined" classes={{root: label2Lines ? classes.label2Lines : null, shrink: 'shrink'}}>{label}</InputLabel> : null }
            <Grid container spacing={1} justify="space-between" alignItems="flex-start">
                <Grid item xs={6}>
                    <AnyField
                        {...otherProps}
                        disabled={disabled}
                        name="days"
                        value={duration.days}
                        InputProps={{
                            ...InputProps,
                            inputComponent: NumberFormatCount,
                            endAdornment: <InputAdornment position="end">D</InputAdornment>
                        }}
                        onChange={this.handleChange}
                        fullWidth={false}
                        className={classes.field}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AnyField
                        {...otherProps}
                        disabled={disabled}
                        name="months"
                        value={duration.months}
                        InputProps={{
                            ...InputProps,
                            inputComponent: NumberFormatCount,
                            endAdornment: <InputAdornment position="end">M</InputAdornment>
                        }}
                        onChange={this.handleChange}
                        fullWidth={false}
                        className={classes.field}
                    />
                </Grid>
            </Grid>
        </FormControl>;
    }
}