import React from "react";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/settings";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import AnyField from "../../components/fields/AnyField";
import {Fab, Grid, MenuItem} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {
    getActivityGroups, getDealTypes,
    getReasonClassesDeal,
    getTypeActivities,
    saveSettingNameForActivity
} from "../../services/moneyFlow";
import CheckboxField from "../../components/fields/CheckboxField";


const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
});
export default @connect(state => ({}), {
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class SettingNameForActivity extends React.Component {

    state = {
        settingNames: [{
            // id: '',
            reasonClass: null,
            reasonName: null,
            activityGroup: null,
            activityType: null,
            duplicate: false
        }
        ],
        reasonClasses: {
            BusinessUnit: [],
            Debt: [],
            GoodStorageMove: [],
            Sale: []
        },
        settingNameValues: {},
        activityGroups: [],
        activityTypes: [],
        deleteDT: []
    }

    componentDidMount() {
        const {settingNames} = this.state
        getReasonClassesDeal()
            .then(response => {
                this.setState({
                    reasonClasses: response.data
                })
            });

        getActivityGroups()
            .then(response => {
                this.setState({
                    activityGroups: response.data
                })
            })
        getTypeActivities()
            .then(response => {
                this.setState({
                    activityTypes: response.data
                })
            })
        getDealTypes()
            .then(response => {
                if (response.success)
                    this.setState({
                        settingNames: response.data.length > 0 ? response.data : settingNames
                    })
                else
                    this.props.showMessage(response.error ? response.error.message : response.message);
            })

    }

    handleSave = () => {
        const {settingNames, deleteDT} = this.state

        let settings = {
            deleteDT : deleteDT,
            settingNames: settingNames
        };





        saveSettingNameForActivity(settings)
            .then(response => {
                this.props.showMessage(response.error ? response.error.message : response.message);
                getDealTypes()
                    .then(response => {
                        if (response.success)
                            this.setState({
                                settingNames: response.data.length > 0 ? response.data : settingNames
                            })
                        else
                            this.props.showMessage(response.error ? response.error.message : response.message);
                    })
            })
    }

    handleAdd = () => {
        const {settingNames} = this.state
        settingNames.push({
            // id: '',
            reasonClass: null,
            reasonName: null,
            activityGroup: null,
            activityType: null,
            duplicate: false
        });

        this.setState({
            settingNames: settingNames
        });
    }

    handleDelete = (idx) => () => {
        const {settingNames, deleteDT} = this.state

        // if(settingNames[idx].id !== '')
        //     deleteDT.push(settingNames[idx].id);

        settingNames.splice(idx, 1)
        this.setState({
            settingNames: settingNames,
            deleteDT: deleteDT
        })
    }

    handleChange = (idx) => (event) => {
        const {settingNames} = this.state
        settingNames[idx] = {
            ...settingNames[idx],
            [event.target.name]: event.target.value
        }
        this.setState({
            settingNames: settingNames
        })
    }

    canSave = () => {
        const {settingNames} = this.state

        let checkNull = true;
        settingNames.forEach((elem) => {
            Object.values(elem).forEach((elem2) => {
                if (elem2 === null) {
                    checkNull = false;
                }
            })
        })
        return checkNull;
    }


    render() {
        const {settingNames, reasonClasses, activityGroups, activityTypes} = this.state
        const {t} = this.props

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>

                    <br/>
                    <br/>
                    <ActionButton onClick={this.handleSave}
                                  disabled={!this.canSave()}>Сохранить</ActionButton>
                </LeftBar>
                <Content>
                    {settingNames.map((value, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <Grid style={{display: "flex" }}>
                                    <Grid item xs={2} style={{marginRight: 3}}>
                                        <AnyField
                                            label='Сущность(сделка)'
                                            name='reasonClass'
                                            select
                                            fullWidth
                                            value={value.reasonClass}
                                            onChange={this.handleChange(idx)}
                                        >
                                            {Object.keys(reasonClasses).map(item => <MenuItem key={item}
                                                                                              value={item}> {t("SettingNameActivity." + item)}</MenuItem>)}
                                        </AnyField>
                                    </Grid>
                                    <Grid item xs={4} style={{marginRight: 3}}>
                                        <AnyField
                                            label='Наименование сделки'
                                            name='reasonName'
                                            value={value.reasonName}
                                            fullWidth
                                            onChange={this.handleChange(idx)}
                                            select
                                        >
                                            {reasonClasses[value.reasonClass] ? reasonClasses[value.reasonClass].map(item =>
                                                    <MenuItem key={item.id} value={item.text}>{item.text}</MenuItem>) :
                                                <MenuItem key={null} value={null} disabled>Выберите сущность</MenuItem>}
                                        </AnyField>
                                    </Grid>
                                    <Grid item xs={3} style={{marginRight: 3}}>
                                        <AnyField
                                            label='Группа деятельности'
                                            name='activityGroup'
                                            value={value.activityGroup}
                                            fullWidth
                                            onChange={this.handleChange(idx)}
                                            select
                                        >{activityGroups.map(item =>
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        )}
                                        </AnyField>
                                    </Grid>
                                    <Grid item xs={4} style={{marginRight: 3}}>
                                        <AnyField
                                            label='Вид деятельности'
                                            name='activityType'
                                            value={value.activityType}
                                            fullWidth
                                            onChange={this.handleChange(idx)}
                                            select
                                        >{activityTypes.map(item =>
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                        </AnyField>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <CheckboxField
                                        label="Дублирование"
                                        name="duplicate"
                                        value={value.duplicate}
                                        onChange={this.handleChange(idx)}
                                        />
                                    </Grid>
                                    <Grid item xs={1} style={{marginTop: 17, marginLeft: '4vh', float: "right"}}>
                                        <Fab size="small" color="secondary"
                                             onClick={this.handleDelete(idx)}><ClearIcon
                                        /></Fab>
                                    </Grid>
                                </Grid>
                            </React.Fragment>);
                    })
                    }

                    <Grid item xs={2}>
                        <ActionButton onClick={this.handleAdd} size={'small'}>Добавить</ActionButton>
                    </Grid>
                </Content>
            </React.Fragment>

        )
    }
}