import React from "react"
import * as PropTypes from "prop-types"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import GuestField from "../../components/fields/GuestField";
import {withTranslation} from "react-i18next";
import {createAndClosePayDocsSale} from "../../services/sale";
import infoActions from "../../components/info/info-actions";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";

const blankSale = {
    id: null,
    guestId: null
}

export default
@withTranslation()
@connect(() => ({}),
    {
        showError: infoActions.showError,
        showMessage: messageDialogActions.show
    }
)
class PayDocsSaleDialog extends React.Component {

    static propTypes = {
        payDocs: PropTypes.array,
        open: PropTypes.bool,
        onClose: PropTypes.func,
        onCompleteSale: PropTypes.func,
        onChangeGuestId: PropTypes.func
    }

    static defaultProps = {
        open: false,
        onChangeGuestId: f => f
    }

    state = {
        open: false,
        sale: blankSale
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        let state = {}

        if (nextProps.open !== prevState.open) {
            state = {
                open: nextProps.open,
                sale: blankSale
            }
        }

        return state
    }

    handleChangeSale = (event) => {
        this.setState({
            sale:{
                [event.target.name]: event.target.value
            }
        })

        if (event.target.name === 'guestId') {
            this.props.onChangeGuestId(event.target.value)
        }
    }

    handleCreateAndClosePayDocsSale = () => {

        const { guestId } = this.state.sale
        const { payDocs } = this.props

        createAndClosePayDocsSale(guestId, payDocs.map(pd => pd.id))
            .then((response) => {
                if (response.success) {
                    this.setState({
                        sale: response.data
                    },
                        this.props.onCompleteSale
                    )
                } else {
                    this.props.showError(response.error ? response.error.message : response.message);
                }
            })
    }

    render() {

        const { t, open, onClose } = this.props
        const { sale } = this.state

        return (
            <Dialog
                open={open}
                fullWidth
                onClose={onClose}
            >
                <DialogTitle>{t("Sale of multiple paydocs")}</DialogTitle>
                <DialogContent>
                    <GuestField
                        name={"guestId"}
                        label={t("Guest")}
                        onChange={this.handleChangeSale}
                        value={sale.guestId}
                        fullWidth
                        readOnly={!!sale.id}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={this.handleCreateAndClosePayDocsSale}
                        disabled={!sale.guestId}
                    >
                        {t("payDoc.doSale")}
                    </Button>
                    <Button
                        onClick={onClose}
                        color="primary"
                    >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
