import baseRequest from "./baseReuqest"

export const navLinks = [
    { path: "/tasks", text: "Задачи", role: "ROLE_UI_TAB_TASKS"},
    { path: "/tasks/delivery", text: "Задачи на доставку", role: "ROLE_UI_TAB_TASKS"},
    { path: "/tasks/statuses", text: "Статусы задач", role: "ROLE_UI_TAB_TASKS_STATUSES"},
    { path: "/tasks/priorities", text: "Приоритеты задач", role: "ROLE_UI_TAB_TASKS_PRIORITIES"},
    { path: "/tasks/types", text: "Типы задач", role: "ROLE_UI_TAB_TASKS_TYPES"},
];

export function getTaskStatuses(page = 1, per_page = -1, order, order_by) {
    return baseRequest('/api/task/status', {query: {page, per_page, order, order_by}});
}

export const emptyStatus = {
    id: null,
    title: null,
    buttonTitle: null,
};

export function saveTaskStatus(status) {
    return baseRequest('/api/task/status',{
        method: 'POST',
        body: status,
    })
}

export function getTaskPriorities(page = 1, per_page = -1, order, order_by) {
    return baseRequest('/api/task/priority', {query: {page, per_page, order, order_by}});
}

export const emptyPriority = {
    id: null,
    title: null,
    color: '#000000',
};

export function saveTaskPriority(priority) {
    return baseRequest('/api/task/priority',{
        method: 'POST',
        body: priority,
    })
}

export function getTaskTypes(page = 1, per_page = -1, order, order_by) {
    return baseRequest('/api/task/type', {query: {page, per_page, order, order_by}});
}

export function getTaskTypesForTask() {
    return baseRequest('/api/task/type', {query: {per_page: -1, order_by: 'title', include: 'availableStatuses,availablePriorities'}});
}

export function getTaskType(id) {
    return baseRequest(`/api/task/type/${id}`, {query: {include: 'availableStatusesIds,availablePrioritiesIds,workflow,transitions,extraFields'}});
}

export const emptyTaskType = {
    id: null,
    title: null,
    isSystem: false,
    system: null,
    linkedObject: null,
    availableStatuses: [],
    availablePriorities: [],
    availableStatusesIds: [],
    availablePrioritiesIds: [],
    workflow: [],
    transitions: [],
    extraFields: [],
    extraFieldsTypes: [],
    startDefaultStatus: null,
    finishStatus: null,
};

export const emptyTaskWorkflow = {
    id: null,
    fromStatus: null,
    toStatus: null,
};

export const emptyTaskTransition = {
    id: null,
    fromStatus: null,
    toStatus: null,
    trigger: null,
};

export const emptyTaskExtraField = {
    id: null,
    sort: null,
    title: null,
    name: null,
    type: null,
};

export function saveTaskType(taskType) {
    return baseRequest('/api/task/type',{
        method: 'POST',
        body: taskType,
    })
}

export function getLinkObjectTypes() {
    return baseRequest('/api/task/link-object');
}


export function getTasks(search, page, per_page, order, order_by)
{
    return baseRequest('/api/task', {query: {search, page, per_page, order, order_by}});
}

export function getDeliveryTasks(search, page, per_page, order, order_by)
{
    return baseRequest('/api/task/delivery', {query: {search, page, per_page, order, order_by}});
}

const taskIncludes = 'parent,files,watchers,nextWorkflow,nextWorkflow.toStatusTitle,nextWorkflow.toStatusButton,executor,executorBusinessUnit,executorDepartment,extraFieldsData';

export function getTask(id) {
    return baseRequest(`/api/task/${id}`, {query: {include: taskIncludes}});
}

export const emptyTask = {
    id: null,
    num: null,
    title: null,
    description: null,
    expectDate: null,
    creator: null,
    createdAt: null,
    files: [],
    executor: null,
    executorBusinessUnit: null,
    executorDepartment: null,
    executedAt: null,
    nextWorkflow: [],
    extraFieldsData: [],
    canceled: false,
    parent: {
        id: null,
        num: null,
    },
};

export function saveTask(task)
{
    return baseRequest('/api/task',{
        method: 'POST',
        body: task,
        query: {include: taskIncludes},
    })
}

export function getExtraFieldTypes() {
    return baseRequest('/api/task/extra-field-types');
}

export function getExtraFields(taskTypeId) {
    return baseRequest(`/api/task/extra-fields/${taskTypeId}`);
}

export function getTriggers() {
    return baseRequest('/api/task/triggers');
}

export function getSystemTypes() {
    return baseRequest('/api/task/system-types');
}

export function getNotCompletedTasks(businessUnitId, date = null) {
    return baseRequest('/api/task/get-not-completed', {
        query: { businessUnitId, date },
    });
}