import React, {useCallback, useContext} from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import Context from '../Context';

const DateType = () => {
    const { search: { dateType }, updateSearch } = useContext(Context);

    const handleChange = useCallback((event) => {
        updateSearch(draftSearch => {
            draftSearch.dateType = event.target.value;
        });
    }, [updateSearch]);

    return (
        <FormControl component="fieldset" fullWidth>
            <RadioGroup
                value={dateType}
                onChange={handleChange}
                row
            >
                <FormControlLabel control={<Radio/>} value="period" label="за период"/>
                <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
            </RadioGroup>
        </FormControl>
    );
};

export default DateType;