import React from 'react';
import DateType from "./DateType";
import PeriodMonth from "./PeriodMonth";
import BusinessUnits from "./BusinessUnits";

const Filter = () => {
    return (
        <>
            <DateType/>
            <PeriodMonth/>
            <BusinessUnits/>
        </>
    );
};

export default Filter;