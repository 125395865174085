import React from 'react';
import {getHistoryGuest, getPayDoc, navLinks} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import HistoryLeftFields from "./HistoryLeftFields";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";

const columnData = [
    { id: 'dt', numeric: false, disablePadding: false, label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'oldPhone', numeric: false, disablePadding: false, label: 'Previous guest phone' },
    { id: 'oldName', numeric: false, disablePadding: false, label: 'Previous guest full name' },
    { id: 'newPhone', numeric: false, disablePadding: false, label: 'Next guest phone' },
    { id: 'newName', numeric: false, disablePadding: false, label: 'Next guest full name' },
    { id: 'comment', label: 'Comment' },
    { id: 'user', numeric: false, disablePadding: false, label: 'User' },
];

@withRouter
@withTranslation()
class HistoryGuestPage extends React.Component {

    state = {
        id: '',
        payDoc: {
            id: null,
            num: null,
            type: null,
            statusId: null,
            status: null,
            place: null,
            pin: null,
            phone: null,
            fio: null,
            balance: null,
            validity: null,
            canRefill: false
        },
        refreshTable: false,
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getPayDoc(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            payDoc: response.data
                        })
                    }
                });
        }
    }

    getTableData = (...params) => {
        return this.state.id ? getHistoryGuest(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { t } = this.props;
        const { payDoc, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("Guest change history")}/>}>
                    <HistoryLeftFields payDoc={payDoc}/>
                </LeftBar>
                <Content title={t("Guest change history")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default HistoryGuestPage;
