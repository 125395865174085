import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import {getEquipments, getParlours, navLinks} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";

export default
@withTranslation()
class EquipmentListPage extends React.Component {

    state = {
        search: {
            search: null,
            businessUnitId: null,
        },
        refreshTable: false,
        parlours: [],
    };

    componentDidMount() {
        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });
    }

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            },
            refreshTable: prop === 'businessUnitId' ? !this.state.refreshTable : this.state.refreshTable,
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/organization/equipments/view/0`);
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getEquipments(search, ...params);
    };

    columnData = [
        { id: 'name', label: 'Название' },
        { id: 'typeText', label: 'Тип' },
        { id: 'statusText', label: 'Статус'},
        { id: 'businessUnit.name', label: 'Бизнес юнит'},
        { id: 'numberGuestsSameTime', label: 'Кол-во мест'}
    ];

    render() {
        const { search, refreshTable, parlours } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <SelectFromItemsField
                        items={parlours}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        nullable
                        fullWidth
                        label="Салон"
                        nullableText="Все"
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Оборудование салонов">
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/organization/equipments/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
