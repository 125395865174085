import React from 'react';
import {DropTarget} from "react-dnd";
import * as PropTypes from "prop-types";
import {propTypeDuration, propTypeMoment} from "../../utils/moment-utils";
import withStyles from "@material-ui/core/styles/withStyles";
import dimensions from "./ScheduleVisitsWeekDimensions";
import * as cn from "classnames";
import Tooltip from "@material-ui/core/Tooltip";

export default
@DropTarget(
    'SCHEDULE_EVENT',
    {
        drop(props) {
            return props.onSlotDrop ? props.onSlotDrop(props.slot) : props;
        },

        hover(props, monitor) {
            if (monitor.canDrop() && props.onSlotDropHover) {
                const item = monitor.getItem();
                props.onSlotDropHover(props.slot.fullValue, props.subColumn, item.element.visitIdx, item.element.vfIdx, item.element.masterIdx);
            }
        },

        canDrop(props, monitor) {
            if (props.disabled) {
                return false;
            }

            const item = monitor.getItem();
            if (item.element && item.element.masterRequested) {
                return props.subColumn.id === item.element.masterId;
            }

            return true;
        }
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
    })
)
@withStyles(theme => ({
    root: {
        zIndex: 2,
        height: dimensions.slotHeight,
        borderBottom: '1px solid rgba(92,92,92,0.51)',
        '&:last-of-type': {
            borderBottom: 'none',
        },
        '&:not($disabled):hover': {
            background: 'rgba(115,115,115,0.51) !important',
        },
        '&:not($disabled):focus': {
            background: 'rgba(115,115,115,0.51) !important',
        }
    },
    beginGroup: {
        borderTop: '1px solid #777',
    },
    slotOver: {
        backgroundColor: 'rgba(0,165,3,0.51)',
    },
    disabled: {
        backgroundColor: 'rgba(0,0,0,0.51)',
        cursor: 'default',

        '@media print': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        }
    },
    disabledTitle: {
        borderTop: '1px solid #c0c0c0',
        fontSize: '70%',
        color: '#fff',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    adminGap: {
        backgroundImage: 'url(/img/blue-dot-alpha20.png)',
    },
}))
class ScheduleVisitsWeekSlot extends React.PureComponent {
    static propTypes = {
        slot: PropTypes.shape({
            beginGroup: PropTypes.bool,
            endGroup: PropTypes.bool,
            value: propTypeDuration,
            valueEnd: propTypeDuration,
            fullValue: propTypeMoment,
            fullValueEnd: propTypeMoment,
            beginGroupValue: propTypeDuration,
            endGroupValue: propTypeDuration,
            beginGroupFullValue: propTypeMoment,
            endGroupFullValue: propTypeMoment,
            style: PropTypes.object,
        }).isRequired,

        subColumn: PropTypes.object,

        onSlotClick: PropTypes.func,
        onSlotDbClick: PropTypes.func,
        onSlotContextMenu: PropTypes.func,
        onSlotDrop: PropTypes.func,
        onSlotDropHover: PropTypes.func,

        disabled: PropTypes.bool,
        disabledTitle: PropTypes.any,

        adminGap: PropTypes.bool,
    }

    static defaultProps = {
        disabled: false,
    }

    handleClick = event => {
        const { disabled, onSlotClick, slot, subColumn } = this.props;
        if (!disabled && onSlotClick) {
            onSlotClick(slot.fullValue, subColumn, event);
        }
    }

    handleDoubleClick = event => {
        const { disabled, onSlotDbClick, slot, subColumn } = this.props;
        if (!disabled && onSlotDbClick) {
            onSlotDbClick(slot.fullValue, subColumn, event);
        }
    }

    handleContextMenu = event => {
        const { disabled, onSlotContextMenu, slot, subColumn } = this.props;

        if (!disabled && onSlotContextMenu && !event.altKey) {
            onSlotContextMenu(slot.fullValue, subColumn, event);
        }
    }

    render() {
        const {
            classes,
            connectDropTarget,
            slot,
            disabled,
            disabledTitle,
            children,
            adminGap,
        } = this.props;

        return connectDropTarget(<div
            className={cn(classes.root, {[classes.beginGroup]: slot.beginGroup, [classes.disabled]: disabled, [classes.adminGap]: adminGap})}
            //onClick={this.handleClick}
            onFocus={this.handleClick}
            onDoubleClick={this.handleDoubleClick}
            onContextMenu={this.handleContextMenu}
            tabIndex={10}
            style={slot.style}
        >{children ? children : disabledTitle ? <Tooltip title={disabledTitle}><div className={classes.disabledTitle}>{disabledTitle}</div></Tooltip> : null} </div>);
    }
}