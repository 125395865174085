import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import MoneyField from "../../components/fields/MoneyField";
import produce from "immer";
import { getCheckingAccounts } from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import { sortBy } from "lodash";

const useStyles = makeStyles({
    content: {
        minWidth: 600,
    },
});

function DealViewTransactionEditDialog({ transaction: outerTransaction, onClose, onSubmit }) {
    const { t } = useTranslation();
    const [transaction, setTransaction] = useState(outerTransaction);
    const [checkingAccounts, setCheckingAccounts] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        getCheckingAccounts().then(response => {
            if (response.success) {
                setCheckingAccounts(
                    sortBy(
                        response.data.map(acc => ({
                            value: acc.id,
                            label: `${acc.juridicalPersonShortName} - ${acc.rs}`,
                            data: acc,
                        })),
                        "label",
                    ),
                );
            }
        });
    }, []);

    const handleOk = useCallback(() => {
        onSubmit(transaction);
    }, [transaction, onSubmit]);

    const handleChange = useCallback((e) => {
        const name = e.target.name;
        const value = e.target.value;
        setTransaction(produce(draft => {
            draft[name] = value;
        }));
    }, []);

    const fromCheckingAccountId = useMemo(() => {
        if (transaction.from.parentClass === "checking_account") {
            return transaction.from.parentId;
        }

        return null;
    }, [transaction]);

    const toCheckingAccountId = useMemo(() => {
        if (transaction.to.parentClass === "checking_account") {
            return transaction.to.parentId;
        }

        return null;
    }, [transaction]);

    const handleChangeFromAccountId = useCallback((e) => {
        const accountId = e.target.value;
        const account = checkingAccounts.find(it => it.value === accountId);
        setTransaction(produce(draft => {
            draft.from.parentId = accountId;
            draft.fromParentName = account.data.rs;
        }));
    }, [checkingAccounts]);

    const handleChangeToAccountId = useCallback((e) => {
        const accountId = e.target.value;
        const account = checkingAccounts.find(it => it.value === accountId);
        setTransaction(produce(draft => {
            draft.to.parentId = accountId;
            draft.toParentName = account.data.rs;
        }));
    }, [checkingAccounts]);

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>Редактирование транзакции</DialogTitle>
            <DialogContent className={classes.content}>
                <MoneyField
                    label={t("Sum")}
                    value={transaction.amount}
                    name="amount"
                    onChange={handleChange}
                    fullWidth
                />
                {!!fromCheckingAccountId && (
                    <AutocompleteSelectField
                        label={`Откуда ${transaction.from.name}`}
                        value={fromCheckingAccountId}
                        onChange={handleChangeFromAccountId}
                        options={checkingAccounts}
                        fullWidth
                        menuPosition="fixed"
                    />
                )}
                {!!toCheckingAccountId && (
                    <AutocompleteSelectField
                        label={`Куда РС ${transaction.to.name}`}
                        value={toCheckingAccountId}
                        onChange={handleChangeToAccountId}
                        options={checkingAccounts}
                        fullWidth
                        menuPosition="fixed"
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">OK</Button>
                <Button onClick={onClose} color="primary">{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DealViewTransactionEditDialog;

DealViewTransactionEditDialog.propTypes = {
    transaction: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};