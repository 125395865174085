import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {navLinks, getPayDocTypes} from "../../services/payDocs";
import HLine from "../../components/left-bar/HLine";

const columnData = [
    { id: 'code', label: 'Код' },
    { id: 'name', label: 'Название' },
    { id: 'hasSubtype', label: 'Имеет подтипы' },
    { id: 'canSetNominal', label: 'Можно установить номинал' },
    { id: 'isPaper', label: 'Бумажный' },
    { id: 'canRechargeDefault', label: 'Пополняемый' },
    { id: 'electronic', label: 'Электронный' },
    { id: 'measureText', label: 'Мера' },
    { id: 'variantText', label: 'Тип' },
    { id: 'onlyOneVisit', label: 'Только на один визит' },
    { id: 'credit', label: 'Кредитный' },
    { id: 'validityMonth', label: 'Срок действия, мес'},
];

class PayDocTypeListPage extends React.Component {

    state = {
        search: {
            search: null
        },
        refreshTable: false
    };

    handleChange = event => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/pay-docs/types/0`);
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getPayDocTypes(search, ...params);
    };

    editLink = item => `/pay-docs/types/${item.id}`

    render() {
        const { search, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        name="search"
                        onChange={this.handleChange}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <ActionButton onClick={this.handleClickSearch}>Поиск</ActionButton>
                    <HLine/>
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Типы платежных документов">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={this.editLink}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default PayDocTypeListPage;
