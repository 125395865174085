import React from 'react';
import {getDownloadLink} from "./services/storage";
import ActionButton from "./components/button/ActionButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Content from "./components/content/Content";

const styles = {
    button: {
        width: 300,
        display: 'flex',
        margin: '40px auto'
    }
};

export default
@withStyles(styles)
class Download extends React.Component {
    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);

        if (resultId.length && resultId[0]) {
            window.location.href = getDownloadLink(resultId[0]);
        }
    }

    render() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        const {classes} = this.props;

        if (resultId.length && resultId[0]) {
            return (
                <Content>
                    <ActionButton className={classes.button} color="primary" href={getDownloadLink(resultId[0])}>Download</ActionButton>
                </Content>
            );
        }

        return null;
    }
}