import {isValidPhoneNumber as checkPhoneNumber} from "react-phone-number-input";

export function isValidPhoneNumber (phone) {
    if (phone) {
        if (!phone.startsWith('+')) {
            phone = '+' + phone;
        }
        return checkPhoneNumber(phone);
    }

    return false;
}