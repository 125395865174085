import React from 'react';
import AnyField from "../../../components/fields/AnyField";
import {Grid, MenuItem} from "@material-ui/core";
import * as propTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import {getCommissionSchemeValues} from '../../../services/organization';
import {getPrices} from "../../../services/facilities";

export default

@withTranslation()

class PropertyReseller extends React.Component {
    static propTypes = {
        externalSubject: propTypes.object,
        commissionScheme: propTypes.array,
        handleChange: propTypes.func,
    }

    static defaultProps = {
        externalSubject: {},
        commissionSchemes: [],
    }

    state = {
        commissionSchemeValues: [],
        prices: [],
    }

    componentDidMount() {
        getCommissionSchemeValues()
            .then(response => {
                if (response.success) {
                    this.setState({
                        ...this.state,
                        commissionSchemeValues: response.data,
                    })
                }
            })

        getPrices(null, null, -1)
            .then(response => {
                if (response.success) {
                    this.setState({
                        ...this.state,
                        prices: response.data
                    })
                }
            })
    }

    render() {
        const { externalSubject, commissionSchemes, handleChange, t } = this.props;
        const { commissionSchemeValues, prices } = this.state;

        return (
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <AnyField
                        label={t('Money.schemeOfCalculationOfCommission')}
                        value={externalSubject.commissionScheme}
                        onChange={handleChange('commissionScheme')}
                        fullWidth
                        select
                    >
                        <MenuItem value={null}>{t('Not chosen')}</MenuItem>
                        {commissionSchemes.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                    </AnyField>
                </Grid>
                {
                    externalSubject.commissionScheme === commissionSchemeValues.PRICE
                    ?
                    <Grid item xs={2}>
                        <AnyField
                            label={t('Price list')}
                            value={externalSubject.price}
                            onChange={handleChange('price')}
                            fullWidth
                            select
                        >
                            <MenuItem value={null}>{t('Not chosen')}</MenuItem>
                            {prices.map(item => <MenuItem key={item.id}
                                                                     value={item.id}>{item.name}</MenuItem>)}
                        </AnyField>
                    </Grid>
                    : null
                }
                {
                    externalSubject.commissionScheme === commissionSchemeValues.FORMULA
                    ?
                        <Grid item xs={4}>
                            <AnyField
                                id="commissionFormula"
                                label={t('OrganizationPage.FormulaForCalculatingFee')}
                                value={externalSubject.commissionFormula}
                                fullWidth
                                onChange={handleChange('commissionFormula')}
                                multiline
                                rows={4}
                                helperText={t('OrganizationPage.formulaDescription')}
                            />
                        </Grid>
                    : null
                }
            </Grid>
        )
    }

}