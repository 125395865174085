import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar"
import NavigationLinks from "../../components/navigation-links/NavigationLinks"
import {
    emptyTaskExtraField,
    emptyTaskTransition,
    emptyTaskType, emptyTaskWorkflow, getExtraFieldTypes,
    getLinkObjectTypes, getTaskPriorities,
    getTaskStatuses, getTaskType,
    getTaskTypes, getTriggers,
    getSystemTypes,
    navLinks,
    saveTaskType
} from "../../services/tasks"
import Content from "../../components/content/Content"
import DataTable from "../../components/data-table/DataTable";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import CheckboxField from "../../components/fields/CheckboxField";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FieldsList from "../../components/fields/FieldsList";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";

export default
@connect(null,{
    showMessage: messageDialogActions.show
})
class TypesPage extends React.Component {
    columnData = [
        {id: 'title', label: 'Заголовок'}
    ]

    state = {
        taskType: emptyTaskType,
        dialogEditOpen: false,
        refreshTable: false,
        linkedObjectTypes: [{value: null, label: '-- нет --'}],
        taskStatuses: [],
        taskPriorities: [],
        extraFieldsTypes: [],
        triggers: [],
        systemTypes: [],
    }

    componentDidMount() {
        getLinkObjectTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        linkedObjectTypes: [{value: null, label: '-- нет --'}, ...response.data.map(item => ({value: item, label: item}))],
                    })
                }
            });

        getTaskStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskStatuses: response.data,
                    })
                }
            })

        getTaskPriorities()
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskPriorities: response.data,
                    })
                }
            })

        getSystemTypes()
            .then(response => {
                if (response.success) {
                   this.setState({
                       systemTypes: response.data,
                   })
                }
            })

        getExtraFieldTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        extraFieldsTypes: response.data,
                    })
                }
            });

        getTriggers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        triggers: response.data,
                    })
                }
            });
    }

    handleAdd = () => {
        this.setState({
            taskType: emptyTaskType,
            dialogEditOpen: true,
        })
    }

    handleEdit = taskType => {
        getTaskType(taskType.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        taskType: response.data,
                        dialogEditOpen: true,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleChange = prop => event => {
        this.setState({
            taskType: {
                ...this.state.taskType,
                [prop]: event.target.value,
            }
        })
    }

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        saveTaskType(this.state.taskType)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })

    }

    render() {
        const { taskType, dialogEditOpen, refreshTable, linkedObjectTypes, taskStatuses, taskPriorities, extraFieldsTypes, triggers, systemTypes } = this.state;

        const availableStatuses = taskStatuses.filter(status => taskType.availableStatusesIds.indexOf(status.id) !== -1);

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
            </LeftBar>
            <Content title="Типы задач">
                <DataTable
                    columnData={this.columnData}
                    dataFunc={getTaskTypes}
                    refresh={refreshTable}
                    onClick={this.handleEdit}
                    selector={false}
                />
            </Content>
            <Dialog
                open={dialogEditOpen}
                onClose={this.handleCloseEditDialog(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                fullScreen
            >
                <DialogTitle id="dialog-title">Тип задачи</DialogTitle>
                <DialogContent style={{overflowY: 'visible'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <AnyField
                                        label="Наименование"
                                        value={taskType.title}
                                        onChange={this.handleChange('title')}
                                        required
                                        error={!taskType.title}
                                        fullWidth
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label="Системная задача"
                                        value={taskType.isSystem}
                                        onChange={this.handleChange('isSystem')}
                                        fullWidth
                                        margin="none"
                                    />
                                    <SelectFromItemsField
                                        items={systemTypes}
                                        onChange={this.handleChange('system')}
                                        value={taskType.system}
                                        fullWidth
                                        required
                                        textField="text"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AnyField
                                        label="Используемые статусы"
                                        value={taskType.availableStatusesIds}
                                        onChange={this.handleChange('availableStatusesIds')}
                                        select
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: selected => {
                                                return taskStatuses.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.title).join(', ');
                                            },
                                        }}
                                        fullWidth
                                    >
                                        {taskStatuses.map(status => <MenuItem key={status.id} value={status.id}>
                                            <Checkbox checked={taskType.availableStatusesIds.indexOf(status.id) > -1} disabled={taskType.workflow.findIndex(item => item.fromStatus === status.id || item.toStatus === status.id) > -1} />
                                            <ListItemText primary={status.title} />
                                        </MenuItem>)}
                                    </AnyField>
                                </Grid>
                                <Grid item xs={12}>
                                    <AnyField
                                        label="Используемые приоритеты"
                                        value={taskType.availablePrioritiesIds}
                                        onChange={this.handleChange('availablePrioritiesIds')}
                                        select
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: selected => {
                                                return taskPriorities.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.title).join(', ');
                                            },
                                        }}
                                        fullWidth
                                    >
                                        {taskPriorities.map(priority => <MenuItem key={priority.id} value={priority.id}>
                                            <Checkbox checked={taskType.availablePrioritiesIds.indexOf(priority.id) > -1} />
                                            <ListItemText primary={priority.title} />
                                        </MenuItem>)}
                                    </AnyField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <FieldsList
                                items={taskType.workflow}
                                emptyItem={emptyTaskWorkflow}
                                fullWidth
                                label="Возможные переходы"
                                onChange={this.handleChange('workflow')}
                                headers={['Из статуса', 'В статус']}
                                fields={[
                                    (item, onChange) => <AnyField
                                        value={item.fromStatus}
                                        onChange={onChange('fromStatus')}
                                        select
                                        fullWidth
                                        required
                                        error={!item.fromStatus || item.fromStatus === item.toStatus}
                                    >
                                        {availableStatuses.map(status => <MenuItem key={status.id} value={status.id}>{status.title}</MenuItem>)}
                                    </AnyField>,
                                    (item, onChange) => <AnyField
                                        value={item.toStatus}
                                        onChange={onChange('toStatus')}
                                        select
                                        fullWidth
                                        required
                                        error={!item.toStatus || item.fromStatus === item.toStatus}
                                    >
                                        {availableStatuses.map(status => <MenuItem key={status.id} value={status.id}>{status.title}</MenuItem>)}
                                    </AnyField>,
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Статус новой задчи по умолчанию"
                                value={taskType.startDefaultStatus}
                                onChange={this.handleChange('startDefaultStatus')}
                                fullWidth
                                select
                            >
                                {availableStatuses.map(status => <MenuItem key={status.id} value={status.id}>{status.title}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Статус выполнения задачи"
                                value={taskType.finishStatus}
                                onChange={this.handleChange('finishStatus')}
                                fullWidth
                                select
                            >
                                {availableStatuses.map(status => <MenuItem key={status.id} value={status.id}>{status.title}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteSelectField
                                label="Тип связанного объекта"
                                value={taskType.linkedObject}
                                onChange={this.handleChange('linkedObject')}
                                fullWidth
                                options={linkedObjectTypes}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldsList
                                items={taskType.transitions}
                                emptyItem={emptyTaskTransition}
                                fullWidth
                                label="События"
                                onChange={this.handleChange('transitions')}
                                headers={['Из статуса', 'В статус', 'Триггер']}
                                fields={[
                                    (item, onChange) =>
                                        <SelectFromItemsField
                                            items={availableStatuses}
                                            nullableText="--Нет--"
                                            nullable
                                            onChange={onChange('fromStatus')}
                                            value={item.fromStatus}
                                            fullWidth
                                            required
                                            textField="title"
                                        />,
                                    (item, onChange) =>
                                        <SelectFromItemsField
                                            items={availableStatuses}
                                            onChange={onChange('toStatus')}
                                            value={item.toStatus}
                                            fullWidth
                                            required
                                            textField="title"
                                        />,
                                    (item, onChange) => <SelectFromItemsField
                                        items={triggers}
                                        onChange={onChange('trigger')}
                                        value={item.trigger}
                                        fullWidth
                                        required
                                        textField="title"
                                    />
                                ]}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldsList
                            items={taskType.extraFields}
                            emptyItem={emptyTaskExtraField}
                            fullWidth
                            label="Дополнительные поля"
                            onChange={this.handleChange('extraFields')}
                            headers={['Сортировка', 'Подпись', 'Название', 'Тип поля']}
                            fields={[
                                (item, onChange) =>
                                <AnyField
                                    value={item.sort}
                                    onChange={onChange('sort')}
                                    fullWidth
                                    required
                                    error={!item.sort}
                                />,
                                (item, onChange) =>
                                <AnyField
                                    value={item.title}
                                    onChange={onChange('title')}
                                    fullWidth
                                    required
                                    error={!item.title}
                                />,
                                (item, onChange) =>
                                <AnyField
                                    value={item.name}
                                    onChange={onChange('name')}
                                    fullWidth
                                    required
                                    error={!item.name}
                                />,
                                (item, onChange) =>
                                <SelectFromItemsField
                                    items={extraFieldsTypes}
                                    onChange={onChange('type')}
                                    value={item.type}
                                    fullWidth
                                    required
                                    textField="text"
                                />
                            ]}
                        />
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseEditDialog(true)} disabled={!taskType.title} color="primary">OK</Button>
                    <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    }
}
