import React from 'react';
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    root: {
        color: 'green',
    },
});

const CorrectValue = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {children}
        </div>
    );
};

CorrectValue.propTypes = {
    children: PropTypes.node,
};

export default CorrectValue;