import React from 'react';
import {getDynamicDateFieldTypes} from "../../services/common";
import * as PropTypes from "prop-types";
import {FormControl, Grid, InputLabel, withStyles} from "@material-ui/core";
import classNames from "classnames";
import SelectFromItemsField from "./SelectFromItemsField";
import DatePeriodField2 from "./DatePeriodField2";
import CheckboxField from "./CheckboxField";
import {withTranslation} from "react-i18next";
import moment from "moment";

const styles = theme => ({
    field: {
        marginTop: 0,
    },
    root: {
        marginTop:  theme.spacing(1),
        width: '100%'
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
});

const DynamicDateFieldTypes = {
    interval: 'interval',
    past_day: 'past_day',
    past_month: 'past_month',
    past_year: 'past_year',
    current_day: 'current_day',
    current_month: 'current_month',
    current_year: 'current_year',
    future_day: 'future_day',
    future_month: 'future_month',
    future_year: 'future_year',
}

export const emptyDynamicDateField = {
    type: null,
    dateStart: null,
    dateEnd: null
};

export default
@withStyles(styles)
@withTranslation()
class DynamicDateField extends React.PureComponent
{
    static propTypes = {
        value: PropTypes.object,
        onChange: PropTypes.func,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        name: PropTypes.string,
        className: PropTypes.string,
        ignoreYear: PropTypes.bool,
    };

    static defaultProps = {
        ignoreYear: false
    }

    constructor(props) {
        super(props);

        this.state = {
            types: []
        }
    }

    componentDidMount() {
        getDynamicDateFieldTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data
                    });
                }
            });
    }

    onChange = event => {
        let { value, name } = this.props;

        value = {
            ...value,
            [event.target.name]: moment(event.target.value).format("YYYY-MM-DD")
        }

        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    };

    onChangeFilter = event => {
        let { value, name } = this.props;

        value = {
            ...value,
            dateStart: null,
            dateEnd: null,
            [event.target.name]: event.target.value
        }

        this.props.onChange({
            target: {
                name: name,
                value: value,
            }
        });
    };

    render() {
        const { types } = this.state;
        const {
            disabled, required, label, className,
            classes, t, ignoreYear
        } = this.props;

        let { value } = this.props;

        if (!value) {
            value = { ...emptyDynamicDateField };
        }

        return <FormControl
                className={classNames(classes.root,{[classes.withLabel]: label}, className)}
                disabled={disabled}
            >
            { label ? <InputLabel shrink disabled={disabled} required={required} variant="outlined">{label}</InputLabel> : null }
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={value.type === DynamicDateFieldTypes.interval  ? 4 :
                    ignoreYear && value.type ? 8 : 12}>
                    <SelectFromItemsField
                        items={types}
                        valueField="id"
                        textField="text"
                        label={null}
                        name="type"
                        onChange={this.onChangeFilter}
                        value={value.type}
                        fullWidth
                        nullable
                    />
                </Grid>
                { value.type === DynamicDateFieldTypes.interval &&
                    <Grid item xs={ ignoreYear ? 4 : 8}>
                        <DatePeriodField2
                            valueFrom={value.dateStart}
                            valueTo={value.dateEnd}
                            nameFrom="dateStart"
                            nameTo="dateEnd"
                            onChangeFrom={this.onChange}
                            onChangeTo={this.onChange}
                            className={classes.field}
                        />
                    </Grid>
                }
                { ignoreYear && value.type &&
                    <Grid item xs={4}>
                        <CheckboxField
                            label={t("Ignore the year")}
                            name="ignoreYear"
                            onChange={this.onChange}
                            value={value.ignoreYear}
                            className={classes.field}
                            nullable
                        />
                    </Grid>
                }
            </Grid>
        </FormControl>
    }
}
