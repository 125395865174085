import React from 'react';
import * as PropTypes from 'prop-types';
import AnyField from "./AnyField";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/core";


function NumberFormatMass(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=" "
            allowNegative={false}
            decimalScale={3}
        />
    );
}

@withStyles({
    button: {
        padding: 5,
        marginRight: -8
    },
})
class MassField extends React.PureComponent
{
    state = {
        anchorEl: null,
    }

    handleOpenUnits = event => {
        if (this.props.readOnly || this.props.disableUnitSelect) {
            return;
        }
        this.setState({
            anchorEl: event.currentTarget,
        });
    }

    handleCloseUnits = event => {
        const unit = event.currentTarget.getAttribute('itemid');
        if (unit && this.props.onChange) {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: {...this.props.value, unit},
                }
            });
        }

        this.setState({
            anchorEl: null,
        });
    }

    handleChange = event => {
        if (this.props.onChange) {
            const value = event.target.value !== '' ? parseFloat(event.target.value) : null;
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: {...this.props.value, val: value},
                }
            });
        }
    }

    render() {
        const { classes, InputProps, value, units, disableUnitSelect, ...otherProps } = this.props;
        const { anchorEl } = this.state;
        const currentUnit = value ? value.unit : ' ';
        let currentUnitText = currentUnit;
        if (units && units.length) {
            const found = units.find(unit => unit.id === currentUnit);
            if (found) {
                currentUnitText = found.text;
            }
        }
        return <AnyField
            {...otherProps}
            value={value ? value.val : null}
            InputProps={{
                ...InputProps,
                inputComponent: NumberFormatMass,
                endAdornment: Boolean(units.length) && units[0] && <>
                    <Button onClick={this.handleOpenUnits} className={classes.button}>
                        { currentUnitText } { !this.props.readOnly && !disableUnitSelect && <ArrowDropDown fontSize="small"/> }
                    </Button>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={ this.handleCloseUnits }>
                        {units.map(unit => (
                            <MenuItem key={unit.id} itemID={unit.id} selected={unit.id === currentUnit} onClick={this.handleCloseUnits}>
                                {unit.text}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            }}
            onChange={this.handleChange}
        />
    }
}

MassField.propTypes = {
    ...AnyField.propTypes,
    value: PropTypes.object,
    units: PropTypes.array,
    disableUnitSelect: PropTypes.bool,
};

MassField.defaultProps = {
    units: [],
    disableUnitSelect: false,
};

export default MassField;