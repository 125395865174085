import React from "react";
import * as PropTypes from "prop-types";
import { propTypeDuration } from "../../utils/moment-utils";
import moment from "moment";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";

export default
@withStyles({
    equipmentBreak: {
        backgroundColor: "#ff9e9e",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        textTransform: 'uppercase',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'grey',
        zIndex: 3,
    }
})
@withTranslation()
@connect(state => ({
    calendarCurrentDate: state.calendar.currentDate,
}), {})
class ScheduleSheetEquipmentBreakTime extends React.PureComponent {

    static propTypes = {
        slotHeight: PropTypes.number.isRequired,
        columnWidth: PropTypes.number.isRequired,
        startTime: propTypeDuration,
        endTime: propTypeDuration,
        timeStep: propTypeDuration,
        equipment: PropTypes.object.isRequired,
    }

    state = {
        breaks: [],
    }

    static getDerivedStateFromProps(props) {
        const { calendarCurrentDate, equipment, startTime, endTime, timeStep, slotHeight } = props;
        const { equipmentBreakStartedAt, equipmentBreakEndedAt, workTime, businessUnit } = equipment;

        if (!equipment || !calendarCurrentDate || !timeStep?.asMinutes()) {
            return null;
        }

        const workTimeDay = workTime && workTime[calendarCurrentDate.isoWeekday()];

        const startOfDate = calendarCurrentDate.clone().startOf('day');
        const startDate = startOfDate.clone().add(startTime);
        const endDate = startOfDate.clone().add(endTime);

        const parlourWorkRange = moment.range(startDate, endDate);

        let globalBreakRange;
        if (equipmentBreakStartedAt && equipmentBreakEndedAt) {
            let breakStartDate = moment(equipmentBreakStartedAt).utcOffset(businessUnit.timeZone).utcOffset(calendarCurrentDate.utcOffset(), true);
            let breakEndDate = moment(equipmentBreakEndedAt).utcOffset(businessUnit.timeZone).utcOffset(calendarCurrentDate.utcOffset(), true);
            const tempGlobalBreakRange = moment.range(breakStartDate, breakEndDate);
            if (tempGlobalBreakRange.overlaps(parlourWorkRange)) {
                globalBreakRange = tempGlobalBreakRange.intersect(parlourWorkRange);
            }
        }

        let equipmentWorkRange;
        if (workTimeDay) {
            const start = moment.duration(workTimeDay.start);
            const end = moment.duration(workTimeDay.finish);
            equipmentWorkRange = moment.range(startOfDate.clone().add(start), startOfDate.clone().add(end));
        }

        let breaksRanges = [];
        if (equipmentWorkRange) {
            breaksRanges = parlourWorkRange.subtract(equipmentWorkRange);
        }

        if (globalBreakRange) {
            breaksRanges.push(globalBreakRange);
        }

        // Проверка пересечений, объединение
        if (breaksRanges.length > 1) {
            let isChanged = false;
            do {
                isChanged = false;
                let i = 0;
                while (i < breaksRanges.length - 1) {
                    if (breaksRanges[i].overlaps(breaksRanges[i + 1])) {
                        breaksRanges[i] = breaksRanges[i].add(breaksRanges[i + 1]);
                        breaksRanges.splice(i + 1, 1);
                        isChanged = true;
                    } else {
                        i++;
                    }
                }
            } while (isChanged);
        }

        const breaks = [];

        if (breaksRanges) {
            const k = slotHeight / timeStep.asMinutes();
            breaksRanges.forEach(range => {
                breaks.push({
                    key: `${equipment.id}_${range.start.toISOString()}`,
                    top: moment.duration(range.start.diff(parlourWorkRange.start)).asMinutes() * k,
                    height: moment.duration(range.end.diff(range.start)).asMinutes() * k,
                });
            })
        }

        return { breaks };
    }

    render() {
        const { t, classes, columnWidth } = this.props;
        const { breaks } = this.state;

        if (!breaks.length) {
            return null;
        }

        return breaks.map(item => (
            <div
                key={item.key}
                className={classes.equipmentBreak}
                style={{ top: item.top, height: item.height, width: columnWidth }}
            >{t('Break')}</div>
        ));
    }
}