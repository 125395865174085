import React from 'react';
import { connect } from "react-redux";
import infoActions from "../../components/info/info-actions";
import {
    Button,
    Dialog,
    DialogActions,
    withStyles,
    DialogTitle,
    DialogContent,
    Grid, MenuItem
} from "@material-ui/core";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    emptyOrder,
    getOrder,
    navLinks, processOrderAfterGet,
    emptyOrderGood, emptyStorageGood, completeOrder
} from "../../services/goods";
import Content from "../../components/content/Content";
import ShowField from "../../components/fields/ShowField";
import ActionButton from "../../components/button/ActionButton";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import AnyField from "../../components/fields/AnyField";
import _ from 'lodash';
import DateField from "../../components/fields/DateField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {withRouter} from "react-router";


const columnData = [
    { id: 'good.name', label: 'Наименование товара' },
    { id: 'good.category.name', label: 'Группа товаров' },
    { id: 'purposeText', label: 'Предназначение' },
    { id: 'priceFormat', number: true, label: 'Цена покупки' },
    { id: 'quantityStorage', label: 'Остаток на складе' },
    { id: 'quantity', label: 'Количество товаров в заказе' },
    { id: 'quantityDelivery', label: 'Количество товаров в поставке' },
    { id: 'quantityRecommend', label: 'Рекомендованное количество' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'comment', label: 'Комментарий' },
    { id: 'usernamesRequests', label: 'Пользователь, создавший запрос' },
    { id: 'phonesRequests', label: 'Телефон пользователя, создавшего запрос' },
    { id: 'numsRequests', label: 'Номер запроса' },
];

const autoNumValue = '--Автоматически--';

const styles = theme => ({
    selectField: {
        width: '100%',
    },
    dialogContent: {
        minWidth: 600,
    },
    groupedTitle: {
        display: 'inline-block',
        marginRight: 5,
        color: theme.palette.text.secondary,
    },
    groupedValue: {
        display: 'inline-block',
        marginRight: 10,
    },
});

@withRouter
class ReceiptViewPage extends React.Component
{
    state = {
        order: emptyOrder,

        addGood: {
            ...emptyStorageGood,
            groupedIdx: null
        },
        editAddIdx: null,
        dialogAddOpen: false,
        addedGoods: 0,

        dialogReceiptOpen: false,
    };


    processNum = value => {
        if (value === null) {
            return autoNumValue;
        }

        return value;
    };

    columnDataStorage = [
        { id: 'good.name', label: 'Наименование товара' },
        { id: 'purposeText', label: 'Предназначение' },
        { id: 'good.category.name', label: 'Группа товаров' },
        { id: 'good.description', label: 'Описание товара', minWidth: 600 },
        { id: 'currentMassFormatted', label: 'Количество'},
        { id: 'num', label: 'Номер', processValue: this.processNum },
        { id: 'expiration', dateFormat: 'DD.MM.YYYY', label: 'Срок годности' },

    ];

    componentDidMount() {
        const reId = new RegExp('/goods/receipt/view/([^/]+)$');
        const resultId = reId.exec(this.props.location.pathname);
        if (!resultId || resultId.length !== 2) {
            //Error request
            return;
        }

        if (resultId[1] !== "0") {
            getOrder(resultId[1])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            order: processOrderAfterGet(response.data),
                        })
                    }
                });
        }
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/receipt`);
    };

    handleChange = prop => event => {
        this.setState({
            order: {
                ...this.state.order,
                [prop]: event.target.value,
            }
        })
    };

    handleAdd = () => {
        this.setState({
            addGood: {
                ...emptyStorageGood,
                groupedIdx: this.state.addGood.groupedIdx,
            },
            editAddIdx: null,
            dialogAddOpen: true,
        });
    };

    handleEditAdded = goodStorage => {
        if (!goodStorage.id) {
            this.setState({
                addGood: {
                    ...goodStorage,
                },
                editAddIdx: goodStorage.idx,
                dialogAddOpen: true,
            });
        }
    };

    handleAddChange = prop => event => {
        this.setState({
            addGood: {
                ...this.state.addGood,
                [prop]: event.target.value,
            }
        });
    };

    handleAddDateChange = prop => date => {
        this.setState({
            addGood: {
                ...this.state.addGood,
                [prop]: date,
            }
        });
    };

    handleCloseAddDialog = ok => () => {
        const { addGood, order, editAddIdx } = this.state;

        if (!ok) {
            this.setState({
                dialogAddOpen: false,
            });
            return;
        }

        let grouped = order.goodStorageGroupedOrder;

        let currentMassFormatted = grouped[addGood.groupedIdx].good.mass.val;
        if (grouped[addGood.groupedIdx].good.expendable) {
            currentMassFormatted = addGood.currentMass;
        }
        currentMassFormatted += ' ' + grouped[addGood.groupedIdx].unitText;


        if (editAddIdx !== null) {
            const item = grouped[addGood.groupedIdx].goodStorage[editAddIdx];

            grouped[addGood.groupedIdx].goodStorage[editAddIdx] = {
                ...item,
                ...addGood,
                good: grouped[addGood.groupedIdx].good,
                purpose: grouped[addGood.groupedIdx].purpose,
                purposeText: grouped[addGood.groupedIdx].purposeText,
                currentMassFormatted: currentMassFormatted
            };

        } else {
            grouped[addGood.groupedIdx].goodStorage = [
                ...grouped[addGood.groupedIdx].goodStorage,
                {
                    ...addGood,
                    good: grouped[addGood.groupedIdx].good,
                    purpose: grouped[addGood.groupedIdx].purpose,
                    purposeText: grouped[addGood.groupedIdx].purposeText,
                    currentMassFormatted: currentMassFormatted
                }
            ];
        }

        this.setState({
            order: {
                ...order,
                goodStorageGroupedOrder: grouped,
            },
            dialogAddOpen: false,
            addedGoods: this.state.addedGoods + 1,
        });
    };

    handleReceipt = () => {
        this.setState({
            dialogReceiptOpen: true,
        })
    };
    handleCloseReceiptDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogReceiptOpen: false,
            });
            return;
        }

        const {order} = this.state;

        completeOrder({
            id: order.id,
            comment: order.receiptComment,
            goodStorageGroupedOrder: order.goodStorageGroupedOrder.map(item => ({
                ...item,
                good: item.good.id,
                goodStorageGrouped: item.goodStorageGrouped.map(item => item.id),
                goodStorage: item.goodStorage.map(item => ({...item, good: item.good.id})),
            })),

        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogReceiptOpen: false,
                    });

                    this.props.history.push(`/goods/receipt`);
                    this.props.showInfo('Заказ оприходован');
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleDeleteAdded = goodStorage => {
        if (goodStorage.id) {
            this.props.showMessage({
                message: 'Удаление оприходованных товаров запрещено!',
                title: 'Внимание',
            });
            return;
        }

        this.props.showConfirm({
            message: 'Вы уверены, что хотите удалить товар?',
            title: 'Удаление товара',
            onClose: this.handleCloseDeleteAdded(goodStorage.groupedIdx, goodStorage.idx),
        })
    };

    handleCloseDeleteAdded = (groupedIdx, goodStorageIdx) => ok => {
        if (ok) {
            let grouped = this.state.order.goodStorageGroupedOrder;
            const newGoodStorage = [...grouped[groupedIdx].goodStorage];
            _.remove(newGoodStorage, (value, index) => index === goodStorageIdx);

            grouped[groupedIdx].goodStorage = newGoodStorage;

            this.setState({
                order: {
                    ...this.state.order,
                    goodStorageGroupedOrder: grouped,
                },
                addedGoods: this.state.addedGoods - 1,
            });
        }
    };

    goodStorageRowStyle = goodStorage => {
        if (goodStorage.id) {
            return { backgroundColor: '#e4ffe8' }
        }

        return null;
    };

    render() {
        const { order, addedGoods } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    {this.renderLeftFields()}
                    <br/><br/>

                    <ActionButton onClick={this.handleReceipt} disabled={!addedGoods}>Оприходовать</ActionButton>
                    <ActionButton onClick={this.handleClickBack}>Назад</ActionButton>
                </LeftBar>

                <Content title="Просмотр заказа">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>Товары в заказе:</Grid>
                        <Grid item xs={12}>
                            <DataTableLocal
                                columnData={columnData}
                                data={order.goodStorageGroupedOrder}
                                pagination={false}
                                selector={false}
                            />
                        </Grid>
                        <Grid item xs={12}>Принятые товары:</Grid>
                        <Grid item xs={12}>
                            <DataTableLocal
                                columnData={this.columnDataStorage}
                                data={_.flatten(order.goodStorageGroupedOrder.map(grouped => grouped.goodStorage.map( (item,idx) => ({...item, idx}) )))}
                                pagination={false}
                                selector={false}
                                onClick={this.handleEditAdded}
                                onDelete={this.handleDeleteAdded}
                                rowStyleFunc={this.goodStorageRowStyle}
                            />

                            <ActionButton fullWidth={false} onClick={this.handleAdd}>Добавить товар</ActionButton>
                        </Grid>

                        <Grid item xs={12}>
                            <UploadFilesField
                                id="invoice"
                                label="Скан счет-фактуры"
                                value={order.invoice}
                                onChange={this.handleChange('invoice')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <UploadFilesField
                                id="consignmentNote"
                                label="Товарно-транспортные накладные"
                                value={order.consignmentNote}
                                onChange={this.handleChange('consignmentNote')}
                                multiple
                            />
                        </Grid>
                    </Grid>
                </Content>
                {this.renderAddDialog()}
                {this.renderReceiptDialog()}
            </React.Fragment>
        );
    }

    renderLeftFields() {
        const { order } = this.state;

        return (
            <React.Fragment>
                <ShowField
                    label="Номер заказа"
                    value={order.num}
                />
                <ShowField
                    label="Дата создания заказа"
                    value={order.createdAt}
                    dateFormat="DD.MM.YYYY"
                />
                <ShowField
                    label="Пользователь, создавший заказ"
                    value={order.creator.username}
                />
                <ShowField
                    label="Тел. пользователя, создавшего заказ"
                    value={order.creator.phone}
                />
                <ShowField
                    label="Примерная стоимость заказа"
                    value={order.price.val}
                />
                <ShowField
                    label="Контрагент"
                    value={order.counterpart.username}
                />
                <ShowField
                    label="Комментарий"
                    value={order.comment}
                />
                <ShowField
                    label="Статус"
                    value={order.statusText}
                />
            </React.Fragment>
        );
    }

    renderAddDialog() {
        const { classes } = this.props;
        const { dialogAddOpen, addGood, order, editAddIdx } = this.state;
        const selectedGrouped = addGood.groupedIdx !== null ? order.goodStorageGroupedOrder[addGood.groupedIdx] : emptyOrderGood;

        return (
            <Dialog
                open={dialogAddOpen}
                onClose={this.handleCloseAddDialog(false)}
            >
                <DialogTitle>Добавление товара</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Товар"
                                value={addGood.groupedIdx === null ? '' : addGood.groupedIdx}
                                onChange={this.handleAddChange('groupedIdx')}
                                fullWidth
                                select
                                readOnly={editAddIdx !== null}
                                autoFocus={addGood.groupedIdx === null}
                            >
                                {order.goodStorageGroupedOrder.map((grouped, idx) => <MenuItem key={idx} value={idx}>{grouped.good.name} - {grouped.good.category.name} ({grouped.purposeText})</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Описание товара"
                                value={selectedGrouped.good.description}
                                readOnly
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Масса товара"
                                value={selectedGrouped.good.massFormat}
                                readOnly
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Номер"
                                value={addGood.num}
                                placeholder={autoNumValue}
                                fullWidth
                                onChange={this.handleAddChange('num')}
                                autoFocus={addGood.groupedIdx !== null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateField
                                value={addGood.expiration}
                                onChange={this.handleAddDateChange('expiration')}
                                disablePast
                                label="Срок годности"
                                fullWidth
                            />
                        </Grid>
                        { selectedGrouped.good.expendable &&
                            <React.Fragment>
                                <Grid item xs={6}>
                                    <AnyField
                                        label="Принимаемое количество"
                                        value={addGood.currentMass}
                                        onChange={ this.handleAddChange('currentMass') }
                                        required
                                        error={!addGood.currentMass || addGood.currentMass > selectedGrouped.quantityDelivery}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        label="Всего в поставке"
                                        value={selectedGrouped.quantityDelivery}
                                        readOnly
                                        fullWidth
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseAddDialog(true)} disabled={addGood.groupedIdx === null || ( selectedGrouped.good.expendable && (!addGood.currentMass || addGood.currentMass > selectedGrouped.quantityDelivery) ) }  color="primary">OK</Button>
                    <Button onClick={this.handleCloseAddDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderReceiptDialog() {
        const { dialogReceiptOpen, order } = this.state;
        return (
            <Dialog
                open={dialogReceiptOpen}
                onClose={this.handleCloseReceiptDialog(false)}
            >
                <DialogTitle>Оприходование заказа</DialogTitle>
                <DialogContent>
                    Вы уверены, что хотите оприходовать заказ?
                    <AnyField
                        id="receiptComment"
                        label="Укажите комментарий"
                        value={order.receiptComment}
                        onChange={this.handleChange('receiptComment')}
                        multiline
                        rows={4}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseReceiptDialog(true)}  color="primary">Да</Button>
                    <Button onClick={this.handleCloseReceiptDialog(false)} color="primary">Нет</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(null, {showInfo: infoActions.show, showMessage: messageDialogActions.show, showConfirm: confirmDialogActions.show})(withStyles(styles)(ReceiptViewPage));
