import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getVisitsPaymentReport, navLinks} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem, Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import DataTableLocal from "../../components/data-table/DataTableLocal";
import _ from "lodash";
import XLSX from "xlsx";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    table: {
        marginBottom: 30
    },
});

export default
@withStyles(styles)
class VisitPaymentsReport extends React.Component {
    state = {
        search: {
            date_start: moment(),
            date_end: moment(),
            businessUnitIds: [],
        },
        businessUnits: [],
        columnData: [],
        data: [],
        progress: false,
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: '---Все---'},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleExport = () => {
        const {search, businessUnits} = this.state;
        const { t } = this.props;
        const from = search.date_start;
        const to = search.date_end;

        const bu = search.businessUnitIds.length ? businessUnits.filter(item => search.businessUnitIds.indexOf(item.id) !== -1).map(bu => bu.name).join(', ') : 'All';

        getVisitsPaymentReport(search)
            .then(response => {
                if (response.success) {
                    let data = [];

                    response.data.data.forEach(bu => {
                        data.push([bu.name]);
                        data.push(response.data.columns.map(item => item.label));
                        bu.data.forEach(row => {
                            data.push(
                                response.data.columns.map(item => {
                                    const value = _.get(row, item.id);
                                    if (item.processValue) {
                                        return item.processValue(value, t);
                                    }
                                    return value;
                                })
                            );
                        });
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Visit payment report");
                    XLSX.writeFile(new_workbook, `Visit payment report ${bu} ${from}-${to}.xlsx`);
                }
            });

    }

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleChangeState = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            [prop]: value,
        });
    };

    handleClickFilter = () => {
        this.getTableData();
    };

    getTableData = () => {
        const {search} = this.state;
        return getVisitsPaymentReport(search)
            .then(response => {
                if (response.success) {
                    this.setState({
                        columnData: response.data.columns,
                        data: response.data.data,
                    });
                }
            });
    };

    render() {
        const { classes } = this.props;
        const { search, businessUnits, columnData, data, progress } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={"Показать изменения за интервал"}
                        className={classes.filterControl}
                    />
                    <AnyField
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        label="Салоны"
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>---Все---</span>;
                                }

                                return businessUnits.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                            },
                        }}
                        className={classes.filterControl}
                        fullWidth
                    >
                        {businessUnits.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={search.businessUnitIds.indexOf(item.id) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </AnyField>
                    <ActionButton onClick={this.handleClickFilter}>Отфильтровать</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title="Оплаты визитов">
                    <p>В скобочках реальных доход.</p>
                    {data.map( (parlour, index) => (
                        <React.Fragment key={index}>
                            <div className={classes.table}>
                                <Typography variant="h6" gutterBottom>{ parlour.name }</Typography>
                                <DataTableLocal
                                    columnData={columnData}
                                    data={parlour.data}
                                    selector={false}
                                    pagination={false}
                                    disableSorting
                                />
                            </div>
                        </React.Fragment>
                    ) )}

                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
