import React from 'react';
import {createOrder, getOrders, navLinks, orderStatuses} from "../../services/goods";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem
} from "@material-ui/core";
import {getParlours} from "../../services/organization";
import AnyField from "../../components/fields/AnyField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core/styles";


const columnData = [
    { id: 'num', label: 'Номер заказа' },
    { id: 'createdAt', label: 'Дата создания заказа', dateFormat: 'DD.MM.YYYY' },
    { id: 'creator.username', label: 'Пользователь, создавший заказ' },
    { id: 'creator.phone', label: 'Телефон пользователя, создавшего заказ' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'counterpart.username', label: 'Контрагент' },
    { id: 'statusText', label: 'Статус заказа' },
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

@withStyles(styles)
@withRouter
class ReceiptPage extends React.Component {

    state = {
        dialogFileOpen: false,
        xls: null,
        parlour: null,
        parlours: [],
    };

    componentDidMount() {
        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });
    }

    handleBack = () => {
        this.props.history.push('/goods');
    };

    getTableData = (...params) => {
        return getOrders({status: [orderStatuses.delivery,orderStatuses.received_partly].join(',')},...params)
    };

    handleFile = () => {
        this.setState({
            dialogFileOpen: true,
            xls: null,
            parlour: null,
        });
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleCloseFileDialog = ok => () => {
        const { parlour, xls } = this.state;

        if (!ok) {
            this.setState({
                dialogFileOpen: false,
            });
            return;
        }

        createOrder({
            parlour,
            deliveryList: xls.id
        })
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/goods/requests/view/${response.data.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    render() {
        // const { classes } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleFile}>Загрузить из файла</ActionButton>
                    <ActionButton onClick={this.handleBack}>Назад</ActionButton>

                </LeftBar>
                <Content title="Приемка товаров">
                    Заказы в доставке:
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/goods/receipt/view/${item.id}`}
                        selector={false}
                    />
                </Content>
                { this.renderFileDialog() }
            </React.Fragment>
        );
    }

    renderFileDialog() {
        const { classes } = this.props;
        const { parlour, dialogFileOpen, xls, parlours } = this.state;

        return (
            <Dialog
                open={dialogFileOpen}
                onClose={this.handleCloseFileDialog(false)}
            >
                <DialogTitle>Создание заказа из файла</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UploadFilesField
                                id="xls"
                                label="Файл"
                                value={xls}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={this.handleChange('xls')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Салон"
                                value={parlour}
                                required
                                error={!parlour}
                                onChange={this.handleChange('parlour')}
                                select
                                fullWidth
                            > {parlours.map(parlour => <MenuItem key={parlour.id} value={parlour.id}>{parlour.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseFileDialog(true)} disabled={!parlour || !xls}  color="primary">OK</Button>
                    <Button onClick={this.handleCloseFileDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(ReceiptPage);
