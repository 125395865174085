import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getSettlementHotels, navLinks} from "../../services/moneyFlow";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import DatePeriodField from "../../components/fields/DatePeriodField";
import {getBusinessUnits} from "../../services/organization";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import {withTranslation} from "react-i18next";
import infoActions from "../../components/info/info-actions";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getCurrencies} from "../../services/common";
import _ from "lodash";
import XLSX from "xlsx";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    },
    container: {
        marginTop: 12,
        paddingBottom: 20,
        maxWidth: 900,
        borderBottom: '1px solid #ccc',
    },
    error: {
        color: 'red',
        whiteSpace: 'pre-line',
    }
});

let columnData = [
    {id: 'resellerName', label: 'Reseller'},
    {id: 'totalDuration', label: 'payDoc.amountTime', processValue: (item) => moment.duration(item, 'minutes').asHours()},
    {id: 'costHour', label: 'Cost of hour'},
    {id: 'schemeText', label: 'Money.schemeOfCalculationOfCommission'},
    {id: 'sumVisits', label: 'Amount of visits'},
    {id: 'sumPaymentVisits', label: 'Amount of payment'}
];

export default
@withStyles(styles)
@withTranslation()
@connect(state => ({
    currentParlour: state.common.currentParlour,
}), {
    showError: infoActions.showError,
})

class SettlementHotelsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            search: {
                date_start: moment(),
                date_end: moment(),
                businessUnitIds: [],
            },
            businessUnitId: props.currentParlour && props.currentParlour.id,
            businessUnits: [],
            refreshTable: false,
            currencies: [],
        }
    }

    componentDidMount() {
        const { t } = this.props;

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: '---' + t('All') + '---'},
                            ...response.data
                        ],
                    });
                }
            });

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data.map(currency => ({
                            id: currency.id,
                            label: currency.text
                        })),
                    })
                }
            })
    }

    render() {
        const { classes, t } = this.props;
        const { businessUnits, search, refreshTable, currencies } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t('Show changes by date interval')}
                        className={classes.filterControl}
                    />
                    <AnyField
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        label={t("Money.salons")}
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selected => {
                                if (selected.length === 0) {
                                    return <span className={classes.placeholder}>---{t('All')}---</span>;
                                }

                                return businessUnits.filter(item => selected.indexOf(item.id) > -1 ).map(item => item.name).join(', ');
                            },
                        }}
                        className={classes.filterControl}
                        fullWidth
                    >
                        {businessUnits.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={search.businessUnitIds.indexOf(item.id) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </AnyField>
                    <ActionButton onClick={this.handleClickSelect}>{t('Select')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('SettlementHotels')}>
                    <DataTable
                        columnData={columnData.map(item => {return ['sumVisits', 'sumPaymentVisits'].indexOf(item.id) !== -1 ? {...item, subColumns: currencies}: item })}
                        dataFunc={this.getSettlementHotels}
                        selector={false}
                        refresh={refreshTable}
                        pagination={false}
                        disableSorting
                    />
                </Content>
            </React.Fragment>
        )
    }

    handleExport = () => {
        const { t } = this.props;
        const { search } = this.state;
        const from = search.date_start.format("YYYY-MM-DD");
        const to = search.date_end.format("YYYY-MM-DD");

        this.getSettlementHotels()
            .then(response => {
                if (response.success) {
                    const {currencies} = this.state;
                    let availableCurrencies = {};
                    let values = {...response.data};

                    for (let valueKey in response.data) {
                        for (let itemPropery in response.data[valueKey]) {
                            if (response.data[valueKey][itemPropery] instanceof Object) {
                                for (let itemCurrency in response.data[valueKey][itemPropery]) {
                                    let findCurrency = currencies.find(currency => itemCurrency === currency.id);

                                    if (findCurrency) {
                                        values[valueKey][itemPropery + itemCurrency] = response.data[valueKey][itemPropery][itemCurrency];
                                        availableCurrencies[itemCurrency] = {...findCurrency};
                                    }
                                }
                            }
                        }
                    }

                    let columnDataXLS = [];

                     for (let idColumn in columnData) {
                        if (['sumVisits', 'sumPaymentVisits'].indexOf(columnData[idColumn].id) !== -1) {
                            for (let currency in availableCurrencies) {
                                columnDataXLS.push({id: columnData[idColumn].id + currency, label: columnData[idColumn].label, labelAfter: availableCurrencies[currency].label});
                            }
                        } else {
                            columnDataXLS.push({...columnData[idColumn]});
                        }
                    }

                    const data = [
                        //First row - headers
                        columnDataXLS.map(item => {
                            let headerText = t(item.label);

                            headerText += item.labelAfter ? (' ' + t(item.labelAfter)) : ''

                            return headerText;
                        })
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataXLS.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Settlement Hotels");
                    XLSX.writeFile(new_workbook, `Settlement Hotels ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };


    getSettlementHotels = (...params) => {
        const { search } = this.state;

        return getSettlementHotels({
            ...search,
            date_start: search.date_start.clone().startOf('day').toISOString(),
            date_end: search.date_end.clone().endOf('day').toISOString(),
        }, ...params)
            .then(response => {
                return response;
            });
    }

    handleClickSelect = () => {
        this.setState({
            refreshTable: !this.state.refreshTable
        });
    }
}