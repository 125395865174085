import { handleActions } from 'redux-actions';
import goodsActions from './goods-actions';

export default handleActions({
    [goodsActions.setMovingGoods]: (state, action) => {
        return {...state, movingStorageGoods: action.payload };
    },

},{
    movingStorageGoods: [],
})