import baseRequest from "./baseReuqest";

export const navLinks = [
    { path: "/personal-account", text: "Profile" },
    { path: "/personal-account/personal-schedule", text: "My schedule", role: 'ROLE_UI_TAB_ACCOUNT_SCHEDULE'},
    { path: "/personal-account/referral-program", text: "Referral program", role: 'ROLE_UI_TAB_ACCOUNT_REFERRAL_PARTNER_INFO'},
    { path: "/personal-account/salary", text: "Salary", role: 'ROLE_UI_TAB_ACCOUNT_SALARY'},
];

export function referralPartnerInfo() {
    return baseRequest('/api/organization/referral-partner/info');
}