import React from 'react';
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    rub: {
        fontFamily: 'Verdana, serif',
    },
});

class SymbolRub extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.rub}>₽</div>
        )
    }
}

export default withStyles(styles)(SymbolRub);