import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

export default

@withStyles(theme => ({
    activityNotApproved: {
        width: 15,
        height: 15,
        borderRadius: 7,
        backgroundColor: '#ff0000',
        color: '#fff',
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: 13,
    },
}))

class NotApproved extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.activityNotApproved}>?</div>
        )
    }
}
