export function createChangeHandle(context, stateField = null) {
    let handler;
    if (stateField) {
        handler =  function (event) {
            this.setState({
                [stateField]: {
                    ...this.state[stateField],
                    [event.target.name]: event.target.value,
                }
            });
        }
    } else {
        handler = function(event) {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    }

    return handler.bind(context);
}

export function loadToState(componentContext, requestPromise, prop, dataMapper = null) {
    requestPromise.then(response => {
        if (response.success) {
            componentContext.setState({
                [prop]: dataMapper ? dataMapper(response.data) : response.data,
            })
        }
    })
}

export function updateStateArrayElement(
    context,
    propertyName,
    i,
    value,
    subPropertyName = null,
    callback = (f) => f
) {
    let newValue = [...context.state[propertyName]]
    if (subPropertyName) {
        newValue[i][subPropertyName] = value
    } else {
        newValue[i] = value
    }

    context.setState({
        [propertyName]: newValue
    }, callback)
}


export function getIdByUrl(pathname){
    const path = pathname;
    const reId = new RegExp('[^/]+$');

    return reId.exec(path)[0] ?? null;
}