import React from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {formatMoney} from "../../../../../services/common";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
    valueError: {
        color: 'red',
    },
});

const MoneyList = ({ title, value, children, errors }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    if (value?.length) {
        return (
            <div className={!!children ? classes.flex : null}>
                {!!title && `${t(title)}: `}
                {value.map((money, idx) => (
                     <span
                         key={idx}
                         className={errors?.[money.cur] ? classes.valueError : null}
                         title={errors?.[money.cur] ? `Отличие ${errors[money.cur]}` : null}
                     >{formatMoney(money)} </span>
                ))}
                {children}
            </div>
        );
    }

    return null;
};

MoneyList.propTypes = {
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({ val: PropTypes.number, cur: PropTypes.string })),
    children: PropTypes.node,
    errors: PropTypes.objectOf(PropTypes.number),
};

export default MoneyList;