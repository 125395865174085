import React from 'react';
import * as PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ActionButton from "../../components/button/ActionButton";
import Grid from "@material-ui/core/Grid";
import {changeValidity, formatGroupedRange, groupPayDocsList} from "../../services/payDocs";
import AnyField from "../../components/fields/AnyField";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import _ from 'lodash';
import moment from 'moment';
import DateField2 from "../../components/fields/DateField2";

export default
@connect(null, {showMessage: messageDialogActions.show})
@withTranslation()
class PayDocChangeValidityDialog extends React.PureComponent
{
    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        payDocs: PropTypes.array,
    };

    static defaultProps = {
        open: false,
    };

    state = {
        validity: null,
        comment: null,
        ranges: [],
    };

    handleCloseCancel = () => {
        if (this.props.onClose) {
            this.props.onClose(false);
        }
    }

    handleCloseSuccess = () => {
        const { comment, validity } = this.state;
        const { payDocs } = this.props;

        changeValidity({
            payDocIds: payDocs.map(payDoc => payDoc.id),
            validity,
            comment,
        }).then(response => {
            if (response.success) {
                if (this.props.onClose) {
                    this.props.onClose(true);
                }
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        })
    };

    updatePayDocsData() {
        this.setState({
            ranges: groupPayDocsList(this.props.payDocs),
            validity: this.props.payDocs && this.props.payDocs.length ? moment(this.props.payDocs[0].validity) : null,
        });
    }

    componentDidMount() {
        this.updatePayDocsData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.payDocs, prevProps.payDocs)) {
           this.updatePayDocsData();
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    render() {
        const { open, t } = this.props;
        const { comment, validity, ranges } = this.state;

        if (!open) {
            return null;
        }

        return <Dialog open={open} onClose={this.handleCloseCancel} maxWidth="md">
            <DialogTitle>{t('Change validity payment documents')}</DialogTitle>
            <DialogContent style={{minWidth: 600}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AnyField
                            label={t('Selected PD')}
                            multiline
                            rows={3}
                            readOnly
                            fullWidth
                            value={ranges.map(range => formatGroupedRange(range)).join(', ')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateField2
                            label={t("payDoc.validity")}
                            value={validity}
                            fullWidth
                            name="validity"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AnyField
                            label={t("Comment")}
                            value={comment}
                            fullWidth
                            name="comment"
                            onChange={this.handleChange}
                            multiline
                            rows={2}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <ActionButton fullWidth={false} onClick={this.handleCloseSuccess} disabled={!validity}>OK</ActionButton>
                <ActionButton fullWidth={false} onClick={this.handleCloseCancel}>{t('Cancel')}</ActionButton>
            </DialogActions>
        </Dialog>
    }
}
