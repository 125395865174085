import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import infoActions from "../info/info-actions";
import messageDialogActions from "../dialogs/messageDialog-acions";
import confirmDialogActions from "../dialogs/confirmDialog-acions";
import * as PropTypes from "prop-types";
import {getFilterPresetsByType, removeFilterPreset, saveFilterPreset} from "../../services/common";
import SelectFromItemsField from "../fields/SelectFromItemsField";
import {withTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import AnyField from "../fields/AnyField";
import moment from "moment";
import {hasRole} from "../../services/user";

const emptyPreset = {
    id: null,
    name: null,
    data: {}
}

const dateFormat = 'YYYY-MM-DD';

export default
@withRouter
@withTranslation()
@connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
class FilterPreset extends React.PureComponent {

    static propTypes = {
        type: PropTypes.string,
        onApply: PropTypes.func,
        filterData: PropTypes.object
    };

    state = {
        presets: [],
        currentPreset: {},
        editModalOpen: false
    };

    getPresets = () => {
        getFilterPresetsByType(this.props.type).then(response => {
            if (response.success) {
                this.setState({
                    presets: response.data
                });
            }
        });
    }

    componentDidMount() {
        this.getPresets();
    }

    handleChangePreset = (event) => {
        let preset;
        if (event.target.value) {
            preset = this.state.presets.find(preset => preset.id === event.target.value);
            this.props.onApply(preset.data);
        } else {
            preset = {...emptyPreset};
        }
        this.setState({
            currentPreset: preset
        })
    }

    editPreset = () => {
        this.setState({
            editModalOpen: true
        });
    }

    close = () => {
        this.setState({
            editModalOpen: false
        });
    }

    remove = () => {
        removeFilterPreset(this.state.currentPreset.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        currentPreset: {...emptyPreset},
                        editModalOpen: false,
                    }, this.getPresets);
                    this.props.showInfo(this.props.t('Preset deleted'));
                }
            });
    }

    save = () => {
        let filterData = {...this.props.filterData};

        for (let searchProp in filterData) {
            if (!filterData.hasOwnProperty(searchProp)) {
                continue;
            }

            if (moment.isMoment(filterData[searchProp])) filterData[searchProp] = filterData[searchProp].format(dateFormat);
        }

        saveFilterPreset({
            ...this.state.currentPreset,
            type: this.props.type,
            data: filterData
        }).then(response => {
            if (response.success) {
                this.setState({
                    currentPreset: response.data,
                    editModalOpen: false,
                }, this.getPresets);
                this.props.showInfo(this.props.t('Preset saved'));
            } else {
                if (response.error && response.error.errors && response.error.errors.length) {
                    let errors = response.error.errors.map((error, idx) => <span key={idx}>{error.property} - {error.message}<br/></span>);
                    this.props.showMessage(errors);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            }
        });
    }

    handleChange = (event) => {
        this.setState({
            currentPreset: {
                ...this.state.currentPreset,
                [event.target.name]: event.target.value
            }
        });
    }

    render() {
        const { presets, currentPreset, editModalOpen } = this.state;
        const { t } = this.props;

        return (
            <>
                <SelectFromItemsField
                    items={presets}
                    nullable
                    nullableText={t('Create new preset')}
                    valueField="id"
                    textField="name"
                    value={currentPreset.id}
                    fullWidth
                    label={t('Filter presets')}
                    onChange={this.handleChangePreset}
                    InputProps={hasRole('ROLE_COMMON_FILTERPRESET_SAVE') && {
                        endAdornment: <InputAdornment position="end" style={{position: "absolute", right: 30}}>
                            <IconButton
                                aria-label="Edit"
                                onClick={this.editPreset}
                            >
                                {currentPreset.id ? <EditIcon/> : <AddIcon/> }
                            </IconButton>
                        </InputAdornment>
                    }}
                />
                <Dialog
                    open={editModalOpen}
                    onClose={this.close}
                    aria-labelledby="filter-dialog-title"
                    maxWidth={"sm"}
                    fullWidth
                >
                    <DialogTitle id="filter-dialog-title">{currentPreset.id ? t('Edit filter preset') : t('Create filter preset')}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    value={currentPreset.name}
                                    label={t('Name')}
                                    onChange={this.handleChange}
                                    required
                                    name="name"
                                    autoFocus
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.remove} disabled={!currentPreset.id} color="secondary">{t('Remove')}</Button>
                        <Button onClick={this.save} color="primary" disabled={!currentPreset.name}>{t('Save')}</Button>
                        <Button onClick={this.close} color="primary">{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}