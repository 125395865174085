import { handleActions } from 'redux-actions';
import commonActions from './common-actions';
import {saveCurrentParlour} from "./services/localStorage";

export default handleActions({
    [commonActions.setCurrentParlour]: (state, action) => {
        saveCurrentParlour(action.payload);

        return {...state, currentParlour: action.payload };
    }

}, {
    currentParlour: null,
})