import React from 'react';
import {getRequests, getRequestTypes, navLinks} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import {withTranslation} from "react-i18next";
import CheckboxField from "../../components/fields/CheckboxField";
import ActionButton from "../../components/button/ActionButton";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {createChangeHandle} from "../../utils/helpers";

const columnData = [
    { id: 'num', label: 'Request for making / moving payment documents' },
    { id: 'comment', label: 'Comment' },
    { id: 'status', label: 'Status' },
    { id: 'typeWithInfo', label: 'Request type' },
    { id: 'payDocs', label: 'Payment documents', disableSorting: true },
];

@withTranslation()
class RequestsPage extends React.Component {

    state = {
        search: {
            search: '',
            showNotCompleted: true,
            type: null,
        },
        refreshTable: false,
        types: [],
    };

    componentDidMount() {
        getRequestTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data,
                    })
                }
            })
    }

    handleChange = createChangeHandle(this, 'search')

    handleClickSearch = () => {
        this.setState({
            refreshTable: !this.state.refreshTable,
        });
    };

    getTableData = (...params) => {
        const { search } = this.state;
        return getRequests(search, ...params);
    };

    render() {
        const { t } = this.props;
        const { search, refreshTable, types } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        name="search"
                        onChange={this.handleChange}
                        onClick={this.handleClickSearch}
                        label={t("Search")}
                    />
                    <CheckboxField
                        label={t("Only not completed")}
                        value={search.showNotCompleted}
                        name="showNotCompleted"
                        onChange={this.handleChange}
                        margin="none"
                    />
                    <SelectFromItemsField
                        items={types}
                        textField="text"
                        nullable
                        nullableText={t('All')}
                        value={search.type}
                        name="type"
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <br />
                    <ActionButton onClick={this.handleClickSearch}>{t("Filter")}</ActionButton>
                    {/*<ActionButton>{t("Export to Excel")}</ActionButton>*/}
                </LeftBar>
                <Content title={t("Requests")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/pay-docs/requests/view/${item.id}`}
                        order="desc"
                        refresh={refreshTable}
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default RequestsPage;