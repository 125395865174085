import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import SalesListPage from "./SalesListPage";
import AddCashBoxCorrectionPage from "./AddCashBoxCorrectionPage";
import AddEmployeeCashBoxCorrectionPage from "./AddEmployeeCashBoxCorrectionPage";
import SaleViewPage from "./SaleViewPage";
import AddCheckingAccountCorrectionPage from "./AddCheckingAccountCorrectionPage";

export default
class ToolsPage extends React.Component {
    render() {
        return <Switch>
            <Route exact path="/tools"            render={() => (<Redirect to="/tools/sales"/>)}/>
            <Route exact path="/tools/sales"      component={SalesListPage} />
            <Route       path="/tools/sales/"     component={SaleViewPage} />
            <Route exact path="/tools/cash-box-correction"      component={AddCashBoxCorrectionPage} />
            <Route exact path="/tools/employee-cash-box-correction"      component={AddEmployeeCashBoxCorrectionPage} />
            <Route exact path="/tools/checking-account-correction"      component={AddCheckingAccountCorrectionPage} />
        </Switch>
    }
}