import React from "react";
import {getBusinessUnitByRole} from "../../services/user";
import {getEmployees} from "../../services/organization";
import {withRouter} from "react-router";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/goods";
import moment from "moment";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import SearchField from "../../components/fields/SearchField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import ActionButton from "../../components/button/ActionButton";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getGoodStorageInvoices} from "../../services/goods";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

const columnData = [
    { id: 'outerNum', label: 'Num', },
    { id: 'num', label: 'Inner num', },
    { id: 'statusText', label: 'Status', },
    { id: 'businessUnitName', label: 'Parlour', },
    { id: 'contractorName', label: 'Contractor', },
    { id: 'totalFormatted', label: 'Total', },
    { id: 'applyAt', label: 'Apply at', dateFormat: "DD.MM.YYYY"},
    { id: 'creatorName', label: 'Creator', },
    { id: 'createdAt', label: 'Created at', dateFormat: "DD.MM.YYYY HH:mm"},
    { id: 'comment', label: 'Comment', },
];


@withRouter
@withStyles(styles)
@withTranslation()
class InvoiceListPage extends React.PureComponent
{
    constructor(props) {
        super(props);

        const querySearch = getFilterFromQuery();

        let search = null;
        let dateStart = null;
        let dateEnd = null;
        let businessUnitIds = [];
        let employeeIds = [];

        if (querySearch) {
            if (querySearch.search) {
                search = moment(querySearch.search);
            }
            if (querySearch.dateStart) {
                dateStart = moment(querySearch.dateStart);
            }
            if (querySearch.dateEnd) {
                dateEnd = moment(querySearch.dateEnd);
            }
            if (querySearch.businessUnitIds && querySearch.businessUnitIds.length) {
                businessUnitIds = querySearch.businessUnitIds;
            }
            if (querySearch.employeeIds && querySearch.employeeIds.length) {
                employeeIds = querySearch.employeeIds;
            }

        }

        this.state = {
            search: {
                search,
                dateStart,
                dateEnd,
                businessUnitIds,
                employeeIds,
            },
            businessUnits: [],
            employees: [],
            refreshTable: false
        };
    }



    componentDidMount() {
        getBusinessUnitByRole('ROLE_GOOD_GOODSTORAGE_INVOICE_GET')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    })
                }
            });
    }

    handleAdd = () => {
        this.props.history.push('/goods/invoice/view/0');
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    }

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleClickFilter = () => {
        pushFilterToQuery(processMomentFieldInObject(this.state.search, [
            'search',
            'dateStart',
            'dateEnd',
            'businessUnitIds',
            'employeeIds',
        ]));
        this.setState(state => ({
            refreshTable: !state.refreshTable,
        }));
    };

    getTableData = (...params) => {
        const { search } = this.state;
        return getGoodStorageInvoices({...search}, ...params);
    }

    render() {
        const {
            search,
            businessUnits,
            employees,
            refreshTable,
        } = this.state;

        const { t, classes } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label={t('Search')}
                        className={classes.filterControl}
                    />
                    <DatePeriodField2
                        valueFrom={search.dateStart}
                        valueTo={search.dateEnd}
                        onChangeFrom={this.handleChangeSearch('dateStart')}
                        onChangeTo={this.handleChangeSearch('dateEnd')}
                        className={classes.filterControl}
                        label={t('Date interval')}
                    />
                    <MultiSelectFromItemsField
                        label={t('Business unit')}
                        value={search.businessUnitIds}
                        onChange={this.handleChangeSearch('businessUnitIds')}
                        fullWidth
                        className={classes.filterControl}
                        items={businessUnits}
                    />
                    <MultiSelectFromItemsField
                        label={t('Employee')}
                        value={search.employeeIds}
                        onChange={this.handleChangeSearch('employeeIds')}
                        fullWidth
                        className={classes.filterControl}
                        items={employees}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleAdd}>{t('Create')}</ActionButton>
                </LeftBar>
                <Content title={t('Good invoices')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        linkTemplate={(row) => `/goods/invoice/view/${row.id}`}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default InvoiceListPage;