import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {copyCourses, getCourses, navLinks} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";

const columnData = [
    { id: 'date', label: 'Дата', dateFormat: 'DD.MM.YYYY' },
    { id: 'currencyFrom', label: 'Валюта из' },
    { id: 'currencyTo', label: 'Валюта в' },
    { id: 'value', label: 'Значение' },
    { id: 'businessUnitName', label: 'Бизнес юнит' },
];

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@withStyles(styles)
@connect(null,{
    showMessage: messageDialogActions.show,
})
class CoursesListPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: moment(),
            date_end: moment(),
            businessUnitId: 0
        },
        refreshTable: false,
        businessUnits: [{ id: 0, name: '---Все---'}],
        progress: false,
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: '---Все---'},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getCourses(search, ...params);
    };

    copyCourses = () => {
        copyCourses().then((response) => {
            if (response.success) {
                this.props.showMessage('Курсы скопированы');
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        })
    }

    render() {
        const { classes } = this.props;
        const { refreshTable, search, businessUnits, progress } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={"Показать курсы за интервал"}
                        className={classes.filterControl}
                    />
                    <AnyField
                        id="parlour"
                        label="Бизнес юнит"
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <ActionButton onClick={this.handleClickFilter}>Отфильтровать</ActionButton>
                    <ActionButton onClick={this.copyCourses}>Скопировать последние курсы</ActionButton>
                </LeftBar>
                <Content title="Валютные курсы">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                    />
                </Content>
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }
}
