import React from 'react';
import {withTranslation} from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {getDepartments, getMasters, getParlours, getRegions} from "../../services/organization";
import {getBusinessRoles, getUsers, hasRole} from "../../services/user";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {guestsSearch, navLinks} from "../../services/callCenter";
import Content from "../../components/content/Content";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem} from "@material-ui/core";
import {
    discountTypeCustom,
    getDiscountActiveTypes,
    getDiscountTypes,
    getDiscounts,
    getVisitStatuses,
    getVisitStatusText,
} from "../../services/calendar";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {getPaymentTypes, getPersonalDiscountNames, massCreateAndSetPersonalDiscount} from "../../services/sale";
import {getPayDocStatuses, getPayDocTypes} from "../../services/payDocs";
import {getFacilities} from "../../services/facilities";
import NumberRangeField from "../../components/fields/NumberRangeField";
import {getGoods} from "../../services/goods";
import ActionButton from "../../components/button/ActionButton";
import DataTable from "../../components/data-table/DataTable";
import {getFalseResult} from "../../services/common";
import FilterPreset from "../../components/content/FilterPreset";
import _ from "lodash";
import XLSX from "xlsx";
import DynamicDateField, {emptyDynamicDateField} from "../../components/fields/DynamicDateField";
import {getGenders, getLoyalties} from "../../services/guests";
import {getCrnExecutorsWithNames, getCrnExecutorsWithProperties} from "../../services/crn";
import CrnSegment from "../../components/crn/Segment";
import AnyField from "../../components/fields/AnyField";
import NumberFormatDecimal from "../../components/fields/NumberFormatDecimal";
import DateField from "../../components/fields/DateField";
import NumberFormatCount from "../../components/fields/NumberFormatCount";
import CheckboxField from "../../components/fields/CheckboxField";
import moment from "moment";
import {emptyDiscount} from "../../entities/discount";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import {visitType} from "../../entities/enums/visitType";


const styles = theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    discountContainer: {
        paddingBottom: 15,
        marginBottom: 15,
    },
});

const columnData = [
    { id: 'phone', label: 'Guest phone' },
    { id: 'name', label: 'Name' },
    { id: 'gender', label: 'Gender' },
    { id: 'specialStatus', label: 'Status' },
    { id: 'birthDate', label: 'Date of Birth', dateFormat: "DD.MM.YYYY" },
    { id: 'freeTimeBalance', label: 'Free time balance' },
    { id: 'unfreeTimeBalance', label: 'Unfree time balance' },
    { id: 'freeMoneyBalance', label: 'Free money balance' },
    { id: 'unfreeMoneyBalance', label: 'Unfree money balance' },
    { id: 'lastVisit', label: 'Last visit date', dateFormat: "DD.MM.YYYY" },
    { id: 'visitsCount', label: 'Visits count' },
    { id: 'nextVisit', label: 'Date of next visit', dateFormat: "DD.MM.YYYY" }
];

const columnDataForExelExport = [
    { id: 'phone', label: 'Guest phone' },
    { id: 'name', label: 'Name' },
    { id: 'gender', label: 'Gender' },
    { id: 'specialStatus', label: 'Status' },
    { id: 'birthDate', label: 'Date of Birth', dateFormat: "DD.MM.YYYY" },
    { id: 'freeTimeBalance', label: 'Free time balance' },
    { id: 'unfreeTimeBalance', label: 'Unfree time balance' },
    { id: 'freeMoneyBalance', label: 'Free money balance' },
    { id: 'unfreeMoneyBalance', label: 'Unfree money balance' },
    { id: 'lastVisit', label: 'Last visit date', dateFormat: "DD.MM.YYYY" },
    { id: 'visitsCount', label: 'Visits count' },
    { id: 'nextVisit', label: 'Date of next visit', dateFormat: "DD.MM.YYYY" },
    { id: 'regionNameVisit', label: 'Region name visit' },
    { id: 'regionNameRefill', label: 'Region name refill' },
    { id: 'lastDateVisitOrPaydoc', label: 'Last date visit or refill' },
    { id: 'id', label: 'Guest id'}
];

const pamelNames = {
    visits: 'visits',
    payDocs: 'payDocs',
    goodStorageSale: 'goodStorageSale',
    guests: 'guests'
};

const emptySearch = {
    visitStartInterval: {...emptyDynamicDateField},
    visitPriceFrom: null,
    visitPriceTo: null,
    visitPriceTotalFrom: null,
    visitPriceTotalTo: null,
    visitCountFrom: null,
    visitCountTo: null,
    visitDiscountIds: [],
    visitRequested: null,
    visitGratuityPriceFrom: null,
    visitGratuityPriceTo: null,
    visitGratuityTotalFrom: null,
    visitGratuityTotalTo: null,
    visitPaymentTypes: [],
    visitPaymentTypePayDocTypes: [],
    visitCreatorIds: [],
    visitCreatorRoles: [],
    visitCreatorDepartmentIds: [],
    visitMasterIds: [],
    visitFacilityIds: [],
    visitParlourIds: [],
    visitParlourRegionIds: [],
    //visitStatuses: null,
    visitMultiCountFrom: null,
    visitMultiCountTo: null,
    visitExcludeStart: null,
    visitExcludeEnd: null,
    visitType: null,

    payDocRealisationInterval: {...emptyDynamicDateField},
    payDocRefillMoneyAmountFrom: null,
    payDocRefillMoneyAmountTo: null,
    payDocRefillMoneyAmountTotalFrom: null,
    payDocRefillMoneyAmountTotalTo: null,
    payDocRefillTimeAmountFrom: null,
    payDocRefillTimeAmountTo: null,
    payDocRefillTimeAmountTotalFrom: null,
    payDocRefillTimeAmountTotalTo: null,
    payDocRefillSumAmountFrom: null,
    payDocRefillSumAmountTo: null,
    payDocRefillSumAmountTotalFrom: null,
    payDocRefillSumAmountTotalTo: null,
    payDocPaymentInterval: {...emptyDynamicDateField},
    payDocPaymentMoneyAmountFrom: null,
    payDocPaymentMoneyAmountTo: null,
    payDocPaymentMoneyAmountTotalFrom: null,
    payDocPaymentMoneyAmountTotalTo: null,
    payDocPaymentTimeAmountFrom: null,
    payDocPaymentTimeAmountTo: null,
    payDocPaymentTimeAmountTotalFrom: null,
    payDocPaymentTimeAmountTotalTo: null,
    payDocPaymentSumAmountFrom: null,
    payDocPaymentSumAmountTo: null,
    payDocPaymentSumAmountTotalFrom: null,
    payDocPaymentSumAmountTotalTo: null,
    payDocBalanceMoneyFrom: null,
    payDocBalanceMoneyTo: null,
    payDocBalanceTimeFrom: null,
    payDocBalanceTimeTo: null,
    payDocTypeIds: [],
    payDocStatuses: [],
    payDocRegions: [],
    payDocGuestChangingCountFrom: null,
    payDocGuestChangingCountTo: null,

    goodStorageSaleInterval: {...emptyDynamicDateField},
    goodStorageSaleOneTotalFrom: null,
    goodStorageSaleOneTotalTo: null,
    goodStorageSaleTotalFrom: null,
    goodStorageSaleTotalTo: null,
    goodStorageSaleBusinessUnitIds: [],
    goodStorageSaleBusinessUnitRegionIds: [],
    goodStorageSaleGoodIds: [],

    guestBirthDay: {
        type: null,
        dateStart: null,
        dateEnd: null
    },
    guestGender: null,
    guestAgreementCall: null,
    guestAgreementSms: null,
    guestAgreementPolicy: null,
    guestSpecialStatus: null,
    guestLoyalties: [],
    guestClubMember: null,
    guestIsCold: null,
    guestEmail: null,
    guestSkype: null,
    guestFacebook: null,
    guestInstagram: null,
    guestOdnoklassniki: null,
    guestVkontakte: null,
    guestFavoriteParlourIds: [],
    guestFavoriteMasterIds: [],
    guestNotFavoriteMasterIds: [],
    guestConvenientParlourHomeIds: [],
    guestConvenientParlourWorkIds: [],
    guestFavoriteProcedureIds: [],
    guestDiscount: null,
    guestDiscountNames: [],
    guestDiscountValueFrom: null,
    guestDiscountValueTo: null,
    guestDiscountUsingCountFrom: null,
    guestDiscountUsingCountTo: null,
    guestDiscountActiveTypes: null,
    guestDiscountMaxLimitFrom: null,
    guestDiscountMaxLimitTo: null,
    guestDiscountExpiredAt: {
        type: null,
        dateStart: null,
        dateEnd: null
    },
    guestRegions: [],
}

export default
@withTranslation()
@withStyles(styles)
@connect(null,{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
class GuestSearchPage extends React.PureComponent {

    state = {
        search: { ...emptySearch },

        refreshTable: false,
        expanded: null,
        visitFiltersActiveCount: 0,
        payDocFiltersActiveCount: 0,
        goodStorageSaleFiltersActiveCount: 0,
        guestFiltersActiveCount: 0,

        guests: [],

        visitDiscounts: [],
        visitTypes: visitType,
        paymentTypes: [],
        payDocTypes: [],
        creators: [],
        roles: [],
        departments: [],
        masters: [],
        facilities: [],
        parlours: [],
        regions: [],
        //visitStatuses: visitStatusesArray
        visitStatuses: [],
        payDocStatuses: [],
        goods: [],
        loyalties: [],
        genders: [],
        discountActiveTypes: [],
        discountTypes: [],
        personalDiscountNames: [],
        crnExecutors: {
            executorsWithNames: {},
            executorsWithProperties: {},
        },
        crnSegmentFormVisible: false,

        selectedGuests: [],

        personalDiscount: emptyDiscount,
        personalDiscountNotifyGuestsBySms: false,
        isPersonalDiscountDialogOpen: false,
        isPersonalDiscountDialogBlock: false,

        showVisitStatus: false
    }

    visitStatusesArray = [];

    boolValues = [
        { text: this.props.t('Yes'), value: '1' },
        { text: this.props.t('No'), value: '0' },
    ];

    componentDidMount() {
        let visitStatuses = getVisitStatuses();
        for (let statusKey in visitStatuses ) {
            this.visitStatusesArray.push({
                id: visitStatuses[statusKey],
                text: this.props.t(getVisitStatusText(visitStatuses[statusKey]))
            });
        }

        getMasters(null)
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [
                            { id: 0, name: this.props.t('---All---')},
                            ...response.data
                        ],
                    });
                }
            });

        getDiscounts({active: 'active,not_active', forSalableEntity: 'Visit'})
            .then(response => {
                if (response.success) {
                    this.setState({
                        visitDiscounts: response.data,
                    })
                }
            });

        getPaymentTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        paymentTypes: response.data,
                    })
                }
            });

        getPayDocTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDocTypes: response.data,
                    })
                }
            });

        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        creators: response.data,
                    })
                }
            });

        getBusinessRoles()
            .then(response => {
                if (response.success) {
                    this.setState({
                        roles: response.data,
                    })
                }
            });

        getDepartments()
            .then(response => {
                if (response.success) {
                    this.setState({
                        departments: response.data,
                    })
                }
            });

        getMasters()
            .then(response => {
                if (response.success) {
                    this.setState({
                        masters: response.data,
                    })
                }
            });

        getFacilities(null, null, -1)
            .then(response => {
                if (response.success) {
                    this.setState({
                        facilities: response.data,
                    })
                }
            });

        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });

        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    })
                }
            });

        getPayDocStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        payDocStatuses: response.data,
                    })
                }
            });

        getGoods({'purpose': 'for_sale'})
            .then(response => {
                if (response.success) {
                    this.setState({
                        goods: response.data,
                    })
                }
            });

        getLoyalties()
            .then(response => {
                if (response.success) {
                    this.setState({
                        loyalties: response.data,
                    })
                }
            });

        getGenders()
            .then(response => {
                if (response.success) {
                    this.setState({
                        genders: response.data,
                    })
                }
            });

        getDiscountActiveTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        discountActiveTypes: response.data,
                    })
                }
            });

        getDiscountTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        discountTypes: response.data,
                    })
                }
            });

        getPersonalDiscountNames()
            .then(response => {
                if (response.success) {
                    this.setState({
                        personalDiscountNames: response.data,
                    })
                }
            });

        getCrnExecutorsWithNames()
            .then(response => {
                if (response.success) {
                    const { crnExecutors } = this.state;

                    this.setState({
                        crnExecutors: {
                            ...crnExecutors,
                            executorsWithNames: response.data
                        }
                    })
                }
            })

        getCrnExecutorsWithProperties()
            .then(response => {
                if (response.success) {
                    const { crnExecutors } = this.state;

                    this.setState({
                        crnExecutors: {
                            ...crnExecutors,
                            executorsWithProperties: response.data
                        }
                    })
                }
            })

    }

    clearFilter = () => {
        this.setState({
            search: {...emptySearch}
        }, this.recalculateActiveFilters);
    }

    handleChangeSearch = event => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: event.target.value
            }
        }, this.recalculateActiveFilters);
    };

    handleChangeDate = event => {
        this.setState({
            search: {
                ...this.state.search,
                [event.target.name]: moment(event.target.value).format("YYYY-MM-DD")
            }
        }, this.recalculateActiveFilters);
    };

    handleChangePanel = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : null,
        });
    }

    recalculateActiveFilters = () => {
        let visitFiltersActiveCount = 0;
        let payDocFiltersActiveCount = 0;
        let goodStorageSaleFiltersActiveCount = 0;
        let guestFiltersActiveCount = 0;

        for (let searchProp in this.state.search) {
            if( !this.state.search.hasOwnProperty( searchProp ) ) {
                continue;
            }

            if ( this.state.search[searchProp] === null || this.state.search[searchProp] === '' ||
                (Array.isArray(this.state.search[searchProp]) && this.state.search[searchProp].length === 0) ||
                ( this.state.search[searchProp] && this.state.search[searchProp].type  !== undefined && !this.state.search[searchProp].type )
            ) {
                continue;
            }

            if (searchProp.startsWith('visit')) {
                visitFiltersActiveCount++;
            }

            if (searchProp.startsWith('payDoc')) {
                payDocFiltersActiveCount++;
            }

            if (searchProp.startsWith('goodStorageSale')) {
                goodStorageSaleFiltersActiveCount++;
            }

            if (searchProp.startsWith('guest')) {
                guestFiltersActiveCount++;
            }
        }

        const showVisitStatus = this?.state?.search?.visitStatuses?.indexOf('closed') !== -1;

        this.setState({
            search: {
                ...this.state.search,
                visitType: !showVisitStatus ? null : this?.state?.search?.visitType
            },
            visitFiltersActiveCount: visitFiltersActiveCount,
            payDocFiltersActiveCount: payDocFiltersActiveCount,
            goodStorageSaleFiltersActiveCount: goodStorageSaleFiltersActiveCount,
            guestFiltersActiveCount: guestFiltersActiveCount,
            showVisitStatus: showVisitStatus
        });
    };

    getGuests = (...params) => {
        if (!this.state.visitFiltersActiveCount && !this.state.payDocFiltersActiveCount && !this.state.goodStorageSaleFiltersActiveCount && !this.state.guestFiltersActiveCount) {
            return getFalseResult();
        }
        return guestsSearch({...this.state.search}, ...params);
    }

    handleExport = () => {
        guestsSearch({...this.state.search}, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        columnDataForExelExport.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnDataForExelExport.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Guests search");
                    XLSX.writeFile(new_workbook, `Guests search.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    }

    onApplyFilter = (data) => {
        this.setState({
            search: data
        }, this.recalculateActiveFilters);
    }

    handleCrnSegmentForm = () => {
        const { crnSegmentFormVisible } = this.state;

        this.setState({
            crnSegmentFormVisible: !crnSegmentFormVisible
        })
    }

    handleSelectGuests = (selected) => {
        this.setState({ selectedGuests: selected });
    };

    handleChangePersonalDiscount = prop => event => {
        this.setState({
            personalDiscount: {
                ...this.state.personalDiscount,
                [prop] : moment.isMoment(event) ? event : event.target.value
            }
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    renderPersonalDiscountDialog() {
        const { t, classes } = this.props;
        const {
            personalDiscount,
            isPersonalDiscountDialogOpen,
            personalDiscountNotifyGuestsBySms,
            isPersonalDiscountDialogBlock,
            discountActiveTypes,
            discountTypes,
            selectedGuests
        } = this.state;
        const canEdit =
            !isPersonalDiscountDialogBlock &&
            selectedGuests.length
        const canCreate =
            selectedGuests.length &&
            personalDiscount.name &&
            personalDiscount.type &&
            personalDiscount.active &&
            personalDiscount.value

        return (
            <Dialog
                fullWidth
                open={isPersonalDiscountDialogOpen}
                onClose={this.handlePersonalDiscountDialogOpen}
            >
                <DialogTitle>{t('Discount assignment')}</DialogTitle>
                <DialogContent

                >
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                    <Grid container spacing={2} className={classes.discountContainer}>
                        <Grid item xs={6}>
                            <AnyField
                                label={t("Title")}
                                value={personalDiscount.name}
                                onChange={this.handleChangePersonalDiscount('name')}
                                required
                                error={ !personalDiscount.name }
                                fullWidth
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectFromItemsField
                                items={discountTypes}
                                value={personalDiscount.type}
                                onChange={this.handleChangePersonalDiscount('type')}
                                label={t("Type")}
                                fullWidth
                                textField="text"
                                readOnly={!canEdit}
                                disabled={personalDiscount.type === discountTypeCustom}
                                disabledValues={[discountTypeCustom]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label={t("Value")}
                                value={personalDiscount.value}
                                onChange={this.handleChangePersonalDiscount('value')}
                                fullWidth
                                required
                                error={ personalDiscount.value === null }
                                InputProps={{
                                    inputComponent: NumberFormatDecimal,
                                }}
                                readOnly={!canEdit}
                                disabled={personalDiscount.type === discountTypeCustom}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateField
                                label={t("Expired at")}
                                value={personalDiscount.expiredAt}
                                onChange={this.handleChangePersonalDiscount('expiredAt')}
                                fullWidth
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                label="Макс. кол-во использований одним гостем"
                                value={personalDiscount.maxLimit}
                                onChange={this.handleChangePersonalDiscount('maxLimit')}
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatCount,
                                }}
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectFromItemsField
                                items={discountActiveTypes}
                                value={personalDiscount.active}
                                onChange={this.handleChangePersonalDiscount('active')}
                                label={t("Active")}
                                fullWidth
                                textField="text"
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CheckboxField
                                label="Специальная"
                                value={personalDiscount.special}
                                onChange={this.handleChangePersonalDiscount('special')}
                                readOnly={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CheckboxField
                                className={classes.notifySmsLabel}
                                name="personalDiscountNotifyGuestsBySms"
                                label={t('Notify guest by sms')}
                                value={personalDiscountNotifyGuestsBySms}
                                onChange={this.handleChange}
                                disabled={!canEdit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CheckboxField
                                label="Может применяться с дополнительным прайсом"
                                value={personalDiscount.allowUseWithClubPrice}
                                onChange={this.handleChangePersonalDiscount('allowUseWithClubPrice')}
                                disabled={!canEdit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleMassCreateAndSetPersonalDiscount} color="primary" disabled={!canEdit || !canCreate}>{t('Save')}</Button>
                    <Button onClick={this.handlePersonalDiscountDialogOpen} color="primary" disabled={!canEdit}>{t('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    handlePersonalDiscountDialogOpen = () => {
        this.setState( state => ({
          isPersonalDiscountDialogOpen: !state.isPersonalDiscountDialogOpen
        }))
    }

    handleMassCreateAndSetPersonalDiscount = () => {
        const { personalDiscount, personalDiscountNotifyGuestsBySms, selectedGuests } = this.state
        const { t } = this.props
        const request = {
            discount: personalDiscount,
            notifyGuestsBySms: personalDiscountNotifyGuestsBySms,
            guestsIds: selectedGuests.map(g => g.id)
        }

        this.setState({
            isPersonalDiscountDialogBlock: true
        })

        massCreateAndSetPersonalDiscount(request)
            .then(response => {
                if (response.success) {
                    this.props.showMessage(t('Discount assigned'));
                    this.handlePersonalDiscountDialogOpen()
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }

                this.setState({
                    isPersonalDiscountDialogBlock: false
                })
            })
    }

    render() {
        const { t, classes } = this.props;
        const {
            search, expanded, refreshTable,
            visitFiltersActiveCount, payDocFiltersActiveCount, goodStorageSaleFiltersActiveCount, guestFiltersActiveCount,

            visitDiscounts,
            paymentTypes, payDocTypes,
            creators, roles, departments, masters,
            facilities, parlours, regions,
            payDocStatuses, goods, loyalties,
            genders,
            discountActiveTypes,
            personalDiscountNames,
            crnSegmentFormVisible,
            selectedGuests,
            showVisitStatus
        } = this.state;
        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <FilterPreset
                    onApply={this.onApplyFilter}
                    filterData={search}
                    type="guest_search"
                />
                <ActionButton onClick={this.handleFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.clearFilter}>{t('Clear filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                <ActionButton onClick={this.handleCrnSegmentForm}>{(crnSegmentFormVisible) ? t('Close form for segment create') : t('Open form for segment create')}</ActionButton>
                <ActionButton
                    onClick={this.handlePersonalDiscountDialogOpen}
                    disabled={
                        selectedGuests.length === 0 ||
                        !hasRole('ROLE_GUEST_GUEST_SAVE_PERSONAL_DISCOUNT')
                    }
                >
                    {t('Discount assignment')}
                </ActionButton>
                <CrnSegment
                    visible={crnSegmentFormVisible}
                    searchParams={this.state.search}
                />
            </LeftBar>
            {this.renderPersonalDiscountDialog()}
            <Content title={t("Guests search")}>

                <ExpansionPanel expanded={expanded === pamelNames.visits} onChange={this.handleChangePanel(pamelNames.visits)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={classes.heading}>{ t('Visits filters') }</Typography>
                        <Typography className={classes.secondaryHeading}>{ t('Active') }: {visitFiltersActiveCount}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <DynamicDateField
                                    value={search.visitStartInterval}
                                    name="visitStartInterval"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.visitStartInterval')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.visitPriceFrom}
                                    valueTo={search.visitPriceTo}
                                    nameFrom="visitPriceFrom"
                                    nameTo="visitPriceTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.visitPrice')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.visitPriceTotalFrom}
                                    valueTo={search.visitPriceTotalTo}
                                    nameFrom="visitPriceTotalFrom"
                                    nameTo="visitPriceTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.visitPriceTotal')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.visitCountFrom}
                                    valueTo={search.visitCountTo}
                                    nameFrom="visitCountFrom"
                                    nameTo="visitCountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.visitCount')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitDiscountIds')}
                                    value={search.visitDiscountIds}
                                    name="visitDiscountIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={visitDiscounts}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.visitRequested}
                                    valueField="value"
                                    name="visitRequested"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.visitRequested')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.visitGratuityPriceFrom}
                                    valueTo={search.visitGratuityPriceTo}
                                    nameFrom="visitGratuityPriceFrom"
                                    nameTo="visitGratuityPriceTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.visitGratuityPrice')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.visitGratuityTotalFrom}
                                    valueTo={search.visitGratuityTotalTo}
                                    nameFrom="visitGratuityTotalFrom"
                                    nameTo="visitGratuityTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.visitGratuityTotal')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitPaymentTypes')}
                                    value={search.visitPaymentTypes}
                                    name="visitPaymentTypes"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={paymentTypes}
                                    placeholder={t('---All---')}
                                    valueField="id"
                                    textField="text"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitPaymentTypePayDocTypes')}
                                    value={search.visitPaymentTypePayDocTypes}
                                    name="visitPaymentTypePayDocTypes"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={payDocTypes}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitCreatorIds')}
                                    value={search.visitCreatorIds}
                                    name="visitCreatorIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={creators}
                                    placeholder={t('---All---')}
                                    textField="username"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitCreatorRoles')}
                                    value={search.visitCreatorRoles}
                                    name="visitCreatorRoles"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={roles}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitCreatorDepartmentIds')}
                                    value={search.visitCreatorDepartmentIds}
                                    name="visitCreatorDepartmentIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={departments}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitMasterIds')}
                                    value={search.visitMasterIds}
                                    name="visitMasterIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={masters}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitFacilityIds')}
                                    value={search.visitFacilityIds}
                                    name="visitFacilityIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={facilities}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitParlourIds')}
                                    value={search.visitParlourIds}
                                    name="visitParlourIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={parlours}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitParlourRegionIds')}
                                    value={search.visitParlourRegionIds}
                                    name="visitParlourRegionIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={regions}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.visitStatuses')}
                                    value={search.visitStatuses}
                                    name="visitStatuses"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={this.visitStatusesArray}
                                    placeholder={t('---All---')}
                                    valueField="id"
                                    textField="text"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.visitMultiCountFrom}
                                    valueTo={search.visitMultiCountTo}
                                    nameFrom="visitMultiCountFrom"
                                    nameTo="visitMultiCountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.visitMultiCount')}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePeriodField2
                                    label={'Исключить визиты в дни'}
                                    valueFrom={search.visitExcludeStart}
                                    valueTo={search.visitExcludeEnd}
                                    nameFrom="visitExcludeStart"
                                    nameTo="visitExcludeEnd"
                                    onChangeFrom={this.handleChangeDate}
                                    onChangeTo={this.handleChangeDate}
                                    className={classes.field}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {
                                    showVisitStatus && 
                                    <AnyField
                                    select
                                    fullWidth
                                    label={t('guestSearchPage.visitType')}
                                    onChange={this.handleChangeSearch}
                                    value={search.visitType}
                                    name='visitType'
                                    >
                                        {
                                            visitType.map((type, idx) => (
                                                <MenuItem key={idx} value={type.id}>
                                                    {type.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </AnyField>    
                                    }
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === pamelNames.payDocs} onChange={this.handleChangePanel(pamelNames.payDocs)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={classes.heading}>{ t('PayDocs filters') }</Typography>
                        <Typography className={classes.secondaryHeading}>{ t('Active') }: {payDocFiltersActiveCount}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <DynamicDateField
                                    value={search.payDocRealisationInterval}
                                    name="payDocRealisationInterval"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocRealisationInterval')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DynamicDateField
                                    value={search.payDocPaymentInterval}
                                    name="payDocPaymentInterval"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocPaymentInterval')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocRefillMoneyAmountFrom}
                                    valueTo={search.payDocRefillMoneyAmountTo}
                                    nameFrom="payDocRefillMoneyAmountFrom"
                                    nameTo="payDocRefillMoneyAmountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocRefillMoneyAmount')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocRefillMoneyAmountTotalFrom}
                                    valueTo={search.payDocRefillMoneyAmountTotalTo}
                                    nameFrom="payDocRefillMoneyAmountTotalFrom"
                                    nameTo="payDocRefillMoneyAmountTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocRefillMoneyAmountTotal')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocRefillTimeAmountFrom}
                                    valueTo={search.payDocRefillTimeAmountTo}
                                    nameFrom="payDocRefillTimeAmountFrom"
                                    nameTo="payDocRefillTimeAmountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocRefillTimeAmount')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocRefillTimeAmountTotalFrom}
                                    valueTo={search.payDocRefillTimeAmountTotalTo}
                                    nameFrom="payDocRefillTimeAmountTotalFrom"
                                    nameTo="payDocRefillTimeAmountTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocRefillTimeAmountTotal')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocRefillSumAmountFrom}
                                    valueTo={search.payDocRefillSumAmountTo}
                                    nameFrom="payDocRefillSumAmountFrom"
                                    nameTo="payDocRefillSumAmountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocRefillSumAmount')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocRefillSumAmountTotalFrom}
                                    valueTo={search.payDocRefillSumAmountTotalTo}
                                    nameFrom="payDocRefillSumAmountTotalFrom"
                                    nameTo="payDocRefillSumAmountTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocRefillSumAmountTotal')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocPaymentMoneyAmountFrom}
                                    valueTo={search.payDocPaymentMoneyAmountTo}
                                    nameFrom="payDocPaymentMoneyAmountFrom"
                                    nameTo="payDocPaymentMoneyAmountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocPaymentMoneyAmount')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocPaymentMoneyAmountTotalFrom}
                                    valueTo={search.payDocPaymentMoneyAmountTotalTo}
                                    nameFrom="payDocPaymentMoneyAmountTotalFrom"
                                    nameTo="payDocPaymentMoneyAmountTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocPaymentMoneyAmountTotal')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocPaymentTimeAmountFrom}
                                    valueTo={search.payDocPaymentTimeAmountTo}
                                    nameFrom="payDocPaymentTimeAmountFrom"
                                    nameTo="payDocPaymentTimeAmountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocPaymentTimeAmount')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocPaymentTimeAmountTotalFrom}
                                    valueTo={search.payDocPaymentTimeAmountTotalTo}
                                    nameFrom="payDocPaymentTimeAmountTotalFrom"
                                    nameTo="payDocPaymentTimeAmountTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocPaymentTimeAmountTotal')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocPaymentSumAmountFrom}
                                    valueTo={search.payDocPaymentSumAmountTo}
                                    nameFrom="payDocPaymentSumAmountFrom"
                                    nameTo="payDocPaymentSumAmountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocPaymentSumAmount')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocPaymentSumAmountTotalFrom}
                                    valueTo={search.payDocPaymentSumAmountTotalTo}
                                    nameFrom="payDocPaymentSumAmountTotalFrom"
                                    nameTo="payDocPaymentSumAmountTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocPaymentSumAmountTotal')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocBalanceMoneyFrom}
                                    valueTo={search.payDocBalanceMoneyTo}
                                    nameFrom="payDocBalanceMoneyFrom"
                                    nameTo="payDocBalanceMoneyTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocBalanceMoney')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocBalanceTimeFrom}
                                    valueTo={search.payDocBalanceTimeTo}
                                    nameFrom="payDocBalanceTimeFrom"
                                    nameTo="payDocBalanceTimeTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocBalanceTime')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.payDocGuestChangingCountFrom}
                                    valueTo={search.payDocGuestChangingCountTo}
                                    nameFrom="payDocGuestChangingCountFrom"
                                    nameTo="payDocGuestChangingCountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.payDocGuestChangingCount')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.payDocTypeIds')}
                                    value={search.payDocTypeIds}
                                    name="payDocTypeIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={payDocTypes}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.payDocStatuses')}
                                    value={search.payDocStatuses}
                                    name="payDocStatuses"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={payDocStatuses}
                                    placeholder={t('---All---')}
                                    valueField="id"
                                    textField="text"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.payDocRegions')}
                                    value={search.payDocRegions}
                                    name="payDocRegions"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={regions}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === pamelNames.goodStorageSale} onChange={this.handleChangePanel(pamelNames.goodStorageSale)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={classes.heading}>{ t('GoodStorageSales filters') }</Typography>
                        <Typography className={classes.secondaryHeading}>{ t('Active') }: {goodStorageSaleFiltersActiveCount}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <DynamicDateField
                                    value={search.goodStorageSaleInterval}
                                    name="goodStorageSaleInterval"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.goodStorageSaleInterval')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.goodStorageSaleOneTotalFrom}
                                    valueTo={search.goodStorageSaleOneTotalTo}
                                    nameFrom="goodStorageSaleOneTotalFrom"
                                    nameTo="goodStorageSaleOneTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.goodStorageSaleOneTotal')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.goodStorageSaleTotalFrom}
                                    valueTo={search.goodStorageSaleTotalTo}
                                    nameFrom="goodStorageSaleTotalFrom"
                                    nameTo="goodStorageSaleTotalTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.goodStorageSaleTotal')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.goodStorageSaleBusinessUnitIds')}
                                    value={search.goodStorageSaleBusinessUnitIds}
                                    name="goodStorageSaleBusinessUnitIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={parlours}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.goodStorageSaleBusinessUnitRegionIds')}
                                    value={search.goodStorageSaleBusinessUnitRegionIds}
                                    name="goodStorageSaleBusinessUnitRegionIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={regions}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.goodStorageSaleGoodIds')}
                                    value={search.goodStorageSaleGoodIds}
                                    name="goodStorageSaleGoodIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={goods}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === pamelNames.guests} onChange={this.handleChangePanel(pamelNames.guests)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={classes.heading}>{ t('Guests filters') }</Typography>
                        <Typography className={classes.secondaryHeading}>{ t('Active') }: {guestFiltersActiveCount}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <DynamicDateField
                                    value={search.guestBirthDay}
                                    name="guestBirthDay"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestBirthDay')}
                                    ignoreYear
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestAgreementCall}
                                    valueField="value"
                                    name="guestAgreementCall"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestAgreementCall')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestAgreementSms}
                                    valueField="value"
                                    name="guestAgreementSms"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestAgreementSms')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestAgreementPolicy}
                                    valueField="value"
                                    name="guestAgreementPolicy"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestAgreementPolicy')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={genders}
                                    textField="text"
                                    value={search.guestGender}
                                    valueField="id"
                                    name="guestGender"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestGender')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestSpecialStatus}
                                    valueField="value"
                                    name="guestSpecialStatus"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestSpecialStatus')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestLoyalties')}
                                    value={search.guestLoyalties}
                                    name="guestLoyalties"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={loyalties}
                                    placeholder={t('---All---')}
                                    valueField="id"
                                    textField="text"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestClubMember}
                                    valueField="value"
                                    name="guestClubMember"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestClubMember')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestIsCold}
                                    valueField="value"
                                    name="guestIsCold"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestIsCold')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestEmail}
                                    valueField="value"
                                    name="guestEmail"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestEmail')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestSkype}
                                    valueField="value"
                                    name="guestSkype"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestSkype')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestFacebook}
                                    valueField="value"
                                    name="guestFacebook"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestFacebook')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestInstagram}
                                    valueField="value"
                                    name="guestInstagram"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestInstagram')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestOdnoklassniki}
                                    valueField="value"
                                    name="guestOdnoklassniki"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestOdnoklassniki')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestVkontakte}
                                    valueField="value"
                                    name="guestVkontakte"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestVkontakte')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestFavoriteParlourIds')}
                                    value={search.guestFavoriteParlourIds}
                                    name="guestFavoriteParlourIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={parlours}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestConvenientParlourHomeIds')}
                                    value={search.guestConvenientParlourHomeIds}
                                    name="guestConvenientParlourHomeIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={parlours}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestConvenientParlourWorkIds')}
                                    value={search.guestConvenientParlourWorkIds}
                                    name="guestConvenientParlourWorkIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={parlours}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestFavoriteMasterIds')}
                                    value={search.guestFavoriteMasterIds}
                                    name="guestFavoriteMasterIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={masters}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestNotFavoriteMasterIds')}
                                    value={search.guestNotFavoriteMasterIds}
                                    name="guestNotFavoriteMasterIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={masters}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestFavoriteProcedureIds')}
                                    value={search.guestFavoriteProcedureIds}
                                    name="guestFavoriteProcedureIds"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={facilities}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={this.boolValues}
                                    textField="text"
                                    value={search.guestDiscount}
                                    valueField="value"
                                    name="guestDiscount"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestDiscount')}
                                    nullable
                                    nullableText={t('guestSearchPage.noMatter')}
                                    nullValue={null}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t("guestSearchPage.guestDiscountName")}
                                    name={"guestDiscountNames"}
                                    value={search.guestDiscountNames}
                                    options={personalDiscountNames}
                                    onChange={this.handleChangeSearch}
                                    textField={"name"}
                                    valueField={"name"}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.guestDiscountValueFrom}
                                    valueTo={search.guestDiscountValueTo}
                                    nameFrom="guestDiscountValueFrom"
                                    nameTo="guestDiscountValueTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestDiscountValue')}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.guestDiscountUsingCountFrom}
                                    valueTo={search.guestDiscountUsingCountTo}
                                    nameFrom="guestDiscountUsingCountFrom"
                                    nameTo="guestDiscountUsingCountTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestDiscountUsingCount')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestDiscountActiveTypes')}
                                    value={search.guestDiscountActiveTypes}
                                    name="guestDiscountActiveTypes"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={discountActiveTypes}
                                    placeholder={t('---All---')}
                                    valueField="id"
                                    textField="text"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberRangeField
                                    valueFrom={search.guestDiscountMaxLimitFrom}
                                    valueTo={search.guestDiscountMaxLimitTo}
                                    nameFrom="guestDiscountMaxLimitFrom"
                                    nameTo="guestDiscountMaxLimitTo"
                                    onChangeFrom={this.handleChangeSearch}
                                    onChangeTo={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestDiscountMaxLimit')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DynamicDateField
                                    value={search.guestDiscountExpiredAt}
                                    name="guestDiscountExpiredAt"
                                    onChange={this.handleChangeSearch}
                                    label={t('guestSearchPage.guestDiscountExpiredAt')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <AutocompleteMultiSelectField
                                    label={t('guestSearchPage.guestRegions')}
                                    value={search.guestRegions}
                                    name="guestRegions"
                                    onChange={this.handleChangeSearch}
                                    fullWidth
                                    options={regions}
                                    placeholder={t('---All---')}
                                />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <DataTable
                    dataFunc={this.getGuests}
                    refresh={refreshTable}
                    columnData={columnData}
                    linkTemplate={(item) => `/guests/view/${item.id}`}
                    onSelect={this.handleSelectGuests}
                />
            </Content>
        </React.Fragment>
    }
}