import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    getGoodStorageRequests,
    getGroupedFromRequests,
    navLinks,
    requestStatuses, saveRequest
} from "../../services/goods";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem
} from "@material-ui/core";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import AnyField from "../../components/fields/AnyField";
import {getCounterparts} from "../../services/user";
import {getParlours} from "../../services/organization";
import UploadFilesField from "../../components/fields/UploadFilesField";
import CreateOrdersDialog from "./CreateOrdersDialog";
import {withRouter} from "react-router";

const columnData = [
    { id: 'num', label: 'Номер запроса' },
    { id: 'createdAt', label: 'Дата создания запроса', dateFormat: 'DD.MM.YYYY' },
    { id: 'creator.username', label: 'Пользователь, создавший запрос' },
    { id: 'creator.phone', label: 'Телефон пользователя, создавшего запрос' },
    { id: 'parlour.name', label: 'Салон' },
    { id: 'statusText', label: 'Статус запроса' },
];

const styles = theme => ({
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
        marginTop: 0
    },
    counterpartField: {
        width: 300
    },
    grid: {
        alignSelf: 'center'
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    dialogContent: {
        minWidth: 600,
    },
    tableTitle: {
        fontWeight: 'bold',
    },
    error: {
        color: theme.palette.error.main,
    }
});

@withRouter
class RequestListPage extends React.Component {

    state = {
        search: '',
        selected: [],
        dialogOrdersOpen: false,
        grouped: [],
        counterparts:[],
        refreshTable: false,

        dialogFileOpen: false,
        xls: null,
        parlour: null,
        parlours: [],
    };

    componentDidMount() {
        getCounterparts()
            .then(response => {
                if (response.success) {
                    this.setState({
                        counterparts: response.data,
                    })
                }
            });

        getParlours()
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    })
                }
            });
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickSearch = () => {
        this.setState({
            refreshTable: !this.state.refreshTable,
        })
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getGoodStorageRequests({search}, ...params);
    };

    handleAdd = () => {
        this.props.history.push(`/goods/requests/view/0`);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleCreateOrders = () => {
        getGroupedFromRequests(this.state.selected.map(item => item.id))
            .then(response => {
                if (response.success) {
                    this.setState({
                        grouped: response.data,
                        dialogOrdersOpen: true,
                    });
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    handleCloseCreateOrdersDialog = () => {
        this.setState({
            dialogOrdersOpen: false
        });
    };

    handleFromFile = () => {
        this.setState({
            dialogFileOpen: true,
            xls: null,
            parlour: null,
        })
    };

    handleCloseFileDialog = ok => () => {
        const { parlour, xls } = this.state;

        if (!ok) {
            this.setState({
                dialogFileOpen: false,
            });
            return;
        }

        saveRequest({
            parlour,
            xls: xls.id
        })
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/goods/requests/view/${response.data.id}`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };


    render() {
        const { search, selected, refreshTable, dialogOrdersOpen, grouped } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search}
                        onChange={this.handleChange('search')}
                        label="Поиск по запросам"
                        onClick={this.handleClickSearch}
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить новый запрос</ActionButton>
                    <ActionButton onClick={this.handleFromFile}>Добавить запрос из файла</ActionButton>
                    <ActionButton onClick={this.handleCreateOrders} disabled={!selected.length || selected.length !== selected.filter(item => item.status === requestStatuses.main_pending).length}>Сформировать заказы из запросов</ActionButton>
                </LeftBar>
                <Content title="Запросы на товары">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/goods/requests/view/${item.id}`}
                        onSelect={this.handleSelect}
                        refresh={refreshTable}
                        order="desc"
                    />
                </Content>
                { this.renderFileDialog() }
                { dialogOrdersOpen && <CreateOrdersDialog grouped={grouped} onClose={this.handleCloseCreateOrdersDialog} /> }
            </React.Fragment>
        );
    }

    renderFileDialog() {
        const { classes } = this.props;
        const { parlour, dialogFileOpen, xls, parlours } = this.state;

        return (
            <Dialog
                open={dialogFileOpen}
                onClose={this.handleCloseFileDialog(false)}
            >
                <DialogTitle>Создание запроса из файла</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UploadFilesField
                                id="xls"
                                label="Файл"
                                value={xls}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={this.handleChange('xls')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Салон"
                                value={parlour}
                                required
                                error={!parlour}
                                onChange={this.handleChange('parlour')}
                                select
                                fullWidth
                            > {parlours.map(parlour => <MenuItem key={parlour.id} value={parlour.id}>{parlour.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseFileDialog(true)} disabled={!parlour || !xls}  color="primary">OK</Button>
                    <Button onClick={this.handleCloseFileDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(withStyles(styles)(RequestListPage));
