import React from 'react';
import moment from "moment";
import {getBusinessUnits} from "../../services/organization";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import DataTable from "../../components/data-table/DataTable";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import _ from "lodash";
import XLSX from "xlsx";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {
    navLinks, getGoodsByBusinessUnits, getCategories, getGoodPurposes, getCosmeticsMovementReport
} from "../../services/goods";
import DatePeriodField from "../../components/fields/DatePeriodField";
import CheckboxField from "../../components/fields/CheckboxField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";

export default
@withTranslation()
class CosmeticsMovementReportPage extends React.Component
{
    constructor(props) {
        super(props);

        let dateStart = moment().startOf('week');
        let dateEnd = moment();
        let businessUnits = [];
        let categories = [];
        let goods = [];
        let purposes = [];
        let showMass = true;
        let showMoney = false;
        let showCount = false;

        if (props.currentParlour) {
            businessUnits.push(props.currentParlour.id);
        }

        const querySearch = getFilterFromQuery();

        if (querySearch) {
            if (querySearch.dateStart) {
                dateStart = moment(querySearch.dateStart);
            }
            if (querySearch.dateEnd) {
                dateEnd = moment(querySearch.dateEnd);
            }
            if (querySearch.businessUnits) {
                businessUnits = querySearch.businessUnits;
            }
            if (querySearch.categories) {
                categories = querySearch.categories;
            }
            if (querySearch.goods) {
                goods = querySearch.goods;
            }

            if (querySearch.purposes) {
                purposes = querySearch.purposes;
            }
            if (querySearch.showMass) {
                showMass = Boolean(querySearch.showMass);
            }
            if (querySearch.showMoney) {
                showMoney = Boolean(querySearch.showMoney);
            }
            if (querySearch.showCount) {
                showCount = Boolean(querySearch.showCount);
            }
        }

        this.state = {
            search: {
                dateStart: dateStart,
                dateEnd: dateEnd,
                businessUnits: businessUnits,
                goods: goods,
                categories: categories,
                purposes: purposes,
                showMass: showMass,
                showMoney: showMoney,
                showCount: showCount,
            },
            refreshTable: false,
            businessUnits: [],
            goods: [],
            categories: [],
            purposes: [],
        };
    }


    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });

        getCategories()
            .then(response => {
                if(response.success) {
                    this.setState({
                        categories: response.data
                    })
                }
            })

        getGoodPurposes()
            .then(response => {
                if(response.success) {
                    this.setState({
                        purposes: response.data
                    })
                }
            })

        this.getGoods()
    }

    getGoods(search = []) {
        getGoodsByBusinessUnits(search, 1, -1)
            .then(response => {
                if(response.success) {
                    this.setState({
                        goods: response.data
                    })
                }
            })
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        }, () => {
            const {businessUnits, categories, purposes} = this.state.search
            if(prop === 'businessUnits' || prop === 'categories' || prop === 'purposes') {
                this.getGoods({businessUnits, categories, purposes})
            }
        });
    };

    handleClickFilter = () => {
        pushFilterToQuery(processMomentFieldInObject(this.state.search, ['dateStart', 'dateEnd', 'month']));
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableColumns = () => {
        const {search} = this.state
        return [
            {id: 'goodName', label: 'Good', show: true},
            {id: 'beginCount', label: 'cosmeticsMovementReport.beginCount', show: search.showCount},
            {id: 'beginMass', label: 'cosmeticsMovementReport.beginMass', show: search.showMass},
            {id: 'beginMoney', label: 'cosmeticsMovementReport.beginMoney', show: search.showMoney},
            {id: 'incomeCount', label: 'cosmeticsMovementReport.incomeCount', show: search.showCount},
            {id: 'incomeMass', label: 'cosmeticsMovementReport.incomeMass', show: search.showMass},
            {id: 'incomeMoney', label: 'cosmeticsMovementReport.incomeMoney', show: search.showMoney},
            {id: 'removedCount', label: 'cosmeticsMovementReport.removedCount', show: search.showCount},
            // {id: 'soldCount', label: 'cosmeticsMovementReport.soldCount', show: search.showCount},
            // {id: 'soldMass', label: 'cosmeticsMovementReport.soldMass', show: search.showMass},
            // {id: 'soldMoney', label: 'cosmeticsMovementReport.soldMoney', show: search.showMoney},
            {id: 'removedMass', label: 'cosmeticsMovementReport.removedMass', show: search.showMass},
            {id: 'removedMoney', label: 'cosmeticsMovementReport.removedMoney', show: search.showMoney},
            {id: 'endCount', label: 'cosmeticsMovementReport.endCount', show: search.showCount},
            {id: 'endMass', label: 'cosmeticsMovementReport.endMass', show: search.showMass},
            {id: 'endMoney', label: 'cosmeticsMovementReport.endMoney', show: search.showMoney},
        ].filter(col => col.show);
    }

    getTableData = (...params) => {
        const {search} = this.state;
        return getCosmeticsMovementReport(processMomentFieldInObject(
            search,
            [
                'dateStart',
                'dateEnd'
            ]), ...params);
    };

    handleExport = () => {
        const { search } = this.state;
        const from = moment.isMoment(search.dateStart) ? search.dateStart.format('YYYY-MM-DD') : search.dateStart;
        const to = moment.isMoment(search.dateEnd) ? search.dateEnd.format('YYYY-MM-DD') : search.dateEnd;
        const columnData = this.getTableColumns()
        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        columnData.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnData.map(item => {
                                const value = _.get(row, item.id);
                                if (item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                } else {
                                    return value;
                                }
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Goods sales");
                    XLSX.writeFile(new_workbook, `Cosmetics  movement report ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits, goods, categories, purposes } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField
                    valueFrom={search.dateStart}
                    valueTo={search.dateEnd}
                    maxDate={moment()}
                    onChangeFrom={this.handleChange('dateStart')}
                    onChangeTo={this.handleChange('dateEnd')}
                    label={t('Date')}
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.businessUnits}
                    onChange={this.handleChange('businessUnits')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={categories}
                    label={t('Category')}
                    value={search.categories}
                    onChange={this.handleChange('categories')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={purposes}
                    label={t('Purpose')}
                    value={search.purposes}
                    onChange={this.handleChange('purposes')}
                    fullWidth
                    textField={'text'}
                />
                <AutocompleteMultiSelectField
                    options={goods}
                    label={t('Goods')}
                    value={search.goods}
                    onChange={this.handleChange('goods')}
                    fullWidth
                    isClearable={true}
                />
                <CheckboxField
                    label={t('cosmeticsMovementReport.showCount')}
                    value={search.showCount}
                    onChange={this.handleChange('showCount')}
                />
                <CheckboxField
                    label={t('cosmeticsMovementReport.showMass')}
                    value={search.showMass}
                    onChange={this.handleChange('showMass')}
                />
                <CheckboxField
                    label={t('cosmeticsMovementReport.showMoney')}
                    value={search.showMoney}
                    onChange={this.handleChange('showMoney')}
                />
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title={t('cosmeticsMovementReport.title')}>
                <DataTable
                    columnData={this.getTableColumns()}
                    dataFunc={this.getTableData}
                    selector={false}
                    refresh={refreshTable}
                    disableSorting
                />
            </Content>
        </React.Fragment>;
    }
}