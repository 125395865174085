import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import {getPaymentHistoryChange, getSalableEntityLink, getSale, getSaleHistoryChange} from "../../services/sale";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import ShowField from "../../components/fields/ShowField";
import {Divider, withStyles} from "@material-ui/core";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import {getFalseResult} from "../../services/common";

export default
@withStyles(theme => ({
    divider: {
        marginTop:  theme.spacing(1),
        marginBottom:  theme.spacing(1),
    }
}))
class SaleViewPage extends React.Component
{
    state = {
        sale: {
            id: null,
            createdAt: null,
            creatorName: null,
            totalFormatted: null,
            guest: null,
            businessUnitName: null,
            statusText: null,
            payments: [],
            salableEntity: [],
        },
        saleHistoryRefreshTable: false,
        paymentsHistoryRefreshTable: false
    };

    paymentsColumns = [
        {id: 'id', label: 'ID'},
        {id: 'statusText', label: 'Статус'},
        {id: 'typeText', label: 'Тип'},
        {id: 'amountFormatted', label: 'Сумма'},
        {id: 'payDocName', label: 'ПД'},
        {id: 'payDocMeasure', label: 'Тип ПД'},
        {id: 'createdAt', label: 'Дата время', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        {id: 'creatorName', label: 'Создатель'},
    ];

    salableEntityColumns = [
        {id: 'id', label: 'ID'},
        {id: 'entityText', label: 'Тип', linkTemplate: row => getSalableEntityLink(row.entity, row.id)},
        {id: 'totalFormatted', label: 'Стоимость'},
        {id: 'discount.name', label: 'Скидка'},
        {id: 'discountNumber', label: 'Номер скидки'},
    ];

    saleHistoryColumns = [
        { id: 'id', label: 'ID' },
        { id: 'dt', label: 'Дата и время', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'user', label: 'Пользователь' },
        { id: 'field', label: 'Поле' },
        { id: 'oldValue', label: 'Старое значение' },
        { id: 'newValue', label: 'Новое значение' },
    ];

    paymentHistoryColumns = [
        { id: 'id', label: 'ID' },
        { id: 'paymentId', label: 'ID оплаты' },
        { id: 'dt', label: 'Дата и время', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'user', label: 'Пользователь' },
        { id: 'field', label: 'Поле' },
        { id: 'oldValue', label: 'Старое значение' },
        { id: 'newValue', label: 'Новое значение' },
    ];

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);

        if (resultId.length) {
            getSale(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            sale: response.data,
                            saleHistoryRefreshTable: true,
                            paymentsHistoryRefreshTable: true
                        });
                    }
                });

        }
    }

    saleHistoryData = (...params) => {
        return this.state.sale.id ? getSaleHistoryChange(this.state.sale.id, ...params) : getFalseResult();
    }

    paymentHistoryData = (...params) => {
        return this.state.sale.id ? getPaymentHistoryChange(this.state.sale.id, ...params) : getFalseResult();
    }

    render() {
        const { classes } = this.props;
        const { sale, saleHistoryRefreshTable, paymentsHistoryRefreshTable } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={[]}/>}>
                <ShowField
                    label="Сумма"
                    value={sale.totalFormatted}
                />
                <ShowField
                    label="Бизнес юнит"
                    value={sale.businessUnitName}
                />
                <ShowField
                    label="Создан"
                    dateFormat="DD.MM.YYYY HH:mm:ss"
                    value={sale.createdAt}
                />
                <ShowField
                    label="Создатель"
                    value={sale.creatorName}
                />
                <ShowField
                    label="Гость"
                    value={sale.guest ? sale.guest.fullName : ''}
                />
                <ShowField
                    label="Телефон гостя"
                    value={sale.guest ? sale.guest.phone : ''}
                />
                <ShowField
                    label="Статус"
                    value={sale.statusText}
                />

            </LeftBar>
            <Content title="Продажа">
                <div>Сущности:</div>
                <DataTableLocal
                    pagination={false}
                    selector={false}
                    data={sale.salableEntity}
                    columnData={this.salableEntityColumns}
                />
                <Divider className={classes.divider}/>
                <div>Платежи:</div>
                <DataTableLocal
                    pagination={false}
                    selector={false}
                    data={sale.payments}
                    columnData={this.paymentsColumns}
                />
                <Divider className={classes.divider}/>
                <div>История продажи:</div>
                <DataTable
                    columnData={this.saleHistoryColumns}
                    dataFunc={this.saleHistoryData}
                    refresh={saleHistoryRefreshTable}
                    selector={false}
                    order="desc"
                    orderBy="dt"
                    pagination={false}
                />
                <Divider className={classes.divider}/>
                <div>История оплат:</div>
                <DataTable
                    columnData={this.paymentHistoryColumns}
                    dataFunc={this.paymentHistoryData}
                    refresh={paymentsHistoryRefreshTable}
                    selector={false}
                    order="desc"
                    orderBy="dt"
                    pagination={false}
                />
            </Content>
        </React.Fragment>;
    }
}