import moment from "moment";
import React from "react";

const dayWithBu = (value, t, row) => {
    return <div>
        <div>{moment(value).format('DD.MM.YYYY')}</div>
        <div>{row.businessUnitName}</div>
    </div>
};

export default dayWithBu;
