import React from 'react';
import {
    Grid, withStyles
} from '@material-ui/core';
import DataTable from "../../components/data-table/DataTable";
import {getReportMoving, getReportMovingUrl} from "../../services/goods";
import ActionButton from "../../components/button/ActionButton";
import { connect } from "react-redux";
import moment from "moment";
import DatePeriodField from "../../components/fields/DatePeriodField";

const columnData = [
    { id: 'name'                , label:'Наименование товара' },
    { id: 'category'            , label:'Группа товаров' },
    { id: 'description'         , label:'Описание товара', minWidth: 600 },
    { id: 'moneyPlusFormatted'  , label:'Приход в денежных единицах' },
    { id: 'moneyMinusFormatted' , label:'Расход в денежных единицах' },
    { id: 'moneyDeltaFormatted' , label:'Баланс в денежных единицах' },
    { id: 'massPlusFormatted'   , label:'Приход в массе' },
    { id: 'massMinusFormatted'  , label:'Расход в массе' },
    { id: 'massDeltaFormatted'  , label:'Баланс в массе' },
    { id: 'countPlus'           , label:'Приход в штуках' },
    { id: 'countMinus'          , label:'Расход в штуках' },
    { id: 'countDelta'          , label:'Баланс в штуках' },
    { id: 'parlour'             , label:'Салон' },
];

const styles = theme => ({
});

class ReportMoving extends React.Component
{
    state = {
        from: moment().subtract(7, 'days'),
        to: moment(),
        refreshTable: false,
    };

    getPeriod = () => {
        const { from, to } = this.state;
        return {
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD'),
        }
    };

    getTableData = (...params) => {
        return getReportMoving(this.getPeriod(), ...params);
    };

    handleDateChange = prop => date => {
        this.setState({
            [prop]: date,
            refreshTable: !this.state.refreshTable,
        })
    };

    render() {
        const { token } = this.props;
        const { from, to, refreshTable } = this.state;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>Движение средств по косметике</Grid>
                <Grid item xs={9}>
                    <DatePeriodField
                        label="Период"
                        valueFrom={from}
                        valueTo={to}
                        onChangeFrom={this.handleDateChange('from')}
                        onChangeTo={this.handleDateChange('to')}
                    />
                </Grid>
                <Grid item xs={3}><ActionButton href={getReportMovingUrl({access_token: token.access_token, xls: 1, search: this.getPeriod()})} marginLabeledFields>Выгрузить в Excel</ActionButton></Grid>
                <Grid item xs={12}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
});

export default connect(mapStateToProps, {})(withStyles(styles)(ReportMoving));
