import React from "react";
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogTitle, Grid,
    withStyles
} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {getParlourTimes, getVisits, visitStatuses} from "../../services/calendar";
import {getCurrentCashBoxAndSafeValue, getPaymentsIncoming} from "../../services/moneyFlow";
import {getNotCompletedTasks} from "../../services/tasks";
import { sendDayReport } from "../../services/organization";
import {connect} from 'react-redux';
import Typography from "@material-ui/core/Typography";
import MoneyField from "../../components/fields/MoneyField";
import AnyField from "../../components/fields/AnyField";
import {checkAuthorizationParams, hasRole} from "../../services/user";
import authActions from "../login/auth-actions";
import {createChangeHandle} from "../../utils/helpers";
import * as PropTypes from "prop-types";

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
    user: state.auth.user,
}), {
    login: authActions.login
})
@withStyles({
    red: {
        color: 'red'
    },
    green: {
        color: 'green'
    },
    visitsOpened__block: {
        border: '1px solid #f00',
        color: '#f00',
        marginTop: 0,
    },
    visitsOpened__title: {
        color: 'red',
    },
    visitsOpened__item: {
        color: 'red',
    },
    registration__container: {
        marginTop: 15,
    },
    removeBottomPadding: {
        paddingBottom: '0 !important',
    },
})
@withTranslation()
class ChangeAdminWorkShiftModal extends React.Component
{
    static propTypes = {
        toggleDialog: PropTypes.bool,
        funcCloseDialog: PropTypes.func,
        currentDate: PropTypes.any,
        businessUnitName: PropTypes.string,
    }

    static defaultProps = {
        toggleDialog: false,
    }

    state = {
        toggleDialog: true,
        closeWorkShiftMoneyData: null,
        visitsOpened: [],
        notCompletedTasks: [],
        newAdmin:{
            login: null,
            password: null,
            dataIsCorrect: true,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.toggleDialog  && nextProps.toggleDialog !== this.props.toggleDialog && nextProps.toggleDialog === true) {
            this.loadData();
        }
    }

    render() {
        const { t, toggleDialog, classes, businessUnitName } = this.props;
        const { visitsOpened, notCompletedTasks, closeWorkShiftMoneyData, newAdmin } = this.state;
        const types = {
            cash: t("Incoming cash"),
            cashless: t("Incoming cashless"),
            total: t("Incoming total"),
            cash_box: t("Cashbox"),
        };

        return (
        <Dialog
            open={toggleDialog}
            onClose={this.props.funcCloseDialog}
            aria-labelledby="delivery-dialog-title"
            >
            <DialogTitle id="delivery-dialog-title">{t('Changing administrator')} {businessUnitName}</DialogTitle>
            <DialogContent style={{width: 600}}>
                {
                    visitsOpened && Array.isArray(visitsOpened) && visitsOpened.length > 0
                        ?
                        <React.Fragment>
                            <Grid container spacing={2} className={classes.visitsOpened__block}>
                                <Grid item xs={12} className={classes.removeBottomPadding}>
                                    <Typography variant="h6" gutterBottom={false} className={classes.visitsOpened__title}>
                                        {t('Calendar.openVisits')}
                                    </Typography>
                                </Grid>
                                {visitsOpened.map((visit, index) =>
                                    <Grid item xs={12} key={index}>
                                        <a href={'/orders/view/' + visit.id} className={classes.visitsOpened__item}>
                                            {visit.guest ? visit.guest.fullName : 'Инкогнито'}
                                        </a>
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                        : null
                }
                {
                    notCompletedTasks && Array.isArray(notCompletedTasks) && notCompletedTasks.length > 0
                        ?
                        <React.Fragment>
                            <Grid container spacing={2} className={classes.visitsOpened__block}>
                                <Grid item xs={12} className={classes.removeBottomPadding}>
                                    <Typography variant="h6"  gutterBottom={false} className={classes.visitsOpened__title}>
                                        {t('Calendar.notCompletedTasks')}
                                    </Typography>
                                </Grid>
                                {notCompletedTasks.map((task, index) =>
                                    <Grid item xs={12} key={index}>
                                        <a href={'/tasks/view/' + task.id} className={classes.visitsOpened__item}>
                                            {task.title} №{task.num}
                                        </a>
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                        : null
                }
                {closeWorkShiftMoneyData && Object.keys(types).map((type) => {
                    if (closeWorkShiftMoneyData[type]) {
                        const valuesCount = Object.keys(closeWorkShiftMoneyData[type]).length;
                        const itemBlocks = Math.min(Math.trunc(12 / valuesCount), 6);
                        return <Grid container spacing={2} key={type}>
                            <Grid item xs={12} className={classes.removeBottomPadding}>
                                <Typography variant="h6" gutterBottom={false}>{types[type]}</Typography>
                            </Grid>
                            {Object.keys(closeWorkShiftMoneyData[type]).map((currency) =>
                                <Grid item xs={itemBlocks} key={type + currency}>
                                    <MoneyField
                                        value={closeWorkShiftMoneyData[type][currency].fact}
                                        onChange={this.handleChangeCloseWorkShiftMoneyData(type, currency)}
                                        fullWidth
                                        readOnly={type === 'total'}
                                        required
                                        onFocus={event => event.target.select()}
                                        disableCurrencySelect
                                    />
                                </Grid>
                            )}

                        </Grid>;
                    }

                    return null;
                })}
                <Grid container spacing={2} className={classes.registration__container}>
                    <Grid item xs={12} className={classes.removeBottomPadding}>
                        <Typography variant="h6" gutterBottom={false}>Учетные данные нового администратора</Typography>
                    </Grid>
                    { !newAdmin.dataIsCorrect &&
                    <Grid item xs={12} className={classes.red}>Данные аутентификации неверны</Grid>
                    }
                    <Grid item xs={6}>
                        <AnyField
                            label={t("Login")}
                            value={newAdmin.login}
                            name="login"
                            onChange={this.handleChangeAdmin}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            label={t("Password")}
                            type="password"
                            value={newAdmin.password}
                            name="password"
                            onChange={this.handleChangeAdmin}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={this.changeAdministrator}
                    color="primary"
                    disabled={!(newAdmin.login && newAdmin.password)}
                >
                    {t('Change administrator')}
                </Button>
                <Button onClick={this.props.funcCloseDialog} color="primary">{t('Cancel')}</Button>
            </DialogActions>
        </Dialog>)
    }

    changeAdministrator = () => {
        const { newAdmin, closeWorkShiftMoneyData } = this.state;
        const { currentParlour, user } = this.props;

        if (!(currentParlour && newAdmin && closeWorkShiftMoneyData)) {
            return;
        }

        checkAuthorizationParams(newAdmin)
            .then(response => {
                if (response.success) {
                    if (response.data.result && newAdmin.login && newAdmin.password) {
                        this.props.login(newAdmin.login, newAdmin.password);
                        sendDayReport(closeWorkShiftMoneyData, currentParlour.id, newAdmin.login, user.username);
                    }

                    this.setState({
                        newAdmin:{
                            ...newAdmin,
                            dataIsCorrect: response.data.result
                        }
                    })
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    loadData = () => {
        const { currentParlour, currentDate } = this.props;

        if (!currentParlour) {
            return;
        }

        let promises = [];
        let parlourTimes = getParlourTimes(currentParlour, currentDate);

        promises[0] = getPaymentsIncoming(currentParlour.id, currentDate)
            .then(response => {
                if (!response.success) {
                    this.props.showMessage(response.error ? response.error.message : response.message);

                }
                return response.data;
            });

        promises[1] = getCurrentCashBoxAndSafeValue({businessUnitId: currentParlour.id, noFormat: '1', day: currentDate})
            .then(response => {
                if (!response.success) {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
                return response.data;
            });

        promises[2] = getVisits({
            parlour: currentParlour.id,
            fromDate: currentDate.clone().hours(parlourTimes.startTime.hours()).minutes(parlourTimes.startTime.minutes()).seconds(0).millisecond(0).toISOString(),
            toDate: currentDate.clone().hours(parlourTimes.endTime.hours()).minutes(parlourTimes.endTime.minutes()).seconds(0).millisecond(0).toISOString(),
            statuses: [
                visitStatuses.providing,
                visitStatuses.starting,
                visitStatuses.complete,
                visitStatuses.risky,
                visitStatuses.confirmed,
                visitStatuses.created,
                visitStatuses.new
            ]
        })
            .then(response => {
                if (!response.success) {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }

                return response.data;
            });

        if (hasRole('ROLE_TASK_GET')) {
            promises[3] = getNotCompletedTasks(currentParlour.id, currentDate)
                .then(response => {
                    if (!response.success) {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }

                    return response.data;
                });
        } else {
            promises[3] = new Promise(resolve => {
                resolve([]);
            });
        }

        Promise.all(promises).then((data) => {
            let preCloseWorkShiftMoneyData = Object.assign(data[0], data[1]);
            let closeWorkShiftMoneyData = {};

            //fill all types and currencies
            Object.keys(preCloseWorkShiftMoneyData).forEach( (type) => {
                let currencyKey = type === 'cashless' ? 'usingCurrenciesCashless' : 'usingCurrencies';
                currentParlour[currencyKey].forEach( (currency) => {
                    let emptyMoney = { val: 0, cur:currency };

                    if (!closeWorkShiftMoneyData[type]) {
                        closeWorkShiftMoneyData[type] = {};
                    }

                    if (!closeWorkShiftMoneyData[type][currency]) {
                        closeWorkShiftMoneyData[type][currency] = {};
                    }

                    if ( !preCloseWorkShiftMoneyData[type][currency] ) {
                        closeWorkShiftMoneyData[type][currency].system = {...emptyMoney};
                    } else {
                        closeWorkShiftMoneyData[type][currency].system = {...preCloseWorkShiftMoneyData[type][currency]};
                    }
                    closeWorkShiftMoneyData[type][currency].fact = {...emptyMoney};
                    closeWorkShiftMoneyData[type][currency].delta = {...emptyMoney};
                })
            });

            this.setState({
                closeWorkShiftMoneyData: closeWorkShiftMoneyData,
                visitsOpened: data[2],
                notCompletedTasks: data[3],
            });

        });
    }

    handleChangeCloseWorkShiftMoneyData = (type, currency) => (event) => {
        const { closeWorkShiftMoneyData } = this.state;
        closeWorkShiftMoneyData[type][currency].fact = event.target.value;
        closeWorkShiftMoneyData[type][currency].delta = {
            val: closeWorkShiftMoneyData[type][currency].fact.val - closeWorkShiftMoneyData[type][currency].system.val,
            cur: closeWorkShiftMoneyData[type][currency].system.cur
        };

        closeWorkShiftMoneyData.total[currency].fact = {
            val: closeWorkShiftMoneyData.cash[currency].fact.val*1 + (closeWorkShiftMoneyData.cashless[currency] ? closeWorkShiftMoneyData.cashless[currency].fact.val*1 : 0),
            cur: closeWorkShiftMoneyData[type][currency].fact.cur
        };

        closeWorkShiftMoneyData.total[currency].delta = {
            val: closeWorkShiftMoneyData.total[currency].fact.val - closeWorkShiftMoneyData.total[currency].system.val,
            cur: closeWorkShiftMoneyData.total[currency].system.cur
        };

        this.setState({
            closeWorkShiftMoneyData: closeWorkShiftMoneyData
        });
    };

    handleChangeAdmin = createChangeHandle(this, 'newAdmin')
}