import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/goods";
import Content from "../../components/content/Content";

class CreateOrdersPage extends React.Component
{
    render() {
        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                </LeftBar>

                <Content title="">
                </Content>
            </React.Fragment>
        );
    }
}

export default CreateOrdersPage;
