import {createContext} from "react";

const Context = createContext({
    search: {
        dayStart: null,
        dayEnd: null,
        businessUnitIds: [],
        dateType: 'period',
        month: null,
    },
    isValidPeriod: false,
    isValidBusinessUnits: false,
    updateSearch: () => undefined,
    refreshTable: false,
    setRefreshTable: () => undefined,
    detailsDialog: {
        open: false,
        title: '',
        data: null,
        columnsData: [],
        linkTemplate: null,
    },
    showDetailsDialog: () => undefined,
    hideDetailsDialog: () => undefined,
    tableData: null,
    setTableData: () => undefined
});

export default Context;