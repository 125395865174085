import React, {useCallback, useContext, useMemo} from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {IconButton, makeStyles} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import Context from "../../Context";
import MoneyValue from "./MoneyValue";

const useStyles = makeStyles((theme) => ({
    subBlock: {
        paddingLeft: theme.spacing(2),
    },
}));

const MoneyValueDetailsDialog = ({
                                    detailsTitle,
                                    detailsData,
                                    detailsColumns,
                                    detailsLinkTemplate,
                                    children,
                                    ...rest
                                }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { showDetailsDialog } = useContext(Context);

    const handleClick = useCallback(() => {
        showDetailsDialog({
            title: t(detailsTitle),
            data: detailsData,
            columnsData: detailsColumns,
            linkTemplate: detailsLinkTemplate,
        });
    }, [detailsColumns, detailsData, detailsLinkTemplate, detailsTitle, showDetailsDialog, t]);

    const valueContent = useMemo(() => (
        <MoneyValue {...rest}>
            {!!detailsData?.length && (
                <IconButton
                    size="small"
                    onClick={handleClick}
                >
                    <HelpIcon fontSize="inherit"/>
                </IconButton>
            )}
        </MoneyValue>
    ), [detailsData, handleClick, rest]);

    if (children) {
        return (
            <div>
                {valueContent}
                <div className={classes.subBlock}>
                    {children}
                </div>
            </div>
        )
    }

    return valueContent;
};

MoneyValueDetailsDialog.propTypes = {
    title: PropTypes.string,
    value: PropTypes.shape({ val: PropTypes.number, cur: PropTypes.string }),
    detailsTitle: PropTypes.node,
    detailsData: PropTypes.any,
    detailsColumns: PropTypes.array,
    detailsLinkTemplate: PropTypes.func,
    children: PropTypes.node,
};

export default MoneyValueDetailsDialog;