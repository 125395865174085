import React from 'react';
import * as PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, MenuItem
} from "@material-ui/core";
import GuestField from "../../components/fields/GuestField";
import AnyField from "../../components/fields/AnyField";
import {
    getHistoryBalance,
    refundMoney
} from "../../services/payDocs";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import _ from 'lodash';
import {getSpendingTypes} from "../../services/moneyFlow";

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}),{
    showInfo: infoActions.show,
    showError: infoActions.showError,
    showMessage: messageDialogActions.show,
})
@withTranslation()
class MoneyBackDialog extends React.Component
{
    static propTypes = {
        open: PropTypes.bool,
        payDoc: PropTypes.object,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        open: false,
        payDoc: null
    };

    paymentsTypeTitles = {
        cash: 'Наличный',
        cashless: 'Безналичный'
    }

    sendData = {
        id: null,
        businessUnitId: null,
        guestId: null,
        amountOfMoney: 0,
        baseUnitAmount: 0,
        refundMethod: null,
        comment: '',
        spendingType: null
    }

    validateSettings = {
        guestId: [
            {
                state: 'empty',
                message: this.props.t('Guest should not be empty'),
            }
        ],
        baseUnitAmount: [
            {
                state: 'empty',
                message: this.props.t('Money should not be empty or 0'),
            }
        ],
        comment: [
            {
                state: 'empty',
                message: this.props.t('Comment should not be empty'),
            }
        ]
    }

    state = {
        ...this.sendData,
        businessUnitName: null,
        wrongAmountError: false,
        wrongAmountErrorText: '',
        updating: false,
        validateErrorState: {
            guestId: false,
            baseUnitAmount: false,
            comment: false,
        },
        validateErrorMessages: {
            guestId: '',
            baseUnitAmount: '',
            comment: '',
        },
        spendingTypes: [],
        allSpendingTypes: [],
        spendingType: null
    }

    componentDidMount() {}

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const { payDoc } = this.props;

        if (payDoc.id) {
            this.setState({
                id: payDoc.id,
                businessUnitName: (payDoc.businessUnit) ? payDoc.businessUnit.name : null,
                businessUnitId: payDoc.businessUnit?.id,
                guestId: payDoc.guestId,
                baseUnitAmount: (payDoc.balance > 0) ? payDoc.balance : 0,
                amountOfMoney: this.getRecalculatedMoney(),
            });

            if (!this.state.refundMethod) {
                getHistoryBalance(payDoc.id).then(this.saveRefundMethod);
            }
        }
    }

    getRecalculatedMoney(baseUnitAmountForRecalculate = 0) {
        const { payDoc } = this.props
        const { baseUnitAmount } = this.state

        if (baseUnitAmountForRecalculate)
            return ( payDoc.mod ) ? baseUnitAmountForRecalculate * payDoc.mod : 0
        else
            return ( payDoc.mod ) ? baseUnitAmount * payDoc.mod : 0
    }

    validateForm() {

        const validateSettings = this.validateSettings;

        let validated = true;
        let errorFields = [];
        let baseErrorState = {
            validateErrorState: {
                ...this.state.validateErrorState
            },
            validateErrorMessages: {
                ...this.state.validateErrorMessages
            }
        }

        // validate empty values
        _.forEach(
            validateSettings,
            _.bind(function(el, key) {
                if (_.filter(el, { state: 'empty' }).length > 0) {
                    if (!this.state[key]) {
                        errorFields.push({ name: key, state: 'empty' });
                        validated  = false;
                    }
                }
            }, this)
        )

        if (errorFields.length > 0) {
            _.forEach(errorFields, (el) => {
                baseErrorState.validateErrorState[el.name] = true;
                baseErrorState.validateErrorMessages[el.name] = _.filter(validateSettings[el.name], { state: el.state })[0].message;
            });

            this.setState(baseErrorState);
        }

        return validated;
    }

    saveRefundMethod = result => {
        // gel last "in" balance movement
        if (result.data.length > 0) {
            _.forEachRight(
                result.data,
                _.bind( val => {
                    if (val.tranId === 'in' && val.refillPayments.length > 0) {
                        this.setState({
                            refundMethod: val.refillPayments[0].type,
                        }, () => {

                            getSpendingTypes({ search: '16.' })
                                .then(response => {
                                    if (response.success) {
                                        this.setState({
                                            allSpendingTypes: response.data
                                        }, () => { this.filterSpendingTypes() });
                                    }
                                });

                        });
                        return false;
                    }
                }, this)
            )
        }
    }

    filterSpendingTypes = () => {
        const { allSpendingTypes, refundMethod } = this.state;

        let refundMethodSearchText = this.paymentsTypeTitles[refundMethod];

        this.setState({
            spendingTypes: _.filter(allSpendingTypes, function(spendingType){
                return spendingType.name.indexOf(refundMethodSearchText) !== -1;
            })
        });

    }

    handleChangeRefundMethod = prop => event => {
        this.setState({
            refundMethod: event.target.value,
            spendingType: null
        }, () => {
            this.filterSpendingTypes()
        });
    }

    handleChange = prop => event => {

        const { t } = this.props;

        if (prop === 'baseUnitAmount') {
            if (
                !/^\d*$/.test(event.target.value)
                || parseInt(event.target.value) > this.props.payDoc.balance
            )
            {
                this.setState({
                    wrongAmountError: true,
                    wrongAmountErrorText: t('Wrong amount,not be more then ') + this.props.payDoc.balance,
                })

                return;
            } else {
                if (this.state.wrongAmountError)
                    this.setState({
                        wrongAmountError: false,
                        wrongAmountErrorText: '',
                    })
            }

            this.setState({
                amountOfMoney: this.getRecalculatedMoney(event.target.value)
            })
        }

        // clear before send validate errors
        if (this.state.validateErrorState[prop])
            this.setState({
                validateErrorState: {
                    ...this.state.validateErrorState,
                    [prop]: false
                },
                validateErrorMessages: {
                    ...this.state.validateErrorMessages,
                    [prop]: ''
                }
            })

        this.setState({
            [prop]: event.target.value,
        });
    };

    handleClose = ok => event => {

        const { onClose, t } = this.props;

        if (!ok || ok === 'close') {
            onClose && onClose(false);
            return;
        }

        if (!this.validateForm())
            return;

        this.setState({
            updating: true,
        });

        this.sendData = {
            id: this.state.id,
            businessUnitId: this.state.businessUnitId,
            guestId: this.state.guestId,
            baseUnitAmount: this.state.baseUnitAmount,
            amountOfMoney: this.state.amountOfMoney,
            refundMethod: this.state.refundMethod,
            comment: this.state.comment,
            spendingType: this.state.spendingType
        }

        refundMoney(this.sendData)
            .then(response => {
                this.setState({
                    updating: false,
                });

                if (response.success) {
                    this.props.showInfo(t('Refund completed'));
                    onClose && onClose(ok);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { open, t, payDoc } = this.props;
        const {
            updating, businessUnitName, guestId, baseUnitAmount, refundMethod, wrongAmountError,
            wrongAmountErrorText, comment, validateErrorMessages, validateErrorState, spendingTypes, spendingType,
            amountOfMoney
        } = this.state;

        return <Dialog
            open={open}
            onClose={this.handleClose('close')}
            aria-labelledby="recharge-dialog-title"
            aria-describedby="recharge-dialog-description"
            maxWidth="md"
            disableBackdropClick={updating}
            disableEscapeKeyDown={updating}
        >
            <DialogTitle id="recharge-dialog-title">{ t('Money back') }</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AnyField
                            label={t('Parlour')}
                            value={businessUnitName}
                            fullWidth
                            readOnly={true}
                        >
                        </AnyField>
                    </Grid>
                    <Grid item xs={6}>
                        <GuestField
                            label={t("Guest")}
                            value={guestId}
                            error={ !guestId || validateErrorState.comment }
                            onChange={this.handleChange('guestId')}
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <AnyField
                            label={(payDoc.type.measure === 'time') ? t('payDoc.amountHours') : t('payDoc.amountMoney')}
                            value={baseUnitAmount}
                            error={ wrongAmountError || validateErrorState.baseUnitAmount }
                            onChange={this.handleChange('baseUnitAmount')}
                            fullWidth
                            helperText={ wrongAmountErrorText || validateErrorMessages.baseUnitAmount }
                        >
                        </AnyField>
                    </Grid>
                    <Grid item xs={2}>
                        <AnyField
                            label={t("payDoc.moneyWillBeSpend")}
                            value={amountOfMoney}
                            fullWidth
                        >
                        </AnyField>
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            label={t('Refund method')}
                            value={ refundMethod }
                            fullWidth
                            select
                            onChange={this.handleChangeRefundMethod()}
                        >
                            {Object.keys(this.paymentsTypeTitles).map((type) => (
                                <MenuItem key={type} value={type}>
                                    {this.paymentsTypeTitles[type]}
                                </MenuItem>
                            ))}
                        </AnyField>
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            label={t('Refund spending type')}
                            value={spendingType}
                            onChange={this.handleChange('spendingType')}
                            fullWidth
                            select
                        >
                            {spendingTypes.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </AnyField>
                    </Grid>
                    <Grid item xs={12}>
                        <AnyField
                            label={t('Comment')}
                            value={comment}
                            error={ !comment || validateErrorState.comment }
                            helperText={validateErrorMessages.comment}
                            fullWidth
                            multiline
                            rows={4}
                            onChange={this.handleChange('comment')}
                        >
                        </AnyField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={this.handleClose(true)}
                    color="primary"
                >{t('Approve refund')}</Button>
                <Button
                    onClick={this.handleClose('close')} color="primary">{t("Close")}</Button>
            </DialogActions>
        </Dialog>;
    }
}