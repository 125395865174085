import React from "react";
import { propTypeMoment } from "../../utils/moment-utils";
import withStyles from "@material-ui/core/styles/withStyles";
import Moment from "moment";
import { extendMoment } from "moment-range";
import * as PropTypes from "prop-types";
import * as cn from "classnames";
import {
    getCalendarEmployees,
    saveActivityEmployee,
    saveSlotEvent,
    activityTypes,
    getActivityTypeColor,
    activityTypeClear,
    activityTypeEdit,
    checkActivities,
    removeActivityEmployee,
    getDayWorkTime,
    saveApproved,
    activityTypeSplit,
    getEmployeePostOrder,
    activityTypeNames,
    addActivityEmployee,
    checkTimeEmployee,
    getCalendarEmployeeBreakTypes,
    createMomentToTime,
} from "../../services/calendarEmployee";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import infoActions from "../../components/info/info-actions";
import socketActions from "../../socket/socket-actions";
import NotApproved from "./activityStatus/NotApproved";
import Approved from "./activityStatus/Approved";
import { withRouter } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, DialogActions, Typography } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import ShowField from "../../components/fields/ShowField";
import TimeField from "../../components/fields/TimeField";
import { getParlours } from "../../services/organization";
import AnyField from "../../components/fields/AnyField";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import SplitDialog from "../../components/dialogs/SplitDialog";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import { getParlourTimes } from "../../services/calendar";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import { createChangeHandle } from "../../utils/helpers";
import {
    workingHours,
    workingHoursType,
} from "../../entities/enums/workingHours";

const moment = extendMoment(Moment);

const colorEvent = "rgba(115,115,115,0.51)";
const action = {
    save: "save",
    saveEvent: "saveEvent",
    remove: "remove",
};

const POPUP_SPLIT = "split";

export default
@withStyles(theme => ({
    beginGroup: {
        textAlign: "center",
        paddingTop: 1,
    },
    groupTotal: {
        fontWeight: 500,
    },
    columnHeader: {
        textAlign: "center",
        borderRight: "1px solid #777",
        position: "relative",
        fontSize: theme.typography.body2.fontSize,
    },
    columnHeaderDateCurrent: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    columnHeaderEvent: {
        textAlign: "center",
        borderRight: "1px solid #777",
        borderTop: "1px solid #777",
        position: "relative",
        cursor: "pointer",
        "&:hover": {
            boxShadow: "inset 0 0 3px rgba(0,0,0,0.5)",
        },
    },
    columnHeaderFirst: {
        textAlign: "center",
        position: "relative",
    },
    columnsHeaderContainer: {
        flex: "1 0 0%",
        display: "flex",
        position: "relative",
    },
    container: {
        position: "relative",
        border: "1px solid #777",
    },
    rightContainer: {
        display: "flex",
    },
    reportCardContainer: {
        display: "flex",
    },
    reportCardContainerHeader: {
        textAlign: "center",
        borderTop: "1px solid #777",
        borderRight: "1px solid #777",
        padding: 8,
    },
    dayBlockSlot: {
        position: "relative",
    },
    columnSlot: {
        textAlign: "center",
        borderRight: "1px solid #777",
        cursor: "pointer",
        position: "relative",
        "&:hover": {
            background: "rgba(115,115,115,0.51)",
        },
        display: "flex",
    },
    activity: {
        borderRight: "1px solid #777",
        boxSizing: "border-box",
        position: "relative",
        cursor: "pointer",

        "&:hover": {
            backgroundColor: "#ffa0e7",
        },
    },
    columnSlotShade: {
        width: "100%",
        height: "100%",
        backgroundColor: "#777",
        opacity: 0.2,
        position: "absolute",
        top: 0,
        left: 0,
    },
    otherParlourName: {
        fontSize: "0.7rem",
        position: "absolute",
        left: 0,
        top: 0,
        display: "inline-block",
    },
}))
@connect(
    state => ({
        socketMessages: state.socket.messages,
    }),
    {
        removeMessages: socketActions.removeMessages,
        showMessage: messageDialogActions.show,
        showError: infoActions.showError,
        showConfirm: confirmDialogActions.show,
    }
)
@withRouter
@withTranslation()
class Timesheets extends React.Component {
    static propTypes = {
        slotHeight: PropTypes.number,
        columnWidth: PropTypes.number,
        currentDate: propTypeMoment,
        parlour: PropTypes.object,
        employees: PropTypes.object.isRequired,
        isAdmin: PropTypes.bool,
        approved: PropTypes.bool,
        saveApproved: PropTypes.bool,
        periodNewActivities: PropTypes.object,
        toggleFillSchedule: PropTypes.bool,
        refresh: PropTypes.bool,
        employeeFilter: PropTypes.string,
        workingHour: PropTypes.string,
    };

    static defaultProps = {
        slotHeight: 22,
        columnWidth: 130,

        isAdmin: false,
        approved: false,
        saveApproved: false,
        periodNewActivities: {},
        refresh: false,

        parlour: null,
        employeeFilter: "",
    };

    constructor(props) {
        super(props);

        const calendarEmployeeMessages = props.socketMessages.filter(
            message => message.entity === "calendarEmployee"
        );

        if (calendarEmployeeMessages.length) {
            props.removeMessages(
                calendarEmployeeMessages.map(message => message.id)
            );
        }

        this.state = {
            week: [],
            activities: {},
            durationWork: 720,
            activitiesApprove: [],
            splitDialogOpen: false,
            addActivityDialogOpen: false,
            selectedActivity: null,
            selectedEmployee: null,
            selectedDay: null,
            selectedDayStart: null,
            selectedShiftId: null,
            newActivity: {
                start: null,
                end: null,
                comment: null,
                breakType: null,
            },
            parlours: [],
            //TODO: Подумать над сортировкой
            employeesGroups: Timesheets.getGroups(props.employees),
            breakTypes: [],
            toggleFillSchedule: false,
            confirmFill: null,
        };
    }

    static getGroups(employees) {
        return _.sortBy(_.keys(employees), group =>
            getEmployeePostOrder(group)
        );
    }

    componentDidMount() {
        this.selectWeek(this.props.currentDate);
        getParlours().then(result => {
            if (result.success) {
                this.setState({
                    parlours: result.data,
                });
            }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.employees !== nextProps.employees) {
            this.setState({
                employeesGroups: Timesheets.getGroups(nextProps.employees),
            });
        }
        if (this.props.currentDate !== nextProps.currentDate) {
            this.selectWeek(nextProps.currentDate);
        }

        if (
            this.props.parlour !== nextProps.parlour ||
            this.props.refresh !== nextProps.refresh
        ) {
            this.loadParams(nextProps.parlour);
        }

        if (this.props.approved === false && nextProps.approved === true) {
            this.createActivitiesApprove();
        }

        if (
            this.props.saveApproved === false &&
            nextProps.saveApproved === true
        ) {
            this.saveApproved();
        }

        if (nextProps.toggleFillSchedule !== this.props.toggleFillSchedule) {
            this.fillSchedule();
        }

        if (nextProps.socketMessages !== this.props.socketMessages) {
            nextProps.socketMessages.forEach(message => {
                if (message.entity && message.entity === "calendarEmployee") {
                    let { activities } = this.state;
                    let employeeActivities, employee;

                    for (let itemEmployee in message.entityData) {
                        employee = itemEmployee;
                        employeeActivities = message.entityData[itemEmployee];
                    }

                    if (!employeeActivities) {
                        return;
                    }

                    let date = moment(employeeActivities.timeStart).format(
                        "YYYY.MM.DD"
                    );

                    switch (message.action) {
                        case action.save:
                            if (activities[employee]) {
                                if (activities[employee][date]) {
                                    const foundIndex = activities[employee][
                                        date
                                    ].findIndex(
                                        act => act.id === employeeActivities.id
                                    );
                                    if (foundIndex >= 0) {
                                        activities[employee][date][foundIndex] =
                                            {
                                                ...employeeActivities,
                                            };
                                    } else {
                                        activities[employee][date].push({
                                            ...employeeActivities,
                                        });
                                    }
                                } else {
                                    activities[employee][date] = [
                                        { ...employeeActivities },
                                    ];
                                }
                            } else {
                                activities[employee] = {};
                                activities[employee][date] = [
                                    { ...employeeActivities },
                                ];
                            }

                            break;

                        case action.remove:
                            if (
                                activities[employee] &&
                                activities[employee][date] &&
                                Array.isArray(activities[employee][date])
                            ) {
                                let activitiesDate = [];

                                activities[employee][date].forEach(activity => {
                                    if (activity.id !== employeeActivities.id) {
                                        activitiesDate.push({ ...activity });
                                    }
                                });

                                if (activitiesDate.length) {
                                    activities[employee][date] = [
                                        ...activitiesDate,
                                    ];
                                } else {
                                    delete activities[employee][date];
                                }
                            }

                            break;

                        default:
                            break;
                    }

                    this.props.removeMessages([message.id]);

                    this.setState({
                        activities: activities,
                    });
                }
            });
        }
    }

    createActivitiesApprove() {
        const { activities } = this.state;
        let activitiesApprove = [];

        for (let employee in activities) {
            if (employee === "events") {
                continue;
            }

            for (let date in activities[employee]) {
                activities[employee][date].forEach(item => {
                    if (!item.approved) {
                        activitiesApprove.push(item);
                    }
                });
            }
        }

        this.setState({
            activitiesApprove: activitiesApprove,
        });
    }

    saveApproved() {
        const { activitiesApprove } = this.state;

        saveApproved(activitiesApprove).then(response => {
            if (response.success) {
                this.props.clearApprove();
                this.loadParams(this.props.parlour.id);
            }
        });
    }

    selectWeek(currentDate) {
        this.setState(
            {
                week: Array.from(moment(currentDate).range("month").by("days")),
            },
            () => this.loadParams(this.state.parlour)
        );
    }

    loadParams(parlour) {
        const { week } = this.state;

        if (week.length < 1) {
            return;
        }

        const timeStartDuration = getParlourTimes(parlour, week[0]).startTime;
        const timeEndDuration = getParlourTimes(
            parlour,
            week[week.length - 1]
        ).endTime;
        const timeZone = parlour ? parlour.timeZone : 0;
        const request = {
            timeStart: week[0]
                .clone()
                .utcOffset(timeZone, true)
                .startOf("day")
                .add(timeStartDuration)
                .toISOString(),
            timeEnd: week[week.length - 1]
                .clone()
                .utcOffset(timeZone, true)
                .startOf("day")
                .add(timeEndDuration)
                .toISOString(),
            parlour: parlour ? parlour.id : null,
        };

        getCalendarEmployees(request).then(response => {
            if (response.success) {
                let activities = {};

                response.data.forEach(dataItem => {
                    _.forOwn(dataItem, (activity, employee) => {
                        const timeStart = moment(activity.timeStart).format(
                            "YYYY.MM.DD"
                        );
                        if (activities[employee]) {
                            if (activities[employee][timeStart]) {
                                activities[employee][timeStart].push(activity);
                            } else {
                                activities[employee][timeStart] = [activity];
                            }
                        } else {
                            activities[employee] = {};
                            activities[employee][timeStart] = [activity];
                        }
                    });
                });

                this.setState({
                    activities: activities,
                });
            }
        });

        getCalendarEmployeeBreakTypes().then(response => {
            if (response.success) {
                this.setState({
                    breakTypes: response.data,
                });
            }
        });
    }

    renderEmployees() {
        const { employees, classes, slotHeight, columnWidth, parlour } =
            this.props;
        const numberShift =
            parlour &&
            parlour.workShift &&
            Array.isArray(parlour.workShift) &&
            parlour.workShift.length > 0
                ? parlour.workShift.length
                : 1;
        const { employeesGroups } = this.state;

        const style = {
            height: slotHeight * numberShift,
            width: 150,
            minWidth: columnWidth,
            borderTop: "1px solid #777",
            fontSize: 14,
            overflow: "hidden",
        };

        const totalRows = ["Человек в смене", "Часов в смене"];

        const result = [];

        employeesGroups.forEach(groupEmployee => {
            let filteredEmployees = employees[groupEmployee].filter(
                this.filterEmployees
            );

            if (filteredEmployees.length > 0) {
                result.push(
                    <div
                        key={groupEmployee}
                        style={{
                            height: 31,
                            width: 150,
                            minWidth: columnWidth,
                            borderTop: "1px solid #777",
                        }}
                    ></div>
                );

                result.push(
                    filteredEmployees.map(employee => (
                        <Tooltip title={employee.name} key={employee.id}>
                            <div
                                className={classes.beginGroup}
                                style={{
                                    ...style,
                                    backgroundColor:
                                        parlour &&
                                        employee.mainBusinessUnitId ===
                                            parlour.id
                                            ? "#9ac2e6"
                                            : null,
                                }}
                            >
                                {employee.name}
                            </div>
                        </Tooltip>
                    ))
                );

                totalRows.forEach(title => {
                    result.push(
                        <div
                            key={`${groupEmployee} - ${title}`}
                            className={cn(
                                classes.beginGroup,
                                classes.groupTotal
                            )}
                            style={style}
                        >
                            {title}
                        </div>
                    );
                });
            }
        });

        return result;
    }

    filterEmployees = e => {
        const { employeeFilter } = this.props;

        return (
            !employeeFilter ||
            e.name.toLowerCase().includes(employeeFilter.toLowerCase())
        );
    };

    renderReportCard() {
        const { employees, parlour } = this.props;
        const { employeesGroups } = this.state;

        const result = [];
        employeesGroups.forEach(groupEmployee => {
            result.push(
                employees[groupEmployee]
                    .filter(this.filterEmployees)
                    .map((employee, id) =>
                        parlour
                            ? this.renderReportCardSlot(employee, id)
                            : this.renderReportCardSlotForEmployeesFromAllBusinessUnits(
                                  employee,
                                  id
                              )
                    )
            );
            if (parlour) {
                result.push(this.renderAmountEmployees(groupEmployee));
                result.push(this.renderAmountHours(groupEmployee));
            }
        });

        return result;
    }

    renderAmountEmployees(groupEmployee) {
        const { slotHeight, columnWidth } = this.props;

        const style = {
            height: slotHeight,
            width: columnWidth,
            minWidth: columnWidth,
            borderTop: "1px solid #777",
            borderRight: "1px solid #777",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        };

        const countPeopleShifts = this.getAmountEmployees(groupEmployee);

        return (
            <div key={Math.random()} style={{ display: "flex" }}>
                {countPeopleShifts.map(count => {
                    return (
                        <div key={Math.random()} style={style}>
                            {count}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderAmountHours(groupEmployee) {
        const amountHours = this.getAmountHours(groupEmployee);
        const { slotHeight, columnWidth } = this.props;

        const style = {
            height: slotHeight,
            width: columnWidth,
            minWidth: columnWidth,
            borderTop: "1px solid #777",
            borderRight: "1px solid #777",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        };

        return (
            <div key={Math.random()} style={{ display: "flex" }}>
                {amountHours.map(count => {
                    return (
                        <div key={Math.random()} style={style}>
                            {count}
                        </div>
                    );
                })}
            </div>
        );
    }

    getAmountEmployees(groupEmployee) {
        const { employees } = this.props;
        const { state } = this;

        return state.week.map(day => {
            return employees[groupEmployee]
                .map(empl => {
                    let dayActs = this.getDayActivities(day, empl.id) ?? [];
                    return dayActs && dayActs.length > 0
                        ? dayActs
                              .filter(act => (act.activity === activityTypes.working ||
                                      act.activity === activityTypes.internship) &&
                                      this.props.parlour.id === act.parlour.id)
                              .map(act => act.activity)
                        : [];
                })
                .map(emplAct => _.uniq(emplAct).length)
                .reduce((sumWork, isWork) => sumWork + isWork, 0);
        });
    }

    getAmountHours(groupEmployee) {
        const { t, employees } = this.props;
        const { state } = this;

        return state.week
            .map(day => {
                return employees[groupEmployee].map(empl => {
                    let dayActs = this.getDayActivities(day, empl.id) ?? [];
                    return dayActs && dayActs.length > 0
                        ? dayActs
                              .filter(
                                  act =>
                                      (act.activity === activityTypes.working ||
                                      act.activity === activityTypes.break ||
                                          act.activity === activityTypes.internship) &&
                                      this.props.parlour.id === act.parlour.id
                              )
                              .map(act => act.duration)
                              .reduce((sumDur, dur) => sumDur + dur, 0)
                        : 0;
                });
            })
            .map((durations) => {
                const totalMinutes = durations.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                const durMins = moment.duration(totalMinutes, "minutes");
                const hours = Math.floor(durMins.asHours());
                const mins = Math.floor(durMins.asMinutes()) - hours * 60;

                return mins !== 0
                    ? `${hours}` + t("hours") + ` ${mins}` + t("mins")
                    : `${hours}` + t("hours");
            });
    }

    handleSlotClick = (employeeId, day, activity, shiftId) => event => {

        const {
            parlour,
            activityType,
            approved,
            t,
            replaceMode,
            isAdmin,
            workingHour,
        } = this.props;
        let { activities, breakTypes } = this.state;
        if (activityType === null) {
            this.props.showError(t("CalendarEmployee.SelectTool"));
        }

        if (activityType === activityTypes.waiting) {
            this.props.showMessage('Внимание, данная активность используется только для “Листа ожидания” убедитесь что данная активность будет установлена у технического мастера для “Листа ожидания”');
        }

        if (activities.events && activities.events[day.format("YYYY.MM.DD")]) {
            let eventActivities = activities.events[day.format("YYYY.MM.DD")][0]
                .event.activities
                ? activities.events[day.format("YYYY.MM.DD")][0].event
                      .activities
                : null;

            if (Array.isArray(eventActivities)) {
                eventActivities.forEach(activity => {
                    if (activity === activityType) {
                        this.props.showMessage({
                            message:
                                "Установленное событие не предусматривает данную активность",
                            title: "Предупреждение",
                        });
                    }
                });
            }
        }

        if (
            [
                activityTypes.working,
                activityTypes.waiting,
                activityTypes.leave,
                activityTypes.sick_leave,
                activityTypes.internship,
                activityTypes.transfer
            ].indexOf(activityType) !== -1
        ) {
            //установка рабочего дня, отпуска, больничного
            if (!activity || replaceMode) {
                let activityEmployee;

                if (!activity) {
                    if (
                        activities[employeeId] &&
                        activities[employeeId][day.format("YYYY.MM.DD")] &&
                        activities[employeeId][day.format("YYYY.MM.DD")]
                            .length > 0
                    ) {
                        this.props.showError(t("CalendarEmployee.BusyDay"));
                        return;
                    }

                    let { dayTimeStart, durationWork } = getDayWorkTime(
                        day,
                        this.props.parlour,
                        shiftId
                    );

                    if (workingHour !== workingHoursType.fulltime) {
                        const wh = workingHours.find(
                            wh => wh.id === workingHour
                        );

                        dayTimeStart = dayTimeStart.hour(wh.hour);
                        durationWork = wh.duration;
                    }

                    activityEmployee = {
                        employees: [employeeId],
                        duration: durationWork,
                        activity: activityTypes[`${activityType}`],
                        parlour: parlour.id,
                        timeStart: dayTimeStart.toISOString(),
                        shift: shiftId,
                        approved: !!isAdmin,
                    };
                } else {
                    activityEmployee = {
                        ...activity,
                        employees: [activity.employee.id],
                        parlour: activity.parlour.id,
                        activity: activityTypes[`${activityType}`],
                        approved: !!isAdmin,
                    };
                }

                if (this.props.employees.master &&
                    this.props.employees.master.find(m => m.id === employeeId) &&
                    activityEmployee?.activity === activityTypes.internship) {

                    this.props.showError(`Невозможно поставить инструмент стажировка для мастера`);
                    return;
                }

                saveActivityEmployee(activityEmployee).then(response => {
                    if (!response.success) {
                        this.props.showMessage({
                            message: response.error
                                ? response.error.message
                                : response.message,
                            title: "Ошибка создания табеля",
                        });
                    }
                });
            } else {
                this.props.showError(t("CalendarEmployee.TimeOccupied"));
            }
        } else if (activityType === activityTypeClear) {
            //удаление активности
            const dayActivities =
                activities[employeeId] &&
                activities[employeeId][day.format("YYYY.MM.DD")];
            let removeActivity;

            if (activity && activity.id) {
                removeActivity = dayActivities.find(
                    item => item.id === activity.id
                );
            } else {
                removeActivity =
                    dayActivities && dayActivities.length && dayActivities[0];
            }

            if (removeActivity) {
                removeActivityEmployee(removeActivity.id).then(response => {
                    if (!response.success) {
                        this.props.showMessage({
                            message: response.error
                                ? response.error.message
                                : response.message,
                            title: "Ошибка удаления активности",
                        });
                    }
                });
            }
        } else if (activityType === activityTypeSplit && activity) {
            const { dayTimeStart } = getDayWorkTime(day, this.props.parlour);

            //перевод в другой салон
            this.setState({
                splitDialogOpen: true,
                selectedActivity: activity,
                selectedDay: day,
                selectedDayStart: dayTimeStart,
            });
        } else if (
            activityType === activityTypes.break &&
            activity &&
            [activityTypes.overtime, activityTypes.break].indexOf(
                activity.activity
            ) === -1
        ) {
            //установка перерыва
            this.setState({
                addActivityDialogOpen: true,
                selectedActivity: activity,
                selectedEmployee: employeeId,
                selectedDay: day,
                selectedShift: shiftId,
                newActivity: {
                    ...this.state.newActivity,
                    start: this.getTime(day),
                    end: this.getTime(day),
                    breakType: breakTypes[1].id,
                },
            });
        } else if (activityType === activityTypes.overtime) {
            if (!activity) {
                //установка сверхурочных часов
                this.setState({
                    addActivityDialogOpen: true,
                    selectedActivity: null,
                    selectedDay: day,
                    selectedEmployee: employeeId,
                    newActivity: {
                        ...this.state.newActivity,
                        start: this.getTime(day),
                        end: this.getTime(day),
                    },
                });
            } else if (activity && replaceMode) {
                let activityEmployee = {
                    ...activity,
                    employees: [activity.employee.id],
                    parlour: activity.parlour.id,
                    activity: activityTypes[`${activityType}`],
                    approved: !!isAdmin,
                };

                saveActivityEmployee(activityEmployee).then(response => {
                    if (!response.success) {
                        this.props.showMessage({
                            message: response.error
                                ? response.error.message
                                : response.message,
                            title: "Ошибка создания табеля",
                        });
                    }
                });
            } else {
                this.props.showError(t("CalendarEmployee.TimeOccupied"));
            }
        } else if (approved && activity && activity.id) {
            let { activitiesApprove } = this.state;

            let filterActivitiesApprove = activitiesApprove.filter(
                item => !(item.id === activity.id)
            );

            this.setState({
                activitiesApprove: filterActivitiesApprove,
            });
        } else if (activityType === activityTypeEdit) {
            // редактирование активности
            if (activity) {
                this.setState({
                    addActivityDialogOpen: true,
                    selectedActivity: Object.assign(activity),
                    selectedDay: day,
                    selectedEmployee: employeeId,
                    newActivity: {
                        start: moment(activity.timeStart).utcOffset(
                            parlour.timeZone
                        ),
                        end: moment(activity.timeStart)
                            .utcOffset(parlour.timeZone)
                            .add(activity.duration, "minute"),
                    },
                });
            } else {
                this.props.showError(t("CalendarEmployee.NothingToEditHere"));
            }
        }

        event.stopPropagation();
    };

    handleSlotEventClick = day => () => {
        const { eventSelected } = this.props;

        if (eventSelected && eventSelected.id) {
            let { activities } = this.state;
            let date = day.format("YYYY.MM.DD");

            if (eventSelected.id === "clear") {
                if (activities.events && activities.events[date]) {
                    let id = activities.events[date][0].id;

                    removeActivityEmployee(id).then(response => {
                        if (!response.success) {
                            this.props.showMessage({
                                message: response.error
                                    ? response.error.message
                                    : response.message,
                                title: "Ошибка удаления события",
                            });
                        }
                    });
                }
            } else {
                if (activities.events && activities.events[`${date}`]) {
                    this.props.showMessage({
                        message:
                            "Необходимо сначала удалить запланированое событие",
                        title: "Предупреждение",
                    });

                    return;
                }

                const { durationWork } = getDayWorkTime(
                    day,
                    this.props.parlour
                );
                const { parlour } = this.props;

                let eventParams = {
                    employees: null,
                    duration: durationWork,
                    activity: null,
                    parlour: parlour.id,
                    timeStart: day.toISOString(),
                    event: eventSelected.id,
                };

                saveSlotEvent(eventParams).then(response => {
                    if (response.success) {
                        const newEvent = response.data.events;
                        const date = moment(newEvent.timeStart).format(
                            "YYYY.MM.DD"
                        );

                        if (activities.events) {
                            if (activities.events[date]) {
                                activities.events[date].push(newEvent);
                            } else {
                                activities.events[date] = [newEvent];
                            }
                        } else {
                            activities.events = {};
                            activities.events[date] = [newEvent];
                        }

                        this.setState({
                            activities: activities,
                        });
                    }
                });
            }
        } else {
            this.props.showError("Не выбрано ни одно событие!");
        }
    };

    renderReportCardSlot(employee, id) {
        const { week, activitiesApprove } = this.state;
        const {
            classes,
            slotHeight,
            columnWidth,
            parlour,
            currentDate,
            isAdmin,
            approved,
        } = this.props;
        const today = moment();

        const style = {
            height: slotHeight,
            width: columnWidth,
            minWidth: columnWidth,
            borderTop: "1px solid #777",
        };

        return (
            <React.Fragment key={id}>
                {id ? null : (
                    <div
                        style={{ height: 31 }}
                        className={classes.reportCardContainerHeader}
                    >
                        {employee.employeePostText}
                    </div>
                )}
                <div key={id} className={classes.reportCardContainer}>
                    {week.map((day, id) => {
                        let employeeId = employee.id;
                        let dayActivities = this.getDayActivities(
                            day,
                            employeeId
                        );
                        return (
                            <div key={id} className={classes.dayBlockSlot}>
                                {parlour.workShift &&
                                Array.isArray(parlour.workShift) &&
                                parlour.workShift.length > 0 ? (
                                    parlour.workShift.map((shift, i) => {
                                        let result = [];
                                        let shiftStart = createMomentToTime(
                                            shift.start
                                        );
                                        let shiftEnd = createMomentToTime(
                                            shift["end"]
                                        );

                                        if (dayActivities) {
                                            let activity = dayActivities.find(
                                                activity =>
                                                    !!(
                                                        (activity.shift &&
                                                            activity.shift
                                                                .id ===
                                                                shift.id) ||
                                                        (activity.shift ===
                                                            null &&
                                                            i === 0)
                                                    )
                                            );

                                            let activityAnotherSalonStart,
                                                activityAnotherSalonEnd;

                                            const activityAnotherSalon =
                                                dayActivities.find(activity => {
                                                    if (
                                                        activity.parlour.id !==
                                                        parlour.id
                                                    ) {
                                                        activityAnotherSalonStart =
                                                            createMomentToTime(
                                                                activity.shift
                                                                    .start
                                                            );
                                                        activityAnotherSalonEnd =
                                                            createMomentToTime(
                                                                activity.shift
                                                                    .end
                                                            );

                                                        return true;
                                                    }

                                                    return false;
                                                });

                                            if (activity) {
                                                result.push(
                                                    <div
                                                        key={i}
                                                        className={
                                                            classes.columnSlot
                                                        }
                                                        onClick={
                                                            day.isAfter(
                                                                today
                                                            ) || isAdmin
                                                                ? this.handleSlotClick(
                                                                      employeeId,
                                                                      day,
                                                                      activity,
                                                                      shift.id
                                                                  )
                                                                : null
                                                        }
                                                        style={
                                                            activity
                                                                ? {
                                                                      ...style,
                                                                      backgroundColor:
                                                                          getActivityTypeColor(
                                                                              activity.activity
                                                                          ),
                                                                  }
                                                                : style
                                                        }
                                                    >
                                                        {activity.duration
                                                            ? activity.duration /
                                                              60
                                                            : null}
                                                        {!activity.approved &&
                                                        !approved ? (
                                                            <NotApproved />
                                                        ) : null}
                                                        {approved &&
                                                        !activity.approved &&
                                                        activitiesApprove.find(
                                                            item =>
                                                                item.id ===
                                                                activity.id
                                                        ) ? (
                                                            <Approved />
                                                        ) : null}
                                                    </div>
                                                );
                                            } else if (activityAnotherSalon) {
                                                result.push(
                                                    <div
                                                        key={i}
                                                        className={
                                                            classes.columnSlot
                                                        }
                                                        onClick={
                                                            day.isAfter(
                                                                currentDate
                                                            ) || isAdmin
                                                                ? this.handleSlotClick(
                                                                      employeeId,
                                                                      day,
                                                                      activityAnotherSalon,
                                                                      shift.id
                                                                  )
                                                                : null
                                                        }
                                                        style={{
                                                            ...style,
                                                            backgroundColor:
                                                                getActivityTypeColor(
                                                                    activityAnotherSalon.activity,
                                                                    false
                                                                ),
                                                        }}
                                                    >
                                                        {activityAnotherSalon &&
                                                        ((shiftStart.isSameOrBefore(
                                                            activityAnotherSalonStart
                                                        ) &&
                                                            shiftEnd.isSameOrAfter(
                                                                activityAnotherSalonStart
                                                            )) ||
                                                            (shiftStart.isSameOrBefore(
                                                                activityAnotherSalonEnd
                                                            ) &&
                                                                shiftEnd.isSameOrAfter(
                                                                    activityAnotherSalonEnd
                                                                ))) ? (
                                                            <div
                                                                className={
                                                                    classes.otherParlourName
                                                                }
                                                            >
                                                                {
                                                                    activityAnotherSalon
                                                                        .parlour
                                                                        .name
                                                                }
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                );
                                            }
                                        } else {
                                            result.push(
                                                <div
                                                    key={i}
                                                    className={
                                                        classes.columnSlot
                                                    }
                                                    onClick={this.handleSlotClick(
                                                        employeeId,
                                                        day,
                                                        null,
                                                        shift.id
                                                    )}
                                                    style={{
                                                        ...style,
                                                        backgroundColor:
                                                            day.isBefore(
                                                                today.startOf(
                                                                    "day"
                                                                )
                                                            )
                                                                ? "#d1d1d1"
                                                                : "#fff",
                                                    }}
                                                ></div>
                                            );
                                        }

                                        return result;
                                    })
                                ) : (
                                    <div
                                        className={classes.columnSlot}
                                        onClick={
                                            day.isAfter(currentDate) || isAdmin
                                                ? this.handleSlotClick(
                                                      employeeId,
                                                      day,
                                                      null
                                                  )
                                                : null
                                        }
                                        style={{
                                            ...style,
                                            backgroundColor: day.isBefore(
                                                today.startOf("day")
                                            )
                                                ? "#d1d1d1"
                                                : "#fff",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {Array.isArray(dayActivities)
                                            ? dayActivities.map(
                                                  (
                                                      activity,
                                                      numberActivity
                                                  ) => {
                                                      const timeStart = moment(
                                                          activity.timeStart
                                                      ).utcOffset(
                                                          activity.parlour
                                                              .timeZone
                                                      );
                                                      const timeEnd = timeStart
                                                          .clone()
                                                          .add(
                                                              activity.duration,
                                                              "minute"
                                                          );
                                                      const { dayTimeStart } =
                                                          getDayWorkTime(
                                                              day,
                                                              this.props.parlour
                                                          );
                                                      const timeEndPrevious =
                                                          numberActivity > 0
                                                              ? moment(
                                                                    dayActivities[
                                                                        numberActivity -
                                                                            1
                                                                    ].timeStart
                                                                ).add(
                                                                    dayActivities[
                                                                        numberActivity -
                                                                            1
                                                                    ].duration,
                                                                    "minutes"
                                                                )
                                                              : null;
                                                      const diffTime =
                                                          (timeEndPrevious &&
                                                              timeStart.diff(
                                                                  timeEndPrevious,
                                                                  "minutes"
                                                              )) ||
                                                          (numberActivity ===
                                                              0 &&
                                                              timeStart.diff(
                                                                  dayTimeStart,
                                                                  "minutes"
                                                              ) > 0 &&
                                                              timeStart.diff(
                                                                  dayTimeStart,
                                                                  "minutes"
                                                              ));

                                                      return (
                                                          <Tooltip
                                                              key={activity.id}
                                                              title={
                                                                  <span>
                                                                      {timeStart.format(
                                                                          "HH:mm"
                                                                      )}{" "}
                                                                      -{" "}
                                                                      {timeEnd.format(
                                                                          "HH:mm"
                                                                      )}
                                                                      <br />
                                                                      {
                                                                          activity
                                                                              .creator
                                                                              .username
                                                                      }
                                                                      {activity.activity ===
                                                                      activityTypes.break ? (
                                                                          <React.Fragment>
                                                                              <br />{" "}
                                                                              {
                                                                                  activity.comment
                                                                              }
                                                                          </React.Fragment>
                                                                      ) : null}
                                                                  </span>
                                                              }
                                                          >
                                                              <div
                                                                  key={
                                                                      activity.id
                                                                  }
                                                                  className={
                                                                      classes.activity
                                                                  }
                                                                  style={{
                                                                      width: this.getActivityWidth(
                                                                          dayActivities,
                                                                          activity,
                                                                          day
                                                                      ),
                                                                      backgroundColor:
                                                                          getActivityTypeColor(
                                                                              activity.activity,
                                                                              activity
                                                                                  .parlour
                                                                                  .id ===
                                                                                  parlour.id
                                                                          ),
                                                                      marginLeft:
                                                                          diffTime
                                                                              ? this.getIntervalWidth(
                                                                                    diffTime
                                                                                )
                                                                              : null,
                                                                      borderLeft:
                                                                          diffTime
                                                                              ? "1px solid #777"
                                                                              : "0",
                                                                  }}
                                                                  onClick={this.handleSlotClick(
                                                                      employeeId,
                                                                      day,
                                                                      activity
                                                                  )}
                                                              >
                                                                  {activity
                                                                      .parlour
                                                                      .id !==
                                                                  parlour.id ? (
                                                                      <div
                                                                          className={
                                                                              classes.otherParlourName
                                                                          }
                                                                      >
                                                                          {
                                                                              activity
                                                                                  .parlour
                                                                                  .name
                                                                          }
                                                                      </div>
                                                                  ) : null}
                                                                  {approved &&
                                                                  !activity.approved &&
                                                                  activitiesApprove.find(
                                                                      item =>
                                                                          item.id ===
                                                                          activity.id
                                                                  ) ? (
                                                                      <Approved />
                                                                  ) : null}
                                                              </div>
                                                          </Tooltip>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }

    renderReportCardSlotForEmployeesFromAllBusinessUnits(employee, id) {
        const { week, activitiesApprove, parlours } = this.state;
        const { classes, slotHeight, columnWidth, approved } = this.props;
        const today = moment();
        const employeeMainParlour = parlours.find(
            p => p.id === employee.mainBusinessUnitId
        );

        const style = {
            height: slotHeight,
            width: columnWidth,
            minWidth: columnWidth,
            borderTop: "1px solid #777",
        };

        return (
            <React.Fragment key={id}>
                {id ? null : (
                    <div
                        style={{ height: 31 }}
                        className={classes.reportCardContainerHeader}
                    >
                        {employee.employeePostText}
                    </div>
                )}
                <div key={id} className={classes.reportCardContainer}>
                    {week.map((day, id) => {
                        let employeeId = employee.id;
                        let dayActivities = this.getDayActivities(
                            day,
                            employeeId
                        );

                        return (
                            <div key={id} className={classes.dayBlockSlot}>
                                {employeeMainParlour &&
                                employeeMainParlour.workShift &&
                                Array.isArray(employeeMainParlour.workShift) &&
                                employeeMainParlour.workShift.length > 0 ? (
                                    employeeMainParlour.workShift.map(
                                        (shift, i) => {
                                            let result = [];
                                            let shiftStart = createMomentToTime(
                                                shift.start
                                            );
                                            let shiftEnd = createMomentToTime(
                                                shift["end"]
                                            );

                                            if (dayActivities) {
                                                let activity =
                                                    dayActivities.find(
                                                        activity =>
                                                            !!(
                                                                (activity.shift &&
                                                                    activity
                                                                        .shift
                                                                        .id ===
                                                                        shift.id) ||
                                                                (activity.shift ===
                                                                    null &&
                                                                    i === 0)
                                                            )
                                                    );

                                                let activityAnotherSalonStart,
                                                    activityAnotherSalonEnd;

                                                const activityAnotherSalon =
                                                    dayActivities.find(
                                                        activity => {
                                                            if (
                                                                activity.parlour
                                                                    .id !==
                                                                employeeMainParlour.id
                                                            ) {
                                                                activityAnotherSalonStart =
                                                                    createMomentToTime(
                                                                        activity
                                                                            .shift
                                                                            .start
                                                                    );
                                                                activityAnotherSalonEnd =
                                                                    createMomentToTime(
                                                                        activity
                                                                            .shift
                                                                            .end
                                                                    );

                                                                return true;
                                                            }

                                                            return false;
                                                        }
                                                    );

                                                if (activity) {
                                                    result.push(
                                                        <div
                                                            key={i}
                                                            className={
                                                                classes.columnSlot
                                                            }
                                                            style={
                                                                activity
                                                                    ? {
                                                                          ...style,
                                                                          backgroundColor:
                                                                              getActivityTypeColor(
                                                                                  activity.activity
                                                                              ),
                                                                      }
                                                                    : style
                                                            }
                                                        >
                                                            {activity.duration
                                                                ? activity.duration /
                                                                  60
                                                                : null}
                                                            {!activity.approved &&
                                                            !approved ? (
                                                                <NotApproved />
                                                            ) : null}
                                                            {approved &&
                                                            !activity.approved &&
                                                            activitiesApprove.find(
                                                                item =>
                                                                    item.id ===
                                                                    activity.id
                                                            ) ? (
                                                                <Approved />
                                                            ) : null}
                                                            <div
                                                                className={
                                                                    classes.otherParlourName
                                                                }
                                                            >
                                                                {
                                                                    activity
                                                                        .parlour
                                                                        .name
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                } else if (
                                                    activityAnotherSalon
                                                ) {
                                                    result.push(
                                                        <div
                                                            key={i}
                                                            className={
                                                                classes.columnSlot
                                                            }
                                                            style={{
                                                                ...style,
                                                                backgroundColor:
                                                                    getActivityTypeColor(
                                                                        activityAnotherSalon.activity,
                                                                        false
                                                                    ),
                                                            }}
                                                        >
                                                            {activityAnotherSalon &&
                                                            ((shiftStart.isSameOrBefore(
                                                                activityAnotherSalonStart
                                                            ) &&
                                                                shiftEnd.isSameOrAfter(
                                                                    activityAnotherSalonStart
                                                                )) ||
                                                                (shiftStart.isSameOrBefore(
                                                                    activityAnotherSalonEnd
                                                                ) &&
                                                                    shiftEnd.isSameOrAfter(
                                                                        activityAnotherSalonEnd
                                                                    ))) ? (
                                                                <div
                                                                    className={
                                                                        classes.otherParlourName
                                                                    }
                                                                >
                                                                    {
                                                                        activityAnotherSalon
                                                                            .parlour
                                                                            .name
                                                                    }
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    );
                                                }
                                            } else {
                                                result.push(
                                                    <div
                                                        key={i}
                                                        className={
                                                            classes.columnSlot
                                                        }
                                                        style={{
                                                            ...style,
                                                            backgroundColor:
                                                                day.isBefore(
                                                                    today.startOf(
                                                                        "day"
                                                                    )
                                                                )
                                                                    ? "#d1d1d1"
                                                                    : "#fff",
                                                        }}
                                                    ></div>
                                                );
                                            }

                                            return result;
                                        }
                                    )
                                ) : (
                                    <div
                                        className={classes.columnSlot}
                                        style={{
                                            ...style,
                                            backgroundColor: day.isBefore(
                                                today.startOf("day")
                                            )
                                                ? "#d1d1d1"
                                                : "#fff",
                                        }}
                                    >
                                        {Array.isArray(dayActivities)
                                            ? dayActivities.map(
                                                  (
                                                      activity,
                                                      numberActivity
                                                  ) => {
                                                      const timeStart = moment(
                                                          activity.timeStart
                                                      ).utcOffset(
                                                          activity.parlour
                                                              .timeZone
                                                      );
                                                      const timeEnd = timeStart
                                                          .clone()
                                                          .add(
                                                              activity.duration,
                                                              "minute"
                                                          );
                                                      const { dayTimeStart } =
                                                          getDayWorkTime(
                                                              day,
                                                              activity.parlour
                                                          );
                                                      const timeEndPrevious =
                                                          numberActivity > 0
                                                              ? moment(
                                                                    dayActivities[
                                                                        numberActivity -
                                                                            1
                                                                    ].timeStart
                                                                ).add(
                                                                    dayActivities[
                                                                        numberActivity -
                                                                            1
                                                                    ].duration,
                                                                    "minutes"
                                                                )
                                                              : null;
                                                      const diffTime =
                                                          (timeEndPrevious &&
                                                              timeStart.diff(
                                                                  timeEndPrevious,
                                                                  "minutes"
                                                              )) ||
                                                          (numberActivity ===
                                                              0 &&
                                                              timeStart.diff(
                                                                  dayTimeStart,
                                                                  "minutes"
                                                              ) > 0 &&
                                                              timeStart.diff(
                                                                  dayTimeStart,
                                                                  "minutes"
                                                              ));

                                                      return (
                                                          <Tooltip
                                                              key={activity.id}
                                                              title={
                                                                  <span>
                                                                      {timeStart.format(
                                                                          "HH:mm"
                                                                      )}{" "}
                                                                      -{" "}
                                                                      {timeEnd.format(
                                                                          "HH:mm"
                                                                      )}
                                                                      <br />
                                                                      {
                                                                          activity
                                                                              .creator
                                                                              .username
                                                                      }
                                                                      {activity.activity ===
                                                                      activityTypes.break ? (
                                                                          <React.Fragment>
                                                                              <br />{" "}
                                                                              {
                                                                                  activity.comment
                                                                              }
                                                                          </React.Fragment>
                                                                      ) : null}
                                                                  </span>
                                                              }
                                                          >
                                                              <div
                                                                  key={
                                                                      activity.id
                                                                  }
                                                                  className={
                                                                      classes.activity
                                                                  }
                                                                  style={{
                                                                      width: this.getActivityWidth(
                                                                          dayActivities,
                                                                          activity,
                                                                          day,
                                                                          activity.parlour
                                                                      ),
                                                                      backgroundColor:
                                                                          getActivityTypeColor(
                                                                              activity.activity,
                                                                              employeeMainParlour
                                                                                  ? activity
                                                                                        .parlour
                                                                                        .id ===
                                                                                        employeeMainParlour.id
                                                                                  : false
                                                                          ),
                                                                      marginLeft:
                                                                          diffTime
                                                                              ? this.getIntervalWidth(
                                                                                    diffTime
                                                                                )
                                                                              : null,
                                                                      borderLeft:
                                                                          diffTime
                                                                              ? "1px solid #777"
                                                                              : "0",
                                                                  }}
                                                              >
                                                                  <div
                                                                      className={
                                                                          classes.otherParlourName
                                                                      }
                                                                  >
                                                                      {
                                                                          activity
                                                                              .parlour
                                                                              .name
                                                                      }
                                                                  </div>
                                                                  {approved &&
                                                                  !activity.approved &&
                                                                  activitiesApprove.find(
                                                                      item =>
                                                                          item.id ===
                                                                          activity.id
                                                                  ) ? (
                                                                      <Approved />
                                                                  ) : null}
                                                              </div>
                                                          </Tooltip>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }

    renderShade = () => {
        const { classes } = this.props;

        return <div className={classes.columnSlotShade}></div>;
    };

    getWorkHours = () => {
        const { t, employees } = this.props;
        const { state } = this;

        return state.employeesGroups.map(eg => {
            return employees[eg]
                .filter(this.filterEmployees)
                .map(empl => {
                    return state.week
                        .map(day => {
                            let dayActs =
                                this.getDayActivities(day, empl.id) ?? [];
                            return dayActs && dayActs.length > 0
                                ? dayActs
                                      .filter(act => {
                                          return (
                                              act.activity === activityTypes.working ||
                                              act.activity === activityTypes.break ||
                                              act.activity === activityTypes.internship
                                          );
                                      })
                                      .reduce((sumMins, act) => {
                                          return sumMins + act.duration
                                      }, 0)
                                : 0;
                        })
                        .reduce((sumMins, mins) => {
                            return sumMins + mins;
                        }, 0);
                })
                .map(workMins => {
                    const durMins = moment.duration(workMins, "minutes");
                    const hours = Math.floor(durMins.asHours());
                    const mins = Math.floor(durMins.asMinutes()) - hours * 60;

                    return mins !== 0
                        ? `${hours}` + t("hours") + ` ${mins}` + t("mins")
                        : `${hours}` + t("hours");
                });
        });
    };

    render() {
        const { classes, slotHeight, columnWidth, currentDate, isAdmin } =
            this.props;

        const {
            week,
            activities,
            splitDialogOpen,
            selectedActivity,
            parlours,
            selectedDayStart,
        } = this.state;

        const style = {
            height: slotHeight,
            width: columnWidth,
            minWidth: columnWidth,
        };

        let events = activities.events ? activities.events : null;
        let today = moment();

        return (
            <div style={{ display: "flex" }}>
                <div className={classes.container}>
                    <div
                        className={classes.columnHeaderFirst}
                        style={{ ...style, minWidth: 150, width: 150 }}
                    >
                        &nbsp;
                    </div>

                    <div
                        className={classes.columnHeaderFirst}
                        style={{ ...style, minWidth: 150, width: 150 }}
                    >
                        &nbsp;
                    </div>
                    {this.renderEmployees()}
                </div>
                <div
                    className={classes.container}
                    style={{ overflowX: "scroll", overflowY: "hidden" }}
                >
                    <div className={classes.columnsHeaderContainer}>
                        {week.map((day, id) => {
                            return (
                                <div
                                    key={id}
                                    className={cn(classes.columnHeader, {
                                        [classes.columnHeaderDateCurrent]:
                                            currentDate.isSame(day, "day"),
                                    })}
                                    style={style}
                                >
                                    {day.format("DD.MM.YYYY (dd)")}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.columnsHeaderContainer}>
                        {week.map((day, id) => {
                            let itemEvent =
                                events && events[day.format("YYYY.MM.DD")]
                                    ? events[day.format("YYYY.MM.DD")]
                                    : null;
                            let eventName = itemEvent
                                ? itemEvent[0].event.name
                                : "";

                            return (
                                <div
                                    key={id}
                                    className={classes.columnHeaderEvent}
                                    style={
                                        eventName
                                            ? {
                                                  ...style,
                                                  background: colorEvent,
                                              }
                                            : style
                                    }
                                    onClick={
                                        isAdmin
                                            ? this.handleSlotEventClick(day)
                                            : null
                                    }
                                >
                                    {eventName}
                                    {today.isAfter(day)
                                        ? this.renderShade()
                                        : null}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.reportCardContainer}>
                        <div>{this.renderReportCard()}</div>
                    </div>
                    <SplitDialog
                        splitDialogOpen={splitDialogOpen}
                        parlours={parlours}
                        selectedActivity={selectedActivity}
                        selectedDayStart={selectedDayStart}
                        closeDialog={this.closeDialog}
                    />
                    {this.renderAddActivityDialog()}
                </div>
                <div className={classes.container}>
                    <div className={classes.columnsHeaderContainer}>
                        <div style={{ ...style, textAlign: "center" }}>
                            Кол-во часов
                        </div>
                    </div>
                    <div className={classes.columnsHeaderContainer}>
                        <div style={style}></div>
                    </div>
                    <div className={classes.reportCardContainer}>
                        <div>{this.renderWorkHours()}</div>
                    </div>
                </div>
            </div>
        );
    }

    closeDialog = name => {
        switch (name) {
            case POPUP_SPLIT:
                this.setState({
                    splitDialogOpen: false,
                    selectedActivity: null,
                });
                break;

            default:
                break;
        }
    };

    renderWorkHours() {
        const { classes, slotHeight, columnWidth, parlour } = this.props;
        const numberShift =
            parlour &&
            parlour.workShift &&
            Array.isArray(parlour.workShift) &&
            parlour.workShift.length > 0
                ? parlour.workShift.length
                : 1;

        const style = {
            height: slotHeight * numberShift,
            width: 70,
            minWidth: columnWidth,
            borderTop: "1px solid #777",
            fontSize: 14,
            overflow: "hidden",
        };

        const result = [];
        const workHours = this.getWorkHours() ?? [];

        workHours.forEach(whg => {
            result.push(
                <div
                    key={Math.random()}
                    style={{
                        height: 31,
                        width: 70,
                        minWidth: columnWidth,
                        borderTop: "1px solid #777",
                    }}
                ></div>
            );

            whg.forEach(wh => {
                result.push(
                    <div
                        key={Math.random()}
                        className={classes.beginGroup}
                        style={{
                            ...style,
                            borderLeftColor: "transparent",
                            borderRightColor: "transparent",
                        }}
                    >
                        {wh}
                    </div>
                );
            });

            result.push(
                <div
                    key={Math.random()}
                    className={classes.beginGroup}
                    style={{
                        border: "1px solid transparent",
                        color: "transparent",
                        height: slotHeight,
                    }}
                >

                </div>
            );
            result.push(
                <div
                    key={Math.random()}
                    className={classes.beginGroup}
                    style={{
                        border: "1px solid transparent",
                        color: "transparent",
                        height: slotHeight,
                    }}
                >

                </div>
            );
        });

        return result;
    }

    renderAddActivityDialog() {
        const { parlour, activityType, t } = this.props;
        const {
            addActivityDialogOpen,
            selectedActivity,
            newActivity,
            breakTypes,
        } = this.state;
        const timeStart = selectedActivity
            ? moment(selectedActivity.timeStart).utcOffset(parlour.timeZone)
            : null;
        const timeEnd = selectedActivity
            ? moment(selectedActivity.timeStart)
                  .add(selectedActivity.duration, "minute")
                  .utcOffset(parlour.timeZone)
            : null;

        return (
            <Dialog
                open={addActivityDialogOpen}
                maxWidth="xs"
                fullWidth
                onClose={this.handleAddActivityDialogCancel}
            >
                <DialogTitle>{this.getTitleAddActivityDialog()}</DialogTitle>
                <DialogContent>
                    {selectedActivity ? (
                        <React.Fragment>
                            <div>
                                <Typography variant="body2">
                                    {selectedActivity.employee.employeePostText}
                                    : {selectedActivity.employee.name}
                                </Typography>
                            </div>
                            <ShowField fullWidth label="Выбранный блок">
                                {timeStart.format("HH:mm")}-
                                {timeEnd.format("HH:mm")}
                            </ShowField>
                        </React.Fragment>
                    ) : null}
                    <TimeField
                        label="Начиная с"
                        value={newActivity.start}
                        name="start"
                        onChange={this.handleChangeTime}
                        fullWidth
                        minutesStep={15}
                    />
                    <TimeField
                        label="До"
                        value={newActivity.end}
                        name="end"
                        onChange={this.handleChangeTime}
                        fullWidth
                        minutesStep={15}
                    />
                    {selectedActivity &&
                    activityType === activityTypes.break ? (
                        <React.Fragment>
                            <AnyField
                                label={t("Calendar.comment")}
                                value={newActivity.comment}
                                fullWidth
                                multiline
                                rows="4"
                                name="comment"
                                onChange={this.handleChange}
                            />
                            <SelectFromItemsField
                                label="Тип перерыва"
                                value={newActivity.breakType}
                                name="breakType"
                                fullWidth
                                onChange={this.handleChange}
                                items={breakTypes}
                                textField="text"
                            />
                        </React.Fragment>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleAddActivityDialogSuccess}
                        color="primary"
                    >
                        OK
                    </Button>
                    <Button
                        onClick={this.handleAddActivityDialogCancel}
                        color="primary"
                    >
                        {t("Cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange = createChangeHandle(this, "newActivity");
    handleChangeTime = (time, name) => {
        const { parlour } = this.props;
        const { selectedDay, newActivity } = this.state;

        if (time) {
            time.utcOffset(parlour.timeZone, true);
            time.set({
                year: selectedDay.get("year"),
                month: selectedDay.get("month"),
                date: selectedDay.get("date"),
                second: 0,
                millisecond: 0,
            });
        }

        this.setState({
            newActivity: {
                ...newActivity,
                [name]: time,
            },
        });
    };

    handleAddActivityDialogCancel = () => {
        this.setState({
            addActivityDialogOpen: false,
        });
    };

    handleAddActivityDialogSuccess = () => {
        const { selectedActivity, newActivity, selectedEmployee, selectedDay } =
            this.state;
        const { parlour, activityType, t } = this.props;

        const timeStart =
            (selectedActivity &&
                activityType === activityTypes.break &&
                moment(selectedActivity.timeStart)) ||
            createMomentToTime(
                parlour.workTime[selectedDay.isoWeekday()].start,
                selectedDay,
                parlour.timeZone
            );
        const timeEnd =
            (selectedActivity &&
                activityType === activityTypes.break &&
                moment(selectedActivity.timeStart).add(
                    selectedActivity.duration,
                    "minutes"
                )) ||
            createMomentToTime(
                parlour.workTime[selectedDay.isoWeekday()].finish,
                selectedDay,
                parlour.timeZone
            );

        if (timeEnd.isBefore(timeStart)) {
            timeEnd.add(1, "days");
        }

        if (newActivity.end.isBefore(newActivity.start)) {
            newActivity.end.add(1, "days");
        }

        if (
            newActivity.start.isSameOrAfter(newActivity.end) ||
            newActivity.start.isBefore(timeStart) ||
            newActivity.start.isAfter(timeEnd) ||
            newActivity.end.isBefore(timeStart) ||
            newActivity.end.isAfter(timeEnd)
        ) {
            this.props.showError(
                t("CalendarEmployee.TheTimeIntervalIsNotSetCorrectly")
            );

            return;
        }

        if (selectedActivity && activityType !== activityTypeEdit) {
            //проверка и сохранение перерыва
            checkTimeEmployee(
                newActivity.start.toISOString(),
                newActivity.end.toISOString(),
                selectedEmployee
            ).then(result => {
                if (result.success) {
                    if (result.data.result) {
                        addActivityEmployee(
                            {
                                ...selectedActivity,
                                timeStart,
                                timeEnd,
                            },
                            newActivity,
                            parlour,
                            { id: selectedEmployee }
                        );
                    } else {
                        this.props.showMessage({
                            message: t(
                                "CalendarEmployee.ThereAreScheduledVisits"
                            ),
                            title: t("CalendarEmployee.Attention"),
                        });
                    }
                } else {
                    this.props.showMessage({
                        message: t("System error"),
                        title: t("System error"),
                    });
                }
            });
        } else {
            let activityEmployee;

            if (selectedActivity) {
                const dayActivities = this.getDayActivities(
                    selectedDay,
                    selectedEmployee
                );

                if (dayActivities && dayActivities.length > 1) {
                    for (let index in dayActivities) {
                        const activity = dayActivities[index];

                        if (activity.id !== selectedActivity.id) {
                            const timeActivityEnd = moment(
                                activity.timeStart
                            ).add(activity.duration);
                            const timeActivityStart = moment(
                                activity.timeStart
                            );

                            if (
                                (newActivity.start >= timeActivityStart &&
                                    newActivity.start < timeActivityEnd) ||
                                (newActivity.end > timeActivityStart &&
                                    newActivity.end <= timeActivityEnd) ||
                                (newActivity.start <= timeActivityStart &&
                                    newActivity.end >= timeActivityEnd)
                            ) {
                                this.props.showError(
                                    t("CalendarEmployee.TimeOccupied")
                                );
                                return;
                            }
                        }
                    }
                }

                activityEmployee = {
                    id: selectedActivity.id,
                    employees: [selectedActivity.employee.id],
                    duration: newActivity.end.diff(
                        newActivity.start,
                        "minutes"
                    ),
                    activity: activityTypes[selectedActivity.activity],
                    parlour: selectedActivity.parlour.id,
                    timeStart: newActivity.start.toISOString(),
                    approved: true,
                };
            } else {
                activityEmployee = {
                    employees: [selectedEmployee],
                    duration: newActivity.end.diff(
                        newActivity.start,
                        "minutes"
                    ),
                    activity: activityTypes[activityType],
                    parlour: parlour.id,
                    timeStart: newActivity.start.toISOString(),
                    approved: true,
                };
            }

            saveActivityEmployee(activityEmployee).then(response => {
                if (!response.success) {
                    this.props.showMessage({
                        message: response.error
                            ? response.error.message
                            : response.message,
                        title: "Ошибка создания табеля",
                    });
                }
            });
        }

        this.setState({
            addActivityDialogOpen: false,
            newActivity: {
                end: null,
                start: null,
                breakType: null,
                comment: null,
            },
        });
    };

    getActivityWidth = (dayActivities, activity, day, shiftId) => {
        const { durationWork } = getDayWorkTime(
            day,
            this.props.parlour ?? activity.parlour,
            shiftId
        );

        let activityWidth =
            activity.duration !== durationWork
                ? ((100 * activity.duration) / durationWork).toFixed(2)
                : 100;

        return `${activityWidth}%`;
    };

    getIntervalWidth = moveTime => {
        const { durationWork } = this.state;
        const width = Math.round((100 * moveTime) / durationWork);

        return `${width}%`;
    };

    getTitleAddActivityDialog = () => {
        const { activityType } = this.props;
        const { selectedActivity } = this.state;

        let result =
            activityType === activityTypeEdit && selectedActivity
                ? "Редактировать "
                : "Добавить ";
        result +=
            activityType === activityTypeEdit
                ? selectedActivity &&
                  activityTypeNames[selectedActivity.activity].toLowerCase()
                : activityTypeNames[activityType] &&
                  activityTypeNames[activityType].toLowerCase();

        return result;
    };

    getDayActivities = (day, employeeId) => {
        const { activities } = this.state;

        const date = day.format("YYYY.MM.DD");
        const employeeActivities = activities[employeeId]
            ? activities[employeeId]
            : null;

        return employeeActivities && employeeActivities[date]
            ? employeeActivities[date]
            : null;
    };

    /**
     * получаем время со значениями минут кратным 15
     * для адекватного отображения на сетке расписания
     */
    getTime = day => {
        const { parlour } = this.props;

        let time = moment().utcOffset(parlour.timeZone, true);
        let minutes = time.minutes();
        let addMinute = 0;

        while (!(minutes === 0 || (minutes + addMinute) % 15 === 0)) {
            addMinute++;
        }
        if (addMinute) {
            time.add(addMinute, "minute");
        }
        time.set({
            year: day.get("year"),
            month: day.get("month"),
            date: day.get("date"),
            second: 0,
            millisecond: 0,
        });

        return time;
    };

    fillSchedule = editedNewActivities => {
        let newActivities = [];
        const { parlour, periodNewActivities, t } = this.props;

        if (!editedNewActivities) {
            let countWeekends = periodNewActivities.weekends.length;
            let shift =
                periodNewActivities.workingDay && periodNewActivities.weekend;
            let itemWorkDay = periodNewActivities.workingDay;
            let itemWeekDay = periodNewActivities.weekend;

            for (
                let itemDay = periodNewActivities.from.clone();
                periodNewActivities.to.isSameOrAfter(itemDay);
                itemDay.add(1, "day")
            ) {
                if (
                    countWeekends &&
                    periodNewActivities.weekends.indexOf(
                        itemDay.isoWeekday()
                    ) !== -1
                ) {
                    continue;
                }

                if (shift) {
                    if (itemWorkDay > 0) {
                        itemWorkDay--;
                    } else if (itemWeekDay > 0) {
                        itemWeekDay--;
                        continue;
                    } else {
                        itemWorkDay = periodNewActivities.workingDay - 1;
                        itemWeekDay = periodNewActivities.weekend;
                    }
                }

                newActivities.push({
                    employees: [periodNewActivities.employee],
                    duration: periodNewActivities.timeEnd.diff(
                        periodNewActivities.timeStart,
                        "minutes"
                    ),
                    activity: periodNewActivities.activity,
                    parlour: parlour.id,
                    timeStart: moment()
                        .utcOffset(parlour.timeZone)
                        .set({
                            year: itemDay.get("year"),
                            month: itemDay.get("month"),
                            date: itemDay.get("date"),
                            hour: periodNewActivities.timeStart.get("hour"),
                            minute: periodNewActivities.timeStart.get("minute"),
                        })
                        .startOf("minutes")
                        .toISOString(),
                    shift: null,
                    approved: true,
                });
            }
        } else {
            newActivities = editedNewActivities;
        }

        checkActivities(newActivities).then(response => {
            if (response.success) {
                if (response.data.result) {
                    if (newActivities.length > 0) {
                        saveActivityEmployee(newActivities).then(response => {
                            if (!response.success) {
                                this.props.showMessage({
                                    message: response.error
                                        ? response.error.message
                                        : response.message,
                                    title: t(
                                        "CalendarEmployee.TimesheetFillingError"
                                    ),
                                });
                            }
                        });

                        this.setState({
                            confirmFill: null,
                        });
                    }
                }

                if (response.data[periodNewActivities.employee]) {
                    const activity =
                        response.data[periodNewActivities.employee];
                    const activityStart = moment(activity.timeStart).utcOffset(
                        parlour.timeZone
                    );
                    const activityEnd = activityStart
                        .clone()
                        .add(activity.duration, "minutes");

                    if (this.state.confirmFill === null) {
                        this.props.showConfirm({
                            message: (
                                <span>
                                    {activityStart.format("DD.MM.YYYY")}{" "}
                                    {t("CalendarEmployee.from")}{" "}
                                    {activityStart.format("HH:mm")}{" "}
                                    {t("CalendarEmployee.to")}{" "}
                                    {activityEnd.format("HH:mm")}{" "}
                                    {t("CalendarEmployee.detected")}:{" "}
                                    {activity.activityText}.{" "}
                                    {t("CalendarEmployee.WantToReplace")}?
                                </span>
                            ),
                            title: (
                                <span>
                                    {t(
                                        "CalendarEmployee.TimesheetFillingError"
                                    )}
                                </span>
                            ),
                            onClose: this.handleFillReport(
                                activity,
                                newActivities
                            ),
                            showYesAll: true,
                        });
                    } else {
                        this.handleFillReport(
                            activity,
                            newActivities
                        )(this.state.confirmFill);
                    }
                }
            }
        });
    };

    handleFillReport = (activity, newActivities) => (ok, comment, all) => {
        if (all) {
            this.setState({
                confirmFill: ok,
            });
        }

        if (ok) {
            removeActivityEmployee(activity.id).then(response => {
                if (!response.success) {
                    this.props.showMessage({
                        message: response.error
                            ? response.error.message
                            : response.message,
                        title: "Ошибка удаления активности",
                    });
                } else {
                    this.fillSchedule(newActivities);
                }
            });
        } else {
            const activityStart = moment(activity.timeStart).utcOffset(0);
            const activityEnd = moment(activity.timeStart)
                .add(activity.duration, "minutes")
                .utcOffset(0);
            newActivities = newActivities.filter(
                item =>
                    !(
                        moment(item.timeStart).isBetween(
                            activityStart,
                            activityEnd,
                            null,
                            "[]"
                        ) ||
                        moment(item.timeStart)
                            .add(item.duration, "minutes")
                            .isBetween(activityStart, activityEnd, null, "[]")
                    )
            );

            this.fillSchedule(newActivities);
        }
    };
}
