import React from 'react';
import {
    getMoveRequests,
    navLinks
} from "../../services/goods";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {withRouter} from "react-router";

const columnData = [
    { id: 'num', label: 'Номер запроса' },
    { id: 'createdAt', label: 'Дата создания запроса', dateFormat: 'DD.MM.YYYY' },
    { id: 'creator.username', label: 'Пользователь, создавший запрос' },
    { id: 'creator.phone', label: 'Телефон пользователя, создавшего запрос' },
    { id: 'parlour.name', label: 'Салон отправитель' },
    { id: 'newParlour.name', label: 'Салон получатель' },
    { id: 'statusText', label: 'Статус запроса' },
];

@withRouter
class MoveListPage extends React.Component {

    state = {
        search: '',
        refreshTable: false,
    };
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickSearch = () => {
        this.setState({
            refreshTable: !this.state.refreshTable,
        })
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getMoveRequests({search}, ...params);
    };

    handleAdd = () => {
        this.props.history.push(`/goods/move/view/0`);
    };

    render() {
        const { search, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search}
                        onChange={this.handleChange('search')}
                        label="Поиск по запросам"
                        onClick={this.handleClickSearch}
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить новый запрос</ActionButton>
                    </LeftBar>
                <Content title="Запросы на перемещение товаров">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/goods/move/view/${item.id}`}
                        refresh={refreshTable}
                        order="desc"
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default MoveListPage;
