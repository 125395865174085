import {dataTableGroupValueStrategy} from "../../../../../services/common";

const incomeVisits = (currentVal, row) => {
    return {
        visit_cash: dataTableGroupValueStrategy.arrayMoneySum('visit_cash')(
            currentVal ? currentVal.visit_cash : null,
            row.income
        ),
        visit_cashless: dataTableGroupValueStrategy.arrayMoneySum('visit_cashless')(
            currentVal ? currentVal.visit_cashless : null,
            row.income
        ),
        visit_paydoc_own: dataTableGroupValueStrategy.arrayMoneySum('visit_paydoc_own')(
            currentVal ? currentVal.visit_paydoc_own : null,
            row.income
        ),
        visit_paydoc_other: dataTableGroupValueStrategy.arrayMoneySum('visit_paydoc_other')(
            currentVal ? currentVal.visit_paydoc_other : null,
            row.income
        ),
        visit_paydoc_frozen_own: dataTableGroupValueStrategy.arrayMoneySum('visit_paydoc_frozen_own')(
            currentVal ? currentVal.visit_paydoc_frozen_own : null,
            row.income
        ),
        visit_paydoc_frozen_other: dataTableGroupValueStrategy.arrayMoneySum('visit_paydoc_frozen_other')(
            currentVal ? currentVal.visit_paydoc_frozen_other : null,
            row.income
        ),
        details: dataTableGroupValueStrategy.appendArray('details')(
            currentVal ? currentVal.details: [],
            row.income
        ),
    };
};

export default incomeVisits;
