import React from "react"
import {Grid, IconButton, ListItemText, MenuItem, Popover} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/styles";
import {getBusinessUnitTypes, getRegions} from "../../services/organization";
import {connect} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import calendarActions from "./calendar-actions";
import {FilterList} from "@material-ui/icons";
import CheckboxField from "../../components/fields/CheckboxField";

const allowedBusinessUnitTypes = [
    "parlour",
    "external_parlour"
]

@connect((state) => ({
        businessUnitFilter: state.calendar.businessUnitFilter
    }),
    {
        setBusinessUnitFilter: calendarActions.setBusinessUnitFilter,
    }
)
@withTranslation()
@withStyles(() => ({
    popoverContainer: {
        width: "300px",
        height: "250px",
        margin:"18px",
        marginTop:"0px",
    }
}))

class ScheduleHeaderBusinessUnitFilter extends React.Component {

    state = {
        openFilterPopover: false,
        popoverAnchorEl: null,

        filter: {
            params: {
                regions: [],
                types:[]
            },
            enabled: {
                regions: false,
                types: false
            }
        },

        regions: [],
        businessUnitTypes: []
    }

    componentDidMount() {
        this.setState({
            filter: this.props.businessUnitFilter
        })
    }

    render() {
        return (
            <React.Fragment>
                <IconButton
                    onClick={this.openFilterPopover}
                >
                    <FilterList fontSize="small"/>
                </IconButton>

                {this.renderFilterPopover()}

            </React.Fragment>
        )
    }

    openFilterPopover = (event) => {

        const { regions, businessUnitTypes } = this.state

        if (!regions.length || !businessUnitTypes.length) {
            this.fetchFilterParams()
        }

        this.setState({
            openFilterPopover: true,
            popoverAnchorEl: event.currentTarget
        })
    }

    fetchFilterParams = () => {

        getRegions()
            .then((response) => {
                if (response.success) {
                    this.setState({regions: response.data})
                }
            })

        getBusinessUnitTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnitTypes: this.filterBusinessUnitTypes(response.data)
                    })
                }
            })
    }

    renderFilterPopover = () => {

        const {
            openFilterPopover,
            popoverAnchorEl,
            regions,
            businessUnitTypes,
            filter
        } = this.state

        const { t, classes } = this.props

        return <Popover
            open={openFilterPopover}
            anchorEl={popoverAnchorEl}
            onClose={() => this.setState({openFilterPopover: false, popoverAnchorEl: null})}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <Grid container className={classes.popoverContainer}>
                <Grid item xs={12}>
                    <CheckboxField
                        label={t("By region")}
                        value={filter.enabled.regions}
                        onChange={this.handleChangeFilterEnabled("regions")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AnyField
                        label={t("Regions")}
                        value={filter.params.regions}
                        onChange={this.handleChangeFilterParam('regions')}
                        disabled={!filter.enabled.regions}
                        fullWidth
                        select
                        SelectProps={{
                            multiple: true,
                            displayEmpty: true,
                            renderValue: selectedRegions => {
                                if (this.state.filter.params.regions.length === 0) {
                                    return t("All")
                                }

                                return this.state.regions
                                    .filter(region => selectedRegions.indexOf(region.id) > -1)
                                    .map(region => region.name)
                                    .join(", ")
                            }
                        }}
                    >
                        {regions.map(region => (
                            <MenuItem key={region.id} value={region.id}>
                                <Checkbox checked={this.state.filter.params.regions.indexOf(region.id) > -1} />
                                <ListItemText primary={region.name} />
                            </MenuItem>
                        ))}
                    </AnyField>
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField
                        label={t("By type")}
                        value={filter.enabled.types}
                        onChange={this.handleChangeFilterEnabled("types")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AnyField
                        label={t("Types")}
                        value={filter.params.types}
                        disabled={!filter.enabled.types}
                        onChange={this.handleChangeFilterParam("types")}
                        fullWidth
                        select
                        SelectProps={{
                            displayEmpty: true,
                            multiple: true,
                            renderValue: (selectedBusinessUnitTypes) => {
                                if (selectedBusinessUnitTypes.length === 0) {
                                    return t("All")
                                }

                                return this.state.businessUnitTypes
                                    .filter(type => selectedBusinessUnitTypes.indexOf(type.id) > -1)
                                    .map(type => type.text)
                                    .join(', ')
                            }
                        }
                        }
                    >
                        {businessUnitTypes.map(type =>
                            <MenuItem key={type.id} value={type.id}>
                                <Checkbox checked={this.state.filter.params.types.indexOf(type.id) > -1}/>
                                <ListItemText primary={type.text}/>
                            </MenuItem>
                        )}
                    </AnyField>
                </Grid>
            </Grid>
        </Popover>
    }

    handleChangeFilterParam = prop => event => {

        const value = event.target.value

        this.setState({
            filter: {
                    ...this.state.filter,
                    params:{
                        ...this.state.filter.params,
                        [prop]: value
                    }
                }
            },
    () => this.props.setBusinessUnitFilter(this.state.filter)
        )
    }

    handleChangeFilterEnabled = prop => event => {

        const value = event.target.value

        this.setState({
            filter: {
                ...this.state.filter,
                enabled:{
                    ...this.state.filter.enabled,
                    [prop]: value
                }
            }
        },
            () => this.props.setBusinessUnitFilter(this.state.filter))
    }

    filterBusinessUnitTypes = (types) => {
        return types.filter(type => allowedBusinessUnitTypes.indexOf(type.id) > -1)
    }
}

export default ScheduleHeaderBusinessUnitFilter