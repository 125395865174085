import {calendarActionTypes} from './calendar-actions';
import {saveCalendarBusinessUnitFilter, saveCalendarCurrentDate, saveHiddenStatuses} from "../../services/localStorage";
import moment from "moment";

const initialState = {
    currentDate: null,
    visits: [],
    hiddenStatuses: [],
    multiColumns: [],
    buffer: null,
    bufferCutOrCopy: null,
    showTransferredVisits: false,
    transferredVisits: [],
};

export default function calendarReducer(state = initialState, action) {
    switch (action.type) {
        case calendarActionTypes.SET_CURRENT_DATE:
            saveCalendarCurrentDate(moment.isMoment(action.date) ? action.date.format('YYYY-MM-DD') : action.date);
            return {
                ...state,
                currentDate: action.date,
            };
        case calendarActionTypes.SET_VISITS:
            return {
                ...state,
                visits: action.visits,
            };
        case calendarActionTypes.SET_HIDDEN_STATUSES:
            saveHiddenStatuses(action.statuses);
            return {
                ...state,
                hiddenStatuses: action.statuses,
            };
        case calendarActionTypes.SET_MULTI_COLUMNS:
            return {
                ...state,
                multiColumns: action.columns,
            }
        case calendarActionTypes.SET_BUFFER:
            return {
                ...state,
                buffer: action.visit,
                bufferCutOrCopy: action.cutOrCopy,
            }
        case calendarActionTypes.SET_TRANSFERRED_VISITS:
            return {
                ...state,
                transferredVisits: action.transferredVisits.map(v => ({...v, isTransferred: true})),
            }
        case calendarActionTypes.SET_SHOW_TRANSFERRED_VISITS:
            return {
                ...state,
                showTransferredVisits: action.showTransferredVisits,
            }
        case calendarActionTypes.SET_BUSINESS_UNIT_FILTER:
            saveCalendarBusinessUnitFilter(action.filter)

            return {
                ...state,
                businessUnitFilter: action.filter
            }
        default:
            return state;
    }
}