import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import {getDeletedTransactions, navLinks} from "../../services/moneyFlow";
import moment from "moment";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import {loadToState} from "../../utils/helpers";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import DatePeriodField from "../../components/fields/DatePeriodField";
import DateMonthField from "../../components/fields/DateMonthField";
import {getUsers} from "../../services/user";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import {getPaymentTypes} from "../../services/sale";
import { getDealReasonClassTypes, getBillParentClassTypes } from "../../services/common";

const dateTypes = {
    period: 'period',
    month: 'month'
}

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
});

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class DeletedTransactionsListPage extends React.Component {

    state = {
        search: {
            date_start: null,
            date_end: null,
            businessUnitIds: [],
            dateType: dateTypes.period,
            month: moment().startOf('month'),
            deletedByIds: [],
            paymentType: null,
            dealReasonClassTypeIds: [],
            fromBillParentClassTypeIds: [],
            toBillParentClassTypeIds: []
        },
        refreshTable: false,
        businessUnits: [],
        users: [],
        paymentTypes: [],
        dealReasonClassTypes: [],
        billParentClassTypes: []
    };

    columnData = [
        { id: 'deletedAt', label: 'Deleted at', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'deletedBy', label: 'Deleted by'},
        { id: 'createdAt', label: 'Created date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'reasonClass', label: 'Deal (entity)' },
        { id: 'reasonName', label: 'Deal name' },
        { id: 'amountFormat', label: 'Amount' },
        { id: 'fromParentClass', label: 'From (entity)' },
        { id: 'fromParentName', label: 'From name' },
        { id: 'fromBillType', label: 'From (bill)' },
        { id: 'toParentClass', label: 'To (entity)' },
        { id: 'toParentName', label: 'To name' },
        { id: 'toBillType', label: 'To (bill)' },
        { id: 'name', label: 'Name' },
        { id: 'statusText', label: 'Status' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'creatorName', label: 'User' },
        { id: 'comment', label: 'Comment' },
        { id: 'applyAt', label: 'Account for the month', dateFormat: 'MMMM YYYY'},
    ];

    columnDataXlsx = [
        { id: 'deletedAt', label: 'Deleted at', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'deletedBy', label: 'Deleted by'},
        { id: 'createdAt', label: 'Created date', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
        { id: 'reasonClass', label: 'Deal (entity)' },
        { id: 'reasonName', label: 'Deal name' },
        { id: 'soldText', label: 'Sold' },
        { id: 'amount.val', label: 'Amount' },
        { id: 'amount.cur', label: 'Currency' },
        { id: 'from.parentClassText', label: 'From (entity)' },
        { id: 'fromParentName', label: 'From name' },
        { id: 'from.typeText', label: 'From (bill)' },
        { id: 'to.parentClassText', label: 'To (entity)' },
        { id: 'toParentName', label: 'To name' },
        { id: 'to.typeText', label: 'To (bill)' },
        { id: 'name', label: 'Name' },
        { id: 'statusText', label: 'Status' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'creatorName', label: 'User' },
        { id: 'comment', label: 'Comment' },
        { id: 'applyAt', label: 'Account for the month', dateFormat: 'MMMM YYYY'},
    ];

    componentDidMount() {
        loadToState(this, getBusinessUnits(), 'businessUnits')
        loadToState(this, getUsers(), 'users')
        loadToState(this, getPaymentTypes(),'paymentTypes')
        loadToState(this, getDealReasonClassTypes(),'dealReasonClassTypes')
        loadToState(this, getBillParentClassTypes(),'billParentClassTypes')
    }

    handleChange = prop => event => {

        let value = null;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getDeletedTransactions({...search, deleted: true}, ...params);
    };

    handleExport = () => {
        const { search, businessUnits, bill } = this.state;
        const businessUnitName = businessUnits?.find(item => item.id === search.businessUnitId)?.name;
        const { t } = this.props;
        const from = moment.isMoment(search.date_start) ? search.date_start.format("YYYY-MM-DD") : search.date_start;
        const to = moment.isMoment(search.date_end) ? search.date_end.format("YYYY-MM-DD") : search.date_end;
        const bu = businessUnitName ? ` ${businessUnitName}` : '';
        const billName = search.billId && bill ? ` ${bill.parentName} ${bill.typeText}` : '';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => t(item.label) ),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => {
                                let value = _.get(row, item.id);

                                if (item.dateFormat) {
                                    value = moment(value).format(item.dateFormat);
                                }

                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Transactions");
                    XLSX.writeFile(new_workbook, `Transactions${bu}${billName} ${from}-${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    renderDateFilter = () => {
        const { search } = this.state
        const { classes, t } = this.props

        return <>
            <FormControl component="fieldset" fullWidth>
                <RadioGroup
                    value={search.dateType}
                    onChange={this.handleChange('dateType')}
                    name={'dateType'}
                    row
                >
                    <FormControlLabel control={<Radio/>} value={dateTypes.period} label="за период"/>
                    <FormControlLabel control={<Radio/>} value={dateTypes.month} label="за месяц"/>
                </RadioGroup>
            </FormControl>
            {
                search.dateType === dateTypes.period
                    ? <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={t("Deleted at")}
                        className={classes.filterControl}
                        required={false}
                      />
                    : <DateMonthField
                        value={search.month}
                        name={'month'}
                        onChange={this.handleChange('month')}
                        fullWidth
                      />
            }
        </>
    }

    render() {
        const { t } = this.props;
        const {
            refreshTable,
            search,
            businessUnits,
            users,
            dealReasonClassTypes,
            billParentClassTypes
        } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    {this.renderDateFilter()}
                    <MultiSelectFromItemsField
                        items={businessUnits}
                        nullable
                        nullableText={t('---All---')}
                        label={t('Business unit')}
                        value={search.businessUnitIds}
                        name="businessUnitIds"
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                        select
                    />
                    <MultiSelectFromItemsField
                        items={dealReasonClassTypes}
                        label={t('Deal (entity)')}
                        value={search.dealReasonClassTypeIds}
                        onChange={this.handleChange('dealReasonClassTypeIds')}
                        fullWidth
                        valueField={'id'}
                        textField={'text'}
                    />
                    <MultiSelectFromItemsField
                        items={billParentClassTypes}
                        label={t('From')}
                        value={search.fromBillParentClassTypeIds}
                        onChange={this.handleChange('fromBillParentClassTypeIds')}
                        fullWidth
                        textField={'text'}
                    />
                    <MultiSelectFromItemsField
                        items={billParentClassTypes}
                        label={t('To')}
                        value={search.toBillParentClassTypeIds}
                        onChange={this.handleChange('toBillParentClassTypeIds')}
                        fullWidth
                        textField={'text'}
                    />
                    <AutocompleteMultiSelectField
                        options={users}
                        label={t('User')}
                        value={search.deletedByIds}
                        onChange={this.handleChange('deletedByIds')}
                        fullWidth
                        valueField={'id'}
                        textField={'username'}
                        isClearable={true}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Deleted transactions')}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        disableSorting
                    />
                </Content>
            </React.Fragment>
        );
    }
}
