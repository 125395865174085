import React, { memo, useCallback, useState } from "react";
import types from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateDomRuPhone } from "../../../services/settings";
import infoActions from "../../../components/info/info-actions";
import AnyField from "../../../components/fields/AnyField";
import produce from "immer";
import CheckboxField from "../../../components/fields/CheckboxField";


function EditDialog({ data, onClose, onSubmit }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showError = useCallback((message) => dispatch(infoActions.showError(message)), [dispatch]);

    const [domRuPhone, setDomRuPhone] = useState(data || {});

    const handleChange = useCallback((e) => {
        const name = e.target.name;
        const value = e.target.value;

        setDomRuPhone(produce(draft => {
            draft[name] = value;
        }));
    }, []);

    const handleOk = useCallback(() => {
        updateDomRuPhone(domRuPhone)
            .then(response => {
                if (response.success) {
                    onSubmit();
                } else
                    showError(response?.error ? response.error.message : response?.message);
            });
    }, [onSubmit, showError, domRuPhone]);

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">{t("DomRu.DomRu phone")}</DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AnyField
                            label={t("DomRu.Phone number")}
                            value={domRuPhone.telnum}
                            name="telnum"
                            onChange={handleChange}
                            required
                            error={!domRuPhone.telnum}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            label={t("DomRu.Location")}
                            value={domRuPhone.location}
                            name="location"
                            onChange={handleChange}
                            required
                            error={!domRuPhone.location}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CheckboxField
                            label={t("DomRu.Is online-marketing phone")}
                            value={domRuPhone.online}
                            name="online"
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary"
                        disabled={!(domRuPhone.telnum && domRuPhone.location)}>Сохранить</Button>
                <Button onClick={onClose} color="primary">Отмена</Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    data: types.object,
    onClose: types.func,
    onSubmit: types.func,
};

export default memo(EditDialog);