import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import {getExternalSubjects, getExternalSubjectTypes, navLinks} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {withTranslation} from "react-i18next";
import HLine from "../../components/left-bar/HLine";

const columnData = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Название' },
    { id: 'typeText', numeric: false, disablePadding: false, label: 'Тип' },
    { id: 'juridicalPerson.shortName', numeric: false, disablePadding: false, label: 'Юридическое лицо' },
    { id: 'comment', numeric: false, disablePadding: false, label: 'Комментарий' },
];


@withTranslation()
class ExternalSubjectListPage extends React.Component {

    state = {
        search: {
            search: null
        },
        externalSubjectTypes: [],
        refreshTable: false
    };

    componentDidMount() {
        getExternalSubjectTypes()
            .then(response => {
                if(response.success) {
                    this.setState({
                        externalSubjectTypes: response.data
                    })
                }
            })
    }

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/organization/external-subjects/view/0`);
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getExternalSubjects(search, ...params);
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    render() {
        const {t} = this.props;
        const { search, refreshTable, externalSubjectTypes } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <SelectFromItemsField
                        label={t('Types')}
                        nullable={true}
                        nullValue={null}
                        nullableText={'All'}
                        value={search.type}
                        items={externalSubjectTypes}
                        onChange={this.handleChange('type')}
                        fullWidth
                        textField="text"
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <HLine/>
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Внешние субъекты">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/organization/external-subjects/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default ExternalSubjectListPage;
