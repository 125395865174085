import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getHumanEmployees, getJuridicalPersons} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getDebts, getSpendingTypes,
    navLinks,
    debtLevels
} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import XLSX from "xlsx";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import CheckboxField from "../../components/fields/CheckboxField";
import {getBusinessUnitByRole, hasRole} from "../../services/user";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import DateMonthField from "../../components/fields/DateMonthField";
import {FormControl, FormControlLabel,Radio, RadioGroup} from "@material-ui/core";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

export default
@connect(state => ({
    currentParlour: state.common.currentParlour,
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withTranslation()
class DebtListPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: null,
            date_end: null,
            month: null,
            dateType: 'period',
            businessUnitIds: [],
            employeeId: null,
            spendingTypesIds: [],
            unpaid: false,
            lvl: [],
            juridicalPersonIds: [],
            paymentTypes:[],
        },
        refreshTable: false,
        businessUnits: [],
        employees: [],
        spendingTypes: [],
        juridicalPersons:[],

    };

    columnData = [
        { id: 'amountFormat', label: 'Sum' },
        { id: 'applyAt', label: 'Debt apply', dateFormat: 'MMMM YYYY' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'currentAmountFormat', label: 'Current sum' },
        { id: 'reasonName', label: 'Debt name' },
        { id: 'lvl', label: 'Level' },
        { id: 'juridicalPersonName', label: 'Juridical person' },
        { id: 'comment', label: 'Comment',disableSorting:true },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'creatorName', label: 'Creator' },
    ];

    columnDataXlsx = [
        { id: 'amount.val', label: 'Sum' },
        { id: 'applyAt', label: 'Debt apply', dateFormat: 'MMMM YYYY' },
        { id: 'businessUnitName', label: 'Business unit' },
        { id: 'currentAmount.val', label: 'Current sum' },
        { id: 'reasonName', label: 'Debt name' },
        { id: 'juridicalPersonName', label: 'Juridical person' },
        { id: 'comment', label: 'Comment' },
        { id: 'createdAt', label: 'Created', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
        { id: 'creatorName', label: 'Creator' },
    ];

    componentDidMount() {
        getBusinessUnitByRole('ROLE_MONEYFLOW_DEBT_GET')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data
                    });
                }
            });

        getHumanEmployees({'canHaveMoney': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    });
                }
            });

        getSpendingTypes({showInactive: 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypes: response.data
                    });
                }
            });
        getJuridicalPersons()
            .then(response=>{
                if(response.success){
                    this.setState({
                        juridicalPersons: response.data,
                    });

                }
            });


    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const { search } = this.state;
        return getDebts(processMomentFieldInObject({
            ...search,
            date_start: search.date_start ? search.date_start.startOf('day') : null,
            date_end: search.date_end ? search.date_end.endOf('day') : null,
        }, ['date_start', 'date_end', 'month'],null), ...params);
    };

    handleExport = () => {
        const { t } = this.props;
        const { search, businessUnits } = this.state;
        const from = search.date_start || '';
        const to = search.date_end || '';
        const bu = search.businessUnitIds.length ? search.businessUnitIds.map(id => businessUnits.find(item => item.id === id).name).join(', ') : 'All';

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnDataXlsx.map(item => t(item.label)),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnDataXlsx.map(item => _.get(row, item.id))
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Deals");
                    XLSX.writeFile(new_workbook, `Debts ${bu} ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleCreate = () => {
        this.props.history.push(`/money-flow/debt/0`);
    };

    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits, employees, spendingTypes, juridicalPersons } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>

                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.dateType}
                            onChange={this.handleChange('dateType')}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="period" label="за период" />
                            <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
                        </RadioGroup>
                    </FormControl>

                    {search.dateType === 'period' ?
                        <DatePeriodField
                            valueFrom={search.date_start}
                            valueTo={search.date_end}
                            onChangeFrom={this.handleChange('date_start')}
                            onChangeTo={this.handleChange('date_end')}
                            label={t("Show debts by date interval")}
                            required={false}
                        /> :
                        <DateMonthField
                            value={search.month}
                            onChange={this.handleChange('month')}
                            fullWidth
                        />
                    }
                    <AutocompleteMultiSelectField
                        options={businessUnits}
                        label={t("Business unit")}
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        fullWidth
                    />
                    <SelectFromItemsField
                        items={employees}
                        label={t("Employee")}
                        value={search.employeeId}
                        onChange={this.handleChange('employeeId')}
                        fullWidth
                        nullable
                        nullableText="Все"
                    />
                    <MultiSelectFromItemsField
                        items={debtLevels}
                        emptySelectedLabel="---Не выбрано---"
                        label={t('Level')}
                        value={search.lvl}
                        onChange={this.handleChange('lvl')}
                        fullWidth
                    />
                    <AutocompleteMultiSelectField
                        label={t('Spending type')}
                        value={search.spendingTypesIds}
                        name="spendingTypesIds"
                        onChange={this.handleChange('spendingTypesIds')}
                        fullWidth
                        options={spendingTypes}
                        placeholder={t('---All---')}
                        valueField="id"
                        textField="name"
                    />
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        label={t("Search")}
                    />
                    <AutocompleteMultiSelectField
                        label={t('Juridical persons')}
                        value={search.juridicalPersonIds}
                        name="juridicalPersonIds"
                        onChange={this.handleChange('juridicalPersonIds')}
                        fullWidth
                        options={juridicalPersons}
                        placeholder={t('---All---')}
                        textField="shortName"
                    />
                    <MultiSelectFromItemsField
                        items={[
                            {'id': 'cash', 'name': t('pay.types.cash')},
                            {'id': 'cashless', 'name': t('pay.types.cashless')},
                            {'id':'checking_account', 'name':t('pay.types.checking_account')}
                        ]}
                        emptySelectedLabel="---Все типы оплаты---"
                        label={t('Payment type')}
                        value={search.paymentTypes}
                        onChange={this.handleChange('paymentTypes')}
                        fullWidth
                    />

                    <CheckboxField
                        label={t("Unpaid debts")}
                        value={search.unpaid}
                        onChange={this.handleChange('unpaid')}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                    <ActionButton onClick={this.handleCreate} visible={hasRole('ROLE_MONEYFLOW_DEBT_SAVE')}>{t("Create debt")}</ActionButton>
                </LeftBar>
                <Content title={t("Debts")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        linkTemplate={ (row) => `/money-flow/debt/id/` + row.id }
                        order="desc"
                        orderBy="createdAt"
                />
                </Content>
            </React.Fragment>
        );
    }
}
