import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import ActionButton from "../../components/button/ActionButton";
import {
    getReferralPartnersReport,
    getReferralPartnersReportFull,
    navLinks
} from "../../services/moneyFlow";
import moment from "moment";
import ProgressDialog from "../../components/dialogs/ProgressDialog"
import {withTranslation} from "react-i18next";
import {dataTableGroupValueStrategy, formatMoney, fromToDates} from "../../services/common";
import {getBusinessUnits, getReferralPartners, getRegions} from "../../services/organization";
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
} from "@material-ui/core";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import DatePeriodField from "../../components/fields/DatePeriodField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

const paymentsCount = (currentCount, row) => {
    currentCount = currentCount  || 0;
    return row.referralProgramPayId !== 'Еще не оплачен' ? currentCount + 1 : currentCount;
};

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    modalSection: {
        marginBottom: 30,
        borderBottom: '1px solid #ccc',
        paddingBottom: 30
    }
});

const visitsColumnData = [
    { id: 'name', label: 'Referral partner' },
    { id: 'visit', label: 'Visit', linkTemplate: (item) => `/orders/view/` + item.visit },
    { id: 'businessUnitName', label: 'Parlour' },
    { id: 'total', label: 'Total', processValue: formatMoney },
    { id: 'profit', label: 'Partner profit', processValue: formatMoney },
    { id: 'duration', label: 'Duration' },
    { id: 'start', label: 'Date', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'asReferral', label: 'Referral' },
    { id: 'asCreator', label: 'Creator' },
    { id: 'programName', label: 'Program' },
    { id: 'calculatingTick', label: 'Tick' },
];

export default
@withStyles(styles)
@withTranslation()
@connect(null,{
    showMessage: messageDialogActions.show,
})
class ReferralPartnersReportFullPage extends React.Component {

    state = {
        search: {
            referralPartnersIds: [],
            maxRows: 3,
            region: null,
            businessUnits: [],
        },
        refreshTable: false,
        progress: false,
        referralPartners: [],
        detailedVisitsModalOpen: false,
        detailedVisitsModalTitle: null,
        visitsData: [],
        maxRows: [],
        regions: [],
        businessUnits: [],
    };

    openDetailedVisitsModal = (itemRow) => {
        let periodStart;
        let periodEnd;

        if ( Array.isArray(itemRow.periodStart) && Array.isArray(itemRow.periodEnd) ) {
            periodStart = Math.min(...itemRow.periodStart);
            periodEnd = Math.max(...itemRow.periodEnd);
            periodStart = moment.unix(periodStart);
            periodEnd = moment.unix(periodEnd);
        } else {
            periodStart = moment(itemRow.periodStart);
            periodEnd = moment(itemRow.periodEnd);
        }

        getReferralPartnersReport({
            'dateStart': periodStart,
            'dateEnd': periodEnd,
            'referralPartnersIds': [itemRow.externalSubjectId]
        })
            .then(response => {
                if (response.success) {
                    this.setState({
                        detailedVisitsModalOpen: true,
                        visitsData: response.data,
                        detailedVisitsModalTitle: itemRow.externalSubjectName + ' ' + periodStart.format('DD.MM.YYYY') + ' - ' + periodEnd.format('DD.MM.YYYY')
                    });
                }
            });
    };

    columnData = [
        { id: 'externalSubjectName', label: 'Referral partner' },
        { id: 'programName', label: 'Program', groupStrategy: dataTableGroupValueStrategy.concat('programName') },
        { id: 'calculatingTick', label: 'Tick', groupStrategy: dataTableGroupValueStrategy.concat('calculatingTick') },

        { id: 'referralProgramPayId', label: 'Payment', linkTemplate: (item) => item.referralProgramPayId !== 'Еще не оплачен' ? `/money-flow/deal/id/` + item.dealId : null, groupStrategy: paymentsCount },
        { id: 'dealTotal', label: 'Profit money', groupStrategy: dataTableGroupValueStrategy.moneySum('dealTotal'), processValue: formatMoney },
        { id: 'amountBarter', label: 'Profit barter', groupStrategy: dataTableGroupValueStrategy.moneySum('amountBarter'), processValue: formatMoney },
        { id: 'periodStart', label: 'Period start', dateFormat: 'DD.MM.YYYY', groupStrategy: dataTableGroupValueStrategy.fromTo('periodStart'), parentProcessValue: fromToDates('DD.MM.YYYY') },
        { id: 'periodEnd', label: 'Period end', dateFormat: 'DD.MM.YYYY', groupStrategy: dataTableGroupValueStrategy.fromTo('periodEnd'), parentProcessValue: fromToDates('DD.MM.YYYY') },
        { id: 'periodsCount', label: 'Periods count', groupStrategy: dataTableGroupValueStrategy.sum('periodsCount') },

        { id: 'total', label: 'Visits total', groupStrategy: dataTableGroupValueStrategy.moneySum('total'), processValue: formatMoney, onClick: this.openDetailedVisitsModal },
        { id: 'count', label: 'Visits count', groupStrategy: dataTableGroupValueStrategy.sum('count') },
        { id: 'totalCreator', label: 'Visit creating total', groupStrategy: dataTableGroupValueStrategy.moneySum('totalCreator'), processValue: formatMoney },
        { id: 'countCreator', label: 'Visit creating count', groupStrategy: dataTableGroupValueStrategy.sum('countCreator') },
        { id: 'totalReferral', label: 'Referral visits total', groupStrategy: dataTableGroupValueStrategy.moneySum('totalReferral'), processValue: formatMoney },
        { id: 'countReferral', label: 'Referral visits count', groupStrategy: dataTableGroupValueStrategy.sum('countReferral') },
        { id: 'duration', label: 'Duration', groupStrategy: dataTableGroupValueStrategy.sum('duration') },
    ];

    componentDidMount() {
        const { t } = this.props;
        getReferralPartners()
            .then(response => {
                if (response.success) {
                    this.setState({
                        referralPartners: response.data,
                        maxRows: [
                            [1, 1],
                            [2, 2],
                            [3, 3],
                            [4, 4],
                            [0, t('---All---')],
                        ]
                    })
                }
            });

        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    })
                }
            });

        getBusinessUnits({region: this.state.search.region})
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        }, function () {
            if(prop === 'region') {
                getBusinessUnits({region: value})
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                businessUnits: response.data,
                            })
                        }
                    });
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = () => {
        const {search} = this.state;
        return getReferralPartnersReportFull(search);
    };

    closeDetailedVisitsModal = () => {
        this.setState({
            detailedVisitsModalOpen: false
        });
    };

    render() {
        const { classes, t } = this.props;
        const { refreshTable, search, progress, referralPartners, detailedVisitsModalOpen, regions, businessUnits } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <AutocompleteMultiSelectField
                        options={referralPartners}
                        value={search.referralPartnersIds}
                        name="referralPartnersIds"
                        onChange={this.handleChange('referralPartnersIds')}
                        label={t('Partners')}
                        fullWidth
                    />
                    <DatePeriodField
                        valueFrom={search.dateFrom}
                        valueTo={search.dateTo}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('dateFrom')}
                        onChangeTo={this.handleChange('dateTo')}
                        label={"Закрытые визиты за период"}
                        className={classes.filterControl}
                    />
                    <SelectFromItemsField
                        items={regions}
                        label={t('Region')}
                        value={search.region}
                        onChange={this.handleChange('region')}
                        fullWidth
                        nullable
                        nullableText={t("---All---")}
                    />
                    <MultiSelectFromItemsField
                        items={businessUnits}
                        label={t('Business unit')}
                        value={search.businessUnits}
                        onChange={this.handleChange('businessUnits')}
                        fullWidth
                    />
                    <br/><br/>
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                </LeftBar>
                <Content title={t('Referral partners report full')}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        onClick={this.handleEdit}
                        selector={false}
                        pagination={false}
                        groupBy="externalSubjectName"
                        autoload={false}
                    />
                </Content>
                {detailedVisitsModalOpen && this.detailedVisitsModal()}
                <ProgressDialog open={progress}/>
            </React.Fragment>
        );
    }

    detailedVisitsModal = () => {
        const { t } = this.props;
        const { detailedVisitsModalTitle, visitsData } = this.state;

        return (
            <Dialog
                open={true}
                onClose={this.closeDetailedVisitsModal}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth={"lg"}
                fullWidth
            >
                <DialogTitle id="dialog-title">{t("Detailed visits")}: {detailedVisitsModalTitle}</DialogTitle>
                <DialogContent>
                    <DataTableLocal
                        selector={false}
                        data={visitsData}
                        columnData={visitsColumnData}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeDetailedVisitsModal} color="primary">{t("Close")}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
