import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Fab,
    Grid, MenuItem, Typography,
    withStyles
} from "@material-ui/core";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import * as PropTypes from "prop-types";
import {withRouter} from "react-router";
import {getMedicalCardZonePrescriptions, getMedicalCardZones} from "../../services/guests";
import * as cn from "classnames";
import Popover from "@material-ui/core/Popover";
import {getMedicalCardData, saveMedicalCardParts, startVisitAndSaveMedicalCardParts} from "../../services/calendar";
import AnyField from "../../components/fields/AnyField";
import Cancel from "@material-ui/icons/Cancel";
import Save from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import moment from "moment";
import {withTranslation} from "react-i18next";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import {getVisitElaborationDegreeTypes} from "../../services/common";
import _ from "lodash";

export default
@withTranslation()
@withRouter
@connect(state => ({
    token: state.auth.token,
}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
@withStyles(theme => ({
    dialogContent: {
        minWidth: 400,
        maxWidth: 850,
    },
    svgPart: {
        stroke: '#000000',
        fill: '#e1bee7',
        strokeLinecap: 'square',
        strokeWidth: '2px',
        cursor: 'pointer',

        '&:hover': {
            opacity: 0.7
        }
    },
    active: {
        opacity: 0.7
    },
    input: {
        marginBottom: '15px'
    },
    subheading: {
        fontSize: 18
    },
    popoverWrapper: {
        padding: 15,
        width: 200
    },
    gridItem: {
        minWidth: 400,
    },
    humanImage: {
        width: 400,
        height: 'auto',
    },
}))
class MedicalCardDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        onCloseWithStart: PropTypes.func,
        visitId: PropTypes.string,
        startVisit: PropTypes.bool
    };

    emptyPart = {
        id: null,
        zone: null,
        zonePrescriptionId: null,
        zonePrescriptionColor: null,
        comment: null,
    };

    state = {
        medicalCardData: {
            guestName: null,
            currentVisitData: {
                medicalCardParts: null,
                date: null,
                elaborationDegree: null
            },
            prevVisitData: {
                medicalCardParts: null,
                date: null,
                elaborationDegree: null
            }
        },
        activePart: this.emptyPart,
        activePartIsPrev: false,
        zones: [],
        zonePrescriptions: [],
        popoverTarget: null,
        elaborationDegreeTypes: [],
    };

    componentDidMount() {
        getMedicalCardZones().then(response => {
            if (response.success) {
                this.setState({
                    zones: response.data
                });
            }
        });

        getMedicalCardZonePrescriptions().then(response => {
            if (response.success) {
                this.setState({
                    zonePrescriptions: response.data
                });
            }
        });

        getMedicalCardData(this.props.visitId).then(response => {
            if (response.success) {
                this.setState({
                    medicalCardData: response.data
                });
            }
        });

        getVisitElaborationDegreeTypes().then(response => {
            if (response.success) {
                this.setState({
                    elaborationDegreeTypes: response.data
                });
            }
        });
    };

    getPartsName = (isPrev) => {
        return isPrev ? 'prevVisitData' : 'currentVisitData';
    };

    getPartClass = (partName, isPrev) => {
        const { classes } = this.props;

        return cn(
            classes.svgPart,
            this.state.activePart.zone === partName && this.state.activePartIsPrev === isPrev ? classes.active : null
        );
    };

    getPartStyle = (partName, isPrev) => {
        isPrev = isPrev || false;
        let partsName = this.getPartsName(isPrev);
        let parts = this.state.medicalCardData[partsName].medicalCardParts;

        if (!parts) {
            return null;
        }

        let part = parts[partName];

        if (part) {
            return {
                fill: part.zonePrescriptionColor
            };
        }
        return {};
    };

    setActivePart = (partName, isPrev) => (event) => {
        isPrev = isPrev || false;
        let partsName = this.getPartsName(isPrev);
        let part = this.state.medicalCardData[partsName].medicalCardParts && this.state.medicalCardData[partsName].medicalCardParts[partName];
        part = part || this.emptyPart;

        this.setState({
            popoverTarget: event.currentTarget,
            activePartIsPrev: isPrev,
            activePart: {
                ...part,
                zone: partName
            }
        });
    };

    handleChangeActivePart = (event) => {
        this.setActivePart(event.target.value)();
    };

    closePopover = () => {
        this.setState({
            popoverTarget: null
        });
    };
    
    setPopoverContent = () => {
        const { t } = this.props;
        const { activePart, activePartIsPrev, zonePrescriptions, zones } = this.state;
        const zone =  zones.find(zone => zone.id === activePart.zone);
        const { classes } = this.props;
        return (
          <div className={classes.popoverWrapper}>
              <Typography variant="subtitle1"> { zone && zone.text }</Typography>
              <AnyField
                  value={activePart.zonePrescriptionId}
                  onChange={this.handleChangeZonePrescription}
                  label={t('medicalCard.prescription')}
                  readOnly={activePartIsPrev}
                  select
                  required={!activePartIsPrev}
                  className={classes.input}
                  fullWidth>
                  {zonePrescriptions.map(item => <MenuItem key={item.id} value={item.id}>{item.nameRu} ({item.nameEn})</MenuItem>)}
              </AnyField>
              <AnyField
                  label={t("Comment")}
                  value={activePart.comment}
                  onChange={this.handleChange('comment')}
                  multiline
                  className={classes.input}
                  rows={4}
                  readOnly={activePartIsPrev}
                  fullWidth
              />

              <Grid container justify="space-around">
                  <Fab color="secondary" size="small"  onClick={this.save(false)} disabled={!activePart.zonePrescriptionId}><Save fontSize="small"/></Fab>
                  <Fab color="secondary" size="small"  onClick={this.save(true)} disabled={!activePart.zonePrescriptionId}><Delete fontSize="small"/></Fab>
                  <Fab color="secondary" size="small"  onClick={this.closePopover}><Cancel fontSize="small"/></Fab>
              </Grid>

          </div>
        );
    };

    handleChange = prop => event => {
        this.setState({
            activePart: {
                ...this.state.activePart,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeElaborationDegree = event => {
        this.setState({
            medicalCardData: {
                ...this.state.medicalCardData,
                currentVisitData:{
                    ...this.state.medicalCardData.currentVisitData,
                    elaborationDegree: event.target.value
                }
            },
        });
    };

    handleChangeZonePrescription = (event) => {

        const { zonePrescriptions } = this.state;

        this.setState({
            activePart: {
                ...this.state.activePart,
                zonePrescriptionId: event.target.value,
                zonePrescriptionColor: zonePrescriptions.find((zonePrescription) => zonePrescription.id === event.target.value).color
            }
        });
    };

    save = (remove) => () => {
        let medicalCardParts = { ...this.state.medicalCardData.currentVisitData.medicalCardParts };

        if (this.state.activePart.zone) {
            if (remove) {
                delete medicalCardParts[this.state.activePart.zone];
            } else {
                medicalCardParts[this.state.activePart.zone] = this.state.activePart;
            }
        }

        this.setState({
            activePart: this.emptyPart,
            popoverTarget: null,
            medicalCardData: {
                ...this.state.medicalCardData,
                currentVisitData: {
                    ...this.state.medicalCardData.currentVisitData,
                    medicalCardParts: medicalCardParts
                }
            }
        });
    };


    handleClose = ok => () => {
        const { t } = this.props;
        if (!ok) {
            this.props.onClose(ok);
            return;
        }

        let params = {
            visitId: this.props.visitId,
            medicalCardPartsDtos: _.toArray(this.state.medicalCardData.currentVisitData.medicalCardParts),
            elaborationDegree: this.state.medicalCardData.currentVisitData.elaborationDegree
        };

        let handler;
        if (this.props.startVisit) {
            handler = startVisitAndSaveMedicalCardParts(params);
        } else {
            handler = saveMedicalCardParts(params);
        }

        handler.then(response => {
                if (response.success) {
                    this.props.showInfo(t('medicalCard.saved')+ ( this.props.startVisit ? ' ' + t('medicalCard.visitConfirmed') : '') );
                    this.props.onClose(ok);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes, open, t } = this.props;
        const { popoverTarget, medicalCardData, elaborationDegreeTypes } = this.state;
        let disabled = false;
        let popoverOpen = !!popoverTarget;
        let lastVisit = null;
        if (medicalCardData.prevVisitData.medicalCardParts) {
            lastVisit = moment(medicalCardData.prevVisitData.date).format('DD.MM.YYYY');
        }

        return (
            <Dialog
                open={open}
                onClose={this.handleClose(false)}
                aria-labelledby="delivery-dialog-title"
                maxWidth={false}
            >
                <DialogTitle id="delivery-dialog-title">{this.props.startVisit ? t('medicalCard.titleConfirmation') : t('medicalCard.titleCard')} {medicalCardData.guestName}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={2}>
                        {medicalCardData.prevVisitData.medicalCardParts &&
                        <Grid item sm={6} className={classes.gridItem}>
                            <Typography variant="h6" className={classes.subheading}>{t('medicalCard.prevVisitCard')}: {lastVisit}</Typography>
                            <svg viewBox="0 0 700 700" className={classes.humanImage}>
                                <path
                                    d="m 173.81776,124.80329 -18.92001,6.30423 -19.09694,-6.2039 -13.21697,-6.1346 -13.59117,12.4921 -13.36557,25.68791 2.865098,15.96117 4.233452,20.73705 103.35074,0.47587 3.9451,-23.98431 3.72209,-16.90654 -9.4135,-21.64424 -15.20458,-12.9578 z"
                                    className={this.getPartClass("chest", true)} style={this.getPartStyle('chest', true)}
                                    onClick={this.setActivePart('chest', true)}/>
                                <path
                                    d="m 174.4375,83.269531 -6.54843,5.909596 -6.04923,4.320873 -9.04619,-0.04021 -10.0417,-3.012524 -8.17578,-7.154298 -1.13669,8.720453 -7.87698,12.633059 -2.97866,14.12254 13.21697,6.1346 19.09694,6.2039 18.92001,-6.30423 15.30774,-6.17306 -2.71534,-16.31968 -8.98765,-10.639893 z"
                                    className={this.getPartClass("neck", true)} style={this.getPartStyle('neck', true)}
                                    onClick={this.setActivePart('neck', true)}/>
                                <path
                                    d="m 543,238.25 -94,-0.25 -7,26 -1,12 v 17 l 15.52597,-5.39169 24.47575,0.0806 15.58421,5.31109 15.56859,-5.31109 24.47575,-0.0806 L 552,293 551.47487,273.34835 550,262.93934 Z"
                                    className={this.getPartClass("loin", true)} style={this.getPartStyle('loin', true)}
                                    onClick={this.setActivePart('loin', true)}/>
                                <path
                                    d="m 130.53125,564.50977 -0.33398,5.83398 -0.0723,9.09375 -2.06836,3.7793 -4.51953,4.52929 -7,4 -4,5 1,6 5.125,2.125 11.875,0.375 h 13.08792 3.16208 l 6.1875,-3 1.21094,-7.93359 -3.1047,-8.81005 1.4504,-20.99268 z"
                                    className={this.getPartClass("foot_right", true)} style={this.getPartStyle('foot_right', true)}
                                    onClick={this.setActivePart('foot_right', true)}/>
                                <path
                                    d="m 186.58836,549.3531 7,-23 5.81802,-34.33103 -3.29026,-40.81927 -33.6078,-0.0677 -5.30124,41.13482 1.38388,35.48699 1.9974,36.59619 h 22 z"
                                    className={this.getPartClass("shin_left", true)} style={this.getPartStyle('shin_left', true)}
                                    onClick={this.setActivePart('shin_left', true)}/>
                                <path
                                    d="m 64.99804,217.91872 32.04501,7.74869 v 11.30827 l -2.01926,15.53416 -3.03675,10.43034 -6.06173,13.25373 -16.13447,24.56568 -8.31276,8.76465 -24.8892,-9.71805 8.17134,-24.88226 3.01515,-16.01821 5.05405,-16.09737 9.15544,-13.50036 z"
                                    className={this.getPartClass("forearm_right", true)}
                                    style={this.getPartStyle('forearm_right', true)}
                                    onClick={this.setActivePart('forearm_right', true)}/>
                                <path
                                    d="m 244.37264,214.20196 -32.04501,7.74869 v 11.30827 l 2.01926,15.53416 3.03675,10.43034 6.06173,13.25373 16.13447,24.56568 8.31276,8.76465 24.8892,-9.71805 -8.17134,-24.88226 -3.01515,-16.01821 -5.05405,-16.09737 -9.15544,-13.50036 z"
                                    className={this.getPartClass("forearm_left", true)}
                                    style={this.getPartStyle('forearm_left', true)}
                                    onClick={this.setActivePart('forearm_left', true)}/>
                                <path
                                    d="m 67.87068,156.13082 27.75642,0.81821 3.106928,17.14575 1.790252,25.31542 -0.64383,15.34953 -3.00977,11.57031 -31.86328,-8.22266 2.86133,-13.41992 -0.99805,-19.97461 1,-14.99609 v -0.004 z"
                                    className={this.getPartClass("cubit_right", true)}
                                    style={this.getPartStyle('cubit_right', true)}
                                    onClick={this.setActivePart('cubit_right', true)}/>
                                <path
                                    d="m 241.5,152.41406 -27.75642,0.81821 -3.72209,16.90654 -1.17509,25.55463 0.64383,15.34953 3.00977,11.57031 31.86328,-8.22266 L 241.50195,200.9707 242.5,180.99609 241.5,166 v -0.004 z"
                                    className={this.getPartClass("cubit_left", true)} style={this.getPartStyle('cubit_left', true)}
                                    onClick={this.setActivePart('cubit_left', true)}/>
                                <path
                                    d="m 272.71416,296.36828 -24.75391,9.16035 5.69922,11.10933 -0.98633,8.7332 v 0.0225 8.04146 l 3.12891,8.31125 2.89062,13.8832 3.06641,6.33286 8.14258,6.30866 9.35351,3.68178 6.25586,-4.61735 4.16211,-3.68524 v -8.15214 l -1.94336,-15.62292 -1.07226,-6.64589 -1.83594,-3.25117 3.99804,4.58277 6.11719,5.41631 7.45899,0.94423 0.17773,-0.20925 3.2168,-3.79937 -14.26953,-18.04748 -7.46094,-4.08299 -5.44141,-6.42451 z"
                                    className={this.getPartClass("hand_left", true)} style={this.getPartStyle('hand_left', true)}
                                    onClick={this.setActivePart('hand_left', true)}/>
                                <path
                                    d="m 206.07639,194.12312 -103.35074,-0.47587 5.83075,39.6498 -1.40084,26.14539 -3.679,20.0224 35.09757,13.63587 20.79362,3.29156 0.58322,0.0173 19.79903,-3.15765 28.75,-12.7832 -4.893,-20.89066 -3.70125,-26.33401 z"
                                    className={this.getPartClass("stomach", true)} style={this.getPartStyle('stomach', true)}
                                    onClick={this.setActivePart('stomach', true)}/>
                                <path
                                    d="m 154.55789,450.69288 -37.94032,0.2478 L 110,418 103,398 99,372 96.232233,347.2981 94.87868,324.35876 97,306 l 6.47656,-26.53516 35.09757,13.63587 20.79362,3.29156 0.0411,103.49759 z"
                                    className={this.getPartClass("hip_right", true)} style={this.getPartStyle('hip_right', true)}
                                    onClick={this.setActivePart('hip_right', true)}/>
                                <path
                                    d="m 159.95097,296.40956 19.79903,-3.15765 28.75,-12.7832 6.62132,21.07203 2.11612,21.18198 -0.76777,19.63083 -0.76257,28.96016 -5.41421,22.05025 -6.94454,25.18198 -7.23223,32.65686 -33.6078,-0.0677 -3.09947,-51.24526 z"
                                    className={this.getPartClass("hip_left", true)} style={this.getPartStyle('hip_left', true)}
                                    onClick={this.setActivePart('hip_left', true)}/>
                                <path
                                    d="m 471,72 h 47 l -2,7 -0.625,10.625 L 520,100 510,110 h -32.12105 l -10,-10 L 473,90 V 80 Z"
                                    className={this.getPartClass("neck", true)} style={this.getPartStyle('neck', true)}
                                    onClick={this.setActivePart('neck', true)}/>
                                <path
                                    d="m 547,188 4,-22 7,-10 -20,-31 -28,-15 h -32.12105 l -28,15 -19.31538,29.93883 6.66893,9.52704 4,22 -1,12 L 449,238 l 94,0.25 5,-39 z"
                                    className={this.getPartClass("back", true)} style={this.getPartStyle('back', true)}
                                    onClick={this.setActivePart('back', true)}/>
                                <path
                                    d="m 496.57812,341.66206 -2.82812,5 -5.75,3.125 -9.75,2.125 -15.875,0.25 -16.875,-4.5 -9.5,-10 V 320 l 1,-9 4,-18 15.52597,-5.39169 24.47575,0.0806 L 496.58593,293 Z"
                                    className={this.getPartClass("butt_left", true)} style={this.getPartStyle('butt_left', true)}
                                    onClick={this.setActivePart('butt_left', true)}/>
                                <path
                                    d="m 437.25,387.25 2.5,24.5 5.75,20.25 8.5,22 2,5 36.86409,-1.84321 3.50245,-43.43557 0.33658,-48.43416 -0.125,-23.625 -2.82812,5 -5.75,3.125 -9.75,2.125 -15.875,0.25 -16.875,-4.5 -9.5,-10 z"
                                    className={this.getPartClass("hip_left", true)} style={this.getPartStyle('hip_left', true)}
                                    onClick={this.setActivePart('hip_left', true)}/>
                                <path
                                    d="M 504.00611,582.27145 523.15624,582 l 3,-11 4,-19 8,-28 4,-18 1,-18 -2,-16 -4,-13 -36.88279,-1.84414 -2.61721,18.09414 1.21967,27.13909 3.16421,58.30241 1.96599,21.57995"
                                    className={this.getPartClass("shin_right", true)} style={this.getPartStyle('shin_right', true)}
                                    onClick={this.setActivePart('shin_right', true)}/>
                                <path
                                    d="m 500.1875,603.4375 v -6 l 3.6875,-7.375 0.13111,-7.79105 19.15013,-0.27145 1.03126,8.4375 12,7 1.625,7.625 -9.125,3.625 -23.8125,0.0625 z"
                                    className={this.getPartClass("foot_right", true)} style={this.getPartStyle('foot_right', true)}
                                    onClick={this.setActivePart('foot_right', true)}/>
                                <path
                                    d="m 520,100 13,8 13,5 12,3 8,4 8,8 5,11 3,12 v 10 l -3,7 -21,-12 -20,-31 -28,-15 z"
                                    className={this.getPartClass("shoulder_right", true)}
                                    style={this.getPartStyle('shoulder_right', true)}
                                    onClick={this.setActivePart('shoulder_right', true)}/>
                                <path
                                    d="m 558,156 21,12 5.94454,12.93414 1.49296,21.25336 1.10387,14.39308 L 552,224 l -4,-24 -1,-12 4,-22 z"
                                    className={this.getPartClass("cubit_right", true)}
                                    style={this.getPartStyle('cubit_right', true)}
                                    onClick={this.setActivePart('cubit_right', true)}/>
                                <path
                                    d="M 600,258 595.7019,231.80761 587.54137,216.58058 552,224 552.31574,235.2102 556.25,255.75 566,273 l 5,6 3,6 6,9 8,10 26,-10 -9,-20 z"
                                    className={this.getPartClass("forearm_right", true)}
                                    style={this.getPartStyle('forearm_right', true)}
                                    onClick={this.setActivePart('forearm_right', true)}/>
                                <path
                                    d="m 614,294 -26,10 4,8 -2,9 3,13 2,19 3,9 3.25,5.375 3.75,3.625 5,2 6,2 5,-3 4,-2 3,-8 1,-11 -2,-22 8,9 4.75,-0.75 v -6 L 635,324 l -4,-7 -5,-6 -9,-6 z"
                                    className={this.getPartClass("hand_right", true)} style={this.getPartStyle('hand_right', true)}
                                    onClick={this.setActivePart('hand_right', true)}/>
                                <path
                                    d="M 463.51777,55.494796 463,35 l 8.5,-13.5 13.25,-7 h 19.5 l 13.5,6.5 6.42678,14.59099 -0.0732,18.50736 L 518,72 h -47 z"
                                    className={this.getPartClass("head", true)} style={this.getPartStyle('head', true)}
                                    onClick={this.setActivePart('head', true)}/>
                                <path
                                    d="m 126.53033,549.50952 -7,-23 -5.81802,-34.33103 2.90526,-41.23781 37.94032,-0.2478 1.35372,41.73346 -1.38388,35.48699 -1.9974,36.59619 h -22 z"
                                    className={this.getPartClass("shin_right", true)} style={this.getPartStyle('shin_right', true)}
                                    onClick={this.setActivePart('shin_right', true)}/>
                                <path
                                    d="m 182.58834,564.3531 0.33398,5.83398 0.0723,9.09375 2.06836,3.7793 4.51953,4.52929 7,4 4,5 -1,6 -5.125,2.125 -11.875,0.375 h -13.08792 -3.16208 l -6.1875,-3 -1.21094,-7.93359 3.1047,-8.81005 -1.4504,-20.99268 z"
                                    className={this.getPartClass("foot_left", true)} style={this.getPartStyle('foot_left', true)}
                                    onClick={this.setActivePart('foot_left', true)}/>
                                <path
                                    d="m 467.87895,100 -13,8 -13,5 -12,3 -8,4 -8,8 -5,11 -3,12 v 10 l 3,7 21,-12 20,-31 28,-15 z"
                                    className={this.getPartClass("shoulder_left", true)}
                                    style={this.getPartStyle('shoulder_left', true)}
                                    onClick={this.setActivePart('shoulder_left', true)}/>
                                <path
                                    d="m 430.2325,154.46587 -21,12 -5.94454,12.93414 -1.49296,21.25336 -1.10387,14.39308 35.54137,7.41942 4,-24 1,-12 -4,-22 z"
                                    className={this.getPartClass("cubit_left", true)} style={this.getPartStyle('cubit_left', true)}
                                    onClick={this.setActivePart('cubit_left', true)}/>
                                <path
                                    d="m 388.2325,256.46587 4.2981,-26.19239 8.16053,-15.22703 35.54137,7.41942 -0.31574,11.2102 -3.93426,20.5398 -9.75,17.25 -5,6 -3,6 -6,9 -8,10 -26,-10 9,-20 z"
                                    className={this.getPartClass("forearm_left", true)}
                                    style={this.getPartStyle('forearm_left', true)}
                                    onClick={this.setActivePart('forearm_left', true)}/>
                                <path
                                    d="m 374.2325,292.46587 26,10 -4,8 2,9 -3,13 -2,19 -3,9 -3.25,5.375 -3.75,3.625 -5,2 -6,2 -5,-3 -4,-2 -3,-8 -1,-11 2,-22 -8,9 -4.75,-0.75 v -6 l 4.75,-7.25 4,-7 5,-6 9,-6 z"
                                    className={this.getPartClass("hand_left", true)} style={this.getPartStyle('hand_left', true)}
                                    onClick={this.setActivePart('hand_left', true)}/>
                                <path
                                    d="m 496.57812,341.66206 2.82812,5 5.75,3.125 9.75,2.125 15.875,0.25 16.875,-4.5 9.5,-10 V 320 l -1,-9 -4,-18 -15.52597,-5.39169 -24.47575,0.0806 L 496.58593,293 Z"
                                    className={this.getPartClass("butt_right", true)} style={this.getPartStyle('butt_right', true)}
                                    onClick={this.setActivePart('butt_right', true)}/>
                                <path
                                    d="m 555.90624,387.25 -2.5,24.5 -5.75,20.25 -8.5,22 -2,5 -36.88279,-1.84414 -3.90691,-43.43464 0.0866,-48.43416 0.125,-23.625 2.82812,5 5.75,3.125 9.75,2.125 15.875,0.25 16.875,-4.5 9.5,-10 z"
                                    className={this.getPartClass("hip_right", true)} style={this.getPartStyle('hip_right', true)}
                                    onClick={this.setActivePart('hip_right', true)}/>
                                <path
                                    d="M 489.15009,582.27145 469.99996,582 l -3,-11 -4,-19 -8,-28 -4,-18 -1,-18 2,-16 4,-13 36.86413,-1.84321 2.63587,18.09321 -1.21967,27.13909 -3.16421,58.30241 -1.96599,21.57995"
                                    className={this.getPartClass("shin_left", true)} style={this.getPartStyle('shin_left', true)}
                                    onClick={this.setActivePart('shin_left', true)}/>
                                <path
                                    d="m 492.9687,603.4375 v -6 l -3.6875,-7.375 -0.13111,-7.79105 L 469.99996,582 l -1.03126,8.4375 -12,7 -1.625,7.625 9.125,3.625 23.8125,0.0625 z"
                                    className={this.getPartClass("foot_left", true)} style={this.getPartStyle('foot_left', true)}
                                    onClick={this.setActivePart('foot_left', true)}/>
                                <path
                                    d="m 36.65652,300.08504 24.75391,9.16035 -5.69922,11.10933 0.98633,8.7332 v 0.0225 8.04146 l -3.12891,8.31125 -2.89062,13.8832 -3.06641,6.33286 -8.14258,6.30866 -9.35351,3.68178 -6.25586,-4.61735 -4.16211,-3.68524 v -8.15214 l 1.94336,-15.62292 1.07226,-6.64589 1.83594,-3.25117 -3.99804,4.58277 -6.11719,5.41631 -7.45899,0.94423 -0.17773,-0.20925 -3.2168,-3.79937 14.26953,-18.04748 7.46094,-4.08299 5.44141,-6.42451 z"
                                    className={this.getPartClass("hand_right", true)} style={this.getPartStyle('hand_right', true)}
                                    onClick={this.setActivePart('hand_right', true)}/>
                                <path
                                    d="m 186.41016,102.31055 2.71534,16.31968 15.20458,12.9578 9.4135,21.64424 27.75642,-0.81821 -2.00781,-13.52929 -2.96864,-10.20876 -4.1173,-6.99632 -8.13086,-6.09768 -7.09375,-4.05273 -23.01367,-7.00196 z"
                                    className={this.getPartClass("shoulder_left", true)}
                                    style={this.getPartStyle('shoulder_left', true)}
                                    onClick={this.setActivePart('shoulder_left', true)}/>
                                <path
                                    d="m 125.5625,104.64648 -2.97866,14.12254 -13.59117,12.4921 -13.36557,25.68791 -27.75642,-0.81821 2.00781,-13.52929 2.96864,-10.20876 4.1173,-6.99632 8.13086,-6.09768 7.09375,-4.05273 23.01367,-7.00196 z"
                                    className={this.getPartClass("shoulder_right", true)}
                                    style={this.getPartStyle('shoulder_right', true)}
                                    onClick={this.setActivePart('shoulder_right', true)}/>
                                <g id="face_group" onClick={this.setActivePart('face', true)}>
                                    <path className={this.getPartClass("face", true)} style={this.getPartStyle('face', true)}
                                          d="m 122.5,40.5 v 8.347656 l -2.02148,3.033203 1.0371,7.269532 5.0625,10.125 4.98438,5.980468 3.01367,8.03711 8.17578,7.154297 10.0417,3.012524 9.04619,0.04021 6.04923,-4.320873 6.54843,-5.909596 2.03125,-6.09375 2.96094,-7.898437 3.98047,-3.980469 3.07031,-6.140625 1.0332,-6.197266 -2.02539,-6.074218 L 184.5,40.5 Z"/>
                                    <circle cx="140.8246" cy="56.532829" r="2"/>
                                    <circle cx="170.84433" cy="56.532829" r="2"/>
                                    <path
                                        d="m 163.01073,70.316361 c -5.13701,7.863097 -9.33282,5.144636 -13.31467,0.02077 l 0.97275,-0.707811 c 4.35195,5.065324 6.90928,6.115979 11.45001,0.03723 0.21105,0.152831 0.89476,0.420341 0.89191,0.649851 z"/>
                                </g>
                                <path
                                    d="M 140.32031,10.5625 129.64062,17.152344 123.03033,29.545811 122.5,40.5 h 62 l -0.53033,-11.355002 -6.01482,-12.766775 -9.90968,-5.789835 -13.35762,-2.2001477 z"
                                    className={this.getPartClass("head", true)} style={this.getPartStyle('head', true)}
                                    onClick={this.setActivePart('head', true)}/>
                            </svg>
                        </Grid>
                        }
                        <Grid item sm={6} className={classes.gridItem}>
                            <Typography variant="h6" className={classes.subheading}>{t('medicalCard.currentVisitCard')}</Typography>
                            <svg viewBox="0 0 700 700" className={classes.humanImage}>
                                <path
                                    d="m 173.81776,124.80329 -18.92001,6.30423 -19.09694,-6.2039 -13.21697,-6.1346 -13.59117,12.4921 -13.36557,25.68791 2.865098,15.96117 4.233452,20.73705 103.35074,0.47587 3.9451,-23.98431 3.72209,-16.90654 -9.4135,-21.64424 -15.20458,-12.9578 z"
                                    className={this.getPartClass("chest")} style={this.getPartStyle('chest')}
                                    onClick={this.setActivePart('chest')}/>
                                <path
                                    d="m 174.4375,83.269531 -6.54843,5.909596 -6.04923,4.320873 -9.04619,-0.04021 -10.0417,-3.012524 -8.17578,-7.154298 -1.13669,8.720453 -7.87698,12.633059 -2.97866,14.12254 13.21697,6.1346 19.09694,6.2039 18.92001,-6.30423 15.30774,-6.17306 -2.71534,-16.31968 -8.98765,-10.639893 z"
                                    className={this.getPartClass("neck")} style={this.getPartStyle('neck')}
                                    onClick={this.setActivePart('neck')}/>
                                <path
                                    d="m 543,238.25 -94,-0.25 -7,26 -1,12 v 17 l 15.52597,-5.39169 24.47575,0.0806 15.58421,5.31109 15.56859,-5.31109 24.47575,-0.0806 L 552,293 551.47487,273.34835 550,262.93934 Z"
                                    className={this.getPartClass("loin")} style={this.getPartStyle('loin')}
                                    onClick={this.setActivePart('loin')}/>
                                <path
                                    d="m 130.53125,564.50977 -0.33398,5.83398 -0.0723,9.09375 -2.06836,3.7793 -4.51953,4.52929 -7,4 -4,5 1,6 5.125,2.125 11.875,0.375 h 13.08792 3.16208 l 6.1875,-3 1.21094,-7.93359 -3.1047,-8.81005 1.4504,-20.99268 z"
                                    className={this.getPartClass("foot_right")} style={this.getPartStyle('foot_right')}
                                    onClick={this.setActivePart('foot_right')}/>
                                <path
                                    d="m 186.58836,549.3531 7,-23 5.81802,-34.33103 -3.29026,-40.81927 -33.6078,-0.0677 -5.30124,41.13482 1.38388,35.48699 1.9974,36.59619 h 22 z"
                                    className={this.getPartClass("shin_left")} style={this.getPartStyle('shin_left')}
                                    onClick={this.setActivePart('shin_left')}/>
                                <path
                                    d="m 64.99804,217.91872 32.04501,7.74869 v 11.30827 l -2.01926,15.53416 -3.03675,10.43034 -6.06173,13.25373 -16.13447,24.56568 -8.31276,8.76465 -24.8892,-9.71805 8.17134,-24.88226 3.01515,-16.01821 5.05405,-16.09737 9.15544,-13.50036 z"
                                    className={this.getPartClass("forearm_right")}
                                    style={this.getPartStyle('forearm_right')}
                                    onClick={this.setActivePart('forearm_right')}/>
                                <path
                                    d="m 244.37264,214.20196 -32.04501,7.74869 v 11.30827 l 2.01926,15.53416 3.03675,10.43034 6.06173,13.25373 16.13447,24.56568 8.31276,8.76465 24.8892,-9.71805 -8.17134,-24.88226 -3.01515,-16.01821 -5.05405,-16.09737 -9.15544,-13.50036 z"
                                    className={this.getPartClass("forearm_left")}
                                    style={this.getPartStyle('forearm_left')}
                                    onClick={this.setActivePart('forearm_left')}/>
                                <path
                                    d="m 67.87068,156.13082 27.75642,0.81821 3.106928,17.14575 1.790252,25.31542 -0.64383,15.34953 -3.00977,11.57031 -31.86328,-8.22266 2.86133,-13.41992 -0.99805,-19.97461 1,-14.99609 v -0.004 z"
                                    className={this.getPartClass("cubit_right")}
                                    style={this.getPartStyle('cubit_right')}
                                    onClick={this.setActivePart('cubit_right')}/>
                                <path
                                    d="m 241.5,152.41406 -27.75642,0.81821 -3.72209,16.90654 -1.17509,25.55463 0.64383,15.34953 3.00977,11.57031 31.86328,-8.22266 L 241.50195,200.9707 242.5,180.99609 241.5,166 v -0.004 z"
                                    className={this.getPartClass("cubit_left")} style={this.getPartStyle('cubit_left')}
                                    onClick={this.setActivePart('cubit_left')}/>
                                <path
                                    d="m 272.71416,296.36828 -24.75391,9.16035 5.69922,11.10933 -0.98633,8.7332 v 0.0225 8.04146 l 3.12891,8.31125 2.89062,13.8832 3.06641,6.33286 8.14258,6.30866 9.35351,3.68178 6.25586,-4.61735 4.16211,-3.68524 v -8.15214 l -1.94336,-15.62292 -1.07226,-6.64589 -1.83594,-3.25117 3.99804,4.58277 6.11719,5.41631 7.45899,0.94423 0.17773,-0.20925 3.2168,-3.79937 -14.26953,-18.04748 -7.46094,-4.08299 -5.44141,-6.42451 z"
                                    className={this.getPartClass("hand_left")} style={this.getPartStyle('hand_left')}
                                    onClick={this.setActivePart('hand_left')}/>
                                <path
                                    d="m 206.07639,194.12312 -103.35074,-0.47587 5.83075,39.6498 -1.40084,26.14539 -3.679,20.0224 35.09757,13.63587 20.79362,3.29156 0.58322,0.0173 19.79903,-3.15765 28.75,-12.7832 -4.893,-20.89066 -3.70125,-26.33401 z"
                                    className={this.getPartClass("stomach")} style={this.getPartStyle('stomach')}
                                    onClick={this.setActivePart('stomach')}/>
                                <path
                                    d="m 154.55789,450.69288 -37.94032,0.2478 L 110,418 103,398 99,372 96.232233,347.2981 94.87868,324.35876 97,306 l 6.47656,-26.53516 35.09757,13.63587 20.79362,3.29156 0.0411,103.49759 z"
                                    className={this.getPartClass("hip_right")} style={this.getPartStyle('hip_right')}
                                    onClick={this.setActivePart('hip_right')}/>
                                <path
                                    d="m 159.95097,296.40956 19.79903,-3.15765 28.75,-12.7832 6.62132,21.07203 2.11612,21.18198 -0.76777,19.63083 -0.76257,28.96016 -5.41421,22.05025 -6.94454,25.18198 -7.23223,32.65686 -33.6078,-0.0677 -3.09947,-51.24526 z"
                                    className={this.getPartClass("hip_left")} style={this.getPartStyle('hip_left')}
                                    onClick={this.setActivePart('hip_left')}/>
                                <path
                                    d="m 471,72 h 47 l -2,7 -0.625,10.625 L 520,100 510,110 h -32.12105 l -10,-10 L 473,90 V 80 Z"
                                    className={this.getPartClass("neck")} style={this.getPartStyle('neck')}
                                    onClick={this.setActivePart('neck')}/>
                                <path
                                    d="m 547,188 4,-22 7,-10 -20,-31 -28,-15 h -32.12105 l -28,15 -19.31538,29.93883 6.66893,9.52704 4,22 -1,12 L 449,238 l 94,0.25 5,-39 z"
                                    className={this.getPartClass("back")} style={this.getPartStyle('back')}
                                    onClick={this.setActivePart('back')}/>
                                <path
                                    d="m 496.57812,341.66206 -2.82812,5 -5.75,3.125 -9.75,2.125 -15.875,0.25 -16.875,-4.5 -9.5,-10 V 320 l 1,-9 4,-18 15.52597,-5.39169 24.47575,0.0806 L 496.58593,293 Z"
                                    className={this.getPartClass("butt_left")} style={this.getPartStyle('butt_left')}
                                    onClick={this.setActivePart('butt_left')}/>
                                <path
                                    d="m 437.25,387.25 2.5,24.5 5.75,20.25 8.5,22 2,5 36.86409,-1.84321 3.50245,-43.43557 0.33658,-48.43416 -0.125,-23.625 -2.82812,5 -5.75,3.125 -9.75,2.125 -15.875,0.25 -16.875,-4.5 -9.5,-10 z"
                                    className={this.getPartClass("hip_left")} style={this.getPartStyle('hip_left')}
                                    onClick={this.setActivePart('hip_left')}/>
                                <path
                                    d="M 504.00611,582.27145 523.15624,582 l 3,-11 4,-19 8,-28 4,-18 1,-18 -2,-16 -4,-13 -36.88279,-1.84414 -2.61721,18.09414 1.21967,27.13909 3.16421,58.30241 1.96599,21.57995"
                                    className={this.getPartClass("shin_right")} style={this.getPartStyle('shin_right')}
                                    onClick={this.setActivePart('shin_right')}/>
                                <path
                                    d="m 500.1875,603.4375 v -6 l 3.6875,-7.375 0.13111,-7.79105 19.15013,-0.27145 1.03126,8.4375 12,7 1.625,7.625 -9.125,3.625 -23.8125,0.0625 z"
                                    className={this.getPartClass("foot_right")} style={this.getPartStyle('foot_right')}
                                    onClick={this.setActivePart('foot_right')}/>
                                <path
                                    d="m 520,100 13,8 13,5 12,3 8,4 8,8 5,11 3,12 v 10 l -3,7 -21,-12 -20,-31 -28,-15 z"
                                    className={this.getPartClass("shoulder_right")}
                                    style={this.getPartStyle('shoulder_right')}
                                    onClick={this.setActivePart('shoulder_right')}/>
                                <path
                                    d="m 558,156 21,12 5.94454,12.93414 1.49296,21.25336 1.10387,14.39308 L 552,224 l -4,-24 -1,-12 4,-22 z"
                                    className={this.getPartClass("cubit_right")}
                                    style={this.getPartStyle('cubit_right')}
                                    onClick={this.setActivePart('cubit_right')}/>
                                <path
                                    d="M 600,258 595.7019,231.80761 587.54137,216.58058 552,224 552.31574,235.2102 556.25,255.75 566,273 l 5,6 3,6 6,9 8,10 26,-10 -9,-20 z"
                                    className={this.getPartClass("forearm_right")}
                                    style={this.getPartStyle('forearm_right')}
                                    onClick={this.setActivePart('forearm_right')}/>
                                <path
                                    d="m 614,294 -26,10 4,8 -2,9 3,13 2,19 3,9 3.25,5.375 3.75,3.625 5,2 6,2 5,-3 4,-2 3,-8 1,-11 -2,-22 8,9 4.75,-0.75 v -6 L 635,324 l -4,-7 -5,-6 -9,-6 z"
                                    className={this.getPartClass("hand_right")} style={this.getPartStyle('hand_right')}
                                    onClick={this.setActivePart('hand_right')}/>
                                <path
                                    d="M 463.51777,55.494796 463,35 l 8.5,-13.5 13.25,-7 h 19.5 l 13.5,6.5 6.42678,14.59099 -0.0732,18.50736 L 518,72 h -47 z"
                                    className={this.getPartClass("head")} style={this.getPartStyle('head')}
                                    onClick={this.setActivePart('head')}/>
                                <path
                                    d="m 126.53033,549.50952 -7,-23 -5.81802,-34.33103 2.90526,-41.23781 37.94032,-0.2478 1.35372,41.73346 -1.38388,35.48699 -1.9974,36.59619 h -22 z"
                                    className={this.getPartClass("shin_right")} style={this.getPartStyle('shin_right')}
                                    onClick={this.setActivePart('shin_right')}/>
                                <path
                                    d="m 182.58834,564.3531 0.33398,5.83398 0.0723,9.09375 2.06836,3.7793 4.51953,4.52929 7,4 4,5 -1,6 -5.125,2.125 -11.875,0.375 h -13.08792 -3.16208 l -6.1875,-3 -1.21094,-7.93359 3.1047,-8.81005 -1.4504,-20.99268 z"
                                    className={this.getPartClass("foot_left")} style={this.getPartStyle('foot_left')}
                                    onClick={this.setActivePart('foot_left')}/>
                                <path
                                    d="m 467.87895,100 -13,8 -13,5 -12,3 -8,4 -8,8 -5,11 -3,12 v 10 l 3,7 21,-12 20,-31 28,-15 z"
                                    className={this.getPartClass("shoulder_left")}
                                    style={this.getPartStyle('shoulder_left')}
                                    onClick={this.setActivePart('shoulder_left')}/>
                                <path
                                    d="m 430.2325,154.46587 -21,12 -5.94454,12.93414 -1.49296,21.25336 -1.10387,14.39308 35.54137,7.41942 4,-24 1,-12 -4,-22 z"
                                    className={this.getPartClass("cubit_left")} style={this.getPartStyle('cubit_left')}
                                    onClick={this.setActivePart('cubit_left')}/>
                                <path
                                    d="m 388.2325,256.46587 4.2981,-26.19239 8.16053,-15.22703 35.54137,7.41942 -0.31574,11.2102 -3.93426,20.5398 -9.75,17.25 -5,6 -3,6 -6,9 -8,10 -26,-10 9,-20 z"
                                    className={this.getPartClass("forearm_left")}
                                    style={this.getPartStyle('forearm_left')}
                                    onClick={this.setActivePart('forearm_left')}/>
                                <path
                                    d="m 374.2325,292.46587 26,10 -4,8 2,9 -3,13 -2,19 -3,9 -3.25,5.375 -3.75,3.625 -5,2 -6,2 -5,-3 -4,-2 -3,-8 -1,-11 2,-22 -8,9 -4.75,-0.75 v -6 l 4.75,-7.25 4,-7 5,-6 9,-6 z"
                                    className={this.getPartClass("hand_left")} style={this.getPartStyle('hand_left')}
                                    onClick={this.setActivePart('hand_left')}/>
                                <path
                                    d="m 496.57812,341.66206 2.82812,5 5.75,3.125 9.75,2.125 15.875,0.25 16.875,-4.5 9.5,-10 V 320 l -1,-9 -4,-18 -15.52597,-5.39169 -24.47575,0.0806 L 496.58593,293 Z"
                                    className={this.getPartClass("butt_right")} style={this.getPartStyle('butt_right')}
                                    onClick={this.setActivePart('butt_right')}/>
                                <path
                                    d="m 555.90624,387.25 -2.5,24.5 -5.75,20.25 -8.5,22 -2,5 -36.88279,-1.84414 -3.90691,-43.43464 0.0866,-48.43416 0.125,-23.625 2.82812,5 5.75,3.125 9.75,2.125 15.875,0.25 16.875,-4.5 9.5,-10 z"
                                    className={this.getPartClass("hip_right")} style={this.getPartStyle('hip_right')}
                                    onClick={this.setActivePart('hip_right')}/>
                                <path
                                    d="M 489.15009,582.27145 469.99996,582 l -3,-11 -4,-19 -8,-28 -4,-18 -1,-18 2,-16 4,-13 36.86413,-1.84321 2.63587,18.09321 -1.21967,27.13909 -3.16421,58.30241 -1.96599,21.57995"
                                    className={this.getPartClass("shin_left")} style={this.getPartStyle('shin_left')}
                                    onClick={this.setActivePart('shin_left')}/>
                                <path
                                    d="m 492.9687,603.4375 v -6 l -3.6875,-7.375 -0.13111,-7.79105 L 469.99996,582 l -1.03126,8.4375 -12,7 -1.625,7.625 9.125,3.625 23.8125,0.0625 z"
                                    className={this.getPartClass("foot_left")} style={this.getPartStyle('foot_left')}
                                    onClick={this.setActivePart('foot_left')}/>
                                <path
                                    d="m 36.65652,300.08504 24.75391,9.16035 -5.69922,11.10933 0.98633,8.7332 v 0.0225 8.04146 l -3.12891,8.31125 -2.89062,13.8832 -3.06641,6.33286 -8.14258,6.30866 -9.35351,3.68178 -6.25586,-4.61735 -4.16211,-3.68524 v -8.15214 l 1.94336,-15.62292 1.07226,-6.64589 1.83594,-3.25117 -3.99804,4.58277 -6.11719,5.41631 -7.45899,0.94423 -0.17773,-0.20925 -3.2168,-3.79937 14.26953,-18.04748 7.46094,-4.08299 5.44141,-6.42451 z"
                                    className={this.getPartClass("hand_right")} style={this.getPartStyle('hand_right')}
                                    onClick={this.setActivePart('hand_right')}/>
                                <path
                                    d="m 186.41016,102.31055 2.71534,16.31968 15.20458,12.9578 9.4135,21.64424 27.75642,-0.81821 -2.00781,-13.52929 -2.96864,-10.20876 -4.1173,-6.99632 -8.13086,-6.09768 -7.09375,-4.05273 -23.01367,-7.00196 z"
                                    className={this.getPartClass("shoulder_left")}
                                    style={this.getPartStyle('shoulder_left')}
                                    onClick={this.setActivePart('shoulder_left')}/>
                                <path
                                    d="m 125.5625,104.64648 -2.97866,14.12254 -13.59117,12.4921 -13.36557,25.68791 -27.75642,-0.81821 2.00781,-13.52929 2.96864,-10.20876 4.1173,-6.99632 8.13086,-6.09768 7.09375,-4.05273 23.01367,-7.00196 z"
                                    className={this.getPartClass("shoulder_right")}
                                    style={this.getPartStyle('shoulder_right')}
                                    onClick={this.setActivePart('shoulder_right')}/>
                                <g id="face_group" onClick={this.setActivePart('face')}>
                                    <path className={this.getPartClass("face")} style={this.getPartStyle('face')}
                                          d="m 122.5,40.5 v 8.347656 l -2.02148,3.033203 1.0371,7.269532 5.0625,10.125 4.98438,5.980468 3.01367,8.03711 8.17578,7.154297 10.0417,3.012524 9.04619,0.04021 6.04923,-4.320873 6.54843,-5.909596 2.03125,-6.09375 2.96094,-7.898437 3.98047,-3.980469 3.07031,-6.140625 1.0332,-6.197266 -2.02539,-6.074218 L 184.5,40.5 Z"/>
                                    <circle cx="140.8246" cy="56.532829" r="2"/>
                                    <circle cx="170.84433" cy="56.532829" r="2"/>
                                    <path
                                        d="m 163.01073,70.316361 c -5.13701,7.863097 -9.33282,5.144636 -13.31467,0.02077 l 0.97275,-0.707811 c 4.35195,5.065324 6.90928,6.115979 11.45001,0.03723 0.21105,0.152831 0.89476,0.420341 0.89191,0.649851 z"/>
                                </g>
                                <path
                                    d="M 140.32031,10.5625 129.64062,17.152344 123.03033,29.545811 122.5,40.5 h 62 l -0.53033,-11.355002 -6.01482,-12.766775 -9.90968,-5.789835 -13.35762,-2.2001477 z"
                                    className={this.getPartClass("head")} style={this.getPartStyle('head')}
                                    onClick={this.setActivePart('head')}/>
                            </svg>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="h6" className={classes.subheading}>
                                Степень обработки
                            </Typography>
                        </Grid>
                            {medicalCardData.prevVisitData.date &&
                                <Grid item sm={6}>
                                    <SelectFromItemsField
                                        value={medicalCardData.prevVisitData.elaborationDegree}
                                        items={elaborationDegreeTypes}
                                        onChange={this.handleChangeElaborationDegree}
                                        disabled
                                        fullWidth
                                        textField="text"
                                        nullable
                                        nullableText={t("Not chosen")}
                                    />
                                </Grid>
                            }
                        <Grid item sm={6}>
                            <SelectFromItemsField
                                value={medicalCardData.currentVisitData.elaborationDegree}
                                items={elaborationDegreeTypes}
                                onChange={this.handleChangeElaborationDegree}
                                fullWidth
                                textField="text"
                                nullable
                                nullableText={t("Not chosen")}
                            />
                        </Grid>
                    </Grid>
                    <Popover
                        id="simple-popper"
                        open={popoverOpen}
                        anchorEl={popoverTarget}
                        onClose={this.closePopover}
                    >
                        { this.setPopoverContent() }
                    </Popover>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClose(true)}
                        disabled={disabled}
                        color="primary"
                    >{ this.props.startVisit ? t('medicalCard.saveConfirm') : t('medicalCard.saveCard') }</Button>
                    <Button onClick={this.handleClose(false)} color="primary">{t("Cancel")}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
