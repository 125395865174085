import React from 'react';
import {Route, Switch} from "react-router"
import TypesPage from "./TypesPage"
import DeliveryPage from "./DeliveryPage";
import StatusesPage from "./StatusesPage";
import PrioritiesPage from "./PrioritiesPage";
import ListPage from "./ListPage";
import ViewPage from "./ViewPage";

export default class TasksPage extends React.Component {
    render() {
        return <Switch>
            <Route exact path="/tasks" component={ListPage}/>
            <Route       path="/tasks/delivery" component={DeliveryPage}/>
            <Route       path="/tasks/statuses" component={StatusesPage}/>
            <Route       path="/tasks/priorities" component={PrioritiesPage}/>
            <Route       path="/tasks/types" component={TypesPage}/>
            <Route       path="/tasks/view" component={ViewPage}/>
        </Switch>
    }
}
