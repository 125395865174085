import React from 'react';
import * as PropTypes from 'prop-types';
import ShowField from "../../components/fields/ShowField";
import {withTranslation} from "react-i18next";
import moment from "moment";
import {durationAsMMss} from "../../utils/moment-utils";

export default
@withTranslation()
class OrderTimer extends React.Component
{
    static propTypes = {
        durationSeconds: PropTypes.number,
        onTimeOver: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            durationSeconds: props.durationSeconds,
        };
    }

    componentDidMount() {
        if (this.props.durationSeconds > 0) {
            this.startTimer();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.durationSeconds !== this.props.durationSeconds) {
            this.setState({
                durationSeconds: nextProps.durationSeconds,
            }, () => {
                if (this.state.durationSeconds > 0) {
                    this.startTimer();
                } else {
                    this.stopTimer();
                }
            });
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    startTimer() {
        if (!this.timer) {
            this.timer = setInterval(() => this.tick(), 1000);
        }
    }

    stopTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    tick() {
        if (this.state.durationSeconds - 1 > 0) {
            this.setState({
                durationSeconds: this.state.durationSeconds - 1,
            });
        } else {
            this.setState({
                durationSeconds: 0,
            });
            this.stopTimer();
            if (this.props.onTimeOver) {
                this.props.onTimeOver();
            }
        }
    }
    render() {
        const { t } = this.props;
        const { durationSeconds } = this.state;

        return <ShowField
            label = {t("Time to create a visit")}
            value = { durationSeconds > 0 ? durationAsMMss(moment.duration(durationSeconds, 'seconds')) : t("Time is over") }
        />;
    }
}