import React from 'react';
import PropTypes from 'prop-types'
import {Dialog, DialogContent, DialogContentText, DialogTitle, CircularProgress, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";

export default
@withStyles({
    content: {
        textAlign: 'center',
    },
})
@withTranslation()
class ProgressDialog extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        message: PropTypes.node
    }

    static defaultProps = {
        open: true
    }

    render() {
        const { open, classes, message, t } = this.props;

        return (
            <Dialog
                open={open}
                aria-labelledby="progress-dialog-title"
                aria-describedby="progress-dialog-description"
            >
                <DialogTitle id="progress-dialog-title">{t('Please wait')}</DialogTitle>
                <DialogContent className={classes.content}>
                    <DialogContentText id="progress-dialog-description">{message || t('Please wait, accessing the server')}</DialogContentText>
                    <CircularProgress size={50}/>
                </DialogContent>
            </Dialog>
        );
    }
}
