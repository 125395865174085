const dimensions = {
    headerHeight: 40,
    slotHeight: 15,
    slotsInGroup: 4,

    subColumnWidth: 70,
    getColumnWidthWeek: subColumns =>
        ((subColumns && subColumns.length) || 4) * dimensions.subColumnWidth + 1/*Column border*/,
}

export default dimensions;
