import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, MenuItem
} from '@material-ui/core';
import {
    createDepartment, getBusinessUnits,
    getDepartment, getDepartments, getHumanEmployees,
    navLinks,
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    container: {
        width: 900,
        marginBottom: 10,
    },
    cb: {
        marginTop: 10,
    }
});

@withRouter
class DepartmentViewPage extends React.Component {

    state = {
        department: {
            id: null,
            name: null,
            chiefId: null,
            parentId: null,
            businessUnitId: null,
        },
        employees: [],
        departments: [],
        businessUnits: []
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getDepartment(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            department: response.data
                        })
                    }
                });
        }

        getHumanEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: response.data,
                    })
                }
            });

        getDepartments()
            .then(response => {
                if (response.success) {
                    this.setState({
                        departments: response.data,
                    })
                }
            });

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

    }

    handleChange = prop => event => {
        const { department } = this.state;
        this.setState({ department: {...department, [prop]: event.target.value} });
    };

    handleSave = () => {
        createDepartment(this.state.department)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/departments`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes } = this.props;
        const { department, departments, businessUnits, employees } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleSave} disabled={!department.name}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Карточка подразделения">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="name"
                                label="Название"
                                value={department.name}
                                fullWidth
                                error={!department.name}
                                onChange={this.handleChange('name')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="parentId"
                                label="Родительское подразделение"
                                value={department.parentId}
                                onChange={this.handleChange('parentId')}
                                fullWidth
                                select
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                {departments.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="chiefId"
                                label="Начальник подразделения"
                                value={department.chiefId}
                                onChange={this.handleChange('chiefId')}
                                fullWidth
                                select
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                {employees.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="businessUnitId"
                                label="Бизнес юнит"
                                value={department.businessUnitId}
                                onChange={this.handleChange('businessUnitId')}
                                fullWidth
                                select
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(withStyles(styles)(DepartmentViewPage));
