import React, {useState, useEffect, useCallback} from "react";
import {useParams, useHistory, useRouteMatch} from "react-router-dom";
import AnyField from "../../../components/fields/AnyField";
import {Grid} from "@material-ui/core";
import Content from "../../../components/content/Content";
import LeftBar from "../../../components/left-bar/LeftBar";
import NavigationLinks from "../../../components/navigation-links/NavigationLinks";
import ActionButton from "../../../components/button/ActionButton";
import {
    navLinks, getExport, createExport, updateExport, deleteExport, executeExport
} from "../../../services/settings";
import {connect, useDispatch} from "react-redux";
import infoActions from "../../../components/info/info-actions";
import ShowField from "../../../components/fields/ShowField";
import moment from "moment";
import CheckboxField from "../../../components/fields/CheckboxField";
import cronParser from "cron-parser";
import {withTranslation} from "react-i18next";
import confirmDialogActions from "../../../components/dialogs/confirmDialog-acions";

function ExportDetailsPage(props) {
    const {id} = useParams();
    const history = useHistory();

    const { showConfirm} = props

    const [exportState, setExport] = useState({
        id: "",
        name: "",
        active: false,
        schedule: "* * * * *",
        description:"",
        request: "",
        link: "",
        creator: "",
        createdAt: ""
    });
    const [canSaveExport, setCanSaveExport] = useState({
       scheduleFormatted: true
    });
    const [initialExport, setInitialExport] = useState({});
    const [isEditing, setIsEditing] = useState(id === "create");

    const dispatch = useDispatch();
    const showInfo = useCallback((message) => dispatch(infoActions.show(message)), [dispatch]);
    const showError = useCallback((message) => dispatch(infoActions.showError(message)), [dispatch]);

    const [queryDuration, setQueryDuration] = useState(0);
    const [response, setResponse] = useState("");
    const [responseCount, setResponseCount] = useState(0);

    const fetchExport = useCallback(async () => {
        try {
            const fetchedExport = await getExport(id);
            const formattedCreatedAt = moment(fetchedExport.data.createdAt).format("DD.MM.YYYY HH:mm");
            setExport({
                ...fetchedExport.data,
                createdAt: formattedCreatedAt
            });
            setInitialExport({
                ...fetchedExport.data,
                createdAt: formattedCreatedAt
            });
        } catch (error) {
            console.error("Error fetching export:", error);
            showError("Произошла ошибка при получении данных экспорта.");
        }
    }, [id, showError]);

    useEffect(() => {
        if (id !== "create") {
            fetchExport();
        }
    }, [id, fetchExport]);

    const handleChangeSchedule = (e) =>{
            const {name, value} = e.target;
        setExport((prevExport) => ({
            ...prevExport,
            [name]: value
        }));
        try {
            cronParser.parseExpression(value)
            isScheduleComplete(value)
        }catch (err){
            console.log('Parse Cron Error:' + err.message)
            setCanSaveExport((prevState)=>({
                ...prevState,
                scheduleFormatted: false
            }));
            return
        }
        setCanSaveExport((prevState)=>({
            ...prevState,
            scheduleFormatted: true
        }));

    };

    /** То есть 5 выражений, между ними только 4 пробела.
     *  Regex для Расписания Cron-tab
     */
    const isScheduleComplete = (value) => {
        let regExp = /^\S+\s\S+\s\S+\s\S+\s\S+$/
        if(!regExp.test(value)){
            throw new Error('Schedule doesnt applied to expression rules')
        }
    }

    const handleChange = (name) => (e) =>{
        const value = e.target.value;

        setExport((prevExport) => ({
            ...prevExport,
            [name]: value
        }));
    };

    const handleChangeRequestField = (e) => {
        const {name, value} = e.target;
        const lowerCaseValue = value.toLowerCase();
        const forbiddenCommands = ["insert", "delete", "truncate", "drop", "alter", "references"];
        const containsForbiddenCommand = forbiddenCommands.some(command =>
            lowerCaseValue.includes(command)
        );

        if (containsForbiddenCommand) {
            showError("Введена недопустимая SQL команда");
            return;
        }

        if (name === "request" && value.length > 65000) {
            showError("Превышен лимит в 65000 символ");
            return;
        }

        setExport((prevExport) => ({
            ...prevExport,
            [name]: value
        }));
    };

    const handleChangeDescriptionField = (e) => {
        const {name, value} = e.target;

        setExport((prevExport) => ({
            ...prevExport,
            [name]: value
        }));
    };

    const handleChangeNameField = (e) => {
        const {name, value} = e.target;
        const allowedCharsRegex = /^[a-z0-9_]*$/;

        if (allowedCharsRegex.test(value)) {
            setExport((prevExport) => ({
                ...prevExport,
                [name]: value
            }));
        } else {
            let invalidChar = value.match(/[^a-z0-9_]/)[0];

            if (invalidChar === ' ') {
                invalidChar = 'Пробел';
            }

            const errorMessage = `Недопустимый символ: ${invalidChar}`;

            showError(errorMessage);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };



    const executeRequest = async () => {
        try {
            const startTime = Date.now();
            const response = await executeExport(exportState.id);

            if (response && response.data) {
                const responseData = response.data;
                console.log('response')
                console.log(response)
                console.log('responseData')
                console.log(responseData)
                const responseString = responseData.map((item) => {
                    console.log('item')
                    console.log(item)
                    // return `${item.phone}`;
                    return Object.values(item).join(' | ');
                }).join("\n");

                setResponse(responseString);
                setResponseCount(responseData.length);

                const endTime = Date.now();
                setQueryDuration((endTime - startTime) / 1000);
            } else {
                showError("Произошла ошибка при выполнении запроса: " + response.error.message);
            }
        } catch (error) {
            console.error("Error executing request", error);

            if (response.error && response.error.message) {
                showError("Произошла ошибка при выполнении запроса: " + response.error.message);
            }
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setExport(initialExport);
        if (!exportState.name && !exportState.description && !exportState.request) {
            history.push({
                pathname: `/settings/exports`,
            });
        }
    };

    const match = useRouteMatch();
    const currentPath = match.url;

    const handleSave = async () => {
        try {
            let response;

            if (!exportState.name || !exportState.description || !exportState.request) {
                showError("Заполните все поля");
                return;
            }

            if (exportState.request.length > 65000) {
                showError("Превышен лимит в 65000 символ");
                return;
            }

            const lowerCaseValue = exportState.request.toLowerCase();

            const forbiddenCommands = ["insert", "delete", "truncate", "drop", "alter", "references"];
            const containsForbiddenCommand = forbiddenCommands.some(command =>
                lowerCaseValue.includes(command)
            );

            const forbiddenCommandsRegex = /\b(create|update)\b/;
            const containsForbiddenCommandRegex = forbiddenCommandsRegex.test(lowerCaseValue);

            if (containsForbiddenCommand || containsForbiddenCommandRegex) {
                showError("Введена недопустимая SQL команда");
                return;
            }

            if (id === "create") {
                response = await createExport(exportState);
            } else {
                response = await updateExport(exportState);
            }

            if (response && response.success && response.data && response.data.id) {
                const newId = response.data.id;
                const newPath = currentPath.replace("create", newId);
                history.push(newPath);

                showInfo("Export успешно сохранен");
            } else {
                showError("Произошла ошибка при сохранении экспорта: " + response.error.message);
                return;
            }

            setIsEditing(false);
        } catch (error) {
            console.error("Error saving export:", error);
            showError("Произошла ошибка при сохранении экспорта: " + response.error.message);
        }
    };

    const handleBack = () => {
        history.push({
            pathname: `/settings/exports`,
        });
    };

    const confirmDelete = () => {
        showConfirm({
            message: 'Уверены что хотите удалить',
            title: 'Удаление',
            onClose: handleDelete
        })
    }

    const handleDelete = (ok) => {
        if(ok) {
            try {
                deleteExport(exportState.id)
                    .then(response => {
                        if (response.success) {
                            showInfo(`Экспорт ${exportState.name} удален`)
                            history.push('/settings/exports')
                        } else {
                            showError("Произошла ошибка при удалении экспорта.");
                        }
                    });
            } catch (error) {
                console.error("Error deleting export:", error);
            }
        }
    };

    const canSaveExportCheck = () =>{
        const {name, link, description, request} = exportState
        return canSaveExport.scheduleFormatted && name && link && description && request
    }

    return (
        <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                Экспорт в гугл-таблицу
                <ShowField
                    label="Создатель"
                    value={exportState.creator}
                />
                <ShowField
                    label="Дата создания"
                    value={exportState.createdAt}
                />
                {isEditing ? (
                    <>
                        <ActionButton onClick={handleSave} disabled={!canSaveExportCheck()}>Сохранить Экспорт</ActionButton>
                        <ActionButton onClick={handleCancel}>Отмена редактирования</ActionButton>
                    </>
                ) : (
                    <>
                        <ActionButton onClick={handleEdit}>Редактировать</ActionButton>
                        <ActionButton onClick={handleBack}>Назад</ActionButton>
                        <ShowField></ShowField>
                        <ActionButton onClick={confirmDelete}>Удалить</ActionButton>
                    </>
                )}
            </LeftBar>

            <Content title="Экспорт в гугл-таблицу">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AnyField
                            label="Описание"
                            value={exportState.description}
                            name="description"
                            onChange={handleChangeDescriptionField}
                            readOnly={!isEditing}
                            fullWidth
                            inputProps={{style: {color: isEditing ? "black" : "gray"}}}
                            required
                            autoFocus
                        />
                        <AnyField
                            label="SQL-запрос"
                            value={exportState.request}
                            name="request"
                            onChange={handleChangeRequestField}
                            readOnly={!isEditing}
                            inputProps={{
                                style: {height: "250px", color: isEditing ? "black" : "gray"}
                            }}
                            fullWidth
                            multiline
                            rows={4}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnyField
                            label="Название"
                            value={exportState.name}
                            name="name"
                            onChange={handleChangeNameField}
                            readOnly={!isEditing}
                            fullWidth
                            inputProps={{style: {color: isEditing ? "black" : "gray"}}}
                            required
                        />

                        <AnyField
                            label="Ссылка"
                            value={exportState.link}
                            name="link"
                            onChange={handleChange('link')}
                            readOnly={!isEditing}
                            fullWidth
                            inputProps={{style: {color: isEditing ? "black" : "gray"}}}
                            required
                        />
                        <CheckboxField
                            label="Активный"
                            value={exportState.active}
                            onChange={handleChange('active')}
                            disabled={!isEditing}
                            required
                        />
                        <AnyField
                            label="Расписание (по UTC)"
                            value={exportState.schedule}
                            name="schedule"
                            onChange={handleChangeSchedule}
                            readOnly={!isEditing}
                            error={!canSaveExport.scheduleFormatted}
                            inputProps={{style: {color: isEditing ? "black" : "gray"}}}
                            required
                        />
                        <ActionButton onClick={executeRequest} disabled={isEditing}>
                            Запустить SQL-запрос
                        </ActionButton>
                        <div style={{marginTop: "10px", position: "relative"}}>
                            <ShowField>
                                Время выполнения запроса: {queryDuration} сек.
                            </ShowField>
                            {response ?
                                <ShowField>Количество элементов в ответе: {responseCount}</ShowField> :
                                <ShowField>Количество элементов в ответе: 0</ShowField>}
                        </div>
                        <AnyField
                            label="Ответ по запущенному SQL-запросу"
                            value={response}
                            name="response"
                            readOnly
                            inputProps={{style: {height: "250px", color: "gray"}}}
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </Content>
        </React.Fragment>
    );
}

export default withTranslation()(connect(state => ({}), {
    showConfirm: confirmDialogActions.show
})(ExportDetailsPage));