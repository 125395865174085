import {dataTableGroupValueStrategy} from "../../../../../services/common";

const income = (currentVal, row) => {
    return {
        visit_total: dataTableGroupValueStrategy.arrayMoneySum('visit_total')(
            currentVal ? currentVal.visit_total : null,
            row.income
        ),
        goods_total: dataTableGroupValueStrategy.arrayMoneySum('goods_total')(
            currentVal ? currentVal.goods_total : null,
            row.income
        ),
        gratuity_total: dataTableGroupValueStrategy.arrayMoneySum('gratuity_total')(
            currentVal ? currentVal.gratuity_total : null,
            row.income
        ),
        gratuity_cash: dataTableGroupValueStrategy.arrayMoneySum('gratuity_cash')(
            currentVal ? currentVal.gratuity_cash : null,
            row.income
        ),
        gratuity_cashless: dataTableGroupValueStrategy.arrayMoneySum('gratuity_cashless')(
            currentVal ? currentVal.gratuity_cashless : null,
            row.income
        ),
        gratuity_paydoc_total: dataTableGroupValueStrategy.arrayMoneySum('gratuity_paydoc_total')(
            currentVal ? currentVal.gratuity_paydoc_total : null,
            row.income
        ),
        gratuity_paydoc_own: dataTableGroupValueStrategy.arrayMoneySum('gratuity_paydoc_own')(
            currentVal ? currentVal.gratuity_paydoc_own : null,
            row.income
        ),
        gratuity_paydoc_other: dataTableGroupValueStrategy.arrayMoneySum('gratuity_paydoc_other')(
            currentVal ? currentVal.gratuity_paydoc_other : null,
            row.income
        ),
        total: dataTableGroupValueStrategy.arrayMoneySum('total')(
            currentVal ? currentVal.total : null,
            row.income
        ),
        spendingTransferIncomeTotal: dataTableGroupValueStrategy.arrayMoneySum('spendingTransferIncomeTotal')(
            currentVal ? currentVal.spendingTransferIncomeTotal : null,
            row.income
        ),
        spendingTransferIncomeDetails: dataTableGroupValueStrategy.appendArray('spendingTransferIncomeDetails')(
            currentVal?.spendingTransferIncomeDetails,
            row.income
        ),
        paydoc_comission: dataTableGroupValueStrategy.arrayMoneySum('paydoc_comission')(
            currentVal ? currentVal.paydoc_comission : null,
            row.income
        ),
        paydoc_burned_payments_owner: dataTableGroupValueStrategy.arrayMoneySum('paydoc_burned_payments_owner')(
            currentVal ? currentVal.paydoc_burned_payments_owner : null,
            row.income
        ),
        paydoc_burned_payments_other: dataTableGroupValueStrategy.arrayMoneySum('paydoc_burned_payments_other')(
            currentVal ? currentVal.paydoc_burned_payments_other : null,
            row.income
        ),
        paydoc_burned: dataTableGroupValueStrategy.arrayMoneySum('paydoc_burned')(
            currentVal ? currentVal.paydoc_burned : null,
            row.income
        ),
        details: dataTableGroupValueStrategy.appendArray('details')(
            currentVal ? currentVal.details: [],
            row.income
        ),
        vgo: dataTableGroupValueStrategy.arrayMoneySum('vgo')(
            currentVal ? currentVal.vgo: [],
            row.income
        ),
    };
};

export default income;
