import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from "classnames";
import {withStyles, FormControl, InputLabel} from '@material-ui/core';
import Select, {components, createFilter} from 'react-select';
import {purple, red} from '@material-ui/core/colors';
import {emptyFieldColor, fillFieldColor} from "../../services/constants"
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {withTranslation} from "react-i18next";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowDropDown/>
        </components.DropdownIndicator>
    );
};

export default
@withStyles(theme => ({
    root: {

    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
}))
@withTranslation()
class AutocompleteMultiSelectField extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        fullWidth: PropTypes.bool,
        label: PropTypes.string,
        options: PropTypes.array,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        style: PropTypes.any,
        name: PropTypes.string,
        isClearable: PropTypes.bool,
        error: PropTypes.bool,
        valueField: PropTypes.string,
        textField: PropTypes.string,
        selectStyles: PropTypes.any,
        saveSelectedOrder: PropTypes.bool
    };

    static defaultProps = {
        fullWidth: false,
        required: false,
        disabled: false,
        readOnly: false,
        placeholder: '',
        isClearable: false,
        valueField: 'id',
        textField: 'name',
        selectStyles: {},
        saveSelectedOrder: false
    };

    handleChange = (selectedOptions) => {
        const { onChange, name } = this.props;
        if (onChange) {
            let value = [];
            if (selectedOptions && selectedOptions.length) {
                value = selectedOptions.map(option => this.getOptionValue(option));
            }
            onChange({ target: { name, value }});
        }
    }

    handleNoOptions = () => {
        const { t } = this.props;

        return t("Not found");
    }

    getOptionLabel = option => {
        const { textField } = this.props;
        return option[textField];
    }

    getOptionValue = option => {
        const { valueField } = this.props;
        return option[valueField];
    }

    render() {
        const {
            classes,
            className,
            value,
            placeholder,
            fullWidth,
            label,
            options,
            required,
            disabled,
            readOnly,
            style,
            isClearable,
            error,
            selectStyles,
            saveSelectedOrder
        } = this.props;

        let selectedValue = []

        if (value && value.length && options && options.length) {
            if (saveSelectedOrder) {
                value.forEach(id => {
                    let option = options.find(option => this.getOptionValue(option) === id)
                    if (option) {
                        selectedValue.push(option)
                    }
                })
            } else {
                selectedValue = options.filter(option => value.indexOf(this.getOptionValue(option)) !== -1)
            }
        }

        return (
            <FormControl className={classNames(classes.root,{[classes.withLabel]: label}, className)} fullWidth={fullWidth} style={style}>
                {label ? <InputLabel variant="outlined" required={required} shrink error={error}>{label}</InputLabel> : null}
                <Select
                    value={selectedValue}
                    onChange={this.handleChange}
                    placeholder={placeholder}
                    isMulti
                    isSearchable
                    options={options}
                    isDisabled={disabled || readOnly}
                    noOptionsMessage={this.handleNoOptions}
                    getOptionValue={this.getOptionValue}
                    getOptionLabel={this.getOptionLabel}
                    filterOption={createFilter({stringify: option => "".concat(option.label, " ")})}
                    openMenuOnClick={false}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary: purple[500],
                            primary75: purple[400],
                            primary50: purple[300],
                            primary25: purple[200],
                            danger: red[500],
                            dangerLight: red[300],
                        },
                        spacing: {
                            ...theme.spacing,
                            controlHeight: 35,
                        },
                    })}
                    styles={{
                        control: (styles, {isDisabled, isFocused, theme: { colors } }) => ({
                            ...styles,
                            backgroundColor: (value === null || value === '' || (Array.isArray(value) && value.length === 0)) ? emptyFieldColor : fillFieldColor,
                            borderColor: isDisabled ? 'rgba(0, 0, 0, 0.23)' : error ? '#f44336' : isFocused ? colors.primary : 'rgba(0, 0, 0, 0.23)',

                            '&:hover': {
                                borderColor: error ? '#f44336' : isFocused ? colors.primary : '#000000',
                            },
                        }),
                        dropdownIndicator: (styles) => ({
                            ...styles,
                            padding: 4,
                            paddingRight: 0,
                            color: 'rgba(0, 0, 0, 0.54)',
                        }),
                        indicatorSeparator: styles => ({
                            ...styles,
                            display: 'none',
                        }),
                        menu: styles => ({
                            ...styles,
                            zIndex: 10,
                        }),
                        clearIndicator: styles => ({
                            ...styles,
                            padding: 4,
                        }),
                        ...selectStyles
                    }}
                    components={{
                        DropdownIndicator,
                    }}
                    isClearable={isClearable}
                />
            </FormControl>
        )
    }
}
