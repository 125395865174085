import React from "react";
import * as PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles, FormControl, InputLabel } from "@material-ui/core";
import Select, { components, createFilter } from "react-select";
import { purple, red } from "@material-ui/core/colors";
import { emptyFieldColor, fillFieldColor } from "../../services/constants";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { withTranslation } from "react-i18next";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowDropDown/>
        </components.DropdownIndicator>
    );
};

export default @withStyles(theme => ({
    root: {},
    withLabel: {
        marginTop: theme.spacing(3),
    },
}))
@withTranslation()
class AutocompleteSelectField extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        fullWidth: PropTypes.bool,
        label: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        style: PropTypes.any,
        name: PropTypes.string,
        isClearable: PropTypes.bool,
        error: PropTypes.bool,
        menuPosition: PropTypes.string,
    };

    static defaultProps = {
        fullWidth: false,
        required: false,
        disabled: false,
        readOnly: false,
        placeholder: "",
        isClearable: false,
    };

    handleChange = (selectedOption) => {
        const { onChange, name } = this.props;

        if (onChange) {
            let value = null;
            if (selectedOption) {
                value = selectedOption.value;
            }
            onChange({ target: { name, value } });
        }
    };

    handleNoOptions = () => {
        const { t } = this.props;

        return t("Not found");
    };

    render() {
        const {
            classes,
            className,
            value,
            placeholder,
            fullWidth,
            label,
            options,
            required,
            disabled,
            readOnly,
            style,
            isClearable,
            error,
            menuPosition,
        } = this.props;

        return (
            <FormControl className={classNames(classes.root, { [classes.withLabel]: label }, className)}
                         fullWidth={fullWidth} style={style}>
                {label ?
                    <InputLabel variant="outlined" required={required} shrink error={error}>{label}</InputLabel> : null}
                <Select
                    value={options?.find(item => item.value === value) || null}
                    onChange={this.handleChange}
                    placeholder={placeholder}
                    isSearchable
                    options={options}
                    isDisabled={disabled || readOnly}
                    noOptionsMessage={this.handleNoOptions}
                    filterOption={createFilter({ stringify: option => "".concat(option.label, " ") })}
                    openMenuOnClick={false}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary: purple[500],
                            primary75: purple[400],
                            primary50: purple[300],
                            primary25: purple[200],
                            danger: red[500],
                            dangerLight: red[300],
                        },
                        spacing: {
                            ...theme.spacing,
                            controlHeight: 35,
                        },
                    })}
                    styles={{
                        control: (styles, { isDisabled, isFocused, theme: { colors } }) => ({
                            ...styles,
                            backgroundColor: (value === null || value === "" || (Array.isArray(value) && value.length === 0)) ? emptyFieldColor : fillFieldColor,
                            borderColor: isDisabled ? "rgba(0, 0, 0, 0.23)" : error ? "#f44336" : isFocused ? colors.primary : "rgba(0, 0, 0, 0.23)",

                            "&:hover": {
                                borderColor: error ? "#f44336" : isFocused ? colors.primary : "#000000",
                            },
                        }),
                        dropdownIndicator: (styles) => ({
                            ...styles,
                            padding: 4,
                            paddingRight: 0,
                            color: "rgba(0, 0, 0, 0.54)",
                        }),
                        indicatorSeparator: styles => ({
                            ...styles,
                            display: "none",
                        }),
                        menu: styles => ({
                            ...styles,
                            zIndex: 10,
                        }),
                        clearIndicator: styles => ({
                            ...styles,
                            padding: 4,
                        }),
                    }}
                    components={{
                        DropdownIndicator,
                    }}
                    isClearable={isClearable}
                    menuPosition={menuPosition}
                />
            </FormControl>
        );
    }
}
