import React from "react";
import * as PropTypes from "prop-types";
import {durationAsHHmm} from "../../utils/moment-utils";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import ScheduleTimeSlot from "./ScheduleTimeSlot";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import {transferMasterColor, waitingMasterColor} from "../../services/calendar";

const VAR_HEADER = 'header';
const VAR_COLUMN = 'column';

export default
@withStyles(theme => ({
    root: {
        zIndex: 2,
    },
    label: {
        paddingLeft:  theme.spacing(1),
        paddingRight:  theme.spacing(2),
    },
}))
@withTranslation()
class ScheduleTimeSlots extends React.PureComponent
{
    static propTypes = {
        timeZone: PropTypes.string,
        showCurrentTime: PropTypes.bool,
        enabledSlots: PropTypes.array,
        disabledSlots: PropTypes.array,
        slots: PropTypes.array,
        slotHeight: PropTypes.number,
        slotsInGroup: PropTypes.number,
        variant: PropTypes.oneOf([VAR_HEADER, VAR_COLUMN]),
        onSlotClick: PropTypes.func,
        onSlotDbClick: PropTypes.func,
        onSlotDrop: PropTypes.func,
        onSlotDropHover: PropTypes.func,
        columnIdx: PropTypes.number,
        onSlotContextMenu: PropTypes.func,
        yellow: PropTypes.bool,
        transfer: PropTypes.bool,
        lines: PropTypes.array,
        markAdminGapSlots: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
        column: PropTypes.any,
        lineColor: PropTypes.string
    };

    static defaultProps = {
        variant: VAR_COLUMN,
        showCurrentTime: false,
        yellow: false,
        transfer: false,
        lines: [],
        markAdminGapSlots: false,
        onSlotDrop: (column, slot) => ({slot, column}),
        lineColor: null
    };

    renderSlot = (slot, slotIdx) => {
        const {
            classes, variant, slotHeight, slotsInGroup, slots,
            columnIdx, timeZone, showCurrentTime, enabledSlots, disabledSlots, t,
            yellow, lines, markAdminGapSlots, lineColor, transfer
        } = this.props;
        let slotValue = null;
        const beginGroup = slotIdx % slotsInGroup === 0;
        if (variant === VAR_HEADER && beginGroup) {
            const nextGroupSlot = slotIdx + slotsInGroup;
            const end = nextGroupSlot < slots.length ? slots[nextGroupSlot] : slots[slots.length-1];
            const dayStart = moment().utcOffset(timeZone).startOf('day');
            const isNow =showCurrentTime && moment().isBetween(dayStart.clone().add(slot),dayStart.clone().add(end));
            slotValue = <Typography color={isNow ? 'primary' : 'initial'} className={classes.label}>{durationAsHHmm(slot)}</Typography>;
        }
        let disabled = false;
        let disabledTitle = null;
        if (enabledSlots && enabledSlots.length) {
            if (enabledSlots.findIndex(item => item.startDuration <= slot && slot < item.endDuration) < 0) {
                disabled = true;
            }

            if (disabled && disabledSlots && disabledSlots.length) {
                // eslint-disable-next-line eqeqeq
                const dFound = disabledSlots.find(item => item.startDuration == slot);
                if (dFound) {
                    disabledTitle = dFound.activity === 'working' ? dFound.parlour.name : dFound.comment ? t('Break') + ' ' + dFound.comment : t('Break');
                }
            }
        } else if (disabledSlots && disabledSlots.length) {
            disabled = true;
        }

        let bgColor = null;
        if (lines.length) {
            let line = lines.find(line => slot.hours() === line.time.hours() && slot.minutes() === line.time.minutes());
            if (line) {
                bgColor = line.color;
            }
        }

        if (!bgColor && yellow) {
            bgColor = waitingMasterColor;
        }

        if (!bgColor && transfer) {
            bgColor = transferMasterColor;
        }

        if (!bgColor && lineColor) {
            bgColor = lineColor;
        }

        let markGap = false;
        if (markAdminGapSlots && Array.isArray(markAdminGapSlots)) {
            if (markAdminGapSlots.findIndex(item => item.startDuration <= slot && slot < item.endDuration) < 0) {
                markGap = true;
            }
        }

        return <ScheduleTimeSlot
            disabled={disabled}
            disabledTitle={disabledTitle}
            key={slotIdx}
            slot={slot}
            beginGroup={beginGroup}
            slotHeight={slotHeight}
            onSlotClick={this.handleSlotClick}
            onSlotDbClick={this.handleSlotDbClick}
            onSlotContextMenu={this.handleSlotContextMenu}
            variant={variant}
            onSlotDrop={this.handleSlotDrop}
            onSlotDropHover={this.handleSlotDropHover}
            columnIdx={columnIdx}
            bgColor={bgColor}
            bgImage={markGap ? 'url(/img/blue-dot-alpha20.png)' : null}
            activity={this.getActivity(slot, timeZone)}
        >{slotValue}</ScheduleTimeSlot>
    }

    render() {
        const { slots, classes } = this.props;
        return <div className={classes.root} >{slots.map(this.renderSlot)}</div>
    }

    getActivity(slot, timeZone = '+00:00') {
        const { column } = this.props;

        if (!column?.workShift) return null;

        return column.workShift.find(ws => {
            const timeStart = moment(ws.timeStart);
            const timeEnd = moment(ws.timeEnd);
            const startDayUtc = moment.utc(timeStart.format('YYYY-MM-DD')).startOf('day');

            const workShiftTimeStartFormat = moment(timeStart.format());
            const workShiftTimeEndFormat = moment(timeEnd.format());
            const slotTimeFormat = moment(startDayUtc.add(slot).format('YYYY-MM-DDTHH:mm:ss') + timeZone);
    
            return slotTimeFormat.isBetween(workShiftTimeStartFormat, workShiftTimeEndFormat) || 
                slotTimeFormat.isSame(workShiftTimeStartFormat);
        })?.activity;
    }

    handleSlotClick = (slot, event) => {
        const { column, onSlotClick } = this.props;
        if (onSlotClick) {
            onSlotClick(column, slot, event);
        }
    }

    handleSlotDbClick = (slot, event) => {
        const { column, onSlotDbClick } = this.props;
        if (onSlotDbClick) {
            onSlotDbClick(column, slot, event);
        }
    }

    handleSlotContextMenu = (slot, event) => {
        const { column, onSlotContextMenu } = this.props;
        if (onSlotContextMenu) {
            onSlotContextMenu(column, slot, event);
        }
    }

    handleSlotDropHover = (slot, event, ids) => {
        const { column, onSlotDropHover } = this.props;
        if (onSlotDropHover) {
            onSlotDropHover(slot, column, event, ids);
        }
    }

    handleSlotDrop = (slot) => {
        const { column, onSlotDrop } = this.props;
        if (onSlotDrop) {
            onSlotDrop(column, slot);
        }
    }
}
