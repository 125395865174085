import React from 'react';
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {withTranslation} from "react-i18next";
import {getReportPayDocDelivery, navLinks} from "../../services/payDocs";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import SearchField from "../../components/fields/SearchField";

export default
@withTranslation()
class PayDocReportDeliveryPage extends React.Component
{
    columnData = [
        { id: 'num', numeric: false, disablePadding: false, label: 'Identifier' },
        { id: 'phone', numeric: false, disablePadding: false, label: 'Guest phone' },
        { id: 'fio', numeric: false, disablePadding: false, label: 'Full name', linkTemplate: payDoc => `/guests/view/${payDoc.guestId}` },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'payDocDeliveryCash', numeric: false, disablePadding: false, label: 'pay.types.cash' },
        { id: 'payDocDeliveryCashless', numeric: false, disablePadding: false, label: 'pay.types.cashless' },
        { id: 'parlour', label: 'Parlour' },
    ];

    state = {
        refreshTable: false,
        search: ''
    };

    render() {
        const {t} = this.props;
        const {refreshTable, search} = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search || ''}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label={t("Search for payment documents")}
                    />
                </LeftBar>
                <Content title={t("Payment documents")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/pay-docs/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        )
    };

    getTableData = (...params) => {
        const {search} = this.state;
        //return getPayDocs(search, null, null, payDocStatuses.delivery_guest, null, ...params);
        return getReportPayDocDelivery(search, ...params);
    };

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleClickSearch = () => {
        this.setState(state => ({refreshTable: !state.refreshTable, page: 0}));
    };
}