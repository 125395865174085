import React from 'react';
import {getHistoryBalance, getPayDoc, navLinks} from "../../services/payDocs";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import DataTable from "../../components/data-table/DataTable";
import LeftBar from "../../components/left-bar/LeftBar";
import Content from "../../components/content/Content";
import HistoryLeftFields from "./HistoryLeftFields";
import {getFalseResult} from "../../services/common";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {getSalableEntityLink, salableEntityClasses} from "../../services/sale";
import {hasRole} from "../../services/user";

function actionProcessValue(_, t, row) {
    const value = [];

    if (row.visits && row.visits.length) {
        row.visits.forEach(visitId => {
            value.push(<Link key={visitId} to={`/orders/view/${visitId}`}>{t('Visit')}</Link>);
        })
    }
    if (row.goodStorageSales && row.goodStorageSales.length) {
        row.goodStorageSales.forEach(gssId => {
            value.push(<Link key={gssId} to={`/goods/sell/view/${gssId}`}>{t('Sale')}</Link>);
        })
    }

    if (row.refillPayments && row.refillPayments.length) {
        if (hasRole('ROLE_PAYDOC_PAYDOC_RECHARGE_GET')) {
            value.push(<Link key={row.payDocRefillId}
                to={getSalableEntityLink(salableEntityClasses.payDocRefill, row.payDocRefillId)}>{t('View')}</Link>);
        }

        row.refillPayments.forEach(payment => {
            value.push(<div>{payment.businessUnitName} {t(`pay.types.${payment.type}`)}: {payment.amount}</div>)
        })
    }

    if (row.refundDealId) {
        value.push(<Link key={row.refundDealId}
            to={getSalableEntityLink(salableEntityClasses.payDocRefundDeal, row.refundDealId)}>{t('View')}</Link>);
    }

    if (value.length) {
        value.unshift(<span key="empty">: </span>)
    }

    if (row.saleId && hasRole('ROLE_UI_TAB_TOOLS')) {
        value.unshift(<Link key={row.saleId} to={`/tools/sales/${row.saleId}`}>{row.tran}</Link>)
    } else {
        value.unshift(<span key={row.tran}>{row.tran}</span>);
    }

    return value;
}

const columnData = [
    { id: 'dt', label: 'Date and time', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
    { id: 'amount', label: 'Sum' },
    { id: 'user', label: 'User' },
    { id: 'tran', label: 'Action', processValue: actionProcessValue },
    { id: 'businessUnit', label: 'Parlour' },
    { id: 'juridicalPersonName', label: 'Juridical person'},
    { id: 'arrivedInCheckingAccountAt', label: 'Date arrive in checking account', minWidth: 215},
    { id: 'comment', label: 'Comment' },
];

@withRouter
@withTranslation()
class HistoryBalancePage extends React.Component {

    state = {
        id: '',
        payDoc: {
            id: null,
            num: null,
            type: null,
            statusId: null,
            status: null,
            place: null,
            pin: null,
            phone: null,
            fio: null,
            balance: null,
            validity: null,
            canRefill: false,
        },
        refreshTable: false,
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            this.setState({id: resultId[0], refreshTable: !this.state.refreshTable});

            getPayDoc(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            payDoc: response.data
                        })
                    }
                });
        }
    }

    getTableData = (...params) => {
        return this.state.id ? getHistoryBalance(this.state.id, ...params) : getFalseResult();
    };

    render() {
        const { t } = this.props;
        const { payDoc, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("History of replenishment and expenditure")}/>}>
                    <HistoryLeftFields payDoc={payDoc}/>
                </LeftBar>
                <Content title={t("History of replenishment and expenditure")}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default HistoryBalancePage;
