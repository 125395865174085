import React from 'react';
import Select, { components } from 'react-select';

import {withTranslation} from "react-i18next";
import * as PropTypes from 'prop-types';
import {
    withStyles,
    FormControl,
    InputLabel, 
    Icon, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    MenuItem,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import classNames from "classnames";
import DialogButton from "../button/DialogButton";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {purple} from '@material-ui/core/colors';
import {connect} from "react-redux";
import AnyField from './AnyField';
import AutocompleteSelectField from './AutocompleteSelectField';
import { 
    getJuridicalPersons,
    getBusinessUnits, 
    saveExternalSubject,
    getExternalSubjects 
} from '../../services/organization';
import messageDialogActions from "../../components/dialogs/messageDialog-acions";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowDropDown/>
        </components.DropdownIndicator>
    );
};

const Control = ({ children, ...props }) => {
    const { onAddClick, classes } = props.selectProps;
    return (
        <components.Control {...props}>
            <span onMouseDown={onAddClick} className={classNames(classes.addBtn)}>
                <Icon>add</Icon>
            </span>
            {children}
        </components.Control>
    );
};

const styles = theme => ({
    root: {
        marginTop:  theme.spacing(3),
    },
    addBtn: {
        color: '#11cb5f',
        cursor: 'pointer',
        marginTop: 5,
        display: 'block',
    }
});


export default
@withTranslation()
@connect(null, {showMessage: messageDialogActions.show})
@withStyles(styles)
class SelectCreateField extends React.PureComponent
{    
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        fullWidth: PropTypes.bool,
        label: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.any, label: PropTypes.string})),
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        style: PropTypes.any,
        name: PropTypes.string,
        isClearable: PropTypes.bool,
        error: PropTypes.bool,
    };

    static defaultProps = {
        fullWidth: false,
        required: false,
        disabled: false,
        readOnly: false,
        placeholder: '',
        isClearable: false,
    };

    state = {
        isOpenCreateDialog: false,
        juridicalPersons: null,
        businessUnits: null,
        externalSubjects: null,
        externalSubject: {
            name: null,
            type: 'external_organization',
            juridicalPersonId: null,
            businessUnitsIds: [],
            comment: null,
            payDocPartner: true,
        },
    };

    componentDidMount() {
        this.loadData();     
    }

    loadData() {
        this.loadJuridicalPersons();
        this.loadBusinessUnits();
        this.loadExternalSubject();  
    }

    loadJuridicalPersons() {
        getJuridicalPersons()
            .then(res => {
                if (res.success) {
                    this.setJuridicalPersons(res.data);
                }
            });
    }

    loadExternalSubject = () => {
        getExternalSubjects({'type': 'external_organization'})
            .then(res => {
                if (res.success) {
                    this.setExternalSubjects(res?.data?.map(es => ({value: es.id, label: es.name})));
                }
            });
    }

    loadBusinessUnits = () => {
        getBusinessUnits()
        .then(res => {
            if (res.success) {
                this.setBusinessUnits(res.data);
            }
        }); 
    }

    handleOpenAddExternalSubjectDialog = e => {
        e.preventDefault();
        e.stopPropagation();

        this.setIsOpenCreateDialog(true);
    }

    handleChange = (selectedOption) => {
        const { onChange, name } = this.props;

        if (!onChange) {
            return;
        }
        let value = selectedOption ? selectedOption.value : null; 

        onChange({ target: { name, value }});
    }
    
    getOptionValue = option => {
        const { valueField } = this.props;
        return option[valueField];
    }

    handleChangeExternalSubject = e => {
        this.setExternalSubject(e.target.name, e.target.value);
    }

    closeCreateDialog = () => {
        this.setIsOpenCreateDialog(false);
    }

    updateOptions = (id) => {
        const { updateOptions } = this.props;
        updateOptions(id);
    }

    createExternalSubject = () => {
        const { state } = this;
        const { props } = this;

        saveExternalSubject(state?.externalSubject)
            .then(res => {
                if (res.success) {
                    this.setIsOpenCreateDialog(false);
                    this.updateOptions(res?.data?.id);
                } else {
                    props.showMessage(res.error ? res.error.message : res.message);
                }
            });
    }

    render() {
        const { t, className, classes, label, value, style, colors, options, isDisabled } = this.props;
        const { state } = this;

        return (
            <div>
            <div className={classNames(classes.root,{[classes.withLabel]: label}, className)}>
                <FormControl fullWidth={true} style={style}>
                    <InputLabel 
                        shrink
                        variant="outlined"
                    >
                        Партнер ПД
                    </InputLabel>
                        <Select
                            value={options?.find(item => item.value === value) || null}
                            isSearchable
                            options={options}
                            placeholder={''}
                            onAddClick={this.handleOpenAddExternalSubjectDialog}
                            onChange={this.handleChange}
                            classes={classes}
                            colors={colors}
                            isDisabled={isDisabled}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary: purple[500],
                                },
                            })}
                            styles={{
                                control: (styles, { theme: { colors } }) => ({
                                    ...styles,
                                    borderColor: colors.primary,
                                    paddingLeft: '1rem',
                                }),
                                menu: styles => ({
                                    ...styles,
                                    zIndex: 10,
                                }),
                            }}
                            components={{
                                DropdownIndicator,
                                Control
                            }}
                        /> 
                </FormControl>
            </div>
            <div>
                <Dialog open={state?.isOpenCreateDialog} fullWidth>
                    <DialogTitle>{t("Add external subject")}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AnyField
                                    label={t("Title")}
                                    name="name"
                                    value={state?.externalSubject.name}
                                    fullWidth
                                    error={!state?.externalSubject.name}
                                    onChange={this.handleChangeExternalSubject}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteSelectField
                                    label={t("Juridical person")}
                                    value={state?.externalSubject.juridicalPersonId}
                                    name="juridicalPersonId"
                                    onChange={this.handleChangeExternalSubject}
                                    fullWidth
                                    select
                                    options={state?.juridicalPersons?.map(item => ({value: item.id, label: item.shortName}))}
                                />
                            </Grid>                 
                            <Grid item xs={12}>
                                <AnyField
                                    label={t("Available for parlour")}
                                    value={state?.externalSubject?.businessUnitsIds}
                                    onChange={this.handleChangeExternalSubject}
                                    name="businessUnitsIds"
                                    select
                                    SelectProps={{
                                        multiple: true,
                                        displayEmpty: true,
                                        renderValue: selected => {
                                            if (selected.length === 0) {
                                                return <span className={classes.placeholder}>Не выбрано</span>;
                                            }

                                            return state?.businessUnits?.filter(item => selected.indexOf(item.id) > -1 )?.map(item => item.name).join(', ');
                                        },
                                    }}
                                    fullWidth
                                >
                                    {state?.businessUnits?.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            <Checkbox checked={ state?.externalSubject?.businessUnitsIds?.indexOf(item.id) > -1 } />
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    ))}
                                </AnyField>                                
                            </Grid>           
                            <Grid item xs={12}>
                                <AnyField
                                    name="comment"
                                    label={t("Comment")}
                                    value={state?.externalSubject.comment}
                                    fullWidth
                                    multiline={true}
                                    rows={4}
                                    onChange={this.handleChangeExternalSubject}
                                />
                            </Grid>                        
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={this.createExternalSubject}>{t("Add")}</DialogButton>
                        <DialogButton onClick={this.closeCreateDialog}>{t("Cancel")}</DialogButton>
                    </DialogActions>
                </Dialog>
            </div>
            </div>
        );
    }
    
    setIsOpenCreateDialog(val) {
        this.setState({ isOpenCreateDialog: val });
    }

    setJuridicalPersons(val) {
        this.setState({ juridicalPersons: val });
    }

    setBusinessUnits(val) {
        this.setState({ businessUnits: val });
    }

    setExternalSubjects(val) {
        this.setState({ externalSubjects: val })
    }

    setExternalSubject(name, val) {
        const { externalSubject } = this.state;

        this.setState({
            externalSubject: {
                ...externalSubject,
                [name]: val
            }
        });
    }
}