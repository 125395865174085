import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid
} from '@material-ui/core';
import {
    createJuridicalPerson,
    getJuridicalPerson,
    navLinks,
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import PhoneField from "../../components/fields/PhoneField";
import {withRouter} from "react-router";
import CheckboxField from "../../components/fields/CheckboxField";

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    container: {
        width: 900,
        marginBottom: 10,
    },
    cb: {
        marginTop: 10,
    }
});

@withRouter
class JuridicalPersonViewPage extends React.Component {

    state = {
        juridicalPerson: {
            id: null,
            shortName: null,
            fullName: null,
            legalAddress: null,
            actualAddress: null,
            email: null,
            ogrn: null,
            inn: null,
            licence: null,
            phone: null,
            checkingAccounts: null,
            companyDepartment: false,
        },
        types: [],
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getJuridicalPerson(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            juridicalPerson: response.data
                        })
                    }
                });
        }

    }

    handleChange = prop => event => {
        const { juridicalPerson } = this.state;
        this.setState({ juridicalPerson: {...juridicalPerson, [prop]: event.target.value} });
    };

    handleChangeDate = prop => date => {
        const { juridicalPerson } = this.state;
        this.setState({
            juridicalPerson: {
                ...juridicalPerson,
                [prop]: date,
            }
        });

    };

    handleSave = () => {
        createJuridicalPerson(this.state.juridicalPerson)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/juridical-persons`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes } = this.props;
        const { juridicalPerson } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleSave} disabled={!juridicalPerson.shortName || !juridicalPerson.fullName || !juridicalPerson.legalAddress || !juridicalPerson.actualAddress || !juridicalPerson.email || !juridicalPerson.ogrn || !juridicalPerson.inn || !juridicalPerson.licence || !juridicalPerson.phone}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Карточка юридического лица">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="shortName"
                                label="Короткое наименование"
                                value={juridicalPerson.shortName}
                                fullWidth
                                error={!juridicalPerson.shortName}
                                onChange={this.handleChange('shortName')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="name"
                                label="Полное наименование"
                                value={juridicalPerson.fullName}
                                fullWidth
                                error={!juridicalPerson.fullName}
                                onChange={this.handleChange('fullName')}
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="legalAddress"
                                label="Юридический адрес"
                                value={juridicalPerson.legalAddress}
                                fullWidth
                                error={!juridicalPerson.legalAddress}
                                onChange={this.handleChange('legalAddress')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="actualAddress"
                                label="Фактический адрес"
                                value={juridicalPerson.actualAddress}
                                fullWidth
                                error={!juridicalPerson.actualAddress}
                                onChange={this.handleChange('actualAddress')}
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="email"
                                label="Email"
                                value={juridicalPerson.email}
                                fullWidth
                                error={!juridicalPerson.email}
                                onChange={this.handleChange('email')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <PhoneField
                                id="phone"
                                label="Телефон"
                                value={juridicalPerson.phone}
                                defaultCountry="ru"
                                fullWidth
                                required
                                onChange={this.handleChangeDate('phone')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="ogrn"
                                label="ОГРН"
                                value={juridicalPerson.ogrn}
                                fullWidth
                                error={!juridicalPerson.ogrn}
                                onChange={this.handleChange('ogrn')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="inn"
                                label="ИНН"
                                value={juridicalPerson.inn}
                                fullWidth
                                error={!juridicalPerson.inn}
                                onChange={this.handleChange('inn')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="licence"
                                label="Лицензия"
                                value={juridicalPerson.licence}
                                fullWidth
                                error={!juridicalPerson.licence}
                                onChange={this.handleChange('licence')}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CheckboxField
                                label="Подразделение компании"
                                value={juridicalPerson.companyDepartment}
                                onChange={this.handleChange('companyDepartment')}
                            />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(withStyles(styles)(JuridicalPersonViewPage));
