import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    changeDealsStatus, dealApprovedStatuses,
    getDealApproveStatuses,
    getDealsOverrated,
    navLinks
} from "../../services/moneyFlow";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {MenuItem} from "@material-ui/core";
import infoActions from "../../components/info/info-actions";
import {hasRole} from "../../services/user";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

export default
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
class DealOverratedListPage extends React.Component {
    state = {
        search: {
            search: null,
            date_start: null,
            date_end: null,
            businessUnitId: 0,
            approveStatus: null
        },
        refreshTable: false,
        businessUnits: [{ id: 0, name: '---Все---'}],
        approveStatuses: [{ id: 0, text: '---Все---'}],
        selected: []
    };

    columnData = [
        { id: 'reasonClassText', label: 'Причина (Сущность)' },
        { id: 'reasonName', label: 'Название сделки' },
        { id: 'amountFormat', label: 'Сумма' },
        { id: 'transactionsShort', label: 'Транзакции/лимиты', multiline: true },
        { id: 'approveStatusText', label: 'Статус' },
        { id: 'comment', label: 'Комментарий' },
        { id: 'businessUnitName', label: 'Бизнес юнит' },
        { id: 'createdAt', label: 'Создано', dateFormat: 'DD.MM.YYYY HH:mm:ss'},
    ];

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            { id: 0, name: '---Все---'},
                            ...response.data
                        ],
                    });
                }
            });

        getDealApproveStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        approveStatuses: [
                            { id: 0, text: '---Все---'},
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value = null;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getDealsOverrated(search, ...params);
    };

    handleSelect = (selected) => {
        this.setState({ selected });
    };

    handleChangeDealsStatus = (type) => () => {
        let selected = {ids: this.state.selected.map(item => item.id)};
        changeDealsStatus(selected, type)
            .then(response => {
                if (response.success) {
                    this.handleClickFilter();
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    changeStatusButtonDisabled = (status) => {
        const {selected} = this.state;
        const filtered = selected.filter(item => item.approveStatus === dealApprovedStatuses[status] );
        return !selected.length || selected.length !== filtered.length;
    };

    render() {
        const { classes } = this.props;
        const { refreshTable, search, businessUnits, approveStatuses } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={"Показать сделки по дате"}
                        className={classes.filterControl}
                        required={false}
                    />
                    <AnyField
                        id="parlour"
                        label="Бизнес юнит"
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </AnyField>
                    <AnyField
                        id="status"
                        label="Статус"
                        value={search.approveStatus}
                        onChange={this.handleChange('approveStatus')}
                        fullWidth
                        select
                        className={classes.filterControl}
                    >
                        {approveStatuses.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                    </AnyField>
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        className={classes.filterControl}
                        label="Поиск"
                    />
                    <ActionButton onClick={this.handleClickFilter}>Отфильтровать</ActionButton>
                    <br/>
                    <br/>
                    <ActionButton onClick={this.handleChangeDealsStatus('approve')} disabled={this.changeStatusButtonDisabled('pending')} visible={hasRole('ROLE_UI_TAB_MONEYFLOW_DEALOVERRATED_APPROVE')}>Одобрить</ActionButton>
                    <ActionButton onClick={this.handleChangeDealsStatus('reject')} disabled={this.changeStatusButtonDisabled('pending')} visible={hasRole('ROLE_UI_TAB_MONEYFLOW_DEALOVERRATED_APPROVE')}>Отклонить</ActionButton>
                    <ActionButton onClick={this.handleChangeDealsStatus('complete')} disabled={this.changeStatusButtonDisabled('approved')} visible={hasRole('ROLE_UI_TAB_MONEYFLOW_DEALOVERRATED_COMPLETE')}>Потратить</ActionButton>
                </LeftBar>
                <Content title="Запросы на перерасходы">
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        onSelect={this.handleSelect}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
