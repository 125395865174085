import React from 'react';
import moment from "moment";
import {getBusinessUnits, getExternalSubjects, getRegions} from "../../services/organization";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getPayDocReportSale,
    getPayDocStatuses,
    getPayDocTypes,
    navLinks
} from "../../services/payDocs";
import LeftBar from "../../components/left-bar/LeftBar";
import DataTable from "../../components/data-table/DataTable";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import _ from "lodash";
import XLSX from "xlsx";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import DatePeriodField from "../../components/fields/DatePeriodField";
import AnyField from "../../components/fields/AnyField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";

export default
@withTranslation()
class PayDocReportSoldPage extends React.Component
{
    state = {
        search: {
            search: null,
            dateStart: moment(),
            dateEnd: moment(),
            regionIds: [],
            businessUnitIds: [],
            externalSubjectIds: [],
        },
        refreshTable: false,
        businessUnits: [],
        regions: [],
        types: [],
        statuses: [],
        externalSubjects: [],
        loading: true,
    };

    processAction = value => {
        const { t } = this.props;
        return t('pdAction.' + value);
    };

    columnData = [
        {id: 'actionType', label: 'Действие', processValue: this.processAction},
        {id: 'dt', label: 'Дата время', dateFormat: 'DD.MM.YYYY HH:MM'},
        {id: 'code', label: 'Тип'},
        {id: 'num', label: 'Номер', linkTemplate: row => `/pay-docs/view/${row.payDocId}`},
        {id: 'measure.text', label: 'Тип'},
        {id: 'amount', label: 'Сумма'},
        {id: 'username', label: 'Пользователь'},
        {id: 'businessUnitName', label: 'Салон'},
        {id: 'guest', label: 'Гость'},
        {id: 'paymentsCash', label: 'Платежи нал'},
        {id: 'paymentsCashless', label: 'Платежи б/н'},
        {id: 'comment', label: 'Comment'},
        {
            id: 'externalSubjectId',
            label: 'External subject',
            processValue: (value) => {
                return this.state.externalSubjects.find((es) => es.id === value)?.name ?? value;
            },
        },
    ];

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    });
                }
            });
        getPayDocTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data,
                    })
                }
            });
        getPayDocStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        statuses: response.data,
                    })
                }
            });
        getExternalSubjects({'type': 'external_organization'})
            .then(response => {
                if (response.success) {
                    this.setState({
                        externalSubjects: response.data,
                        loading: false,
                    })
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getPayDocReportSale(processMomentFieldInObject(search, ['dateStart', 'dateEnd']), ...params);
    };

    handleExport = () => {
        const { search } = this.state;
        const { t } = this.props;
        const rd = moment.isMoment(search.dateEnd) ? search.dateEnd.format('YYYY-MM-DD') : search.dateEnd;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        this.columnData.map(item => t(item.label)),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            this.columnData.map(item => {
                                const value = _.get(row, item.id);
                                if (item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                } else if (typeof item.processValue !== 'undefined') {
                                     return item.processValue(value);
                                } else {
                                    return value;
                                }
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "PayDocs Balance");
                    XLSX.writeFile(new_workbook, `PayDocs Balance ${rd}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits, regions, externalSubjects, loading } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField
                    valueFrom={search.dateStart}
                    valueTo={search.dateEnd}
                    maxDate={moment()}
                    onChangeFrom={this.handleChange('dateStart')}
                    onChangeTo={this.handleChange('dateEnd')}
                    label={t('Show sales by date interval')}
                />
                <MultiSelectFromItemsField
                    items={regions}
                    label={t('Region')}
                    value={search.regionIds}
                    onChange={this.handleChange('regionIds')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.businessUnitIds}
                    onChange={this.handleChange('businessUnitIds')}
                    fullWidth
                />
                <AutocompleteMultiSelectField
                    value={search.externalSubjectIds}
                    onChange={this.handleChange('externalSubjectIds')}
                    valueField="id"
                    textField="name"
                    fullWidth
                    label={t("External subject")}
                    options={externalSubjects}
                />
                <AnyField
                    label={t('Search')}
                    onChange={this.handleChange('search')}
                    value={search.search}
                    fullWidth
                />
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title="Продажи/создание/пополнение ПД">
                <DataTable
                    columnData={this.columnData}
                    dataFunc={this.getTableData}
                    selector={false}
                    refresh={refreshTable}
                    loading={loading}
                    disableSorting
                />
            </Content>
        </React.Fragment>;
    }
}