import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {useImmer} from "use-immer";
import moment from "moment";
import {getFilterFromQuery} from "../../../history";
import Context from './Context';

const ContextProvider = ({ children }) => {
    const currentParlour = useSelector(state => state.common.currentParlour);

    const [search, updateSearch] = useImmer(() => {
        let businessUnitIds = [];

        if (currentParlour) {
            businessUnitIds.push(currentParlour.id);
        }

        const querySearch = getFilterFromQuery();

        let dayStart = moment().startOf('week');
        let dayEnd = moment();
        let dateType = 'period';
        let month = moment().add(-10, 'day').startOf('month');

        if (querySearch) {
            if (querySearch.dayStart) {
                dayStart = moment(querySearch.dayStart);
            }

            if (querySearch.dayEnd) {
                dayEnd = moment(querySearch.dayEnd);
            }

            if (querySearch.businessUnitIds) {
                businessUnitIds = querySearch.businessUnitIds;
            }

            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }

            if (querySearch.month) {
                month = moment(querySearch.month);
            }
        }

        return {
            dayStart,
            dayEnd,
            businessUnitIds,
            dateType,
            month,
        };
    });

    const { dayStart, dayEnd, dateType, month, businessUnitIds } = search;

    const isValidPeriod = useMemo(() => {
        if (dateType === 'period') {
            return !!(dayStart && dayEnd && dayStart.isSameOrBefore(dayEnd));
        } else {
            return !!month;
        }
    }, [dateType, dayEnd, dayStart, month]);

    const isValidBusinessUnits = useMemo(() => !!businessUnitIds.length, [businessUnitIds.length]);

    const [refreshTable, setRefreshTable] = useState(false);

    const [detailsDialog, updateDetailsDialog] = useImmer({
        open: false,
        title: '',
        data: null,
        columnsData: [],
        linkTemplate: null
    });

    const showDetailsDialog = useCallback(({ title, data, columnsData, linkTemplate }) => {
        updateDetailsDialog(draft => {
            draft.open = true;
            draft.title = title;
            draft.data = data;
            draft.columnsData = columnsData;
            draft.linkTemplate = linkTemplate;
        })
    }, [updateDetailsDialog]);

    const hideDetailsDialog = useCallback(() => {
        updateDetailsDialog(draft => {
            draft.open = false;
        })
    }, [updateDetailsDialog]);

    const [tableData, setTableData] = useState([]);

    const ctx = useMemo(() => ({
        search,
        updateSearch,
        isValidPeriod,
        isValidBusinessUnits,
        refreshTable,
        setRefreshTable,
        detailsDialog,
        showDetailsDialog,
        hideDetailsDialog,
        tableData,
        setTableData
    }), [
        detailsDialog,
        hideDetailsDialog,
        isValidBusinessUnits,
        isValidPeriod,
        refreshTable,
        search,
        showDetailsDialog,
        updateSearch,
        tableData,
        setTableData
    ]);

    return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

ContextProvider.propTypes = {
    children: PropTypes.node,
};

export default function withProvider(Component) {
    return (props) => (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );
}