import React from 'react';
import ActionButton from "./ActionButton";

export default function DialogButton(props)
{
    return <ActionButton
        fullWidth={false}
        noMargin
        color="primary"
        variant="text"
        size="medium"
        {...props}
    />
}