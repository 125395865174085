import React from 'react';
import * as PropTypes from 'prop-types';
import ShowField from "../../components/fields/ShowField";

class MoveLeftFields extends React.Component
{
    render() {
        const { moveRequest } = this.props;

        return (
            <React.Fragment>
                <ShowField
                    label="Номер запроса"
                    value={moveRequest.num}
                />
                <ShowField
                    label="Дата создания запроса"
                    value={moveRequest.createdAt}
                    dateFormat="DD.MM.YYYY"
                />
                <ShowField
                    label="Пользователь, создавший запрос"
                    value={moveRequest.creator.username}
                />
                <ShowField
                    label="Тел. пользователя, создавшего запрос"
                    value={moveRequest.creator.phone}
                    label2Lines
                />
                <ShowField
                    label="Салон отправитель"
                    value={moveRequest.parlourName}
                />
                <ShowField
                    label="Салон получатель"
                    value={moveRequest.newParlourName}
                />
                <ShowField
                    label="Комментарий"
                    value={moveRequest.comment}
                    multiline
                    rows={4}
                />
                <ShowField
                    label="Статус"
                    value={moveRequest.statusText}
                />
            </React.Fragment>
        );
    }
}

MoveLeftFields.propTypes = {
    moveRequest: PropTypes.object.isRequired,
};

export default MoveLeftFields;