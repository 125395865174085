import React from 'react';
import moment from "moment";
import {getBusinessUnits, getRegions} from "../../services/organization";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import LeftBar from "../../components/left-bar/LeftBar";
import DataTable from "../../components/data-table/DataTable";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import ActionButton from "../../components/button/ActionButton";
import _ from "lodash";
import XLSX from "xlsx";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {
    getGoodsReportSales,
    navLinks
} from "../../services/goods";
import DatePeriodField from "../../components/fields/DatePeriodField";

const columnData = [
    {id: 'businessUnit', label: 'Салон'},
    {id: 'categoryName', label: 'Группа'},
    {id: 'name', label: 'Товар'},
    {id: 'goodStorageSaleNum', label: 'Продажа', linkTemplate: (row) => `/goods/sell/view/${row.goodStorageSaleId}`},
    {id: 'soldPrice.format', label: 'Цена'},
    {id: 'countGoods', label: 'Кол-во'},
    {id: 'total', label: 'Стоимость'},
    {id: 'purchasePrice', label: 'Цена покупки'},
];

export default
@withTranslation()
class GoodsReportSoldPage extends React.Component
{
    state = {
        search: {
            search: null,
            dateStart: moment(),
            dateEnd: moment(),
            regionIds: [],
            businessUnitIds: [],
        },
        refreshTable: false,
        businessUnits: [],
        regions: [],
    };

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        regions: response.data,
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getGoodsReportSales(processMomentFieldInObject(
            search,
            [
                'dateStart',
                'dateEnd'
            ]), ...params);
    };

    handleExport = () => {
        const { search } = this.state;
        const from = moment.isMoment(search.dateStart) ? search.dateStart.format('YYYY-MM-DD') : search.dateStart;
        const to = moment.isMoment(search.dateEnd) ? search.dateEnd.format('YYYY-MM-DD') : search.dateEnd;

        this.getTableData(1, -1)
            .then(response => {
                if (response.success) {
                    const data = [
                        //First row - headers
                        columnData.map(item => item.label),
                    ];

                    response.data.forEach(row => {
                        data.push(
                            columnData.map(item => {
                                const value = _.get(row, item.id);
                                if (item.dateFormat) {
                                    return moment(value).format(item.dateFormat);
                                } else {
                                    return value;
                                }
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(data);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "Goods sales");
                    XLSX.writeFile(new_workbook, `Goods sales ${from} - ${to}.xlsx`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits, regions } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <DatePeriodField
                    valueFrom={search.dateStart}
                    valueTo={search.dateEnd}
                    maxDate={moment()}
                    onChangeFrom={this.handleChange('dateStart')}
                    onChangeTo={this.handleChange('dateEnd')}
                    label={t('Show sales by date interval')}
                />
                <MultiSelectFromItemsField
                    items={regions}
                    label={t('Region')}
                    value={search.regionIds}
                    onChange={this.handleChange('regionIds')}
                    fullWidth
                />
                <MultiSelectFromItemsField
                    items={businessUnits}
                    label={t('Business unit')}
                    value={search.businessUnitIds}
                    onChange={this.handleChange('businessUnitIds')}
                    fullWidth
                />
                <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                <ActionButton onClick={this.handleExport}>Excel</ActionButton>
            </LeftBar>
            <Content title="Проданные товары">
                <DataTable
                    columnData={columnData}
                    dataFunc={this.getTableData}
                    selector={false}
                    refresh={refreshTable}
                    disableSorting
                />
            </Content>
        </React.Fragment>;
    }
}