import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    saveLimit,
    getSpendingTypes,
    navLinks,
    getLimit,
    getLimitMonthlyByLimitId
} from "../../services/moneyFlow";
import AnyField from "../../components/fields/AnyField";
import {Grid, MenuItem} from "@material-ui/core";
import {defaultCurrency, emptyMoney} from "../../services/common";
import infoActions from "../../components/info/info-actions";
import {getBusinessUnits} from "../../services/organization";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import DateField from "../../components/fields/DateField";
import PageTitle from "../../components/typography/PageTitle";
import DataTable from "../../components/data-table/DataTable";
import {withRouter} from "react-router";
import MoneyField from "../../components/fields/MoneyField";


const styles = theme => ({

});

const columnData = [
    { id: 'month', label: 'Месяц', dateFormat: 'MMMM YYYY' },
    { id: 'amountFormatted', label: 'Лимит' },
    { id: 'currentAmountFormatted', label: 'Осталось' },
    { id: 'currentAmountSpendFormatted', label: 'Потрачено' },
    { id: 'createdAt', label: 'Создано', dateFormat: 'DD.MM.YYYY HH:mm:ss' },
];

const emptyLimit = {
    id: null,
    spendingTypeId: null,
    startDate: null,
    amount: {...emptyMoney},
    businessUnitId: null,
    comment: null,
};

export default
@withRouter
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
class LimitDealPage extends React.Component {
    state = {
        spendingTypes: [],
        businessUnits: [],
        refreshTable: false,
        limit: {
            ...emptyLimit
        }
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getLimit(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            limit: response.data
                        })
                    }
                });
        }

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: [
                            ...response.data
                        ],
                    });
                }
            });

        getSpendingTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypes: [
                            ...response.data
                        ],
                    });
                }
            });
    }

    handleChange = prop => event => {
        this.setState({
            limit: {
                ...this.state.limit,
                [prop]: event.target.value,
            }
        });
    };

    handleChangeMoney = prop => event => {
        this.setState({
            limit: {
                ...this.state.limit,
                [prop]: {
                    val: event.target.value,
                    cur: defaultCurrency
                }
            }
        });
    };

    handleChangeDate = prop => date => {
        this.setState({
            limit: {
                ...this.state.limit,
                [prop]: date,
            }
        });

    };

    handleAdd = () => {
        saveLimit(this.state.limit)
            .then(response => {
                if (response.success) {
                    this.props.showInfo('Лимит сохранен.');
                    this.props.history.push(`/money-flow/limits`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    getLimitMonthlyTableData = (...params) => {
        return getLimitMonthlyByLimitId(this.state.limit.id, ...params);
    };

    render() {
        const { classes } = this.props;
        const { limit, businessUnits, spendingTypes, refreshTable } = this.state;
        const disabled = !limit.spendingTypeId || !limit.startDate || !limit.amount.val || !limit.businessUnitId;

        let selectedBusinessUnit = businessUnits.find((item) => limit.businessUnitId === item.id) || {};

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleAdd} disabled={disabled}>{ limit.id ? "Изменить лимит" : "Установить лимит" }</ActionButton>
                </LeftBar>
                <Content title="Карточка лимита">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                id="parlour"
                                label="Бизнес юнит"
                                value={limit.businessUnitId}
                                required
                                error={!limit.businessUnitId}
                                onChange={this.handleChange('businessUnitId')}
                                fullWidth
                                select
                            >
                                {businessUnits.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteSelectField
                                label="Статья расхода"
                                value={limit.spendingTypeId}
                                required
                                error={!limit.spendingTypeId}
                                onChange={this.handleChange('spendingTypeId')}
                                fullWidth
                                options={spendingTypes.map(item => ({value: item.id, label: `${item.name}`}))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <MoneyField
                                label="Сумма"
                                currencies={ [selectedBusinessUnit.mainCurrency] }
                                value={ limit.amount }
                                error = { !limit.amount.val }
                                onChange={this.handleChange('amount')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <DateField
                                label="Действует с"
                                value={limit.startDate}
                                format="MMMM YYYY"
                                required
                                error={!limit.startDate}
                                onChange={this.handleChangeDate('startDate')}
                                mask={null}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={3}>
                            <AnyField
                                label="Комментарий"
                                value={limit.comment}
                                onChange={this.handleChange('comment')}
                                multiline
                                rows={4}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {limit.id &&
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <PageTitle children="Использование лимита"/>
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                columnData={columnData}
                                dataFunc={this.getLimitMonthlyTableData}
                                selector={false}
                                refresh={refreshTable}
                            />
                        </Grid>
                    </Grid>
                    }
                </Content>
            </React.Fragment>
        );
    }
}
