import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getBusinessUnitBalanceReport,
    navLinks
} from "../../services/moneyFlow";
import moment from "moment";
import {withTranslation} from "react-i18next";
import DateMonthField from "../../components/fields/DateMonthField";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {getBusinessUnits} from "../../services/organization";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import {isNumber} from "lodash/lang";
import {formatMoney} from "../../services/common";
import {ErrorOutline} from "@material-ui/icons";

const subtypes = {
    infotTotal: 'info_total',
    total: 'total',
    typeTotal: 'type_total',
    subtypeTotal: 'subtype_total',
    subtypeTotal2: 'subtype_total2',
}
const reportDateFormat = 'Y-MM-DD'
const detailsDateFormat = 'DD.MM.YYYY'
const detailsColumnData = [
    {id: 'applyAt', label: 'Date', dateFormat: detailsDateFormat},
    {id: 'amount', label: 'Sum', processValue: formatMoney},
    {id: 'creatorName', label: 'Creator'},
]

export default @connect(() => ({}), {
    showMessage: messageDialogActions.show,
})
@withTranslation()
class BusinessUnitBalanceReport extends React.Component {

    constructor(props) {
        super(props);

        let businessUnitId = null;

        if (props.currentParlour) {
            businessUnitId = props.currentParlour.id;
        }

        const querySearch = getFilterFromQuery();

        let startMonth = moment().startOf('week');
        let endMonth = moment();

        if (querySearch) {
            if (querySearch.startMonth) {
                startMonth = moment(querySearch.startMonth);
            }

            if (querySearch.endMonth) {
                endMonth = moment(querySearch.endMonth);
            }

            if (querySearch.businessUnitId) {
                businessUnitId = querySearch.businessUnitId;
            }
        }

        this.state = {
            search: {
                startMonth: startMonth,
                endMonth: endMonth,
                businessUnitId: businessUnitId
            },
            businessUnits: [],
            report: {
                columns: [],
                data: []
            },
            detailsDialog: {
                open: false,
                title: null,
                data: [],
                columns: []
            }
        };

    }

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });

    }

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickFilter = () => {
        pushFilterToQuery(processMomentFieldInObject(this.state.search, ['startMonth', 'endMonth', 'month']));
        this.getTableData()
    };

    getTableData = () => {
        const {search} = this.state;
        return getBusinessUnitBalanceReport(
            processMomentFieldInObject(search, ['startMonth', 'endMonth', 'month'])

        )
            .then(response => {
                if (response.success) {
                    this.setState({
                        report: response.data
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    };

    getCellStyle(row, index) {

        if (row.subtype) {
            if (row.subtype === subtypes.infotTotal) {
                return {backgroundColor: '#89c9ff'}
            }

            if (row.subtype === subtypes.total) {
                return {backgroundColor: '#3cffba'}
            }

            if (row.subtype === subtypes.typeTotal) {
                return {backgroundColor: '#b7ffb2'}
            }

            if (row.subtype === subtypes.subtypeTotal) {
                return {backgroundColor: '#e1ffde'}
            }

            if (row.subtype === subtypes.subtypeTotal2) {
                return {backgroundColor: '#f2fff1'}
            }
        }

        return {}
    }

    fillAndOpenDetailsDialog = (details, title) => {
        this.setState({
            detailsDialog: {
                open: true,
                data: details,
                title: title,
                columns: detailsColumnData
            }
        })
    }

    processReportData = (data) => {
        return data.map(row => this.processReportRow(row))
    }

    processReportRow = (row) => {
        const { search } = this.state
        const range = moment.range(
            moment(search.startMonth).startOf('month'),
            moment(search.endMonth).startOf('month')
        );

        for (let month of range.by('month')) {
            let formattedMonth = month.format(reportDateFormat);

            if (row[formattedMonth]) {
                row[formattedMonth].val = this.isReportRowNotUpdatedAndHaveDetails(row, formattedMonth)
                    ? this.processReportRowValByMonth(row, formattedMonth)
                    : row[formattedMonth].val
            }
        }

        return row
    }

    isReportRowNotUpdatedAndHaveDetails = (row, month) => {
        return row[month] &&
                (isNumber(row[month].val)) &&
                row[month].details && row[month].details.length > 0
    }

    processReportRowValByMonth = (row, month) => {
        return <>
                {row[month].val}
                <IconButton
                    size="small"
                    onClick={() => this.fillAndOpenDetailsDialog(row[month].details, row.name)}
                >
                    <ErrorOutline fontSize="small"/>
                </IconButton>
            </>
    }

    detailsDialogOnClose = () => { this.setState({detailsDialog: {...this.state.detailsDialog, open: false} }) }

    isSearchMonthRangeValid = () => {
        const { search } = this.state;

        return search.startMonth && search.endMonth && search.startMonth < search.endMonth
    }

    render() {
        const { t } = this.props;
        const { search, report, businessUnits, detailsDialog } = this.state;
        const disableFilterButton =
            !search.businessUnitId ||
            !search.startMonth || !search.endMonth ||
            search.startMonth > search.endMonth

        let processedData = [...report.data]
        if (this.isSearchMonthRangeValid() && processedData.length > 0) {
            processedData = this.processReportData(processedData)
        }

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>

                    <DateMonthField
                        label={t('Interval start')}
                        value={search.startMonth}
                        onChange={this.handleChange('startMonth')}
                        fullWidth
                    />
                    <DateMonthField
                        label={t('Interval end')}
                        value={search.endMonth}
                        onChange={this.handleChange('endMonth')}
                        fullWidth
                    />

                    <AutocompleteSelectField
                        options={businessUnits.map(item => ({value: item.id, label: `${item.name}`}))}
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                    />
                    <ActionButton onClick={this.handleClickFilter} disabled={disableFilterButton}>{t("Filter")}</ActionButton>
                </LeftBar>
                <Content title={t("Business Unit Balance Report")}>
                    {report.columns.length ?
                        <React.Fragment key={'report'}>
                            <DataTableLocal
                                columnData={report.columns}
                                data={processedData}
                                selector={false}
                                pagination={false}
                                disableSorting
                                cellStyleFunc={this.getCellStyle}
                                stickyLeftColumn
                                tableStyle={{borderCollapse: 'separate'}}
                            />
                        </React.Fragment>
                        : null
                    }
                </Content>

                <Dialog
                    open={detailsDialog.open}
                    onClose={this.detailsDialogOnClose}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle>{detailsDialog.title}</DialogTitle>
                    <DialogContent>
                        <DataTableLocal
                            columnData={detailsDialog.columns}
                            data={detailsDialog.data}
                            selector={false}
                            pagination={false}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.detailsDialogOnClose} color="primary">{t("Close")}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}