import React from 'react';
import * as PropTypes from 'prop-types';
import ShowField from "../../components/fields/ShowField";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {InputLabel} from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";

export default
@withTranslation()
@withStyles(theme => ({
    payDoc: {
        fontSize: theme.typography.body2.fontSize,
        border: '1px solid #777',
        borderRadius: '3px',
        textAlign: 'center',
        padding: '1px 2px',
        marginRight: '2px',

        '&:last-child': {
            marginRight: 0,
        }
    },
    topBadge: {
        marginBottom: 5
    }
}))
class PayDocsLeftFields extends React.Component
{
    static propTypes = {
        payDocs: PropTypes.any,
    };

    getActingPayDocs = () =>
        this.props.payDocs ?
            this.props.payDocs.filter(
                payDoc => payDoc.balance > 0
                    && (payDoc.statusId === "sold" || payDoc.statusId === "active")
            ):
            []


    getSpentOrUsedPayDocs = () =>
         this.props.payDocs ?
            this.props.payDocs.filter(
                payDoc => payDoc.balance === 0
                    && (payDoc.statusId === "sold" || payDoc.statusId === "active")
            ):
            []

    getExpiredPayDocs = () =>
        this.props.payDocs ?
            this.props.payDocs.filter(
                payDoc => payDoc.statusId === "expired"
            ):
            []

    getBurnedPayDocs = () =>
        this.props.payDocs ?
            this.props.payDocs.filter(
                payDoc => payDoc.statusId === "burned"
            ):
            []

    getLockedPayDocs = () =>
        this.props.payDocs ?
            this.props.payDocs.filter(
                (payDoc) => payDoc.statusId === "locked"
        ):
        []

    getOtherPayDocs = () => {
        if (this.props.payDocs) {
            let payDocs = this.props.payDocs

            payDocs = payDocs.filter(p => !this.getActingPayDocs().includes(p))
            payDocs = payDocs.filter(p => !this.getSpentOrUsedPayDocs().includes(p))
            payDocs = payDocs.filter(p => !this.getExpiredPayDocs().includes(p))
            payDocs = payDocs.filter(p => !this.getBurnedPayDocs().includes(p))
            payDocs = payDocs.filter(p => !this.getLockedPayDocs().includes(p))

            return payDocs
        }

        return []
    }

    getPayDocsShowField = (payDocs, label, collapsed = true) =>
        <ShowField
            label={label}
            flexWrap
            collapsible={collapsed}
        >
            {payDocs && payDocs.map(payDoc => (
                <div key={payDoc.id}
                     className={
                         classNames(
                             this.props.classes.payDoc,
                             {[this.props.payDocNoValidity]: moment(payDoc.validity).isBefore(moment())}
                         )}>
                    <Link to={`/pay-docs/view/${payDoc.id}`}>{payDoc.num}</Link><br/>{payDoc.balanceText}
                </div>
            ))}
        </ShowField>

    render() {
        const { t } = this.props;

        return <React.Fragment>
            <InputLabel>{t("Payment documents")}</InputLabel>
            {this.getPayDocsShowField(this.getActingPayDocs(), t('payDoc.acting'), false)}
            {this.getPayDocsShowField(this.getSpentOrUsedPayDocs(), t('payDoc.spentOrUsed'))}
            {this.getPayDocsShowField(this.getExpiredPayDocs(), t('payDoc.expired'))}
            {this.getPayDocsShowField(this.getBurnedPayDocs(), t('payDoc.burned'))}
            {this.getPayDocsShowField(this.getLockedPayDocs(), t('payDoc.locked'))}

            {this.getOtherPayDocs().length ?
                this.getPayDocsShowField(this.getOtherPayDocs(), t("payDoc.others")):
                null}

        </React.Fragment>;
    }
}