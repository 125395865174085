import React from 'react';
import * as PropTypes from 'prop-types';
import {DragSource} from "react-dnd";
import {Tooltip, withStyles} from "@material-ui/core";
import dimensions from './ScheduleVisitsWeekDimensions';
import {visitStatuses} from "../../services/calendar";
import * as cn from "classnames";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Face from "@material-ui/icons/Face";
import OnlineIcon from "@material-ui/icons/Public";
import LinkIcon from "@material-ui/icons/Link";
import StarIcon from "@material-ui/icons/Star";

export default
@DragSource(
    'SCHEDULE_EVENT',
    {

        beginDrag(props) {
            if (props.onBeginDrag) {
                props.onBeginDrag(props.element.visitIdx);
            }
            return props;
        },

        endDrag(props, monitor, component) {
            if (!monitor.didDrop()) {
                return;
            }
            const item = monitor.getItem();
            const dropResult = monitor.getDropResult();

            if (props.onEndDrag) {
                props.onEndDrag(item.event, dropResult.slot, dropResult.column, dropResult.subColumn);
            }
        },

        canDrag(props) {
            if (props.element.disableDrag) {
                return false;
            }

            return (props.draggingIdx <= 0 || props.draggingIdx === props.element.visitIdx) &&
                [
                    visitStatuses.created,
                    visitStatuses.confirmed,
                    visitStatuses.risky,
                    visitStatuses.starting,
                    visitStatuses.providing
                ].indexOf(props.element.visit.status) >= 0;
        },
    },
    (connect, monitor) => {
        return {
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        }
    }
)
@withStyles({
    event: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 4,
        padding: '1px 1px',
        border: '1px solid #888',
        cursor: 'pointer',
        overflow: 'hidden',

        '&:hover': {
            zIndex: 5,
        },

        '@media print': {
            borderColor: '#000',
        }
    },
    eventHasLinkedVisits: {
        '@media screen': {
            //boxShadow: 'inset 0px 0px 5px 2px rgba(122,95,42,1)',
            borderColor: 'blue',
            borderWidth: 2,
        }
    },
    eventCurrent: {
        borderColor: '#333',
        borderWidth: 2,
    },
    eventStriped: {
        backgroundImage: 'url(/img/striped-red.png)',
    },
    markPair: {
        '@media screen': {
            borderRadius: '50%',
            left: '50%',
            top: '50%',
            position: 'absolute',
            width: 10,
            height: 10,
            background: 'red',
            border: '1px solid black',
        }
    },
    eventLabelTime: {
        fontSize: '0.7rem',
        fontWeight: 'normal',
    },
    eventIcons: {
        position: 'absolute',
        right: 0,
        top: 0,
        maxWidth: 13,
        display: 'flex',
        flexWrap: 'wrap',
    },
    eventIcon: {
        fontSize: '13px',
        display: 'block',
    },
    starIcon: {
        fontSize: '13px',
        color: '#f00',
        display: 'block',
    },
    eventWithColorFacilityLine: {
        paddingLeft: 7,
    },
    facilityLine: {
        width: 5,
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
})
class ScheduleVisitsWeekElement extends React.PureComponent {
    static propTypes = {
        element: PropTypes.shape({
            columnIdx: PropTypes.number,
            subColumnIdx: PropTypes.number,
            slotIdx: PropTypes.number,
            visitIdx: PropTypes.number,
            height: PropTypes.number,
            left: PropTypes.number,
            top: PropTypes.number,
            style: PropTypes.object,
            masterId: PropTypes.string,
            masterRequested: PropTypes.bool,
            visit: PropTypes.object,
            disableDrag: PropTypes.bool,
            icons: PropTypes.object,
            content: PropTypes.object,
        }),
        selectedVisit: PropTypes.object,
        connectDragSource: PropTypes.func,
        connectDragPreview: PropTypes.func,
        isDragging: PropTypes.bool,

        onBeginDrag: PropTypes.func,
        onEndDrag: PropTypes.func,
        isDragTarget: PropTypes.bool,
        isDraggingSource: PropTypes.bool,
        draggingIdx: PropTypes.number,
        ctrlPressed: PropTypes.bool,

        onVisitClick: PropTypes.func,
        onVisitDbClick: PropTypes.func,
        onVisitContextMenu: PropTypes.func,
    }

    static defaultProps = {
        isDragTarget: false,
        isDraggingSource: false,
        ctrlPressed: false,
    }

    componentDidMount() {
        const img = new Image();
        img.src = '/img/drag-preview.png';
        img.onload = () => this.props.connectDragPreview(img);
    }

    handleClick = event => {
        const { element, onVisitClick } = this.props;
        if (onVisitClick) {
            onVisitClick(element.visit, event);
        }
    }

    handleDbClick = event => {
        const { element, onVisitDbClick } = this.props;
        if (onVisitDbClick) {
            onVisitDbClick(element.visit, event);
        }
    }

    handleContextMenu = event => {
        const { element, onVisitContextMenu } = this.props;
        if (onVisitContextMenu && !event.altKey) {
            onVisitContextMenu(element.visit, event);
        }
    }

    isPairWithSelected() {
        const { selectedVisit, element } = this.props;

        if (selectedVisit) {
            return element.visit.parentId === selectedVisit.id
                || element.visit.id       === selectedVisit.parentId
                || (selectedVisit.hasChildren && selectedVisit.id === element.visit.id)
                || (element.visit.parentId && selectedVisit.parentId && element.visit.parentId === selectedVisit.parentId);
        }

        return false;
    }

    render() {
        const {
            classes,
            connectDragSource,
            element,
            isDragging,
            isDragTarget,
            isDraggingSource,
            draggingIdx,
            ctrlPressed,
            selectedVisit,
        } = this.props;
        const lighten =
            isDragging
            || (isDragTarget && isDraggingSource)
        ;
        const isOldVisit = (!isDraggingSource && !isDragTarget && draggingIdx === element.visitIdx);
        const isDraggingOther = draggingIdx >= 0 && draggingIdx !== element.visitIdx;
        return connectDragSource(<div
            className={cn(classes.event,{
                [classes.eventHasLinkedVisits]: (element.visit.parentId || element.visit.hasChildren),
                [classes.eventCurrent]: (selectedVisit && element.visit.id === selectedVisit.id),
                [classes.eventStriped]: element.visit.hasWaitingMasters,
                [classes.eventWithColorFacilityLine]: element.color !== 'transparent'
            })}
            style={{
                ...element.style,
                width: dimensions.subColumnWidth,
                zIndex: lighten ? 1 : ctrlPressed ? null : element.style.zIndex,
                borderStyle: lighten || isOldVisit ? 'dotted' : element.style.borderStyle,
                borderWidth: lighten || isOldVisit ? 1 : element.style.borderWidth,
                opacity: lighten || isOldVisit ? 0.5 : isDraggingOther ? 0.2 : ctrlPressed ? null : element.style.opacity,
                height: element.height,
                transform: `translate3d(${element.left}px, ${element.top}px, 0)`,
            }}
            onClick={this.handleClick}
            onDoubleClick={this.handleDbClick}
            onContextMenu={this.handleContextMenu}
        >
            <div className={classes.facilityLine} style={{backgroundColor: element.color}}/>
            <div className={classes.eventLabelTime}>{element.content.timeStartFormat}<br/>{element.content.timeEndFormat}</div>
            <div className={classes.eventIcons}>
                {element.icons.firstTime ? <Tooltip title="First time guest"><StarIcon className={classes.starIcon}/></Tooltip> : null}
                {element.icons.isCash ? <Tooltip title="Cash"><MoneyIcon className={classes.eventIcon}/></Tooltip> : null}
                {element.icons.isCashless ? <Tooltip title="Credit card"><CreditCardIcon className={classes.eventIcon}/></Tooltip> : null}
                {element.icons.isPaymentProcessing ? <Tooltip title="Have processing payment(s)"><HourglassEmptyIcon className={classes.eventIcon}/></Tooltip> : null}
                {element.icons.isClubMember ? <Tooltip title="Club member"><Face className={classes.eventIcon}/></Tooltip> : null}
                {element.icons.isOnline ? <Tooltip title="Online"><OnlineIcon className={classes.eventIcon}/></Tooltip> : null }
                {element.icons.isRequested ? <Tooltip title="RQ"><LinkIcon className={classes.eventIcon}/></Tooltip> : null }
            </div>
            { this.isPairWithSelected() ?
                <div className={classes.markPair}> </div>
                : null
            }
        </div>);
    }
}