import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import {navLinks} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import {getSalarySettingsFull} from "../../services/salary";
import {withTranslation} from "react-i18next";

export default
@withTranslation()
class SalarySettingListPage extends React.Component {

    state = {
        search: {
            search: null
        },
        refreshTable: false
    };

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/organization/salary-settings/view/0`);
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getSalarySettingsFull(search, ...params);
    };

    processParameters = (parameters, t, row) => {
        const result = [];
        for (let key in parameters) {
            if (!parameters.hasOwnProperty(key)) {
                continue;
            }

            result.push(<div key={key}>{t("salaryParameters." + row.function + "." + key)}: {parameters[key]}</div>);
        }

        return result;
    };

    columnData = [
        { id: 'name', label: 'Название' },
        { id: 'functionText', label: 'Функция' },
        { id: 'parentName', label: 'Родительские настройки'},
        { id: 'employeePostText', label: 'Должность'},
        { id: 'businessUnit.name', label: 'Бизнес юнит'},
        { id: 'regionName', label: 'Регион'},
        { id: 'employee.name', label: 'Сотрудник'},
        { id: 'parametersFormatted', label: 'Параметры', processValue: this.processParameters},
    ];

    render() {
        const { search, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Настройки расчета зарплаты">
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/organization/salary-settings/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                        rowStyleFunc={row => row.active ? null : {background: '#EEE'}}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
