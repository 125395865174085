import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import React from "react";
import {getRevisions, navLinks} from "../../services/goods";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";

const styles = theme => ({
    filterControl: {
        marginBottom: 15
    }
});

const columnData = [
    { id: 'businessUnitName', label: 'Business unit', },
    { id: 'creatorName', label: 'Creator', },
    { id: 'comment', label: 'Comment', },
    { id: 'createdAt', label: 'Created at', dateFormat: "DD.MM.YYYY HH:mm"},
];

@withRouter
@withStyles(styles)
@withTranslation()
class RevisionListPage extends React.PureComponent
{
    state = {
        search: {
            search: null
        },
        refreshTable: false
    };

    handleChangeSearch = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    }

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleClickFilter = () => {
        this.setState(state => ({
            refreshTable: !state.refreshTable,
        }));
    };

    getTableData = (...params) => {
        const { search } = this.state;
        return getRevisions({...search}, ...params);
    }

    render() {
        const {
            search,
            refreshTable,
        } = this.state;

        const { t, classes } = this.props;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChangeSearch('search')}
                        onClick={this.handleClickSearch}
                        label={t('Search')}
                        className={classes.filterControl}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                </LeftBar>
                <Content title={t('Revisions')}>
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        refresh={refreshTable}
                        selector={false}
                        linkTemplate={(row) => `/goods/revision/view/${row.id}`}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default RevisionListPage;