import baseRequest from "./baseReuqest";

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
const clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET;

export function login(username, password) {


    return baseRequest('/oauth/v2/token', {
        method: 'POST',
        body: {
            client_id: clientId,
            client_secret: clientSecret,
            grant_type: 'password',
            username,
            password,
        },
        headers:  new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
    }, false);
}
