import React from "react";
import AnyField from "./AnyField";
import * as PropTypes from "prop-types";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {InputAdornment} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

export default
class CopyField extends React.Component
{
    static propTypes = {
        ...AnyField.propTypes,
        value: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.input = React.createRef();
    }

    copy = (event) => {
        this.input.current.select();
        document.execCommand('copy');
        event.target.focus();
    };

    render() {
        const { classes, InputProps, onChange, value, ...otherProps } = this.props;

        return (
            <AnyField
                value = {value}
                readOnly
                inputRef={this.input}
                InputProps={{
                    ...InputProps,
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="Copy"
                            onClick={this.copy}
                        >
                            <FileCopyIcon/>
                        </IconButton>
                    </InputAdornment>
                }}
                {...otherProps}
            />
        );
    }
}
