const dimensions = {
    headerHeight: 130,
    slotHeight: 15,
    slotsInGroup: 4,

    subColumnWidth: 100,
    rightColumnBorderWidth: 7,
    getColumnWidth: subColumns =>
        ((subColumns && subColumns.length) || 4) * dimensions.subColumnWidth + dimensions.rightColumnBorderWidth,

    maxColumns: 6,
}

export default dimensions;
