import React from "react";
import NoWrap from "./NoWrap";
import MoneyList from "./MoneyList";

const profit = (value) => {
    return <NoWrap>
        <MoneyList value={value}/>
    </NoWrap>
}

export default profit;
