import React, {useCallback, useContext} from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {IconButton} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutline";
import Context from "../../Context";
import MoneyList from "./MoneyList";

const MoneyListDetailsDialog = ({ detailsTitle, detailsData, detailsColumns, detailsLinkTemplate, ...rest }) => {
    const { t } = useTranslation();
    const { showDetailsDialog } = useContext(Context);

    const handleClick = useCallback(() => {
        showDetailsDialog({
            title: t(detailsTitle),
            data: detailsData,
            columnsData: detailsColumns,
            linkTemplate: detailsLinkTemplate,
        });
    }, [detailsColumns, detailsData, detailsLinkTemplate, detailsTitle, showDetailsDialog, t]);

    return (
        <MoneyList {...rest}>
            <IconButton
                size="small"
                onClick={handleClick}
            >
                <HelpIcon fontSize="inherit"/>
            </IconButton>
        </MoneyList>
    )
};

MoneyListDetailsDialog.propTypes = {
    title: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({ val: PropTypes.number, cur: PropTypes.string })),
    detailsTitle: PropTypes.node,
    detailsData: PropTypes.any,
    detailsColumns: PropTypes.array,
    detailsLinkTemplate: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.number),
};

export default MoneyListDetailsDialog;