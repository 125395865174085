import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar"
import NavigationLinks from "../../components/navigation-links/NavigationLinks"
import {
    emptyStatus,
    getTaskStatuses,
    navLinks,
    saveTaskStatus
} from "../../services/tasks"
import Content from "../../components/content/Content"
import DataTable from "../../components/data-table/DataTable";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import AnyField from "../../components/fields/AnyField";
import ActionButton from "../../components/button/ActionButton";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {createChangeHandle} from "../../utils/helpers";

export default
@connect(null,{
    showMessage: messageDialogActions.show
})
class StatusesPage extends React.Component {
    columnData = [
        {id: 'title', label: 'Наименование'},
        {id: 'buttonTitle', label: 'Наименование кнопки'},
    ];

    state = {
        status: emptyStatus,
        dialogEditOpen: false,
        refreshTable: false,
    };

    handleAdd = () => {
        this.setState({
            status: emptyStatus,
            dialogEditOpen: true,
        })
    }

    handleEdit = status => {
        this.setState({
            status,
            dialogEditOpen: true,
        })
    };

    handleChange = createChangeHandle(this, 'status')

    handleCloseEditDialog = ok => () => {
        if (!ok) {
            this.setState({
                dialogEditOpen: false,
            });
            return;
        }

        saveTaskStatus(this.state.status)
            .then(response => {
                if (response.success) {
                    this.setState({
                        dialogEditOpen: false,
                        refreshTable: !this.state.refreshTable,
                    })
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })

    }

    render() {
        const { status, refreshTable, dialogEditOpen } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
            </LeftBar>
            <Content title="Статусы задач">
                <DataTable
                    columnData={this.columnData}
                    dataFunc={getTaskStatuses}
                    selector={false}
                    refresh={refreshTable}
                    onClick={this.handleEdit}
                />
            </Content>
            <Dialog
                open={dialogEditOpen}
                onClose={this.handleCloseEditDialog(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">Статус задачи</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AnyField
                                label="Наименование"
                                value={status.title}
                                name="title"
                                onChange={this.handleChange}
                                required
                                error={!status.title}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AnyField
                                label="Наименование кнопки"
                                value={status.buttonTitle}
                                name="buttonTitle"
                                onChange={this.handleChange}
                                required
                                error={!status.buttonTitle}
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseEditDialog(true)} disabled={!status.title || !status.buttonTitle} color="primary">OK</Button>
                    <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    }
}
