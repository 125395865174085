import React from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {formatMoney} from "../../../../../services/common";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    }
})

const MoneyValue = ({ title, value, children }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (value?.val) {
        return (
            <div className={!!children ? classes.flex : null}>{t(title)}: {formatMoney(value)} {children}</div>
        );
    }

    return null;
};

MoneyValue.propTypes = {
    title: PropTypes.string,
    value: PropTypes.shape({ val: PropTypes.number, cur: PropTypes.string }),
    children: PropTypes.node,
};

export default MoneyValue;