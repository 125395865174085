import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, MenuItem
} from '@material-ui/core';
import {
    createCheckingAccount,
    getCheckingAccount,
    getJuridicalPersons,
    getBanks,
    navLinks,
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import {getCurrencies} from "../../services/common";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import CheckboxField from "../../components/fields/CheckboxField";
import {withTranslation} from "react-i18next";
import FieldsList from "../../components/fields/FieldsList";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import moment from "moment/moment";
import PercentField from "../../components/fields/PercentField";

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
    container: {
        width: 900,
        marginBottom: 10,
    },
    cb: {
        marginTop: 10,
    },
    checkboxFilter: {
        marginTop: 0,
    },
    field: {
        marginTop: 0,
    },
});

@withRouter
@withTranslation()
class CheckingAccountViewPage extends React.Component {

    state = {
        checkingAccount: {
            id: null,
            rs: null,
            kr: null,
            bik: null,
            bankName: null,
            bankAddress: null,
            bankId: null,
            juridicalPersonId: null,
            commissionFormula: null,
            currency: null,
            active: true,
            acquiring: false,
            acquir: [],
            acquiringCardPeriods: [],
            acquiringSbpPeriods: [],
            acquiringSberQrPeriods: [],
        },
        juridicalPersons: [],
        banks: [],
        currencies: []
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getCheckingAccount(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            checkingAccount: response.data
                        })
                    }
                });
        }

        getBanks()
            .then(response=>{
                if(response.success){
                    this.setState({
                        banks: response.data
                    })
                }
            })

        getJuridicalPersons()
            .then(response => {
                if (response.success) {
                    this.setState({
                        juridicalPersons: response.data,
                    })
                }
            });

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data
                    });
                }
            });

    }

    handleChange = prop => event => {
        const { checkingAccount } = this.state;
        if (prop === 'acquiringCardPeriods') {
            event.target.value = event.target.value.map(item => {
                item.type = 'card';
                return item;
            })
        } else if (prop === 'acquiringSberQrPeriods') {
            event.target.value = event.target.value.map(item => {
                item.type = 'sberQr';
                return item;
            })
        } else if (prop === 'acquiringSbpPeriods') {
            event.target.value = event.target.value.map(item => {
                item.type = 'sbp';
                return item;
            })
        }

        this.setState({ checkingAccount: {...checkingAccount, [prop]: event.target.value} });
    };

    handleSave = () => {
        createCheckingAccount(this.state.checkingAccount)
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/checking-accounts`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    render() {
        const { classes, t } = this.props;
        const { checkingAccount, juridicalPersons, currencies, banks } = this.state;

        const saveDisabled = !checkingAccount.rs ||
                            !checkingAccount.kr ||
                            !checkingAccount.bankId ||
                            (Object.values(checkingAccount.acquiringCardPeriods).find(ac => !ac.startedAt || !ac.percent) ||
                            Object.values(checkingAccount.acquiringSbpPeriods).find(ac => !ac.startedAt || !ac.percent) ||
                            Object.values(checkingAccount.acquiringSberQrPeriods).find(ac => !ac.startedAt || !ac.percent))

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br />
                    <br />
                    <ActionButton onClick={this.handleSave} disabled={saveDisabled}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Карточка расчетного счета">


                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="rs"
                                label="Р/С"
                                value={checkingAccount.rs}
                                fullWidth
                                error={!checkingAccount.rs}
                                onChange={this.handleChange('rs')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="kr"
                                label="К/С"
                                value={checkingAccount.kr}
                                fullWidth
                                error={!checkingAccount.kr}
                                onChange={this.handleChange('kr')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteSelectField
                                label="Юридическое лицо"
                                value={checkingAccount.juridicalPersonId}
                                onChange={this.handleChange('juridicalPersonId')}
                                error={!checkingAccount.juridicalPersonId }
                                fullWidth
                                select
                                options={juridicalPersons.map(item => ({value: item.id, label: item.shortName}))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AutocompleteSelectField
                                label="Банк"
                                value={checkingAccount.bankId}
                                fullWidth
                                error={!checkingAccount.bankId}
                                onChange={this.handleChange('bankId')}
                                required
                                select
                                options={banks.map(bank=>({value: bank.id, label: bank.name}))}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AnyField
                                id="commissionFormula"
                                label="Формула расчета комиссии"
                                value={checkingAccount.commissionFormula}
                                fullWidth
                                onChange={this.handleChange('commissionFormula')}
                                multiline
                                rows={4}
                                helperText="sum - сумма сделки  Например: sum*0.06 будет значить что комиссия - 6%"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Валюта"
                                value={checkingAccount.currency}
                                fullWidth
                                onChange={this.handleChange('currency')}
                                select
                                readOnly={ !!checkingAccount.id }
                            >
                                <MenuItem value={null}>Не выбрано</MenuItem>
                                {currencies.map(item => <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>)}
                            </AnyField>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={8}>
                            <FieldsList
                                items={Object.values(checkingAccount.acquiringCardPeriods)}
                                emptyItem={{id: null, startedAt: null, endedAt: null, percent: 0}}
                                fullWidth
                                label={t('Acquiring period (card)')}
                                name="Card"
                                onChange={this.handleChange("acquiringCardPeriods")}
                                headers={[t('Period'), t('Percents')]}
                                fields={[
                                    (item, onChange) =>
                                        <DatePeriodField2
                                            valueFrom={moment(item.startedAt).utcOffset(0, false)}
                                            valueTo={moment(item.endedAt).utcOffset(0,false)}
                                            onChangeFrom={onChange('startedAt')}
                                            onChangeTo={onChange('endedAt')}
                                            className={classes.field}
                                            invalidDateMessage={''}
                                        />,
                                    (item, onChange) =>
                                        <PercentField
                                            value={item.percent}
                                            required
                                            fullWidth
                                            name="percent"
                                            error={!item.percent}
                                            onChange={onChange('percent')}
                                        />
                                ]}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FieldsList
                                items={Object.values(checkingAccount.acquiringSbpPeriods)}
                                emptyItem={{id: null, startedAt: null, endedAt: null, percent: 0}}
                                fullWidth
                                label={t('Acquiring period (sbp)')}
                                name="SberQr"
                                onChange={this.handleChange("acquiringSbpPeriods")}
                                headers={[t('Period'), t('Percents')]}
                                fields={[
                                    (item, onChange) =>
                                        <DatePeriodField2
                                            valueFrom={moment(item.startedAt).utcOffset(0, false)}
                                            valueTo={moment(item.endedAt).utcOffset(0,false)}
                                            onChangeFrom={onChange('startedAt')}
                                            onChangeTo={onChange('endedAt')}
                                            className={classes.field}
                                            invalidDateMessage={''}
                                        />,
                                    (item, onChange) =>
                                        <PercentField
                                            value={item.percent}
                                            required
                                            fullWidth
                                            name="percent"
                                            error={!item.percent}
                                            onChange={onChange('percent')}
                                        />
                                ]}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FieldsList
                                items={Object.values(checkingAccount.acquiringSberQrPeriods)}
                                emptyItem={{id: null, startedAt: null, endedAt: null, percent: 0}}
                                fullWidth
                                label={t('Acquiring period (sber qr)')}
                                name="SberQr"
                                onChange={this.handleChange("acquiringSberQrPeriods")}
                                headers={[t('Period'), t('Percents')]}
                                fields={[
                                    (item, onChange) =>
                                        <DatePeriodField2
                                            valueFrom={moment(item.startedAt).utcOffset(0, false)}
                                            valueTo={moment(item.endedAt).utcOffset(0,false)}
                                            onChangeFrom={onChange('startedAt')}
                                            onChangeTo={onChange('endedAt')}
                                            className={classes.field}
                                            invalidDateMessage={''}
                                        />,
                                    (item, onChange) =>
                                        <PercentField
                                            value={item.percent}
                                            required
                                            fullWidth
                                            name="percent"
                                            error={!item.percent}
                                            onChange={onChange('percent')}
                                        />
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <CheckboxField
                        label={t('Active')}
                        value={checkingAccount.active}
                        onChange={this.handleChange('active')}
                        className={classes.checkboxFilter}
                    />
                    <CheckboxField
                    label={t('Acquiring')}
                    value={checkingAccount.acquiring}
                    onChange={this.handleChange('acquiring')}
                    className={classes.checkboxFilter}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(withStyles(styles)(CheckingAccountViewPage));
