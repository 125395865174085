import * as queryString from "query-string-object";
import baseRequest from "./baseReuqest";

export const navLinks = [
    { path: "/facilities", text: "Услуги"},
    ];

export function getFacilities(search, page, per_page, order, order_by) {
    let url = '/api/facilities';
    const q = queryString.stringify({search, page, per_page, order, order_by});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function getFacilitiesVisit(search) {
    return baseRequest('/api/facilities-visit', {
        query: {search, per_page: -1}
    });
}

export function getFacilityTypes() {
    return baseRequest('/api/facility/types');
}

export function createFacility(facility) {
    return baseRequest('/api/facility/create', {
        method: 'POST',
        body: facility,
    });
}

export function getFacility(id) {
	return baseRequest(`/api/facility/id/${id}`);
}

export function updateFacility(facility) {
	return baseRequest(`/api/facility/id/${facility.id}`, {
		method: 'PUT',
		body: facility,
	});
}

export function getPrices(search, page, per_page, order, order_by) {
    const q = queryString.stringify({search, page, per_page, order, order_by});
    let url = '/api/price';

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function getPriceBy (search) {
    let url = `/api/price/by`;
    const q = queryString.stringify({search});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function getPriceById(id) {
    return baseRequest(`/api/price/id/${id}`);
}

export function updatePrice(price) {
    return baseRequest(`/api/price/id/${price.id}`, {
        method: 'PUT',
        body: JSON.stringify(price),
    });
}

export function createPrice(price) {
    return baseRequest('/api/price/create', {
        method: 'POST',
        body: JSON.stringify(price),
    });
}

export function fillPrice(fillPrice) {
    return baseRequest(`/api/price/fill/${fillPrice.id}`, {
        method: 'PUT',
        body: JSON.stringify(fillPrice),
    });
}

export function getHistoryChange(id, page, per_page, order, order_by) {
    let url = `/api/facility/history-change/${id}`;
    const q = queryString.stringify({page, per_page, order, order_by});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export function getGoods(category) {
    let url = `/api/goods`;
    const q = queryString.stringify({category});

    if (q) {
        url += `?${q}`;
    }

    return baseRequest(url);
}

export const priceStatusName = {
    draft: 'Черновик',
    active: 'Активный',
    inactive: 'Не активный',
}

export const priceStatus = {
    draft: 'draft',
    active: 'active',
    inactive: 'inactive',
}
