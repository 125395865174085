export function spendingXLSXRowsToDTOArray(data, keys) {
    let DTOArray = [];
    let error = false;

    data.forEach((row) => {
        let dto = {};
        for (let prop in keys) {
            let XLSXKey = keys[prop];
            if (typeof row[XLSXKey] === 'undefined') {
                error = 'Колонка "' + XLSXKey + '" не существует';
                return false;
            }
            dto[prop] = row[XLSXKey];
        }
        DTOArray.push(dto);
    });

    if (error) {
        return error;
    }

    return DTOArray;
}