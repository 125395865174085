import {withTranslation} from "react-i18next";
import React from "react";
import {CircularProgress, Grid} from "@material-ui/core";
import AnyField from "../../../components/fields/AnyField";
import * as PropTypes from "prop-types";
import {getGuestNalegke} from "../../../services/guests";

export default
@withTranslation()
class ViewPageTabNalegke extends React.PureComponent {
    static propTypes = {
        guest: PropTypes.object,
    };

    state = {
        nalegke: null,
        loading: true,
    };

    get nalegke() {
        return (this.state.nalegke ?? []).filter((item) => {
            return item.usedTotal !== 0;
        });
    }

    componentDidMount() {
        getGuestNalegke(this.props.guest.id)
            .then(response => {
                if (response.success) {
                    this.setState({
                        nalegke: response.data,
                        loading: false,
                    })
                }
            });
    }

    render() {
        return (
            this.state.loading
                ? <CircularProgress/>
                : (this.nalegke.length === 0 ? 'Не найдено активных скидок' : <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {this.nalegke.map((item, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={4}>
                                    <AnyField
                                        label={'Название скидки'}
                                        value={item.name}
                                        fullWidth
                                        readOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        label={'Использовано часов'}
                                        value={item.usedText}
                                        fullWidth
                                        readOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        label={'Остаток часов'}
                                        value={item.remainText}
                                        fullWidth
                                        readOnly
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>)
        )
    }
}