import income from "./income";
import spending from "./spending";
import spending2 from "./spending2";
import debts from "./debts";
import debts2 from "./debts2";
import masterHourAvg from "./masterHourAvg";
import incomeVisits from "./incomeVisits";
import incomeGoods from "./incomeGoods";
import pdBalance from "./pdBalance";
import revenue from "./revenue";
import otherSupply from "./otherSupply";
import masterWorkload from "./masterWorkload";
import distributionCheck from "./distributionCheck";
import vgo from "./vgo";

const groupStrategy = {
    income,
    spending,
    spending2,
    debts,
    debts2,
    masterHourAvg,
    incomeVisits,
    incomeGoods,
    pdBalance,
    distributionCheck,
    revenue,
    otherSupply,
    masterWorkload,
    vgo
}

export default groupStrategy;
