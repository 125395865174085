import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import { navLinks, specialDiscountsReport} from "../../services/moneyFlow";
import moment from "moment";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import XLSX from 'xlsx';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import {getBusinessUnitByRole} from "../../services/user";
import {getDiscounts} from "../../services/calendar";
import {getPersonalDiscountNames} from "../../services/sale";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import {FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import DateMonthField from "../../components/fields/DateMonthField";

const styles = theme => ({
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    },
    h6: {
        marginTop: 30
    }
});

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class SpecialDiscountsReportPage extends React.Component {
    state = {
        search: {
            dateType: 'period',
            month: moment(),
            dateStart: null,
            dateEnd: null,
            businessUnitIds: [],
            discountIds: [],
            personalDiscountNames: [],
        },
        groupedByParlours: [],
        groupedByDiscounts: [],
        businessUnits: [],
        discounts: [],
        personalDiscounts: [],
    };

    columnDataParlours = [
        { id: 'businessUnitName', label: 'Business unit', },

        { id: 'moneyTotal', label: 'Money Total', },
        { id: 'loadingTotal', label: 'Hours Total', },
        { id: 'avgHourTotal', label: 'AVG Hour Total', },

        { id: 'moneyUnfree', label: 'Money Unfree', },
        { id: 'loadingUnfree', label: 'Hours Unfree', },
        { id: 'avgHourUnfree', label: 'AVG Hour Unfree', },

        { id: 'moneyFree', label: 'Money Free', },
        { id: 'loadingFree', label: 'Hours Free', },
        { id: 'avgHourFree', label: 'AVG Hour Free', },

        { id: 'moneySpecial', label: 'Money Special', },
        { id: 'loadingSpecial', label: 'Hours Special', },
        { id: 'avgHourSpecial', label: 'AVG Hour Special', },

        { id: 'moneyResult', label: 'Money Result', },
        { id: 'loadingResult', label: 'Hours Result', },
        { id: 'avgHourResult', label: 'AVG Hour Result', },
    ];

    columnDataDiscount = [
        { id: 'discountName', label: 'Discount', },

        { id: 'moneyTotal', label: 'Money Total', },
        { id: 'loadingTotal', label: 'Hours Total', },
        { id: 'avgHourTotal', label: 'AVG Hour Total', },

        { id: 'moneyUnfree', label: 'Money Unfree', },
        { id: 'loadingUnfree', label: 'Hours Unfree', },
        { id: 'avgHourUnfree', label: 'AVG Hour Unfree', },

        { id: 'moneyFree', label: 'Money Free', },
        { id: 'loadingFree', label: 'Hours Free', },
        { id: 'avgHourFree', label: 'AVG Hour Free', },

        { id: 'moneySpecial', label: 'Money Special', },
        { id: 'loadingSpecial', label: 'Hours Special', },
        { id: 'avgHourSpecial', label: 'AVG Hour Special', },
    ];

    componentDidMount() {
        getBusinessUnitByRole('ROLE_ANALYTICS_SPECIAL_DISCOUNTS_REPORT')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data
                    });
                }
            });

        getDiscounts({active: 'active,not_active', forSalableEntity: 'Visit'})
            .then(response => {
                if (response.success) {
                    this.setState({
                        discounts: response.data
                    })
                }
            });

        getPersonalDiscountNames()
            .then(response => {
                if (response.success) {
                    this.setState({
                        personalDiscounts: response.data
                    })
                }
            });

        this.getTableData();
    }

    handleChange = prop => event => {
        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        this.getTableData();
    };

    getTableData = () => {
        const {search} = this.state;
        specialDiscountsReport(search)
            .then(response => {
                if (response.success) {
                    this.setState({
                        groupedByParlours: response.data.groupedByParlours,
                        groupedByDiscounts: response.data.groupedByDiscounts,
                    })
                }
            });
    };

    handleExport = () => {
        const { t } = this.props;

        const dataByParlours = [
            this.columnDataParlours.map(item => t(item.label) ),
        ];

        this.state.groupedByParlours.forEach(row => {
            dataByParlours.push(
                this.columnDataParlours.map(item => _.get(row, item.id))
            );
        });

        let worksheetByParlours = XLSX.utils.aoa_to_sheet(dataByParlours);
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheetByParlours, "By parlours");


        const dataByDiscounts = [
            this.columnDataDiscount.map(item => t(item.label) ),
        ];

        this.state.groupedByDiscounts.forEach(row => {
            dataByDiscounts.push(
                this.columnDataDiscount.map(item => _.get(row, item.id))
            );
        });

        let worksheetByDiscounts = XLSX.utils.aoa_to_sheet(dataByDiscounts);
        XLSX.utils.book_append_sheet(workbook, worksheetByDiscounts, "By discounts");

        XLSX.writeFile(workbook, `SpecialDiscounts.xlsx`);
    };

    render() {
        const { classes, t } = this.props;
        const { groupedByParlours, groupedByDiscounts, search, businessUnits, discounts, personalDiscounts } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.dateType}
                            onChange={this.handleChange('dateType')}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="period" label="за период" />
                            <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
                        </RadioGroup>
                    </FormControl>
                    {search.dateType === 'period' ?
                        <DatePeriodField2
                            valueFrom={search.dateStart}
                            valueTo={search.dateEnd}
                            maxDate={moment()}
                            onChangeFrom={this.handleChange('dateStart')}
                            onChangeTo={this.handleChange('dateEnd')}
                            checkValuesOrder
                        /> :
                        <DateMonthField
                            value={search.month}
                            onChange={this.handleChange('month')}
                            fullWidth
                        />
                    }

                    <MultiSelectFromItemsField
                        items={businessUnits}
                        value={search.businessUnitIds}
                        onChange={this.handleChange('businessUnitIds')}
                        textField="name"
                        fullWidth
                        label={t('Business units')}
                        emptySelectedLabel="Не выбрано"
                    />

                    <AutocompleteMultiSelectField
                        options={discounts}
                        value={search.discountIds}
                        onChange={this.handleChange('discountIds')}
                        textField="name"
                        fullWidth
                        label={t('Discounts')}
                    />

                    <AutocompleteMultiSelectField
                        options={personalDiscounts}
                        value={search.personalDiscountNames}
                        onChange={this.handleChange('personalDiscountNames')}
                        textField="name"
                        valueField="name"
                        fullWidth
                        label={t('Personal discounts')}
                    />

                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t('Special discounts report')}>
                    <Grid item xs={12} className={classes.h6}>
                        <Typography variant="h6" gutterBottom>{t('By parlours')}</Typography>
                    </Grid>
                    <DataTableLocal
                        columnData={this.columnDataParlours}
                        data={groupedByParlours}
                        selector={false}
                        pagination={false}
                        disableSorting
                    />

                    <Grid item xs={12} className={classes.h6}>
                        <Typography variant="h6" gutterBottom>{t('By discounts')}</Typography>
                    </Grid>
                    <DataTableLocal
                        columnData={this.columnDataDiscount}
                        data={groupedByDiscounts}
                        selector={false}
                        pagination={false}
                        disableSorting
                    />
                </Content>
            </React.Fragment>
        );
    }
}
