import React from "react";
import NoWrap from "./NoWrap";
import MoneyList from "./MoneyList";
import MoneyListDetailsDialog from "./MoneyListDetailsDialog";
import {formatMoney} from "../../../../../services/common";

const otherDetailsColumns = [
    { id: 'entityDate', dateFormat: 'DD.MM.YYYY HH:mm', label: 'Date' },
    { id: 'payDocNum', label: 'Payment document', linkTemplate: (item) => `/pay-docs/view/${item.payDocId}` },
    { id: 'amount', label: 'Amount', processValue: formatMoney },
    { id: 'payDocParlourInfo.name', label: 'Parlour' },
];

const incomeVisits = (value) => {
    const pdOtherDetails = value.details?.filter(
        (d) => d.seType === 'visit' && d.type === 'paydoc' && d.payDocParlour === 'other' && ['paid'].indexOf(d.status) !== -1 && !d.isFree
    ) || [];
    const pdOwnDetails = value.details?.filter(
        (d) => d.seType === 'visit' && d.type === 'paydoc' && d.payDocParlour === 'owner' && ['paid'].indexOf(d.status) !== -1 && !d.isFree
    ) || [];

    return <NoWrap>
        <MoneyList title="f.Cash" value={value.visit_cash}/>
        <MoneyList title="f.Cashless" value={value.visit_cashless}/>
        <MoneyListDetailsDialog detailsData={pdOwnDetails} detailsColumns={otherDetailsColumns} detailsTitle="f.PDOwner"
                                title="f.PDOwner" detailsLinkTemplate={(row) => `/orders/view/${row.entityId}`}
                                value={value.visit_paydoc_own}/>
        <MoneyListDetailsDialog detailsData={pdOtherDetails} detailsColumns={otherDetailsColumns} detailsTitle="f.PDOther"
                                title="f.PDOther" detailsLinkTemplate={(row) => `/orders/view/${row.entityId}`}
                                value={value.visit_paydoc_other}/>
    </NoWrap>;
};

export default incomeVisits;
