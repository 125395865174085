import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import DatePeriodField from "../../components/fields/DatePeriodField";
import moment from "moment";
import AnyField from "../../components/fields/AnyField";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {withTranslation} from "react-i18next";
import {columnDataSales, getSales} from "../../services/sale";
import {loadTlSalesFilter, saveTlSalesFilter} from "../../services/localStorage";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";

export default
@connect(null,{
    showMessage: messageDialogActions.show,
})
@withTranslation()
class TransactionsCashListPage extends React.Component {
    constructor(props) {
        super(props);

        const search = loadTlSalesFilter();
        if (search) {
            search.date_start = moment(search.date_start);
            search.date_end = moment(search.date_end);
        }

        this.state = {
            search: {
                search: null,
                date_start: moment().add(-7, 'days'),
                date_end: moment(),
                businessUnitId: null,
                ...search,
            },
            refreshTable: false,
            businessUnits: [],
        };
    }

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                }
            });
    }

    handleChange = prop => event => {

        let value;

        if (event instanceof moment) {
            value = event;
        } else {
            value = event.target.value;
        }

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        });
    };

    handleClickFilter = () => {
        saveTlSalesFilter(this.state.search);
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getSales(search, ...params);
    };

    render() {
        const { t } = this.props;
        const { refreshTable, search, businessUnits } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={[]}/>}>
                    <DatePeriodField
                        valueFrom={search.date_start}
                        valueTo={search.date_end}
                        maxDate={moment()}
                        onChangeFrom={this.handleChange('date_start')}
                        onChangeTo={this.handleChange('date_end')}
                        label={"Фильтр по дате создания"}
                    />
                    <SelectFromItemsField
                        items={businessUnits}
                        label={t('Business unit')}
                        value={search.businessUnitId}
                        onChange={this.handleChange('businessUnitId')}
                        fullWidth
                        nullable
                        nullableText="Все"
                    />
                    <AnyField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        fullWidth
                        label={t('Search')}
                    />
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title="Продажи">
                    <DataTable
                        columnData={columnDataSales}
                        dataFunc={this.getTableData}
                        selector={false}
                        refresh={refreshTable}
                        linkTemplate={sale => `/tools/sales/${sale.id}`}
                        order="desc"
                    />
                </Content>
            </React.Fragment>
        );
    }
}
