import React from "react";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getPayDocRefill, navLinks, rechargeChangeCreator, rechargeChangeDate} from "../../services/payDocs";
import Content from "../../components/content/Content";
import {withTranslation} from "react-i18next";
import ShowField from "../../components/fields/ShowField";
import Pay from "../../components/pay/Pay";
import ActionButton from "../../components/button/ActionButton";
import {history} from "../../history";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import {deletePayDocRefill, getSale} from "../../services/sale";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DateField2 from "../../components/fields/DateField2";
import {getCurrentUser, getUsers, hasRole} from "../../services/user";
import {IconButton, InputAdornment} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import {kkmService} from "../../services/kkm";

export default
@withTranslation()
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show
})
class RefillViewPage extends React.Component
{
    state = {
        payDocRefill: {
            id: null,
            amount: null,
            guestId: null,
            guestFullName: null,
            guestPhone: null,
            payDocId: null,
            payDocNum: null,
            businessUnitId: null,
            businessUnitName: null,
            saleId: null,
            discountId: null,
            discountName: null,
            createdAt: null,
            creatorName: null,
            creatorId: null,
            total: null,
            totalFormatted: null,
        },
        changeDateDialogOpen: false,
        changeDate: null,
        availableCreators: [],
        user: {},
        isPrintReceiptModalOpen: false
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length) {
            getPayDocRefill(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            payDocRefill: response.data,
                        })
                    }
                })

            getUsers().then(response => {
                if (response.success) {
                    this.setState({
                        availableCreators: response.data.map(user => ({value: user.id, label: user.username})),
                    });
                }
            });

            getCurrentUser().then(response => {
                if (response.success) {
                    this.setState({
                        user: response.data,
                    });
                }
            })
        }
    }

    handleClickBack = () => {
        const { payDocRefill } = this.state;

        history.push(`/pay-docs/history/balance/${payDocRefill.payDocId}`);
    };

    handleClickDelete = () => {
        const { payDocRefill } = this.state;
        let backUrl = `/pay-docs/history/balance/${payDocRefill.payDocId}`;

        this.props.showConfirm({
            message: 'Это действие удалит пополнение ПД и обновит балансы. Вы уверены?',
            title: 'Удаление пополнения ПД',
            onClose: (ok) => {
                if (!ok) {
                    return;
                }

                deletePayDocRefill(payDocRefill.id)
                    .then(response => {
                        if (response.success) {
                            this.props.showInfo('Пополнение удалено.');
                            history.push(backUrl);
                        } else {
                            this.props.showMessage(response.error ? response.error.message : response.message);
                        }
                    });
            }
        });


    }

    handleClickChangeDate = () => {
        this.setState({
            changeDateDialogOpen: true,
            changeDate: null,
        });
    }

    handleChangeDate = event => {
        this.setState({
            changeDate: event.target.value,
        });
    }

    handleClickChangeDateOk = () => {
        rechargeChangeDate(this.state.payDocRefill.id, this.state.changeDate.format('YYYY-MM-DD'))
            .then(response => {
                if (response.success) {
                    this.handleClickBack();
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    handleClickChangeDateCancel = () => {
        this.setState({
            changeDateDialogOpen: false,
        });
    }

    handleChangeCreator = event => {
        const {t} = this.props;

        this.props.showConfirm({
            message: t('Are you sure you want to change creator'),
            title: t('Change of paydoc refill creator'),
            onClose: ok => {
                if (!ok) return;

                const { payDocRefill} = this.state;

                rechargeChangeCreator(payDocRefill.id, event.target.value)
                    .then(response => {
                        if (response.success){
                            this.props.showInfo(t('Creator changed'));
                            this.setState({
                                payDocRefill: {
                                    ...this.state.payDocRefill,
                                    creatorId: event.target.value
                                }
                            });
                        } else {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
            }
        });
    };

    handlePrintRefundReceipt = () => {
        const { t } = this.props
        let [username, cash, cashless, sumPaid, items] = ['', 0, 0, 0, []]

        getSale(this.state.payDocRefill.saleId)
            .then(response => {
                if (response.data.payments.length > 0)
                    response.data.payments.forEach((payment) => {
                        if (payment.status === 'paid') {
                            if (payment.type === 'cashless')
                                cashless += payment.amount.val
                            else if (payment.type === 'cash')
                                cash += payment.amount.val
                        }
                    });

                sumPaid = cash + cashless;
                username = this.state.user.username

                items.push({
                    title: this.state.payDocRefill.payDocTypeReceiptName,
                    cost: sumPaid,
                })

                kkmService.registerCheck(
                    username,
                    cash,
                    cashless,
                    items,
                    4,
                    true
                ).then(() => {
                    this.props.showInfo(t('Receipt printed'))
                })

            })
    }

    handleOpenPrintReceiptModal = () => {
        this.setState({
            isPrintReceiptModalOpen: true
        })
    }

    handlePrintReceiptModalApproved = () => {
        this.handlePrintRefundReceipt();
        this.setState({
            isPrintReceiptModalOpen: false
        })
    }

    handlePrintReceiptModalReject = () => {
        this.setState({
            isPrintReceiptModalOpen: false
        })
    }

    render() {
        const { t } = this.props;
        const {
            payDocRefill, changeDateDialogOpen,
            changeDate, availableCreators,
            isPrintReceiptModalOpen
        } = this.state;

        return <React.Fragment>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                <ShowField
                    label={t("Number")}
                    value={payDocRefill.payDocNum}
                />
                <ShowField
                    label={t("Amount")}
                    value={payDocRefill.amount}
                />
                <ShowField
                    label={t("Cost")}
                    value={payDocRefill.totalFormatted}
                />
                <ShowField
                    label={t("Discount")}
                    value={payDocRefill.discountName}
                />
                <ShowField
                    label={t("Parlour")}
                    value={payDocRefill.businessUnitName}
                />
                <ShowField
                    label={t("Guest")}
                    value={payDocRefill.guestFullName}
                    linkTo={`/guests/view/${payDocRefill.guestId}`}
                />
                <ShowField
                    label={t("Guest phone")}
                    value={payDocRefill.guestPhone}
                />
                <ShowField
                    label={t('Created date')}
                    value={payDocRefill.createdAt}
                    dateFormat="DD.MM.YYYY hh:mm:ss"
                    endAdornment={Boolean(payDocRefill.payDocId) && hasRole('ROLE_PAYDOC_PAYDOC_RECHARGE_CHANGE_DATE') &&
                        <InputAdornment position="end">
                            <IconButton onClick={this.handleClickChangeDate}><Edit /></IconButton>
                        </InputAdornment>
                    }
                />
                { hasRole('ROLE_PAYDOC_PAYDOC_RECHARGE_CHANGE_CREATOR') ?
                    <AutocompleteSelectField
                        label={t("Creator")}
                        value={payDocRefill.creatorId}
                        fullWidth
                        options={availableCreators}
                        onChange={this.handleChangeCreator}
                    />
                    :
                    <ShowField
                        label={t('Creator')}
                        value={payDocRefill.creatorName}
                    />
                }


                <ActionButton onClick={this.handleClickBack} visible={Boolean(payDocRefill.payDocId)}>{t("Back")}</ActionButton>
                <ActionButton onClick={this.handleClickDelete} visible={Boolean(payDocRefill.payDocId)}>{t("Delete")}</ActionButton>
                <ActionButton onClick={this.handleOpenPrintReceiptModal}>{t("Print refund receipt")}</ActionButton>
            </LeftBar>
            <Content title={t("PayDocRefill")}>
                <Pay
                    saleId={payDocRefill.saleId}
                    enableCheckingAccountPaymentType
                />
            </Content>
            <Dialog open={changeDateDialogOpen} maxWidth="sm" onClose={this.handleClickChangeDateCancel}>
                <DialogTitle>{t('Change date')}</DialogTitle>
                <DialogContent>
                    <DateField2
                        label={t('Date')}
                        value={changeDate}
                        onChange={this.handleChangeDate}
                        required
                        error={!changeDate}
                    />
                </DialogContent>
                <DialogActions>
                    <ActionButton fullWidth={false} disabled={!changeDate} onClick={this.handleClickChangeDateOk}>OK</ActionButton>
                    <ActionButton fullWidth={false} onClick={this.handleClickChangeDateCancel}>{t('Cancel')}</ActionButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isPrintReceiptModalOpen}
                onClose={this.handlePrintReceiptModalReject}
            >
                <DialogTitle id="alert-dialog-title">{"Провести возврат по кассе?"}</DialogTitle>
                <DialogActions>
                    <ActionButton fullWidth={false} onClick={this.handlePrintReceiptModalApproved}>{t('Yes')}</ActionButton>
                    <ActionButton fullWidth={false} onClick={this.handlePrintReceiptModalReject}>{t('No')}</ActionButton>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    }
}