import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import {
    getPayDocPromo,
    createPayDocPromo,
    updatePayDocPromo,
    removePayDocPromo,
    disablePayDocPromo,
    navLinks,
    getPayDocTypes
} from "../../services/payDocs";
import {getRegions} from "../../services/organization";
import { getDiscounts } from "../../services/calendar";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import moment from "moment";
import {withTranslation} from "react-i18next";
import DateField from "../../components/fields/DateField";
import FloatField from "../../components/fields/FloatField";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";


const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    container: {
        width: 900,
        marginBottom: 10,
    },
    securityContainer: {
        marginBottom: 15,
    },
    del: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    paper: {
        overflow: 'auto',
        height: 300,
    },
});

@connect(state => ({
    currentDate: state.calendar.currentDate,
}))
@withRouter
@withTranslation()
class PayDocPromoViewPage extends React.Component {

    state = {
        payDocPromo: {
            id: null,
            name: null,
            description: null,
            dateStart: null,
            dateEnd: null,
            comment: null,
            enableActivation: false,
            payDocType: null,
        },
        regions: [],
        types: [],
        discounts: [],
        currentType: null,
        currentDiscount: null,
        currentRegions: []
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        const getPayDocTypesPromise = getPayDocTypes({active: 1, electronic: 1, showInMobile: 1}).then(response => {
            if (response.success) {
                this.setState({types: response.data});
                return response.data;
            }
            return false;
        });
        const getDiscountsPromise = getDiscounts().then(response => {
            if (response.success) {
                this.setState({discounts: response.data});
                return response.data;
            }
            return false;
        });
        const getRegionsPromise = getRegions().then(response => {
            if (response.success) {
                this.setState({regions: response.data});
                return response.data;
            }
            return false;
        });

        if (resultId.length && resultId[0] !== '0') {
            getPayDocPromo(resultId[0]).then(response => {
                if (response.success) {
                    const typeId = response.data.type.id;
                    this.setState({ payDocPromo: { ...response.data, payDocType: typeId } });
                    getPayDocTypesPromise.then(types => {
                        let curType = types.filter(type => type.id === typeId);
                        if (!curType.length) return;
                        else curType = curType[0];

                        this.setState({ currentType: curType });

                        const availableRegions = curType.availableRegions.filter((value, index, array) => array.indexOf(value) === index);
                        getRegionsPromise.then(regions => {
                            let resultRegions = [];
                            availableRegions.forEach(regionId => {
                                resultRegions.push(regions.filter(region => region.id === regionId)[0]);
                            })
                            this.setState({ currentRegions: resultRegions });
                        });

                        getDiscountsPromise.then(discounts => {
                            const curDiscount = discounts.filter(discount => discount.id === curType.fixedRefillDiscount)[0];
                            this.setState({ currentDiscount: curDiscount });
                        });
                    });
                }
            });
        }
    }

    handleChange = event => {
        const { payDocPromo, types, discounts, regions } = this.state;
        const eventName = event.target.name,
            eventValue = event.target.value;
        this.setState({payDocPromo: {...payDocPromo, [eventName]: eventValue}});
        if (eventName === 'payDocType') {
            const curType = types.filter(type => type.id === eventValue)[0];
            const curDiscount = discounts.filter(discount => discount.id === curType.fixedRefillDiscount)[0];

            const availableRegions = curType.availableRegions.filter((value, index, array) => array.indexOf(value) === index);
            let resultRegions = [];
            availableRegions.forEach(regionId => {
                resultRegions.push(regions.filter(region => region.id === regionId)[0]);
            })

            this.setState({ currentType: curType, currentDiscount: curDiscount, currentRegions: resultRegions });
        }
    };

    handleChangeByName = prop => event => {
        let value = null;
        if (event instanceof moment) value = event;
        else if (event != null) value = moment(event.target.value);

        this.setState({
            payDocPromo: {
                ...this.state.payDocPromo,
                [prop]: value,
            }
        });
    };

    handleSave = () => {
        let { payDocPromo } = this.state;
        if (!(payDocPromo.dateStart instanceof moment)) payDocPromo.dateStart = moment(payDocPromo.dateStart);
        if (!(payDocPromo.dateEnd instanceof moment)) payDocPromo.dateEnd = moment(payDocPromo.dateEnd);
        payDocPromo.dateStart = payDocPromo.dateStart.format("DD.MM.YYYY");
        payDocPromo.dateEnd = payDocPromo.dateEnd.endOf('day').format("DD.MM.YYYY HH:mm:ss");
        const saveRequest = !payDocPromo.id ? createPayDocPromo(payDocPromo) : updatePayDocPromo(payDocPromo);
        saveRequest.then(response => {
            if (response.success) {
                document.location.pathname = "/pay-docs/promo";
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        });
    };

    handleRemove = () => {
        const { payDocPromo } = this.state;
        removePayDocPromo(payDocPromo.id).then(response => {
            if (response.success) {
                document.location.pathname = "/pay-docs/promo";
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        });
    };

    handleDisable = () => {
        let payDocPromo = this.state.payDocPromo;
        disablePayDocPromo(payDocPromo.id).then(response => {
            if (response.success) {
                document.location.pathname = "/pay-docs/promo";
            } else {
                this.props.showMessage(response.error ? response.error.message : response.message);
            }
        });
    };

    render() {
        const classes= this.props;
        const {
            payDocPromo,
            types,
            currentType,
            currentDiscount,
            currentRegions
        } = this.state;

        const saveDisabled = !payDocPromo.name || !payDocPromo.dateStart || !payDocPromo.dateEnd
            || !payDocPromo.payDocType || !payDocPromo.description
            || moment() - moment(payDocPromo.dateStart) >= 24 * 60 * 60 * 1000;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleSave} disabled={saveDisabled}>Сохранить</ActionButton>
                    <ActionButton onClick={this.handleDisable} disabled={saveDisabled}>Выключить</ActionButton>
                    <ActionButton onClick={this.handleRemove} disabled={!payDocPromo.id}>Удалить</ActionButton>
                </LeftBar>
                <Content title="Карточка акции платежного документа">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                id="name"
                                label="Название"
                                value={payDocPromo.name}
                                fullWidth
                                error={!payDocPromo.name}
                                name="name"
                                onChange={this.handleChange}
                                required
                                inputProps={{maxLength: 45}}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Тип ПД"
                                value={payDocPromo.payDocType}
                                name="payDocType"
                                onChange={this.handleChange}
                                error={!payDocPromo.payDocType}
                                fullWidth
                                items={types.filter(type => type.id !== payDocPromo.id)}
                                textField="name"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={8}>
                            <AnyField
                                label="Описание"
                                value={payDocPromo.description}
                                name="description"
                                onChange={this.handleChange}
                                error={!payDocPromo.description}
                                fullWidth
                                multiline={true}
                                rows={4}
                                inputProps={{maxLength: 54}}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <DateField
                                label="Дата начала"
                                value={payDocPromo.dateStart ? moment(payDocPromo.dateStart) : null}
                                onChange={this.handleChangeByName("dateStart")}
                                error={!payDocPromo.dateStart || moment() - moment(payDocPromo.dateStart) >= 24 * 60 * 60 * 1000}
                                name="dateStart"
                                fullWidth
                                disablePast
                                invalidDateMessage={''}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateField
                                label="Дата окончания"
                                value={payDocPromo.dateEnd ? moment(payDocPromo.dateEnd) : null}
                                onChange={this.handleChangeByName("dateEnd")}
                                error={!payDocPromo.dateEnd}
                                name="dateEnd"
                                fullWidth
                                disablePast
                                invalidDateMessage={''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={8}>
                            <AnyField
                                label="Комментарий, с которым будет создаваться платежный документ"
                                value={payDocPromo.comment}
                                name="comment"
                                onChange={this.handleChange}
                                fullWidth
                                multiline={true}
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}><Typography variant="subtitle1">Дополнительно</Typography></Grid>
                        <Grid item xs={12}><Typography variant="subtitle2">Чтобы изменить поля, необходимо изменить непосредственно тип ПД</Typography></Grid>
                        <Grid item xs={4}>
                            <FloatField
                                label="Минимальное значение часов"
                                value={currentType && currentType.refillLimits && currentType.refillLimits.min}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FloatField
                                label="Скидка при пополнении"
                                value={currentDiscount && currentDiscount.value}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}><Typography variant="subtitle2">Доступные регионы</Typography></Grid>
                        <Grid item xs={8}>
                            <Paper label="qwe">
                                <List dense component="div" role="list">
                                    {currentRegions.map(region => {
                                        return <ListItem dense key={region.id} itemID={region.id} role="listitem">
                                            <ListItemText primary={region.name}/>
                                        </ListItem>
                                    })}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(withStyles(styles)(PayDocPromoViewPage));
