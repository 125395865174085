import React from "react";
import {DropTarget} from "react-dnd";
import withStyles from "@material-ui/core/styles/withStyles";
import * as cn from "classnames";
import * as PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { activityTypes } from "../../services/calendarEmployee";

const VAR_HEADER = 'header';
const VAR_COLUMN = 'column';

export default
@DropTarget(
    'SCHEDULE_EVENT',
    {
        drop(props) {
            return props.onSlotDrop ? props.onSlotDrop(props.slot) : props;
        },

        hover(props, monitor) {
            if (monitor.canDrop() && props.onSlotDropHover) {
                const item = monitor.getItem();
                props.onSlotDropHover(props.slot, item.event, item.ids);
            }
        },

        canDrop(props, monitor) {
            const item = monitor.getItem();
            if (props.variant !== VAR_COLUMN) {
                return false;
            }

            if (item.disabledDragColumns && item.disabledDragColumns.indexOf(props.columnIdx) >= 0) {
                return false;
            }

            return true;
        }
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    })
)
@withStyles(theme => ({
    beginGroup: {
        borderTop: '1px solid #777',
    },
    columnSlot: {
        borderBottom: '1px solid rgba(92,92,92,0.51)',
        '&:last-of-type': {
            borderBottom: 'none',
        },
        '&:not($disabled):hover': {
            background: 'rgba(115,115,115,0.51) !important',
        },
        '&:not($disabled):focus': {
            background: 'rgba(115,115,115,0.51) !important',
        }
    },
    slotOver: {
        backgroundColor: 'rgba(0,165,3,0.51)',
    },
    disabled: {
        backgroundColor: 'rgba(0,0,0,0.51)',
        cursor: 'default',

        '@media print': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        }
    },
    disabledTitle: {
        fontSize: '70%',
        color: '#fff',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}))
class ScheduleTimeSlot extends React.PureComponent
{
    static propTypes = {
        disabled: PropTypes.bool,
        disabledTitle: PropTypes.string,
        beginGroup: PropTypes.bool,
        slotHeight: PropTypes.number,
        variant: PropTypes.oneOf([VAR_HEADER, VAR_COLUMN]),
        onSlotClick: PropTypes.func,
        onSlotDbClick: PropTypes.func,
        onSlotContextMenu: PropTypes.func,
        onSlotDrop: PropTypes.func,
        onSlotDropHover: PropTypes.func,
        columnIdx: PropTypes.number,
        bgColor: PropTypes.string,
        bgImage: PropTypes.string,
        activity: PropTypes.string,
    };

    static defaultProps = {
        variant: VAR_COLUMN,
        disabled: false,
        disabledTitle: null,
        yellow: false,
    };

    render() {
        const { classes, children, variant, slot, slotHeight, beginGroup, onSlotClick, onSlotDbClick, connectDropTarget,
            isOver, disabled, disabledTitle, onSlotContextMenu, bgColor, bgImage, activity } = this.props;
        const style = {
            height: slotHeight,
        };

        if (isOver) {
            style.backgroundColor = 'rgba(0,165,3,0.51)';
        } else if (!disabled && bgColor) {
            style.backgroundColor = bgColor;
        }

        if (bgImage) {
            style.backgroundImage = bgImage;
        }

        const activitiesForContextMenu = [ activityTypes.break ];

        return connectDropTarget(<div
            className={cn({[classes.beginGroup]: beginGroup, [classes.columnSlot]: variant === VAR_COLUMN, [classes.disabled]: disabled})}
            style={style}
            // onClick={event => !disabled && onSlotClick && onSlotClick(slot, event)}
            onFocus={event => !disabled && onSlotClick && onSlotClick(slot, event)}
            onDoubleClick={event => !disabled && onSlotDbClick && onSlotDbClick(slot, event)}
            onContextMenu={event => ((!disabled && !event.altKey) || activitiesForContextMenu.includes(activity)) &&
                onSlotContextMenu && onSlotContextMenu(slot, event)}
            tabIndex={10}
        >{children ? children : disabledTitle ? <Tooltip title={disabledTitle}><div className={classes.disabledTitle}>{disabledTitle}</div></Tooltip> : null}</div>)
    }
}
