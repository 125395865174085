import React, {useEffect, useState} from 'react';
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import SelectFromItemsField from "./SelectFromItemsField";

/**
 * @param t Function i18n.t
 * @param {int} duration Duration in minutes
 */
export function formatDuration(t, duration)
{
    const hours = Math.trunc(duration / 60);
    const minutes = duration - hours * 60;

    if (hours > 0) {
        if (minutes) {
            return `${hours} ${t('h')} ${minutes} ${t('min')}`;
        }
        return `${hours} ${t('h')}`;
    }

    return `${minutes} ${t('min')}`;
}

export function formatDurationHour(t, durationHour)
{
    return formatDuration(t, Math.round(durationHour * 60));
}

const FacilityDurationField = ({ step, min, max, availableDurations, ...otherProps }) => {
    const { t } = useTranslation();
    const [durations, setDurations] = useState([]);

    useEffect(() => {
        const durations = [];
        if (availableDurations && availableDurations.length) {
            availableDurations.forEach(id => {
                durations.push({
                    id,
                    name: formatDuration(t, id),
                })
            })
        } else {
            for (let id = min; id <= max; id += step) {
                durations.push({
                    id,
                    name: formatDuration(t, id),
                });
            }
        }
        setDurations(durations);
    }, [t, step, min, max, availableDurations])

    return <SelectFromItemsField
        items={durations}
        {...otherProps}
    />;
}

FacilityDurationField.propTypes = {
    /**
     * Доступные длительности, если передаётся массив, то будут доступны только указанные длительности для выбора
     */
    availableDurations: PropTypes.arrayOf(PropTypes.number),

    /**
     * Шаг в минутах, по умолчанию 5 минут
     */
    step: PropTypes.number,

    /**
     * Минимальная длительность, по умолчанию 0 мин
     */
    min: PropTypes.number,

    /**
     * Максимальная длительность, по умолчанию 600 мин
     */
    max: PropTypes.number,
}

FacilityDurationField.defaultProps = {
    step: 5,
    min: 0,
    max: 600,
}

export default FacilityDurationField