import React from "react";
import AnyField from "./AnyField";
import * as PropTypes from "prop-types";
import {InputAdornment} from "@material-ui/core";
import NumberFormatDecimal from "./NumberFormatDecimal";

export default
class PercentFieldDecimal extends React.Component
{
    static propTypes = {
        ...AnyField.propTypes,
        value: PropTypes.number,
    };

    handleChange = event => {
        if (this.props.onChange) {
            this.props.onChange({ target: {
                    name: this.props.name,
                    value: event.target.value > 100 ? this.props.value : event.target.value / 100
                }});
        }
    };

    render() {
        const { classes, InputProps, onChange, value, ...otherProps } = this.props;

        return (
            <AnyField
                value = {value === null || value === undefined ? null : value*100}
                InputProps={{
                    ...InputProps,
                    inputComponent: NumberFormatDecimal,
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                {...otherProps}
                onChange={this.handleChange}
            />
        );
    }
}
