import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import moment from "moment";
import Edit from '@material-ui/icons/Edit';
import AnyField from "./AnyField";
import ShowField from "./ShowField";

const styles = theme => ({
    dialogContent: {
        minWidth: 400,
        paddingTop:  theme.spacing(1),
    },
});

class ShowWithEditField extends React.Component {
    state = {
        editDialogOpen: false,
        editValue: null,
    };

    processValue = () => {
        let { value, dateFormat } = this.props;

        if (value && dateFormat) {
            value = moment(value).format(dateFormat);
        }

        return value === null || (typeof value !== 'number' && !value) ? <span>&nbsp;</span> : value;
    };

    handleClickEdit = () => {
        this.setState({
            editDialogOpen: true,
            editValue: this.props.value,
        })
    };

    handleCloseEditDialog =ok => () => {
        if (ok && this.props.onChange) {
            this.props.onChange({target: { value: this.state.editValue }});
        }

        this.setState({
            editDialogOpen: false,
        });
    };

    handleChange = event => {
        this.setState({
            editValue: event.target.value,
        })
    };

    render() {
        const { classes, fullWidth, className, label, linkTo, children, multiline, rows, value, label2Lines } = this.props;
        const { editDialogOpen, editValue } = this.state;

        return (
            <React.Fragment>
                <ShowField
                    className={className}
                    label={label}
                    fullWidth={fullWidth}
                    children={children}
                    linkTo={linkTo}
                    value={value}
                    label2Lines={label2Lines}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={this.handleClickEdit}><Edit /></IconButton>
                        </InputAdornment>
                    }
                />
                <Dialog
                    open={editDialogOpen}
                    onClose={this.handleCloseEditDialog(false)}
                >
                    <DialogTitle id="balance-dialog-title">Редактирование</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <AnyField
                            autoFocus
                            margin="dense"
                            multiline={multiline}
                            rows={rows}
                            label={label}
                            value={editValue}
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditDialog(true)} disabled={!value && value === editValue} color="primary">OK</Button>
                        <Button onClick={this.handleCloseEditDialog(false)} color="primary">Отмена</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };
}

ShowWithEditField.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.any,
    dateFormat: PropTypes.string,
    linkTo: PropTypes.string,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    onChange: PropTypes.func,
    label2Lines: PropTypes.bool,
};

ShowWithEditField.defaultProps = {
    fullWidth: true,
};

export default withStyles(styles)(ShowWithEditField);
