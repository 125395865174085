import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {getClubSubscriptionHistory, getGuest, navLinks} from "../../services/guests";
import GuestLeftFields from "./GuestLeftFields";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {getIdByUrl} from "../../utils/helpers";


const columnData = [
    {id: 'subscriptionType', label: 'Тип подписки'},
    {id: 'active', label: 'Активна'},
    {id: 'creator', label: 'Создатель'},
    {id: 'createdAt', label: 'Дата создания'},
    {id: 'finishAt', label: 'Закончится'},
    {id: 'amount', label: 'Сумма'},
    {id: 'canceler', label: 'Отменил'},
    {id: 'canceledAt', label: 'Дата отмены'},
    {id: 'cancelReason', label: 'Причина отписки'},
]


function ClubSubscriptionHistory(props) {

    const {t, location, history} = props

    const [state, setState] = useState({
        id: null,
        refreshTable: false,
        guest: {},
        clubSubscriptions: []
    })


    useEffect(() => {

        let urlId = getIdByUrl(location.pathname)

        if (urlId !== null) {
            setState(prev => ({...prev, id: urlId}));
            handleRefresh()
        }
        if (state.id !== null) {
            getGuest(state.id)
                .then(response => {
                    if (response.success) {
                        setState(prev => ({
                            ...prev,
                            guest: response.data
                        }))
                    }
                });
        }
    }, [location.pathname, state.id])


    const handleRefresh = () => {
        setState(prev => ({
            ...prev,
            refreshTable: !prev.refreshTable
        }))
    }

    // useEffect(()=>{
    //     let urlId = getIdByUrl(location.pathname)
    //
    //     if (urlId !== null) {
    //         setState(prev => ({...prev, id: urlId, refreshTable: !state.refreshTable}));
    //     }
    // },[location.pathname, state.refreshTable])


    const getTableData = (...param) => {
        return getClubSubscriptionHistory(state.id, ...param);
        // return getClubSubscriptionHistory(getIdByUrl(location.pathname), ...param);
    }

    const handleClickBack = () => {
        history.push(`/guests/view/${state.id}`)
    }


    const {guest, refreshTable} = state
    return (
        <React.Fragment>
            <LeftBar
                navigationLinks={<NavigationLinks links={navLinks} fakeLink={t("History of change of parameters")}/>}>
                <GuestLeftFields guest={guest}/>
                <br/>
                <br/>
                <ActionButton onClick={handleClickBack}>{t("Back")}</ActionButton>
            </LeftBar>
            <Content title={t("History of change of parameters")}>
                <DataTable
                    columnData={columnData}
                    dataFunc={getTableData}
                    refresh={refreshTable}
                    selector={false}
                    order="desc"
                    orderBy="createdAt"
                    loading={!!state.id}
                />
            </Content>
        </React.Fragment>
    )
}


export default withTranslation()(connect(null, {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
})(ClubSubscriptionHistory))