import React from 'react';
import * as PropTypes from 'prop-types';
import ActionButton from "../button/ActionButton";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

export default
@withStyles(theme => ({
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    deleteColumn: {
      width: 44,
    },
    column: {
        paddingLeft:  theme.spacing(1),
        paddingRight:  theme.spacing(1),

        '&:last-child': {
           paddingRight: 0,
        },
    }
}))
class FieldsList extends React.Component
{
    static propTypes = {
        items: PropTypes.array,
        headers: PropTypes.array,
        fields: PropTypes.arrayOf(PropTypes.func).isRequired,
        emptyItem: PropTypes.any,
        onChange: PropTypes.func,
        postChange: PropTypes.func,
        addButton: PropTypes.any,
        fullWidth: PropTypes.bool,
        className: PropTypes.string,
        name: PropTypes.string,
        limit: PropTypes.number,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        addButton: 'Добавить',
        fullWidth: false,
        headers: [],
        disabled: false,
        postChange: f => f
    };

    handleChange = itemIdx => prop => event => {
        const { items, postChange } = this.props;
        let value = null;
        if(moment.isMoment(event)) {
            value = event
        } else {
            value = event.target.value
        }

        items[itemIdx] = {
            ...items[itemIdx],
            [prop]: value,
        };

        items[itemIdx] = postChange(items[itemIdx], prop)

        this.triggerChange(items);
    };

    handleAdd = () => {
        const { items, emptyItem } = this.props;
        this.triggerChange([...items, emptyItem]);
    }

    handleRemove = itemIdx => () => {
        const { items } = this.props;
        let newItems = [...items];
        newItems.splice(itemIdx, 1);

        this.triggerChange(newItems);
    }

    handleRemoveAll = () => {
        this.triggerChange([]);
    }

    triggerChange = items => {
        const { onChange } = this.props;
        const target = {value: items};
        if(this.props.name) {
            target.name = this.props.name
        }
        if (onChange) {
            onChange({target});
        }
    }

    render() {
        const { classes, label, className, headers, fields, items, addButton, fullWidth, limit, disabled } = this.props;
        return <FormControl fullWidth={fullWidth} className={classNames(classes.root,{[classes.withLabel]: label}, className)}>
            { label ? <InputLabel shrink variant="outlined">{label}</InputLabel> : null }
            <Table>
                {headers.length ?
                    <TableHead>
                        <TableRow>
                            <TableCell padding="none" className={classes.deleteColumn}><Tooltip title="Удалить всё"><IconButton disabled={disabled} onClick={this.handleRemoveAll} aria-label="Удалить всё"><DeleteIcon fontSize="small"/></IconButton></Tooltip></TableCell>
                            {headers.map((header, idx) => <TableCell className={classes.column} key={idx}>{header}</TableCell>)}
                        </TableRow>
                    </TableHead>
                :null}
                <TableBody>
                    {items.map((item,itemIdx)=><TableRow key={itemIdx}>
                        <TableCell padding="none" className={classes.deleteColumn}><Tooltip title="Удалить"><IconButton disabled={disabled} onClick={this.handleRemove(itemIdx)} aria-label="Удалить"><DeleteIcon fontSize="small"/></IconButton></Tooltip></TableCell>
                        {fields.map((field, fieldIdx) => <TableCell className={classes.column} key={fieldIdx}>{field(item, this.handleChange(itemIdx))}</TableCell>)}
                    </TableRow>)}
                </TableBody>
            </Table>
            <ActionButton noMargin fullWidth={false} disabled={(limit && limit <= items.length) || disabled} onClick={this.handleAdd}>{addButton}</ActionButton>
        </FormControl>
    }
}
