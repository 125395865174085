import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getDigitalizedMutualPayDoc,
    navLinks
} from "../../services/moneyFlow";
import moment from "moment";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import XLSX from "xlsx";
import {getFilterFromQuery} from "../../history";
import DataTable from "../../components/data-table/DataTable";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import DateMonthField from "../../components/fields/DateMonthField";
import Typography from "@material-ui/core/Typography";
import {getBusinessUnit} from "../../services/organization";

export default
@connect(state => ({
}),{
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withTranslation()
class DigitalizedMutualPayDocReports extends React.Component {

    constructor(props) {
        super(props);

        const querySearch = getFilterFromQuery();

        let dateStart = moment().startOf('week');
        let dateEnd = moment();
        let dateType = 'period';
        let month = moment().add(-10, 'day').startOf('month');
        let owner = null;
        let implementer = null;

        if (querySearch) {
            if (querySearch.dateStart) {
                dateStart = moment(querySearch.dateStart);
            }

            if (querySearch.dateEnd) {
                dateEnd = moment(querySearch.dateEnd);
            }

            if (querySearch.owner) {
                owner = querySearch.owner;
            }

            if (querySearch.implementer) {
                implementer = querySearch.implementer;
            }

            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }

            if (querySearch.month) {
                month = moment(querySearch.month);
            }
        }

        this.state = {
            search: {
                dateStart: dateStart,
                dateEnd: dateEnd,
                month: month,
                dateType: dateType,
                owner: owner,
                implementer: implementer,
            },
            businessUnit: {}
        };

    }

    columnData = [
        { id: 'visitDate', label: 'Visit', dateFormat: 'DD.MM.YYYY' },
        { id: 'parlour', label: 'Business unit', },
        { id: 'phone', label: 'Phone' },
        { id: 'payDocNum', label: 'Payment documents', linkTemplate: row => `/pay-docs/view/${row.payDocId}` },
        { id: 'amount2', label: 'Сколько списано с ПД'},
        { id: 'amount', label: 'Сколько списано с ПД фактических средсв' },
    ];

    componentDidMount() {
        const {search} = this.state;
        getBusinessUnit(search.owner)
            .then(response => {
                if(response.success) {
                    this.setState({
                        businessUnit: response.data
                    })
                }
            })
    }

    getTableData = () => {
        const {search} = this.state;
        return getDigitalizedMutualPayDoc(search)
    };

    handleExport = () => {
        const { search } = this.state;
        const from = search.dateStart;
        const to = search.dateEnd;
        let XMLData = [];
        const headers = this.columnData.map(col => col.id);
        XMLData.push(headers)

        this.getTableData()
            .then(response => {
                if(response.success) {
                    response.data.forEach(row => {
                        XMLData.push(
                            this.columnData.map(item=> {
                                const value = _.get(row, item.id)
                                if (item.dateFormat) {
                                    return moment(value).toDate();
                                }
                                return value;
                            })
                        );
                    });

                    const worksheet = XLSX.utils.aoa_to_sheet(XMLData);
                    const new_workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(new_workbook, worksheet, "DigitalizedMutualPayDocReports");
                    XLSX.writeFile(new_workbook, `Digitalized Mutual Reports ${from} - ${to}.xlsx`);
                }
            })
    };


    render() {
        const { t } = this.props;
        const { search, businessUnit } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <Typography color={'primary'} >{businessUnit.name}</Typography>

                    {search.dateType === 'period' ?
                        <DatePeriodField2
                            valueFrom={search.dateStart}
                            valueTo={search.dateEnd}
                            maxDate={moment()}
                            onChangeFrom={() => 0}
                            onChangeTo={() => 0}
                            checkValuesOrder
                            disabled={true}
                        /> :
                        <DateMonthField
                            value={search.month}
                            onChange={() => 0}
                            fullWidth
                            disabled={true}
                        />
                    }
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t("Digitalized mutual reports PayDoc")}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        pagination={false}
                        selector={false}
                        linkTemplate={row => `/orders/view/${row.visitId}`}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
