import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    MenuItem,
    InputAdornment,
    TextField,
    IconButton, LinearProgress
} from "@material-ui/core";
import Autosuggest from 'react-autosuggest/dist/Autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';
import BackspaceRounded from "@material-ui/icons/BackspaceRounded";
import ForwardIcon from "@material-ui/icons/Forward";
import {emptyFieldColor, fillFieldColor} from "../../services/constants";
import { connect } from "react-redux";
import messageDialogActions from "../dialogs/messageDialog-acions";
import classNames from "classnames";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import {getPayDoc, getPayDocs} from "../../services/payDocs";

const styles = theme => ({
    root: {
        marginTop:  theme.spacing(1),
    },
    withLabel: {
        marginTop:  theme.spacing(3),
    },
    imgLoading: {
        height: 30,
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    dialogContent: {
        paddingTop:  theme.spacing(1),
    },
    endAdornment: {
        marginRight: - theme.spacing(1),
    },
    textFieldContainer: {
        position: 'relative',
    },
    progress: {
        position: 'absolute',
        bottom: 1,
        left: 1,
        right: 1,
    },
});

export default
@connect(null, {
    showMessage: messageDialogActions.show,
})
@withStyles(styles)
@withTranslation()
class PayDocField extends React.Component
{
    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
        readOnly: PropTypes.bool,
        required: PropTypes.bool,
        error: PropTypes.any,
        filterStatus: PropTypes.string,
        filterBusinessUnits: PropTypes.array,
        filterType: PropTypes.array,
        filterStatuses: PropTypes.array,
    };

    static defaultProps = {
        readOnly: false,
        required: false,
        filterStatuses: [],
    };

    state = {
        inputValue: '',
        suggestions: [],
        isLoading: false,
        payDoc: {
            id: null,
            readableName: null,
            num: null,
        },
        foundPayDoc: null,
    };

    componentDidMount() {
        this.loadPayDoc(this.props.value)
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.state.payDoc.id) {
            this.loadPayDoc(nextProps.value);
        }
    }

    loadPayDoc = (id) => {
        if (id) {
            getPayDoc(id)
                .then(response => {
                    if (response.success) {
                        this.setState({
                            payDoc: response.data,
                            inputValue: this.getSuggestionValue(response.data),
                        })
                    }
                });
        } else {
            this.setState({
                payDoc: {
                    id: null,
                    num: null,
                    readableName: null,
                },
                inputValue: '',
            });
        }
    };

    handleClear = () => {
        if (this.props.readOnly) {
            return;
        }

        this.setState({
            payDoc: {
                id: null,
                readableName: null,
            },
            inputValue: '',
        });

        if (this.props.onChange) {
            this.props.onChange( { target: { value: null } }, null );
        }
    };

    renderInputComponent = inputProps => {
        const { classes, readOnly, t } = this.props;
        const { payDoc } = this.state;
        const { inputRef = () => {}, ref, loading, ...other } = inputProps;

        return (
            <div className={classes.textFieldContainer}>
                <TextField
                    fullWidth
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                        endAdornment: payDoc.id ?
                            <React.Fragment>
                                <InputAdornment position="end" className={classes.endAdornment}>
                                    <Link to={`/pay-docs/view/${payDoc.id}`} style={{display: 'flex'}}><Tooltip title={t("Go to payDoc card")}><ForwardIcon fontSize="small"/></Tooltip></Link>
                                </InputAdornment>
                                <InputAdornment position="end" className={classes.endAdornment}>
                                    <IconButton
                                        aria-label={t("Clear")}
                                        onClick={this.handleClear}
                                    >
                                        <BackspaceRounded fontSize="small"  />
                                    </IconButton>
                                </InputAdornment>
                            </React.Fragment>
                            : null,
                        readOnly: !!payDoc.id  || readOnly,
                        style: {
                            background: payDoc.id ? fillFieldColor : emptyFieldColor,
                        }
                    }}
                    {...other}
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant="outlined"
                />
                {loading ?
                    <LinearProgress variant="query" className={classes.progress}/>
                    : null
                }
            </div>
        )
    };

    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const label = this.getSuggestionValue(suggestion);
        const matches = match(label, query);
        const parts = parse(label, matches);

        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map((part, index) => {
                        return part.highlight
                            ? <span key={String(index)} style={{fontWeight: 500}}>{part.text}</span>
                            : <strong key={String(index)} style={{fontWeight: 300}}>{part.text}</strong>;
                    })}
                </div>
            </MenuItem>
        );
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.debounceLoadSuggestions(value);
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    loadSuggestions = value => {
        const { filterStatus, filterBusinessUnits, filterType, filterStatuses } = this.props;

        this.setState({
            isLoading: true,
        });

        getPayDocs(
            value,
            null,
            null,
            filterBusinessUnits,
            filterStatus,
            filterStatuses,
            filterType,
            1,
            20,
            null,
            'num',
        ).then(response => {
            this.setState({
                isLoading: false,
            });
            if (response.success) {
                this.setState({
                    suggestions: [...response.data]
                })
            }
        })
    };

    debounceLoadSuggestions = _.debounce(this.loadSuggestions, 500);

    getSuggestionValue(suggestion) {
        return suggestion.readableName + (suggestion.businessUnit ? ` (${suggestion.businessUnit.name})` : ' ⚠️');
    }

    onSuggestionSelected = (event, { suggestion }) => {
        this.setState({
            payDoc: suggestion,
            inputValue: this.getSuggestionValue(suggestion)
        });

        if (this.props.onChange) {
            this.props.onChange( { target: { value: suggestion.id } }, suggestion );
        }
    };

    shouldRenderSuggestions = value => {
        const { payDoc } = this.state;
        return value.length >= 2 && !payDoc.id;
    };

    render() {
        const { classes, className, name, label, required, error} = this.props;
        const { suggestions, inputValue, isLoading } = this.state;

        return (
            <div className={classNames(classes.root,{[classes.withLabel]: label}, className)}>
                <Autosuggest
                    inputProps={{
                        name: name,
                        label: label,
                        loading: isLoading,
                        value: inputValue,
                        onChange: event => {
                            this.setState({ inputValue: event.target.value });
                            },
                        required: required,
                        error: error,
                    }}
                    renderInputComponent={this.renderInputComponent}
                    suggestions={suggestions}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    renderSuggestion={this.renderSuggestion}
                    getSuggestionValue={this.getSuggestionValue}
                    theme={{
                        suggestionsList: classes.suggestionsList,
                    }}
                    onSuggestionSelected={this.onSuggestionSelected}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                />
            </div>
        );
    }
}
