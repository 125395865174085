import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Fab,
    Grid
} from '@material-ui/core';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import { connect } from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import {withRouter} from "react-router";
import {
    getPayDocMeasures,
    getPayDocType,
    getPayDocVariants,
    savePayDocType,
    navLinks,
    getPayDocAskPinRules,
    getPayDocTypes,
} from "../../services/payDocs";
import CheckboxField from "../../components/fields/CheckboxField";
import {getDiscounts} from "../../services/calendar";
import Typography from "@material-ui/core/Typography";
import {getBusinessUnits, getRegions} from "../../services/organization";
import {getBusinessRoles, getUsers} from "../../services/user";
import {getSecuredEvents} from "../../services/common";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import Add from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import FloatField from "../../components/fields/FloatField";
import CountField from "../../components/fields/CountField";
import TransferListField from "../../components/fields/TransferListField";
import {getFacilities} from "../../services/facilities";
import DatePeriodField from "../../components/fields/DatePeriodField";
import TimeField from "../../components/fields/TimeField";
import moment from "moment";
import {withTranslation} from "react-i18next";
import DateField from "../../components/fields/DateField";

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    container: {
        width: 900,
        marginBottom: 10,
    },
    securityContainer: {
        marginBottom: 15,
    },
    del: {
        display: 'flex',
        alignItems: 'flex-end'
    },
});

const emptySecuredAction = {
    event: null,
    businessUnitIds: [],
    businessRolesIds: [],
    businessUnitRegionIds: [],
    usersIds: [],
};

@connect(state => ({
    currentDate: state.calendar.currentDate,
}))
@withRouter
@withTranslation()
class PayDocTypeViewPage extends React.Component {

    state = {
        payDocType: {
            id: null,
            code: null,
            name: null,
            hasSubtype: false,
            canSetNominal: false,
            canRechargeDefault: false,
            isPaper: false,
            electronic: false,
            onlyOneVisit: false,
            measure: null,
            variant: null,
            credit: false,
            fixedRefillDiscount: null,
            fixedSellDiscount: null,
            refillLimits: null,
            validityMonth: 6,
            validityDate: null,
            handler: null,
            securedActions: [],
            salaryCalcSellOrRefill: true,
            availableInBusinessUnits: [],
            maxPaymentCount: null,
            canBeAdditional: false,
            balanceCanBeMoved: false,
            canRefillOnlyOnce: true,
            active: true,
            availableFacilities: [],
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            daysOfWeek: [],
            receiptName: null,
            enableNotificationPayDocRefill: false,
            enableNotificationPayDocPayment: false,
            enableNotificationPayDocBurn: false,
            notificationPayDocRefillMessage: null,
            notificationPayDocPaymentMessage: null,
            notificationPayDocBurnMessage: null,
            showInMobile: false,
            canSetMain: false,
            mainTypes: [],
            canResale: false,
            countResale: null,
            visitingSalon: false,
            pin: [],
            balanceManufacture: false,
            paymentForGoods: false,
        },
        measures: [],
        variants: [],
        types: [],
        askPinRules: [],
        discounts: [],
        businessUnits: [],
        businessRoles: [],
        businessUnitRegions: [],
        securedEvents: [],
        users: [],
        facilities: [],
        weekDays: []
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getPayDocType(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            payDocType: response.data
                        })
                    }
                });
        }

        getPayDocMeasures()
            .then(response => {
                if (response.success) {
                    this.setState({
                        measures: response.data,
                    })
                }
            });

        getPayDocVariants()
            .then(response => {
                if (response.success) {
                    this.setState({
                        variants: response.data,
                    })
                }
            });

        getPayDocAskPinRules()
            .then(response => {
                if (response.success) {
                    this.setState({
                        askPinRules: response.data,
                    })
                }
            });

        getPayDocTypes()
            .then(response => {
                if (response.success) {
                    this.setState({
                        types: response.data,
                    })
                }
            });

        getDiscounts()
            .then(response => {
                if (response.success) {
                    this.setState({
                        discounts: response.data,
                    })
                }
            });

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getRegions()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnitRegions: response.data,
                    })
                }
            });

        getBusinessRoles()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessRoles: response.data,
                    })
                }
            });

        getSecuredEvents()
            .then(response => {
                if (response.success) {
                    this.setState({
                        securedEvents: response.data,
                    })
                }
            });

        getUsers()
            .then(response => {
                if (response.success) {
                    this.setState({
                        users: response.data,
                    })
                }
            });

        getFacilities(null, 1, -1, 'asc', 'code')
            .then(response => {
                if (response.success) {
                    this.setState({
                        facilities: response.data.map(facility => ({...facility, name: facility.code + ' - ' + facility.name})),
                    })
                }
            });

        this.setState({
            refreshTableByDay: !this.state.refreshTable,
            refreshTableByFilter: !this.state.refreshTable
        }, this.getWeekDays)
    }

    handleChange = event => {
        const { payDocType } = this.state;
        this.setState({payDocType: {...payDocType, [event.target.name]: event.target.value}});
    };


    handleChangeVisitingSalon = event => {
        const { payDocType } = this.state;
        this.setState({
                payDocType: {
                    ...payDocType,
                    visitingSalon : event.target.value,
                }
            }
        );
    };
    handleChangeBalanceManufacture = event => {
        const { payDocType } = this.state;
        this.setState({
                payDocType: {
                    ...payDocType,
                    balanceManufacture : event.target.value,
                }
            }
        );
    };

    handleChangePaymentForGoods = event => {
        const { payDocType } = this.state;
        this.setState({
                payDocType: {
                    ...payDocType,
                    paymentForGoods : event.target.value,
                }
            }
        );
    };
    handleChangeCanResale = event => {
        const { payDocType } = this.state;
        this.setState({
                payDocType: {
                    ...payDocType,
                    canResale : event.target.value,
                    countResale: event.target.value ? 1 : 0,
                }
            }
        );
    };

    handleChangeCountResale = event => {
        const { payDocType } = this.state;
        var countResale = null;
        if(payDocType.canResale){
            countResale = event.target.value === '0' ? 1  :  event.target.value ;
        } else {
            countResale = 0;
        }
        this.setState({
                payDocType: {
                    ...payDocType,
                    countResale: countResale,
                }
            }
        );
    }

    handleChangeByName = prop => event => {

        let value = null;

        if (event instanceof moment) {
            value = event;
        } else {
            if (event != null) {
                value = event.target.value;
            }
        }

        this.setState({
            payDocType: {
                ...this.state.payDocType,
                [prop]: value,
            }
        });
    };

    handleSave = () => {
        const { payDocType } = this.state;
        if (payDocType.fixedRefillDiscount === 0) {
            payDocType.fixedRefillDiscount = null;
        }
        if (payDocType.fixedSellDiscount === 0) {
            payDocType.fixedSellDiscount = null;
        }

        savePayDocType(payDocType.id || 0, {
            ...payDocType,
            startTime: moment(payDocType.startTime).utcOffset(0, true),
            endTime: moment(payDocType.endTime).utcOffset(0, true),
            startDate: moment(payDocType.startDate).utcOffset(0, true),
            endDate: moment(payDocType.endDate).utcOffset(0, true),
        })
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/pay-docs/types`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    };

    handleHistory = () => {
        this.props.history.push(`/pay-docs/history/pay-doc-type/${this.state.payDocType.id}`);
    };

    handleChangeLimit = event => {
        const { payDocType } = this.state;
        const refillLimits = payDocType.refillLimits || {};

        this.setState({
            payDocType: {
                ...payDocType,
                refillLimits: {
                    ...refillLimits,
                    [event.target.name]: event.target.value,
                },
            }
        });
    };

    addSecurityAction = () => {
        let securedActions = [...this.state.payDocType.securedActions];
        securedActions.push({...emptySecuredAction});
        this.setState({
            payDocType: {
                ...this.state.payDocType,
                securedActions: securedActions
            }
        });
    };

    deleteSecurityAction = event => {
        const index = parseInt(event.currentTarget.getAttribute('itemid'));
        const securedActions = [...this.state.payDocType.securedActions];
        securedActions.splice(index, 1);
        this.setState({
            payDocType: {
                ...this.state.payDocType,
                securedActions,
            }
        });
    };

    handleSecurityActionChange = event => {
        const names = event.target.name.split('.');
        const index = parseInt(names[0]);
        const prop = names[1];
        const securedActions = [ ...this.state.payDocType.securedActions ];
        securedActions[index][prop] = event.target.value;
        this.setState({
            payDocType: {
                ...this.state.payDocType,
                securedActions: securedActions
            }
        });
    };

    getWeekDays = () => {
        let currentDate = this.props.currentDate;
        let weekDay = currentDate.startOf('week');
        let weekDays = [{id: weekDay.isoWeekday(), name: weekDay.format('dd')}];

        for (let i = 0; i < 6; i++) {
            weekDay.add(1, 'day');
            weekDays.push({id: weekDay.isoWeekday(), name: weekDay.format('dd')});
        }

        this.setState({
            weekDays: weekDays,
        })
    }

    render() {
        const {t, classes} = this.props;
        const {
            payDocType,
            measures,
            variants,
            discounts,
            businessUnits,
            businessRoles,
            businessUnitRegions,
            securedEvents,
            users,
            facilities,
            weekDays,
            askPinRules,
            types,
        } = this.state;

        const saveDisabled = !payDocType.name || !payDocType.code || !payDocType.measure || !payDocType.variant;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ActionButton onClick={this.handleSave} disabled={saveDisabled}>Сохранить</ActionButton>
                    <ActionButton onClick={this.handleHistory} disabled={!payDocType.id}>История изменений</ActionButton>
                </LeftBar>
                <Content title="Карточка типа платежного документа">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <AnyField
                                label="Код"
                                value={payDocType.code}
                                fullWidth
                                error={!payDocType.code}
                                name="code"
                                onChange={this.handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                id="name"
                                label="Название"
                                value={payDocType.name}
                                fullWidth
                                error={!payDocType.name}
                                name="name"
                                onChange={this.handleChange}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Мера"
                                value={payDocType.measure}
                                name="measure"
                                onChange={this.handleChange}
                                error={!payDocType.measure}
                                fullWidth
                                items={measures}
                                textField="text"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Тип"
                                value={payDocType.variant}
                                name="variant"
                                onChange={this.handleChange}
                                error={!payDocType.variant}
                                fullWidth
                                items={variants}
                                textField="text"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CountField
                                label="Срок действия, мес"
                                value={payDocType.validityMonth}
                                name="validityMonth"
                                onChange={this.handleChange}
                                error={!payDocType.validityMonth}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateField
                                label="Срок действия, до даты (включительно)"
                                value={moment(payDocType.validityDate).utcOffset(0, false)}
                                onChange={this.handleChangeByName("validityDate")}
                                name="validityDate"
                                fullWidth
                                disablePast
                                invalidDateMessage={''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.active}
                                name="active"
                                onChange={this.handleChange}
                                label="Активен"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.canSetNominal}
                                name="canSetNominal"
                                onChange={this.handleChange}
                                label="Можно установить номинал"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.electronic}
                                name="electronic"
                                onChange={this.handleChange}
                                label="Электронный"
                                margin="none"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.isPaper}
                                name="isPaper"
                                onChange={this.handleChange}
                                label="Бумажный"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.canRechargeDefault}
                                name="canRechargeDefault"
                                onChange={this.handleChange}
                                label="Можно пополнять"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.onlyOneVisit}
                                name="onlyOneVisit"
                                onChange={this.handleChange}
                                label="Только на один визит"
                                margin="none"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.credit}
                                name="credit"
                                onChange={this.handleChange}
                                label="Кредитный"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.salaryCalcSellOrRefill}
                                name="salaryCalcSellOrRefill"
                                onChange={this.handleChange}
                                label="Учитывать в ЗП"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.canBeAdditional}
                                name="canBeAdditional"
                                onChange={this.handleChange}
                                label="Могут использовать другие гости"
                                margin="none"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.balanceCanBeMoved}
                                name="balanceCanBeMoved"
                                onChange={this.handleChange}
                                label="Можно переносить баланс"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.canRefillOnlyOnce}
                                name="canRefillOnlyOnce"
                                onChange={this.handleChange}
                                label="Может быть пополнен только 1 раз"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.hasSubtype}
                                name="hasSubtype"
                                onChange={this.handleChange}
                                label="Имеет подтипы"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.showInMobile}
                                name="showInMobile"
                                onChange={this.handleChange}
                                label="Доступен для использовании в приложении и виджете"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.paymentForGoods}
                                name="paymentForGoods"
                                onChange={this.handleChangePaymentForGoods}
                                label="Разрешена оплата товаров"
                                margin="none"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.canSetMain}
                                name="canSetMain"
                                onChange={this.handleChange}
                                label="Требуется задать основной ПД"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {!!payDocType.canSetMain ?
                                <MultiSelectFromItemsField
                                    items={types.filter(type => type.id !== payDocType.id)}
                                    value={payDocType.mainTypes ?? []}
                                    name="mainTypes"
                                    onChange={this.handleChangeByName("mainTypes")}
                                    label="Разрешенные типы ПД"
                                    emptySelectedLabel="Все"
                                    fullWidth
                                /> : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.canResale}
                                name="canResale"
                                onChange={this.handleChangeCanResale}
                                label="Можно перепродавать"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <CountField
                                value={payDocType.countResale}
                                name="countResale"
                                onChange={this.handleChangeCountResale}
                                label="Колличество возможных перепродаж"
                                fullwidth
                                multiline={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.visitingSalon}
                                name="visitingSalon"
                                onChange={this.handleChangeVisitingSalon}
                                label="Возможно указание салона посещения при продаже"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.balanceManufacture}
                                name="balanceManufacture"
                                onChange={this.handleChangeBalanceManufacture}
                                label="Доступно выставление баланса при изготовлении"
                                margin="none"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <MultiSelectFromItemsField
                                items={askPinRules}
                                value={payDocType.pin}
                                name="pin"
                                onChange={this.handleChangeByName("pin")}
                                label="Запрашивать ПИН код"
                                valueField="id"
                                textField="text"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Скидка при пополнении"
                                value={payDocType.fixedRefillDiscount}
                                name="fixedRefillDiscount"
                                onChange={this.handleChange}
                                items={discounts}
                                fullWidth
                                nullable
                                nullableText="--- Нет ограничения ---"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                label="Скидка при продаже"
                                value={payDocType.fixedSellDiscount}
                                name="fixedSellDiscount"
                                onChange={this.handleChange}
                                items={discounts}
                                fullWidth
                                nullable
                                nullableText="--- Нет ограничения ---"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Кастомный обработчик"
                                value={payDocType.handler}
                                name="handler"
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CountField
                                label="Максимально оплат в визите"
                                value={payDocType.maxPaymentCount}
                                name="maxPaymentCount"
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                label="Наименование в чеке"
                                value={payDocType.receiptName}
                                name="receiptName"
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}><Typography variant="subtitle1">Ограничения при
                            пополнении</Typography></Grid>
                        <Grid item xs={4}>
                            <FloatField
                                label="Минимальное значение"
                                value={payDocType.refillLimits && payDocType.refillLimits.min}
                                name="min"
                                onChange={this.handleChangeLimit}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FloatField
                                label="Максимальное значение"
                                value={payDocType.refillLimits && payDocType.refillLimits.max}
                                name="max"
                                onChange={this.handleChangeLimit}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FloatField
                                label="Кратно значению"
                                value={payDocType.refillLimits && payDocType.refillLimits.multiple}
                                name="multiple"
                                onChange={this.handleChangeLimit}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}><Typography variant="subtitle1">Сообщения при событиях в
                            ПД</Typography></Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.enableNotificationPayDocRefill}
                                name="enableNotificationPayDocRefill"
                                onChange={this.handleChange}
                                label="Включено сообщение при пополнении ПД"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <AnyField
                                label="Текст сообщения при пополнении ПД"
                                value={payDocType.notificationPayDocRefillMessage}
                                name="notificationPayDocRefillMessage"
                                onChange={this.handleChange}
                                fullWidth
                                multiline={true}
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.enableNotificationPayDocPayment}
                                name="enableNotificationPayDocPayment"
                                onChange={this.handleChange}
                                label="Включено сообщение при списании с ПД"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <AnyField
                                label="Текст сообщения при списании с ПД"
                                value={payDocType.notificationPayDocPaymentMessage}
                                name="notificationPayDocPaymentMessage"
                                onChange={this.handleChange}
                                fullWidth
                                multiline={true}
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                value={!!payDocType.enableNotificationPayDocBurn}
                                name="enableNotificationPayDocBurn"
                                onChange={this.handleChange}
                                label="Включено сообщение при сгорании ПД"
                                margin="none"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <AnyField
                                label="Текст сообщения при сгорании ПД"
                                value={payDocType.notificationPayDocBurnMessage}
                                name="notificationPayDocBurnMessage"
                                onChange={this.handleChange}
                                fullWidth
                                multiline={true}
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}><Typography variant="subtitle1">Безопасность</Typography></Grid>
                    </Grid>
                    {payDocType.securedActions.map((securedAction, index) => (
                        <Grid key={index} container spacing={2} className={classes.securityContainer}>
                            <Grid item xs={2}>
                                <SelectFromItemsField
                                    items={securedEvents}
                                    value={securedAction.event}
                                    name={`${index}.event`}
                                    onChange={this.handleSecurityActionChange}
                                    required
                                    error={!securedAction.event}
                                    label="Событие"
                                    textField="text"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <MultiSelectFromItemsField
                                    items={businessUnitRegions}
                                    value={securedAction.businessUnitRegionIds}
                                    name={`${index}.businessUnitRegionIds`}
                                    onChange={this.handleSecurityActionChange}
                                    label="Разрешить для регионов"
                                    fullWidth
                                    emptySelectedLabel="Все"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <MultiSelectFromItemsField
                                    items={businessUnits}
                                    value={securedAction.businessUnitIds}
                                    name={`${index}.businessUnitIds`}
                                    onChange={this.handleSecurityActionChange}
                                    label="Разрешить для салонов"
                                    fullWidth
                                    emptySelectedLabel="Все"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <MultiSelectFromItemsField
                                    items={businessRoles}
                                    value={securedAction.businessRolesIds}
                                    name={`${index}.businessRolesIds`}
                                    onChange={this.handleSecurityActionChange}
                                    label="Разрешить для ролей"
                                    fullWidth
                                    emptySelectedLabel="Все"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <MultiSelectFromItemsField
                                    items={users}
                                    value={securedAction.usersIds}
                                    name={`${index}.usersIds`}
                                    onChange={this.handleSecurityActionChange}
                                    label="Разрешить для пользователей"
                                    fullWidth
                                    emptySelectedLabel="Все"
                                    textField="username"
                                />
                            </Grid>
                            <Grid item xs={2} className={classes.del}>
                                <Fab size="small" color="secondary" itemID={index} onClick={this.deleteSecurityAction}>
                                    <ClearIcon/>
                                </Fab>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <ActionButton
                                fullWidth={false}
                                onClick={this.addSecurityAction}
                            >
                                <Add/> Добавить событие
                            </ActionButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}><Typography variant="subtitle1">ПД доступны для использования только в
                            салонах:</Typography></Grid>
                        <Grid item xs={12}>
                            <TransferListField
                                items={businessUnits}
                                value={payDocType.availableInBusinessUnits}
                                name="availableInBusinessUnits"
                                onChange={this.handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{t("Available facilities")}</Typography>
                            <TransferListField
                                items={facilities}
                                name="availableFacilities"
                                value={payDocType.availableFacilities}
                                onChange={this.handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{t("Time of action")}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <DatePeriodField
                                valueFrom={payDocType.startDate}
                                valueTo={payDocType.endDate}
                                onChangeFrom={this.handleChangeByName("startDate")}
                                onChangeTo={this.handleChangeByName("endDate")}
                                label={t('Date')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MultiSelectFromItemsField
                                items={weekDays}
                                value={payDocType.daysOfWeek}
                                name="daysOfWeek"
                                onChange={this.handleChangeByName("daysOfWeek")}
                                label={t("Days of week")}
                                valueField="id"
                                textField="name"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimeField
                                label={t("Start time")}
                                value={payDocType.startTime}
                                onChange={this.handleChangeByName("startTime")}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimeField
                                label={t("End time")}
                                value={payDocType.endTime}
                                onChange={this.handleChangeByName("endTime")}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Content>
            </React.Fragment>
        );
    }
}

export default connect(null, {showMessage: messageDialogActions.show})(withStyles(styles)(PayDocTypeViewPage));
