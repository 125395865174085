import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DataTable from "../../components/data-table/DataTable";
import {getDepartments, navLinks} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";

const columnData = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Название' },
    { id: 'chief.name', numeric: false, disablePadding: false, label: 'Начальник' },
    { id: 'parentName', numeric: false, disablePadding: false, label: 'Под контролем' },
    { id: 'businessUnit.name', numeric: false, disablePadding: false, label: 'Бизнес юнит' },
];

const styles = theme => ({
    table: {
        width: 600,
    },
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 300,
    },
    menu: {
        width: 300,
    },
    addButtonIcon: {
        marginRight:  theme.spacing(1),
        fontSize: 20,
    },
    delButtonIcon: {
        fontSize: 20,
    },
});

class DepartmentListPage extends React.Component {

    state = {
        search: {
            search: null
        },
        refreshTable: false
    };

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/organization/departments/view/0`);
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getDepartments(search, ...params);
    };

    render() {
        // const { classes } = this.props;
        const { search, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Подразделения">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/organization/departments/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DepartmentListPage);
