import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import ActionButton from "../../components/button/ActionButton";
import messageDialogActions from '../../components/dialogs/messageDialog-acions';
import {connect} from "react-redux";
import {
    getMutualPayDocReports,
    navLinks
} from "../../services/moneyFlow";
import moment from "moment";
import {Divider, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import infoActions from "../../components/info/info-actions";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import XLSX from "xlsx";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import DateMonthField from "../../components/fields/DateMonthField";
import {getFilterFromQuery, pushFilterToQuery} from "../../history";
import CheckboxField from "../../components/fields/CheckboxField";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import {getBusinessUnitByRole} from "../../services/user";
import DatePeriodField2 from "../../components/fields/DatePeriodField2";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import * as queryString from "query-string-object";
import {withStyles} from "@material-ui/styles";

export default @connect(() => ({}), {
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
})
@withTranslation()
@withStyles({
    divider: {marginTop: 10}
})
class MutualPayDocReports extends React.Component {

    constructor(props) {
        super(props);

        let businessUnits = [];

        if (props.currentParlour) {
            businessUnits.push(props.currentParlour.id);
        }

        const querySearch = getFilterFromQuery();

        let dateStart = moment().startOf('week');
        let dateEnd = moment();
        let dateType = 'period';
        let month = moment().add(-10, 'day').startOf('month');
        let showServiceTable = false;
        let showMutualReportsTable = false;
        let showPaymentTable = true;

        if (querySearch) {
            if (querySearch.dateStart) {
                dateStart = moment(querySearch.dateStart);
            }

            if (querySearch.dateEnd) {
                dateEnd = moment(querySearch.dateEnd);
            }

            if (querySearch.businessUnits) {
                businessUnits = querySearch.businessUnits;
            }

            if (querySearch.dateType) {
                dateType = querySearch.dateType;
            }

            if (querySearch.month) {
                month = moment(querySearch.month);
            }

            if (querySearch.showServiceTable) {
                showServiceTable = Boolean(querySearch.showServiceTable);
            }

            if (querySearch.showMutualReportsTable) {
                showMutualReportsTable = Boolean(querySearch.showMutualReportsTable);
            }

            if (querySearch.showPaymentTable) {
                showPaymentTable = Boolean(querySearch.showPaymentTable);
            }
        }

        this.state = {
            search: {
                dateStart: dateStart,
                dateEnd: dateEnd,
                month: month,
                dateType: dateType,
                businessUnits: businessUnits,
                showServiceTable: showServiceTable,
                showMutualReportsTable: showMutualReportsTable,
                showPaymentTable: showPaymentTable,
                includeGoodStorageSalesWithoutVisit: false
            },
            businessUnits: [],
            serviceTableColumns: [],
            paymentTableColumns: [],
            mutualReportsTableColumns: [],
            serviceTableData: [],
            paymentTableData: [],
            mutualReportsTableData: [],
            totalData: [],
        };

    }

    totalColumnData = [
        {id: 'parlour', label: 'Parlour'},
        {id: 'credit', label: 'Credit',},
        {id: 'debit', label: 'Debit'},
        {id: 'diff', label: 'Diff'},
    ]

    componentDidMount() {
        getBusinessUnitByRole('ROLE_MONEYFLOW_DEAL_GET')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    });
                    this.getTableData();
                }
            });

    }

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        },
            () => {
                if (prop === 'includeGoodStorageSalesWithoutVisit') {
                    this.handleClickFilter()
                }
            }
        );
    };

    handleClickFilter = () => {
        pushFilterToQuery(processMomentFieldInObject(this.state.search, ['dateStart', 'dateEnd', 'month']));
        this.getTableData()
    };

    getTableData = () => {
        const {search, businessUnits} = this.state;
        return getMutualPayDocReports(search)
            .then(response => {
                if (response.success) {
                    const columns = _.uniqBy([
                        {id: 'owner', label: 'Parlour', width: 200},
                        ...response.data.map(t => ({id: t.ownerId, label: t.owner, width: 100, wordBreak: true})),
                        ...response.data.map(t => ({
                            id: t.implementerId,
                            label: t.implementer,
                            width: 100,
                            wordBreak: true
                        })),
                    ], 'id');

                    const rawData = {};

                    response.data.forEach(t => {
                        if (!rawData[t.ownerId]) {
                            rawData[t.ownerId] = {};
                        }
                        rawData[t.ownerId][t.implementerId] = t.amount;
                    })

                    const serviceTableData = []
                    const paymentTableData = []
                    const mutualReportsTableData = []
                    const serviceTableColumns = []
                    const paymentTableColumns = []
                    const mutualReportsTableColumns = []
                    let totalData = []

                    _.forEach(columns, owner => {
                        if (owner.id === 'owner') {
                            return;
                        }

                        const serviceTableRow = {}
                        const paymentTableRow = {}
                        const mutualReportsTableRow = {}

                        _.forEach(columns, implementer => {
                            if (implementer.id === 'owner') {
                                serviceTableRow[implementer.id] =
                                    paymentTableRow[implementer.id] =
                                        mutualReportsTableRow[implementer.id] =
                                            owner.label;
                                return;
                            }

                            if (!serviceTableRow[implementer.id]) {
                                serviceTableRow[implementer.id] = 0;
                            }

                            serviceTableRow[implementer.id] = !(owner.id in rawData && implementer.id in rawData[owner.id]) ? 0 : rawData[owner.id][implementer.id]

                            if (
                                (implementer.id in rawData) &&
                                (owner.id in rawData[implementer.id]) &&
                                (owner.id in rawData) &&
                                (implementer.id in rawData[owner.id])
                            ) {
                                let diff = rawData[owner.id][implementer.id] - rawData[implementer.id][owner.id];
                                mutualReportsTableRow[implementer.id] = diff;
                                paymentTableRow[implementer.id] = diff > 0 ? diff : 0;

                                if (search.businessUnits.includes(owner.id)) {
                                    const totalRow = totalData.find(t => t.parlourId === owner.id)
                                    if (totalRow) {
                                        totalRow.debit += diff > 0 ? diff : 0
                                    } else {
                                        totalData.push({
                                            parlourId: owner.id,
                                            parlour: businessUnits.find(t => t.id === owner.id).name,
                                            credit: 0,
                                            debit: diff > 0 ? diff : 0,
                                            diff: 0
                                        })
                                    }
                                }
                            } else if (
                                (implementer.id in rawData) &&
                                (owner.id in rawData[implementer.id])
                            ) {
                                mutualReportsTableRow[implementer.id] = rawData[implementer.id][owner.id] * -1;
                                paymentTableRow[implementer.id] = 0;
                            } else if (
                                (owner.id in rawData) &&
                                (implementer.id in rawData[owner.id])
                            ) {
                                mutualReportsTableRow[implementer.id] = rawData[owner.id][implementer.id];
                                paymentTableRow[implementer.id] = rawData[owner.id][implementer.id];

                                if (search.businessUnits.includes(owner.id)) {
                                    const totalRow = totalData.find(t => t.parlourId === owner.id)
                                    if (totalRow) {
                                        totalRow.credit += rawData[owner.id][implementer.id]
                                    } else {
                                        totalData.push({
                                            parlourId: owner.id,
                                            parlour: businessUnits.find(t => t.id === owner.id).name,
                                            credit: rawData[owner.id][implementer.id],
                                            debit: 0,
                                            diff: 0
                                        })
                                    }
                                }
                            } else {
                                mutualReportsTableRow[implementer.id] = 0;
                                paymentTableRow[implementer.id] = 0;
                            }
                        })

                        if (
                            _.filter(serviceTableRow, t => t !== 0)
                                .length > 1) {
                            serviceTableData.push(serviceTableRow)
                        }

                        if (
                            _.filter(paymentTableRow, t => t !== 0)
                                .length > 1) {
                            paymentTableData.push(paymentTableRow)
                        }

                        if (
                            _.filter(mutualReportsTableRow, t => t !== 0)
                                .length > 1) {
                            mutualReportsTableData.push(mutualReportsTableRow)
                        }
                    });

                    _.forEach(columns, col => {
                        if (col.id === 'owner') {
                            serviceTableColumns.push(col);
                            paymentTableColumns.push(col);
                            mutualReportsTableColumns.push(col);
                            return;
                        }
                        if (serviceTableData.some(row => row[col.id])) {
                            serviceTableColumns.push(col);
                        }
                        if (paymentTableData.some(row => row[col.id])) {
                            paymentTableColumns.push(col);
                        }
                        if (mutualReportsTableData.some(row => row[col.id])) {
                            mutualReportsTableColumns.push(col);
                        }
                    });

                    totalData = totalData.map(t => {
                        return {
                            ...t,
                            diff:  t.debit - t.credit
                        }
                    })

                    this.setState({
                        serviceTableData,
                        paymentTableData,
                        mutualReportsTableData,
                        totalData,
                        serviceTableColumns,
                        paymentTableColumns,
                        mutualReportsTableColumns,
                    });
                }
            });
    };

    linkTemplateFunc = col => (row) => {
        const value = _.get(row, col.id);
        const {search, businessUnits} = this.state;
        const owner = _.findLast(businessUnits, t => t.name === row.owner);
        if (value > 0 && owner) {
            const processedSearch = processMomentFieldInObject(search, ['dateStart', 'dateEnd', 'month'])
            const q = queryString.stringify({
                dateStart: processedSearch.dateStart,
                dateEnd: processedSearch.dateEnd,
                month: processedSearch.month,
                dateType: search.dateType,
                owner: owner.id,
                implementer: col.id,
            })
            return `/money-flow/digitalized-mutual-pay-doc-reports?${q}`;
        }
    }

    handleExport = () => {
        const {search, serviceTableData, mutualReportsTableData, paymentTableData, serviceTableColumns, paymentTableColumns, mutualReportsTableColumns} = this.state;
        const from = search.dateStart;
        const to = search.dateEnd;

        let XMLData = [];

        if (search.showServiceTable) {
            const serviceTableHeaders = serviceTableColumns.map(col => col.label);
            XMLData.push(serviceTableHeaders)
            serviceTableData.forEach(row => {
                XMLData.push(
                    serviceTableColumns.map(item => {
                        return _.get(row, item.id);
                    })
                );
            });
        }

        if (search.showMutualReportsTable) {
            const mutualReportsTableHeaders = mutualReportsTableColumns.map(col => col.label);
            XMLData.push([])
            XMLData.push(mutualReportsTableHeaders)
            mutualReportsTableData.forEach(row => {
                XMLData.push(
                    mutualReportsTableColumns.map(item => {
                        return _.get(row, item.id);
                    })
                );
            });
        }

        if (search.showPaymentTable) {
            const paymentTableHeaders = paymentTableColumns.map(col => col.label);
            XMLData.push([])
            XMLData.push(paymentTableHeaders)
            paymentTableData.forEach(row => {
                XMLData.push(
                    paymentTableColumns.map(item => {
                        return _.get(row, item.id);
                    })
                );
            });
        }

        const worksheet = XLSX.utils.aoa_to_sheet(XMLData);
        const new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "Spending");
        XLSX.writeFile(new_workbook, `Mutual PayDoc Reports ${from} - ${to}.xlsx`);
    };


    render() {
        const {t, classes} = this.props;
        const {search, businessUnits, serviceTableColumns, paymentTableColumns, mutualReportsTableColumns, serviceTableData, paymentTableData, mutualReportsTableData, totalData} = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            value={search.dateType}
                            onChange={this.handleChange('dateType')}
                            row
                        >
                            <FormControlLabel control={<Radio/>} value="period" label="за период"/>
                            <FormControlLabel control={<Radio/>} value="month" label="за месяц"/>
                        </RadioGroup>
                    </FormControl>

                    {search.dateType === 'period' ?
                        <DatePeriodField2
                            valueFrom={search.dateStart}
                            valueTo={search.dateEnd}
                            maxDate={moment()}
                            onChangeFrom={this.handleChange('dateStart')}
                            onChangeTo={this.handleChange('dateEnd')}
                            checkValuesOrder
                        /> :
                        <DateMonthField
                            value={search.month}
                            onChange={this.handleChange('month')}
                            fullWidth
                        />
                    }

                    <MultiSelectFromItemsField
                        items={businessUnits}
                        emptySelectedLabel="---Не выбрано---"
                        label={t('Business unit')}
                        value={search.businessUnits}
                        onChange={this.handleChange('businessUnits')}
                        fullWidth
                    />

                    <CheckboxField
                        label={t('Payment Table')}
                        value={search.showPaymentTable}
                        onChange={this.handleChange('showPaymentTable')}
                    />

                    <CheckboxField
                        label={t('Service Table')}
                        value={search.showServiceTable}
                        onChange={this.handleChange('showServiceTable')}
                    />

                    <CheckboxField
                        label={t('Mutual Reports Table')}
                        value={search.showMutualReportsTable}
                        onChange={this.handleChange('showMutualReportsTable')}
                    />

                    <Divider className={classes.divider}/>

                    <CheckboxField
                        label={t('Sales of goods outside the visit')}
                        value={search.includeGoodStorageSalesWithoutVisit}
                        onChange={this.handleChange('includeGoodStorageSalesWithoutVisit')}
                    />

                    <ActionButton onClick={this.handleClickFilter}>{t("Filter")}</ActionButton>
                    <ActionButton onClick={this.handleExport}>Excel</ActionButton>
                </LeftBar>
                <Content title={t("Mutual reports")}>

                    {search.businessUnits.length > 0 ?
                        <React.Fragment key={'totalTable'}>
                            <Typography variant="h6" gutterBottom>{t('Summary')}</Typography>
                            <DataTableLocal
                                columnData={this.totalColumnData}
                                data={totalData}
                                selector={false}
                                pagination={false}
                                disableSorting
                            />
                        </React.Fragment> : null}

                    {paymentTableColumns.length > 0 && search.showPaymentTable ?
                        <React.Fragment key={'paymentTable'}>
                            <Typography variant="h6" gutterBottom>{t('Payment Table')}</Typography>
                            <DataTableLocal
                                columnData={paymentTableColumns}
                                data={paymentTableData}
                                selector={false}
                                pagination={false}
                                disableSorting
                            />
                        </React.Fragment>
                        : null
                    }

                    {serviceTableColumns.length > 0 && search.showServiceTable ?
                        <React.Fragment key={'serviceTable'}>
                            <Typography variant="h6" gutterBottom>{t('Service Table')}</Typography>
                            <DataTableLocal
                                columnData={serviceTableColumns.map(t => {
                                    return {...t, linkTemplate: this.linkTemplateFunc(t)}
                                })}
                                data={serviceTableData}
                                selector={false}
                                pagination={false}
                                summary={true}
                                disableSorting
                                stickyHeader
                                stickyLeftColumn
                                height='65em'
                            />
                        </React.Fragment>
                        : null
                    }

                    {mutualReportsTableColumns.length > 0 && search.showMutualReportsTable ?
                        <React.Fragment key={'mutualReportsTable'}>
                            <Typography variant="h6" gutterBottom>{t('Mutual Reports Table')}</Typography>
                            <DataTableLocal
                                columnData={mutualReportsTableColumns}
                                data={mutualReportsTableData}
                                selector={false}
                                pagination={false}
                                disableSorting
                            />
                        </React.Fragment>
                        : null
                    }

                </Content>
            </React.Fragment>
        );
    }
}
