import {Route, withRouter} from 'react-router';
import { connect } from 'react-redux';
import {hasRole } from "../../services/user";
import authActions from "../../pages/login/auth-actions";

export default
@withRouter
@connect(state => ({
    token: state.auth.token,
}),{
    setRedirectUri: authActions.setRedirectUri,
})
class AuthenticatedRoute extends Route {

    componentDidMount() {
        if (!this.isAuthorized()) {
            if (!new RegExp("^/login").test(this.props.history.location.pathname)) {
                this.props.setRedirectUri(this.props.history.location.pathname);
            }
            this.props.history.push("/login");
        }
    }

    isAuthorized = () => {
        const { token } = this.props;

        return token && token.access_token;
    };

    render() {
        if (this.isAuthorized() && hasRole(this.props.userRole)) {
            return Route.prototype.render.call(this);
        }

        return null;
    }
}
