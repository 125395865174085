import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import LeftBar from "../../components/left-bar/LeftBar";
import DateByTypeRadioSelectField from "../../components/fields/DateByTypeRadioSelectField";
import Content from "../../components/content/Content";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {
    getOsvSpendingType,
    getSpendingTypes,
    navLinks
} from "../../services/moneyFlow";
import {Grid} from "@material-ui/core";
import moment from "moment/moment";
import {getBusinessUnits} from "../../services/organization";
import ActionButton from "../../components/button/ActionButton";
import AutocompleteMultiSelectField from "../../components/fields/AutocompleteMultiSelectField";
import DataTable from "../../components/data-table/DataTable";
import {formatMoney} from "../../services/common";
import {processMomentFieldInObject} from "../../utils/moment-utils";
import CheckboxField from "../../components/fields/CheckboxField";
import {dateByTypeRadioSelectFieldDateTypes} from '../../services/common'

const styles = theme => ({
    dialogContent: {
        paddingTop: theme.spacing(1),
    },
    filterControl: {
        marginBottom: 15
    }
});

export default
@withStyles(styles)
@withTranslation()
class OSVSpendingTypeReport extends React.Component {
    state = {
        search: {
            dateType: dateByTypeRadioSelectFieldDateTypes.month,
            month: moment(),
            startedAt: moment(),
            endedAt: moment(),

            businessUnitIds: [],
            spendingTypesIds: [],
            hideInactive: true,
            costsIsNull: false,
        },
        refreshTable: false,
        businessUnits: [],
        spendingTypes: [],
    };

    columnData = [
        { id: 'period', label: 'Период', dateFormat: "MMM yyyy"},
        { id: 'businessUnitName', label: 'Салон', width: '12.4%' },
        { id: 'saldoStart', label: 'Сальдо на начало периода', processValue: formatMoney },
        { id: 'accrued', label: 'Начислено', processValue: formatMoney },
        { id: 'paid', label: 'Оплачено', processValue: formatMoney },
        { id: 'saldoEnd', label: 'Сальдо на конец периода', processValue: formatMoney },
        { id: 'spendingTypeName', label: 'Статья расхода'},
    ];

    componentDidMount() {
        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data
                    });
                }
            });

        getSpendingTypes({showInactive: !this.state.search.hideInactive})
            .then(response => {
                if (response.success) {
                    this.setState({
                        spendingTypes: response.data
                    });
                }
            });
    }

    handleChange = prop => event => {
        const value = (event instanceof moment) ?
            event :
            event.target.value;

        this.setState({
            search: {
                ...this.state.search,
                [prop]: value,
            }
        }, () => {
            if (prop === 'hideInactive' ) {
                getSpendingTypes({
                    showInactive: !this.state.search.hideInactive
                })
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                spendingTypes: response.data,
                            });
                        }
                    });
            }
        });
    };

    handleClickFilter = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    getTableData = (...params) => {
        const {search} = this.state;

        return getOsvSpendingType(
            processMomentFieldInObject(search, ['date', 'startedAt', 'endedAt', 'month']), ...params
        );
    };

    render() {
        const { t } = this.props;
        const { search, businessUnits, spendingTypes, refreshTable } = this.state;

        return(
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DateByTypeRadioSelectField
                                onChange={this.handleChange}
                                startedAt={search.startedAt}
                                endedAt={search.endedAt}
                                dateType={search.dateType}
                                month={search.month}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteMultiSelectField
                                options={businessUnits}
                                label={t("Business unit")}
                                value={search.businessUnitIds}
                                placeholder={t('---All---')}
                                onChange={this.handleChange('businessUnitIds')}
                                fullWidth
                            />
                            <AutocompleteMultiSelectField
                                label={t('Spending type')}
                                value={search.spendingTypesIds}
                                name="spendingTypesIds"
                                onChange={this.handleChange('spendingTypesIds')}
                                fullWidth
                                options={spendingTypes}
                                placeholder={t('---All---')}
                                valueField="id"
                                textField="name"
                            />
                            <CheckboxField
                                label={t('Hide not active spending type')}
                                value={search.hideInactive}
                                onChange={this.handleChange('hideInactive')}
                            />
                            <CheckboxField
                                label={t('Скрывать при нулевых начислениях/списаниях')}
                                value={search.costsIsNull}
                                onChange={this.handleChange('costsIsNull')}
                            />
                        </Grid>
                    </Grid>
                    <ActionButton onClick={this.handleClickFilter}>{t('Filter')}</ActionButton>
                </LeftBar>

                <Content title={t('OSV Spending Type Report')}>
                    <DataTable
                        columnData={this.columnData}
                        dataFunc={this.getTableData}
                        selector={false}
                        pagination={false}
                        refresh={refreshTable}
                        autoload={false}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
