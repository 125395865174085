import React from 'react';
import DataTable from "../../components/data-table/DataTable";
import {getEmployeeSpecializations, navLinks} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import SearchField from "../../components/fields/SearchField";
import ActionButton from "../../components/button/ActionButton";

const columnData = [
    { id: 'name', label: 'Название' },
];

export default
class EmployeeSpecializationListPage extends React.Component {

    state = {
        search: {
            search: null
        },
        refreshTable: false
    };

    handleChange = prop => event => {
        this.setState({
            search: {
                ...this.state.search,
                [prop]: event.target.value,
            }
        });
    };

    handleClickSearch = () => {
        this.setState(state => ({ refreshTable: !state.refreshTable }));
    };

    handleAdd = () => {
        this.props.history.push(`/organization/employee-specializations/view/0`);
    };

    getTableData = (...params) => {
        const {search} = this.state;
        return getEmployeeSpecializations(search, ...params);
    };

    render() {
        const { search, refreshTable } = this.state;

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <SearchField
                        value={search.search}
                        onChange={this.handleChange('search')}
                        onClick={this.handleClickSearch}
                        label="Поиск"
                    />
                    <br />
                    <br />
                    <ActionButton onClick={this.handleAdd}>Добавить</ActionButton>
                </LeftBar>
                <Content title="Специализации сотрудников">
                    <DataTable
                        columnData={columnData}
                        dataFunc={this.getTableData}
                        linkTemplate={(item) => `/organization/employee-specializations/view/${item.id}`}
                        selector={false}
                        refresh={refreshTable}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
