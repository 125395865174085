import React from "react";
import {
    completeGoodStorageInvoice, createGoodsStorageFast,
    deleteGoodStorageInvoice,
    getCategories,
    getGoodPriceByGood,
    getGoodPurposes,
    getGoods, getGoodStorageForRevision,
    getGoodStorageInvoice,
    removeGoodStorageInvoice,
    saveGoodStorageInvoice,
    uploadScanWithTelegram
} from "../../services/goods";
import {getBusinessUnitByRole} from "../../services/user";
import { getExternalSubjects} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import {navLinks} from "../../services/goods";
import ShowField from "../../components/fields/ShowField";
import Content from "../../components/content/Content";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import AnyField from "../../components/fields/AnyField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";
import DataTableLocal from "../../components/data-table/DataTableLocal";
import Typography from "@material-ui/core/Typography";
import ActionButton from "../../components/button/ActionButton";
import MoneyField from "../../components/fields/MoneyField";
import {formatMoney} from "../../services/common";
import {connect} from "react-redux";
import infoActions from "../../components/info/info-actions";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import DateField2 from "../../components/fields/DateField2";
import socketActions from "../../socket/socket-actions";
import moment from "moment";
import FloatField from "../../components/fields/FloatField";
import IntegerField from "../../components/fields/IntegerField";

const emptyGoodStorageInvoiceGrouped = {
    id: null,
    goodId: null,
    goodName: null,
    isExpendable: false,
    categoryName: null,
    categoryId: null,
    price: null,
    total: null,
    comment: null,
    purpose: 'for_sale',
    purposeText: null,
    quantity: 0,
    useOrSalePrice: null,
    status: null,
    statusText: null
};

const emptyGoodStorageInvoice = {
    id: null,
    outerNum: null,
    num: null,
    status: 'new',
    statusText: null,
    businessUnitId: null,
    contractorId: null,
    goodStorageGrouped: [],
    applyAt: moment(),
    scanId: null,
    comment: null,
    creatorName: null,
    createdAt: null,
    materialsForCraft: []
};

const columnData = [
    { id: 'goodName', label: 'Good' },
    { id: 'categoryName', label: 'Category' },
    { id: 'statusText', label: 'Status' },
    { id: 'purposeText', label: 'Purpose' },
    { id: 'isExpendable', label: 'Expendable' },
    { id: 'useOrSalePrice', label: 'useOrSalePrice', processValue: (value) => value && value.val ? formatMoney(value) : null },
    { id: 'price', label: 'Price', processValue: formatMoney },
    { id: 'quantity', label: 'Quantity' },
    { id: 'total', label: 'Total', processValue: formatMoney },
    { id: 'comment', label: 'Comment' },
    { id: 'invoiceSort', label: 'Invoice sort'},
];

@connect(state => ({
    currentUser: state.auth.user,
    socketMessages: state.socket.messages,
}),{
    removeMessages: socketActions.removeMessages,
    showInfo: infoActions.show,
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
})
@withTranslation()
@withRouter
class InvoiceViewPage extends React.PureComponent
{
    state = {
        invoice: emptyGoodStorageInvoice,
        currentGoodStorageGrouped: {},
        currentIndex: null,
        currentGoodStorageGroupedPrices: [],
        goodStorageGroupedModalOpen: false,
        businessUnits: [],
        externalSubjects: [],
        //statuses: [],
        purposes: [],
        goods: [],
        categories: [],
        telegramRequest: false,
        goodPriceLoading: false,
        materialsForCraft: [],
        goodStorages: []
    }

    constructor(props) {
        super(props);

        const goodStorageInvoiceMessages = props.socketMessages.filter(message => message.entity === 'goodStorageInvoice');
        if (goodStorageInvoiceMessages.length) {
            props.removeMessages(goodStorageInvoiceMessages.map(message => message.id));
        }
    }

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            getGoodStorageInvoice(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            invoice: response.data
                        }, () => {
                            this.sortGoodStorageGrouped();
                        })
                    }
                });
        }

        getBusinessUnitByRole('ROLE_GOOD_GOODSTORAGE_INVOICE_SAVE')
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getExternalSubjects()
            .then(response => {
                if (response.success) {
                    this.setState({
                        externalSubjects: response.data.map(item => ({value: item.id, label: `${item.name}`})),
                    })
                }
            });

        getGoodPurposes()
            .then(response => {
                if (response.success) {
                    emptyGoodStorageInvoiceGrouped.purposeText = response.data.find(purpose => purpose.id === emptyGoodStorageInvoiceGrouped.purpose).text;
                    this.setState({
                        purposes: response.data,
                    })
                }
            });

        getCategories({'activeForInvoice': true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        categories: response.data,
                    })
                }
            });

        getGoods()
            .then(response => {
                if (response.success) {
                    this.setState({
                        goods: response.data,
                    })
                }
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.socketMessages !== prevProps.socketMessages) {
            this.props.socketMessages.forEach(message => {
                if (message.entity && message.entity === 'goodStorageInvoice') {
                    if (message.action === 'update') {
                        this.setState({
                            invoice: message.entityData
                        })
                    }
                }
            });
        }
    }

    handleChange = event => {
        let  value = event.target.value;
        let prop = event.target.name;

        this.setState({
            invoice: {
                ...this.state.invoice,
                [prop]: value,
            }
        }, this.setGoodStorage);
    }

    setGoodStorage = () => {
        getGoodStorageForRevision(
            { search: null, businessUnitId: this.state.invoice.businessUnitId, purpose: "for_use", inStorageOnly: true },
            -1,
            -1
        ).then(response => {
            if (response.success) {
                this.setState(prev => ({
                    ...prev,
                    goodStorages: response.data
                }))
            }
        })
    }

    addGoodStorageGrouped = () => {
        this.setState({
            currentGoodStorageGrouped: {...emptyGoodStorageInvoiceGrouped},
            currentIndex: null,
            goodStorageGroupedModalOpen: true
        }, this.setGoodStorage);
    }

    editGoodStorageGrouped = (goodStorageGrouped, index) => {
        this.setState({
            currentGoodStorageGrouped: {...goodStorageGrouped},
            currentIndex: index,
            goodStorageGroupedModalOpen: true
        }, this.setQuantityForMaterials)
    }

    deleteGoodStorageGrouped = (item, index) => {
        if (item.status === 'complete') {
            return;
        }

        let goodStorageGrouped = [...this.state.invoice.goodStorageGrouped];
        goodStorageGrouped.splice(index, 1);
        this.setState({
            invoice: {
                ...this.state.invoice,
                goodStorageGrouped: goodStorageGrouped
            }
        }, () => { this.recalculateInvoiceTotal(); this.sortGoodStorageGrouped() });
    }

    sortGoodStorageGrouped = () => {
        let goodStorageGrouped = [ ...this.state.invoice.goodStorageGrouped ];

        goodStorageGrouped.sort((a,b) => {
            if (isNaN(parseInt(b.invoiceSort)))
                return -1

            if (isNaN(parseInt(a.invoiceSort)))
                return 1

            return (parseInt(a.invoiceSort) > parseInt(b.invoiceSort)) ? 1 : (parseInt(a.invoiceSort) < parseInt(b.invoiceSort)) ? -1 : 0;
        });

        this.setState({
            invoice: {
                ...this.state.invoice,
                goodStorageGrouped: goodStorageGrouped
            }
        })
    }

    closeGoodStorageGroupedModal = (ok) => () => {
        if (!ok) {
            this.setState({
                goodStorageGroupedModalOpen: false,
                materialsForCraft: []

            });
            return;
        }

        const {
            invoice,
            currentGoodStorageGrouped,
            currentIndex,
        } = this.state;

        let goodStorageGrouped = [...invoice.goodStorageGrouped];

        if (isNaN(parseInt(currentGoodStorageGrouped.invoiceSort)))
            currentGoodStorageGrouped.invoiceSort = goodStorageGrouped.length + 1;

        currentGoodStorageGrouped.invoiceSort = parseInt(currentGoodStorageGrouped.invoiceSort);

        if (currentIndex === null) {
            goodStorageGrouped.push(currentGoodStorageGrouped);
        } else {
            goodStorageGrouped[currentIndex] = currentGoodStorageGrouped;
        }

        this.setState({
            invoice: {
                ...invoice,
                goodStorageGrouped: goodStorageGrouped
            },
            goodStorageGroupedModalOpen: false
        }, () => {
            this.recalculateInvoiceTotal();
            this.saveInvoice();
        });
    };

    handleChangeGoodStorageGrouped = (event) => {
        let prop = event.target.name;
        let value = event.target.value;

        const { currentGoodStorageGrouped, goods, categories, purposes } = this.state;

        let newCurrentStorageGoodGrouped = {
            ...currentGoodStorageGrouped,
            [prop]: value
        };

        if (prop === 'goodId') {
            let good = goods.find(good => good.id === event.target.value);
            newCurrentStorageGoodGrouped.goodName = good.name;
            newCurrentStorageGoodGrouped.categoryId = good.category.id;
            newCurrentStorageGoodGrouped.categoryName = good.category.name;
            newCurrentStorageGoodGrouped.isExpendable = good.expendable;
            if (good.expendable) {
                newCurrentStorageGoodGrouped.purposeText = 'Для расхода';
                newCurrentStorageGoodGrouped.purpose = 'for_use';
            }

        }

        if (prop === 'categoryId') {
            let category = categories.find(cat => cat.id === event.target.value);
            newCurrentStorageGoodGrouped.categoryName = category.name;
        }

        if ( prop === 'price' || prop === 'quantity' ) {
            let total = { ...newCurrentStorageGoodGrouped.price };
            total.val = total.val * (newCurrentStorageGoodGrouped.isExpendable ? 1 : newCurrentStorageGoodGrouped.quantity);
            newCurrentStorageGoodGrouped.total = total;
        }

        if ( prop === 'purpose' ) {
            newCurrentStorageGoodGrouped.purposeText = purposes.find(purpose => purpose.id === event.target.value).text;
        }

        this.setState({
            currentGoodStorageGrouped: newCurrentStorageGoodGrouped
        }, () => {
            if (prop === 'goodId' && value) {
                this.findGoodPrices();
                this.setQuantityForMaterials();
            }

            if (prop ==='quantity') {
                this.setNeededQuantityForMaterials();
            }
        });
    };

    setQuantityForMaterials = () => {
        const materialsForCraft = this.state.goods.find(good => good.id === this.state.currentGoodStorageGrouped.goodId)?.materialsForCraft;

        if (materialsForCraft) {
            if (this.state.goodStorages.length !== 0) {
                const materialsForCraftStorages = materialsForCraft.map(goodMaterial => {
                    const copiedGoodMaterial = JSON.parse(JSON.stringify(goodMaterial));

                    copiedGoodMaterial.count *= this.state.currentGoodStorageGrouped.quantity ?? 0;
                    if (this.state.goodStorages) {
                        copiedGoodMaterial.storages = this.state?.goodStorages.find(goodStorage => goodStorage.id === copiedGoodMaterial.id)?.rest?.val;
                    } else {
                        copiedGoodMaterial.storages = 0;
                    }

                    return copiedGoodMaterial;
                });

                this.setState(prev => ({
                    ...prev,
                    materialsForCraft: materialsForCraftStorages
                }))
            } else {
                getGoodStorageForRevision(
                    { search: null, businessUnitId: this.state.invoice.businessUnitId, purpose: "for_use", inStorageOnly: true },
                    -1,
                    -1
                ).then(response => {
                    if (response.success) {
                        const goodInStock = response.data;
                        if (goodInStock.length !== 0) {
                            this.setState(prev => ({
                                ...prev,
                                goodStorages: goodInStock
                            }), this.setQuantityForMaterials)
                        }
                    }
                })
            }
        }
    }

    setNeededQuantityForMaterials = () => {
        const materialsForCraft = this.state.goods.find(good => good.id === this.state.currentGoodStorageGrouped.goodId)?.materialsForCraft;

        if (materialsForCraft) {
            getGoodStorageForRevision(
                { search: null, businessUnitId: this.state.invoice.businessUnitId, purpose: "for_use", inStorageOnly: true },
                -1,
                -1
            ).then(response => {
                if (response.success) {
                    const { currentGoodStorageGrouped } = this.state;

                    let currentGoodStorageGroupedQuantity = (currentGoodStorageGrouped.quantity ?? 0);

                    if (currentGoodStorageGroupedQuantity === 0 || currentGoodStorageGroupedQuantity === '') {
                        currentGoodStorageGroupedQuantity = 1;
                    }

                    if (materialsForCraft) {
                        const materialsForCraftStorages = materialsForCraft.map(goodMaterial => {
                            const copiedGoodMaterial = JSON.parse(JSON.stringify(goodMaterial));

                            copiedGoodMaterial.count *= currentGoodStorageGroupedQuantity;
                            if (this.state.goodStorages) {
                                copiedGoodMaterial.storages = (this.state?.goodStorages ?? []).find(goodStorage => goodStorage.id === copiedGoodMaterial.id)?.rest?.val;
                            } else {
                                copiedGoodMaterial.storages = 0;
                            }

                            return copiedGoodMaterial;
                        });

                        this.setState(prev => ({
                            ...prev,
                            materialsForCraft: materialsForCraftStorages
                        }))
                    }
                }
            })
        }
    }

    recalculateInvoiceTotal = () => {
        let total = null;
        if (this.state.invoice.goodStorageGrouped.length) {
            total = this.state.invoice.goodStorageGrouped.reduce((total, good) => total + good.total.val, 0);
            total = {val: total, cur: this.state.invoice.goodStorageGrouped[0].total.cur};
        }
        this.setState({
            invoice: {
                ...this.state.invoice,
                total: total
            }
        });
    };

    saveInvoice = () => {
        const  {t, showMessage, showInfo} = this.props;
        saveGoodStorageInvoice(this.state.invoice)
            .then(response => {
                if (response.success) {
                    showInfo(t('Invoice saved'));
                    this.setState({
                        invoice: response.data
                    }, this.sortGoodStorageGrouped);
                    this.props.history.replace(`/goods/invoice/view/${response.data.id}`);
                } else {
                    if (response.error && response.error.errors && response.error.errors.length) {
                        let errors = response.error.errors.map((error, idx) => <span key={idx}>{error.property} - {error.message}<br/></span>);
                        showMessage(errors);
                    } else {
                        showMessage(response.error ? response.error.message : response.message);
                    }

                }
            });
    }

    completeInvoice = () => {
        const  {t, showMessage, showInfo} = this.props;
        const {invoice, goods} = this.state;

        const invoiceGoodStorageGrouped = (invoice?.goodStorageGrouped ?? []);

        const goodMaterials = invoiceGoodStorageGrouped.map(goodGrouped => {
            return (goods.find(g => g.id === goodGrouped.goodId)?.materialsForCraft ?? []);
        })

        if (goodMaterials.length > 0) {
            getGoodStorageForRevision(
                { search: null, businessUnitId: this.state.invoice.businessUnitId, purpose: "for_use", inStorageOnly: true },
                -1,
                -1
            ).then(response => {
                if (response.success) {
                    const goodInStock = response.data;
                    const materials = this.state.invoice.goodStorageGrouped.map(goodStorageGrouped => {
                        const newGoodStorageGrouped = {};
                        newGoodStorageGrouped.goodStorageGroupedId = goodStorageGrouped.id;
                        newGoodStorageGrouped.goodStorages = this.state.goods.find(g => g.id === goodStorageGrouped.goodId)?.materialsForCraft
                            .map(goodMaterial => {
                                const newGoodMaterial = {};
                                newGoodMaterial.id = goodMaterial.id;
                                newGoodMaterial.count = goodMaterial.count * goodStorageGrouped.quantity;
                                newGoodMaterial.storages = (goodInStock.find(goodInStock => goodInStock.id === goodMaterial.id)?.rest.val ?? 0);

                                return newGoodMaterial;
                            })

                        return newGoodStorageGrouped;
                    });

                    const isOutOfStock = materials
                        ?.flatMap((goodMaterial) => goodMaterial.goodStorages)
                        ?.reduce((acc, goodMaterial) => {
                            const doubleGood = acc.find((good) => good.id === goodMaterial.id);
                            if (doubleGood) {
                                doubleGood.count += goodMaterial.count;
                            } else {
                                acc.push({ ...goodMaterial });
                            }
                            return acc;
                        }, [])
                        .some(goodStorage => goodStorage.count > goodStorage.storages);

                    if (isOutOfStock) {
                        this.props.showMessage('Оприходование не выполнено! На балансе бизнес-юнита недостаточно материалов для производства.');
                        return;
                    }

                    completeGoodStorageInvoice({
                        ...invoice,
                        materialsForCraft: materials
                    })
                        .then(response => {
                            if (response.success) {
                                showInfo(t('Invoice completed'));
                                this.setState({
                                    invoice: response.data
                                }, this.sortGoodStorageGrouped);
                            } else {
                                showMessage(response.error ? response.error.message : response.message);
                            }
                        });
                }
            })
        } else {
            completeGoodStorageInvoice(invoice)
                .then(response => {
                if (response.success) {
                    showInfo(t('Invoice completed'));
                    this.setState({
                        invoice: response.data
                    }, this.sortGoodStorageGrouped);
                } else {
                    showMessage(response.error ? response.error.message : response.message);
                }
            });
        }
    }

    deleteInvoice = () => {
        const  {t, showMessage, showInfo, history} = this.props;

        this.props.showConfirm({
            message: t('Are you sure') + '?',
            title: t('Delete invoice'),
            onClose: (ok) => {
                if (!ok) {
                    return;
                }
                deleteGoodStorageInvoice(this.state.invoice)
                    .then(response => {
                        if (response.success) {
                            showInfo(t('Invoice removed'));
                            history.push(`/goods/invoice/`);
                        } else {
                            showMessage(response.error ? response.error.message : response.message);
                        }
                    });
            },
        });


    }

    removeInvoice = () => {
        const  {t, showMessage, showInfo, history} = this.props;
        this.props.showConfirm({
            message: t('Are you sure') + '?',
            title: t('Delete invoice'),
            onClose: (ok) => {
                if (!ok) {
                    return;
                }

                const materials = this.state.invoice.goodStorageGrouped.filter(gs => {
                    return this.state.goods.find(good => good.id === gs.goodId)?.materialsForCraft.length !== 0;
                })

                if (this.state.invoice.status === 'complete' && materials.length !== 0) {
                    getGoodStorageInvoice(this.state.invoice.id)
                        .then(response => {
                            if (response.success) {
                                const materials = this.state.invoice.goodStorageGrouped.map(goodGrouped => {
                                    const newObj = {};

                                    newObj.materials = this.state.goods.find(good => good.id === goodGrouped.goodId)?.materialsForCraft
                                        .map(goodMaterial => {
                                            const copiedGoodMaterial = {};

                                            copiedGoodMaterial.quantity = goodMaterial.count * goodGrouped.quantity
                                            copiedGoodMaterial.good = goodMaterial.id;
                                            copiedGoodMaterial.businessUnitId = this.state.invoice.businessUnitId;
                                            copiedGoodMaterial.expressDelivery = false;
                                            copiedGoodMaterial.price = {cur: "RUB"};
                                            copiedGoodMaterial.purchasePrice = {cur: "RUB"};
                                            copiedGoodMaterial.purpose = 'for_use';

                                            return copiedGoodMaterial;
                                        });

                                    return newObj;
                                }).reduce((acc, item) => {
                                    return acc.concat(item.materials);
                                }, []);

                                removeGoodStorageInvoice(this.state.invoice).then(response => {
                                    if (response.success) {
                                        showInfo(t('Invoice removed'));

                                        if (materials) {
                                            createGoodsStorageFast(materials)
                                                .then(response => {
                                                    if (response.success) {
                                                        history.push(`/goods/invoice/`);
                                                    }
                                                })
                                        } else {
                                            history.push(`/goods/invoice/`);
                                        }
                                    } else {
                                        showMessage(response.error ? response.error.message : response.message);
                                    }
                                })
                            }
                        });
                } else {
                    removeGoodStorageInvoice(this.state.invoice).then(response =>  {
                        if (response.success) {
                            showInfo(t('Invoice removed'));
                            history.push(`/goods/invoice/`);
                        } else {
                            showMessage(response.error ? response.error.message : response.message);
                        }
                    })
                }
            },
        });
    }

    handleClickBack = () => {
        this.props.history.push(`/goods/invoice`);
    };

    uploadWithTelegram = () => {
        const {invoice} = this.state;
        const  {showMessage} = this.props;
        uploadScanWithTelegram(invoice.id)
            .then(response => {
                if(response.success) {
                    this.setState({
                        telegramRequest: true
                    })
                } else {
                    showMessage(response.error ? response.error.message : response.message);
                }
            })
    }

    findGoodPrices = () => {
        const  {showMessage} = this.props;

        let goodId = this.state.currentGoodStorageGrouped.goodId;
        if (!goodId) {
            this.sortGoodStorageGrouped();
            return;
        }

        this.setState({
            goodPriceLoading: true
        });

        getGoodPriceByGood(goodId)
            .then(response => {
                if(response.success) {
                    this.setState({
                        currentGoodStorageGroupedPrices: response.data,
                        goodPriceLoading: false
                    }, this.sortGoodStorageGrouped)
                } else {
                    showMessage(response.error ? response.error.message : response.message);
                    this.setState({
                        goodPriceLoading: false
                    });
                }
            })
    }

    render() {
        const {
            invoice,
            businessUnits,
            externalSubjects,
            goodStorageGroupedModalOpen,
            telegramRequest
        } = this.state;

        const { t, currentUser } = this.props;

        let saveDisabled = !invoice.businessUnitId || !invoice.contractorId || !invoice.outerNum || !invoice.goodStorageGrouped.length;
        let completeEnabled = invoice.goodStorageGrouped.filter(goodStorageGrouped => goodStorageGrouped.status === 'new').length;
        let readOnly = invoice.status !== 'new';
        let deletePossible = !!invoice.id && invoice.status === 'new';

        return (
            <>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <ShowField label={t('Status')} value={invoice.statusText} />
                    <ShowField label={t('Total')} value={formatMoney(invoice.total)} />
                    <ShowField label={t('Inner num')} value={invoice.num} />
                    <ShowField label={t('Creator')} value={invoice.creatorName} />
                    <ShowField label={t('Created at')} value={invoice.createdAt} dateFormat="DD.MM.YYYY HH:mm"/>
                    <ActionButton onClick={this.saveInvoice} disabled={saveDisabled}>{t('Save')}</ActionButton>
                    <ActionButton onClick={this.completeInvoice} disabled={ !completeEnabled || saveDisabled || !invoice.applyAt || !invoice.id }>{t('Complete invoice')}</ActionButton>
                    <ActionButton onClick={this.deleteInvoice} disabled={!deletePossible}>{t('Delete')}</ActionButton>
                    <ActionButton variant={"outlined"} onClick={this.removeInvoice} visible={currentUser.isSuperAdmin} >{t('Delete')}</ActionButton>
                    <ActionButton onClick={this.handleClickBack}>{t('Back')}</ActionButton>
                </LeftBar>
                <Content title={t('Invoice card')}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                name="businessUnitId"
                                items={businessUnits}
                                value={invoice.businessUnitId}
                                error={!invoice.businessUnitId}
                                valueField="id"
                                textField="name"
                                onChange={this.handleChange}
                                readOnly={readOnly}
                                label={t('Business unit')}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteSelectField
                                options={externalSubjects}
                                value={invoice.contractorId}
                                error={!invoice.contractorId}
                                valueField="id"
                                textField="name"
                                onChange={this.handleChange}
                                name="contractorId"
                                label={t('Contractor')}
                                required
                                readOnly={readOnly}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                value={invoice.outerNum}
                                error={!invoice.outerNum}
                                onChange={this.handleChange}
                                name="outerNum"
                                label={t('Num')}
                                readOnly={readOnly}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateField2
                                onChange={this.handleChange}
                                name="applyAt"
                                value={invoice.applyAt}
                                readOnly={readOnly}
                                label={t('Apply at')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                value={invoice.comment}
                                onChange={this.handleChange}
                                name="comment"
                                label={t('Comment')}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <UploadFilesField
                                id="scanId"
                                value={invoice.scanId}
                                onChange={this.handleChange}
                                name="scanId"
                                label={t('Scan')}
                                fullWidth
                            />
                            {!invoice.scanId ?
                                <ActionButton onClick={this.uploadWithTelegram} disabled={telegramRequest}>{t('Telegram')}</ActionButton>
                            : null}

                        </Grid>
                    </Grid>
                    <Typography variant="h5">{t('Goods')}</Typography>
                    <DataTableLocal
                        columnData={columnData}
                        data={invoice.goodStorageGrouped}
                        selector={false}
                        pagination={false}
                        disableSorting
                        onClick={this.editGoodStorageGrouped}
                        onDelete={this.deleteGoodStorageGrouped}
                    />
                        <ActionButton onClick={this.addGoodStorageGrouped} fullWidth={false} disabled={!invoice.businessUnitId}>{t('Add good')}</ActionButton>
                    {goodStorageGroupedModalOpen && !!businessUnits.length && this.renderGoodStorageGroupedModal()}
                </Content>
            </>
        );
    }

    validSave = () => {
        return (!this.state.currentGoodStorageGrouped.goodId ||
            !this.state.currentGoodStorageGrouped.price ||
            !this.state.currentGoodStorageGrouped.purpose ||
            !this.state.currentGoodStorageGrouped.quantity) ||
            this.getNeededStorages()?.length > 0;
    }

    getNeededStorages = () => {
        return this.state?.materialsForCraft?.filter(goodMaterial => {
            return (goodMaterial?.count ?? 0) > (goodMaterial.storages ?? 0);
        });
    }

    renderGoodStorageGroupedModal = () => {
        const { invoice, currentGoodStorageGrouped, goods, categories, purposes, businessUnits, currentGoodStorageGroupedPrices, goodPriceLoading } = this.state;
        const { t } = this.props;

        let filteredGoods = currentGoodStorageGrouped.categoryId ? goods.filter(good => good.category.id === currentGoodStorageGrouped.categoryId) : goods;
        let currentBusinessUnit = invoice.businessUnitId ? businessUnits.find(businessUnit => businessUnit.id === invoice.businessUnitId) : null;

        let goodPrice = t('Loading');
        if (currentGoodStorageGrouped.goodId && invoice.businessUnitId) {
            let goodPriceObj = currentGoodStorageGroupedPrices.find(goodPrice => goodPrice.businessUnitId === invoice.businessUnitId);
            if (goodPriceObj) {
                goodPrice = currentGoodStorageGrouped.purpose === 'for_sale' ? goodPriceObj.salePriceFormatted : goodPriceObj.usePriceFormatted;
                if (!goodPrice) {
                    goodPrice = t('priceNotSet');
                }
            } else {
                goodPrice = t('priceNotFound');
            }
        }
        let readOnly = currentGoodStorageGrouped.id && currentGoodStorageGrouped.status !== 'new';

        return (
            <Dialog
                open={true}
                onClose={this.closeGoodStorageGroupedModal(false)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="dialog-title">{t('Add good')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                items={categories}
                                label={t('Category')}
                                value={currentGoodStorageGrouped.categoryId}
                                valueField="id"
                                textField="name"
                                name="categoryId"
                                onChange={this.handleChangeGoodStorageGrouped}
                                readOnly={readOnly}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <AutocompleteSelectField
                                options={filteredGoods.map(item => ({value: item.id, label: `${item.nameWithMass}`}))}
                                value={currentGoodStorageGrouped.goodId}
                                error={!currentGoodStorageGrouped.goodId}
                                onChange={this.handleChangeGoodStorageGrouped}
                                name="goodId"
                                label={t('Good')}
                                readOnly={readOnly}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <AnyField
                                value={currentGoodStorageGrouped.invoiceSort}
                                onChange={this.handleChangeGoodStorageGrouped}
                                name="invoiceSort"
                                label={t('Invoice sort')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {currentGoodStorageGrouped.isExpendable ?
                                <FloatField
                                    value={currentGoodStorageGrouped.quantity}
                                    error={!currentGoodStorageGrouped.quantity || this.getNeededStorages()?.length > 0}
                                    onChange={this.handleChangeGoodStorageGrouped}
                                    name="quantity"
                                    label={!currentGoodStorageGrouped.isExpendable ? t('Quantity') : t('Full quantity')}
                                    required
                                    fullWidth
                                    readOnly={readOnly}
                                />
                                :
                                <IntegerField
                                    value={currentGoodStorageGrouped.quantity}
                                    error={!currentGoodStorageGrouped.quantity || this.getNeededStorages()?.length > 0}
                                    onChange={this.handleChangeGoodStorageGrouped}
                                    name="quantity"
                                    label={!currentGoodStorageGrouped.isExpendable ? t('Quantity') : t('Full quantity')}
                                    required
                                    readOnly={readOnly}
                                    fullWidth
                                />
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <MoneyField
                                label={!currentGoodStorageGrouped.isExpendable ? t('Price per item') : t('Full price') }
                                onChange={this.handleChangeGoodStorageGrouped}
                                name="price"
                                currencies={[currentBusinessUnit.mainCurrency]}
                                value={currentGoodStorageGrouped.price}
                                error={!currentGoodStorageGrouped.price}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MoneyField
                                label={t('Total')}
                                currencies={[currentBusinessUnit.mainCurrency]}
                                value={currentGoodStorageGrouped.total}
                                required
                                fullWidth
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFromItemsField
                                items={purposes}
                                label={t('Purpose')}
                                value={currentGoodStorageGrouped.purpose}
                                error={!currentGoodStorageGrouped.purpose}
                                valueField="id"
                                textField="text"
                                onChange={this.handleChangeGoodStorageGrouped}
                                name="purpose"
                                required
                                fullWidth
                                readOnly={currentGoodStorageGrouped.isExpendable || readOnly}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MoneyField
                                label={currentGoodStorageGrouped.purpose === 'for_sale' ? t('SalePrice') : t('UsePrice')}
                                currencies={[currentBusinessUnit.mainCurrency]}
                                value={currentGoodStorageGrouped.useOrSalePrice}
                                disabled={!currentGoodStorageGrouped.goodId || !invoice.businessUnitId}
                                helperText={currentGoodStorageGrouped.goodId && invoice.businessUnitId ? t('UseOrSalePriceHelper', {price: goodPrice}) : null}
                                fullWidth
                                name="useOrSalePrice"
                                onChange={this.handleChangeGoodStorageGrouped}
                                disableCurrencySelect
                                updatingValue={goodPriceLoading}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AnyField
                                value={currentGoodStorageGrouped.comment}
                                onChange={this.handleChangeGoodStorageGrouped}
                                name="comment"
                                label={t('Comment')}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        {
                            this.state.materialsForCraft &&
                            this.state.materialsForCraft.map(goodMaterial => {
                                const goodName = goods.find(g => g.id === goodMaterial?.id)?.name;

                                const validError = (goodMaterial?.count ?? 0) > (goodMaterial.storages ?? 0);

                                return (
                                    <Grid container spacing={1} key={goodMaterial?.id}>
                                        <Grid item xs={4}>
                                            <AnyField
                                                readOnly={true}
                                                label='Название расходника (товара)'
                                                value={goodName ?? ''}
                                                error={validError}
                                                fullWidth></AnyField>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <AnyField
                                                readOnly={true}
                                                type='number'
                                                label='Необходимо'
                                                error={validError}
                                                value={goodMaterial?.count ?? 0}
                                                fullWidth></AnyField>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <AnyField
                                                readOnly={true}
                                                type='number'
                                                label='На складе'
                                                error={validError}
                                                value={goodMaterial.storages ?? 0}
                                                fullWidth></AnyField>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeGoodStorageGroupedModal(true)} disabled={this.validSave()} color="primary">{t('Ok')}</Button>
                    <Button onClick={this.closeGoodStorageGroupedModal(false)} color="primary">{t('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default InvoiceViewPage;