import React from "react";
import NoWrap from "./NoWrap";
import MoneyValue from "./MoneyValue";
import MoneyValueDetailsDialog from "./MoneyValueDetailsDialog";
import {formatMoney} from "../../../../../services/common";
import {Translation} from "react-i18next";
import MoneyValueDetailsLink from "./MoneyValueDetailsLink";
import moment from "moment";
import * as queryString from "query-string-object";

const processPdPayments = (payments) => {
    return payments?.map(payment => (
        <Translation key={payment.id}>
            {(t) => <div>{t(`pay.typesShort.${payment.type}`)}:&nbsp;{formatMoney(payment.amount)}</div>}
        </Translation>
    ))
}

const detailsPdInColumnData = [
    { id: 'num', label: 'Payment document' },
    { id: 'amount', processValue: formatMoney, label: 'Sum', getSortValue: value => value?.val },
    { id: 'buName', label: 'Business unit' },
    { id: 'dt', dateFormat: 'DD.MM.YYYY', label: 'Date' },
    { id: 'payments', label: 'Payments', processValue: processPdPayments },
]

const getPDHistoryBalancesLink = (tran) => (row) => {
    let dayStart, dayEnd
    if (Array.isArray(row.day)) {
        dayStart = moment.unix(row.day[0]).format('YYYY-MM-DD')
        dayEnd = moment.unix(row.day[1]).format('YYYY-MM-DD')
    } else {
        dayStart = dayEnd = moment(row.day).format('YYYY-MM-DD')
    }
    const search = queryString.stringify({
        businessUnitIds: [row.businessUnitId],
        tran: [tran],
        dayStart,
        dayEnd,
        excludeFree: 'true'
    })
    return `/pay-docs/report/history-balances?${search}`
}

const pdBalance = (value, t, row) => {
    if (!value) {
        return null;
    }

    return <NoWrap>
        <MoneyValue title="Balance" value={value.balance}/>
        <MoneyValueDetailsDialog
            title="Frozen2"
            value={value.frozen}
            detailsColumns={detailsPdInColumnData}
            detailsData={value.frozenDetails}
            detailsTitle="Frozen2"
            detailsLinkTemplate={row => ({ to: `/pay-docs/view/${row.pdId}`, target: '_blank' })}
        />
        <MoneyValueDetailsDialog
            title="Incoming"
            value={value.amountIn}
            detailsColumns={detailsPdInColumnData}
            detailsData={value.detailsIn}
            detailsTitle="PayDocRefill"
            detailsLinkTemplate={row => ({ to: `/pay-docs/view/${row.pdId}`, target: '_blank' })}
        >
            {value.incomePayments?.map?.(payment => (
                <div key={payment.key}>
                    {!payment.buOwner && `${payment.buName} `}
                    {t(`pay.typesShort.${payment.type}`)}:&nbsp;{formatMoney(payment.amount)}
                </div>
            ))}
        </MoneyValueDetailsDialog>
        <MoneyValueDetailsDialog
            title="Payed"
            value={value.amountOut}
            detailsColumns={detailsPdInColumnData}
            detailsData={value.amountOutDetails}
            detailsTitle="Payed"
            detailsLinkTemplate={row => ({ to: `/pay-docs/view/${row.pdId}`, target: '_blank' })}
        />
        <MoneyValueDetailsDialog
            title="Froze"
            value={value.amountFreeze}
            detailsColumns={detailsPdInColumnData}
            detailsData={value.amountFreezeDetails}
            detailsTitle="Froze"
            detailsLinkTemplate={row => ({ to: `/pay-docs/view/${row.pdId}`, target: '_blank' })}
        />
        <MoneyValueDetailsDialog
            title="Freeze payed"
            value={value.amountFreezePay}
            detailsColumns={detailsPdInColumnData}
            detailsData={value.amountFreezePayDetails}
            detailsTitle="Freeze payed"
            detailsLinkTemplate={row => ({ to: `/pay-docs/view/${row.pdId}`, target: '_blank' })}
        />
        <MoneyValue title="Unfroze" value={value.amountUnfreeze}/>
        <MoneyValueDetailsLink title="Burned amount" value={value.amountBurn}
                               href={getPDHistoryBalancesLink('burn_balance')(row)}/>
        <MoneyValueDetailsLink title="Correction" value={value.amountCorrection}
                               href={getPDHistoryBalancesLink('correction')(row)}/>
        <MoneyValue title="Burned frozen amount" value={value.amountBurnFrozen}/>
        <MoneyValueDetailsLink title="Refund amount" value={value.amountRefund}
                               href={getPDHistoryBalancesLink('refund')(row)}/>
    </NoWrap>;
};

export default pdBalance;
