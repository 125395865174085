import React from 'react';
import {MenuItem, Grid, Menu, Snackbar, IconButton} from '@material-ui/core';
import {connect} from "react-redux";
import calendarActions from "./calendar-actions";
import {
    availableToCutStatuses,
    closeVisit, completeVisit, confirmVisit, copyVisit, createVisit, cutVisit,
    navLinks, pasteVisit, provideVisit,
    visitStatuses
} from "../../services/calendar"
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import ActionButton from "../../components/button/ActionButton";
import Content from "../../components/content/Content";
import CalendarField from "../../components/fields/CalendarField";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import infoActions from "../../components/info/info-actions";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import MedicalCardDialog from "./MedicalCardDialog";
import ScheduleHeader from "./ScheduleHeader";
import CalendarLeftFields from "./CalendarLeftFields";
import {getParlours} from "../../services/organization";
import VisitCancelDialog from "./VisitCancelDialog";
import {hasRole} from "../../services/user";
import ScheduleVisitsWeek from "./ScheduleVisitsWeek";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";

const POPUP_CONFIRM = 'confirm';
const POPUP_COME = 'come';
const POPUP_BEGIN = 'begin';
const POPUP_COMPLETE = 'complete';
const POPUP_CANCEL = 'cancel';
const POPUP_CLOSE = 'close';
const POPUP_EDIT = 'edit';
const POPUP_CUT = 'cut';
const POPUP_COPY = 'copy';
const POPUP_PASTE = 'paste';

@withRouter
@connect(state => ({
    currentDate: state.calendar.currentDate,
    currentParlour: state.common.currentParlour,
    user: state.auth.user,
    buffer: state.calendar.buffer,
    bufferCutOrCopy: state.calendar.bufferCutOrCopy
}), {
    setCurrentDate: calendarActions.setCurrentDate,
    showMessage: messageDialogActions.show,
    showError: infoActions.showError,
    setBuffer: calendarActions.setBuffer,
    showInfo: infoActions.show,
})
@withTranslation()
class CalendarWeekPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedVisit: null,
            selectedMoment: null,
            selectedMaster: {
                id: null,
                name: null
            },
            visitAnchorEl: null,
            slotAnchorEl: null,
            openMedicalCardDialogVisitId: false,
            pasteConfirmBar: false,

            parlours: [],
            openCancelDialog: false,

            ...this.getDatesRange(),
        };
    }

    getDatesRange() {
        let timeZone = "+04:00";
        if (this.props.currentParlour) {
            timeZone = this.props.currentParlour.timeZone;
        }

        return {
            dateFrom: this.props.currentDate.clone().utcOffset(timeZone, true).startOf('day'),
            dateTo: this.props.currentDate.clone().add(7,'days').utcOffset(timeZone, true).endOf('day'),
        }
    }

    componentDidMount() {
        getParlours({'active': 1})
            .then(response => {
                if (response.success) {
                    this.setState({
                        parlours: response.data,
                    });
                }
            });

        document.addEventListener('keydown', this.handleKeyboardEvent);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentDate !== this.props.currentDate ||
            prevProps.currentParlour !== this.props.currentParlour) {
            this.setState({
                ...this.getDatesRange(),
                selectedVisit: null,
            });
        }
    }

    handleKeyboardEvent = (event) => {
        if (event.ctrlKey && event.code === 'KeyX') {
            this.cutVisit();
        }

        if (event.ctrlKey && event.code === 'KeyC') {
            this.copyVisit();
        }

        if (event.ctrlKey && event.code === 'KeyV') {
            this.pasteVisit();
        }
    }

    cutVisit = () => {
        cutVisit(this.props, this.state.selectedVisit);
    }

    copyVisit = () => {
        copyVisit(this.props, this.state.selectedVisit);
    }

    pasteVisit = () => {
        if (!this.props.buffer) {
            return;
        }

        this.setState({
            pasteConfirmBar: true
        })
    }

    handleAcceptPaste = () => {
        this.setState({
            pasteConfirmBar: false
        }, pasteVisit(this.props, this.state.selectedVisit, this.state.selectedMoment, this.state.selectedMaster));
    }

    handleDeclinePaste = () => {
        this.setState({
            pasteConfirmBar: false
        })
    }

    handleDateChange = date => {
        this.props.setCurrentDate(date);
    };

    handleVisitClick = visit => {
        this.setState({
            selectedVisit: visit,
            selectedMoment: null,
            selectedMaster: null,
        });
    };

    handleSlotContextMenu = (value, master, event) => {
        event.preventDefault();

        this.setState({
            selectedMoment: value,
            selectedMaster: master,
            selectedVisit: null,
            slotAnchorEl: event.currentTarget,
        })
    }

    handleVisitContextMenu = (visit, event) => {
        event.preventDefault();

        this.setState({
            selectedVisit: visit,
            visitAnchorEl: event.currentTarget,
        });
    };

    handleVisitDbClick = visit => {
        this.setState({selectedVisit: visit, selectedMoment: null, selectedMaster: null}, this.handleEditVisit);
    };

    handleSlotClick = (value, master) => {
        this.setState({selectedMoment: value, selectedMaster: master, selectedVisit: null});
    };

    handleSlotDbClick = (value, master) => {
        this.setState({
            selectedMoment: value,
            selectedMaster: master,
            selectedVisit: null,
        }, this.handleAddVisit);
    };

    handleAddVisit = () => {
        const { selectedMoment, selectedMaster } = this.state;
        const { currentParlour } = this.props;

        if (currentParlour && selectedMoment && selectedMaster) {
            createVisit({
                parlour: currentParlour,
                visitFacility: [{
                    start: selectedMoment,
                    master: [selectedMaster],
                }]
            })
                .then(response => {
                    if (response.success) {
                        this.props.history.push(`/orders/edit/${response.data.id}`);
                    } else {
                        this.props.showMessage(response.error ? response.error.message : response.message);
                    }
                });
        }
    };

    handleEditVisit = () => {
        this.props.history.push(`/orders/view/${this.state.selectedVisit.id}`);
    };

    handlePopupMenuClose = key => () => {
        const { selectedVisit } = this.state;

        this.setState({
            visitAnchorEl: null,
            slotAnchorEl: null
        });

        switch (key) {
            case POPUP_CONFIRM:
                confirmVisit(selectedVisit.id)
                    .then(response => {
                        if (!response.success) {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;
            case POPUP_COME:
                this.setState({
                    openMedicalCardDialogVisitId: selectedVisit.id
                });
                break;

            case POPUP_BEGIN:
                provideVisit(selectedVisit.id)
                    .then(response => {
                        if (!response.success) {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_COMPLETE:
                completeVisit(selectedVisit.id)
                    .then(response => {
                        if (!response.success) {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;


            case POPUP_CANCEL:
                this.setState({
                    openCancelDialog: true
                });
                break;

            case POPUP_CLOSE:
                closeVisit(selectedVisit.id)
                    .then(response => {
                        if (!response.success) {
                            this.props.showError(response.error ? response.error.message : response.message);
                        }
                    });
                break;

            case POPUP_EDIT:
                this.handleEditVisit();
                break;

            case POPUP_CUT:
                this.cutVisit();
                break;

            case POPUP_COPY:
                this.copyVisit();
                break;

            case POPUP_PASTE:
                this.pasteVisit();
                break;

            default:
                break;
        }
    };

    handleHeaderClick = date => {
        this.props.setCurrentDate(date.clone().startOf('day'));
        this.props.history.push('/orders');
    };

    closeMedicalCardDialog = () => {
        this.setState({
            openMedicalCardDialogVisitId: false
        });
    };

    render() {
        const { currentDate, t, currentParlour } = this.props;
        const { selectedVisit, selectedMoment, selectedMaster, visitAnchorEl, dateFrom, dateTo, openMedicalCardDialogVisitId, parlours, slotAnchorEl, pasteConfirmBar } = this.state;
        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <CalendarLeftFields
                        selectedMoment={selectedMoment}
                        selectedMaster={selectedMaster}
                        selectedVisit={selectedVisit}
                    />
                    <br />
                    <br />
                    <ActionButton disabled={!selectedMoment || !selectedMaster } visible={!selectedVisit} onClick={this.handleAddVisit}>{t("Add visit")}</ActionButton>
                    <ActionButton visible={Boolean(selectedVisit)} onClick={this.handleEditVisit}>{t("Edit visit")}</ActionButton>
                    <ActionButton visible={false}>Распечатать расписание</ActionButton>
                    <br />
                    <br />
                    <CalendarField
                        value={currentDate}
                        onChange={this.handleDateChange}
                    />
                </LeftBar>
                <Content title={t("Schedule")}>
                    <Grid container spacing={1}>
                        <ScheduleHeader parlours={parlours}/>
                        <Grid item xs={12}>
                            <ScheduleVisitsWeek
                                dateFrom={dateFrom}
                                dateTo={dateTo}

                                onSlotClick={this.handleSlotClick}
                                onSlotContextMenu={this.handleSlotContextMenu}
                                onSlotDbClick={this.handleSlotDbClick}

                                selectedVisit={selectedVisit}

                                onVisitClick={this.handleVisitClick}
                                onVisitDbClick={this.handleVisitDbClick}
                                onVisitContextMenu={this.handleVisitContextMenu}

                                onHeaderClick={this.handleHeaderClick}

                                parlour={currentParlour}
                            />
                        </Grid>
                    </Grid>
                </Content>
                <Menu
                    id="visit-popup-menu"
                    anchorEl={visitAnchorEl}
                    open={Boolean(visitAnchorEl)}
                    onClose={this.handlePopupMenuClose(null)}
                >
                    {this.renderItem(t("Confirm"), POPUP_CONFIRM, [visitStatuses.created, visitStatuses.risky])}
                    {this.renderItem(t("Guest came"), POPUP_COME, [visitStatuses.confirmed, visitStatuses.risky, visitStatuses.created], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderItem(t("Start of service"), POPUP_BEGIN, [visitStatuses.starting], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderItem(t("End of service"), POPUP_COMPLETE, [visitStatuses.providing], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))}
                    {this.renderItem(t("Close"), POPUP_CLOSE, [visitStatuses.created, visitStatuses.confirmed, visitStatuses.starting, visitStatuses.providing, visitStatuses.complete], Boolean(selectedVisit && !selectedVisit.hasWaitingMasters))/*TODO: Временно разрешено*/}
                    {this.renderItem(t("Cancel"), POPUP_CANCEL, [visitStatuses.confirmed, visitStatuses.created, visitStatuses.risky])}
                    {this.renderItem(t("View"), POPUP_EDIT, Object.keys(visitStatuses))}
                    {this.renderItem(t("Cut"), POPUP_CUT, availableToCutStatuses)}
                    {this.renderItem(t("Copy"), POPUP_COPY, [])}
                </Menu>
                <Menu
                    id="visit-popup-menu"
                    anchorEl={slotAnchorEl}
                    open={Boolean(slotAnchorEl)}
                    onClose={this.handlePopupMenuClose(null)}
                >
                    {this.renderSlotMenuItem(t('Paste'), POPUP_PASTE)}
                </Menu>
                <Snackbar
                    open={pasteConfirmBar}
                    color=""
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    message={<span>{t("Paste visit in this slot Are you sure")}</span>}
                    action={[
                        <IconButton
                            key="accept-paste"
                            onClick={this.handleAcceptPaste}
                            color="inherit"
                        >
                            <CheckIcon />
                        </IconButton>,
                        <IconButton
                            key="cancel-paste"
                            onClick={this.handleDeclinePaste}
                            color="inherit"
                        >
                            <CancelIcon />
                        </IconButton>,
                    ]}
                />
                { openMedicalCardDialogVisitId && <MedicalCardDialog open={!!openMedicalCardDialogVisitId} visitId={openMedicalCardDialogVisitId} onClose={this.closeMedicalCardDialog} startVisit={true}/> }
                { this.state.openCancelDialog && <VisitCancelDialog onClose={() => { this.setState({ openCancelDialog: false }) }} open={this.state.openCancelDialog} visitId={selectedVisit.id} />}
            </React.Fragment>
        );
    }

    renderItem(title, key, statuses)
    {
        const { selectedVisit } = this.state;

        if (key === POPUP_CONFIRM) {
            if (!(selectedVisit && selectedVisit.parlour && selectedVisit.parlour.hasRiskyStatus)) {
                return null;
            }
        }

        if ( selectedVisit && (!statuses.length || statuses.indexOf(selectedVisit.status) >= 0) ) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }

    renderVisitMenuItem(title, key, statuses = [], roleOrCondition = true)
    {
        const { selectedVisit } = this.state;
        let enabled = roleOrCondition;

        if (typeof roleOrCondition === 'string' || roleOrCondition instanceof String) {
            enabled = hasRole(roleOrCondition);
        }

        if ( selectedVisit && statuses.indexOf(selectedVisit.status) >= 0 && enabled ) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }

    renderSlotMenuItem(title, key)
    {
        const { selectedMoment } = this.state;

        if ( selectedMoment ) {
            return (
                <MenuItem key={key} onClick={this.handlePopupMenuClose(key)} dense>{title}</MenuItem>
            )
        }

        return null;
    }
}

export default CalendarWeekPage;
