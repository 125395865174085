import React from 'react';
import {Grid, Paper} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import AnyField from "../../components/fields/AnyField";
import { connect } from 'react-redux';
import LoadingButton from "../../components/button/LoadingButton";
import {passwordRecovery, requestRecoveryPassword} from "../../services/user";
import {withRouter} from "react-router";
import infoActions from "../../components/info/info-actions";

export default
@withStyles(theme => ({
    root: {
        textAlign: 'center',
        flexGrow: 1,
    },
    paper: theme.mixins.gutters({
        padding:  theme.spacing(1),
        marginTop:  theme.spacing(3),
        width: 300,
        display: 'inline-block',
    }),
    textField: {
        marginLeft:  theme.spacing(1),
        marginRight:  theme.spacing(1),
        width: 200,
    },
    button: {
        margin:  theme.spacing(1),
    },
}))
@withRouter
@withTranslation()
@connect(state => ({
    isLoginPending: state.auth.isLoginPending,
}),
    {
        showInfo: infoActions.show,
        showError: infoActions.showError,
})

class RecoveryPage extends React.Component {
    state = {
        login: null,
        keyChangePassword: null,
        newPassword: null,
        verificationNewPassword: null,
    }

    componentDidMount() {
        this.init();
    }

    init() {
        const path = this.props.location.pathname;
        const reId = new RegExp('/password-recovery/([^/]+)$');
        const result = reId.exec(path);

        if (Array.isArray(result) && result[1]) {
            this.setState({
                keyChangePassword: result[1]
            })
        }
    }

    render() {
        const {classes} = this.props;
        const {keyChangePassword} = this.state;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper} square>
                    <Grid container spacing={2}>
                        {
                            keyChangePassword
                            ? this.renderChangePassword()
                            : this.renderInputLogin()
                        }
                    </Grid>
                </Paper>
            </div>
        )
    }

    handleChange = (props) => (event) => {
        this.setState({
            [props]: event.target.value,
        })
    }

    handleSubmit = event => {
        const {login} = this.state;
        const { t } = this.props;

        requestRecoveryPassword(login)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Check email'));
                    this.props.history.push('/login');
                } else {
                    this.props.showError(response.error ? t(response.error.message) : response.message);
                }
            })

    };

    renderInputLogin = () => {
        const { classes, t, isLoginPending } = this.props;

        return (
        <React.Fragment>
            <Grid item xs={12}>
                <AnyField
                    className={classes.textField}
                    label={t("Login")}
                    autoFocus={true}
                    value={this.state.login}
                    onChange={this.handleChange('login')}
                    disabled={isLoginPending}
                />
            </Grid>
            <Grid item xs={12}>
                <LoadingButton className={classes.button} onClick={this.handleSubmit} disabled={!this.state.login} loading={isLoginPending}>{t('Submit')}</LoadingButton>
            </Grid>
        </React.Fragment>
        )
    }

    renderChangePassword = () => {
        const { classes, t } = this.props;
        const { newPassword, verificationNewPassword } = this.state;

        return (
            <React.Fragment>
                <AnyField
                    label={t("New password")}
                    value={newPassword}
                    onChange={this.handleChange('newPassword')}
                    type="password"
                    error={newPassword && verificationNewPassword && newPassword !== verificationNewPassword}
                    helperText={newPassword && newPassword.length < 4 ? 'Минимальная длина пароля 4 символа' : null}
                    fullWidth
                />
                <AnyField
                    label={t("Confirm new password")}
                    value={verificationNewPassword}
                    onChange={this.handleChange('verificationNewPassword')}
                    type="password"
                    error={newPassword && verificationNewPassword && newPassword !== verificationNewPassword}
                    helperText={verificationNewPassword && newPassword !== verificationNewPassword ? 'Введенные пароли не совпадают' : null}
                    fullWidth
                />
                <Grid item xs={12}>
                    <LoadingButton onClick={this.handleChangePassword} className={classes.button} disabled={this.checkDisableSubmit()}>{t('Password сhange')}</LoadingButton>
                </Grid>
            </React.Fragment>
        );
    }

    checkDisableSubmit = () => {
        const {newPassword, verificationNewPassword} = this.state;

        return !newPassword || newPassword.length < 4 || !verificationNewPassword || (newPassword && verificationNewPassword && newPassword !== verificationNewPassword)
    }

    handleChangePassword = () => {
        const { newPassword, keyChangePassword } = this.state;
        const { t } = this.props;

        passwordRecovery(newPassword, keyChangePassword)
            .then(response => {
                if (response.success) {
                    this.props.showInfo(t('Password is changed'));
                    this.props.history.push('/login');
                } else {
                    this.props.showError(response.error ? t(response.error.message) : response.message);
                }
            })
    }
}